import React from 'react';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IPostageFees } from '../../../../interfaces/postage-fees/IPostageFees';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import Table from '../../../../shared/table';

const tableColumns = ['Market', 'Asset Template', 'Postage Fee', 'Actions']

interface IPostageFeesTable {
    rows: IPostageFees[];
    paginationData?: IPage;
    markets?: IAllMarkets[];
    accessControl?: IAccessControl;
    actions?: IPageActions;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onDeletePostageFee: (id: string) => void;
    onEditPostageFee: (id: string) => void;
}

const PostageFeesTable = ({
    rows,
    paginationData,
    markets,
    accessControl,
    actions,
    rowsLoading,
    setPaginationState,
    onDeletePostageFee,
    onEditPostageFee
}: IPostageFeesTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IPostageFees, idx: number) => {
                        const findMarket = markets && markets?.length > 0 && markets?.find((market) => market?._id === row?.market?._id)
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.market?.label || ''}</td>
                                <td className='px-6 py-4'>{row?.assetTemplate?.displayName || ''}</td>
                                <td className='px-6 py-4'>{findMarket && CURRENCY_SYMBOLS?.[`${(findMarket?.currency?.name || '')}`]}{Number(row?.postageFee || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa={'edit-postage-fee'} onClick={() => ((!accessControl || actions?.['Edit Postage Fee']) && row?._id) && onEditPostageFee(row?._id)}>
                                            <img title='Edit Postage Fee' alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Postage Fee']) ? 'cursor-pointer' : 'cursor-not-allwed'} mr-3`} />
                                        </div>
                                        <div data-qa={'delete-postage-fee'} onClick={() => ((!accessControl || actions?.['Delete Postage Fee']) && row?._id) && onDeletePostageFee(row?._id)}>
                                            <img title={'Delete Postage Fee'} alt='delete market' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Postage Fee']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default PostageFeesTable;