import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import DropDownButton from '../../../../shared/dropdown-button';
import SelectCheckbox from '../../../../shared/select-checkbox';

const BooleanItems = [
    { name: 'Yes' },
    { name: 'No' },
]

interface IPartnersFilters {
    markets?: Array<IAllMarkets>;
    sectors?: Array<IAssetTemplate>;
    selectedMarket?: string;
    selectedEnabled?: string;
    selectedLogistic?: string;
    selectedInventory?: string;
    selectedSector?: string;
    selectedBroker?: string;
    onSelectMarket: (id: string) => void;
    onSelectSector: (id: string) => void;
    onChangeSelectedBooleanItems: (type: string, name?: string) => void;
}

const PartnersFilters = ({
    markets,
    sectors,
    selectedMarket,
    selectedSector,
    selectedEnabled,
    selectedLogistic,
    selectedInventory,
    selectedBroker,
    onSelectMarket,
    onSelectSector,
    onChangeSelectedBooleanItems
}: IPartnersFilters) => {
    return (
        <div>
            <div className='mb-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-4'>
                <div>
                    <SelectCheckbox
                        name={'Markets'}
                        selectStyle={{backgroundColor:'transparent', borderRadius:'15px'}}
                        placeholder={' '}
                        selectedOption={selectedMarket}
                        options={markets || []}
                        onChangeSelectedOption={onSelectMarket}
                        dataQa={'markets-filter'}
                    />
                </div>
                <div>
                    <SelectCheckbox
                        name={'Categories'}
                        selectStyle={{backgroundColor:'transparent', borderRadius:'15px'}}
                        placeholder={' '}
                        selectedOption={selectedSector}
                        options={sectors || []}
                        onChangeSelectedOption={onSelectSector}
                        dataQa={'categories-filter'}
                    />
                </div>
            </div>
            <div className='mb-6 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-4'>
                <div className='my-2'>
                    <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Enabled</p>
                    <DropDownButton
                        name={selectedEnabled || 'Select'}
                        buttonClassName={'!min-w-[200px]'}
                        dropdownUniqueName={'enabled'}
                        items={BooleanItems}
                        onClickItem={onChangeSelectedBooleanItems}
                        dataQa={'enabled-filter'}
                        
                    />
                </div>
                <div className='my-2'>
                    <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Inventory</p>
                    <DropDownButton
                        name={selectedInventory || 'Select'}
                        buttonClassName={'!min-w-[200px]'}
                        dropdownUniqueName={'inventory'}
                        items={BooleanItems}
                        onClickItem={onChangeSelectedBooleanItems}
                        dataQa={'inventory-filter'}
                    />
                </div>
                <div className='my-2'>
                    <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Logistic</p>
                    <DropDownButton
                        name={selectedLogistic || 'Select'}
                        dropdownUniqueName={'logistic'}
                        buttonClassName={'!min-w-[200px]'}
                        items={BooleanItems}
                        onClickItem={onChangeSelectedBooleanItems}
                        dataQa={'logistic-filter'}
                    />
                </div>
                <div className='my-2'>
                    <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Broker</p>
                    <DropDownButton
                        name={selectedBroker || 'Select'}
                        dropdownUniqueName={'broker'}
                        buttonClassName={'!min-w-[200px]'}
                        items={BooleanItems}
                        onClickItem={onChangeSelectedBooleanItems}
                        dataQa={'broker-filter'}
                    />
                </div>
            </div>
        </div >
    )
}

export default PartnersFilters;