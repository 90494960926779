import { AxiosResponse } from 'axios';
import { CS_API_URL } from '../../../../config';
import { PaginatedTicket } from '../../../../interfaces/customer-care/paginatedStore.type';
import { IFilterTicket, ITicketThread } from '../../../../interfaces/customer-care/ITicket';
import csAxios from '../../../../utils/axios/customer-support.axios';

const tryToFetchFilteredTickets = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: IFilterTicket,
): Promise<AxiosResponse<PaginatedTicket>> => {
    const url = filters
        ? `${CS_API_URL}/support/user/tickets?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${CS_API_URL}/support/user/tickets?page=${pageNumber}&size=${pageSize}`;

    return csAxios.post<PaginatedTicket>(url, data);
};

const tryToFetchUserTickets = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: { opened: boolean },
): Promise<AxiosResponse<PaginatedTicket>> => {
    return csAxios.post<PaginatedTicket>(`${CS_API_URL}/support/user/tickets?page=${pageNumber}&size=${pageSize}&search=${filters}`, data);
};

const tryToAddTicket = (data: any): Promise<AxiosResponse> => {
    return csAxios.post(`${CS_API_URL}/support`, data);
};

const tryToAddTicketThread = (ticketId: string, data: ITicketThread): Promise<AxiosResponse> => {
    return csAxios.post(`${CS_API_URL}/support/ticket/${ticketId}/create-thread`, data)
}

export const CustomerCareService = {
    tryToFetchFilteredTickets,
    tryToFetchUserTickets,
    tryToAddTicket,
    tryToAddTicketThread
};