import React from 'react';
import Button from '../button';
import Modal from '../modal';

interface IDeleteModal {
    openDeleteModal: boolean;
    handleCloseDeleteModal: () => void;
    onApproveDeletion: () => void;
}

const DeleteModal = ({
    openDeleteModal,
    handleCloseDeleteModal,
    onApproveDeletion
}: IDeleteModal) => {
    return (
        <Modal
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Delete Item</p>
                </div>
                <p className='my-2'>Are you sure you want to delete the item?</p>
                <div className='flex flex-row justify-end my-2'>
                    <Button
                        label='Continue'
                        className='btn-error'
                        onClickButton={onApproveDeletion}
                        dataQa={'delete-modal'}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default DeleteModal;