import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ISection } from '../../../interfaces/sections/ISection';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToDeleteSection,
    tryToEnableDisableSection,
    tryToFetchAllSectionsPaginated,
} from '../../../store/brokers/admin/sections/sectionsSlice';
import { SECTION_BUSINESS_TYPE } from '../../../utils/constants/section-business-types';
import { SECTION_USER_TYPES } from '../../../utils/constants/section-user-types';
import PagesTable from './tables/PagesTable';
import PageModal from './modals/PageModal';
import SelectCheckbox from '../../../shared/select-checkbox';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Input from '../../../shared/input';
import Toast from '../../../shared/toast';


interface ISectionFilters {
    search?: string;
    type?: string;
    businessType?: string;
}

const Pages = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const state = useAppSelector((state) => state.sections);
    const [showPageModal, setShowPageModal] = useState<boolean>(false);
    const [showDeletePageModal, setShowDeletePageModal] = useState<boolean>(false);
    const [pageId, setPageId] = useState<string | null>(null);
    const [rows, setRows] = useState<ISection[]>([]);
    const [showToast, setShowToast] = useState<any>({});
    const [filters, setFilters] = useState<ISectionFilters | null>(null);
    const [selectedType, setSelectedType] = useState<any>(null);
    const [selectedBusinessType, setSelectedBusinessType] = useState<any>(null);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state.sections.paginatedSections?.data?.page);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Pages')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        });
    };

    useEffect(() => {
        if (state.paginatedSections) {
            const rows =
                state.paginatedSections &&
                    Array.isArray(state.paginatedSections?.data?.elements)
                    ? state.paginatedSections?.data?.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state]);

    const getAllSectionsPaginated = async () => {
        let payload: any = { ...paginationState };
        if (filters) {
            payload = { ...payload, filters };
        }
        await dispatch(tryToFetchAllSectionsPaginated(payload));
    };

    useEffect(() => {
        setFilters(null);
        getAllSectionsPaginated();
    }, []);

    useEffect(() => {
        setPageId(null);
        getAllSectionsPaginated();
    }, [paginationState]);

    const onViewPage = (id: string) => {
        navigate(`/pages/${id}`);
    };

    const onAddNewPage = async () => {
        setPageId(null);
        setShowPageModal(true);
    };

    const onEditPage = async (id: string) => {
        setShowPageModal(true);
        setPageId(id);
    };

    const onShowDeletePageModal = (id: string) => {
        setPageId(id);
        setShowDeletePageModal(true);
    };

    const onDeletePage = async () => {
        try {
            if (pageId) {
                await dispatch(tryToDeleteSection(pageId)).unwrap();
                setShowToast({
                    type: 'success',
                    message: 'Page was successfully deleted',
                });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setPageId(null);
        setShowDeletePageModal(false);
        getAllSectionsPaginated();
    };

    const onCloseDeletePageModal = () => {
        setPageId(null);
        setShowDeletePageModal(false);
    };

    const onClosePageModal = () => {
        setPageId(null);
        setShowPageModal(false);
        getAllSectionsPaginated();
    };

    const onSavePageChanges = (type: string, message: string) => {
        getAllSectionsPaginated();
        setShowToast({ type, message });
    };

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setFilters({
            ...filters,
            search: value || undefined,
        });
        onResetPaginationState();
    };

    const onChangeSelectTypeOption = (option: any) => {
        setFilters({ ...filters, type: option.value });
        setSelectedType(option);
        onResetPaginationState();
    };

    const onChangeSelectBusinessTypeOption = (option: any) => {
        setFilters({ ...filters, businessType: option.map((opt: any) => opt.value) });
        setSelectedBusinessType(option);
        onResetPaginationState();
    };

    const onClearFilters = () => {
        setFilters(null);
        setSelectedBusinessType(undefined)
        setSelectedType(null)
    };

    useEffect(() => {
        if (filters?.businessType?.length === 0) delete filters.businessType
        getAllSectionsPaginated();
    }, [filters]);

    const onEnableDisablePage = async (id: string, enabled: boolean) => {
        try {
            if (id) {
                await dispatch(tryToEnableDisableSection({ id: id, status: !enabled })).unwrap();
                setShowToast({
                    type: 'success',
                    message: `Page was successfully ${enabled ? 'disabled' : 'enabled'}`,
                });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
    }

    return (
        <div>
            <div>
                <p className='page-title'>Pages</p>
            </div>
            <div className='flex flex-row justify-end mb-2'>
                <Button
                    label={'Add New Page'}
                    dataQa={'add-new-page'}
                    className={(!accessControl || pageAccess?.actions?.['Add New Page']) ? 'btn-main' : 'btn-main-disable'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Page']) && onAddNewPage()}
                />
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 justify-start items-center'>
                <div>
                    <Input
                        label='Search'
                        dataQa={'filter-table'}
                        placeholder={'Filter Table'}
                        showValue={true}
                        inputValue={filters?.search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        containerStyle='mb-0 min-w-[90%]'
                    />
                </div>
                <div>
                    <SelectCheckbox
                        name={'Type'}
                        dataQa={'type-selected'}
                        selectedOption={selectedType}
                        options={SECTION_USER_TYPES}
                        onChangeSelectedOption={onChangeSelectTypeOption}
                        containerStyle='min-w-[90%]'
                    />
                </div>
                <div>
                    <SelectCheckbox
                        name={'Business Type'}
                        selectedOption={selectedBusinessType}
                        dataQa={'business-type'}
                        options={SECTION_BUSINESS_TYPE}
                        onChangeSelectedOption={onChangeSelectBusinessTypeOption}
                        containerStyle='min-w-[90%]'
                        multiple={true}
                    />
                </div>
                <div>
                    <Button
                        label={'Clear Filters'}
                        dataQa={'clear-filters'}
                        onClickButton={onClearFilters}
                        className={'!shadow-none btn-primary-text-underline'}
                    />
                </div>
            </div>
            <div>
                <PagesTable
                    rows={rows}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    onEnableDisablePage={onEnableDisablePage}
                    setPaginationState={setPaginationState}
                    onViewPage={onViewPage}
                    onEditPage={onEditPage}
                    onDeletePage={onShowDeletePageModal}
                />
            </div>
            <PageModal
                openPageModal={showPageModal}
                handleClosePageModal={onClosePageModal}
                onSaveChanges={onSavePageChanges}
                pageId={pageId || ''}
            />
            <DeleteModal
                openDeleteModal={showDeletePageModal}
                handleCloseDeleteModal={onCloseDeletePageModal}
                onApproveDeletion={onDeletePage}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    );
};

export default Pages;
