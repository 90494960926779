import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { Shipment } from '../../../../interfaces/proforma/IProforma';
import { IInvoices } from '../../../../interfaces/invoices/IInvoices';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToFetchProformas } from '../../../../store/brokers/partners/sales-orders/salesOrdersSlice';
import TableActions from '../details/TableActions';
import Table from '../../../../shared/table';

const tableColumns = ['Invoice Number', 'Quote ID', 'Market', 'Total Items Amount', 'Total Postage', 'Total Commision', 'Total Amount', 'Actions']

interface IListTable {
    rows: IInvoices[];
    paginationData?: IPage;
    activeMarket?: IActiveMarket;
    accessInvoiceDetails?: any;
    accessCSV?: any;
    accessPDF?: any;
    search?: string
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeInvoiceDetails: (invoiceNumber: string) => void;
}

const ProformasTab = ({
    activeMarket,
    accessCSV,
    accessPDF,
    search,
    accessInvoiceDetails,
    onSeeInvoiceDetails,
}: IListTable) => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<Shipment[]>([])
    const [paginationData, setPaginationData] = useState()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [invoiceData, setInvoiceData] = useState<any>()
    const [searchParams, setSearchParams] = useSearchParams();

    const getProformas = async () => {
        const data = await dispatch(tryToFetchProformas({...paginationState, search: `${search || ''}`})).unwrap()
        setRows(data?.data?.elements)
        setPaginationData(data?.data?.page)
        setInvoiceData(2)
        searchParams.set('page', `${paginationState?.pageNumber}`)
        searchParams.set('size', `${paginationState?.pageSize}`)
        if(search){
            searchParams.set('search', `${search}`)
        }else{
            searchParams.delete('search')
        }
        setSearchParams(searchParams);
    }

    useEffect(() => {
        getProformas()
    }, [paginationState, search])

    useEffect(() => {
        if (invoiceData && loadingDownload) {
            csvLink.current.link.click()
        }
        setLoadingDownload(false)
    }, [invoiceData])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
        >
            {rows?.length > 0 && rows?.map((row: Shipment, idx: number) => {
                const currency = CURRENCY_SYMBOLS?.[`${(row?.currency || '')}`]
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?.proFormData?.invoiceNumber}</td>
                        <td className='px-6 py-4'>{row?.quoteId}</td>
                        <td className='px-6 py-4'>{activeMarket?.market?.label || ''}</td>
                        <td className='px-6 py-4'>{currency}{(row?.proFormData?.totalItemsAmount || 0).toFixed(2) || ''}</td>
                        <td className='px-6 py-4'>{currency}{(row?.proFormData?.totalPostage || 0 ).toFixed(2) || ''}</td>
                        <td className='px-6 py-4'>{currency}{(row?.proFormData?.totalCommission || 0).toFixed(2) || ''}</td>
                        <td className='px-6 py-4'>{currency}{(row?.proFormData?.totalAmount || 0).toFixed(2) || ''}</td>
                        <td className='px-6 py-4'>
                            <TableActions
                                onClickCSVButton={() => {}}
                                csvLabel={loadingDownload ? 'Loading data...' : 'CSV Details'}
                                pdfLink={row?.proFormData?.pdfLink}
                                excelUrl={row?.proFormData?.csvLink}
                                accessCSV={accessCSV}
                                accessPDF={accessPDF}
                                onClickSeeDetails={() => accessInvoiceDetails && row?.proFormData?.invoiceNumber && onSeeInvoiceDetails(row?.proFormData?.invoiceNumber)}
                            />
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default ProformasTab;