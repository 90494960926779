import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPricingReviewsListState } from '../../../../interfaces/pricing-review/IPricingReviewsList';
import { PricingReviewsService } from './pricingReviewsApi';
import { PaginatedPricingReviews } from '../../../../interfaces/pricing-review/paginatedStore.type';
import { IPricingReview } from '../../../../interfaces/pricing-review/IPricingReview';
import { IEditPricingReview } from '../../../../interfaces/pricing-review/IEditPricingReview';
import { IChangePricingReviewStatus } from '../../../../interfaces/pricing-review/IChangePricingReviewStatus';

const initialState: IPricingReviewsListState = {
    pricingReviewIsLoading: false,
    pricingReviewsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchFilteredPricingReviews = createAsyncThunk<PaginatedPricingReviews, IPaginationPayload>(
    'price-settings/tryToFetchFilteredPricingReviews',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await PricingReviewsService.tryToFetchFilteredPricingReviews(pageNumber, pageSize, filters, data);
        return result?.data;
    },
);

export const tryToAddPriceSettings = createAsyncThunk<ApiResponse<IPricingReview>, IPricingReview>(
    'price-settings/tryToAddPriceSettings',
    async (data: IPricingReview, { rejectWithValue }) => {
        try {
            const result = await PricingReviewsService.tryToAddPriceSettings(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditPriceSetting = createAsyncThunk<ApiResponse<IPricingReview>, IEditPricingReview>(
    'price-settings/tryToEditPriceSetting',
    async (data: IEditPricingReview, { rejectWithValue }) => {
        try {

            const result = await PricingReviewsService.tryToEditPriceSetting(data._id || '', data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToChangePriceSettingStatus = createAsyncThunk<ApiResponse<IPricingReview>, IChangePricingReviewStatus>(
    'price-settings/tryToChangePriceSettingStatus',
    async (data: IChangePricingReviewStatus, { rejectWithValue }) => {
        try {
            const result = await PricingReviewsService.tryToChangePriceSettingStatus(data._id || '', data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchSinglePriceSetting = createAsyncThunk<IPricingReview, string>(
    'price-settings/tryToFetchSinglePriceSetting',
    async (id: string) => {
        const result = await PricingReviewsService.tryToFetchSinglePriceSetting(id);
        return result?.data;
    },
);


export const pricingReviewsSlice = createSlice({
    name: 'pricingReviews',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchFilteredPricingReviews
            .addCase(tryToFetchFilteredPricingReviews.pending, (state) => {
                state.pricingReviewsAreLoading = true;
            })
            .addCase(tryToFetchFilteredPricingReviews.rejected, (state) => {
                state.pricingReviewsAreLoading = false;
            })
            .addCase(tryToFetchFilteredPricingReviews.fulfilled, (state, action) => {
                state.pricingReviewsAreLoading = false;
                state.pricingReviews = action.payload;
            })

            // tryToAddPriceSettings
            .addCase(tryToAddPriceSettings.pending, (state) => {
                state.pricingReviewsAreLoading = true;
            })
            .addCase(tryToAddPriceSettings.rejected, (state) => {
                state.pricingReviewsAreLoading = false;
            })
            .addCase(tryToAddPriceSettings.fulfilled, (state, action) => {
                state.pricingReviewsAreLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.pricingReviews?.data) state.pricingReviews.data.elements = [...state.pricingReviews?.data.elements, data];
            })

            // tryToFetchSinglePriceSetting
            .addCase(tryToFetchSinglePriceSetting.pending, (state) => {
                state.pricingReviewIsLoading = true;
            })
            .addCase(tryToFetchSinglePriceSetting.rejected, (state) => {
                state.pricingReviewIsLoading = false;
            })
            .addCase(tryToFetchSinglePriceSetting.fulfilled, (state, action) => {
                state.pricingReviewIsLoading = false;
                state.pricingReviewDetails = action.payload;
            })

            // tryToChangePriceSettingStatus
            .addCase(tryToChangePriceSettingStatus.pending, (state) => {
                state.pricingReviewIsLoading = true;
            })
            .addCase(tryToChangePriceSettingStatus.rejected, (state) => {
                state.pricingReviewIsLoading = false;
            })
            .addCase(tryToChangePriceSettingStatus.fulfilled, (state, action) => {
                state.pricingReviewIsLoading = false;
                state.requestStatus = 'success';
                if (state.pricingReviews?.data.elements)
                    state.pricingReviews.data.elements =
                        { ...state }.pricingReviews?.data.elements.map((review) => {
                            if (review?._id === action.meta?.arg?._id) {
                                const formatOffers = review?.settings && review?.settings?.length > 0 && review?.settings?.map((offer) => {
                                    if (offer?.enabled) {
                                        return {
                                            ...offer,
                                            status: action?.meta?.arg?.status
                                        }
                                    } else {
                                        return review
                                    }
                                })
                                return {
                                    ...review,
                                    settings: formatOffers || []
                                }
                            } else {
                                return review
                            }
                        }) || [];
            })

            // tryToEditPriceSetting
            .addCase(tryToEditPriceSetting.pending, (state) => {
                state.pricingReviewIsLoading = true;
            })
            .addCase(tryToEditPriceSetting.rejected, (state) => {
                state.pricingReviewIsLoading = false;
            })
            .addCase(tryToEditPriceSetting.fulfilled, (state, action) => {
                state.pricingReviewIsLoading = false;
                if (state.pricingReviews?.data.elements)
                    state.pricingReviews.data.elements =
                        { ...state }.pricingReviews?.data.elements.map((review) => {
                            if (review?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return review;
                            }
                        }) || [];
                state.pricingReviewDetails = action.payload.data;
            })
    },
});

export default pricingReviewsSlice.reducer;