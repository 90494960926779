import React from 'react';
import { IOrders } from '../../../../interfaces/orders/IOrders';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import Table from '../../../../shared/table';

const tableColumns = ['Order ID', 'Tracking Number', 'Total Items', 'Purchase Price', 'VAT', 'Actions']

interface IOrdersTable {
    rows: Array<IOrders>;
    paginationData?: IPage;
    activeMarket?: IActiveMarket;
    ordersLoading: boolean;
    pageAccess?: any;
    accessControl?: any;
    onSeeOrderDetails: (orderId: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const OrdersTable = ({
    rows,
    paginationData,
    activeMarket,
    ordersLoading,
    accessControl,
    pageAccess,
    onSeeOrderDetails,
    setPaginationState,
}: IOrdersTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {ordersLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className={`px-6 py-4`}>{row?._id}</td>
                                <td className='px-6 py-4'>
                                    <a
                                        rel='noreferrer'
                                        href={`https://parcelsapp.com/en/tracking/${row?.trackingNumber}`}
                                        target='_blank'
                                        className='text-primary-default'
                                    >
                                        {row?.trackingNumber}
                                    </a>
                                </td>
                                <td className={`px-6 py-4`}>{row?.totalItems || 0}</td>
                                <td className={`px-6 py-4`}>{CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.descriptor?.name || '')}`]}{row?.totalPrice || 0}</td>
                                <td className={`px-6 py-4`}>{row?.vatType}</td>
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <div className='flex flex-row justify-start'>
                                        <div data-qa={'view-details'} onClick={() => row?._id && (!accessControl || pageAccess?.['See Package Details']) && onSeeOrderDetails(row?._id)}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={'h-[14px] object-contain cursor-pointer mr-2'} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table >
    )
}

export default OrdersTable;