import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../shared/button';

const PasswordActionStatus = () => {
    const navigate = useNavigate()
    return (
        <div className='flex flex-col items-center py-5 min-w-full'>
            <img src={'/assets/shared/success-mark.svg'} className={'w-[100px] object-contain mb-4'} />
            <p className='my-3 text-primary-light font-medium text-md'>Please check email to Reset Password</p>
            <Button
                label={'Reset Password'}
                className={'btn-main !shadow-none min-w-[130px] my-3'}
                onClickButton={() => navigate('/reset-password')}
                dataQa={'reset-password'}
            />
        </div>
    )
}
export default PasswordActionStatus;