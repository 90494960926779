import React from 'react';
import Modal from '../../../shared/modal';

const ShowAiPriceModal = ({
  show,
  onClose,
}: {
  show: boolean
  onClose: () => void
}) => {
  return (
    <Modal
	  open={!!show}
      onClose={onClose}
	  contentContainerStyle='w-[512px]'
    >
      <div className="relative w-[512px]">
        <p className="absolute top-[30%] text-center w-full text-black text-lg">
          Loading..
        </p>
        <iframe
          src={process.env.REACT_APP_AI_GRADING}
          width="100%"
          height="100%"
          frameBorder="0"
          allow={`camera ${process.env.REACT_APP_AI_GRADING}`}
          className="z-20 bg-white relative"
          style={{ minHeight: 750 }}
        ></iframe>
      </div>
    </Modal>
  )
}

export default ShowAiPriceModal
