import React from 'react';


const MarketShippingServicesContainer = () => {
    return (
        <div className='my-5'>
            <div className='flex flex-row item-center justify-center animate-pulse'>
                <div className='bg-[#f9fbfc] w-[40%] min-h-[90px] p-2 flex flex-col items-center my-4'>
                    <div className='h-4 bg-gray-200 rounded dark:bg-gray-200 w-[125px] mb-2'></div>
                    <div className='h-[29px] bg-gray-200 rounded-full dark:bg-gray-200 w-[29px] mt-1'></div>
                </div>
            </div>
            {[...Array(4)].map((x, i) => {
                return (
                    <div key={i} className='px-6 shadow-md bg-white mb-2 text-left items-center h-20 select-none flex justify-between flex-row'>
                        <div className='grid grid-cols-2 gap-4 min-w-[85%] max-w-sm '>
                            <div className='flex flex-row item-center animate-pulse'>
                                <div className='h-[25px] bg-gray-200 rounded-full dark:bg-gray-200 w-[25px]  mr-2'></div>
                                <div className='h-4 bg-gray-200 rounded dark:bg-gray-200 w-[90px] mt-1'></div>
                            </div>
                        </div>
                        <div>
                            <img src={'/assets/shared/arrow-down-gray.svg'} className={'w-[20px] object-contain'} />
                        </div>
                    </div>
                )
            })
            }
        </div>
    )
}

export default MarketShippingServicesContainer;