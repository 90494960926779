import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


interface ICompanyInformations {
    userInfo: any;
    onShowHidePersonalInformation: () => void;
}

const CompanyInformationCategories = ({
    userInfo,
}: ICompanyInformations) => {
    return (
        <WhiteContainer type='rounded'>
            <div className='mt-2 mb-3'>
                <p className='text-[#F7A21E] text-lg font-bold'>Category/Industry</p>
                <div className='mt-7 mb-5 flex flex-row flex-wrap items-center'>
                    {(userInfo?.sectors && userInfo?.sectors?.length > 0) &&
                        userInfo?.sectors?.map((item: any, idx: number) => {
                            return (
                                <div
                                    className='mb-3 mr-4 py-1 px-3 rounded-full bg-gradient-to-tl from-[#FCEE21] to-[#F7931E]'
                                    key={idx}>
                                    <p className='text-[#fff] font-medium'>{item?.displayName || item?.name}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </WhiteContainer>
    )
}

export default CompanyInformationCategories;