import React, { useEffect, useState } from 'react'

import { useAppDispatch } from '../../../../app/hooks';
import { tryToDownloadThings } from '../../../../store/brokers/admin/reports/reportsServiceSlice';
import Button from '../../../../shared/button';
import { CSVLink } from 'react-csv';
import Error from '../../../../shared/error';

const ThingsCategory = () => {
  const dispatch = useAppDispatch();
  const csvLink = React.useRef() as React.MutableRefObject<any>;
  const [templateData, setTemplateData] = useState<any>();
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
  const [downloadError, setDownloadError] = useState<string>()

  const onDownloadData = async () => {
    setLoadingDownload(true)
    try {
      const response = await dispatch(tryToDownloadThings(null)).unwrap()
      setTemplateData(response || [])
      setDownloadError(undefined)
    } catch (err) {
      setDownloadError(`${err}`)
      setLoadingDownload(false)
    }
  }
  useEffect(() => {
    if (templateData && loadingDownload) {
      csvLink.current.link.click()
    }
    setLoadingDownload(false)
  }, [templateData])
  return (
    <div>
      <div className='flex flex-row justify-center my-10 flex-wrap'>
        <Button
          label={loadingDownload ? 'Loading Things Data' : 'Download Things Report'}
          dataQa={'download-things-report'}
          className='btn-main w-[300px] !min-w-[150px] !mt-0 mr-2 mb-1'
          onClickButton={onDownloadData}
          disabled={loadingDownload}
        />
      </div>
      <div className="flex justify-center">
      {downloadError && <Error text={downloadError} />}
      </div>
      <CSVLink
        ref={csvLink}
        data={templateData?.filter((item: any, idx: number, arr: any) => { return idx > 0 }) || []}
        headers={templateData?.[0] || []}
        className={'none'}
        target={'_blank'}
        filename={`Things.csv`}
      >
      </CSVLink>
    </div>
  )
}

export default ThingsCategory