import React, { useEffect, useLayoutEffect, useState } from 'react'
import CreateRMATab from './tabs/CreateRMATab'
import CreatedRMAsTab from './tabs/CreatedRMAsTab'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { useLocation, useSearchParams } from 'react-router-dom'
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload'
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate'
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice'
import ReadyToShipItemsTab from './tabs/ReadyToShipItemsTab'
import Button from '../../../shared/button'

const RMAsTabs = [
    { name: 'All Items', element: CreateRMATab },
    { name: 'Created RMAs', element: CreatedRMAsTab },
    { name: 'Ready to Ship Items', element: ReadyToShipItemsTab },

]
const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const RMAs = () => {
    const [sectors, setSectors] = useState<IAssetTemplate[]>([]);
    const [pageAccess, setPageAccess] = useState<any>()
    const [activeTab, setActiveTab] = useState<any>(RMAsTabs[0])
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: undefined
    });
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.assetsTemplates);
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch()

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined,
        });
        searchParams.set('page', '1');
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    };
    const query = useQuery();

    const onChangeActiveTab = (item: any) => {
        setActiveTab(item)
        searchParams.set('type', item?.name)
        setSearchParams(searchParams)
        searchParams.delete('search');
        onResetPaginationSettings()
        setPaginationState({ ...paginationState, filters:  '' })
    }

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Return Merchandise Authorization')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Return Merchandise Authorization')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.allAssetTemplates) {
            const rows =
                state.allAssetTemplates && Array.isArray(state.allAssetTemplates)
                    ? state.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.name, label: r?.displayName, value: r?._id, }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        } else {
            dispatch(tryToFetchAllAssetsTemplates())
        }
    }, [state.allAssetTemplates])

    useLayoutEffect(() => {
        if (query?.get('type') && query?.get('type') !== activeTab?.name) {
            const findActiveTab = RMAsTabs.find((item) => item?.name === query?.get('type'))
            setActiveTab(findActiveTab || undefined)
        }
    }, [query?.get('type')])

    return (
        <div className='w-full'>
            <p className='page-title mb-10'>Return Merchandise Authorization</p>
            <div className='flex flex-row flex-wrap gap-3  items-center my-5'>
                {RMAsTabs.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name]))?.map((tab: { name: string, element: any }, index) => {
                    return (
                        <Button
                            key={index}
                            dataQa={`${index}`}                            
                            className={`box-border ${activeTab.name === tab?.name ? 'btn-tab-rounded-less' : 'btn-tab-disable '}`}
                            onClickButton={() => onChangeActiveTab(tab)}
                            label={tab?.name}/>
                    )
                })}
            </div>
            <div className='my-2'>
                {(!accessControl || pageAccess?.actions?.[activeTab?.name]) ? React.createElement(activeTab.element, { paginationState, setPaginationState, sectors, pageAccess }) : <></>}
            </div>
        </div >
    )
}

export default RMAs