import React from 'react';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IInvoicesDetails } from '../../../../interfaces/invoices/IInvoicesDetails';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import Table from '../../../../shared/table';

const tableColumns = ['Bill Id', 'Item Id', 'Order Id', 'Item Name', 'Location', 'Invoice Number', 'Partner', 'Catalog', 'Amount', 'Commision', 'Market', 'State']

interface IAdminInvoicesTable {
    rows: IInvoicesDetails[];
    paginationData?: IPage;
    markets?: IAllMarkets[];
    partners?: IPartners[];
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const AdminInvoicesTable = ({
    rows,
    paginationData,
    markets,
    partners,
    setPaginationState,
}: IAdminInvoicesTable) => {

    const onFormatStatus = (status: string) => {
        switch (status) {
            case 'PAID':
                return { name: 'PAID', color: 'bg-[#a7eae0]' }
            case 'UNPAID':
                return { name: 'UNPAID', color: 'bg-[#ff3b30]' }
            default:
                return { name: status, color: 'bg-[#dffffa]' }
        }
    }

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
        >
            {rows?.length > 0 && rows?.map((row: IInvoicesDetails, idx: number) => {
                const status = onFormatStatus(row?.paid ? 'PAID' : 'UNPAID')
                const marketName = markets && markets?.length > 0 && markets?.find((market) => market?._id === row?.marketId)
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?._id}</td>
                        <td className='px-6 py-4'>{row?.id}</td>
                        <td className='px-6 py-4'>{row?.referenceId || ''}</td>
                        <td className='px-6 py-4'>{row?.name || ''}</td>
                        <td className='px-6 py-4'>
                            {row?.location && (Object?.keys(row?.location))?.map((loc: any, index: number) => {
                                return `${index === 0 ? `${row?.location?.[loc]}` : ` - ${row?.location?.[loc]}`}`
                            })}
                        </td>
                        <td className='px-6 py-4'>{row?.invoiceNumber || ''}</td>
                        <td className='px-6 py-4'>{row?.originalBroker?.companyName || row?.originalBroker?.username}</td>
                        <td className='px-6 py-4'>{`${row?.assetCatalog?.code[0]?.toUpperCase()}${row?.assetCatalog?.code?.slice(1).toLowerCase()}` || ''}</td>
                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(marketName && marketName?.currency?.name || '')}`]}{(row?.value?.amount || 0).toFixed(2)}</td>
                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(marketName && marketName?.currency?.name || '')}`]}{(row?.value?.commission || 0).toFixed(2)}</td>
                        <td className='px-6 py-4'>{marketName ? marketName?.label : ''}</td>
                        <td className='px-6 py-4'><div className={`${status ? status?.color : ''} flex flex-row justify-center text-center text-red px-1 min-w-[150px] text-black text-sm py-1 font-medium rounded-2xl`}>{status && status?.name}</div></td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default AdminInvoicesTable;