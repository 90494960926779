import { AxiosResponse } from 'axios';
import { CARRIERS_API_URL } from '../../../../config';
import carriersAxios from '../../../../utils/axios/carriers.axios';

const tryToFetchCarriers = (): Promise<AxiosResponse<any>> => {
    const url = `${CARRIERS_API_URL}/carriers/all`
    return carriersAxios.get(url);
};

const tryToFetchAllPartnerCarriersByMarket = (data: any): Promise<AxiosResponse> => {
    return carriersAxios.put(`${CARRIERS_API_URL}/partner-carriers/all`, data);
};

const tryToCreateReturnShippingNumber = (data: any): Promise<AxiosResponse<any>> => {
    const url = `${CARRIERS_API_URL}/shipment/v2`
    return carriersAxios.post(url, data);
};

export const CarriersService = {
    tryToFetchCarriers,
    tryToCreateReturnShippingNumber,
    tryToFetchAllPartnerCarriersByMarket
};