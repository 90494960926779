import { AxiosResponse } from 'axios';
import { INVENTORY_SERVICE_URL } from '../../../../config';
import inventoryAxios from '../../../../utils/axios/inventory.axios';

const tryToFetchAllFieldSettings = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any,
): Promise<AxiosResponse<any>> => {
    const url = filters
        ? `${INVENTORY_SERVICE_URL}/broker-fields-mappings/all?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${INVENTORY_SERVICE_URL}/broker-fields-mappings/all?page=${pageNumber}&size=${pageSize}`;
    return inventoryAxios.put<any>(url, data);
};

const tryToFetchSingleFieldSetting = (id: string): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_SERVICE_URL}/broker-fields-mappings/${id}`;
    return inventoryAxios.get<any>(url);
};

const tryToDeleteFieldSettings = (id: string): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_SERVICE_URL}/broker-fields-mappings/${id}`;
    return inventoryAxios.delete<any>(url);
};

const tryToAddFieldSettings = (data: any): Promise<AxiosResponse> => {
    return inventoryAxios.post(`${INVENTORY_SERVICE_URL}/broker-fields-mappings`, data);
};

const tryToEditFieldSettings = (id: string, payload: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_SERVICE_URL}/broker-fields-mappings/${id}`, payload);
};

const tryToFindOneFieldSetting = (brokerId: string, templateId: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_SERVICE_URL}/broker-fields-mappings/${brokerId}/template/${templateId}`);
};

export const FieldSettingsService = {
    tryToFetchAllFieldSettings,
    tryToFetchSingleFieldSetting,
    tryToAddFieldSettings,
    tryToEditFieldSettings,
    tryToDeleteFieldSettings,
    tryToFindOneFieldSetting,
};
