import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const SellersInfoContainer = () => {
    return (
        <WhiteContainer containerStyle='!rounded !p-0'>
                <div className='h-full w-full animate-pulse'>
                    <div className='h-full bg-slate-200 dark:bg-slate-200 w-[100%] !rounded'></div>
                </div>
        </WhiteContainer>
    )
}

export default SellersInfoContainer;