import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import {
    tryToAddCatalogToMarket,
    tryToFetchActiveCatalogsForMarket,
    tryToRemoveCatalogFromMarket
} from '../../../store/brokers/admin/markets/marketsSlice';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import ChangeCatalogStatusModal from './modals/ChangeCatalogStatusModal';
import CatalogItem from './details/CatalogItem';
import WhiteContainer from '../../../shared/white-container';
import Toast from '../../../shared/toast';


const MarketDetails = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [showApproveActionModal, setShowApproveActionModal] = useState<{ status: boolean, catalog: string, code: string, name: string, active: boolean }>()
    const [activeCatalogs, setActiveCatalogs] = useState<Array<any>>()
    const [showToast, setShowToast] = useState<IToast | null>()
    const [pageAccess, setPageAccess] = useState<any>()
    const allCatalogs = useAppSelector((state) => state.assetsTemplates.allAssetTemplates);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const activeCatalogsState = useAppSelector(state => state.markets.catalogsActive)

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Markets')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Market Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const getAllCatalogs = async () => {
        await dispatch(tryToFetchAllAssetsTemplates()).unwrap()
    }

    const getAllActiveCatalogsForMarket = async (id: string) => {
        await dispatch(tryToFetchActiveCatalogsForMarket(id)).unwrap()
    }

    const onSuccessfullFinishAction = (type: string, catalogId: string) => {
        if (type === 'disable') {
            const formatActiveCatalogs = activeCatalogs?.filter((item) => item?.catalog?._id !== catalogId)
            setActiveCatalogs(formatActiveCatalogs || [])
        } else {
            const findCatalog = allCatalogs?.find((catalog: { _id: string; }) => catalog?._id === catalogId)
            setActiveCatalogs([
                ...activeCatalogs || [],
                { catalog: findCatalog || {} }
            ])
        }
        if (id) getAllActiveCatalogsForMarket(id)
    }

    const onShowHideCatalogStatusModal = (catalogId?: string, catalogActive?: boolean, name?: string) => {
        setShowApproveActionModal({
            status: !showApproveActionModal?.status,
            catalog: catalogId || '',
            active: catalogActive || false,
            name: name || '',
            code: (name || '')?.toLowerCase() || '',
        })
    }
    const onApproveAction = async () => {
        if(!(!accessControl || pageAccess?.actions?.['Enable Disable Catalog'])){
            setShowToast({ type: 'error', message: 'You have no permission to make changes!' });
            onShowHideCatalogStatusModal()
            return
        }
        try {
            if (showApproveActionModal?.active) {
                const data: any = {
                    marketId: id || '',
                    payload: {
                        assetTemplateId: showApproveActionModal?.catalog || '',
                    }
                }
                await dispatch(tryToRemoveCatalogFromMarket(data))
            } else {
                const data: any = {
                    marketId: id || '',
                    payload: {
                        id: showApproveActionModal?.catalog || '',
                        code: showApproveActionModal?.code || '',
                        name: showApproveActionModal?.name || '',
                    }
                }
                await dispatch(tryToAddCatalogToMarket(data))
            }
            onSuccessfullFinishAction(showApproveActionModal?.active ? 'disable' : 'enable', showApproveActionModal?.catalog || '')
            setShowToast({ type: 'success', message: `Market successfully ${showApproveActionModal?.active ? 'disabled' : 'enabled'}` });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onShowHideCatalogStatusModal()
    }

    useEffect(() => {
        getAllCatalogs()
        if (id) getAllActiveCatalogsForMarket(id)
    }, [])

    return (
        <div>
            <WhiteContainer type={'rounded'}>
                <div>
                    <p className='font-bold text-md mb-3'>Enable catalogs you want to be active on this market.</p>
                    <div className='grid grid-cols-6 gap-3'>
                        {(allCatalogs && allCatalogs?.length > 0) && allCatalogs?.map((catalog: any, index: number) => {
                            const isCatalogActive = activeCatalogsState?.find((activeCatalog) => activeCatalog?.id === catalog?._id)
                            return (
                                <CatalogItem
                                    onClickCatalog={onShowHideCatalogStatusModal}
                                    catalogName={catalog?.displayName}
                                    catalogId={catalog?._id}
                                    catalogActive={!!isCatalogActive}
                                    key={index}
                                />
                            )
                        })}
                    </div>
                </div>
            </WhiteContainer>
            {showApproveActionModal?.status &&
                <ChangeCatalogStatusModal
                    openCatalogStatusModal={showApproveActionModal?.status || false}
                    handleCloseModal={onShowHideCatalogStatusModal}
                    onApproveAction={onApproveAction}
                    disabledType={!showApproveActionModal?.active || false}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    )
};

export default MarketDetails;
