import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ITacLists } from '../../../../interfaces/tac-list/ITacLists';
import Table from '../../../../shared/table';

const tableColumns = ['Id', 'Code', 'Make', 'Model', 'Actions']

interface ITacListsExtendedTable {
    rows: Array<any>;
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onDisableTacList: (id: string) => void;
    onEditTacList: (id: string) => void;
}

const TacListTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    rowsLoading,
    setPaginationState,
    onDisableTacList,
    onEditTacList
}: ITacListsExtendedTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: ITacLists, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?._id}</td>
                                <td className='px-6 py-4'>{row?.code}</td>
                                <td className='px-6 py-4'>{row?.descriptor?.make}</td>
                                <td className='px-6 py-4'>{row?.descriptor?.model}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa={'edit-tac-list-catalog'} onClick={() => ((!accessControl || actions?.['Edit Tac List Catalog']) && row?._id) && onEditTacList(row?._id)}>
                                            <img title={'Edit Tac List Catalog'} alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Tac List Catalog']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa={'delete-tac-list-catalog'} onClick={() => ((!accessControl || actions?.['Delete Tac List Catalog']) && row?._id) && onDisableTacList(row?._id)}>
                                            <img title={'Delete Tac List Catalog'} alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Tac List Catalog']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default TacListTable;