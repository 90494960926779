import React, { useEffect, useState } from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { GRADES_ITEMS } from '../../../../utils/constants/grades';
import Loading from '../../../../shared/Loading';
import Table from '../../../../shared/table';


interface IPricingTable {
    rows: Array<any>;
    paginationData?: IPage;
    currencies?: any;
    state: any;
    pricingLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const PricingHistoryDetailsTable = ({
    rows,
    state,
    paginationData,
    currencies,
    pricingLoading,
    setPaginationState,
}: IPricingTable) => {
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Price', 'Succeeded'])
    const [tableExtraKeys, setTableExtraKeys] = useState<Array<any>>()

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const formatTableColumnsAndKeys = () => {
        setTableLoading(true)
        const findMainKeys = rows && rows?.length > 0 && Object.keys(rows?.[0])
        const findPercentage = findMainKeys && findMainKeys?.length > 0 && findMainKeys?.find((item) => item === 'percentage')
        const findKeys = rows && rows?.length > 0 && rows?.[0]?.asset && Object?.keys(rows?.[0]?.asset)
        const formatTableColumns = []
        const formatTableExtraKeys: any = []
        findKeys && findKeys?.length > 0 && findKeys?.map((key: string) => {
            const formatKey = capitalizeFirstLetter(key)
            const formatElement = (formatKey || '').replaceAll('_', ' ')
            formatTableExtraKeys.push(key)
            formatTableColumns.push(formatElement)
            return key
        })
        if (findPercentage) {
            formatTableColumns.push('Percentage')
            formatTableColumns.push('Type')
        }
        formatTableColumns.push('Price')
        formatTableColumns.push('Succeeded')
        setTableColumns(formatTableColumns || [])
        setTableExtraKeys(formatTableExtraKeys || [])
    }

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [rows, paginationData, state])

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [])

    useEffect(() => {
        if (tableExtraKeys) {
            setTableLoading(false)
        }
    }, [tableExtraKeys])

    const findMainKeys = rows && rows?.length > 0 && Object.keys(rows?.[0])
    const findPercentage = findMainKeys && findMainKeys?.length > 0 && findMainKeys?.find((item) => item === 'percentage')
    return (
        <>
            {tableLoading ?
                <div className='flex flex-row min-w-full justify-center my-3'>
                    <Loading />
                </div>
                :
                <Table
                    columns={tableColumns}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                    containerStyle={'mt-0'}
                >
                    {pricingLoading ?
                        <>
                            <>{[...Array(6)].map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                        {tableExtraKeys?.map(element => {
                                            return (
                                                <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                            )
                                        })
                                        }
                                        <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                        <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    </tr>
                                )
                            })}
                            </>
                        </>
                        : <>
                            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                                const findCurrency = currencies && currencies?.length > 0 && currencies?.find((currency: any) => currency?.value === row?.currency)
                                return (
                                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                        {tableExtraKeys && tableExtraKeys?.length > 0 && tableExtraKeys?.map((key: string, index: number) => {
                                            const findGrade = (key || '')?.toLowerCase() === 'grade' && GRADES_ITEMS.find((grade: any) => (grade.value || '').toLowerCase() === (row?.asset?.[key] || '').toLowerCase())
                                            return (
                                                <td
                                                    key={index}
                                                    className={`px-6 py-4`}>
                                                    {(key || '')?.toLowerCase() === 'grade' ? (findGrade ? findGrade?.name : row?.asset?.[key]) : row?.asset?.[key]}
                                                </td>
                                            )
                                        })}
                                        {findPercentage &&
                                            <>
                                                <td className='px-6 py-4'>
                                                    {row?.percentage}%
                                                </td>
                                                <td className='px-6 py-4'>
                                                    {row?.type !== 'DECREASE' ?
                                                        <img title={row?.type} src={'/assets/table-actions/increase.svg'} className='object-contain w-[15px]' />
                                                        : <img title={row?.type} src={'/assets/table-actions/decrease.svg'} className='object-contain w-[15px]' />
                                                    }
                                                </td>
                                            </>
                                        }
                                        <td className='px-6 py-4 min-w-[120px]'>
                                            <div className='flex flex-row items-center'>
                                                {CURRENCY_SYMBOLS?.[`${(findCurrency?.name || '')}`]}
                                                {Number(row?.quantity || 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-6 py-4'>
                                            {row?.succeeded === 'false' || row?.succeeded === false ? 'No' : 'Yes'}
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    }
                </Table>
            }
        </>
    )
}

export default PricingHistoryDetailsTable;