import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IPostageFeeFilters {
    markets?: Array<IAllMarkets>;
    sectors?: Array<IAssetTemplate>;
    selectedMarket?: any;
    selectedSector?: any;
    onSelectMarket: (e: any) => void;
    onSelectSector: (e: any) => void;
}

const PostageFeeFilters = ({
    markets,
    sectors,
    selectedMarket,
    selectedSector,
    onSelectMarket,
    onSelectSector
}: IPostageFeeFilters) => {
    return (
        <div>
            <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Market'}
                        placeholder=' '
                        dataQa={'market-selected'}
                        selectStyle={{backgroundColor:'transparent', borderRadius:'15px'}}
                        selectedOption={selectedMarket}
                        options={markets || []}
                        onChangeSelectedOption={onSelectMarket}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Category'}
                        placeholder=' '
                        dataQa={'category-selected'}
                        selectStyle={{backgroundColor:'transparent', borderRadius:'15px'}}
                        selectedOption={selectedSector}
                        options={sectors || []}
                        onChangeSelectedOption={onSelectSector}
                    />
                </div>
            </div>
        </div>
    )
}

export default PostageFeeFilters;