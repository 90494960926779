import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AgentsService } from './agentsApi';
import { IAgents } from '../../../../interfaces/agents/IAgents';
import { IAgentsSchema } from '../../../../interfaces/agents/IAgentsSchema';
import { IAgentsListState } from '../../../../interfaces/agents/IAgentsList';
import { PaginatedAgents } from '../../../../interfaces/agents/paginatedStore.type';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';

const initialState: IAgentsListState = {
    agentsAreLoading: false,
    agentIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAgents = createAsyncThunk<PaginatedAgents, IPaginationPayload>(
    'agents/tryToFetchAgents',
    async ({ pageNumber, pageSize, filters }) => {
        const result = await AgentsService.tryToFetchAgents(pageNumber, pageSize, filters);
        return result?.data;
    },
);

export const tryToFetchSingleAgent = createAsyncThunk<IAgents, string>(
    'agents/tryToFetchSingleAgent',
    async (id: string) => {
        const result = await AgentsService.tryToFetchSingleAgent(id);
        return result?.data?.data;
    },
);

export const tryToAddAgent = createAsyncThunk<ApiResponse<IAgentsSchema>, IAgents>(
    'agents/tryToAddAgent',
    async (data: IAgents, { rejectWithValue }) => {
        try {
            const result = await AgentsService.tryToAddAgent(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEnableAgent = createAsyncThunk<ApiResponse<IAgentsSchema>, string>(
    'agents/tryToEnableAgent',
    async (id, { rejectWithValue }) => {
        try {
            const result = await AgentsService.tryToEnableAgent(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDisableAgent = createAsyncThunk<ApiResponse<IAgentsSchema>, string>(
    'agents/tryToDisableAgent',
    async (id, { rejectWithValue }) => {
        try {
            const result = await AgentsService.tryToDisableAgent(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteAgent = createAsyncThunk<ApiResponse<IAgentsSchema>, string>(
    'agents/tryToDeleteAgent',
    async (id, { rejectWithValue }) => {
        try {
            const result = await AgentsService.tryToDeleteAgent(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAssignUserRole = createAsyncThunk<ApiResponse<any>, any>(
    'agents/tryToAssignUserRole',
    async (data, { rejectWithValue }) => {
        try {
            const result = await AgentsService.tryToAssignUserRole(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToControlInventoryStatus = createAsyncThunk<any, { agentId: string, dashboard: string, value: boolean }>(
    'agents/tryToControlInventoryStatus',
    async ({ agentId, dashboard, value }) => {
        const result = await AgentsService.tryToControlInventoryStatus(agentId, dashboard, value);
        return result?.data;
    },
);

export const tryToAssignInventoryRole = createAsyncThunk<ApiResponse<any>, any>(
    'agents/tryToAssignInventoryRole',
    async (data, { rejectWithValue }) => {
        try {
            const result = await AgentsService.tryToAssignInventoryRole(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const agentsSlice = createSlice({
    name: 'agents',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAgents
            .addCase(tryToFetchAgents.pending, (state) => {
                state.agentsAreLoading = true;
            })
            .addCase(tryToFetchAgents.rejected, (state) => {
                state.agentsAreLoading = false;
            })
            .addCase(tryToFetchAgents.fulfilled, (state, action) => {
                state.agentsAreLoading = false;
                state.agents = action.payload;
            })

            // tryToFetchSingleAgent
            .addCase(tryToFetchSingleAgent.pending, (state) => {
                state.agentIsLoading = true;
            })
            .addCase(tryToFetchSingleAgent.rejected, (state) => {
                state.agentIsLoading = false;
                state.agentDetails = undefined;
            })
            .addCase(tryToFetchSingleAgent.fulfilled, (state, action) => {
                state.agentIsLoading = false;
                state.agentDetails = action.payload;
            })

            // tryToAddAgent
            .addCase(tryToAddAgent.pending, (state) => {
                state.agentIsLoading = true;
            })
            .addCase(tryToAddAgent.rejected, (state) => {
                state.agentIsLoading = false;
            })
            .addCase(tryToAddAgent.fulfilled, (state, action) => {
                state.agentIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.agents?.data) state.agents.data.elements = [...state.agents?.data.elements, data];
            })

            // tryToEnableAgent
            .addCase(tryToEnableAgent.pending, (state) => {
                state.agentIsLoading = true;
            })
            .addCase(tryToEnableAgent.rejected, (state) => {
                state.agentIsLoading = false;
            })
            .addCase(tryToEnableAgent.fulfilled, (state, action) => {
                state.agentIsLoading = false;
                state.requestStatus = 'success';
                if (state.agents?.data.elements)
                    state.agents.data.elements =
                        { ...state }.agents?.data.elements.map((agent) => {
                            if (agent?._id === action.meta?.arg) {
                                return {
                                    ...agent,
                                    enabled: true
                                }
                            } else {
                                return agent
                            }
                        }) || [];
            })

            // tryToDisableAgent
            .addCase(tryToDisableAgent.pending, (state) => {
                state.agentIsLoading = true;
            })
            .addCase(tryToDisableAgent.rejected, (state) => {
                state.agentIsLoading = false;
            })
            .addCase(tryToDisableAgent.fulfilled, (state, action) => {
                state.agentIsLoading = false;
                state.requestStatus = 'success';
                if (state.agents?.data.elements)
                    state.agents.data.elements =
                        { ...state }.agents?.data.elements.map((agent) => {
                            if (agent?._id === action.meta?.arg) {
                                return {
                                    ...agent,
                                    enabled: false
                                }
                            } else {
                                return agent
                            }
                        }) || [];
            })

            // tryToDeleteStore
            .addCase(tryToDeleteAgent.rejected, (state) => {
                state.agentIsLoading = false;
                state.requestStatus = 'error';
            })
            .addCase(tryToDeleteAgent.fulfilled, (state, action) => {
                state.agentIsLoading = false;
                state.requestStatus = 'success';
                if (state.agents?.data.elements)
                    state.agents.data.elements =
                        { ...state }.agents?.data.elements.filter((agent) => agent._id !== action.meta.arg) || [];
            })

            // tryToAssignUserRole
            .addCase(tryToAssignUserRole.pending, (state) => {
                state.agentIsLoading = true;
            })
            .addCase(tryToAssignUserRole.rejected, (state) => {
                state.agentIsLoading = false;
            })
            .addCase(tryToAssignUserRole.fulfilled, (state, action) => {
                state.agentIsLoading = false;
                state.requestStatus = 'success';
                if (state.agents?.data.elements)
                    state.agents.data.elements =
                        { ...state }.agents?.data.elements.map((agent) => {
                            if ((agent?._id === action.meta?.arg?.userId) && (action?.meta?.arg?.dashboard === 'inventory')) {
                                return {
                                    ...agent,
                                    inventoryRole: action?.meta?.arg?.role
                                }
                            } else if (agent?._id === action.meta?.arg?.userId) {
                                return {
                                    ...agent,
                                    role: action?.meta?.arg?.role
                                }
                            } else {
                                return agent
                            }
                        }) || [];
            })

            // tryToControlLogisticAndInventoryFeature
            .addCase(tryToControlInventoryStatus.pending, (state) => {
                state.agentIsLoading = true;
            })
            .addCase(tryToControlInventoryStatus.rejected, (state) => {
                state.agentIsLoading = false;
            })
            .addCase(tryToControlInventoryStatus.fulfilled, (state, action) => {
                state.agentIsLoading = false;
                state.requestStatus = 'success';
                if (state.agents?.data.elements)
                    state.agents.data.elements =
                        { ...state }.agents?.data.elements.map((agent) => {
                            if (agent?._id === action.payload.data._id) {
                                return {
                                    ...agent,
                                    inventory: action.payload.data.inventory
                                }
                            } else {
                                return agent
                            }
                        }) || [];
            })


            // tryToAssignInventoryRole
            .addCase(tryToAssignInventoryRole.pending, (state) => {
                state.agentIsLoading = true;
            })
            .addCase(tryToAssignInventoryRole.rejected, (state) => {
                state.agentIsLoading = false;
            })
            .addCase(tryToAssignInventoryRole.fulfilled, (state, action) => {
                state.agentIsLoading = false;
                state.requestStatus = 'success';
                if (state.agents?.data.elements)
                    state.agents.data.elements =
                        { ...state }.agents?.data.elements.map((agent) => {
                            if ((agent?._id === action.meta?.arg?.userId) && (action?.meta?.arg?.dashboard === 'inventory')) {
                                return {
                                    ...agent,
                                    inventoryRole: action?.meta?.arg?.role
                                }
                            } else if (agent?._id === action.meta?.arg?.userId) {
                                return {
                                    ...agent,
                                    role: action?.meta?.arg?.role
                                }
                            } else {
                                return agent
                            }
                        }) || [];
            })
    },
});

export default agentsSlice.reducer;