import React, { useEffect, useState } from 'react'
import SelectFrameStep from '../drawing-failures/SelectFrameStep';
import CanvasDrawing from '../../../../shared/drawing-canvas';
import Modal from '../../../../shared/modal';


interface IReviewDrawScratchesModal {
    openModal: boolean;
    categoryFrames: any;
    handleCloseModal: () => void;
    onSaveDrawingImage: (image: any) => void;
}

const ReviewDrawScratchesModal = ({
    openModal,
    categoryFrames,
    handleCloseModal,
    onSaveDrawingImage
}: IReviewDrawScratchesModal) => {
    const [selectedFrame, setSelectedFrame] = useState<any>()
    const [activeStep, setActiveStep] = useState<number>(1)

    useEffect(() => {
        if (categoryFrames && categoryFrames?.length === 1) {
            setSelectedFrame(categoryFrames?.[0]?.url)
            setActiveStep(2)
        } else {
            setSelectedFrame(undefined)
            setActiveStep(1)
        }
    }, [categoryFrames])

    const onCloseModal = () => {
        handleCloseModal();
        setSelectedFrame(undefined)
        setActiveStep(1)
    }

    const onClickFrame = (url: any) => {
        setSelectedFrame(url)
        setActiveStep(2)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            dataQa={'close-button'}
            contentContainerStyle='!min-w-[650px]'>
            <div className={'p-2 min-w-[600px] min-h-[500px] relative'}>
                {(activeStep === 1 && categoryFrames?.length > 1) ?
                    <SelectFrameStep
                        frames={categoryFrames}
                        onClickFrame={onClickFrame}
                    />
                    : <CanvasDrawing
                        bgImage={selectedFrame}
                        closeModal={onCloseModal}
                        onSaveDrawingImage={onSaveDrawingImage}
                    />
                }
            </div>
        </Modal>
    )
}

export default ReviewDrawScratchesModal;