import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssetLibraryService } from './assetLibraryApi';
import {
    IPaginationPayload,
} from '../../../../interfaces/shared/IPaginationPayload';
import { IAssetLibraryState } from '../../../../interfaces/assets-list/IAssetLibraryState';
import { PaginatedAssetBatch, PaginatedAssetTemplate } from '../../../../interfaces/asset-templates/paginatedStore.type';

const initialState: IAssetLibraryState = {
    assetsAreLoading: false,
    toastSuccessRequest: false,
    requestStatus: 'default',
    message: '',
	assets: []
};

export const tryToFetchAssets = createAsyncThunk<PaginatedAssetTemplate, IPaginationPayload>(
    'partners/tryToFetchAssets',
    async ({ pageNumber, pageSize, filters, sessionId }) => {
        const result = await AssetLibraryService.tryToFetchAssets(
            pageNumber,
            pageSize,
            filters,
			sessionId
        );
        return result?.data;
    },
);

export const tryToFetchBatchAssets = createAsyncThunk<PaginatedAssetBatch, IPaginationPayload>(
    'partners/tryToFetchBatchAssets',
    async ({ pageNumber, pageSize, filters, sessionId }) => {
        const result = await AssetLibraryService.tryToFetchBatchAssets(
            pageNumber,
            pageSize,
            filters,
			sessionId
        );
        return result?.data;
    },
);

export const assetLibrarySlice = createSlice({
    name: 'assetLibrary',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchPartners
            .addCase(tryToFetchAssets.pending, (state) => {
                state.assetsAreLoading = true;
            })
            .addCase(tryToFetchAssets.rejected, (state) => {
                state.assetsAreLoading = false;
            })
            .addCase(tryToFetchAssets.fulfilled, (state, action) => {
                state.assetsAreLoading = false;
                state.assets = action.payload.data;
            })

    },
});

export default assetLibrarySlice.reducer;
