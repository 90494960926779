import React from 'react';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Switch from '../../../../shared/switch';
import Table from '../../../../shared/table';

const tableColumns = ['Name', 'Icon', 'Path', 'Type', 'Business Type', 'Active', 'Edit']

interface IPagesTable {
    rows?: Array<any>;
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    onEnableDisablePage: (id: string, enabled: boolean) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onViewPage: (id: string) => void;
    onEditPage: (id: string) => void;
    onDeletePage: (id: string) => void;
}

const PagesTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    onEnableDisablePage,
    setPaginationState,
    onViewPage,
    onEditPage,
    onDeletePage,
}: IPagesTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {(rows && rows?.length > 0) && rows?.map((row: any, idx: number) => {
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?.name}</td>
                        <td className='px-6 py-4'>
                            {row?.icon
                                ? <img title={'Section Icon'} alt='Section Icon' src={row?.icon} className={'h-[20px] object-contain'} />
                                : null
                            }
                        </td>
                        <td className='px-6 py-4'>{row?.path}</td>
                        <td className='px-6 py-4'>
                            <div className='grid'>
                                {
                                    row?.types && row?.types?.map((item: any, idx: string) => {
                                        return <p key={idx}>{item}</p>
                                    }
                                    )
                                }
                            </div>
                        </td>
                        <td className='px-6 py-4'>
                            <div className='grid'>
                                {
                                    row?.businessType && row?.businessType?.map((item: any, idx: string) => {
                                        return <p key={idx}>{item}</p>
                                    })
                                }
                            </div>
                        </td>
                        <td className='px-6 py-4'>
                            <Switch
                                checked={!!row?.enabled}
                                onToggleSwitch={() => ((!accessControl || actions?.['Partner Enable Disable']) && row?._id) && onEnableDisablePage(row?._id, row?.enabled || false)}
                                dataQa={'enabled-page'}
                            />
                        </td>
                        <td className='px-6 py-4'>
                            <div className='flex flex-row items-center'>
                                <div data-qa={'view-page'} onClick={() => ((!accessControl || actions?.['See Page Details']) && row?._id) && onViewPage(row?._id)}>
                                    <img title={'View Page'} alt='view item' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Page Details']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                </div>
                                <div data-qa={'edit-page'} onClick={() => ((!accessControl || actions?.['Edit Page']) && row?._id) && onEditPage(row?._id)}>
                                    <img title={'Edit Page'} alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Page']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                </div>
                                <div data-qa={'delete-page'} onClick={() => ((!accessControl || actions?.['Delete Page']) && row?._id) && onDeletePage(row?._id)}>
                                    <img title={'Delete Page'} alt='edit item' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Page']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default PagesTable;