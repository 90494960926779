import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IRentalPricingSettingsFilters {
    sectors?: Array<IAssetTemplate>;
    markets?: Array<any>;
    selectedSector?: any;
    selectedMarket?: any;
    onSelectFilter: (id: string, type?: string) => void;
}

const RentalPricingSettingsFilters = ({
    sectors,
    markets,
    selectedSector,
    selectedMarket,
    onSelectFilter
}: IRentalPricingSettingsFilters) => {
    return (
        <div>
            <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Market'}
                        placeholder={' '}
                        dataQa={'market-to-select'}
                        options={markets || []}
                        uniqueName={'market'}
                        selectedOption={selectedMarket}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Category'}
                        placeholder={' '}
                        dataQa={'category-to-select'}
                        selectedOption={selectedSector}
                        options={sectors || []}
                        uniqueName={'sector'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
            </div>
        </div>
    )
}

export default RentalPricingSettingsFilters;