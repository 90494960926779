import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { PERCENTAGE_TYPE } from '../../../../utils/constants/pricing-percentage-type';
import { useUserProfileMutation } from '../../../../store/user/userDomApi';
import { tryToFetchAssetValues } from '../../../../store/brokers/admin/assets/assetsSlice';
import { tryToChangePricingData } from '../../../../store/brokers/partners/pricing/pricingSlice';
import { tryToFindOperatingMarkets } from '../../../../store/brokers/admin/partners/partnersSlice';
import { tryToFetchAllCurrencies } from '../../../../store/brokers/shared-endpoints/wholesale-price/wholesalePriceSlice';
import ChangePricesSuccessfully from '../details/ChangePricesSuccessfully';
import ChangePricesForm from '../details/ChangePricesForm';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IChangePricesModal {
    openModal: boolean;
    handleCloseModal: () => void;
}

const ChangePricesModal = ({
    openModal,
    handleCloseModal,
}: IChangePricesModal) => {
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<any>()
    const [activeStep, setActiveStep] = useState<number>(1)
    const [filterErrors] = useState<any>()
    const [templateFields, setTemplateFields] = useState<any>()
    const [loadingValues, setLoadingValues] = useState<boolean>(false)
    const [loadingFields, setLoadingFields] = useState<boolean>(false)
    const [generalError, setGeneralError] = useState<string>()
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>()
    const [assetTemplateSelected, setAssetTemplateSelected] = useState<any>()
    const [marketSelected, setMarketSelected] = useState<any>()
    const [markets, setMarkets] = useState<any>()
    const [percentage, setPercentage] = useState<any>()
    const [allCurrencies, setAllCurrencies] = useState<any>()
    const [percentageType, setPercentageType] = useState<any>()
    const [showMarkets, setShowMarkets] = useState<boolean>(false)
    const [selectedCurrency, setSelectedCurrency] = useState<any>()
    const [searchField, setSearchField] = useState<any>()
    const [filteredFields, setFilteredFields] = useState<any>()
    const [loadingOptions, setLoadingOptions] = useState<any>()
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)
    const [userProfileMutation] = useUserProfileMutation()
    const currencies = useAppSelector((state: any) => state?.wholesale?.wholesaleCurrencies);
    const state = useAppSelector((state) => state.partners);

    const getUserProfileInfo = async () => {
        const response = await userProfileMutation(null).unwrap()
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ ...s, label: s?.displayName, value: s?.id })) : []
        setBrokerCategories(formatSectors || [])
    }

    useEffect(() => {
        if (currencies) {
            const formatCurrencies = currencies && currencies?.length > 0 && currencies?.map((currency: any) => ({ label: currency?.descriptor?.name, value: currency?._id, name: currency?.descriptor?.name }))
            setAllCurrencies(formatCurrencies || undefined)
        }
    }, [currencies])

    useEffect(() => {
        if (state.partnerMarkets) {
            const rows =
                state.partnerMarkets && Array.isArray(state.partnerMarkets)
                    ? state.partnerMarkets.map((r) => ({ ...r, name: r?.market?.label, label: r?.market?.label, value: r?.market?._id }))
                    : [];
            setMarkets(rows || []);
        }
    }, [state?.partnerMarkets])

    useEffect(() => {
        dispatch(tryToFindOperatingMarkets(null)).unwrap()
    }, [])

    useEffect(() => {
        if (formData) {
            setLoadingValues(false)
        }
    }, [formData])

    useEffect(() => {
        getUserProfileInfo()
        dispatch(tryToFetchAllCurrencies())
    }, [])

    const onGettingFieldValues = async () => {
        if (assetTemplateSelected?.id) {
            let data: any = {
                exclude: true,
                values: []
            }
            if (formData) {
                const keysArray = formData && Object.keys(formData)
                const formatValues = keysArray && keysArray?.length > 0 && keysArray?.map((name: string) => { return { [name]: formData?.[name] } })
                data = {
                    ...data || {},
                    values: formatValues || []
                }
            }
            if (searchField) {
                data = {
                    ...data || {},
                    search: {
                        ...searchField || {}
                    }
                }
            }
            const response = await dispatch(tryToFetchAssetValues({ id: assetTemplateSelected?.id || '', data })).unwrap()
            setTemplateFields(response || [])
            setLoadingFields(false)
            setLoadingOptions(undefined)
        }
    }

    useEffect(() => {
        onGettingFieldValues()
    }, [formData, searchField])

    const onChangeSearchAssetFields = (e: any, type?: string) => {
        if (type && e) {
            if (templateFields && templateFields?.[type]) {
                const formatFilteredFields = templateFields?.[type] && templateFields?.[type]?.length > 0 && templateFields?.[type]?.filter((next: any) => (next).toLowerCase().includes((e || '')?.toLowerCase()))
                if (formatFilteredFields && formatFilteredFields?.length > 0) {
                    setFilteredFields({ [type]: formatFilteredFields })
                }
                else {
                    setFilteredFields(undefined)
                    setLoadingOptions({ [type]: true })
                    setSearchField({
                        [type]: e
                    })
                    if (formData?.[type]) {
                        const formatFormData = Object.assign(formData)
                        delete formatFormData[type]
                        setFormData(
                            {
                                ...formatFormData || {}
                            }
                        )
                    }
                }
            }
        } else {
            setFilteredFields(undefined)
        }
    }

    const onMenuCloseAfterSearch = (type?: string) => {
        if (type && searchField?.[type]) {
            setSearchField(undefined)
        }
    }

    useEffect(() => {
        if (assetTemplateSelected?.id) {
            onGettingFieldValues()
        }
    }, [assetTemplateSelected])

    const onCloseModal = () => {
        handleCloseModal();
        setActiveStep(1)
        setFormData(undefined)
    }

    const onSubmit = async () => {
        if (!assetTemplateSelected || !percentage || !percentageType || (showMarkets && !marketSelected) || (!showMarkets && !selectedCurrency)) {
            setGeneralError('Please make sure you fill in all the fields to change pricing.')
            return;
        }
        setLoadingUpdate(true)
        try {
            let formatData: any = {

                type: percentageType?.value || '',
                percentage: parseFloat(percentage || 0),
                assetTemplateId: assetTemplateSelected?.id || '',
                asset: {
                    ...(formData || {})
                }
            }
            if (selectedCurrency && !showMarkets) {
                formatData = {
                    ...formatData || {},
                    currency: selectedCurrency?.value || ''
                }
            }
            if (showMarkets && marketSelected) {
                formatData = {
                    ...formatData || {},
                    marketId: marketSelected?.value || ''
                }
            }
            await dispatch(tryToChangePricingData(formatData)).unwrap()
            setActiveStep(2)
            setGeneralError(undefined)
        } catch (err: any) {
            setGeneralError(`${err || 'Something went wrong!'}`)
        }
        setLoadingUpdate(false)
    }

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        if (type) {
            setLoadingFields(true)
            setFormData({
                ...(formData || {}),
                [type]: value?.value || undefined,
            });
            if (searchField?.[type]) {
                setSearchField(undefined)
            }
        }
    };

    const onChangeFilterInput = (value: string | null | undefined, type: string) => {
        const findField = templateFields && templateFields?.length > 0 && templateFields?.find((field: any) => field?.name === type);
        setFormData({
            ...(formData || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
    };

    const onChangePriceValidate = () => {
        let errorData: any
        const objectKeys = templateFields && Object.keys(templateFields)
        if (objectKeys && objectKeys?.length > 0) {
            objectKeys?.map((item: any) => {
                if (formData?.[item]) {
                    errorData = false
                } else if (errorData !== false) {
                    errorData = true
                }
                return item
            })
        }
        if (errorData) {
            setGeneralError('Please make sure you fill in all the fields to change pricing.')
            return
        }
        onSubmit()
    }

    const onChangeCategorySelected = (value: any, name?: string) => {
        setAssetTemplateSelected(value)
        setFormData(undefined)
    }

    const onClickClearFields = () => {
        setFormData(undefined)
    }

    const onChangeMarketSelected = (value: any, name?: string) => {
        setMarketSelected(value)
    }

    const onChangeTypeSelected = (value: any, name?: string) => {
        setPercentageType(value)
    }

    const onChangePercentage = (value: string | null | undefined, type: string) => {
        setPercentage(value ? (/^\d*\.?\d*$/).test(value) ? value : percentage : undefined)
    }

    const onChangeSelectedCurrency = (e: any) => {
        if (e?.value === 'market') {
            setShowMarkets(true)
        } else {
            setShowMarkets(false)
            setMarketSelected(undefined)
        }
        setSelectedCurrency(e)
    }

    const onClickBasedOnMarket = () => {
        setShowMarkets(!showMarkets)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle={'!min-w-[470px] min-h-[350px]'}
        >
            <div className={'p-4 !min-w-[100%] !min-h-[inherit] flex flex-col justify-between'}>
                {activeStep === 1
                    ? <>
                        <div>
                            <p className='mb-4 font-semibold text-sm'>Price Change Form</p>
                            <p className='font-bold text-md mb-4'>Use this form to change prices of one or multiple items.</p>
                            {generalError && <Error text={generalError} />}
                        </div>
                        <div className='mt-5'>
                            <SelectCheckbox
                                name={'Category'}
                                dataQa={'category'}
                                placeholder={assetTemplateSelected?.label || 'Select'}
                                options={brokerCategories || []}
                                containerStyle={'max-w-[90%]'}
                                selectStyle={{ backgroundColor: 'transparent', borderRadius: '4px' }}
                                onChangeSelectedOption={onChangeCategorySelected}
                                uniqueName={'category'}
                            />
                            <div>
                                <SelectCheckbox
                                    name={'Currency'}
                                    dataQa={'currency'}
                                    placeholder={selectedCurrency?.label || 'Select'}
                                    options={allCurrencies || []}
                                    disabled={showMarkets}
                                    containerStyle={'mb-0 max-w-[90%]'}
                                    selectStyle={{ backgroundColor: 'transparent', marginBottom: 0, borderRadius: '4px' }}
                                    onChangeSelectedOption={onChangeSelectedCurrency}
                                    uniqueName={'currency'}
                                />
                                <div className='flex flex-row justify-start items-center mb-3 min-w-[100%]'>
                                    <div className='flex items-center mr-2'>
                                        <input
                                            onChange={onClickBasedOnMarket}
                                            checked={showMarkets}
                                            id='checkbox-all'
                                            type='checkbox'
                                            data-qa={'checkbox'}
                                            className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                        />
                                        <label htmlFor='checkbox-all' className='sr-only'>
                                            checkbox
                                        </label>
                                    </div>
                                    <p className='text-[#a4a4a4]'>
                                        Import based on market.
                                    </p>
                                </div>
                            </div>
                            {showMarkets &&
                                <SelectCheckbox
                                    name={'Market'}
                                    dataQa={'market'}
                                    placeholder={marketSelected?.label || 'Select'}
                                    options={markets || []}
                                    containerStyle={'max-w-[90%]'}
                                    selectStyle={{ backgroundColor: 'transparent', borderRadius: '4px' }}
                                    onChangeSelectedOption={onChangeMarketSelected}
                                    uniqueName={'markets'}
                                />
                            }
                        </div>
                        <div className='mb-5'>
                            <SelectCheckbox
                                name={'Percentage Type'}
                                dataQa={'percentage-type-selected'}
                                placeholder={percentageType?.label || 'Select'}
                                options={PERCENTAGE_TYPE || []}
                                containerStyle={'max-w-[90%]'}
                                selectStyle={{ backgroundColor: 'transparent', borderRadius: '4px' }}
                                onChangeSelectedOption={onChangeTypeSelected}
                                uniqueName={'type'}
                            />
                            <Input
                                label='Percentage'
                                dataQa={'percentage'}
                                showValue={true}
                                inputStyle={'max-w-[90%]'}
                                inputValue={percentage}
                                onChangeInput={onChangePercentage}
                            />
                        </div>
                        <ChangePricesForm
                            items={templateFields || []}
                            loadingValues={loadingValues}
                            loadingFields={loadingFields}
                            loadingOptions={loadingOptions}
                            filteredFields={filteredFields}
                            filterValues={formData}
                            templateId={assetTemplateSelected?.id || ''}
                            assetTemplateSelected={assetTemplateSelected}
                            filterErrors={filterErrors}
                            onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                            onChangeSelectedValue={onChangeSelectedFilterValue}
                            onChangeSearchAssetFields={onChangeSearchAssetFields}
                            onChangeInput={onChangeFilterInput}
                        />
                        <div className='min-w-full flex flex-row justify-center my-3 '>
                            <Button
                                className={'!py-0 btn-main !shadow-none mr-4 min-w-[150px]'}
                                label={'Clear fields'}
                                dataQa={'clear-fields'}
                                onClickButton={onClickClearFields}
                            />
                            <Button
                                label={'Update Prices'}
                                dataQa={'update-prices'}
                                className={`${loadingUpdate ? 'btn-noactive-filters' : 'btn-filters'} !rounded flex flex-row mr-4 items-center justify-center min-w-[150px] !shadow-none`}
                                onClickButton={() => !loadingUpdate && onChangePriceValidate()}
                            />
                        </div>
                    </>
                    : <>
                        <ChangePricesSuccessfully
                            handleCloseModal={onCloseModal} />
                    </>
                }
            </div>
        </Modal >
    )
}

export default ChangePricesModal;