import React, { useEffect } from 'react';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';


interface IPrintLabel {
    itemId: string;
    index: number;
}

const PrintLabel = ({
    itemId,
}: IPrintLabel) => {
    const componentRef = React.useRef([]) as React.MutableRefObject<any>;

    useEffect(() => {
        if (componentRef.current) {
            componentRef.current.click();
        }
    }, [itemId]);

    return (
        <>
            <div style={{ display: 'none' }}>
                <div ref={componentRef}>
                    <div className='flex flex-col items-center my-10'>
                        <Barcode value={itemId || 'null'} width={1.4} height={60} fontSize={14} displayValue={true} textAlign={'center'} />
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-2 gap-3 my-2'>
                <p className=''>Item Barcode</p>
                <ReactToPrint
                    trigger={() =>
                        <button className='btn-main'>
                            Print Barcode
                        </button>
                    }
                    content={() => componentRef.current}
                />
            </div>
            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
        </>
    )
}

export default PrintLabel;