import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddExtendedAsset,
    tryToEditExtendedAsset,
    tryToFetchSingleAssetExtended,
    tryToSearchNotAddedExtendedAssets
} from '../../../../store/brokers/admin/assets/assetsSlice';
import Loading from '../../../../shared/Loading';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IAssetExtendedModal {
    openCreateAssetModal: boolean;
    handleCloseAssetModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
    assetItemId?: string | null;
    name?: string;
}

const AssetExtendedModal = ({
    openCreateAssetModal,
    handleCloseAssetModal,
    onSaveChanges,
    assetItemId,
    name
}: IAssetExtendedModal) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.assets);
    const assetLoading = useAppSelector((state) => state.assets?.assetIsLoading);
    const [colors, setColors] = useState<any>([{ name: undefined, storages: undefined }])
    const [templateFields, setTemplateFields] = useState<any>()
    const [searchInDB, setSearchInDB] = useState<any>()
    const [assetSelected, setAssetSelected] = useState<any>()
    const [generalError, setGeneralError] = useState<string>()
    const [loadingOptions, setLoadingOptions] = useState<boolean>(false)
    const [filteredFields, setFilteredFields] = useState<any>()
    const [searchField, setSearchField] = useState<any>()
    const [newValue, setNewValue] = useState<any>()

    const onGettingFieldValues = async () => {
        const response = await dispatch(tryToSearchNotAddedExtendedAssets(searchField || undefined)).unwrap()
        setTemplateFields(response?.data || [])
        setLoadingOptions(false)
    }

    useEffect(() => {
        if (name) {
            setAssetSelected({ label: name, value: name })
            setColors([{ name: undefined, storages: undefined }])
            setSearchField(undefined)
            setSearchInDB(undefined)
            setFilteredFields(undefined)
            setNewValue(undefined)
        } else {
            onGettingFieldValues()
        }
    }, [name, openCreateAssetModal])

    useEffect(() => {
        if (state.extendedAsset && assetItemId) {
            setAssetSelected(assetItemId ? state?.extendedAsset?.name ? { label: state?.extendedAsset?.name, value: state?.extendedAsset?.name } : undefined : undefined)
            setColors(state?.extendedAsset?.colors || {})
        }
    }, [state.extendedAsset]);

    useEffect(() => {
        if (assetItemId) {
            dispatch(tryToFetchSingleAssetExtended(assetItemId));
            onGettingFieldValues()
        }
    }, [assetItemId, openCreateAssetModal])

    const onCloseModal = () => {
        setGeneralError(undefined)
        setAssetSelected(undefined)
        setSearchField(undefined)
        setSearchInDB(undefined)
        setLoadingOptions(false)
        setTemplateFields(undefined)
        setFilteredFields(undefined)
        handleCloseAssetModal()
        setNewValue(undefined)
        setColors([{ name: undefined, storages: undefined }])
    }

    const onSubmit = async () => {
        if (!assetSelected) {
            setGeneralError('Please select Name.')
            return;
        }
        if ((!colors || colors?.length <= 0)) {
            setGeneralError('Please write at least one storage.')
            return;
        }
        const formatData: any = {
            name: assetSelected?.value,
            colors: colors
        }
        try {
            if (assetItemId) {
                const payload = {
                    id: assetItemId || '',
                    data: formatData
                }
                await dispatch(tryToEditExtendedAsset(payload)).unwrap();
            }
            else {
                await dispatch(tryToAddExtendedAsset(formatData)).unwrap();
            }
            onSaveChanges('success', 'Extended asset successfully added')
            onCloseModal()
        } catch (error) {
            setGeneralError(`${error}`)
        }
    }

    const onChangeNewValue = (value: string | null | undefined, type: string, index?: any) => {
        const filterOtherValues = newValue && newValue?.length > 0 && newValue?.filter((item: any) => item?.id !== index)
        setNewValue([
            ...filterOtherValues || [],
            /* eslint-disable-next-line */
            { value: value?.replace(/[^a-zA-Z0-9]/g, ''), id: index }
        ])
    }

    const onAddNewValue = (index: number) => {
        const findNewValue = newValue && newValue?.length > 0 && newValue?.find((item: any) => item?.id === index)
        if (findNewValue && findNewValue?.value !== '') {
            const formatColors = colors && colors?.length > 0 && colors?.map((color: any, idx: number) => {
                if (idx === index) {
                    return {
                        ...(color || {}),
                        storages: [
                            ...color.storages || [],
                            findNewValue?.value
                        ]
                    }
                } else {
                    return color
                }
            })
            setColors(formatColors || [])
            const filterOtherValues = newValue && newValue?.length > 0 && newValue?.filter((item: any) => item?.id !== index)
            setNewValue(filterOtherValues || [])
        }
    }

    const onDeleteStorageValue = (colorId: number, storageId: number) => {
        const formatColors = colors && colors?.length > 0 && colors?.map((color: any, idx: number) => {
            if (idx === colorId) {
                const formatStorages = color?.storages && color?.storages?.length > 0 && color?.storages?.filter((storage: any, index: number) => index !== storageId)
                return {
                    ...(color || {}),
                    storages: formatStorages || []
                }
            } else {
                return color
            }
        })
        setColors(formatColors)
    }

    const onChangeInputColor = (value: any, type?: string, index?: any) => {
        const formatColors = colors && colors?.length > 0 && colors?.map((color: any, idx: number) => {
            if (idx === index) {
                return {
                    ...(color || {}),
                    name: value
                }
            } else {
                return color
            }
        })
        setColors(formatColors)
    }

    const onClickAddNewColor = () => {
        setColors([
            ...colors || [],
            { name: undefined, storages: undefined }
        ])
    }

    const onChangeSearchAssetFields = (e: any, type?: string) => {
        if (e) {
            if (templateFields) {
                const formatFilteredFields = (templateFields && templateFields?.length > 0) && templateFields?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
                if (formatFilteredFields && formatFilteredFields?.length > 0) {
                    setSearchInDB(e)
                    setLoadingOptions(false)
                    setFilteredFields(formatFilteredFields)
                }
                else {
                    setFilteredFields(undefined)
                    setLoadingOptions(true)
                    setSearchField(e)
                }
            } else {
                setSearchField(e)
            }
        } else {
            setFilteredFields(undefined)
        }
    }

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        setAssetSelected(value)
        if (searchField) {
            setSearchField(undefined)
        }
    };

    const onMenuCloseAfterSearch = () => {
        setSearchField(undefined)
    }

    const onClickSearchInDb = () => {
        if (searchInDB) {
            setFilteredFields(undefined)
            setLoadingOptions(true)
            setSearchField(searchInDB)
        }
    }

    useEffect(() => {
        onGettingFieldValues()
    }, [searchField])

    return (
        <Modal
            open={openCreateAssetModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>Extended Asset</p>
                {generalError && <Error text={generalError} />}
                {((assetItemId && !assetLoading) || !assetItemId) ?
                    <div className='flex flex-col my-4'>
                        {assetItemId ?
                            <SelectCheckbox
                                name={'Name'}
                                placeholder=' '
                                disabled={true}
                                selectedOption={assetSelected}
                                options={templateFields}
                                onChangeSelectedOption={onChangeSelectedFilterValue}
                            />
                            : <AsyncSelectCheckbox
                                isLoading={false}
                                name={'Name'}
                                placeholder={assetSelected?.value || ' '}
                                options={(filteredFields && filteredFields?.length > 0) ? filteredFields?.map((item: any) => ({ label: item, value: item })) : templateFields && templateFields?.length > 0 && templateFields?.map((item: any) => ({ label: item, value: item }))}
                                dynamicOptions={true}
                                showSearchDB={searchInDB || false}
                                loadingOptions={loadingOptions}
                                onChangeValue={onChangeSearchAssetFields}
                                onMenuClose={onMenuCloseAfterSearch}
                                onClickSearchInDb={onClickSearchInDb}
                                selectedOption={assetSelected}
                                selectStyle={{ backgroundColor: 'white', borderRadius: '4px' }}
                                onChangeSelectedOption={onChangeSelectedFilterValue}
                            />
                        }
                        <div>
                            {colors && colors?.length > 0 && colors?.map((item: any, index: number) => {
                                const findValue = newValue && newValue?.length > 0 && newValue?.find((value: any) => value?.id === index)
                                return (
                                    <div key={index} className='my-6'>
                                        <Input
                                            placeholder='Color'
                                            dataQa={'extended-assets-color'}
                                            label={assetItemId ? 'Color' : ''}
                                            onChangeInput={onChangeInputColor}
                                            inputUniqueName={'color'}
                                            showValue={true}
                                            index={index}
                                            inputValue={item?.name}
                                        />
                                        <div className='flex flex-row items-center'>
                                            <Input
                                                placeholder='Storage Value'
                                                dataQa={'extended-assets-storage'}
                                                containerStyle={'mt-0 flex flex-row h-full items-center'}
                                                showValue={true}
                                                inputValue={findValue?.value || null}
                                                inputUniqueName={'newValue'}
                                                onChangeInput={onChangeNewValue}
                                                index={index}
                                            />
                                            <Button
                                                label='Add Storage'
                                                className='btn-filters ml-2 mt-0'
                                                onClickButton={() => onAddNewValue(index)}
                                                dataQa={'extended-assets-add-storage-btn'}
                                            />
                                        </div>
                                        <div className='flex flex-row items-center flex-wrap my-3'>
                                            {item?.storages && item?.storages?.length > 0 && item?.storages?.map((storage: any, idx: number) => {
                                                return (
                                                    <div key={idx} className={'flex flex-row items-center justify-between border border-solid border-[#F7A21E] rounded-md px-1 mr-1 mb-1'}>
                                                        <p className='text-slate-400'>{storage}</p>
                                                        <div data-qa={'close'} className='pl-1 flex flex-row items-center h-full' onClick={() => onDeleteStorageValue(index, idx)}>
                                                            <img alt='close' src={'/assets/shared/close-grey.svg'} className={'w-[20px] mt-1 object-contain cursor-pointer'} />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <Button
                                label='Add New Color'
                                className={'btn-main'}
                                onClickButton={onClickAddNewColor}
                                dataQa={'extended-assets-add-new-color'}
                            />
                        </div>
                        <div className='flex flex-row justify-end mt-4'>
                            <Button
                                label='Save'
                                onClickButton={onSubmit}
                                className={'btn-primary'}
                                dataQa={'extended-assets-save'}
                            />
                        </div>
                    </div>
                    : <div className='my-3 flex flex-row justify-center items-center'>
                        <Loading />
                    </div>
                }
            </div>
        </Modal>
    )
}
export default AssetExtendedModal;