import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { tryToFetchAllPartners } from '../../../store/brokers/admin/partners/partnersSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToFetchAggregatedPricingHistory } from '../../../store/brokers/shared-endpoints/pricing-history/pricingHistorySlice';
import { PRICING_HISTORY_TYPES } from '../../../utils/constants/pricing-history-types';
import PricingHistoryTable from './tables/PricingHistoryTable';
import PricingClassification from './details/PricingClassification';
import SearchWithButton from '../../../shared/search-with-button';
import SelectCheckbox from '../../../shared/select-checkbox';


const PricingHistoryAdmin = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<Array<any>>([]);
    const [search, setSearch] = useState<string>();
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [partners, setPartners] = useState<IPartners[]>()
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [selectedMarket, setSelectedMarket] = useState<string>()
    const [selectedPartner, setSelectedPartner] = useState<string>()
    const [selectedSector, setSelectedSector] = useState<string>()
    const [typeSelected, setTypeSelected] = useState<any>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state?.pricingHistory?.pricingsHistory?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state) => state.pricingHistory);
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Pricing History')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

    useEffect(() => {
        if (state.pricingsHistory) {
            const rowsData =
                state.pricingsHistory?.data?.elements && Array.isArray(state.pricingsHistory?.data.elements)
                    ? state.pricingsHistory?.data.elements.map((r) => ({ ...r }))
                    : [];
            if (rowsData !== rows) {
                setRows(rowsData);
            }
        }
    }, [state.pricingsHistory]);

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.displayName, label: r?.displayName, value: r?._id, }))
                    : [];
            const formatAssets = rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []
            setSectors(formatAssets);
            setSelectedSector(formatAssets?.[0]?.name)
            onResetPaginationSettings()
        }
    }, [stateAssets.allAssetTemplates]);

    const onGetUserSectorsAndMarkets = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, value: partner?.username, label: partner?.companyName, name: partner?.companyName }))
        setPartners(formatPartners || [])
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, displayName: market?.name }))
        setMarkets(formatMarkets || [])
    }

    const onGettingPricingData = async () => {
        if (selectedSector) {
            const findCategory: any = sectors && sectors?.length > 0 && sectors?.find((category: any) => category?.name === selectedSector)
            let data: any = {
                assetTemplateId: findCategory?._id || '',
            }
            if (selectedMarket) {
                const findMarket: any = markets && markets?.length > 0 && markets?.find((market: IAllMarkets) => market?.name === selectedMarket)
                data = {
                    ...data || {},
                    marketId: findMarket?._id
                }
            }
            if (selectedPartner) {
                const findPartner: any = partners && partners?.length > 0 && partners?.find((partner: any) => partner?.value === selectedPartner)
                data.partnerId = findPartner?._id
            }
            if (search) {
                data = {
                    ...data,
                    search: search
                }
            }
            if (typeSelected) {
                const findType = PRICING_HISTORY_TYPES?.find((item) => item?.label === typeSelected)
                data = {
                    ...data,
                    type: findType?.value
                }
            }
            const fetchData = {
                ...paginationState || {},
                data
            }
            dispatch(tryToFetchAggregatedPricingHistory(fetchData)).unwrap()
        }
    }

    useEffect(() => {
        onGetUserSectorsAndMarkets()
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    useEffect(() => {
        onGettingPricingData()
    }, [paginationState])

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationSettings()
    }

    const onClearAllFilters = () => {
        setTypeSelected(undefined)
        setSelectedMarket(undefined)
        setSelectedPartner(undefined)
        onResetPaginationSettings()
    }

    const onSeePricingHistoryDetails = (id: string) => {
        navigate(`/pricing-history-admin/${id}`)
    }

    const onClickImportType = (value: any, type?: string) => {
        setTypeSelected(value?.label)
        onResetPaginationSettings()
    }

    const onSelectFilterOption = (value: any, name?: string) => {
        if (name) {
            switch (name) {
                case 'market':
                    setSelectedMarket(value?.name)
                    break;
                case 'partner':
                    setSelectedPartner(value?.value)
                    break;
                case 'category':
                    setSelectedSector(value?.name)
                    break;
            }
            onResetPaginationSettings()
        }
    }

    return (
        <div>
            <div className='mb-5'>
                <p className='page-title'>Pricing History</p>
            </div>
            <div className='mt-10 mb-5'>
                <PricingClassification
                    sectors={sectors}
                    markets={markets}
                    partners={partners}
                    selectedMarket={selectedMarket}
                    selectedPartner={selectedPartner}
                    selectedSector={selectedSector}
                    onSelectFilterOption={onSelectFilterOption}
                />
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 mb-8'>
                <SelectCheckbox
                    name='Import Type'
                    dataQa={'import-type'}
                    placeholder={typeSelected || ''}
                    options={PRICING_HISTORY_TYPES || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200, }}
                    onChangeSelectedOption={onClickImportType}
                    uniqueName={'type'}
                />
            </div>
            <div className='mb-4 mt-2'>
                <div className='bg-white pl-4 pt-2'>
                    <SearchWithButton
                        buttonLabel='Clear All Filters'
                        placeholder='Filter table'
                        onChangeSearchValue={onChangeSearchValue}
                        search={search}
                        onClickButton={onClearAllFilters}
                        buttonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end bg-transparent mt-1'}
                        mainContainerStyle={'!grid-cols-10'}
                    />
                </div>
                <div>
                    <PricingHistoryTable
                        rows={rows}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        partners={partners}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onSeeDetails={onSeePricingHistoryDetails}
                    />
                </div>
            </div>
        </div>
    )
}

export default PricingHistoryAdmin;