import React, { useEffect, useRef } from 'react';


interface IDropDownButton {
    items?: Array<any>;
    onClickItem: (item: string, uniqueName?: string) => void;
    name: string,
    dropdownUniqueName?: string;
    buttonClassName?: string;
    arrowColor?: string;
    containerClassName?: string;
    itemClassName?: string;
    buttonIcon?: string;
    btnIconClassName?: string;
    dataQa?: string;

}

const DropDownButton = ({
    items,
    onClickItem,
    name,
    dropdownUniqueName,
    buttonClassName,
    arrowColor,
    containerClassName,
    itemClassName,
    buttonIcon,
    btnIconClassName,
    dataQa


}: IDropDownButton) => {
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const dropdownMenu = useRef<any>(null);

    const closeDropdownPopover = (e: any) => {
        if (dropdownMenu?.current && !dropdownMenu?.current?.contains(e.target)) {
            setDropdownPopoverShow(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', closeDropdownPopover, false);
        return () => {
            document.removeEventListener('click', closeDropdownPopover, false);
        }
    }, [])

    const onClickDropDownItem = (item: string, uniqueName?: string) => {
        onClickItem(item, uniqueName)
        setDropdownPopoverShow(false)
    }

    return (
        <div className='relative inline-block text-left' ref={dropdownMenu}>
            <div>
                <button
                    data-qa={dataQa || ''}
                    onClick={(e) => setDropdownPopoverShow(!dropdownPopoverShow)}
                    type='button'
                    className={`rounded-[14px] py-1 px-3 min-w-full flex flex-row !bg-white text-primary-default items-center !justify-between !px-4 ${buttonClassName || ''}`} id='menu-button' aria-expanded='true' aria-haspopup='true'>
                    <div className='flex flex-row items-center'>
                        {buttonIcon && <img src={buttonIcon} className={`mr-2 w-[15px] ${btnIconClassName}`} />}
                        <p>{name}</p>
                    </div>
                    <svg className='-mr-1 ml-2 h-5 w-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill={arrowColor || '#F7931E'} aria-hidden='true'>
                        <path fillRule='evenodd' d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z' clipRule='evenodd' />
                    </svg>
                </button>
            </div>
            <div
                className={`${dropdownPopoverShow ? '' : 'hidden'} max-h-[150px] overflow-auto absolute border border-solid border-[#F7A21E] left-0 z-10 mt-2 w-56 origin-top-right divide-y divide-[#F7A21E] rounded-md bg-white focus:outline-none ${containerClassName || ''}`}
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='menu-button'
                tabIndex={-1}>
                {items && items?.length > 0 && items?.map((item: any, idx: number) => {
                    return (
                        <div
                            onClick={() => onClickDropDownItem(item?.name, dropdownUniqueName)}
                            key={idx}
                            id={`test-qa-id-${item?.name}`.toLocaleLowerCase()}
                            className='py-1 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'
                            role='none'>
                            {item?.icon && <img src={item?.icon} className={'mr-2 py-2 w-[15px]'} />}
                            <p className={`text-[#F7A21E] font-medium block  py-2 text-md ${itemClassName || ''}`} role='menuitem' id='menu-item-0'>{item?.name}</p>
                        </div>
                    )
                })}
            </div>
        </div>

    );
};

export default DropDownButton;