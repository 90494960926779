import React, { useState } from 'react';
import Toast from '../../../../shared/toast';
import ChangePassword from '../modals/ChangePassword';
import CompanyInformationCategories from './CompanyInformationCategories';
import CompanyInformations from './CompanyInformations';
import EditPersonalInformation from './EditPersonalInformation';
import PersonalInformationDetails from './PersonalInformationDetails';


interface IPersonalInformation {
    userInfo: any;
    accessControl?: any;
    pageAccess?: any;
    getUserProfileInfo: () => void;
}

const PersonalInformation = ({
    userInfo,
    accessControl,
    pageAccess,
    getUserProfileInfo
}: IPersonalInformation) => {
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false)
    const [toatsStatus, setToastStatus] = useState<{ type: string, msg: string }>()
    const [showEditInfo, setShowEditInfo] = useState<boolean>(false)

    const onShowHideChangePasswordModal = () => {
        setShowChangePassword(!showChangePassword)
    }

    const onShowHidePersonalInformation = () => {
        setShowEditInfo(!showEditInfo)
    }

    const passwordChangedSuccessfully = () => {
        setToastStatus({ type: 'success', msg: 'Password changed successfully!' })
        setShowChangePassword(false)
    }

    const profileUpdatedSuccessfully = () => {
        getUserProfileInfo()
        setShowEditInfo(false)
        setToastStatus({ type: 'success', msg: 'Profile data updated successfully!' })
    }

    return (
        <div>
            {showEditInfo ?
                <EditPersonalInformation
                    userInfo={userInfo}
                    profileUpdatedSuccessfully={profileUpdatedSuccessfully}
                    onShowHidePersonalInformation={onShowHidePersonalInformation}
                />
                : <>
                    <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-4'>
                        <CompanyInformations
                            userInfo={userInfo}
                            accessChangePassword={!accessControl || pageAccess?.actions?.['Change Password']}
                            accessEditInformation={!accessControl || pageAccess?.actions?.['Edit Company Information']}
                            onShowHideChangePasswordModal={onShowHideChangePasswordModal}
                            onShowHidePersonalInformation={onShowHidePersonalInformation}
                        />
                        <PersonalInformationDetails
                            userInfo={userInfo}
                            accessEditInformation={!accessControl || pageAccess?.actions?.['Edit Company Information']}
                            onShowHidePersonalInformation={onShowHidePersonalInformation}
                        />
                    </div>
                    <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-4'>
                        <div className='sm:col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2'>
                            <CompanyInformationCategories
                                userInfo={userInfo}
                                onShowHidePersonalInformation={onShowHidePersonalInformation}
                            />
                        </div>
                    </div>
                </>
            }
            <ChangePassword
                openChangePasswordModal={showChangePassword}
                handleCloseModal={onShowHideChangePasswordModal}
                passwordChangedSuccessfully={passwordChangedSuccessfully}
            />
            {toatsStatus &&
                <Toast
                    type={toatsStatus?.type}
                    text={toatsStatus?.msg}
                    onHandleCloseToast={() => setToastStatus(undefined)}
                />
            }
        </div>
    )
}

export default PersonalInformation;