import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IAllBrokersComparisonSites } from '../../../../interfaces/brokers-comparison-sites/IAllBrokersComparisonSites';
import { IBrokersComparisonSchema } from '../../../../interfaces/brokers-comparison-sites/IBrokersComparisonSchema';
import { IBrokersComparisonSitesListState } from '../../../../interfaces/brokers-comparison-sites/IBrokersComparisonSitesList';
import { PaginatedBrokersComparison } from '../../../../interfaces/brokers-comparison-sites/paginatedStore.type';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { BrokersComparisonService } from './brokersComparisonSitesApi';

const initialState: IBrokersComparisonSitesListState = {
    brokersComparisonAreLoading: false,
    toastSuccessRequest: false,
    brokerComparisonIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchBrokersComparisonSites = createAsyncThunk<
    PaginatedBrokersComparison,
    IPaginationPayload
>('brokersComparison/tryToFetchBrokersComparisonSites', async ({ pageNumber, pageSize, filters, data }) => {
    const result = await BrokersComparisonService.tryToFetchBrokersComparisonSites(
        pageNumber,
        pageSize,
        filters,
        data,
    );
    return result?.data;
});

export const tryToFetchAllBrokerComparisonSite = createAsyncThunk<IAllBrokersComparisonSites, undefined>(
    'brokersComparison/tryToFetchAllBrokerComparisonSite',
    async () => {
        const result = await BrokersComparisonService.tryToFetchAllBrokerComparisonSite();
        return result?.data?.data;
    },
);


export const tryToFetchSingleBrokerComparison = createAsyncThunk<IAllBrokersComparisonSites, string>(
    'brokersComparison/tryToFetchSingleBrokerComparison',
    async (id: string) => {
        const result = await BrokersComparisonService.tryToFetchSingleBrokerComparison(id);
        return result?.data?.data;
    },
);

export const tryToAddBrokerComparison = createAsyncThunk<
    ApiResponse<IBrokersComparisonSchema>,
    any
>('brokersComparison/tryToAddBrokerComparison', async (data: any, { rejectWithValue }) => {
    try {
        const result = await BrokersComparisonService.tryToAddBrokerComparison(data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToEditBrokerComparison = createAsyncThunk<
    ApiResponse<IBrokersComparisonSchema>,
    any
>('brokersComparison/tryToEditBrokerComparison', async (data: any, { rejectWithValue }) => {
    try {
        const result = await BrokersComparisonService.tryToEditBrokerComparison(
            data.id || '',
            data?.data,
        );
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToEnableBrokerComparisonSite = createAsyncThunk<ApiResponse<IBrokersComparisonSchema>, string>(
    'brokersComparison/tryToEnableBrokerComparisonSite',
    async (id, { rejectWithValue }) => {
        try {
            const result = await BrokersComparisonService.tryToEnableBrokerComparisonSite(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDisableBrokerComparisonSite = createAsyncThunk<ApiResponse<IBrokersComparisonSchema>, string>(
    'brokersComparison/tryToDisableBrokerComparisonSite',
    async (id, { rejectWithValue }) => {
        try {
            const result = await BrokersComparisonService.tryToDisableBrokerComparisonSite(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteBrokerComparisonSite = createAsyncThunk<ApiResponse<IBrokersComparisonSchema>, string>(
    'brokersComparison/tryToDeleteBrokerComparisonSite',
    async (id, { rejectWithValue }) => {
        try {
            const result = await BrokersComparisonService.tryToDeleteBrokerComparisonSite(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const brokersComparisonSlice = createSlice({
    name: 'brokersComparison',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchBrokersComparisonSites
            .addCase(tryToFetchBrokersComparisonSites.pending, (state) => {
                state.brokersComparisonAreLoading = true;
            })
            .addCase(tryToFetchBrokersComparisonSites.rejected, (state) => {
                state.brokersComparisonAreLoading = false;
            })
            .addCase(tryToFetchBrokersComparisonSites.fulfilled, (state, action) => {
                state.brokersComparisonAreLoading = false;
                state.brokersComparison = action.payload;
            })

            // tryToFetchSingleBrokerComparison
            .addCase(tryToFetchSingleBrokerComparison.pending, (state) => {
                state.brokerComparisonIsLoading = true;
            })
            .addCase(tryToFetchSingleBrokerComparison.rejected, (state) => {
                state.brokerComparisonIsLoading = false;
                state.brokerComparison = undefined;
            })
            .addCase(tryToFetchSingleBrokerComparison.fulfilled, (state, action) => {
                state.brokerComparisonIsLoading = false;
                state.brokerComparison = action.payload;
            })

            // tryToAddBrokerComparison
            .addCase(tryToAddBrokerComparison.pending, (state) => {
                state.brokerComparisonIsLoading = true;
            })
            .addCase(tryToAddBrokerComparison.rejected, (state) => {
                state.brokerComparisonIsLoading = false;
            })
            .addCase(tryToAddBrokerComparison.fulfilled, (state, action) => {
                state.brokerComparisonIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.brokersComparison?.data)
                    state.brokersComparison.data.elements = [
                        data,
                        ...state.brokersComparison?.data.elements,
                    ];
            })

            // tryToEditBrokerComparison
            .addCase(tryToEditBrokerComparison.pending, (state) => {
                state.brokerComparisonIsLoading = true;
            })
            .addCase(tryToEditBrokerComparison.rejected, (state) => {
                state.brokerComparisonIsLoading = false;
            })
            .addCase(tryToEditBrokerComparison.fulfilled, (state, action) => {
                state.brokerComparisonIsLoading = false;
                if (state.brokersComparison?.data.elements)
                    state.brokersComparison.data.elements =
                        { ...state }.brokersComparison?.data.elements.map((broker) => {
                            if (broker?._id === action.meta?.arg?.id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return broker;
                            }
                        }) || [];
                state.brokerComparison = action.payload.data;
            })


            // tryToEnableBrokerComparisonSite
            .addCase(tryToEnableBrokerComparisonSite.pending, (state) => {
                state.brokerComparisonIsLoading = true;
            })
            .addCase(tryToEnableBrokerComparisonSite.rejected, (state) => {
                state.brokerComparisonIsLoading = false;
            })
            .addCase(tryToEnableBrokerComparisonSite.fulfilled, (state, action) => {
                state.brokerComparisonIsLoading = false;
                state.requestStatus = 'success';
                if (state.brokersComparison?.data.elements)
                    state.brokersComparison.data.elements =
                        { ...state }.brokersComparison?.data.elements.map((broker: any) => {
                            if (broker?._id === action.meta?.arg) {
                                return {
                                    ...broker,
                                    enabled: true
                                }
                            } else {
                                return broker
                            }
                        }) || [];
            })

            // tryToDisableBrokerComparisonSite
            .addCase(tryToDisableBrokerComparisonSite.pending, (state) => {
                state.brokerComparisonIsLoading = true;
            })
            .addCase(tryToDisableBrokerComparisonSite.rejected, (state) => {
                state.brokerComparisonIsLoading = false;
            })
            .addCase(tryToDisableBrokerComparisonSite.fulfilled, (state, action) => {
                state.brokerComparisonIsLoading = false;
                state.requestStatus = 'success';
                if (state.brokersComparison?.data.elements)
                    state.brokersComparison.data.elements =
                        { ...state }.brokersComparison?.data.elements.map((broker) => {
                            if (broker?._id === action.meta?.arg) {
                                return {
                                    ...broker,
                                    enabled: false
                                }
                            } else {
                                return broker
                            }
                        }) || [];
            })

            // tryToDeleteBrokerComparisonSite
            .addCase(tryToDeleteBrokerComparisonSite.rejected, (state) => {
                state.brokerComparisonIsLoading = false;
                state.requestStatus = 'error';
            })
            .addCase(tryToDeleteBrokerComparisonSite.fulfilled, (state, action) => {
                state.brokerComparisonIsLoading = false;
                state.requestStatus = 'success';
                if (state.brokersComparison?.data.elements)
                    state.brokersComparison.data.elements =
                        { ...state }.brokersComparison?.data.elements.filter((broker) => broker._id !== action.meta.arg) || [];
            })

    }
})


export default brokersComparisonSlice.reducer;