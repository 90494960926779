import React from 'react';


interface ICatalogItem {
    catalogActive?: boolean;
    catalogName: string;
    catalogId: string;
    onClickCatalog: (catalog?: string, catalogActive?: boolean, catalogName?: string) => void;
}

const CatalogItem = ({
    catalogActive,
    catalogName,
    catalogId,
    onClickCatalog
}: ICatalogItem) => {
    return (
        <div data-qa={'catalog-active'} className='flex flex-row items-center mb-2 cursor-pointer' onClick={() => onClickCatalog(catalogId, catalogActive, catalogName)}>
            <img
                alt={'Catalog Active'}
                src={`/assets/profile/${catalogActive ? 'open-success.svg' : 'open-disabled.svg'}`}
                className={'mr-2 w-[16px] object-contain'}
            />
            <p>{catalogName}</p>
        </div>
    )
}

export default CatalogItem;