import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { tryToFetchSingleAssetTemplate } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import SalesGradeMappings from './categories/SalesGradeMappings';
import InventoryFields from './categories/InventoryFields';
import ExtendedFields from './categories/ExtendedFields';
import CatalogFields from './categories/CatalogFields';
import RentalFields from './categories/RentalFields';
import Button from '../../../shared/button';

const templateCategories = [
    { name: 'Catalog Fields', element: <CatalogFields /> },
    { name: 'Inventory Fields', element: <InventoryFields /> },
    { name: 'Rental Fields', element: <RentalFields /> },
    { name: 'Extended Fields', element: <ExtendedFields /> },
    { name: 'Sales Grade', element: <SalesGradeMappings /> },

]

const AssetTemplateDetails = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [assetTemplate, setAssetTemplate] = useState<IAssetTemplate>()
    const [activeCategory, setActiveCategory] = useState<any>(templateCategories[0])
    const [pageAccess, setPageAccess] = useState<any>()
    const state = useAppSelector((state) => state.assetsTemplates);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const assetTemplateLoading = useAppSelector((state) => state.assetsTemplates?.assetTemplateIsLoading);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Asset Catalogs')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Asset Catalog Details')
        setPageAccess(findChild || undefined)
        if (findChild) {
            const findActiveCategory = findChild?.actions?.[templateCategories[0]?.name] ? templateCategories[0] : templateCategories?.find((item: any) => findChild?.actions?.[item?.name])
            setActiveCategory(findActiveCategory)
        }
    }, [accessControl])

    const onChangeActiveCategory = (item: any) => {
        setActiveCategory(item)
    }

    useEffect(() => {
        if (state.assetTemplateDetails) {
            setAssetTemplate(state.assetTemplateDetails)
        }
    }, [state.assetTemplateDetails]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleAssetTemplate(id));
        }
    }, []);

    return (
        <div>
            <div>
                <p className='page-title'>Manage Asset Catalog</p>
                {!assetTemplateLoading &&
                    <>
                        <p className='mt-3 font-medium'>Display Name: {assetTemplate?.displayName}</p>
                        <p className='mt-3 font-medium'>Name: {assetTemplate?.name}</p>
                    </>
                }
            </div>
            <div className='flex flex-row items-center my-5'>
                {templateCategories?.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name]))?.map((category, index) => {
                    return (
                        <Button
                            key={index}
                            label={category?.name}
                            className={activeCategory?.name === category?.name ? 'btn-categories ml-2 min-w-[80px]' : 'btn-noactive-categories ml-2 min-w-[80px]'}
                            onClickButton={() => onChangeActiveCategory(category)}
                            dataQa={`${(category?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                        />
                    )
                })}
            </div>
            <div className='my-2'>
                {(!accessControl || pageAccess?.actions?.[activeCategory?.name]) && activeCategory?.element}
            </div>
        </div>
    )
}

export default AssetTemplateDetails;