import React from 'react';


const PartnerPriorityContainer = () => {
    return (
        <div className='my-5'>
            {[...Array(3)].map((x, i) => {
                return (
                    <div key={i} className='grid grid-cols-4 gap-3 mb-6'>
                        <div className='animate-pulse'>
                            <div className='h-3 bg-gray-200 rounded-full dark:bg-gray-200 w-[50%] mr-2'></div>
                            <div className='h-7 bg-gray-200 rounded dark:bg-gray-200 w-[94%] mt-1'></div>
                        </div>
                        <div className='animate-pulse'>
                            <div className='h-3 bg-gray-200 rounded-full dark:bg-gray-200 w-[50%] mr-2'></div>
                            <div className='h-7 bg-gray-200 rounded dark:bg-gray-200 w-[94%] mt-1'></div>
                        </div>
                        <div className='animate-pulse'>
                            <div className='h-3 bg-gray-200 rounded-full dark:bg-gray-200 w-[50%] mr-2'></div>
                            <div className='h-7 bg-gray-200 rounded dark:bg-gray-200 w-[94%] mt-1'></div>
                        </div>
                        <div className='flex flex-row mt-3'>
                            <div className='h-[25px] bg-gray-200 rounded-full dark:bg-gray-200 w-[25px] mr-5'></div>
                            <div className='h-[25px] bg-gray-200 rounded-full dark:bg-gray-200 w-[25px] mr-2'></div>
                        </div>
                    </div>
                )
            })
            }
        </div>
    )
}

export default PartnerPriorityContainer;