import React, { useEffect, useState, useCallback } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone'
import { useForm, useFieldArray } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ICreateAssetField } from '../../../../interfaces/asset-templates/IAssetTemplateSchema';
import {
    tryToEditAssetTemplateField,
    tryToFetchSingleAssetTemplateField
} from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';


interface IReplaceFieldsModal {
    openFieldsModal: boolean;
    fieldItemId: string | null;
    assetTemplateId: string;
    handleCloseFieldsModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    values?: { name: string }[];
};

const ReplaceFieldsModal = ({
    openFieldsModal,
    fieldItemId,
    assetTemplateId,
    handleCloseFieldsModal,
    onSaveChanges,
}: IReplaceFieldsModal) => {
    const dispatch = useAppDispatch();
    const [newValue, setNewValue] = useState<string>()
    const [listError, setListError] = useState<string>()
    const [fileSelected, setFileSelected] = useState<any>()
    const state = useAppSelector((state) => state.assetsTemplates);
    const fieldLoading = useAppSelector((state) => state.assetsTemplates?.assetTemplateFieldIsLoading);

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'values'
    })

    useEffect(() => {
        setValue('name', state?.assetTemplateFieldsDetails?.name || '')
        const formatValuesData = fieldItemId && state?.assetTemplateFieldsDetails?.replaceField?.values && state?.assetTemplateFieldsDetails?.replaceField?.values?.length > 0 && state?.assetTemplateFieldsDetails?.replaceField?.values?.map((v) => { return { name: v } })
        setValue('values', fieldItemId ? formatValuesData || [] : [])
    }, [state.assetTemplateFieldsDetails]);

    useEffect(() => {
        if (fieldItemId) {
            const data = {
                assetId: assetTemplateId,
                fieldId: fieldItemId
            }
            dispatch(tryToFetchSingleAssetTemplateField(data));
        }
    }, [fieldItemId])

    const onCloseModal = () => {
        setListError(undefined)
        setFileSelected(undefined)
        setNewValue(undefined)
        reset()
        handleCloseFieldsModal()
    }

    const onSubmit = async (data: FormValues) => {
        if ((!fields || fields?.length <= 0)) {
            setListError('Please write at least one list item.')
            return;
        }
        const formatData: any = {
            ...state.assetTemplateFieldsDetails || {},
            replaceField: {
                name: data?.name,
            }
        }
        if (data?.values && data?.values?.length > 0) {
            formatData.replaceField.values = data?.values?.map((value) => value?.name)
        }
        const payload: ICreateAssetField = {
            ...formatData,
            assetId: assetTemplateId
        };
        try {
            if (fieldItemId) {
                payload.id = fieldItemId
                await dispatch(tryToEditAssetTemplateField(payload)).unwrap();
            }
            onSaveChanges('success', `Asset Field successfully changed.`)
        } catch (error) {
            onSaveChanges('error', `${error}`)
        }
        onCloseModal()
    };

    const onChangeNewValue = (value: string | null | undefined, type: string) => {
        setNewValue(value || undefined)
        setListError(undefined)
    }

    const onAddNewValue = () => {
        if (newValue && newValue !== '') {
            const appendData = { name: newValue }
            append(appendData)
            setNewValue('')
        }
    }

    const onDeleteValue = (id: number) => {
        remove(id)
    }

    const onGettingListValuesFromFile = () => {
        if (fileSelected) {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const key = results?.data?.[0] && Object.keys(results?.data?.[0])
                    const unique = results && results?.data && results?.data?.length > 0 && results?.data?.map((item: any) => item?.[key?.[0]])
                        .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
                    if (unique && unique?.length > 0) {
                        unique?.map((item: any) => {
                            append({ name: item })
                            return item;
                        })
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (fileSelected) {
            onGettingListValuesFromFile()
        }
    }, [fileSelected])

    const onDeleteReplaceField = async () => {
        const payload: any = {
            ...state.assetTemplateFieldsDetails || {},
            replaceField: undefined,
            assetId: assetTemplateId
        };
        try {
            if (fieldItemId) {
                payload.id = fieldItemId
                await dispatch(tryToEditAssetTemplateField(payload)).unwrap();
            }
            handleCloseFieldsModal()
            onSaveChanges('success', `Asset Replace Field successfully deleted.`)
        } catch (error) {
            onSaveChanges('error', `${error}`)
        }
        setListError(undefined)
        setFileSelected(undefined)
        reset();
    }

    return (
        <Modal
            open={openFieldsModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Replace Field</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {((!fieldLoading && fieldItemId) || !fieldItemId) ?
                        <div className='flex flex-col my-4'>
                            <Input
                                label='Name'
                                dataQa={'name'}
                                disabled={true}
                                register={register('name', {
                                    required: {
                                        message: 'Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) => !!value.trim() || 'Name is required'
                                })}
                                error={errors.name?.message}
                            />
                            <div className='flex flex-row items-center'>
                                <Input
                                    placeholder='List Value'
                                    containerStyle={'mt-0 flex flex-row h-full items-center'}
                                    showValue={true}
                                    inputValue={newValue}
                                    inputUniqueName={'newValue'}
                                    onChangeInput={onChangeNewValue}
                                    dataQa={'list-value'}
                                />
                                <Button
                                    label='Add Value'
                                    className='btn-filters ml-2 mt-0'
                                    onClickButton={onAddNewValue}
                                    dataQa={'add-value'}
                                />
                                <div className='flex flex-col items-center justify-center ml-2' {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button
                                        icon={<img src='/assets/shared/upload-file.svg' className='w-[20px] object-contains pr-2' />}
                                        className={'btn-primary-rounded !py-1 !shadow-none flex flex-row items-center'}
                                        label={'Select File'}
                                        dataQa={'select-file'}
                                    />
                                </div>
                            </div>
                            {listError && <Error text={listError} />}
                            <div className='flex flex-row items-center flex-wrap my-3'>
                                {(fields && fields?.length > 0) && fields?.map((item: any, idx: number) => {
                                    return (
                                        <div key={idx} className={'flex flex-row items-center justify-between border border-solid border-[#F7A21E] rounded-md px-1 mr-1 mb-1'}>
                                            <p className='text-slate-400'>{item?.name}</p>
                                            <div data-qa={'delete-value'} className='pl-1 flex flex-row items-center h-full' onClick={() => onDeleteValue(idx)}>
                                                <img alt='close' src={'/assets/shared/close-grey.svg'} className={'w-[20px] mt-1 object-contain cursor-pointer'} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Delete Replace Field'
                                    onClickButton={onDeleteReplaceField}
                                    className={'btn-error mr-2'}
                                    dataQa={'delete-replace-field-btn'}
                                />
                                <Button
                                    label='Save'
                                    type={'submit'}
                                    className={'btn-primary'}
                                    dataQa={'replace-field-save-btn'}
                                />
                            </div>
                        </div>
                        : <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    }
                </form>
            </div>
        </Modal>
    )
}
export default ReplaceFieldsModal;