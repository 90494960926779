import React from 'react';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAllBrokersComparisonSites } from '../../../../interfaces/brokers-comparison-sites/IAllBrokersComparisonSites';
import Table from '../../../../shared/table';
import Switch from '../../../../shared/switch';

const tableColumns = ['ID', 'Name', 'Display Name', 'Logo URL', 'Enabled', 'Markets', 'Sectors', 'Actions']

interface IBrokersComparisonTable {
    rows: IAllBrokersComparisonSites[];
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    accessEnableDisable?: any;
    rowsLoading: boolean;
    onEnableDisableBrokerComparisonSites: (value: string, currentStatus: boolean) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onDeleteBrokerComparisonSites: (id: string) => void;
    onAddNewBroker: (id: string) => void;
    onSeeBrokerDetails: (id: string) => void;
}

const BrokersComparisonTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    accessEnableDisable,
    rowsLoading,
    onEnableDisableBrokerComparisonSites,
    setPaginationState,
    onDeleteBrokerComparisonSites,
    onAddNewBroker,
    onSeeBrokerDetails,
}: IBrokersComparisonTable) => {

    return (
        <Table
            columns={tableColumns}
            containerStyle={'mt-0'}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IAllBrokersComparisonSites, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.id}</td>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>{row?.displayName}</td>
                                <td className='px-6 py-4'>{row?.logoUrl && <img src={row?.logoUrl} alt='flag' className={'mr-2 py-2 w-[100px]'} />}</td>
                                <td className={`px-6 py-4 ${!accessEnableDisable && 'cursor-not-allowed'}`}>
                                    <Switch
                                        dataQa={`${row?.enabled ? 'enabled' : 'disabled'}`}
                                        checked={!!row?.enabled}
                                        onToggleSwitch={() => row?._id && accessEnableDisable && onEnableDisableBrokerComparisonSites(row?._id, row?.enabled || false)}
                                    />
                                </td>
                                <td>
                                    {(row?.markets && row?.markets.length > 0 && row?.markets?.[0]) ? row?.markets?.map((item: any, index: number) => {
                                        return <p key={index}>{item?.label}</p>
                                    })
                                        : <p>All Markets</p>
                                    }
                                </td>
                                <td className='px-6 py-4'>
                                    {(row?.sectors && row?.sectors.length > 0 && row?.sectors?.[0]) ? row?.sectors?.map((item: any, index: number) => {
                                        return <p key={index}>{item?.name}</p>
                                    })
                                        : <p>All Sectors</p>
                                    }
                                </td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa={'see-broker-details'} onClick={() => ((!accessControl || actions?.['See Broker Details']) && row?.id) && onSeeBrokerDetails(row?.id)}>
                                            <img title={'View broker'} alt='view broker' data-qa='details-broker' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Broker Details']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa='edit-broker' onClick={() => ((!accessControl || actions?.['Edit Broker']) && row?._id) && onAddNewBroker(row?._id)}>
                                            <img title='Edit Broker' alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Broker']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa='delete-broker' onClick={() => ((!accessControl || actions?.['Delete Broker']) && row?._id) && onDeleteBrokerComparisonSites(row?._id)}>
                                            <img title={'Delete Broker'} alt='delete Broker' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Broker']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default BrokersComparisonTable;