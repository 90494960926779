import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddPayService,
    tryToEditPayService,
    tryToFetchSinglePayService
} from '../../../../store/brokers/admin/pay-services/payServicesSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';


interface IPayServiceActionsModal {
    openActionsModal: boolean;
    payServiceItemId?: string | null;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    displayName: string;
};

const PayServiceActionsModal = ({
    openActionsModal,
    payServiceItemId,
    handleCloseModal,
    onSaveChanges,
}: IPayServiceActionsModal) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.payServices);
    const payServiceLoading = useAppSelector((state) => state.payServices?.payServiceIsLoading);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        setValue('displayName', payServiceItemId ? state?.payService?.displayName || '' : '');
        setValue('name', payServiceItemId ? state?.payService?.name || '' : '');
    }, [state.payService]);

    useEffect(() => {
        if (payServiceItemId) {
            dispatch(tryToFetchSinglePayService(payServiceItemId));
        }
    }, [payServiceItemId]);

    const onSubmit = async (data: FormValues) => {
        const payload: any = {
            ...data,
        };
        try {
            if (payServiceItemId) {
                payload._id = payServiceItemId;
                await dispatch(tryToEditPayService(payload)).unwrap();
            } else {
                await dispatch(tryToAddPayService(payload)).unwrap();
            }
            onSaveChanges('success', `Pay Service successfully ${payServiceItemId ? 'changed' : 'added'}.`);
        } catch (error) {
            onSaveChanges('error', `${error}`);
        }
        reset();
        handleCloseModal();
    };

    const onCloseModal = () => {
        reset();
        handleCloseModal();
    };

    return (
        <Modal
            open={openActionsModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>Service</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(payServiceItemId && !payServiceLoading) || !payServiceItemId ? (
                        <div className='flex flex-col my-4'>
                            <Input
                                label='Display Name'
                                dataQa={'display-name'}
                                type='text'
                                register={register('displayName', {
                                    required: {
                                        message: 'Display Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) => !!value.trim() || 'Display Name is required'
                                })}
                                error={errors.displayName?.message}
                            />
                            <Input
                                label='Name'
                                dataQa={'name'}
                                type='text'
                                register={register('name', {
                                    required: {
                                        message: 'Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) => !!value.trim() || 'Name is required'
                                })}
                                error={errors.name?.message}
                            />
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    dataQa={'submit'}
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default PayServiceActionsModal;