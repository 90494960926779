import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
    useLocation,
    useSearchParams
} from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { USER_TYPES } from '../../../utils/constants/user-types';
import AdminUsers from './categories/AdminUsers';
import UserTypes from './details/UserTypes';
import Partners from '../partners';
import CSAgents from '../cs-agents';
import Retailers from '../retailers';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Users = () => {
    const query = useQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const [userTypes, setUserTypes] = useState<any>('partners');
    const [userPageTitle, setUserPageTitle] = useState<any>('Partners');
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onClickUserType = (value: any, name: any) => {
        setUserTypes(value)
        setUserPageTitle(name)
        searchParams.set('type', value)
        setSearchParams(searchParams)
    }

    useLayoutEffect(() => {
        if (query?.get('type') && query?.get('type') !== userTypes) {
            setUserTypes(query?.get('type') || undefined)
        }
    }, [query?.get('type')])

    return (
        <div>
            <div>
                <p className='page-title'>{userPageTitle}</p>
            </div>
            <div className='mt-7 mb-7'>
                <UserTypes
                    items={USER_TYPES?.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name])) || []}
                    activeButton={userTypes || 'Type'}
                    onClickType={onClickUserType}
                />
            </div>
            {(userTypes === 'partners' && (!accessControl || pageAccess?.actions?.Partners)) && <Partners />}
            {(userTypes === 'customer_support' && (!accessControl || pageAccess?.actions?.['Customer Support'])) && <CSAgents />}
            {(userTypes === 'admin_users' && (!accessControl || pageAccess?.actions?.['Admin Users'])) && <AdminUsers />}
            {(userTypes === 'retailers' && (!accessControl || pageAccess?.actions?.Retailers)) && <Retailers />}
        </div>
    )
}

export default Users;