import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetLibraryService } from '../../../../store/brokers/partners/asset-library/assetLibraryApi';
import { IAssetDetails } from '../../../../interfaces/asset-templates/IAssetTemplateSchema';
import moment from 'moment';

const FormatDetails = ({ title, description }: { title: string, description: string }) => <>
	<div className='flex flex-wrap w-full font-medium text-sm mb-5'>
		<div className='text-agentDetails-secondaryTitle min-w-52'>
			{title}
		</div>
		<div className='text-agentDetails-secondaryDescription'>
			{description}
		</div>
	</div>
</>

const AssetLibraryDetails = () => {
	const {id} = useParams();
	const [details, setDetails] = useState<IAssetDetails>();
	const navigate = useNavigate();
	const formatCondition = (condition: string) => {
		let conditionStar = 3;
		if (condition.toLowerCase() === 'damaged') conditionStar = 1;
		else if (condition.toLowerCase() === 'excellent') conditionStar = 5;
		return <>
			<div className='flex flex-row my-5'>
				{Array.from({ length: conditionStar }, (_v, k) => <img key={k.toString()} alt='star' src='/assets/ai-agents/star.svg' />)}
			</div>
			<p className='ml-2 pt-1'>{condition}</p>
		</>
	}

	const getAssetItemDetails = async () => {
		const {data} = await AssetLibraryService.tryToFetchAssetsDetails(id || '');
		setDetails(data.data);
	}

	useEffect(()=>{
		getAssetItemDetails();
	}, [])

	const handleGoBack = useCallback(() => navigate('/asset-library'), [navigate])

	return (
		<div>
			<h2 className='font-semibold text-xl md:text-4xl flex flex-row items-center -ml-8 mb-2'>
				<button className='mr-2 w-6 h-6' onClick={handleGoBack} type='button'>
					<img alt='go back' src='/assets/shared/go-back-circle.svg' />
				</button>
				Asset Details
			</h2>
			<div className='flex flex-wrap -mx-2'>
				<div className='w-full md:w-9/12 px-2'>
					<div className='bg-white rounded-lg my-2 py-3 px-4'>
						<h4 className=' text-agentDetails-title text-xl'>{details?.productName}</h4>
						<p className='font-normal text-agentDetails-description text-xs flex flex-wrap items-center'>
							{formatCondition(details?.condition || '')}
						</p>
						<div className='mt-5'>
							<div className='w-full border-b border-agentDetails-titleBg pb-2'>
								<span className='border-b  border-agentDetails-border pb-2 text-center text-agentDetails-whiteTitle'>
									Overview
								</span>
							</div>
							<div className='p-5'>
								<div className='flex flex-wrap'>
									<div className='w-full md:w-8/12'>
										<h5 className='font-semibold text-base text-agentDetails-whiteTitle mb-3'>Primary Details</h5>
										<FormatDetails title='Product name' description={details?.productName || ''} />
										<FormatDetails title='Product ID' description={details?.productId || ''} />
										<FormatDetails title='Product category' description={details?.productCategory || ''} />
										<FormatDetails title='Brand' description={details?.makeBrand || ''} />
										<FormatDetails title='Capacity' description={details?.capacity || ''} />
										<FormatDetails title='Purchase Price' description={details?.purchasePrice || ''} />
										<FormatDetails title='Repair Cost' description={details?.repairCost || ''}/>
										<FormatDetails title='Current Offer' description={details?.currentOffer + '' || ''} />
									</div>
									<div className='w-full md:w-4/12 pt-10 mt-auto'>
										<FormatDetails title='AI Graded' description={details?.aiGraded || ''} />
										<FormatDetails title='AI Grading at' description={moment(details?.aiGradingAt).format('DD.MM.YYYY')} />
										<FormatDetails title='Condition' description={details?.condition || ''} />
										{/* <FormatDetails title='Condition description' description='Excellent condition smarphones don’t have any scratches on the screen front or back of the phone, no issues with any hardware or software part and the battery capacity is over 90%' /> */}
									</div>
								</div>
								<div className='w-full md:w-8/12 mt-12'>
									<h5 className='font-semibold text-base text-agentDetails-whiteTitle mb-3'>Other Details</h5>
									<FormatDetails title='Status' description={details?.status || ''} />
									{/* <FormatDetails title='Extra Description' description='[Here goes data that user either inputet on a file upload or edited into the product ]' /> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className='w-full md:w-3/12 px-2'>
					<div className='bg-white rounded my-2 py-3 px-4'>
						<h4 className='text-xl text-agentDetails-title mb-2'>Similar Assets</h4>
						<div className='w-full'>
							<div className='bg-title p-4 text-sm text-agentDetails-lightBg'>
								Item Name
							</div>
							<div className='bg-agentDetails-lightBg bg-age w-full mb-1 p-3'>
								iPhone 13 Pro Max 256 GB Unlocked Network Some Color Excellent condition
							</div>
							<div className='bg-agentDetails-lightBg w-full mb-1 p-3'>
								iPhone 13 Pro Max 256 GB Unlocked Network Some Color Excellent condition
							</div>
							<div className='bg-agentDetails-lightBg w-full mb-1 p-3'>
								iPhone 13 Pro Max 256 GB Unlocked Network Some Color Excellent condition
							</div>
							<div className='bg-agentDetails-lightBg w-full mb-1 p-3'>
								iPhone 14 Pro Max
							</div>
						</div>
					</div>
				</div> */}
			</div>


		</div>
	)
}

export default AssetLibraryDetails;