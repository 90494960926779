import React, { useEffect, useRef } from 'react';
import Button from '../../../../shared/button';

interface ITableActions {
    onAcceptOffer: () => void;
    onRejectOffer: () => void;
}

const TableActions = ({ onAcceptOffer, onRejectOffer }: ITableActions) => {
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const dropdownMenu = useRef<HTMLDivElement | null>(null);

    const closeDropdownPopover = (e: MouseEvent) => {
        if (dropdownMenu.current && !dropdownMenu.current.contains(e.target as Node)) {
            setDropdownPopoverShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', closeDropdownPopover);
        return () => {
            document.removeEventListener('click', closeDropdownPopover);
        };
    }, []);

    const onClickDropDownItem = (action: string) => {
        if (action === 'accept') {
            onAcceptOffer();
        } else if (action === 'reject') {
            onRejectOffer();
        }
        setDropdownPopoverShow(false);
    };

    return (
        <div className='relative inline-block text-left'>
            <div>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        setDropdownPopoverShow(!dropdownPopoverShow);
                    }}
                    type='button'
                    data-qa={'actions'}
                    className={`!px-4`}
                    id='menu-button'
                    aria-expanded={dropdownPopoverShow}
                    aria-haspopup='true'
                >
                    <img
                        title={'Action'}
                        alt='items arrow'
                        src={'/assets/pricing/see-more.svg'}
                        className={'w-[4px] rotate-180 mb-2 cursor-pointer mr-2'}
                    />
                </button>
            </div>
            <div
                ref={dropdownMenu}
                className={`${
                    dropdownPopoverShow ? '' : 'hidden'
                } shadow-md max-h-[150px] overflow-auto absolute right-0 z-10 mt-0 w-40 origin-top-right divide-y divide-[#a4a4a4] bg-white focus:outline-none`}
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='menu-button'
                tabIndex={-1}
            >
                <div
                    onClick={() => onClickDropDownItem('accept')}
                    className='py-1 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'
                    role='none'
                >
                    <Button
                        label={'Accept Offer'}
                        dataQa={'accept-offer'}
                        className='btn-primary-text-underline !no-underline !text-[#00B67A]'                    />
                </div>
                <div
                    onClick={() => onClickDropDownItem('reject')}
                    className='py-1 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'
                    role='none'
                >
                    <Button
                        label={'Reject Offer'}
                        dataQa={'reject-offer'}
                        className='btn-primary-text-underline !no-underline !text-[#E50000]'                    />
                </div>
            </div>
        </div>
    );
};

export default TableActions;
