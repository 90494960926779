import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { NotificationSectionsService } from './notificationSectionsApi';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { INotificationSection } from '../../../../interfaces/notification-sections/INotificationSection';
import { PaginatedNotificationSections } from '../../../../interfaces/notification-sections/paginatedStore.type';
import { INotificationSectionListState } from '../../../../interfaces/notification-sections/INotificationSectionList';

const initialState: INotificationSectionListState = {
    sectionIsLoading: false,
    sectionsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchNotificationSections = createAsyncThunk<
    PaginatedNotificationSections,
    IPaginationPayload
>('notification-sections/tryToFetchNotificationSections',
    async ({ pageNumber, pageSize, data }) => {
        const result = await NotificationSectionsService.tryToFetchNotificationSections(
            pageNumber,
            pageSize,
            data,
        );
        return result?.data;
    });

export const tryToFetchAllNotificationSection = createAsyncThunk<any>(
    'notification-sections/tryToFetchAllNotificationSection',
    async () => {
        const result = await NotificationSectionsService.tryToFetchAllNotificationSection();
        return result?.data;
    });

export const tryToFetchSingleNotificationSection = createAsyncThunk<ApiResponse<INotificationSection>, string>(
    'notification-sections/tryToFetchSingleNotificationSection',
    async (id: string) => {
        const result = await NotificationSectionsService.tryToFetchSingleNotificationSection(id);
        return result?.data;
    },
);

export const tryToAddNotificationSection = createAsyncThunk<ApiResponse<INotificationSection>, INotificationSection>(
    'notification-sections/tryToAddNotificationSection',
    async (data: INotificationSection, { rejectWithValue }) => {
        try {
            const result = await NotificationSectionsService.tryToAddNotificationSection(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToEditNotificationSection = createAsyncThunk<ApiResponse<INotificationSection>, INotificationSection>(
    'notification-sections/tryToEditNotificationSection',
    async (data: INotificationSection, { rejectWithValue }) => {
        try {
            const result = await NotificationSectionsService.tryToEditNotificationSection(
                data._id || '',
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToDeleteNotificationSection = createAsyncThunk<ApiResponse<INotificationSection>, string>(
    'notification-sections/tryToDeleteNotificationSection',
    async (id, { rejectWithValue }) => {
        try {
            const results = await NotificationSectionsService.tryToDeleteNotificationSection(id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const notificationSectionsSlice = createSlice({
    name: 'notificationSections',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchNotificationSections
            .addCase(tryToFetchNotificationSections.pending, (state) => {
                state.sectionsAreLoading = true;
            })
            .addCase(tryToFetchNotificationSections.rejected, (state) => {
                state.sectionsAreLoading = false;
            })
            .addCase(tryToFetchNotificationSections.fulfilled, (state, action) => {
                state.sectionsAreLoading = false;
                state.sections = action.payload;
            })

            // tryToAddNotificationSection
            .addCase(tryToAddNotificationSection.pending, (state) => {
                state.sectionIsLoading = true;
            })
            .addCase(tryToAddNotificationSection.rejected, (state) => {
                state.sectionIsLoading = false;
            })
            .addCase(tryToAddNotificationSection.fulfilled, (state, action) => {
                state.sectionIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.sections?.data)
                    state.sections.data.elements = [
                        ...state.sections?.data.elements,
                        data,
                    ];
            })

            // tryToDeleteNotificationSection
            .addCase(tryToDeleteNotificationSection.pending, (state) => {
                state.sectionIsLoading = true;
            })
            .addCase(tryToDeleteNotificationSection.rejected, (state) => {
                state.sectionIsLoading = false;
            })
            .addCase(tryToDeleteNotificationSection.fulfilled, (state, action) => {
                state.sectionIsLoading = false;
                state.requestStatus = 'success';
                if (state.sections?.data.elements)
                    state.sections.data.elements =
                        { ...state }.sections?.data.elements.filter(
                            (section) =>
                                section?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToEditNotificationSection
            .addCase(tryToEditNotificationSection.pending, (state) => {
                state.sectionIsLoading = true;
            })
            .addCase(tryToEditNotificationSection.rejected, (state) => {
                state.sectionIsLoading = false;
            })
            .addCase(tryToEditNotificationSection.fulfilled, (state, action) => {
                state.sectionIsLoading = false;
                if (state.sections?.data.elements)
                    state.sections.data.elements =
                        { ...state }.sections?.data.elements.map((section) => {
                            if (section?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return section;
                            }
                        }) || [];
                state.section = action.payload.data;
            })

            // tryToFetchSingleNotificationSection
            .addCase(tryToFetchSingleNotificationSection.pending, (state) => {
                state.sectionIsLoading = true;
            })
            .addCase(tryToFetchSingleNotificationSection.rejected, (state) => {
                state.sectionIsLoading = false;
            })
            .addCase(tryToFetchSingleNotificationSection.fulfilled, (state, action) => {
                state.sectionIsLoading = false;
                state.section = action.payload?.data;
            })

            // tryToFetchAllNotificationSection
            .addCase(tryToFetchAllNotificationSection.pending, (state) => {
                state.sectionIsLoading = true;
            })
            .addCase(tryToFetchAllNotificationSection.rejected, (state) => {
                state.sectionIsLoading = false;
            })
            .addCase(tryToFetchAllNotificationSection.fulfilled, (state, action) => {
                state.sectionIsLoading = false;
                state.allSections = action.payload?.data;
            });
    },
});

export default notificationSectionsSlice.reducer;