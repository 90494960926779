import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';

const tableColumns = ['Role Name', 'Type', 'Edit/View Details', 'Delete']

interface IRolesTable {
    rows: any;
    paginationData?: IPage;
    accessEditRole?: any;
    accessDeleteRole?: any;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeDetails: (id: string) => void;
    onDeleteRole: (id: string) => void;
}

const RolesTable = ({
    rows,
    paginationData,
    accessEditRole,
    accessDeleteRole,
    rowsLoading,
    setPaginationState,
    onSeeDetails,
    onDeleteRole
}: IRolesTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}>
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>{row?.type}</td>
                                <td className='px-6 py-4'>
                                    <Button
                                        label='Edit/View'
                                        dataQa={'edit-view'}
                                        onClickButton={() => accessEditRole && row?._id && onSeeDetails(row?._id)}
                                        className={`${accessEditRole ? 'btn-main' : 'btn-main-disable'} !shadow-none`}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row'>
                                        <div data-qa={'delete-role'} onClick={() => accessDeleteRole && row?._id && onDeleteRole(row?._id)}>
                                            <img title={'Delete Role'} alt='delete role' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${accessEditRole ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default RolesTable;