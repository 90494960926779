import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BrokerNotificationsService } from './brokerNotificationsApi';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IBrokerNotifications } from '../../../../interfaces/notification-sections/IBrokerNotifications';
import { IBrokerNotificationsListState } from '../../../../interfaces/notification-sections/IBrokerNotificationsList';

const initialState: IBrokerNotificationsListState = {
    brokerNotificationsAreLoading: false,
    brokerNotificationsIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchBrokerNotificationSettings = createAsyncThunk<any>(
    'broker-notifications/tryToFetchBrokerNotificationSettings',
    async () => {
        const result = await BrokerNotificationsService.tryToFetchBrokerNotificationSettings();
        return result?.data;
    });

export const tryToChangeBrokerNotificationSettings = createAsyncThunk<ApiResponse<IBrokerNotifications>, IBrokerNotifications>(
    'broker-notifications/tryToChangeBrokerNotificationSettings',
    async (data: IBrokerNotifications, { rejectWithValue }) => {
        try {
            const result = await BrokerNotificationsService.tryToChangeBrokerNotificationSettings(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });


export const brokerNotificationsSlice = createSlice({
    name: 'brokerNotifications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchBrokerNotificationSettings
            .addCase(tryToFetchBrokerNotificationSettings.pending, (state) => {
                state.brokerNotificationsAreLoading = true;
            })
            .addCase(tryToFetchBrokerNotificationSettings.rejected, (state) => {
                state.brokerNotificationsAreLoading = false;
            })
            .addCase(tryToFetchBrokerNotificationSettings.fulfilled, (state, action) => {
                state.brokerNotificationsAreLoading = false;
                state.brokerNotifications = action.payload?.data;
            })

            // tryToChangeBrokerNotificationSettings
            .addCase(tryToChangeBrokerNotificationSettings.pending, (state) => {
                state.brokerNotificationsIsLoading = true;
            })
            .addCase(tryToChangeBrokerNotificationSettings.rejected, (state) => {
                state.brokerNotificationsIsLoading = false;
            })
            .addCase(tryToChangeBrokerNotificationSettings.fulfilled, (state, action) => {
                state.brokerNotificationsIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                state.brokerNotifications = data;
            })
    },
});

export default brokerNotificationsSlice.reducer;