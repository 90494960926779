import React from 'react';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IInvoicesDetails } from '../../../../interfaces/invoices/IInvoicesDetails';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Error from '../../../../shared/error';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';
import ItemsTable from '../tables/ItemsTable';
import Input from '../../../../shared/input';
import SelectCheckbox from '../../../../shared/select-checkbox';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';


interface IItemsStep {
    loadingCreate?: boolean;
    rows: IInvoicesDetails[];
    paginationData?: IPage;
    selectedRows?: Array<string>;
    markets?: IAllMarkets[];
    partners?: IPartners[];
    error?: string;
    loadingGenerateAll?: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSelectRow: (id?: string) => void;
    onCreateInvoice: () => void;
    onClickGenerateAll: () => void;
    search: string | undefined;
    onChangeSearchValue: (value: string | null | undefined) => void;
    selectedSector: any;
    sectors: IAssetTemplate[] | undefined;
    onChangeSelectedFilter: (e: any, type?: string) => void;

}

const ItemsStep = ({
    loadingCreate,
    rows,
    paginationData,
    markets,
    partners,
    selectedRows,
    error,
    loadingGenerateAll,
    setPaginationState,
    onClickSelectRow,
    onCreateInvoice,
    onClickGenerateAll,
    search,
    onChangeSearchValue,
    selectedSector,
    sectors,
    onChangeSelectedFilter
}: IItemsStep) => {
    return (
        <div className='pr-5'>
            <p className='text-[#a4a4a4] font-medium text-sm mt-2 mb-5'>Select items to create an invoice.</p>
            {error && <Error text={error} />}
            <div className='grid grid-cols-3 gap-5'>
            <SelectCheckbox
                name='Category'
                placeholder='All Categories'
                dataQa={'category-selected'}
                containerStyle='mb-1 min-w-full'
                selectedOption={selectedSector}
                onChangeSelectedOption={onChangeSelectedFilter}
                uniqueName={'sector'}
                options={sectors ? [{ name: 'All Categories', label: 'All Categories', value: undefined }, ...sectors] : []}
            />
            </div>
            <div className='my-6 flex flex-row justify-end'>
                <Button
                    label={'Generate Invoice For All'}
                    dataQa={'generate-invoice-for-all'}
                    className={(loadingGenerateAll || loadingCreate) ? 'btn-main-disable min-w-[200px]' : 'btn-main min-w-[200px]'}
                    onClickButton={() => !(loadingGenerateAll || loadingCreate) && onClickGenerateAll()}
                />
            </div>
            <div className='mt-7 mb-3'>

                <div>
                    <Input
                        placeholder='Filter table'
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search || undefined}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                        dataQa={'search-table'}
                    />
                    {(selectedRows && selectedRows?.length > 0) &&
                        <div className={'min-w-full py-2 px-2 flex flex-row justify-between items-center bg-[#F1F9F7]'}>
                            <p className='font-bold'>{selectedRows ? selectedRows?.length : 0} Rows selected</p>
                            <Button
                                icon={(loadingGenerateAll || loadingCreate) ? <Loading /> : <img src={'/assets/invoices/create-invoice.svg'} className={'w-[20px] object-cover mr-2'} />}
                                label={'Create Invoice'}
                                dataQa={'create-invoice'}
                                disabled={loadingCreate}
                                onClickButton={() => !(loadingGenerateAll || loadingCreate) && onCreateInvoice()}
                                className={`${(loadingGenerateAll || loadingCreate) ? 'btn-main-disable' : 'btn-main !bg-black !text-white'}  flex flex-row align-start my-2 !py-2`}
                            />
                        </div>
                    }
                    <ItemsTable
                        rows={rows}
                        paginationData={paginationData}
                        markets={markets}
                        partners={partners}
                        selectedRows={selectedRows}
                        setPaginationState={setPaginationState}
                        onClickSelectRow={onClickSelectRow}
                    />
                </div>
            </div>
        </div>
    )
}
export default ItemsStep;