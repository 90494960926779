import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { tryToDownloadInvoicesCSV } from '../../../../store/brokers/partners/invoices/invoicesSlice';
import { useAppDispatch } from '../../../../app/hooks';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IDownloadInvoicesModal {
    openDownloadModal: boolean;
    filters?: any;
    activeMarket?: IActiveMarket;
    handleCloseDownloadModal: () => void;
}

const DownloadInvoicesModal = ({
    openDownloadModal,
    activeMarket,
    filters,
    handleCloseDownloadModal,
}: IDownloadInvoicesModal) => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [downloadError, setDownloadError] = useState<string>()
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [invoiceCsvData, setInvoiceCsvData] = useState<Array<any>>()


    const onCloseModal = () => {
        handleCloseDownloadModal();
        setDownloadError(undefined)
        setLoadingDownload(false)
    }

    const onDownloadTemplate = async (type?: string) => {
        if (loadingDownload) {
            return;
        }
        setInvoiceCsvData(undefined)
        setLoadingDownload(true)
        try {
            let settingsFilters: any = filters?.data || {}
            if (type === 'market') {
                settingsFilters = {
                    ...settingsFilters || {},
                    market: activeMarket?.market?._id
                }
            }
            const response: any = await dispatch(tryToDownloadInvoicesCSV(settingsFilters)).unwrap()
            setInvoiceCsvData(response?.data || [])
        } catch (err: any) {
            setDownloadError(`${err}`)
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (invoiceCsvData && loadingDownload) {
            csvLink.current.link.click()
            onCloseModal()
        }
        setLoadingDownload(false)
    }, [invoiceCsvData])

    return (
        <Modal
            open={openDownloadModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[350px] min-h-[300px]'}>
                <p className='mb-2 font-semibold text-sm'>Export CSV Invoices</p>
                {downloadError && <Error text={downloadError} />}
                <div className='flex flex-col items-center'>
                    <Button
                        label={loadingDownload ? 'Loading data...' : `Invoices For ${activeMarket?.market?.label} Market`}
                        onClickButton={() => !loadingDownload && onDownloadTemplate('market')}
                        dataQa={loadingDownload ? 'loading-data...' : `invoices-for-${activeMarket?.market?.label}-market`}
                        disabled={loadingDownload}
                        className={`${loadingDownload ? 'btn-main-disable' : 'btn-main'} mb-2 mt-9 !py-2 !w-[100%] !shadow-none`}
                    />
                    <div className='horizontalSeparator mt-5'>
                        <span>Or</span>
                    </div>
                    <Button
                        label={loadingDownload ? 'Loading data...' : 'All Invoices'}
                        dataQa={loadingDownload ? 'loading-data...' : 'all-invoices'}
                        onClickButton={() => !loadingDownload && onDownloadTemplate()}
                        disabled={loadingDownload}
                        className={`${loadingDownload ? 'btn-main-disable' : 'btn-main'} mb-2 mt-9 !py-2 !w-[100%] !shadow-none`}
                    />
                    <CSVLink
                        ref={csvLink}
                        data={invoiceCsvData && invoiceCsvData?.length > 0 ? invoiceCsvData?.filter((item, idx) => idx > 0) || [] : []}
                        headers={invoiceCsvData?.[0] || []}
                        className={'none'}
                        target={'_blank'}
                        asyncOnClick={true}
                        filename={`Invoices-data-sample.csv`}
                    >
                    </CSVLink>
                </div>
            </div>
        </Modal >
    )
}
export default DownloadInvoicesModal;