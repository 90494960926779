import React from 'react';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IAdminPricingFilters {
    markets?: Array<any>;
    partners?: Array<any>;
    sectors?: Array<any>;
    selectedMarket?: any;
    selectedPartner?: any;
    selectedSector?: any;
    onChangeSelectedFilter: (e: any, name?: string) => void;
}

const AdminPricingFilters = ({
    markets,
    partners,
    sectors,
    selectedMarket,
    selectedPartner,
    selectedSector,
    onChangeSelectedFilter
}: IAdminPricingFilters) => {

    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
            <SelectCheckbox
                name='Market'
                placeholder=' '
                dataQa={'market-selected'}
                containerStyle='mb-1 min-w-full'
                selectedOption={selectedMarket}
                onChangeSelectedOption={onChangeSelectedFilter}
                uniqueName={'market'}
                options={markets || []}
            />
            <SelectCheckbox
                name='Partner'
                placeholder=' '
                dataQa={'partner-selected'}
                containerStyle='mb-1 min-w-full'
                selectedOption={selectedPartner}
                onChangeSelectedOption={onChangeSelectedFilter}
                uniqueName={'partner'}
                options={partners || []}
            />
            <SelectCheckbox
                name='Category'
                placeholder=' '
                dataQa={'category-selected'}
                containerStyle='mb-1 min-w-full'
                selectedOption={selectedSector}
                onChangeSelectedOption={onChangeSelectedFilter}
                uniqueName={'sector'}
                options={sectors || []}
            />
        </div>
    )
}
export default AdminPricingFilters