import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import {
    tryToFetchRMADetails,
    tryToReviewReturn
} from '../../../store/brokers/admin/rma/RMAsSlice';
import ActionCheckModal from './modals/ActionCheckModal';
import RejectReturnModal from './modals/RejectReturnModal';
import RmaDetailsTable from './tables/RmaDetailsTable';
import RevalueModal from './modals/RevalueModal';
import Toast from '../../../shared/toast';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import Loading from '../../../shared/Loading';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import moment from 'moment';
import Input from '../../../shared/input';


const RMAManagementDetails = () => {
    const { id } = useParams() || { id: '' };
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [pageAccess, setPageAccess] = useState<any>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: undefined,
    });
    const [rows, setRows] = useState<any[]>([]);
    const [RMADetails, setRMADetails] = useState<any>();
    const [sectors, setSectors] = useState<IAssetTemplate[]>([]);

    const [showActionCheckModal, setShowActionCheckModal] = useState<{
        show: boolean;
        text: JSX.Element;
        title: string;
        type: string;
        error: boolean;
        maxAmount?: number;
    }>();
    const [loadingAction, setLoadingAction] = useState(false)
    const [showToast, setShowToast] = useState<IToast | null>();
    const [itemId, setItemId] = useState<string>()
    const rowsLoading = useAppSelector((state) => state?.RMAs.RMAIsLoading);
    const paginationData = useAppSelector(
        (state) => state?.RMAs?.RMADetails?.page,
    );
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.assetsTemplates);
    const [markets, setMarkets] = useState<IAllMarkets[]>();
    const [getAllMarkets] = useGetAllMarketsMutation();

    const getRMADetails = async () => {
        if (id) {
            const resp = await dispatch(
                tryToFetchRMADetails({ id, paginationState }),
            ).unwrap();
            setRMADetails({
                ...resp?.data?.rma,
                actionsNeeded: resp?.data?.actionsNeeded,
                totalInitialPrice: resp?.data?.totalInitialPrice,
                totalFinalPrice: resp?.data?.totalFinalPrice,
                totalCreditAmount: resp?.data?.totalCreditAmount
            });
            setRows(resp?.data?.elements);
        }
    };
    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    };
    const getCategoryIcon = (category: string) => {
        const res = sectors.find((sector) => sector.name === category);
        return res?.disabledIcon;
    };
    const getMarketIcon = () => {
        const res =
            markets &&
            markets?.length > 0 &&
            markets?.find((market: IAllMarkets) => market?._id === RMADetails?.marketId);
        if (res)
            return res.flagUrl
    };
    const getMarketDisplayName = () => {
        const res =
            markets &&
            markets?.length > 0 &&
            markets?.find((market: IAllMarkets) => market?._id === RMADetails.marketId);
        if (res)
            return res.label
    };

    const getMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap();
        const formatMarkets =
            marketResponse &&
            marketResponse?.length > 0 &&
            marketResponse?.map((market: IAllMarkets) => ({
                ...market,
                value: market?._id,
                displayName: market?.name,
            }));
        setMarkets(formatMarkets || []);
    };

    const onGoBack = () => {
        navigate(-1)
    }

    const onChangeSearchValue = (
        value: string | null | undefined,
        type: string,
    ) => {
        setPaginationState({
            ...(paginationState || {}),
            pageNumber: 1,
            filters: value || undefined,
        });
    };

    const onToggleActionModal = (type?: string, item?: any) => {
        if (type) {
            setItemId(item?.item?._id)
            if (type === 'accept-return') {
                setShowActionCheckModal({
                    show: true,
                    text: <p>Are you sure you want to <span className='text-[#00B67A]'>Accept</span> the return of the <span className='font-dm_sansbold'>{item?.item?.itemName}</span> with id  <span className='font-dm_sansbold'>{item?.item?._id}</span>? <br /><br /> This action cannot be undone!</p>,
                    title: 'Accept Return',
                    error: true,
                    type: 'accept-return',
                });
            }
            if (type === 'reject-return') {
                setShowActionCheckModal({
                    show: true,
                    text: <p>Are you sure you want to <span className='text-[#E50000]'>Reject</span> the return of the <span className='font-dm_sansbold'>${item?.item?.itemName}</span> with id <span className='font-dm_sansbold'>{item?.item?._id}</span>? <br /><br /> This action cannot be undone!`</p>,
                    title: 'Reject Return',
                    error: true,
                    type: 'reject-return',
                });
            }
            if (type === 'revalue-item') {
                setShowActionCheckModal({
                    show: true,
                    text: <p>Are you sure you want to <span className='text-[#00B67A]'>Revalue</span> the return of the <span className='font-dm_sansbold'>{item?.item?.itemName}</span> with id  <span className='font-dm_sansbold'>{item?.item?._id}</span>? <br /><br /> This action cannot be undone!</p>,
                    title: 'Revalue item',
                    error: true,
                    type: 'revalue-item',
                    maxAmount: Number(item.price)
                });
            }
        } else {
            setShowActionCheckModal(undefined)
        }
    }

    const onAcceptReturn = async () => {
        try {
            itemId && await dispatch(tryToReviewReturn({ itemId, type: 'approve' })).unwrap()
            setShowToast({
                type: 'success',
                message: 'Return was accepted successfully.',
            });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onToggleActionModal();
        getRMADetails()
        setLoadingAction(false)
    }

    const onRejectReturn = async (rejectReason: string) => {
        try {
            itemId && await dispatch(tryToReviewReturn({ itemId, type: 'reject', rejectReason })).unwrap()
            setShowToast({
                type: 'success',
                message: 'Return was rejected successfully.',
            });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onToggleActionModal();
        getRMADetails()
        setLoadingAction(false)
    }

    const onApproveActionCheck = (action: string) => {
        setLoadingAction(true)
        switch (action) {
            case 'accept-return':
                onAcceptReturn();
                break;
        }
    }

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Return Merchandise Authorization')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'RMAManagementDetails')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        getMarketData()
    }, [])

    useEffect(() => {
        if (state.allAssetTemplates) {
            const rows =
                state.allAssetTemplates &&
                    Array.isArray(state.allAssetTemplates)
                    ? state.allAssetTemplates?.map((r) => ({
                        ...(r || {}),
                        name: r?.name,
                        label: r?.displayName,
                        value: r?._id,
                    }))
                    : [];
            setSectors(
                rows?.length > 0
                    ? rows?.filter(
                        (r) => (r?.name || '').toLowerCase() !== 'currencies',
                    )
                    : [],
            );
        } else {
            dispatch(tryToFetchAllAssetsTemplates());
        }
    }, [state.allAssetTemplates]);


    useEffect(() => {
        getRMADetails()
    }, [id, paginationState])

    const onRevalueItem = async (amount: number) => {
        try {
            itemId && await dispatch(tryToReviewReturn({ itemId, type: 'revalue', revaluePrice: Number(amount) })).unwrap()
            setShowToast({
                type: 'success',
                message: 'Item was revalued successfully.',
            });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onToggleActionModal();
        getRMADetails()
        setLoadingAction(false)
    }
    if (!RMADetails)
        return (
            <div className="w-full h-full  md:min-h-[calc(100vh-12rem)] flex justify-center items-center">
                <Loading />
            </div>
        );
    return (
        <div>
            <div className={'flex flex-row items-center'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back-circle.svg'} className={'w-6 object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Submitted RMA Details</p>
                </div>
            </div>
            <div className="w-full bg-white flex justify-start items-center p-6 rounded-xl gap-11">
                <div
                    className={`px-3 py-2 rounded-[10px] border ${RMADetails?.status === 'created'
                        ? 'text-[#F7A21E] border-[#F7A21E]'
                        : 'text-[#00B67A] border-[#00B67A]'
                        }`}>
                    {capitalizeFirstLetter(RMADetails.status)}
                </div>
                <div className="flex flex-col">
                    <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
                        RMA ID
                    </span>
                    <span className="text-[#202020] text-sm">{id}</span>
                </div>
                <div className="flex flex-col">
                    <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
                        Category
                    </span>
                    <div className="flex gap-1 items-center">
                        {RMADetails.categories?.slice(0, 2).map((category: string, index: number) => <img key={index} className='w-6 h-6' src={getCategoryIcon(category)} alt={category} title={category} />)}
                        {RMADetails?.categories?.length > 2 && (
                            <span className="ml-1 hover:cursor-pointer relative" title={RMADetails?.categories?.slice(2).join(', ')}>
                                ...
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
                        Market
                    </span>
                    <span className="text-[#202020] text-sm flex gap-1 items-center">
                        <img src={getMarketIcon()} alt={getMarketDisplayName()} />
                        {getMarketDisplayName()}
                    </span>
                </div>
                <div className="flex flex-col">
                    <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
                        Created Date
                    </span>
                    <span className="text-[#202020] text-sm">
                        {moment(RMADetails?.createdAt).format(
                            'MMMM Do YYYY, h:mm:ss a',
                        )}
                    </span>
                </div>
            </div>
            <div className="my-5 bg-[#F7A21E1A] rounded-[10px] p-2 flex flex-row justify-end items-center">
                <Input
                    placeholder={'Filter Table'}
                    containerStyle="mb-0 pb-0 relative flex-initial w-96"
                    inputStyle={`mb-0 border-0 focus:outline-none`}
                    icon={'/assets/shared/search.svg'}
                    showValue={true}
                    inputValue={paginationState?.filters}
                    inputUniqueName={'search'}
                    onChangeInput={onChangeSearchValue}
                    hideInputSpace={true}
                    dataQa={'filter-table'}
                />
            </div>
            <RmaDetailsTable
                rows={rows}
                rowsLoading={rowsLoading}
                paginationData={paginationData}
                setPaginationState={setPaginationState}
                sectors={sectors}
                pageAccess={pageAccess}
                accessControl={accessControl}
                markets={markets || []}
                RMADetails={RMADetails}
                onToggleActionModal={onToggleActionModal}
            />
            <ActionCheckModal
                openMarketStatusModal={(showActionCheckModal?.type === 'accept-return' && showActionCheckModal?.show) || false}
                text={showActionCheckModal?.text || ''}
                title={showActionCheckModal?.title || ''}
                errorType={showActionCheckModal?.error || false}
                action={showActionCheckModal?.type || ''}
                loading={loadingAction}
                handleCloseModal={onToggleActionModal}
                onApproveAction={onApproveActionCheck}
            />
            {showActionCheckModal?.type === 'reject-return' &&
                <RejectReturnModal openModal={showActionCheckModal?.show || false}
                    text={showActionCheckModal?.text || ''}
                    title={showActionCheckModal?.title || ''}
                    rejectLoading={loadingAction}
                    handleCloseModal={() => onToggleActionModal()}
                    onSubmitSuccessfully={onRejectReturn}
                />
            }
            {showActionCheckModal?.type === 'revalue-item' &&
                <RevalueModal
                    openModal={showActionCheckModal?.show || false}
                    text={showActionCheckModal?.text || ''}
                    title={showActionCheckModal?.title || ''}
                    revalueLoading={loadingAction}
                    handleCloseModal={() => onToggleActionModal()}
                    onSubmitSuccessfully={onRevalueItem}
                    maxAmount={showActionCheckModal.maxAmount || 0} />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    )
}

export default RMAManagementDetails