import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    ICurrencySettings,
    IParseCurrency
} from '../../../../interfaces/curreny-settings/ICurrencySettings';
import { tryToFindOperatingMarkets } from '../../../../store/brokers/admin/partners/partnersSlice';
import {
    tryToConvertCurrency,
} from '../../../../store/brokers/partners/currency-settings/currencySettingsSlice';
import ChangePricesSuccessfully from '../details/ChangePricesSuccessfully';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IConvertOnceModal {
    categories: Array<any>;
    openModal: boolean;
    handleCloseModal: () => void;
}

const ConvertOnceModal = ({
    categories,
    openModal,
    handleCloseModal,
}: IConvertOnceModal) => {
    const dispatch = useAppDispatch();
    const [activeStep, setActiveStep] = useState<number>(1)
    const [generalError, setGeneralError] = useState<string>()
    const [baseCurrency, setBaseCurrency] = useState<any>()
    const [selectedCurrencies, setSelectedCurrencies] = useState<any>()
    const [selectedCategories, setSelectedCategories] = useState<any>()
    const [currencySettings, setCurrencySettings] = useState<ICurrencySettings>({ parseCurrency: [] })
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)
    const state = useAppSelector((state) => state.partners);

    const getPartnerCurrencies = () => {
        const rows = state.partnerMarkets && Array.isArray(state.partnerMarkets)
            ? state.partnerMarkets.map((r: any) => {
                const currency = {
                    flag: r.market.flagUrl,
                    name: r.currency.descriptor?.name,
                    active: false,
                    id: r.currency._id,
                }
                if (r.currency?.descriptor?.name === 'EUR') {
                    currency.flag = '/assets/pricing/european-flag.svg'
                }
                return currency
            })
            : [];
        const removedDuplicates: any = []
        rows?.map((item: any) => {
            const findIfRemovedDuplicateExist = removedDuplicates?.find((obj: any) => obj?.name === item?.name)
            if (!findIfRemovedDuplicateExist && item?.id !== baseCurrency?.id) {
                removedDuplicates.push(item)
            }
            return item
        })
        setCurrencySettings({
            parseCurrency: removedDuplicates
        })
    }


    useEffect(() => {
        dispatch(tryToFindOperatingMarkets(null)).unwrap()
    }, [])

    useEffect(() => {
        getPartnerCurrencies()
    }, [state.partnerMarkets])

    useEffect(() => {
        if (baseCurrency) {
            getPartnerCurrencies()
        }
    }, [baseCurrency])

    const onCloseModal = () => {
        handleCloseModal();
        setBaseCurrency(undefined)
        setSelectedCurrencies(undefined)
        setSelectedCategories(undefined)
        setActiveStep(1)
    }

    const onSubmit = async () => {
        if (!baseCurrency || !selectedCategories) {
            setGeneralError('Please make sure you select a base currency and categories.')
            return;
        }
        if (!selectedCurrencies || selectedCurrencies?.length <= 0) {
            setGeneralError('Please ake sure you select currencies to convert.')
            return;
        }
        setLoadingUpdate(true)
        const formatAssetTemplates = selectedCategories && selectedCategories?.length > 0 && selectedCategories?.map((item: any) => item?.value)
        const formatParseCurrency = selectedCurrencies && selectedCurrencies?.length > 0 && selectedCurrencies?.map((item: any) => item?.id)
        const data = {
            assetTemplates: formatAssetTemplates,
            baseCurrency: baseCurrency?.id,
            parseCurrency: formatParseCurrency
        }
        try {
            await dispatch(tryToConvertCurrency(data)).unwrap()
            setActiveStep(2)
            setGeneralError(undefined)
        } catch (err: any) {
            setGeneralError(`${err || 'Something went wrong!'}`)
        }
        setLoadingUpdate(false)
    }

    const onChangeSelectedBaseCurrency = (e: any) => {
        setBaseCurrency(e)
    }

    const onToggleActiveCurrency = ((currency: any) => {
        const findIfAlreadyChecked = selectedCurrencies && selectedCurrencies?.length > 0 && selectedCurrencies?.find((item: any) => item?.id === currency?.id)
        if (findIfAlreadyChecked) {
            const filterCheckedCurrencies = selectedCurrencies && selectedCurrencies?.length > 0 && selectedCurrencies?.filter((item: any) => item?.id !== currency?.id)
            setSelectedCurrencies(filterCheckedCurrencies || [])
        } else {
            setSelectedCurrencies([
                ...selectedCurrencies || [],
                currency || {}
            ])
        }
    })

    const onChangeSelectedCategories = (value: any, type?: string) => {
        setSelectedCategories(value)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle={'!min-w-[470px] min-h-[350px]'}
        >
            <div className={'p-4 !min-w-[100%] !min-h-[inherit] flex flex-col justify-between'}>
                {activeStep === 1
                    ? <>
                        <div>
                            <p className='mb-4 font-semibold text-sm'>Convert Once</p>
                            <p className='font-bold text-md mb-4'>Use this form to convert currencies from base currency immediately</p>
                            {generalError && <Error text={generalError} />}
                        </div>
                        <div className='mt-5'>
                            <div>
                                <SelectCheckbox
                                    name={'Categories'}
                                    dataQa={'categories'}
                                    multiple={true}
                                    placeholder=' '
                                    containerStyle='mb-4 max-w-[90%]'
                                    selectedOption={selectedCategories}
                                    options={categories}
                                    onChangeSelectedOption={onChangeSelectedCategories}
                                />
                                <SelectCheckbox
                                    name={'Currency'}
                                    dataQa={'currency'}
                                    placeholder={' '}
                                    selectedOption={baseCurrency}
                                    options={
                                        currencySettings?.parseCurrency?.map((currency: IParseCurrency) => {
                                            return { ...currency, label: currency.name, value: currency.id }
                                        }) || []
                                    }
                                    containerStyle={'mb-3 max-w-[90%]'}
                                    selectStyle={{ backgroundColor: 'transparent', marginBottom: 0, borderRadius: '4px' }}
                                    onChangeSelectedOption={onChangeSelectedBaseCurrency}
                                    uniqueName={'currency'}
                                />
                                {currencySettings.parseCurrency &&
                                    <div className='flex flex-col items-center mt-4'>
                                        <div className='grid grid-cols-2 mb-6 items-center gap-4 w-[90%]'>
                                            <p className='font-medium'>Currency</p>
                                            <p className='font-medium text-center'>Convert</p>
                                        </div>
                                    </div>
                                }
                                <div className='flex flex-col items-center'>
                                    {currencySettings?.parseCurrency && currencySettings?.parseCurrency?.map((currency: any, index: any) => {
                                        const findIfSelected = selectedCurrencies && selectedCurrencies?.length > 0 && selectedCurrencies?.find((item: any) => item?.id === currency?.id)
                                        return (
                                            <div key={index} className='grid grid-cols-2 mb-6 items-center gap-4 w-[90%]' >
                                                <div className='flex flex-row items-center'>
                                                    <img src={currency.flag} alt='Currency flag' className='w-[30px] mr-3' />
                                                    <p>{currency.name}</p>
                                                </div>
                                                <div className='flex flex-row justify-center'>
                                                    <div
                                                        data-qa={'checkbox-type'}
                                                        className={'min-w-[100px] flex justify-center cursor-pointer gap-2'}
                                                        onClick={() => onToggleActiveCurrency(currency)}>
                                                        <img
                                                            src={findIfSelected ? '/assets/pricing/checkbox-active.svg' : '/assets/pricing/checkbox1.svg'}
                                                            alt='checkbox'
                                                            className={findIfSelected ? 'w-[20px]' : 'min-w-[20px]'} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='mb-5'>
                        </div>
                        <div className='min-w-full flex flex-row justify-center my-3 '>
                            <Button
                                label={'Convert Now'}
                                dataQa={'convert-now'}
                                className={`${loadingUpdate ? 'btn-main-disable' : 'btn-main'} !rounded flex flex-row mr-4 items-center justify-center min-w-[150px] !shadow-none`}
                                onClickButton={() => !loadingUpdate && onSubmit()}
                            />
                        </div>
                    </>
                    : <>
                        <ChangePricesSuccessfully
                            text={'Convert started. You will receive a confirmation message to your email address once the conversion is complete.'}
                            handleCloseModal={onCloseModal} />
                    </>
                }
            </div>
        </Modal >
    )
}

export default ConvertOnceModal;