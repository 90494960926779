import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IWarehouseField } from '../../../../interfaces/warehouses/IWarehouseField';
import {
    tryToAddWarehouseLocation,
    tryToEditWarehouseLocation,
    tryToFetchWarehouseSingleLocation
} from '../../../../store/brokers/admin/warehouses/warehousesSlice';
import Loading from '../../../../shared/Loading';
import Button from '../../../../shared/button';
import Select from '../../../../shared/select';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';


interface IWarehouseLocationModal {
    id: string;
    locationId?: string;
    openModal: boolean;
    warehouseFieldsData?: IWarehouseField[];
    handleCloseModal: () => void;
}

const WarehouseLocationModal = ({
    id,
    locationId,
    warehouseFieldsData,
    openModal,
    handleCloseModal
}: IWarehouseLocationModal) => {
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<any>();
    const [formErrors, setFormErrors] = useState<any>();
    const [generalError, setGeneralError] = useState<string>();
    const [warehouseFields, setWarehouseFields] = useState<IWarehouseField[]>(warehouseFieldsData || []);
    const stateWarehouseFields = useAppSelector((state) => state.warehouses);
    const locationDetails = useAppSelector((state) => state.warehouses?.warehouseLocationDetails);
    const locationLoading = useAppSelector((state) => state.warehouses?.warehouseLocationIsLoading)

    const onCloseModal = () => {
        setFormErrors(undefined)
        setGeneralError(undefined)
        setFormData(undefined)
        setWarehouseFields([])
        handleCloseModal()
    }

    const onGettingLocationData = async () => {
        if (id && locationId) {
            const data = {
                warehouseId: id,
                locationId
            }
            dispatch(tryToFetchWarehouseSingleLocation(data))
        }
    }

    useEffect(() => {
        onGettingLocationData()
    }, []);

    useEffect(() => {
        onGettingLocationData()
    }, [id, locationId, openModal]);

    useEffect(() => {
        if (locationDetails && locationId) {
            setFormData(locationDetails?.descriptor)
        }
    }, [locationDetails])

    useEffect(() => {
        if (warehouseFieldsData && !locationId) {
            setWarehouseFields(warehouseFieldsData)
        }
    }, [warehouseFieldsData])

    useEffect(() => {
        if (stateWarehouseFields.warehouse && locationId) {
            const rows =
                stateWarehouseFields?.warehouse?.locations &&
                    Array.isArray(stateWarehouseFields?.warehouse?.locations)
                    ? stateWarehouseFields?.warehouse?.locations
                        .map((r) => ({ ...r, id: r.id }))
                    : [];
            setWarehouseFields(rows);
        }
    }, [stateWarehouseFields.warehouse]);

    const onSubmit = async () => {
        const formatDescriptor = formData;
        const data: any = {
            warehouseId: id || '',
            descriptor: formatDescriptor,
        };
        try {
            if (locationId) {
                data.locationId = locationId || ''
                await dispatch(tryToEditWarehouseLocation(data)).unwrap()
            } else {
                await dispatch(tryToAddWarehouseLocation(data)).unwrap();
            }
            onCloseModal()
        } catch (err) {
            setGeneralError(`${err}`);
        }
    };

    const onChangeInput = (value: string | null | undefined, type: string) => {
        const findField = warehouseFields?.find((field) => field?.name === type);
        setFormData({
            ...(formData || {}),
            [type]:
                findField?.type === 'number' ? Number(value || 0) : value,
        });
    };

    const onChangeSelectedValue = (e: any, type: string) => {
        const findField = warehouseFields?.find((field) => field?.name === type);
        setFormData({
            ...(formData || {}),
            [type]: findField?.type === 'boolean' ? e?.target?.value === 'true' : e?.target?.value || undefined,
        });
    };

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[600px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>{locationId ? 'Edit Location' : 'Create Location'}</p>
                    {generalError && <Error text={generalError} />}
                </div>
                <div>
                    {(locationLoading && locationId) ?
                        <div className='flex flex-row justify-center items-center my-5'>
                            <Loading />
                        </div>
                        : <div className='grid grid-cols-4 gap-4 mt-6'>
                            {warehouseFields?.length > 0 &&
                                warehouseFields?.map((field, idx) => {
                                    let values: any = field?.values;
                                    if (field?.type === 'boolean') {
                                        values = [
                                            { value: true, label: 'True' },
                                            { value: false, label: 'False' },
                                        ];
                                    } else if (field?.type === 'list') {
                                        values =
                                            field?.values &&
                                            field?.values?.length > 0 &&
                                            values?.map((value: string) => {
                                                return { value: value, label: value };
                                            });
                                    }
                                    return (
                                        <>
                                            {field?.type === 'number' ||
                                                field?.type === 'string' ? (
                                                <Input
                                                    key={idx}
                                                    containerStyle='mb-0'
                                                    placeholder={field?.name}
                                                    showValue={true}
                                                    type={field?.type}
                                                    inputValue={formData?.[field?.name]}
                                                    error={formErrors?.[field?.name] || ''}
                                                    onChangeInput={onChangeInput}
                                                    inputUniqueName={field?.name}
                                                />
                                            ) : (
                                                <Select
                                                    key={idx}
                                                    placeholder={field?.name}
                                                    containerStyle='mb-1'
                                                    value={formData?.[field?.name] || null}
                                                    showValue={true}
                                                    onChangeSelectValue={(e) =>
                                                        onChangeSelectedValue(
                                                            e,
                                                            field?.name,
                                                        )
                                                    }
                                                    options={values}
                                                    error={formErrors?.[field?.name] || ''}
                                                />
                                            )}
                                        </>
                                    );
                                })}
                        </div>
                    }
                    <div className='flex flex-row justify-end my-6'>
                        <Button
                            label='Discard'
                            className='!rounded-[4px] btn-categories min-w-[130px] '
                            onClickButton={onCloseModal}
                        />
                        <Button
                            label='Save'
                            onClickButton={() => onSubmit()}
                            className={
                                '!shadow-none btn-main min-w-[150px] mr-4'
                            }
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default WarehouseLocationModal;
