import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IPartnersMarket, IPartnersSector } from '../../../interfaces/partners/IPartners';
import {
    tryToCreateRetailers,
    tryToEditRetailers,
    tryToFetchSingleRetailers
} from '../../../store/brokers/admin/retailers/RetailersSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import Input from '../../../shared/input';
import Error from '../../../shared/error';
import Button from '../../../shared/button';
import SelectCheckbox from '../../../shared/select-checkbox';


type FormValues = {
    name: string;
    email: string;
    username: string;
    password: string;
    country: string;
    companyName: string;
    companyRegistrationNumber: string;
    vatNumber: string;
    annualGMV?: string;
    phoneNumber: string;
    sectors: IPartnersSector[];
    markets: IPartnersMarket[];
    confirmPassword: string;
    url?: string;
};

const CreateRetailer = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [createError, setCreateError] = useState<string | null>()
    const [sectorItems, setSectorItems] = useState<Array<any>>([])
    const [marketItems, setMarketItems] = useState<Array<any>>([])
    const [selectedSectors, setSelectedSectors] = useState<any>()
    const [selectedMarkets, setSelectedMarkets] = useState<any>()
    const [fileSelected, setFileSelected] = useState<any>()
    const [getAllMarkets] = useGetAllMarketsMutation()
    const state = useAppSelector((state) => state?.assetsTemplates);
    const retailer = useAppSelector((state) => state?.retailers?.retailer);

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const getMarketsData = async () => {
        const response = await getAllMarkets(null).unwrap()
        const formatMarkets = response && response?.length > 0 && response?.map((market: any) => ({ ...market, value: market?._id }))
        setMarketItems(formatMarkets || [])
    }

    useEffect(() => {
        dispatch(tryToFetchAllAssetsTemplates());
        getMarketsData()
    }, []);

    useEffect(() => {
        if (state.allAssetTemplates) {
            const rows =
                state.allAssetTemplates && Array.isArray(state.allAssetTemplates)
                    ? state.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setSectorItems(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [state.allAssetTemplates]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue
    } = useForm<FormValues>({});

    const getRetailerDetailsData = async () => {
        if (id) {
            dispatch(tryToFetchSingleRetailers(id)).unwrap()
        } else {
            setSelectedMarkets(undefined)
            setSelectedSectors(undefined)
            setCreateError(undefined)
            setFileSelected(undefined)
            reset()
        }
    }

    const onSetRetailerMarketsAndSectors = () => {
        if (retailer && marketItems && marketItems?.length > 0 && sectorItems && sectorItems?.length > 0 && id) {
            id && reset(retailer)
            const findMarkets = retailer?.markets && retailer?.markets?.length > 0 && retailer?.markets?.map((market) => {
                const findDetails = marketItems?.length > 0 && marketItems?.find((item) => item?._id === market?.id)
                return {
                    label: findDetails?.label,
                    value: findDetails?._id,
                }
            })
            const findSectors = (retailer?.sectors && retailer?.sectors?.length > 0) && retailer?.sectors?.map((sector) => {
                const findDetails = sectorItems?.length > 0 && sectorItems?.find((item) => item?._id === sector?.id)
                return {
                    label: findDetails?.label,
                    value: findDetails?._id,
                }
            })
            setSelectedMarkets(findMarkets || [])
            setSelectedSectors(findSectors || [])
        } else {
            reset()
        }
    }

    useEffect(() => {
        getRetailerDetailsData()
    }, [])

    useEffect(() => {
        getRetailerDetailsData()
        onSetRetailerMarketsAndSectors()
    }, [id])

    useEffect(() => {
        onSetRetailerMarketsAndSectors()
    }, [retailer, marketItems, sectorItems])

    const onSubmit = async (data: FormValues) => {
        if (!(fileSelected || retailer?.picture)) {
            setCreateError('Image is required.')
            return;
        }
        const filterSectors = selectedSectors?.map((sector: any) => {
            return { name: sector?.label, id: sector?.value }
        })
        const formatMarkets = selectedMarkets && selectedMarkets?.length > 0 && selectedMarkets?.map((market: any) => ({ id: market?.value }))
        const formData = new FormData()
        formData.append('username', (data?.username || '').toLowerCase())
        formData.append('name', data?.name)
        formData.append('email', data?.email)
        formData.append('country', data?.country)
        data?.url && formData.append('url', data?.url)
        formData.append('companyName', data?.companyName)
        data?.annualGMV && formData.append('annualGMV', data?.annualGMV)
        formData.append('companyRegistrationNumber', data?.companyRegistrationNumber)

        formData.append('vatNumber', data?.vatNumber)
        formData.append('phoneNumber', data?.phoneNumber)
        formData.append('markets', JSON.stringify(formatMarkets))
        formData.append('sectors', JSON.stringify(filterSectors))

        if (fileSelected) {
            formData.append('file', fileSelected)
        }
        try {
            if (id) {
                await dispatch(tryToEditRetailers({ id: id || '', data: formData })).unwrap();
            } else {
                formData.append('password', data?.password)
                await dispatch(tryToCreateRetailers(formData)).unwrap();
            }
            reset();
            navigate('/users');
            setCreateError(null)
        } catch (error) {
            setCreateError(`${error}`)
        }
    };

    const onChangeSelectedOption = (e: any) => {
        if (id) {
            let findIfAnyFixedIsMissing = false
            selectedSectors?.filter((sector: any) => sector?.isFixed)?.map((sector: any) => {
                const findNewSelected = e?.length > 0 && e?.find((newSelected: any) => newSelected?.value === sector?.value)
                if (!findNewSelected) findIfAnyFixedIsMissing = true
                return sector;
            })
            if (findIfAnyFixedIsMissing) {
                return;
            }
        }
        setSelectedSectors(e?.length > 0 ? e?.map((s: any) => ({ ...s })) : [])
        setValue('sectors', e?.length > 0 ? e?.map((s: any) => s?.value) : [])
    }

    const onChangeSelectedMarket = (e: any) => {
        if (id) {
            let findIfAnyFixedIsMissing = false
            selectedMarkets?.filter((market: any) => market?.isFixed)?.map((market: any) => {
                const findNewSelected = e?.length > 0 && e?.find((newSelected: any) => newSelected?.value === market?.value)
                if (!findNewSelected) findIfAnyFixedIsMissing = true
                return market;
            })
            if (findIfAnyFixedIsMissing) {
                return;
            }
        }
        setSelectedMarkets(e?.length > 0 ? e?.map((s: any) => ({ ...s })) : [])
        setValue('markets', e?.length > 0 ? e?.map((s: any) => s?.value) : [])
    }

    const onGoBack = () => {
        navigate('/users?type=retailers')
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>{id ? 'Edit Retailer' : 'Create Retailer'}</p>
                </div>
            </div>
            <div className='mt-5 mb-2'>
                {createError && <Error text={createError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-3 gap-4 content-center'>
                        <Input
                            label='Name'
                            dataQa={'create-retailer-name'}
                            disabled={!!id}
                            register={register('name', {
                                required: {
                                    message: 'Name is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Name is required'
                            })}
                            error={errors.name?.message}
                        />
                        <Input
                            label='Username'
                            dataQa={'create-retailer-username'}
                            disabled={!!id}
                            register={register('username', {
                                required: {
                                    message: 'Username is required',
                                    value: true,
                                },
                                pattern: {
                                    /* eslint-disable-next-line */
                                    value: /^\S*$/,
                                    message: 'Username should not include spaces'
                                },
                                validate: (value: string) => !!value.trim() || 'Username is required'
                            })}
                            error={errors.username?.message}
                        />
                        <Input
                            label='Email'
                            dataQa={'create-retailer-email'}
                            disabled={!!id}
                            register={register('email', {
                                required: {
                                    message: 'Email is required',
                                    value: true,
                                },
                                pattern: {
                                    /* eslint-disable-next-line */
                                    value: /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please write a valid email'
                                },
                                validate: (value: string) => !!value.trim() || 'Email is required'
                            })}
                            error={errors.email?.message}
                        />
                        <Input
                            label='Password'
                            dataQa={'create-retailer-password'}
                            disabled={!!id}
                            type={'password'}
                            register={register('password', {
                                required: {
                                    message: 'Password is required',
                                    value: !id,
                                },
                                minLength: {
                                    value: 8,
                                    message: 'Minimum number of characters is 8'
                                },
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                    message: 'Password should be at least 8 characters long, and must contain at least 1 number, 1 lower case, 1 upper case and 1 special character(@$!%*?&)'
                                },
                                validate: (value: string) => id ? true : !!value.trim() || 'Password is required and min length is 8'
                            },
                            )}
                            error={errors.password?.message}
                        />
                        <Input
                            label='Repeat Password'
                            dataQa={'create-retailer-repeat-password'}
                            disabled={!!id}
                            type={'password'}
                            register={register('confirmPassword', {
                                required: {
                                    message: 'Repeat password is required',
                                    value: !id,
                                },
                                validate: (val: string) => {
                                    if (watch('password') !== val) {
                                        return 'Repeat password do not match';
                                    }
                                }
                            })}
                            error={errors.confirmPassword?.message}
                        />
                        <Input
                            label='Company Name'
                            dataQa={'create-retailer-company-name'}
                            register={register('companyName', {
                                required: {
                                    message: 'Company Name is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Company Name is required'
                            })}
                            error={errors.companyName?.message}
                        />
                        <Input
                            label='Country'
                            dataQa={'create-retailer-country'}
                            register={register('country', {
                                required: {
                                    message: 'Country is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Country is required'
                            })}
                            error={errors.country?.message}
                        />
                        <Input
                            label='Retailer URL'
                            dataQa={'create-retailer-url'}
                            register={register('url')}
                            error={errors.url?.message}
                        />
                        <Input
                            label='Company Registration Number'
                            dataQa={'create-retailer-registration-number'}
                            register={register('companyRegistrationNumber', {
                                required: {
                                    message: 'Company Registration Number is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Company Registration Number is required'
                            })}
                            error={errors.companyRegistrationNumber?.message}
                        />
                        <Input
                            label='VAT Number'
                            dataQa={'create-retailer-VAT'}
                            register={register('vatNumber', {
                                required: {
                                    message: 'VAT Number is required',
                                    value: true,
                                },
                                minLength: {
                                    value: 8,
                                    message: 'Minimum number of characters is 8'
                                },
                                validate: (value: string) => !!value.trim() || 'VAT Number is required'
                            })}
                            error={errors.vatNumber?.message}
                        />
                        <Input
                            label='Annual Turnover'
                            dataQa={'create-retailer-GMV'}
                            register={register('annualGMV')}
                            error={errors.annualGMV?.message}
                        />
                        <Input
                            label='Phone Number'
                            dataQa={'create-retailer-phone-number'}
                            register={register('phoneNumber', {
                                required: {
                                    message: 'Phone Number is required',
                                    value: true,
                                },
                                pattern: {
                                    value: /^\+[0-9 ]+$/,
                                    message: 'Please write a valid number ex. +123456789'
                                },
                                validate: (value: string) => !!value.trim() || 'Phone Number is required'
                            })}
                            error={errors.phoneNumber?.message}
                        />
                        <SelectCheckbox
                            name='Category'
                            placeholder=' '
                            onChangeSelectedOption={onChangeSelectedOption}
                            options={sectorItems}
                            selectedOption={selectedSectors}
                            dataQa={'create-retailer-category'}
                            multiple={true}
                            error={(Object.keys(errors).length !== 0 && (!watch('sectors') || watch('sectors')?.length <= 0)) ? 'Category is required' : undefined}
                        />
                        <SelectCheckbox
                            name='Market'
                            placeholder=' '
                            onChangeSelectedOption={onChangeSelectedMarket}
                            dataQa={'create-retailer-market'}
                            options={marketItems}
                            selectedOption={selectedMarkets}
                            multiple={true}
                            error={(Object.keys(errors).length !== 0 && (!watch('markets') || watch('markets')?.length <= 0)) ? 'Market is required' : undefined}
                        />
                    </div>
                    <div className='mb-6'>
                        <p className='mb-3 mt-4 font-medium'>Import retailer image:</p>
                        <div className='flex flex-col items-start' {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button
                                icon={<img alt='' src='/assets/shared/upload-file.svg' className='w-[30px] object-contains pr-2' />}
                                className={'btn-main !bg-[#202020] !text-white !py-1 !shadow-none flex flex-row items-center'}
                                label={'Select Image'}
                                dataQa={'select-image'}
                            />
                            {fileSelected && fileSelected?.name
                                ? <p className={'ml-3'}>{fileSelected?.name}</p>
                                :
                                <>
                                    {id && <img alt='' src={retailer?.picture} className={'w-[40px] my-3'} />}
                                </>
                            }
                        </div>
                    </div>
                    <div className='flex flex-row justify-end py-2'>
                        <Button
                            label={id ? 'Save Changes' : 'Create Retailer'}
                            type={'submit'}
                            className={'btn-primary min-w-[250px]'}
                            dataQa={'create-retailer-button'}
                        />
                    </div>
                </form>
            </div>
        </div >
    )
}

export default CreateRetailer;