import React, { useState } from 'react';
import { ISingleOrderSchema } from '../../../../interfaces/orders/IOrdersSchema';
import SellerAddressDetails from '../details/SellerAddressDetails';
import ReturnDetailsModal from '../modals/ReturnDetailsModal';
import WhiteContainer from '../../../../shared/white-container';
import Button from '../../../../shared/button';


interface IReturnedItemDetails {
    orderDetails?: ISingleOrderSchema;
}

const ReturnedItemDetails = ({
    orderDetails,
}: IReturnedItemDetails) => {
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false)

    const onToggleReturnedDetailsModal = () => {
        if (showDetailsModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowDetailsModal(!showDetailsModal)
    }

    return (
        <WhiteContainer containerStyle='!rounded'>
            <p className='font-semibold text-[20px] mb-3'>Return Details</p>
            <SellerAddressDetails
                orderDetails={orderDetails}
                bgColor='!bg-[#f8f9fc]'
            />
            <div className='flex flex-row justify-center my-3'>
                <Button
                    label='More Details'
                    className='btn-primary-text-underline !text-black'
                    onClickButton={onToggleReturnedDetailsModal}
                />
            </div>
            {showDetailsModal &&
                <ReturnDetailsModal
                    openModal={showDetailsModal}
                    returnTrackingNumber={orderDetails?.item?.returnTrackingNumber}
                    carrier={orderDetails?.item?.returnCarrier}
                    handleCloseModal={onToggleReturnedDetailsModal}
                />
            }
        </WhiteContainer>
    );
};

export default ReturnedItemDetails;
