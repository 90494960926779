import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToDownloadCashouts } from '../../../../store/brokers/admin/reports/reportsServiceSlice';
import DownloadDataModal from '../modals/DownloadDataModal';
import Button from '../../../../shared/button';


const CashoutsCategory = () => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [templateData, setTemplateData] = useState<any>();
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false)
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [downloadError, setDownloadError] = useState<string>()

    const onToggleDownloadModal = () => {
        setShowDownloadModal(!showDownloadModal)
        setDownloadError(undefined)
    }

    const onDownloadData = async (filters?: any) => {
        setLoadingDownload(true)
        try {
            const data = {
                dateRange: filters?.range,
                testCashouts: filters?.testCashouts
            }
            const response = await dispatch(tryToDownloadCashouts(data)).unwrap()
            setTemplateData(response || [])
            setDownloadError(undefined)
        } catch (err) {
            setDownloadError(`${err}`)
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (templateData && loadingDownload) {
            csvLink.current.link.click()
            onToggleDownloadModal()
        }
        setLoadingDownload(false)
    }, [templateData])

    return (
        <div>
            <div className='flex flex-row justify-center my-10 flex-wrap'>
                <Button
                    label={'Download Cashouts Report'}
                    dataQa={'download-buy-report'}
                    className='btn-main w-[300px] !min-w-[150px] !mt-0 mr-2 mb-1'
                    onClickButton={onToggleDownloadModal}
                />
            </div>
            <CSVLink
                ref={csvLink}
                data={templateData?.filter((item: any, idx: number, arr: any) => { return idx > 0 }) || []}
                headers={templateData?.[0] || []}
                className={'none'}
                target={'_blank'}
                filename={`Cashouts.csv`}
            >
            </CSVLink>
            <DownloadDataModal
                loadingDownload={loadingDownload}
                hideFilters={true}
                openDownloadModal={showDownloadModal}
                downloadError={downloadError}
                setDownloadError={setDownloadError}
                handleCloseDownloadModal={onToggleDownloadModal}
                setLoadingDownload={setLoadingDownload}
                onDownloadData={onDownloadData}
                cashouts={true}
            />
        </div>
    )
}

export default CashoutsCategory;