import { AxiosResponse } from 'axios';
import { INVENTORY_SERVICE_URL } from '../../../../config';
import inventoryAxios from '../../../../utils/axios/inventory.axios';
import { PaginatedSales } from '../../../../interfaces/sales/paginatedStore.type';
import { ISale } from '../../../../interfaces/sales/ISales';

const tryToFetchSales = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedSales>> => {
    const url = `${INVENTORY_SERVICE_URL}/broker-sales?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedSales>(url, data);
};

const tryToFetchSingleSale = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_SERVICE_URL}/broker-sales/${id}`);
};

const tryToEditSale = (id: string, data: ISale): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_SERVICE_URL}/broker-sales/${id}`, data);
};

const tryToCreateSale = (data: any): Promise<AxiosResponse> => {
    return inventoryAxios.post(`${INVENTORY_SERVICE_URL}/broker-sales`, data);
};

const tryToDeleteSale = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.delete(`${INVENTORY_SERVICE_URL}/broker-sales/${id}`);
};

const tryToFetchMainOrder = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_SERVICE_URL}/broker-sales/${id}/main-view`)
};

const tryToSubmitSale = (purchaseId: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_SERVICE_URL}/broker-sales/${purchaseId}/status/submitted`)
};

const tryToAcceptOrRejectSale = (purchaseId: string, status: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_SERVICE_URL}/broker-sales/${purchaseId}/status/${status}`, data)
};

const tryToImportBatchSale = (data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_SERVICE_URL}/broker-sales/import/batch`, data)
};

const tryToFetchProformas = (pageNumber: number,
    pageSize: number, search: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_SERVICE_URL}/sales/broker-proformas/all?page=${pageNumber}&size=${pageSize}${search ? `&search=${search}` : ``}`)
};

const tryToFetchInvoices = (pageNumber: number,
    pageSize: number, search: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_SERVICE_URL}/sales/broker-invoices/all?page=${pageNumber}&size=${pageSize}${search ? `&search=${search}` : ``}`)
};


export const SalesOrdersService = {
    tryToFetchSales,
    tryToFetchSingleSale,
    tryToEditSale,
    tryToCreateSale,
    tryToDeleteSale,
    tryToSubmitSale,
    tryToFetchMainOrder,
    tryToImportBatchSale,
    tryToAcceptOrRejectSale,
    tryToFetchProformas,
    tryToFetchInvoices
};