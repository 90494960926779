import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { INotificationSection } from '../../../interfaces/notification-sections/INotificationSection';
import {
    tryToDeleteNotificationSection,
    tryToFetchNotificationSections
} from '../../../store/brokers/admin/notification-sections/notificationSectionsSlice';
import NotificationSectionsTable from './tables/NotificationSectionsTable';
import SectionActionsModal from './modals/SectionActionsModal';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';


const NotificationSetions = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<INotificationSection[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [sectionItemId, setSectionItemId] = useState<string | null>();
    const [showSectionModal, setShowSectionModal] = useState<boolean>(false);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.notificationSections.sections?.data?.page);
    const rowsLoading = useAppSelector((state) => state.notificationSections.sectionsAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.notificationSections);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Notification Sections')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.sections) {
            const rows = state.sections?.data?.elements
            setRows(rows);
        }
    }, [state.sections]);

    const getNotificationSectionsData = async () => {
        const formatData: any = {
            ...paginationState,
        }
        await dispatch(tryToFetchNotificationSections(formatData));
    }

    useEffect(() => {
        getNotificationSectionsData()
    }, [paginationState]);

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const onDeleteNotificationSection = async () => {
        try {
            await dispatch(tryToDeleteNotificationSection(sectionItemId || ''));
            setShowToast({
                type: 'success',
                message: `Section successfully deleted`,
            });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setSectionItemId(undefined);
        setShowDeleteModal(false);
    };

    const onAddSection = (id?: string) => {
        if (id) {
            setSectionItemId(id);
        }
        setShowSectionModal(true);
    };

    const onCloseSectionModal = () => {
        setSectionItemId(null);
        setShowSectionModal(false);
    };

    const onSaveNotificationSectionChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        getNotificationSectionsData()
    };

    const onDeleteSectionRequest = (id: string) => {
        setShowDeleteModal(true);
        setSectionItemId(id);
    };

    return (
        <div>
            <div>
                <p className='page-title'>Notification Sections</p>
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New section'}
                    dataQa={'add-new-section'}
                    className={(!accessControl || pageAccess?.actions?.['Add New Section']) ? 'btn-main' : 'btn-main-disable'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Section']) && onAddSection()}
                />
            </div>
            <div className='my-5 relativ'>
                {(rows?.length > 0 || rowsLoading) ?
                    <NotificationSectionsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onDeleteNotificationSection={onDeleteSectionRequest}
                        onEditSection={onAddSection}
                    />
                    : <EmptyContainer
                        text={'No Section added yet.'}
                        showImage={true}
                    />
                }
            </div>
            {showSectionModal &&
                <SectionActionsModal
                    sectionItemId={sectionItemId}
                    openActionsModal={showSectionModal}
                    handleCloseModal={onCloseSectionModal}
                    onSaveChanges={onSaveNotificationSectionChanges}
                />
            }
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeleteNotificationSection}
            />
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
        </div>
    );
};

export default NotificationSetions;