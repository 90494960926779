import { AxiosResponse } from 'axios';
import { OFFER_API_URL, UPLOAD_PRICING_URL } from '../../../../config';
import offersAxios from '../../../../utils/axios/offers.axios';
import uploadPricingAxios from '../../../../utils/axios/upload-pricing.axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { PaginatedComparisonSitePricing } from '../../../../interfaces/comparison-sites-pricing/paginatedStore.type';


const tryToFetchFilteredComparisonPricings = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse<PaginatedComparisonSitePricing>> => {
    const url = `${OFFER_API_URL}/comparison-site/offers?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedComparisonSitePricing>(url, data);
};

const tryToDownloadComparisonPricing = (data: any): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/comparison-site/offers/download`, data);
};

const tryToUploadComparisonPricingCSV = async (
    data: any,
    assetTemplateId: string,
    marketId?: string,
    currency?: string,
    subBrandId?: string,
    template?: string
): Promise<any> => {
    const urlOrder = `${UPLOAD_PRICING_URL}/comparison-site-offers/import`;
    const formatData: any = {
        offers: {
            assetTemplateId: assetTemplateId,
            data: data,
        },
    };
    if (currency) {
        formatData.offers = {
            ...formatData.offers || {},
            currency: currency || ''
        }
    }
    if (marketId) {
        formatData.offers = {
            ...formatData.offers || {},
            marketId: marketId || ''
        }
    }
    if (subBrandId) {
        formatData.offers = {
            ...formatData.offers || {},
            subBrandId: subBrandId || ''
        }
    }
    if (template) {
        formatData.offers = {
            ...formatData.offers || {},
            template: template || ''
        }
    }
    await uploadPricingAxios.post<ApiResponse<any>>(urlOrder, formatData);
    return formatData;
};

export const ComparisonPricingService = {
    tryToDownloadComparisonPricing,
    tryToFetchFilteredComparisonPricings,
    tryToUploadComparisonPricingCSV,
};