import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToFetchSingleSaleHistory } from '../../../../store/brokers/admin/reports/reportsServiceSlice';
import SalesHistoryFilterDetails from '../details/SalesHistoryFilterDetails';
import SalesHistoryDetailsTable from '../tables/SalesHistoryDetailsTable';
import Button from '../../../../shared/button';


interface ISalesHistoryDetails {
    id: string;
    onGoBack: () => void;
}

const SalesHistoryDetails = ({
    id,
    onGoBack
}: ISalesHistoryDetails) => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<Array<any>>([]);
    const [categorySelected, setCategorySelected] = useState<string>();
    const [typeSelected, setTypeSelected] = useState<any>()
    const [filterValues, setFilterValues] = useState<any>();
    const [vatSelected, setVatSelected] = useState<any>();
    const [itemId, setItemId] = useState<any>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const state = useAppSelector((state) => state?.reports);
    const loading = useAppSelector((state) => state.reports?.saleHistoryIsLoading);
    const paginationData = useAppSelector((state) => state.reports.saleHistoryDetail?.data.page)

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

    useEffect(() => {
        if (state?.saleHistoryDetail) {
            const rowsData =
                state.saleHistoryDetail?.data?.elements && Array.isArray(state.saleHistoryDetail?.data.elements)
                    ? state.saleHistoryDetail?.data.elements.map((r: any) => ({ ...r }))
                    : [];
            if (rowsData !== rows) {
                setRows(rowsData);
            }
            if (!categorySelected) {
                setCategorySelected(rowsData?.[0]?.assetTemplateId || undefined)
            }
        }
    }, [state?.saleHistoryDetail]);

    const onClearAllFilters = () => {
        setTypeSelected(undefined)
        setVatSelected(undefined)
        setFilterValues(undefined)
        setItemId(undefined)
        onResetPaginationSettings()
    }

    const getSingleSalesHistoryData = async () => {
        let formatBody: any = {
            ...paginationState,
            filters: id
        }
        if (filterValues?.from || filterValues?.to) {
            if (filterValues?.from) {
                formatBody = {
                    ...formatBody || {},
                    data: {
                        ...formatBody?.data || {},
                        dateRange: {
                            ...formatBody?.data?.dateRange || {},
                            from: filterValues?.from
                        }
                    }
                }
            }
            if (filterValues?.to) {
                formatBody = {
                    ...formatBody || {},
                    data: {
                        ...formatBody?.data || {},
                        dateRange: {
                            ...formatBody?.data?.dateRange || {},
                            to: filterValues?.to
                        }
                    }
                }
            }
        }
        if (itemId) {
            formatBody = {
                ...formatBody || {},
                data: {
                    ...formatBody?.data || {},
                    itemId: itemId
                }
            }
        }
        if (vatSelected) {
            formatBody = {
                ...formatBody || {},
                data: {
                    ...formatBody?.data || {},
                    vatType: vatSelected?.value
                }
            }
        }
        if (typeSelected) {
            formatBody = {
                ...formatBody || {},
                data: {
                    ...formatBody?.data || {},
                    type: typeSelected?.value
                }
            }
        }
        await dispatch(tryToFetchSingleSaleHistory(formatBody));
    }

    useEffect(() => {
        getSingleSalesHistoryData()
    }, [paginationState])

    const onChangeFilter = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'type':
                    setTypeSelected(value)
                    break;
                case 'vat':
                    setVatSelected(value)
                    break;
            }
            onResetPaginationSettings()
        }
    }

    const onChangeFilterValue = (value: string | null | undefined, type: string) => {
        setFilterValues({
            ...filterValues,
            [type]: value || undefined
        })
        onResetPaginationSettings()
    }

    const onChangeItemValue = (value: string | null | undefined, type?: string) => {
        setItemId(value)
        onResetPaginationSettings()
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center ml-10'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-5'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[14px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title !text-[18px]'>Sales History Details</p>
                </div>
            </div>
            <div>
                <SalesHistoryFilterDetails
                    filters={filterValues}
                    itemIdValue={itemId}
                    selectedVAT={vatSelected}
                    typeSelected={typeSelected}
                    onChangeItemValue={onChangeItemValue}
                    onSelectFilter={onChangeFilter}
                    onChangeFilterValue={onChangeFilterValue}
                />
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label='Clear all filters'
                    dataQa={'clear-all-filters'}
                    className='btn-primary-text-underline min-w-auto col-span-1 col-end-8 flex flex-row justify-end items-center'
                    onClickButton={onClearAllFilters}
                />
            </div>
            <div>
                <SalesHistoryDetailsTable
                    rows={rows}
                    state={state}
                    loading={loading}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                />
            </div>
        </div >
    )
}

export default SalesHistoryDetails;