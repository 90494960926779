import React from 'react';
import { ICSAgents } from '../../../../interfaces/cs-agents/cs-agents';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Switch from '../../../../shared/switch';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';

const tableColumns = ['Email', 'Username', 'Name', 'Country', 'Access Role', 'Enabled', 'Manage Role', 'Actions']

interface ICSAgentsTable {
    rows: ICSAgents[];
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    dataQa?: string;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeCSAgentDetails: (id: string) => void;
    onEnableDisableCSAgent: (value: string, currentStatus: boolean) => void;
    onDeleteCSAgent: (id: string) => void;
    onOpenCSRole: (id: string, role?: string) => void;
    onClickDeleteCsButton: (id: string) => void;
}

const CSAgentsTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    rowsLoading,
    setPaginationState,
    onEnableDisableCSAgent,
    onOpenCSRole,
    onClickDeleteCsButton,
    dataQa,
}: ICSAgentsTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: ICSAgents, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.email}</td>
                                <td className='px-6 py-4'>{row?.username}</td>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>{row?.country}</td>
                                <td className='px-6 py-4'>{row?.role}</td>
                                <td className='px-6 py-4'>
                                    <Switch
                                        checked={!!row?.enabled}
                                        onToggleSwitch={() => (!accessControl || actions?.['CS Enable Disable']) && row?._id && onEnableDisableCSAgent(row?._id, row?.enabled || false)}
                                        dataQa={'cs-enabled'}
                                    />
                                </td>
                                <td className='px-6'>
                                    <Button
                                        label={'Manage Role'}
                                        onClickButton={() => (!accessControl || actions?.['CS Manage Role']) && onOpenCSRole(row?._id || '', row?.role)}
                                        className={`${(!accessControl || actions?.['CS Manage Role']) ? 'btn-main' : 'btn-main-disable'} !shadow-none`}
                                        dataQa={'manage-role-cs-btn'}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row'>
                                        <div data-qa='delete-cs' onClick={() => ((!accessControl || actions?.['CS Delete']) && row?._id) && onClickDeleteCsButton(row._id)}>
                                            <img title={'Delete CS Agent'} alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['CS Delete']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default CSAgentsTable;