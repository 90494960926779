import React, { useCallback } from 'react';
import { ILogisticDetails } from '../../../interfaces/ai-agents/IItem';
import Button from '../../../shared/button';
import { tryToAddConversationAiAgent, tryToAddNextConversationAiAgent } from '../../../store/brokers/ai-agents/aiAgentsSlice';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';

const LogisticDetailsItem = ({
	message,
	logisticDetails,
	isLoading,
	type,
	userHasConfirmOrder,
	buttons,
	stepDetails
}: {
	message: string,
	logisticDetails: ILogisticDetails,
	isLoading: boolean,
	type?: 'seller' | 'buyer',
	userHasConfirmOrder?: boolean,
	buttons?: string[],
	stepDetails?: {stepNr: number, stepAnswer: string}
}) => {
	const dispatch = useAppDispatch();
	const { id } = useParams();
	const { street, city, stateOrProvince, zipOrPostalCode, country } = logisticDetails.logisticsDetails || {}
	const handleConfirm = useCallback(async (answer: string) => {
		const addChat = await dispatch(tryToAddConversationAiAgent({ answer, chatId: id || '', type, userHasConfirmOrder: true })).unwrap();
		if (addChat.success && !addChat.finalStep) {
			await dispatch(tryToAddNextConversationAiAgent({ chatId: id || '', type })).unwrap();
		}
	}, [id]);
	return (
		<div className='w-full'>
			<p>{message}</p>
			<div className='flex flex-wrap my-4'>
				<div className='w-full md:w-4/12 px-2 mb-2 md:mb-0'>
					<div className='py-3 px-5 rounded-2xl bg-primary-default bg-opacity-20 h-full flex flex-col justify-center'>
						<h4 className='text-[#484A4B] font-bold text-2xl md:text-4xl'>{logisticDetails?.orderConfirmationNumber}</h4>
						<p className='text-sm font-normal text-[#484A4B]'>Order Confirmation Number</p>
					</div>
				</div>
				<div className='w-full md:w-4/12 px-2 mb-2 md:mb-0'>
					<div className='py-3 px-5 rounded-2xl bg-primary-default bg-opacity-20 h-full flex flex-col justify-center'>
						<h4 className='text-[#484A4B] font-bold text-2xl md:text-4xl'>{logisticDetails?.totalItems}</h4>
						<p className='text-sm font-normal text-[#484A4B]'>Total Items</p>
					</div>
				</div>
				<div className='w-full md:w-4/12 px-2 mb-2 md:mb-0'>
					<div className='py-3 px-5 rounded-2xl bg-primary-default bg-opacity-20 h-full flex flex-col justify-center'>
						<h4 className='text-[#484A4B] font-bold text-2xl md:text-4xl'>
							{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(logisticDetails?.totalAmount)}
						</h4>
						<p className='text-sm font-normal text-[#484A4B]'>Total Amount</p>
					</div>
				</div>
			</div>
			<div className='w-full px-2'>
				<p>
					{street}, {city}, <br />
					{stateOrProvince}, {zipOrPostalCode} <br />
					{country}
				</p>
			</div>
			<div className='w-full my-5'>
				{stepDetails?.stepAnswer ? <p className='text-[#00B67A] text-sm font-normal'>{stepDetails.stepAnswer}</p> : buttons?.map((button) =>
					<td key={button} className='pt-5'>
						<Button
							disabled={isLoading}
							loading={isLoading}
							className="text-label text-sm py-3 px-7 rounded-xl bg-gradient-to-br from-gradient-start to-gradient-end hover:from-gradient-end hover:to-gradient-start mx-2"
							label={button}
							onClickButton={() => handleConfirm(button)}
						/>
					</td>
				)}
				{/* {!userHasConfirmOrder && <Button
					disabled={isLoading}
					loading={isLoading}
					className='text-[#00B67A] text-sm font-normal hover:underline'
					label='Confirm'
					onClickButton={handleConfirm}
				/>} */}
			</div>
		</div>
	)
}

export default LogisticDetailsItem;