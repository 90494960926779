import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { IAssetFields } from '../../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToDeleteAssetTemplateFilter,
    tryToDeleteInventoryFilter,
    tryToSetupAssetTemplateFilters,
    tryToSetupInventoryFieldsFilters
} from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import Switch from '../../../../shared/switch';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IAssetFiltersModal {
    openFilterModal: boolean;
    assetTemplateId: string;
    items?: Array<any>;
    fields?: Array<IAssetFields>;
    type?: string;
    handleCloseModal: () => void;
}

const AssetFiltersModal = ({
    openFilterModal,
    items,
    assetTemplateId,
    fields,
    type,
    handleCloseModal,
}: IAssetFiltersModal) => {
    const dispatch = useAppDispatch();
    const [changeFilterStatusError, setChangeFilterStatusError] = useState<string>()

    const onChangeFilterStatus = async (currentStatus: boolean, fieldId: string) => {
        try {
            if (currentStatus) {
                const data = {
                    assetId: assetTemplateId,
                    fieldId
                }
                if (type === 'inventory') {
                    await dispatch(tryToDeleteInventoryFilter(data))
                } else if (type === 'rental') {
                    await dispatch(tryToDeleteInventoryFilter(data))
                }
                else {
                    await dispatch(tryToDeleteAssetTemplateFilter(data))
                }
            } else {
                const formatData = {
                    assetTemplateId: assetTemplateId,
                    fieldId
                }
                if (type === 'inventory') {
                    await dispatch(tryToSetupInventoryFieldsFilters(formatData))
                } else if (type === 'rental') {
                    await dispatch(tryToSetupInventoryFieldsFilters(formatData))
                } else {
                    await dispatch(tryToSetupAssetTemplateFilters(formatData))
                }
            }
        } catch (err: any) {
            setChangeFilterStatusError(`${err?.response || err}`)
        }
    }

    return (
        <Modal
            open={openFilterModal}
            onClose={handleCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mb-4 font-semibold text-sm'>Asset Filters</p>
                <p className='font-bold text-md mb-3'>By setting on fields you can set that field as an option to filter pricing.</p>
                {changeFilterStatusError && <Error text={changeFilterStatusError} />}
                <div className='mt-4 mb-2 grid grid-cols-2 gap-4'>
                    <p className='italic text-md mb-5'>Field name</p>
                    <p className='italic text-md mb-5'>Filter status</p>
                </div>
                {fields && fields?.length > 0 && fields?.map((item: any, idx: number) => {
                    const findIfExist = items && items?.length > 0 && items?.find((next) => next?.fieldId === item?.id)
                    return (
                        <div key={idx} className='my-1 grid grid-cols-2 gap-4'>
                            <p className='text-md mb-5'>{item?.name}</p>
                            <div>
                                <Switch
                                    onToggleSwitch={() => onChangeFilterStatus(findIfExist, item?.id)}
                                    checked={findIfExist || false}
                                    dataQa={`enabled-btn-${idx}`}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </Modal>
    )
}

export default AssetFiltersModal;