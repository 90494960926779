import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


interface IShippingProvider {
    selected?: boolean;
    image: string;
    name: string;
}

const ShippingProvider = ({
    selected,
    image,
    name
}: IShippingProvider) => {
    return (
        <WhiteContainer
            type={'rounded'}
            containerStyle={'flex flex-col justify-between min-h-[300px] items-center'}>
            <div className='flex flex-row justify-end h-[25px] items-center w-full'>
                {selected && <img src={'/assets/profile/open-success.svg'} className={'w-[23px] object-contain'} />}
            </div>
            <img src={image} className={'w-[75%] object-contain'} />
            <p className='text-2xl align-center font-bold my-3'>{name}</p>
        </WhiteContainer>
    )
}

export default ShippingProvider;