import { AxiosResponse } from 'axios';
import { ASSETS_API_URL, OFFER_API_URL } from '../../../../config';
import assetsAxios from '../../../../utils/axios/assets.axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import {
    IAssetFields,
    IAssetTemplate,
} from '../../../../interfaces/asset-templates/IAssetTemplate';
import { ISortAssetField } from '../../../../interfaces/asset-templates/IAssetTemplateSchema';

const tryToFetchAssetsTemplates = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
): Promise<AxiosResponse<any>> => {
    const url = `${ASSETS_API_URL}/asset-templates?page=${pageNumber}&size=${pageSize}`;
    return assetsAxios.put<any>(url, { filters: { search: filters } });
};

const tryToFetchAllAssetTemplates = (): Promise<AxiosResponse<any>> => {
    return assetsAxios.get<any>(`${ASSETS_API_URL}/dom/asset-templates`);
};

const tryToFetchAssetFieldsTemplate = (
    assetId: string,
): Promise<AxiosResponse<any>> => {
    return assetsAxios.get<any>(
        `${ASSETS_API_URL}/asset-templates/${assetId}/fields/all`,
    );
};

const tryToFetchAssetTemplateFieldsFilter = (
    assetId: string,
): Promise<AxiosResponse<any>> => {
    return assetsAxios.get<any>(
        `${ASSETS_API_URL}/asset-templates/${assetId}/field-values`,
    );
};

const tryToFetchSingleAssetTemplate = (
    id: string,
): Promise<AxiosResponse<ApiResponse<any>>> => {
    return assetsAxios.get(`${ASSETS_API_URL}/asset-templates/${id}`);
};

const tryToFetchSingleAssetTemplateField = (
    assetId: string,
    fieldId: string,
): Promise<AxiosResponse<ApiResponse<any>>> => {
    return assetsAxios.get(
        `${ASSETS_API_URL}/asset-templates/${assetId}/field/${fieldId}`,
    );
};

const tryToAddAssetTemplate = (
    data: IAssetTemplate,
): Promise<AxiosResponse> => {
    return assetsAxios.post(`${ASSETS_API_URL}/asset-templates`, data);
};

const tryToAddAssetTemplateField = (
    assetId: string,
    data: IAssetFields,
): Promise<AxiosResponse> => {
    return assetsAxios.put(
        `${ASSETS_API_URL}/asset-templates/${assetId}/fields`,
        data,
    );
};

const tryToEnableAssetTemplate = (id: string): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/asset-templates/${id}/enable`);
};

const tryToEditAssetTemplate = (
    id: string,
    payload: IAssetTemplate,
): Promise<AxiosResponse> => {
    return assetsAxios.put(
        `${ASSETS_API_URL}/asset-templates/${id}/edit`,
        payload,
    );
};

const tryToEditAssetTemplateField = (
    assetId: string,
    fieldId: string,
    payload: IAssetFields,
): Promise<AxiosResponse> => {
    return assetsAxios.put(
        `${ASSETS_API_URL}/asset-templates/${assetId}/fields/${fieldId}`,
        payload,
    );
};

const tryToSortAssetTemplateField = (
    assetId: string,
    payload: ISortAssetField,
): Promise<AxiosResponse> => {
    return assetsAxios.post(
        `${ASSETS_API_URL}/asset-templates/${assetId}/fields/sort`,
        payload,
    );
};

const tryToDisableAssetTemplateField = (
    assetId: string,
    fieldId: string,
): Promise<AxiosResponse> => {
    return assetsAxios.delete(
        `${ASSETS_API_URL}/asset-templates/${assetId}/fields/${fieldId}`,
    );
};

const tryToDisableAssetInventoryField = (
    assetId: string,
    fieldId: string,
): Promise<AxiosResponse> => {
    return assetsAxios.delete(
        `${ASSETS_API_URL}/asset-templates/${assetId}/inventory-fields/${fieldId}`,
    );
};

const tryToDisableAssetRentalField = (
    assetId: string,
    fieldId: string,
): Promise<AxiosResponse> => {
    return assetsAxios.delete(
        `${ASSETS_API_URL}/asset-templates/${assetId}/rental-fields/${fieldId}`,
    );
};

const tryToDisableAssetExtendedField = (
    assetId: string,
    fieldId: string,
): Promise<AxiosResponse> => {
    return assetsAxios.delete(
        `${ASSETS_API_URL}/asset-templates/${assetId}/extended-fields/${fieldId}`,
    );
};

const tryToDisableAssetTemplate = (id: string): Promise<AxiosResponse> => {
    return assetsAxios.delete(`${ASSETS_API_URL}/asset-templates/${id}`);
};

const tryToSetupAssetTemplateFilters = (
    assetTemplateId: string,
    payload: IAssetFields,
): Promise<AxiosResponse> => {
    return assetsAxios.post(
        `${ASSETS_API_URL}/asset-templates/${assetTemplateId}/setup-filters`,
        payload,
    );
};

const tryToFetchAssetTemplateFilters = (
    assetId: string,
    replacements?: boolean,
): Promise<AxiosResponse<ApiResponse<any>>> => {
    let url = `${ASSETS_API_URL}/asset-templates/${assetId}/get-filters`;
    if (replacements) {
        url = url + '?replacements=true';
    }
    return assetsAxios.get(url);
};

const tryToDeleteAssetTemplateFilter = (
    assetTemplateId: string,
    fieldId: string,
): Promise<AxiosResponse> => {
    return assetsAxios.delete(
        `${ASSETS_API_URL}/asset-templates/${assetTemplateId}/delete-filter/${fieldId}`,
    );
};

const tryToSetupInventoryFieldsFilters = (
    assetTemplateId: string,
    payload: IAssetFields,
): Promise<AxiosResponse> => {
    return assetsAxios.post(
        `${ASSETS_API_URL}/asset-templates/${assetTemplateId}/setup-inventory-filters`,
        payload,
    );
};

const tryToFetchInventoryFilters = (
    assetId: string,
): Promise<AxiosResponse<ApiResponse<any>>> => {
    return assetsAxios.get(
        `${ASSETS_API_URL}/asset-templates/${assetId}/get-inventory-filters`,
    );
};

const tryToDeleteInventoryFilter = (
    assetTemplateId: string,
    fieldId: string,
): Promise<AxiosResponse> => {
    return assetsAxios.delete(
        `${ASSETS_API_URL}/asset-templates/${assetTemplateId}/delete-inventory-filter/${fieldId}`,
    );
};

const tryToFetchParentCategoriesByLevel = (
    level: string,
): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/asset-templates/level/${level}`);
};

const tryToChangeTemplatePricingField = (
    assetTemplateId: string,
    data: any,
): Promise<AxiosResponse> => {
    return assetsAxios.put(
        `${ASSETS_API_URL}/asset-templates/${assetTemplateId}/default/pricing-field`,
        data,
    );
};

const tryToFetchAssetTemplateFrames = (
    pageNumber: number,
    pageSize: number,
    data?: string,
): Promise<AxiosResponse<any>> => {
    const url = `${ASSETS_API_URL}/revaluation-draw-frames/asset-templates/frames?page=${pageNumber}&size=${pageSize}`;
    return assetsAxios.put<any>(url, data);
};

const tryToFetchSingleAssetTemplateFrame = (
    assetTemplateId: string,
): Promise<AxiosResponse<ApiResponse<any>>> => {
    return assetsAxios.get(
        `${ASSETS_API_URL}/revaluation-draw-frames/asset-templates/${assetTemplateId}`,
    );
};

const tryToAddAssetTemplateFrame = (payload: any): Promise<AxiosResponse> => {
    return assetsAxios.post(
        `${ASSETS_API_URL}/revaluation-draw-frames/asset-templates/frames`,
        payload,
    );
};

const tryToEditAssetTemplateFrame = (payload: any): Promise<AxiosResponse> => {
    return assetsAxios.put(
        `${ASSETS_API_URL}/revaluation-draw-frames/asset-templates/frames/edit`,
        payload,
    );
};

const tryToDeleteAssetTemplateFrame = (
    assetTemplateId: string,
): Promise<AxiosResponse> => {
    return assetsAxios.delete(
        `${ASSETS_API_URL}/revaluation-draw-frames/asset-templates/${assetTemplateId}`,
    );
};

const tryToChangeTemplateFieldDetails = (
    assetTemplateId: string,
    fieldId: string,
    data: any,
) => {
    return assetsAxios.put(
        `${ASSETS_API_URL}/asset-templates/${assetTemplateId}/fields/${fieldId}/details`,
        data,
    );
};

const tryToImportAssetsTemplateTranslations = (
    data: any,
): Promise<AxiosResponse> => {
    return assetsAxios.post(
        `${ASSETS_API_URL}/asset-templates/translations`,
        data,
    );
};

const tryToFetchAssetTemplateMappings = (
    assetTemplateId: string,
): Promise<AxiosResponse<any>> => {
    return assetsAxios.get(
        `${OFFER_API_URL}/sales-grade-mappings/${assetTemplateId}`,
    );
};

const tryToEditAssetTemplateMappings = (
    assetTemplateId: string,
    deductionPercentageA?: string,
    deductionPercentageB?: string,
    deductionPercentageC?: string,
    deductionPercentageD?: string,
    deductionPercentageE?: string,
): Promise<AxiosResponse<any>> => {
    return assetsAxios.put(
        `${OFFER_API_URL}/sales-grade-mappings/${assetTemplateId}`,
        {
            mappings: [
                {
                    grade: 'A',
                    rank: 1,
                    condition: 'Flawless',
                    deductionPercentage: deductionPercentageA,
                },
                {
                    grade: 'B',
                    rank: 2,
                    condition: 'Excellent',
                    deductionPercentage: deductionPercentageB,
                },
                {
                    grade: 'C',
                    rank: 3,
                    condition: 'Good',
                    deductionPercentage: deductionPercentageC,
                },
                {
                    grade: 'D',
                    rank: 4,
                    condition: 'Damaged',
                    deductionPercentage: deductionPercentageD,
                },
                {
                    grade: 'E',
                    rank: 5,
                    condition: 'Faulty',
                    deductionPercentage: deductionPercentageE,
                },
            ],
        },
    );
};

const tryToAddAssetTemplateMappings = (
    assetTemplateId: string,
    deductionPercentageA?: string,
    deductionPercentageB?: string,
    deductionPercentageC?: string,
    deductionPercentageD?: string,
    deductionPercentageE?: string,
): Promise<AxiosResponse<any>> => {
    return assetsAxios.post(`${OFFER_API_URL}/sales-grade-mappings`, {
        assetTemplateId,
        mappings: [
            {
                grade: 'A',
                rank: 1,
                condition: 'Flawless',
                deductionPercentage: deductionPercentageA,
            },
            {
                grade: 'B',
                rank: 2,
                condition: 'Excellent',
                deductionPercentage: deductionPercentageB,
            },
            {
                grade: 'C',
                rank: 3,
                condition: 'Good',
                deductionPercentage: deductionPercentageC,
            },
            {
                grade: 'D',
                rank: 4,
                condition: 'Damaged',
                deductionPercentage: deductionPercentageD,
            },
            {
                grade: 'E',
                rank: 5,
                condition: 'Faulty',
                deductionPercentage: deductionPercentageE,
            },
        ],
    });
};

export const AssetsTemplatesService = {
    tryToFetchAssetsTemplates,
    tryToFetchSingleAssetTemplateField,
    tryToFetchSingleAssetTemplate,
    tryToFetchAssetFieldsTemplate,
    tryToFetchAssetTemplateFieldsFilter,
    tryToAddAssetTemplate,
    tryToAddAssetTemplateField,
    tryToEnableAssetTemplate,
    tryToEditAssetTemplate,
    tryToEditAssetTemplateField,
    tryToSortAssetTemplateField,
    tryToDisableAssetTemplate,
    tryToDisableAssetTemplateField,
    tryToDisableAssetInventoryField,
    tryToFetchAllAssetTemplates,
    tryToSetupAssetTemplateFilters,
    tryToFetchAssetTemplateFilters,
    tryToDeleteAssetTemplateFilter,
    tryToSetupInventoryFieldsFilters,
    tryToFetchInventoryFilters,
    tryToDeleteInventoryFilter,
    tryToFetchParentCategoriesByLevel,
    tryToChangeTemplatePricingField,
    tryToDisableAssetRentalField,
    // catalog frame endpoints
    tryToFetchAssetTemplateFrames,
    tryToFetchSingleAssetTemplateFrame,
    tryToAddAssetTemplateFrame,
    tryToEditAssetTemplateFrame,
    tryToDeleteAssetTemplateFrame,
    tryToChangeTemplateFieldDetails,
    tryToImportAssetsTemplateTranslations,
    tryToFetchAssetTemplateMappings,
    tryToEditAssetTemplateMappings,
    tryToAddAssetTemplateMappings,
    tryToDisableAssetExtendedField
};
