import React, { useState, useCallback } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToImportSales } from '../../../../store/brokers/admin/reports/reportsServiceSlice';
import SuccessfullyUploadFile from '../details/SuccessfullyUploadedFile';
import UploadCsvFile from '../details/UploadCsvFile';
import Modal from '../../../../shared/modal';


interface IImportDataModal {
    openImportModal: boolean;
    sectors?: any;
    markets?: any;
    handleCloseModal: () => void;
}

const ImportDataModal = ({
    openImportModal,
    handleCloseModal,
}: IImportDataModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [sectorSelected, setSectorSelected] = useState<any>()
    const [vatSelected, setVatSelected] = useState<any>()
    const [fileSelected, setFileSelected] = useState<any>()
    const [currencySelected, setCurrencySelected] = useState<any>()
    const [importLoading, setImportLoading] = useState<boolean>(false)
    const [dateFiltersValues, setDateFiltersValues] = useState<any>();

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onCloseModal = () => {
        handleCloseModal();
        setImportError(undefined)
        setImportedSuccessfully(false)
        setFileSelected(undefined)
        setSectorSelected(undefined)
        setCurrencySelected(undefined)
        setVatSelected(undefined)
        setDateFiltersValues(undefined)
    }

    const onUploadFile = async () => {
        setImportLoading(true)
        try {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    let payload: any = {
                        salesData: results?.data || []
                    }
                    if (sectorSelected) {
                        payload = {
                            ...payload || {},
                            assetTemplateId: sectorSelected?.value || ''
                        }
                    }
                    if (vatSelected) {
                        payload = {
                            ...payload || {},
                            vat: vatSelected?.value || ''
                        }
                    }
                    if (currencySelected) {
                        payload = {
                            ...payload || {},
                            currency: currencySelected?.value || '',
                        }
                    }
                    if (dateFiltersValues) {
                        payload = {
                            ...payload || {},
                            range: {
                                to: new Date(dateFiltersValues?.maxDateCreated),
                                from: new Date(dateFiltersValues?.minDateCreated)
                            }
                        }
                    }
                    await dispatch(tryToImportSales(payload)).unwrap()
                }
            })
            setImportError(undefined)
            setImportedSuccessfully(true)
            setSectorSelected(undefined)
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
        setImportLoading(false)
    }

    return (
        <Modal
            open={openImportModal}
            onClose={() => !importLoading && onCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <SuccessfullyUploadFile
                        handleCloseModal={() => !importLoading && onCloseModal()}
                    />
                    : <UploadCsvFile
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                        importLoading={importLoading}
                    />
                }
            </div>
        </Modal>
    )
}

export default ImportDataModal;