import React from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { IOrders } from '../../../../interfaces/orders/IOrders';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';

const tableColumns = ['Order ID', 'Tracking Number', 'Total Items', 'Price', 'Broker', 'Categories', 'Package Label', 'Item Details']

interface IReshippedPackage {
    rows: Array<IOrders>;
    paginationData?: IPage;
    activeMarket?: IActiveMarket;
    ordersLoading: boolean;
    received?: boolean;
    pageAccess?: IPageActions;
    accessControl?: IAccessControl;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSeeMoreDetails: (type: string, data: any) => void;
    onClickSeeItems: (items: any) => void;
}

const ReshippedPackageTable = ({
    rows,
    paginationData,
    activeMarket,
    ordersLoading,
    received,
    accessControl,
    pageAccess,
    onClickSeeMoreDetails,
    setPaginationState,
    onClickSeeItems,
}: IReshippedPackage) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {ordersLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className={`px-6 py-4`}>{row?._id}</td>
                                <td className='px-6 py-4'>
                                    <a
                                        rel='noreferrer'
                                        href={`https://parcelsapp.com/en/tracking/${row?.trackingNumber}`}
                                        target='_blank'
                                        className='text-primary-default'
                                    >
                                        {row?.trackingNumber}
                                    </a>
                                </td>
                                <td className={`px-6 py-4`}>{row?.items?.length || 0}</td>
                                <td className={`px-6 py-4`}>{CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.descriptor?.name || '')}`]}{row?.price || row?.initialPrice || 0}</td>
                                <td className='pl-6 pr-2 py-4 min-w-[150px]'>{row?.broker?.companyName || row?.broker?.name || ''}</td>
                                <td className='pl-6 pr-2 py-4 min-w-[150px]'>
                                    <div
                                        onClick={() => (row?.catalogs && row?.catalogs?.length > 0) && onClickSeeMoreDetails('categories', row?.catalogs)}
                                        className='cursor-pointer hover:text-black font-bold'>
                                        {`${row?.catalogs?.length || 0} categories`}
                                    </div>
                                </td>
                                <td className='px-6 py-4 min-w-[120px]'>
                                    {row?.shippingLabel ?
                                        <a
                                            rel='noreferrer'
                                            data-qa={'return-shipping-label'}
                                            href={`${row?.shippingLabel}`}
                                            target='_blank'
                                            className='text-primary-default'
                                        >
                                            Get Label
                                        </a>
                                        : <Button
                                            label={'Get Label'}
                                            className='btn-main-disable'
                                        />
                                    }
                                </td>
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <Button
                                        label='See Details'
                                        className='btn-main'
                                        onClickButton={() => onClickSeeItems(row?.items)}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default ReshippedPackageTable;