import React, { useEffect, useState } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

import Input from '../../../../shared/input';
import EmptyContainer from '../../../../shared/empty-container';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToFetchAllCreatedRMAs } from '../../../../store/brokers/partners/rma/partnerRMAsSlice';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import CreatedRMAsTable from '../tables/CreatedRMAsTable';
import SelectCheckbox from '../../../../shared/select-checkbox';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import Button from '../../../../shared/button';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};
interface ICreatedRMAsTab {
    paginationState: IPaginationPayload;
    setPaginationState: React.Dispatch<
        React.SetStateAction<IPaginationPayload>
    >;
    sectors: IAssetTemplate[];
    pageAccess: any;
}
const OPTIONS = [
    {
        label: 'All statuses',
        value: undefined,
    },
    {
        label: 'Created',
        value: 'created',
    },
    {
        label: 'Completed',
        value: 'completed',
    },
];

const CreatedRMAsTab = ({
    paginationState,
    setPaginationState,
    sectors,
    pageAccess,
}: ICreatedRMAsTab) => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any[]>([]);
    const paginationData = useAppSelector(
        (state) => state.partnersRMAs.createdRMAs?.data?.page,
    );
    const rowsLoading = useAppSelector(
        (state) => state.partnersRMAs.createdRMAsAreLoading,
    );
    const state = useAppSelector((state) => state.partnersRMAs);
    const [selectedStatus, setSelectedStatus] = useState(OPTIONS[0]);
    const [searchParams, setSearchParams] = useSearchParams();
    const query = useQuery();
    const accessControl = useAppSelector(
        (state) => state?.partners?.partnerNavigation,
    );
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const currency = CURRENCY_SYMBOLS?.[activeMarketState?.currency?.descriptor?.name || 'GBP']
    const onChangeSearchValue = (
        value: string | null | undefined,
        type: string,
    ) => {
        setPaginationState({
            ...(paginationState || {}),
            pageNumber: 1,
            filters: value || undefined,
        });
        if (value) searchParams.set('search', value);
        else searchParams.delete('search');
        searchParams.set('page', '1');
        setSearchParams(searchParams);
    };
    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined,
        });
        searchParams.set('page', '1');
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    };

    const onClickStatusFilter = (value: any, type?: string) => {
        setSelectedStatus(value);
        if (value.value) {
            searchParams.set('status', value?.value);
        } else searchParams.delete('status');
        setSearchParams(searchParams);
        onResetPaginationSettings();
    };

    useEffect(() => {
        const paginationStateFormat = paginationState;
        if (
            query?.get('page') &&
            Number(query?.get('page')) !== paginationState?.pageNumber
        ) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1);
        }
        if (
            query?.get('size') &&
            Number(query?.get('size')) !== paginationState?.pageSize
        ) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10);
        }
        if (query?.get('search')) {
            paginationStateFormat.filters = query.get('search') || undefined;
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat);
        }
    }, [query?.get('page'), query?.get('size'), query?.get('search')]);

    useEffect(() => {
        if (query?.get('status')) {
            setSelectedStatus(OPTIONS.find(o => o.value === query?.get('status')) || OPTIONS[0])
        } else searchParams.delete('status')
    }, [
        query?.get('status')
    ])

    useEffect(() => {
        searchParams.set('page', `${paginationState?.pageNumber}`);
        searchParams.set('size', `${paginationState?.pageSize}`);
        setSearchParams(searchParams);
        if (activeMarketState) {
            dispatch(tryToFetchAllCreatedRMAs({ ...paginationState, status: selectedStatus.value, data: { marketName: activeMarketState?.market?.name } }));
        }
    }, [paginationState, selectedStatus, activeMarketState]);

    useEffect(() => {
        if (state.createdRMAs) {
            const rows =
                state.createdRMAs?.data?.elements &&
                    Array.isArray(state.createdRMAs?.data.elements)
                    ? state.createdRMAs?.data.elements.map((r: any) => ({
                        ...r,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.createdRMAs]);

    const onClearAllFilters = () => {
        searchParams.delete('status');
        setSearchParams(searchParams)
        setSelectedStatus(OPTIONS[0])
        setPaginationState({ ...paginationState, filters:  '' })
    }

    return (
        <div>
            <div className="my-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 sm:gap-2 lg:gap-4">
                <SelectCheckbox
                    name="Status"
                    dataQa={'status-filter'}
                    placeholder={selectedStatus.label || ''}
                    options={OPTIONS}
                    selectStyle={{
                        backgroundColor: '#ffffffa4',
                        borderRadius: '4px',
                    }}
                    onChangeSelectedOption={onClickStatusFilter}
                    uniqueName={'Status'}
                />
            </div>
            <div className="bg-[#F7A21E1A] rounded-[10px] p-2 flex flex-row items-center justify-between">
                <Input
                    placeholder={'Filter Table'}
                    containerStyle="mb-0 pb-0 relative flex-initial w-96"
                    inputStyle={`mb-0 border-0 focus:outline-none`}
                    icon={'/assets/shared/search.svg'}
                    showValue={true}
                    inputValue={paginationState?.filters}
                    inputUniqueName={'search'}
                    onChangeInput={onChangeSearchValue}
                    hideInputSpace={true}
                    dataQa={'filter-table'}
                />
                <Button
                    label={'Clear All Filters'}
                    dataQa={'clear-all-filters'}
                    className='btn-primary-text-underline min-w-auto !mt-0 flex flex-row justify-end bg-transparent mr-2 mb-1'
                    onClickButton={onClearAllFilters}
                />
            </div>

            <div className="my-5 relative">
                {rows?.length > 0 || rowsLoading ? (
                    <CreatedRMAsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        sectors={sectors}
                        pageAccess={pageAccess}
                        accessControl={accessControl}
                        currency={currency}
                    />
                ) : (
                    <EmptyContainer text={'No RMA found.'} showImage={true} />
                )}
            </div>
        </div>
    );
};

export default CreatedRMAsTab;
