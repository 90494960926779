import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';


interface IModalErrorChangeStatus {
    openModal: boolean;
    handleCloseModal: () => void;
}

const ModalErrorChangeStatus = ({
    openModal,
    handleCloseModal,
}: IModalErrorChangeStatus) => {

    const onCloseModal = () => {
        handleCloseModal();
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle='min-w-[600px]'>
            <div className={'p-4 min-w-[550px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Asset Status Error</p>
                </div>
                <div className='my-5'>
                    <p>You can&apos;t change Asset Status without adding Asset Extended Details first.</p>
                </div>
                <div className='flex flex-row justify-end'>
                    <Button
                        className='btn-error !rounded ml-3'
                        label={'Close'}
                        onClickButton={() => onCloseModal()}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ModalErrorChangeStatus;