import React from 'react';


interface IMarketItem {
    marketOpen?: boolean;
    marketName: string;
    marketId: string;
    marketFlag?: string;
    onClickMarket: (market?: string, open?: boolean) => void;
}

const MarketItem = ({
    marketOpen,
    marketName,
    marketId,
    marketFlag,
    onClickMarket
}: IMarketItem) => {
    return (
        <div data-qa={'countries'} className='flex flex-row items-center mb-2 cursor-pointer' onClick={() => onClickMarket(marketId, marketOpen)}>
            <img
                src={`/assets/profile/${marketOpen ? 'open-success.svg' : 'open-disabled.svg'}`}
                className={'mr-2 w-[16px] object-contain'}
            />
            {marketFlag &&
                <img
                    src={`${marketFlag}`}
                    className={'mr-2 w-[16px] object-contain'}
                />
            }
            <p>{marketName}</p>
        </div>
    )
}

export default MarketItem;