import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToDeleteAssetTemplateFrame,
    tryToFetchAssetTemplateFrames,
    tryToFetchAllAssetsTemplates
} from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import AssetTemplateFramesTable from './tables/AssetTemplateFramesTable';
import AssetTemplateFrameModal from './modals/AssetTemplateFrameModal';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';
import SelectCheckbox from '../../../shared/select-checkbox';
import ImagesCarousel from '../../../shared/images-carousel';


const AssetsTemplatesFrames = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any[]>([]);
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [sectorSelected, setSectorSelected] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>()
    const [showAssetFrameModal, setShowAssetFrameModal] = useState<boolean>(false)
    const [assetItemId, setAssetItemId] = useState<string | null>()
    const [imageGallery, setImageGallery] = useState<{ show: boolean, frames: any }>();
    const [showDeleteAssetModal, setShowDeleteAssetModal] = useState<boolean>(false)
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.assetsTemplates?.assetTemplateFrames?.data?.page);
    const rowsLoading = useAppSelector((state) => state.assetsTemplates?.assetTemplateFramesAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.assetsTemplates);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Asset Catalogs')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Asset Catalogs Frames')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    useEffect(() => {
        if (state.allAssetTemplates) {
            const rows =
                state.allAssetTemplates && Array.isArray(state.allAssetTemplates)
                    ? state.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.displayName, label: r?.displayName, value: r?._id, }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [state.allAssetTemplates]);

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.assetTemplateFrames) {
            const rows =
                state.assetTemplateFrames?.data?.elements && Array.isArray(state.assetTemplateFrames?.data.elements)
                    ? state.assetTemplateFrames?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.assetTemplateFrames]);

    useEffect(() => {
        const settingsFilters: any = {
            ...paginationState
        }
        if (sectorSelected) {
            settingsFilters.data = { assetTemplateId: sectorSelected?.value }
        }
        dispatch(tryToFetchAssetTemplateFrames(settingsFilters));
    }, [paginationState]);

    const onAddNewAssetTemplateFrame = (id?: string) => {
        if (id) {
            setAssetItemId(id)
        }
        setShowAssetFrameModal(true)
    }

    const onCloseAssetFrameModal = () => {
        setAssetItemId(null)
        setShowAssetFrameModal(false)
    }

    const onDeleteAssetFrame = async () => {
        try {
            if (assetItemId) {
                await dispatch(tryToDeleteAssetTemplateFrame(assetItemId)).unwrap();
                setShowToast({ type: 'success', message: 'Asset Catalog Frames successfully deleted' });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setAssetItemId(null)
        setShowDeleteAssetModal(false)
    }

    const onSaveAssetChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        setAssetItemId(null)
    }

    const onDeleteRequestModal = (assetTemplateId: string) => {
        setAssetItemId(assetTemplateId)
        setShowDeleteAssetModal(true)
    }

    const onCloseDeleteAssetModal = () => {
        setAssetItemId(null)
        setShowDeleteAssetModal(false)
    }

    const onChangeSelectedFilter = (value: any, type?: string) => {
        setSectorSelected(value)
        onResetPaginationState()
    }

    const onClearFilters = () => {
        onResetPaginationState()
        setSectorSelected(undefined)
    }

    const onSeeAllFrameImages = (frames: any) => {
        setImageGallery({
            show: true,
            frames: frames
        })
    }

    return (
        <div>
            <div className='mb-5'>
                <p className='page-title'>Manage Catalogs Frames</p>
            </div>
            <div className='grid grid-cols-4 gap-4'>
                <SelectCheckbox
                    name='Category'
                    placeholder=' '
                    dataQa={'Category'}
                    selectedOption={sectorSelected}
                    options={sectors || []}
                    onChangeSelectedOption={onChangeSelectedFilter}
                />
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Clear Filters'}
                    dataQa={'clear-all-filters'}
                    className='btn-primary-text-underline min-w-auto !mt-0 flex flex-row justify-end bg-transparent mr-2'
                    onClickButton={onClearFilters}
                />
                <Button
                    label={'Add New Asset Catalog Frame'}
                    className={`${(!accessControl || pageAccess?.actions?.['Add New Asset Catalog Frame']) ? 'btn-main' : 'btn-main-disable'}`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Asset Catalog Frame']) && onAddNewAssetTemplateFrame()}
                    dataQa={'add-new-asset-frame'}
                />
            </div>
            <div className='my-5 relative'>
                {(rows?.length > 0 || rowsLoading) ?
                    <AssetTemplateFramesTable
                        rows={rows}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        loading={rowsLoading}
                        setPaginationState={setPaginationState}
                        onDeleteAssetFrame={onDeleteRequestModal}
                        onEditAssetFrame={onAddNewAssetTemplateFrame}
                        onSeeAllFrameImages={onSeeAllFrameImages}
                    />
                    : <EmptyContainer text={'No Frames added yet.'} showImage={true} />
                }
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {imageGallery?.show &&
                <ImagesCarousel
                    images={imageGallery?.frames}
                    showModal={imageGallery?.show}
                    active={0}
                    handleClose={() => setImageGallery(undefined)}
                />
            }
            {showAssetFrameModal &&
                <AssetTemplateFrameModal
                    openModal={showAssetFrameModal}
                    itemId={assetItemId || ''}
                    sectors={sectors}
                    handleCloseModal={onCloseAssetFrameModal}
                    onSaveChanges={onSaveAssetChanges}
                />
            }
            <DeleteModal
                openDeleteModal={showDeleteAssetModal}
                handleCloseDeleteModal={onCloseDeleteAssetModal}
                onApproveDeletion={onDeleteAssetFrame}
            />
        </div>
    )
}

export default AssetsTemplatesFrames;