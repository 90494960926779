import { AxiosResponse } from 'axios';
import { OFFER_API_URL } from '../../../../config';
import offersAxios from '../../../../utils/axios/offers.axios';
import { PaginatedPricingHistory, PaginatedPricingHistoryDetails } from '../../../../interfaces/pricing-history/paginatedStore.type';

const tryToFetchWholesalePriceHistory = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse<PaginatedPricingHistory>> => {
    const url = `${OFFER_API_URL}/wholesale-pricing-update?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedPricingHistory>(url, data);
};

const tryToFetchDetailedWholesalePriceHistory = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any
): Promise<AxiosResponse<PaginatedPricingHistoryDetails>> => {
    const url = `${OFFER_API_URL}/wholesale-pricing-update/details/${filters}?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedPricingHistoryDetails>(url, data);
};

export const WholesalePriceHistoryService = {
    tryToFetchWholesalePriceHistory,
    tryToFetchDetailedWholesalePriceHistory
};
