import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IToast } from '../../../interfaces/components/IToast';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IPostageFees } from '../../../interfaces/postage-fees/IPostageFees';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import {
    tryToDeletePostageFee,
    tryToDownloadPostageFees,
    tryToFetchPostageFees
} from '../../../store/brokers/admin/postage-fees/postageFeesSlice';
import PostageFeesTable from './tables/PostageFeesTable';
import PostageFeeFilters from './details/PostageFeeFilters';
import PostageFeeActionsModal from './modals/PostageFeeActionsModal';
import ImportPostageFeesModal from './modals/ImportPostageFeesModal';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';


const PostageFees = () => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<IPostageFees[]>([]);
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [showImportModal, setShowImportModal] = useState<boolean>(false)
    const [postageFeeItemId, setPostageFeeItemId] = useState<string | null>();
    const [showPostageFeeModal, setShowPostageFeeModal] = useState<boolean>(false);
    const [loadingDownloadTemplate, setLoadingDownloadTemplate] = useState<boolean>(false)
    const [postageFeesData, setPostageFeesData] = useState<any>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.postageFees.postageFees?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const rowsLoading = useAppSelector((state) => state.postageFees.postagesAreLoading);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state) => state.postageFees);
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Postage Fees')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.postageFees) {
            const rows =
                state.postageFees?.data?.elements &&
                    Array.isArray(state.postageFees?.data.elements)
                    ? state.postageFees?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.postageFees]);

    const getPostageFeeData = async () => {
        const formatData: any = {
            ...paginationState,
            data: {
                'includePagination': true,
            }
        }
        if (selectedMarket) {
            formatData.data.filters = {
                ...formatData.data.filters,
                marketId: selectedMarket ? selectedMarket?._id : ''
            }
        }
        if (selectedSector) {
            formatData.data.filters = {
                ...formatData.data.filters,
                assetTemplateId: selectedSector ? selectedSector?._id : ''
            }
        }
        dispatch(tryToFetchPostageFees(formatData));
    }

    useEffect(() => {
        getPostageFeeData()
    }, [paginationState]);

    const getMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        getMarketData()
        dispatch(tryToFetchAllAssetsTemplates());
    }, []);

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const onDeletePostageFee = async () => {
        try {
            await dispatch(tryToDeletePostageFee(postageFeeItemId || ''));
            setShowToast({
                type: 'success',
                message: `Postage Fee successfully deleted`,
            });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setPostageFeeItemId(undefined);
        setShowDeleteModal(false);
    };

    const onAddPostageFee = (id?: string) => {
        if (id) {
            setPostageFeeItemId(id);
        }
        setShowPostageFeeModal(true);
    };

    const onClosePostageFeeModal = () => {
        setPostageFeeItemId(null);
        setShowPostageFeeModal(false);
    };

    const onSavePostageChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        getPostageFeeData()
    };

    const onDeletePostageRequest = (id: string) => {
        setShowDeleteModal(true);
        setPostageFeeItemId(id);
    };

    const onSelectMarket = (value: any) => {
        setSelectedMarket(value)
        onResetPaginationState()
    }

    const onSelectSector = (value: any) => {
        setSelectedSector(value)
        onResetPaginationState()
    }

    const onClearAllFilters = () => {
        setSelectedMarket(undefined)
        setSelectedSector(undefined)
        onResetPaginationState()
    }

    const onDownloadPostageFees = async () => {
        setLoadingDownloadTemplate(true)
        try {
            const response: any = await dispatch(tryToDownloadPostageFees(null)).unwrap()
            const findKeys = ['assetTemplate', 'assetTemplateId', 'market', 'marketId', 'postageFee']
            const formatData = response && response?.length > 0 && response?.map((item: any) => {
                const formatedDataArray: any = []
                formatedDataArray.push(item?.assetTemplate?.displayName || ' ')
                formatedDataArray.push(item?.assetTemplate?.id || ' ')
                formatedDataArray.push(item?.market?.label || ' ')
                formatedDataArray.push(item?.market?.id || ' ')
                formatedDataArray.push(item?.postageFee)
                return formatedDataArray
            })
            const formatResponse = [
                findKeys || [],
                ...formatData || [],
            ]
            setPostageFeesData(formatResponse || [])
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
            setLoadingDownloadTemplate(false)
        }
    }

    const onImportSuccessfully = () => {
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
        })
        setShowImportModal(false)
    }

    useEffect(() => {
        if (postageFeesData && loadingDownloadTemplate) {
            csvLink.current.link.click()
        }
        setLoadingDownloadTemplate(false)
    }, [postageFeesData])

    const onImportPostageFeesRequest = () => {
        setShowImportModal(true)
    }

    return (
        <div>
            <div>
                <p className='page-title'>Postage Fees</p>
            </div>
            <div>
                <PostageFeeFilters
                    markets={markets}
                    sectors={sectors}
                    selectedMarket={selectedMarket}
                    selectedSector={selectedSector}
                    onSelectMarket={onSelectMarket}
                    onSelectSector={onSelectSector}
                />
            </div>
            <div className='flex flex-row justify-end flex-wrap'>
                <Button
                    label={'Clear all filters'}
                    dataQa={'clear-all-filters'}
                    className={'btn-primary-text-underline flex flex-row justify-end bg-transparent mr-2 mb-1'}
                    onClickButton={onClearAllFilters}
                />
                <Button
                    label={'Add New Postage Fee'}
                    dataQa={'add-new-postage-fee'}
                    className={`${(!accessControl || pageAccess?.actions?.['Add New Postage Fee']) ? 'btn-main' : 'btn-main-disable'} mr-2 mb-1`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Postage Fee']) && onAddPostageFee()}
                />
                <Button
                    label={loadingDownloadTemplate ? 'Loading Data...' : 'Download Postage Fees'}
                    dataQa={loadingDownloadTemplate ? 'loading-data...' : 'download-postage-fees'}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Postage Fees']) ? 'btn-main' : 'btn-main-disable'} mr-2 mb-1`}
                    onClickButton={() => (!loadingDownloadTemplate && (!accessControl || pageAccess?.actions?.['Download Postage Fees'])) && onDownloadPostageFees()}
                />
                <CSVLink
                    ref={csvLink}
                    data={(postageFeesData && postageFeesData?.length > 0) ? postageFeesData?.filter((item: any, idx: number) => idx > 0) || [] : []}
                    headers={(postageFeesData && postageFeesData?.length > 0) ? postageFeesData?.[0] || [] : []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={`Postage-fees.csv`}
                ></CSVLink>
                <Button
                    label={'Import Postage Fees'}
                    dataQa={'import-postage-fees'}
                    className={(!accessControl || pageAccess?.actions?.['Import Postage Fees']) ? 'btn-main mb-1' : 'btn-main-disable mb-1'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Postage Fees']) && onImportPostageFeesRequest()}
                />
            </div>
            <div className='my-5 relativ'>
                {(rows?.length > 0 || rowsLoading) ?
                    <PostageFeesTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        markets={markets}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onDeletePostageFee={onDeletePostageRequest}
                        onEditPostageFee={onAddPostageFee}
                    />
                    : <EmptyContainer
                        text={'No Postage Fee added yet.'}
                        showImage={true}
                    />
                }
            </div>
            <PostageFeeActionsModal
                markets={markets}
                sectors={sectors}
                postageFeeItemId={postageFeeItemId}
                openActionsModal={showPostageFeeModal}
                handleCloseModal={onClosePostageFeeModal}
                onSaveChanges={onSavePostageChanges}
            />
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeletePostageFee}
            />
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
            {showImportModal &&
                <ImportPostageFeesModal
                    openImportModal={showImportModal}
                    handleCloseModal={() => setShowImportModal(false)}
                    onImportSuccessfully={onImportSuccessfully}
                />
            }
        </div>
    );
};

export default PostageFees;
