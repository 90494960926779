import React from 'react';
import LineChart from '../../../../shared/line-chart';
import WhiteContainer from '../../../../shared/white-container';


interface ILineChartDetails {
    title: string;
    data?: any;
}

const LineChartDetails = ({
    title,
    data
}: ILineChartDetails) => {
    return (
        <WhiteContainer type='rounded'>
            <div>
                <div className='flex flex-row justify-between items-center relative'>
                    <p className='pl-2 font-bold'>{title}</p>
                    <div className='flex flex-row text-black font-medium text-sm pl-3 cursor-pointer'>
                        <p className='!text-primary-light !font-normal'>Monthly</p>
                    </div>
                </div>
                <div>
                    <LineChart
                        xAxisData={data && data?.length > 0 ? data?.map((item: any) => item?.name) : []}
                        chartData={data && data?.length > 0 ? data?.map((item: any) => item?.data) : []}
                    />
                </div>
            </div>
        </WhiteContainer>
    )
}

export default LineChartDetails;