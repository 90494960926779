import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import {
    useNavigate,
    useParams,
    useLocation
} from 'react-router-dom';
import {
    useAppDispatch,
    useAppSelector
} from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import { IQuestion, IQuestionDependencies } from '../../../interfaces/questions/IQuestions';
import { IDisplayQuestion, ISingleOrderSchema } from '../../../interfaces/orders/IOrdersSchema';
import {
    tryToAcceptOrder,
    tryToFetchImeiStatusCheck,
    tryToFetchSingleOrder,
    tryToGetCurrentImeiStatus,
    tryToRejectOrder,
    // tryToFindItemByImei,
    tryToRevalueOrder,
    tryToReviewOrder,
} from '../../../store/brokers/partners/orders/ordersSlice';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import {
    tryToFetchAssetTemplateFieldsFilter,
    tryToFetchSingleAssetTemplateFrame
} from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToFetchAssetValues } from '../../../store/brokers/admin/assets/assetsSlice';
import { tryToFetchBestPrice } from '../../../store/brokers/partners/pricing/pricingSlice';
import { tryToFetchSectionLocations } from '../../../store/brokers/admin/warehouses/warehousesSlice';
import { tryToFetchAllWarehouseSections } from '../../../store/brokers/admin/partners/partnersSlice';
import { tryToGetAllQuestions } from '../../../store/brokers/shared-endpoints/questions/questionsSlice';
import RevaluateContainerLoading from './loading/RevaluateContainerLoading';
import RevaluateInventoryFields from './details/RevaluateInventoryFields';
import RevaluateQuestions from './item-details/RevaluateQuestions';
import RevaluateItemImages from './details/RevaluateItemImages';
import OrderItemLocation from './details/OrderItemLocation';
import ProductInfo from './details/ProductInfo';
import Countdown from './details/CountDown';
import ReviewDrawScratchesModal from './modals/ReviewDrawScratchesModal';
import RevaluateMessageModal from './modals/RevaluateMessageModal';
import BlacklistedImeiModal from './modals/BlacklistedImeiModal';
import RevisedOfferModal from './modals/RevisedOfferModal';
import BreakCashoutModal from './modals/BreakCashoutModal';
import ImeiCheckModal from './modals/ImeiCheckModal';
import RejectModal from './modals/RejectModal';
import SelectCheckbox from '../../../shared/select-checkbox';
import ImagesCarousel from '../../../shared/images-carousel';
import WhiteContainer from '../../../shared/white-container';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';
import Error from '../../../shared/error';
import Input from '../../../shared/input';

type FormValues = {
    message: string;
    reason?: string;
    newPrice: number;
};

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

type IQuestionSecondary = IQuestion & { show: boolean }

const OrderReview = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id, itemId } = useParams();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [searchInDB, setSearchInDB] = useState<any>();
    const [sections, setSections] = useState<any>();
    const [showRevaluateImages, setShowRevaluateImages] = useState<boolean>(false);
    const [revaluateImages, setRevaluateImages] = useState<any>();
    const [orderDetails, setOrderDetails] = useState<ISingleOrderSchema>();
    const [formData] = useState<any>();
    const [userProfile, setUserProfile] = useState<any>();
    const [priceFound, setPriceFound] = useState<number>();
    const [filterErrors, setFilterErrors] = useState<any>();
    const [imeiStatusRes, setImeiStatusRes] = useState<any>();
    const [filesSelected, setFilesSelected] = useState<any>();
    const [revalueError, setRevalueError] = useState<string>();
    const [templateFields, setTemplateFields] = useState<any>();
    const [extendedFields, setExtendedFields] = useState<any>();
    const [invertoryFields, setInvertoryFields] = useState<any>();
    const [scannedLocation, setScannedLocation] = useState<any>();
    const [selectedSection, setSelectedSection] = useState<any>();
    const [loadingPrice, setLoadingPrice] = useState<boolean>(false);
    const [inventoryFormData, setInventoryFormData] = useState<any>();
    const [extendedFormData, setExtendedFormData] = useState<any>();
    const [loadingValues, setLoadingValues] = useState<boolean>(false);
    const [loadingFields, setLoadingFields] = useState<boolean>(false);
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>();
    const [loadingImeiStatus, setLoadingImeiStatus] = useState<boolean>(false)
    const [showRevaluateModal, setShowRevaluateModal] = useState<boolean>(false)
    const [loadingOptionsLocation, setLoadingOptionsLocation] = useState<boolean>(false);
    const [inventoryFilterErrors, setInventoryFilterErrors] = useState<any>();
    const [assetTemplateSelected, setAssetTemplateSelected] = useState<any>();
    const [loadingOrder, setLoadingOrder] = useState<boolean>(true);
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>();
    const [imeiSearch, setImeiSearch] = useState<string>();
    const [imeiError, setImeiError] = useState<string>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [fieldDetails, setFieldDetails] = useState<any>();
    const [imeiNotExist, setImeiNotExist] = useState<any>();
    const [searchField, setSearchField] = useState<any>();
    const [loadingOptions, setLoadingOptions] = useState<any>();
    const [filteredFields, setFilteredFields] = useState<any>();
    const [showDrawModal, setShowDrawModal] = useState<{ show: boolean, question: string }>();
    const [drawing, setDrawing] = useState<any>();
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
    const [enableRevaluate, setEnableRevaluate] = useState<boolean>(false);
    const [selectedPrimaryAnswers, setSelectedPrimaryAnswers] = useState<any>();
    const [selectedSecondaryAnswers, setSelectedSecondaryAnswers] = useState<any>();
    const [enableAcceptReview, setEnableAcceptReview] = useState<{ enable: boolean, status?: string }>()
    const [detailsFound, setDetailsFound] = useState<any>();
    const [rejectError, setRejectError] = useState<string>();
    const [filterColor, setFilterColor] = useState<boolean>();
    const [categoryFrames, setCategoryFrames] = useState<any>();
    const [selectedLocation, setSelectedLocation] = useState<any>();
    const [filteredLocations, setFilteredLocations] = useState<any>();
    const [responseLocations, setResponseLocations] = useState<any>();
    const [searchInDBLocation, setSearchInDBLocation] = useState<any>();
    const [questionsLoading, setQuestionsLoading] = useState<boolean>(true);
    const [primaryQuestions, setPrimaryQuestions] = useState<Array<IQuestion>>();
    const [matchedItems, setMatchedItems] = useState<{ [key: string]: boolean }>();
    const [showBlacklistedModal, setShowBlackListedModal] = useState<boolean>(false);
    const [searchFieldLocation, setSearchFieldLocation] = useState<any | null>(null);
    const [showImeiModal, setShowImeiModal] = useState<{ show: boolean, item: any }>();
    const [findBarcodeLocationsResponse, setFindBarcodeLocationsResponse] = useState<any>();
    const [secondaryQuestions, setSecondaryQuestions] = useState<Array<IQuestionSecondary>>();
    const [breakCashout, setBreakCashout] = useState<{ show: boolean, questions: Array<{ question: string, answer: string }> }>()
    const [revisedOfferModal, setRevisedOfferModal] = useState()
    const orderDetailsInfo = useAppSelector((state) => state?.orders?.orderDetail?.data);
    const totalCosts = useAppSelector((state) => state?.orders?.orderDetails?.totalCosts);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const orderStatus = useAppSelector((state) => state?.orders?.orderDetails?.changedStatus);
    const [userProfileMutation] = useUserProfileMutation();
    const now = moment();
    const targetDate = moment(orderDetails?.orderData.cashedOutDate);
    const offerExpired = now.diff(targetDate, 'days') >= 14;

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.map((file: any) => {
            const reader = new FileReader();
            reader.onload = async (event: any) => {
                await setFilesSelected((prevState: any) => {
                    return [...prevState || [], file]
                })
            };
            reader.readAsDataURL(file);
            return file
        });
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: true })

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
    } = useForm<FormValues>({});

    useEffect(() => {
        if (assetTemplateSelected && primaryQuestions && primaryQuestions?.length > 0 && assetTemplateSelected?.name === 'phones') {
            const findColors = primaryQuestions && primaryQuestions?.length > 0 && primaryQuestions?.find((item: IQuestion) => item?.field === 'color' && item?.extendedAssetsField)
            setFilterColor(!!findColors)
        }
    }, [assetTemplateSelected, primaryQuestions])

    const getUserProfileInfo = async () => {
        const response = await userProfileMutation(null).unwrap()
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ ...s, label: s?.displayName, value: s?.id })) : []
        setBrokerCategories(formatSectors || [])
        setUserProfile(response?.data || undefined)
    }

    const onChangeItemImeiStatus = async () => {
        try {
            const formatData = {
                imei: imeiSearch
            }
            await dispatch(tryToFetchImeiStatusCheck({ orderId: id || '', itemId: itemId || '', data: formatData })).unwrap()
        } catch (err) {
            setImeiError(`${err}`)
        }
        setLoadingSubmit(false)
        setLoadingImeiStatus(false)
    }

    const onCheckImeiStatus = async () => {
        if (!imeiSearch) {
            setImeiError('Please write IMEI or fill the checkbox that IMEI doesn\'t exists.')
            return
        }
        setLoadingImeiStatus(true)
        try {
            const res = await dispatch(tryToGetCurrentImeiStatus(imeiSearch || '')).unwrap()
            setImeiError(undefined)
            setLoadingImeiStatus(false)
            return res?.data?.blackliststatus
        } catch (err: any) {
            setImeiError(`${err}`)
            setImeiStatusRes(undefined)
        }
        setLoadingImeiStatus(false)
    }

    const onGettingQuestions = async () => {
        try {
            const formatData = {
                categoryId: assetTemplateSelected?.id || query.get('category'),
                service: query.get('source') || undefined,
                marketId: activeMarket?.market?._id,
                review: true
            }
            const response: any = await dispatch(tryToGetAllQuestions(formatData)).unwrap()
            setPrimaryQuestions(response?.primaryQuestions)
            const formatSecondaryQuestions: Array<IQuestionSecondary> = [];
            response?.secondaryQuestions && response?.secondaryQuestions?.length > 0 &&
                response?.secondaryQuestions?.forEach((item: IQuestion) => {
                    const filterQuestions = [...response?.primaryQuestions || [], ...response?.secondaryQuestions || []].map(item => item.question);
                    const formatDependencies = (item?.dependencies && item?.dependencies?.length > 0) ? item?.dependencies?.filter((dependency: any) => filterQuestions?.includes(dependency?.question)) : undefined
                    formatSecondaryQuestions.push({
                        ...item || {},
                        show: !formatDependencies || formatDependencies?.length <= 0,
                        type: 'SECONDARY',
                        dependencies: formatDependencies
                    })
                })
            setSecondaryQuestions(formatSecondaryQuestions)
        } catch (err) {
            // error here
        }
        setQuestionsLoading(false)
    }

    useEffect(() => {
        if (query.get('source') && activeMarket && assetTemplateSelected?.id) {
            onGettingQuestions()
            setQuestionsLoading(true)
        }
    }, [query.get('source'), activeMarket, assetTemplateSelected])

    const onGettingWarehouseSections = async () => {
        if (orderDetails?.item?.exchangeDefinition?.warehouse?._id) {
            try {
                const response = await dispatch(tryToFetchAllWarehouseSections({ warehouseId: orderDetails?.item?.exchangeDefinition?.warehouse?._id || '', type: 'space', category: assetTemplateSelected?.id || query.get('category') })).unwrap()
                const formatResponse = response && response?.length > 0 && response?.map((item: any) => ({ ...item, value: item?._id, label: item?.name }))
                setSections(formatResponse || undefined)
            } catch (err) {
                // error here
            }
        }
    }

    const getOrderDetailsData = async () => {
        try {
            const formatData = {
                orderId: id,
                data: {
                    itemId,
                    marketId: activeMarket?.market?._id
                }
            }
            await dispatch(tryToFetchSingleOrder(formatData)).unwrap();
        } catch (err) {
            setLoadingOrder(false)
            setShowToast({ type: 'error', message: `${err}` })
        }
    }

    useEffect(() => {
        getUserProfileInfo()
        if (id && itemId && activeMarket?.market?._id) {
            getOrderDetailsData()
        }
    }, [])

    const onGoingToOrderDetails = () => {
        navigate(`/order-items/${id}/${itemId}`);
    }

    useEffect(() => {
        if (orderStatus === 'required_return') {
            onGoingToOrderDetails()
        }
    }, [orderStatus])

    useEffect(() => {
        if (orderDetailsInfo && orderDetailsInfo?.item?.warehouseImages && orderDetailsInfo?.item?.warehouseImages?.length > 0) {
            const formatImages = orderDetailsInfo?.item?.warehouseImages?.map((item) => ({ url: item }))
            setRevaluateImages(formatImages || undefined)
        } else {
            setRevaluateImages(undefined)
        }
        if (orderDetailsInfo) {
            if (JSON.stringify(orderDetailsInfo) !== JSON.stringify(orderDetails)) {
                setOrderDetails(orderDetailsInfo || undefined)
            }
            setLoadingOrder(false)
        }
    }, [orderDetailsInfo])

    const onResponseNotFound = () => {
        setSearchFieldLocation(undefined)
        setSearchInDBLocation(undefined)
        setFilteredLocations(undefined)
        setResponseLocations(undefined)
    }

    useEffect(() => {
        onGettingWarehouseSections()
    }, [orderDetails])

    useEffect(() => {
        if (activeMarketState && (JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket))) {
            setLoadingOrder(true)
            setActiveMarket(activeMarketState || undefined)
        }
    }, [activeMarketState])

    useEffect(() => {
        if (id && itemId && activeMarket?.market?._id) {
            getOrderDetailsData()
        }
        if (!sections) {
            onGettingWarehouseSections()
        }
    }, [activeMarket, id, itemId])

    const onGettingAllAssetFields = async () => {
        if (assetTemplateSelected) {
            try {
                const assetSelected = assetTemplateSelected?.id
                const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(assetSelected)).unwrap()
                if (response?.fields) {
                    setFieldDetails(response?.fields)
                    setInvertoryFields(response?.inventoryFields)
                    setExtendedFields(response?.extendedFields)
                }
            } catch (err) {
                // error here
            }
        }
    }

    const onGettingFieldValues = async () => {
        if (assetTemplateSelected?.id || query.get('category')) {
            try {
                let data: any = {
                    exclude: filterColor || false,
                    values: []
                }
                if (selectedPrimaryAnswers) {
                    const keysArray = selectedPrimaryAnswers && Object.keys(selectedPrimaryAnswers)
                    const formatValues = keysArray && keysArray?.length > 0 && keysArray?.map((name: string) => { return { [selectedPrimaryAnswers[name].field]: selectedPrimaryAnswers?.[name]?.value } })
                    data = {
                        ...data || {},
                        values: formatValues || []
                    }
                }
                if (searchField) {
                    data = {
                        ...data || {},
                        search: {
                            ...searchField || {}
                        }
                    }
                }
                const response = await dispatch(tryToFetchAssetValues({ id: assetTemplateSelected?.id || query.get('category'), data })).unwrap()
                setTemplateFields(response || [])
            } catch (err) {
                // error here
            }
            setLoadingFields(false)
            setLoadingOptions(undefined)
        }
    }

    const onGettingAssetTemplateFrames = async () => {
        try {
            const response = await dispatch(tryToFetchSingleAssetTemplateFrame(assetTemplateSelected?.id)).unwrap()
            setCategoryFrames(response?.frames || undefined)
        } catch (err) {
            setCategoryFrames(undefined)
        }
    }

    useEffect(() => {
        if (assetTemplateSelected?.id) {
            onGettingAllAssetFields()
            onGettingFieldValues()
            if (!sections) {
                onGettingWarehouseSections()
            }
        }
    }, [assetTemplateSelected?.id])

    useEffect(() => {
        if (orderDetails?.item?.itemCode) {
            setImeiSearch(orderDetails.item.itemCode)
        }
    }, [orderDetails?.item?.itemCode])

    const onCheckingDependencies = (selectedPrimQuestions: any, selectedSecQuestions: any, match?: boolean) => {
        let formatSelectedSecondaryQuestions = selectedSecQuestions ? Object.assign(selectedSecQuestions) : {}
        const formatSecondaryQuestions = (secondaryQuestions && secondaryQuestions?.length > 0) ? secondaryQuestions?.map((item: IQuestionSecondary) => {
            if (item?.dependencies && item?.dependencies?.length > 0) {
                let showQuestions = true
                item?.dependencies?.forEach((dependency: IQuestionDependencies) => {
                    if (dependency?.question) {
                        let findQuestions
                        if (selectedPrimQuestions && selectedPrimQuestions?.[dependency?.question]) {
                            findQuestions = selectedPrimQuestions?.[dependency?.question]
                        }
                        if (selectedSecQuestions && selectedSecQuestions?.[dependency?.question]) {
                            findQuestions = selectedSecQuestions?.[dependency?.question]
                        }
                        if (findQuestions) {
                            showQuestions = item?.dependenciesType === 'match_one' ? true : showQuestions
                            if (dependency?.answerValues?.includes(findQuestions?.value)) {
                                if (match) {
                                    const findAnswer: IDisplayQuestion | undefined = (orderDetails?.item?.displayQuestions && orderDetails?.item?.displayQuestions?.length > 0) ? orderDetails?.item?.displayQuestions?.find((question: IDisplayQuestion) => question?.question === item?.question) : undefined
                                    let questionValue
                                    if (findAnswer && item.answers?.type) {
                                        switch (item.answers?.type) {
                                            case 'SINGLE_CHOICE':
                                                questionValue = {
                                                    ...findAnswer || {},
                                                    id: findAnswer?.answers?.[0]?.id,
                                                    value: findAnswer?.answers?.[0]?.answer,
                                                    label: findAnswer?.answers?.[0]?.answer
                                                }
                                                break;
                                            case 'MULTIPLE_CHOICE':
                                                questionValue = (findAnswer?.answers && findAnswer?.answers?.length > 0) &&
                                                    findAnswer?.answers?.map((item: any) => {
                                                        return {
                                                            ...item,
                                                            value: item?.answer,
                                                            label: item?.answer
                                                        }
                                                    })
                                                break;
                                            case 'NUMBER_INPUT':
                                            case 'TEXT_INPUT':
                                                questionValue = {
                                                    ...findAnswer || {},
                                                    value: findAnswer?.value,
                                                }
                                                break;
                                        }
                                    }
                                    formatSelectedSecondaryQuestions[item?.question] = questionValue
                                }
                            } else {
                                showQuestions = item?.dependenciesType === 'match_one' ? showQuestions : false
                                if (formatSelectedSecondaryQuestions?.[item.question]) {
                                    formatSelectedSecondaryQuestions = {
                                        ...formatSelectedSecondaryQuestions || {},
                                        [item.question]: undefined
                                    }
                                }
                            }
                        } else {
                            showQuestions = item?.dependenciesType === 'match_one' ? showQuestions : false
                            if (formatSelectedSecondaryQuestions?.[item.question]) {
                                formatSelectedSecondaryQuestions = {
                                    ...formatSelectedSecondaryQuestions || {},
                                    [item.question]: undefined
                                }
                            }
                            setMatchedItems({
                                ...matchedItems,
                                [item.question]: false
                            })
                        }
                    }
                })
                return {
                    ...item,
                    show: showQuestions
                }
            } else {
                return item
            }
        }) : undefined
        setSelectedSecondaryAnswers(formatSelectedSecondaryQuestions)
        setSecondaryQuestions(formatSecondaryQuestions)
    }

    const checkMatchingAnswers = (sellerAnswers: Array<{ id?: string, answer?: any }> | [], currentAnswers: Array<{ id: string, value: string }> | [] | { id: string, value: string }) => {
        if (sellerAnswers && sellerAnswers?.length > 0 && currentAnswers) {
            if (Array.isArray(currentAnswers) && (sellerAnswers?.length !== currentAnswers?.length)) {
                return false;
            }
            return sellerAnswers.every(obj1 =>
                !Array.isArray(currentAnswers) ? obj1?.answer === currentAnswers?.value : currentAnswers?.find((obj2: { id: string, value: string }) => obj2.id === obj1?.id && obj2.value === obj1?.answer)
            );
        } else {
            return (sellerAnswers?.length <= 0 && (Array.isArray(currentAnswers) && currentAnswers?.length <= 0)) || (!sellerAnswers && !currentAnswers)
        }
    }

    const onCheckToShowRevaluateOrAccept = () => {
        let acceptShow = true
        let reviewShow = true
        let revaluate = true
        if (primaryQuestions && primaryQuestions?.length > 0) {
            primaryQuestions?.forEach((item: IQuestion) => {
                const findField = fieldDetails && fieldDetails?.find((field: any) => field?.name === item?.field)
                const findSelectedValue = selectedPrimaryAnswers && selectedPrimaryAnswers[item?.question]
                if (!findSelectedValue || (!findSelectedValue && findField.required)) {
                    acceptShow = false
                    reviewShow = false
                    revaluate = false
                } else if (acceptShow && reviewShow && revaluate) {
                    const findQuestionFromSeller = (orderDetails?.item?.displayQuestions && orderDetails?.item?.displayQuestions?.length > 0)
                        && orderDetails?.item?.displayQuestions?.find((question: IDisplayQuestion) => question?.id === item._id)
                    if (!(findQuestionFromSeller && (findQuestionFromSeller?.answers?.[0]?.answer === findSelectedValue?.value))) {
                        acceptShow = false
                    }
                }
            })
        }
        if (secondaryQuestions && secondaryQuestions?.length > 0) {
            secondaryQuestions?.filter((item: IQuestionSecondary) => item?.show)?.forEach((item: IQuestion) => {
                const findSelectedValue = selectedSecondaryAnswers && selectedSecondaryAnswers[item?.question]
                if ((!findSelectedValue || findSelectedValue?.value === '') || ((!findSelectedValue || findSelectedValue?.value === '') && item?.required)) {
                    acceptShow = false
                    reviewShow = false
                    revaluate = false
                } else if (acceptShow && reviewShow && revaluate) {
                    const findQuestionFromSeller: IDisplayQuestion | undefined = (orderDetails?.item?.displayQuestions && orderDetails?.item?.displayQuestions?.length > 0)
                        ? orderDetails?.item?.displayQuestions?.find((question: IDisplayQuestion) => question?.id === item._id) : undefined
                    if (findQuestionFromSeller) {
                        const doesAnswerMatch = ['MULTIPLE_CHOICE', 'SINGLE_CHOICE']?.includes(item?.answers?.type) ? checkMatchingAnswers(findQuestionFromSeller?.answers || [], findSelectedValue || []) : (findQuestionFromSeller?.value === findSelectedValue?.value)
                        if (!doesAnswerMatch) {
                            acceptShow = false
                        }
                    } else {
                        acceptShow = false
                    }
                }
            })
        }
        setEnableAcceptReview({ enable: acceptShow || reviewShow, status: acceptShow ? 'accept' : reviewShow ? 'review' : undefined })
        setEnableRevaluate(revaluate)
    }

    useEffect(() => {
        if (selectedPrimaryAnswers || selectedSecondaryAnswers) {
            onCheckingDependencies(selectedPrimaryAnswers, selectedSecondaryAnswers)
        }
        if ((primaryQuestions || secondaryQuestions) && orderDetailsInfo) {
            onCheckToShowRevaluateOrAccept();
        }
    }, [selectedPrimaryAnswers, selectedSecondaryAnswers, matchedItems])

    useEffect(() => {
        if (selectedPrimaryAnswers) {
            setLoadingValues(false)
        }
        onGettingFieldValues()
    }, [selectedPrimaryAnswers, searchField])

    useEffect(() => {
        if (assetTemplateSelected?.id) {
            onGettingAllAssetFields()
            onGettingFieldValues()
            onGettingAssetTemplateFrames()
            onGettingWarehouseSections()
        }
    }, [assetTemplateSelected])

    useEffect(() => {
        if (query.get('category') && brokerCategories) {
            const findCategory = brokerCategories.length > 0 && brokerCategories?.find((category) => category?.value === query.get('category'))
            setAssetTemplateSelected(findCategory || undefined)
            if (orderDetails?.item?.catalog?.code !== (findCategory?.name || '').toLowerCase()) {
                setSelectedPrimaryAnswers(undefined)
                setSelectedSecondaryAnswers(undefined)
            }
        }
    }, [brokerCategories, query.get('category')])

    const onGoBack = () => {
        navigate(`/order-items/${id}/${itemId}`)
    }

    const onClickRevaluateImages = () => {
        setShowRevaluateImages(true)
    }

    const onChangeCategorySelected = (value: any, name?: string) => {
        setAssetTemplateSelected(value)
        if (value.id !== assetTemplateSelected?.id) {
            setTemplateFields(undefined)
            setInventoryFormData(undefined)
            setExtendedFormData(undefined)
            setSelectedPrimaryAnswers(undefined)
            setSelectedSecondaryAnswers(undefined)
            setMatchedItems(undefined)
        }
        reset();
    }

    const onFormatQuestions = (onlySecondary?: boolean) => {
        let formatFormData: { [key: string]: string } = {};
        const formatQuestions: Array<any> = [];
        if (primaryQuestions && primaryQuestions?.length > 0 && !onlySecondary) {
            primaryQuestions?.forEach((item: IQuestion) => {
                if (item?.field) {
                    formatFormData = {
                        ...formatFormData || {},
                        [item?.field]: selectedPrimaryAnswers?.[item?.question]?.value
                    }
                }
                formatQuestions.push({
                    question: item?.question,
                    id: item?._id,
                    answers: [
                        {
                            answer: selectedPrimaryAnswers?.[item?.question]?.value
                        }
                    ]
                })
            })
        }
        if (secondaryQuestions && secondaryQuestions?.length > 0) {
            secondaryQuestions?.forEach((item: IQuestion) => {
                if (item?.answers?.type && selectedSecondaryAnswers?.[item?.question]) {
                    let answerValue
                    let value
                    switch (item?.answers?.type) {
                        case 'SINGLE_CHOICE': {
                            if (selectedSecondaryAnswers?.[item?.question]?.value) {
                                answerValue = [{
                                    id: selectedSecondaryAnswers?.[item?.question]?.id,
                                    answer: selectedSecondaryAnswers?.[item?.question]?.value,
                                }]
                            }
                            break;
                        }
                        case 'MULTIPLE_CHOICE':
                            answerValue = (selectedSecondaryAnswers?.[item?.question]?.value && selectedSecondaryAnswers?.[item?.question]?.value?.length > 0) &&
                                selectedSecondaryAnswers?.[item?.question]?.value?.map((item: any) => {
                                    return {
                                        id: item?.id,
                                        answer: item?.value,
                                    }
                                })
                            break;
                        case 'NUMBER_INPUT':
                        case 'TEXT_INPUT': {
                            if (selectedSecondaryAnswers?.[item?.question]?.value) {
                                value = selectedSecondaryAnswers?.[item?.question]?.value
                            }
                            break;
                        }
                    }
                    if (answerValue || value) {
                        formatQuestions.push({
                            question: item?.question,
                            id: item?._id,
                            answers: answerValue || [],
                            value: value || undefined
                        })
                    }
                }
            })
        }
        return {
            assets: formatFormData,
            questions: formatQuestions
        }
    }

    const onSubmitRevaluate = async (data: FormValues) => {
        if (loadingSubmit) {
            return;
        }
        setLoadingSubmit(true)
        // eslint-disable-next-line
        if (!data?.message || data?.message === '' || !(/^(?!\s+$).*/.test(data?.message))) {
            setRevalueError(`Message should not be empty!`)
            setLoadingSubmit(false);
            return;
        }
        if ((assetTemplateSelected?.name || '').toLowerCase() === 'clothing' && !data?.reason) {
            setRevalueError(`Reason should not be empty!`)
            setLoadingSubmit(false);
            return;
        }
        if (priceFound && data?.newPrice < priceFound) {
            setRevalueError(`Price cannot be lower than ${priceFound}!`)
            setLoadingSubmit(false);
            return;
        }
        try {
            const dataForm = new FormData()
            const formatAsset = onFormatQuestions()?.assets
            const formatQuestions = onFormatQuestions(true)?.questions;
            const formatQuestionsData = (formatQuestions && formatQuestions?.length > 0) && formatQuestions?.map((question: any) => {
                const findDrawing = drawing?.[question?.id]
                if (findDrawing) {
                    return {
                        ...question || {},
                        drawFile: `draw_file_${question?.id}`
                    }
                } else {
                    return question
                }
            })
            dataForm.append('assetTemplateId', assetTemplateSelected?.id || orderDetails?.item?.catalog?.id || '')
            dataForm.append('marketId', activeMarket?.market?._id || '')
            dataForm.append('newPrice', (data?.newPrice).toString())
            dataForm.append('itemId', itemId || '')
            dataForm.append('questions', JSON.stringify(formatQuestionsData))
            dataForm.append('asset', JSON.stringify(formatAsset))
            dataForm.append('pricingEffects', JSON.stringify(detailsFound?.pricingEffects))
            dataForm.append('computedNewPrice', JSON.stringify(detailsFound?.computedQuantity))
            dataForm.append('originalBroker', JSON.stringify(detailsFound?.originalBroker))
            dataForm.append('displayPriceInfo', JSON.stringify(detailsFound?.displayPriceInfo))
            dataForm.append('message', data?.message)
            filesSelected && filesSelected?.length > 0 && filesSelected?.map((file: any, index: number) => {
                dataForm.append(`image${index + 1}`, file)
                return file
            })
            if (invertoryFields && inventoryFormData) {
                dataForm.append('inventory', JSON.stringify(inventoryFormData))
            }
            if (extendedFields && extendedFormData) {
                dataForm.append('extended', JSON.stringify(extendedFormData))
            }
            if ((assetTemplateSelected?.name || '').toLowerCase() === 'clothing' && data?.reason) {
                dataForm.append('reason', data?.reason)
            }
            if (drawing) {
                const findDrawingKeys = Object.keys(drawing)
                findDrawingKeys && findDrawingKeys?.length > 0 && findDrawingKeys?.forEach((draw: string) => {
                    dataForm.append(`draw_file_${draw}`, drawing?.[draw].blob)
                })
            }
            if (userProfile?.inventory && selectedLocation?._id) {
                dataForm.append('location', selectedLocation?._id)
            }

            if (!imeiNotExist && imeiSearch) {
                dataForm.append('itemCode', imeiSearch)
            }
            await dispatch(tryToRevalueOrder({ data: dataForm, orderId: id || '' })).unwrap()
            document.body.style.overflow = 'auto';
            onGoingToOrderDetails()
        } catch (err: any) {
            setRevalueError(`${err?.response?.message || err?.response || err || 'Something went wrong!'}`)
        }
        setLoadingSubmit(false)
    }

    const onChangeSearchAssetFields = (e: any, type?: string, index?: string) => {
        if (type && e && index) {
            if (templateFields && templateFields?.[index]) {
                const formatFilteredFields = templateFields?.[index] && templateFields?.[index]?.length > 0 && templateFields?.[index]?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
                if (formatFilteredFields && formatFilteredFields?.length > 0) {
                    setSearchInDB({ [index]: true, searchValue: e })
                    setFilteredFields({ [index]: formatFilteredFields })
                }
                else {
                    setFilteredFields(undefined)
                    setLoadingOptions({ [index]: true })
                    setSearchField({
                        [index]: e
                    })
                    if (selectedPrimaryAnswers?.[type]) {
                        const formatFormData = Object.assign(selectedPrimaryAnswers)
                        delete formatFormData[type]
                        setSelectedPrimaryAnswers(
                            {
                                ...formatFormData || {}
                            }
                        )
                    }
                }
            }
        } else {
            setFilteredFields(undefined)
        }
    }

    const onMenuCloseAfterSearch = (type?: string) => {
        if (type && searchField?.[type]) {
            setSearchField(undefined)
        }
    }

    const onChangeSelectedInventoryValue = (value: any, type?: string) => {
        if (type) {
            const findField = invertoryFields && invertoryFields?.length > 0 && invertoryFields?.find((field: any) => field?.name === type);
            setInventoryFormData({
                ...(inventoryFormData || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
        }
    }

    const onChangeSelectedExtendedValue = (value: any, type?: string) => {
        if (type) {
            const findField = extendedFields && extendedFields?.length > 0 && extendedFields?.find((field: any) => field?.name === type);
            setExtendedFormData({
                ...(extendedFormData || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
        }
    }

    const onChangeFilterExtendedInput = (value: string | null | undefined, type: string) => {
        const findField = extendedFields && extendedFields?.length > 0 && extendedFields?.find((field: any) => field?.name === type);
        setExtendedFormData({
            ...(extendedFormData || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
    };

    const onChangeFilterInventoryInput = (value: string | null | undefined, type: string) => {
        const findField = invertoryFields && invertoryFields?.length > 0 && invertoryFields?.find((field: any) => field?.name === type);
        setInventoryFormData({
            ...(inventoryFormData || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
    };

    const onFindingBestPrice = async () => {
        setLoadingPrice(true)
        try {
            let assetData: any = onFormatQuestions()?.assets
            if ((assetTemplateSelected?.name).toLowerCase() === 'clothing') {
                assetData = orderDetails?.item?.exchangeDefinition?.assets?.[0]?.descriptor || undefined
            }
            const data: any = {
                asset: assetData,
                questions: onFormatQuestions(true)?.questions,
                service: query.get('source'),
                assetTemplate: assetTemplateSelected?.name ? (assetTemplateSelected?.name || '').toLowerCase() : orderDetails?.item?.catalog?.code || '',
                country: activeMarket?.market?.name,
                region: activeMarket?.market?.name,
                originalBrokerId: orderDetails?.item?.originalBrokerData?.brokerId || undefined,
                brokerId: userProfile?._id || '',
                ...(inventoryFormData || {})
            }
            const response = await dispatch(tryToFetchBestPrice(data)).unwrap()
            setPriceFound(response?.data?.exchangeDefinition?.counter?.quantity || undefined)
            setDetailsFound({
                computedQuantity: response?.data?.exchangeDefinition?.counter?.computedQuantity || undefined,
                pricingEffects: response?.data?.exchangeDefinition?.pricingEffects || undefined,
                originalBroker: response?.data?.exchangeDefinition?.originalBroker || undefined,
                displayPriceInfo: response?.data?.exchangeDefinition?.displayPriceInfo || undefined,
            })
            response?.data?.exchangeDefinition?.counter?.quantity && setValue('newPrice', response?.data?.exchangeDefinition?.counter?.quantity)
        } catch (err) {
            setPriceFound(undefined)
            setDetailsFound(undefined)
        }
        setRevalueError(undefined)
        document.body.style.overflow = 'hidden';
        setShowRevaluateModal(true)
        setLoadingSubmit(false)
        setLoadingPrice(false)
    }

    const findIfAnyBreakCashoutAnswer = () => {
        if (secondaryQuestions && secondaryQuestions?.length > 0 && selectedSecondaryAnswers) {
            const breakErrors: Array<{ question: string, answer: any }> = []
            const findKeySecondary = selectedSecondaryAnswers && Object.keys(selectedSecondaryAnswers)
            findKeySecondary && findKeySecondary?.length > 0 && findKeySecondary?.forEach((item: string) => {
                const findIfBreak = selectedSecondaryAnswers?.[item]?.breakCashout
                if (findIfBreak) {
                    breakErrors.push({ question: item, answer: selectedSecondaryAnswers?.[item] })
                }
            })
            if (breakErrors && breakErrors?.length > 0) {
                setBreakCashout({ show: true, questions: breakErrors })
                return true
            } else {
                return false
            }
        }
        else return false
    }

    const onValidateFindingPrice = async () => {
        setLoadingPrice(true)
        if (!(orderDetails?.item?.warehouseImages && orderDetails?.item?.warehouseImages?.length > 0) && !(filesSelected && filesSelected?.length > 0)) {
            setLoadingPrice(false)
            setRevalueError('Please upload images to continue with the next step.')
            return;
        }
        if ((((assetTemplateSelected?.label?.toLowerCase() || orderDetails?.item?.catalog?.code) === 'phones'
            || (assetTemplateSelected?.label?.toLowerCase() || orderDetails?.item?.catalog?.code) === 'tablets')
        )) {
            if (!(imeiSearch && imeiSearch !== '') && !imeiNotExist) {
                setRevalueError('Please write IMEI or select that IMEI doesn\'t exist.')
                setLoadingPrice(false)
                return;
            } else if (!imeiNotExist && imeiStatusRes && (imeiStatusRes || '')?.toLowerCase() === 'yes') {
                setShowBlackListedModal(true)
                setLoadingPrice(false)
                return;
            } else if (!imeiStatusRes && !imeiNotExist) {
                const response = await onCheckImeiStatus()
                setImeiStatusRes(response)
                if (response && (response || '')?.toLowerCase() === 'yes') {
                    setShowBlackListedModal(true)
                    setLoadingPrice(false)
                    return;
                }
            }
        }
        if (userProfile?.inventory && sections && (responseLocations || filteredLocations) && !selectedLocation) {
            setRevalueError('Please select item location.')
            setLoadingPrice(false)
            return;
        }
        setRevalueError(undefined)
        let priceErrors: { [key: string]: string } | undefined;
        let inventoryErrors: { [key: string]: string } | undefined;
        const fieldValues: Array<string> = [];
        for (const key in selectedPrimaryAnswers) {
            if (Object.hasOwnProperty.call(selectedPrimaryAnswers[key], 'field')) {
                if (selectedPrimaryAnswers[key].value) {
                    fieldValues.push(selectedPrimaryAnswers[key].field);
                }
            }
        }
        const keys = (primaryQuestions && primaryQuestions?.length > 0) && primaryQuestions?.map((item: IQuestion) => item?.field)
        if (templateFields && keys && keys?.length > 0) {
            keys?.map((item: any) => {
                const findField = fieldDetails && fieldDetails?.find((field: any) => field?.name === item)
                const findIfInSelected = (fieldValues && fieldValues?.length > 0) && fieldValues?.find((field: string) => field === item)
                if (!findIfInSelected && findField?.required) {
                    priceErrors = {
                        ...(priceErrors || {}),
                        [item]: 'This question is required'
                    }
                }
                return item
            })
        }
        if (secondaryQuestions && secondaryQuestions?.length > 0) {
            secondaryQuestions?.forEach((item: IQuestionSecondary) => {
                const findIfInSelected = selectedSecondaryAnswers[item.question]
                if (!(findIfInSelected && (findIfInSelected?.answers || findIfInSelected?.value)) && item?.required && item.show) {
                    priceErrors = {
                        ...(priceErrors || {}),
                        [item.question]: 'This question is required'
                    }
                }
            })
        }
        if (invertoryFields && invertoryFields?.length > 0) {
            invertoryFields?.map((item: any) => {
                if (!inventoryFormData?.[item?.name] && item?.required) {
                    inventoryErrors = {
                        ...(inventoryErrors || {}),
                        [item?.name]: `${item?.name} is required`
                    }
                }
                return item
            })
        }
        if (priceErrors) {
            setFilterErrors(priceErrors)
            setLoadingPrice(false)
        }
        if (inventoryErrors) {
            setInventoryFilterErrors(inventoryErrors)
            setLoadingPrice(false)
        }
        if (!priceErrors && !inventoryErrors) {
            setRevalueError(undefined)
            setFilterErrors(undefined)
            setInventoryFilterErrors(undefined)
            if (findIfAnyBreakCashoutAnswer()) {
                setLoadingPrice(false)
                return;
            } else {
                setBreakCashout(undefined)
            }
            onFindingBestPrice()
        }
    }

    useEffect(() => {
        if (userProfile?.inventory) {
            onGettingWarehouseSections()
        }
    }, [userProfile?.inventory])

    const onClickClearLocationAndSection = () => {
        setSelectedSection(undefined)
        setScannedLocation(undefined)
        setSearchFieldLocation(undefined)
        setFilteredLocations(undefined)
        setSearchInDBLocation(undefined)
        setResponseLocations(undefined)
        setSelectedLocation(undefined)
    }

    const onFindIfThereIsSpaceInSelectedLocation = async () => {
        if (selectedSection || scannedLocation) {
            try {
                const response: any = await dispatch(tryToFetchSectionLocations({ barcode: selectedSection?.barcode || scannedLocation }))?.unwrap()
                if (response && response?.length > 0) {
                    const getFirstItem = response?.[0]?.categories
                    const getKeys = getFirstItem && Object.keys(getFirstItem)
                    if (getFirstItem?.[getKeys?.[0]]?.[0]?.id !== (assetTemplateSelected?.id || query.get('category'))) {
                        onResponseNotFound()
                        setResponseLocations(undefined)
                        setFindBarcodeLocationsResponse('The barcode assigned to this location belongs to a different category.')
                        return
                    }
                    const formatResponse = response && response?.length > 0 && response?.map((item: any) => {
                        let formatLabel: any
                        item?.descriptor && (Object?.keys(item?.descriptor))?.forEach((obj: any, idx: number) => {
                            formatLabel = idx === 0 ? item?.descriptor?.[obj] : formatLabel + ` - ${item?.descriptor?.[obj]}`
                        })
                        return {
                            ...item,
                            value: item?._id,
                            label: formatLabel,
                            name: formatLabel,
                            fullName: item?.fullName
                        }
                    })
                    setResponseLocations(formatResponse || undefined)
                    setFindBarcodeLocationsResponse(undefined)
                } else if (response && response?.length === 0) {
                    onResponseNotFound()
                    setFindBarcodeLocationsResponse('No available capacity in this location!')
                } else {
                    onResponseNotFound()
                    setFindBarcodeLocationsResponse('Not found any location for the Barcode!')
                }
            } catch (err) {
                setFindBarcodeLocationsResponse(`${err}`)
            }
        }
    }

    const onChangeSelectedSection = (e: any, type?: string) => {
        setSelectedSection(e)
        setScannedLocation(undefined)
    }

    const onChangedScannedLocation = (e: any, type?: string) => {
        setScannedLocation(e)
        setSelectedSection(undefined)
        setResponseLocations(undefined)
    }

    const onEnterAction = async (e: any, type?: string) => {
        e.stopPropagation()
        if (e?.key === 'Enter') {
            if (scannedLocation) {
                onFindIfThereIsSpaceInSelectedLocation()
            }
        }
    }

    const onChangeSelectedLocation = (e: any, type?: string) => {
        if (type) {
            setSelectedLocation(e)
            if (searchFieldLocation) {
                setSearchFieldLocation(undefined)
            }
            setFindBarcodeLocationsResponse(undefined)
        }
    }

    const onMenuCloseAfterSearchLocation = () => {
        setSearchFieldLocation(undefined)
    }

    const onClickSearchInDbLocation = () => {
        if (searchInDBLocation) {
            setFilteredLocations(undefined)
            setLoadingOptionsLocation(true)
            setSearchFieldLocation(searchInDBLocation)
        }
    }

    const onChangeSearchLocationFields = (e: any, type?: string) => {
        setSearchInDBLocation(e)
        if (e) {
            if (responseLocations) {
                const formatSearch = e ? e?.split?.(' ').join('')?.replace('-', '') : null
                const formatFilteredFields = (responseLocations && responseLocations?.length > 0) && responseLocations?.filter((next: any) => (next?.fullName || '').toLowerCase().includes((formatSearch || '')?.toLowerCase()))
                if (formatFilteredFields && formatFilteredFields?.length > 0) {
                    setSearchInDBLocation(e)
                    setLoadingOptionsLocation(false)
                    setFilteredLocations(formatFilteredFields)
                }
                else {
                    setFilteredLocations(undefined)
                    setLoadingOptionsLocation(true)
                    setSearchFieldLocation(e)
                }
            } else {
                setSearchFieldLocation(e)
            }
        } else {
            setFilteredLocations(undefined)
        }
    }

    useEffect(() => {
        if (selectedSection) {
            onFindIfThereIsSpaceInSelectedLocation()
        }
    }, [selectedSection])

    const onGettingFilteredLocations = async () => {
        try {
            const formatSearch = searchFieldLocation ? searchFieldLocation?.split?.(' ').join('')?.replace('-', '') : searchFieldLocation
            const response: any = await dispatch(tryToFetchSectionLocations({ barcode: selectedSection?.value || scannedLocation, data: { search: formatSearch } }))?.unwrap()
            const formatResponse: any = (response && response?.length > 0) && response?.map((item: any) => {
                let formatLabel: any
                item?.descriptor && (Object?.keys(item?.descriptor))?.forEach((obj: any, idx: number) => {
                    formatLabel = idx === 0 ? item?.descriptor?.[obj] : formatLabel + ` - ${item?.descriptor?.[obj]}`
                })
                return {
                    ...item,
                    value: item?._id,
                    label: formatLabel,
                    name: formatLabel,
                    fullName: item?.fullName
                }
            })
            setFilteredLocations(formatResponse || [])
            setLoadingOptionsLocation(false)
        } catch (err) {
            // error here
        }
        setLoadingOptionsLocation(false)
        setSearchFieldLocation(null)
    }

    useEffect(() => {
        if (searchFieldLocation !== null) {
            onGettingFilteredLocations()
        }
    }, [searchFieldLocation])

    const onChangeValueMessage = (e: any) => {
        setValue('message', e?.target?.value)
    }

    const onSubmitToInventory = async () => {
        try {
            const dataForm = new FormData()
            const formatAsset = onFormatQuestions()?.assets;
            const formatQuestions = onFormatQuestions(true)?.questions;
            dataForm.append('assetTemplateId', assetTemplateSelected?.id || orderDetails?.item?.catalog?.id || '')
            dataForm.append('marketId', activeMarket?.market?._id || '')
            dataForm.append('itemId', itemId || '')
            if (userProfile?.inventory && selectedLocation?._id) {
                dataForm.append('location', selectedLocation?._id)
            }
            dataForm.append('questions', JSON.stringify(formatQuestions))
            dataForm.append('asset', JSON.stringify(formatAsset))
            filesSelected && filesSelected?.length > 0 && filesSelected?.map((file: any, index: number) => {
                dataForm.append(`image${index + 1}`, file)
                return file
            })
            if (!imeiNotExist && imeiSearch) {
                dataForm.append('itemCode', imeiSearch)
            }
            if (invertoryFields && inventoryFormData) {
                dataForm.append('inventory', JSON.stringify(inventoryFormData))
            }
            if (extendedFields && extendedFormData) {
                dataForm.append('extended', JSON.stringify(extendedFormData))
            }
            const data = await dispatch(tryToReviewOrder({ data: dataForm, orderId: id || '' })).unwrap()
            if (data?.data?.package?.reviseInfo) {
                setRevisedOfferModal(data.data.package.reviseInfo)
            } else {
                onGoingToOrderDetails()
            }
        } catch (err: any) {
            setRevalueError(`${err || 'Something went wrong!'}`)
        }
        setLoadingPrice(false)
    }

    const onAcceptItem = async () => {
        try {
            let formatData: any = {
                orderId: id || '',
                data: {
                    itemId: itemId || '',
                    marketId: activeMarket?.market?._id,
                },
            };
            if (userProfile?.inventory && selectedLocation?._id) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        location: selectedLocation?._id
                    }
                }
            }
            if (invertoryFields && inventoryFormData) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        inventory: inventoryFormData
                    }
                }
            }
            if (extendedFields && extendedFormData) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        extended: extendedFormData
                    }
                }
            }
            if (imeiNotExist) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        imeiNotExisting: true,
                    }
                }
            } else {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        itemCode: imeiSearch
                    }
                }
            }
            const data = itemId && id && await dispatch(tryToAcceptOrder(formatData)).unwrap();
            if (data?.reviseInfo) {
                setRevisedOfferModal(data.reviseInfo)
            } else {
                onGoingToOrderDetails()
            }
        } catch (err: any) {
            setRevalueError(`${err || 'Something went wrong!'}`)
        }
        setLoadingPrice(false)
    }

    const onValidateAccept = async () => {
        setLoadingPrice(true)
        if ((((assetTemplateSelected?.label?.toLowerCase() || orderDetails?.item?.catalog?.code) === 'phones'
            || (assetTemplateSelected?.label?.toLowerCase() || orderDetails?.item?.catalog?.code) === 'tablets')
        )) {
            if (!(imeiSearch && imeiSearch !== '') && !imeiNotExist) {
                setRevalueError('Please write IMEI or select that IMEI doesn\'t exist.')
                setLoadingPrice(false)
                return;
            }
            else if (!imeiNotExist && imeiStatusRes && (imeiStatusRes || '')?.toLowerCase() === 'yes') {
                setShowBlackListedModal(true)
                setLoadingPrice(false)
                return;
            } else if (!imeiStatusRes && !imeiNotExist) {
                const response = await onCheckImeiStatus()
                setImeiStatusRes(response)
                if (response && (response || '')?.toLowerCase() === 'yes') {
                    setShowBlackListedModal(true)
                    setLoadingPrice(false)
                    return;
                }
            }
        }
        if (userProfile?.inventory && sections && (responseLocations || filteredLocations) && !selectedLocation) {
            setRevalueError('Please select item location.')
            setLoadingPrice(false)
            return;
        }
        setRevalueError(undefined)
        let inventoryErrors: any
        if (invertoryFields && invertoryFields?.length > 0) {
            invertoryFields?.map((item: any) => {
                if (!inventoryFormData?.[item?.name] && item?.required) {
                    inventoryErrors = {
                        ...(inventoryErrors || {}),
                        [item?.name]: `${item?.name} is required`
                    }
                }
                return item
            })
        }
        if (inventoryErrors) {
            setInventoryFilterErrors(inventoryErrors)
            setLoadingPrice(false)
        } else {
            setFilterErrors(undefined)
            setInventoryFilterErrors(undefined)
            onAcceptItem()
        }
    }

    const onValidateAddToInventory = async () => {
        setLoadingPrice(true)
        if ((((assetTemplateSelected?.label?.toLowerCase() || orderDetails?.item?.catalog?.code) === 'phones'
            || (assetTemplateSelected?.label?.toLowerCase() || orderDetails?.item?.catalog?.code) === 'tablets')
        )) {
            if (!(imeiSearch && imeiSearch !== '') && !imeiNotExist) {
                setRevalueError('Please write IMEI or select that IMEI doesn\'t exist.')
                setLoadingPrice(false)
                return;
            } else if (!imeiNotExist && imeiStatusRes && (imeiStatusRes || '')?.toLowerCase() === 'yes') {
                setShowBlackListedModal(true)
                setLoadingPrice(false)
                return;
            } else if (!imeiStatusRes && !imeiNotExist) {
                const response = await onCheckImeiStatus()
                setImeiStatusRes(response)
                if (response && (response || '')?.toLowerCase() === 'yes') {
                    setShowBlackListedModal(true)
                    setLoadingPrice(false)
                    return;
                }
            }
        }
        if (userProfile?.inventory && sections && (responseLocations || filteredLocations) && !selectedLocation) {
            setRevalueError('Please select item location.')
            setLoadingPrice(false)
            return;
        }
        setRevalueError(undefined)
        let priceErrors: any
        let inventoryErrors: any
        const fieldValues: Array<string> = [];
        for (const key in selectedPrimaryAnswers) {
            if (Object.hasOwnProperty.call(selectedPrimaryAnswers[key], 'field')) {
                if (selectedPrimaryAnswers[key].value) {
                    fieldValues.push(selectedPrimaryAnswers[key].field);
                }
            }
        }
        const keys = (primaryQuestions && primaryQuestions?.length > 0) && primaryQuestions?.map((item: IQuestion) => item?.field)
        if (templateFields && keys && keys?.length > 0) {
            keys?.map((item: any) => {
                const findField = fieldDetails && fieldDetails?.find((field: any) => field?.name === item)
                const findIfInSelected = (fieldValues && fieldValues?.length > 0) && fieldValues?.find((field: string) => field === item)
                if (!findIfInSelected && findField?.required) {
                    priceErrors = {
                        ...(priceErrors || {}),
                        [item]: 'This question is required'
                    }
                }
                return item
            })
        }
        if (secondaryQuestions && secondaryQuestions?.length > 0) {
            secondaryQuestions?.forEach((item: IQuestionSecondary) => {
                const findIfInSelected = selectedSecondaryAnswers[item.question]
                if (!(findIfInSelected && (findIfInSelected?.answers || findIfInSelected?.value)) && item?.required && item.show) {
                    priceErrors = {
                        ...(priceErrors || {}),
                        [item.question]: 'This question is required'
                    }
                }
            })
        }
        if (invertoryFields && invertoryFields?.length > 0) {
            invertoryFields?.map((item: any) => {
                if (!inventoryFormData?.[item?.name] && item?.required) {
                    inventoryErrors = {
                        ...(inventoryErrors || {}),
                        [item?.name]: `${item?.name} is required`
                    }
                }
                return item
            })
        }
        if (priceErrors) {
            setFilterErrors(priceErrors)
            setLoadingPrice(false)
        }
        if (inventoryErrors) {
            setInventoryFilterErrors(inventoryErrors)
            setLoadingPrice(false)
        }
        if (!priceErrors && !inventoryErrors) {
            setFilterErrors(undefined)
            setInventoryFilterErrors(undefined)
            if (findIfAnyBreakCashoutAnswer()) {
                setLoadingPrice(false)
                return;
            } else {
                setBreakCashout(undefined)
            }
            onSubmitToInventory()
        }
    }

    const onChangeImeiSearch = (value: string | null | undefined, type?: string) => {
        setImeiSearch(value || undefined)
        setImeiError(undefined)
        setImeiStatusRes(undefined)
    }

    const onDeleteRevaluateImage = (file: any) => {
        const filterOutFile = (filesSelected && filesSelected?.length > 0) && filesSelected?.filter((item: any) => item !== file)
        setFilesSelected(filterOutFile || undefined)
    }

    const onClickImeiNotExist = () => {
        if (orderDetails?.item?.itemCode) {
            return;
        }
        if (!imeiNotExist) {
            setImeiSearch(undefined)
        }
        setImeiNotExist(!imeiNotExist)
        setImeiStatusRes(undefined)
        setImeiError(undefined)
    }

    const onSelectPrimaryQuestion = (value: any, type?: string, index?: string) => {
        if (type && index) {
            const findSellerQuestions: IDisplayQuestion | undefined = (orderDetails?.item?.displayQuestions && orderDetails?.item?.displayQuestions?.length > 0) ? orderDetails?.item?.displayQuestions?.find((item: IDisplayQuestion) => item?.question === type) : undefined
            setMatchedItems((prevMatched: any) => ({
                ...prevMatched || {},
                [type]: findSellerQuestions?.answers?.[0]?.answer === value?.value
            }))
            setSelectedPrimaryAnswers((prevPrimaryAnswer: any) => ({
                ...prevPrimaryAnswer || {},
                [type]: value
            }))
        }
    }

    const onSelectSecondaryQuestion = (value: any, type?: string, index?: string) => {
        if (type) {
            const findSellerQuestions: IDisplayQuestion | undefined = (orderDetails?.item?.displayQuestions && orderDetails?.item?.displayQuestions?.length > 0) ? orderDetails?.item?.displayQuestions?.find((item: IDisplayQuestion) => item?.question === type) : undefined
            const doesAnswerMatch = checkMatchingAnswers(findSellerQuestions?.answers || [], value || [])
            setMatchedItems((prevMatched: any) => ({
                ...prevMatched || {},
                [type]: doesAnswerMatch
            }))
            setSelectedSecondaryAnswers((prevSecondaryAnswer: any) => ({
                ...prevSecondaryAnswer || {},
                [type]: value
            }))
        }
    }

    const onChangeSecondaryQuestion = (value: any, type?: string, index?: any) => {
        if (type) {
            const findSellerQuestions: IDisplayQuestion | undefined = (orderDetails?.item?.displayQuestions && orderDetails?.item?.displayQuestions?.length > 0) ? orderDetails?.item?.displayQuestions?.find((item: IDisplayQuestion) => item?.question === type) : undefined
            setMatchedItems((prevMatched: any) => ({
                ...prevMatched || {},
                [type]: findSellerQuestions?.value === value
            }))
            setSelectedSecondaryAnswers((prevSecondaryAnswer: any) => ({
                ...prevSecondaryAnswer || {},
                [type]: {
                    type: index,
                    value: index === 'NUMBER_INPUT' ? (/^\d*\.?\d*$/).test(value) ? value : selectedSecondaryAnswers?.[type]?.value : value
                }
            }))
        }
    }

    const onClickMatchItem = (question: string, matched: boolean, sellerAnswer?: any, field?: string) => {
        if (assetTemplateSelected?.name !== orderDetails?.item?.catalog?.code) {
            return;
        }
        if (matched) {
            setMatchedItems((prevMatched: any) => ({
                ...prevMatched || {},
                [question]: false
            }))
            setSelectedPrimaryAnswers((prevPrimaryAnswer: any) => ({
                ...prevPrimaryAnswer || {},
                [question]: undefined
            }))
        } else {
            setMatchedItems((prevMatched: any) => ({
                ...prevMatched || {},
                [question]: true
            }))
            setSelectedPrimaryAnswers((prevPrimaryAnswer: any) => ({
                ...prevPrimaryAnswer || {},
                [question]: { ...sellerAnswer || {}, field: field, label: sellerAnswer?.answers?.[0]?.answer, value: sellerAnswer?.answers?.[0]?.answer }
            }))
        }
    }

    const onClickMatchSecondaryItem = (question: string, matched: boolean, sellerAnswer?: any, ansType?: string) => {
        if (assetTemplateSelected?.name !== orderDetails?.item?.catalog?.code) {
            return;
        }
        if (matched) {
            setMatchedItems((prevMatched: any) => ({
                ...prevMatched || {},
                [question]: false
            }))
            setSelectedSecondaryAnswers((prevSecondaryAnswer: any) => ({
                ...prevSecondaryAnswer || {},
                [question]: undefined
            }))
        } else {
            let questionValue: any
            if (sellerAnswer && sellerAnswer?.value) {
                questionValue = {
                    ...sellerAnswer || {},
                    label: sellerAnswer?.value,
                    value: sellerAnswer?.value
                }
            }
            else if (ansType) {
                switch (ansType) {
                    case 'SINGLE_CHOICE':
                        questionValue = {
                            ...sellerAnswer || {},
                            id: sellerAnswer?.answers?.[0]?.id,
                            value: sellerAnswer?.answers?.[0]?.answer,
                            label: sellerAnswer?.answers?.[0]?.answer
                        }
                        break;
                    case 'MULTIPLE_CHOICE':
                        questionValue = (sellerAnswer?.answers && sellerAnswer?.answers?.length > 0) &&
                            sellerAnswer?.answers?.map((item: any) => {
                                return {
                                    ...item,
                                    value: item?.answer,
                                    label: item?.answer
                                }
                            })
                        break;
                    case 'NUMBER_INPUT':
                    case 'TEXT_INPUT':
                        questionValue = {
                            ...sellerAnswer || {},
                            value: sellerAnswer?.value,
                            label: sellerAnswer?.value
                        }
                        break;
                }
            }
            setMatchedItems((prevMatched: any) => ({
                ...prevMatched || {},
                [question]: true
            }))
            setSelectedSecondaryAnswers((prevSecondaryAnswer: any) => ({
                ...prevSecondaryAnswer || {},
                [question]: questionValue
            }))
        }
    }

    const onClickMatchAll = () => {
        if (assetTemplateSelected?.name !== orderDetails?.item?.catalog?.code) {
            return;
        }
        let formatedMatch: any
        let formatSelectedPrimaryQuestion: any
        if (primaryQuestions && primaryQuestions?.length > 0) {
            primaryQuestions?.forEach((item: IQuestion) => {
                const findAnswer = (orderDetails?.item?.displayQuestions && orderDetails?.item?.displayQuestions?.length > 0) && orderDetails?.item?.displayQuestions?.find((question: any) => question?.question === item?.question)
                if (findAnswer) {
                    formatedMatch = {
                        ...formatedMatch || {},
                        [item.question]: true
                    }
                    formatSelectedPrimaryQuestion = {
                        ...formatSelectedPrimaryQuestion || {},
                        [item.question]: {
                            ...findAnswer || {},
                            field: item?.field,
                            value: findAnswer?.answers?.[0]?.answer,
                            label: findAnswer?.answers?.[0]?.answer
                        }
                    }
                } else {
                    formatedMatch = {
                        ...formatedMatch || {},
                        [item.question]: true
                    }
                    formatSelectedPrimaryQuestion = {
                        ...formatSelectedPrimaryQuestion || {},
                        [item?.question]: null
                    }
                }
            })
        }
        setSelectedPrimaryAnswers(formatSelectedPrimaryQuestion)
        let formatSelectedSecondaryQuestions: any
        if (secondaryQuestions && secondaryQuestions?.length > 0) {
            secondaryQuestions?.forEach((item: IQuestion) => {
                const findAnswer: any = (orderDetails?.item?.displayQuestions && orderDetails?.item?.displayQuestions?.length > 0) && orderDetails?.item?.displayQuestions?.find((question: any) => question?.question === item?.question)
                if (findAnswer && item?.answers?.type) {
                    let questionValue
                    switch (item.answers?.type) {
                        case 'SINGLE_CHOICE':
                            questionValue = {
                                ...findAnswer || {},
                                id: findAnswer?.answers?.[0]?.id,
                                value: findAnswer?.answers?.[0]?.answer,
                                label: findAnswer?.answers?.[0]?.answer
                            }
                            break;
                        case 'MULTIPLE_CHOICE':
                            questionValue = (findAnswer?.answers && findAnswer?.answers?.length > 0) &&
                                findAnswer?.answers?.map((item: any) => {
                                    return {
                                        ...item,
                                        value: item?.answer,
                                        label: item?.answer
                                    }
                                })
                            break;
                        case 'NUMBER_INPUT':
                        case 'TEXT_INPUT':
                            questionValue = {
                                ...findAnswer || {},
                                value: findAnswer?.value,
                            }
                            break;
                    }
                    formatedMatch = {
                        ...formatedMatch || {},
                        [item.question]: true
                    }
                    formatSelectedSecondaryQuestions = {
                        ...formatSelectedSecondaryQuestions || {},
                        [item?.question]: questionValue
                    }
                }
                else {
                    formatedMatch = {
                        ...formatedMatch || {},
                        [item.question]: true
                    }
                    formatSelectedSecondaryQuestions = {
                        ...formatSelectedSecondaryQuestions || {},
                        [item?.question]: null
                    }
                }
            })
        }
        setMatchedItems(formatedMatch)
        setSelectedSecondaryAnswers(formatSelectedSecondaryQuestions)
        onCheckingDependencies(formatSelectedPrimaryQuestion, formatSelectedSecondaryQuestions, true)
    }

    const onClickSearchInDb = (type?: any) => {
        if (searchInDB?.[type]) {
            setFilteredFields(undefined)
            setLoadingOptions({ [type]: true })
            setSearchField({
                [type]: searchInDB?.searchValue
            })
            if (selectedPrimaryAnswers?.[type]) {
                const formatFormData = Object.assign(selectedPrimaryAnswers)
                delete formatFormData[type]
                setSelectedPrimaryAnswers(
                    {
                        ...formatFormData || {}
                    }
                )
            }
        }
    }

    const onSuccessfullyChanged = (type: string, message: string) => {
        setShowToast({ type, message })
        setShowImeiModal(undefined)
    }

    const onSaveDrawingImage = (image: any) => {
        if (showDrawModal?.question) {
            setDrawing({
                ...drawing || {},
                [showDrawModal?.question]: image
            })
        }
    }

    const onClickShowDrawModal = (question: string) => {
        setShowDrawModal({ show: true, question: question })
    }

    const onShowHideRejectModal = () => {
        if (showRejectModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowRejectModal(!showRejectModal);
    };

    const onHideRevaluateModal = () => {
        document.body.style.overflow = 'auto';
        setShowRevaluateModal(false)
    }

    const onEnterImei = async (e: any) => {
        if (loadingImeiStatus) {
            return;
        }
        e.stopPropagation()
        if (e?.key === 'Enter') {
            const response = await onCheckImeiStatus()
            if (response && (response || '')?.toLowerCase() === 'yes') {
                setShowBlackListedModal(true)
            }
            setImeiStatusRes(response)
            setLoadingImeiStatus(false)
        }
    }

    const onAcceptImeiStatus = async () => {
        setLoadingSubmit(true)
        setLoadingImeiStatus(true)
        await onChangeItemImeiStatus()
        onGoBack()
        setLoadingSubmit(false)
        setLoadingImeiStatus(false)
    }

    const onRejectItem = async () => {
        if (breakCashout) {
            try {
                setRejectError(undefined)
                setLoadingSubmit(true)
                const dataForm: any = new FormData()
                dataForm.append('reason', 'The item did not meet expectations.')
                dataForm.append('fake', 'false')
                dataForm.append('itemId', itemId)
                dataForm.append('marketId', activeMarket?.market?._id)
                if (filesSelected && filesSelected?.length > 0) {
                    filesSelected?.map((file: any, index: number) => {
                        dataForm.append(`file${index + 1}`, file)
                        return file
                    })
                }
                await dispatch(tryToRejectOrder({ orderId: id || '', data: dataForm })).unwrap()
                onGoBack()
                document.body.style.overflow = 'auto';
                setBreakCashout(undefined)
            } catch (err) {
                setRejectError(`${err}`)
            }
            setLoadingSubmit(false)
        }
    }

    const onResetAnswers = () => {
        setSelectedPrimaryAnswers(undefined)
        setSelectedSecondaryAnswers(undefined)
    }

    const showImei = ((assetTemplateSelected?.label?.toLowerCase() || orderDetails?.item?.catalog?.code) === 'phones'
        || (assetTemplateSelected?.label?.toLowerCase() || orderDetails?.item?.catalog?.code) === 'tablets'
    )

    return (
        <div>
            <div className={'flex flex-row items-center justify-between mb-2'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-8'>
                        <img src={'/assets/shared/back.svg'} className={'w-[22px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title !text-3xl'>Seal The Deal</p>
                    {(userProfile?.businessType.includes('wing') && ['not_shipped', 'device_in_review', 'shipped'].includes(orderDetails?.orderData?.status || '')) && <Countdown cashedOutDate={orderDetails?.orderData.cashedOutDate || ''} />}
                </div>
            </div>
            {loadingOrder ?
                <>
                    <RevaluateContainerLoading />
                </>
                : <>
                    {(revalueError && !showRevaluateModal) && <Error text={revalueError} />}
                    <div className='grid grid-cols-3 gap-4'>
                        <div className='col-span-2'>
                            <WhiteContainer containerStyle='!rounded mb-2 !h-auto'>
                                <div className='flex flex-row items-center mb-5'>
                                    <div className='font-semibold text-[16px]'>Item inspection</div>
                                    <div className='after:block after:bg-black after:w-[1.5px] after:h-[16px] after:mx-1'></div>
                                    <div className='text-[13px]'><span className='font-semibold'>Order ID:</span> {id}</div>
                                    <div className='after:block after:bg-black after:w-[1.5px] after:h-[16px] after:mx-1'></div>
                                    <div className='text-[13px]'><span className='font-semibold'>Item ID:</span> {itemId}</div>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <div className='flex flex-row items-center z-20'>
                                        <p className='mb-6 mr-3 min-w-[120px]'>Category: </p>
                                        <SelectCheckbox
                                            dataQa={'category'}
                                            placeholder={assetTemplateSelected?.label || orderDetails?.item?.catalog?.code || 'Category'}
                                            options={brokerCategories || []}
                                            selectStyle={{ backgroundColor: 'white', borderRadius: '4px', maxWidth: '300px', minWidth: '200px' }}
                                            onChangeSelectedOption={onChangeCategorySelected}
                                            uniqueName={'category'}
                                        />
                                    </div>
                                    <Button
                                        label={'Reject'}
                                        className='btn-main ml-3 !mb-3 !text-[#e50000] !font-medium'
                                        onClickButton={onShowHideRejectModal}
                                    />
                                </div>
                                {(imeiStatusRes && (imeiStatusRes || '').toLowerCase() === 'no') && <p className='text-[#00B67A] ml-[130px]'>IMEI status: clean.</p>}
                                <div className={'flex flex-row items-center'}>
                                    {showImei &&
                                        <div className='flex flex-row items-center'>
                                            <p className='mb-5 mr-3 min-w-[120px]'>IMEI: </p>
                                            <Input
                                                containerStyle='mb-0 mr-4'
                                                dataQa={'add-imei'}
                                                showValue={true}
                                                placeholder={' '}
                                                disabled={imeiNotExist || loadingImeiStatus}
                                                error={imeiError}
                                                inputValue={imeiSearch}
                                                onHandleKeyDown={onEnterImei}
                                                onChangeInput={onChangeImeiSearch}
                                            />
                                            <div className='flex flex-row items-center mb-6'>
                                                <div className='flex items-center mr-2'>
                                                    <input
                                                        onChange={() => onClickImeiNotExist()}
                                                        checked={imeiNotExist}
                                                        id='checkbox-all'
                                                        data-qa={'checkbox'}
                                                        type='checkbox'
                                                        className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-yellow-300 rounded focus:accent-yellow-300 focus:ring-2'
                                                    />
                                                    <label htmlFor='checkbox-all' className='sr-only'>
                                                        checkbox
                                                    </label>
                                                </div>
                                                <p>
                                                    IMEI doesn&apos;t exist.
                                                </p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div>
                                    <RevaluateQuestions
                                        primaryQuestions={primaryQuestions}
                                        secondaryQuestions={secondaryQuestions}
                                        items={templateFields || []}
                                        questionsLoading={questionsLoading}
                                        loadingValues={loadingValues}
                                        loadingFields={loadingFields}
                                        loadingOptions={loadingOptions}
                                        filteredFields={filteredFields}
                                        searchInDB={searchInDB}
                                        filterErrors={filterErrors}
                                        secondaryQuestionsErrors={filterErrors}
                                        matchedItems={matchedItems}
                                        selectedPrimaryAnswers={selectedPrimaryAnswers}
                                        selectedSecondaryAnswers={selectedSecondaryAnswers}
                                        displayQuestions={orderDetails?.item?.displayQuestions}
                                        onMatchItem={onClickMatchItem}
                                        onShowDrawing={onClickShowDrawModal}
                                        onMatchAllItems={onClickMatchAll}
                                        onClickSearchInDb={onClickSearchInDb}
                                        onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                                        onMatchSecondaryItem={onClickMatchSecondaryItem}
                                        onChangeSecondaryInputValue={onChangeSecondaryQuestion}
                                        onSelectPrimaryQuestion={onSelectPrimaryQuestion}
                                        onSelectSecondaryQuestion={onSelectSecondaryQuestion}
                                        onChangeSearchAssetFields={onChangeSearchAssetFields}
                                    />
                                </div>
                                <div className='flex flex-row justify-end'>
                                    <Button
                                        label={'Reset Answers'}
                                        className='btn-main'
                                        onClickButton={onResetAnswers}
                                    />
                                </div>
                            </WhiteContainer>
                            {(invertoryFields && invertoryFields?.length > 0) &&
                                <WhiteContainer containerStyle='!rounded mb-2 !h-auto'>
                                    <RevaluateInventoryFields
                                        loadingValues={loadingValues}
                                        filteredFields={filteredFields || templateFields}
                                        inventoryItems={invertoryFields || []}
                                        filterInventoryValues={inventoryFormData}
                                        filterExtendedValues={extendedFormData}
                                        extendedItems={extendedFields || []}
                                        assetTemplateSelected={assetTemplateSelected}
                                        inventoryFilterErrors={inventoryFilterErrors}
                                        onChangeInventoryInput={onChangeFilterInventoryInput}
                                        onChangeSelectedInventoryValue={onChangeSelectedInventoryValue}
                                        onChangeExtendedInput={onChangeFilterExtendedInput}
                                        onChangeSelectedExtendedValue={onChangeSelectedExtendedValue}
                                    />
                                </WhiteContainer>
                            }
                        </div>
                        <div>
                            <WhiteContainer containerStyle='!rounded !h-auto'>
                                {userProfile?.inventory &&
                                    <OrderItemLocation
                                        loadingOptions={loadingOptionsLocation}
                                        filteredLocations={(filteredLocations && filteredLocations?.length > 0) ? filteredLocations : responseLocations}
                                        searchInDBLocation={searchFieldLocation}
                                        selectedItemLocation={selectedLocation}
                                        searchLocations={searchInDBLocation}
                                        sections={sections}
                                        selectedSection={selectedSection}
                                        scannedLocation={scannedLocation}
                                        findBarcodeLocationsResponse={findBarcodeLocationsResponse}
                                        onChangeSearchLocationField={onChangeSearchLocationFields}
                                        onChangeSelectedLocation={onChangeSelectedLocation}
                                        onClickSearchInDb={onClickSearchInDbLocation}
                                        onChangeSelectedSection={onChangeSelectedSection}
                                        onEnterAction={onEnterAction}
                                        onMenuCloseAfterSearch={onMenuCloseAfterSearchLocation}
                                        onChangedScannedLocation={onChangedScannedLocation}
                                        onClickClearLocationAndSection={onClickClearLocationAndSection}
                                    />
                                }
                                {(!orderDetails?.item?.warehouseImages || orderDetails?.item?.warehouseImages?.length <= 0) ?
                                    <RevaluateItemImages
                                        itemId={itemId}
                                        isDragActive={isDragActive}
                                        getInputProps={getInputProps}
                                        getRootProps={getRootProps}
                                        filesSelected={filesSelected}
                                        onDeleteImage={onDeleteRevaluateImage}
                                    />
                                    :
                                    <div className='flex flex-row justify-center my-6'>
                                        <Button
                                            label={'Warehouse Images Uploaded'}
                                            className={'btn-main'}
                                            dataQa={'warehouse-images-uploaded'}
                                            onClickButton={onClickRevaluateImages}
                                        />
                                    </div>
                                }
                            </WhiteContainer>
                            <WhiteContainer containerStyle='!p-0 !rounded mt-2 !h-auto'>
                                <ProductInfo
                                    orderDetails={orderDetails}
                                    totalCost={totalCosts}
                                    pricesStyle={'bg-[#f8f9fc] rounded p-2'}
                                />
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 p-2'>
                                    <Button
                                        label={'Review'}
                                        className={`${enableRevaluate && !loadingPrice && (enableAcceptReview?.status !== 'accept' || offerExpired) ? 'btn-primary' : 'btn-primary-disable'}`}
                                        onClickButton={() => enableRevaluate && !loadingPrice && (enableAcceptReview?.status !== 'accept' || offerExpired) && onValidateFindingPrice()}
                                    />
                                    <Button
                                        label='Accept'
                                        className={`${enableAcceptReview && enableAcceptReview?.enable && !loadingPrice ? 'btn-primary' : 'btn-primary-disable'}`}
                                        onClickButton={() => (enableAcceptReview?.enable && !loadingPrice) ? enableAcceptReview?.status === 'accept' ? onValidateAccept() : onValidateAddToInventory() : undefined}
                                    />
                                </div>
                            </WhiteContainer>
                        </div>
                    </div>
                    {showImeiModal?.show &&
                        <ImeiCheckModal
                            openModal={showImeiModal?.show}
                            itemId={itemId}
                            orderId={id}
                            imeiSearch={imeiSearch}
                            formData={formData}
                            handleCloseModal={() => setShowImeiModal(undefined)}
                            onApproveAction={onSuccessfullyChanged}
                        />
                    }
                    {showToast?.message &&
                        <Toast
                            type={showToast?.type}
                            text={showToast?.message}
                            onHandleCloseToast={() => setShowToast(null)}
                        />
                    }
                    {showRejectModal &&
                        <RejectModal
                            openRejectModal={showRejectModal}
                            handleCloseModal={onShowHideRejectModal}
                            orderId={id || ''}
                            itemId={itemId || ''}
                            warehouseImages={revaluateImages}
                            marketId={activeMarket?.market?._id || ''}
                        />
                    }
                    {revaluateImages && revaluateImages?.length > 0 && showRevaluateImages &&
                        <ImagesCarousel
                            images={revaluateImages || []}
                            showModal={showRevaluateImages}
                            handleClose={() => setShowRevaluateImages(false)}
                            active={0}
                        />
                    }
                </>
            }
            {showRevaluateModal &&
                <RevaluateMessageModal
                    openModal={showRevaluateModal}
                    priceFound={priceFound}
                    activeMarket={activeMarket}
                    errors={errors}
                    category={(assetTemplateSelected?.name || '').toLowerCase()}
                    loadingSubmit={loadingSubmit}
                    revaluateError={revalueError}
                    onSubmit={onSubmitRevaluate}
                    setValue={setValue}
                    register={register}
                    handleSubmit={handleSubmit}
                    watch={watch}
                    onChangeValueMessage={onChangeValueMessage}
                    handleCloseModal={onHideRevaluateModal}
                />
            }
            {showDrawModal?.show &&
                <ReviewDrawScratchesModal
                    openModal={showDrawModal?.show}
                    categoryFrames={categoryFrames}
                    handleCloseModal={() => setShowDrawModal(undefined)}
                    onSaveDrawingImage={onSaveDrawingImage}
                />
            }
            {showBlacklistedModal &&
                <BlacklistedImeiModal
                    openModal={showBlacklistedModal}
                    handleCloseModal={() => (!loadingImeiStatus && !loadingSubmit) && setShowBlackListedModal(false)}
                    loadingSubmit={(loadingImeiStatus || loadingSubmit)}
                    onGoBack={onAcceptImeiStatus}
                />
            }
            {breakCashout?.show &&
                <BreakCashoutModal
                    openModal={breakCashout?.show}
                    loadingSubmit={loadingSubmit}
                    rejectError={rejectError}
                    handleCloseModal={() => setBreakCashout(undefined)}
                    questions={breakCashout?.questions}
                    onRejectItem={onRejectItem}
                />
            }
            {revisedOfferModal &&
                <RevisedOfferModal
                    reviseInfo={revisedOfferModal}
                    handleCloseModal={() => {
                        setRevisedOfferModal(undefined);
                        onGoingToOrderDetails()
                    }}
                />
            }
        </div >
    )
}

export default OrderReview;