import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { tryToAcceptOrRejectPurchase } from '../../../../store/brokers/partners/purchase-orders/purchaseOrdersSlice';
import OffersHistoryModal from './OffersHistoryModal';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';

interface IOfferModal {
    openModal: boolean;
    brokerCategories: any;
    data: any;
    itemId: string;
    handleCloseModal: () => void;
}

const OfferModal = ({
    openModal,
    data,
    brokerCategories,
    itemId,
    handleCloseModal,
}: IOfferModal) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>();
    const [offersHistoryModal, setOffersHistoryModal] = useState<boolean>(false);
    const [newOffer, setNewOffer] = useState<any>();

    const onCloseModal = () => {
        setLoading(false)
        setSubmitError(undefined)
        handleCloseModal()
        setNewOffer(undefined)
    }

    const onAcceptOrRejectOffer = async (status: string) => {
        setLoading(true)
        setSubmitError(undefined)
        try {
            if (status === 'counter' || status === 'final') {
                if (!newOffer) {
                    setSubmitError('Please write new offer price to continue!')
                    setLoading(false)
                    return;
                }
                const formatData = {
                    totalOfferPrice: Number(newOffer || 0),
                    currency: data?.currency || data?.expectedItems?.[0]?.currency,
                    dashboard: 'brokers',
                    negotiate: true,
                    offerType: status
                }
                await dispatch(tryToAcceptOrRejectPurchase({ id: itemId, status: 'broker_offer_submitted', data: formatData })).unwrap();
            } else {
                await dispatch(tryToAcceptOrRejectPurchase({ id: itemId, status: status, data: { dashboard: 'brokers', negotiate: data?.negotiateOffer } })).unwrap();
        }
            onCloseModal()
    } catch (err) {
        setSubmitError(`${err}`)
    }
    setLoading(false)
}

const onSeeOffersHistory = () => {
    if (!offersHistoryModal) {
        setOffersHistoryModal(true)
    } else {
        setOffersHistoryModal(false)
    }
}

const onChangeNewOffer = (e: any) => {
    setNewOffer(e ? (/^\d*\.?\d*$/).test(e) ? e : newOffer : undefined)
}

const latestOffer = (data?.offers && data?.offers?.length > 0 && !data?.totalOfferPrice) && data?.offers.reduce((max: any, obj: any) => { return obj.counter > max.counter ? obj : max });
return (
    <Modal
        open={openModal}
        showInRight
        title={'Purchase Order Offer'}
        onClose={onCloseModal}
    >
        <div className={'p-2 min-w-[500px]'}>
            <div className='border-b-2 border-slate-200 pb-2 my-1'>
                <p className='font-medium text-slate-700'>Order Offer</p>
            </div>
            {submitError &&
                <div className='my-3'>
                    <Error text={submitError} />
                </div>
            }
            {(data?.batch && data?.offers && data?.offers?.length > 0 && data?.negotiateOffer) &&
                <div className='flex flex-row justify-end my-4'>
                    <Button
                        label={'Offers History'}
                        className='btn-main !rounded min-w-[150px]'
                        onClickButton={onSeeOffersHistory}
                    />
                </div>
            }
            <div
                className='!rounded p-3'
                style={{ background: 'linear-gradient(107.85deg, rgba(252, 238, 33, 0.2) 19.37%, rgba(247, 147, 30, 0.2) 96.06%)' }}>
                <p className='font-bold text-[20px] mb-4'>Offer</p>
                <div className='flex flex-col'>
                    {data?.categories && data?.categories?.length > 0 && data?.categories?.map((item: any, idx: number) => {
                        const findCategory = brokerCategories && brokerCategories?.length > 0 && brokerCategories?.find((category: any) => category?.id === item?.id)
                        const findItems = data?.expectedItems && data?.expectedItems?.length > 0 && data?.expectedItems?.filter((itemCategory: any) => itemCategory?.categoryId === item?.id)
                        const totalQuantity = findItems && findItems?.length > 0 && findItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.qty), 0)
                        return (
                            <div key={idx} className='flex flex-row justify-between items-center my-2'>
                                <p className='font-bold'>{findCategory?.displayName}</p>
                                <p>{totalQuantity || 0} {(totalQuantity && totalQuantity > 1) ? 'items' : 'item'}</p>
                            </div>
                        )
                    })}
                    <div className='border-t-2 border-slate-400 flex flex-row justify-between items-center py-2 my-2'>
                        <p className='font-bold text-[16px]'>Offer</p>
                        <p>{CURRENCY_SYMBOLS?.[data?.currency || data?.expectedItems?.[0]?.currency || '']}{Number(data?.totalOfferPrice || latestOffer?.price || 0)?.toFixed(2)}</p>
                    </div>
                </div>
            </div>
            <div className='flex flex-row justify-center items-center mt-7 mb-3'>
                <Button
                    label='Reject Offer'
                    dataQa={'reject-button'}
                    className={`${loading ? 'btn-main-disable' : 'btn-main !border-0'} !shadow-none !rounded min-w-[150px] mr-5`}
                    onClickButton={() => !loading && onAcceptOrRejectOffer('offer_rejected')}
                />
                <Button
                    label='Accept Offer'
                    dataQa={'accept-button'}
                    className={`${loading ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} !shadow-none !rounded min-w-[150px]`}
                    onClickButton={() => !loading && onAcceptOrRejectOffer('offer_accepted')}
                />
            </div>
            {(data?.batch && data?.negotiateOffer && !data?.totalOfferPrice) &&
                <>
                    <div className="flex items-center justify-center my-10">
                        <div className="border-t border-gray-300 flex-grow"></div>
                        <span className="px-4 text-gray-500">OR</span>
                        <div className="border-t border-gray-300 flex-grow"></div>
                    </div>
                    <div
                        className='!rounded p-3'
                        style={{ background: 'linear-gradient(107.85deg, rgba(252, 238, 33, 0.2) 19.37%, rgba(247, 147, 30, 0.2) 96.06%)' }}
                    >
                        <p className='font-bold text-[16px] mb-5'>Submit new Alternative or Final Offer</p>
                        <Input
                            label={`New Offer (${CURRENCY_SYMBOLS?.[data?.currency || data?.expectedItems?.[0]?.currency || '']})`}
                            showValue={true}
                            inputStyle='max-w-[80%]'
                            inputValue={newOffer}
                            onChangeInput={onChangeNewOffer}
                        />
                    </div>
                    <div className='flex flex-row justify-center items-center mt-7 mb-3'>
                        <Button
                            label='Submit Alternative Offer'
                            dataQa={'counter-button'}
                            className={`${loading ? 'btn-main-disable' : 'btn-main !border-0'} !shadow-none !rounded min-w-[150px] mr-5`}
                            onClickButton={() => !loading && onAcceptOrRejectOffer('counter')}
                        />
                        <Button
                            label='Submit Final Offer'
                            dataQa={'final-button'}
                            className={`${loading ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} !shadow-none !rounded min-w-[150px]`}
                            onClickButton={() => !loading && onAcceptOrRejectOffer('final')}
                        />
                    </div>
                </>
            }
            {offersHistoryModal &&
                <OffersHistoryModal
                    openModal={offersHistoryModal}
                    offers={data.offers}
                    handleCloseModal={onSeeOffersHistory}
                />
            }
        </div>
    </Modal>
)
}
export default OfferModal;
