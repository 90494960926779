import React from 'react';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';

interface IImportAssetCsv {
    onUploadFile: () => void;
    getRootProps: any;
    getInputProps: any;
    isDragActive: boolean;
    fileSelected?: any;
    importError?: string;
}

const ImportAssetsCsv = ({
    onUploadFile,
    getRootProps,
    getInputProps,
    isDragActive,
    fileSelected,
    importError
}: IImportAssetCsv) => {
    return (
        <div className='p-3'>
            <div className='mb-3'>
                <p className='text-sm font-semibold'>Import Assets CSV</p>
                {importError && <Error text={importError} />}
            </div>
            <div className='flex flex-col items-center justify-center min-h-[200px] rounded'>
                <div className='flex flex-col items-center justify-center' {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p className='font-semibold'>Drag n drop csv file here</p>
                    }
                    <p className='mb-2'>
                        or
                    </p>
                    <Button
                        icon={<img src='/assets/shared/upload-file.svg' className='w-[30px] object-contains pr-2' />}
                        className={'btn-main !bg-[#202020] !text-white !py-2 !shadow-none flex flex-row items-center'}
                        label={'Select File'}
                        dataQa={'select-fole-asset-btn'}
                    />
                    {fileSelected && <p>{fileSelected?.name}</p>}
                </div>
            </div>
            <div className='flex flex-col items-center my-2'>
                <Button
                    label='Upload File'
                    dataQa={'upload-file'}
                    onClickButton={() => fileSelected && onUploadFile()}
                    className={`${fileSelected ? 'btn-main' : 'btn-main-disable'} !py-2 !shadow-none min-w-[150px]`}
                />
            </div>
        </div>
    )
}

export default ImportAssetsCsv;