import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { tryToFetchCreditNoteDetails } from '../../../store/brokers/admin/rma/RMAsSlice';
import moment from 'moment';
import Loading from '../../../shared/Loading';
import { CURRENCY_SYMBOLS } from '../../../utils/constants/currency-symbols';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import Input from '../../../shared/input';
import CreditNoteDetailsTable from './tables/CreditNoteDetailsTable'

const CreditNoteDetails = () => {
  const { id } = useParams() || { id: '' };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [creditNoteDetails, setCreditNoteDetails] = useState<any>();
  const [search, setSearch] = useState('');
  const [markets, setMarkets] = useState<IAllMarkets[]>();
  const [getAllMarkets] = useGetAllMarketsMutation();

  const getMarketData = async () => {
    const marketResponse = await getAllMarkets(null).unwrap();
    const formatMarkets =
      marketResponse &&
      marketResponse?.length > 0 &&
      marketResponse?.map((market: IAllMarkets) => ({
        ...market,
        value: market?._id,
        displayName: market?.name,
      }));
    setMarkets(formatMarkets || []);
  };

  const onGoBack = () => {
    navigate(-1)
  }
  const getCreditNoteDetails = async () => {
    if (id) {
      const resp = await dispatch(
        tryToFetchCreditNoteDetails(id),
      ).unwrap();
      setCreditNoteDetails(resp?.data);

    }
  };
  const getMarketDisplayName = () => {
    const res =
      markets &&
      markets?.length > 0 &&
      markets?.find((market: IAllMarkets) => market?.name === creditNoteDetails?.market?.name);
    if (res)
      return res.label
  };
  const onChangeSearchValue = (value: string | null | undefined,) => {
    if (value) setSearch(value)
    else setSearch('')
  }

  useEffect(() => {
    getMarketData()
  }, [])
  useEffect(() => {
    getCreditNoteDetails()
  }, [id])
  if (!creditNoteDetails)
    return (
      <div className="w-full h-full  md:min-h-[calc(100vh-12rem)] flex justify-center items-center">
        <Loading />
      </div>
    );
  return (
    <div>
      <div className='flex flex-row items-center'>
        <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
          <img src={'/assets/shared/go-back-circle.svg'} className={'w-6 object-contain cursor-pointer'} />
        </div>
        <p className='page-title'>Credit Note Details</p>
      </div>
      <div className="w-full bg-white flex flex-wrap justify-start items-center p-6 rounded-xl gap-11">
        <div
          className={`px-3 py-2 rounded-[10px] border ${creditNoteDetails?.paid
            ? 'text-[#00B67A] border-[#00B67A]'
            : 'text-[#E50000] border-[#E50000]'
            }`}>
          {creditNoteDetails?.paid ? 'Paid' : 'Not Paid'}
        </div>

        <div className="flex flex-col">
          <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
            Credit Note ID
          </span>
          <span className="text-[#202020] text-sm">{id}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
            Created Date
          </span>
          <span className="text-[#202020] text-sm">
            {moment(creditNoteDetails?.createdAt).format(
              'DD-MM-YYYY',
            )}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
            Category
          </span>
          <div className="flex gap-1 items-center">
            {creditNoteDetails?.categories?.slice(0, 2).map((c: any, index: number) => (
              <img
                key={index}
                className="w-6 h-6"
                src={c.enabledIcon}
                alt={c.category}
                title={c.category} // Show category name as tooltip
              />
            ))}
            {creditNoteDetails?.categories?.length > 2 && (
              <span className="ml-1 hover:cursor-pointer relative" title={creditNoteDetails?.categories?.slice(2).map((category: any) => category.category).join(', ')}>
                ...
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
            Nr. of Items
          </span>
          <span className="text-[#202020] text-sm">{creditNoteDetails?.itemCreditsDetails?.length}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
            Total Credit
          </span>
          <span className="text-[#202020] text-sm">{CURRENCY_SYMBOLS?.[creditNoteDetails?.market?.name]}{creditNoteDetails?.totalCreditAmount}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
            Market
          </span>
          <span className="text-[#202020] text-sm flex gap-1 items-center">
            <img src={creditNoteDetails?.market?.flagUrl} alt={creditNoteDetails?.market?.name} />
            {getMarketDisplayName()}
          </span>
        </div>
      </div>
      <div className="my-5 bg-[#F7A21E1A] rounded-[10px] p-2 flex flex-row justify-end items-center">
        <Input
          placeholder={'Filter Table'}
          containerStyle="mb-0 pb-0 relative flex-initial w-96"
          inputStyle={`mb-0 border-0 focus:outline-none`}
          icon={'/assets/shared/search.svg'}
          showValue={true}
          inputValue={search}
          inputUniqueName={'search'}
          onChangeInput={onChangeSearchValue}
          hideInputSpace={true}
          dataQa={'filter-table'}
        />
      </div>
      <CreditNoteDetailsTable rows={creditNoteDetails?.itemCreditsDetails?.filter((item: any) => item?.itemName?.toLowerCase()?.includes(search.toLowerCase()) || item?.itemCode?.toLowerCase()?.includes(search.toLowerCase()) || item._id?.toLowerCase()?.includes(search.toLowerCase()))} markets={markets || []} />
    </div>
  )
}

export default CreditNoteDetails