import React from 'react';
import { IDisplayQuestion } from '../../../../interfaces/orders/IOrdersSchema';
import Modal from '../../../../shared/modal';

interface IRevaluateDataDetailsModal {
    openModal: boolean;
    revaluationData?: Array<IDisplayQuestion>;
    handleCloseModal: () => void;
}

const RevaluateDataDetailsModal = ({
    openModal,
    revaluationData,
    handleCloseModal
}: IRevaluateDataDetailsModal) => {
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <div>
                    {(revaluationData && revaluationData?.length > 0) &&
                        revaluationData?.map((item: any) => {
                            return (
                                <>
                                    <div className='grid grid-cols-2 gap-3 my-2'>
                                        <p>{item?.question}</p>
                                        <p className='break-words'>
                                            {(item?.displayValue) ?
                                                item?.displayValue :
                                                (item?.value) ?
                                                    item?.value :
                                                    ((item?.answers && item?.answers?.length > 0)
                                                        ? item?.answers?.map((answer: { answer: string }, index: number) => `${index ? ', ' : ''}${answer?.answer}`) : 'No answer')
                                            }
                                        </p>
                                    </div>
                                    <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                </>
                            );
                        })}
                </div>
            </div>
        </Modal>
    )
}

export default RevaluateDataDetailsModal;