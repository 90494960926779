import React from 'react';
import Button from '../../../../shared/button';
import {
    FILTER_ITEMS,
    RECENT_CHANGES_ITEMS,
} from '../../../../utils/constants/orders-items-consts';


interface IOrderFilters {
    selectedFilter?: string;
    selectedStatus?: string;
    recentSelected?: string;
    historyFilterStatus?: string;
    onChangeFilterSelected: (filter: string) => void;
    onChangeRecentFilter: (name: string) => void;
    onChangeStatusSelected: (status: string) => void;
    onClickHistoryStatus: (status: string) => void;
}

const OrderFilters = ({
    selectedFilter,
    selectedStatus,
    recentSelected,
    historyFilterStatus,
    onChangeFilterSelected,
    onChangeRecentFilter,
    onChangeStatusSelected,
    onClickHistoryStatus,
}: IOrderFilters) => {
    return (
        <div className='flex flex-row justify-between items-center min-w-full'>
            {selectedStatus !== 'Recent Changes' ?
                <div className='flex flex-row items-center flex-wrap'>
                    <img src={'/assets/shared/filter.svg'} className={'w-[13px] object-contain'} />
                    <p className='text-[#8F8F8F] text-sm font-bold ml-2 mr-4 mb-1'>Filters:</p>
                    {selectedStatus !== 'History' ?
                        FILTER_ITEMS && FILTER_ITEMS?.length > 0 && FILTER_ITEMS?.map((item, index) => {
                            return (
                                <Button
                                    key={index}
                                    onClickButton={() => onChangeFilterSelected(item?.name)}
                                    label={item?.label}
                                    dataQa={`${(item?.label || '')?.toLowerCase().replaceAll(' ', '-')}`}
                                    className={`${selectedFilter === item?.name ? 'btn-filters' : 'btn-noactive-filters'} mr-2 mb-1 !font-bold !text-[13px]`}
                                />
                            )
                        })
                        : <>
                            <Button
                                onClickButton={() => onClickHistoryStatus('Accepted')}
                                label='Accepted'
                                dataQa={'accepted'}
                                className={`btn-primary-rounded !shadow-none mr-2 !bg-[#a7eae0] !text-black ${historyFilterStatus === 'Accepted' ? '!opacity-100' : 'opacity-75'}`}
                            />
                            <Button
                                onClickButton={() => onClickHistoryStatus('Missing')}
                                label='Missing'
                                dataQa={'missing'}
                                className={`btn-primary-rounded !shadow-none mr-2 !bg-[#cc2149] ${historyFilterStatus === 'Missing' ? '' : 'opacity-75'}`}
                            />
                            <Button
                                onClickButton={() => onClickHistoryStatus('Rejected')}
                                label='Rejected'
                                dataQa={'rejected'}
                                className={`btn-primary-rounded !shadow-none mr-2 !bg-[#ff3b30] ${historyFilterStatus === 'Rejected' ? '' : 'opacity-75'}`}
                            />
                            <Button
                                onClickButton={() => onClickHistoryStatus('Canceled')}
                                label='Canceled'
                                dataQa={'canceled'}
                                className={`btn-primary-rounded !shadow-none !bg-[#d41106] ${historyFilterStatus === 'Canceled' ? '' : 'opacity-75'}`}
                            />

                        </>
                    }
                </div>
                :
                <div className='flex flex-row items-center'>
                    <img src={'/assets/shared/filter.svg'} className={'w-[13px] object-contain'} />
                    <p className='text-[#8F8F8F] text-sm font-bold ml-2 mr-4'>Filters:</p>
                    {RECENT_CHANGES_ITEMS?.length > 0 && RECENT_CHANGES_ITEMS?.map((item, index) => {
                        return (
                            <Button
                                key={index}
                                onClickButton={() => onChangeRecentFilter(item?.name)}
                                label={item?.name}
                                dataQa={'filters'}
                                className={`${recentSelected === item?.name ? 'btn-filters' : 'btn-noactive-filters'} mr-2 !text-[13px] !font-bold`}
                            />
                        )
                    })}
                </div>
            }
            {/* <div className='flex flex-row items-center'>
                {STATUS_ITEMS && STATUS_ITEMS?.length > 0 && STATUS_ITEMS?.map((item, index) => {
                    return (
                        <Button
                            onClickButton={() => onChangeStatusSelected(item?.name)}
                            key={index}
                            label={item?.label}
                            className={`btn-noactive-categories mr-2 !font-bold text-sm ${selectedStatus === item?.name && '!text-[#F7931E]'}`}
                        />
                    )
                })}
            </div> */}
        </div>
    )
}

export default OrderFilters