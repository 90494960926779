import React, { useEffect } from 'react';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';


interface IItemBarcode {
    itemId: string;
}

const ItemBarcode = ({
    itemId,
}: IItemBarcode) => {
    const componentRef = React.useRef([]) as React.MutableRefObject<any>;

    useEffect(() => {
        if (componentRef.current) {
            componentRef.current.click();
        }
    }, [itemId]);

    return (
        <>
            <div style={{ display: 'none' }}>
                <div ref={componentRef}>
                    <div className='flex flex-col items-center my-10'>
                        <Barcode value={itemId || 'null'} width={1.4} height={60} fontSize={14} displayValue={true} textAlign={'center'} />
                    </div>
                </div>
            </div>
            <ReactToPrint
                trigger={() =>
                    <button className='btn-main'>
                        Print Barcode
                    </button>
                }
                content={() => componentRef.current}
            />
        </>
    )
}

export default ItemBarcode;