export const SECTION_USER_TYPES = [
    {
        value: 'organization-account',
        label: 'Organization Account',
    },
    {
        value: 'agent',
        label: 'Agent',
    },
    {
        value: 'cs',
        label: 'CS',
    },
    {
        value: 'admins',
        label: 'Admins',
    },
    {
        value: 'inventory-broker',
        label: 'Inventory Broker',
    },
    {
        value: 'inventory-admin',
        label: 'Inventory Admin',
    },
    {
        value: 'inventory-agent',
        label: 'Inventory Agent',
    },
];
