import React from 'react';
import {
    AdminGuard,
    GuestGuard,
    PartnerAgentGuard,
    PartnerGuard,
} from '../guards/RolesGuard';
import { UserGuard } from '../guards/UserGuard';
import Layout from '../container/Layout';
import Login from '../features/auth/login';
import Register from '../features/auth/register';
import Agents from '../features/dashboard/agents';
import Tac from '../features/admin-dashboard/tac';
import Orders from '../features/dashboard/orders';
import Profile from '../features/dashboard/profile';
import Pricing from '../features/dashboard/pricing';
// import Support from '../features/dashboard/settings';
import Invoices from '../features/dashboard/invoices';
import Insights from '../features/dashboard/insights';
import Users from '../features/admin-dashboard/users';
import Pages from '../features/admin-dashboard/pages';
import Assets from '../features/admin-dashboard/assets';
import Markets from '../features/admin-dashboard/markets';
import ResetPassword from '../features/auth/reset-password';
import AdminOrders from '../features/admin-dashboard/orders';
import SalesOrders from '../features/dashboard/sales-orders';
import CreateAgent from '../features/dashboard/agents/create';
import OrderReview from '../features/dashboard/orders/review';
import CustomerCare from '../features/dashboard/customer-care';
import PricingAdmin from '../features/admin-dashboard/pricing';
import UIActions from '../features/admin-dashboard/ui-actions';
import AdminReports from '../features/admin-dashboard/reports';
import AgentDetails from '../features/dashboard/agents/details';
import OrderDetails from '../features/dashboard/orders/details';
import AdminInvoices from '../features/admin-dashboard/invoices';
import ShippedOrders from '../features/dashboard/shipped-orders';
import PostageFees from '../features/admin-dashboard/postage-fees';
import PayServices from '../features/admin-dashboard/pay-services';
import PricingHistory from '../features/dashboard/pricing-history';
import CashoutFlow from '../features/admin-dashboard/cashout-flow';
import PurchaseOrders from '../features/dashboard/purchase-orders';
import CreateAsset from '../features/admin-dashboard/assets/create';
import MultipleCashout from '../features/dashboard/multiple-cashout';
import ScrapingFees from '../features/admin-dashboard/scraping-fees';
import CategoryMapping from '../features/dashboard/category-mapping';
import AssetDetails from '../features/admin-dashboard/assets/details';
import OrdersHighLevel from '../features/dashboard/orders-high-level';
import PriceEffects from '../features/dashboard/pricing/price-effects';
import RentalPricing from '../features/admin-dashboard/rental-pricing';
import CreatePartner from '../features/admin-dashboard/partners/create';
import SubBrands from '../features/admin-dashboard/partners/sub-brands';
import PageDetails from '../features/admin-dashboard/pages/pageDetails';
import MarketDetails from '../features/admin-dashboard/markets/details';
import PricingReviews from '../features/admin-dashboard/pricing-reviews';
import ExtendedAssets from '../features/admin-dashboard/assets-extended';
import CommissionFees from '../features/admin-dashboard/commission-fees';
import CreateCSAgent from '../features/admin-dashboard/cs-agents/create';
// import WholeSalePrice from '../features/admin-dashboard/wholesale-price';
import PricingEffects from '../features/admin-dashboard/pricing/effects';
// import WholesalePriceBroker from '../features/dashboard/wholesale-price';
import PartnerDetails from '../features/admin-dashboard/partners/details';
import CreateRetailer from '../features/admin-dashboard/retailers/create';
import UserAccessControl from '../features/dashboard/user-access-control';
import AssetsTemplates from '../features/admin-dashboard/assets-templates';
import CSAgentDetails from '../features/admin-dashboard/cs-agents/details';
import ComparisonSites from '../features/admin-dashboard/comparison-sites';
import ManageRoles from '../features/dashboard/user-access-control/manage';
import InvoiceDetails from '../features/dashboard/invoices/invoice-details';
import RetailerDetails from '../features/admin-dashboard/retailers/details';
import UIActionDetails from '../features/admin-dashboard/ui-actions/details';
import ShippingServices from '../features/admin-dashboard/shipping-services';
import ItemAllocationAdmin from '../features/admin-dashboard/item-allocation';
import PricingHistoryAdmin from '../features/admin-dashboard/pricing-history';
import AdminInvoicesDetails from '../features/admin-dashboard/invoice-details';
import ShippedOrderDetails from '../features/dashboard/shipped-orders/details';
import PurchaseForm from '../features/dashboard/purchase-orders/purchase-form';
import PricingHistoryDetails from '../features/dashboard/pricing-history/details';
import CreateCategoryMapping from '../features/dashboard/category-mapping/create';
// import WholesalePriceHistory from '../features/dashboard/wholesale-price-history';
import NotificationSetions from '../features/admin-dashboard/notification-sections';
import BrokerFieldsSettings from '../features/admin-dashboard/field-broker-settings';
import InvoiceTemplates from '../features/admin-dashboard/invoices/invoice-templates';
import WarehouseManage from '../features/dashboard/profile/categories/WarehouseManage';
import AssetTemplateDetails from '../features/admin-dashboard/assets-templates/details';
import ComparisonSitePricing from '../features/admin-dashboard/comparison-site-pricing';
import RentalPricingSettings from '../features/admin-dashboard/rental-pricing/settings';
import ComparisonSiteDetails from '../features/admin-dashboard/comparison-sites/details';
import BrokersComparisonSites from '../features/admin-dashboard/brokers-comparison-sites';
import GenerateInvoice from '../features/admin-dashboard/invoice-details/generate-invoice';
import PricingHistoryDetailsAdmin from '../features/admin-dashboard/pricing-history/details';
import RentalAssetsData from '../features/admin-dashboard/rental-pricing/rental-assets-data';
// import WholesalePriceAdminSettings from '../features/admin-dashboard/wholesale-price/settings';
import QuestionDetails from '../features/admin-dashboard/cashout-flow/details/QuestionDetails';
// import WholesalePriceHistoryDetails from '../features/dashboard/wholesale-price-history/details';
import AssetsTemplatesFrames from '../features/admin-dashboard/assets-templates/category-frames';
import BrokerComparisonDetails from '../features/admin-dashboard/brokers-comparison-sites/details';
import TemplateFieldDetails from '../features/admin-dashboard/assets-templates/field-details';
import RMAManagement from '../features/admin-dashboard/rma-management';
import RMAManagementDetails from '../features/admin-dashboard/rma-management/details';
import RMAs from '../features/dashboard/RMAs';
import RMADetails from '../features/dashboard/RMAs/details';
import CreditNoteDetails from '../features/admin-dashboard/rma-management/credit-note-details';
import AiAgents from '../features/ai-agents/AiAgents';
import AiAgentsContainer from '../features/dashboard/ai-agents/AiAgents';
import SaleForm from '../features/dashboard/sales-orders/sale-form';
import LayoutNoAuth from '../container/LayoutNoAuth';
import { AssetLibrary } from '../features/dashboard/asset-library';
import AssetLibraryDetails from '../features/dashboard/asset-library/details/AssetLibraryDetails';
import AICall from '../features/ai-call/AiCall';
// import WelcomePage from '../features/auth/welcome';

export interface RouteDefinition {
    path: string;
    element: JSX.Element;
    guard?: () => boolean;
    index?: string;
    key?: string;
    childrens?: RouteDefinition[];
}

export const routes: RouteDefinition[] = [
    // {
    //     path: '/',
    //     element: <WelcomePage />,
    //     key: 'guest',
    //     guard: GuestGuard,
    // },
    {
        path: '/login',
        element: <Login />,
        key: 'guest',
        guard: GuestGuard,
    },
    {
        path: '/apply',
        element: <Register />,
        key: 'guest',
        guard: GuestGuard,
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
        key: 'guest',
        guard: GuestGuard,
    },
    {
        path: '/chat',
        element: <LayoutNoAuth noAuth={!UserGuard()} />,
        key: 'broker',
        // guard: GuestGuard,
        childrens: [
            {
                path: ':id',
                element: <AiAgents />
            }
        ]
    },
    {
        path: '/ai-agents',
        element: <Layout />,
        index: '/ai-agents',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <AiAgentsContainer />
            },
            {
                path: 'chat/buy/:id',
                element: <AiAgents type='buyer' />
            },
            {
                path: 'chat/sell/:id',
                element: <AiAgents type='seller' />
            },
        ],
    },
    {
        path: '/agent-call',
        element: <Layout />,
        index: 'agent-call',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <AICall />
            }
        ]
    },
    {
        path: '/asset-library',
        element: <Layout />,
        index: '/asset-library',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <AssetLibrary />
            },
            {
                path: ':id',
                element: <AssetLibraryDetails />
            }
        ],
    },
    {
        path: '/users',
        element: <Layout />,
        index: '/users',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <Users />
            },
            {
                path: 'partner/create',
                element: <CreatePartner />
            },
            {
                path: 'partner/edit/:id',
                element: <CreatePartner />
            },
            {
                path: 'partner/:id',
                element: <PartnerDetails />
            },
            {
                path: 'retailer/create',
                element: <CreateRetailer />
            },
            {
                path: 'retailer/edit/:id',
                element: <CreateRetailer />
            },
            {
                path: 'retailer/:id',
                element: <RetailerDetails />
            },
            {
                path: 'partner/sub-brands/:id',
                element: <SubBrands />
            },
            {
                path: 'cs-agent/create',
                element: <CreateCSAgent />,
            },
            {
                path: 'cs-agent/:id',
                element: <CSAgentDetails />,
            },
        ],
    },
    {
        path: '/asset-catalogs',
        element: <Layout />,
        index: '/asset-catalogs',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <AssetsTemplates />,
            },
            {
                path: ':id',
                element: <AssetTemplateDetails />,
            },
            {
                path: ':id/field/:fieldId',
                element: <TemplateFieldDetails />,
            },
            {
                path: 'frames',
                element: <AssetsTemplatesFrames />,
            },
        ],
    },
    {
        path: '/assets',
        element: <Layout />,
        index: '/assets',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <Assets />,
            },
            {
                path: ':id',
                element: <AssetDetails />
            },
            {
                path: 'create/:id',
                element: <CreateAsset />
            },
            {
                path: ':id/edit/:assetId',
                element: <CreateAsset />
            },
        ],
    },
    {
        path: '/assets-extended',
        element: <Layout />,
        index: '/assets-extended',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <ExtendedAssets />,
            },
        ]
    },
    {
        path: '/broker-fields-settings',
        element: <Layout />,
        index: '/broker-fields-settings',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <BrokerFieldsSettings />,
            },
        ]
    },
    {
        path: '/reports',
        element: <Layout />,
        index: '/reports',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <AdminReports />,
            },
        ]
    },
    {
        path: '/markets',
        element: <Layout />,
        index: '/markets',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <Markets />,
            },
            {
                path: ':id',
                element: <MarketDetails />
            },
        ],
    },
    {
        path: '/shipping-services',
        element: <Layout />,
        index: '/shipping-services',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <ShippingServices />,
            },
        ],
    },
    {
        path: '/admin-orders',
        element: <Layout />,
        index: '/admin-orders',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <AdminOrders />,
            },
        ],
    },
    {
        path: '/pricing-reviews',
        element: <Layout />,
        index: '/pricing-reviews',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <PricingReviews />,
            },
        ],
    },
    {
        path: '/rental-pricing',
        element: <Layout />,
        index: '/rental-pricing',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <RentalPricing />,
            },
            {
                path: 'assets/:id',
                element: <RentalAssetsData />,
            },
            {
                path: 'settings',
                element: <RentalPricingSettings />,
            },
        ],
    },
    {
        path: '/comparison-sites',
        element: <Layout />,
        index: '/comparison-sites',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <ComparisonSites />,
            },
            {
                path: ':id',
                element: <ComparisonSiteDetails />,
            },
        ],
    },
    {
        path: '/item-allocation',
        element: <Layout />,
        index: '/item-allocation',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <ItemAllocationAdmin />,
            }
        ],
    },
    {
        path: '/brokers-comparison-sites',
        element: <Layout />,
        index: '/brokers-comparison-sites',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <BrokersComparisonSites />,
            },
            {
                path: ':id',
                element: <BrokerComparisonDetails />,
            },
        ],
    },
    {
        path: '/comparison-site-pricing',
        element: <Layout />,
        index: '/comparison-site-pricing',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <ComparisonSitePricing />,
            }
        ],
    },
    {
        path: '/admin-invoices',
        element: <Layout />,
        index: '/admin-invoices',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <AdminInvoices />,
            },
            {
                path: 'invoice-templates',
                element: <InvoiceTemplates />,
            },
        ],
    },
    {
        path: '/admin-invoices-details',
        element: <Layout />,
        index: '/admin-invoices-details',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <AdminInvoicesDetails />,
            },
            {
                path: 'generate',
                element: <GenerateInvoice />,
            },
        ],
    },
    // {
    //     path: '/wholesale-price-admin',
    //     element: <Layout />,
    //     index: '/wholesale-price-admin',
    //     guard: AdminGuard,
    //     childrens: [
    //         {
    //             path: '',
    //             element: <WholeSalePrice />,
    //         },
    //         {
    //             path: 'settings',
    //             element: <WholesalePriceAdminSettings />,
    //         }
    //     ],
    // },
    {
        path: '/pricing-admin',
        element: <Layout />,
        index: '/pricing-admin',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <PricingAdmin />
            },
            {
                path: 'pricing-adjustments',
                element: <PricingEffects />
            }
        ],
    },
    {
        path: '/pricing-history-admin',
        element: <Layout />,
        index: '/pricing-history-admin',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <PricingHistoryAdmin />
            },
            {
                path: ':id',
                element: <PricingHistoryDetailsAdmin />
            }
        ],
    },
    {
        path: '/postage-fees',
        element: <Layout />,
        index: '/postage-fees',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <PostageFees />,
            }
        ],
    },
    {
        path: '/commission-fees',
        element: <Layout />,
        index: '/commission-fees',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <CommissionFees />,
            }
        ],
    },
    {
        path: '/scraping-fees',
        element: <Layout />,
        index: '/scraping-fees',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <ScrapingFees />,
            }
        ],
    },
    {
        path: '/cashout-flow',
        element: <Layout />,
        index: '/cashout-flow',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <CashoutFlow />,
            },
            {
                path: 'question/:id',
                element: <QuestionDetails />
            }
        ],
    },
    {
        path: '/pay-services',
        element: <Layout />,
        index: '/pay-services',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <PayServices />,
            }
        ],
    },
    {
        path: '/tac-admin',
        element: <Layout />,
        index: '/tac-admin',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <Tac />
            }
        ],
    },
    {
        path: '/ui-actions',
        element: <Layout />,
        index: '/ui-actions',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <UIActions />
            },
            {
                path: ':id',
                element: <UIActionDetails />,
            },
        ],
    },
    {
        path: '/pages',
        element: <Layout />,
        index: '/pages',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <Pages />
            },
            {
                path: ':id',
                element: <PageDetails />
            }
        ],
    },
    {
        path: '/notification-sections',
        element: <Layout />,
        index: '/notification-sections',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <NotificationSetions />
            }
        ],
    },
    {
        path: '/rma-management',
        element: <Layout />,
        index: '/rma-management',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <RMAManagement />
            },
            {
                path: 'credit-note/:id',
                element: <CreditNoteDetails />
            },
            {
                path: ':id',
                element: <RMAManagementDetails />
            }
        ],
    },
    {
        path: '/insights',
        element: <Layout />,
        index: '/insights',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <Insights />
            }
        ],
    },
    {
        path: '/category-mapping',
        element: <Layout />,
        key: 'broker',
        index: '/category-mapping',
        guard: UserGuard,
        childrens: [
            {
                path: '',
                element: <CategoryMapping />
            },
            {
                path: 'create',
                element: <CreateCategoryMapping />
            },
            {
                path: 'create/:id',
                element: <CreateCategoryMapping />
            },
            {
                path: 'create/copy/:copyId',
                element: <CreateCategoryMapping />
            }
        ],
    },
    {
        path: '/orders',
        element: <Layout />,
        index: '/orders',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <OrdersHighLevel />
            }
        ],
    },
    {
        path: '/purchase-orders',
        element: <Layout />,
        index: '/purchase-orders',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <PurchaseOrders />
            },
            {
                path: 'create',
                element: <PurchaseForm />
            },
            {
                path: 'create/:id',
                element: <PurchaseForm />
            },
            {
                path: 'edit/:id',
                element: <PurchaseForm />
            }
        ],
    },
    {

        path: '/sales-orders-broker',
        element: <Layout />,
        index: '/sales-orders-broker',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <SalesOrders />
            },
            {
                path: 'create',
                element: <SaleForm />
            },
            {
                path: 'create/:id',
                element: <SaleForm />
            },
            {
                path: 'edit/:id',
                element: <SaleForm />
            }
        ]
    },
    {
        path: '/multiple-cashouts',
        element: <Layout />,
        index: '/multiple-cashouts',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <MultipleCashout />
            }
        ],
    },
    {
        path: '/wing-packages',
        element: <Layout />,
        index: '/wing-packages',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <ShippedOrders />
            },
            {
                path: ':id',
                element: <ShippedOrderDetails />
            },
        ],
    },
    {
        path: '/order-items',
        element: <Layout />,
        index: '/order-items',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <Orders />
            },
            {
                path: ':id/:itemId',
                element: <OrderDetails />
            },
            {
                path: 'review/:id/:itemId',
                element: <OrderReview />
            }
        ],
    },
    {
        path: '/invoices',
        element: <Layout />,
        index: '/invoices',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <Invoices />
            },
            {
                path: ':id',
                element: <InvoiceDetails />
            }
        ],
    },
    {
        path: '/pricing',
        element: <Layout />,
        index: '/pricing',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <Pricing />
            },
            {
                path: 'price-effects',
                element: <PriceEffects />
            }
        ],
    },
    {
        path: '/pricing-history',
        element: <Layout />,
        key: 'broker',
        index: '/pricing-history',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <PricingHistory />
            },
            {
                path: ':id',
                element: <PricingHistoryDetails />
            }
        ],
    },
    // {
    //     path: '/support',
    //     element: <Layout />,
    //     index: '/support',
    //     key: 'broker',
    //     guard: PartnerAgentGuard,
    //     childrens: [
    //         {
    //             path: '',
    //             element: <Support />,
    //         },
    //     ],
    // },
    {
        path: '/customer-care',
        element: <Layout />,
        index: '/customer-care',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <CustomerCare />
            }
        ],
    },
    // {
    //     path: '/wholesale-price',
    //     element: <Layout />,
    //     index: '/wholesale-price',
    //     key: 'broker',
    //     guard: PartnerAgentGuard,
    //     childrens: [
    //         {
    //             path: '',
    //             element: <WholesalePriceBroker />,
    //         }
    //     ],
    // },
    {
        path: '/profile',
        element: <Layout />,
        index: '/profile',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <Profile />
            },
            {
                path: 'warehouse/:id',
                element: <WarehouseManage />
            }
        ],
    },
    {
        path: '/members',
        element: <Layout />,
        index: '/members',
        key: 'broker',
        guard: PartnerGuard,
        childrens: [
            {
                path: '',
                element: <Agents />,
            },
            {
                path: 'create',
                element: <CreateAgent />,
            },
            {
                path: ':id',
                element: <AgentDetails />,
            },
        ],
    },
    {
        path: '/rma',
        element: <Layout />,
        index: '/rma',
        key: 'broker',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <RMAs />
            },
            {
                path: ':id',
                element: <RMADetails />
            }
        ],
    },
    {
        path: '/user-access-control',
        element: <Layout />,
        index: '/user-access-control',
        key: 'broker',
        guard: UserGuard,
        childrens: [
            {
                path: '',
                element: <UserAccessControl />,
            },
            {
                path: ':id',
                element: <ManageRoles />,
            },
            {
                path: 'create',
                element: <ManageRoles />,
            }
        ],
    },
    // {
    //     path: '/wholesale-price-history',
    //     element: <Layout />,
    //     index: '/wholesale-price-history',
    //     key: 'broker',
    //     guard: UserGuard,
    //     childrens: [
    //         {
    //             path: '',
    //             element: <WholesalePriceHistory />,
    //         },
    //         {
    //             path: ':id',
    //             element: <WholesalePriceHistoryDetails />,
    //         }
    //     ],
    // }
];
