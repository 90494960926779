import React from 'react';
import { INVENTORY_FILTERS } from '../../../../utils/constants/inventory-filter-values';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import { IPayServices } from '../../../../interfaces/pay-services/IPayServices';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';


interface IAllocationFilters {
    sectors?: Array<IAssetTemplate>;
    markets?: Array<IAllMarkets>;
    warehouses?: Array<IWarehouse>;
    partners?: Array<IPartners>;
    payServices?: Array<IPayServices>;
    selectedSector?: ISelectedValue;
    selectedMarket?: ISelectedValue;
    selectedPartner?: ISelectedValue;
    selectedWarehouse?: ISelectedValue;
    selectedSource?: ISelectedValue;
    selectedAllocation?: ISelectedValue;
    items: Array<any>;
    filterValues: any;
    showCatalogFilters: boolean;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onChangeInput: (value: string | null | undefined, type: string) => void;
    onClearAllFilters: () => void;
    onSelectFilter: (id: string, type?: string) => void;
}

const AllocationFilters = ({
    sectors,
    markets,
    partners,
    warehouses,
    payServices,
    selectedSector,
    selectedMarket,
    selectedPartner,
    selectedSource,
    selectedWarehouse,
    selectedAllocation,
    items,
    filterValues,
    showCatalogFilters,
    onChangeInput,
    onChangeSelectedValue,
    onClearAllFilters,
    onSelectFilter
}: IAllocationFilters) => {
    return (
        <div>
            <div className='mb-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
                <SelectCheckbox
                    name={'Market'}
                    placeholder={' '}
                    dataQa={'market'}
                    containerStyle='!mb-0'
                    options={markets || []}
                    uniqueName={'market'}
                    selectedOption={selectedMarket}
                    onChangeSelectedOption={onSelectFilter}
                />
                <SelectCheckbox
                    name={'Partner'}
                    placeholder={' '}
                    dataQa={'partner'}
                    containerStyle='!mb-0'
                    selectedOption={selectedPartner}
                    options={partners || []}
                    uniqueName={'partner'}
                    onChangeSelectedOption={onSelectFilter}
                />
                <SelectCheckbox
                    name={'Category'}
                    placeholder={' '}
                    dataQa={'category'}
                    containerStyle='!mb-0'
                    selectedOption={selectedSector}
                    options={sectors || []}
                    uniqueName={'category'}
                    onChangeSelectedOption={onSelectFilter}
                />
                <SelectCheckbox
                    name={'Warehouse'}
                    placeholder={' '}
                    dataQa={'warehouse'}
                    containerStyle='!mb-0'
                    selectedOption={selectedWarehouse}
                    options={warehouses || []}
                    uniqueName={'warehouse'}
                    onChangeSelectedOption={onSelectFilter}
                />
                <SelectCheckbox
                    name={'Source'}
                    placeholder={' '}
                    dataQa={'source'}
                    containerStyle='!mb-0'
                    selectedOption={selectedSource}
                    options={payServices || []}
                    uniqueName={'source'}
                    onChangeSelectedOption={onSelectFilter}
                />
                <SelectCheckbox
                    name={'Allocated Status'}
                    placeholder={' '}
                    dataQa={'allocated'}
                    containerStyle='!mb-0'
                    selectedOption={selectedAllocation}
                    options={INVENTORY_FILTERS || []}
                    uniqueName={'allocated'}
                    onChangeSelectedOption={onSelectFilter}
                />
            </div>
            {showCatalogFilters &&
                <div>
                    <p className='text-primary-light font-bold text-sm mt-3 mb-4'>Filters</p>
                    <div className='my-1 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-7 gap-4'>
                        {items && items?.length > 0 && items?.filter((item) => item?.type === 'boolean' || item?.type === 'list')?.map((item, index) => {
                            let values: any = item?.options;
                            if (item?.type === 'boolean') {
                                values = [
                                    { value: true, name: 'True' },
                                    { value: false, name: 'False' },
                                ];
                            } else if (item?.type === 'list') {
                                values =
                                    item?.options &&
                                    item?.options?.length > 0 &&
                                    values?.map((value: string) => {
                                        return { value: value, label: value };
                                    });
                            }
                            return (
                                <SelectCheckbox
                                    key={index}
                                    name={item?.fieldName && `${item?.fieldName?.[0].toUpperCase()}${item?.fieldName?.slice(1).toLowerCase()}`}
                                    dataQa={item?.fieldName}
                                    placeholder={filterValues?.[item?.fieldName] || ' '}
                                    options={values}
                                    selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                                    onChangeSelectedOption={onChangeSelectedValue}
                                    uniqueName={item?.fieldName || ''}
                                />
                            )
                        })}
                    </div>
                    <div className='flex flex-row justify-end my-1'>
                        <Button
                            label='Clear all filters'
                            dataQa={'clear-all-filters'}
                            className='btn-primary-text-underline min-w-auto col-span-1 col-end-8 flex flex-row justify-end items-center'
                            onClickButton={onClearAllFilters}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default AllocationFilters;