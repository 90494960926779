import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IAddress } from '../../../interfaces/auth/ICreateUserSchema';
import { tryToFetchSingleAgent } from '../../../store/brokers/partners/agents/agentsSlice';
import Loading from '../../../shared/Loading';
import WhiteContainer from '../../../shared/white-container';
import AddressItem from '../../../shared/address/AddressItem';


const AgentDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [agentDetails, setAgentDetails] = useState<any>()
    const agent = useAppSelector((state) => state?.agents?.agentDetails);
    const agentLoading = useAppSelector((state) => state?.agents?.agentIsLoading);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleAgent(id));
        }
    }, [id]);

    useEffect(() => {
        if (agent) {
            setAgentDetails(agent)
        }
        else {
            setAgentDetails(undefined)
        }
    }, [agent])

    const onGoBack = () => {
        navigate(-1);
    };

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Member Details</p>
                </div>
            </div>
            {agentLoading ?
                <div className='my-3 flex flex-row justify-center items-center'>
                    <Loading />
                </div>
                : <div>
                    <WhiteContainer type={'rounded'}>
                        <div>
                            <p className='mb-1'><b>Name:</b> {agentDetails?.name}</p>
                            <p className='mb-1'><b>Username:</b> {agentDetails?.username}</p>
                            <p className='mb-1'><b>Email:</b> {agentDetails?.email}</p>
                            <p className='mb-1'><b>Role:</b> {agentDetails?.role}</p>
                            <p className='mb-1'><b>Is Enabled:</b> {agentDetails?.enabled ? 'Yes' : 'No'}</p>
                        </div>
                    </WhiteContainer>
                    {(agentDetails?.addresses && agentDetails?.addresses?.length > 0) && <>
                        <p className='mt-4 mb-2 font-medium pl-1'>Member Addresses</p>
                        <div className='grid grid-cols-4 gap-1 content-center mt-2 mb-2'>
                            {agentDetails?.addresses && agentDetails?.addresses?.length > 0 && agentDetails?.addresses?.map((address: IAddress, idx: number) => {
                                return (
                                    <AddressItem
                                        key={idx}
                                        address={address}
                                    />
                                )
                            })
                            }
                        </div>
                    </>
                    }
                </div>
            }
        </div>
    )
}

export default AgentDetails;