import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IPricing } from '../../../interfaces/pricing/IPricing';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { tryToFetchFilteredAdminPricings } from '../../../store/brokers/partners/pricing/pricingSlice';
import {
    tryToFetchAllPartners,
    tryToFetchPartnerCatalogs
} from '../../../store/brokers/admin/partners/partnersSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import FilterStep from './steps/FilterStep';
import DetailsStep from './steps/DetailsStep';
import Button from '../../../shared/button';


const PricingAdmin = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IPricing[]>([]);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [partners, setPartners] = useState<IPartners[]>()
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [selectedPartner, setSelectedPartner] = useState<any>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [filterStepError, setFilterStepError] = useState<string>()
    const [seeDetails, setSeeDetails] = useState<boolean>(false)
    const [pageAccess, setPageAccess] = useState<any>();
    const [getAllMarkets] = useGetAllMarketsMutation()
    const paginationData = useAppSelector((state) => state.pricings.pricings?.data?.page);
    const pricingLoading = useAppSelector((state) => state.pricings?.pricingsAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state) => state.pricings)

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Pricing Admin')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.pricings) {
            const rows =
                state.pricings?.data?.elements && Array.isArray(state.pricings?.data.elements)
                    ? state.pricings?.data.elements.map((r) => ({ ...r }))
                    : [];
            setRows(rows);
        }
    }, [state.pricings]);

    const getPartnerAndMarketData = async () => {
        try {
            const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
            const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.companyName }))
            setPartners(formatPartners || [])
            const marketResponse = await getAllMarkets(null).unwrap()
            const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id, displayName: market?.name }))
            setMarkets(formatMarkets || [])
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.displayName, label: r?.displayName, value: r?._id, }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    useEffect(() => {
        getPartnerAndMarketData()
    }, [])

    const onGetPartnerSectors = async () => {
        const response: any = await dispatch(tryToFetchPartnerCatalogs(selectedPartner?.username || '')).unwrap()
        if (response?.data) {
            const formatSectors = response?.data?.length > 0 && response?.data?.map((category: any) => ({ ...category, label: category?.displayName, name: category?.displayName, value: category?._id }))
            setSectors(formatSectors || [])
        }
    }

    useEffect(() => {
        if (selectedPartner) {
            onGetPartnerSectors()
        }
    }, [selectedPartner])

    const onChangeSelectedFilter = (e: any, type?: string) => {
        setFilterStepError(undefined)
        switch (type) {
            case 'market':
                setSelectedMarket(e);
                break;
            case 'sector':
                setSelectedSector(e)
                break;
            case 'partner':
                setSelectedPartner(e)
                break;
        }
    }

    const onClickSeeDetails = () => {
        if (!selectedMarket || !selectedPartner || !selectedSector) {
            setFilterStepError('Please select all to see details.')
            return;
        }
        setSeeDetails(true)
    }

    const onGetItemsData = async () => {
        try {
            const formatData = {
                ...paginationState,
                brokerId: selectedPartner?.value,
                data: {
                    filters: {
                        country: selectedMarket ? selectedMarket?.displayName : '',
                        partnerId: selectedPartner?.value,
                        assetTemplateId: selectedSector?.value
                    }
                }
            }
            dispatch(tryToFetchFilteredAdminPricings(formatData))
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (selectedMarket && selectedPartner && selectedSector) {
            onGetItemsData()
        }
    }, [seeDetails, selectedMarket, selectedPartner, selectedSector, paginationState])

    const onManagePricingEffects = () => {
        navigate('/pricing-admin/pricing-adjustments')
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <p className='page-title'>Pricing</p>
            </div>
            <div className='flex flex-row justify-end my-4'>
                <Button
                    label={'Manage Pricing Adjustments'}
                    dataQa={'manage-pricing-adjustments'}
                    className={`${(!accessControl || pageAccess?.actions?.['Manage Pricing Adjustments']) ? 'btn-main' : 'btn-main-disable'} mr-2`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Manage Pricing Adjustments']) && onManagePricingEffects()}
                />
            </div>
            <div>
                <FilterStep
                    markets={markets}
                    sectors={sectors}
                    partners={partners}
                    selectedMarket={selectedMarket}
                    selectedPartner={selectedPartner}
                    selectedSector={selectedSector}
                    error={filterStepError}
                    seeDetails={seeDetails}
                    onChangeSelectedFilter={onChangeSelectedFilter}
                    onClickSeeDetails={onClickSeeDetails}
                />
                {seeDetails &&
                    <DetailsStep
                        rows={rows}
                        state={state}
                        pageAccess={pageAccess}
                        accessControl={accessControl}
                        partnerSelected={selectedPartner}
                        marketSelected={selectedMarket}
                        templateSelected={selectedSector}
                        pricingLoading={pricingLoading}
                        paginationData={paginationData}
                        markets={markets}
                        sectors={sectors}
                        setPaginationState={setPaginationState}
                    />
                }
            </div>
        </div>
    );
};

export default PricingAdmin;