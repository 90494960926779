import React from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { onFormatPackageStatus } from '../../../../utils/constants/format-order-statuses';
import { IOrders } from '../../../../interfaces/orders/IOrders';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';

const tableColumns = ['Order ID', 'Order Number', 'Tracking Number', 'Total Items', 'Purchase Price', 'Order Source', 'Package Status', 'Brokers', 'Categories', 'Item Details']

interface IMultipleCashoutTable {
    rows: Array<IOrders>;
    paginationData?: IPage;
    activeMarket?: IActiveMarket;
    ordersLoading: boolean;
    received?: boolean;
    pageAccess?: IPageActions;
    accessControl?: IAccessControl;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSeeMoreDetails: (type: string, data: any) => void;
    onClickSeeItems: (orderId: string, items: any) => void;
    onClickSplitItems?: (orderId: string) => void;
}

const MultipleCashoutTable = ({
    rows,
    paginationData,
    activeMarket,
    ordersLoading,
    received,
    accessControl,
    pageAccess,
    onClickSeeMoreDetails,
    setPaginationState,
    onClickSeeItems,
    onClickSplitItems,
}: IMultipleCashoutTable) => {
    return (
        <Table
            columns={received ? [...tableColumns || [], 'Is Splitted', 'Split Order Items'] : tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}>
            {ordersLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        const value = onFormatPackageStatus(row?.status)?.name
                        const color = onFormatPackageStatus(row?.status)?.color
                        const text = onFormatPackageStatus(row?.status)?.text || 'text-black'
                        const item = color && <div className={`${color} flex flex-row justify-center text-center px-1 min-w-[150px] ${text} text-sm py-1 font-medium rounded-2xl`}>{value}</div>
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className={`px-6 py-4`}>{row?._id}</td>
                                <td className={`px-6 py-4`}>{row?.orderNumber}</td>
                                <td className='px-6 py-4'>
                                    <a
                                        rel='noreferrer'
                                        href={`https://parcelsapp.com/en/tracking/${row?.trackingNumber}`}
                                        target='_blank'
                                        className='text-primary-default'
                                    >
                                        {row?.trackingNumber}
                                    </a>
                                </td>
                                <td className={`px-6 py-4`}>{row?.items?.length || 0}</td>
                                <td className={`px-6 py-4`}>{CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.descriptor?.name || '')}`]}{row?.price || row?.initialPrice || 0}</td>
                                <td className={`px-6 py-4`}>{row?.source}</td>
                                <td className={`px-6 py-4`}>{item}</td>
                                <td className='pl-6 pr-2 py-4 min-w-[150px]'>
                                    <div
                                        onClick={() => (row?.brokers && row?.brokers?.length > 0) && onClickSeeMoreDetails('brokers', row?.brokers)}
                                        className='cursor-pointer hover:text-black font-bold'>
                                        {`${row?.brokers?.length || 0} brokers`}
                                    </div>
                                </td>
                                <td className='pl-6 pr-2 py-4 min-w-[150px]'>
                                    <div
                                        onClick={() => (row?.catalogs && row?.catalogs?.length > 0) && onClickSeeMoreDetails('categories', row?.catalogs)}
                                        className='cursor-pointer hover:text-black font-bold'>
                                        {`${row?.catalogs?.length || 0} categories`}
                                    </div>
                                </td>
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <Button
                                        label='See Details'
                                        className='btn-main'
                                        onClickButton={() => onClickSeeItems(row?._id || '', row?.items)}
                                    />
                                </td>
                                {received &&
                                    <td className={`px-6 py-4`}>{row?.splited ? 'Yes' : 'No'}</td>
                                }
                                {received &&
                                    <td className='px-6 py-4 min-w-[120px]'>
                                        <Button
                                            label='Split'
                                            className={((!accessControl || (pageAccess?.['Split Order'])) && !row?.splited) ? 'btn-main' : 'btn-main-disable'}
                                            onClickButton={() => ((!accessControl || (pageAccess?.['Split Order'])) && !row?.splited) && onClickSplitItems?.(row?._id)}
                                        />
                                    </td>
                                }
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default MultipleCashoutTable;