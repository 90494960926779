import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import { tryToDeleteBrokerPricing } from '../../../../store/brokers/partners/pricing/pricingSlice';
import { tryToFetchAllAssetsTemplates } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IDeleteOffersModal {
    partnerId?: string;
    openModal: boolean;
    handleCloseModal: () => void;
    onFinishSuccesfully: () => void;
}

const DeleteOffersModal = ({
    openModal,
    partnerId,
    handleCloseModal,
    onFinishSuccesfully
}: IDeleteOffersModal) => {
    const dispatch = useAppDispatch();
    const [generalError, setGeneralError] = useState<string>()
    const [markets, setMarkets] = useState<any>()
    const [sectors, setSectors] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const [getAllMarkets] = useGetAllMarketsMutation()

    const onGettingMarkets = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, value: market?._id, name: market?.label }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        onGettingMarkets()
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, label: r?.displayName, name: r?.displayName, value: r?._id, }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const onCloseModal = () => {
        handleCloseModal();
        setSelectedSector(undefined)
        setSelectedMarket(undefined)
        setDeleteLoading(false)
        setGeneralError(undefined)
    }

    const onSubmit = async () => {
        setDeleteLoading(true)
        try {
            if (!selectedMarket && !selectedSector) {
                setGeneralError('Please select one market or catalog to continue.')
                setDeleteLoading(false)
                return;
            }
            if (partnerId) {
                let formatData: any = {
                    brokerId: partnerId || '',
                }
                if (selectedMarket) {
                    formatData = {
                        ...formatData || {},
                        data: {
                            ...formatData?.data || {},
                            marketId: selectedMarket?.value || '',
                        }
                    }
                }
                if (selectedSector) {
                    formatData = {
                        ...formatData || {},
                        data: {
                            ...formatData?.data || {},
                            catalogId: selectedSector?.value || ''
                        }
                    }
                }
                await dispatch(tryToDeleteBrokerPricing(formatData)).unwrap()
                onFinishSuccesfully()
                onCloseModal()
            }
        }
        catch (err) {
            setGeneralError('Something went wrong, please try again or contact our team')
            setDeleteLoading(false)
        }
    }

    const onSelectSector = (e: any) => {
        setSelectedSector(e)
    }

    const onSelectMarket = (e: any) => {
        setSelectedMarket(e)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[400px]'}>
                <p className='mb-4 font-semibold text-sm'>Delete Partner Offers</p>
                {generalError && <Error text={generalError} />}
                <div>
                    <SelectCheckbox
                        name='Market'
                        placeholder=' '
                        containerStyle='mb-1 min-w-full'
                        selectedOption={selectedMarket}
                        onChangeSelectedOption={onSelectMarket}
                        uniqueName={'market'}
                        options={markets || []}
                        dataQa={'delete-offers-market'}
                    />
                    <SelectCheckbox
                        name='Catalog'
                        placeholder=' '
                        containerStyle='mb-5 min-w-full'
                        selectedOption={selectedSector}
                        onChangeSelectedOption={onSelectSector}
                        uniqueName={'sector'}
                        options={sectors || []}
                        dataQa={'delete-offers-catalog'}
                    />
                    <Button
                        label={'Delete Offers'}
                        className={`${deleteLoading ? 'btn-main-disable !cursor-not-allowed' : 'btn-main'} my-8 min-w-full !shadow-none`}
                        type='submit'
                        disabled={deleteLoading}
                        onClickButton={onSubmit}
                        dataQa={'delete-offers-btn'}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default DeleteOffersModal;