import React from 'react';
import WhiteContainer from '../white-container';

interface IAddAddress {
    title?: string;
    onClickAddAddress: () => void;
}

const AddAddress = ({
    onClickAddAddress,
    title
}: IAddAddress) => {
    return (
        <WhiteContainer containerStyle={'w-fit h-fit flex max-w-[90%] flex-col items-center justify-center'} type='rounded'>
            <div data-qa={'add-new-address'} onClick={onClickAddAddress} className='w-full h-full flex flex-col justify-center items-center cursor-pointer'>
                <img alt='plus' src={'/assets/shared/add-new.svg'} className='mb-2 w-[50px] object-contain' />
                <p className={'text-center mb-2'}>{title || 'Add new address'}</p>
            </div>
        </WhiteContainer>
    )
}

export default AddAddress;