import React from 'react';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IUploadCsvFile {
    getRootProps: any;
    getInputProps: any;
    isDragActive: boolean;
    fileSelected?: any;
    importError?: string;
    sectors: Array<any>;
    templates?: any;
    selectedSector?: any;
    selectedCurrency?: any;
    currencies: Array<any>;
    showMarket?: boolean;
    markets: Array<any>;
    selectedMarket?: any;
    selectedTemplate?: any;
    onChangeSelectedSector: (e: any) => void;
    onChangeSelectedCurrency: (e: any) => void;
    onChangeSelectedMarket: (e: any) => void;
    onChangeSelectedTemplate: (e: any) => void;
    onUploadFile: () => void;
    onClickBasedOnMarket: () => void;
}

const UploadCsvFile = ({
    getRootProps,
    getInputProps,
    isDragActive,
    fileSelected,
    templates,
    importError,
    currencies,
    sectors,
    selectedSector,
    selectedTemplate,
    selectedCurrency,
    showMarket,
    markets,
    selectedMarket,
    onChangeSelectedSector,
    onChangeSelectedCurrency,
    onChangeSelectedMarket,
    onChangeSelectedTemplate,
    onUploadFile,
    onClickBasedOnMarket
}: IUploadCsvFile) => {
    return (
        <div className='p-3'>
            <div className='mb-3'>
                <p className='text-sm font-semibold'>Import CSV</p>
                <p className='text-md mb-2 mt-1'>Import data for partner.</p>
                {importError && <Error text={importError} />}
            </div>
            <div className='flex flex-col items-center'>
                <SelectCheckbox
                    placeholder='Select'
                    name='Category'
                    dataQa={'category-to-select'}
                    containerStyle='mb-1 min-w-full'
                    selectedOption={selectedSector}
                    onChangeSelectedOption={onChangeSelectedSector}
                    options={sectors || []}
                />
                <SelectCheckbox
                    name='Currency'
                    placeholder='Select'
                    dataQa={'currency-to-select'}
                    disabled={showMarket}
                    containerStyle='mb-1 min-w-full'
                    selectedOption={selectedCurrency}
                    onChangeSelectedOption={onChangeSelectedCurrency}
                    options={currencies || []}
                />
                <div className='flex flex-row justify-start items-center mb-6 min-w-[100%]'>
                    <div className='flex items-center mr-2'>
                        <input
                            onChange={onClickBasedOnMarket}
                            checked={showMarket}
                            id='checkbox-all'
                            type='checkbox'
                            data-qa={'checkbox'}
                            className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                        />
                        <label htmlFor='checkbox-all' className='sr-only'>
                            checkbox
                        </label>
                    </div>
                    <p>
                        Import based on market.
                    </p>
                </div>
                {showMarket &&
                    <SelectCheckbox
                        name='Market'
                        placeholder='Select'
                        dataQa={'market-to-select'}
                        containerStyle='mb-1 min-w-full'
                        selectedOption={selectedMarket}
                        onChangeSelectedOption={onChangeSelectedMarket}
                        options={markets || []}
                    />
                }
                <SelectCheckbox
                    name='Template'
                    dataQa={'template'}
                    placeholder='Select'
                    containerStyle='mb-1 min-w-full'
                    selectedOption={selectedTemplate}
                    onChangeSelectedOption={onChangeSelectedTemplate}
                    options={templates || []}
                />
            </div>
            <div className='flex flex-col items-center justify-center min-h-[200px] rounded'>
                <div className='flex flex-col items-center justify-center' {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p className='font-semibold'>Drag n drop csv file here</p>
                    }
                    <p className='mb-2'>
                        or
                    </p>
                    <Button
                        icon={<img src='/assets/shared/upload-file.svg' className='w-[30px] object-contains pr-2' />}
                        className={'btn-main !bg-[#202020] !text-white !py-2 !shadow-none flex flex-row items-center'}
                        label={'Select File'}
                        dataQa={'select-file'}
                    />
                    {fileSelected && <p>{fileSelected?.name}</p>}
                </div>
            </div>
            <div className='flex flex-col items-center my-2'>
                <Button
                    label='Upload File'
                    dataQa={'upload-file'}
                    onClickButton={() => fileSelected && onUploadFile()}
                    className={`${fileSelected ? 'btn-main' : 'btn-main-disable'} !py-2 !shadow-none min-w-[150px]`}
                />
            </div>
        </div>
    )
}

export default UploadCsvFile;