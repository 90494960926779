import React, { useState, useEffect } from 'react';
import moment from 'moment';


interface ICountdown {
    cashedOutDate: string
}

const Countdown = ({ cashedOutDate }: ICountdown) => {
    const [timeLeft, setTimeLeft] = useState<{
        days: number,
        hours: number,
        minutes: number,
        seconds: number,
        time: number,
    }>();

    const calculateTimeLeft = () => {
        const now = moment();
        const target = moment(cashedOutDate).add(14, 'days');
        const duration = moment.duration(target.diff(now));

        return {
            days: Math.floor(duration.asDays()),
            hours: duration.hours(),
            minutes: duration.minutes(),
            seconds: duration.seconds(),
            time: duration.asMilliseconds(),
        };
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer);
    }, [cashedOutDate]);

    if (timeLeft && timeLeft.time < 0) return <div className='ml-6 px-4 py-2 bg-white flex gap-2 rounded-md'>
        <img className='w-[24px] object-contain' src="/assets/order-status/timer-passed.svg" alt="timer" />
        <div>
            <p className='text-[#E50000] text-base'>14 days passed, </p>
            <div className='text-[#E50000] text-base'>
                Review Price
            </div>
        </div>
    </div>
    return (
        <div className='ml-6 px-4 py-2 bg-white flex gap-2 rounded-md'>
            <img className='w-[24px] object-contain' src="/assets/order-status/timer.svg" alt="timer" />
            <div>
                <p className='text-[#8A8A8E] text-sm'>System price review in: </p>
                <div className='text-[#202020] text-base'>
                    {timeLeft?.days && !isNaN(timeLeft?.days)? timeLeft.days : '-'}d {timeLeft?.hours && !isNaN(timeLeft?.hours)? timeLeft.hours : '-'}h {timeLeft?.minutes && !isNaN(timeLeft?.minutes)? timeLeft.minutes : '-'}min {timeLeft?.seconds && !isNaN(timeLeft?.seconds)? timeLeft.seconds : '-'}sec
                </div>
            </div>
        </div>
    );
};

export default Countdown;
