import React from 'react';
import PieChart from '../../../../shared/pie-chart';
import WhiteContainer from '../../../../shared/white-container';


interface IPieChartDetails {
    marketsStats: any
}

let chartData = [
    { name: 'Broker Available Markets', value: 0 },
    { name: 'Non-operating Markets', value: 0 },
]

const PieChartDetails = ({
    marketsStats
}: IPieChartDetails) => {

    chartData = chartData?.map((data) => {
        return {
            ...data,
            value: data?.name === 'Broker Available Markets' ? marketsStats?.operatingMarkets : marketsStats?.missingMarkets
        }
    })

    return (
        <WhiteContainer type='rounded'>
            <div>
                <div className='flex flex-row justify-between'>
                    <p className='pl-2 text-primary-light font-bold text-xs mb-2'>Available Markets</p>
                    {/* <img alt='items arrow' src={'/assets/pricing/see-more.svg'} className={'w-[7x] rotate-90 mb-2 cursor-pointer mr-2'} /> */}
                </div>
                <div className='grid grid-cols-7'>
                    <div className='col-span-2 pl-2 flex flex-col justify-center'>
                        {marketsStats?.missingMarkets > 0 ?
                            <div>
                                <p className='font-bold text-[18px]'>Today you only reach {(marketsStats?.operatingMarkets || 0).toFixed(2)}% of the total Market</p>
                                <p className='mt-5'>Open new markets to reach more customers.</p>
                            </div>

                            : <div>
                                <p className='font-bold text-[18px]'>Today you are available on all markets</p>
                                <p className='mt-5'>New markets will be added soon.</p>
                            </div>
                        }
                    </div>
                    <div className='col-span-5'>
                        <PieChart
                            chartData={chartData}
                        />
                    </div>
                </div>
            </div>
        </WhiteContainer>
    )
}

export default PieChartDetails;