import { createApi } from '@reduxjs/toolkit/query/react';
import { IUserSchema } from '../../interfaces/user/IUserSchema';
import { ApiResponse } from '../../interfaces/models/models/paginated-response.model';
import { userAxiosQuery } from '../../utils/axios/user.axios';
import { IUser } from '../../interfaces/user/IUser';
import { IPaginationPayload } from '../../interfaces/shared/IPaginationPayload';
import { PaginatedUsers } from '../../interfaces/user/paginatedUser.type';
import { readFromStorage } from '../../utils/webStorage';

export const userAPI = createApi({
    reducerPath: 'users',
    baseQuery: userAxiosQuery(),
    tagTypes: ['Posts'],
    endpoints: (builder) => ({
        getAllUsers: builder.query<PaginatedUsers, IPaginationPayload>({
            query: ({ filters, pageNumber, pageSize }: IPaginationPayload) => ({
                url: filters
                    ? `/users?page=${pageNumber}&size=${pageSize}&filters=${filters}`
                    : `/users?page=${pageNumber}&size=${pageSize}`,
                method: 'GET',
            }),
        }),
        setPassword: builder.mutation<PaginatedUsers, { username: string, password: string }>({
            query: ({ username, password }) => ({
                url: `/${username}/set-password`,
                method: 'PUT',
                data: { password }
            }),
        }),
        getCurrentUser: builder.query<IUser, void>({
            query: () => ({
                url: '/dom/authorize',
                method: 'POST',
                data: {
                    token: localStorage.getItem('accessToken') || ''
                },
            }),
        }),
        getSingleUser: builder.query<IUserSchema, string>({
            query: (id: string) => ({
                url: `/user/${id}`,
                method: 'GET',
            }),
        }),
        postUser: builder.mutation<ApiResponse<IUserSchema>, IUserSchema>({
            query: (data) => ({
                url: '/sign-up',
                method: 'POST',
                data,
            }),
        }),
        addToGroup: builder.mutation<IUserSchema, { username: string; group: string }>({
            query: ({ username, group }) => ({
                url: `/${username}/add-to-group`,
                method: 'POST',
                data: { group },
            }),
        }),
        removeFromGroup: builder.mutation<IUserSchema, { username: string; group: string }>({
            query: ({ username, group }) => ({
                url: `/${username}/remove-from-group`,
                method: 'POST',
                data: { group },
            }),
        }),
        userNavigation: builder.query<any, void>({
            query: () => ({
                url: '/users/access-control',
                method: 'GET',
                data: {
                    token: localStorage.getItem('accessToken') || ''
                },
            }),
        }),
        confirmUser: builder.mutation<IUserSchema, string>({
            query: (username) => ({
                url: `/${username}/confirm`,
                method: 'GET',
            }),
        }),
        getWingUserHash: builder.mutation<any, void>({
            query: () => ({
                url: '/users/intercom',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${readFromStorage('accessToken')}`,
                },
            }),
        })
    }),
});

export const {
    useGetAllUsersQuery,
    useSetPasswordMutation,
    useGetSingleUserQuery,
    usePostUserMutation,
    useAddToGroupMutation,
    useRemoveFromGroupMutation,
    useGetCurrentUserQuery,
    useConfirmUserMutation,
    useUserNavigationQuery,
    useGetWingUserHashMutation
} = userAPI;
