import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';
import { IShippingServices } from '../../../../interfaces/shipping-services/IShippingServices';

const tryToFetchShippingServices = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${USERS_API_URL}/markets/all/shipping-configs`
    return brokersAxios.put<any>(url, data);
};

const tryToFetchShippingServicesGrid = (): Promise<AxiosResponse<any>> => {
    const url = `${USERS_API_URL}/markets/all/shipping-configs/grid-view`
    return brokersAxios.put<any>(url);
};

const tryToFetchMarketShippingServices = (marketId: string): Promise<AxiosResponse<any>> => {
    const url = `${USERS_API_URL}/markets/${marketId}/shipping-configs/all`
    return brokersAxios.get<any>(url);
};

const tryToDeleteMarketShippingServices = (marketId: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/markets/${marketId}/shipping-configs/delete`);
};

const tryToAddMarketShippingServices = (marketId: string, payload: IShippingServices): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/markets/${marketId}/shipping-configs/insert`, payload);
};

const tryToEditMarketShippingServices = (marketId: string, payload: { data: IShippingServices }): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/markets/${marketId}/shipping-configs/edit`, payload);
};

export const ShippingServicesService = {
    tryToFetchShippingServices,
    tryToFetchShippingServicesGrid,
    tryToFetchMarketShippingServices,
    tryToDeleteMarketShippingServices,
    tryToAddMarketShippingServices,
    tryToEditMarketShippingServices
};
