import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../app/hooks';
import { tryToAddAgent } from '../../../store/brokers/partners/agents/agentsSlice';
import Input from '../../../shared/input';
import Error from '../../../shared/error';
import Button from '../../../shared/button';


type FormValues = {
    name: string;
    email: string;
    username: string;
    password: string;
    country: string;
    type: string;
    priority?: number
};

const CreateAgent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [createError, setCreateError] = useState<string | null>()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormValues>({});

    const onSubmit = async (data: FormValues) => {
        const payload = {
            ...data,
            enabled: false,
            username: (data?.username || '').toLowerCase(),
            type: 'agent'
        };
        delete payload.priority
        try {
            await dispatch(tryToAddAgent(payload)).unwrap();
            reset();
            navigate('/members');
            setCreateError(null)
        } catch (error) {
            setCreateError(`${error}`)
        }
    };

    return (
        <div>
            <div>
                <p className='page-title'>Create Member</p>
            </div>
            <div className='mt-5 mb-2'>
                {createError && <Error text={createError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-3 gap-4 content-center'>
                        <Input
                            placeholder='Name'
                            dataQa={'name'}
                            register={register('name', {
                                required: {
                                    message: 'Name is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Name is required'
                            })}
                            error={errors.name?.message}
                        />
                        <Input
                            placeholder='Username'
                            dataQa={'username'}
                            register={register('username', {
                                required: {
                                    message: 'Username is required',
                                    value: true,
                                },
                                pattern: {
                                    /* eslint-disable-next-line */
                                    value: /^\S*$/,
                                    message: 'Username should not include spaces'
                                },
                                validate: (value: string) => !!value.trim() || 'Username is required'
                            })}
                            error={errors.username?.message}
                        />
                        <Input
                            placeholder='Email'
                            dataQa={'email'}
                            register={register('email', {
                                required: {
                                    message: 'Email is required',
                                    value: true,
                                },
                                pattern: {
                                    /* eslint-disable-next-line */
                                    value: /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please write a valid email'
                                },
                                validate: (value: string) => !!value.trim() || 'Email is required'
                            })}
                            error={errors.email?.message}
                        />
                        <Input
                            placeholder='Country'
                            dataQa={'country'}
                            register={register('country', {
                                required: {
                                    message: 'Country is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Country is required'
                            })}
                            error={errors.country?.message}
                        />
                        <Input
                            placeholder='Password'
                            dataQa={'password'}
                            type={'password'}
                            register={register('password', {
                                required: {
                                    message: 'Password is required',
                                    value: true,
                                },
                                minLength: {
                                    value: 8,
                                    message: 'Minimum number of characters is 8'
                                },
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                    message: 'Password should be at least 8 characters long, and must contain at least 1 number, 1 lower case, 1 upper case and 1 special character(@$!%*?&)'
                                },
                                validate: (value: string) => !!value.trim() || 'Password is required and min length is 8'
                            },
                            )}
                            error={errors.password?.message}
                        />
                    </div>
                    <div className='flex flex-row justify-start py-2'>
                        <Button
                            label='Create Member'
                            dataQa={'create-agent'}
                            type={'submit'}
                            className={'btn-primary'}
                        />
                    </div>
                </form>
            </div>
        </div >
    )
}

export default CreateAgent;