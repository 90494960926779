import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ISectionListState } from '../../../../interfaces/sections/ISectionListState';
import { SectionsService } from './sectionsApi';
import {
    ISection,
    ISectionChild,
} from '../../../../interfaces/sections/ISection';
import { IEditChild } from '../../../../interfaces/sections/IEditChild';
import { PaginatedSections } from '../../../../interfaces/sections/paginatedStore.type';


const initialState: ISectionListState = {
    sectionIsLoading: false,
    sectionsAreLoading: false,
    requestStatus: 'default',
    message: '',
    toastSuccessRequest: false,
};

export const tryToFetchAllSections = createAsyncThunk<any, any>(
    'sections/tryToFetchAllSections',
    async ({ filters }) => {
        const result = await SectionsService.tryToFetchAllSections(filters);
        return result?.data;
    },
);


export const tryToFetchAllSectionsPaginated = createAsyncThunk<
    PaginatedSections,
    any
>('sections/tryToFetchAllSectionsPaginated', async ({ pageNumber, pageSize, filters }) => {
    const result = await SectionsService.tryToFetchAllSectionsPaginated(
        pageNumber,
        pageSize,
        filters,
    );
    return result?.data;
});

export const tryToCreateSection = createAsyncThunk<any, any>(
    'sections/tryToCreateSection',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await SectionsService.tryToCreateSection(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditSection = createAsyncThunk<any, any>(
    'sections/tryToEditSection',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await SectionsService.tryToEditSection(
                data._id || '',
                data?.data
            );
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEnableDisableSection = createAsyncThunk<any, any>(
    'sections/tryToEnableDisableSection',
    async ({ id, status }, { rejectWithValue }) => {
        try {
            const result = await SectionsService.tryToEnableDisableSection(
                id || '',
                status
            );
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchSingleSection = createAsyncThunk<any, string>(
    'sections/tryToFetchSingleSection',
    async (sectionId) => {
        const result = await SectionsService.tryToFetchSingleSection(sectionId);
        return result?.data?.data;
    },
);

export const tryToDeleteSection = createAsyncThunk<any, string>(
    'sections/tryToDeleteSection',
    async (sectionId, { rejectWithValue }) => {
        try {
            const result = await SectionsService.tryToDeleteSection(sectionId);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToCreateChild = createAsyncThunk<any, ISectionChild>(
    'sections/tryToCreateChild',
    async (data: ISectionChild, { rejectWithValue }) => {
        try {
            const result = await SectionsService.tryToCreateChild(
                data.sectionId || '',
                data,
            );
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditChild = createAsyncThunk<any, IEditChild>(
    'sections/tryToEditChild',
    async (data: IEditChild, { rejectWithValue }) => {
        try {
            const result = await SectionsService.tryToEditChild(
                data._id || '',
                data,
            );
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditActions = createAsyncThunk<any, any>(
    'sections/tryToEditActions',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await SectionsService.tryToEditActions(id, data)
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDeleteChild = createAsyncThunk<any, string>(
    'sections/tryToDeleteChild',
    async (sectionId, { rejectWithValue }) => {
        try {
            const result = await SectionsService.tryToDeleteChild(sectionId);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const sectionsSlice = createSlice({
    name: 'sections',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAllSections
            .addCase(tryToFetchAllSections.pending, (state) => {
                state.sectionsAreLoading = true;
            })
            .addCase(tryToFetchAllSections.rejected, (state) => {
                state.sectionsAreLoading = false;
            })
            .addCase(tryToFetchAllSections.fulfilled, (state, action) => {
                state.sectionsAreLoading = false;
                state.allSections = action.payload.data;
            })

            // tryToFetchAllSectionsPaginated
            .addCase(tryToFetchAllSectionsPaginated.pending, (state) => {
                state.sectionsAreLoading = true;
            })
            .addCase(tryToFetchAllSectionsPaginated.rejected, (state) => {
                state.sectionsAreLoading = false;
            })
            .addCase(tryToFetchAllSectionsPaginated.fulfilled, (state, action) => {
                state.sectionsAreLoading = false;
                state.paginatedSections = action.payload;
            })

            // tryToCreateSection
            .addCase(tryToCreateSection.pending, (state) => {
                state.sectionsAreLoading = true;
            })
            .addCase(tryToCreateSection.rejected, (state) => {
                state.sectionsAreLoading = false;
            })
            .addCase(tryToCreateSection.fulfilled, (state, action) => {
                state.sectionsAreLoading = false;
                state.requestStatus = 'success';
                const data = action.payload || action.meta.arg;
                if (state.allSections) {
                    state.allSections = [...state.allSections, data];
                }
            })

            // tryToEditSection
            .addCase(tryToEditSection.pending, (state) => {
                state.sectionsAreLoading = true;
            })
            .addCase(tryToEditSection.rejected, (state) => {
                state.sectionsAreLoading = false;
            })
            .addCase(tryToEditSection.fulfilled, (state, action) => {
                state.sectionsAreLoading = false;
                if (state.allSections)
                    state.allSections =
                        { ...state }.allSections?.map((section: ISection) => {
                            if (section?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return section;
                            }
                        }) || [];
                state.sectionDetails = action.payload;
            })

            // tryToFetchSingleSection
            .addCase(tryToFetchSingleSection.pending, (state) => {
                state.sectionIsLoading = true;
            })
            .addCase(tryToFetchSingleSection.rejected, (state) => {
                state.sectionIsLoading = false;
            })
            .addCase(tryToFetchSingleSection.fulfilled, (state, action) => {
                state.sectionIsLoading = false;
                state.sectionDetails = action.payload;
            })

            // tryToDeleteSection
            .addCase(tryToDeleteSection.pending, (state) => {
                state.sectionsAreLoading = true;
            })
            .addCase(tryToDeleteSection.rejected, (state) => {
                state.sectionsAreLoading = false;
            })
            .addCase(tryToDeleteSection.fulfilled, (state, action) => {
                state.sectionsAreLoading = false;
            })

            // tryToEnableDisableSection
            .addCase(tryToEnableDisableSection.pending, (state) => {
                state.sectionIsLoading = true;
            })
            .addCase(tryToEnableDisableSection.rejected, (state) => {
                state.sectionIsLoading = false;
            })
            .addCase(tryToEnableDisableSection.fulfilled, (state, action) => {
                state.sectionIsLoading = false;
                if (state.paginatedSections?.data?.elements)
                    state.paginatedSections.data.elements =
                        { ...state }.paginatedSections?.data.elements.map((paginatedSection: any) => {
                            if (paginatedSection?._id === action.meta?.arg?.id) {
                                return {
                                    ...paginatedSection,
                                    enabled: action?.meta?.arg?.status
                                };
                            } else {
                                return paginatedSection;
                            }
                        }) || [];
            })

            // trytoCreateChild
            .addCase(tryToCreateChild.pending, (state) => {
                state.sectionIsLoading = true;
            })
            .addCase(tryToCreateChild.rejected, (state) => {
                state.sectionIsLoading = false;
            })
            .addCase(tryToCreateChild.fulfilled, (state) => {
                state.sectionIsLoading = false;
            })

            // tryToEditChild
            .addCase(tryToEditChild.pending, (state) => {
                state.sectionsAreLoading = true;
            })
            .addCase(tryToEditChild.rejected, (state) => {
                state.sectionsAreLoading = false;
            })
            .addCase(tryToEditChild.fulfilled, (state, action) => {
                state.sectionsAreLoading = false;
                if (state.sectionDetails?.children)
                    state.sectionDetails.children =
                        { ...state }.sectionDetails?.children?.map((child: ISectionChild) => {
                            if (child?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return child;
                            }
                        }) || [];
                state.sectionDetails = action.payload;
            })

            // tryToEditActions
            .addCase(tryToEditActions.pending, (state) => {
                state.sectionsAreLoading = true;
            })
            .addCase(tryToEditActions.rejected, (state) => {
                state.sectionsAreLoading = false;
            })
            .addCase(tryToEditActions.fulfilled, (state, action) => {
                state.sectionsAreLoading = false;
                state.sectionDetails = action.payload
            })

            // tryToDeleteChild
            .addCase(tryToDeleteChild.pending, (state) => {
                state.sectionsAreLoading = true;
            })
            .addCase(tryToDeleteChild.rejected, (state) => {
                state.sectionsAreLoading = false;
            })
            .addCase(tryToDeleteChild.fulfilled, (state, action) => {
                state.sectionsAreLoading = false;
            })

    },
});

export default sectionsSlice.reducer;
