import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToFetchAllSalesHistory } from '../../../../store/brokers/admin/reports/reportsServiceSlice';
import SalesHistoryTable from '../tables/SalesHistoryTable';
import SalesHistoryDetails from './SalesHistoryDetailsCategory';
import SalesHistoryTableFilters from '../details/SalesHistoryTableFilters';
import SearchWithButton from '../../../../shared/search-with-button';


const SalesHistory = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<Array<any>>([]);
    const [search, setSearch] = useState<string>();
    const [filtersValues, setFiltersValues] = useState<any>();
    const [showSalesHistory, setShowSalesHistory] = useState<{ show: boolean, id: any }>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const paginationData = useAppSelector((state) => state?.reports?.salesHistory?.data.page);
    const state = useAppSelector((state) => state.reports);
    const loading = useAppSelector((state) => state?.reports?.salesHistoryAreLoading)

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

    useEffect(() => {
        if (state.salesHistory) {
            const rows =
                state.salesHistory?.data?.elements &&
                    Array.isArray(state.salesHistory?.data.elements)
                    ? state.salesHistory?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.salesHistory]);

    const getSalesHistoryData = async () => {
        let formatBody: any = {
            ...paginationState,
        }
        if (filtersValues?.user) {
            formatBody = {
                ...formatBody || {},
                filters: {
                    user: filtersValues?.user
                }
            }
        }
        if (filtersValues?.from || filtersValues?.to) {
            if (filtersValues?.from) {
                formatBody = {
                    ...formatBody || {},
                    filters: {
                        ...formatBody?.filters || {},
                        dateRange: {
                            ...formatBody?.filters?.dateRange || {},
                            from: filtersValues?.from
                        }
                    }
                }
            }
            if (filtersValues?.to) {
                formatBody = {
                    ...formatBody || {},
                    filters: {
                        ...formatBody?.filters || {},
                        dateRange: {
                            ...formatBody?.filters?.dateRange || {},
                            to: filtersValues?.to
                        }
                    }
                }
            }
        }
        if (search) {
            formatBody = {
                ...formatBody || {},
                filters: {
                    ...formatBody?.filters || {},
                    itemId: search
                }
            }
        }
        await dispatch(tryToFetchAllSalesHistory(formatBody));
    }

    useEffect(() => {
        getSalesHistoryData()
    }, [paginationState])

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationSettings()
    }

    const onClearAllFilters = () => {
        setFiltersValues(undefined)
        onResetPaginationSettings()
    }

    const onSeeSalesHistoryDetails = (id: string) => {
        setShowSalesHistory({
            show: true,
            id: id
        })
    }

    const onChangeFilterValue = (value: string | null | undefined, type: string) => {
        setFiltersValues({
            ...filtersValues,
            [type]: value || undefined
        })
        onResetPaginationSettings()
    }

    const onGoBack = () => {
        setShowSalesHistory(undefined)
    }

    return (
        <>{
            showSalesHistory?.show ?
                <SalesHistoryDetails
                    onGoBack={onGoBack}
                    id={showSalesHistory?.id || ''} />
                :
                <div>
                    <div className='mt-10 mb-5'>
                        <SalesHistoryTableFilters
                            filters={filtersValues}
                            onChangeFilterValue={onChangeFilterValue}
                        />
                    </div>
                    <div className='mb-4 mt-2'>
                        <div className='bg-white pl-4 pt-2'>
                            <SearchWithButton
                                buttonLabel='Clear All Filters'
                                placeholder='Filter table'
                                onChangeSearchValue={onChangeSearchValue}
                                search={search}
                                onClickButton={onClearAllFilters}
                                buttonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end bg-transparent mt-1'}
                                mainContainerStyle={'!grid-cols-10'}
                            />
                        </div>
                        <div>
                            <SalesHistoryTable
                                rows={rows}
                                paginationData={paginationData}
                                setPaginationState={setPaginationState}
                                onSeeSalesHistoryDetails={onSeeSalesHistoryDetails}
                                rowsLoading={loading}
                            />
                        </div>
                    </div>
                </div>
        }
        </>
    )
}

export default SalesHistory;