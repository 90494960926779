import React, { useEffect, useState } from 'react'
import SelectCheckbox from '../../../../shared/select-checkbox';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../shared/input';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToFetchAllCreditNotes, tryToMarkCreditNoteAsPaid } from '../../../../store/brokers/admin/rma/RMAsSlice';
import EmptyContainer from '../../../../shared/empty-container';
import CreditNotesTable from '../tables/CreditNotesTable';
import MarkAsPaidModal from '../modals/MarkAsPaidModal';
import Toast from '../../../../shared/toast';
import { IToast } from '../../../../interfaces/components/IToast';
import Button from '../../../../shared/button';

interface ICreditNotesTab {
  paginationState: IPaginationPayload,
  setPaginationState: React.Dispatch<React.SetStateAction<IPaginationPayload>>
  onResetPaginationSettings: () => void
  accessControl: any
  pageAccess: any
  query: URLSearchParams
  searchParams: URLSearchParams
  setSearchParams: any
  markets: any
}

const OPTIONS = [
  {
    label: 'All statuses',
    value: undefined,
  },
  {
    label: 'Not Paid',
    value: false,
  },
  {
    label: 'Paid',
    value: true,
  },
];

const CreditNotesTab = ({ paginationState, setPaginationState, onResetPaginationSettings, accessControl, pageAccess, query, searchParams, setSearchParams, markets }: ICreditNotesTab) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const [showMarkAsPaidModal, setShowMarkAsPaidModal] = useState({ show: false, creditAmount: 0, id: '', currency: '' })
  const [loadingAction, setLoadingAction] = useState(false)
  const [showToast, setShowToast] = useState<IToast | null>();
  const [selectedStatus, setSelectedStatus] = useState(OPTIONS[0]);
  const [selectedMarket, setSelectedMarket] = useState<{label: string, value: string, id: string}>({label: '', value: '', id: ''});
  const [rows, setRows] = useState<any[]>([]);
  const paginationData = useAppSelector((state) => state.RMAs.creditNotes?.data?.page);
  const rowsLoading = useAppSelector((state) => state.RMAs.creditNotesLoading);
  const state = useAppSelector((state) => state.RMAs);

  const onSeeCreditNoteDetails = (id: string) => {
    navigate(`/rma-management/credit-note/${id}`);
  }

  const onClickStatusFilter = (value: any, type?: string) => {
    setSelectedStatus(value);
    if (value.value !== undefined) {
      searchParams.set('paid', value?.value);
    } else searchParams.delete('paid');
    setSearchParams(searchParams);
    onResetPaginationSettings();
  };
  const onChangeSearchValue = (
    value: string | null | undefined,
    type: string,
  ) => {
    setPaginationState({
      ...(paginationState || {}),
      pageNumber: 1,
      filters: value || undefined,
    });
    if (value) searchParams.set('search', value);
    else searchParams.delete('search');
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };
  const getCreditNotes = async () => {
    const formatData: any = {
      ...paginationState,
      data: {
        paidStatus: selectedStatus.value,
      }
    }
    if(selectedMarket?.id?.length){
      formatData.data.marketId = selectedMarket?.id
    }
    dispatch(tryToFetchAllCreditNotes(formatData))
  }
  const onClickMarkAsPaid = (id: string, creditAmount: number, currency: string) => {
    setShowMarkAsPaidModal({ show: true, creditAmount, id, currency })
  }
  const onApproveAction = async () => {
    setLoadingAction(true)
    try {
      await dispatch(tryToMarkCreditNoteAsPaid(showMarkAsPaidModal.id)).unwrap()
      setShowToast({
        type: 'success',
        message: 'Credit Note was successfully marked as paid.',
      });
    } catch (err: any) {
      setShowToast({ type: 'error', message: `${err.message}` });
    } finally {
      setShowMarkAsPaidModal({ show: false, creditAmount: 0, id: '', currency: '' })
      setLoadingAction(false)
      getCreditNotes()
    }
  }

  useEffect(() => {
    getCreditNotes()
  }, [paginationState, selectedStatus])

  useEffect(() => {
    if (query?.get('search')) {
      setPaginationState({ ...paginationState, filters: query?.get('search') || undefined })
    } else searchParams.delete('search')
    if (query?.get('paid')) {
      setSelectedStatus(OPTIONS.find(o => (o.value + '') === query?.get('paid')) || OPTIONS[0])
    } else searchParams.delete('paid')
  }, [query?.get('search'), query?.get('paid')])

  const onClickMarketFilter = (value: any, name?: string) => {
    if (name) {
        setSelectedMarket({label: value?.label, value: value?.displayName, id: value?._id })
        searchParams.set('market', value?.displayName)
        setSearchParams(searchParams)
        onResetPaginationSettings()
    }
  } 


  useEffect(() => {
    if (state.creditNotes) {
      const rows =
        state.creditNotes?.data?.elements &&
          Array.isArray(state.creditNotes?.data?.elements)
          ? state.creditNotes?.data?.elements?.map((r) => ({
            ...r,
            id: r._id,
          }))
          : [];
      setRows(rows);
    }
  }, [state.creditNotes]);

  const onClearAllFilters = () => {
    setSelectedStatus(OPTIONS[0]);
    setSearchParams('')
    setSelectedMarket({label: '', value: '', id: ''}) 
    setPaginationState({ ...paginationState, filters:  '' })
  }


  return (
    <div>
      <div className='my-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
        <SelectCheckbox
          name={'Market'}
          dataQa={'market-selected'}
          containerStyle={'!min-w-[200px]'}
          placeholder={selectedMarket.label || 'All Markets'}
          options={[{label: 'All Markets', value: undefined}, ...markets || []] || []}
          uniqueName={'market'}
          onChangeSelectedOption={onClickMarketFilter}
        />
        <SelectCheckbox
          name="Status"
          dataQa={'status-filter'}
          placeholder={selectedStatus.label || ''}
          options={OPTIONS}
          containerStyle={'!min-w-[200px]'}
          onChangeSelectedOption={onClickStatusFilter}
          uniqueName={'Status'}
        />
      </div>
      <div className="bg-[#F7A21E1A] rounded-[10px] p-2 flex flex-row items-center justify-between">
        <Input
          placeholder={'Filter Table'}
          containerStyle="mb-0 pb-0 relative flex-initial w-96"
          inputStyle={`mb-0 border-0 focus:outline-none`}
          icon={'/assets/shared/search.svg'}
          showValue={true}
          inputValue={paginationState?.filters}
          inputUniqueName={'search'}
          onChangeInput={onChangeSearchValue}
          hideInputSpace={true}
          dataQa={'filter-table'}
        />
        <Button
          label={'Clear All Filters'}
          dataQa={'clear-all-filters'}
          className='btn-primary-text-underline min-w-auto !mt-0 flex flex-row justify-end bg-transparent mr-2 mb-1'
          onClickButton={onClearAllFilters}
        />
      </div>
      <div className="my-5 relativ">
        {rows?.length > 0 || rowsLoading ? (
          <CreditNotesTable
            rows={rows}
            rowsLoading={rowsLoading}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            onSeeCreditNoteDetails={onSeeCreditNoteDetails}
            viewDetails={(!accessControl || (pageAccess?.actions?.['View Credit Note Details'])) || false}
            accessPDF={!accessControl || pageAccess?.actions?.['Download PDF'] || false}
            accessCSV={!accessControl || pageAccess?.actions?.['Download CSV'] || false}
            accessMarkAsPaid={!accessControl || pageAccess?.actions?.['Mark as Paid'] || false}
            onClickMarkAsPaid={onClickMarkAsPaid}
          />
        ) : (
          <EmptyContainer text={'No items found.'} showImage={true} />
        )}
      </div>
      {<MarkAsPaidModal open={showMarkAsPaidModal.show}
        handleCloseModal={() => setShowMarkAsPaidModal({ show: false, creditAmount: 0, id: '', currency: '' })}
        onApproveAction={onApproveAction}
        creditAmount={showMarkAsPaidModal.creditAmount}
        currency={showMarkAsPaidModal.currency}
        loading={loadingAction} />}
      {showToast?.message &&
        <Toast
          type={showToast?.type}
          text={showToast?.message}
          onHandleCloseToast={() => setShowToast(null)}
        />
      }
    </div>
  )
}

export default CreditNotesTab