import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import { IPartnerSettings } from '../../../../interfaces/partners/IPartnerSettings';
import {
    tryToGetPrioritySettings,
    tryToSetPriorityOfBrokers
} from '../../../../store/brokers/admin/partners/partnersSlice';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';
import Button from '../../../../shared/button';
import SelectCheckbox from '../../../../shared/select-checkbox';
import PartnerPriorityContainer from '../loading/PartnerPriorityContainer';


interface IPriorityModal {
    markets: Array<any>;
    sectors: Array<any>;
    brokerId: string;
    openPriorityModal: boolean;
    setShowToast: React.Dispatch<React.SetStateAction<IToast | null | undefined>>;
    handleClosePriorityModal: () => void;
}

const PriorityModal = ({
    markets,
    sectors,
    brokerId,
    openPriorityModal,
    setShowToast,
    handleClosePriorityModal,
}: IPriorityModal) => {
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<any>([])
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [saveError, setSaveError] = useState<string>()
    const [loading, setLoading] = useState<boolean>(true)

    const getDataFromPrioritySettings = async () => {
        try {
            if (brokerId) {
                const response = await dispatch(tryToGetPrioritySettings(brokerId)).unwrap()
                setFormData(response?.data || [])
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
            setFormData([])
        }
        setLoading(false)
    }

    useEffect(() => {
        getDataFromPrioritySettings()
        setFormData([])
    }, [brokerId, openPriorityModal])

    const onChangesSelectedCategory = (value: any, type?: string, index?: number) => {
        if (index || index === 0) {
            const findIndexAssetTemplate = (formData && formData?.length > 0) && formData?.find((item: any, idx: number) => idx === index)
            if (findIndexAssetTemplate) {
                const formatCategoryMarket = (formData && formData?.length > 0) && formData?.map((item: any, idx: number) => {
                    if (idx === index) {
                        return {
                            assetTemplateId: value?.value,
                            assetTemplateName: value?.displayName,
                            marketsData: []
                        }
                    } else {
                        return item
                    }
                })
                setFormData(formatCategoryMarket)
            } else {
                const formatCategoryMarket = [
                    ...formData || [],
                    {
                        assetTemplateId: value?.value,
                        assetTemplateName: value?.displayName,
                        marketsData: []
                    }
                ]
                setFormData(formatCategoryMarket)
            }
        }
    }

    const onChangeSelectedMarkets = (values: any, assetTemplateId?: string, index?: number) => {
        if ((index || index === 0) && assetTemplateId) {
            const findItem = (formData && formData?.length > 0) && formData?.find((item: any) => item?.assetTemplateId === assetTemplateId)
            if (findItem) {
                const formatMarkets = (formData && formData?.length > 0) && formData?.map((item: any) => {
                    if (item?.assetTemplateId === assetTemplateId) {
                        const formatMarketsData = (item?.marketsData && item?.marketsData?.length > 0) ? item?.marketsData?.map((obj: any, idx: number) => {
                            if (idx === index) {
                                return {
                                    ...obj || {},
                                    markets: (values && values?.length > 0) ? values?.map((value: any) => value?.displayName) : []
                                }
                            } else {
                                return obj
                            }
                        }) :
                            [
                                {
                                    priorityValue: 0,
                                    markets: (values && values?.length > 0) ? values?.map((value: any) => value?.displayName) : []
                                }
                            ]
                        return {
                            ...item || {},
                            marketsData: formatMarketsData || []
                        }
                    } else {
                        return item
                    }
                })
                setFormData(formatMarkets || [])
            }
        }
    }

    const onChangePriority = (value: any, assetTemplateId?: string, index?: number) => {
        if ((index || index === 0) && assetTemplateId) {
            const findItem = (formData && formData?.length > 0) && formData?.find((item: any) => item?.assetTemplateId === assetTemplateId)
            if (findItem) {
                const formatMarkets = (formData && formData?.length > 0) && formData?.map((item: any) => {
                    if (item?.assetTemplateId === assetTemplateId) {
                        const formatMarketsData = (item?.marketsData && item?.marketsData?.length > 0) ? item?.marketsData?.map((obj: any, idx: number) => {
                            if (idx === index) {
                                return {
                                    ...obj || {},
                                    priorityValue: value
                                }
                            } else {
                                return obj
                            }
                        }) :
                            [
                                {
                                    priorityValue: value,
                                    markets: []
                                }
                            ]
                        return {
                            ...item || {},
                            marketsData: formatMarketsData || []
                        }
                    } else {
                        return item
                    }
                })
                setFormData(formatMarkets || [])
            }
        }
    }

    const onDeleteCategory = (index: number) => {
        setFormData((prevFormData: any) => prevFormData?.length > 0 ? prevFormData?.filter((item: any, idx: number) => idx !== index) : [])
    }

    const onDeleteCategoryMarkets = (index: number, marketsIndex: number) => {
        if ((index || index === 0) && (marketsIndex || marketsIndex === 0)) {
            const formatMarkets = (formData && formData?.length > 0) && formData?.map((item: any, idx: number) => {
                if (idx === index) {
                    const filterMarkets = item?.marketsData && item?.marketsData?.length > 0 && item?.marketsData?.filter((market: any, id: number) => id !== marketsIndex)
                    return {
                        ...item || {},
                        marketsData: filterMarkets || []
                    }
                } else {
                    return item
                }
            })
            setFormData(formatMarkets || [])
        }
    }

    const onAddNewMarket = (index: number) => {
        if ((index || index === 0)) {
            const findIndexCategory = ((formData && formData?.length > 0) ? formData : [{}])?.find((item: any, idx: number) => idx === index)
            if (findIndexCategory) {
                const formatMarkets = (formData && formData?.length > 0) && formData?.map((item: any, idx: number) => {
                    if (idx === index) {
                        return {
                            ...item || {},
                            marketsData: [
                                ...item?.marketsData || [],
                                {}
                            ]
                        }
                    } else {
                        return item
                    }
                })
                setFormData(formatMarkets || [])
            }
        }
    }

    const onAddNewCategory = () => {
        const reformatCategories = [
            ...formData || [],
            {}
        ]
        setFormData(reformatCategories || [])
    }

    const onSubmitData = async () => {
        setSaveError(undefined)
        setLoadingSubmit(true);
        const formatData: IPartnerSettings = {
            data: formData.map((item: any) => ({
                assetTemplateId: item.assetTemplateId,
                assetTemplateName: item.assetTemplateName,
                marketsData: (item.marketsData).map((data: any) => ({
                    markets: data?.markets || '',
                    priorityValue: Number(data?.priorityValue || 0),
                })),
            })),
        };
        try {
            if (brokerId) {
                const data: { formatData: IPartnerSettings; id: string } = {
                    id: brokerId,
                    formatData,
                };
                await dispatch(tryToSetPriorityOfBrokers(data)).unwrap();
            }
            setShowToast({ type: 'success', message: 'Broker Priority changed successfully' });
            setSaveError(undefined);
            handleClosePriorityModal();
        } catch (err) {
            setSaveError(`${err}`);
        }
        setLoadingSubmit(false);
    };

    return (
        <Modal
            open={openPriorityModal}
            onClose={handleClosePriorityModal}
            contentContainerStyle='min-w-[65vw]'>
            <div className={'p-2 min-w-[60vw] min-h-[300px]'}>
                <p className='mt-2 mb-4'>Priority</p>
                {loading ?
                    <>
                        <PartnerPriorityContainer />
                    </>
                    : <>
                        {((formData && formData?.length > 0) ? formData : [{}])?.map((item: any, index: number) => {
                            const findSelectedSectors = (formData && formData?.length > 0) ? formData?.map((data: any) => data?.assetTemplateId) : []
                            const findThisSector = (item?.assetTemplateId && sectors && sectors?.length > 0) && sectors?.find((sector: any) => sector?.value === item?.assetTemplateId)
                            const filteredSectorsToSelect = sectors?.filter((item: any) => !findSelectedSectors?.includes(item?.value));
                            const marketsUsed = item?.marketsData?.flatMap((group: any) => group?.markets?.length > 0 && group?.markets?.map((market: any) => market))
                            if (findThisSector) {
                                filteredSectorsToSelect?.push(findThisSector)
                            }
                            return (
                                <div key={index}>
                                    <div key={index} className='grid grid-cols-3 gap-4'>
                                        <SelectCheckbox
                                            name={'Category'}
                                            dataQa={'category'}
                                            placeholder=' '
                                            selectedOption={item?.assetTemplateId ? sectors?.length > 0 && sectors?.find((category: any) => category?.value === item?.assetTemplateId) : undefined}
                                            options={filteredSectorsToSelect}
                                            onChangeSelectedOption={onChangesSelectedCategory}
                                            uniqueName={'category'}
                                            index={index}
                                        />
                                        {((item?.marketsData && item?.marketsData?.length > 0) ? item?.marketsData : [{}])?.map((market: any, idx: number) => {
                                            const filteredMarketsToSelect = (markets && markets?.length > 0) ? markets?.filter((obj: any) => !(marketsUsed || [])?.includes(obj?.displayName)) : [];
                                            const findAllSelectedMarkets = (market?.markets && market?.markets?.length > 0) && markets?.filter((obj: any) => (market?.markets)?.includes(obj?.displayName))
                                            if (findAllSelectedMarkets) {
                                                filteredMarketsToSelect.push(findAllSelectedMarkets)
                                            }
                                            return (
                                                <>
                                                    {idx > 0 && <div></div>}
                                                    <div key={idx} className=' col-span-2 flex-flex-col mb-2'>
                                                        <div className='flex flex-row items-center'>
                                                            <SelectCheckbox
                                                                name='Markets'
                                                                placeholder=' '
                                                                dataQa={'markets'}
                                                                options={filteredMarketsToSelect || []}
                                                                selectedOption={findAllSelectedMarkets}
                                                                containerStyle='min-w-[45%] w-[45%] max-w-[45%] mr-2'
                                                                onChangeSelectedOption={onChangeSelectedMarkets}
                                                                uniqueName={item.assetTemplateId}
                                                                index={idx}
                                                                multiple={true}
                                                            />
                                                            <Input
                                                                label='Priority'
                                                                placeholder=' '
                                                                dataQa={'priority'}
                                                                containerStyle={'min-w-[30%] w-[30%] max-w-[30%] mx-2'}
                                                                showValue={true}
                                                                inputValue={market?.priorityValue || null}
                                                                inputUniqueName={item?.assetTemplateId}
                                                                onChangeInput={onChangePriority}
                                                                index={idx}
                                                            />
                                                            <div className='cursor-pointer' onClick={() => onDeleteCategoryMarkets(index, idx)}>
                                                                <img data-qa={'delete-category-markets'} src={'/assets/shared/close-grey.svg'} className={'w-[22px] object-contain mt-3 hover:w-[22px] mx-2'} />
                                                            </div>
                                                            {idx === 0 &&
                                                                <div className='cursor-pointer' onClick={() => onDeleteCategory(index)}>
                                                                    <img data-qa={'delete-category'} src={'/assets/shared/trash-gray.svg'} className={'w-[20px] object-contain mt-3 hover:w-[22px] mx-2'} />
                                                                </div>
                                                            }
                                                        </div>
                                                        {((item?.marketsData?.length || 1) - 1 === idx) && (filteredMarketsToSelect && filteredMarketsToSelect?.length > 1) &&
                                                            <div className='flex flex-row justify-end mr-[20%]'>
                                                                <Button
                                                                    label={'Add Market'}
                                                                    dataQa={'add-market'}
                                                                    onClickButton={() => onAddNewMarket(index)}
                                                                    className={'!shadow-none text-[13px] btn-primary-text-underline'}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        {((sectors?.length || 0) > (formData?.length || 0)) &&
                            <div className='flex flex-row justify-start mt-2'>
                                <Button
                                    label={'Add Category'}
                                    dataQa={'add-category'}
                                    onClickButton={onAddNewCategory}
                                    className={'btn-main !shadow'}
                                />
                            </div>
                        }
                        {saveError && <div className='flex flex-row justify-center my-2'>
                            <Error text={saveError} />
                        </div>
                        }
                        <div className='flex flex-row justify-end mt-10 mb-4'>
                            <Button
                                className={loadingSubmit ? 'btn-main-disable' : 'btn-main'}
                                label='Save Changes'
                                dataQa={'save-changes'}
                                onClickButton={() => !loadingSubmit && onSubmitData()}
                            />
                        </div>
                    </>
                }
            </div>
        </Modal>
    )
}
export default PriorityModal;