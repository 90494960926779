import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';


interface IConfirmHighestPriceModal {
    openModal: boolean;
    loadingAllocate: boolean;
    handleCloseModal: () => void;
    onApproveAction: () => void;
}

const ConfirmHighestPriceModal = ({
    openModal,
    loadingAllocate,
    handleCloseModal,
    onApproveAction,
}: IConfirmHighestPriceModal) => {
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            dataQa={'close-modal'}
        >
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Allocate Items To Highest Price</p>
                </div>
                <p className='my-2'>Are you sure you want to allocate all items to Brokers with highest prices?</p>
                <div className='flex flex-row justify-end my-2'>
                    <Button
                        label='Continue'
                        dataQa={'continue-button'}
                        className={loadingAllocate ? 'btn-primary-disable' : 'btn-primary'}
                        onClickButton={() => !loadingAllocate && onApproveAction()}
                    />
                    <Button
                        label='Cancel'
                        dataQa={'cancel-button'}
                        className={loadingAllocate ? 'btn-main-disable' : 'btn-error ml-2'}
                        onClickButton={() => !loadingAllocate && handleCloseModal()}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default ConfirmHighestPriceModal;