import React from 'react';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import AdminPricingFilters from '../details/AdminPricingFilters';


interface IFilterStep {
    markets?: Array<any>;
    partners?: Array<any>;
    sectors?: Array<any>;
    selectedMarket?: any;
    selectedPartner?: any;
    selectedSector?: any;
    error?: string;
    seeDetails?: boolean;
    onChangeSelectedFilter: (e: any, name?: string) => void;
    onClickSeeDetails: () => void;
}

const FilterStep = ({
    markets,
    partners,
    sectors,
    selectedMarket,
    selectedPartner,
    selectedSector,
    error,
    seeDetails,
    onChangeSelectedFilter,
    onClickSeeDetails,
}: IFilterStep) => {

    return (
        <div className='pr-5'>
            <p className='text-[#a4a4a4] font-medium text-sm mt-2 mb-5'>Please select Market, Partner and Category to see details of pricing.</p>
            {error && <Error text={error} />}
            <div className='mt-7 mb-3'>
                <AdminPricingFilters
                    markets={markets}
                    partners={partners}
                    sectors={sectors}
                    selectedMarket={selectedMarket}
                    selectedPartner={selectedPartner}
                    selectedSector={selectedSector}
                    onChangeSelectedFilter={onChangeSelectedFilter}
                />
            </div>
            {!seeDetails &&
                <div className='my-4 flex flex-row justify-end'>
                    <Button
                        label={'See Details'}
                        dataQa={'see-details'}
                        className={'btn-main min-w-[150px]'}
                        onClickButton={onClickSeeDetails}
                    />
                </div>
            }
        </div>
    )
}
export default FilterStep;