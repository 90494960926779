import React from 'react';
import Button from '../../../../shared/button';


interface IPricingReviewsStatuses {
    selectedStatus?: string;
    pricingStatuses: Array<{ name: string, label: string }>;
    onChangeStatus: (status: string) => void;
}

const PricingReviewsStatuses = ({
    selectedStatus,
    pricingStatuses,
    onChangeStatus
}: IPricingReviewsStatuses) => {
    return (
        <div className='flex flex-row justify-start items-center min-w-full my-4'>
            <img src={'/assets/shared/filter.svg'} className={'w-[13px] object-contain'} />
            <p className='text-[#8F8F8F] text-sm font-bold ml-2 mr-4'>Filters:</p>
            {pricingStatuses && pricingStatuses?.length > 0 && pricingStatuses?.map((status) => {
                return (
                    <Button
                        key={status?.label}
                        label={status?.name}
                        dataQa={`${(status?.name || '')?.toLowerCase()}`}
                        className={`${selectedStatus === status?.label ? 'btn-filters' : 'btn-noactive-filters'} mr-2 !font-bold`}
                        onClickButton={() => onChangeStatus(status?.label || '')}
                    />
                )
            })}
        </div>
    )
}

export default PricingReviewsStatuses;