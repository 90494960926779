import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import RentalPricingCatalogFilters from './RentalPricingCatalogFilters';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IRentalPricingFilters {
    sectors?: Array<IAssetTemplate>;
    markets?: Array<any>;
    selectedSector?: any;
    selectedMarket?: any;
    items: Array<any>;
    filterValues: any;
    showCatalogFilters: boolean;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onChangeInput: (value: string | null | undefined, type: string) => void;
    onClearAllFilters: () => void;
    onSelectFilter: (id: string, type?: string) => void;
}

const RentalPricingFilters = ({
    sectors,
    markets,
    selectedSector,
    selectedMarket,
    items,
    filterValues,
    showCatalogFilters,
    onChangeInput,
    onChangeSelectedValue,
    onClearAllFilters,
    onSelectFilter
}: IRentalPricingFilters) => {
    return (
        <div>
            <div className='mb-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
                <div className='my-2'>
                    <SelectCheckbox
                        name={'Market'}
                        placeholder={' '}
                        dataQa={'market'}
                        options={markets || []}
                        uniqueName={'market'}
                        selectedOption={selectedMarket}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-2'>
                    <SelectCheckbox
                        name={'Category'}
                        placeholder={' '}
                        dataQa={'category'}
                        selectedOption={selectedSector}
                        options={sectors || []}
                        uniqueName={'category'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
            </div>
            {showCatalogFilters &&
                <RentalPricingCatalogFilters
                    items={items || []}
                    filterValues={filterValues || null}
                    onChangeInput={onChangeInput}
                    onChangeSelectedValue={onChangeSelectedValue}
                    onClearAllFilters={onClearAllFilters}
                />
            }
        </div>
    )
}

export default RentalPricingFilters;