import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToChangeImei } from '../../../../store/brokers/partners/orders/ordersSlice';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';


interface IImeiModal {
    openImeiModal: boolean;
    itemId: string;
    handleCloseModal: () => void;
    onSuccessfullyAddedImei: (orderId: string, imei: string) => void;
}

type FormValues = {
    imei: string;
};

const ImeiModal = ({
    openImeiModal,
    itemId,
    handleCloseModal,
    onSuccessfullyAddedImei,
}: IImeiModal) => {
    const dispatch = useAppDispatch();
    const [addImeiError, setImeiError] = useState<string>()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormValues>({});

    const onCloseModal = () => {
        handleCloseModal();
        setImeiError(undefined)
        reset();
    }

    const onSubmit = async (data: FormValues) => {
        try {
            await dispatch(tryToChangeImei({ itemId, imei: data?.imei })).unwrap()
            onSuccessfullyAddedImei(itemId, data?.imei)
            onCloseModal()
        } catch (err) {
            setImeiError(`${err}`)
        }
    }

    return (
        <Modal
            open={openImeiModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mb-4 font-semibold text-sm'>Imei Form</p>
                <p className='font-bold text-md mb-5'>Use this form to add IMEI.</p>
                {addImeiError && <Error text={addImeiError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        placeholder='IMEI'
                        register={register('imei', {
                            required: {
                                message: 'IMEI is required',
                                value: true,
                            },
                            validate: (value: string) => !!value.trim() || 'IMEI is required'
                        })}
                        error={errors.imei?.message}
                        dataQa={'imei'}
                    />
                    <div className='min-w-full flex flex-row justify-center'>
                        <Button
                            label={'Add IMEI'}
                            dataQa={'add-imei-btn'}
                            className={'btn-main my-3 min-w-[130px] !shadow-none'}
                            type='submit'
                        />
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ImeiModal;