import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchSubBrandsByMarket } from '../../../store/brokers/admin/sub-brands/subBrandsSlice';
import { usePricingTemplateMutation } from '../../../store/brokers/partners/download-templates/downloadTemplatesApi';
import { tryToFetchAllBrokerComparisonSite } from '../../../store/brokers/admin/brokers-comparison-sites/brokersComparisonSitesSlice';
import { tryToFetchFilteredComparisonPricings } from '../../../store/brokers/admin/comparison-sites-pricing/comparisonPricingSlice';
import {
    tryToFetchAllAssetsTemplates,
    tryToFetchAssetTemplateFilters
} from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import ComparisonPricingAssetFilters from './details/ComparisonPricingAssetFilters';
import ComparisonSitePricingTable from './tables/ComparisonSitePricingTable';
import ComparisonPricingFilters from './details/ComparisonPricingFilters';
import ImportPricingCsvModal from './modals/ImportPricingCsvModal';
import DownloadPricingModal from './modals/DownloadPricingModal';
import Button from '../../../shared/button';


const ComparisonSitePricing = () => {
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any>([]);
    const [sort, setSort] = useState<string>();
    const [templateData, setTemplateData] = useState<any>();
    const [showDownloadPricingModal, setShowDownloadPricingModal] = useState<boolean>(false);
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [showImportCsvModal, setShowImportCsvModal] = useState<boolean>(false);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [markets, setMarkets] = useState<IAllMarkets[]>();
    const [partners, setPartners] = useState<IPartners[]>();
    const [subbrands, setSubbrands] = useState<any>();
    const [sectors, setSectors] = useState<IAssetTemplate[]>();
    const [selectedSubbrands, setSelectedSubbrands] = useState<any>();
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const [selectedPartner, setSelectedPartner] = useState<any>();
    const [selectedSector, setSelectedSector] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>();
    const [templateFilters, setTemplateFilters] = useState<Array<any>>();
    const [pageAccess, setPageAccess] = useState<any>();
    const state = useAppSelector((state) => state?.comparisonPricing)
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state.comparisonPricing.comparisonPricings?.data?.page);
    const pricingLoading = useAppSelector((state) => state.comparisonPricing?.pricingsAreLoading)
    const stateFilters = useAppSelector((state) => state.assetsTemplates);
    const [getAllMarkets] = useGetAllMarketsMutation()
    const [partnerPricingTemplateMutation] = usePricingTemplateMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Comparison Site Pricing')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

    const onGettingSubbrandsData = async () => {
        try {
            const response = await dispatch(tryToFetchSubBrandsByMarket(selectedMarket?.value || '')).unwrap()
            const formatResponse = response?.data && response?.data?.length > 0 && response?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.displayName }))
            setSubbrands(formatResponse || [])
            setSelectedSubbrands(undefined)
        } catch (err) {
            setSelectedSubbrands(undefined)
            setSubbrands(undefined)
        }
    }

    useEffect(() => {
        if (selectedMarket) {
            onGettingSubbrandsData()
        } else {
            setSelectedSubbrands(undefined)
        }
    }, [selectedMarket])

    useEffect(() => {
        if (stateFilters.assetTemplateFilters) {
            setTemplateFilters(stateFilters.assetTemplateFilters)
        }
    }, [stateFilters?.assetTemplateFilters]);

    useEffect(() => {
        if (state.comparisonPricings) {
            const rows =
                state.comparisonPricings?.data?.elements && Array.isArray(state.comparisonPricings?.data.elements)
                    ? state.comparisonPricings?.data.elements.map((r) => ({ ...r }))
                    : [];
            setRows(rows);
        }
    }, [state.comparisonPricings]);

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.displayName, label: r?.displayName, value: r?._id, }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
            const findPhones = (rows && rows?.length > 0) && rows?.find((r) => (r?.name || '').toLowerCase() === 'phones')
            setSelectedSector(findPhones || rows?.[0] || undefined)
        }
    }, [stateAssets.allAssetTemplates]);

    const getBrokersData = async () => {
        try {
            const brokerResponse: any = await dispatch(tryToFetchAllBrokerComparisonSite()).unwrap()
            const formatBrokers = brokerResponse && brokerResponse?.length > 0 && brokerResponse?.map((broker: any) => ({ ...broker, label: broker?.displayName, value: broker?._id }))
            setPartners(formatBrokers || [])
        } catch (err) {
            // error here
        }
    }

    const getMarketData = async () => {
        try {
            const marketResponse = await getAllMarkets(null).unwrap()
            const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id, displayName: market?.name }))
            setMarkets(formatMarkets || [])
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        getMarketData()
        getBrokersData()
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    const onGetItemsData = async () => {
        try {
            const filters = filterValues && Object.keys(filterValues)
            let formatFilters
            const filtersValuesFormat = filters && filters?.length > 0 && filters?.map((item: any) => {
                return {
                    key: item,
                    value: Object.keys(filterValues) ? filterValues?.[item] || undefined : undefined
                }
            })
            if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
                formatFilters = {
                    value: filtersValuesFormat?.filter((key: any) => key?.value)
                }
            }
            let data: any = {
                filters: {
                    assetTemplateId: selectedSector?.value || '',
                }
            }
            if (selectedPartner) {
                data = {
                    ...data || {},
                    filters: {
                        ...data?.filters || {},
                        brokerId: selectedPartner?.value
                    }
                }
            }
            if (selectedSubbrands) {
                data = {
                    ...data || {},
                    filters: {
                        ...data?.filters || {},
                        subBrandId: selectedSubbrands?.value
                    }
                }
            }
            if (selectedMarket) {
                data = {
                    ...data || {},
                    filters: {
                        ...data?.filters || {},
                        country: selectedMarket?.displayName
                    }
                }
            }
            if (sort) {
                data = {
                    ...data,
                    sort,
                }
            }
            if (formatFilters && formatFilters?.value) {
                data = {
                    ...data,
                    filters: {
                        ...data?.filters || {},
                        ...formatFilters || {}
                    }
                }
            }
            const fetchData = {
                ...paginationState || {},
                data
            }
            await dispatch(tryToFetchFilteredComparisonPricings(fetchData))
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (selectedSector) {
            onGetItemsData()
        }
    }, [selectedMarket, selectedSubbrands, selectedSector, paginationState])

    const onDownloadTemplate = async () => {
        if (loadingDownload) {
            return;
        }
        setLoadingDownload(true)
        try {
            const response = await partnerPricingTemplateMutation({ assetTemplateId: selectedSector?.value || '' }).unwrap()
            setTemplateData(response || [])
        } catch (err) {
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (templateData && loadingDownload) {
            csvLink.current.link.click()
            setLoadingDownload(false)
        }
    }, [templateData])

    const onChangeSelectedFilter = (e: any, type?: string) => {
        switch (type) {
            case 'market':
                setSelectedMarket(e);
                break;
            case 'sector':
                setSelectedSector(e)
                break;
            case 'partner':
                setSelectedPartner(e)
                break;
            case 'subbrands':
                setSelectedSubbrands(e)
                break;
        }
    }

    const onToggleImportCsvModal = () => {
        setShowImportCsvModal(!showImportCsvModal)
    }

    const onChangeFilterInput = (value: string | null | undefined, type: string) => {
        const findField = templateFilters?.find((field) => field?.name === type);
        setFilterValues({
            ...(filterValues || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
        onResetPaginationSettings()
    };

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        if (type) {
            const findField = templateFilters?.find((field) => field?.name === type);
            setFilterValues({
                ...(filterValues || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
            onResetPaginationSettings()
        }
    };

    const onClearAllFilters = () => {
        setFilterValues(undefined)
        setSelectedMarket(undefined)
        setSelectedSubbrands(undefined)
        onResetPaginationSettings()
    }

    const onClickSortPricing = (name?: string) => {
        setSort((!sort || sort === 'desc') ? 'asc' : 'desc')
        onResetPaginationSettings()
    }

    useEffect(() => {
        if (selectedSector) {
            dispatch(tryToFetchAssetTemplateFilters({ id: selectedSector?.value || '' }))
        }
        setFilterValues(undefined)
    }, [selectedSector])

    return (
        <div>
            <div className='mb-5'>
                <p className='page-title'>Comparison Site Pricing</p>
            </div>
            <div className='my-10'>
                <ComparisonPricingFilters
                    sectors={sectors || []}
                    markets={markets || []}
                    subbrands={subbrands || []}
                    selectedSubbrands={selectedSubbrands}
                    selectedMarket={selectedMarket}
                    selectedSector={selectedSector}
                    onChangeSelectedFilter={onChangeSelectedFilter}
                />
                {selectedSector && templateFilters && templateFilters?.length > 0 &&
                    <ComparisonPricingAssetFilters
                        items={templateFilters || []}
                        filterValues={filterValues}
                        onChangeInput={onChangeFilterInput}
                        onChangeSelectedValue={onChangeSelectedFilterValue}
                        onClearAllFilters={onClearAllFilters}
                    />
                }
            </div>
            <div className={'min-w-full py-1 px-2 flex flex-row justify-end items-center flex-wrap bg-[#fefefe]'}>
                <Button
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Download Sample']) && onDownloadTemplate()}
                    dataQa={loadingDownload ? 'loading-data...' : 'download-sample'}
                    label={loadingDownload ? 'Loading data...' : 'Download Sample'}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Sample']) ? 'btn-main' : 'btn-main-disable'}  flex flex-row align-start my-2 !py-2 mb-1`}
                />
                <CSVLink
                    ref={csvLink}
                    data={(templateData && templateData?.length > 0) && templateData?.filter((item: any, idx: number) => idx > 0) || []}
                    headers={templateData?.[0] ? [...templateData?.[0], 'price'] : []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={'Comparison-site-pricing-sample.csv'}
                >
                </CSVLink>
                <Button
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Comparison Site Pricing']) && onToggleImportCsvModal()}
                    label={'Import Comparison Site Pricing'}
                    dataQa={'import-comparison-site-pricing'}
                    className={`${(!accessControl || pageAccess?.actions?.['Import Comparison Site Pricing']) ? 'btn-main' : 'btn-main-disable'} btn-main flex flex-row align-start my-2 ml-2 !py-2 mb-1`}
                />
                <Button
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Download Pricing']) && setShowDownloadPricingModal(true)}
                    label={'Download Pricing'}
                    dataQa={'download-pricing'}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Pricing']) ? 'btn-main' : 'btn-main-disable'} flex flex-row align-start my-2 ml-2 !py-2 mb-1`}
                />
            </div>
            <div>
                <ComparisonSitePricingTable
                    rows={rows}
                    state={state}
                    markets={markets}
                    selectedMarket={selectedMarket || ''}
                    paginationData={paginationData}
                    pricingLoading={pricingLoading}
                    showHighestPrice={sort === 'desc'}
                    onClickSortPricing={onClickSortPricing}
                    setPaginationState={setPaginationState}
                />
            </div>
            <ImportPricingCsvModal
                openImportModal={showImportCsvModal}
                handleCloseModal={onToggleImportCsvModal}
                sectors={sectors || []}
                brokers={partners || []}
            />
            <DownloadPricingModal
                openDownloadModal={showDownloadPricingModal}
                markets={markets || []}
                brokers={partners || []}
                sectors={sectors || []}
                handleCloseDownloadModal={() => setShowDownloadPricingModal(false)}
            />
        </div>
    )
}
export default ComparisonSitePricing