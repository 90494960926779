import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IScrapingFeeFilters {
    sectors?: Array<IAssetTemplate>;
    selectedSector?: any;
    onSelectSector: (e: any) => void;
}

const ScrapingFeeFilters = ({
    sectors,
    selectedSector,
    onSelectSector
}: IScrapingFeeFilters) => {
    return (
        <div>
            <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-2 lg:gap-4'>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Category'}
                        placeholder=' '
                        dataQa={'category-to-select'}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        selectedOption={selectedSector}
                        options={sectors || []}
                        onChangeSelectedOption={onSelectSector}
                    />
                </div>
            </div>
        </div>
    )
}

export default ScrapingFeeFilters;