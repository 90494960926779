import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToFetchSubBrandsByMarket } from '../../../../store/brokers/admin/sub-brands/subBrandsSlice';
import { tryToDownloadComparisonPricing } from '../../../../store/brokers/admin/comparison-sites-pricing/comparisonPricingSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IDownloadPricingModal {
    openDownloadModal: boolean;
    sectors: Array<any>;
    markets: Array<any>;
    brokers: Array<any>;
    handleCloseDownloadModal: () => void;
}

const DownloadPricingModal = ({
    openDownloadModal,
    sectors,
    markets,
    brokers,
    handleCloseDownloadModal,
}: IDownloadPricingModal) => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [selectedSector, setSelectedSector] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [downloadError, setDownloadError] = useState<string>()
    const [subbrands, setSubbrands] = useState<any>();
    const [selectedSubbrands, setSelectedSubbrands] = useState<any>();
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [pricingTemplateData, setPricingTemplateData] = useState<Array<any>>()

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(e)
    }

    const onChangeSelectedMarket = (e: any) => {
        setSelectedMarket(e)
    }

    const onChangeSelectedSubBrand = (e: any) => {
        setSelectedSubbrands(e)
    }

    const onGettingSubbrandsData = async () => {
        try {
            const response = await dispatch(tryToFetchSubBrandsByMarket(selectedMarket?.value || '')).unwrap()
            const formatResponse = response?.data && response?.data?.length > 0 && response?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.displayName }))
            setSubbrands(formatResponse || [])
            setSelectedSubbrands(undefined)
        } catch (err) {
            setSelectedSubbrands(undefined)
            setSubbrands(undefined)
        }
    }

    useEffect(() => {
        if (selectedMarket) {
            onGettingSubbrandsData()
        } else {
            setSelectedMarket(undefined)
            setSubbrands(undefined)
        }
    }, [selectedMarket])

    const onCloseModal = () => {
        handleCloseDownloadModal();
        setSelectedSector(undefined)
        setSelectedMarket(undefined)
        setSelectedSubbrands(undefined)
        setSubbrands(undefined)
        setDownloadError(undefined)
        setLoadingDownload(false)
    }

    const onDownloadTemplate = async () => {
        setLoadingDownload(true)
        if (!selectedSector || !selectedSubbrands || !selectedMarket) {
            setDownloadError('All fields must be completed to continue with the download.')
            setLoadingDownload(false)
            return;
        }
        try {
            const formatData = {
                assetTemplateId: selectedSector?.value || '',
                country: selectedMarket?.displayName || '',
                subBrandId: selectedSubbrands?.value || ''
            }
            const response: any = await dispatch(tryToDownloadComparisonPricing(formatData)).unwrap()
            setPricingTemplateData(response?.data?.data || [])
            setDownloadError(undefined)
        } catch (err: any) {
            setDownloadError(`${err || err?.response || err?.data?.message}`)
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (pricingTemplateData && loadingDownload) {
            csvLink.current.link.click()
            onCloseModal()
        }
        setLoadingDownload(false)
    }, [pricingTemplateData])

    return (
        <Modal
            open={openDownloadModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[350px] min-h-[300px]'}>
                <p className='mb-2 font-semibold text-sm'>Download Comparison Site Pricing</p>
                <p className='mb-4 text-sm'>Complete all Fields to download Data as a CSV File</p>
                {downloadError && <Error text={downloadError} />}
                <div className='flex flex-col items-center'>
                    <SelectCheckbox
                        name='Market'
                        dataQa={'market-selected'}
                        placeholder=' '
                        containerStyle='min-w-full mb-5'
                        selectedOption={selectedMarket}
                        onChangeSelectedOption={onChangeSelectedMarket}
                        options={markets || []}
                    />
                    <SelectCheckbox
                        name='Sub Brand'
                        dataQa={'subbrand-selected'}
                        placeholder=' '
                        containerStyle='min-w-full mb-5'
                        selectedOption={selectedSubbrands}
                        onChangeSelectedOption={onChangeSelectedSubBrand}
                        options={subbrands || []}
                    />
                    <SelectCheckbox
                        name='Category'
                        dataQa={'category-selected'}
                        placeholder=' '
                        containerStyle='min-w-full mb-5'
                        selectedOption={selectedSector}
                        onChangeSelectedOption={onChangeSelectedSector}
                        options={sectors || []}
                    />
                    <Button
                        label={loadingDownload ? 'Loading data...' : 'Download Pricing Data'}
                        onClickButton={() => !loadingDownload && onDownloadTemplate()}
                        disabled={loadingDownload}
                        dataQa={loadingDownload ? 'loading-data...' : 'download-pricing-data'}
                        className={`${loadingDownload ? 'btn-main-disable' : 'btn-main'} mb-2 mt-9 !py-2 !shadow-none`}
                    />
                    <CSVLink
                        ref={csvLink}
                        data={pricingTemplateData && pricingTemplateData?.length > 0 ? pricingTemplateData?.filter((item, idx) => idx > 0) : []}
                        headers={pricingTemplateData && pricingTemplateData?.length > 0 ? pricingTemplateData?.[0] : []}
                        className={'none'}
                        target={'_blank'}
                        filename={`Comparison-Site-Pricing-${selectedSector?.name || 'catalog'}-data.csv`}
                    >
                    </CSVLink>
                </div>
            </div>
        </Modal >
    )
}
export default DownloadPricingModal;