import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IEditPricingReview } from '../../../../interfaces/pricing-review/IEditPricingReview';
import { IPricingReview } from '../../../../interfaces/pricing-review/IPricingReview';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';


interface IPricingActions {
    openActionsModal: boolean;
    loadingPriceSettings: boolean;
    existingData?: IPricingReview;
    loadingUpdate?: boolean;
    handleCloseActionsModal: () => void;
    onAddOrEditPriceSetting: (data: IEditPricingReview) => void;
}

type FormValues = {
    amount: number;
    numberOfItems: number;
};

const PricingActionsModal = ({
    openActionsModal,
    loadingPriceSettings,
    existingData,
    loadingUpdate,
    handleCloseActionsModal,
    onAddOrEditPriceSetting
}: IPricingActions) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        if (existingData) {
            setValue('amount', existingData?.amount)
            setValue('numberOfItems', existingData?.numberOfItems)
        }
    }, [existingData])

    const onSubmit = async (data: FormValues) => {
        onAddOrEditPriceSetting(data)
    }

    const onCloseActionsModal = () => {
        handleCloseActionsModal();
        reset();
    }

    return (
        <Modal
            open={openActionsModal}
            onClose={onCloseActionsModal}
            dataQa={'close-button'}>
            <div className={'p-2 min-w-[200px]'}>
                <p className='mb-4 font-semibold text-sm'>Actions</p>
                {loadingPriceSettings ?
                    <div className='min-h-[150px] flex flex-row justify-center items-center'>
                        <Loading />
                    </div> :
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <p className='font-bold text-md mb-2 mt-3'>Price per unit</p>
                            <Input
                                placeholder='Amount'
                                dataQa={'price-per-unit'}
                                type='text'
                                register={register('amount', {
                                    required: {
                                        message: 'Price per unit is required',
                                        value: true,
                                    },
                                    pattern: {
                                        value: /^-?[0-9]\d*\.?\d*$/,
                                        message: 'Only numbers are accepted.'
                                    },
                                    validate: (value: number) => (Number(value) >= 0) || 'Price per unit should be a positive number'
                                })}
                                error={errors.amount?.message}
                            />
                            <p className='font-bold text-md mb-2 mt-3'>Number of items</p>
                            <Input
                                placeholder='Amount'
                                dataQa={'number-of-items'}
                                type='number'
                                register={register('numberOfItems', {
                                    required: {
                                        message: 'Number of Items is required',
                                        value: true,
                                    },
                                    validate: (value: number) => value >= 0 || 'Number of Items should be a positive number'
                                })}
                                error={errors.numberOfItems?.message}
                            />
                            <Button
                                label={'Save changes'}
                                dataQa={'submit-button'}
                                className={`${loadingUpdate ? 'btn-main-disable' : 'btn-main'} my-3 min-w-full !shadow-none`}
                                type='submit'
                            />
                        </form>
                    </div>
                }
            </div>
        </Modal>
    )
}
export default PricingActionsModal;