import React from 'react';
import Button from '../../../../shared/button';


interface IUserType {
    items: Array<any>;
    activeButton?: string;
    onClickType: (value: string, name: string) => void;
}

const UserTypes = ({
    items,
    activeButton,
    onClickType
}: IUserType) => {
    return (
        <div>
            <div className='my-2 flex flex-row items-center'>
                {items && items?.length > 0 && items?.map((item, index) => {
                    let buttonClassName = 'btn-categories'
                    if (activeButton === item?.value) buttonClassName = 'btn-categories';
                    else buttonClassName = 'btn-noactive-categories border';
                    return (
                        <Button
                            key={index}
                            label={item?.name}
                            className={`${buttonClassName} !min-w-[70px] mr-5`}
                            onClickButton={() => onClickType(item?.value, item?.name)}
                            dataQa={item?.id}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default UserTypes;