import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';

interface ISuccessResetModal {
    openModal: boolean;
    handleCloseModal: () => void;
}

const SuccessResetModal = ({
    openModal,
    handleCloseModal
}: ISuccessResetModal) => {
    const navigate = useNavigate()

    const onGoToLogin = () => {
        navigate('/login')
    }

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}>
            <div className={'p-2 min-w-[400px]'}>
                <div className='flex flex-col items-center py-5 min-w-full'>
                    <img src={'/assets/shared/success-mark.svg'} className={'w-[100px] object-contain mb-4'} />
                    <p className='my-3 text-primary-light font-medium text-md'>Password reset successfully</p>
                    <div className='flex flex-row min-w-[100%] justify-center my-2'>
                        <Button
                            label={'Login'}
                            dataQa={'login'}
                            className={'btn-main !shadow-none min-w-[100px]'}
                            onClickButton={onGoToLogin}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default SuccessResetModal;