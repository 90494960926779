import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToFetchAllPartners,
    tryToFetchPartnerCatalogs
} from '../../../store/brokers/admin/partners/partnersSlice';
import {
    tryToDeleteFieldSettings,
    tryToFetchAllFieldSettings
} from '../../../store/brokers/admin/broker-fields-settings/fieldSettingsSlice';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import ManageFieldsSettingsModal from './modals/ManageFieldsSettings';
import FieldSettingsFilters from './details/FieldSettingsFilters';
import FieldSettingsTable from './tables/FieldSettingsTable';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Input from '../../../shared/input';
import Toast from '../../../shared/toast';


const BrokerFieldsSettings = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any[]>([]);
    const [search, setSearch] = useState<string>();
    const [fields, setFields] = useState<any>();
    const [partners, setPartners] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [brokerCategories, setBrokerCategories] = useState<any>();
    const [selectedPartner, setSelectedPartner] = useState<any>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [selectedField, setSelectedField] = useState<any>();
    const [itemId, setItemId] = useState<any>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showFieldSettingsModal, setShowFieldSettingsModal] = useState<any>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const state = useAppSelector((state) => state.fieldSettings);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const rowsLoading = useAppSelector((state) => state.fieldSettings.fieldSettingsAreLoading);
    const paginationData = useAppSelector((state) => state.fieldSettings.fieldSettings?.data?.page);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Asset Catalogs')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.fieldSettings) {
            const rows =
                state.fieldSettings?.data?.elements &&
                    Array.isArray(state.fieldSettings?.data.elements)
                    ? state.fieldSettings?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.fieldSettings]);

    const getFieldSettingsData = async () => {
        const formatData: any = {
            ...paginationState,
        }
        if (selectedPartner) {
            formatData.data = {
                ...formatData.data,
                brokerId: selectedPartner ? selectedPartner?._id : ''
            }
        }
        if (selectedField) {
            formatData.data = {
                ...formatData.data,
                field: selectedField ? selectedField?.value : ''
            }
        }
        if (selectedCategory) {
            formatData.data = {
                ...formatData.data,
                assetTemplateId: selectedCategory ? selectedCategory?.value : ''
            }
        }
        await dispatch(tryToFetchAllFieldSettings(formatData));
    }

    const getPartnersData = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.companyName }))
        setPartners(formatPartners || [])
    }

    useEffect(() => {
        getPartnersData();
        getFieldSettingsData();
    }, [])

    useEffect(() => {
        getFieldSettingsData();
    }, [paginationState])

    const onGetPartnerSectors = async () => {
        const response: any = await dispatch(tryToFetchPartnerCatalogs(selectedPartner?.username || '')).unwrap()
        if (response?.data) {
            const formatSectors = response?.data?.length > 0 && response?.data?.map((category: any) => ({ ...category, label: category?.displayName, name: category?.displayName, value: category?._id }))
            setBrokerCategories(formatSectors || [])
        }
    }

    const onGettingAllAssetFields = async () => {
        if (selectedCategory) {
            try {
                const assetSelected = selectedCategory?.value
                const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(assetSelected)).unwrap()
                if (response?.inventoryFields) {
                    const findListFields = response?.inventoryFields?.length > 0 && response?.inventoryFields?.filter((item: any) => item?.dataType === 'list')
                    const formatFields = findListFields && findListFields?.length > 0 && findListFields?.map((item: any) => ({ ...item, value: item?.name, label: item?.name }))
                    setFields(formatFields)
                }
            } catch (err) {
                // error here
            }
        }
    }

    useEffect(() => {
        if (selectedPartner) {
            onGetPartnerSectors()
        }
    }, [selectedPartner])

    useEffect(() => {
        if (selectedCategory) {
            onGettingAllAssetFields()
        }
    }, [selectedCategory])

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const onSelectFilter = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'partner':
                    setSelectedPartner(value)
                    break;
                case 'field':
                    setSelectedField(value)
                    break;
                case 'sector':
                    setSelectedCategory(value)
                    break;
            }
        }
        onResetPaginationState()
    }

    const onClickClearAllFilter = () => {
        setSelectedCategory(undefined)
        setSelectedPartner(undefined)
        setSelectedField(undefined)
        setSearch(undefined)
        onResetPaginationState()
    }

    const onDeleteFieldSettingsById = async () => {
        try {
            await dispatch(tryToDeleteFieldSettings(itemId || ''));
            setShowToast({
                type: 'success',
                message: 'Broker Fields Settings successfully deleted',
            });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setItemId(undefined);
        setShowDeleteModal(false);
    };

    const onDeleteFieldSettings = (id: string) => {
        setShowDeleteModal(true);
        setItemId(id);
    };

    const onSaveFieldChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        getFieldSettingsData()
    };

    const onAddFieldSettings = (id?: string) => {
        if (id) {
            setItemId(id);
        }
        setShowFieldSettingsModal(true);
        document.body.style.overflow = 'hidden';
    };

    const onCloseFieldSettingsModal = () => {
        setItemId(undefined)
        setShowFieldSettingsModal(false)
        document.body.style.overflow = 'auto';
    }

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setItemId(undefined)
    };

    return (
        <div>
            <div>
                <p className='page-title'>Broker Fields Settings</p>
            </div>
            <div>
                <div>
                    <FieldSettingsFilters
                        partners={partners}
                        sectors={brokerCategories}
                        fields={fields}
                        selectedPartner={selectedPartner}
                        selectedField={selectedField}
                        selectedSector={selectedCategory}
                        onSelectFilter={onSelectFilter}
                    />
                </div>
                <div className={`flex flex-row justify-between items-center bg-[#f8f0e6] mt-3 mb-2 p-1 !rounded`}>
                    <div>
                        <Input
                            placeholder={'Filter Table'}
                            dataQa={'search'}
                            containerStyle='mb-0 pb-0 relative min-w-[400px]'
                            inputStyle={`mb-0 border-0 focus:outline-none`}
                            icon={'/assets/shared/search.svg'}
                            showValue={true}
                            inputValue={search}
                            inputUniqueName={'search'}
                            onChangeInput={onChangeSearchValue}
                            hideInputSpace={true}
                        />
                    </div>
                    <div className='flex flex-row justify-end'>
                        <div className='flex flex-row justify-end ml-5'>
                            <Button
                                label='Clear Filters'
                                className={'btn-primary-text-underline min-w-auto'}
                                onClickButton={() => onClickClearAllFilter()}
                            />
                        </div>
                        <div className='col-span-2 flex flex-row justify-end'>
                            <Button
                                label={'Add New Settings'}
                                className={`${(!accessControl || pageAccess?.actions?.['Add New Settings']) ? 'btn-primary' : 'btn-primary-disable'} !shadow-none`}
                                onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Settings']) && onAddFieldSettings()}
                            />
                        </div>
                    </div>
                </div>
                <FieldSettingsTable
                    rows={rows}
                    rowsLoading={rowsLoading}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                    onEditFieldSettings={onAddFieldSettings}
                    onDeleteFieldSettings={onDeleteFieldSettings}
                />
            </div>
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeleteFieldSettingsById}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showFieldSettingsModal &&
                <ManageFieldsSettingsModal
                    openModal={showFieldSettingsModal}
                    partners={partners}
                    itemId={itemId}
                    onCloseModal={onCloseFieldSettingsModal}
                    onSaveFieldChanges={onSaveFieldChanges}
                />
            }
        </div>
    )
}

export default BrokerFieldsSettings;