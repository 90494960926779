export const onFormatOrderStatus = (status: string, selectedFilter?: any, isRecentChanges?: any) => {
    switch (status) {
        case 'device_inbound':
            return { name: 'Inbound', color: 'bg-[#ebedef]', text: 'text-[#63F6FF]' }
        case 'not_shipped':
            return { name: 'Not Shipped', color: 'bg-[#ebedef]', text: 'text-[#FC5555]' }
        case 'shipped':
            return { name: 'Shipped', color: 'bg-[#ebedef]', text: 'text-[#7B61FF]' }
        case 'device_in_review':
            return { name: 'In Review', color: 'bg-[#ebedef]', text: 'text-[#1389E6]' }
        case 'device_accepted':
            return { name: isRecentChanges ? 'Delivered' : 'Accepted', color: 'bg-[#ebedef]', text: 'text-[#00B67A]' }
        case 'device_rejected':
            return { name: 'Rejected', color: 'bg-[#ebedef]', text: 'text-[#E50000]' }
        case 'canceled':
            return { name: 'Canceled', color: 'bg-[#ebedef]', text: 'text-[#E50000]' }
        case 'awaiting_user_response':
            return { name: isRecentChanges ? 'Revaluation Awaiting Response' : 'Awaiting User Response', color: isRecentChanges ? 'bg-[#ebedef]' : 'bg-[#ebedef]', text: 'text-[#fb6f21]' }
        case 'awaiting_cs_response':
            return { name: 'Awaiting CS Response', color: 'bg-[#ebedef]', text: 'text-[#fb6f21]' }
        case 'user_accepted_offer':
            return { name: isRecentChanges ? 'Price Revaluation Accepted' : 'User Accepted Offer', color: 'bg-[#ebedef]', text: 'text-[#00B67A]' }
        case 'user_rejected_offer':
            return { name: 'User Rejected Offer', color: 'bg-[#ebedef]', text: 'text-[#E50000]' }
        case 'cs_ticket_response':
            return { name: 'CS Ticket Response', color: 'bg-[#ebedef]', text: 'text-[#FB6F21]' }
        case 'required_return':
            return { name: 'Return Required', color: 'bg-[#ebedef]', text: 'text-[#FB6F21]' }
        case 'returned':
            return { name: 'Returned', color: 'bg-[#ebedef]', text: 'text-[#FC5555]' }
        case 'blacklisted':
            return { name: 'Blacklisted', color: 'bg-[#ebedef]', text: 'text-[#FC5555]' }
        case 'cs_required_return':
            return { name: 'CS Required Return', color: 'bg-[#ebedef]', text: 'text-[#FB6F21]' }
        case 'item_missing':
            return { name: 'Missing', color: 'bg-[#ebedef]', text: 'text-[#E50000]' }
        default:
            return { name: selectedFilter === 'In Review' ? 'In Review' : selectedFilter === 'Inbound' ? 'Inbound' : status, color: 'bg-[#ebedef]', text: 'text-[#1389E6]' }
    }
}

export const onFormatPackageStatus = (status: string) => {
    switch (status) {
        case 'canceled':
            return { name: 'Canceled', color: 'bg-[#ebedef]', text: 'text-[#E50000]' }
        case 'failed':
            return { name: 'Failed', color: 'bg-[#ebedef]', text: 'text-[#E50000]' }
        case 'shipped':
            return { name: 'Shipped', color: 'bg-[#ebedef]', text: 'text-[#7B61FF]' }
        case 'delivered':
            return { name: 'Delivered', color: 'bg-[#ebedef]', text: 'text-[#7B61FF]' }
        case 'reviewed':
            return { name: 'Reviewed', color: 'bg-[#ebedef]', text: 'text-[#00B67A]' }
        case 'rejected':
            return { name: 'Rejected', color: 'bg-[#ebedef]', text: 'text-[#E50000]' }
        case 'accepted':
            return { name: 'Accepted', color: 'bg-[#ebedef]', text: 'text-[#00B67A]' }
        case 'not_shipped':
            return { name: 'Not Shipped', color: 'bg-[#ebedef]', text: 'text-[#FC5555]' }
        case 'multiple_cashout':
            return { name: 'Multiple Cashout', color: 'bg-[#ebedef]', text: 'text-[#FC5555]' }
        case 'multiple_cashout_received':
            return { name: 'Received', color: 'bg-[#ebedef]', text: 'text-[#7B61FF]' }
        case 'to_reship':
            return { name: 'To Reship', color: 'bg-[#ebedef]', text: 'text-[#00B67A]' }
        default:
            return { name: status, color: 'bg-[#FFF964]' }
    }
}