import React, { useEffect, useState } from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import Loading from '../../../../shared/Loading';
import Table from '../../../../shared/table';


interface IOrderDetailsTable {
    rows: Array<any>;
    paginationData?: IPage;
    state: any;
    ordersLoading: boolean;
    accessControl?: any;
    actions?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const OrderDetailsTable = ({
    rows,
    paginationData,
    state,
    ordersLoading,
    setPaginationState,
}: IOrderDetailsTable) => {
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Item ID', 'Item Name', 'Price', 'Invoice Number'])
    const [tableExtraKeys, setTableExtraKeys] = useState<Array<any>>()

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const formatTableColumnsAndKeys = () => {
        setTableLoading(true)
        const allKeysArray: any = (rows && rows?.length > 0) && rows.flatMap(obj =>
            Object.keys(obj?.descriptor)
        );
        const findKeys = (allKeysArray && allKeysArray?.length > 0) && allKeysArray?.filter((key: any, index: any, array: any) => array?.indexOf(key) === index);
        const formatTableColumns = []
        const formatTableExtraKeys: any = []
        formatTableColumns.push('Item ID')
        formatTableColumns.push('Item Name')
        if ((rows?.[0]?.category || '').toLowerCase() === 'phones' || (rows?.[0]?.category || '').toLowerCase() === 'tablets') {
            formatTableColumns.push('IMEI')
        }
        findKeys && findKeys?.length > 0 && findKeys?.map((key: string) => {
            const formatKey = capitalizeFirstLetter(key)
            const formatElement = (formatKey || '').replaceAll('_', ' ')
            formatTableExtraKeys.push(key)
            formatTableColumns.push(formatElement)
            return key
        })
        formatTableColumns.push('Price')
        formatTableColumns.push('Invoice Number')
        setTableColumns(formatTableColumns || [])
        setTableExtraKeys(formatTableExtraKeys || [])
    }

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [rows, paginationData, state])

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [])

    useEffect(() => {
        if (tableExtraKeys) {
            setTableLoading(false)
        }
    }, [tableExtraKeys])

    return (
        <>
            {tableLoading && !tableColumns ?
                <div className='flex flex-row min-w-full justify-center my-3'>
                    <Loading />
                </div>
                :
                <Table
                    columns={tableColumns}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                    containerStyle={'mt-0'}
                >
                    {ordersLoading ?
                        <>
                            <>{[...Array(4)].map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                        {tableExtraKeys && tableExtraKeys?.length > 0 && tableExtraKeys?.map(element => {
                                            return (
                                                <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                            )
                                        })
                                        }
                                        <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                        <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    </tr>
                                )
                            })}
                            </>
                        </>
                        : <>
                            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                                return (
                                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                        <td className='px-6 py-4'>{row?.itemId}</td>
                                        <td className='px-6 py-4'>{row?.name}</td>
                                        {((rows?.[0]?.category || '').toLowerCase() === 'phones' || (rows?.[0]?.category || '').toLowerCase() === 'tablets')
                                            && <td className='px-6 py-4'>{row?.itemCode}</td>
                                        }
                                        {tableExtraKeys && tableExtraKeys?.length > 0 && tableExtraKeys?.map((key: string, index: number) => {
                                            return (
                                                <td
                                                    key={index}
                                                    className={`px-6 py-4`}>
                                                    {row?.descriptor?.[key]}
                                                </td>
                                            )
                                        })}
                                        <td className='px-6 py-4 min-w-[120px]'>
                                            <div className='flex flex-row items-center '>
                                                {row?.currency && CURRENCY_SYMBOLS?.[`${(row?.currency || '')}`]}
                                                {Number(row?.totalPrice || 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-6 py-4'>{row?.invoiceNumber}</td>
                                    </tr>
                                )
                            })}
                        </>
                    }
                </Table>
            }
        </>
    )
}

export default OrderDetailsTable;