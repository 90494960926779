import { AxiosResponse } from 'axios';
import {
    OFFER_API_URL,
    UPLOAD_PRICING_URL
} from '../../../../config';
import offersAxios from '../../../../utils/axios/offers.axios';
import uploadPricingAxios from '../../../../utils/axios/upload-pricing.axios';
import { ICurrencySettings } from '../../../../interfaces/curreny-settings/ICurrencySettings';

const tryToFetchCurrencySettings = (): Promise<AxiosResponse> => {
    return offersAxios.get(`${OFFER_API_URL}/offers-currency-settings`);
};

const tryToEditCurrencySettings = (data: ICurrencySettings): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/offers-currency-settings/edit`, data);
};

const tryToConvertCurrency = (data: any): Promise<AxiosResponse> => {
    return uploadPricingAxios.put(`${UPLOAD_PRICING_URL}/offers/currency-convert`, data);
};

export const CurrencySettingsService = {
    tryToFetchCurrencySettings,
    tryToEditCurrencySettings,
    tryToConvertCurrency
};
