import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IToast } from '../../../interfaces/components/IToast';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToFetchShippingServices } from '../../../store/brokers/admin/shipping-services/shippingServicesSlice';
import ShippingServicesTableContainer from './loading/ShippingServicesTableContainer';
import MarketShippingServicesModal from './modals/MarketShippingServicesModal';
import ShippingServicesFilters from './details/ShippingServicesFilters';
import AllFeeConfigurations from './components/AllFeeConfigurations';
import EmptyContainer from '../../../shared/empty-container';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const ShippingServices = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<[]>([]);
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [showToast, setShowToast] = useState<IToast | null>();
    const [selectedSector, setSelectedSector] = useState<any>()
    const [markets, setMarkets] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const [marketItemId, setMarketItemId] = useState<string | null>();
    const [selectedTemplatesToShow, setSelectedTemplatesToShow] = useState<Array<string>>()
    const [showModalShppingFee, setShowModalShppingFee] = useState<boolean>(false);
    const [pageAccess, setPageAccess] = useState<any>()
    const [getAllMarkets] = useGetAllMarketsMutation()
    const rowsLoading = useAppSelector((state) => state.shippingServices.shippingServicesAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state) => state.shippingServices);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Shipping Services')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.shippingServicesAll) {
            const rows = state?.shippingServicesAll || []
            setRows(rows);
        }
    }, [state.shippingServicesAll]);

    const getMarketsData = async () => {
        try {
            const marketResponse = await getAllMarkets(null).unwrap()
            const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id, displayName: market?.name, icon: market?.flagUrl }))
            setMarkets(formatMarkets || [])
        } catch (err) {
            // error here
        }
    }

    const getShippingServicesData = async () => {
        let formData: any = {}
        if (selectedMarket) {
            formData = {
                ...formData || {},
                market: selectedMarket?.displayName
            }
        }
        if (selectedSector) {
            formData = {
                ...formData || {},
                assetTemplateName: selectedSector?.name
            }
        }
        await dispatch(tryToFetchShippingServices(formData));
    }

    useEffect(() => {
        getShippingServicesData()
    }, []);

    useEffect(() => {
        getShippingServicesData()
    }, [selectedMarket, selectedSector]);

    useEffect(() => {
        dispatch(tryToFetchAllAssetsTemplates());
        getMarketsData()
    }, []);

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const onAddShippingServices = (id?: string) => {
        if (id) {
            setMarketItemId(id);
        }
        setShowModalShppingFee(true);
    };

    const onCloseShippingServicesModal = () => {
        setMarketItemId(null);
        setShowModalShppingFee(false);
    };

    const onSaveChanges = (type: string, message: string) => {
        setShowToast({ type, message });
    };

    const onSelectFilter = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'market':
                    setSelectedMarket(value)
                    break;
                case 'sector':
                    setSelectedSector(value)
                    break;
            }
        }
    }

    const onClearAllFilters = () => {
        setSelectedMarket(undefined)
        setSelectedSector(undefined)
    }

    const onChangeSelectedTemplateToShow = (name: string) => {
        if (selectedTemplatesToShow && selectedTemplatesToShow?.length > 0 && selectedTemplatesToShow?.includes(name)) {
            setSelectedTemplatesToShow((items: any) => items?.filter((i: any) => i !== name));
        } else {
            setSelectedTemplatesToShow((items: any) => [...items || [], name]);
        }
    }

    const onClickEditMarketServices = (marketId: string) => {
        setMarketItemId(marketId)
        setShowModalShppingFee(true)
    }

    return (
        <div>
            <div>
                <p className='page-title'>Shipping Services</p>
            </div>
            <div className='mb-4'>
                <ShippingServicesFilters
                    markets={markets}
                    sectors={sectors}
                    selectedMarket={selectedMarket}
                    selectedSector={selectedSector}
                    onSelectFilter={onSelectFilter}
                />
            </div>
            <div className='flex flex-row flex-wrap justify-end mb-4'>
                <Button
                    label={'Clear all filters'}
                    dataQa={'clear-all-filters'}
                    className={'btn-primary-text-underline flex flex-row justify-end bg-transparent mr-2 mb-1'}
                    onClickButton={onClearAllFilters}
                />
                <Button
                    label={'Manage Shipping Services'}
                    dataQa={'manage-shipping-services'}
                    className={`${(!accessControl || pageAccess?.actions?.['Manage Shipping Services']) ? 'btn-main' : 'btn-main-disable'} mr-2 mb-1`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Manage Shipping Services']) && onAddShippingServices()}
                />
            </div>
            <div className='my-5 relativ'>
                {(rows?.length > 0 || rowsLoading) ?
                    <>
                        {rowsLoading ?
                            <ShippingServicesTableContainer />
                            : <AllFeeConfigurations
                                markets={markets}
                                sectors={sectors}
                                data={rows}
                                accessControl={accessControl}
                                pageAccess={pageAccess}
                                selectedTemplateToShow={selectedTemplatesToShow}
                                onClickEditMarketServices={onClickEditMarketServices}
                                onChangeSelectedTemplateToShow={onChangeSelectedTemplateToShow}
                            />
                        }
                    </>
                    : <EmptyContainer
                        text={'No Shipping Services Configuration added yet.'}
                        showImage={true}
                    />
                }
            </div>
            <MarketShippingServicesModal
                sectors={sectors}
                markets={markets}
                marketId={marketItemId}
                openActionsModal={showModalShppingFee}
                handleCloseModal={onCloseShippingServicesModal}
                onSaveChanges={onSaveChanges}
            />
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
        </div>
    );
};

export default ShippingServices;
