import React, { useEffect, useState } from 'react';
import { IAssets } from '../../../../interfaces/assets/IAssets';
import { IAssetFields } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayloadWithId } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';
import Loading from '../../../../shared/Loading';


interface IRentalAssetsDetailsTable {
    rows: IAssets[];
    assetTemplateFields: IAssetFields[];
    paginationData?: IPage;
    paginationState?: any;
    accessControl?: any;
    actions?: any;
    onDeleteAssetRequest: (id: string) => void;
    onEditAsset: (id: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayloadWithId) => void;
}

const RentalAssetsDetailsTable = ({
    rows,
    paginationData,
    assetTemplateFields,
    paginationState,
    accessControl,
    actions,
    onDeleteAssetRequest,
    setPaginationState,
    onEditAsset
}: IRentalAssetsDetailsTable) => {
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Actions'])
    const [tableExtraKeys, setTableExtraKeys] = useState<Array<any>>()

    useEffect(() => {
        if (assetTemplateFields && assetTemplateFields?.length > 0) {
            const tableColumnsFormat = assetTemplateFields?.map((field) => field?.name)
            const formatTableExtraKeys: any = assetTemplateFields?.map((field) => field?.name)
            tableColumnsFormat.push('Actions')
            setTableExtraKeys(formatTableExtraKeys)
            setTableColumns(tableColumnsFormat)
        }
    }, [assetTemplateFields])

    return (
        <>
            {!tableColumns ?
                <div className='flex flex-row min-w-full justify-center my-3'>
                    <Loading />
                </div>
                :
                <Table
                    columns={tableColumns}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                    paginationState={paginationState}
                    containerStyle={'mt-0'}
                >
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} data-qa={'broker-qa'} className='bg-white border-b hover:bg-gray-50'>
                                {tableExtraKeys && tableExtraKeys?.length > 0 && tableExtraKeys?.map((column: any, id: number) => {
                                    return (
                                        <td key={id} className='px-6 py-4'>{(row?.descriptor?.[column] !== true && row?.descriptor?.[column] !== false) ? row?.descriptor?.[column] : (row?.descriptor?.[column] ? 'True' : 'False')}</td>
                                    )
                                })}
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa={'edit-asset'} onClick={() => (!accessControl || actions?.['Edit Asset']) && onEditAsset(row?._id || '')}>
                                            <img title='Edit Asset' alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Asset']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa={'delete-asset'} onClick={() => (!accessControl || actions?.['Delete Asset']) && onDeleteAssetRequest(row?._id)}>
                                            <img title='Delete Asset' alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Asset']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </Table >
            }
        </>
    )
}

export default RentalAssetsDetailsTable;