import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { tryToFetchSingleRMADetails } from '../../../store/brokers/partners/rma/partnerRMAsSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Loading from '../../../shared/Loading';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import Input from '../../../shared/input';
import RMADetailsItemsTable from './tables/RMADetailsItemsTable';
import EmptyContainer from '../../../shared/empty-container';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IMarket } from '../../../interfaces/markets/IMarket';
import moment from 'moment';

const RMADetails = () => {
    const { id } = useParams() || { id: '' };
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: undefined,
    });
    const [rows, setRows] = useState<any[]>([]);
    const [RMADetails, setRMADetails] = useState<any>();
    const [sectors, setSectors] = useState<IAssetTemplate[]>([]);
    const state = useAppSelector((state) => state.assetsTemplates);
    const [markets, setMarkets] = useState<IAllMarkets[]>();
    const [pageAccess, setPageAccess] = useState<any>();
    const accessControl = useAppSelector(
        (state) => state?.partners?.partnerNavigation,
    );

    const [getAllMarkets] = useGetAllMarketsMutation();
    const getMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap();
        const formatMarkets =
            marketResponse &&
            marketResponse?.length > 0 &&
            marketResponse?.map((market: IMarket) => ({
                ...market,
                value: market?._id,
                displayName: market?.name,
            }));
        setMarkets(formatMarkets || []);
    };

    const paginationData = useAppSelector(
        (state) => state.partnersRMAs?.rmaDetails?.data?.page,
    );
    const rowsLoading = useAppSelector(
        (state) => state.partnersRMAs?.rmaDetailsLoading,
    );
    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    };
    const getCategoryIcon = (category: string) => {
        const res = sectors.find((sector) => sector.name === category);
        return res?.disabledIcon;
    };
    const getMarketIcon = () => {
        const res =
            markets &&
            markets?.length > 0 &&
            markets?.find((market: IAllMarkets) => market?._id === RMADetails?.marketId);
        if (res)
            return res.flagUrl
    };
    const getMarketDisplayName = () => {
        const res =
            markets &&
            markets?.length > 0 &&
            markets?.find((market: IAllMarkets) => market?._id === RMADetails.marketId);
        if (res)
            return res.label
    };

    const onGoBack = () => {
        navigate(-1);
    };
    const onChangeSearchValue = (
        value: string | null | undefined,
        type: string,
    ) => {
        setPaginationState({
            ...(paginationState || {}),
            pageNumber: 1,
            filters: value || undefined,
        });
    };
    const getRMADetails = async () => {
        if (id) {
            const resp = await dispatch(
                tryToFetchSingleRMADetails({ id, paginationState }),
            ).unwrap();
            setRMADetails({
                ...resp?.data?.rma,
                actionsNeeded: resp?.data?.actionsNeeded,
                totalInitialPrice: resp?.data?.totalInitialPrice,
                totalFinalPrice: resp?.data?.totalFinalPrice,
                reason: resp?.data?.elements?.[0]?.reason || ''
            });
            setRows(resp?.data?.elements);
        }
    };
    useEffect(() => {
        const findPage =
            accessControl &&
            accessControl?.length > 0 &&
            accessControl?.find(
                (item: any) =>
                    item?.name === 'Return Merchandise Authorization',
            );
        const findChild =
            findPage &&
            findPage?.children &&
            findPage?.children?.length > 0 &&
            findPage?.children?.find(
                (item: any) => item?.name === 'RMA Details',
            );
        setPageAccess(findChild || undefined);
    }, [accessControl]);
    useEffect(() => {
        getMarketData();
    }, []);
    useEffect(() => {
        if (state.allAssetTemplates) {
            const rows =
                state.allAssetTemplates &&
                    Array.isArray(state.allAssetTemplates)
                    ? state.allAssetTemplates.map((r) => ({
                        ...(r || {}),
                        name: r?.name,
                        label: r?.displayName,
                        value: r?._id,
                    }))
                    : [];
            setSectors(
                rows?.length > 0
                    ? rows?.filter(
                        (r) => (r?.name || '').toLowerCase() !== 'currencies',
                    )
                    : [],
            );
        } else {
            dispatch(tryToFetchAllAssetsTemplates());
        }
    }, [state.allAssetTemplates]);
    useEffect(() => {
        getRMADetails();
    }, [id, paginationState]);

    if (!RMADetails)
        return (
            <div className="w-full h-full  md:min-h-[calc(100vh-12rem)] flex justify-center items-center">
                <Loading />
            </div>
        );
    return (
        <div className="w-full">
            <div className={'flex flex-row items-center'}>
                <div className="flex flex-row items-center">
                    <div
                        data-qa={'back-button'}
                        onClick={onGoBack}
                        className="md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10">
                        <img
                            src={'/assets/shared/go-back-circle.svg'}
                            className={'w-6 object-contain cursor-pointer'}
                        />
                    </div>
                    <p className="page-title">Created RMA Details</p>
                </div>
            </div>
            <div className="w-full bg-white flex justify-start items-center p-6 rounded-xl gap-11">
                <div
                    className={`px-3 py-2 rounded-[10px] border ${RMADetails?.status === 'created'
                        ? 'text-[#F7A21E] border-[#F7A21E]'
                        : 'text-[#00B67A] border-[#00B67A]'
                        }`}>
                    {capitalizeFirstLetter(RMADetails.status)}
                </div>
                <div className="flex flex-col">
                    <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
                        RMA ID
                    </span>
                    <span className="text-[#202020] text-sm">{id}</span>
                </div>
                <div className="flex flex-col">
                    <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
                        Category
                    </span>
                    <div className="flex gap-1 items-center">
                        {RMADetails.categories?.slice(0, 2).map((category: string, index: number) => <img key={index} className='w-6 h-6' src={getCategoryIcon(category)} alt={category} title={category} />)}
                        {RMADetails?.categories?.length > 2 && (
                            <span className="ml-1 hover:cursor-pointer relative" title={RMADetails?.categories?.slice(2).join(', ')}>
                                ...
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
                        Market
                    </span>
                    <span className="text-[#202020] text-sm flex gap-1 items-center">
                        <img src={getMarketIcon()} alt={getMarketDisplayName()} />
                        {getMarketDisplayName()}
                    </span>
                </div>
                <div className="flex flex-col">
                    <span className="text-[#202020] text-base font-bold font-dm_sansbold ">
                        Created Date
                    </span>
                    <span className="text-[#202020] text-sm">
                        {moment(RMADetails?.createdAt).format(
                            'MMMM Do YYYY, h:mm:ss a',
                        )}
                    </span>
                </div>
            </div>
            <div className="my-5 bg-[#F7A21E1A] rounded-[10px] p-2 flex flex-row justify-end items-center">
                <Input
                    placeholder={'Filter Table'}
                    containerStyle="mb-0 pb-0 relative flex-initial w-96"
                    inputStyle={`mb-0 border-0 focus:outline-none`}
                    icon={'/assets/shared/search.svg'}
                    showValue={true}
                    inputValue={paginationState?.filters}
                    inputUniqueName={'search'}
                    onChangeInput={onChangeSearchValue}
                    hideInputSpace={true}
                    dataQa={'filter-table'}
                />
            </div>

            <div className="my-5 relative">
                {rows?.length > 0 || rowsLoading ? (
                    <RMADetailsItemsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        sectors={sectors}
                        pageAccess={pageAccess}
                        accessControl={accessControl}
                        markets={markets || []}
                        RMADetails={RMADetails}
                        getRMADetails={getRMADetails}
                        rmaId={id || ''}
                    />
                ) : (
                    <EmptyContainer text={'No Item found.'} showImage={true} />
                )}
            </div>
        </div>
    );
};

export default RMADetails;
