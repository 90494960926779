import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import { tryToDownloadPricingReport } from '../../../../store/brokers/admin/reports/reportsServiceSlice';
import { tryToFetchAllAssetsTemplates } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import DownloadDataModal from '../modals/DownloadDataModal';
import Button from '../../../../shared/button';

const PricingCategory = () => {
    const dispatch = useAppDispatch();
    const [sectors, setSectors] = useState<IAssetTemplate[]>();
    const [markets, setMarkets] = useState<any>();
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [downloadError, setDownloadError] = useState<string>();
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const [getAllMarkets] = useGetAllMarketsMutation();

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates &&
                    Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({
                        ...(r || {}),
                        value: r?._id,
                        label: r?.displayName,
                    }))
                    : [];
            setSectors(
                rows?.length > 0
                    ? rows?.filter(
                        (r) => (r?.name || '').toLowerCase() !== 'currencies',
                    )
                    : [],
            );
        }
    }, [stateAssets.allAssetTemplates]);

    const getPartnerAndMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap();
        const formatMarkets =
            marketResponse &&
            marketResponse?.length > 0 &&
            marketResponse?.map((market: IMarket) => ({
                ...market,
                value: market?._id,
            }));
        setMarkets(formatMarkets || []);
    };

    useEffect(() => {
        getPartnerAndMarketData();
        if (!stateAssets?.assetTemplates) {
            dispatch(tryToFetchAllAssetsTemplates());
        }
    }, []);

    const onToggleDownloadModal = (type?: string) => {
        setShowDownloadModal(!showDownloadModal);
    };

    const onDownloadData = async (filters?: any) => {
        setLoadingDownload(true);
        try {
            const response: any = await dispatch(
                tryToDownloadPricingReport(filters),
            ).unwrap();
            const file = new Blob([response], {
                type: 'text/csv',
            });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            setDownloadError(undefined);
        } catch (err) {
            setDownloadError(`${err}`);
        } finally {
            setLoadingDownload(false);
        }
    };

    return (
        <div>
            <div className='flex flex-row justify-center my-10'>
                <Button
                    label={'Download Pricing Report'}
                    dataQa={'download-pricing-report'}
                    className='btn-main w-[300px] !min-w-[150px] !mt-0 mr-2'
                    onClickButton={onToggleDownloadModal}
                />
            </div>
            <DownloadDataModal
                sectors={sectors}
                markets={markets}
                hideFilters={false}
                hideDates={true}
                loadingDownload={loadingDownload}
                openDownloadModal={showDownloadModal}
                downloadError={downloadError}
                setDownloadError={setDownloadError}
                handleCloseDownloadModal={onToggleDownloadModal}
                setLoadingDownload={setLoadingDownload}
                onDownloadData={onDownloadData}
            />
        </div>
    );
};

export default PricingCategory;
