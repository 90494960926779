import React from 'react';
import { IPageAccess } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import Button from '../../../../shared/button';


interface IDetailsActions {
    onClickOrderDetailsAction: (action: string) => void;
    showReject?: boolean;
    showContactCS?: boolean;
    showMissing: boolean;
    pageAccess?: IPageAccess;
    accessControl?: IAccessControl;
}

const DetailsActions = ({
    onClickOrderDetailsAction,
    showContactCS,
    showReject,
    showMissing,
    pageAccess,
    accessControl,
}: IDetailsActions) => {
    return (
        <div className='flex flex-row flex-wrap'>
            {showContactCS &&
                <Button
                    label={'Support'}
                    icon={<img src='/assets/order-actions/customer-support.svg' className='w-[30px] object-contains pr-2' />}
                    className={`${(!accessControl || (pageAccess?.actions?.['Contact CS'])) ? 'btn-main' : 'btn-main-disable'} !font-medium !shadow-none flex flex-row items-center`}
                    onClickButton={() => (!accessControl || (pageAccess?.actions?.['Contact CS'])) && onClickOrderDetailsAction('Contact CS')}
                />
            }
            {showReject &&
                <Button
                    label={'Reject'}
                    className={`${(!accessControl || (pageAccess?.actions?.Reject)) ? 'btn-main' : 'btn-main-disable'} ml-3 !text-[#e50000] !font-medium`}
                    onClickButton={() => (!accessControl || (pageAccess?.actions?.Reject)) && onClickOrderDetailsAction('Reject')}
                />
            }
            {showMissing &&
                <Button
                    label={'Missing'}
                    className={`${(!accessControl || (pageAccess?.actions?.['Damaged Package'])) ? 'btn-main' : 'btn-main-disable'} ml-3 !text-[#fb6f21] !font-medium`}
                    onClickButton={() => (!accessControl || (pageAccess?.actions?.['Damaged Package'])) && onClickOrderDetailsAction('Missing Item')}
                />
            }
        </div>
    );
};

export default DetailsActions;
