import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IUIAction } from '../../../interfaces/ui-actions/IUIAction';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToDeleteUIAction,
    tryToFetchUIActions
} from '../../../store/brokers/admin/ui-actions/uiActionsSlice';
import Toast from '../../../shared/toast';
import Input from '../../../shared/input';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';
import UIActionModal from './modals/UIActionModal';
import UIActionsTable from './tables/UIActionTable';


const UIActions = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IUIAction[]>([]);
    const [search, setSearch] = useState<string>();
    const [showToast, setShowToast] = useState<IToast | null>()
    const [showUIActionModal, setShowUIActionModal] = useState<boolean>(false)
    const [uiActionId, setUIActionId] = useState<string | null>()
    const [showDeleteUIActionModal, setShowDeleteUIActionModal] = useState<boolean>(false)
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.uiActions?.uiActions?.data?.page);
    const rowsLoading = useAppSelector((state) => state.uiActions?.uiActionsAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.uiActions);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'UI Actions')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.uiActions) {
            const rows =
                state.uiActions?.data?.elements && Array.isArray(state.uiActions?.data.elements)
                    ? state.uiActions?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.uiActions]);

    const fetchPaginatedUIActions = () => {
        const settingsFilters: any = {
            ...paginationState
        }
        if (search) {
            settingsFilters.filters = { search }
        }
        dispatch(tryToFetchUIActions(settingsFilters));
    }

    useEffect(() => {
        fetchPaginatedUIActions()
    }, [paginationState]);

    const onAddNewUIAction = (id?: string) => {
        if (id) {
            setUIActionId(id)
        }
        setShowUIActionModal(true)
    }

    const onCloseUIActionModal = () => {
        setUIActionId(null)
        setShowUIActionModal(false)
    }

    const onSeeUIActionDetails = async (id: string) => {
        navigate(`/ui-actions/${id}`);
    }
    const onShowDeleteUIActionModal = (id: string) => {
        setUIActionId(id)
        setShowDeleteUIActionModal(true)
    }
    const onDeleteUIAction = async () => {
        try {
            if (uiActionId) {
                await dispatch(tryToDeleteUIAction(uiActionId)).unwrap();
                setShowToast({ type: 'success', message: `UI Action successfully deleted` });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setUIActionId(null)
        setShowDeleteUIActionModal(false)
        fetchPaginatedUIActions()
    }

    const onSaveUIActionChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        setUIActionId(null)
    }

    const onCloseDeleteUIActionModal = () => {
        setUIActionId(null)
        setShowDeleteUIActionModal(false)
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    return (
        <div>
            <div>
                <p className='page-title'>UI Actions</p>
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New UI Action'}
                    dataQa={'add-new-ui-action'}
                    className={(!accessControl || pageAccess?.actions?.['Add New UI Actions']) ? 'btn-main' : 'btn-main-disable'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New UI Actions']) && onAddNewUIAction()}
                />
            </div>
            <div className='my-5 relative'>
                <div className={`min-w-[100px]`}>
                    <Input
                        placeholder={'Filter Table'}
                        dataQa={'filter-table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                {(rows?.length > 0 || rowsLoading) ?
                    <UIActionsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onSeeUIActionDetails={onSeeUIActionDetails}
                        onDeleteUIAction={onShowDeleteUIActionModal}
                        onEditUIAction={onAddNewUIAction}
                    />
                    : <EmptyContainer
                        text={'No UI Actions added yet.'}
                        showImage={true} />
                }
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            <UIActionModal
                openCreateAssetModal={showUIActionModal}
                handleCloseAssetModal={onCloseUIActionModal}
                onSaveChanges={onSaveUIActionChanges}
                uiActionId={uiActionId}
            />
            <DeleteModal
                openDeleteModal={showDeleteUIActionModal}
                handleCloseDeleteModal={onCloseDeleteUIActionModal}
                onApproveDeletion={onDeleteUIAction}
            />
        </div>
    )
}

export default UIActions;