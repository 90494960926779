import React from 'react';
import Button from '../button';
import Input from '../input';
import Loading from '../Loading';

interface ISearchWithButton {
    placeholder: string;
    search?: string | null;
    buttonLabel?: string;
    mainContainerStyle?: string,
    buttonStyle?: string;
    inputStyle?: string;
    showScndBtn?: boolean;
    scndButtonLabel?: string;
    thirdButtonLabel?: string;
    showThirdBtn?: boolean;
    isLoadingButton?: boolean;
    isLoadingScndButton?: boolean;
    isLoadingThrdButton?: boolean;
    dataQa?: string;
    scndButtonStyle?: string;
    thirdButtonStyle?: string;
    onClickSecondButton?: () => void;
    onClickThirdButton?: () => void;
    onClickButton?: () => void;
    onChangeSearchValue: (value: string | null | undefined, type: string) => void;
}

const SearchWithButton = ({
    search,
    buttonLabel,
    placeholder,
    mainContainerStyle,
    buttonStyle,
    inputStyle,
    showScndBtn,
    scndButtonLabel,
    scndButtonStyle,
    showThirdBtn,
    thirdButtonLabel,
    thirdButtonStyle,
    isLoadingButton,
    isLoadingScndButton,
    isLoadingThrdButton,
    onClickThirdButton,
    onClickSecondButton,
    onChangeSearchValue,
    onClickButton,
    dataQa
}: ISearchWithButton) => {
    return (
        <div className={`grid bg-white ${mainContainerStyle || 'grid-cols-9'}`}>
            <div className={`${showThirdBtn ? '!col-span-4' : showScndBtn ? 'col-span-6' : 'col-span-8 '}`}>
                <Input
                    placeholder={placeholder}
                    containerStyle='mb-0 pb-0 relative'
                    inputStyle={`mb-0 border-0 focus:outline-none ${inputStyle || ''}`}
                    icon={'/assets/shared/search.svg'}
                    showValue={true}
                    inputValue={search || undefined}
                    inputUniqueName={'search'}
                    onChangeInput={onChangeSearchValue}
                    hideInputSpace={true}
                    dataQa={'search-table'}
                />
            </div>
            {showThirdBtn &&
                <Button
                    label={thirdButtonLabel || ''}
                    icon={isLoadingThrdButton ? <Loading /> : undefined}
                    disabled={isLoadingThrdButton || false}
                    onClickButton={onClickThirdButton}
                    className={`!shadow-none min-w-full pb-2 ${thirdButtonStyle || scndButtonStyle}`}
                    dataQa={`${thirdButtonLabel?.toLocaleLowerCase().replaceAll(' ', '-')}`}

                />}
            {showScndBtn &&
                <Button
                    label={scndButtonLabel || ''}
                    icon={isLoadingScndButton ? <Loading /> : undefined}
                    disabled={isLoadingScndButton || false}
                    onClickButton={onClickSecondButton}
                    className={`!shadow-none min-w-full pb-2 ${scndButtonStyle}`}
                    dataQa={`${scndButtonLabel?.toLocaleLowerCase().replaceAll(' ', '-')}`}

                />}
            {buttonLabel && <Button
                label={buttonLabel}
                icon={isLoadingButton ? <Loading /> : undefined}
                disabled={isLoadingButton || false}
                onClickButton={onClickButton}
                className={`!shadow-none min-w-full pb-2 ${buttonStyle}`}
                dataQa={`${buttonLabel?.toLocaleLowerCase().replaceAll(' ', '-')}`}

            />
            }
        </div>
    )
}

export default SearchWithButton;