import React, { useState, useCallback } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone'
import { useAppDispatch } from '../../../../app/hooks';
import { tryToImportAssetImagesData } from '../../../../store/brokers/admin/assets/assetsSlice';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';

interface IImportRentalAssetsImagesModal {
  openImportModal: boolean;
  assetTemplateId: string;
  handleCloseModal: () => void;
  onImportAssetsSuccessfully: () => void;
}

const ImportRentalAssetsImagesModal = (
  {
    openImportModal,
    assetTemplateId,
    handleCloseModal,
    onImportAssetsSuccessfully
  }: IImportRentalAssetsImagesModal) => {
  const dispatch = useAppDispatch();
  const [importError, setImportError] = useState<string>()
  const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
  const [fileSelected, setFileSelected] = useState<any>()

  const onDrop = useCallback((acceptedFiles: any) => {
    setFileSelected(acceptedFiles?.[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const onCloseModal = () => {
    handleCloseModal();
    setImportedSuccessfully(false);
    setImportError(undefined);
    setFileSelected(undefined)
  }

  const onUploadFile = async () => {
    try {
      Papa.parse(fileSelected, {
        header: true,
        skipEmptyLines: true,
        complete: async function (results: any) {
          const payload = {
            assetTemplateId: assetTemplateId,
            data: results?.data || [],
            rental: true
          }
          await dispatch(tryToImportAssetImagesData(payload)).unwrap()
        }
      })
      setImportError(undefined)
      setImportedSuccessfully(true)
      onImportAssetsSuccessfully()
    } catch (err) {
      setImportedSuccessfully(false)
      setImportError(`${err}`)
    }
  }

  return (
    <Modal
      open={openImportModal}
      onClose={onCloseModal}>
      <div className={'p-2 min-w-[500px]'}>
        {importedSuccessfully ?
          <div className={'w-full flex flex-col items-center justify-between'}>
            <div>
              <p className='text-xl font-bold text-center'>Rental Assets Images uploaded successfully</p>
            </div>
            <img src={'/assets/shared/success-mark.svg'} className={'h-[150px] object-contain my-6'} />
            <Button
              label='Close'
              dataQa={'close'}
              className='btn-main my-3 min-w-[150px] !shadow-none'
              onClickButton={handleCloseModal}
            />
          </div>
          : <div className='p-3'>
            <div className='mb-3'>
              <p className='text-sm font-semibold'>Import Rental Assets Images CSV</p>
              {importError && <Error text={importError} />}
            </div>
            <div className='flex flex-col items-center justify-center min-h-[200px] rounded'>
              <div className='flex flex-col items-center justify-center' {...getRootProps()}>
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p className='font-semibold'>Drag n drop csv file here</p>
                }
                <p className='mb-2'>
                  or
                </p>
                <Button
                  icon={<img src='/assets/shared/upload-file.svg' className='w-[30px] object-contains pr-2' />}
                  className={'btn-main !bg-[#202020] !text-white !py-2 !shadow-none flex flex-row items-center'}
                  label={'Select File'}
                  dataQa={'select-fole-asset-btn'}
                />
                {fileSelected && <p>{fileSelected?.name}</p>}
              </div>
            </div>
            <div className='flex flex-col items-center my-2'>
              <Button
                label='Upload File'
                dataQa={'upload-file'}
                onClickButton={() => fileSelected && onUploadFile()}
                className={`${fileSelected ? 'btn-main' : 'btn-main-disable'} !py-2 !shadow-none min-w-[150px]`}
              />
            </div>
          </div>
        }
      </div>
    </Modal>
  )
}

export default ImportRentalAssetsImagesModal;