import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToCreateCreditNote, tryToFetchAllItemCredits } from '../../../../store/brokers/admin/rma/RMAsSlice';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToFetchAllPartners } from '../../../../store/brokers/admin/partners/partnersSlice';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import SelectCheckbox from '../../../../shared/select-checkbox';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import ItemCreditsTable from '../tables/ItemCreditsTable';
import EmptyContainer from '../../../../shared/empty-container';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import Input from '../../../../shared/input';
import Button from '../../../../shared/button';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import CreateCreditNoteModal from '../modals/CreateCreditNoteModal';
import { IToast } from '../../../../interfaces/components/IToast';
import Toast from '../../../../shared/toast';
import CreditSummaryModal from '../modals/CrediSummaryModal';


interface IItemCreditsTab {
  paginationState: IPaginationPayload,
  setPaginationState: React.Dispatch<React.SetStateAction<IPaginationPayload>>
  onResetPaginationSettings: () => void
  accessControl: any
  pageAccess: any
  query: URLSearchParams
  searchParams: URLSearchParams
  setSearchParams: any
  sectors: IAssetTemplate[]
  markets: IAllMarkets[]
}

const ItemCreditsTab = ({ paginationState, setPaginationState, onResetPaginationSettings, pageAccess, accessControl, query, searchParams, setSearchParams, sectors, markets }: IItemCreditsTab) => {
  const dispatch = useAppDispatch();
  const [selectedRows, setSelectedRows] = useState<any[] | undefined>();
  const [partners, setPartners] = useState<IPartners[]>()
  const [selectedPartner, setSelectedPartner] = useState<string>()
  const [sectorSelected, setSectorSelected] = useState<string>();
  const [selectedMarket, setSelectedMarket] = useState<{label: string, value: string, id: string}>({label: '', value: '', id: ''});
  const [rows, setRows] = useState<any[]>([]);
  const [showActionCheckModal, setShowActionCheckModal] = useState<{
    show: boolean;
    text: JSX.Element;
  }>();
  const [showSummaryModal, setShowSummaryModal] = useState(false)
  const [loadingAction, setLoadingAction] = useState(false)
  const [showToast, setShowToast] = useState<IToast | null>();
  const paginationData = useAppSelector((state) => state.RMAs.RMAs?.data?.page);
  const rowsLoading = useAppSelector((state) => state.RMAs.RMAsAreLoading);
  const state = useAppSelector((state) => state.RMAs);
  const findPartner: any = (partners && selectedPartner && partners?.length > 0) && partners?.find((item: any) => item?.value === selectedPartner)
  const findSector: any = (sectors && sectorSelected && sectors?.length > 0) && sectors?.find((item: any) => item?.name === sectorSelected)
  const totalCreditAmount = selectedRows?.reduce((sum, item) => sum + item.amount, 0) || 0;

  const findMarket =
    markets &&
    markets?.length > 0 &&
    rows &&
    rows?.length > 0 &&
    markets?.find(
      (market: any) =>
        market?.id ===
        rows[0].market?._id
    );

  const onToggleCreateCreditNoteModal = () => {
    if (!showActionCheckModal) {
      setShowActionCheckModal({ show: true, text: <p>Are you sure you want to create a Credit Note for <span className='font-dm_sansbold'>{findMarket && CURRENCY_SYMBOLS?.[`${(findMarket?.currency?.name || '')}`]}{totalCreditAmount}</span> for the items that were selected? <br /><br /> This action cannot be undone!</p> })
    }
    else { setShowActionCheckModal(undefined) }
  }
  const onToggleSummmaryModal = () => {
    setShowSummaryModal(!showSummaryModal)
  }

  const onClickSelectRow = (id?: string) => {
    if (!id) {
      const getAllIds = rows?.length > 0 && rows?.filter(row => !selectedRows?.find(sr => sr._id === row?._id))
      if ((selectedRows && rows && rows.every(r => selectedRows.find(sr => sr._id === r._id)))) {
        setSelectedRows(sr => sr?.filter(sr => !rows.find(row => row?._id === sr._id)));
      } else {
        setSelectedRows(sr => getAllIds ? [...(sr || []), ...getAllIds] : getAllIds || []);
      }
    } else {
      const findIfAlreadySelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find(sr => sr._id === id);
      if (findIfAlreadySelected) {
        const filterOthers = selectedRows && selectedRows?.length > 0 && selectedRows?.filter((row) => row._id !== id);
        setSelectedRows(filterOthers || undefined);
      } else {
        const rowToAdd = rows.find(row => row._id === id);
        if (rowToAdd) {
          setSelectedRows(sr => [...(sr || []), rowToAdd]);
        }
      }
    }
  };
  const getFiltersData = async () => {
    const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
    const formatPartners = partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, value: partner?._id, label: partner?.companyName }))
    setPartners(formatPartners || [])
    if (!selectedPartner) {
      setSelectedPartner(query?.get('partnerId') || formatPartners?.[0]?.value || undefined)
    }
  }
  const getItemCredits = async () => {
    const formatData: any = {
      ...paginationState,
      data: {

        brokerId: selectedPartner,
        category: sectorSelected,
      }
    }
    if(selectedMarket?.id?.length){
      formatData.data.marketId = selectedMarket?.id
    }
    dispatch(tryToFetchAllItemCredits(formatData))
  }
  const onSelectFilterOption = (value: any, name?: string) => {
    if (name) {
      switch (name) {
        case 'partner':
          setSelectedPartner(value?.value)
          if (value?.value) searchParams.set('partnerId', value?.value)
          else searchParams.delete('partnerId')
      }
      setSearchParams(searchParams)
      setSelectedRows([])
      onResetPaginationSettings()
    }
  }

  const onChangeSelectedFilter = (value: any, type?: string) => {
    setSectorSelected(value?.name)
    if (value?.name) searchParams.set('category', value?.name)
    else searchParams.delete('category')
    setSelectedRows([])
    onResetPaginationSettings()
  }
  const onChangeSearchValue = (value: string | null | undefined, type: string) => {
    setPaginationState({
      ...paginationState || {},
      pageNumber: 1,
      filters: value || undefined
    })
    if (value) searchParams.set('search', value)
    else searchParams.delete('search')
    searchParams.set('page', '1')
    setSelectedRows([])
    setSearchParams(searchParams)
  }

  const onClickMarketFilter = (value: any, name?: string) => {
    if (name) {
        setSelectedMarket({label: value?.label, value: value?.displayName, id: value?._id })
        searchParams.set('market', value?.displayName)
        setSearchParams(searchParams)
        setSelectedRows([])
        onResetPaginationSettings()
    }
  } 

  const onApproveActionCheck = async () => {
    setLoadingAction(true)
    try {
      const creditItemIds = selectedRows?.map(sr => sr.itemId) || []
      const resp = creditItemIds?.length > 0 && await dispatch(tryToCreateCreditNote(
        { creditItemIds, marketId: findMarket && findMarket._id, brokerId: selectedPartner }
      )).unwrap()
      
      setShowToast({
        type: 'success',
        message: 'Credit Note was created successfully.',
      });
      if(resp){
        window.open(resp?.data?.pdf, '_blank')
      }
    } catch (err: any) {
      setShowToast({ type: 'error', message: `${err.message}` });
    }
    onToggleCreateCreditNoteModal();
    setSelectedRows([])
    getItemCredits()
    setLoadingAction(false)
  }

  useEffect(() => {
    const paginationStateFormat = paginationState
    if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
      paginationStateFormat.pageNumber = Number(query?.get('page') || 1)
    }
    if (query?.get('size') && Number(query?.get('size')) !== paginationState?.pageSize) {
      paginationStateFormat.pageSize = Number(query.get('size') || 10)
    }
    if (query?.get('search')) {
      paginationStateFormat.filters = query.get('search') || undefined
    }
    if (paginationStateFormat !== paginationState) {
      setPaginationState(paginationStateFormat)
    }
    if (query?.get('category') && query?.get('category') !== sectorSelected) {
      setSectorSelected(query?.get('category') || '')
    }
  }, [
    query?.get('page'),
    query?.get('size'),
    query?.get('search'),
    query?.get('category')
  ])

  useEffect(() => {
    getFiltersData()
  }, [])

  useEffect(() => {
    if (selectedPartner) {
      getItemCredits()
    }
  }, [paginationState, selectedPartner, sectorSelected])

  useEffect(() => {
    if (state.RMAs) {
      const rows =
        state.RMAs?.data?.elements &&
          Array.isArray(state.RMAs?.data?.elements)
          ? state.RMAs?.data?.elements?.map((r) => ({
            ...r,
            id: r._id,
          }))
          : [];
      setRows(rows);
    }
  }, [state.RMAs]);

  const onClearAllFilters = async () => {
    setSelectedPartner(partners?.[0]?._id || undefined)
    setSectorSelected(undefined)
    setSelectedMarket({label: '', value: '', id: ''}) 
    setSearchParams('')
    setSelectedRows([])
    searchParams.delete('search')
    searchParams.delete('category')
    searchParams.delete('partnerId')
    setPaginationState({ ...paginationState, filters:  '' })
  }

  return (
    <div>
      <div className='grid grid-flow-col my-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
          <SelectCheckbox
            name={'Market'}
            dataQa={'market-selected'}
            containerStyle={'!min-w-[200px]'}
            placeholder={selectedMarket.label || 'All Markets'}
            options={[{label: 'All Markets', value: undefined}, ...markets] || []}
            uniqueName={'market'}
            onChangeSelectedOption={onClickMarketFilter}
          />
          <SelectCheckbox
            name={'Brokers'}
            dataQa={'partner-selected'}
            containerStyle={'!min-w-[200px]'}
            placeholder={findPartner?.label || 'Select'}
            options={partners || []}
            uniqueName={'partner'}
            onChangeSelectedOption={onSelectFilterOption}
          />
          <SelectCheckbox
            name='Category'
            containerStyle={'!min-w-[200px]'}
            placeholder={findSector?.label || 'All'}
            dataQa={'Category'}
            options={[{ name: undefined, displayName: 'All', label: 'All' }, ...sectors] || []}
            onChangeSelectedOption={onChangeSelectedFilter}
          />
        </div>
        <div className='flex justify-end self-center'>
          <Button
            label={'Credit Summary'}
            onClickButton={(!accessControl || pageAccess?.actions?.['Credit Summary']) ? onToggleSummmaryModal : () => { }}
            className={`${(!accessControl || pageAccess?.actions?.['Credit Summary']) ? 'btn-main !bg-[#202020] !text-white' : 'btn-main-disable cursor-not-allowed'}  !py-3 !px-5`}
          />
        </div>
      </div>
      <div className='bg-[#F7A21E1A] rounded-[10px] p-2 flex flex-row justify-between items-center'>
        <div>
          <p className='font-bold'>{selectedRows ? selectedRows?.length : 0} Items selected{totalCreditAmount !== 0 && <span> - - Total Credit: <span className='font-dm_sansbold'>{findMarket && CURRENCY_SYMBOLS?.[`${(findMarket?.currency?.name || '')}`]}{totalCreditAmount?.toFixed(2)}</span></span>}</p>
        </div>
        <div className='flex flex-row gap-5 items-center'>
          <Input
            placeholder={'Filter Table'}
            containerStyle='mb-0 pb-0 relative flex-initial w-96'
            inputStyle={`mb-0 border-0 focus:outline-none`}
            icon={'/assets/shared/search.svg'}
            showValue={true}
            inputValue={paginationState?.filters}
            inputUniqueName={'search'}
            onChangeInput={onChangeSearchValue}
            hideInputSpace={true}
            dataQa={'filter-table'}
          />
          <Button
            label={'Create Credit Note'}
            dataQa={'create-credit-note'}
            onClickButton={selectedRows && selectedRows?.length > 0 && (!accessControl || pageAccess?.actions?.['Create Credit Note']) ? onToggleCreateCreditNoteModal : () => { }}
            className={`${((selectedRows && selectedRows?.length > 0) && (!accessControl || pageAccess?.actions?.['Create Credit Note'])) ? 'btn-main !bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !text-[#202020]' : 'btn-main-disable cursor-not-allowed'}   flex flex-row align-start my-2 !py-3 !px-5`}
          />
          <Button
            label={'Clear All Filters'}
            dataQa={'clear-all-filters'}
            className='btn-primary-text-underline min-w-auto !mt-0 flex flex-row justify-end bg-transparent mr-2 mb-1'
            onClickButton={onClearAllFilters}
          />
        </div>

      </div>
      <div className="my-5 relativ">
        {rows?.length > 0 || rowsLoading ? (
          <ItemCreditsTable
            rows={rows}
            rowsLoading={rowsLoading}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            onClickSelectRow={onClickSelectRow}
            selectedRows={selectedRows}
            sectors={sectors}
            markets={markets}
          />
        ) : (
          <EmptyContainer text={'No items found.'} showImage={true} />
        )}
      </div>
      <CreateCreditNoteModal
        openModal={showActionCheckModal?.show || false}
        text={showActionCheckModal?.text || ''}
        loading={loadingAction}
        handleCloseModal={onToggleCreateCreditNoteModal}
        onApproveAction={onApproveActionCheck}
      />
      {showSummaryModal && <CreditSummaryModal
        isOpen={showSummaryModal}
        onRequestClose={() => setShowSummaryModal(false)}
        partners={partners || []} />}
      {showToast?.message &&
        <Toast
          type={showToast?.type}
          text={showToast?.message}
          onHandleCloseToast={() => setShowToast(null)}
        />
      }
    </div>
  )
}

export default ItemCreditsTab