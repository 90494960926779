import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToFetchPartnerCatalogs } from '../../../../store/brokers/admin/partners/partnersSlice';
import {
    tryToAddFieldSettings,
    tryToDeleteFieldSettings,
    tryToEditFieldSettings,
    tryToFetchSingleFieldSetting,
    tryToFindOneFieldSetting
} from '../../../../store/brokers/admin/broker-fields-settings/fieldSettingsSlice';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';


interface IManageFieldsSettingsModal {
    openModal: boolean;
    partners: any;
    itemId?: string;
    onCloseModal: () => void;
    onSaveFieldChanges: (type: string, message: string) => void;
}

const ManageFieldsSettingsModal = ({
    openModal,
    partners,
    itemId,
    onCloseModal,
    onSaveFieldChanges
}: IManageFieldsSettingsModal) => {
    const dispatch = useAppDispatch();
    const [fields, setFields] = useState<any>();
    const [brokerCategories, setBrokerCategories] = useState<any>();
    const [selectedPartner, setSelectedPartner] = useState<any>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [selectedFields, setSelectedFields] = useState<any>();
    const [fieldsData, setFieldsData] = useState<any>();
    const [generalError, setGeneralError] = useState<any>();
    const [detailsSettings, setDetailsSettings] = useState<any>();
    const [actionLoading, setActionLoading] = useState<boolean>(false);
    const state = useAppSelector((state) => state.fieldSettings);

    useEffect(() => {
        if (itemId) {
            dispatch(tryToFetchSingleFieldSetting(itemId)).unwrap();
        }
    }, [itemId])

    const onGettingFieldDetailsIfExist = async () => {
        try {
            await dispatch(tryToFindOneFieldSetting({ brokerId: selectedPartner?.value, templateId: selectedCategory?.value })).unwrap();
        } catch (err) {
            // error here
            setDetailsSettings(undefined)
        }
    }

    useEffect(() => {
        if (selectedCategory && selectedPartner) {
            onGettingFieldDetailsIfExist()
        }
    }, [selectedPartner, selectedCategory])

    useEffect(() => {
        if (state?.fieldSettingsDetails && (itemId || (selectedPartner && selectedCategory))) {
            setDetailsSettings(state?.fieldSettingsDetails)
        } else {
            setDetailsSettings(undefined)
        }
    }, [state?.fieldSettingsDetails])

    useEffect(() => {
        if (detailsSettings?.fieldMapInfo && fields) {
            setFieldsData(detailsSettings?.fieldMapInfo)
            const formatFields = detailsSettings?.fieldMapInfo && Object.entries(detailsSettings?.fieldMapInfo)
                .map(([key, value]) => ({ name: key, label: key, value: key }));
            setSelectedFields(formatFields)
        }
    }, [detailsSettings, fields])

    useEffect(() => {
        if (detailsSettings?.brokerInfo && partners && itemId) {
            const findPartner = (partners && partners?.length > 0) && partners?.find((item: any) => item?.value === detailsSettings?.brokerInfo?.id)
            setSelectedPartner(findPartner)
        }
    }, [detailsSettings, partners])

    useEffect(() => {
        if (detailsSettings?.assetTemplateInfo && brokerCategories && itemId) {
            const findTemplate = brokerCategories && brokerCategories?.length > 0 && brokerCategories?.find((item: any) => item?.value === detailsSettings?.assetTemplateInfo?.id)
            setSelectedCategory(findTemplate)
        }
    }, [detailsSettings, brokerCategories])

    const onGetPartnerSectors = async () => {
        const response: any = await dispatch(tryToFetchPartnerCatalogs(selectedPartner?.username || '')).unwrap()
        if (response?.data) {
            const formatSectors = response?.data?.length > 0 && response?.data?.map((category: any) => ({ ...category, secondName: category?.name, label: category?.displayName, name: category?.displayName, value: category?._id }))
            setBrokerCategories(formatSectors || [])
        }
    }

    const onCloseModalSettings = async () => {
        setFields(undefined)
        setBrokerCategories(undefined)
        setSelectedCategory(undefined)
        setSelectedFields(undefined)
        setSelectedPartner(undefined)
        setGeneralError(undefined)
        setDetailsSettings(undefined)
        setFieldsData(undefined)
        setActionLoading(false)
        onCloseModal()
    }

    useEffect(() => {
        if (selectedPartner) {
            onGetPartnerSectors()
        }
    }, [selectedPartner])

    const onGettingAllAssetFields = async () => {
        if (selectedCategory) {
            try {
                const assetSelected = selectedCategory?.value
                const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(assetSelected)).unwrap()
                if (response?.inventoryFields) {
                    const findListFields = response?.inventoryFields?.length > 0 && response?.inventoryFields?.filter((item: any) => item?.dataType === 'list')
                    const formatFields = findListFields && findListFields?.length > 0 && findListFields?.map((item: any) => ({ ...item, value: item?.name, label: item?.name }))
                    setFields(formatFields)
                }
            } catch (err) {
                // error here
            }
        }
    }

    useEffect(() => {
        if (selectedCategory) {
            onGettingAllAssetFields()
        }
    }, [selectedCategory])

    const onChangeSelectedInput = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'partner': {
                    if (value?.value !== selectedPartner?.value) {
                        setSelectedCategory(undefined)
                        setSelectedFields(undefined)
                        setFieldsData(undefined)
                    }
                    setSelectedPartner(value);
                    break;
                }
                case 'catalog': {
                    if (value?.value !== selectedCategory?.value) {
                        setSelectedFields(undefined)
                        setFieldsData(undefined)
                    }
                    setSelectedCategory(value);
                    break;
                }
                case 'fields': {
                    setSelectedFields(value);
                    break;
                }
            }
        }
    }

    const onChangeMappedField = (e: any, type?: string, fieldValue?: any) => {
        if (type) {
            const formatFieldsData = {
                ...fieldsData || {},
                [type]: {
                    ...fieldsData?.[type] || {},
                    [e?.value || '']: fieldsData?.[type]?.[fieldValue] || undefined
                }
            }
            if (formatFieldsData?.[type]?.[fieldValue] || fieldValue || fieldValue === '') {
                delete formatFieldsData?.[type]?.[fieldValue]
            }
            setFieldsData(formatFieldsData)
        }
    }

    const onWriteInputFieldValue = (e: any, type?: string, fieldValue?: any) => {
        if (type && fieldValue) {
            const formatFieldsData = {
                ...fieldsData || {},
                [type]: {
                    ...fieldsData?.[type] || {},
                    [fieldValue]: e || undefined
                }
            }
            setFieldsData(formatFieldsData)
        }
    }

    const onClickAddValueButton = (type: string) => {
        setFieldsData((prevFieldsData: any) => ({
            ...prevFieldsData || {},
            [type || '']: {
                ...prevFieldsData?.[type] || [],
                '': undefined
            }
        }))
    }

    const onDeleteDetails = async () => {
        try {
            setActionLoading(true)
            await dispatch(tryToDeleteFieldSettings(detailsSettings?._id || itemId)).unwrap();
            onCloseModalSettings()
        } catch (err) {
            setGeneralError(`${err}`)
        }
        setActionLoading(false)
    }

    const onSubmitDetails = async () => {
        if(!selectedPartner || !selectedCategory || !selectedFields){
            setGeneralError('Please fill the data to continue!')
            return;
        }
        try {
            setGeneralError(undefined)
            setActionLoading(true)
            const formatData = {
                brokerId: selectedPartner?.value,
                assetTemplateInfo: {
                    id: selectedCategory?.value,
                    name: selectedCategory?.secondName,
                    displayName: selectedCategory?.displayName
                },
                fieldMapInfo: fieldsData
            }
            if (itemId || detailsSettings) {
                await dispatch(tryToEditFieldSettings({ id: detailsSettings?._id || itemId, data: formatData })).unwrap();
            } else {
                await dispatch(tryToAddFieldSettings(formatData)).unwrap();
            }
            onSaveFieldChanges('success','Successfully saved!')
            onCloseModalSettings();
        } catch (err) {
            setGeneralError(`${err}`)
        }
        setActionLoading(false)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModalSettings}
            showInRight
            contentContainerStyle='min-w-[700px]'>
            <div className={'p-4 min-w-[650px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Broker Field Settings</p>
                </div>
                {generalError && <Error text={generalError} />}
                <div className='bg-[#f8f9fc] rounded p-2 my-5'>
                    <SelectCheckbox
                        name={'Select Broker'}
                        placeholder=' '
                        selectedOption={selectedPartner}
                        options={partners || []}
                        uniqueName='partner'
                        onChangeSelectedOption={onChangeSelectedInput}
                    />
                    <SelectCheckbox
                        name={'Select Catalog'}
                        placeholder=' '
                        selectedOption={selectedCategory}
                        options={brokerCategories || []}
                        uniqueName='catalog'
                        onChangeSelectedOption={onChangeSelectedInput}
                    />
                    <SelectCheckbox
                        name={'Select Catalog Fields'}
                        placeholder=' '
                        selectedOption={selectedFields}
                        options={fields || []}
                        multiple={true}
                        uniqueName='fields'
                        onChangeSelectedOption={onChangeSelectedInput}
                    />
                    {(selectedFields && selectedFields?.length > 0) &&
                        <div className='my-3'>
                            {selectedFields?.map((item: any, index: number) => {
                                const findFieldDetails = fields && fields?.length > 0 && fields?.find((details: any) => details?.name === item?.value)
                                const findFieldMappings = fieldsData?.[item?.value] && Object.entries(fieldsData?.[item?.value])
                                    .map(([key, value]) => ({ key, value }));
                                return (
                                    <div key={index}>
                                        <p className='font-bold mb-3'>{item?.name}</p>
                                        {((findFieldMappings && findFieldMappings?.length > 0) ? findFieldMappings : [{}])?.map((field: any, idx: number) => {
                                            const uniqueValues = fieldsData?.[item?.value] ? findFieldDetails?.values?.filter((value: any) => !(value in fieldsData?.[item?.value])) : findFieldDetails?.values;
                                            const formatValues = (uniqueValues && uniqueValues?.length > 0) && uniqueValues?.map((value: any) => ({ value: value, label: value }))
                                            return (
                                                <div key={idx}>
                                                    <div className='flex flex-row justify-between items-center'>
                                                        <SelectCheckbox
                                                            name={'Field Value'}
                                                            placeholder=' '
                                                            containerStyle='max-w-[43%] w-[43%]'
                                                            selectedOption={field?.key && field?.key !== 'newValue' ? { value: field?.key, label: field?.key } : {}}
                                                            options={formatValues || []}
                                                            index={field?.key}
                                                            uniqueName={item?.value}
                                                            onChangeSelectedOption={onChangeMappedField}
                                                        />
                                                        <Input
                                                            label='Mapped Value'
                                                            placeholder=' '
                                                            containerStyle='max-w-[43%] w-[43%]'
                                                            inputValue={fieldsData?.[item?.value]?.[field?.key]}
                                                            showValue={true}
                                                            inputUniqueName={item?.value}
                                                            index={field?.key}
                                                            onChangeInput={onWriteInputFieldValue}
                                                        />
                                                    </div>
                                                    <div className='flex flex-row justify-end'>
                                                        {(findFieldMappings?.length > 0 ? ((findFieldMappings?.length - 1) === idx) : idx === 0) && ((findFieldMappings?.length || 0) <= uniqueValues?.length) &&
                                                            <Button
                                                                label={'Add'}
                                                                className='btn-main items-end'
                                                                onClickButton={() => onClickAddValueButton(item?.value)}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
                <div className='my-4 flex flex-row justify-center'>
                    <Button
                        label={(detailsSettings || itemId) ? 'Edit Changes' : 'Submit Settings'}
                        className={`${!actionLoading ? 'btn-primary' : 'btn-main-disable'} !rounded mr-3 !shadow-none`}
                        onClickButton={() => !actionLoading && onSubmitDetails()}
                    />
                    {(detailsSettings || itemId) &&
                        <Button
                            label={'Delete Settings'}
                            className={`${!actionLoading ? 'btn-error' : 'btn-main-disable'} !rounded`}
                            onClickButton={() => !actionLoading && onDeleteDetails()}
                        />
                    }
                </div>
            </div>
        </Modal >
    )
}

export default ManageFieldsSettingsModal;
