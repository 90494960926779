import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IQuestion } from '../../../../interfaces/questions/IQuestions';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';
import Switch from '../../../../shared/switch';

const tableColumns = ['Question', 'Category', 'Question Type', 'Field', 'Mapped Field', 'Answers Type', 'Answers', 'Dependencies', 'Services', 'Markets', 'Enabled', 'Show In Review', 'Actions']
const tableColumnsOrder = ['Question', 'Order', 'Category', 'Question Type', 'Field', 'Mapped Field', 'Answers Type', 'Answers', 'Dependencies', 'Services', 'Markets', 'Enabled', 'Show In Review', 'Service Details', 'Actions']

interface IQuestionsTable {
    rows?: Array<any>;
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    loading: boolean;
    selectedService?: any;
    sortedOrder?: string;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onViewQuestion: (id: string) => void;
    onEditQuestion: (id: string) => void;
    onDeleteQuestion: (id: string) => void;
    onChangeSortOrder: (name?: string) => void;
    onClickSeeMoreDetails: (type: string, data: any) => void;
    onEnableDisableQuestion: (id: string, enabled: boolean) => void;
    onEnableDisableReviewQuestion: (id: string, enabled: boolean) => void;
    onEditServiceDetails: (id: string, service: string) => void;
}

const QuestionsTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    loading,
    selectedService,
    sortedOrder,
    setPaginationState,
    onViewQuestion,
    onEditQuestion,
    onDeleteQuestion,
    onChangeSortOrder,
    onClickSeeMoreDetails,
    onEnableDisableQuestion,
    onEnableDisableReviewQuestion,
    onEditServiceDetails
}: IQuestionsTable) => {

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + (word.slice(1) || '')?.toLowerCase();
    }

    const capitalizeFirstLetterOfEachWord = (word: string) => {
        let formattedWord = word?.replace(/_/g, ' ');
        const words = formattedWord?.split(/\s+/);
        formattedWord = words?.map(word => {
            if (!word.trim()) return '';
            return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
        })?.join(' ');
        return formattedWord;
    }

    return (
        <Table
            columns={selectedService ? tableColumnsOrder : tableColumns}
            paginationData={paginationData}
            sortColumns={['Order']}
            sortStatus={{ 'Order': sortedOrder }}
            onClickSortColumn={onChangeSortOrder}
            setPaginationState={setPaginationState}
        >
            {loading ?
                <>
                    <>{[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                            </tr>
                        )
                    })}
                    </>
                </>
                : <>
                    {(rows && rows?.length > 0) && rows?.map((row: IQuestion, idx: number) => {
                        const findOrder = selectedService && row?.services && row?.services?.length > 0 && row?.services?.find((item: any) => item?.id === selectedService?._id)
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4 !font-bold min-w-[200px]'>{row?.question}</td>
                                {selectedService && <td className='px-6 py-4'>{findOrder?.order || ''}</td>}
                                <td className='px-6 py-4'>{row?.category?.displayName || ''}</td>
                                <td className={`px-6 py-4 ${row?.type === 'PRIMARY' && '!font-bold'}`}>{capitalizeFirstLetter(row?.type || '')}</td>
                                <td className='px-6 py-4'>{row?.field || ''}</td>
                                <td className='px-6 py-4'>{row?.fieldMapped ? row?.fieldMapped : 'Not selected'}</td>
                                <td className='px-6 py-4 min-w-[100px]'>{row?.type === 'SECONDARY' ? capitalizeFirstLetterOfEachWord(row?.answers?.type) : 'Single Choice From Catalog'}</td>
                                <td className='px-6 py-4'>
                                    {row?.type === 'SECONDARY' ?
                                        <div
                                            className='cursor-pointer hover:text-black !font-bold'
                                            onClick={() => (row?.answers?.options && row?.answers?.options?.length > 0) && onClickSeeMoreDetails('answers', row?.answers?.options)}>
                                            {`${row?.answers?.options?.length || 0} options`}
                                        </div>
                                        : 'Dynamic from catalog'}
                                </td>
                                <td className='pl-6 pr-2 py-4 min-w-[150px]'>
                                    {row?.type === 'SECONDARY' ?
                                        <div
                                            onClick={() => (row?.dependencies && row?.dependencies?.length > 0) && onClickSeeMoreDetails('dependencies', row?.dependencies)}
                                            className='cursor-pointer hover:text-black font-semibold'>
                                            {`${row?.dependencies?.length || 0} dependencies`}
                                        </div>
                                        : 'No dependencies'}
                                </td>
                                <td className='pl-6 pr-2 py-4 min-w-[120px]'>
                                    {row?.services && row?.services.length > 0 ? row?.services?.map((item, index) => `${index ? ', ' : ''}${item?.name}`)
                                        : 'Not Assigned'
                                    }
                                </td>
                                <td className='px-6 py-4'>
                                    {(row?.markets && row?.markets.length > 0 && row?.markets?.[0]) ? row?.markets?.map((item, index) => `${index ? ', ' : ''}${item?.name || item?.label}`)
                                        : <p>All Markets</p>
                                    }
                                </td>
                                <td className='px-6 py-4'>
                                    <Switch
                                        checked={!!row?.enabled}
                                        onToggleSwitch={() => ((!accessControl || actions?.['Question Enable Disable']) && row?._id) && onEnableDisableQuestion(row?._id || '', row?.enabled || false)}
                                        dataQa={'enabled-question'}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <Switch
                                        checked={!!row?.showInReview}
                                        onToggleSwitch={() => ((!accessControl || actions?.['Question Enable Review']) && row?._id) && onEnableDisableReviewQuestion(row?._id || '', row?.showInReview || false)}
                                        dataQa={'enabled-review'}
                                    />
                                </td>
                                {selectedService &&
                                    <td className='pl-6 pr-2 py-1 min-w-[150px]'>
                                        <Button
                                            label='Edit Service'
                                            onClickButton={() => ((!accessControl || actions?.['Edit Service']) && row?._id) && onEditServiceDetails(row?._id, selectedService?.value)}
                                            className={(!accessControl || actions?.['Edit Service']) ? 'btn-main mr-2' : 'btn-main-disable mr-2'}
                                        />
                                    </td>
                                }
                                <td className='px-6 py-1 min-w-[270px]'>
                                    <div className='flex flex-row items-center'>
                                        <Button
                                            label='View'
                                            data-qa={'view-question'}
                                            onClickButton={() => ((!accessControl || actions?.['See Question Details']) && row?._id) && onViewQuestion(row?._id)}
                                            className={(!accessControl || actions?.['See Question Details']) ? 'btn-main mr-2' : 'btn-main-disable mr-2'}
                                        />
                                        <Button
                                            label='Edit'
                                            data-qa={'edit-question'}
                                            onClickButton={() => ((!accessControl || actions?.['Edit Question']) && row?._id) && onEditQuestion(row?._id)}
                                            className={(!accessControl || actions?.['Edit Question']) ? 'btn-main mr-2' : 'btn-main-disable mr-2'}
                                        />
                                        <Button
                                            label='Delete'
                                            data-qa={'delete-question'}
                                            onClickButton={() => ((!accessControl || actions?.['Delete Question']) && row?._id) && onDeleteQuestion(row?._id)}
                                            className={(!accessControl || actions?.['Delete Question']) ? 'btn-main' : 'btn-main-disable'}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default QuestionsTable;