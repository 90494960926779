import React from 'react';
import moment from 'moment';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { onFormatOrderStatus } from '../../../../utils/constants/format-order-statuses';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { ITicketResponse } from '../../../../interfaces/customer-care/ITicket';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['Order ID', 'Updated Date', 'Item Name', 'Purchase Price', 'Subject', 'Tracking Number', 'Order Status', 'Actions']

interface ITicketsTable {
    rows: ITicketResponse[];
    paginationData?: IPage;
    markets?: IAllMarkets[];
    accessSeeDetails?: any;
    ticketsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeOrderDetails: (orderId: string, itemId: string) => void;
}

const TicketsTable = ({
    rows,
    markets,
    paginationData,
    ticketsLoading,
    accessSeeDetails,
    onSeeOrderDetails,
    setPaginationState,
}: ITicketsTable) => {

    const onFindCurrency = (country?: string) => {
        switch ((country || '').toLowerCase()) {
            case 'uk':
            case 'gb':
            case 'gbr': {
                const findMarket = markets && markets?.length > 0 && markets?.find((market: IAllMarkets) => (market?.name || '').toLowerCase() === 'uk')
                return findMarket && findMarket?.currency?.name;
            }
            default: {
                const marketData = markets && markets?.length > 0 && markets?.find((market: IAllMarkets) => (market?.name || '').toLowerCase() === (country || '').toLowerCase())
                return marketData ? marketData?.currency?.name : 'gbp';
            }
        }
    }

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
        >
            {ticketsLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: ITicketResponse, idx: number) => {
                        const date = moment(row?.ticket?.updatedAt).fromNow()
                        const formatOrderStatus = onFormatOrderStatus(row?.ticket?.orderStatus || '')
                        const orderStatusItem = formatOrderStatus?.color && <div className={`${formatOrderStatus?.color} flex flex-row justify-center text-center px-1 min-w-[150px] ${formatOrderStatus?.text || 'text-black'} text-sm py-1 font-medium rounded-2xl`}>{formatOrderStatus?.name}</div>
                        const findCurrencySymbol = onFindCurrency(row?.ticket?.country || '')
                        return (
                            <tr key={idx} title={row?.ticket?.readByBroker === false ? 'Unread' : 'Read'} className={`border-b hover:bg-gray-50 ${row?.ticket?.readByBroker === false ? 'font-medium text-[#000000] bg-[#dffffa10]' : 'font-normal bg-white'}`}>
                                <td className='px-6 py-4'>{row?.ticket?.orderId}</td>
                                <td className='px-6 py-4'>{date}</td>
                                <td className='px-6 py-4'>{row?.item?.name}</td>
                                <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[(findCurrencySymbol || 'gbp').toLowerCase()]}{(row?.item?.revaluatedPrice || row?.item?.price || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{row?.ticket?.subject}</td>
                                <td className='px-6 py-4'>
                                    <a
                                        data-qa={'tracking-number'}
                                        rel='noreferrer'
                                        href={`https://parcelsapp.com/en/tracking/${row?.ticket?.trackingNumber}`}
                                        target='_blank'
                                        className='text-primary-default'
                                    >
                                        {row?.ticket?.trackingNumber}
                                    </a>
                                </td>
                                <td className='px-6 py-4'>{orderStatusItem}</td>
                                <td className='px-6 py-4'>
                                    <div data-qa={'view-details'} className='ml-2' onClick={() => (accessSeeDetails && row?.ticket?.orderId && row?.ticket?.itemId) && onSeeOrderDetails(row?.ticket?.orderId, row?.ticket?.itemId)}>
                                        <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${accessSeeDetails ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default TicketsTable;