import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IPageAccess } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import WhiteContainer from '../../../../shared/white-container';
import Button from '../../../../shared/button';


interface IReceivedItemDetails {
    receivedDate?: string;
    pageAccess?: IPageAccess;
    accessControl?: IAccessControl;
    onClickOrderDetailsAction: () => void;
}

const ReceivedItemDetails = ({
    receivedDate,
    pageAccess,
    accessControl,
    onClickOrderDetailsAction
}: IReceivedItemDetails) => {
    const [timeCalc, setTimeCalc] = useState<string>();

    const getDifference = () => {
        const startDate = moment(receivedDate)
        const currentDate = moment();
        let diffCalculated = ''
        const diffInSeconds = currentDate.diff(startDate, 'seconds');
        if (diffInSeconds < 60) {
            diffCalculated = `${diffInSeconds}s`;
        } else {
            const diffInMinutes = currentDate.diff(startDate, 'minutes');
            if (diffInMinutes < 60) {
                diffCalculated = `${diffInMinutes}m`;
            } else {
                const diffInHours = currentDate.diff(startDate, 'hours');
                if (diffInHours < 24) {
                    diffCalculated = `${diffInHours}h`;
                } else {
                    const diffInDays = currentDate.diff(startDate, 'days');
                    if (diffInDays < 7) {
                        diffCalculated = `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'}`;
                    } else {
                        const diffInWeeks = Math.floor(diffInDays / 7);
                        diffCalculated = `${diffInWeeks} ${diffInWeeks === 1 ? 'week' : 'weeks'}`;
                    }
                }
            }
        }
        setTimeCalc(diffCalculated || '')
    }

    useEffect(() => {
        getDifference()
    }, [receivedDate])

    return (
        <WhiteContainer containerStyle='!p-0 !rounded'>
            <div className='bg-gradient-to-br from-[#FCEE21] to-[#F7931E] py-10 px-3 flex flex-col items-center !rounded'>
                <p className='text-[18px] font-bold text-center'>Start Review Process?</p>
                <p className='text-center mt-2 mb-4'>This item has been received <span className='font-semibold'>{timeCalc} ago</span></p>
                <Button
                    label={'Seal the deal'}
                    className={`${(!accessControl || (pageAccess?.actions?.Review) || (pageAccess?.actions?.Revaluate)) ? 'btn-black-rounded' : 'btn-black-rounded opacity-70'} !text-[#fbdd21] min-w-[180px]`}
                    onClickButton={() => (!accessControl || (pageAccess?.actions?.Review) || (pageAccess?.actions?.Revaluate)) && onClickOrderDetailsAction()}
                />
            </div>
        </WhiteContainer>
    );
};

export default ReceivedItemDetails;
