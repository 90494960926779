import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToDeleteWarehouseField } from '../../../../store/brokers/admin/warehouses/warehousesSlice';
import { IToast } from '../../../../interfaces/components/IToast';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import WarehouseFieldsTable from '../tables/WarehouseFieldsTable';
import WarehouseFieldModal from '../modals/WarehouseFieldModal';
import EmptyContainer from '../../../../shared/empty-container';
import DeleteModal from '../../../../shared/delete-modal';
import Loading from '../../../../shared/Loading';
import Button from '../../../../shared/button';
import Toast from '../../../../shared/toast';


interface ISetupFields {
    warehouseData?: IWarehouse
}

const SetupFields = ({
    warehouseData
}: ISetupFields) => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [showToast, setShowToast] = useState<IToast | null>()
    const [showFieldModal, setShowFieldModal] = useState<boolean>(false)
    const [fieldSelected, setFieldSelected] = useState<string>()
    const [showDeleteFieldModal, setShowDeleteFieldModal] = useState<boolean>(false)
    const warehouseIsLoading = useAppSelector((state) => state.warehouses?.warehouseIsLoading)

    const onToggleFieldModal = (id?: string) => {
        if (id) {
            setFieldSelected(id)
        } else {
            setFieldSelected(undefined)
        }
        setShowFieldModal(!showFieldModal)
    }

    const onSaveFieldChanges = async (type: string, message: string) => {
        setShowToast({ type, message });
    }

    const onRequestToDeleteField = (id: string) => {
        setShowDeleteFieldModal(true)
        setFieldSelected(id)
    }

    const onCloseDeleteFieldModal = () => {
        setFieldSelected(undefined)
        setShowDeleteFieldModal(false)
    }

    const onDeleteWarehouseField = async () => {
        try {
            if (fieldSelected) {
                const data = {
                    warehouseId: id,
                    fieldId: fieldSelected
                }
                await dispatch(tryToDeleteWarehouseField(data)).unwrap();
                setShowToast({ type: 'error', message: 'Field deleted successfully' });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        onCloseDeleteFieldModal()
    }

    return (
        <div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New Field'}
                    dataQa={'add-new-field'}
                    className={'btn-main'}
                    onClickButton={onToggleFieldModal}
                />
            </div>
            <div className='my-5 relative'>
                {warehouseIsLoading ?
                    <div className='my-3 flex flex-row justify-center items-center'>
                        <Loading />
                    </div>
                    :
                    <>
                        {(warehouseData?.locations && warehouseData?.locations?.length > 0) ?
                            <WarehouseFieldsTable
                                rows={warehouseData?.locations || []}
                                onEditField={onToggleFieldModal}
                                warehouseId={id || ''}
                                onDeleteField={onRequestToDeleteField}
                            />
                            : <EmptyContainer text={'No field added yet'} showImage={true} />}
                    </>
                }
            </div>
            <DeleteModal
                openDeleteModal={showDeleteFieldModal}
                handleCloseDeleteModal={onCloseDeleteFieldModal}
                onApproveDeletion={onDeleteWarehouseField}
            />
            {showFieldModal &&
                <WarehouseFieldModal
                    openFieldsModal={showFieldModal}
                    handleCloseFieldsModal={onToggleFieldModal}
                    warehouseId={id || ''}
                    onSaveChanges={onSaveFieldChanges}
                    fieldItemId={fieldSelected}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div >
    )
}

export default SetupFields;