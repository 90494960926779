import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { AxiosError } from 'axios';
import { PricingEffectsService } from './pricingEffectsApi';
import { IPricingEffectsListState } from '../../../../interfaces/pricing-effects/IPricingEffectsList';
import { PaginatedPricingEffects } from '../../../../interfaces/pricing-effects/paginatedStore.type';
import { IPricingEffects } from '../../../../interfaces/pricing-effects/IPricingEffects';

const initialState: IPricingEffectsListState = {
    effectIsLoading: false,
    effectsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchPricingEffects = createAsyncThunk<PaginatedPricingEffects, IPaginationPayload>(
    'pricing-effects/tryToFetchPricingEffects',
    async ({ pageNumber, pageSize, data }) => {
        const result = await PricingEffectsService.tryToFetchPricingEffects(
            pageNumber,
            pageSize,
            data,
        );
        return result?.data;
    });

export const tryToFetchSinglePricingEffect = createAsyncThunk<ApiResponse<IPricingEffects>, string>(
    'pricing-effects/tryToFetchSinglePricingEffect',
    async (id: string) => {
        const result = await PricingEffectsService.tryToFetchSinglePricingEffect(id);
        return result?.data;
    },
);

export const tryToDownloadPricingEffects = createAsyncThunk<ApiResponse<IPricingEffects>, any>(
    'pricing-effects/tryToDownloadPricingEffects',
    async () => {
        try {
            const result = await PricingEffectsService.tryToDownloadPricingEffects();
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return err?.response?.data?.message;
        }
    },
);


export const tryToEditPricingEffect = createAsyncThunk<ApiResponse<IPricingEffects>, IPricingEffects>(
    'pricing-effects/tryToEditPricingEffect',
    async (data: IPricingEffects, { rejectWithValue }) => {
        try {
            const result = await PricingEffectsService.tryToEditPricingEffect(
                data._id || '',
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToAddPricingEffect = createAsyncThunk<ApiResponse<IPricingEffects>, IPricingEffects>(
    'pricing-effects/tryToAddPricingEffect',
    async (data: IPricingEffects, { rejectWithValue }) => {
        try {
            const result = await PricingEffectsService.tryToAddPricingEffect(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });


export const tryToDeletePricingEffect = createAsyncThunk<ApiResponse<IPricingEffects>, string>(
    'pricing-effects/tryToDeletePricingEffect',
    async (id, { rejectWithValue }) => {
        try {
            const results = await PricingEffectsService.tryToDeletePricingEffect(id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const pricingEffectsSlice = createSlice({
    name: 'pricingEffects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchPricingEffects
            .addCase(tryToFetchPricingEffects.pending, (state) => {
                state.effectsAreLoading = true;
            })
            .addCase(tryToFetchPricingEffects.rejected, (state) => {
                state.effectsAreLoading = false;
            })
            .addCase(tryToFetchPricingEffects.fulfilled, (state, action) => {
                state.effectsAreLoading = false;
                state.pricingEffects = action.payload;
            })

            // tryToAddPricingEffect
            .addCase(tryToAddPricingEffect.pending, (state) => {
                state.effectIsLoading = true;
            })
            .addCase(tryToAddPricingEffect.rejected, (state) => {
                state.effectIsLoading = false;
            })
            .addCase(tryToAddPricingEffect.fulfilled, (state, action) => {
                state.effectIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.pricingEffects?.data)
                    state.pricingEffects.data.elements = [
                        data,
                        ...state.pricingEffects?.data.elements || [],
                    ];
            })

            // tryToDeletePricingEffect
            .addCase(tryToDeletePricingEffect.pending, (state) => {
                state.effectIsLoading = true;
            })
            .addCase(tryToDeletePricingEffect.rejected, (state) => {
                state.effectIsLoading = false;
            })
            .addCase(tryToDeletePricingEffect.fulfilled, (state, action) => {
                state.effectIsLoading = false;
                state.requestStatus = 'success';
                if (state.pricingEffects?.data.elements)
                    state.pricingEffects.data.elements =
                        { ...state }.pricingEffects?.data.elements.filter(
                            (pricingEffect) =>
                                pricingEffect?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToEditPricingEffect
            .addCase(tryToEditPricingEffect.pending, (state) => {
                state.effectIsLoading = true;
            })
            .addCase(tryToEditPricingEffect.rejected, (state) => {
                state.effectIsLoading = false;
            })
            .addCase(tryToEditPricingEffect.fulfilled, (state, action) => {
                state.effectIsLoading = false;
                if (state.pricingEffects?.data?.elements)
                    state.pricingEffects.data.elements =
                        { ...state }.pricingEffects?.data.elements.map((pricingEffect) => {
                            if (pricingEffect?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return pricingEffect;
                            }
                        }) || [];
                state.pricingEffect = action.payload.data;
            })

            // tryToFetchSinglePricingEffect
            .addCase(tryToFetchSinglePricingEffect.pending, (state) => {
                state.effectIsLoading = true;
            })
            .addCase(tryToFetchSinglePricingEffect.rejected, (state) => {
                state.effectIsLoading = false;
            })
            .addCase(tryToFetchSinglePricingEffect.fulfilled, (state, action) => {
                state.effectIsLoading = false;
                state.pricingEffect = action.payload?.data;
            });
    },
});

export default pricingEffectsSlice.reducer;
