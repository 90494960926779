import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { IResetPassword } from '../../../interfaces/auth/IResetPassword';
import { useResetPasswordMutation } from '../../../store/user/userDomApi';
import Button from '../../../shared/button';
import Error from '../../../shared/error';
import Input from '../../../shared/input';
import SuccessResetModal from './modals/SuccessResetModal';


type FormValues = {
    username: string;
    newPassword: string;
    confirmNewPassword: string;
    confirmationCode: string;
};

const ResetPassword = () => {
    const [generalError, setGeneralError] = useState<string>()
    const [resetPasswordMutation] = useResetPasswordMutation()
    const [successModal, setSuccessModal] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch
    } = useForm<FormValues>({});

    const onSubmit = async (data: FormValues) => {
        try {
            const payload: IResetPassword = {
                username: data?.username,
                password: data?.newPassword,
                confirmationCode: data?.confirmationCode
            }
            await resetPasswordMutation(payload).unwrap()
            setGeneralError(undefined)
            setSuccessModal(true)
            reset()
        } catch (err: any) {
            setGeneralError(`${err?.data?.message || err?.response || err}`)
        }
    }

    return (
        <div className='md:min-h-screen px-10 py-12'>
            <NavLink to='/apply'>
                {/* Logo here */}
                {/* <img className='max-w-[290px]' alt='for the earth' src='/logo/logo.svg' /> */}
            </NavLink>
            <div className='max-w-[330px] m-auto mt-[9%]'>
                <h1 className='font-dm_sansbold text-4xl text-center mb-5'>Please write your new password</h1>
                {generalError && <Error text={generalError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        placeholder='Username'
                        dataQa='username-reset'
                        register={register('username', {
                            required: {
                                message: 'Username is required',
                                value: true,
                            },
                            validate: (value: string) => !!value.trim() || 'Username is required'
                        })}
                        error={errors.username?.message}
                    />
                    <Input
                        placeholder='New Password'
                        type={'password'}
                        dataQa='new-password-reset'
                        register={register('newPassword', {
                            required: {
                                message: 'Password is required',
                                value: true,
                            },
                            minLength: {
                                value: 8,
                                message: 'Minimum number of characters is 8'
                            },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                message: 'Password should be at least 8 characters long, and must contain at least 1 number, 1 lower case, 1 upper case and 1 special character(@$!%*?&)'
                            },
                            validate: (value: string) => !!value.trim() || 'New Password is required and min length is 8'
                        },
                        )}
                        error={errors.newPassword?.message}
                    />
                    <Input
                        placeholder='Repeat Password'
                        dataQa='confirm-new-password-reset'
                        type={'password'}
                        register={register('confirmNewPassword', {
                            required: {
                                message: 'Repeat password is required',
                                value: true,
                            },
                            validate: (val: string) => {
                                if (watch('newPassword') !== val) {
                                    return 'Repeat password do not match with new password';
                                }
                            }
                        })}
                        error={errors.confirmNewPassword?.message}
                    />
                    <Input
                        placeholder='Confirmation Code'
                        dataQa='confirmation-code-reset'
                        register={register('confirmationCode', {
                            required: {
                                message: 'Confirmation Code is required',
                                value: true,
                            },
                            validate: (value: string) => !!value.trim() || 'Confirmation Code is required'
                        })}
                        error={errors.confirmationCode?.message}
                    />
                    <Button
                        label={'Reset Password'}
                        dataQa={'submit-reset'}
                        className={'btn-main min-w-full my-2 !py-2'}
                        type={'submit'}
                    />
                </form>
                <SuccessResetModal
                    openModal={successModal}
                    handleCloseModal={() => setSuccessModal(false)}
                />
            </div>

        </div>
    )
}

export default ResetPassword;