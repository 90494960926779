import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import { ICSAgents } from '../../../../interfaces/cs-agents/cs-agents';
import { PaginatedCSAgents } from '../../../../interfaces/cs-agents/paginatedStore.type';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';


const tryToCreateCsUser = (data: ICSAgents): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/cs-users`, data);
};

const tryToFindAllCsUsers = (
    pageNumber: number,
    pageSize: number,
    filters?: { search?: any },
): Promise<AxiosResponse<PaginatedCSAgents>> => {
    const url = `${USERS_API_URL}/cs-users?page=${pageNumber}&size=${pageSize}`;
    return brokersAxios.put<PaginatedCSAgents>(url, { filters })
}

const tryToFetchSingleCsUser = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/cs-users/${id}`)
}

const tryToEnableCsUsers = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/cs-users/${data.id}/update/enable-status`, data.data);
};

const tryToDeleteCsUser = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/cs-users/${id}`);
};


const tryToFindAllAdmins = (
    pageNumber: number,
    pageSize: number,
    filters?: any,
): Promise<AxiosResponse<PaginatedCSAgents>> => {
    const url = `${USERS_API_URL}/admin-users?page=${pageNumber}&size=${pageSize}`;
    return brokersAxios.put<PaginatedCSAgents>(url, { filters })
}

const tryToCreateAdmin = (data: ICSAgents): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/admin-users`, data);
};

const tryToFetchSingleAdmin = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/admin-users/${id}`)
}

const tryToEnableDisableAdmin = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/admin-users/${data.id}/update/enabled-status`, data.data);
};

export const csAgentsService = {
    tryToCreateCsUser,
    tryToFindAllCsUsers,
    tryToFetchSingleCsUser,
    tryToEnableCsUsers,
    tryToDeleteCsUser,
    tryToFindAllAdmins,
    tryToCreateAdmin,
    tryToFetchSingleAdmin,
    tryToEnableDisableAdmin
}