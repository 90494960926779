import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { AxiosError } from 'axios';
import { IPostageFeesListState } from '../../../../interfaces/postage-fees/IPostageFeesList';
import { PostageFeesService } from './postageFeesApi';
import { PaginatedPostageFees } from '../../../../interfaces/postage-fees/paginatedStore.type';
import { IPostageFees } from '../../../../interfaces/postage-fees/IPostageFees';
import { IPostageFeesSchema } from '../../../../interfaces/postage-fees/IPostageFeesSchema';

const initialState: IPostageFeesListState = {
    postagesAreLoading: false,
    postageIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchPostageFees = createAsyncThunk<
    PaginatedPostageFees,
    IPaginationPayload
>('postage-fees/tryToFetchPostageFees', async ({ pageNumber, pageSize, data }) => {
    const result = await PostageFeesService.tryToFetchPostageFees(
        pageNumber,
        pageSize,
        data,
    );
    return result?.data;
});

export const tryToFetchSinglePostageFee = createAsyncThunk<IPostageFees, string>(
    'postage-fees/tryToFetchSinglePostageFee',
    async (id: string) => {
        const result = await PostageFeesService.tryToFetchSinglePostageFee(id);
        return result?.data;
    },
);

export const tryToDownloadPostageFees = createAsyncThunk<IPostageFees, any>(
    'postage-fees/tryToDownloadPostageFees',
    async () => {
        const result = await PostageFeesService.tryToDownloadPostageFees();
        return result?.data;
    },
);

export const tryToImportPostageFees = createAsyncThunk<ApiResponse<any>, any>(
    'postage-fees/tryToImportPostageFees',
    async (data,{ rejectWithValue }) => {
        try {
            const result = await PostageFeesService.tryToImportPostageFees(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditPostageFee = createAsyncThunk<
    ApiResponse<IPostageFeesSchema>,
    IPostageFees
>('postage-fees/tryToEditPostageFee', async (data: IPostageFees, { rejectWithValue }) => {
    try {
        const result = await PostageFeesService.tryToEditPostageFee(
            data._id || '',
            data,
        );
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToAddPostageFee = createAsyncThunk<
    ApiResponse<IPostageFeesSchema>,
    IPostageFees
>('postage-fees/tryToAddPostageFee', async (data: IPostageFees, { rejectWithValue }) => {
    try {
        const result = await PostageFeesService.tryToAddPostageFee(data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToEnablePostageFee = createAsyncThunk<IPostageFees, string>(
    'postage-fees/tryToEnablePostageFee',
    async (id: string) => {
        const result = await PostageFeesService.tryToEnablePostageFee(id);
        return result?.data;
    },
);

export const tryToDeletePostageFee = createAsyncThunk<
    ApiResponse<IPostageFeesSchema>,
    string
>('postage-fees/tryToDeletePostageFee', async (id, { rejectWithValue }) => {
    try {
        const results = await PostageFeesService.tryToDeletePostageFee(id);
        return results.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const postageFeesSlice = createSlice({
    name: 'postageFees',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchPostageFees
            .addCase(tryToFetchPostageFees.pending, (state) => {
                state.postagesAreLoading = true;
            })
            .addCase(tryToFetchPostageFees.rejected, (state) => {
                state.postagesAreLoading = false;
            })
            .addCase(tryToFetchPostageFees.fulfilled, (state, action) => {
                state.postagesAreLoading = false;
                state.postageFees = action.payload;
            })

            // tryToAddPostageFee
            .addCase(tryToAddPostageFee.pending, (state) => {
                state.postageIsLoading = true;
            })
            .addCase(tryToAddPostageFee.rejected, (state) => {
                state.postageIsLoading = false;
            })
            .addCase(tryToAddPostageFee.fulfilled, (state, action) => {
                state.postageIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.postageFees?.data)
                    state.postageFees.data.elements = [
                        ...state.postageFees?.data.elements,
                        data,
                    ];
            })

            // tryToDeletePostageFee
            .addCase(tryToDeletePostageFee.pending, (state) => {
                state.postageIsLoading = true;
            })
            .addCase(tryToDeletePostageFee.rejected, (state) => {
                state.postageIsLoading = false;
            })
            .addCase(tryToDeletePostageFee.fulfilled, (state, action) => {
                state.postageIsLoading = false;
                state.requestStatus = 'success';
                if (state.postageFees?.data.elements)
                    state.postageFees.data.elements =
                        { ...state }.postageFees?.data.elements.filter(
                            (postageFee) =>
                                postageFee?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToEnablePostageFee
            .addCase(tryToEnablePostageFee.pending, (state) => {
                state.postageIsLoading = true;
            })
            .addCase(tryToEnablePostageFee.rejected, (state) => {
                state.postageIsLoading = false;
            })
            .addCase(tryToEnablePostageFee.fulfilled, (state, action) => {
                state.postageIsLoading = false;
                if (state.postageFees?.data.elements)
                    state.postageFees.data.elements =
                        { ...state }.postageFees?.data.elements.map((postageFee) => {
                            if (postageFee?._id === action.meta?.arg) {
                                return {
                                    ...postageFee,
                                    enabled: true
                                };
                            } else {
                                return postageFee;
                            }
                        }) || [];
                state.postageFee = action.payload;
            })

            // tryToEditPostageFee
            .addCase(tryToEditPostageFee.pending, (state) => {
                state.postageIsLoading = true;
            })
            .addCase(tryToEditPostageFee.rejected, (state) => {
                state.postageIsLoading = false;
            })
            .addCase(tryToEditPostageFee.fulfilled, (state, action) => {
                state.postageIsLoading = false;
                if (state.postageFees?.data.elements)
                    state.postageFees.data.elements =
                        { ...state }.postageFees?.data.elements.map((postageFee) => {
                            if (postageFee?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return postageFee;
                            }
                        }) || [];
                state.postageFee = action.payload.data;
            })

            // tryToFetchSinglePostageFee
            .addCase(tryToFetchSinglePostageFee.pending, (state) => {
                state.postageIsLoading = true;
            })
            .addCase(tryToFetchSinglePostageFee.rejected, (state) => {
                state.postageIsLoading = false;
            })
            .addCase(tryToFetchSinglePostageFee.fulfilled, (state, action) => {
                state.postageIsLoading = false;
                state.postageFee = action.payload;
            });
    },
});

export default postageFeesSlice.reducer;
