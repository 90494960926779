import React from 'react';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import Table from '../../../../shared/table';

const tableColumns = ['Display Name', 'Name', 'Min Cashout', 'Max Cashout', 'Min Price', 'Max Price', 'Carbon Impact', 'UI Action', 'Actions']

interface IAssetsTemplatesTable {
    rows: IAssetTemplate[];
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeAssetTemplateDetails: (id: string) => void;
    onDisableAsset: (id: string) => void;
    onEditAsset: (id: string) => void;
}

const AssetsTemplatesTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    rowsLoading,
    setPaginationState,
    onSeeAssetTemplateDetails,
    onDisableAsset,
    onEditAsset
}: IAssetsTemplatesTable) => {
    return (
        <Table
            columns={tableColumns}
            containerStyle={'mt-0'}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IAssetTemplate, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.displayName}</td>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>{row?.minCashout}</td>
                                <td className='px-6 py-4'>{row?.maxCashout}</td>
                                <td className='px-6 py-4'>{row?.minPrice}</td>
                                <td className='px-6 py-4'>{row?.maxPrice}</td>
                                <td className='px-6 py-4'>{row?.carbonImpact}</td>
                                <td className='px-6 py-4'>{row?.uiActions?.displayName || ''}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa={'view-details'} onClick={() => ((!accessControl || actions?.['See Asset Catalog Details']) && row?._id) && onSeeAssetTemplateDetails(row?._id)}>
                                            <img title={'View Details'} data-qa='details-asset-catalog' alt='item details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Asset Catalog Details']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa={'edit-asset-catalog'} onClick={() => ((!accessControl || actions?.['Edit Asset Catalog']) && row?._id) && onEditAsset(row?._id)}>
                                            <img title={'Edit Asset Catalog'} data-qa='edit-asset-catalog' alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Asset Catalog']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa={'delete-asset-catalog'} onClick={() => ((!accessControl || actions?.['Delete Asset Catalog']) && row?._id) && onDisableAsset(row?._id)}>
                                            <img title={'Delete Asset Catalog'} data-qa='delete-asset-catalog' alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Asset Catalog']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default AssetsTemplatesTable;