import React from 'react';
import Modal from '../../../../shared/modal';


interface IMoreDetailsModal {
    openModal: boolean;
    item?: any;
    handleCloseModal: () => void;
}

const MoreDetailsModal = ({
    openModal,
    item,
    handleCloseModal
}: IMoreDetailsModal) => {

    const onCloseModal = () => {
        handleCloseModal();
    }

    const keys = item?.exchangeDefinition?.asset?.descriptor && Object.keys(item?.exchangeDefinition?.asset?.descriptor);
    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            showInRight={true}
            contentContainerStyle={'min-w-[650px]'}>
            <div className={'p-2 min-w-[600px]'}>
                <div className='flex flex-cols items-center mb-5'>
                    <p className='mt-2 mb-4 page-title !text-[18px] font-bold mr-10'>
                        Item Details
                    </p>
                </div>
                <div>
                    <div className='bg-[#f8f9fc] rounded p-3 mb-2'>
                        {item?.category &&
                            <>
                                <div className='grid grid-cols-2 gap-3 my-2'>
                                    <p className=''>Category</p>
                                    <p>{item?.category}</p>
                                </div>
                                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            </>
                        }
                        <div className={'mt-2 overflow-hidden transition-max-h ease-out duration-300 max-h-auto'}>
                            {keys?.length > 0 &&
                                keys?.map((key: any) => {
                                    return (
                                        <>
                                            <div className='grid grid-cols-2 gap-3 my-2'>
                                                <p className=''>{`${key[0].toUpperCase()}${key?.slice(1).toLowerCase()}`}</p>
                                                <p className='break-words'>
                                                    {item?.exchangeDefinition?.asset?.descriptor?.[key] || ''}
                                                </p>
                                            </div>
                                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                        </>
                                    );
                                })
                            }
                            {item?.exchangeDefinition?.questions?.length > 0 &&
                                item?.exchangeDefinition?.questions?.map((item: any) => {
                                    return (
                                        <>
                                            <div className='grid grid-cols-2 gap-3 my-2'>
                                                <p>{item?.question}</p>
                                                <p className='break-words'>
                                                    {(item?.displayValue) ?
                                                        item?.displayValue :
                                                        (item?.value) ?
                                                            item?.value :
                                                            ((item?.answers && item?.answers?.length > 0)
                                                                ? item?.answers?.map((answer: { answer: string }, index: number) => `${index ? ', ' : ''}${answer?.answer}`) : 'No answer')
                                                    }
                                                </p>
                                            </div>
                                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                        </>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </Modal >
    )
}

export default MoreDetailsModal;