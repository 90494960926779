import React from 'react';
import { PURCHASE_ORDER_STATUS } from '../../../../utils/constants/purchase-order-status';
import { VAT_TYPE_ITEMS } from '../../../../utils/constants/vat-type';
import Input from '../../../../shared/input';
import Datepicker from '../../../../shared/datepicker';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IFieldsStep {
    fieldsData?: any;
    formData?: any;
    formErrors?: any;
    onChangeInput: (value: string | null | undefined, type: string) => void;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onChangeDateInput: (value: string | null | undefined, type: string) => void;
}

const FieldsStep = ({
    fieldsData,
    formData,
    formErrors,
    onChangeDateInput,
    onChangeInput,
    onChangeSelectedValue,
}: IFieldsStep) => {
    return (
        <div className='bg-white p-3 border-b-2 border-[#e4e4e4]'>
            <p className='font-bold mb-2'>Purchase Details</p>
            <p className='mb-4'>Please fill details on form below.</p>
            <div className={'grid grid-cols-4 gap-4'}>
                <Datepicker
                    label='Due Date'
                    placeholder=' '
                    containerStyle='mb-0'
                    inputValue={formData?.dueDate}
                    onChangeInput={onChangeDateInput}
                    inputUniqueName={'dueDate'}
                    error={formErrors?.dueDate}
                />
                <SelectCheckbox
                    name='Status'
                    placeholder={formData?.status || ''}
                    disabled={true}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={PURCHASE_ORDER_STATUS || []}
                    selectedOption={fieldsData?.status}
                    uniqueName={''}
                    error={formErrors?.status}
                />
                <Input
                    label={'Order Creator'}
                    containerStyle='mb-0'
                    showValue={true}
                    disabled={true}
                    inputValue={formData?.orderCreator}
                    inputUniqueName={'orderCreator'}
                    error={formErrors?.orderCreator}
                />
                <SelectCheckbox
                    name='Stock VAT'
                    placeholder={formData?.stockVat || ''}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={VAT_TYPE_ITEMS || []}
                    selectedOption={fieldsData?.stockVat}
                    uniqueName={'stockVat'}
                    error={formErrors?.stockVat}
                />
                <Input
                    label={'Warranty (Days)'}
                    containerStyle='mb-0'
                    showValue={true}
                    type={'number'}
                    inputValue={formData?.warranty}
                    onChangeInput={onChangeInput}
                    inputUniqueName={'warranty'}
                    error={formErrors?.warranty}
                />
            </div>
        </div>
    )
}
export default FieldsStep;