import React from 'react';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['Name', 'Colors', 'Storages', 'Actions']

interface IAssetsExtendedTable {
    rows?: Array<any>;
    rowsLoading: boolean;
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onDisableAsset: (id: string) => void;
    onEditAsset: (id: string) => void;
}

const AssetsExtendedTable = ({
    rows,
    rowsLoading,
    paginationData,
    accessControl,
    actions,
    setPaginationState,
    onDisableAsset,
    onEditAsset
}: IAssetsExtendedTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                            </tr>
                        )
                    })}
                </>
                : <>
                    {(rows && rows?.length > 0) && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>
                                    <ol className='list-decimal'>
                                        {row?.colors && row?.colors?.length > 0 && row?.colors?.map((item: any, index: number) => {
                                            return (
                                                <li key={index}>{item?.name}</li>
                                            )
                                        })}
                                    </ol>
                                </td>
                                <td className='px-6 py-4'>
                                    {row?.colors && row?.colors?.length > 0 && row?.colors?.map((item: any, index: number) => {
                                        return (
                                            <ol className='list-decimal' key={index}>
                                                <p>{index + 1}.{' '}
                                                    {item?.storages && item?.storages?.length > 0 && item?.storages?.map((storage: string, id: number) => {
                                                        return id > 0 ? `, ${storage}` : storage || ''
                                                    })}
                                                </p>
                                            </ol>
                                        )
                                    })}
                                </td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div onClick={() => ((!accessControl || actions?.['Edit Asset']) && row?._id) && onEditAsset(row?._id)}>
                                            <img title={'Edit Asset'} alt='edit item' data-qa='extended-asset-edit' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Asset']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div onClick={() => ((!accessControl || actions?.['Delete Asset']) && row?._id) && onDisableAsset(row?._id)}>
                                            <img title={'Delete Asset'} alt='delete item' data-qa='extended-asset-delete' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Asset']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default AssetsExtendedTable;