import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { InvoiceTemplatesService } from './invoiceTemplateApi';
import { IInvoiceTemplatesListState } from '../../../../interfaces/invoice-templates/IInvoiceTemplateList';
import { PaginatedInvoiceTemplates } from '../../../../interfaces/invoice-templates/paginatedStore.type';
import { IInvoiceTemplate } from '../../../../interfaces/invoice-templates/IInvoiceTemplates';
import { IInvoiceTemplateSchema } from '../../../../interfaces/invoice-templates/IInvoiceTemplatesSchema';


const initialState: IInvoiceTemplatesListState = {
    templateIsLoading: false,
    templatesAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchInvoiceTemplates = createAsyncThunk<
    PaginatedInvoiceTemplates,
    IPaginationPayload
>('invoice-templates/tryToFetchInvoiceTemplates', async ({ pageNumber, pageSize, filters }) => {
    const result = await InvoiceTemplatesService.tryToFetchInvoiceTemplates(
        pageNumber,
        pageSize,
        filters,
    );
    return result?.data;
});

export const tryToFetchAllInvoiceTemplates = createAsyncThunk<any>(
    'invoice-templates/tryToFetchAllInvoiceTemplates', async () => {
        const result = await InvoiceTemplatesService.tryToFetchAllInvoiceTemplates();
        return result?.data;
    });

export const tryToFetchSingleInvoiceTemplate = createAsyncThunk<ApiResponse<IInvoiceTemplate>, string>(
    'invoice-templates/tryToFetchSingleInvoiceTemplate',
    async (id: string) => {
        const result = await InvoiceTemplatesService.tryToFetchSingleInvoiceTemplate(id);
        return result?.data;
    },
);

export const tryToEditInvoiceTemplate = createAsyncThunk<
    ApiResponse<IInvoiceTemplate>,
    IInvoiceTemplateSchema
>('invoice-templates/tryToEditInvoiceTemplate', async (data: IInvoiceTemplateSchema, { rejectWithValue }) => {
    try {
        const result = await InvoiceTemplatesService.tryToEditInvoiceTemplate(
            data._id || '',
            data,
        );
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToAddInvoiceTemplate = createAsyncThunk<
    ApiResponse<IInvoiceTemplateSchema>,
    IInvoiceTemplateSchema
>('invoice-templates/tryToAddInvoiceTemplate', async (data: IInvoiceTemplateSchema, { rejectWithValue }) => {
    try {
        const result = await InvoiceTemplatesService.tryToAddInvoiceTemplate(data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToDeleteInvoiceTemplate = createAsyncThunk<
    ApiResponse<IInvoiceTemplateSchema>,
    string
>('invoice-templates/tryToDeleteInvoiceTemplate', async (id, { rejectWithValue }) => {
    try {
        const results = await InvoiceTemplatesService.tryToDeleteInvoiceTemplate(id);
        return results.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const invoiceTemplatesSlice = createSlice({
    name: 'invoiceTemplates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchInvoiceTemplates
            .addCase(tryToFetchInvoiceTemplates.pending, (state) => {
                state.templatesAreLoading = true;
            })
            .addCase(tryToFetchInvoiceTemplates.rejected, (state) => {
                state.templatesAreLoading = false;
            })
            .addCase(tryToFetchInvoiceTemplates.fulfilled, (state, action) => {
                state.templatesAreLoading = false;
                state.invoiceTemplates = action.payload;
            })

            // tryToAddInvoiceTemplate
            .addCase(tryToAddInvoiceTemplate.pending, (state) => {
                state.templateIsLoading = true;
            })
            .addCase(tryToAddInvoiceTemplate.rejected, (state) => {
                state.templateIsLoading = false;
            })
            .addCase(tryToAddInvoiceTemplate.fulfilled, (state, action) => {
                state.templateIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.invoiceTemplates?.data)
                    state.invoiceTemplates.data.elements = [
                        data,
                        ...state.invoiceTemplates?.data.elements,
                    ];
            })

            // tryToDeleteInvoiceTemplate
            .addCase(tryToDeleteInvoiceTemplate.pending, (state) => {
                state.templateIsLoading = true;
            })
            .addCase(tryToDeleteInvoiceTemplate.rejected, (state) => {
                state.templateIsLoading = false;
            })
            .addCase(tryToDeleteInvoiceTemplate.fulfilled, (state, action) => {
                state.templateIsLoading = false;
                state.requestStatus = 'success';
                if (state.invoiceTemplates?.data.elements)
                    state.invoiceTemplates.data.elements =
                        { ...state }.invoiceTemplates?.data.elements.filter(
                            (invoiceTemplate) =>
                            invoiceTemplate?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToEditInvoiceTemplate
            .addCase(tryToEditInvoiceTemplate.pending, (state) => {
                state.templateIsLoading = true;
            })
            .addCase(tryToEditInvoiceTemplate.rejected, (state) => {
                state.templateIsLoading = false;
            })
            .addCase(tryToEditInvoiceTemplate.fulfilled, (state, action) => {
                state.templateIsLoading = false;
                if (state.invoiceTemplates?.data.elements)
                    state.invoiceTemplates.data.elements =
                        { ...state }.invoiceTemplates?.data.elements.map((invoiceTemplate) => {
                            if (invoiceTemplate?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return invoiceTemplate;
                            }
                        }) || [];
                state.invoiceTemplate = action.payload.data;
            })

            // tryToFetchSingleInvoiceTemplate
            .addCase(tryToFetchSingleInvoiceTemplate.pending, (state) => {
                state.templateIsLoading = true;
            })
            .addCase(tryToFetchSingleInvoiceTemplate.rejected, (state) => {
                state.templateIsLoading = false;
            })
            .addCase(tryToFetchSingleInvoiceTemplate.fulfilled, (state, action) => {
                state.templateIsLoading = false;
                state.invoiceTemplate = action.payload?.data;
            });
    },
});

export default invoiceTemplatesSlice.reducer;
