import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    tryToControlLogisticAndInventoryFeature,
    tryToDisablePartner,
    tryToEnablePartner,
    tryToFetchAllPartners,
    tryToFetchPartners,
} from '../../../store/brokers/admin/partners/partnersSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IToast } from '../../../interfaces/components/IToast';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import PartnerTrustpilotModal from './modals/PartnerTrustpilotModal';
import DeletePartnerModal from './modals/DeletePartnerModal';
import AdminRoleModal from '../users/modals/AdminRoleModal';
import DeleteOffersModal from './modals/DeleteOffersModal';
import ApiGenerateModal from './modals/ApiGenerateModal';
import SchedulerModal from './modals/SchedulerModal';
import PriorityModal from './modals/PriorityModal';
import PartnersFilters from './details/PartnersFilters';
import PartnersTable from './tables/PartnersTable';
import SearchWithButton from '../../../shared/search-with-button';
import EmptyContainer from '../../../shared/empty-container';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const Partners = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const csvLink3 = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<IPartners[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [showPriorityModal, setShowPriorityModal] = useState<boolean>(false)
    const [showSchedulerModal, setShowSchedulerModal] = useState<boolean>(false)
    const [partnerSelected, setPartnerSelected] = useState<string>()
    const [showDeleteOfferModal, setShowDeleteOfferModal] = useState<boolean>(false)
    const [showDeletePartnerModal, setShowDeletePartnerModal] = useState<{ show: boolean, id: string }>()
    const [itemPriorityToShow, setItemPriorityToShow] = useState<string | null>()
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [selectedInventory, setSelectedInventory] = useState<string>()
    const [selectedEnabled, setSelectedEnabled] = useState<string>()
    const [selectedLogistic, setSelectedLogistic] = useState<string>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [selectedBroker, setSelectedBroker] = useState<string>()
    const [showGenerateKeyModal, setShowGenerateKeyModal] = useState<{ show: boolean, id: string, apiKey?: any }>()
    const [showRoleModal, setShowRoleModal] = useState<{ show: boolean, id: string, type: string, role?: any }>()
    const [showTrustModal, setShowTrustModal] = useState<{ show: boolean, id: string, value?: any }>()
    const [loadingDownloadPartnerDetails, setLoadingDownloadPartnerDetails] = useState<boolean>(false)
    const [partnerDetailsToDownload, setPartnerDetailsToDownload] = useState<Array<any>>()
    const [search, setSearch] = useState<string>()
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.partners.partners?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const rowsLoading = useAppSelector((state) => state.partners.partnersAreLoading);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state) => state.partners);
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, displayName: r?.name, name: r?.displayName, value: r?._id, label: r?.displayName }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const onGettingAllMarkets = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, displayName: market?.name, name: market?.label, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        onGettingAllMarkets()
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    useEffect(() => {
        if (state.partners) {
            const rows =
                state.partners?.data?.elements && Array.isArray(state.partners?.data.elements)
                    ? state.partners?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.partners]);

    const onGettingFilters = (type?: string) => {
        let settingsFilters: any
        if (!type || type !== 'download') {
            settingsFilters = {
                ...paginationState
            }
        }
        if (selectedMarket) {
            settingsFilters.data = { market: selectedMarket ? selectedMarket?._id : '' }
        }
        if (selectedSector) {
            settingsFilters.data = {
                ...settingsFilters.data,
                catalog: selectedSector ? (selectedSector?.name || '').toLowerCase() : ''
            }
        }
        if (selectedEnabled) {
            const value = selectedEnabled === 'Yes'
            settingsFilters.data = {
                ...settingsFilters.data,
                enabled: value
            }
        }
        if (selectedLogistic) {
            const value = selectedLogistic === 'Yes'
            settingsFilters.data = {
                ...settingsFilters.data,
                logistic: value
            }
        }
        if (selectedBroker) {
            const value = selectedBroker === 'Yes'
            settingsFilters.data = {
                ...settingsFilters.data,
                brokers: value
            }
        }
        if (selectedInventory) {
            const value = selectedInventory === 'Yes'
            settingsFilters.data = {
                ...settingsFilters.data,
                inventory: value
            }
        }
        if (search) {
            settingsFilters.data = {
                ...settingsFilters.data,
                search: search
            }
        }
        return settingsFilters
    }

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    const getPartnersData = async () => {
        const settingsFilters = onGettingFilters()
        dispatch(tryToFetchPartners(settingsFilters));
    }

    useEffect(() => {
        getPartnersData();
    }, [paginationState]);

    const onAddNewPartner = () => {
        navigate('/users/partner/create');
    }

    const onSeePartnerDetails = async (id: string) => {
        navigate(`/users/partner/${id}`);
    }

    const onEnableDisablePartner = async (id: string, currentStatus: boolean) => {
        try {
            if (currentStatus) {
                await dispatch(tryToDisablePartner(id)).unwrap();
                setShowToast({ type: 'success', message: `Partner disabled successfully` });
            } else {
                await dispatch(tryToEnablePartner(id)).unwrap();
                setShowToast({ type: 'success', message: `Partner enabled successfully` });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
    }

    const onEnableDisableFeatures = async (id: string, currentStatus: boolean, type: string) => {
        try {
            await dispatch(tryToControlLogisticAndInventoryFeature({ partnerId: id, type, enable: !currentStatus })).unwrap();
            setShowToast({ type: 'success', message: `Partner ${type} ${currentStatus ? 'disabled' : 'enabled'} successfully` });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
    }

    const onOpenPriorityModal = async (id: string) => {
        setItemPriorityToShow(id)
        setShowPriorityModal(true)
    }

    const onEditPartner = (partnerId: string) => {
        navigate(`/users/partner/edit/${partnerId}`)
    }

    const onToggleSchedulersSettings = async (id?: string) => {
        setShowSchedulerModal(!showSchedulerModal)
        setPartnerSelected(id || undefined)
    }

    const onCreateEditSchedulerSettings = () => {
        onToggleSchedulersSettings()
    }

    const onDeletePartnerOffersModal = (id?: string) => {
        setShowDeleteOfferModal(!showDeleteOfferModal)
        setPartnerSelected(id || undefined)
    }

    const onSelectMarket = (value: string) => {
        setSelectedMarket(value)
        onResetPaginationState()
    }

    const onChangeSelectedBooleanItems = (value: string, name?: string) => {
        switch (name) {
            case 'enabled':
                setSelectedEnabled(value)
                break;
            case 'inventory':
                setSelectedInventory(value)
                break;
            case 'logistic':
                setSelectedLogistic(value)
                break;
            case 'broker':
                setSelectedBroker(value)
                break;
        }
        onResetPaginationState()
    }

    const onSelectSector = (value: string) => {
        setSelectedSector(value)
        onResetPaginationState()
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const clearAllFilters = () => {
        setSelectedMarket(undefined)
        setSelectedEnabled(undefined)
        setSelectedSector(undefined)
        setSelectedInventory(undefined)
        setSelectedBroker(undefined)
        setSelectedLogistic(undefined)
        setSearch(undefined)
        onResetPaginationState()
    }

    const onSeeSubBrands = (id: string) => {
        navigate(`/users/partner/sub-brands/${id}`)
    }

    const onDownloadPartnerDetails = async () => {
        setLoadingDownloadPartnerDetails(true)
        try {
            const partnerDetailArr: any = []
            partnerDetailArr.push(['Email', 'Username', 'Name', 'Company Name', 'Phone Number', 'Country', 'Company Registration Number', 'VAT Number', 'Annual GVM'])
            const response: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
            response.data.map((partnerDetail: any) => {
                partnerDetailArr.push([partnerDetail.email, partnerDetail.username, partnerDetail.name, partnerDetail.companyName, partnerDetail.phoneNumber, partnerDetail.country, partnerDetail.companyRegistrationNumber, partnerDetail?.vatNumber, partnerDetail?.annualGMV])
                return partnerDetailArr
            }
            )
            setPartnerDetailsToDownload(partnerDetailArr || [])
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
            setLoadingDownloadPartnerDetails(false)
        }
    }

    useEffect(() => {
        if (partnerDetailsToDownload && loadingDownloadPartnerDetails) {
            csvLink3.current.link.click()
        }
        setLoadingDownloadPartnerDetails(false)
    }, [partnerDetailsToDownload])

    const onOpenPartnerRole = (userId: string, type: string, role?: any) => {
        setShowRoleModal({
            show: true,
            id: userId,
            type: type,
            role: role,
        })
    }

    const onOpenPartnerApiKey = (userId: string, key?: any) => {
        setShowGenerateKeyModal({
            show: true,
            id: userId,
            apiKey: key,
        })
    }

    const onSuccessfullySavedRole = (body: any) => {
        const updateRows = (rows && rows?.length > 0) && rows?.map((item: IPartners) => {
            if (item?._id === body?.userId && body?.dashboard === 'inventory') {
                return {
                    ...item,
                    inventoryRole: body?.role || undefined
                }
            } else if (item?._id === body?.userId) {
                return {
                    ...item,
                    role: body?.role || undefined
                }
            } else {
                return item
            }
        })
        setRows(updateRows || [])
    }

    const onDeletePartnerRequest = (id: string) => {
        setShowDeletePartnerModal({ show: true, id: id })
    }

    const onClickManageTrustpilot = (id: string, value?: any) => {
        setShowTrustModal({
            show: true,
            id: id,
            value: value
        })
    }

    const onSuccessfullySavedTrustpilot = () => {
        setShowToast({ type: 'success', message: 'Partner Trustpilot Score changed successfully' })
    }

    return (
        <div>
            <div>
                <PartnersFilters
                    markets={markets}
                    sectors={sectors}
                    selectedMarket={selectedMarket}
                    selectedSector={selectedSector}
                    selectedEnabled={selectedEnabled}
                    selectedInventory={selectedInventory}
                    selectedLogistic={selectedLogistic}
                    selectedBroker={selectedBroker}
                    onSelectMarket={onSelectMarket}
                    onSelectSector={onSelectSector}
                    onChangeSelectedBooleanItems={onChangeSelectedBooleanItems}
                />
            </div>
            <div className='min-w-full flex flex-row justify-end my-10'>
                {(!accessControl || pageAccess?.actions?.['Download Partner Users']) &&
                    <Button
                        label={loadingDownloadPartnerDetails ? 'Loading data...' : 'Download Partner Users'}
                        onClickButton={onDownloadPartnerDetails}
                        className={'btn-main mr-2 !py-2 !shadow-none'}
                        dataQa={'download-partners'}
                    />
                }
                <CSVLink
                    ref={csvLink3}
                    data={(partnerDetailsToDownload && partnerDetailsToDownload?.length > 0) ? partnerDetailsToDownload?.filter((item, idx) => idx > 0) || [] : []}
                    headers={partnerDetailsToDownload?.[0] || []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={`Partner-Details.csv`}
                ></CSVLink>
                {(!accessControl || pageAccess?.actions?.['Add New Partner']) &&
                    <Button
                        label={'Add New Partner'}
                        onClickButton={onAddNewPartner}
                        className={'btn-main !col-span-2'}
                        dataQa={'add-new-partner'}
                    />
                }
            </div>
            <div>
                <SearchWithButton
                    placeholder='Filter partners'
                    dataQa={'filter-partners'}
                    search={search}
                    mainContainerStyle={'!grid-cols-10'}
                    onChangeSearchValue={onChangeSearchValue}
                    buttonLabel={'Clear All Filters'}
                    onClickButton={clearAllFilters}
                    buttonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end bg-transparent mt-1'}
                />
            </div>
            <div className='my-5 relative'>
                {(rowsLoading || rows?.length > 0) ?
                    <PartnersTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        onManageTrustpilot={onClickManageTrustpilot}
                        onSeePartnerDetails={onSeePartnerDetails}
                        onEnableDisablePartner={onEnableDisablePartner}
                        onOpenSchedulersSettings={onToggleSchedulersSettings}
                        onEnableDisableFeatures={onEnableDisableFeatures}
                        setPaginationState={setPaginationState}
                        onOpenPriority={onOpenPriorityModal}
                        onEditPartner={onEditPartner}
                        onSeeSubBrands={onSeeSubBrands}
                        onOpenPartnerRole={onOpenPartnerRole}
                        onOpenPartnerApiKey={onOpenPartnerApiKey}
                        onDeletePartnerOffers={onDeletePartnerOffersModal}
                        onDeletePartnerRequest={onDeletePartnerRequest}
                    />
                    : <EmptyContainer
                        text={'No Partners added yet'}
                    />
                }
            </div>
            {showPriorityModal &&
                <PriorityModal
                    markets={markets || []}
                    sectors={sectors || []}
                    brokerId={itemPriorityToShow || ''}
                    openPriorityModal={showPriorityModal}
                    setShowToast={setShowToast}
                    handleClosePriorityModal={() => setShowPriorityModal(false)}
                />
            }
            {showSchedulerModal &&
                <SchedulerModal
                    partnerId={partnerSelected}
                    openModal={showSchedulerModal}
                    handleCloseModal={onToggleSchedulersSettings}
                    onFinishSuccesfully={onCreateEditSchedulerSettings}
                />
            }
            {showDeletePartnerModal?.show &&
                <DeletePartnerModal
                    openModal={showDeletePartnerModal?.show}
                    partnerId={showDeletePartnerModal?.id || ''}
                    handleCloseModal={() => setShowDeletePartnerModal(undefined)}
                />
            }
            <DeleteOffersModal
                partnerId={partnerSelected}
                openModal={showDeleteOfferModal}
                handleCloseModal={onDeletePartnerOffersModal}
                onFinishSuccesfully={onDeletePartnerOffersModal}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showRoleModal?.show &&
                <AdminRoleModal
                    openModal={showRoleModal?.show}
                    accessType={showRoleModal?.type || ''}
                    id={showRoleModal?.id || ''}
                    role={showRoleModal?.role}
                    onSuccessfullySaved={onSuccessfullySavedRole}
                    handleCloseModal={() => setShowRoleModal(undefined)}
                />
            }
            {showTrustModal?.show &&
                <PartnerTrustpilotModal
                    openModal={showTrustModal?.show}
                    actualValue={showTrustModal?.value}
                    id={showTrustModal?.id || ''}
                    onSuccessfullySaved={onSuccessfullySavedTrustpilot}
                    handleCloseModal={() => setShowTrustModal(undefined)}
                />
            }
            {showGenerateKeyModal?.show &&
                <ApiGenerateModal
                    openModal={showGenerateKeyModal?.show}
                    partnerId={showGenerateKeyModal?.id || ''}
                    apiKey={showGenerateKeyModal?.apiKey}
                    handleCloseModal={() => setShowGenerateKeyModal(undefined)}
                />
            }
        </div>
    )
}

export default Partners;