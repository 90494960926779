import React, { useEffect, useState } from 'react';
import { ISingleOrderSchema } from '../../../../interfaces/orders/IOrdersSchema';
import { onFormatOrderStatus } from '../../../../utils/constants/format-order-statuses';


interface IOrderDetailsInfo {
    orderDetails: ISingleOrderSchema;
}

const OrderDetailsInfo = ({
    orderDetails,
}: IOrderDetailsInfo) => {
    const [orderStatusDetails, setOrderStatusDetails] = useState<{ name: string, color?: string, text?: string }>()

    useEffect(() => {
        const orderName = onFormatOrderStatus(orderDetails?.orderData?.status)?.name
        const color = onFormatOrderStatus(orderDetails?.orderData?.status)?.color || 'bg-[#ebedef]'
        const text = onFormatOrderStatus(orderDetails?.orderData?.status)?.text || 'text-black'
        setOrderStatusDetails({
            name: orderName,
            color,
            text
        })
    }, [orderDetails?.orderData?.status])

    return (
        <div className='flex flex-row items-center rounded-md bg-white py-3 px-5 my-5'>
            <div
                className={`${orderStatusDetails?.color} rounded-md ${orderStatusDetails?.text} mr-8 py-1 px-3 font-semibold`}>
                {orderStatusDetails?.name}
            </div>
            {orderDetails?.item?.name &&
                <div className='flex flex-col mr-5'>
                    <p className='font-semibold text-[14px]'>Item</p>
                    <p className='text-[14px]'>{orderDetails?.item?.name}</p>
                </div>
            }
            {orderDetails?.shipment?.trackingNumber &&
                <div className='flex flex-col mr-5'>
                    <p className='font-semibold text-[14px]'>Tracking Number</p>
                    <a
                        rel='noreferrer'
                        data-qa={'tracking-number'}
                        href={`https://parcelsapp.com/en/tracking/${orderDetails?.shipment?.trackingNumber}`}
                        target='_blank'
                        className='text-primary-default !text-[13px]'>
                        {orderDetails?.shipment?.trackingNumber}
                    </a>
                </div>
            }
            {orderDetails?.item?.id &&
                <div className='flex flex-col mr-5'>
                    <p className='font-semibold text-[14px]'>Item ID</p>
                    <p className='text-[14px]'>{orderDetails?.item?.id}</p>
                </div>
            }
            {orderDetails?.shipment?.orderNumber &&
                <div className='flex flex-col mr-5'>
                    <p className='font-semibold text-[14px]'>Order Number</p>
                    <p className='text-[14px]'>{orderDetails?.shipment?.orderNumber}</p>
                </div>
            }
            {orderDetails?.item?.sourceOrder &&
                <div className='flex flex-col mr-5'>
                    <p className='font-semibold text-[13px]'>Order Source</p>
                    <p className='text-[14px]'>{orderDetails?.item?.sourceOrder}</p>
                </div>
            }
            {orderDetails?.item?.location &&
                <div className='flex flex-col mr-5'>
                    <p className='font-semibold text-[14px]'>Warehouse Placement</p>
                    <p className='text-[14px]'>
                        {orderDetails?.item?.location && (Object?.keys(orderDetails?.item?.location))?.map((loc: any, index: number) => {
                            return `${index === 0 ? `${orderDetails?.item?.location?.[loc]}` : ` - ${orderDetails?.item?.location?.[loc]}`}`
                        })}
                    </p>
                </div>
            }
        </div >
    );
};

export default OrderDetailsInfo;
