import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    tryToDeleteRole,
    tryToFetchRoles
} from '../../../store/brokers/partners/user-access-control/userAccessSlice';
import { ROLE_TYPES_ITEMS } from '../../../utils/constants/user-access-admin-roles';
import { AdminGuard } from '../../../guards/RolesGuard';
import { IAgents } from '../../../interfaces/agents/IAgents';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import SelectCheckbox from '../../../shared/select-checkbox';
import EmptyContainer from '../../../shared/empty-container';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';
import RolesTable from './tables/RolesTable';


const UserAccessControl = () => {
    const isAdmin = AdminGuard()
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IAgents[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>()
    const [roleType, setRoleType] = useState<any>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [showDeleteModal, setShowDeleteModal] = useState<{ show: boolean, id: string }>()
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state.userAccess.roles?.data?.page);
    const rowsLoading = useAppSelector((state) => state.userAccess.rolesAreLoading);
    const state = useAppSelector((state) => state.userAccess);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'User Access Control')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.roles) {
            const rows =
                state.roles?.data?.elements && Array.isArray(state.roles?.data.elements)
                    ? state.roles?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.roles]);

    const getRolesData = async () => {
        let formatBody: any = {
            ...paginationState || {},
        }
        if (roleType) {
            formatBody = {
                ...formatBody,
                data: {
                    type: roleType?.value
                }
            }
        }
        await dispatch(tryToFetchRoles(formatBody));
    }

    useEffect(() => {
        getRolesData()
    }, [paginationState, roleType]);

    const onAddNewRole = () => {
        navigate('/user-access-control/create');
    }

    const onSeeRoleDetails = async (id: string) => {
        navigate(`/user-access-control/${id}`);
    }

    const onDeleteRole = async () => {
        try {
            await dispatch(tryToDeleteRole(showDeleteModal?.id || ''))
            setShowToast({ type: 'success', message: 'Role successfully deleted' });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setShowDeleteModal(undefined)
    }

    const onRequestDeleteRole = (id: string) => {
        setShowDeleteModal({ show: true, id })
    }

    const onCloseDeleteModal = () => {
        setShowDeleteModal(undefined)
    }

    const onChangeRoleTypeSelected = (e: any, type?: string) => {
        setRoleType(e)
    };

    const onClearAllFilters = () => {
        setRoleType(undefined)
    }

    return (
        <div>
            <div>
                <p className='page-title'>User Access Control</p>
            </div>
            {isAdmin &&
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-3'>
                    <SelectCheckbox
                        name='Type'
                        key={1}
                        placeholder=' '
                        dataQa={'type-selected'}
                        containerStyle='mb-1'
                        selectedOption={roleType}
                        onChangeSelectedOption={onChangeRoleTypeSelected}
                        options={isAdmin ? ROLE_TYPES_ITEMS?.filter((item: any) => !(['inventory-agent', 'agent']?.includes(item?.value))) || [] : ROLE_TYPES_ITEMS?.filter((item: any) => item?.value === 'agent' || item?.value === 'inventory-agent') || []}
                    />
                </div>
            }
            <div className='flex flex-row justify-end flex-wrap'>
                {isAdmin &&
                    <Button
                        label='Clear All Filters'
                        dataQa={'clear-all-filters'}
                        onClickButton={onClearAllFilters}
                        className='btn-primary-text align-end'
                    />
                }
                <Button
                    label={'Add New Role'}
                    dataQa={'add-new-role'}
                    className={`${(!accessControl || pageAccess?.actions?.['Add New Role']) ? 'btn-main' : 'btn-main-disable'}`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Role']) && onAddNewRole()}
                />
            </div>
            <div className='my-5 relative'>
                {(rows?.length > 0 || rowsLoading) ?
                    <RolesTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        accessEditRole={!accessControl || pageAccess?.actions?.['Edit Role']}
                        accessDeleteRole={!accessControl || pageAccess?.actions?.['Delete Role']}
                        onSeeDetails={onSeeRoleDetails}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        onDeleteRole={onRequestDeleteRole}
                    />
                    : <EmptyContainer
                        text={'No role added yet.'}
                        showImage={true} />
                }
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            <DeleteModal
                openDeleteModal={showDeleteModal?.show || false}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeleteRole}
            />
        </div>
    )
}

export default UserAccessControl;