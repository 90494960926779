import React from 'react';
import Button from '../../../../shared/button';


interface ISuccessSplitOrder {
    handleCloseModal: () => void;
}

const SuccessSplitOrder = ({
    handleCloseModal
}: ISuccessSplitOrder) => {
    return (
        <div className={'w-full flex flex-col items-center justify-between'}>
            <div>
                <p className='text-md font-semibold text-center mb-3'>Split Order finished successfully.</p>
                <p className='text-md font-semibold text-center'>Your items you can find in Order Items received status, items of other brokers you can find in to reship tab.</p>
            </div>
            <img src={'/assets/shared/success-mark.svg'} className={'h-[100px] object-contain my-10'} />
            <Button
                label='Close'
                className='btn-main my-3 min-w-[150px] !shadow-none'
                onClickButton={handleCloseModal}
            />
        </div>
    )
}
export default SuccessSplitOrder;