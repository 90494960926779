import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IQuestionService } from '../../../../interfaces/questions/IQuestions';
import {
    tryToDeleteQuestionService,
    tryToFetchSingleQuestion
} from '../../../../store/brokers/shared-endpoints/questions/questionsSlice';
import QuestionDetailsModal from '../modals/QuestionDetailsModal';
import QuestionDetailsTable from '../tables/QuestionDetailsTable';
import DeleteModal from '../../../../shared/delete-modal';
import Loading from '../../../../shared/Loading';
import Button from '../../../../shared/button';
import Toast from '../../../../shared/toast';


const QuestionDetails = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const { id } = useParams();
    const [showQuestionDetailsModal, setShowQuestionDetailsModal] = useState<boolean>(false);
    const [serviceId, setServiceId] = useState<string | null>(null);
    const [rows, setRows] = useState<IQuestionService[]>([]);
    const [showDeleteServiceModal, setShowDeleteServiceModal] = useState<boolean>(false)
    const [showToast, setShowToast] = useState<any>({});
    const [pageAccess, setPageAccess] = useState<any>()
    const [isCopy, setIsCopy] = useState<boolean>(false)
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const questionsDetails = useAppSelector((state) => state.questions?.questionDetails);
    const loadingQuestion = useAppSelector((state) => state?.questions?.questionIsLoading);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Cashout Flow')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Question Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const getQuestion = () => {
        if (id) {
            dispatch(tryToFetchSingleQuestion(id));
        }
    };

    useEffect(() => {
        getQuestion();
    }, [id]);

    useEffect(() => {
        if (questionsDetails?.services) {
            const rows: any =
                questionsDetails?.services &&
                    Array.isArray(questionsDetails.services)
                    ? questionsDetails?.services?.map((r) => ({
                        ...r,
                    }))
                    : [];
            setRows(rows);
        }
    }, [questionsDetails]);

    const onAddNewService = async () => {
        if (id) {
            document.body.style.overflow = 'hidden';
            setShowQuestionDetailsModal(true);
        }
    };

    const onEditService = async (serviceId: string) => {
        document.body.style.overflow = 'hidden';
        setShowQuestionDetailsModal(true);
        setServiceId(serviceId);
    };

    const onCloseQuestionDetailsModal = () => {
        document.body.style.overflow = 'auto';
        setServiceId(null);
        setIsCopy(false);
        setShowQuestionDetailsModal(false);
    };

    const onSaveQuestionDetailsChanges = () => {
        document.body.style.overflow = 'auto';
        setShowQuestionDetailsModal(false);
    };

    const onShowDeleteServiceModal = (id: string) => {
        setServiceId(id)
        setShowDeleteServiceModal(true)
    }

    const onDeleteService = async () => {
        try {
            if (serviceId) {
                await dispatch(tryToDeleteQuestionService({ id: id || '', serviceId })).unwrap();
                setShowToast({ type: 'success', message: `Service was successfully deleted` });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setServiceId(null)
        setShowDeleteServiceModal(false)
        getQuestion()
    }

    const onCloseDeleteServiceModal = () => {
        setServiceId(null)
        setShowDeleteServiceModal(false)
    }

    const onGoBack = () => {
        navigate(-1)
    }

    const onCopyService = (id: string) => {
        document.body.style.overflow = 'hidden';
        setServiceId(id);
        setShowQuestionDetailsModal(true);
        setIsCopy(true)
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img alt='' src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Services</p>
                </div>
            </div>
            <div>
                <div className='flex flex-row gap-2 items-center ml-2'>
                    <p>Question: {questionsDetails?.question} </p>
                </div>
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New Service'}
                    dataQa={'add-new-service'}
                    className={(!accessControl || pageAccess?.actions?.['Add New Service']) ? 'btn-main' : 'btn-main-disable'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Service']) && onAddNewService()}
                />
            </div>
            {!loadingQuestion ?
                <div>
                    <QuestionDetailsTable
                        rows={rows}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        questionDetails={questionsDetails}
                        onEditService={onEditService}
                        onDeleteService={onShowDeleteServiceModal}
                        onCopyService={onCopyService}
                    />
                </div>
                : <div className='flex flex-row justify-center my-11'>
                    <Loading />
                </div>
            }
            <QuestionDetailsModal
                openQuestionDetailsModal={showQuestionDetailsModal}
                handleCloseQuestionDetailsModal={onCloseQuestionDetailsModal}
                onSaveChanges={onSaveQuestionDetailsChanges}
                serviceId={serviceId || ''}
                questionId={id || ''}
                isCopy={isCopy}
            />
            <DeleteModal
                openDeleteModal={showDeleteServiceModal}
                handleCloseDeleteModal={onCloseDeleteServiceModal}
                onApproveDeletion={onDeleteService}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    );
};

export default QuestionDetails;
