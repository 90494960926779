import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import Button from '../../../../shared/button';
import Switch from '../../../../shared/switch';
import Table from '../../../../shared/table';

const tableColumns = ['Email', 'Username', 'Name', 'Company Name', 'Role', 'Access Role', 'Manage Role', 'Inventory Access Role', 'Manage Inventory Role', 'Manage Api Key', 'Enabled', 'Trustpilot Score', 'Manage Trustpilot Score', 'Change Prority', 'Brokers Status', 'Inventory Status', 'Logistic Status', 'Scheduler', 'Sub Brands', 'Delete Offers', 'Actions']

interface IPartnersTable {
    rows: IPartners[];
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    rowsLoading: boolean;
    onSeePartnerDetails: (id: string) => void;
    onEditPartner: (partnerId: string) => void;
    onOpenPartnerRole: (id: string, type: string, role?: string) => void;
    onOpenPartnerApiKey: (id: string, key?: string) => void;
    onOpenPriority: (id: string, value?: number | string) => void;
    onEnableDisablePartner: (id: string, currentStatus: boolean) => void;
    onEnableDisableFeatures: (id: string, currentStatus: boolean, type: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onOpenSchedulersSettings: (id: string) => void;
    onDeletePartnerOffers: (id?: string) => void;
    onSeeSubBrands: (id: string) => void;
    onManageTrustpilot: (id: string, value?: any) => void;
    onDeletePartnerRequest: (id: string) => void;
}

const PartnersTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    rowsLoading,
    onEditPartner,
    onSeePartnerDetails,
    onEnableDisablePartner,
    onEnableDisableFeatures,
    setPaginationState,
    onOpenPartnerRole,
    onOpenPriority,
    onOpenPartnerApiKey,
    onOpenSchedulersSettings,
    onDeletePartnerOffers,
    onSeeSubBrands,
    onManageTrustpilot,
    onDeletePartnerRequest
}: IPartnersTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IPartners, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.email}</td>
                                <td className='px-6 py-4'>{row?.username}</td>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>{row?.companyName}</td>
                                <td className='px-6 py-4'>{row?.type}</td>
                                <td className='px-6 py-4'>{row?.role}</td>
                                <td className='px-4 !min-w-[180px]'>
                                    <Button
                                        label={'Manage Role'}
                                        dataQa={'manage-role'}
                                        onClickButton={() => (!accessControl || actions?.['Partner Manage Role']) && onOpenPartnerRole(row?._id || '', 'organization-account', row?.role)}
                                        className={`${(!accessControl || actions?.['Partner Manage Role']) ? 'btn-main' : 'btn-main-disable'} !shadow-none`}
                                    />
                                </td>
                                <td className='px-6 py-4'>{row?.inventoryRole}</td>
                                <td className='px-6 py-4'>
                                    <Button
                                        label={'Manage Inventory Role'}
                                        className={row?.inventory && (!accessControl || actions?.['Partner Manage Inventory Access']) ? 'btn-main' : 'btn-main-disable'}
                                        onClickButton={() => ((!accessControl || actions?.['Partner Manage Inventory Access']) && row?._id && row?.inventory) && onOpenPartnerRole(row?._id || '', 'inventory-broker', row?.inventoryRole)}
                                        dataQa={'manage-inventory-access'}
                                    />
                                </td>
                                <td className='px-4 !min-w-[180px]'>
                                    <Button
                                        label={'Manage Key'}
                                        dataQa={'manage-key'}
                                        onClickButton={() => onOpenPartnerApiKey(row?._id || '', row?.apiIntegrationKey)}
                                        className={`btn-main !shadow-none`}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <Switch
                                        checked={!!row?.enabled}
                                        onToggleSwitch={() => ((!accessControl || actions?.['Partner Enable Disable']) && row?._id) && onEnableDisablePartner(row?._id, row?.enabled || false)}
                                        dataQa={'enabled-partner'}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    {(row?.trustPilotKey || row?.trustPilotKey === 0) ? row?.trustPilotKey?.toFixed(2) : ''}
                                </td>
                                <td className='px-6 py-4 !min-w-[150px]'>
                                    <Button
                                        label={'Manage Trustpilot'}
                                        className={row?.enabled && (!accessControl || actions?.['Manage Trustpilot']) ? 'btn-main' : 'btn-main-disable'}
                                        onClickButton={() => ((!accessControl || actions?.['Manage Trustpilot']) && row?._id && row?.enabled) && onManageTrustpilot(row?._id, row?.trustPilotKey)}
                                        dataQa={'manage-trustpilot'}
                                    />
                                </td>
                                <td className='px-6 py-4 !min-w-[150px]'>
                                    <Button
                                        label={'Set Priority'}
                                        className={row?.enabled && (!accessControl || actions?.['Partner Set Priority']) ? 'btn-main' : 'btn-main-disable'}
                                        onClickButton={() => ((!accessControl || actions?.['Partner Set Priority']) && row?._id && row?.enabled) && onOpenPriority(row?._id, row?.priority)}
                                        dataQa={'set-priority'}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <Switch
                                        checked={!!row?.brokers}
                                        onToggleSwitch={() => ((!accessControl || actions?.['Access Broker Dashboard']) && row?._id) && onEnableDisableFeatures(row?._id, row?.brokers || false, 'brokers')}
                                        dataQa={'enabled-broker'}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <Switch
                                        checked={!!row?.inventory}
                                        onToggleSwitch={() => ((!accessControl || actions?.['Access Inventory Dashboard']) && row?._id) && onEnableDisableFeatures(row?._id, row?.inventory || false, 'inventory')}
                                        dataQa={'enabled-inventory'}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <Switch
                                        checked={!!row?.logistic}
                                        onToggleSwitch={() => ((!accessControl || actions?.['Access Logistic Dashboard']) && row?._id) && onEnableDisableFeatures(row?._id, row?.logistic || false, 'logistics')}
                                        dataQa={'enabled-logistic'}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <Button
                                        label={'Settings'}
                                        className={row?.enabled && (!accessControl || actions?.['Partner Settings Invoice']) ? 'btn-main' : 'btn-main-disable'}
                                        onClickButton={() => ((!accessControl || actions?.['Partner Settings Invoice']) && row?._id && row?.enabled) && onOpenSchedulersSettings(row?._id)}
                                        dataQa={'settings-send-invoice'}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <Button
                                        label={'View'}
                                        className={`${(!accessControl || actions?.['Partner See Subbrands']) ? 'btn-main' : 'btn-main-disable'}`}
                                        onClickButton={() => ((!accessControl || actions?.['Partner See Subbrands']) && row?._id) && onSeeSubBrands(row?._id)}
                                        dataQa={'sub-brands'}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <Button
                                        label={'Delete'}
                                        className={`${(!accessControl || actions?.['Partner Delete Offers']) ? 'btn-filters' : 'btn-noactive-filters'}`}
                                        onClickButton={() => ((!accessControl || actions?.['Partner Delete Offers']) && row?._id) && onDeletePartnerOffers(row?._id)}
                                        dataQa={'delete-offers'}
                                    />
                                </td>
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <div className='flex flex-row justify-around'>
                                        <div data-qa='partners-details' onClick={() => ((!accessControl || actions?.['Partner Details']) && row?._id) && onSeePartnerDetails(row?._id)}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Partner Details']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                        </div>
                                        <div data-qa='partners-edit' onClick={() => ((!accessControl || actions?.['Partner Edit']) && row?._id) && onEditPartner(row?._id)}>
                                            <img title={'Edit Partner'} alt='edit partner' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Partner Edit']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                        </div>
                                        <div data-qa='partners-delete' onClick={() => ((!accessControl || actions?.['Partner Delete']) && row?._id) && onDeletePartnerRequest(row?._id)}>
                                            <img title={'Delete Partner'} alt='delete partner' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Partner Delete']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default PartnersTable;