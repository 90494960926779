import React, { useEffect, useLayoutEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
    useLocation,
    useSearchParams
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RECENT_CHANGES_ITEMS } from '../../../utils/constants/orders-items-consts';
import { INVENTORY_FILTERS } from '../../../utils/constants/inventory-filter-values';
import { IOrders } from '../../../interfaces/orders/IOrders';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IToast } from '../../../interfaces/components/IToast';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IPayServices } from '../../../interfaces/pay-services/IPayServices';
import { IOrderFilterBody } from '../../../interfaces/orders/IOrderFilterBody';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToFetchCashouts,
    tryToFetchInboundOrders,
    tryToFetchOrders,
    tryToFetchOrdersInCs,
    tryToFetchOrdersInReview,
    tryToFetchRecentOrders,
    tryToFetchResolvedOrders,
    tryToFindItemByTrackingNumber
} from '../../../store/brokers/partners/orders/ordersSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchAllPartners } from '../../../store/brokers/admin/partners/partnersSlice';
import {
    useGetAllCashoutsMutation,
    useGetAllInboundShipmentsMutation,
    useGetAllResolvedOrdersMutation,
    useGetAllShipmentsInCsMutation,
    useGetAllShipmentsInReviewMutation,
    useGetAllShipmentsMutation
} from '../../../store/brokers/partners/orders/shippmentsAPI';
import { tryToFetchAllPayServices } from '../../../store/brokers/admin/pay-services/payServicesSlice';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import OrderCategories from './details/OrderCategories';
import OrderNotFound from './details/OrderNotFound';
import OrderFilters from './details/OrderFilters';
import OrdersTable from './tables/OrdersTable';
import SelectCheckbox from '../../../shared/select-checkbox';
import Button from '../../../shared/button';
import Input from '../../../shared/input';
import Toast from '../../../shared/toast';


const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AdminOrders = () => {
    const dispatch = useAppDispatch();
    const query = useQuery();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<Array<IOrders>>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterSelected, setFilterSelected] = useState<string | undefined>(query?.get('search') ? undefined : 'Not Shipped');
    const [statusSelected, setStatusSelected] = useState<string>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: undefined
    });
    const [historyFilterStatus, setHistoryFilterStatus] = useState<string>()
    const [recentFilterStatus, setRecentFilterStatus] = useState<string>()
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [partners, setPartners] = useState<IPartners[]>()
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [selectedMarket, setSelectedMarket] = useState<string>()
    const [selectedPartner, setSelectedPartner] = useState<string>()
    const [selectedSector, setSelectedSector] = useState<string>()
    const [selectedSource, setSelectedSource] = useState<string>()
    const [payServices, setPayServices] = useState<any>()
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [ordersToExport, setOrdersToExport] = useState<Array<any>>()
    const [showToast, setShowToast] = useState<IToast | null>()
    const [searchValue, setSearchValue] = useState<any>()
    const [inventorySelected, setInventorySelected] = useState<string>()
    const [loadingOrders, setLoadingOrders] = useState<boolean>(true)
    const [pageAccess, setPageAccess] = useState<any>()
    const state = useAppSelector((state) => state.orders);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state?.orders?.orders?.data?.page);
    const [getAllInboundOrdersNoPagination] = useGetAllInboundShipmentsMutation()
    const [getAllOrdersInReviewNoPagination] = useGetAllShipmentsInReviewMutation()
    const [getAllOrdersInCSNoPagination] = useGetAllShipmentsInCsMutation()
    const [getAllCashouts] = useGetAllCashoutsMutation()
    const [getAllRejsolvedOrdersNoPagination] = useGetAllResolvedOrdersMutation()
    const [getAllOrdersNoPagination] = useGetAllShipmentsMutation()
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Orders Admin')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined
        })
        searchParams.set('page', '1')
        searchParams.set('size', `${paginationState?.pageSize || 10}`)
        setSearchParams(searchParams)
    }

    const getFiltersData = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, value: partner?.username, label: partner?.companyName, name: partner?.companyName }))
        setPartners(formatPartners || [])
        if (!selectedPartner) {
            setSelectedPartner(query?.get('partner') || formatPartners?.[0]?.value || undefined)
        }
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, displayName: market?.name }))
        setMarkets(formatMarkets || [])
        if (!selectedMarket) {
            setSelectedMarket(query?.get('market') || formatMarkets?.[0]?.name || undefined)
        }
        const payServicesResponse = await dispatch(tryToFetchAllPayServices()).unwrap()
        const formatPayServices = payServicesResponse && payServicesResponse?.data && payServicesResponse?.data?.length > 0 && payServicesResponse?.data?.map((service: IPayServices) => ({ ...service, label: service?.displayName, value: service?._id }))
        setPayServices(formatPayServices || [])
        onResetPaginationSettings()
    }

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.displayName, label: r?.displayName, value: r?._id, }))
                    : [];
            const formatAssets = rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []
            setSectors(formatAssets);
            if (!selectedSector) {
                setSelectedSector(query?.get('category') || formatAssets?.[0]?.name)
            }
            onResetPaginationSettings()
        }
    }, [stateAssets.allAssetTemplates]);

    useEffect(() => {
        getFiltersData()
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    useLayoutEffect(() => {
        const paginationStateFormat = paginationState
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1)
        }
        if (query?.get('size') && Number(query?.get('size')) !== paginationState?.pageSize) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10)
        }
        if (query?.get('search') && query?.get('search') !== paginationState?.filters) {
            paginationStateFormat.filters = query.get('search') || undefined
            setSearchValue(query.get('search') || undefined)
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat)
        }
        if (query?.get('filter')) {
            switch (query?.get('filter')) {
                case 'inbound':
                case 'Inbound':
                    setFilterSelected('Inbound')
                    break;
                case 'shipped':
                case 'Shipped':
                    setFilterSelected('Shipped')
                    break;
                case 'not_shipped':
                case 'Not Shipped':
                    setFilterSelected('Not Shipped')
                    break;
                case 'Delivered':
                    setFilterSelected('Delivered')
                    break;
                case 'review':
                case 'In Review':
                    setFilterSelected('In Review')
                    break;
                case 'Awaiting User Response':
                    setFilterSelected('Awaiting User Response')
                    break;
                case 'Awaiting CS Response':
                    setFilterSelected('Awaiting CS Response')
                    break;
                case 'Required Return':
                    setFilterSelected('Required Return')
                    break;
                case 'Returned':
                case 'returned':
                    setFilterSelected('Returned')
                    break;
                case 'cs':
                case 'CS':
                    setFilterSelected('CS')
                    break;
                case 'history':
                case 'History':
                    setStatusSelected('History')
                    break;
                case 'recent':
                case 'Recent Changes':
                case 'Recent':
                    setStatusSelected('Recent Changes')
                    break;
                case 'rejected':
                case 'Rejected':
                    setStatusSelected('History')
                    setHistoryFilterStatus('Rejected')
                    break;
                case 'canceled':
                case 'Canceled':
                    setStatusSelected('History')
                    setHistoryFilterStatus('Canceled')
                    break;
                case 'accepted':
                case 'Accepted':
                    setFilterSelected('Accepted')
                    break;
                case 'missing':
                case 'Missing':
                    setFilterSelected('Missing')
                    break;
                case 'Price Revaluation Accepted':
                case 'Recent Delivered':
                case 'CS Ticket Response':
                case 'Revaluation Awaiting Response':
                    setStatusSelected('Recent Changes')
                    setRecentFilterStatus(query?.get('filter') === 'Recent Delivered' ? 'Delivered' : query?.get('filter') || '')
                    break;
            }
        }
        if (query?.get('category') && query?.get('category') !== selectedSector) {
            setSelectedSector(query?.get('category') || undefined)
        }
        if (query?.get('market') && query?.get('market') !== selectedMarket) {
            setSelectedMarket(query?.get('market') || undefined)
        }
        if (query?.get('partner') && query?.get('partner') !== selectedPartner) {
            setSelectedPartner(query?.get('partner') || undefined)
        }
        if (query?.get('source') && query?.get('source') !== selectedSource) {
            setSelectedSource(query?.get('source') || undefined)
        }
    }, [
        query?.get('page'),
        query?.get('size'),
        query?.get('filter'),
        query?.get('source'),
        query?.get('search'),
        query?.get('category'),
        query?.get('market'),
        query?.get('partner')
    ])

    const getOrdersData = async () => {
        setLoadingOrders(true)
        const findCategory = sectors && sectors?.length > 0 && sectors?.find((category: any) => category?.name === selectedSector)
        if (!selectedSector || !sectors || !findCategory || !selectedMarket) {
            return;
        }
        const data: IOrderFilterBody = {}

        if (selectedMarket) {
            const findMarket: any = markets && markets?.length > 0 && markets?.find((market: IAllMarkets) => market?.name === selectedMarket)
            data.country = findMarket?.displayName || ''
            data.marketId = findMarket?._id
        }
        if (selectedSector) {
            data.category = {
                id: findCategory?._id || '',
                name: selectedSector
            }
        }
        if (selectedPartner) {
            data.brokerUsername = selectedPartner
        }
        if (inventorySelected) {
            data.location = inventorySelected === 'Yes'
        }
        if (selectedSource) {
            const findSource = payServices && payServices?.length > 0 && payServices?.find((item: any) => item?.label === selectedSource)
            data.sourceOrder = findSource?.name || ''
        }
        if (historyFilterStatus) {
            switch (historyFilterStatus) {
                case 'Accepted':
                    data.status = 'reviewed'
                    break;
                case 'Canceled':
                    data.status = 'canceled'
                    break;
                case 'Rejected':
                    data.status = 'rejected'
                    break;
                case 'Missing':
                    data.status = 'missing'
                    break;
            }
        }
        if (recentFilterStatus) {
            const findFilter = RECENT_CHANGES_ITEMS?.find((item) => item?.name === recentFilterStatus)
            data.status = findFilter?.status
        }
        if (filterSelected === 'Awaiting User Response') {
            data.status = 'revaluated'
        }
        if (filterSelected === 'Awaiting CS Response') {
            data.status = 'awaiting_cs_response'
        }
        if (filterSelected === 'In Review') {
            data.status = 'cashed_out'
        }
        if (filterSelected === 'Returned') {
            data.status = 'returned'
        }
        if (filterSelected === 'Required Return') {
            data.status = 'required_return'
        }
        if (filterSelected === 'CS') {
            data.status = 'cs'
        }
        if (filterSelected === 'Accepted') {
            data.status = 'reviewed'
        }
        if (filterSelected === 'Missing') {
            data.status = 'missing'
        }
        if (filterSelected === 'Shipped') {
            data.status = 'shipped'
        }
        if (filterSelected === 'Not Shipped') {
            data.status = 'not_shipped'
        }
        const resolvedData = { ...paginationState, data }
        searchParams.set('page', `${paginationState?.pageNumber}`)
        searchParams.set('size', `${paginationState?.pageSize}`)
        setSearchParams(searchParams)
        switch (filterSelected || statusSelected) {
            case 'Inbound':
                dispatch(tryToFetchInboundOrders(resolvedData)).unwrap()
                break;
            case 'Shipped':
                dispatch(tryToFetchCashouts(resolvedData)).unwrap()
                break;
            case 'Not Shipped':
                dispatch(tryToFetchCashouts(resolvedData)).unwrap()
                break;
            case 'Delivered':
                dispatch(tryToFetchOrdersInReview(resolvedData)).unwrap()
                break;
            case 'In Review':
                dispatch(tryToFetchOrdersInReview(resolvedData)).unwrap()
                break;
            case 'CS':
                dispatch(tryToFetchOrdersInCs(resolvedData)).unwrap()
                break;
            case 'Awaiting User Response':
                dispatch(tryToFetchOrdersInReview(resolvedData)).unwrap()
                break;
            case 'Awaiting CS Response':
                dispatch(tryToFetchOrdersInReview(resolvedData)).unwrap()
                break;
            case 'Required Return':
                dispatch(tryToFetchResolvedOrders(resolvedData)).unwrap()
                break;
            case 'Returned':
                dispatch(tryToFetchResolvedOrders(resolvedData)).unwrap()
                break;
            case 'Accepted':
                dispatch(tryToFetchResolvedOrders(resolvedData)).unwrap()
                break;
            case 'Missing':
                dispatch(tryToFetchResolvedOrders(resolvedData)).unwrap()
                break;
            case 'History':
                dispatch(tryToFetchResolvedOrders(resolvedData)).unwrap()
                break;
            case 'Recent Changes':
                dispatch(tryToFetchRecentOrders(resolvedData)).unwrap()
                break;
            default:
                dispatch(tryToFetchOrders(resolvedData)).unwrap()
                break;
        }
    }

    useEffect(() => {
        if (state.orders) {
            const rowsData =
                state.orders?.data?.elements && Array.isArray(state.orders?.data.elements)
                    ? state.orders?.data.elements.map((r) => ({ ...r, id: r.id }))
                    : [];
            if (JSON.stringify(rows) !== JSON.stringify(rowsData)) {
                setRows(rowsData);
            }
            setLoadingOrders(false)
        }
    }, [state.orders]);

    const onChangeSearchValue = async (value: string | null | undefined, type: string) => {
        setSearchValue(value)
        if (value && value !== '') {
            setLoadingOrders(true)
            try {
                const response: any = await dispatch(tryToFindItemByTrackingNumber(value || '')).unwrap()
                if (response) {
                    const findCategory = (response && response?.catalog && sectors && sectors?.length > 0) && sectors?.find((category) => (category?.name || '').toLowerCase() === (response?.catalog?.code || '').toLowerCase())
                    if (findCategory) {
                        searchParams.set('category', findCategory?.name || '')
                        setSelectedSector(findCategory?.name)
                    }
                    if (markets && markets?.length > 0) {
                        const findMarket = markets?.find((market: any) => market?.displayName === response?.country)
                        if (findMarket) {
                            setSelectedMarket(findMarket?.name || undefined)
                        }
                    }
                    setSelectedPartner(response?.username || selectedPartner)
                }
            } catch (err) {
                setLoadingOrders(false)
            }
        }
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
            filters: value || undefined
        })
        if (value) searchParams.set('search', value)
        else searchParams.delete('search')
        searchParams.set('page', '1')
        searchParams.delete('filter')
        setFilterSelected(undefined)
        setSearchParams(searchParams)
    }

    const onChangeFilterSelected = (filter: string) => {
        setStatusSelected(undefined)
        setHistoryFilterStatus(undefined)
        setFilterSelected(filterSelected === filter ? undefined : filter)
        if (filterSelected === filter) searchParams.delete('filter')
        else searchParams.set('filter', filter)
        setSearchParams(searchParams)
        onResetPaginationSettings()
    }

    useLayoutEffect(() => {
        if (selectedMarket && selectedSector && paginationState) {
            getOrdersData()
        } else {
            setLoadingOrders(true)
        }
    }, [selectedMarket, paginationState])

    const onChangeStatusSelected = (status: string) => {
        setFilterSelected(undefined)
        setStatusSelected(statusSelected === status ? undefined : status)
        setHistoryFilterStatus(undefined)
        setRecentFilterStatus(undefined)
        if (statusSelected !== status) searchParams.set('filter', status)
        else searchParams.delete('filter')
        setSearchParams(searchParams)
        onResetPaginationSettings()
    }

    const onClickHistoryStatus = (status: string) => {
        setHistoryFilterStatus(status === historyFilterStatus ? undefined : status)
        searchParams.set('filter', status === historyFilterStatus ? 'History' : status)
        setSearchParams(searchParams)
        onResetPaginationSettings()
    }

    const onChangeRecentFilter = (status: string) => {
        setRecentFilterStatus(status === recentFilterStatus ? undefined : status)
        searchParams.set('filter', status === recentFilterStatus ? 'Recent' : status === 'Delivered' ? 'Recent Delivered' : status)
        setSearchParams(searchParams)
        onResetPaginationSettings()
    }

    const onExportOrders = async () => {
        if (loadingDownload) {
            return;
        }
        setLoadingDownload(true)
        const findCategory = sectors && sectors?.length > 0 && sectors?.find((category: any) => category?.name === selectedSector)
        if (!selectedSector || !sectors || !findCategory) {
            return;
        }
        try {
            const data: any = { download: true }
            if (selectedMarket) {
                const findMarket: any = markets && markets?.length > 0 && markets?.find((market: IAllMarkets) => market?.name === selectedMarket)
                data.country = findMarket?.displayName || ''
                data.marketId = findMarket?._id
            }
            if (selectedSector) {
                data.category = {
                    id: findCategory?._id || '',
                    name: selectedSector
                }
            }
            if (selectedPartner) {
                data.brokerUsername = selectedPartner
            }
            if (inventorySelected) {
                data.location = inventorySelected === 'Yes'
            }
            if (selectedSource) {
                const findSource = payServices && payServices?.length > 0 && payServices?.find((item: any) => item?.label === selectedSource)
                data.sourceOrder = findSource?.name || ''
            }
            if (historyFilterStatus) {
                switch (historyFilterStatus) {
                    case 'Accepted':
                        data.status = 'reviewed'
                        break;
                    case 'Canceled':
                        data.status = 'canceled'
                        break;
                    case 'Rejected':
                        data.status = 'rejected'
                        break;
                    case 'Missing':
                        data.status = 'missing'
                        break;
                }
            }
            if (recentFilterStatus) {
                const findFilter = RECENT_CHANGES_ITEMS?.find((item) => item?.name === recentFilterStatus)
                data.status = findFilter?.status
            }
            if (filterSelected === 'In Review') {
                data.status = 'cashed_out'
            }
            if (filterSelected === 'Awaiting User Response') {
                data.status = 'revaluated'
            }
            if (filterSelected === 'Required Return') {
                data.status = 'required_return'
            }
            if (filterSelected === 'Returned') {
                data.status = 'returned'
            }
            if (filterSelected === 'CS') {
                data.status = 'cs'
            }
            if (filterSelected === 'Accepted') {
                data.status = 'reviewed'
            }
            if (filterSelected === 'Missing') {
                data.status = 'missing'
            }
            if (filterSelected === 'Shipped') {
                data.status = 'shipped'
            }
            if (filterSelected === 'Not Shipped') {
                data.status = 'not_shipped'
            }
            const resolvedData = { ...paginationState, data }
            let response: any = []
            switch (filterSelected || statusSelected) {
                case 'Inbound':
                    response = await getAllInboundOrdersNoPagination(resolvedData).unwrap()
                    break;
                case 'Shipped':
                    response = await getAllCashouts(resolvedData).unwrap()
                    break;
                case 'Not Shipped':
                    response = await getAllCashouts(resolvedData).unwrap()
                    break;
                case 'Delivered':
                    response = await getAllOrdersInReviewNoPagination(resolvedData).unwrap()
                    break;
                case 'In Review':
                    response = await getAllOrdersInReviewNoPagination(resolvedData).unwrap()
                    break;
                case 'CS':
                    response = await getAllOrdersInCSNoPagination(resolvedData).unwrap()
                    break;
                case 'Awaiting user response':
                    response = await getAllOrdersInReviewNoPagination(resolvedData).unwrap()
                    break;
                case 'Returned':
                    response = await getAllRejsolvedOrdersNoPagination(resolvedData).unwrap()
                    break;
                case 'Required Return':
                    response = await getAllRejsolvedOrdersNoPagination(resolvedData).unwrap()
                    break;
                case 'Accepted':
                    response = await getAllRejsolvedOrdersNoPagination(resolvedData).unwrap()
                    break;
                case 'Missing':
                    response = await getAllRejsolvedOrdersNoPagination(resolvedData).unwrap()
                    break;
                case 'History':
                    response = await getAllRejsolvedOrdersNoPagination(resolvedData).unwrap()
                    break;
                case 'Recent Changes':
                    response = await getAllRejsolvedOrdersNoPagination(resolvedData).unwrap()
                    break;
                default:
                    response = await getAllOrdersNoPagination(resolvedData).unwrap()
                    break;
            }
            setOrdersToExport(response || [])
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
    }

    useEffect(() => {
        if (ordersToExport && loadingDownload) {
            csvLink.current.link.click()
        }
        setLoadingDownload(false)
    }, [ordersToExport])

    const clearAllFilters = () => {
        setPaginationState({
            ...paginationState,
            pageNumber: 1,
            filters: undefined
        })
        setSearchValue(undefined)
        setStatusSelected(undefined)
        setFilterSelected(undefined)
        setHistoryFilterStatus(undefined)
        setInventorySelected(undefined)
        setSelectedSource(undefined)
        searchParams.delete('search')
        searchParams.delete('category')
        searchParams.delete('filter')
        searchParams.delete('source')
        searchParams.set('page', '1')
        setSearchParams(searchParams)
    }

    const onClickInventoryFilter = (value: any, type?: string) => {
        setInventorySelected(value?.value)
        onResetPaginationSettings()
    }

    const onClickSourceFilter = (value: any, type?: string) => {
        setSelectedSource(value?.label)
        setSearchParams(searchParams)
        onResetPaginationSettings()
    }

    const onSelectFilterOption = (value: any, name?: string) => {
        if (name) {
            switch (name) {
                case 'market':
                    setSelectedMarket(value?.name)
                    searchParams.set('market', value?.name)
                    break;
                case 'partner':
                    setSelectedPartner(value?.value)
                    searchParams.set('partner', value?.value)
                    break;
                case 'category':
                    setSelectedSector(value?.name)
                    searchParams.set('category', value?.name)
                    break;
            }
            setSearchParams(searchParams)
            onResetPaginationSettings()
        }
    }

    return (
        <div>
            <div className='mb-4'>
                <p className='page-title'>Orders</p>
            </div>
            <div className='my-10'>
                <OrderCategories
                    sectors={sectors}
                    markets={markets}
                    partners={partners}
                    selectedMarket={selectedMarket}
                    selectedPartner={selectedPartner}
                    selectedSector={selectedSector}
                    onSelectFilterOption={onSelectFilterOption}
                />
            </div>
            <div className='my-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 md:gap-4'>
                <SelectCheckbox
                    name='Inventory'
                    dataQa={'inventory'}
                    placeholder={inventorySelected || ''}
                    options={INVENTORY_FILTERS || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200, }}
                    onChangeSelectedOption={onClickInventoryFilter}
                    uniqueName={'inventory'}
                />
                <SelectCheckbox
                    name='Order Source'
                    dataQa={'order-source'}
                    placeholder={selectedSource || ''}
                    options={payServices || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200, }}
                    onChangeSelectedOption={onClickSourceFilter}
                    uniqueName={'payService'}
                />
            </div>
            <div className='my-5'>
                <OrderFilters
                    onChangeFilterSelected={onChangeFilterSelected}
                    onChangeStatusSelected={onChangeStatusSelected}
                    onChangeRecentFilter={onChangeRecentFilter}
                    onClickHistoryStatus={onClickHistoryStatus}
                    recentSelected={recentFilterStatus}
                    selectedFilter={filterSelected}
                    selectedStatus={statusSelected}
                    historyFilterStatus={historyFilterStatus}
                />
            </div>
            <div>
                <div className={'grid grid-cols-10 bg-white'}>
                    <div className={'col-span-10 md:col-span-6 lg:col-span-7'}>
                        <Input
                            placeholder={'Filter table'}
                            dataQa={'filter-table'}
                            containerStyle='mb-0 pb-0 relative'
                            inputStyle={'mb-0 border-0 focus:outline-none'}
                            icon={'/assets/shared/search.svg'}
                            showValue={true}
                            inputValue={searchValue}
                            inputUniqueName={'search'}
                            onChangeInput={onChangeSearchValue}
                            hideInputSpace={true}
                        />
                    </div>
                    <Button
                        label={'Clear all filters'}
                        dataQa={'clear-all-filters'}
                        className={'btn-primary-text-underline min-w-auto col-span-5 md:col-span-2 lg:col-span-2 flex flex-row justify-center md:justify-end bg-transparent mt-1'}
                        onClickButton={clearAllFilters}
                    />
                    <Button
                        label={loadingDownload ? 'Loading data...' : 'Export CSV'}
                        dataQa={loadingDownload ? 'loading-data...' : 'export-csv'}
                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Export CSV']) && onExportOrders()}
                        className={`${(!accessControl || pageAccess?.actions?.['Export CSV']) ? 'btn-main' : 'btn-main-disable'} mr-0 md:mr-2 lg:mr-0 !py-2 col-span-5 md:col-span-2 lg:col-span-1 !shadow-none`}
                    />
                </div>
                <CSVLink
                    ref={csvLink}
                    data={(ordersToExport && ordersToExport?.length > 0) ? ordersToExport?.filter((order, idx) => idx > 0) : []}
                    headers={ordersToExport?.[0] || []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={`orders-${selectedPartner}-${selectedMarket}-${selectedSector}.csv`}
                >
                    {loadingDownload ? 'Loading data...' : 'Download Sample'}
                </CSVLink>
            </div>
            <div>
                {(loadingOrders || rows?.length > 0)
                    ? <OrdersTable
                        rows={rows}
                        selectedFilter={filterSelected || statusSelected}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        activeMarket={selectedMarket}
                        markets={markets}
                        isRecentChanges={statusSelected === 'Recent Changes'}
                        ordersLoading={loadingOrders}
                        categorySelected={selectedSector || ''}
                    />
                    : <OrderNotFound
                        selectedMarket={selectedMarket}
                        selectedSector={selectedSector}
                        filterSelected={filterSelected}
                        historyFilterStatus={historyFilterStatus}
                        recentFilterStatus={recentFilterStatus}
                    />
                }
                {showToast?.message &&
                    <Toast
                        type={showToast?.type}
                        text={showToast?.message}
                        onHandleCloseToast={() => setShowToast(null)}
                    />
                }
            </div>
        </div>
    )
}

export default AdminOrders;