import React from 'react';
import {
    FILTER_ITEMS,
    RECENT_CHANGES_ITEMS,
} from '../../../../utils/constants/orders-items-consts';
import Button from '../../../../shared/button';


interface IOrderFilters {
    selectedFilter?: string;
    selectedStatus?: string;
    recentSelected?: string;
    historyFilterStatus?: string;
    onChangeFilterSelected: (filter: string) => void;
    onChangeRecentFilter: (name: string) => void;
    onChangeStatusSelected: (status: string) => void;
    onClickHistoryStatus: (status: string) => void;
}

const OrderFilters = ({
    selectedFilter,
    selectedStatus,
    recentSelected,
    historyFilterStatus,
    onChangeFilterSelected,
    onChangeRecentFilter,
    onClickHistoryStatus,
}: IOrderFilters) => {
    return (
        <div className='flex flex-row justify-between items-center min-w-full'>
            {selectedStatus !== 'Recent Changes' ?
                <div className='flex flex-row items-center'>
                    <div className='flex flex-row items-center'>
                        <img src={'/assets/shared/filter.svg'} className={'w-[13px] object-contain'} />
                        <p className='text-[#8F8F8F] text-sm font-bold ml-2 mr-4 mb-2'>Filters:</p>
                    </div>
                    <div className='flex flex-row items-center flex-wrap'>
                        {selectedStatus !== 'History' ?
                            FILTER_ITEMS && FILTER_ITEMS?.length > 0 && FILTER_ITEMS?.map((item, index) => {
                                return (
                                    <Button
                                        key={index}
                                        onClickButton={() => onChangeFilterSelected(item?.name)}
                                        label={item?.label}
                                        dataQa={`${(item?.label || '')?.toLocaleLowerCase().replaceAll(' ', '-')}`}
                                        className={`${selectedFilter === item?.name ? 'btn-filters' : 'btn-noactive-filters'} mr-2 mb-2 !font-bold !text-[13px]`}
                                    />
                                )
                            })
                            : <>
                                <Button
                                    onClickButton={() => onClickHistoryStatus('Accepted')}
                                    label='Accepted'
                                    className={`btn-primary-rounded !shadow-none mr-2 !bg-[#a7eae0] !text-black ${historyFilterStatus === 'Accepted' ? '!opacity-100' : 'opacity-75'}`}
                                />
                                <Button
                                    onClickButton={() => onClickHistoryStatus('Missing')}
                                    label='Missing'
                                    className={`btn-primary-rounded !shadow-none mr-2 !bg-[#cc2149] ${historyFilterStatus === 'Missing' ? '' : 'opacity-75'}`}
                                />
                                <Button
                                    onClickButton={() => onClickHistoryStatus('Rejected')}
                                    label='Rejected'
                                    className={`btn-primary-rounded !shadow-none mr-2 !bg-[#ff3b30] ${historyFilterStatus === 'Rejected' ? '' : 'opacity-75'}`}
                                />
                                <Button
                                    onClickButton={() => onClickHistoryStatus('Canceled')}
                                    label='Canceled'
                                    className={`btn-primary-rounded !shadow-none !bg-[#d41106] ${historyFilterStatus === 'Canceled' ? '' : 'opacity-75'}`}
                                />

                            </>
                        }
                    </div>
                </div>
                :
                <div className='flex flex-row items-center'>
                    <img src={'/assets/shared/filter.svg'} className={'w-[13px] object-contain'} />
                    <p className='text-[#8F8F8F] text-sm font-bold ml-2 mr-4'>Filters:</p>
                    {RECENT_CHANGES_ITEMS?.length > 0 && RECENT_CHANGES_ITEMS?.map((item, index) => {
                        return (
                            <Button
                                key={index}
                                onClickButton={() => onChangeRecentFilter(item?.name)}
                                label={item?.name}
                                className={`${recentSelected === item?.name ? 'btn-filters' : 'btn-noactive-filters'} mr-2 !text-[13px] !font-bold`}
                            />
                        )
                    })}
                </div>
            }
        </div>
    )
}

export default OrderFilters