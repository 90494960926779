import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import { IPostageFees } from '../../../../interfaces/postage-fees/IPostageFees';
import { PaginatedPostageFees } from '../../../../interfaces/postage-fees/paginatedStore.type';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';

const tryToFetchPostageFees = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedPostageFees>> => {
    const url = `${USERS_API_URL}/postage-fees/all?page=${pageNumber}&size=${pageSize}`
    return brokersAxios.put<PaginatedPostageFees>(url, data);
};

const tryToFetchSinglePostageFee = (postageId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/postage-fees/${postageId}/postage-fee`);
};

const tryToDownloadPostageFees = (): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/postage-fees/all`, { includePagination: false });
};

const tryToAddPostageFee = (data: IPostageFees): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/postage-fees`, data);
};

const tryToDeletePostageFee = (postageId: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/postage-fees/${postageId}/delete`);
};

const tryToEnablePostageFee = (postageId: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/postage-fees/${postageId}/enable`);
};

const tryToEditPostageFee = (postageId: string, payload: IPostageFees): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/postage-fees/${postageId}/edit`, payload);
};

const tryToImportPostageFees = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/postage-fees/import`, { data });
};

export const PostageFeesService = {
    tryToFetchPostageFees,
    tryToFetchSinglePostageFee,
    tryToDownloadPostageFees,
    tryToAddPostageFee,
    tryToImportPostageFees,
    tryToDeletePostageFee,
    tryToEnablePostageFee,
    tryToEditPostageFee
};
