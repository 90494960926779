import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';
import { IBrokerNotifications } from '../../../../interfaces/notification-sections/IBrokerNotifications';


const tryToFetchBrokerNotificationSettings = (): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/notifications/partner`);
};

const tryToChangeBrokerNotificationSettings = (data: IBrokerNotifications): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/notifications/partner`, data);
};

export const BrokerNotificationsService = {
    tryToFetchBrokerNotificationSettings,
    tryToChangeBrokerNotificationSettings
};
