import React, { useState } from 'react';
import Select, { components } from 'react-select';


interface IMarketsSelectSearch {
    options: any;
    selectedOption?: string | string[] | any;
    placeholder?: string;
    dataQa?: string;
    contentContainer?: string;
    iconContainer?: string;
    hideChevron?: boolean;
    labelContainer?: string;
    onChangeSelectedOption: (e: any) => void;
}

const Svg = (p: any) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        focusable='false'
        role='presentation'
        {...p}
    />
);
const DropdownIndicator = () => (
    <div style={{ color: '#a4a4a4', height: 24, width: 32 }}>
        {/* <Svg>
            <path
                d='M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'
                fill='currentColor'
                fillRule='evenodd'
            />
        </Svg> */}
    </div>
);
const ChevronDown = () => (
    <Svg style={{ marginRight: -6 }}>
        <path
            d='M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z'
            fill='#464e5f'
            fillRule='evenodd'
        />
    </Svg>)

const Menu = (props: any) => {
    return (
        <div
            className={'bg-white rounded-2  mt-4 bottom-0 left-0 top-4 right-0 absolute z-20'}
            {...props}
        />
    );
};
const Blanket = (props: any) => (
    <div
        className={'bottom-0 left-0 top-0 right-0 fixed z-10'}
        {...props}
    />
);
const Dropdown = ({ children, isOpen, target, onClose }: any) => (
    <div style={{ position: 'relative' }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);

const SelectSearchInside = ({
    options,
    selectedOption,
    placeholder,
    contentContainer,
    iconContainer,
    hideChevron,
    labelContainer,
    onChangeSelectedOption
}: IMarketsSelectSearch) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const { Option } = components;
    const IconOption = (props: any) => (
        <Option {...props} >
            <div className='flex flex-row items-center'>
                <img
                    src={props.data.icon}
                    style={{ width: 15, marginRight: 5 }}
                    alt={props.data.label}
                />
                <p>
                    {props.data.label}
                </p>
            </div>
        </Option>
    );

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    };
    const onSelectChange = (value: any) => {
        toggleOpen();
        onChangeSelectedOption(value)
    };

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            position: 'absolute',
            top: 1,
            left: 0,
            zIndex: 100,
            right: 0,
            boxShadow: 'none',
            backgroundColor: 'white',
            border: '0px solid white',
            fontWeight: 600,
            color: '#464e5f',
            maxHeight: 40,
            paddingRight: 0,
            marginRight: 0,
            fontSize: 15,
            borderRadius: 0,
            marginTop: hideChevron ? 20 : 4,
            paddingTop: 2,
            borderBottom: '0.6px solid #a4a4a4',
            paddingBottom: 2,
            '&:hover': {
                cursor: 'text'
            },
            '&focus': {
                outline: 'none'
            }
        }),
        placeholder: (base: any) => ({
            ...base,
            color: '#464e5f',
            fontSize: 15,
            fontWeight: 600,
        }),
        menu: (base: any, state: any) => ({
            ...base,
            maxHeight: 130,
            marginTop: hideChevron ? 60 : 44,
            boxShadow: '-3px -5px 55px -8px rgba(168,168,168,1)'

        }),
        menuList: (base: any, state: any) => ({
            ...base,
            maxHeight: 130
        }),
        dropdownIndicator: (base: any) => ({
            ...base,
            paddingLeft: 3,
            paddingRight: 5,
            width: 26,
            color: '#464e5f' // Custom colour
        }),
        option: (base: any, state: any) => ({
            ...base,
            borderBottom: '0.6px solid #a4a4a4',
            fontWeight: 600,
            cursor: 'pointer',
            backgroundColor: 'white',
            color: '#464e5f',
            '&:hover': {
                backgroundColor: '#f4f4f4'
            }
        })
    }

    return (
        <Dropdown
            isOpen={isOpen}
            onClose={toggleOpen}
            target={
                <div
                    data-qa={'counties-list'}
                    onClick={toggleOpen}
                    className='bg-[#ebedef] flex flex-row items-center justify-between px-2 py-2 cursor-pointer !rounded-lg'>
                    <div
                        className={`bg-[#ebedef] flex flex-row items-center font-semibold text-[#464e5f] font-[15px] rounded-1 ${contentContainer || ''}`}>
                        <img
                            src={selectedOption?.icon}
                            style={{ width: 15, marginRight: 5 }}
                            className={`${iconContainer || ''}`}
                            alt={selectedOption?.label}
                        />
                        <div className={labelContainer || ''}>
                            {selectedOption?.label ? `${selectedOption?.label}` : `${placeholder}`}
                            {hideChevron && <ChevronDown />}
                        </div>
                    </div>
                    {!hideChevron && <ChevronDown />}
                </div>
            }
        >
            <Select
                autoFocus
                styles={customStyles}
                backspaceRemovesValue={false}
                components={{ DropdownIndicator, IndicatorSeparator: null, Option: IconOption }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                menuIsOpen
                onChange={onSelectChange}
                options={options}
                placeholder='Search...'
                tabSelectsValue={false}
                value={selectedOption || null}
            />
        </Dropdown>
    );
}

export default SelectSearchInside;