import { AxiosResponse } from 'axios';
import { ASSETS_API_URL} from '../../../../config';
import { PaginatedUIActions } from '../../../../interfaces/ui-actions/paginatedStore.type';
import { IEditUIAction } from '../../../../interfaces/ui-actions/IEditUIAction';
import assetsAxios from '../../../../utils/axios/assets.axios';

const tryToFetchAllUIActions = (filters? : any) :Promise<AxiosResponse> => {
    const url = `${ASSETS_API_URL}/ui-actions/`
    return filters
     ? assetsAxios.put(url, {filters})
     : assetsAxios.put(url)
}

const tryToFetchUIActions = (
    pageNumber: number,
    pageSize: number,
    filters?: any,
): Promise<AxiosResponse<PaginatedUIActions>> => {
    const url = `${ASSETS_API_URL}/ui-actions/paginated?page=${pageNumber}&size=${pageSize}`
    return filters
        ? assetsAxios.put(url, {filters}) 
        : assetsAxios.put(url)
}

const tryToEditUIAction = (
    id: string,
    payload: IEditUIAction,
) => {
    return assetsAxios.put(`${ASSETS_API_URL}/ui-actions/${id}/edit`, payload)
}

const tryToCreateUIAction = ( data: IEditUIAction ):Promise<AxiosResponse> => {
    return assetsAxios.post(`${ASSETS_API_URL}/ui-actions`, data)
}
const tryToDeleteUIAction = (id:string):Promise<AxiosResponse> => {
    return assetsAxios.delete(`${ASSETS_API_URL}/ui-actions/${id}`)
}
const tryToFetchSingleUIAction = (id: string):Promise<AxiosResponse> => {
    return assetsAxios.get(`${ASSETS_API_URL}/ui-actions/${id}`)
}

export const uiActionsService = {
    tryToFetchAllUIActions,
    tryToFetchUIActions,
    tryToEditUIAction,
    tryToCreateUIAction,
    tryToDeleteUIAction,
    tryToFetchSingleUIAction,
}

