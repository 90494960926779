import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { PayServicesService } from './payServicesApi';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPayServicesSchema } from '../../../../interfaces/pay-services/IPayServicesSchema';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPayServicesListState } from '../../../../interfaces/pay-services/IPayServicesList';
import { PaginatedPayServices } from '../../../../interfaces/pay-services/paginatedStore.type';

const initialState: IPayServicesListState = {
    payServiceIsLoading: false,
    payServicesAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchPayServices = createAsyncThunk<
    PaginatedPayServices,
    IPaginationPayload
>('pay-service/tryToFetchPayServices', async ({ pageNumber, pageSize, data }) => {
    const result = await PayServicesService.tryToFetchPayServices(
        pageNumber,
        pageSize,
    );
    return result?.data;
});

export const tryToFetchSinglePayService = createAsyncThunk<ApiResponse<IPayServicesSchema>, string>(
    'pay-service/tryToFetchSinglePayService',
    async (id: string) => {
        const result = await PayServicesService.tryToFetchSinglePayService(id);
        return result?.data;
    },
);

export const tryToFetchAllPayServices = createAsyncThunk<any, null | undefined>(
    'pay-service/tryToFetchAllPayServices',
    async () => {
        const result = await PayServicesService.tryToFetchAllPayServices();
        return result?.data;
    },
);

export const tryToEditPayService = createAsyncThunk<
    ApiResponse<IPayServicesSchema>,
    IPayServicesSchema
>('pay-service/tryToEditPayService', async (data: IPayServicesSchema, { rejectWithValue }) => {
    try {
        const result = await PayServicesService.tryToEditPayService(
            data._id || '',
            data,
        );
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToAddPayService = createAsyncThunk<
    ApiResponse<IPayServicesSchema>,
    IPayServicesSchema
>('pay-service/tryToAddPayService', async (data: IPayServicesSchema, { rejectWithValue }) => {
    try {
        const result = await PayServicesService.tryToAddPayService(data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToDeletePayService = createAsyncThunk<
    ApiResponse<IPayServicesSchema>,
    string
>('pay-service/tryToDeletePayService', async (id, { rejectWithValue }) => {
    try {
        const results = await PayServicesService.tryToDeletePayService(id);
        return results.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const payServicesSlice = createSlice({
    name: 'payServices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchPayServices
            .addCase(tryToFetchPayServices.pending, (state) => {
                state.payServicesAreLoading = true;
            })
            .addCase(tryToFetchPayServices.rejected, (state) => {
                state.payServicesAreLoading = false;
            })
            .addCase(tryToFetchPayServices.fulfilled, (state, action) => {
                state.payServicesAreLoading = false;
                state.payServices = action.payload;
            })

            // tryToAddPayService
            .addCase(tryToAddPayService.pending, (state) => {
                state.payServiceIsLoading = true;
            })
            .addCase(tryToAddPayService.rejected, (state) => {
                state.payServiceIsLoading = false;
            })
            .addCase(tryToAddPayService.fulfilled, (state, action) => {
                state.payServiceIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.payServices?.data)
                    state.payServices.data.elements = [
                        ...state.payServices?.data.elements,
                        data,
                    ];
            })

            // tryToDeletePayService
            .addCase(tryToDeletePayService.pending, (state) => {
                state.payServiceIsLoading = true;
            })
            .addCase(tryToDeletePayService.rejected, (state) => {
                state.payServiceIsLoading = false;
            })
            .addCase(tryToDeletePayService.fulfilled, (state, action) => {
                state.payServiceIsLoading = false;
                state.requestStatus = 'success';
                if (state.payServices?.data.elements)
                    state.payServices.data.elements =
                        { ...state }.payServices?.data.elements.filter(
                            (payService) =>
                                payService?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToEditPayService
            .addCase(tryToEditPayService.pending, (state) => {
                state.payServiceIsLoading = true;
            })
            .addCase(tryToEditPayService.rejected, (state) => {
                state.payServiceIsLoading = false;
            })
            .addCase(tryToEditPayService.fulfilled, (state, action) => {
                state.payServiceIsLoading = false;
                if (state.payServices?.data.elements)
                    state.payServices.data.elements =
                        { ...state }.payServices?.data.elements.map((payService) => {
                            if (payService?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return payService;
                            }
                        }) || [];
                state.payService = action.payload.data;
            })

            // tryToFetchSinglePayService
            .addCase(tryToFetchSinglePayService.pending, (state) => {
                state.payServiceIsLoading = true;
            })
            .addCase(tryToFetchSinglePayService.rejected, (state) => {
                state.payServiceIsLoading = false;
            })
            .addCase(tryToFetchSinglePayService.fulfilled, (state, action) => {
                state.payServiceIsLoading = false;
                state.payService = action.payload?.data;
            });
    },
});

export default payServicesSlice.reducer;
