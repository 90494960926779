import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import {
    tryToAddMarket,
    tryToDisableMarket
} from '../../../../store/brokers/admin/partners/partnersSlice';
import { IToast } from '../../../../interfaces/components/IToast';
import ChangeMarketStatusModal from '../modals/ChangeMarketStatusModal';
import MarketItem from '../details/MarketItem';
import WhiteContainer from '../../../../shared/white-container';
import Toast from '../../../../shared/toast';


interface IMarkets {
    allMarkets: Array<any>;
    brokerMarkets: Array<any>;
    accessControl?: any;
    pageAccess?: any;
    onSuccessfullFinishAction: (type: string, marketId: string) => void;
}

const Markets = ({
    allMarkets,
    brokerMarkets,
    accessControl,
    pageAccess,
    onSuccessfullFinishAction
}: IMarkets) => {
    const dispatch = useAppDispatch()
    const [showApproveActionModal, setShowApproveActionModal] = useState<{ status: boolean, market: string, open: boolean }>()
    const [showToast, setShowToast] = useState<IToast | null>()

    const onShowHideMarketStatusModal = (market?: string, marketOpen?: boolean) => {
        setShowApproveActionModal({
            status: !showApproveActionModal?.status,
            market: market || '',
            open: marketOpen || false
        })
    }

    const onApproveAction = async () => {
        const role = localStorage.getItem('roles')
        if (role !== 'partners' || !(!accessControl || pageAccess?.actions?.['Enable Disable Market'])) {
            setShowToast({ type: 'error', message: 'You have no permission to make changes!' });
            onShowHideMarketStatusModal();
            return
        }
        try {
            if (showApproveActionModal?.open) {
                await dispatch(tryToDisableMarket(showApproveActionModal?.market || ''))
            } else {
                const data: any = {
                    id: showApproveActionModal?.market || ''
                }
                await dispatch(tryToAddMarket(data))
            }
            onSuccessfullFinishAction(showApproveActionModal?.open ? 'disable' : 'enable', showApproveActionModal?.market || '')
            setShowToast({ type: 'success', message: `Market successfully ${showApproveActionModal?.open ? 'disabled' : 'enabled'}` });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onShowHideMarketStatusModal()
    }

    return (
        <div>
            <WhiteContainer type={'rounded'}>
                <div>
                    <p className='font-bold text-md mb-3'>Enable countries you want to be active on.</p>
                    <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-4'>
                        {(allMarkets && allMarkets?.length > 0) && allMarkets?.map((market: any, index: number) => {
                            const isMarketOpen = brokerMarkets?.find((brokerMarket) => brokerMarket?.market?._id === market?._id)
                            return (
                                <MarketItem
                                    onClickMarket={onShowHideMarketStatusModal}
                                    marketName={market?.label}
                                    marketFlag={market?.flagUrl}
                                    marketId={market?._id}
                                    marketOpen={!!isMarketOpen}
                                    key={index}
                                />
                            )
                        })}
                    </div>
                </div>
            </WhiteContainer>
            {showApproveActionModal?.status &&
                <ChangeMarketStatusModal
                    openMarketStatusModal={showApproveActionModal?.status || false}
                    handleCloseModal={onShowHideMarketStatusModal}
                    onApproveAction={onApproveAction}
                    disabledType={!showApproveActionModal?.open || false}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    )
}

export default Markets;