import React from 'react';
import Modal from '../../../../shared/modal';


interface IAllCategories {
    openModal: boolean
    categories: any
    handleCloseModal: () => void
}

const AllCategoriesModal = ({
    openModal, 
    categories, 
    handleCloseModal
}:IAllCategories) => {

    const onCloseModal = () => {
        handleCloseModal()
    }

    return (
        <Modal 
            open={openModal} 
            onClose={onCloseModal} 
            showInRight 
            contentContainerStyle={'min-w-[650px]'}>
            <div className='p-4 min-w-[600px]'>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Categories</p>
                </div>
                <div>
                    <div className='bg-[#f8f9fc] rounded p-3 mb-2'>
                        <div className={'mt-2 overflow-hidden transition-max-h ease-out duration-300 max-h-auto'}>
                            {categories?.length > 0 && categories.map((key: any, index: number) => {
                                return (
                                    <div key={index} className='mb-2 px-3 py-2 bg-[#f8f9fc] rounded'>
                                        <div className='bg-white p-2'>
                                            <p>{key?.code}</p>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AllCategoriesModal