import React, { useEffect, useLayoutEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IOrders } from '../../../interfaces/orders/IOrders';
import { IToast } from '../../../interfaces/components/IToast';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import { IPayServices } from '../../../interfaces/pay-services/IPayServices';
import { IOrderFilterBody } from '../../../interfaces/orders/IOrderFilterBody';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToAcceptOrder,
    tryToFetchCashouts,
    tryToFetchInboundOrders,
    tryToFetchOrders,
    tryToFetchOrdersCount,
    tryToFetchOrdersInCs,
    tryToFetchOrdersInReview,
    tryToFetchRecentOrders,
    tryToFetchResolvedOrders,
    tryToFindItemByTrackingNumber,
} from '../../../store/brokers/partners/orders/ordersSlice';
import {
    tryToChangeActiveMarket,
    tryToFindOperatingMarkets,
} from '../../../store/brokers/admin/partners/partnersSlice';
import {
    useGetAllCashoutsMutation,
    useGetAllInboundShipmentsMutation,
    useGetAllResolvedOrdersMutation,
    useGetAllShipmentsInCsMutation,
    useGetAllShipmentsInReviewMutation,
    useGetAllShipmentsMutation,
} from '../../../store/brokers/partners/orders/shippmentsAPI';
import { useUserGetProfileQuery } from '../../../store/user/userDomApi';
import { tryToFetchAllPayServices } from '../../../store/brokers/admin/pay-services/payServicesSlice';
// import { useCarbonImpactForItemMutation } from '../../../store/brokers/partners/carbon-impact/CarbonImpactApi';
import { INVENTORY_FILTERS } from '../../../utils/constants/inventory-filter-values';
import { RECENT_CHANGES_ITEMS } from '../../../utils/constants/orders-items-consts';
import OrderCategories from './details/OrderCategories';
import OrderNotFound from './details/OrderNotFound';
import OrderFilters from './details/OrderFilters';
import OrdersTable from './tables/OrdersTable';
// import EmissionAvoidedModal from './modals/EmissionAvoidedModal';
import AcceptWithImeiModal from './modals/AcceptWithImeiModal';
import AcceptPackageModal from './modals/AcceptPackageModal';
import RevisedOfferModal from './modals/RevisedOfferModal';
import ActionCheckModal from './modals/ActionCheckModal';
import ImeiModal from './modals/ImeiModal';
import SelectCheckbox from '../../../shared/select-checkbox';
import Button from '../../../shared/button';
import Input from '../../../shared/input';
import Toast from '../../../shared/toast';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Orders = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const query = useQuery();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<Array<IOrders>>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    // const [emissionValue, setEmissionValue] = useState<any>();
    const [filterSelected, setFilterSelected] = useState<string | undefined>(query?.get('search') ? undefined : 'Not Shipped');
    const [statusSelected, setStatusSelected] = useState<string>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: undefined,
    });
    const [showActionCheckModal, setShowActionCheckModal] = useState<{
        show: boolean;
        text: string;
        title: string;
        orderId: string;
        initialCategory: any;
        itemId: string;
        type: string;
        error: boolean;
        imei?: any;
        descriptor?: any;
        warehouseId?: string;
    }>();
    const [historyFilterStatus, setHistoryFilterStatus] = useState<string>();
    const [recentFilterStatus, setRecentFilterStatus] = useState<string>();
    const [showImeiModal, setShowImeiModal] = useState<boolean>(false);
    const [orderItemId, setOrderItemId] = useState<string>();
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [selectedSource, setSelectedSource] = useState<string>();
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>();
    const [payServices, setPayServices] = useState<any>();
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [ordersToExport, setOrdersToExport] = useState<Array<any>>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [inventorySelected, setInventorySelected] = useState<string>();
    const [loadingOrders, setLoadingOrders] = useState<boolean>(true);
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>();
    const [searchValue, setSearchValue] = useState<any>();
    const [userData, setUserData] = useState<any>();
    const [showAcceptPackageModal, setshowAcceptPackageModal] = useState<boolean>(false);
    // const [showEmissionModal, setShowEmissionModal] = useState<boolean>(false)
    const [loadingShippment, setLoadingShippment] = useState<boolean>(false);
    const [pageAccess, setPageAccess] = useState<any>();
    const [usernameValue, setUsernameValue] = useState<any>();
    const [postcodeValue, setPostcodeValue] = useState<any>();
    const [revisedOfferModal, setRevisedOfferModal] = useState()
    const state = useAppSelector((state) => state.orders);
    const ordersCount = useAppSelector((state) => state?.orders.ordersCount);
    const stateMarkets = useAppSelector((state) => state.partners?.partnerMarkets);
    const paginationData = useAppSelector((state) => state?.orders?.orders?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const { data: user } = useUserGetProfileQuery();
    const [getAllCashouts] = useGetAllCashoutsMutation();
    const [getAllOrdersNoPagination] = useGetAllShipmentsMutation();
    const [getAllOrdersInCSNoPagination] = useGetAllShipmentsInCsMutation();
    const [getAllInboundOrdersNoPagination] = useGetAllInboundShipmentsMutation();
    const [getAllRejsolvedOrdersNoPagination] = useGetAllResolvedOrdersMutation();
    const [getAllOrdersInReviewNoPagination] = useGetAllShipmentsInReviewMutation();
    // const [carbonImpactForItem] = useCarbonImpactForItemMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Order Items');
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '');
        setPageAccess(findChild || undefined);
    }, [accessControl]);

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined,
        });
        searchParams.set('page', '1');
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    };

    const getBrokerMarketsAndSectors = async () => {
        setUserData(user?.data || undefined);
        const formatSectors: any = (user?.data?.sectors?.length > 0) ? user?.data?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : [];
        const filterSectors: any = formatSectors && formatSectors?.length > 0 && formatSectors?.filter((s: any) => (s?.label || '').toLowerCase() !== 'mens wear' && (s?.label || '').toLowerCase() !== 'womens wear');
        setBrokerCategories(filterSectors || []);
        if (!selectedCategory) {
            setSelectedCategory(query?.get('category') || filterSectors?.[0]?.value);
            onResetPaginationSettings();
        }
        const payServicesResponse = await dispatch(tryToFetchAllPayServices()).unwrap();
        const formatPayServices = payServicesResponse && payServicesResponse?.data && payServicesResponse?.data?.length > 0 && payServicesResponse?.data?.map((service: IPayServices) => ({ ...service, label: service?.displayName, value: service?._id }));
        if (query?.get('source') && query?.get('source') !== selectedSource) {
            setSelectedSource(query?.get('source') || undefined);
            onResetPaginationSettings();
        }
        setPayServices(formatPayServices || []);
    };

    useEffect(() => {
        getBrokerMarketsAndSectors();
        dispatch(tryToFindOperatingMarkets(null));
    }, []);

    useEffect(() => {
        getBrokerMarketsAndSectors();
        dispatch(tryToFindOperatingMarkets(null));
    }, [user]);

    useLayoutEffect(() => {
        const paginationStateFormat = paginationState;
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1);
        }
        if (query?.get('size') && Number(query?.get('size')) !== paginationState?.pageSize) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10);
        }
        if (query?.get('search') && query?.get('search') !== paginationState?.filters) {
            paginationStateFormat.filters = query.get('search') || undefined;
            setSearchValue(query.get('search') || undefined);
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat);
        }
        if (query?.get('filter')) {
            switch (query?.get('filter')) {
                case 'inbound':
                case 'Inbound':
                    setFilterSelected('Inbound');
                    break;
                case 'shipped':
                case 'Shipped':
                    setFilterSelected('Shipped');
                    break;
                case 'not_shipped':
                case 'Not Shipped':
                    setFilterSelected('Not Shipped');
                    break;
                case 'Delivered':
                    setFilterSelected('Delivered');
                    break;
                case 'review':
                case 'In Review':
                    setFilterSelected('In Review');
                    break;
                case 'Awaiting User Response':
                    setFilterSelected('Awaiting User Response');
                    break;
                case 'Awaiting CS Response':
                    setFilterSelected('Awaiting CS Response');
                    break;
                case 'Required Return':
                    setFilterSelected('Required Return');
                    break;
                case 'Returned':
                case 'returned':
                    setFilterSelected('Returned');
                    break;
                case 'cs':
                case 'CS':
                    setFilterSelected('CS');
                    break;
                case 'history':
                case 'History':
                    setStatusSelected('History');
                    break;
                case 'recent':
                case 'Recent Changes':
                case 'Recent':
                    setStatusSelected('Recent Changes');
                    break;
                case 'rejected':
                case 'Rejected':
                    setStatusSelected('History');
                    setHistoryFilterStatus('Rejected');
                    break;
                case 'canceled':
                case 'Canceled':
                    setStatusSelected('History');
                    setHistoryFilterStatus('Canceled');
                    break;
                case 'accepted':
                case 'Accepted':
                    setFilterSelected('Accepted');
                    break;
                case 'missing':
                case 'Missing':
                    setFilterSelected('Missing');
                    break;
                case 'Price Revaluation Accepted':
                case 'Recent Delivered':
                case 'CS Ticket Response':
                case 'Revaluation Awaiting Response':
                    setStatusSelected('Recent Changes');
                    setRecentFilterStatus(
                        query?.get('filter') === 'Recent Delivered'
                            ? 'Delivered'
                            : query?.get('filter') || '',
                    );
                    break;
            }
        }
        if (query?.get('category') && query?.get('category') !== selectedCategory) {
            setSelectedCategory(query?.get('category') || undefined);
        }
        if (query?.get('source') && query?.get('source') !== selectedSource) {
            setSelectedSource(query?.get('source') || undefined);
        }
        if (query?.get('postcode') && query?.get('postcode') !== postcodeValue) {
            setPostcodeValue(query?.get('postcode') || undefined);
        }
        if (query?.get('username') && query?.get('username') !== usernameValue) {
            setUsernameValue(query?.get('username') || undefined);
        }
        if (query?.get('inventory') && query?.get('inventory') !== inventorySelected) {
            setInventorySelected(query?.get('inventory') || undefined);
        }
    }, [
        query?.get('page'),
        query?.get('size'),
        query?.get('filter'),
        query?.get('inventory'),
        query?.get('source'),
        query?.get('postcode'),
        query?.get('username'),
        query?.get('search'),
        query?.get('category'),
    ]);

    const getOrdersData = async () => {
        setLoadingOrders(true);
        if (!selectedCategory || !activeMarket || (query?.get('inventory') && !inventorySelected) || (query?.get('source') && !selectedSource) || (selectedSource && !payServices)) {
            return;
        }
        const data: IOrderFilterBody = {};
        if (activeMarket) {
            data.country = activeMarket?.market?.name;
            data.marketId = activeMarket?.market?._id;
        }
        if (selectedCategory) {
            const findCategory = brokerCategories && brokerCategories?.length > 0 && brokerCategories?.find((category) => category?.value === selectedCategory);
            data.category = {
                id: findCategory?.id,
                name: selectedCategory,
            };
        }
        if (inventorySelected) {
            data.location = inventorySelected === 'Yes';
        }
        if (selectedSource && payServices) {
            const findSource = payServices && payServices?.length > 0 && payServices?.find((item: any) => item?.label === selectedSource);
            data.sourceOrder = findSource?.name;
        }
        if (usernameValue) {
            data.username = usernameValue;
        }
        if (postcodeValue) {
            data.postCode = postcodeValue;
        }
        if (historyFilterStatus) {
            switch (historyFilterStatus) {
                case 'Accepted':
                    data.status = 'reviewed';
                    break;
                case 'Canceled':
                    data.status = 'canceled';
                    break;
                case 'Rejected':
                    data.status = 'rejected';
                    break;
                case 'Missing':
                    data.status = 'missing';
                    break;
            }
        }
        if (recentFilterStatus) {
            const findFilter = RECENT_CHANGES_ITEMS?.find((item) => item?.name === recentFilterStatus);
            data.status = findFilter?.status;
        }
        if (filterSelected === 'Awaiting User Response') {
            data.status = 'revaluated';
        }
        if (filterSelected === 'Awaiting CS Response') {
            data.status = 'awaiting_cs_response';
        }
        if (filterSelected === 'In Review') {
            data.status = 'cashed_out';
        }
        if (filterSelected === 'Returned') {
            data.status = 'returned';
        }
        if (filterSelected === 'Required Return') {
            data.status = 'required_return';
        }
        if (filterSelected === 'CS') {
            data.status = 'cs';
        }
        if (filterSelected === 'Accepted') {
            data.status = 'reviewed';
        }
        if (filterSelected === 'Missing') {
            data.status = 'missing';
        }
        if (filterSelected === 'Shipped') {
            data.status = 'shipped';
        }
        if (filterSelected === 'Not Shipped') {
            data.status = 'not_shipped';
        }
        const resolvedData = { ...paginationState, data };
        searchParams.set('page', `${paginationState?.pageNumber}`);
        searchParams.set('size', `${paginationState?.pageSize}`);
        setSearchParams(searchParams);
        switch (filterSelected || statusSelected) {
            case 'Inbound':
                dispatch(tryToFetchInboundOrders(resolvedData)).unwrap();
                break;
            case 'Shipped':
                dispatch(tryToFetchCashouts(resolvedData)).unwrap();
                break;
            case 'Not Shipped':
                dispatch(tryToFetchCashouts(resolvedData)).unwrap();
                break;
            case 'Delivered':
                dispatch(tryToFetchOrdersInReview(resolvedData)).unwrap();
                break;
            case 'In Review':
                dispatch(tryToFetchOrdersInReview(resolvedData)).unwrap();
                break;
            case 'CS':
                dispatch(tryToFetchOrdersInCs(resolvedData)).unwrap();
                break;
            case 'Awaiting User Response':
                dispatch(tryToFetchOrdersInReview(resolvedData)).unwrap();
                break;
            case 'Awaiting CS Response':
                dispatch(tryToFetchOrdersInReview(resolvedData)).unwrap();
                break;
            case 'Required Return':
                dispatch(tryToFetchResolvedOrders(resolvedData)).unwrap();
                break;
            case 'Returned':
                dispatch(tryToFetchResolvedOrders(resolvedData)).unwrap();
                break;
            case 'Accepted':
                dispatch(tryToFetchResolvedOrders(resolvedData)).unwrap();
                break;
            case 'Missing':
                dispatch(tryToFetchResolvedOrders(resolvedData)).unwrap();
                break;
            case 'History':
                dispatch(tryToFetchResolvedOrders(resolvedData)).unwrap();
                break;
            case 'Recent Changes':
                dispatch(tryToFetchRecentOrders(resolvedData)).unwrap();
                break;
            default:
                dispatch(tryToFetchOrders(resolvedData)).unwrap();
                break;
        }
    };

    useEffect(() => {
        if (state.orders) {
            const rowsData = state.orders?.data?.elements && Array.isArray(state.orders?.data.elements)
                ? state.orders?.data.elements.map((r) => ({
                    ...r,
                }))
                : [];
            if (JSON.stringify(rows) !== JSON.stringify(rowsData)) {
                setRows(rowsData);
            }
            setLoadingOrders(false);
        } else if (rows && !state?.orders) {
            setRows([]);
            setTimeout(() => {
                setLoadingOrders(false);
            }, 1500);
        }
    }, [state.orders]);

    const onShowHideImeiModal = (itemId?: string) => {
        if (!showImeiModal) {
            setOrderItemId(itemId);
        }
        setShowImeiModal(!showImeiModal);
    };

    const onChangeSearchValue = async (value: string | null | undefined, type: string,) => {
        setSearchValue(value);
    };

    const onChangeUsernameSearchValue = async (value: string | null | undefined) => {
        setUsernameValue(value);
    };

    const onChangePostCodeSearchValue = async (value: string | null | undefined) => {
        setPostcodeValue(value);
    };

    const onEnter = async (e: any) => {
        if (e.key === 'Enter') {
            onResetPaginationSettings();
            getOrdersData();
        }
    };

    const onEnterSearchInput = async (e: any) => {
        if (e?.key === 'Enter') {
            setLoadingOrders(true);
            let response: any;
            if (searchValue && searchValue !== '') {
                try {
                    response = await dispatch(tryToFindItemByTrackingNumber(searchValue || '')).unwrap();
                    if (response) {
                        const findCategory = response && response?.catalog && brokerCategories && brokerCategories?.length > 0 && brokerCategories?.find((category) => (category?.name || '').toLowerCase() === (response?.catalog?.code === 'womenswear' || response?.catalog?.code === 'menswear' ? 'clothings' : response?.catalog?.code || '').toLowerCase());
                        if (findCategory) {
                            searchParams.set('category', findCategory?.value || '');
                            setSelectedCategory(findCategory?.value);
                        }
                        if (stateMarkets && stateMarkets?.length > 0) {
                            const findMarket = stateMarkets?.find((market) => market?.market?.name === response?.country);
                            if (findMarket) {
                                dispatch(tryToChangeActiveMarket({ marketId: findMarket?.market?._id || '' }));
                            }
                        }
                        if (response?.status) {
                            switch (response?.status) {
                                case 'shipped':
                                case 'Shipped':
                                    setFilterSelected('Shipped');
                                    searchParams.set('filter', 'Shipped');
                                    break;
                                case 'not_shipped':
                                case 'Not Shipped':
                                    setFilterSelected('Not Shipped');
                                    searchParams.set('filter', 'Not Shipped');
                                    break;
                            }
                        }
                    }
                } catch (err) {
                    setLoadingOrders(false);
                }
            }
            setPaginationState({
                ...(paginationState || {}),
                pageNumber: 1,
                filters: searchValue || undefined,
            });
            if (searchValue) searchParams.set('search', searchValue);
            else searchParams.delete('search');
            searchParams.set('page', '1');
            if (!response?.status) {
                searchParams.delete('filter');
                setFilterSelected(undefined);
            }
            setSearchParams(searchParams);
        }
    };

    const onChangeFilterSelected = (filter: string) => {
        setStatusSelected(undefined);
        setHistoryFilterStatus(undefined);
        setFilterSelected(filterSelected === filter ? undefined : filter);
        if (filterSelected === filter) searchParams.delete('filter');
        else searchParams.set('filter', filter);
        setSearchParams(searchParams);
        onResetPaginationSettings();
    };

    const getOrderCounts = async () => {
        if (activeMarket?.market?._id) {
            await dispatch(tryToFetchOrdersCount({ marketId: activeMarket?.market?._id, country: activeMarket?.market?.name })).unwrap();
        }
    };

    useEffect(() => {
        if (activeMarketState && JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket)) {
            setActiveMarket(activeMarketState || undefined);
            onResetPaginationSettings();
        }
    }, [activeMarketState]);

    useEffect(() => {
        if (activeMarket && selectedCategory && paginationState) {
            getOrdersData();
        } else {
            setLoadingOrders(true);
        }
        if (activeMarket) {
            getOrderCounts();
        }
    }, [paginationState, activeMarket]);

    const onChangeStatusSelected = (status: string) => {
        setFilterSelected(undefined);
        setStatusSelected(statusSelected === status ? undefined : status);
        setHistoryFilterStatus(undefined);
        setRecentFilterStatus(undefined);
        if (statusSelected !== status) searchParams.set('filter', status);
        else searchParams.delete('filter');
        setSearchParams(searchParams);
        onResetPaginationSettings();
    };

    const onClickHistoryStatus = (status: string) => {
        setHistoryFilterStatus(status === historyFilterStatus ? undefined : status);
        searchParams.set('filter', status === historyFilterStatus ? 'History' : status);
        setSearchParams(searchParams);
        onResetPaginationSettings();
    };

    const onChangeRecentFilter = (status: string) => {
        setRecentFilterStatus(status === recentFilterStatus ? undefined : status);
        searchParams.set('filter', status === recentFilterStatus ? 'Recent' : status === 'Delivered' ? 'Recent Delivered' : status);
        setSearchParams(searchParams);
        onResetPaginationSettings();
    };

    const onSeeOrderDetails = (orderId: string, itemId: string) => {
        navigate(`/order-items/${orderId}/${itemId}`);
    };

    const onSuccessfullyAddedImei = (orderId: string, imei: string) => {
        const formatRows = filterSelected === 'Inbound'
            ? rows?.filter((order: any) => order?.id !== orderId)
            : rows?.map((order: any) => {
                if (order?.id === orderId) {
                    return {
                        ...order,
                        imei: imei,
                        orderStatus: 'device_in_review',
                    };
                } else {
                    return order;
                }
            });
        setRows(formatRows || []);
    };

    const onChangeCategorySelected = (value: string) => {
        setSelectedCategory(value);
        searchParams.set('category', value);
        setSearchParams(searchParams);
        onResetPaginationSettings();
    };

    const onExportOrders = async () => {
        if (loadingDownload) {
            return;
        }
        setLoadingDownload(true);
        const findCategory = brokerCategories && brokerCategories?.length > 0 && brokerCategories?.find((category) => category?.value === selectedCategory);
        if (!selectedCategory || !brokerCategories || !findCategory) {
            setLoadingDownload(false);
            return;
        }
        try {
            const data: any = { download: true };
            if (activeMarket) {
                data.country = activeMarket?.market?.name;
                data.marketId = activeMarket?.market?._id;
            }
            if (selectedCategory) {
                data.category = {
                    id: findCategory?.id,
                    name: selectedCategory,
                };
            }
            if (inventorySelected) {
                data.location = inventorySelected === 'Yes';
            }
            if (usernameValue) {
                data.username = usernameValue;
            }
            if (postcodeValue) {
                data.postCode = postcodeValue;
            }
            if (selectedSource) {
                const findSource = (payServices && payServices?.length > 0) && payServices?.find((item: any) => item?.label === selectedSource);
                data.sourceOrder = findSource?.name || '';
            }
            if (historyFilterStatus) {
                switch (historyFilterStatus) {
                    case 'Accepted':
                        data.status = 'reviewed';
                        break;
                    case 'Canceled':
                        data.status = 'canceled';
                        break;
                    case 'Rejected':
                        data.status = 'rejected';
                        break;
                    case 'Missing':
                        data.status = 'missing';
                        break;
                }
            }
            if (recentFilterStatus) {
                const findFilter = RECENT_CHANGES_ITEMS?.find(
                    (item) => item?.name === recentFilterStatus,
                );
                data.status = findFilter?.status;
            }
            if (filterSelected === 'In Review') {
                data.status = 'cashed_out';
            }
            if (filterSelected === 'Awaiting User Response') {
                data.status = 'revaluated';
            }
            if (filterSelected === 'Awaiting CS Response') {
                data.status = 'awaiting_cs_response';
            }
            if (filterSelected === 'Required Return') {
                data.status = 'required_return';
            }
            if (filterSelected === 'Returned') {
                data.status = 'returned';
            }
            if (filterSelected === 'CS') {
                data.status = 'cs';
            }
            if (filterSelected === 'Accepted') {
                data.status = 'reviewed';
            }
            if (filterSelected === 'Missing') {
                data.status = 'missing';
            }
            if (filterSelected === 'Shipped') {
                data.status = 'shipped';
            }
            if (filterSelected === 'Not Shipped') {
                data.status = 'not_shipped';
            }
            const resolvedData = { ...paginationState, data };
            let response: any = [];
            switch (filterSelected || statusSelected) {
                case 'Inbound':
                    response = await getAllInboundOrdersNoPagination(resolvedData).unwrap();
                    break;
                case 'Shipped':
                    response = await getAllCashouts(resolvedData).unwrap();
                    break;
                case 'Not Shipped':
                    response = await getAllCashouts(resolvedData).unwrap();
                    break;
                case 'Delivered':
                    response = await getAllOrdersInReviewNoPagination(resolvedData).unwrap();
                    break;
                case 'In Review':
                    response = await getAllOrdersInReviewNoPagination(resolvedData).unwrap();
                    break;
                case 'CS':
                    response = await getAllOrdersInCSNoPagination(resolvedData).unwrap();
                    break;
                case 'Awaiting user response':
                    response = await getAllOrdersInReviewNoPagination(resolvedData).unwrap();
                    break;
                case 'Accepted':
                    response = await getAllRejsolvedOrdersNoPagination(resolvedData).unwrap();
                    break;
                case 'Returned':
                    response = await getAllRejsolvedOrdersNoPagination(resolvedData).unwrap();
                    break;
                case 'Required Return':
                    response = await getAllRejsolvedOrdersNoPagination(resolvedData).unwrap();
                    break;
                case 'Missing':
                    response = await getAllRejsolvedOrdersNoPagination(resolvedData).unwrap();
                    break;
                case 'History':
                    response = await getAllRejsolvedOrdersNoPagination(resolvedData).unwrap();
                    break;
                case 'Recent Changes':
                    response = await getAllRejsolvedOrdersNoPagination(resolvedData).unwrap();
                    break;
                default:
                    response = await getAllOrdersNoPagination(resolvedData).unwrap();
                    break;
            }
            setOrdersToExport(response || []);
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
            setLoadingDownload(false);
        }
    };

    useEffect(() => {
        if (ordersToExport && loadingDownload) {
            csvLink.current.link.click();
        }
        setLoadingDownload(false);
    }, [ordersToExport]);

    const clearAllFilters = () => {
        setPaginationState({
            ...paginationState,
            pageNumber: 1,
            filters: undefined,
        });
        setSearchValue(undefined);
        setStatusSelected(undefined);
        setFilterSelected(undefined);
        setHistoryFilterStatus(undefined);
        setInventorySelected(undefined);
        setSelectedSource(undefined);
        setUsernameValue(undefined);
        setPostcodeValue(undefined);
        searchParams.delete('search');
        searchParams.delete('category');
        searchParams.delete('inventory');
        searchParams.delete('postcode');
        searchParams.delete('username');
        searchParams.delete('filter');
        searchParams.delete('source');
        searchParams.set('page', '1');
        setSearchParams(searchParams);
    };

    const onClickInventoryFilter = (value: any, type?: string) => {
        setInventorySelected(value?.value);
        searchParams.set('inventory', value?.value || '');
        setSearchParams(searchParams);
        onResetPaginationSettings();
    };

    const onClickSourceFilter = (value: any, type?: string) => {
        setSelectedSource(value?.label);
        searchParams.set('source', value?.label || '');
        setSearchParams(searchParams);
        onResetPaginationSettings();
    };

    const onToggleAcceptedPackageModal = () => {
        setshowAcceptPackageModal(!showAcceptPackageModal);
    };

    const onAcceptedPackageSuccessfully = (type: string, message: string, item?: any) => {
        setShowToast({ type, message });
        if (item) {
            const findCategory = item && item?.category && brokerCategories && brokerCategories?.length > 0 && brokerCategories?.find((category) => (category?.name || '').toLowerCase() === (item?.category || '').toLowerCase());
            if (findCategory) {
                searchParams.set('category', findCategory?.value || '');
                setSelectedCategory(findCategory?.value);
            }
            if (stateMarkets && stateMarkets?.length > 0) {
                const findMarket = stateMarkets?.find((market) => market?.market?.name === item?.country);
                if (findMarket) {
                    dispatch(tryToChangeActiveMarket({ marketId: findMarket?.market?._id || '' }));
                }
            }
            onResetPaginationSettings();
            setStatusSelected(undefined);
            setFilterSelected(undefined);
            setHistoryFilterStatus(undefined);
            setInventorySelected(undefined);
            setSelectedSource(undefined);
            searchParams.delete('search');
            searchParams.delete('filter');
            searchParams.delete('source');
            setSearchParams(searchParams);
            getOrderCounts();
        }
    };

    // const getCarbonImpactForItem = async () => {
    //     const response: any = await carbonImpactForItem(showActionCheckModal ? showActionCheckModal.initialCategory?.code || '' : '').unwrap()
    //     setEmissionValue(response?.basketEmission?.items?.[0]?.emissionValue || 0)
    // }

    const onToggleActionModal = (orderId: string, itemId: string, initialCategory: any, imei?: any, warehouseId?: string, descriptor?: any) => {
        document.body.style.overflow = 'hidden';
        setShowActionCheckModal({
            show: true,
            orderId,
            imei,
            itemId,
            warehouseId,
            initialCategory,
            descriptor,
            text: 'The item is an exact match of the order placed. Confirm to accept the item.',
            title: 'Item Matching',
            error: false,
            type: 'accept',
        });
    };

    const onCloseAcceptModal = () => {
        document.body.style.overflow = 'auto';
        setShowActionCheckModal(undefined);
    };

    const onAcceptShipment = async () => {
        if (loadingShippment) {
            return;
        }
        try {
            setLoadingShippment(true);
            const formatData = {
                orderId: showActionCheckModal?.orderId || '',
                data: {
                    itemId: showActionCheckModal?.itemId || '',
                    marketId: activeMarket?.market?._id,
                },
            };
            const data = showActionCheckModal?.itemId && showActionCheckModal?.orderId && await dispatch(tryToAcceptOrder(formatData)).unwrap();
            setShowToast({ type: 'success', message: `Order Item was accepted successfully.` });
            // getCarbonImpactForItem()
            // setShowEmissionModal(true)
            onResetPaginationSettings();
            if (data?.reviseInfo) {
                setRevisedOfferModal(data.reviseInfo)
            }
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onCloseAcceptModal();
        setLoadingShippment(false);
    };

    return (
        <div>
            <div className='mb-4'>
                <p className='page-title'>Orders</p>
            </div>
            <div className='my-10'>
                <OrderCategories
                    items={brokerCategories || []}
                    // ordersPerCategory={ordersPerCategory}
                    activeButton={selectedCategory}
                    onClickCategory={onChangeCategorySelected}
                    ordersCount={ordersCount}
                />
            </div>
            <div className='my-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 md:gap-4'>
                {(userData && userData?.inventory) &&
                    <SelectCheckbox
                        name='Inventory'
                        dataQa={'inventory-filter'}
                        placeholder={inventorySelected || ''}
                        options={INVENTORY_FILTERS || []}
                        selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                        onChangeSelectedOption={onClickInventoryFilter}
                        uniqueName={'inventory'}
                    />
                }
                <SelectCheckbox
                    name='Order Source'
                    dataQa={'open-source-filter'}
                    placeholder={selectedSource || ''}
                    options={payServices || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                    onChangeSelectedOption={onClickSourceFilter}
                    uniqueName={'payService'}
                />
                <Input
                    label={'Search by username'}
                    dataQa={'filter-table-by-usernameValue'}
                    showValue={true}
                    inputValue={usernameValue}
                    inputUniqueName={'search-by-usernameValue'}
                    onChangeInput={onChangeUsernameSearchValue}
                    onHandleKeyDown={onEnter}
                    hideInputSpace={true}
                />
                <Input
                    label={'Search by post code'}
                    dataQa={'filter-table-by-post-code'}
                    showValue={true}
                    inputValue={postcodeValue}
                    inputUniqueName={'search-by-post-code'}
                    onChangeInput={onChangePostCodeSearchValue}
                    onHandleKeyDown={onEnter}
                    hideInputSpace={true}
                />
            </div>
            <div className='my-5'>
                <OrderFilters
                    onChangeFilterSelected={onChangeFilterSelected}
                    onChangeStatusSelected={onChangeStatusSelected}
                    onChangeRecentFilter={onChangeRecentFilter}
                    onClickHistoryStatus={onClickHistoryStatus}
                    recentSelected={recentFilterStatus}
                    selectedFilter={filterSelected}
                    selectedStatus={statusSelected}
                    historyFilterStatus={historyFilterStatus}
                />
            </div>
            <div>
                <div className={`grid sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-10 bg-white`}>
                    <div className={`sm:col-span-5 md:col-span-6 lg:col-span-4`}>
                        <Input
                            placeholder={'Filter table'}
                            dataQa={'filter-table'}
                            containerStyle='mb-0 pb-0 relative'
                            inputStyle={`mb-0 border-0 focus:outline-none`}
                            icon={'/assets/shared/search.svg'}
                            showValue={true}
                            inputValue={searchValue}
                            inputUniqueName={'search'}
                            onChangeInput={onChangeSearchValue}
                            onHandleKeyDown={onEnterSearchInput}
                            hideInputSpace={true}
                        />
                    </div>
                    <Button
                        label={'Clear all filters'}
                        dataQa={'clear-all-filters'}
                        className={'btn-primary-text-underline min-w-auto sm:col-span-5 md:col-span-2 lg:col-span-2 flex flex-row sm:justify-center md:justify-center lg:justify-end bg-transparent mt-1'}
                        onClickButton={clearAllFilters}
                    />
                    <Button
                        label={loadingDownload ? 'Loading data...' : 'Export CSV'}
                        dataQa={'export-CSV'}
                        onClickButton={() => (!accessControl || (pageAccess?.actions && pageAccess?.actions['Export CSV'])) && onExportOrders()}
                        className={`${!accessControl || (pageAccess?.actions && pageAccess?.actions['Export CSV']) ? 'btn-main' : 'btn-main-disable'} sm:col-span-5 md:col-span-2 lg:col-span-2 sm:mr-0 sm:mb-1 md:mr-2 md:mb-0 !py-2 !shadow-none`}
                    />
                    <Button
                        label={'Accepted Package'}
                        dataQa={'accepted-package'}
                        onClickButton={() => (!accessControl || (pageAccess?.actions && pageAccess?.actions['Accepted Package'])) && onToggleAcceptedPackageModal()}
                        className={`${!accessControl || (pageAccess?.actions && pageAccess?.actions['Accepted Package']) ? 'btn-main' : 'btn-main-disable'} sm:col-span-5 md:col-span-2 lg:col-span-2 !py-2 !shadow-none`}
                    />
                </div>
                <CSVLink
                    ref={csvLink}
                    data={ordersToExport && ordersToExport?.length > 0 ? ordersToExport?.filter((order, idx) => idx > 0) : []}
                    headers={ordersToExport?.[0] || []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={`orders-export.csv`}>
                    {loadingDownload ? 'Loading data...' : 'Download Sample'}
                </CSVLink>
            </div>
            <div>
                {(loadingOrders || rows?.length > 0) ?
                    <OrdersTable
                        rows={rows}
                        selectedFilter={filterSelected || statusSelected}
                        paginationData={paginationData}
                        pageAccess={pageAccess}
                        accessControl={accessControl}
                        activeMarket={activeMarket}
                        isRecentChanges={statusSelected === 'Recent Changes'}
                        ordersLoading={loadingOrders}
                        categorySelected={selectedCategory || ''}
                        onClickAccept={onToggleActionModal}
                        setPaginationState={setPaginationState}
                        onClickAddImei={onShowHideImeiModal}
                        onSeeOrderDetails={onSeeOrderDetails}
                    />
                    : <OrderNotFound
                        selectedMarket={activeMarket?.market?.label}
                        selectedSector={selectedCategory}
                        filterSelected={filterSelected}
                        historyFilterStatus={historyFilterStatus}
                        recentFilterStatus={recentFilterStatus}
                    />
                }
            </div>
            {showImeiModal &&
                <ImeiModal
                    openImeiModal={showImeiModal}
                    itemId={orderItemId || ''}
                    handleCloseModal={onShowHideImeiModal}
                    onSuccessfullyAddedImei={onSuccessfullyAddedImei}
                />
            }
            {showAcceptPackageModal &&
                <AcceptPackageModal
                    openModal={showAcceptPackageModal}
                    handleCloseModal={onToggleAcceptedPackageModal}
                    onSubmitSuccessfully={onAcceptedPackageSuccessfully}
                />
            }
            {showActionCheckModal?.show &&
                <>
                    {selectedCategory && (selectedCategory?.toLowerCase() === 'phones' || selectedCategory?.toLowerCase() === 'tablets') ?
                        <AcceptWithImeiModal
                            openImeiModal={showActionCheckModal?.show || false}
                            itemId={showActionCheckModal?.itemId || ''}
                            orderId={showActionCheckModal?.orderId || ''}
                            imeiData={showActionCheckModal?.imei}
                            activeMarket={activeMarket}
                            userData={userData}
                            descriptor={showActionCheckModal?.descriptor}
                            category={selectedCategory ? brokerCategories?.find((item) => item?.name === selectedCategory)?.id || undefined : undefined}
                            warehouseId={showActionCheckModal?.warehouseId}
                            handleCloseModal={() => setShowActionCheckModal(undefined)}
                            setRevisedOfferModal={setRevisedOfferModal}
                        />
                        : <ActionCheckModal
                            openMarketStatusModal={showActionCheckModal?.show || false}
                            text={showActionCheckModal?.text || ''}
                            title={showActionCheckModal?.title || ''}
                            errorType={showActionCheckModal?.error || false}
                            action={showActionCheckModal?.type || ''}
                            loading={loadingShippment}
                            onApproveAction={onAcceptShipment}
                            handleCloseModal={onCloseAcceptModal}
                        />
                    }
                </>
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {
                revisedOfferModal && <RevisedOfferModal reviseInfo={revisedOfferModal} handleCloseModal={() => setRevisedOfferModal(undefined)} />
            }
            {/* {showEmissionModal &&
                <EmissionAvoidedModal
                    openModal={showEmissionModal}
                    handleCloseModal={() => setShowEmissionModal(false)}
                    emission={emissionValue || 0}
                />
            } */}
        </div>
    );
};

export default Orders;
