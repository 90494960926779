import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { AxiosError } from 'axios';
import { ISubBrandsListState } from '../../../../interfaces/sub-brands/ISubBrandList';
import { SubBrandsService } from './subBrandsApi';
import { PaginatedSubBrands } from '../../../../interfaces/sub-brands/paginatedStore.type';

const initialState: ISubBrandsListState = {
    subBrandAreLoading: false,
    toastSuccessRequest: false,
    subBrandIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchSubBrands = createAsyncThunk<
    PaginatedSubBrands,
    IPaginationPayload
>('subBrands/tryToFetchSubBrands', async ({ pageNumber, pageSize, filters }) => {
    const result = await SubBrandsService.tryToFetchSubBrands(
        pageNumber,
        pageSize,
        filters,
    );
    return result?.data;
});

export const tryToFetchPartnerSubBrands = createAsyncThunk<any, any>(
    'subBrands/tryToFetchPartnerSubBrands',
    async (data: string) => {
        const result = await SubBrandsService.tryToFetchPartnerSubBrands(data);
        return result?.data;
    },
);

export const tryToFetchSingleSubBrands = createAsyncThunk<any, any>(
    'subBrands/tryToFetchSingleSubBrands',
    async ({ id, partnerId }) => {
        const result = await SubBrandsService.tryToFetchSingleSubBrands(id, partnerId);
        return result?.data;
    },
);

export const tryToEditSubBrands = createAsyncThunk<
    ApiResponse<any>,
    any
>('subBrands/tryToEditSubBrands', async (data: any, { rejectWithValue }) => {
    try {
        const result = await SubBrandsService.tryToEditSubBrands(
            data._id || '',
            data?.data,
        );
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToAddSubBrands = createAsyncThunk<
    ApiResponse<any>,
    any
>('subBrands/tryToAddSubBrands', async (data: any, { rejectWithValue }) => {
    try {
        const result = await SubBrandsService.tryToAddSubBrands(data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});


export const tryToDisableSubBrands = createAsyncThunk<
    ApiResponse<any>,
    any
>('subBrands/tryToDisableSubBrands', async ({ id, partnerId }, { rejectWithValue }) => {
    try {
        const results = await SubBrandsService.tryToDisableSubBrands(id, partnerId);
        return results.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToFetchSubBrandsByMarket = createAsyncThunk<any, any>(
    'subBrands/tryToFetchSubBrandsByMarket',
    async (id) => {
        const result = await SubBrandsService.tryToFetchSubBrandsByMarket(id);
        return result?.data;
    },
);

export const subBrandsSlice = createSlice({
    name: 'subBrands',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchSubBrands
            .addCase(tryToFetchSubBrands.pending, (state) => {
                state.subBrandAreLoading = true;
            })
            .addCase(tryToFetchSubBrands.rejected, (state) => {
                state.subBrandAreLoading = false;
            })
            .addCase(tryToFetchSubBrands.fulfilled, (state, action) => {
                state.subBrandAreLoading = false;
                state.subBrands = action.payload;
            })

            // tryToFetchPartnerSubBrands
            .addCase(tryToFetchPartnerSubBrands.pending, (state) => {
                state.subBrandAreLoading = true;
            })
            .addCase(tryToFetchPartnerSubBrands.rejected, (state) => {
                state.subBrandAreLoading = false;
            })
            .addCase(tryToFetchPartnerSubBrands.fulfilled, (state, action) => {
                state.subBrandAreLoading = false;
                state.partnerSubBrands = action.payload?.data;
            })

            // tryToAddSubBrands
            .addCase(tryToAddSubBrands.pending, (state) => {
                state.subBrandIsLoading = true;
            })
            .addCase(tryToAddSubBrands.rejected, (state) => {
                state.subBrandIsLoading = false;
            })
            .addCase(tryToAddSubBrands.fulfilled, (state, action) => {
                state.subBrandIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                state.partnerSubBrands = action.payload.data?.subBrands && action.payload.data?.subBrands?.length > 0 && action.payload.data?.subBrands?.map((item: any) => {
                    return {
                        subBrands: item,
                        _id: undefined,
                    }
                })
                if (state.subBrands?.data)
                    state.subBrands.data.elements = [
                        ...state.subBrands?.data.elements,
                        data,
                    ];
            })

            // tryToDisableSubBrands
            .addCase(tryToDisableSubBrands.pending, (state) => {
                state.subBrandIsLoading = true;
            })
            .addCase(tryToDisableSubBrands.rejected, (state) => {
                state.subBrandIsLoading = false;
            })
            .addCase(tryToDisableSubBrands.fulfilled, (state, action) => {
                state.subBrandIsLoading = false;
                state.requestStatus = 'success';
                if (state.partnerSubBrands)
                    state.partnerSubBrands =
                        { ...state }.partnerSubBrands.filter(
                            (market: any) =>
                                market?.subBrands?.id !== action.meta.arg?.id,
                        ) || [];
            })

            // tryToEditSubBrands
            .addCase(tryToEditSubBrands.pending, (state) => {
                state.subBrandIsLoading = true;
            })
            .addCase(tryToEditSubBrands.rejected, (state) => {
                state.subBrandIsLoading = false;
            })
            .addCase(tryToEditSubBrands.fulfilled, (state, action) => {
                state.subBrandIsLoading = false;
                state.partnerSubBrands = action.payload.data?.subBrands && action.payload.data?.subBrands?.length > 0 && action.payload.data?.subBrands?.map((item: any) => {
                    return {
                        subBrands: item,
                        _id: undefined,
                    }
                })
                state.subBrandDetail = action.payload.data;
            })

            // tryToFetchSingleSubBrands
            .addCase(tryToFetchSingleSubBrands.pending, (state) => {
                state.subBrandIsLoading = true;
            })
            .addCase(tryToFetchSingleSubBrands.rejected, (state) => {
                state.subBrandIsLoading = false;
            })
            .addCase(tryToFetchSingleSubBrands.fulfilled, (state, action) => {
                state.subBrandIsLoading = false;
                state.subBrandDetail = action.payload?.data;
            });
    },
});

export default subBrandsSlice.reducer;