import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RentalPricingSettingsService } from './rentalPricingSettingsApi';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IRentalPricingSettingsListState } from '../../../../interfaces/rental-pricing-settings/IRentalPricingSettingsList';
import { PaginatedRentalPricingSettings } from '../../../../interfaces/rental-pricing-settings/paginatedStore.type';
import { IRentalPricingSettings } from '../../../../interfaces/rental-pricing-settings/IRentalPricingSettings';


const initialState: IRentalPricingSettingsListState = {
    rentalPricingSettingsIsLoading: false,
    rentalPricingSettingsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchRentalPricingSettings = createAsyncThunk<
    PaginatedRentalPricingSettings,
    IPaginationPayload
>('rental-pricing-settings/tryToFetchRentalPricingSettings',
    async ({ pageNumber, pageSize, data }) => {
        const result = await RentalPricingSettingsService.tryToFetchRentalPricingSettings(
            pageNumber,
            pageSize,
            data
        );
        return result.data;
    },
);

export const tryToFetchSingleRentalPricingSetting = createAsyncThunk<IRentalPricingSettings, any>(
    'rental-pricing-settings/tryToFetchSingleRentalPricingSetting',
    async (id) => {
        const result = await RentalPricingSettingsService.tryToFetchSingleRentalPricingSetting(id);
        return result?.data?.data;
    },
);

export const tryToAddRentalPricingSetting = createAsyncThunk<ApiResponse<IRentalPricingSettings>, IRentalPricingSettings>(
    'rental-pricing-settings/tryToAddRentalPricingSetting',
    async (data: IRentalPricingSettings, { rejectWithValue }) => {
        try {
            const result = await RentalPricingSettingsService.tryToAddRentalPricingSetting(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditRentalPricingSetting = createAsyncThunk<ApiResponse<IRentalPricingSettings>, any>(
    'rental-pricing-settings/tryToEditRentalPricingSetting',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await RentalPricingSettingsService.tryToEditRentalPricingSetting(
                data._id || '',
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteRentalPricingSetting = createAsyncThunk<ApiResponse<any>, any>(
    'rental-pricing-settings/tryToDeleteRentalPricingSetting',
    async (id, { rejectWithValue }) => {
        try {
            const results = await RentalPricingSettingsService.tryToDeleteRentalPricingSetting(id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const rentalPricingSettingsSlice = createSlice({
    name: 'rentalPricingSettings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchRentalPricingSettings
            .addCase(tryToFetchRentalPricingSettings.pending, (state) => {
                state.rentalPricingSettingsAreLoading = true;
            })
            .addCase(tryToFetchRentalPricingSettings.rejected, (state) => {
                state.rentalPricingSettingsAreLoading = false;
            })
            .addCase(tryToFetchRentalPricingSettings.fulfilled, (state, action) => {
                state.rentalPricingSettingsAreLoading = false;
                state.rentalPricingSettings = action.payload;
            })

            // tryToFetchSingleRentalPricingSetting
            .addCase(tryToFetchSingleRentalPricingSetting.pending, (state) => {
                state.rentalPricingSettingsIsLoading = true;
            })
            .addCase(tryToFetchSingleRentalPricingSetting.rejected, (state) => {
                state.rentalPricingSettingsIsLoading = false;
            })
            .addCase(tryToFetchSingleRentalPricingSetting.fulfilled, (state, action) => {
                state.rentalPricingSettingsIsLoading = false;
                state.rentalPricingSettingsDetails = action.payload;
            })

            // tryToAddRentalPricingSetting
            .addCase(tryToAddRentalPricingSetting.pending, (state) => {
                state.rentalPricingSettingsIsLoading = true;
            })
            .addCase(tryToAddRentalPricingSetting.rejected, (state) => {
                state.rentalPricingSettingsIsLoading = false;
            })
            .addCase(tryToAddRentalPricingSetting.fulfilled, (state, action) => {
                state.rentalPricingSettingsIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.rentalPricingSettings?.data)
                    state.rentalPricingSettings.data.elements = [
                        data,
                        ...state.rentalPricingSettings?.data.elements,
                    ];
            })

            // tryToEditRentalPricingSetting
            .addCase(tryToEditRentalPricingSetting.pending, (state) => {
                state.rentalPricingSettingsIsLoading = true;
            })
            .addCase(tryToEditRentalPricingSetting.rejected, (state) => {
                state.rentalPricingSettingsIsLoading = false;
            })
            .addCase(tryToEditRentalPricingSetting.fulfilled, (state, action) => {
                state.rentalPricingSettingsIsLoading = false;
                if (state.rentalPricingSettings?.data.elements)
                    state.rentalPricingSettings.data.elements =
                        { ...state }.rentalPricingSettings?.data.elements.map((item: any) => {
                            if (item?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return item;
                            }
                        }) || [];
                state.rentalPricingSettingsDetails = action.payload.data;
            })

            // tryToDeleteRentalPricingSetting
            .addCase(tryToDeleteRentalPricingSetting.pending, (state) => {
                state.rentalPricingSettingsIsLoading = true;
            })
            .addCase(tryToDeleteRentalPricingSetting.rejected, (state) => {
                state.rentalPricingSettingsIsLoading = false;
            })
            .addCase(tryToDeleteRentalPricingSetting.fulfilled, (state, action) => {
                state.rentalPricingSettingsIsLoading = false;
                state.requestStatus = 'success';
                if (state.rentalPricingSettings?.data.elements)
                    state.rentalPricingSettings.data.elements =
                        { ...state }.rentalPricingSettings?.data.elements.filter(
                            (item: any) => item?._id !== action.meta.arg,
                        ) || [];
            })

    },
});

export default rentalPricingSettingsSlice.reducer;
