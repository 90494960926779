import React from 'react';
import { IUIAction } from '../../../../interfaces/ui-actions/IUIAction';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['Display Name', 'Name', 'Actions']

interface IUIActionsTable {
    rows: IUIAction[];
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeUIActionDetails: (id: string) => void;
    onDeleteUIAction: (id: string) => void;
    onEditUIAction: (id: string) => void;
}

const UIActionsTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    rowsLoading,
    setPaginationState,
    onSeeUIActionDetails,
    onDeleteUIAction,
    onEditUIAction
}: IUIActionsTable) => {
    return (
        <Table
            columns={tableColumns}
            containerStyle={'mt-0'}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IUIAction, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.displayName}</td>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa={'view-details'} onClick={() => ((!accessControl || actions?.['See Details']) && row?._id) && onSeeUIActionDetails(row?._id)}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Details']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa={'edit-ui-action'} onClick={() => ((!accessControl || actions?.['Edit UI Action']) && row?._id) && onEditUIAction(row?._id)}>
                                            <img title={'Edit UI Action'} alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit UI Action']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa={'delete-ui-action'} onClick={() => ((!accessControl || actions?.['Delete UI Action']) && row?._id) && onDeleteUIAction(row?._id)}>
                                            <img title={'Delete UI Action'} alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete UI Action']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default UIActionsTable;