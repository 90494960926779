import React from 'react';
import { classNames } from '../../utils/classNames';

interface IWhiteContainer {
    children?: React.ReactNode;
    type?: string;
    containerStyle?: string;
    dataQa?: string;
}

const WhiteContainer = ({
    children,
    type,
    dataQa,
    containerStyle
}: IWhiteContainer) => {
    return (
        <div data-qa={dataQa} className={classNames('w-full h-full bg-white p-4', type === 'rounded' ? 'rounded-[12px]' : '', containerStyle || '')}>
            {children}
        </div >
    );
};

export default WhiteContainer;