import React from 'react';
import ItemInfoContainer from './ItemInfoContainer';
import OrderInfoContainer from './OrderInfoContainer';
import ProductInfoContainer from './ProductInfoContainer';
import SellersInfoContainer from './SellersInfoContainer';
import OrderActionsContainer from './OrderActionsContainer';
import ContactHistoryContainer from './ContactHistoryContainer';


const LoadingPage = () => {
    return (
        <div>
            <div>
                <OrderActionsContainer />
            </div>
            <div className='grid grid-cols-4 lg:grid-cols-11 gap-0 lg:gap-2 mt-8 mb-2 my-8'>
                <div className='col-span-3 gap-2'>
                    <OrderInfoContainer />
                    <ProductInfoContainer />
                </div>
                <div className='col-span-8 grid grid-cols-2 gap-2 mb-1'>
                    <div>
                        <SellersInfoContainer />
                    </div>
                    <div>
                        <ItemInfoContainer />
                    </div>
                </div>
                <div className='mb-4 col-span-11'>
                    <ContactHistoryContainer />
                </div>
            </div>
        </div >
    )
}

export default LoadingPage;