import React, { useEffect, useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { VAT_REGIONS, VAT_TYPE } from '../../../../utils/constants/vat-type';
import { ENTITY_ITEMS } from '../../../../utils/constants/market-entities';
import {
    tryToAddMarket,
    tryToEditMarket,
    tryToFetchSingleMarket,
} from '../../../../store/brokers/admin/markets/marketsSlice';
import { tryToFetchAllInvoiceTemplates } from '../../../../store/brokers/admin/invoice-templates/invoiceTemplateSlice';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface ICreateMarketModal {
    currencies: any[];
    openCreateMarketModal: boolean;
    marketItemId?: string | null;
    handleCloseMarketModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type IPayloadVATTypes = {
    UK?: string;
    US?: string;
    EU?: string;
}

type FormValues = {
    name: string;
    label: string;
    currency: string;
    flag?: string;
    vatRegion?: string;
    UKVatType?: string;
    USVatType?: string;
    EUVatType?: string;
    buyingEntity: string;
    sellingEntity: string;
    invoiceTemplates?: {
        UK?: string;
        EU?: string;
        US?: string;
    };
};

const MarketModal = ({
    openCreateMarketModal,
    marketItemId,
    currencies,
    handleCloseMarketModal,
    onSaveChanges,
}: ICreateMarketModal) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.markets);
    const [invoiceTemplates, setInvoiceTemplates] = useState<any>()
    const [selectedInvoiceTemplate, setSelectedInvoiceTemplate] = useState<any>()
    const [selectedCurrency, setSelectedCurrency] = useState<any>();
    const [selectedVat, setSelectedVat] = useState<{ ukVat?: any, euVat?: any, usVat?: any }>();
    const [selectedSelling, setSelectedSelling] = useState<any>();
    const [selectedBuying, setSelectedBuying] = useState<any>()
    const [fileSelected, setFileSelected] = useState<any>()
    const [selectedVatRegion, setSelectedVatRegion] = useState<any>()
    const [updateError, setUpdateError] = useState<string>()
    const marketLoading = useAppSelector((state) => state.markets?.marketIsLoading);

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        watch,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        if (marketItemId && state?.marketDetail) {
            setValue('name', marketItemId ? state?.marketDetail?.name || '' : '');
            setValue('label', marketItemId ? state?.marketDetail?.label || '' : '');
            setValue('currency', marketItemId ? state?.marketDetail?.currency || '' : '');
            setValue('buyingEntity', marketItemId ? state?.marketDetail?.buyingEntity || '' : '');
            setValue('sellingEntity', marketItemId ? state?.marketDetail?.sellingEntity || '' : '');
            setValue('vatRegion', marketItemId ? state?.marketDetail?.vatRegion || '' : '')
            setValue('invoiceTemplates', marketItemId ? state?.marketDetail?.invoiceTemplates || undefined : undefined)
            if (state?.marketDetail?.currency) {
                const findCurrency: any = currencies && currencies?.length > 0 && currencies.find((item: any) => item?._id === state?.marketDetail?.currency)
                setSelectedCurrency(findCurrency ? {
                    value: findCurrency?._id,
                    label: findCurrency.descriptor.name,
                    name: findCurrency.descriptor.name,
                } : undefined)
            } else {
                setSelectedCurrency(undefined)
            }
            if (state?.marketDetail?.vatRegion) {
                const findVatRegion = VAT_REGIONS && VAT_REGIONS?.length > 0 && VAT_REGIONS.find((item: any) => item?.value === state?.marketDetail?.vatRegion)
                setSelectedVatRegion(state?.marketDetail?.vatRegion ? findVatRegion : undefined)
            } else {
                setSelectedVatRegion(undefined)
            }
            if (state?.marketDetail?.invoiceTemplates) {
                const findUKTemplate = invoiceTemplates && invoiceTemplates?.length > 0 && invoiceTemplates.find((item: any) => item?.value === state?.marketDetail?.invoiceTemplates?.UK)
                const findEUTemplate = invoiceTemplates && invoiceTemplates?.length > 0 && invoiceTemplates.find((item: any) => item?.value === state?.marketDetail?.invoiceTemplates?.EU)
                const findUSTemplate = invoiceTemplates && invoiceTemplates?.length > 0 && invoiceTemplates.find((item: any) => item?.value === state?.marketDetail?.invoiceTemplates?.US)
                let formatData: any
                if (findUKTemplate) {
                    formatData = {
                        UK: findUKTemplate
                    }
                }
                if (findEUTemplate) {
                    formatData = {
                        ...formatData || {},
                        EU: findEUTemplate
                    }
                }
                if (findUSTemplate) {
                    formatData = {
                        ...formatData || {},
                        US: findUSTemplate
                    }
                }
                setSelectedInvoiceTemplate(formatData || undefined)
            } else {
                setSelectedInvoiceTemplate(undefined)
                setValue('invoiceTemplates', undefined)
            }

            if (state?.marketDetail?.vatTypes) {
                const findUkVat = VAT_TYPE && VAT_TYPE?.length > 0 && VAT_TYPE.find((item: any) => item?.value === state?.marketDetail?.vatTypes?.UK)
                setValue('UKVatType', findUkVat ? findUkVat?.value || undefined : undefined)
                const findEuVat = VAT_TYPE && VAT_TYPE?.length > 0 && VAT_TYPE.find((item: any) => item?.value === state?.marketDetail?.vatTypes?.EU)
                setValue('EUVatType', findEuVat ? findEuVat?.value || undefined : undefined)
                const findUsVat = VAT_TYPE && VAT_TYPE?.length > 0 && VAT_TYPE.find((item: any) => item?.value === state?.marketDetail?.vatTypes?.US)
                setValue('USVatType', findUsVat ? findUsVat?.value || undefined : undefined)
                setSelectedVat({
                    ukVat: findUkVat,
                    euVat: findEuVat,
                    usVat: findUsVat,
                })
            } else {
                setSelectedVat(undefined)
                setValue('UKVatType', undefined)
                setValue('EUVatType', undefined)
                setValue('USVatType', undefined)
            }

            if (state?.marketDetail?.sellingEntity) {
                const findSellingEntity = ENTITY_ITEMS && ENTITY_ITEMS?.length > 0 && ENTITY_ITEMS.find((item: any) => item?.value === state?.marketDetail?.sellingEntity)
                setSelectedSelling(state?.marketDetail?.sellingEntity ? findSellingEntity || ENTITY_ITEMS?.find((item: any) => item?.value === 'Other') : undefined)
            }
            if (state?.marketDetail?.buyingEntity) {
                const findBuyingEntity = ENTITY_ITEMS && ENTITY_ITEMS?.length > 0 && ENTITY_ITEMS.find((item: any) => item?.value === state?.marketDetail?.buyingEntity)
                setSelectedBuying(state?.marketDetail?.buyingEntity ? findBuyingEntity || ENTITY_ITEMS?.find((item: any) => item?.value === 'Other') : undefined)
            }
        } else {
            reset();
            setSelectedBuying(undefined)
            setSelectedCurrency(undefined)
            setSelectedSelling(undefined)
            setSelectedInvoiceTemplate(undefined)
            setSelectedVat(undefined)
            setSelectedVatRegion(undefined)
            setUpdateError(undefined)
            setFileSelected(undefined)
        }
    }, [state.marketDetail, marketItemId, openCreateMarketModal]);

    useEffect(() => {
        if (marketItemId) {
            dispatch(tryToFetchSingleMarket(marketItemId));
        }
    }, [marketItemId]);

    const onGettingInvoiceTemplateData = async () => {
        const response = await dispatch(tryToFetchAllInvoiceTemplates()).unwrap()
        const formatResponse = (response?.data && response?.data?.length > 0) && response?.data?.map((item: any) => {
            return {
                ...item,
                label: item?.displayName,
                name: item?.displayName,
                value: item?.templateName
            }
        })
        setInvoiceTemplates(formatResponse)
    }

    useEffect(() => {
        onGettingInvoiceTemplateData()
    }, [])

    const onCloseModal = () => {
        reset();
        setSelectedCurrency(undefined)
        setFileSelected(undefined)
        setSelectedBuying(undefined)
        setSelectedSelling(undefined)
        setSelectedVat(undefined)
        setSelectedVatRegion(undefined)
        setUpdateError(undefined)
        setSelectedInvoiceTemplate(undefined)
        handleCloseMarketModal();
    };

    const onSubmit = async (data: FormValues) => {
        if (!(fileSelected || state?.marketDetail?.flagUrl)) {
            setUpdateError('Flag image is required.')
            return;
        }
        const formData = new FormData()
        formData.append('currency', data?.currency)
        formData.append('label', data?.label)
        formData.append('name', data?.name)
        formData.append('sellingEntity', data?.sellingEntity)
        formData.append('buyingEntity', data?.buyingEntity)
        const vatTypes: IPayloadVATTypes = {}

        if (data.UKVatType) {
            vatTypes.UK = data?.UKVatType
            formData.append('vatTypes[UK]', data?.UKVatType)
        }
        if (data.EUVatType) {
            vatTypes.EU = data?.EUVatType
            formData.append('vatTypes[EU]', vatTypes?.EU!)
        }
        if (data.USVatType) {
            vatTypes.US = data?.USVatType
            formData.append('vatTypes[US]', vatTypes?.US!)
        }
        if (fileSelected) {
            formData.append('file', fileSelected)
        }
        if (data.vatRegion) {
            formData.append('vatRegion', data.vatRegion)
        }
        if (data.invoiceTemplates) {
            formData.append('invoiceTemplates', JSON.stringify(data.invoiceTemplates))
        }
        const payload: any = {
            data: formData,
        };
        try {
            if (marketItemId) {
                payload._id = marketItemId;
                await dispatch(tryToEditMarket(payload)).unwrap();
            } else {
                await dispatch(tryToAddMarket(formData)).unwrap();
            }
            onSaveChanges(
                'success',
                `Market successfully ${marketItemId ? 'changed' : 'added'}.`,
            );
            onCloseModal();
        } catch (error) {
            setUpdateError(`${error}`);
        }
    };

    const currencyOptions = currencies.map((next) => {
        return {
            value: next._id,
            label: next.descriptor.name,
            name: next.descriptor.name,
        };
    });

    const onChangeSelectedCurrency = (e: any) => {
        setSelectedCurrency(e);
        setValue('currency', e?.value)
    };

    const onChangeVatSelected = (e: any, market: string) => {
        if (market === 'uk') {
            setSelectedVat(
                {
                    ...selectedVat,
                    ukVat: e
                }
            );
            setValue('UKVatType', e?.value)
        }
        if (market === 'eu') {
            setSelectedVat(
                {
                    ...selectedVat,
                    euVat: e
                }
            );
            setValue('EUVatType', e?.value)
        }
        if (market === 'us') {
            setSelectedVat(
                {
                    ...selectedVat,
                    usVat: e
                }
            );
            setValue('USVatType', e?.value)
        }
    }

    const onChangeVatRegionSelected = (e: any, type?: string) => {
        setSelectedVatRegion(e)
        setValue('vatRegion', e?.value)
    }

    const onChangeEntitySelected = (e: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'buying': {
                    setSelectedBuying(e)
                    setValue('buyingEntity', e?.value)
                    // e?.value !== 'Other' ? setValue('buyingEntity', e?.value) : reset({ 'buyingEntity': undefined })
                    break;
                }
                case 'selling': {
                    setSelectedSelling(e)
                    setValue('sellingEntity', e?.value)
                    // e?.value !== 'Other' ? setValue('sellingEntity', e?.value) : reset({ 'sellingEntity': undefined })
                    break;
                }
            }
        }
    }

    const onChangeInvoiceTemplates = (e: any, type?: 'EU' | 'UK' | 'US') => {
        if (type) {
            setSelectedInvoiceTemplate({
                ...selectedInvoiceTemplate || {},
                [type]: e
            })
            setValue('invoiceTemplates', { ...watch('invoiceTemplates') || {}, [type]: e?.value })
        }
    }

    return (
        <Modal
            open={openCreateMarketModal}
            onClose={onCloseModal}
            contentContainerStyle={'!min-w-[650px]'}>
            <div className={'p-2 min-w-[600px]'}>
                <p className='mt-2 mb-4 font-semibold font-[17px]'>{marketItemId ? 'Edit Market' : 'Create Market'}</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(marketItemId && !marketLoading) || !marketItemId ? (
                        <div className='flex flex-col my-4'>
                            <div className='grid grid-cols-1 gap-1'>
                                <Input
                                    label='Name'
                                    dataQa={'name'}
                                    disabled={!!marketItemId}
                                    containerStyle='max-w-[90%]'
                                    register={register('name', {
                                        required: {
                                            message: 'Name is required',
                                            value: true,
                                        },
                                        validate: (value: string) =>
                                            !!value.trim() || 'Name is required',
                                    })}
                                    error={errors.name?.message}
                                />
                                <Input
                                    label='Label'
                                    dataQa={'label'}
                                    containerStyle='max-w-[90%]'
                                    register={register('label', {
                                        required: {
                                            message: 'Label is required',
                                            value: true,
                                        },
                                        validate: (value: string) =>
                                            !!value.trim() || 'Label is required',
                                    })}
                                    error={errors.label?.message}
                                />
                                <Controller
                                    control={control}
                                    defaultValue={selectedCurrency}
                                    name='currency'
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Currency is required.',
                                        },
                                    }}
                                    render={({ onChange, value, name, ref }: any) => (
                                        <SelectCheckbox
                                            ref={ref}
                                            selectedOption={selectedCurrency}
                                            key={1}
                                            placeholder=' '
                                            name='Currency'
                                            dataQa={'currency'}
                                            containerStyle='mb-1 max-w-[90%]'
                                            error={errors?.currency?.message}
                                            onChangeSelectedOption={(e) => onChangeSelectedCurrency(e)}
                                            options={currencyOptions || []}
                                        />
                                    )} />
                            </div>
                            <p className='font-semibold mt-3 mb-3'>Entities</p>
                            <div className='grid grid-cols-1 gap-1'>
                                <Controller
                                    control={control}
                                    defaultValue={selectedBuying}
                                    name='buyingEntity'
                                    render={({ ref }: any) => (
                                        <SelectCheckbox
                                            ref={ref}
                                            selectedOption={selectedBuying}
                                            key={1}
                                            placeholder=' '
                                            name='Buying Entity'
                                            dataQa={'buying-entity'}
                                            containerStyle='mb-1 max-w-[90%]'
                                            uniqueName='buying'
                                            error={errors?.buyingEntity?.message}
                                            onChangeSelectedOption={onChangeEntitySelected}
                                            options={ENTITY_ITEMS || []}
                                        />
                                    )} />
                                {/* {selectedBuying?.value === 'Other' &&
                                    <Input
                                        placeholder=' '
                                        containerStyle='max-w-[90%]'
                                        label={'Buying Entity Other'}
                                        register={register('buyingEntity', {
                                            required: {
                                                message: 'Buying Entity is required',
                                                value: true,
                                            },
                                            validate: (value: string) =>
                                                !!value.trim() || 'Buying Entity is required',
                                        })}
                                        error={errors.buyingEntity?.message}
                                    />
                                } */}
                            </div>
                            <div className='grid grid-cols-1 gap-1 mb-4'>
                                <Controller
                                    control={control}
                                    defaultValue={selectedSelling}
                                    name='sellingEntity'
                                    render={({ ref }: any) => (
                                        <SelectCheckbox
                                            ref={ref}
                                            selectedOption={selectedSelling}
                                            key={1}
                                            placeholder=' '
                                            name='Selling Entity'
                                            dataQa={'selling-entity'}
                                            containerStyle='mb-1 max-w-[90%]'
                                            uniqueName='selling'
                                            error={errors?.sellingEntity?.message}
                                            onChangeSelectedOption={onChangeEntitySelected}
                                            options={ENTITY_ITEMS || []}
                                        />
                                    )} />
                                {/* {selectedSelling?.value === 'Other' &&
                                    <Input
                                        placeholder=' '
                                        containerStyle='max-w-[90%]'
                                        label={'Selling Entity Other'}
                                        register={register('sellingEntity', {
                                            required: {
                                                message: 'Selling Entity is required',
                                                value: true,
                                            },
                                            validate: (value: string) =>
                                                !!value.trim() || 'Selling Entity is required',
                                        })}
                                        error={errors.sellingEntity?.message}
                                    />
                                } */}
                            </div>
                            <p className='font-semibold mt-3 mb-3'>VAT</p>
                            <div className='grid grid-cols-1 gap-1'>
                                <Controller
                                    control={control}
                                    defaultValue={selectedVatRegion}
                                    name='vatRegion'
                                    render={({ ref }: any) => (
                                        <SelectCheckbox
                                            ref={ref}
                                            selectedOption={selectedVatRegion}
                                            key={1}
                                            name='VAT Region'
                                            dataQa={'vat-region'}
                                            placeholder=' '
                                            containerStyle='mb-1 max-w-[90%]'
                                            error={errors?.vatRegion?.message}
                                            onChangeSelectedOption={(e) => onChangeVatRegionSelected(e)}
                                            options={VAT_REGIONS || []}
                                        />
                                    )} />
                            </div>
                            <div className='grid grid-cols-1 gap-1'>
                                <Controller
                                    control={control}
                                    defaultValue={selectedVat?.ukVat}
                                    name='UKVatType'
                                    render={({ ref }: any) => (
                                        <SelectCheckbox
                                            ref={ref}
                                            selectedOption={selectedVat?.ukVat}
                                            key={1}
                                            name='UK VAT Type'
                                            dataQa={'uk-vat-type'}
                                            placeholder=' '
                                            containerStyle='mb-1 max-w-[90%]'
                                            error={errors?.UKVatType?.message}
                                            onChangeSelectedOption={(e) => onChangeVatSelected(e, 'uk')}
                                            options={VAT_TYPE || []}
                                        />
                                    )} />
                                <Controller
                                    control={control}
                                    defaultValue={selectedVat?.euVat}
                                    name='EUVatType'
                                    render={({ ref }: any) => (
                                        <SelectCheckbox
                                            ref={ref}
                                            selectedOption={selectedVat?.euVat}
                                            key={1}
                                            name='EU VAT Type'
                                            dataQa={'eu-vat-type'}
                                            placeholder=' '
                                            containerStyle='mb-1 max-w-[90%]'
                                            error={errors?.EUVatType?.message}
                                            onChangeSelectedOption={(e) => onChangeVatSelected(e, 'eu')}
                                            options={VAT_TYPE || []}
                                        />
                                    )} />
                                <Controller
                                    control={control}
                                    defaultValue={selectedVat?.usVat}
                                    name='USVatType'
                                    render={({ ref }: any) => (
                                        <SelectCheckbox
                                            ref={ref}
                                            selectedOption={selectedVat?.usVat}
                                            key={1}
                                            name='US VAT Type'
                                            dataQa={'us-vat-type'}
                                            containerStyle='mb-1 max-w-[90%]'
                                            placeholder=' '
                                            error={errors?.USVatType?.message}
                                            onChangeSelectedOption={(e) => onChangeVatSelected(e, 'us')}
                                            options={VAT_TYPE || []}
                                        />
                                    )} />
                            </div>
                            <p className='font-semibold mt-5 mb-3'>Invoice Templates</p>
                            <div className='grid grid-cols- gap-1 mb-2'>
                                <Controller
                                    control={control}
                                    defaultValue={selectedInvoiceTemplate?.UK}
                                    name='invoiceTemplates'
                                    render={({ ref }: any) => (
                                        <SelectCheckbox
                                            ref={ref}
                                            selectedOption={selectedInvoiceTemplate?.UK}
                                            key={1}
                                            name='UK Invoice Template'
                                            dataQa={'uk-invoice-template'}
                                            placeholder=' '
                                            containerStyle='mb-1 max-w-[90%]'
                                            uniqueName='invoiceTemplates'
                                            error={errors?.invoiceTemplates?.UK?.message}
                                            onChangeSelectedOption={(e) => onChangeInvoiceTemplates(e, 'UK')}
                                            options={invoiceTemplates || []}
                                        />
                                    )} />

                                <Controller
                                    control={control}
                                    defaultValue={selectedInvoiceTemplate?.EU}
                                    name='invoiceTemplates'
                                    render={({ ref }: any) => (
                                        <SelectCheckbox
                                            ref={ref}
                                            selectedOption={selectedInvoiceTemplate?.EU}
                                            key={1}
                                            name='EU Invoice Template'
                                            dataQa={'eu-invoice-template'}
                                            containerStyle='mb-1 max-w-[90%]'
                                            placeholder=' '
                                            uniqueName='invoiceTemplates'
                                            error={errors?.invoiceTemplates?.EU?.message}
                                            onChangeSelectedOption={(e) => onChangeInvoiceTemplates(e, 'EU')}
                                            options={invoiceTemplates || []}
                                        />
                                    )} />
                                <Controller
                                    control={control}
                                    defaultValue={selectedInvoiceTemplate?.US}
                                    name='invoiceTemplates'
                                    render={({ ref }: any) => (
                                        <SelectCheckbox
                                            ref={ref}
                                            selectedOption={selectedInvoiceTemplate?.US}
                                            key={1}
                                            name='US Invoice Template'
                                            dataQa={'us-invoice-template'}
                                            containerStyle='mb-1 max-w-[90%]'
                                            placeholder=' '
                                            uniqueName='invoiceTemplates'
                                            error={errors?.invoiceTemplates?.US?.message}
                                            onChangeSelectedOption={(e) => onChangeInvoiceTemplates(e, 'US')}
                                            options={invoiceTemplates || []}
                                        />
                                    )} />
                            </div>
                            <div className='mb-6'>
                                <p className='mb-3 mt-4 font-medium'>Import market flag:</p>
                                <div className='flex flex-col items-start' {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button
                                        icon={<img alt='' src='/assets/shared/upload-file.svg' className='w-[30px] object-contains pr-2' />}
                                        className={'btn-main !bg-[#202020] !text-white !py-1 !shadow-none flex flex-row items-center'}
                                        label={'Select Image'}
                                        dataQa={'select-image'}
                                    />
                                    {fileSelected && fileSelected?.name
                                        ? <p className={'ml-3'}>{fileSelected?.name}</p>
                                        :
                                        <>
                                            {marketItemId && <img alt='' src={state?.marketDetail?.flagUrl} className={'w-[40px] my-3'} />}
                                        </>
                                    }
                                </div>
                                {updateError && <Error text={updateError} />}
                            </div>
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    dataQa={'submit'}
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default MarketModal;
