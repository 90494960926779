import React, { useEffect, useState } from 'react';
import Input from '../../../../shared/input';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IRevaluateInventoryFields {
    filterInventoryValues: any;
    filterExtendedValues: any;
    inventoryItems?: Array<any>;
    inventoryFilterErrors?: any;
    extendedFilterErrors?: any;
    loadingValues: boolean;
    assetTemplateSelected?: any;
    extendedItems?: any;
    filteredFields?: any;
    onChangeSelectedInventoryValue: (value: string, type?: string) => void;
    onChangeSelectedExtendedValue: (value: string, type?: string) => void;
    onChangeInventoryInput: (value: string | null | undefined, type: string) => void;
    onChangeExtendedInput: (value: string | null | undefined, type: string) => void;
}

const RevaluateInventoryFields = ({
    inventoryItems,
    filterExtendedValues,
    filterInventoryValues,
    inventoryFilterErrors,
    extendedFilterErrors,
    loadingValues,
    assetTemplateSelected,
    extendedItems,
    filteredFields,
    onChangeSelectedInventoryValue,
    onChangeSelectedExtendedValue,
    onChangeInventoryInput,
    onChangeExtendedInput
}: IRevaluateInventoryFields) => {
    const [stringFieldsValues, setStringFieldsValues] = useState<any>()

    useEffect(() => {
        if (assetTemplateSelected) {
            setStringFieldsValues(undefined)
        }
    }, [assetTemplateSelected])

    const onChangeInventoryStringInput = async (value: string | null | undefined, type: string) => {
        const findField = inventoryItems && inventoryItems?.length > 0 && inventoryItems?.find((field: any) => field?.name === type);
        setStringFieldsValues({
            ...(stringFieldsValues || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
        onChangeInventoryInput(value, type || '')
    }

    const capitalizeFirstLetterOfEachWord = (word: string) => {
        let formattedWord = word?.replace(/_/g, ' ');
        const words = formattedWord?.split(/\s+/);
        formattedWord = words?.map(word => {
            if (!word.trim()) return '';
            return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
        })?.join(' ');
        return formattedWord;
    }
 
    return (
        <div className='mt-5 mb-10'>
            <p className='font-semibold text-[16px]'>Other Item Attributes <span className='font-normal'>(Broker Defined)</span></p>
            <div className='my-2 grid grid-cols-3 gap-4 content-start'>
                {!loadingValues && inventoryItems && inventoryItems?.length > 0 && inventoryItems?.map((item, index) => {
                    let values: any = item?.values;
                    const findError = inventoryFilterErrors && inventoryFilterErrors?.[item?.name]
                    if (item?.dataType === 'boolean') {
                        values = [
                            { value: true, name: 'True' },
                            { value: false, name: 'False' },
                        ];
                    } else if (item?.dataType === 'list') {
                        values =
                            item?.values &&
                            item?.values?.length > 0 &&
                            values?.map((value: string) => {
                                return { value: value, label: value };
                            });
                    }
                    return (
                        <>
                            {item?.dataType === 'boolean' || item?.dataType === 'list'
                                ? <SelectCheckbox
                                    key={index}
                                    name={item?.name && (item?.name || '').toLowerCase() === 'health' ? 'Battery Health' : capitalizeFirstLetterOfEachWord(item?.name)}
                                    placeholder={filterInventoryValues?.[item?.name] || ''}
                                    options={values}
                                    selectStyle={{ backgroundColor: 'white', borderRadius: '4px' }}
                                    onChangeSelectedOption={onChangeSelectedInventoryValue}
                                    uniqueName={item?.name || ''}
                                    error={findError}
                                />
                                : <Input
                                    label={item?.name && (item?.name || '').toLowerCase() === 'health' ? 'Battery Health' : item?.name && `${item?.name[0].toUpperCase()}${item?.name.slice(1).toLowerCase()}`}
                                    placeholder={item?.name}
                                    inputUniqueName={item?.name}
                                    onChangeInput={onChangeInventoryStringInput}
                                    inputValue={stringFieldsValues?.[item?.name]}
                                    showValue={true}
                                    error={findError}
                                />
                            }
                        </>
                    )
                })}
                {!loadingValues && extendedItems && extendedItems?.length > 0 && extendedItems?.map((item: any, index: number) => {
                    let values: any = filteredFields && filteredFields[item?.name];
                    const findError = extendedFilterErrors && extendedFilterErrors?.[item?.name]
                    if (item?.dataType === 'boolean') {
                        values = [
                            { value: true, name: 'True' },
                            { value: false, name: 'False' },
                        ];
                    } else if (item?.dataType === 'list') {
                        values =
                            item?.values &&
                            item?.values?.length > 0 &&
                            values?.map((value: string) => {
                                return { value: value, label: value };
                            });
                    }
                    return (
                        <>
                            {item?.dataType === 'boolean' || item?.dataType === 'list'
                                ? <SelectCheckbox
                                    key={index}
                                    name={item?.name && (item?.name || '').toLowerCase() === 'health' ? 'Battery Health' : capitalizeFirstLetterOfEachWord(item?.name)}
                                    placeholder={filterExtendedValues?.[item?.name] || ''}
                                    options={values}
                                    selectStyle={{ backgroundColor: 'white', borderRadius: '4px' }}
                                    onChangeSelectedOption={onChangeSelectedExtendedValue}
                                    uniqueName={item?.name || ''}
                                    error={findError}
                                />
                                : <Input
                                    label={item?.name && (item?.name || '').toLowerCase() === 'health' ? 'Battery Health' : item?.name && `${item?.name[0].toUpperCase()}${item?.name.slice(1).toLowerCase()}`}
                                    placeholder={item?.name}
                                    inputUniqueName={item?.name}
                                    onChangeInput={onChangeExtendedInput}
                                    inputValue={stringFieldsValues?.[item?.name]}
                                    showValue={true}
                                    error={findError}
                                />
                            }
                        </>
                    )
                })}
            </div>
        </div>
    )
}

export default RevaluateInventoryFields;