import React from 'react'
import Switch from '../switch';

interface IAccordion {
    title?: string;
    icon?: string;
    children?: React.ReactNode;
    active: boolean;
    dataQa?: string;
    itemActive: boolean;
    hideSwitch?: boolean;
    divIcon?: boolean
    onToggleAccordion: () => void;
    onToggleSwitch: () => void;
}

const Accordion = ({
    title,
    icon,
    dataQa,
    children,
    active,
    itemActive,
    hideSwitch,
    divIcon,
    onToggleAccordion,
    onToggleSwitch
}: IAccordion) => {
    return (
        <div className='my-2 sm:my-4 md:my-6 shadow-md cursor-pointer bg-white' data-qa={dataQa} onClick={onToggleAccordion}>
            <div className='px-6 text-left items-center h-20 select-none flex justify-between flex-row'>
                <div className='grid grid-cols-2 gap-4 min-w-[85%]'>
                    <div className='flex flex-row item-center'>
                        {icon && !divIcon && <img src={icon} className='w-[20px] object-contain mr-2' />}
                        {divIcon && icon
                            && <div className='w-[21px] h-[21px] mr-3'>
                                <img src={icon} className={'max-w-[100%] max-h-[100%] min-w-[100%] min-h-[100%] object-contain'} />
                            </div>
                        }
                        <p className='flex-1'>{title}</p>
                    </div>
                    {!hideSwitch
                        &&
                        <div>
                            <Switch
                                dataQa={'switch-data'}
                                checked={itemActive}
                                onToggleSwitch={onToggleSwitch}
                            />
                        </div>
                    }
                </div>
                <div>
                    <img data-qa={'arrow-up-down'} src={'/assets/shared/arrow-down-gray.svg'} className={`w-[20px] object-contain ${active ? 'rotate-180' : ''}`} />
                </div>
            </div>
            <div onClick={(e) => { e.stopPropagation() }} className={`px-6 pt-0 overflow-hidden transition-[max-height] duration-500 z-90 ease-in ${active ? 'min-h-[200px]' : 'max-h-0'}`}>
                {children}
            </div>
        </div>
    )
}

export default Accordion