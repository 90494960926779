import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import {
    tryToDeleteBrokerComparisonSite,
    tryToDisableBrokerComparisonSite,
    tryToEnableBrokerComparisonSite,
    tryToFetchBrokersComparisonSites
} from '../../../store/brokers/admin/brokers-comparison-sites/brokersComparisonSitesSlice';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import BrokersComparisonCategories from './details/BrokersComparisonCategories';
import BrokersComparisonTable from './tables/BrokersComparisonTable';
import BrokerComparisonModal from './modals/BrokersComparisonModal';
import SearchWithButton from '../../../shared/search-with-button';
import EmptyContainer from '../../../shared/empty-container';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const BrokersComparisonSites = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any[]>([]);
    const [search, setSearch] = useState<string>();
    const [showDeleteModal, setShowDeleteModal] = useState<{ show: boolean, id: string }>()
    const [showToast, setShowToast] = useState<IToast | null>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [sectors, setSectors] = useState<any[]>();
    const [markets, setMarkets] = useState<any>();
    const [selectedSector, setSelectedSector] = useState<any>();
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const [showBrokerModal, setShowBrokerModal] = useState<boolean>(false)
    const [brokerId, setBrokerId] = useState<string | null>()
    const [pageAccess, setPageAccess] = useState<any>()
    const [getAllMarkets] = useGetAllMarketsMutation();
    const paginationData = useAppSelector((state) => state.brokersComparison.brokersComparison?.data?.page);
    const rowsLoading = useAppSelector((state) => state.brokersComparison.brokersComparisonAreLoading);
    const accessControl = useAppSelector((state) => state?.brokersComparison.brokerComparisonNavigation);
    const state = useAppSelector((state) => state?.brokersComparison);
    const assetTemplateState = useAppSelector((state) => state?.assetsTemplates);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Brokers For Comparison Sites')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.brokersComparison) {
            const rows =
                state.brokersComparison?.data?.elements &&
                    Array.isArray(state.brokersComparison?.data.elements)
                    ? state.brokersComparison?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.brokersComparison]);

    const getPartnerAndMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        getPartnerAndMarketData()
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    useEffect(() => {
        if (assetTemplateState.allAssetTemplates) {
            const rows =
                assetTemplateState.allAssetTemplates && Array.isArray(assetTemplateState.allAssetTemplates)
                    ? assetTemplateState.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
            const findPhones = (rows && rows?.length > 0) && rows?.find((r) => (r?.name || '').toLowerCase() === 'phones')
            setSelectedSector(findPhones || rows?.[0] || undefined)
        }
    }, [assetTemplateState.allAssetTemplates]);

    const getBrokersComparisonData = async () => {
        let formatData: any = {
            ...paginationState,
        }
        if (search) {
            formatData.filters = search
        }
        if (selectedSector) {
            formatData = {
                ...formatData || {},
                data: {
                    ...formatData?.data || {},
                    assetTemplateId: selectedSector?.value
                }
            }
        }
        if (selectedMarket) {
            formatData = {
                ...formatData || {},
                data: {
                    ...formatData?.data || {},
                    marketId: selectedMarket?.value
                }
            }
        }
        await dispatch(tryToFetchBrokersComparisonSites(formatData));
    }

    const onSelectFilter = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'sector':
                    setSelectedSector(value)
                    break;
                case 'market':
                    setSelectedMarket(value)
                    break;
            }
        }
        onResetPaginationState()
    }

    useEffect(() => {
        getBrokersComparisonData()
    }, [paginationState]);

    const onSeeBrokerDetails = async (brokerId: string) => {
        navigate(`/brokers-comparison-sites/${brokerId}`);
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const onAddNewBroker = (id?: string) => {
        if (id) {
            setBrokerId(id)
        }
        setShowBrokerModal(true)
    }

    const onEnableDisableBrokerComparisonSites = async (id: string, currentStatus: boolean) => {
        try {
            if (currentStatus) {
                await dispatch(tryToDisableBrokerComparisonSite(id)).unwrap();
                setShowToast({ type: 'success', message: `Broker disabled successfully` });
            } else {
                await dispatch(tryToEnableBrokerComparisonSite(id)).unwrap();
                setShowToast({ type: 'success', message: `Broker enabled successfully` });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
    }

    const onDeleteBrokerComparisonSites = async () => {
        try {
            await dispatch(tryToDeleteBrokerComparisonSite(showDeleteModal?.id || ''))
            setShowToast({ type: 'success', message: `Broker successfully deleted` });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setShowDeleteModal(undefined)
    }

    const onCloseBrokerModal = () => {
        setBrokerId(null)
        setShowBrokerModal(false)
    }

    const onSaveBrokerChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        setBrokerId(null)
    }

    const onRequestDeleteBrokerComparisonSites = (id: string) => {
        setShowDeleteModal({ show: true, id })
    }

    const onCloseDeleteModal = () => {
        setShowDeleteModal(undefined)
    }

    const onClearFilters = () => {
        setSelectedSector(undefined)
        setSelectedMarket(undefined)
        setSearch(undefined)
        onResetPaginationState()
    };

    return (
        <div>
            <div>
                <p className='page-title'>Brokers for Comparison Site</p>
            </div>
            <div className='my-5 relativ'>
                <div className='flex flex-row justify-end mb-6'>
                    <Button
                        label={'Add New Broker for Comparison Sites'}
                        dataQa={'add-new-broker-comparison'}
                        className={`${(!accessControl || pageAccess?.actions?.['Add New Broker']) ? 'btn-main' : 'btn-main-disable'}`}
                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Broker']) && onAddNewBroker()}
                    />
                </div>
                <div>
                    <BrokersComparisonCategories
                        sectors={sectors}
                        markets={markets}
                        selectedMarket={selectedMarket}
                        selectedSector={selectedSector}
                        onSelectFilter={onSelectFilter}
                    />
                </div>
                <div className={'min-w-[100px]'}>
                    <SearchWithButton
                        placeholder='Filter Table'
                        dataQa={'filter-table'}
                        search={search}
                        mainContainerStyle={'!grid-cols-10'}
                        onChangeSearchValue={onChangeSearchValue}
                        buttonLabel={'Clear All Filters'}
                        onClickButton={onClearFilters}
                        buttonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end bg-transparent mt-1'}
                    />
                </div>
                {(rows?.length > 0 || rowsLoading) ?
                    <BrokersComparisonTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        accessEnableDisable={!accessControl || pageAccess?.actions?.['Enable Disable']}
                        setPaginationState={setPaginationState}
                        onEnableDisableBrokerComparisonSites={onEnableDisableBrokerComparisonSites}
                        onSeeBrokerDetails={onSeeBrokerDetails}
                        onAddNewBroker={onAddNewBroker}
                        onDeleteBrokerComparisonSites={onRequestDeleteBrokerComparisonSites}
                    />
                    : <EmptyContainer
                        text={'No Brokers Comparison Site to view yet.'}
                        showImage={true}
                    />
                }
                {showToast?.message &&
                    <Toast
                        type={showToast?.type}
                        text={showToast?.message}
                        onHandleCloseToast={() => setShowToast(null)}
                    />
                }
                {showBrokerModal &&
                    <BrokerComparisonModal
                        openCreateBrokerModal={showBrokerModal}
                        handleCloseBrokerModal={onCloseBrokerModal}
                        onSaveChanges={onSaveBrokerChanges}
                        brokerId={brokerId}
                    />
                }
                <DeleteModal
                    openDeleteModal={showDeleteModal?.show || false}
                    handleCloseDeleteModal={onCloseDeleteModal}
                    onApproveDeletion={onDeleteBrokerComparisonSites}
                />
            </div>
        </div>
    );
};
export default BrokersComparisonSites;