const GA_TRACKING_ID = 'G-N974ZT28PX';

// log the pageview with their URL
export const gaPageView = (url: string) => {
	if (process.env.NEXT_PUBLIC_ENV !== 'DEV') {
		(window as any).gtag('config', GA_TRACKING_ID, {
			page_path: url,
		});
	}
};

// log specific events happening.
export const onLogEvent = (
	sessionId: string,
	broker: string,
	label: string = window.location.pathname
) => {
	if (process.env.NEXT_PUBLIC_ENV !== 'DEV') {
		(window as any).gtag('event', 'click', {
			event_label: label,
			event_broker: broker,
			event_sessionId: sessionId,
		});
	}
};
