import React from 'react';
import Table from '../../../../shared/table';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IRentalPricingSettings } from '../../../../interfaces/rental-pricing-settings/IRentalPricingSettings';

const tableColumns = ['Category', 'Country', 'Financing Cost', 'Residual Value', 'Rental Term ( Weeks )', 'Actions']

interface IRentalPricingSettingsTable {
    rows?: Array<any>;

    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    loading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onEditRentalPricingSetting: (id: string) => void;
    onDeleteRentalPricingSetting: (id: string) => void;
}

const RentalPricingSettingsTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    loading,
    setPaginationState,
    onEditRentalPricingSetting,
    onDeleteRentalPricingSetting,
}: IRentalPricingSettingsTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {loading ?
                <>
                    <>{[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                            </tr>
                        )
                    })}
                    </>
                </>
                : <>
                    {(rows && rows?.length > 0) && rows?.map((row: IRentalPricingSettings, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.category?.displayName}</td>
                                <td className='px-6 py-4'>{row?.country}</td>
                                <td className='px-6 py-4'>{row?.financingCost || 0}%</td>
                                <td className='px-6 py-4'>{row?.residualValue || 0}%</td>
                                <td className='px-6 py-4'>{row?.termRentalWeeks}</td>

                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        {/* <div onClick={() => ((!accessControl || actions?.['See RentalPricingSettings Details']) && row?._id) && onViewRentalPricingSettings(row?._id)}>
                                            <img title={'View RentalPricingSettings'} alt='view item' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See RentalPricingSettings Details']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div> */}
                                        <div data-qa={'edit-rental-pricing-settings'} onClick={() => ((!accessControl || actions?.['Edit Rental Pricing Settings']) && row?._id) && onEditRentalPricingSetting(row?._id)}>
                                            <img title={'Edit Rental Pricing Settings'} alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Rental Pricing Settings']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa={'delete-rental-pricing-settings'} onClick={() => ((!accessControl || actions?.['Delete Rental Pricing Settings']) && row?._id) && onDeleteRentalPricingSetting(row?._id)}>
                                            <img title={'Delete Rental Pricing Settings'} alt='edit item' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Rental Pricing Settings']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default RentalPricingSettingsTable;