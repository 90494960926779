import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToFetchAssetValues } from '../../../../store/brokers/admin/assets/assetsSlice';
import { tryToDownloadPurchaseSamples } from '../../../../store/brokers/partners/purchase-orders/purchaseOrdersSlice';
import CatalogFiltersSample from '../details/CatalogFiltersSample';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Loading from '../../../../shared/Loading';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IDownloadSampleModal {
    openDownloadModal: boolean;
    sectors: Array<any>;
    handleCloseDownloadModal: () => void;
}

const DownloadSampleModal = ({
    openDownloadModal,
    sectors,
    handleCloseDownloadModal
}: IDownloadSampleModal) => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [selectedSector, setSelectedSector] = useState<any>()
    const [downloadError, setDownloadError] = useState<string>()
    const [templateFields, setTemplateFields] = useState<any>()
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [templateData, setTemplateData] = useState<Array<any>>()
    const [filterValues, setFilterValues] = useState<any>()
    const [loadingFields, setLoadingFields] = useState<boolean>(false)
    const [loadingItemFields, setLoadingItemFields] = useState<boolean>(false)
    const [searchField, setSearchField] = useState<any>()
    const [filteredFields, setFilteredFields] = useState<any>()
    const [loadingOptions, setLoadingOptions] = useState<any>()

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(e)
    }

    const onCloseModal = () => {
        handleCloseDownloadModal();
        setSelectedSector(undefined)
        setDownloadError(undefined)
        setLoadingDownload(false)
        setFilterValues(undefined)
        setLoadingFields(false)
        setLoadingItemFields(false)
    }

    const onGettingFieldValues = async () => {
        if (selectedSector) {
            let data: any = {
                exclude: true,
                values: []
            }
            if (filterValues) {
                const keysArray = filterValues && Object.keys(filterValues)
                const formatValues = keysArray && keysArray?.length > 0 && keysArray?.map((name: string) => { return { [name]: filterValues?.[name] } })
                data = {
                    ...data || {},
                    values: formatValues || []
                }
            }
            if (searchField) {
                data = {
                    ...data || {},
                    search: {
                        ...searchField || {}
                    }
                }
            }
            const response = await dispatch(tryToFetchAssetValues({ id: selectedSector?.id, data })).unwrap()
            setTemplateFields(response || [])
            setLoadingFields(false)
            setLoadingItemFields(false)
            setLoadingOptions(undefined)
        }
    }

    useEffect(() => {
        if (selectedSector) {
            onGettingFieldValues()
            setLoadingFields(true)
        }
    }, [selectedSector])

    useEffect(() => {
        if (selectedSector) {
            onGettingFieldValues()
        }
    }, [filterValues, searchField])

    const onDownloadTemplate = async () => {
        if (loadingDownload) {
            return;
        }
        setLoadingDownload(true)
        if (!selectedSector) {
            setDownloadError('Please select a category first to continue with download.')
            setLoadingDownload(false)
            return;
        }
        try {
            const filters = filterValues && Object.keys(filterValues)
            let formatFilters
            const filtersValuesFormat = filters && filters?.length > 0 && filters?.map((item: any) => {
                return {
                    name: item,
                    value: Object.keys(filterValues) ? filterValues?.[item] || undefined : undefined
                }
            })
            if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
                formatFilters = {
                    fields: filtersValuesFormat?.filter((key: any) => key?.value)
                }
            }
            const response: any = await dispatch(tryToDownloadPurchaseSamples({ id: selectedSector?.id || '', data: formatFilters })).unwrap()
            setTemplateData(response || [])
            setDownloadError(undefined)
        } catch (err: any) {
            setDownloadError(`${err}`)
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (templateData && loadingDownload) {
            csvLink.current.link.click()
            onCloseModal()
        }
        setLoadingDownload(false)
    }, [templateData])

    const onClearAllFilters = () => {
        setFilterValues(undefined)
    }

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        if (type) {
            setLoadingItemFields(true)
            setFilterValues({
                ...(filterValues || {}),
                [type]: value?.value || undefined,
            });
            if (searchField?.[type]) {
                setSearchField(undefined)
            }
        }
    };

    const onMenuCloseAfterSearch = (type?: string) => {
        if (type && searchField?.[type]) {
            setSearchField(undefined)
        }
    }

    const onChangeSearchAssetFields = (e: any, type?: string) => {
        if (type && e) {
            if (templateFields && templateFields?.[type]) {
                const formatFilteredFields = templateFields?.[type] && templateFields?.[type]?.length > 0 && templateFields?.[type]?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
                if (formatFilteredFields && formatFilteredFields?.length > 0) {
                    setFilteredFields({ [type]: formatFilteredFields })
                }
                else {
                    setFilteredFields(undefined)
                    setLoadingOptions({ [type]: true })
                    setSearchField({
                        [type]: e
                    })
                    if (filterValues?.[type]) {
                        const formatFormData = Object.assign(filterValues)
                        delete formatFormData[type]
                        setFilterValues(
                            {
                                ...formatFormData || {}
                            }
                        )
                    }
                }
            }
        } else {
            setFilteredFields(undefined)
        }
    }

    const onClearAllFields = () => {
        setFilterValues(undefined)
        setSearchField(undefined)
        setFilteredFields(undefined)
        setLoadingItemFields(false)
    }

    return (
        <Modal
            open={openDownloadModal}
            onClose={onCloseModal}
            showInRight={true}
            contentContainerStyle={'!min-w-[650px]'}>
            <div className={'p-2 min-w-[600px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='mb-2 font-semibold text-sm'>Download Template</p>
                </div>
                <div className='p-2 !rounded bg-[#f8f9fc]'>
                    <p className='mb-4 font-bold'>Select a category to download the CSV template.</p>
                    {downloadError && <Error text={downloadError} />}
                    <div className='flex flex-col items-center'>
                        <SelectCheckbox
                            name='Category'
                            placeholder=' '
                            dataQa={selectedSector?.name === undefined ? 'category-selected' : selectedSector?.name.toLowerCase().replaceAll(' ', '-')}
                            containerStyle='mb-1 min-w-full'
                            selectedOption={selectedSector}
                            onChangeSelectedOption={onChangeSelectedSector}
                            options={sectors || []}
                        />
                        {loadingFields ?
                            <div className='flex flex-rows col-span-4 justify-center items-center my-7'>
                                <Loading />
                            </div>
                            :
                            <div className={'min-w-[100%]'}>
                                {(selectedSector && templateFields) &&
                                    <>
                                        <p className='mt-4 mb-2'>Select the filters you want to apply to the selected template, or download the full catalog.</p>
                                        <CatalogFiltersSample
                                            templateFields={templateFields || []}
                                            filterValues={filterValues || null}
                                            loadingFields={loadingItemFields}
                                            loadingOptions={loadingOptions}
                                            fieldValues={templateFields}
                                            filteredFields={filteredFields}
                                            onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                                            onChangeSelectedValue={onChangeSelectedFilterValue}
                                            onChangeSearchAssetFields={onChangeSearchAssetFields}
                                            onClearAllFilters={onClearAllFilters}
                                        />
                                    </>
                                }
                            </div>
                        }
                        <div className='flex flex-row justify-center'>
                            {(selectedSector && templateFields) && <Button
                                label={'Clear Fields'}
                                onClickButton={() => onClearAllFields()}
                                disabled={loadingDownload}
                                className={`${loadingDownload ? 'btn-main-disable' : 'btn-main'} my-3 !py-1 !rounded !shadow-none mr-3`}
                            />
                            }
                            <Button
                                label={loadingDownload ? 'Loading data...' : 'Download Template'}
                                onClickButton={() => !loadingDownload && onDownloadTemplate()}
                                disabled={loadingDownload}
                                dataQa={loadingDownload ? 'loading-data...' : 'download-catalog'}
                                className={`${loadingDownload ? 'btn-main-disable' : 'btn-primary'} my-3 !py-1 !rounded !shadow-none`}
                            />
                        </div>
                        <CSVLink
                            ref={csvLink}
                            data={templateData && templateData?.length > 0 ? templateData?.filter((item, idx) => idx > 0) : []}
                            headers={templateData?.[0] || []}
                            className={'none'}
                            target={'_blank'}
                            filename={`Sales-order-${selectedSector?.name || 'catalog'}-template.csv`}
                        >
                        </CSVLink>
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default DownloadSampleModal;