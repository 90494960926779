import React from 'react';

export const navigationAdmin = [
    { name: 'Users', dataQa: 'user-tab', href: '/users', icon: <img alt='Users' src='/assets/navigation-icons/users.svg' />, iconActive: <img alt='users-active' src='/assets/navigation-icons/users-black.svg' />, enabled: true },
    // { name: 'Partners', href: '/partners', icon: <img alt='partners' src='/assets/navigation-icons/partners.svg' />, enabled: true, },
    { name: 'Asset Catalogs', dataQa: 'asset-catalogs-tab', href: '/asset-catalogs', icon: <img alt='assets catalogs' src='/assets/navigation-icons/asset-catalogs.svg' />, iconActive: <img alt='asset-catalogs-active' src='/assets/navigation-icons/asset-catalogs-black.svg' />, enabled: true, parent: 'Catalog Management' },
    { name: 'Assets', dataQa: 'asset-tab', href: '/assets', icon: <img alt='assets' src='/assets/navigation-icons/assets.svg' />, iconActive: <img alt='assets-active' src='/assets/navigation-icons/assets-black.svg' />, enabled: true, parent: 'Catalog Management' },
    { name: 'Assets Extended', dataQa: 'asset-extended-tab', href: '/assets-extended', icon: <img alt='assets' src='/assets/navigation-icons/assets-extended.svg' />, iconActive: <img alt='assets-extended-active' src='/assets/navigation-icons/assets-extended-black.svg' />, enabled: true, parent: 'Catalog Management' },
    { name: 'Broker Fields Settings', dataQa: 'broker-fields-settings', href: '/broker-fields-settings', icon: <img alt='broker-fields-settings' src='/assets/navigation-icons/field-settings.svg' />, iconActive: <img alt='broker-fields-settings-active' src='/assets/navigation-icons/field-settings-black.svg' />, enabled: true, parent: 'Catalog Management' },
    { name: 'Markets', dataQa: 'markets-tab', href: '/markets', icon: <img alt='markets' src='/assets/navigation-icons/markets.svg' />, iconActive: <img alt='markets-active' src='/assets/navigation-icons/markets-black.svg' />, enabled: true },
    { name: 'Orders', dataQa: 'orders-tab', href: '/admin-orders', icon: <img alt='admin-orders' src='/assets/navigation-icons/orders.svg' />, iconActive: <img alt='orders-active' src='/assets/navigation-icons/orders-black.svg' />, enabled: true },
    { name: 'Pricing History', dataQa: 'pricing-history-tab', href: '/pricing-history-admin', icon: <img alt='pricing-hisotry' src='/assets/navigation-icons/pricing-history.svg' />, iconActive: <img alt='pricing-history-active' src='/assets/navigation-icons/pricing-history-black.svg' />, enabled: true, parent: 'Pricing' },
    { name: 'Pricing Reviews', dataQa: 'pricing-review-tab', href: '/pricing-reviews', icon: <img alt='pricing-review' src='/assets/navigation-icons/pricing-reviews.svg' />, iconActive: <img alt='pricing-reviews-active' src='/assets/navigation-icons/pricing-reviews-black.svg' />, enabled: true, parent: 'Pricing' },
    { name: 'Rental Pricing', dataQa: 'rental-pricing', href: '/rental-pricing', icon: <img alt='rental-pricing' src='/assets/navigation-icons/rental-pricing.svg' />, iconActive: <img alt='rental-pricing-active' src='/assets/navigation-icons/rental-pricing-black.svg' />, enabled: true },
    { name: 'Comparison Sites', dataQa: 'comparison-sites-tab', href: '/comparison-sites', icon: <img alt='comparison-sites' src='/assets/navigation-icons/comparison-sites.svg' />, iconActive: <img alt='comparison-sites-active' src='/assets/navigation-icons/comparison-sites-black.svg' />, enabled: true, parent: 'Comparison Sites' },
    { name: 'Brokers For Comparison Sites', dataQa: 'brokers-comparison-sites-tab', href: '/brokers-comparison-sites', icon: <img alt='brokers-for-comparison-sites' src='/assets/navigation-icons/brokers-comparison-sites.svg' />, iconActive: <img alt='brokers-comparison-sites-active' src='/assets/navigation-icons/brokers-comparison-sites-black.svg' />, enabled: true, parent: 'Comparison Sites' },
    { name: 'Comparison Site Pricing', dataQa: 'pricing-comparison-site', href: '/comparison-site-pricing', icon: <img alt='offers-for-comparison-sites' src='/assets/navigation-icons/comparison-site-pricing.svg' />, iconActive: <img alt='comparison-site-pricing-active' src='/assets/navigation-icons/comparison-site-pricing-black.svg' />, enabled: true, parent: 'Comparison Sites' },
    { name: 'Item Allocation', dataQa: 'item-allocation', href: '/item-allocation', icon: <img alt='item-allocation' src='/assets/navigation-icons/item-allocation.svg' />, iconActive: <img alt='item-allocation-active' src='/assets/navigation-icons/item-allocation-black.svg' />, enabled: true },
    { name: 'Invoices', dataQa: 'invoices-tab', href: '/admin-invoices', icon: <img alt='admin-invoices' src='/assets/navigation-icons/invoice-details.svg' />, iconActive: <img alt='invoice-details-active' src='/assets/navigation-icons/invoice-details-black.svg' />, enabled: true, parent: 'Invoicing' },
    { name: 'Invoice Details', dataQa: 'invoices-details-tab', href: '/admin-invoices-details', icon: <img alt='admin-invoices' src='/assets/navigation-icons/invoices.svg' />, iconActive: <img alt='invoices-active' src='/assets/navigation-icons/invoices-black.svg' />, enabled: true, parent: 'Invoicing' },
    { name: 'Category Mapping', dataQa: 'category-mapping-tab', href: '/category-mapping', icon: <img alt='mapping' src='/assets/navigation-icons/category-mapping.svg' />, iconActive: <img alt='category-mapping-active' src='/assets/navigation-icons/category-mapping-black.svg' />, enabled: true },
    { name: 'Postage Fees', dataQa: 'postage-fees-tab', href: '/postage-fees', icon: <img alt='postage-fee' src='/assets/navigation-icons/postage-fees.svg' />, iconActive: <img alt='postage-fees-active' src='/assets/navigation-icons/postage-fees-black.svg' />, enabled: true, parent: 'Fees' },
    { name: 'Commission Fees', dataQa: 'commission-fees-tab', href: '/commission-fees', icon: <img alt='commission-fee' src='/assets/navigation-icons/commission-fees.svg' />, iconActive: <img alt='commission-fees-active' src='/assets/navigation-icons/commission-fees-black.svg' />, enabled: true, parent: 'Fees' },
    { name: 'Scraping Fees ', dataQa: 'scraping-fees-tab', href: '/scraping-fees', icon: <img alt='scraping-fee' src='/assets/navigation-icons/scraping-fees.svg' />, iconActive: <img alt='scraping-fees' src='/assets/navigation-icons/scraping-fees-black.svg' />, enabled: true, parent: 'Fees' },
    { name: 'Shipping Services ', dataQa: 'shipping-services', href: '/shipping-services', icon: <img alt='shipping-services' src='/assets/navigation-icons/shipping-services.svg' />, iconActive: <img alt='shipping-services-active' src='/assets/navigation-icons/shipping-services-black.svg' />, enabled: true, parent: 'Fees' },
    { name: 'Reports', dataQa: 'reports-tab', href: '/reports', icon: <img alt='reports' src='/assets/navigation-icons/reports.svg' />, iconActive: <img alt='reports-active' src='/assets/navigation-icons/reports-black.svg' />, enabled: true },
    { name: 'Pay Services', dataQa: 'pay-services-tab', href: '/pay-services', icon: <img alt='pay-services' src='/assets/navigation-icons/pay-services.svg' />, iconActive: <img alt='pay-services-active' src='/assets/navigation-icons/pay-services-black.svg' />, enabled: true },
    // { name: 'Wholesale Price', dataQa: 'wholesale-price-tab', href: '/wholesale-price-admin', icon: <img alt='wholesale-prices' src='/assets/navigation-icons/wholesale-price.svg' />, iconActive: <img alt='wholesale-price-active' src='/assets/navigation-icons/wholesale-price-black.svg' />, enabled: true, parent: 'Wholesale' },
    // { name: 'Wholesale Price History', dataQa: 'wholesale-price-history-tab', href: '/wholesale-price-history', icon: <img alt='wholesale-price-history' src='/assets/navigation-icons/wholesale-history.svg' />, iconActive: <img alt='wholesale-history-active' src='/assets/navigation-icons/wholesale-history-black.svg' />, enabled: true, parent: 'Wholesale' },
    { name: 'Pricing', dataQa: 'pricing-tab', href: '/pricing-admin', icon: <img alt='pricing-admin' src='/assets/navigation-icons/pricing.svg' />, iconActive: <img alt='pricing-active' src='/assets/navigation-icons/pricing-black.svg' />, enabled: true, parent: 'Pricing' },
    { name: 'TAC', dataQa: 'tac-tab', href: '/tac-admin', icon: <img alt='tac-admin' src='/assets/navigation-icons/tac.svg' />, iconActive: <img alt='tac-active' src='/assets/navigation-icons/tac-black.svg' />, enabled: true },
    { name: 'UI Actions', dataQa: 'ui-tab', href: '/ui-actions', icon: <img alt='ui-actions=admin' src='/assets/navigation-icons/ui-actions.svg' />, iconActive: <img alt='' src='/assets/navigation-icons/ui-actions-black.svg' />, enabled: true },
    { name: 'Pages', dataQa: 'pages-tab', href: '/pages', icon: <img alt='ui-actions=admin' src='/assets/navigation-icons/pages.svg' />, iconActive: <img alt='pages-active' src='/assets/navigation-icons/pages-black.svg' />, enabled: true },
    { name: 'User Access Control', dataQa: 'user-access-tab', href: '/user-access-control', icon: <img alt='user-access-control' src='/assets/navigation-icons/user-access-control.svg' />, iconActive: <img alt='user-access-control-active' src='/assets/navigation-icons/user-access-control-black.svg' />, enabled: true },
    { name: 'Cashout Flow', dataQa: 'cash-out-tab', href: '/cashout-flow', icon: <img alt='cashout-flow' src='/assets/navigation-icons/cashout-flow.svg' />, iconActive: <img alt='cashout-flow-active' src='/assets/navigation-icons/cashout-flow-black.svg' />, enabled: true },
    { name: 'Notification Sections', dataQa: 'notification-sections', href: '/notification-sections', icon: <img alt='notification-sections' src='/assets/navigation-icons/notifications.svg' />, iconActive: <img alt='notifications-active' src='/assets/navigation-icons/notifications-black.svg' />, enabled: true },
    { name: 'Return Merchandise Authorization', dataQa: 'rma-management', href: '/rma-management', icon: <img alt='rma-management' src='/assets/navigation-icons/rma.svg' />, iconActive: <img alt='rma-management-active' src='/assets/navigation-icons/rma-black.svg' />, enabled: true },
];