import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { partnerRMAsService } from './partnerRMAsApi';
import { PaginatedItems } from '../../../../interfaces/items/paginatedStore.type';
import { IItemsListState } from '../../../../interfaces/items/IItemsList';

const initialState: IItemsListState = {
    itemsAreLoading: false,
    requestStatus: 'default',
    message: '',
    createdRMAsAreLoading: false,
    rmaDetailsLoading: false,
};

export const tryToFetchShippedItems = createAsyncThunk<
    PaginatedItems,
    IPaginationPayload
>('RMAs/tryToFetchShippedItems', async ({ pageNumber, pageSize, filters, status, data, category }) => {
    const result = await partnerRMAsService.tryToFetchShippedItems(
        pageNumber,
        pageSize,
        filters,
        status,
        data,
        category
    );
    return result?.data;
});

export const tryToCreateRMA = createAsyncThunk<any, any>(
    'RMAs/tryToCreateRMA',
    async ({ data, brokerId }) => {
        await partnerRMAsService.tryToCreateRMA(data, brokerId);
    },
);

export const tryToFetchAllRMAReasons = createAsyncThunk<any, undefined>(
    'RMAs/tryToFetchAllRMAReasons',
    async () => {
        const result = await partnerRMAsService.tryToFetchAllRMAReasons();
        return result?.data;
    },
);

export const tryToFetchAllCreatedRMAs = createAsyncThunk<
    any,
    IPaginationPayload
>(
    'RMAs/tryToFetchAllCreatedRMAs',
    async ({ pageNumber, pageSize, filters, status , data}) => {
        const result = await partnerRMAsService.tryToFetchAllCreatedRMAs(
            pageNumber,
            pageSize,
            filters,
            status,
            data
        );
        return result?.data;
    },
);

export const tryToFetchSingleRMADetails = createAsyncThunk<any, { id: string, paginationState: IPaginationPayload }>(
    'RMAs/tryToFetchSingleRMADetails',
    async ({ id, paginationState }) => {
        const result = await partnerRMAsService.tryToFetchSingleRMADetails(id, paginationState);
        return result?.data;
    },
);

export const tryToFetchItemsToShip = createAsyncThunk<
    PaginatedItems,
    IPaginationPayload
>('RMAs/tryToFetchItemsToShip', async ({ pageNumber, pageSize, filters, data, category }) => {
    const result = await partnerRMAsService.tryToFetchItemsToShip(
        pageNumber,
        pageSize,
        filters,
        data,
        category
    );
    return result?.data;
});

export const tryToShipItems = createAsyncThunk<any, { itemIds: string[], shippingProvider: string, trackingNumber: string }>(
    'RMAs/tryToShipItems',
    async ({ itemIds, shippingProvider, trackingNumber }) => {
        const result = partnerRMAsService.shipItems({ itemIds, shippingProvider, trackingNumber });
        return result
    },
);

export const tryToAcceptRevaluation = createAsyncThunk<any, string>(
    'RMAs/tryToAcceptRevaluation',
    async (id) => {
        const result = partnerRMAsService.tryToAcceptRevaluation(id)
        return result
    },
);


export const tryToRejectRevaluation = createAsyncThunk<any, string>(
    'RMAs/tryToRejectRevaluation',
    async (id) => {
        const result = partnerRMAsService.tryToRejectRevaluation(id)
        return result
    },
);

export const tryToAcceptRevaluationFromIMS = createAsyncThunk<any, {rmaId:string, id:string}>(
    'RMAs/tryToAcceptRevaluationFromIMS',
    async ({rmaId, id}) => {
        const result = partnerRMAsService.tryToAcceptRevaluationFromIMS(rmaId,id)
        return result
    },
);

export const tryToRejectRevaluationFromIMS = createAsyncThunk<any, {rmaId:string, id:string}>(
    'RMAs/tryToRejectRevaluationFromIMS',
    async ({rmaId, id}) => {
        const result = partnerRMAsService.tryToRejectRevaluationFromIMS(rmaId,id)
        return result
    },
);


export const partnerRMAsSlice = createSlice({
    name: 'RMAs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchShippedItems
            .addCase(tryToFetchShippedItems.pending, (state) => {
                state.itemsAreLoading = true;
            })
            .addCase(tryToFetchShippedItems.rejected, (state) => {
                state.itemsAreLoading = false;
            })
            .addCase(tryToFetchShippedItems.fulfilled, (state, action) => {
                state.itemsAreLoading = false;
                state.items = action.payload;
            })
            // tryToFetchAllCreatedRMAs
            .addCase(tryToFetchAllCreatedRMAs.pending, (state) => {
                state.createdRMAsAreLoading = true;
            })
            .addCase(tryToFetchAllCreatedRMAs.rejected, (state) => {
                state.createdRMAsAreLoading = false;
            })
            .addCase(tryToFetchAllCreatedRMAs.fulfilled, (state, action) => {
                state.createdRMAsAreLoading = false;
                state.createdRMAs = action.payload;
            })
            // tryToFetchSingleRMADetails
            .addCase(tryToFetchSingleRMADetails.pending, (state) => {
                state.rmaDetailsLoading = true
            })
            .addCase(tryToFetchSingleRMADetails.rejected, (state) => {
                state.rmaDetailsLoading = false
            })
            .addCase(tryToFetchSingleRMADetails.fulfilled, (state, action) => {
                state.rmaDetailsLoading = false
                state.rmaDetails = action.payload;
            })
            // tryToFetchItemsToShip
            .addCase(tryToFetchItemsToShip.pending, (state) => {
                state.itemsAreLoading = true;
            })
            .addCase(tryToFetchItemsToShip.rejected, (state) => {
                state.itemsAreLoading = false;
            })
            .addCase(tryToFetchItemsToShip.fulfilled, (state, action) => {
                state.itemsAreLoading = false;
                state.items = action.payload;
            });
    },
});

export default partnerRMAsSlice.reducer;
