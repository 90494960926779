import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { INotificationSection } from '../../../../interfaces/notification-sections/INotificationSection';
import {
    tryToChangeBrokerNotificationSettings,
    tryToFetchBrokerNotificationSettings
} from '../../../../store/brokers/partners/broker-notifications/brokerNotificationsSlice';
import { tryToFetchAllNotificationSection } from '../../../../store/brokers/admin/notification-sections/notificationSectionsSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import NotificationSettingsContainer from '../loading/NotificationSettingsContainer';


interface INotificationSettingsModal {
    openModal: boolean;
    handleCloseModal: () => void;
}

const NotificationSettingsModal = ({
    openModal,
    handleCloseModal,
}: INotificationSettingsModal) => {
    const dispatch = useAppDispatch()
    const [selectedSection, setSelectedSection] = useState<any>()
    const [sections, setSections] = useState<any>();
    const [email, setEmail] = useState<any>();
    const [emailError, setEmailError] = useState<string>();
    const [submitError, setSubmitError] = useState<string>();
    const [editEmail, setEditEmail] = useState<string>();
    const [editSection, setEditSection] = useState<string>();
    const [newEditEmail, setNewEditEmail] = useState<string>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [notificationDetails, setNotificationDetails] = useState<any>()
    const state = useAppSelector(state => state?.brokerNotifications)
    const loadingDetails = useAppSelector(state => state?.brokerNotifications?.brokerNotificationsAreLoading)
    const sectionsData = useAppSelector(state => state?.notificationSections)

    useEffect(() => {
        setNotificationDetails(state?.brokerNotifications?.sections || undefined)
    }, [state?.brokerNotifications])

    useEffect(() => {
        if (sectionsData?.allSections) {
            const format = (sectionsData?.allSections && sectionsData?.allSections?.length > 0)
                && sectionsData?.allSections?.map((item: INotificationSection) => ({ ...item || {}, label: item?.displayName, value: item?.name }))
            setSections(format)
        }
    }, [sectionsData?.allSections])

    const onCloseModal = () => {
        handleCloseModal();
        setSelectedSection(undefined)
        setEmailError(undefined)
        setSubmitError(undefined)
        setLoadingSubmit(false)
    }

    const onGettingSectionsData = async () => {
        try {
            await dispatch(tryToFetchAllNotificationSection()).unwrap()
        } catch (err) {
            setSections(undefined)
        }
    }

    const onGettingBrokerNotificationsData = async () => {
        try {
            await dispatch(tryToFetchBrokerNotificationSettings()).unwrap()
        } catch (err) {
            setSections(undefined)
        }
    }

    useEffect(() => {
        onGettingSectionsData()
        onGettingBrokerNotificationsData()
    }, [])

    const onAddItem = () => {
        /* eslint-disable-next-line */
        if (!(/^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))) {
            setEmailError('Please write valid email')
            return;
        }
        setEmailError(undefined)
        if (!email || !selectedSection) {
            return;
        }
        const findIfSectionExist = (notificationDetails && notificationDetails?.length > 0) && notificationDetails?.find((item: any) => item?.name === selectedSection?.value)
        if (findIfSectionExist) {
            const formatNotifications = notificationDetails && notificationDetails?.length > 0 && notificationDetails?.map((item: any) => {
                if (item?.name === selectedSection?.value) {
                    const formatEmails = [...item?.emails || [], email]
                    const uniqueEmails = _.uniq(formatEmails)
                    return {
                        ...item,
                        emails: uniqueEmails || []
                    }
                } else {
                    return item
                }
            })
            setNotificationDetails(formatNotifications)
        } else {
            const formatNotifications = [
                ...notificationDetails || [],
                {
                    name: selectedSection?.value,
                    displayName: selectedSection?.label,
                    emails: [email]
                }
            ]
            setNotificationDetails(formatNotifications)
        }
        setEmailError(undefined)
        setEmail(undefined)
        setSelectedSection(undefined)
    }

    const onSubmit = async (type?: string) => {
        setLoadingSubmit(true)
        try {
            const body = {
                sections: type === 'delete' ? null : notificationDetails
            }
            await dispatch(tryToChangeBrokerNotificationSettings(body)).unwrap()
            onCloseModal()
        } catch (err) {
            setSubmitError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const onChangeEmail = (e: any, type?: string, index?: any) => {
        setEmail(e)
    }

    const onChangeSelectedSection = (e: any) => {
        setSelectedSection(e)
    }

    const onDeleteEmailFromSection = (email: string, section: string) => {
        const formatNotifications = (notificationDetails && notificationDetails?.length > 0) && notificationDetails?.map((item: any) => {
            if (item?.name === section) {
                const formatEmails = (item?.emails && item?.emails?.length > 0) && item?.emails?.filter((emailItem: string) => emailItem !== email)
                return (formatEmails && formatEmails?.length > 0) ? {
                    ...item,
                    emails: formatEmails
                } : {}
            } else {
                return item
            }
        })
        const newFormat = (formatNotifications && formatNotifications?.length > 0) && formatNotifications.filter((value: any) => Object.keys(value).length !== 0);
        setNotificationDetails(newFormat)
    }

    const onDoubleClickEmail = (email: string, section: string) => {
        setEditEmail(email)
        setEditSection(section)
        setNewEditEmail(email)
    }

    const onEditEmail = (value: any, type?: string) => {
        setNewEditEmail(value)
    }

    const onEnterEmail = async (e: any) => {
        e.stopPropagation()
        if (e?.key === 'Enter') {
            const formatNotifications = (notificationDetails && notificationDetails?.length > 0) && notificationDetails?.map((item: any) => {
                if (item?.name === editSection) {
                    const formatEmails = (item?.emails && item?.emails?.length > 0) && item?.emails?.map((emailItem: string) => {
                        return emailItem === editEmail ? newEditEmail : emailItem
                    })
                    return {
                        ...item,
                        emails: formatEmails
                    }
                } else {
                    return item
                }
            })
            setNotificationDetails(formatNotifications || [])
            setNewEditEmail(undefined)
            setEditEmail(undefined)
            setEditSection(undefined)
        }
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            dataQa={'close-modal'}
            contentContainerStyle='min-w-[650px]'
        >
            <div className={'p-4 min-w-[600px] min-h-[350px] flex flex-col justify-between'}>
                <div>
                    <p className='mb-2 font-semibold text-sm'>Manage Notifications</p>
                    <p className='mb-4 text-sm'>Write email and select the section you want to turn notifications on.</p>
                    {submitError && <Error text={submitError} />}
                    <div>
                        <div className='flex flex-rows items-center mb-3'>
                            <Input
                                label='Email'
                                dataQa={'email'}
                                placeholder=' '
                                showValue={true}
                                onChangeInput={onChangeEmail}
                                containerStyle='mr-3 w-auto'
                                inputValue={email}
                                error={emailError}
                            />
                            <SelectCheckbox
                                name='Section'
                                dataQa={`${(selectedSection?.label || '')?.toLowerCase().replaceAll(' ', '-')}`}
                                placeholder=' '
                                containerStyle='mr-3 min-w-[45%]'
                                selectedOption={selectedSection}
                                onChangeSelectedOption={onChangeSelectedSection}
                                options={sections || []}
                            />
                            <Button
                                label={'+'}
                                dataQa={'add-button'}
                                className={`btn-main rounded mt-2`}
                                onClickButton={onAddItem}
                            />
                        </div>
                        {loadingDetails ?
                            <NotificationSettingsContainer />
                            : <>
                                {(notificationDetails && notificationDetails?.length > 0) && notificationDetails?.map((item: any, index: number) => {
                                    return (
                                        <div key={index}>
                                            <p className='font-bold text-[17px] mb-2 mt-3'>{item?.displayName || item?.name}</p>
                                            {(item?.emails && item?.emails?.length > 0) && item?.emails?.map((emailItem: any, idx: number) => {
                                                return (
                                                    <div key={idx} className='grid grid-cols-2 gap-4 items-center mb-4'>
                                                        {(editEmail === emailItem && item?.name === editSection) ?
                                                            <div onClick={(e) => e.stopPropagation()}>
                                                                <Input
                                                                    placeholder='Email'
                                                                    dataQa={'email'}
                                                                    showValue={true}
                                                                    inputValue={newEditEmail}
                                                                    onChangeInput={onEditEmail}
                                                                    onHandleKeyDown={onEnterEmail}
                                                                />
                                                            </div>
                                                            : <div className='cursor-pointer text-[#a4a4a4]' onDoubleClick={() => onDoubleClickEmail(emailItem, item?.name)}>{emailItem}</div>
                                                        }
                                                        <div className='cursor-pointer' onClick={() => onDeleteEmailFromSection(emailItem, item?.name)}>
                                                            <img data-qa={'delete-item'} src={'/assets/shared/trash-gray.svg'} className={'w-[15px] ml-4 hover:w-[17px] object-contain'} />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </div>
                </div>
                <div className='flex flex-rows justify-end items-center mt-7'>
                    {state?.brokerNotifications?.sections &&
                        <Button
                            className='btn-error rounded !shadow-none min-w-[130px] mr-3'
                            label='Delete Settings'
                            dataQa={'delete-settings'}
                            onClickButton={() => !loadingSubmit && onSubmit('delete')}
                        />
                    }
                    <Button
                        className={`${loadingSubmit ? 'btn-main-disable' : 'btn-main'} !shadow-none min-w-[130px]`}
                        label='Submit'
                        dataQa={'submit'}
                        onClickButton={() => !loadingSubmit && onSubmit()}
                    />
                </div>
            </div>
        </Modal >
    )
}
export default NotificationSettingsModal;