import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IFieldSettingsListState } from '../../../../interfaces/broker-fields-settings/IFieldSettingsList';
import { FieldSettingsService } from './fieldSettingsApi';

const initialState: IFieldSettingsListState = {
    fieldSettingsIsLoading: false,
    fieldSettingsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAllFieldSettings = createAsyncThunk<
    any,
    IPaginationPayload
>('fieldSettings/tryToFetchAllFieldSettings',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await FieldSettingsService.tryToFetchAllFieldSettings(
            pageNumber,
            pageSize,
            filters,
            data
        );
        return result;
    },
);

export const tryToAddFieldSettings = createAsyncThunk<any, any>(
    'fieldSettings/tryToAddFieldSettings',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await FieldSettingsService.tryToAddFieldSettings(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditFieldSettings = createAsyncThunk<any, any>(
    'fieldSettings/tryToEditFieldSettings',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await FieldSettingsService.tryToEditFieldSettings(id, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchSingleFieldSetting = createAsyncThunk<any, string>(
    'fieldSettings/tryToFetchSingleFieldSetting',
    async (id) => {
        const result = await FieldSettingsService.tryToFetchSingleFieldSetting(id);
        return result?.data;
    },
);

export const tryToFindOneFieldSetting = createAsyncThunk<any, any>(
    'fieldSettings/tryToFindOneFieldSetting',
    async ({ brokerId, templateId }) => {
        const result = await FieldSettingsService.tryToFindOneFieldSetting(brokerId, templateId);
        return result?.data;
    },
);

export const tryToDeleteFieldSettings = createAsyncThunk<ApiResponse<any>, any>(
    'fieldSettings/tryToDeleteFieldSettings',
    async (id, { rejectWithValue }) => {
        try {
            const results = await FieldSettingsService.tryToDeleteFieldSettings(id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const fieldSettingsSlice = createSlice({
    name: 'fieldSettings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAllFieldSettings
            .addCase(tryToFetchAllFieldSettings.pending, (state) => {
                state.fieldSettingsAreLoading = true;
            })
            .addCase(tryToFetchAllFieldSettings.rejected, (state) => {
                state.fieldSettingsAreLoading = false;
            })
            .addCase(tryToFetchAllFieldSettings.fulfilled, (state, action) => {
                state.fieldSettingsAreLoading = false;
                state.fieldSettings = action.payload;
            })

            // tryToAddFieldSettings
            .addCase(tryToAddFieldSettings.pending, (state) => {
                state.fieldSettingsIsLoading = true;
            })
            .addCase(tryToAddFieldSettings.rejected, (state) => {
                state.fieldSettingsIsLoading = false;
            })
            .addCase(tryToAddFieldSettings.fulfilled, (state, action) => {
                state.fieldSettingsIsLoading = false;
                state.requestStatus = 'success';
                const data: any = action.payload || action.meta.arg;
                if (state.fieldSettings?.data) {
                    state.fieldSettings.data.elements = [
                        data || {},
                        ...state.fieldSettings?.data.elements || [],
                    ];
                }
            })

            // tryToEditFieldSettings
            .addCase(tryToEditFieldSettings.pending, (state) => {
                state.fieldSettingsIsLoading = true;
            })
            .addCase(tryToEditFieldSettings.rejected, (state) => {
                state.fieldSettingsIsLoading = false;
            })
            .addCase(tryToEditFieldSettings.fulfilled, (state, action) => {
                state.fieldSettingsIsLoading = false;
                if (state.fieldSettings?.data.elements)
                    state.fieldSettings.data.elements =
                        { ...state }.fieldSettings?.data.elements.map((asset) => {
                            if (asset?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload?.data || {},
                                };
                            } else {
                                return asset;
                            }
                        }) || [];
                state.fieldSettingsDetails = action.payload.data;
            })

            // tryToFetchSingleFieldSetting
            .addCase(tryToFetchSingleFieldSetting.pending, (state) => {
                state.fieldSettingsIsLoading = true;
            })
            .addCase(tryToFetchSingleFieldSetting.rejected, (state) => {
                state.fieldSettingsIsLoading = false;
            })
            .addCase(tryToFetchSingleFieldSetting.fulfilled, (state, action) => {
                state.fieldSettingsIsLoading = false;
                state.fieldSettingsDetails = action.payload?.data;
            })

            // tryToFindOneFieldSetting
            .addCase(tryToFindOneFieldSetting.pending, (state) => {
                state.fieldSettingsIsLoading = true;
            })
            .addCase(tryToFindOneFieldSetting.rejected, (state) => {
                state.fieldSettingsIsLoading = false;
            })
            .addCase(tryToFindOneFieldSetting.fulfilled, (state, action) => {
                state.fieldSettingsIsLoading = false;
                state.fieldSettingsDetails = action.payload?.data;
            })

            // tryToDeleteFieldSettings
            .addCase(tryToDeleteFieldSettings.pending, (state) => {
                state.fieldSettingsIsLoading = true;
            })
            .addCase(tryToDeleteFieldSettings.rejected, (state) => {
                state.fieldSettingsIsLoading = false;
            })
            .addCase(tryToDeleteFieldSettings.fulfilled, (state, action) => {
                state.fieldSettingsIsLoading = false;
                state.requestStatus = 'success';
                if (state.fieldSettings?.data.elements)
                    state.fieldSettings.data.elements =
                        { ...state }.fieldSettings?.data.elements.filter((asset) => asset?._id !== action?.meta?.arg) || [];
            })
    },
});

export default fieldSettingsSlice.reducer;
