import React from 'react'
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets'
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols'
import TableActions from './TableActions'

interface ITableRow {
    row: any
    getStatusColor: (status: string | undefined) => '#E50000' | '#1389E6' | '#00B67A' | '#F7A21E' | '#8A8A8E'
    getCategoryIcon: (category: string) => string | undefined
    findMarket: false | IAllMarkets | undefined
    onAcceptOffer(id?: string): Promise<void>
    onRejectOffer(id?: string): Promise<void>
    onRejectOfferFromIMS(id: string): Promise<void>
    onAcceptOfferFromIMS(id: string): Promise<void>


}

const TableRow = ({ row, getCategoryIcon, getStatusColor, findMarket, onAcceptOffer, onRejectOffer, onAcceptOfferFromIMS, onRejectOfferFromIMS }: ITableRow) => {
    const handleAcceptOffer = () => onAcceptOffer(row?.item?._id)
    const handleRejectOffer = () => onRejectOffer(row?.item?._id)
    const handleAcceptOfferFromIMS = () => onAcceptOfferFromIMS(row?.item?._id)
    const handleRejectOfferFromIMS = () => onRejectOfferFromIMS(row?.item?._id)
    return (
        <tr
            className="bg-white border-b hover:bg-gray-50">
            <td className="px-6 py-6 flex gap-1 items-center">
                <img
                    className="w-6 h-6"
                    src={getCategoryIcon(
                        row?.item?.category,
                    )}
                    alt={row.category}
                    title={row.category}
                />
            </td>
            <td className="px-6 py-4">
                {row?.item.itemName}
            </td>
            <td
                className={`px-6 py-4 text-[${getStatusColor(
                    row?.rmaStatus,
                )}]`}>
                <span
                    className="border px-3 py-1 rounded-[10px] whitespace-nowrap"
                    style={{
                        borderColor: getStatusColor(
                            row.rmaStatus,
                        ),
                    }}>
                    {row?.rmaStatus
                        ? row.rmaStatus
                            .split('_')
                            .map(
                                (word: string) =>
                                    word
                                        .charAt(0)
                                        .toUpperCase() +
                                    word
                                        .slice(1)
                                        .toLowerCase(),
                            )
                            .join(' ')
                        : '- -'}
                </span>
                {row.revalueReason && <p className='text-[#6b7280] mt-2'> Reason: {row.revalueReason}</p>}
                {row.rejectReason && <p className='text-[#6b7280] mt-2'> Reason: {row.rejectReason}</p>}
            </td>

            <td className="px-6 py-4">
                {row?.item?._id}
            </td>

            <td className="px-6 py-4">
                {row?.item?.itemCode}
            </td>
            <td className="px-6 py-4">
                {row?.item?.salePrice && findMarket &&
                    CURRENCY_SYMBOLS?.[
                    `${findMarket?.currency
                        ?.name || ''
                    }`
                    ]}
                {row?.item?.salePrice || '- -'}
            </td>
            <td className="px-6 py-4">
                <div className="flex flex-row items-center ">
                    {(row?.itemCredit?.finalPrice || row?.revaluePrice) && findMarket &&
                        CURRENCY_SYMBOLS?.[
                        `${findMarket?.currency
                            ?.name || ''
                        }`
                        ]}
                    {(row?.itemCredit?.finalPrice || row?.revaluePrice) || '- -'}
                </div>
            </td>
            <td className="px-6 py-4">
                {(row.rmaStatus === 'revalued_return') && <TableActions onAcceptOffer={handleAcceptOffer} onRejectOffer={handleRejectOffer} />}
                {(row.rmaStatus === 'revaluation') && <TableActions onAcceptOffer={handleAcceptOfferFromIMS} onRejectOffer={handleRejectOfferFromIMS} />}
                {row.rmaStatus === 'approved_return' && typeof row.initialRevaluePrice === 'number' && <span className='text-[#E50000]'>Rejected Offer</span>}
                {row.rmaStatus === 'broker_accepted_revaluation' && <span className='text-[#00B67A]'>Accepted Offer</span>}
            </td>
        </tr>
    )
}

export default TableRow