import React from 'react';
import Loading from '../Loading';

interface IButton {
  label: any;
  className?: string;
  disabled?: boolean;
  type?: any;
  icon?: React.ReactNode;
  scndIcon?: React.ReactNode;
  dataQa?: string;
  onClickButton?: (type?: string) => void;
  clickType?: string;
  loading?: boolean | null
}

const Button = ({
  type,
  disabled,
  className,
  label,
  icon,
  scndIcon,
  clickType,
  dataQa,
  onClickButton,
  loading
}: IButton) => {
  return (
    <button
      className={className || 'btn-primary'}
      type={type || 'button'}
      onClick={(e) => onClickButton?.(clickType)}
      disabled={disabled || false}
      data-qa={dataQa || ''}
      
    >
      {!loading && icon}
      {!loading && label}
      {!loading && scndIcon}
      {loading && <Loading />}
    </button>
  );
};

export default Button;