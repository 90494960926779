import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import {
    tryToCreateSection,
    tryToEditSection,
    tryToFetchSingleSection,
} from '../../../../store/brokers/admin/sections/sectionsSlice';
import { ISection } from '../../../../interfaces/sections/ISection';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { SECTION_USER_TYPES } from '../../../../utils/constants/section-user-types';
import { SECTION_BUSINESS_TYPE } from '../../../../utils/constants/section-business-types';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IPageModal {
    openPageModal: boolean;
    handleClosePageModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
    pageId?: string;
}

type FormValues = {
    name: string;
    path: string;
    types: string;
    bussinessType: string;
    parent?: string;
};

const PageModal = ({
    openPageModal,
    pageId,
    handleClosePageModal,
}: IPageModal) => {
    const dispatch = useAppDispatch();
    const [createError, setCreateError] = useState<string | null>();
    const [selectedTypes, setSelectedTypes] = useState<any>([])
    const [selectedBusinessType, setSelectedBusinessType] = useState<any>([])
    const [fileSelected, setFileSelected] = useState<any>();
    const [activeIcon, setActiveIcon] = useState<any>();
    const [selectedExtras, setSelectedExtras] = useState<boolean>(false)
    const [orderNumber, setOrderNumber] = useState<any>()
    const [pageDetails, setPageDetails] = useState<ISection>();
    const pageDetailsData = useAppSelector((state) => state.sections?.sectionDetails);

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    const { getRootProps: getRootPropsActive, getInputProps: getInputPropsActive } = useDropzone({
        onDrop: (acceptedFile) => {
            setActiveIcon(acceptedFile[0]);
        },
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        setFileSelected(null)
        setSelectedTypes([])
        setSelectedBusinessType([])
    }, [openPageModal, pageId])

    useEffect(() => {
        if (pageDetails && pageId) {
            setValue('name', pageDetails ? pageDetails?.name : '');
            setValue('path', pageDetails ? pageDetails?.path : '');
            setValue('parent', pageDetails ? pageDetails?.parent : '');
            setFileSelected(pageId && pageDetails ? pageDetails?.file : '');
            setSelectedTypes(pageId && pageDetails?.types && pageDetails?.types?.map((type: any) => {
                return SECTION_USER_TYPES.find(userType => userType?.value === type)
            }))
            setSelectedBusinessType(pageId && pageDetails?.businessType && pageDetails?.businessType?.map((type: any) => {
                return SECTION_BUSINESS_TYPE.find(businessType => businessType.value === type)
            }))
            if (pageDetails?.orderNumber) {
                setOrderNumber(pageDetails?.orderNumber || undefined)
            } else {
                setOrderNumber(undefined)
            }
            if (pageDetails?.extras) {
                setSelectedExtras(pageDetails?.extras || false)
            }
        } else {
            reset();
            setFileSelected(undefined)
            setOrderNumber(undefined)
            setSelectedBusinessType(undefined)
            setSelectedExtras(false)
            setActiveIcon(undefined)
            setSelectedTypes(undefined)
        }
    }, [pageDetails, pageId]);

    useEffect(()=>{
        if(pageDetailsData && pageId){
            setPageDetails(pageDetailsData)
        }else{
            setPageDetails(undefined)
        }
    },[pageDetailsData, pageId, openPageModal])

    useEffect(() => {
        if (pageId) {
            dispatch(tryToFetchSingleSection(pageId)).unwrap();
        }
    }, [pageId]);

    const onAddUserType = (options: any) => {
        setSelectedTypes(options)
        if (options && options?.length > 0 && orderNumber) {
            const keysInArray = options?.map((obj: any) => obj?.value);
            const updatedObject = Object.keys(orderNumber).reduce((acc: any, key: any) => {
                if (keysInArray?.includes(key)) {
                    acc[key] = orderNumber?.[key];
                }
                return acc;
            }, {});
            setOrderNumber(updatedObject);
        } else {
            setOrderNumber(undefined)
        }
    }

    const onAddBusinessType = (options: any) => {
        setSelectedBusinessType(options)
    }

    const onSubmit = async (data: FormValues) => {
        if (selectedTypes.length < 1) {
            setCreateError('At least a type is required!')
            return;
        }
        const formData = new FormData()
        formData.append('name', data.name)
        formData.append('path', data.path)
        selectedTypes?.map((type: any) => {
            return formData.append('types[]', type.value)
        })
        // formData.append('businessType', JSON.stringify(selectedBusinessType.map((type:any)=> type.value)))
        selectedBusinessType?.map((type: any) => {
            return formData.append('businessType[]', type.value)
        })
        if (data.parent) {
            formData.append('parent', data.parent)
        }
        if (fileSelected) {
            formData.append('file', fileSelected)
        }
        if (activeIcon) {
            formData.append('iconActive', activeIcon)
        }
        if (selectedExtras) {
            formData.append('extras', `${selectedExtras}`)
        }
        if (orderNumber) {
            formData.append('orderNumber', JSON.stringify(orderNumber))
        }
        try {
            if (pageId) {
                const payload: any = {
                    data: formData,
                };
                payload._id = pageId
                await dispatch(tryToEditSection(payload)).unwrap();
                setCreateError(null);

            } else {
                await dispatch(tryToCreateSection(formData)).unwrap();
                setCreateError(null);
            }
            reset();
            handleClosePageModal();
            setOrderNumber(undefined)
            setSelectedExtras(false)
        } catch (error) {
            setCreateError(`${error}`)
        }
    };

    const onCloseModal = () => {
        reset();
        setSelectedExtras(false)
        setOrderNumber(undefined)
        setSelectedBusinessType(undefined)
        setFileSelected(undefined)
        setActiveIcon(undefined)
        setSelectedTypes(undefined)
        setCreateError(undefined)
        handleClosePageModal();
    };

    const onClickExtras = () => {
        setSelectedExtras(!selectedExtras)
    }

    const onChangeOrderNumber = (value: string | null | undefined, type: string) => {
        if (type) {
            setOrderNumber({
                ...orderNumber || {},
                [type]: value ? (/^\d*\.?\d*$/).test(value) ? value : orderNumber?.[type] : undefined
            })
        }
    }

    return (
        <Modal open={openPageModal} onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4 font-bold'>
                    {pageId ? 'Edit Page' : 'Add Page'}
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {createError && <Error text={createError} />}
                    <div className='flex flex-col my-4'>
                        <div className='flex flex-row gap-x-3 justify-items-stretch'>
                            <Input
                                placeholder='Name'
                                dataQa={'name'}
                                label={'Name'}
                                register={register('name', {
                                    required: {
                                        message: 'Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) =>
                                        !!value.trim() || 'Name is required',
                                })}
                                error={errors.name?.message}
                            />
                            <Input
                                placeholder='/path'
                                dataQa={'path'}
                                label={'Path'}
                                register={register('path', {
                                    required: {
                                        message: 'Path is required',
                                        value: true,
                                    },
                                    validate: (value: string) =>
                                        !!value.trim() || 'Path is required',
                                })}
                                error={errors.path?.message}
                            />
                        </div>
                        <div>
                            <SelectCheckbox
                                name='Types'
                                placeholder='Types'
                                dataQa={'types'}
                                multiple={true}
                                options={SECTION_USER_TYPES}
                                error={errors?.types?.message}
                                onChangeSelectedOption={onAddUserType}
                                selectedOption={selectedTypes}
                            />
                            {selectedTypes && selectedTypes?.length > 0 &&
                                selectedTypes?.map((item: any) => {
                                    return (
                                        <Input
                                            key={item?.label}
                                            dataQa={'Order'}
                                            label={`${item?.label} Order`}
                                            placeholder={`${item?.label} Order`}
                                            showValue={true}
                                            inputUniqueName={item?.value}
                                            onChangeInput={onChangeOrderNumber}
                                            inputValue={orderNumber?.[item?.value]}
                                        />
                                    )
                                })
                            }
                            <SelectCheckbox
                                name='Bussiness Type'
                                dataQa={'bussiness-type'}
                                placeholder='Bussiness Type'
                                multiple={true}
                                options={SECTION_BUSINESS_TYPE}
                                error={errors?.bussinessType?.message}
                                onChangeSelectedOption={onAddBusinessType}
                                selectedOption={selectedBusinessType}
                            />
                            <Input
                                placeholder='ex. Orders'
                                dataQa={'parent'}
                                label={'Parent Page'}
                                register={register('parent')}
                                error={errors.parent?.message}
                            />
                        </div>
                        <div className='flex flex-row justify-start items-center mb-6 min-w-[100%]'>
                            <div className='flex items-center mr-2'>
                                <input
                                    onChange={() => onClickExtras()}
                                    checked={selectedExtras}
                                    id='checkbox-all'
                                    type='checkbox'
                                    data-qa={'checkbox'}
                                    className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                />
                                <label htmlFor='checkbox-all' className='sr-only'>
                                </label>
                            </div>
                            <p className='text-[#a4a4a4]'>
                                Page is in extra navigation
                            </p>
                        </div>
                        <p className='mb-3 mt-4 font-medium'>
                            Import Section Icon:
                        </p>
                        <div
                            className='flex flex-row items-center'
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <Button
                                dataQa={'select-image'}
                                icon={
                                    <img
                                        alt='upload-file-icon'
                                        src='/assets/shared/upload-file.svg'
                                        className='w-[30px] object-contains pr-2'
                                    />
                                }
                                className={
                                    'btn-main !bg-[#202020] !text-white !py-1 !shadow-none flex flex-row items-center'
                                }
                                label={'Select Image'}
                            />
                            {fileSelected && fileSelected?.name
                                ? <p className={'ml-3'}>{fileSelected?.name}</p>
                                : pageDetails?.icon ? <img alt='Section Icon' src={pageDetails?.icon} className={'w-[40px] mx-3'} /> : <></>
                            }
                        </div>
                        <div
                            className='flex flex-row items-center mt-5'
                            {...getRootPropsActive()}
                        >
                            <input {...getInputPropsActive()} />
                            <Button
                                dataQa={'select-active-image'}
                                icon={
                                    <img
                                        alt='upload-file-icon'
                                        src='/assets/shared/upload-file.svg'
                                        className='w-[30px] object-contains pr-2'
                                    />
                                }
                                className={
                                    'btn-main !bg-[#202020] !text-white !py-1 !shadow-none flex flex-row items-center'
                                }
                                label={'Select Active Image'}
                            />
                            {(activeIcon && activeIcon?.name)
                                ? <p className={'ml-3'}>{activeIcon?.name}</p>
                                : pageDetails?.iconActive ? <img alt='Section Active Icon' src={pageDetails?.iconActive} className={'w-[40px] mx-3'} /> : <></>
                            }
                        </div>
                        <div className='flex flex-row justify-end mt-4'>
                            <Button
                                label='Save'
                                dataQa={'submit'}
                                type={'submit'}
                                className={'btn-primary'}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default PageModal;
