import React, { useEffect, useState } from 'react'
import { IItem } from '../../../../interfaces/items/IItem'
import { IPage } from '../../../../interfaces/models/models/paginated-response.model'
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload'
import Table from '../../../../shared/table'
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate'
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets'
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi'
import { IMarket } from '../../../../interfaces/markets/IMarket'
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';

const tableColumns = ['Item ID', 'Item Code', 'Category', 'Name', 'Sales Grade', 'Price', 'Order ID', 'Item RMA Status', 'RMA Tracking Number']

interface IItemsTable {
    rows: IItem[];
    paginationData?: IPage;
    rowsLoading: boolean;
    selectedRows: IItem[] | undefined;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSelectRow: (id?: string) => void;
    sectors: IAssetTemplate[]

}

const ItemsTable = ({ rows, paginationData, rowsLoading, setPaginationState, onClickSelectRow, selectedRows, sectors }: IItemsTable) => {
    const getItemIcon = (item: any) => {
        const res = sectors.find(category => category.name === item.category)
        return res?.disabledIcon
    }
    const getStatusColor = (status: string | undefined) => {
        if (status) {
            if (['rejected_return', 'warehouse_rejected'].includes(status)) return '#E50000'
            if (['review_return', 'revalued_return'].includes(status)) return '#1389E6'
            if (['accepted_return', 'broker_accepted_revaluation', 'approved_return'].includes(status)) return '#00B67A'
            if (status === 'require_return') return '#F7A21E'
        }
        return '#8A8A8E'
    }

    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [getAllMarkets] = useGetAllMarketsMutation()
    const getMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, value: market?._id, displayName: market?.name }))
        setMarkets(formatMarkets || [])
    }
    useEffect(() => {
        getMarketData()
    }, [])

    return (
        <Table
            selectRows={rows.filter((item: any) => item.rmaStatus).length !== rows.length}
            areAllRowsSelected={(selectedRows && rows && rows.filter((item: any) => !item.rmaStatus).every(r => selectedRows.find(sr => sr._id === r._id)))}
            columns={tableColumns}
            containerStyle={'mt-0'}
            paginationData={paginationData}
            onClickSelectAll={() => onClickSelectRow()}
            setPaginationState={setPaginationState}
            selectStyles='!accent-[#202020] !focus:accent-[#202020]'>
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IItem, idx: number) => {
                        const findIfRowIsSelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find((item) => item._id === row?._id)
                        const findMarket = markets && markets?.length > 0 && markets?.find((market: any) => market?.name === row?.exchangeDefinition?.warehouse?.address?.country)
                        const shouldShowSelect = !row.rmaStatus
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {rows.filter((item: any) => item.rmaStatus).length !== rows.length && <td className='px-6 py-4'>
                                    {shouldShowSelect && <div className='flex items-center'>
                                        <input
                                            onChange={() => onClickSelectRow(row?._id)}
                                            checked={!!findIfRowIsSelected}
                                            data-qa={'checkbox-type'}
                                            id='checkbox-all'
                                            type='checkbox'
                                            className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-[#202020] rounded focus:accent-[#202020] focus:ring-2' />
                                        <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                                    </div>}
                                </td>}
                                <td className='px-6 py-4'>{row?._id}</td>
                                <td className='px-6 py-4'>{row?.itemCode || 'No IMEI'}</td>
                                <td className='px-6 py-6 flex gap-1 items-center'>
                                    <img className='w-6 h-6' src={getItemIcon(row)} alt={row.category} title={row.category} />
                                </td>
                                <td className='px-6 py-4'>{row?.itemName}</td>
                                <td className='px-6 py-4'>{row?.inventory?.sales_grade || row?.inventory?.grade ? `Grade ${row.inventory.sales_grade || row?.inventory?.grade}` : 'Not graded'}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center '>
                                        {findMarket && CURRENCY_SYMBOLS?.[`${(findMarket?.currency?.name || '')}`]}
                                        {Number(row?.salePrice || 0).toFixed(2)}
                                    </div>
                                </td>
                                <td className='px-6 py-4'>{row?.orderId}</td>
                                <td className={`px-6 py-4 text-[${getStatusColor(row?.rmaStatus)}]`}>{row?.rmaStatus ? row.rmaStatus.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : '- -'}</td>
                                <td className='px-6 py-4'>{row?.rmaTrackingNumber ? <a
                                    data-qa={'tracking-nr'}
                                    rel='noreferrer'
                                    href={`https://parcelsapp.com/en/tracking/${row?.rmaTrackingNumber}`}
                                    target='_blank'
                                    className='text-primary-default'
                                >
                                    {row?.rmaTrackingNumber}
                                </a>: <span>- -</span>}</td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default ItemsTable