import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../app/hooks';
import { VAT_TYPE_ITEMS } from '../../../../utils/constants/vat-type';
import { IToast } from '../../../../interfaces/components/IToast';
import { IPartnerWarehouse } from '../../../../interfaces/partners/IPartnerWarehouse';
import {
    tryToAddPartnerWarehouse,
    tryToEditPartnerWarehouse
} from '../../../../store/brokers/admin/partners/partnersSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';


interface IAddWarehouseAddressModal {
    openModal: boolean;
    partnerId: string;
    marketItems: Array<any>;
    warehouse: IPartnerWarehouse | null;
    handleCloseModal: () => void;
    setShowToast: React.Dispatch<React.SetStateAction<IToast | null | undefined>>;
}

interface FormValues {
    name: string;
    street: string;
    addressNo: string;
    city: string;
    postalCode: string;
    state?: string;
    country: string;
    warehouseCountry: string;
    phone: string;
    email: string;
    markets: string[];
    vatTypes: string[]
};

const AddWarehouseAddressModal = ({
    openModal,
    partnerId,
    marketItems,
    warehouse,
    handleCloseModal,
    setShowToast,
}: IAddWarehouseAddressModal) => {
    const dispatch = useAppDispatch();
    const [selectedMarkets, setSelectedMarkets] = useState<any[]>()
    const [selectedVat, setSelectedVat] = useState<any[]>()
    const [submitLoading, setSubmitLoading] = useState(false)
    
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        setValue
    } = useForm<FormValues>({ defaultValues: { vatTypes: [] } });

    const onSubmit = async (data: FormValues) => {
        setSubmitLoading(true)
        const formattedData: any = {
            name: data.name,
            markets: data.markets,
            partnerId: partnerId,
            address: {
                phone: data.phone,
                warehouseCountry: data.warehouseCountry,
                email: data.email,
                street: data.street,
                city: data.city,
                postalCode: data.postalCode,
                addressNo: data.addressNo,
                country: data.country,
                state: data.state,
                default: false
            },
            vatTypes: data.vatTypes || []
        }
        try {
            if (warehouse) {
                await dispatch(tryToEditPartnerWarehouse({ _id: warehouse._id, data: formattedData })).unwrap();
                setShowToast({ type: 'success', message: 'Warehouse Address successfully edited' });
            } else {
                await dispatch(tryToAddPartnerWarehouse(formattedData)).unwrap()
                setShowToast({ type: 'success', message: 'Warehouse Address successfully added' });
                reset()
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        } finally {
            setSubmitLoading(false)
            handleCloseModal()
        }
    };

    const onChangeSelectedMarket = (e: any) => {
        let findIfAnyFixedIsMissing = false
        selectedMarkets?.filter((market: any) => market?.isFixed)?.map((market: any) => {
            const findNewSelected = e?.length > 0 && e?.find((newSelected: any) => newSelected?.value === market?.value)
            if (!findNewSelected) findIfAnyFixedIsMissing = true
            return market;
        })
        if (findIfAnyFixedIsMissing) {
            return;
        }
        setSelectedMarkets(e?.length > 0 ? e?.map((s: any) => ({ ...s })) : [])
        setValue('markets', e?.length > 0 ? e?.map((s: any) => s?.value) : [])
    }

    const onChangeVatType = (e: any | undefined) => {
        setSelectedVat(e)
        setValue('vatTypes', e?.length > 0 ? e?.map((s: any) => s?.value) : [])
    }

    useEffect(() => {
        if (warehouse) {
            setValue('name', warehouse.name)
            setValue('street', warehouse.address.street)
            setValue('addressNo', warehouse.address.addressNo)
            setValue('city', warehouse.address.city)
            setValue('postalCode', warehouse.address.postalCode)
            warehouse.address.state && setValue('state', warehouse.address.state)
            setValue('country', warehouse.address.country)
            setValue('warehouseCountry', warehouse.address.warehouseCountry)
            setValue('phone', warehouse.address.phone)
            setValue('email', warehouse.address.email)
            setValue('markets', warehouse.markets)
            setSelectedMarkets(marketItems.filter(market => warehouse.markets.includes(market._id)))
            setValue('vatTypes', warehouse.vatTypes)
            setSelectedVat(warehouse.vatTypes?.map(vatType => VAT_TYPE_ITEMS.find(vatTypeIem => vatType === vatTypeIem.value) || []))
        } else {
            reset()
            setSelectedMarkets([])
            setSelectedVat([])
        }
        register('markets', {
            required: {
                message: 'Market is required',
                value: true,
            },
            validate: (value) => value.length > 0 || 'Market is required'
        })
    }, [warehouse])

    return (
        <Modal open={openModal} onClose={handleCloseModal} showInRight contentContainerStyle='!min-w-[650px]'>
            <p className='mt-2 mb-4 font-semibold font-md'>{warehouse ? 'Edit' : 'Add'} Warehouse Address</p>
            <form onSubmit={handleSubmit(onSubmit)} className='grid grid-cols-2 gap-4'>
                <Input
                    placeholder='Name'
                    dataQa={'add-warehouse-address-name'}
                    label='Name'
                    register={register('name', {
                        required: {
                            message: 'Name is required',
                            value: true,
                        },
                        minLength: {
                            value: 5,
                            message: 'Minimum number of characters is 5'
                        },
                        validate: (value: string) => !!value.trim() || 'Name is required'
                    })}
                    error={errors.name?.message}
                />
                <Input
                    placeholder='Street'
                    dataQa={'add-warehouse-address-street'}
                    label='Street'
                    register={register('street', {
                        required: {
                            message: 'Street is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'Street is required'
                    })}
                    error={errors.street?.message}
                />
                <Input
                    placeholder='Address No'
                    dataQa={'add-warehouse-address-no'}
                    label='Address No'
                    register={register('addressNo', {
                        required: {
                            message: 'Address No is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'Address No is required'
                    })}
                    error={errors.addressNo?.message}
                />
                <Input
                    placeholder='City'
                    dataQa={'add-warehouse-address-city'}
                    label='City'
                    register={register('city', {
                        required: {
                            message: 'City is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'City is required'
                    })}
                    error={errors.city?.message}
                />
                <Input
                    placeholder='Postal Code'
                    dataQa={'add-warehouse-address-postal-code'}
                    label='Postal Code'
                    register={register('postalCode', {
                        required: {
                            message: 'Postal Code is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'Postal Code is required'
                    })}
                    error={errors.postalCode?.message}
                />
                <Input
                    placeholder='State (optional)'
                    dataQa={'add-warehouse-address-state'}
                    label='State'
                    register={register('state')}
                />
                <Input
                    placeholder='Country'
                    dataQa={'add-warehouse-address-country'}
                    label='Country'
                    register={register('country', {
                        required: {
                            message: 'Country is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'Country is required'
                    })}
                    error={errors.country?.message}
                />
                <Input
                    placeholder='Warehouse Country'
                    dataQa={'add-warehouse-address-warehouse-country'}
                    label='Warehouse Country'
                    register={register('warehouseCountry', {
                        required: {
                            message: 'Warehouse Country is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'Warehouse Country is required'
                    })}
                    error={errors.warehouseCountry?.message}
                />
                <Input
                    placeholder='Phone'
                    dataQa={'add-warehouse-address-phone'}
                    label='Phone'
                    register={register('phone', {
                        required: {
                            message: 'Phone is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'Phone is required'
                    })}
                    error={errors.phone?.message}
                />
                <Input
                    placeholder='Email'
                    dataQa={'add-warehouse-address-email'}
                    label='Email'
                    register={register('email', {
                        required: {
                            message: 'Email is required',
                            value: true,
                        },
                        pattern: {
                            /* eslint-disable-next-line */
                            value: /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Please write a valid email'
                        },
                        validate: (value: string) => !!value.trim() || 'Email is required'
                    })}
                    error={errors.email?.message}
                />

                <SelectCheckbox
                    name='Markets'
                    placeholder='Markets'
                    onChangeSelectedOption={onChangeSelectedMarket}
                    dataQa={'add-warehouse-address-market'}
                    options={marketItems}
                    selectedOption={selectedMarkets}
                    multiple={true}
                    error={errors.markets?.message}
                />
                <SelectCheckbox
                    name='VAT Type'
                    dataQa={'select-vat'}
                    containerStyle='mb-1 min-w-full'
                    selectedOption={selectedVat}
                    multiple={true}
                    onChangeSelectedOption={(e) => onChangeVatType(e)}
                    options={VAT_TYPE_ITEMS || []}
                />
                <div className='flex flex-row justify-end mt-4'>
                    <Button
                        label='Save Address'
                        type={'submit'}
                        className={submitLoading ? 'btn-primary-disable' : 'btn-primary'}
                        dataQa={'add-partner-warehouse-save-btn'}
                    />
                </div>
            </form>
        </Modal>
    )
}
export default AddWarehouseAddressModal;