import React from 'react';


const OrderActionsContainer = () => {
    return (
        <div className='my-5 flex flex-row items-center rounded-md bg-white py-3 px-5 my-5 min-w-full'>
            <div className='mr-8 py-1 px-3 cursor-pointer rounded-md animate-pulse'>
                <div className='h-8 bg-gray-200 dark:bg-gray-200 w-[80px]'></div>
            </div>
            {[...Array(4)].map((x, i) => {
                return (
                    <>
                        <div className='flex flex-col mr-5 cursor-pointer max-w-sm rounded-full px-4 py-2 animate-pulse'>
                            <div className='h-2.5 bg-gray-200 dark:bg-gray-200 w-[120px] rounded'></div>
                            <div className='h-1.5 bg-gray-200 dark:bg-gray-200 w-[120px] mt-3 mb-1 rounded'></div>
                        </div>
                    </>
                )
            })
            }
        </div>
    )
}

export default OrderActionsContainer;