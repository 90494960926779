import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CategoryMappingsService } from './categoryMappingsApi';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { AxiosError } from 'axios';
import { ICategoryMappingsListState } from '../../../../interfaces/category-mappings/ICategoryMappingsList';
import { PaginatedCategoryMappings } from '../../../../interfaces/category-mappings/paginatedStore.type';
import { ICategoryMappings } from '../../../../interfaces/category-mappings/ICategoryMappings';

const initialState: ICategoryMappingsListState = {
    mappingIsLoading: false,
    mappingsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchCategoryMappings = createAsyncThunk<
    PaginatedCategoryMappings,
    IPaginationPayload
>('categoryMappings/tryToFetchCategoryMappings', async ({ pageNumber, pageSize, filters }) => {
    const result = await CategoryMappingsService.tryToFetchCategoryMappings(
        pageNumber,
        pageSize,
        filters,
    );
    return result?.data;
});

export const tryToFetchCategoryMappingsWithoutPagination = createAsyncThunk<any, any>(
    'categoryMappings/tryToFetchCategoryMappingsWithoutPagination',
    async (data?: any) => {
        const result = await CategoryMappingsService.tryToFetchCategoryMappingsWithoutPagination(data);
        return result?.data?.data;
    });

export const tryToFetchSingleCategoryMapping = createAsyncThunk<ICategoryMappings, string>(
    'categoryMappings/tryToFetchSingleCategoryMapping',
    async (id: string) => {
        const result = await CategoryMappingsService.tryToFetchSingleCategoryMapping(id);
        return result?.data?.data;
    },
);

export const tryToCreateOrUpdateCategoryMapping = createAsyncThunk<
    ApiResponse<ICategoryMappings>,
    any
>('categoryMappings/tryToCreateOrUpdateCategoryMapping',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await CategoryMappingsService.tryToCreateOrUpdateCategoryMapping(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToEnableDisableCategoryMapping = createAsyncThunk<
    ApiResponse<ICategoryMappings>,
    { id: string, active: boolean }
>('categoryMappings/tryToEnableDisableCategoryMapping', async ({ id, active }, { rejectWithValue }) => {
    try {
        const results = await CategoryMappingsService.tryToEnableDisableCategoryMapping(id, active);
        return results.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const categoryMappingsSlice = createSlice({
    name: 'categoryMappings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchCategoryMappingsWithoutPagination
            .addCase(tryToFetchCategoryMappingsWithoutPagination.pending, (state) => {
                state.mappingsAreLoading = true;
            })
            .addCase(tryToFetchCategoryMappingsWithoutPagination.rejected, (state) => {
                state.mappingsAreLoading = false;
            })
            .addCase(tryToFetchCategoryMappingsWithoutPagination.fulfilled, (state, action) => {
                state.mappingsAreLoading = false;
                state.categoryMappings = action.payload;
            })

            // tryToFetchSingleCategoryMapping
            .addCase(tryToFetchSingleCategoryMapping.pending, (state) => {
                state.mappingIsLoading = true;
            })
            .addCase(tryToFetchSingleCategoryMapping.rejected, (state) => {
                state.mappingIsLoading = false;
            })
            .addCase(tryToFetchSingleCategoryMapping.fulfilled, (state, action) => {
                state.mappingIsLoading = false;
                state.categoryMappingsDetails = action.payload;
            })

            // tryToCreateOrUpdateCategoryMapping
            .addCase(tryToCreateOrUpdateCategoryMapping.pending, (state) => {
                state.mappingIsLoading = true;
            })
            .addCase(tryToCreateOrUpdateCategoryMapping.rejected, (state) => {
                state.mappingIsLoading = false;
            })
            .addCase(tryToCreateOrUpdateCategoryMapping.fulfilled, (state, action) => {
                state.mappingIsLoading = false;
                state.requestStatus = 'success';
            })

            // tryToEnableDisableCategoryMapping
            .addCase(tryToEnableDisableCategoryMapping.pending, (state) => {
                state.mappingsAreLoading = false;
            })
            .addCase(tryToEnableDisableCategoryMapping.rejected, (state) => {
                state.mappingsAreLoading = false;
            })
            .addCase(tryToEnableDisableCategoryMapping.fulfilled, (state, action) => {
                state.mappingsAreLoading = false;
                state.requestStatus = 'success';
                if (state.categoryMappings)
                    state.categoryMappings =
                        state.categoryMappings?.map(
                            (item) => {
                                if ((item?._id === action.meta.arg?.id) || (item?.id === action.meta.arg?.id)) {
                                    return {
                                        ...item,
                                        enabled: action.meta.arg?.active
                                    }
                                } else {
                                    return item
                                }
                            }

                        );
            });
    },
});

export default categoryMappingsSlice.reducer;
