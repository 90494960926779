import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAgents } from '../../../../interfaces/agents/IAgents';
import Switch from '../../../../shared/switch';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';

const tableColumns = ['Email', 'Username', 'Name', 'Role', 'Enabled', 'Manage Role', 'Inventory Status', 'Inventory Role', 'Manage Inventory Role', 'Actions']

interface IAgentsTable {
    rows: IAgents[];
    paginationData?: IPage;
    accessSeeDetails?: any;
    accessEnableDisable?: any;
    accessDelete?: any;
    accessManageRole?: any;
    rowsLoading: boolean;
    accessManageInventoryRole?: any;
    accessInventoryStatus?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeAgentDetails: (id: string) => void;
    onEnableDisableAgent: (value: string, currentStatus: boolean) => void;
    onEnableDisableFeatures: (agentId: string, dashboard: boolean, value: string) => void;
    onDeleteAgent: (id: string) => void;
    onOpenAgentRole: (userId: string, type: string, role?: any) => void;
}

const AgentsTable = ({
    rows,
    paginationData,
    accessSeeDetails,
    accessEnableDisable,
    accessDelete,
    accessManageRole,
    rowsLoading,
    accessManageInventoryRole,
    accessInventoryStatus,
    setPaginationState,
    onEnableDisableAgent,
    onSeeAgentDetails,
    onEnableDisableFeatures,
    onDeleteAgent,
    onOpenAgentRole
}: IAgentsTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IAgents, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.email}</td>
                                <td className='px-6 py-4'>{row?.username}</td>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>{row?.role || ''}</td>
                                <td className={`px-6 py-4 ${!accessEnableDisable && 'cursor-not-allowed'}`}>
                                    <Switch
                                        checked={!!row?.enabled}
                                        dataQa={'enabled-button'}
                                        onToggleSwitch={() => row?._id && accessEnableDisable && onEnableDisableAgent(row?._id, row?.enabled || false)}
                                    />
                                </td>
                                <td className='px-6'>
                                    <Button
                                        label={'Manage Role'}
                                        dataQa={'manage-role'}
                                        onClickButton={() => accessManageRole && onOpenAgentRole(row?._id || '', 'agent', row?.role)}
                                        className={`${accessManageRole ? 'btn-main' : 'btn-main-disable'} !shadow-none`}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <Switch
                                        checked={!!row?.inventory}
                                        onToggleSwitch={() => row?._id && accessInventoryStatus && onEnableDisableFeatures(row?._id, row?.inventory || false, 'inventory')}
                                        dataQa={'enabled-inventory'}
                                    />
                                </td>
                                <td className='px-6 py-4'>{row?.inventoryRole || ''}</td>
                                <td className='px-6 py-4'>
                                    <Button
                                        label={'Manage Inventory Role'}
                                        className={row?.inventory && (accessManageInventoryRole) ? 'btn-main' : 'btn-main-disable'}
                                        onClickButton={() => ((accessManageInventoryRole) && row?._id && row?.inventory) && onOpenAgentRole(row?._id || '', 'inventory-agent', row?.inventoryRole)}
                                        dataQa={'manage-inventory-access'}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row justify-around'>
                                        {row?.enabled && <div data-qa={'view-details'} onClick={() => accessSeeDetails && row?._id && row?.enabled && onSeeAgentDetails(row?._id)}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={'h-[14px] object-contain cursor-pointer'} />
                                        </div>
                                        }
                                        <div data-qa={'delete-agent'} onClick={() => row?._id && accessDelete && onDeleteAgent(row?._id)}>
                                            <img title={'Delete Member'} alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={'h-[14px] object-contain cursor-pointer'} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default AgentsTable;