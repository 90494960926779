export const ORDER_DETAILS_ACTIONS = [
    {
        name: 'Accept',
        label: 'Accept',
        icon: '/assets/order-actions/accept-new.svg',
    },
    {
        name: 'Review',
        label: 'Revalue',
        icon: '/assets/order-actions/revalue-new.svg',
    },
    {
        name: 'Fake Item',
        label: 'Reject',
        icon: '/assets/order-actions/reject-new.svg',
    },
    {
        name: 'Contact CS',
        label: 'Contact CS',
        icon: '/assets/order-actions/contact-cs-new.svg',
    },
    {
        name: 'Missing Item',
        label: 'Damaged Package',
        icon: '/assets/order-actions/damaged-package-new.svg',
    },
    // { name: 'Revalue', icon: '/assets/order-actions/revalue.svg' },
    {
        name: 'Inventory',
        label: 'Inventory',
        icon: '/assets/order-actions/inventory.svg',
    },
    {
        name: 'Delivered',
        label: 'Received',
        icon: '/assets/order-actions/delivered.svg',
    },
];

export const ORDERS_DROPDOWN_TYPES = [
    {
        // name: 'authenticity',
        label: 'Cannot confirm authenticity',
        value: 'authenticity' ,
    },
    { 
        // name: 'FAILED', 
        label: 'Item is not supported ', 
        value: 'not-supported' 
    },
    { 
        // name: 'INFO', 
        label: 'Item is damaged and beyond repair', 
        value: 'damaged' 
        
    },
    { 
        // name: 'INFO', 
        label: 'Other', 
        value: 'other' 
        
    },
]
