import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import { IInvoices } from '../../../../interfaces/invoices/IInvoices';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToFetchFilteredInvoices } from '../../../../store/brokers/partners/invoices/invoicesSlice';
import { useUserProfileMutation } from '../../../../store/user/userDomApi';
// import { INVOICE_STATES } from '../../../../utils/constants/invoices-states';
import DownloadInvoicesModal from '../modal/DownloadInvoicesModal';
// import InvoicesFilters from '../details/InvoicesFilters';
// import ListTable from '../tables/ListTable';
import SearchWithButton from '../../../../shared/search-with-button';
import Toast from '../../../../shared/toast';
import ProformasTab from '../tabs/ProformasTab';
import InvoicesTab from '../tabs/InvoicesTab';
import Button from '../../../../shared/button';

const RMAsTabs = [
    { name: 'Pro Forma ', element: ProformasTab },
    { name: 'Invoices', element: InvoicesTab },
]

const List = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IInvoices[]>([]);
    const [search, setSearch] = useState<string>()
    const [showToast, setShowToast] = useState<IToast | null>()
    const [selectedCategory, setSelectedCategory] = useState<string>()
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>()
    const [activeTab, setActiveTab] = useState<any>(RMAsTabs[0])
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [selectedState, setSelectedState] = useState<string>()
    const [dateFiltersValues, setDateFiltersValues] = useState<any>();
    const [pageAccess, setPageAccess] = useState<any>()
    const [showDownloadCSV, setShowDownloadCSV] = useState<boolean>(false)
    const [userProfileMutation] = useUserProfileMutation()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state?.invoices?.invoices?.data?.page);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket)
    const state = useAppSelector((state) => state.invoices);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Invoices')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.invoices) {
            const rows =
                state.invoices?.data?.elements && Array.isArray(state.invoices?.data.elements)
                    ? state.invoices?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.invoices]);

    const getBrokerSectors = async () => {
        const response = await userProfileMutation(null).unwrap()
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : []
        const filterSectors: any = formatSectors && formatSectors?.length > 0 && formatSectors?.filter((s: any) => (s?.label || '').toLowerCase() !== 'mens wear' && (s?.label || '').toLowerCase() !== 'womens wear')
        setBrokerCategories(filterSectors || [])
        setSelectedCategory('allCategories')
    }

    useEffect(() => {
        getBrokerSectors()
    }, [])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (activeMarketState && (JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket))) {
            setActiveMarket(activeMarketState || undefined)
            onResetPaginationState()
        }
    }, [activeMarketState])

    const onGettingFilters = (type?: string) => {
        let settingsFilters: any
        if (!type || type !== 'download') {
            settingsFilters = {
                ...paginationState
            }
        }
        if (activeMarket && !type) {
            settingsFilters =
            {
                ...settingsFilters || {},
                data: {
                    market: activeMarket?.market?._id
                }
            }
        }
        if (selectedCategory && selectedCategory !== 'allCategories') {
            const findCategory = brokerCategories && brokerCategories?.length > 0 && brokerCategories?.find((category) => category?.name === selectedCategory)
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    categoryId: findCategory?.id,
                }
            }
        }
        if (selectedState) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    state: selectedState
                }
            }
        }
        if (search) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    search: search
                }
            }
        }
        if (dateFiltersValues) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    range: {
                        to: new Date(dateFiltersValues?.maxDateCreated),
                        from: new Date(dateFiltersValues?.minDateCreated)
                    }
                }
            }
        }
        return settingsFilters
    }

    const getInvoicesData = () => {
        const settingsFilters = onGettingFilters()
        dispatch(tryToFetchFilteredInvoices(settingsFilters));
    }

    useEffect(() => {
        if (paginationState && activeMarket) {
            getInvoicesData()
        }
    }, [paginationState, activeMarket]);

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || '')
        onResetPaginationState()
    }

    // const onChangeActiveState = (status: string) => {
    //     setSelectedState(selectedState === status ? undefined : status)
    //     onResetPaginationState()
    // }

    // const onChangeCategorySelected = (value: string) => {
    //     setSelectedCategory(value)
    //     onResetPaginationState()
    // }

    // const onChangeDateFilters = (value: string | null | undefined, type: string) => {
    //     setDateFiltersValues({
    //         ...dateFiltersValues,
    //         [type]: value || undefined
    //     })
    //     onResetPaginationState()
    // }

    const clearAllFilters = () => {
        setDateFiltersValues(undefined)
        setSelectedState(undefined)
        setSelectedCategory(undefined)
        setSearch(undefined)
        onResetPaginationState()
    }

    const onSeeInvoiceDetails = (invoiceNumber: string) => {
        navigate(`/invoices/${invoiceNumber}`)
    }

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined,
        });
        searchParams.set('page', '1');
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    };

    const onChangeActiveTab = (item: any) => {
        setActiveTab(item)
        setSearch('')
        searchParams.set('type', item?.name)
        setSearchParams(searchParams)
        searchParams.delete('search');
        onResetPaginationSettings()
        setPaginationState({ ...paginationState, filters: '' })
    }

    return (
        <div className='my-6'>
            <div>
                {/* <InvoicesFilters
                    invoicesStates={INVOICE_STATES}
                    brokerCategories={brokerCategories}
                    selectedState={selectedState}
                    activeCategory={selectedCategory}
                    dateFilters={dateFiltersValues}
                    onClickCategory={onChangeCategorySelected}
                    onChangeState={onChangeActiveState}
                    onChangeFilterValue={onChangeDateFilters}
                /> */}
            </div>
            <div className='flex flex-row flex-wrap gap-3  items-center my-5'>
                {RMAsTabs.filter((item: any) => (accessControl || pageAccess?.actions?.[item?.name]))?.map((tab: { name: string, element: any }, index) => {
                    return (
                        <Button
                            key={index}
                            dataQa={`${index}`}
                            className={`box-border ${activeTab.name === tab?.name ? 'btn-tab-rounded-less' : 'btn-tab-disable '}`}
                            onClickButton={() => onChangeActiveTab(tab)}
                            label={tab?.name} />
                    )
                })}
            </div>
            <SearchWithButton
                placeholder='Filter table'
                search={search}
                onChangeSearchValue={onChangeSearchValue}
                onClickButton={() => (!accessControl || pageAccess?.actions?.['Export CSV']) && setShowDownloadCSV(true)}
                scndButtonLabel={'Clear Filters'}
                showScndBtn={true}
                scndButtonStyle={'btn-primary-text-underline !min-w-auto sm:col-span-9 md:col-span-5 lg:col-span-2 flex flex-row sm:justify-center lg:justify-end bg-transparent mt-1'}
                onClickSecondButton={clearAllFilters}
                buttonStyle={`${(!accessControl || pageAccess?.actions?.['Export CSV']) ? 'btn-main sm:col-span-9 md:col-span-4 lg:col-span-1' : 'btn-main-disbale sm:col-span-8 md:col-span-4 lg:col-span-1'}`}
            />
            <div className='my-2'>
                {(accessControl || pageAccess?.actions?.[activeTab?.name]) ? React.createElement(activeTab.element, { rows, paginationData, search, setPaginationState, activeMarket, accessCSV: !accessControl || pageAccess?.actions?.['CSV Details'], accessPDF: !accessControl || pageAccess?.actions?.PDF, accessInvoiceDetails: !accessControl || pageAccess?.actions?.['See Details'], onSeeInvoiceDetails }) : <></>}
            </div>
            {/* <div className='relative'>
                <ListTable
                    rows={rows}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                    activeMarket={activeMarket}
                    accessCSV={!accessControl || pageAccess?.actions?.['CSV Details']}
                    accessPDF={!accessControl || pageAccess?.actions?.PDF}
                    accessInvoiceDetails={!accessControl || pageAccess?.actions?.['See Details']}
                    onSeeInvoiceDetails={onSeeInvoiceDetails}
                />
            </div> */}
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showDownloadCSV &&
                <DownloadInvoicesModal
                    openDownloadModal={showDownloadCSV}
                    filters={onGettingFilters('download')}
                    activeMarket={activeMarket}
                    handleCloseDownloadModal={() => setShowDownloadCSV(false)}
                />
            }
        </div>
    )
}

export default List;