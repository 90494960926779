import React from 'react'
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';

const tableColumns = ['Category', 'Name', 'Item Status', 'Reason', 'Item ID', 'Item Code', 'Initial Price', 'Final Price', 'Credit Amount', 'Actions']

interface IRmaDetailsTable {
    rows: any[];
    paginationData?: IPage;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    sectors: IAssetTemplate[];
    pageAccess: any;
    accessControl: any;
    markets: IAllMarkets[];
    RMADetails: any;
    onToggleActionModal: (type: string, item: any) => void;
}
const RmaDetailsTable = ({
    rows,
    paginationData,
    setPaginationState,
    sectors,
    markets,
    RMADetails,
    accessControl,
    rowsLoading,
    pageAccess,
    onToggleActionModal,
}: IRmaDetailsTable) => {
    const getCategoryIcon = (category: string) => {
        const res = sectors.find((sector) => sector.name === category);
        return res?.disabledIcon;
    };
    const getStatusColor = (status: string | undefined) => {
        if (status) {
            if (['rejected_return', 'warehouse_rejected'].includes(status))
                return '#E50000';
            if (['review_return', 'revalued_return'].includes(status))
                return '#1389E6';
            if (
                [
                    'accepted_return',
                    'broker_accepted_revaluation',
                    'approved_return',
                ].includes(status)
            )
                return '#00B67A';
            if (status === 'require_return') return '#F7A21E';
        }
        return '#8A8A8E';
    };
    return (
        <Table columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            headStyle='!bg-[#202020] !text-[#F8F9FC]'>
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </> : <>
                    {rows?.map((row: any, idx: number) => {
                        const findMarket =
                            markets &&
                            markets?.length > 0 &&
                            markets?.find(
                                (market: any) =>
                                    market?.name ===
                                    row?.item?.exchangeDefinition?.warehouse
                                        ?.address?.country,
                            );
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className="px-6 py-6 flex gap-1 items-center">
                                    <img
                                        className="w-6 h-6"
                                        src={getCategoryIcon(
                                            row?.item?.category,
                                        )}
                                        alt={row.category}
                                        title={row.category}
                                    />
                                </td>
                                <td className='px-6 py-4'>{row.item.itemName}</td>
                                <td
                                    className={`px-6 py-4 text-[${getStatusColor(
                                        row?.rmaStatus,
                                    )}]`}>
                                    <span
                                        className="border px-3 py-1 rounded-[10px] whitespace-nowrap"
                                        style={{
                                            borderColor: getStatusColor(
                                                row.rmaStatus,
                                            ),
                                        }}>
                                        {row?.rmaStatus
                                            ? row.rmaStatus
                                                .split('_')
                                                .map(
                                                    (word: string) =>
                                                        word
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        word
                                                            .slice(1)
                                                            .toLowerCase(),
                                                )
                                                .join(' ')
                                            : '- -'}
                                    </span>
                                </td>
                                <td className='px-6 py-4'>{row?.reason}</td>
                                <td className='px-6 py-4'>{row?.item._id}</td>
                                <td className='px-6 py-4'>{row?.item.itemCode || '- -'}</td>
                                <td className='px-6 py-4'>
                                    {row?.price && findMarket &&
                                        CURRENCY_SYMBOLS?.[
                                        `${findMarket?.currency
                                            ?.name || ''
                                        }`
                                        ]}
                                    {row?.price || '- -'}
                                </td>
                                <td className='px-6 py-4'>
                                    {row?.itemCredit?.finalPrice && findMarket &&
                                        CURRENCY_SYMBOLS?.[
                                        `${findMarket?.currency
                                            ?.name || ''
                                        }`
                                        ]}
                                    {row?.itemCredit?.finalPrice || '- -'}
                                </td>
                                <td className='px-6 py-4'>{(row?.hasItemCredit || row?.itemCredit?.amount) && findMarket &&
                                    CURRENCY_SYMBOLS?.[
                                    `${findMarket?.currency
                                        ?.name || ''
                                    }`
                                    ]}
                                    {row?.itemCredit?.amount ? row?.itemCredit.amount : row.hasItemCredit ? '0' : '- -'}</td>
                                <td className='px-6 py-4 flex items-center gap-3'>
                                    <Button
                                        label={'Accept Return'}
                                        onClickButton={row.rmaStatus === 'require_return' && (!accessControl || (pageAccess?.actions['Accept Return'])) ? () => onToggleActionModal('accept-return', row) : () => { }}
                                        className={`text-left underline ${row.rmaStatus === 'require_return' && (!accessControl || (pageAccess?.actions['Accept Return'])) ? 'text-[#00B67A]' : 'text-[#D9D9D9]'} !shadow-none`}
                                        dataQa={'accept-return'}
                                    />
                                    <Button
                                        label={'Revalue Item'}
                                        onClickButton={row.rmaStatus === 'require_return' && (!accessControl || (pageAccess?.actions['Revalue item'])) ? () => onToggleActionModal('revalue-item', row) : () => { }}
                                        className={`text-left underline ${row.rmaStatus === 'require_return' && (!accessControl || (pageAccess?.actions['Revalue item'])) ? 'text-[#1389E6]' : 'text-[#D9D9D9]'} !shadow-none`}
                                        dataQa={'revalue-item'}
                                    />
                                    <Button
                                        label={'Reject Return'}
                                        onClickButton={row.rmaStatus === 'require_return' && (!accessControl || (pageAccess?.actions['Reject Return'])) ? () => onToggleActionModal('reject-return', row) : () => { }}
                                        className={`text-left underline ${row.rmaStatus === 'require_return' && (!accessControl || (pageAccess?.actions['Reject Return'])) ? 'text-[#E50000]' : 'text-[#D9D9D9]'} !shadow-none`}
                                        dataQa={'reject-return'}
                                    />
                                </td>
                            </tr>
                        )
                    })}</>}
            <tr className="bg-white border-b hover:bg-gray-50">
                <td className="px-6 py-4"></td>
                <td className="px-6 py-4"></td>
                <td className="px-6 py-4"></td>
                <td className="px-6 py-4"></td>
                <td className="px-6 py-4"></td>

                <td colSpan={3} className='px-6 py-4 font-dm_sansbold text-right text-[#202020]'>Total Credit Amount</td>

                <td className="px-6 py-4">{typeof RMADetails?.totalCreditAmount === 'number' && markets && markets.length > 0 &&
                    CURRENCY_SYMBOLS?.[
                    `${markets?.find(
                        (market: any) =>
                            market?._id ===
                            RMADetails?.marketId,
                    )?.currency
                        ?.name || ''
                    }`
                    ]}
                    {typeof RMADetails?.totalCreditAmount === 'number' ? RMADetails?.totalCreditAmount : '- -'}</td>
                <td className="px-6 py-4"></td>
            </tr>
        </Table>
    )
}

export default RmaDetailsTable