import React, { useEffect, useState, useCallback } from 'react';
import Modal from '../../../../shared/modal';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';
import { useForm, Controller } from 'react-hook-form';
import { IItem } from '../../../../interfaces/items/IItem';
import { tryToFetchAllRMAReasons } from '../../../../store/brokers/partners/rma/partnerRMAsSlice';
import { useAppDispatch } from '../../../../app/hooks';

interface ICreateRMAModal {
    openModal: boolean;
    selectedRows: IItem[];
    createLoading: boolean;
    handleCloseModal: () => void;
    onSubmitSuccessfully: (data: any) => void;
}

const tableColumns = ['Item ID', 'Reason'];

const CreateRMAModal = ({ openModal, handleCloseModal, onSubmitSuccessfully, selectedRows, createLoading }: ICreateRMAModal) => {
    const { control, handleSubmit, formState: { isValid } } = useForm();
    const [reasons, setReasons] = useState<any[]>([]);
    const dispatch = useAppDispatch();

    const onSubmit = (data: { [key: string]: string }) => {
        onSubmitSuccessfully(data);
    };

    const getRMAReasons = useCallback(async () => {
        try {
            const res = await dispatch(tryToFetchAllRMAReasons()).unwrap();
            setReasons(res);
        } catch (error) {
            console.error('Failed to fetch RMA reasons:', error);
        }
    }, [dispatch]);

    useEffect(() => {
        getRMAReasons();
    }, [getRMAReasons]);

    return (
        <Modal
            showInRight
            open={openModal}
            onClose={handleCloseModal}
            dataQa={'close-button'}
            contentContainerStyle='min-w-[70vw]'>
            <form onSubmit={createLoading ? () => { } : handleSubmit(onSubmit)}>
                <div>
                    <p className='font-semibold text-sm text-center'>Return Merchandise Authorization Form</p>
                    <hr className='mb-4'/>
                    <p className='font-bold text-md mb-4'>Use this form to create a RMA for one or multiple items.</p>
                </div>
                <Table
                    hidePagination={true}
                    columns={tableColumns}
                    paginationData={{ size: selectedRows.length, totalElements: selectedRows.length, hasNextPage: false, number: 1, totalPages: 1 }} >
                    {selectedRows.map((item, idx: number) => (
                        <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                            <td className='px-6 py-4'>{item._id}</td>
                            <td className='px-6 py-4'>
                                <Controller
                                    name={`item${item._id}_reason`}
                                    control={control}
                                    defaultValue=''
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <select
                                            {...field}
                                            style={{
                                                color: field.value === '' ? '#1389E6' : '#6b7280',
                                                WebkitAppearance: 'none',
                                                MozAppearance: 'none',
                                                appearance: 'none',
                                                backgroundColor: 'transparent',
                                                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12.0008 14.9758C11.8674 14.9758 11.7424 14.9549 11.6258 14.9133C11.5091 14.8716 11.4008 14.8008 11.3008 14.7008L6.70078 10.1008C6.51745 9.91745 6.42578 9.68411 6.42578 9.40078C6.42578 9.11745 6.51745 8.88411 6.70078 8.70078C6.88411 8.51745 7.11745 8.42578 7.40078 8.42578C7.68411 8.42578 7.91745 8.51745 8.10078 8.70078L12.0008 12.6008L15.9008 8.70078C16.0841 8.51745 16.3174 8.42578 16.6008 8.42578C16.8841 8.42578 17.1174 8.51745 17.3008 8.70078C17.4841 8.88411 17.5758 9.11745 17.5758 9.40078C17.5758 9.68411 17.4841 9.91745 17.3008 10.1008L12.7008 14.7008C12.6008 14.8008 12.4924 14.8716 12.3758 14.9133C12.2591 14.9549 12.1341 14.9758 12.0008 14.9758Z" fill="%238A8A8E"/></svg>')`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'right 0 center',
                                                backgroundSize: '24px',
                                                paddingRight: '24px'
                                            }}
                                            aria-label={`Select reason for item ${item._id}`}
                                        >
                                            <option disabled value="">
                                                Select Reason
                                            </option>
                                            {reasons.map((reason, i) => (
                                                <option key={i} value={reason.title}>
                                                    {reason.title}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                />
                            </td>
                        </tr>
                    ))}
                </Table>
                <div className={'min-w-full py-2 flex flex-row justify-center items-center'}>
                    <Button
                        icon={(createLoading) && <Loading />}
                        label={'Submit RMA'}
                        dataQa={'submit-rma'}
                        disabled={createLoading || !isValid}
                        type='submit'
                        className={`${(createLoading || !isValid) ? 'btn-main-disable' : 'btn-main !bg-black !text-white'}  flex flex-row align-start my-2 !py-2 !px-10`}
                    />
                </div>
            </form>
        </Modal>
    );
}

export default React.memo(CreateRMAModal);
