import { AxiosResponse } from 'axios';
import { ASSETS_API_URL, OFFER_API_URL, UPLOAD_PRICING_URL } from '../../../../config';
import assetsAxios from '../../../../utils/axios/assets.axios';
import { IAssets } from '../../../../interfaces/assets/IAssets';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { PaginatedRentalPricing } from '../../../../interfaces/rental-pricing/paginatedStore.type';
import offersAxios from '../../../../utils/axios/offers.axios';
import uploadPricingAxios from '../../../../utils/axios/upload-pricing.axios';
import { IRentalPricing } from '../../../../interfaces/rental-pricing/IRentalPricing';


const tryToFetchRentalAssets = (
    pageNumber: number,
    pageSize: number,
    id: string,
    filters?: string,
): Promise<AxiosResponse<any>> => {
    const url = filters
        ? `${ASSETS_API_URL}/rental-assets/${id}?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${ASSETS_API_URL}/rental-assets/${id}?page=${pageNumber}&size=${pageSize}`;
    return assetsAxios.get<any>(url);
};

const tryToFetchSingleRentalAsset = (id: string, assetId: string): Promise<AxiosResponse<ApiResponse<any>>> => {
    return assetsAxios.get(`${ASSETS_API_URL}/rental-assets/${id}/${assetId}`);
};

const tryToEditRentalAsset = (id: string, payload: IAssets): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/rental-assets/${id}`, payload);
};

const tryToAddRentalAsset = (data: IAssets): Promise<AxiosResponse> => {
    return assetsAxios.post(`${ASSETS_API_URL}/rental-assets`, data);
};

const tryToDeleteRentalAsset = (assetId: string): Promise<AxiosResponse> => {
    return assetsAxios.delete(`${ASSETS_API_URL}/rental-assets/${assetId}`);
};

const tryToImportRentalAssetData = (data: { assetTemplateId: string, assets: Array<any> }): Promise<AxiosResponse> => {
    return assetsAxios.post(`${ASSETS_API_URL}/brokers/rental-assets/import`, data);
};

const tryToDownloadRentalAssets = (id: string, pricing?: boolean): Promise<AxiosResponse> => {
    return assetsAxios.get(pricing ? `${ASSETS_API_URL}/brokers/${id}/download/rental/true` : `${ASSETS_API_URL}/brokers/${id}/download/rental`);
};

const tryToDeleteCatalogRentalAssets = (id: string): Promise<AxiosResponse> => {
    return assetsAxios.delete(`${ASSETS_API_URL}/rental-assets/batch/${id}`);
};

const tryToFetchRentalAssetFieldValuesDependent = (assetTemplateId: string, data?: any, replacements?: boolean): Promise<AxiosResponse> => {
    let url = `${ASSETS_API_URL}/rental-assets/${assetTemplateId}/values/filter`
    if (replacements) {
        url = url + '?replacements=true'
    }
    return assetsAxios.put(url, data)
}

// ---------------------------- Rental Pricing --------------------------------

const tryToFetchRentalPricings = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse<PaginatedRentalPricing>> => {
    const url = `${OFFER_API_URL}/rental-offers?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedRentalPricing>(url, data);
};

const tryToDownloadRentalPricing = (assetTemplateId: string, market: string): Promise<AxiosResponse> => {
    return offersAxios.get(`${OFFER_API_URL}/rental-offers/download/${assetTemplateId}/${market}`);
};

const tryToUploadRentalPricingCSV = async (data: any): Promise<any> => {
    const urlOrder = `${UPLOAD_PRICING_URL}/rental-offers`;
    return uploadPricingAxios.post<ApiResponse<IRentalPricing>>(urlOrder, data);
};

const tryToChangeRentalPricingData = (data: any): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/rental-offers/pricing`, data);
};

export const RentalPricingService = {
    tryToFetchRentalAssets,
    tryToFetchSingleRentalAsset,
    tryToEditRentalAsset,
    tryToAddRentalAsset,
    tryToDeleteRentalAsset,
    tryToImportRentalAssetData,
    tryToDownloadRentalAssets,
    tryToDeleteCatalogRentalAssets,
    tryToFetchRentalAssetFieldValuesDependent,
    tryToFetchRentalPricings,
    tryToDownloadRentalPricing,
    tryToUploadRentalPricingCSV,
    tryToChangeRentalPricingData,
};
