import React from 'react'

interface IRangeSlider {
    currentValue?: number;
    minValue?: number;
    maxValue?: number;
    steps?: string;
    containerClassname?: string;
    inputClassname?: string;
    onChangeRangeSliderValue: (value: any) => void;
}

const RangeSlider = ({
    currentValue,
    minValue,
    maxValue,
    steps,
    containerClassname,
    inputClassname,
    onChangeRangeSliderValue
}: IRangeSlider) => {
    return (
        <div className={containerClassname}>
            <label>
                <input
                    className={inputClassname}
                    id='typeinp'
                    type='range'
                    min={`${minValue || 0}`}
                    max={`${maxValue || 100}`}
                    value={`${currentValue}`}
                    onChange={(e) => onChangeRangeSliderValue(e)}
                    step={steps || '1'} />
            </label>
        </div>
    )
}

export default RangeSlider