import React, { useEffect, useState } from 'react'

import { useLocation, useSearchParams } from 'react-router-dom';

import Toast from '../../../../shared/toast';
import Input from '../../../../shared/input';
import Button from '../../../../shared/button';
import ItemsTable from '.././tables/ItemsTable';
import CreateRMAModal from '.././modals/CreateRMAModal';
import { IItem } from '../../../../interfaces/items/IItem';
import EmptyContainer from '../../../../shared/empty-container';
import { IToast } from '../../../../interfaces/components/IToast';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useUserGetProfileQuery, useUserProfileMutation } from '../../../../store/user/userDomApi';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToCreateRMA, tryToFetchShippedItems } from '../../../../store/brokers/partners/rma/partnerRMAsSlice';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import SelectCheckbox from '../../../../shared/select-checkbox';
const OPTIONS = [
    {
        label: 'All statuses',
        value: undefined,
    },
    {
        label: 'Require Return',
        value: 'require_return',
    },
    {
        label: 'Approved Return',
        value: 'approved_return',
    },
    {
        label: 'Revalued Return',
        value: 'revalued_return',
    },
    {
        label: 'Rejected Return',
        value: 'rejected_return',
    },
    {
        label: 'Review Return',
        value: 'review_return',
    },
    {
        label: 'Accepted Return',
        value: 'accepted_return',
    },
    {
        label: 'Broker accepted Revaluation',
        value: 'broker_accepted_revaluation',
    },
];
const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
interface ICreateRMATab {
    paginationState: IPaginationPayload;
    setPaginationState: React.Dispatch<React.SetStateAction<IPaginationPayload>>
    sectors: IAssetTemplate[],
    pageAccess: any
    markets: IAssetTemplate[]
}

const CreateRMATab = ({ paginationState, setPaginationState, sectors, pageAccess }: ICreateRMATab) => {
    const dispatch = useAppDispatch();
    const [sectorSelected, setSectorSelected] = useState<string | undefined>();
    const [rows, setRows] = useState<IItem[]>([]);
    const [selectedRows, setSelectedRows] = useState<IItem[] | undefined>()
    const [showCreateRMAModal, setShowCreateRMAModal] = useState(false)
    const [createLoading, setCreateLoading] = useState(false)
    const paginationData = useAppSelector((state) => state.partnersRMAs.items?.data?.page);
    const rowsLoading = useAppSelector((state) => state.partnersRMAs.itemsAreLoading);
    const state = useAppSelector((state) => state.partnersRMAs);
    const [showToast, setShowToast] = useState<IToast | null>();
    const [brokerId, setBrokerId] = useState('')
    const [selectedStatus, setSelectedStatus] = useState(OPTIONS[0]);
    const [categories, setCategories] = useState<IAssetTemplate[]>([]);
    
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const findSector: any = (sectors && sectorSelected && sectors?.length > 0) && sectors?.find((item: any) => item?.name === sectorSelected)

    const [userProfileMutation] = useUserProfileMutation();
    const [searchParams, setSearchParams] = useSearchParams();
    const query = useQuery();
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);

    const { data: user } = useUserGetProfileQuery();

    useEffect(() => {
        if(user){
            const transformedSectors = user.data.sectors.map((item: IAssetTemplate) => ({
                _id: item.id,
                name: item.name,
                displayName: item.displayName,
                enabledIcon: item.enabledIcon,
                disabledIcon: item.disabledIcon,
                label: item.displayName,
            }));
    
            setCategories([ ...transformedSectors]);
        }
    }, [user])


    const onClickSelectRow = (id?: string) => {
        if (!id) {
            const getAllIds = rows?.length > 0 && rows?.filter(row => !selectedRows?.find(sr => sr._id === row?._id)).filter(row => !row.rmaStatus)
            if ((selectedRows && rows && rows.filter((item: any) => !item.rmaStatus).every(r => selectedRows.find(sr => sr._id === r?._id)))) {
                setSelectedRows(sr => sr?.filter(sr => !rows.find(row => row?._id === sr._id)))
            } else {
                setSelectedRows(sr => getAllIds ? [...(sr || []), ...getAllIds] : getAllIds || [])
            }
        }
        else {
            const findIfAlreadySelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find((row) => row._id === id)
            if (findIfAlreadySelected) {
                const filterOthers = selectedRows && selectedRows?.length > 0 && selectedRows?.filter((row) => row._id !== id)
                setSelectedRows(filterOthers || undefined)
            } else {
                const rowToAdd = rows.find(row => row._id === id)
                if (rowToAdd) {
                    setSelectedRows(sr => [...(sr || []), rowToAdd])
                }
            }
        }
    }

    const onToggleCreateRMAModal = () => {
        setShowCreateRMAModal(!showCreateRMAModal);

    }

    const onCreateRMASuccessfully = async (data: {
        [key: string]: string
    }) => {

        try {
            setCreateLoading(true);

            const formattedData = selectedRows?.map(item => ({
                reason: data[`item${item._id}_reason`],
                type: data[`item${item._id}_action`],
                item: { ...item, marketId: activeMarketState?.market?._id, currency: activeMarketState?.currency?.descriptor?.name },
                orderId: item.orderId
            })
            )
            await dispatch(tryToCreateRMA({ data: formattedData, brokerId })).unwrap()

            setShowToast({
                type: 'success',
                message: `Return Merchandise Authorization was created successfully.`,
            });
            setSelectedRows(undefined)
            setShowCreateRMAModal(false);
            setCreateLoading(false);
            searchParams.set('page', `${paginationState?.pageNumber || 1}`)
            searchParams.set('size', `${paginationState?.pageSize}`)
            setSearchParams(searchParams)
            dispatch(tryToFetchShippedItems({ ...paginationState, status: selectedStatus.value, data: { marketName: activeMarketState?.market?.name } }))


        } catch (err: any) {
            setShowCreateRMAModal(false);
            setCreateLoading(false);
            setShowToast({ type: 'error', message: `${err.message}` });
        }

    }

    const getUserProfileInfo = async () => {
        const response = await userProfileMutation(null).unwrap()
        setBrokerId(response?.data._id || '')
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
            filters: value || undefined
        })
        if (value) searchParams.set('search', value)
        else searchParams.delete('search')
        searchParams.set('page', '1')
        setSearchParams(searchParams)
    }

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined,
        });
        searchParams.set('page', '1');
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    };

    const onClickStatusFilter = (value: any, type?: string) => {
        setSelectedStatus(value);
        if (value.value) {
            searchParams.set('status', value?.value);
        } else searchParams.delete('status');
        setSearchParams(searchParams);
        onResetPaginationSettings();
    };

    const onChangeSelectedFilter = (value: any, type?: string) => {
        setSectorSelected(value?.name)
        if (value?.name) searchParams.set('category', value?.name)
        else searchParams.delete('category')
        onResetPaginationSettings()
      }


    useEffect(() => {
        getUserProfileInfo()
    }, [])

    useEffect(() => {
        const paginationStateFormat = paginationState
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1)
        }
        if (query?.get('size') && Number(query?.get('size')) !== paginationState?.pageSize) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10)
        }
        if (query?.get('search')) {
            paginationStateFormat.filters = query.get('search') || undefined
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat)
        }
    }, [
        query?.get('page'),
        query?.get('size'),
        query?.get('search'),
    ])
    useEffect(() => {
        if (query?.get('status')) {
            setSelectedStatus(OPTIONS.find(o => o.value === query?.get('status')) || OPTIONS[0])
        } else searchParams.delete('status')
    }, [
        query?.get('status')
    ])

    useEffect(() => {
        searchParams.set('page', `${paginationState?.pageNumber}`)
        searchParams.set('size', `${paginationState?.pageSize}`)
        setSearchParams(searchParams)
        if (activeMarketState) {
            dispatch(tryToFetchShippedItems({ ...paginationState, status: selectedStatus.value, data: { marketName: activeMarketState?.market?.name }, category: sectorSelected  }))
        }
    }, [paginationState, selectedStatus, activeMarketState])

    useEffect(() => {
        if (state.items) {
            const rows =
                state.items?.data?.elements &&
                    Array.isArray(state.items?.data.elements)
                    ? state.items?.data.elements.map((r) => ({
                        ...r,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.items]);

    const onClearAllFilters = () => {
        searchParams.delete('status');
        searchParams.delete('category');
        setSearchParams(searchParams)
        setSelectedStatus(OPTIONS[0])
        setSectorSelected(undefined)
        setPaginationState({ ...paginationState, filters:  '' })
    }


    return (
        <div>
            <div className="my-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 sm:gap-2 lg:gap-4">
                <SelectCheckbox
                    name="Status"
                    dataQa={'status-filter'}
                    placeholder={selectedStatus.label || ''}
                    options={OPTIONS}
                    selectStyle={{
                        backgroundColor: '#ffffffa4',
                        borderRadius: '4px',
                    }}
                    onChangeSelectedOption={onClickStatusFilter}
                    uniqueName={'Status'}
                />
               <SelectCheckbox
                    name='Category'
                    containerStyle={'!min-w-[200px]'}
                    placeholder={findSector?.label || 'All'}
                    dataQa={'Category'}
                    options={[{ name: undefined, displayName: 'All', label: 'All' }, ...categories] || []}
                    onChangeSelectedOption={onChangeSelectedFilter}
                />
            </div>
            <div className='bg-[#F7A21E1A] rounded-[10px] p-2 flex flex-row justify-between items-center'>
                <div>
                    <p className='font-bold'>{selectedRows ? selectedRows?.length : 0} Items selected</p>
                </div>
                <div className='flex flex-row gap-5 items-center'>
                    <Input
                        placeholder={'Filter Table'}
                        containerStyle='mb-0 pb-0 relative flex-initial w-96'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={paginationState?.filters}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                        dataQa={'filter-table'}
                    />
                    <Button
                        label={'Clear All Filters'}
                        dataQa={'clear-all-filters'}
                        className='btn-primary-text-underline min-w-auto !mt-0 flex flex-row justify-end bg-transparent mr-2 mb-1'
                        onClickButton={onClearAllFilters}
                    />
                    <Button
                        label={'Create RMA'}
                        dataQa={'create-rma'}
                        onClickButton={((selectedRows && selectedRows?.length > 0) && (!accessControl || pageAccess?.actions?.['Create RMA'])) ? onToggleCreateRMAModal : () => { }}
                        className={`${((selectedRows && selectedRows?.length > 0) && (!accessControl || pageAccess?.actions?.['Create RMA'])) ? 'btn-main !bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !text-[#202020]' : 'btn-main-disable cursor-not-allowed'}   flex flex-row align-start my-2 !py-3 !px-5`}
                    />
                </div>

            </div>

            <div className='my-5 relativ'>
                {(rows?.length > 0 || rowsLoading) ?
                    <ItemsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        onClickSelectRow={onClickSelectRow}
                        selectedRows={selectedRows}
                        sectors={sectors}
                    />
                    : <EmptyContainer
                        text={'No items found.'}
                        showImage={true}
                    />
                }
            </div>
            {showCreateRMAModal && (
                <CreateRMAModal
                    openModal={showCreateRMAModal}
                    handleCloseModal={onToggleCreateRMAModal}
                    onSubmitSuccessfully={onCreateRMASuccessfully}
                    selectedRows={selectedRows || []}
                    createLoading={createLoading}
                />
            )}
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
        </div>
    )
}

export default CreateRMATab
