import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { WholesaleService } from './wholesalePriceApi';
import { IWholesaleListState } from '../../../../interfaces/wholesale-price/IWholesalePriceList';
import { PaginatedWholesalePrice } from '../../../../interfaces/wholesale-price/paginatedStore.type';
import { IWholesalePrice } from '../../../../interfaces/wholesale-price/IWholesalePrice';
import { IAllWholepricePricing } from '../../../../interfaces/wholesale-price/IAllWholesalePricing';

const initialState: IWholesaleListState = {
    wholesalePricesAreLoading: false,
    wholesalePriceIsLoading: false,
    wholesaleCurrenciesIsLoading: false,
    wholesalePriceMatchAreLoading: false,
    wholesalePriceMatchedAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchFilteredWholesalePrice = createAsyncThunk<PaginatedWholesalePrice, IPaginationPayload>(
    'wholesale/tryToFetchFilteredWholesalePrice',
    async ({ pageNumber, pageSize, data }) => {
        const result = await WholesaleService.tryToFetchFilteredWholesalePrice(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToDownloadWholesaleSamples = createAsyncThunk<any, any>(
    'wholesale/tryToDownloadWholesaleSamples',
    async (data?: any) => {
        const result = await WholesaleService.tryToDownloadWholesaleSamples(data);
        return result?.data;
    }
)

export const tryToFetchFilteredAdminWholesalePrice = createAsyncThunk<PaginatedWholesalePrice, IPaginationPayload>(
    'wholesale/tryToFetchFilteredAdminWholesalePrice',
    async ({ pageNumber, pageSize, data }) => {
        const result = await WholesaleService.tryToFetchFilteredAdminWholesalePrice(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToAddWholesalePrice = createAsyncThunk<ApiResponse<IWholesalePrice>, any>(
    'wholesale/tryToAddWholesalePrice',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await WholesaleService.tryToAddWholesalePrice(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditWholesalePrice = createAsyncThunk<ApiResponse<IWholesalePrice>, any>(
    'wholesale/tryToEditWholesalePrice',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await WholesaleService.tryToEditWholesalePrice(data._id || '', data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteWholesalePrice = createAsyncThunk<ApiResponse<IWholesalePrice>, string>(
    'wholesale/tryToDeleteWholesalePrice',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await WholesaleService.tryToDeleteWholesalePrice(id);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditWholesalePriceStatus = createAsyncThunk<ApiResponse<IWholesalePrice>, any>(
    'wholesale/tryToEditWholesalePriceStatus',
    async ({ data, status }, { rejectWithValue }) => {
        try {
            const result = await WholesaleService.tryToEditWholesalePriceStatus(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchWholesaleAvailableStock = createAsyncThunk<any, any>(
    'wholesale/tryToFetchWholesaleAvailableStock',
    async (data?: any) => {
        const result = await WholesaleService.tryToFetchWholesaleAvailableStock(data);
        return result?.data?.data;
    }
)

export const tryToDownloadFilteredAdminWholesalePrice = createAsyncThunk<any, { brokerId: string, data: any }>(
    'wholesale/tryToDownloadFilteredAdminWholesalePrice',
    async ({ brokerId, data }) => {
        return await WholesaleService.tryToDownloadFilteredAdminWholesalePrice(brokerId, data);
    },
);

export const tryToDownloadPartnerWholesalePrice = createAsyncThunk<any, any>(
    'wholesale/tryToDownloadPartnerWholesalePrice',
    async ({ templateId, data }, { rejectWithValue }) => {
        try {
            return await WholesaleService.tryToDownloadPartnerWholesalePrice(templateId, data);
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToUploadWholesalePricingCSV = createAsyncThunk<Promise<any>, { data: any, assetTemplateId: string, marketId: string, type: any, currency: string, vat: any, brokerUserName?: string, template?: string }>(
    'wholesale/tryToUploadWholesalePricingCSV',
    async ({ data, assetTemplateId, marketId, type, currency, vat, brokerUserName, template }) => {
        return await WholesaleService.tryToUploadWholesalePricingCSV(data, assetTemplateId, marketId, type, currency, vat, brokerUserName, template);
    },
);

export const tryToFetchAllCurrencies = createAsyncThunk<any, undefined>(
    'wholesale/tryToFetchAllCurrencies',
    async () => {
        const response = await WholesaleService.tryToFetchAllCurrencies();
        return response?.data
    }
);

export const tryToFetchAllWholesalePricing = createAsyncThunk<
    ApiResponse<any>,
    IAllWholepricePricing
>(
    'wholesale/tryToFetchAllWholesalePricing',
    async (data: IAllWholepricePricing, { rejectWithValue }) => {
        try {
            const result = await WholesaleService.tryToFetchAllWholesalePricing(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

// tryToDownloadAllWholesaleOffers
export const tryToDownloadAllWholesaleOffers = createAsyncThunk<
    ApiResponse<any>,
    IAllWholepricePricing
>(
    'wholesale/tryToDownloadAllWholesaleOffers',
    async (data: IAllWholepricePricing, { rejectWithValue }) => {
        try {
            const result = await WholesaleService.tryToDownloadAllWholesaleOffers(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDownloadAllWholesaleOffersMatched = createAsyncThunk<ApiResponse<any>, any>(
    'wholesale/tryToDownloadAllWholesaleOffersMatched',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await WholesaleService.tryToDownloadAllWholesaleOffersMatched(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchFilteredAdminWholesalePriceMatch = createAsyncThunk<PaginatedWholesalePrice, IPaginationPayload>(
    'wholesale/tryToFetchFilteredAdminWholesalePriceMatch',
    async ({ pageNumber, pageSize, data }) => {
        const result = await WholesaleService.tryToFetchFilteredAdminWholesalePriceMatch(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToFetchMatchedWholesalePrice = createAsyncThunk<any, IPaginationPayload>(
    'wholesale/tryToFetchMatchedWholesalePrice',
    async ({ pageNumber, pageSize, data }) => {
        const result = await WholesaleService.tryToFetchMatchedWholesalePrice(pageNumber, pageSize, data);
        return result;
    },
);

export const tryToMatchWholesalePrices = createAsyncThunk<ApiResponse<any>, any>(
    'wholesale/tryToMatchWholesalePrices',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await WholesaleService.tryToMatchWholesalePrices(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchWholesalePriceAvailable = createAsyncThunk<any, any>(
    'wholesale/tryToFetchWholesalePriceAvailable',
    async ({ pageNumber, pageSize, data }) => {
        const result = await WholesaleService.tryToFetchWholesalePriceAvailable(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToFetchWholesalePriceAvailableBuy = createAsyncThunk<any, any>(
    'wholesale/tryToFetchWholesalePriceAvailableBuy',
    async ({ pageNumber, pageSize, data }) => {
        const result = await WholesaleService.tryToFetchWholesalePriceAvailableBuy(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToFetchWholesalePriceSettings = createAsyncThunk<any, IPaginationPayload>(
    'wholesale/tryToFetchWholesalePriceSettings',
    async ({ pageNumber, pageSize, data }) => {
        const result = await WholesaleService.tryToFetchWholesalePriceSettings(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToFetchSingleWholesalePriceSettings = createAsyncThunk<any, string>(
    'wholesale/tryToFetchSingleWholesalePriceSettings',
    async (id: string) => {
        const response = await WholesaleService.tryToFetchSingleWholesalePriceSettings(id);
        return response?.data?.data
    }
);

export const tryToCreateWholesalePriceSettings = createAsyncThunk<any, any>(
    'wholesale/tryToCreateWholesalePriceSettings',
    async (data, { rejectWithValue }) => {
        try {
            return await WholesaleService.tryToCreateWholesalePriceSettings(data);
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditWholesalePriceSettings = createAsyncThunk<any, any>(
    'wholesale/tryToEditWholesalePriceSettings',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await WholesaleService.tryToEditWholesalePriceSettings(id, data);
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteWholesalePriceSettings = createAsyncThunk<ApiResponse<any>, string>(
    'commission-fees/tryToDeleteWholesalePriceSettings', async (id, { rejectWithValue }) => {
        try {
            const results = await WholesaleService.tryToDeleteWholesalePriceSettings(id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToFetchBestOffer = createAsyncThunk<any, any>(
    'wholesale/tryToFetchBestOffer',
    async ({ data, type }, { rejectWithValue }) => {
        try {
            const result = await WholesaleService.tryToFetchBestOffer(data, type);
            return result?.data?.data
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToCreateOfferWholesalePrice = createAsyncThunk<any, any>(
    'wholesale/tryToCreateOfferWholesalePrice',
    async (data, { rejectWithValue }) => {
        try {
            const result = await WholesaleService.tryToCreateOfferWholesalePrice(data);
            return result?.data?.data
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const wholesalePriceSlice = createSlice({
    name: 'wholesalePrice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchFilteredWholesalePrice
            .addCase(tryToFetchFilteredWholesalePrice.pending, (state) => {
                state.wholesalePricesAreLoading = true;
            })
            .addCase(tryToFetchFilteredWholesalePrice.rejected, (state) => {
                state.wholesalePricesAreLoading = false;
            })
            .addCase(tryToFetchFilteredWholesalePrice.fulfilled, (state, action) => {
                state.wholesalePricesAreLoading = false;
                state.wholesalePrices = action.payload;
            })

            // tryToFetchFilteredAdminWholesalePrice
            .addCase(tryToFetchFilteredAdminWholesalePrice.pending, (state) => {
                state.wholesalePricesAreLoading = true;
            })
            .addCase(tryToFetchFilteredAdminWholesalePrice.rejected, (state) => {
                state.wholesalePricesAreLoading = false;
            })
            .addCase(tryToFetchFilteredAdminWholesalePrice.fulfilled, (state, action) => {
                state.wholesalePricesAreLoading = false;
                state.wholesalePrices = action.payload;
            })

            // tryToFetchWholesalePriceAvailable
            .addCase(tryToFetchWholesalePriceAvailable.pending, (state) => {
                state.wholesalePricesAreLoading = true;
            })
            .addCase(tryToFetchWholesalePriceAvailable.rejected, (state) => {
                state.wholesalePricesAreLoading = false;
            })
            .addCase(tryToFetchWholesalePriceAvailable.fulfilled, (state, action) => {
                state.wholesalePricesAreLoading = false;
                state.availableStock = action.payload;
            })

            // tryToFetchWholesalePriceAvailableBuy
            .addCase(tryToFetchWholesalePriceAvailableBuy.pending, (state) => {
                state.wholesalePricesAreLoading = true;
            })
            .addCase(tryToFetchWholesalePriceAvailableBuy.rejected, (state) => {
                state.wholesalePricesAreLoading = false;
            })
            .addCase(tryToFetchWholesalePriceAvailableBuy.fulfilled, (state, action) => {
                state.wholesalePricesAreLoading = false;
                state.availableStockBuy = action.payload;
            })

            // tryToUploadWholesalePricingCSV
            .addCase(tryToUploadWholesalePricingCSV.pending, (state) => {
                state.wholesalePriceIsLoading = true;
            })
            .addCase(tryToUploadWholesalePricingCSV.rejected, (state) => {
                state.wholesalePricesAreLoading = false;
            })
            .addCase(tryToUploadWholesalePricingCSV.fulfilled, (state, action) => {
                state.wholesalePricesAreLoading = false;
                // state.wholesalePrices = action.payload;
            })

            // tryToEditWholesalePrice
            .addCase(tryToEditWholesalePrice.pending, (state) => {
                state.wholesalePriceIsLoading = true;
            })
            .addCase(tryToEditWholesalePrice.rejected, (state) => {
                state.wholesalePriceIsLoading = false;
            })
            .addCase(tryToEditWholesalePrice.fulfilled, (state, action) => {
                state.wholesalePriceIsLoading = false;
                if (state.wholesalePrices?.data.elements)
                    state.wholesalePrices.data.elements =
                        { ...state }.wholesalePrices?.data.elements.map((review: any) => {
                            if (review?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return review;
                            }
                        }) || [];
                state.wholesalePrice = action.payload.data;
            })

            // tryToEditWholesalePriceStatus
            .addCase(tryToEditWholesalePriceStatus.pending, (state) => {
                state.wholesalePriceIsLoading = true;
            })
            .addCase(tryToEditWholesalePriceStatus.rejected, (state) => {
                state.wholesalePriceIsLoading = false;
            })
            .addCase(tryToEditWholesalePriceStatus.fulfilled, (state, action) => {
                state.wholesalePriceIsLoading = false;
                if (state.wholesalePriceMatch?.data.elements)
                    state.wholesalePriceMatch.data.elements = action?.meta?.arg?.status ?
                        { ...state }.wholesalePriceMatch?.data.elements.filter((wholesale: any) => !(wholesale?.sell?.[0]?._id === action.meta?.arg?.data?.sellWholesaleOfferId && wholesale.buy?.[0]?._id === action.meta?.arg?.data?.buyWholesaleOfferId)) || []
                        : { ...state }.wholesalePriceMatch?.data.elements.map((wholesale: any) => {
                            if (wholesale?.sell?.[0]?._id === action.meta?.arg?.data?.sellWholesaleOfferId && wholesale.buy?.[0]?._id === action.meta?.arg?.data?.buyWholesaleOfferId) {
                                return {
                                    ...wholesale,
                                    status: action.meta?.arg?.data?.status
                                };
                            } else {
                                return wholesale;
                            }
                        }) || [];
                state.wholesalePrice = action.payload.data;
            })

            // tryToFetchAllWholesalePricing
            .addCase(tryToFetchAllWholesalePricing.pending, (state) => {
                state.wholesalePriceIsLoading = true;
            })
            .addCase(tryToFetchAllWholesalePricing.rejected, (state) => {
                state.wholesalePriceIsLoading = false;
            })
            .addCase(tryToFetchAllWholesalePricing.fulfilled, (state, action) => {
                state.wholesalePriceIsLoading = false;
                if (state.wholesalePrices?.data.elements)
                    state.wholesalePrices.data.elements =
                        { ...state }.wholesalePrices?.data.elements.map((wholesale: any) => {
                            if (wholesale?.assetTemplateId === action.payload?.data?.assetTemplateId) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return wholesale;
                            }
                        }) || [];
                state.wholesalePrice = action.payload.data;
            })

            // tryToDeleteWholesalePrice
            .addCase(tryToDeleteWholesalePrice.pending, (state) => {
                state.wholesalePriceIsLoading = true;
            })
            .addCase(tryToDeleteWholesalePrice.rejected, (state) => {
                state.wholesalePriceIsLoading = false;
            })
            .addCase(tryToDeleteWholesalePrice.fulfilled, (state, action) => {
                state.wholesalePriceIsLoading = false;
                if (state.wholesalePrices?.data.elements)
                    state.wholesalePrices.data.elements = { ...state }.wholesalePrices?.data.elements.filter((wholesale: any) => wholesale?._id !== action?.meta?.arg) || [];
            })

            // tryToGetAllCurrencies
            .addCase(tryToFetchAllCurrencies.pending, (state) => {
                state.wholesaleCurrenciesIsLoading = true;
            })
            .addCase(tryToFetchAllCurrencies.rejected, (state) => {
                state.wholesaleCurrenciesIsLoading = false;
            })
            .addCase(tryToFetchAllCurrencies.fulfilled, (state, action) => {
                state.wholesaleCurrenciesIsLoading = true;
                state.wholesaleCurrencies = action.payload?.data;
            })

            // tryToFetchFilteredAdminWholesalePriceMatch
            .addCase(tryToFetchFilteredAdminWholesalePriceMatch.pending, (state) => {
                state.wholesalePriceMatchAreLoading = true;
            })
            .addCase(tryToFetchFilteredAdminWholesalePriceMatch.rejected, (state) => {
                state.wholesalePriceMatchAreLoading = false;
            })
            .addCase(tryToFetchFilteredAdminWholesalePriceMatch.fulfilled, (state, action) => {
                state.wholesalePriceMatchAreLoading = false;
                state.wholesalePriceMatch = action.payload;
            })

            // tryToFetchMatchedWholesalePrice
            .addCase(tryToFetchMatchedWholesalePrice.pending, (state) => {
                state.wholesalePriceMatchedAreLoading = true;
            })
            .addCase(tryToFetchMatchedWholesalePrice.rejected, (state) => {
                state.wholesalePriceMatchedAreLoading = false;
            })
            .addCase(tryToFetchMatchedWholesalePrice.fulfilled, (state, action) => {
                state.wholesalePriceMatchedAreLoading = false;
                state.wholesalePriceMatched = action.payload;
            })

            // tryToFetchWholesalePriceSettings
            .addCase(tryToFetchWholesalePriceSettings.pending, (state) => {
                state.wholesalePricesAreLoading = true;
            })
            .addCase(tryToFetchWholesalePriceSettings.rejected, (state) => {
                state.wholesalePricesAreLoading = false;
            })
            .addCase(tryToFetchWholesalePriceSettings.fulfilled, (state, action) => {
                state.wholesalePricesAreLoading = false;
                state.wholesalePriceSettings = action.payload;
            })

            // tryToFetchSingleWholesalePriceSettings
            .addCase(tryToFetchSingleWholesalePriceSettings.pending, (state) => {
                state.wholesalePriceIsLoading = true;
            })
            .addCase(tryToFetchSingleWholesalePriceSettings.rejected, (state) => {
                state.wholesalePriceIsLoading = false;
            })
            .addCase(tryToFetchSingleWholesalePriceSettings.fulfilled, (state, action) => {
                state.wholesalePriceIsLoading = false;
                state.wholesalePriceSetting = action.payload;
            })

            // tryToCreateWholesalePriceSettings
            .addCase(tryToCreateWholesalePriceSettings.pending, (state) => {
                state.wholesalePriceIsLoading = true;
            })
            .addCase(tryToCreateWholesalePriceSettings.rejected, (state) => {
                state.wholesalePriceIsLoading = false;
            })
            .addCase(tryToCreateWholesalePriceSettings.fulfilled, (state, action) => {
                state.wholesalePriceIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.wholesalePriceSettings?.data)
                    state.wholesalePriceSettings.data.elements = [
                        data,
                        ...state.wholesalePriceSettings?.data.elements || {},
                    ];
            })

            // tryToEditWholesalePriceSettings
            .addCase(tryToEditWholesalePriceSettings.pending, (state) => {
                state.wholesalePriceIsLoading = true;
            })
            .addCase(tryToEditWholesalePriceSettings.rejected, (state) => {
                state.wholesalePriceIsLoading = false;
            })
            .addCase(tryToEditWholesalePriceSettings.fulfilled, (state, action) => {
                state.wholesalePriceIsLoading = false;
                if (state.wholesalePriceSettings?.data.elements)
                    state.wholesalePriceSettings.data.elements =
                        { ...state }.wholesalePriceSettings?.data.elements.map((setting: any) => {
                            if (setting?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return setting;
                            }
                        }) || [];
                state.wholesalePriceSetting = action.payload.data;
            })

            // tryToDeleteWholesalePriceSettings
            .addCase(tryToDeleteWholesalePriceSettings.pending, (state) => {
                state.wholesalePriceIsLoading = true;
            })
            .addCase(tryToDeleteWholesalePriceSettings.rejected, (state) => {
                state.wholesalePriceIsLoading = false;
            })
            .addCase(tryToDeleteWholesalePriceSettings.fulfilled, (state, action) => {
                state.wholesalePriceIsLoading = false;
                state.requestStatus = 'success';
                if (state.wholesalePriceSettings?.data.elements)
                    state.wholesalePriceSettings.data.elements =
                        { ...state }.wholesalePriceSettings?.data.elements.filter(
                            (setting: any) =>
                                setting?._id !== action.meta.arg,
                        ) || [];
            })

    },
});

export default wholesalePriceSlice.reducer;