import React from 'react';
import { GRADE_DESCRIPTION } from '../../../../utils/constants/grade-descriptions';
import Modal from '../../../../shared/modal';


interface IGradeDetailsModal {
    openModal: boolean;
    handleCloseModal: () => void;
}

const GradeDetailsModal = ({
    openModal,
    handleCloseModal
}: IGradeDetailsModal) => {
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <div>
                    <div className='grid grid-cols-2 gap-3 my-2'>
                        <p className='font-bold text-md'>Condition</p>
                        <p className='font-bold text-md'>Description</p>
                    </div>
                    {GRADE_DESCRIPTION?.length > 0 && GRADE_DESCRIPTION?.map((item: any) => {
                        return (
                            <>
                                <div className='grid grid-cols-2 gap-3 my-2'>
                                    <p className=''>{item?.name}</p>
                                    <p>{item?.description}</p>
                                </div>
                                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            </>
                        )
                    })}
                </div>
            </div>
        </Modal>
    )
}

export default GradeDetailsModal;