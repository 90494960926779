import React from 'react';
import PaymentMethod from '../details/PaymentMethod';

const paymentMethodsItems = [
    { name: 'Wing Pay', selected: true, image: '', countriesAvailable: ['Germany', 'Belgium'] },
    { name: 'PayPal', selected: false, image: '', countriesAvailable: ['UK', 'Italy'] },
]

const Payment = () => {
    return (
        <div className='grid grid-cols-3 gap-4'>
            {paymentMethodsItems && paymentMethodsItems?.length > 0 && paymentMethodsItems?.map((payment, index) => {
                return (
                    <PaymentMethod
                        selected={payment?.selected}
                        key={index}
                        name={payment?.name}
                        image={payment?.image}
                        countriesAvailable={payment?.countriesAvailable}
                    />
                )
            })}
        </div>
    )
}

export default Payment;