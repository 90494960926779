import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface ICommissionFeeFilters {
    partners?: Array<IPartners>;
    sectors?: Array<IAssetTemplate>;
    payServices?: Array<any>;
    selectedPartner?: any;
    selectedSector?: any;
    selectedPayService?: any;
    onSelectFilter: (id: string, type?: string) => void;
}

const CommissionFeeFilters = ({
    partners,
    sectors,
    payServices,
    selectedPartner,
    selectedSector,
    selectedPayService,
    onSelectFilter
}: ICommissionFeeFilters) => {
    return (
        <div>
            <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Partner'}
                        placeholder={' '}
                        dataQa={'partner-selected'}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        options={partners || []}
                        uniqueName={'partner'}
                        selectedOption={selectedPartner}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Category'}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        placeholder={' '}
                        dataQa={'category-selected'}
                        selectedOption={selectedSector}
                        options={sectors || []}
                        uniqueName={'sector'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Pay Service'}
                        placeholder={' '}
                        dataQa={'pay-service'}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        options={payServices || []}
                        selectedOption={selectedPayService}
                        uniqueName={'payService'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
            </div>
        </div>
    )
}

export default CommissionFeeFilters;