import React from 'react';
import Button from '../../../../shared/button';


interface IRevaluateItemImages {
    isDragActive: any;
    itemId: any;
    filesSelected?: any;
    getRootProps: any;
    getInputProps: any;
    onDeleteImage: (file: any) => void;
}

const RevaluateItemImages = ({
    isDragActive,
    itemId,
    filesSelected,
    getRootProps,
    getInputProps,
    onDeleteImage
}: IRevaluateItemImages) => {
    return (
        <div className='mb-4 bg-[#f8f9fc] px-4 p-2'>
            <p className='mb-2'>Insert Snapshot as proof (Required)</p>
            <div className='flex flex-row items-center mr-10' {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                    icon={<img src='/assets/shared/add-plus.svg' className='w-[30px] object-contains pr-2' />}
                    className={'btn-main min-w-[150px] !bg-white !py-2 !pr-3 !shadow-none !border-none flex flex-row items-center'}
                    label={'Upload file'}
                    dataQa={'select-file'}
                />
            </div>
            {/* <div className='ml-10'>
                    <p className='mb-4 text-center'>
                        Scan item barcodes to scan the item using the app
                    </p>
                    <div className='flex flex-col items-center'>
                        <Barcode value={itemId} width={1.2} height={60} fontSize={14} displayValue={true} />
                    </div>
                </div> */}
            {/* {fileSelected && <p>{fileSelected?.name}</p>} */}
            {filesSelected && filesSelected?.length > 0 &&
                <div className='flex flex-rows items-center flex-wrap justify-start rounded p-2 my-2'>
                    {filesSelected?.map((item: any, key: number) => {
                        return (
                            <div
                                key={key}
                                onClick={() => onDeleteImage(item)}
                                className={'relative flex flex-col items-center mr-4 mb-2 z-70'}>
                                <div data-qa={'delete-image'} className='absolute top-[-7px] right-[-7px] cursor-pointer z-50'>
                                    <img src={'/assets/shared/cancel.svg'} className='max-w-[15px] object-contain z-70' />
                                </div>
                                <div className='border border-[#000] p-1 z-0'>
                                    <img src={item && URL.createObjectURL(item)} className='max-w-[70px] object-contain' />
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
        </div >
    )
}

export default RevaluateItemImages;