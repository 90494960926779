import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import {
    tryToEnableDisableAdmin,
    tryToFindAllAdmins
} from '../../../../store/brokers/admin/cs-agents/CSAgentsSlice';
import CreateAdminModal from '../modals/CreateAdminModal';
import AdminRoleModal from '../modals/AdminRoleModal';
import AdminUsersTable from '../tables/AdminUsersTable';
import SearchWithButton from '../../../../shared/search-with-button';
import Button from '../../../../shared/button';
import Toast from '../../../../shared/toast';


const AdminUsers = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<Array<any>>([]);
    const [showToast, setShowToast] = useState<IToast | null>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [selectedEnabled, setSelectedEnabled] = useState<string>()
    const [search, setSearch] = useState<string>()
    const [pageAccess, setPageAccess] = useState<any>()
    const [showAdminModal, setShowAdminModal] = useState<boolean>(false);
    const [showRoleModal, setShowRoleModal] = useState<{ show: boolean, id: string, type: string, role?: any }>()
    const paginationData = useAppSelector((state) => state.csAgents.admins?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const rowsLoading = useAppSelector((state) => state.csAgents.adminsAreLoading);
    const state = useAppSelector((state) => state.csAgents);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.admins) {
            const rows =
                state.admins?.data?.elements && Array.isArray(state.admins?.data.elements)
                    ? state.admins?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.admins]);

    const onGettingFilters = (type?: string) => {
        let settingsFilters: any = {
            ...paginationState,
        }
        if (selectedEnabled) {
            const value = selectedEnabled === 'Yes'
            settingsFilters.data = {
                ...settingsFilters.data,
                enabled: value
            }
        }
        if (search) {
            settingsFilters = {
                ...settingsFilters,
                filters: {
                    search: search
                }
            }
        }
        return settingsFilters
    }

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    const getAdminsData = async () => {
        const settingsFilters = onGettingFilters()
        await dispatch(tryToFindAllAdmins(settingsFilters)).unwrap()
    }

    useEffect(() => {
        getAdminsData();
    }, [paginationState]);

    useEffect(() => {
        getAdminsData();
    }, []);

    const onAddNewAdmin = () => {
        setShowAdminModal(true)
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const clearAllFilters = () => {
        setSelectedEnabled(undefined)
        setSearch(undefined)
        onResetPaginationState()
    }

    const onSaveChanges = (type: string, message: string) => {
        setShowToast({ type, message });
    };

    const onEnableDisableAdmin = async (id: string, type: boolean) => {
        try {
            await dispatch(tryToEnableDisableAdmin({ id, data: { enabled: type } }))
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
    }

    const onOpenAdminRole = (userId: string, type: string, role?: any) => {
        setShowRoleModal({
            show: true,
            id: userId,
            type: type,
            role: role,
        })
    }

    const onSuccessfullySavedRole = (body: any) => {
        const updateRows = (rows && rows?.length > 0) && rows?.map((item: any) => {
            if (item?._id === body?.userId && body?.dashboard === 'inventory') {
                return {
                    ...item,
                    inventoryRole: body?.role || undefined
                }
            } else if (item?._id === body?.userId) {
                return {
                    ...item,
                    role: body?.role || undefined
                }
            }
            else {
                return item
            }
        })
        setRows(updateRows || [])
    }

    return (
        <div>
            <div className='min-w-full flex flex-row justify-end my-10'>
                <Button
                    label={'Add New Admin'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Admin']) && onAddNewAdmin()}
                    className={`${(!accessControl || pageAccess?.actions?.['Add New Admin']) ? 'btn-main' : 'btn-main-disable'} !col-span-2`}
                    dataQa={'add-new-admin-btn'}
                />
            </div>
            <div>
                <SearchWithButton
                    placeholder='Filter admins'
                    search={search}
                    mainContainerStyle={'!grid-cols-10'}
                    onChangeSearchValue={onChangeSearchValue}
                    buttonLabel={'Clear Filters'}
                    onClickButton={clearAllFilters}
                    buttonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end bg-transparent mt-1'}
                />
            </div>
            <div className='my-5 relative'>
                <AdminUsersTable
                    rows={rows}
                    rowsLoading={rowsLoading}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    onOpenAdminRole={onOpenAdminRole}
                    setPaginationState={setPaginationState}
                    onEnableDisableAdmin={onEnableDisableAdmin}
                />
            </div>
            {showAdminModal &&
                <CreateAdminModal
                    openModal={showAdminModal}
                    handleCloseModal={() => setShowAdminModal(false)}
                    onSaveChanges={onSaveChanges}
                />
            }
            {showRoleModal?.show &&
                <AdminRoleModal
                    openModal={showRoleModal?.show}
                    accessType={showRoleModal?.type || ''}
                    id={showRoleModal?.id || ''}
                    role={showRoleModal?.role}
                    onSuccessfullySaved={onSuccessfullySavedRole}
                    handleCloseModal={() => setShowRoleModal(undefined)}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    )
}

export default AdminUsers;