import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IUIAction } from '../../../interfaces/ui-actions/IUIAction';
import { tryToFetchSingleUIAction } from '../../../store/brokers/admin/ui-actions/uiActionsSlice';


const UIActionDetails = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [uiAction, setUIAction] = useState<IUIAction>()
    const state = useAppSelector((state) => state.uiActions);
    const uiActionLoading = useAppSelector((state) => state.uiActions?.uiActionIsLoading);

    useEffect(() => {
        if (state.uiActionDetails) {
            setUIAction(state.uiActionDetails)
        }
    }, [state.uiActionDetails]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleUIAction(id));
        }
    }, []);

    return (
        <div>
            <div>
                <p className='page-title'>Manage UI Action</p>
                {!uiActionLoading &&
                    <>
                        <p className='mt-3 font-medium'>Display Name: {uiAction?.displayName}</p>
                        <p className='mt-3 font-medium'>Name: {uiAction?.name}</p>
                    </>
                }
            </div>
            
        </div >
    )
}

export default UIActionDetails;