import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';


interface IChangeMarketStatusModal {
    openCatalogStatusModal: boolean;
    disabledType?: boolean;
    handleCloseModal: () => void;
    onApproveAction: () => void;
}

const ChangeCatalogStatusModal = ({
    openCatalogStatusModal,
    disabledType,
    handleCloseModal,
    onApproveAction,
}: IChangeMarketStatusModal) => {
    return (
        <Modal
            open={openCatalogStatusModal}
            onClose={handleCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>{!disabledType ? 'Disable Market' : 'Enable Market'}</p>
                </div>
                <p className='my-2'>{!disabledType ? 'Are you sure you want to disable the catalog?' : 'Are you sure you want to add to this catalog the market you clicked?'}</p>
                <div className='flex flex-row justify-end my-2'>
                    <Button
                        label='Continue'
                        dataQa={'continue'}
                        className={!disabledType ? 'btn-error' : 'btn-primary'}
                        onClickButton={onApproveAction}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default ChangeCatalogStatusModal;