import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    tryToDeleteChild,
    tryToFetchSingleSection
} from '../../../store/brokers/admin/sections/sectionsSlice';
import { ISectionChild } from '../../../interfaces/sections/ISection';
import PageDetailsTable from './tables/PageDetailsTable';
import PageDetailsModal from './modals/PageDetailsModal';
import AddActionModal from './modals/AddActionModal';
import DeleteModal from '../../../shared/delete-modal';
import Loading from '../../../shared/Loading';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const PageDetails = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const { id } = useParams();
    const [pageId, setPageId] = useState<string | null>(null);
    const [showPageDetailsModal, setShowPageDetailsModal] = useState<boolean>(false);
    const [showAddActionModal, setShowAddActionModal] = useState<boolean>(false);
    const [childId, setChildId] = useState<string | null>(null);
    const [rows, setRows] = useState<ISectionChild[]>([]);
    const [showDeleteChildModal, setShowDeleteChildModal] = useState<boolean>(false)
    const [showToast, setShowToast] = useState<any>({});
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const sectionDetails = useAppSelector((state) => state.sections.sectionDetails);
    const loadingSection = useAppSelector((state) => state?.sections?.sectionIsLoading);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Pages')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Page Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const getSection = () => {
        if (id) {
            dispatch(tryToFetchSingleSection(id));
        }
    };

    useEffect(() => {
        if (id) {
            setPageId(id);
        }
        getSection();
    }, [id]);

    useEffect(() => {
        if (sectionDetails?.children) {
            const rows =
                sectionDetails?.children &&
                    Array.isArray(sectionDetails.children)
                    ? sectionDetails?.children?.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [sectionDetails]);

    const onAddNewChild = async () => {
        if (id) {
            setPageId(id);
            setShowPageDetailsModal(true);
        }
    };

    const onEditChild = async (childId: string) => {
        setShowPageDetailsModal(true);
        setChildId(childId);
    };

    const onClosePageDetailsModal = () => {
        setChildId(null);
        setShowPageDetailsModal(false);
    };

    const onCloseAddActionModal = () => {
        setChildId(null);
        setShowAddActionModal(false);
    };
    const onSavePageDetailsChanges = () => {
        setShowPageDetailsModal(false);
        getSection();
    };

    const onAddAction = (childId: string) => {
        setChildId(childId);
        setShowAddActionModal(true);
        if (id) {
            setPageId(id);
        }
    };

    const onSaveActionsChanges = () => {
        setShowPageDetailsModal(false);
        getSection()
    };

    const onShowDeleteChildModal = (id: string) => {
        setChildId(id)
        setShowDeleteChildModal(true)
    }

    const onDeleteChild = async () => {
        try {
            if (childId) {
                await dispatch(tryToDeleteChild(childId)).unwrap();
                setShowToast({ type: 'success', message: 'Page was successfully deleted' });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setChildId(null)
        setShowDeleteChildModal(false)
        getSection()
    }

    const onCloseDeleteChildModal = () => {
        setChildId(null)
        setShowDeleteChildModal(false)
    }

    const onGoBack = () => {
        navigate('/pages')
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img alt='' src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Children</p>
                </div>
            </div>
            <div>
                <div className='flex flex-row gap-2 items-center ml-2'>
                    <p>{sectionDetails?.name} </p>
                    <div>
                        {sectionDetails?.icon
                            ? <img title={'Section Icon'} alt='Section Icon' src={sectionDetails?.icon} className={'h-[20px] object-contain'} />
                            : null
                        }
                    </div>
                </div>
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New Child'}
                    dataQa={'add-new-child'}
                    className={(!accessControl || pageAccess?.actions?.['Add New Child']) ? 'btn-main' : 'btn-main-disable'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Child']) && onAddNewChild()}
                />
            </div>
            {!loadingSection ?
                <div>
                    <PageDetailsTable
                        rows={rows}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        onAddAction={onAddAction}
                        onEditChild={onEditChild}
                        onDeleteChild={onShowDeleteChildModal}
                    />
                </div>
                : <div className='flex flex-row justify-center my-11'>
                    <Loading />
                </div>
            }
            <PageDetailsModal
                openPageDetailsModal={showPageDetailsModal}
                handleClosePageDetailsModal={onClosePageDetailsModal}
                onSaveChanges={onSavePageDetailsChanges}
                childId={childId || ''}
                pageId={pageId || ''}
            />
            <AddActionModal
                openAddActionModal={showAddActionModal}
                handleCloseAddActionModal={onCloseAddActionModal}
                onSaveChanges={onSaveActionsChanges}
                childId={childId || ''}
                pageId={pageId || ''}
            />
            <DeleteModal
                openDeleteModal={showDeleteChildModal}
                handleCloseDeleteModal={onCloseDeleteChildModal}
                onApproveDeletion={onDeleteChild}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    );
};

export default PageDetails;
