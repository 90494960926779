import React from 'react';
// import { createPopper } from '@popperjs/core';
import WhiteContainer from '../../../../shared/white-container';


interface IInfoCards {
    title: string;
    stats: number;
    key: number;
    color?: string;
    loadingData?: boolean;
    pathname?: string;
    infoText: string;
    risk?: string;
    onGoToItem: (pathname?: string) => void;
}

const InfoCards = ({
    title,
    stats,
    loadingData,
    key,
    color,
    infoText,
    risk,
}: IInfoCards) => {
    const [tooltipShow] = React.useState(false);
    // const btnRef = React.createRef() as React.MutableRefObject<HTMLButtonElement>;
    // const tooltipRef = React.createRef() as React.MutableRefObject<HTMLInputElement>;

    // const openLeftTooltip = () => {
    //     createPopper(btnRef.current, tooltipRef.current, {
    //         placement: 'bottom'
    //     });
    //     setTooltipShow(true);
    // };

    // const closeLeftTooltip = () => {
    //     setTooltipShow(false);
    // };

    return (
        <WhiteContainer key={key} type='rounded' containerStyle='!bg-gradient-to-r !from-[#fdf690] !to-[#fbd18f]'>
            <div className='flex flex-col min-h-[150px] justify-between'>
                <div className='grid grid-cols-6 gap-4'>
                    <div className='col-span-5 flex flex-row'>
                        {/* <img
                            onMouseEnter={openLeftTooltip}
                            onMouseLeave={closeLeftTooltip}
                            src={'/assets/shared/info-circle.svg'}
                            className={'w-[15px] object-contain mr-2 cursor-pointer'} /> */}
                        <div
                            className={(tooltipShow ? 'hidden' : 'hidden ') + 'absolute border-0 mt-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg'}
                            // ref={tooltipRef}
                        >
                            {/* <div className='text-[#202020] shadow px-4 py-2 rounded-md mt-3'>
                                {infoText}
                            </div> */}
                        </div>
                        <p className={`text-[#202020] font-semibold text-md`}>{title}</p>
                    </div>
                    <div className='flex flex-row items-center'>
                        {/* {pathname &&
                            <div onClick={() => onGoToItem()}>
                                <img src={'/assets/shared/maximise.svg'} className={'w-[16px] object-contain cursor-pointer'} />
                            </div>
                        } */}
                        {title === 'Average turnover time' && risk &&
                            <div>
                                <img src={`/assets/shared/${risk === 'medium' ? 'risk' : 'high-risk'}.svg`} className={'w-[20px] object-contain cursor-pointer'} />
                            </div>
                        }
                    </div>
                </div>
                <div className={'flex flex-row items-center'}>
                    {loadingData ?
                        <div className='my-2 max-w animate-pulse'>
                            <div className='h-[50px] bg-gray-200 rounded-full dark:bg-gray-200 w-[50px]'></div>
                        </div>
                        :
                        <p className={`${color || 'text-[#202020]'} font-bold text-6xl`}>{Number(stats || 0).toFixed(0)}</p>
                    }
                    {title === 'Average turnover time' &&
                        <p className={`${color || 'text-[#202020]'} font-bold text-6xl ml-1`}>hr</p>
                    }
                </div>
            </div>
        </WhiteContainer>
    )
}

export default InfoCards;