import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import Table from '../../../../shared/table';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToAcceptRevaluation, tryToAcceptRevaluationFromIMS, tryToRejectRevaluation, tryToRejectRevaluationFromIMS } from '../../../../store/brokers/partners/rma/partnerRMAsSlice';
import TableRow from '../details/TableRow';
interface IRMADetailsItemsTable {
    rows: any[];
    paginationData?: IPage;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    sectors: IAssetTemplate[];
    pageAccess: any;
    accessControl: any;
    markets: IAllMarkets[];
    RMADetails: any;
    getRMADetails: () => Promise<void>;
    rmaId: string;
}
const tableColumns = [
    'Category',
    'Name',
    'Item Status',
    'Item ID',
    'Item Code',
    'Initial Price',
    'Final Price',
    'Actions',
];

const RMADetailsItemsTable = ({
    rows,
    paginationData,
    rowsLoading,
    setPaginationState,
    sectors,
    pageAccess,
    accessControl,
    markets,
    RMADetails,
    getRMADetails,
    rmaId
}: IRMADetailsItemsTable) => {
    const dispatch = useAppDispatch()
    const getCategoryIcon = (category: string) => {
        const res = sectors.find((sector) => sector.name === category);
        return res?.disabledIcon;
    };
    const getStatusColor = (status: string | undefined) => {
        if (status) {
            if (['rejected_return', 'warehouse_rejected'].includes(status))
                return '#E50000';
            if (['review_return', 'revalued_return', 'revaluation'].includes(status))
                return '#1389E6';
            if (
                [
                    'accepted_return',
                    'broker_accepted_revaluation',
                    'approved_return',
                ].includes(status)
            )
                return '#00B67A';
            if (status === 'require_return') return '#F7A21E';
        }
        return '#8A8A8E';
    };

    async function onAcceptOffer(id?: string) {
        id && await dispatch(tryToAcceptRevaluation(id)).unwrap()
        getRMADetails()
    }

    async function onRejectOffer(id?: string) {
        id && await dispatch(tryToRejectRevaluation(id)).unwrap()
        getRMADetails()
    }

    async function onAcceptOfferFromIMS(id?: string) {
        id && await dispatch(tryToAcceptRevaluationFromIMS({ rmaId, id })).unwrap()
        getRMADetails()
    }
    async function onRejectOfferFromIMS(id?: string) {
        id && await dispatch(tryToRejectRevaluationFromIMS({ rmaId, id })).unwrap()
        getRMADetails()
    }

    return (
        <Table
            columns={tableColumns}
            containerStyle={'mt-0'}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            headStyle='!bg-[#202020] !text-[#F8F9FC]'>
            {rowsLoading ? (
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map(
                                    (x, j) => {
                                        return (
                                            <td
                                                key={j}
                                                className="td-1 !min-w-[100%] text-center py-5">
                                                <span></span>
                                            </td>
                                        );
                                    },
                                )}
                            </tr>
                        );
                    })}
                </>
            ) : (
                <>
                    {rows?.length > 0 &&
                        rows?.map((row: any, idx: number) => {
                            const findMarket =
                                markets &&
                                markets?.length > 0 &&
                                markets?.find(
                                    (market: any) =>
                                        market?.name ===
                                        row?.item?.exchangeDefinition?.warehouse
                                            ?.address?.country,
                                );
                            return (
                                <TableRow findMarket={findMarket} getCategoryIcon={getCategoryIcon} getStatusColor={getStatusColor} onAcceptOffer={onAcceptOffer} onRejectOffer={onRejectOffer} onAcceptOfferFromIMS={onAcceptOfferFromIMS} onRejectOfferFromIMS={onRejectOfferFromIMS} row={row} key={idx} />
                            );
                        })}
                </>
            )}
            <tr className="bg-white border-b hover:bg-gray-50">
                <td className="px-6 py-4"></td>
                <td className="px-6 py-4"></td>
                <td className="px-6 py-4"></td>
                <td className="px-6 py-4"></td>
                <td className='px-6 py-4 font-dm_sansbold text-right text-[#202020]'>Total</td>
                <td className="px-6 py-4">{RMADetails?.totalInitialPrice && markets && markets.length > 0 &&
                    CURRENCY_SYMBOLS?.[
                    `${markets?.find(
                        (market: any) =>
                            market?._id ===
                            RMADetails?.marketId,
                    )?.currency
                        ?.name || ''
                    }`
                    ]}
                    {RMADetails?.totalInitialPrice || '- -'}</td>
                <td className="px-6 py-4">
                    {typeof RMADetails?.totalFinalPrice === 'number' && markets && markets.length > 0 &&
                        CURRENCY_SYMBOLS?.[
                        `${markets?.find(
                            (market: any) =>
                                market?._id ===
                                RMADetails?.marketId,
                        )?.currency
                            ?.name || ''
                        }`
                        ]}
                    {typeof RMADetails?.totalFinalPrice === 'number' ? RMADetails?.totalFinalPrice : '- -'}
                </td>
                <td className={`px-6 py-4  ${RMADetails.actionsNeeded ? 'text-[#F7A21E]' : 'text-[#8A8A8E]'}`}><span className='max-w-20 flex'>{RMADetails?.actionsNeeded ? 'Actions needed' : RMADetails.status === 'created' && 'No Actions Needed Yet'}</span></td>

            </tr>
        </Table>
    );
};

export default RMADetailsItemsTable;
