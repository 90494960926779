import { createApi } from '@reduxjs/toolkit/query/react';
import { addressAxiosQuery } from '../../../../utils/axios/address.axios';
import { GOOGLE_API_KEY } from '../../../../config';

export const addressCheckDomAPI = createApi({
    reducerPath: 'addressCheck',
    baseQuery: addressAxiosQuery(),
    tagTypes: ['Posts'],
    endpoints: (builder) => ({
        getAddressDetails: builder.mutation<any, any>({
            query: (address: string) => ({
                url: `?address=${address}&key=${GOOGLE_API_KEY}`,
                method: 'GET',
            }),
        }),
        validatePostalCode: builder.mutation<any, any>({
            query: ({postalCode, country}) => ({
                url: `?components=postal_code:${postalCode}|country:${country}&key=${GOOGLE_API_KEY}`,
                method: 'GET',
            }),
        })
    }),
});

export const {
    useGetAddressDetailsMutation,
    useValidatePostalCodeMutation
} = addressCheckDomAPI;
