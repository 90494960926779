import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { classNames } from '../../utils/classNames';
import {
    extraNavigation,
    navigationAgents
} from '../../utils/navigation-constants/navigation-partner';
import { navigationAdmin } from '../../utils/navigation-constants/navigation-admin';
import { AdminGuard } from '../../guards/RolesGuard';
import { useGetCurrentUserQuery } from '../../store/user/userApi';
import { tryToFetchPartnerNavigation } from '../../store/brokers/admin/partners/partnersSlice';

const onGetUserRole = (): string | null => {
    const { data } = useGetCurrentUserQuery();
    return data?.groups?.[0] || localStorage.getItem('roles');
};

interface INavigation {
    search?: string
}

const Navigation = ({
    search
}: INavigation) => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const isAdmin = AdminGuard();
    const userRole = onGetUserRole() || '';
    const [navigationPartner, setNavigationPartner] = useState<any>()
    const [activeRoute, setActiveRoute] = useState<string>()
    const [openParent, setOpenParent] = useState<string>()
    const [navigationAdminData, setNavigationAdminData] = useState<any>()
    const [navigationPartnerExtra, setNavigationPartnerExtra] = useState<any>()
    const [adminTempNavigation, setAdminTempNavigation] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const navigationDetails = useAppSelector((state) => state.partners?.partnerNavigation);

    const onFormatNavData = () => {
        if (!isAdmin) {
            const formatNav = (navigationDetails && navigationDetails?.length > 0) ? navigationDetails?.filter((item: any) => !item?.extras)?.map((item: any) => ({ name: item?.name, href: item?.path, enabled: true, icon: <img alt={item?.name} src={item?.icon} /> }))
                : navigationAgents
            const formatExtras = (navigationDetails && navigationDetails?.length > 0) ? navigationDetails?.filter((item: any) => item?.extras)?.map((item: any) => ({ name: item?.name, href: item?.path, enabled: true, icon: <img alt={item?.name} src={item?.icon} /> }))
                : extraNavigation
            setNavigationPartnerExtra(formatExtras || undefined)
            setNavigationPartner(formatNav || undefined)
            setLoading(false)
        } else {
            const formatNav = (navigationDetails && navigationDetails?.length > 0) ? navigationDetails?.filter((item: any) => !item?.extras)?.map((item: any) => ({ name: item?.name, href: item?.path, enabled: true, icon: <img alt={item?.name} src={item?.icon} /> }))
                : navigationAdmin
            setLoading(false)
            setNavigationAdminData(formatNav)
        }
    }

    useEffect(() => {
        setActiveRoute(location.pathname)
    }, [location])

    useEffect(() => {
        if (isAdmin) {
            // (window as any).Intercom('shutdown');
            setLoading(true)
            if (search) {
                const filterNavItems = (navigationAdminData && navigationAdminData?.length > 0) && navigationAdminData?.filter((item: any) => (item?.name?.toLowerCase())?.includes(search.toLowerCase()))
                setAdminTempNavigation(filterNavItems)
            } else {
                setAdminTempNavigation(undefined)
            }
        }
    }, [search])

    useEffect(() => {
        if ((navigationDetails === null || navigationDetails) && (navigationAdminData || navigationPartner)) {
            setLoading(false)
        }
    }, [navigationAdminData, navigationPartner, navigationPartnerExtra, adminTempNavigation])

    useEffect(() => {
        onFormatNavData()
    }, [navigationDetails])

    const getNavData = async () => {
        await dispatch(tryToFetchPartnerNavigation())
    }

    useEffect(() => {
        setLoading(true)
        getNavData()
    }, [])

    return (
        <>
            <p className='mb-3 text-[#8a8a8e]'>Menu</p>
            {isAdmin ? (
                <>
                    {(!loading && navigationAdminData && navigationAdminData?.length > 0)
                        && _(
                            _(adminTempNavigation || navigationAdminData)
                                .groupBy('parent')
                                .value()
                        )?.map((children: any, parent: string) => {
                            if (parent === 'undefined') {
                                return children.map((item: any) => ({ parent: null, children: [item], child: item?.name }));
                            } else {
                                return {
                                    parent: parent || null,
                                    children: children.map((item: any) => item)
                                };
                            }
                        })
                            .flatten()
                            .sortBy((obj: any) => {
                                if (obj.parent === null) {
                                    const index = (adminTempNavigation || navigationAdminData).findIndex((item: any) => item?.name === obj?.child);
                                    return index !== -1 ? index : (adminTempNavigation || navigationAdminData).length;
                                } else {
                                    const index = (adminTempNavigation || navigationAdminData).findIndex((item: any) => item.parent === obj.parent);
                                    return index !== -1 ? index : (adminTempNavigation || navigationAdminData).length;
                                }
                            })
                            .value()?.map((value: any) => {
                                const isAnyChildActive = (value?.children && value?.children?.length > 0) && value?.children?.find((item: any) => item?.href === activeRoute)
                                const isOpenParent = openParent === value?.parent
                                return (
                                    <div key={value.parent}>
                                        {value?.parent
                                            && <div
                                                className='cursor-pointer flex flex-row justify-between items-center px-2 py-2 mb-2'
                                                onClick={() => value?.parent && setOpenParent(value?.parent === openParent ? undefined : value?.parent)}>
                                                <div className='flex flex-row items-center'>
                                                    {(value?.children && value?.children?.length > 0)
                                                        && <div className='flex mr-1 flex-shrink-0 h-5 w-5 justify-center items-center'>{value?.children?.[0]?.icon}</div>
                                                    }
                                                    <p className='text-base text-secondary !font-dm_sansregular group flex items-center px-2 font-medium rounded-md'>{value?.parent || ''}</p>
                                                </div>
                                                <img src={'/assets/navigation-icons/navigation-arrow.svg'} className={`object-contain w-3 transition ${(isAnyChildActive || isOpenParent) ? 'rotate-90' : 'rotate-0'}`} />
                                            </div>
                                        }
                                        {(isAnyChildActive || !value?.parent || isOpenParent) &&
                                            <div className={value?.parent ? 'ml-3 mb-2 transition' : ''}>
                                                {(value?.children && value?.children?.length > 0) && value?.children?.map((item: any) => {
                                                    return (
                                                        <NavLink
                                                            key={item.name}
                                                            data-qa={item?.dataQa}
                                                            to={item.href}
                                                            className={({ isActive }) =>
                                                                classNames(
                                                                    'text-base text-secondary group flex items-center px-2 py-2 font-medium rounded-md mb-2',
                                                                    item.enabled && isActive
                                                                        ? 'font-dm_sansbold !text-[#202020] bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !rounded-lg'
                                                                        : 'font-dm_sansregular',
                                                                )
                                                            }
                                                        >
                                                            {/* <item.icon className='mr-3 flex-shrink-0 h-6 w-6 text-indigo-300' aria-hidden='true' /> */}
                                                            <span className='flex mr-2 flex-shrink-0 h-6 w-6 justify-center items-center'>
                                                                {activeRoute === item?.href ?
                                                                    React.cloneElement(item.iconActive || item.icon, {
                                                                        width: 20,
                                                                        height: 20,
                                                                    })
                                                                    : React.cloneElement(item.icon, {
                                                                        width: 20,
                                                                        height: 20,
                                                                    })
                                                                }
                                                            </span>
                                                            {item.name}
                                                        </NavLink>
                                                    )
                                                }
                                                )
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            })
                    }
                </>
            ) : (
                <>
                    {!loading && navigationPartner?.filter(
                        (item: any) => !item?.access || item.access === userRole,
                    )
                        .map((item: any) => (
                            <NavLink
                                key={item.name}
                                to={item.href}
                                data-qa={`${(item?.name).toLowerCase().replaceAll(' ', '-')}`}
                                className={({ isActive }) =>
                                    classNames(
                                        'text-base text-secondary group flex items-center px-2 py-2 font-medium rounded-md mb-2',
                                        item.enabled && isActive
                                            ? 'font-dm_sansbold !text-[#202020] bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !rounded-lg'
                                            : 'font-dm_sansregular',
                                    )
                                }
                            >
                                {/* <item.icon className='mr-3 flex-shrink-0 h-6 w-6 text-indigo-300' aria-hidden='true' /> */}
                                <span className='flex mr-2 flex-shrink-0 h-6 w-6 justify-center items-center'>
                                    {activeRoute === item?.href ?
                                        React.cloneElement(item.iconActive || item.icon, {
                                            width: 20,
                                            height: 20,
                                        })
                                        : React.cloneElement(item.icon, {
                                            width: 20,
                                            height: 20,
                                        })
                                    }
                                </span>
                                {item.name}
                            </NavLink>
                        ))}
                    <div className='mt-14'>
                        {!loading && navigationPartnerExtra?.map((item: any) => (
                            <NavLink
                                key={item.name}
                                to={item.href}
                                data-qa={`${(item?.name).toLowerCase().replaceAll(' ', '-')}`}
                                className={({ isActive }) =>
                                    classNames(
                                        'text-base text-secondary group flex items-center px-2 py-2 font-medium rounded-md mb-2',
                                        item.enabled && isActive
                                            ? 'font-dm_sansbold !text-[#202020] bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !rounded-lg'
                                            : 'font-dm_sansregular',
                                    )
                                }
                            >
                                {/* <item.icon className='mr-3 flex-shrink-0 h-6 w-6 text-indigo-300' aria-hidden='true' /> */}
                                <span className='flex mr-2 flex-shrink-0 h-6 w-6 justify-center items-center'>
                                    {activeRoute === item?.href ?
                                        React.cloneElement(item.iconActive || item.icon, {
                                            width: 20,
                                            height: 20,
                                        })
                                        : React.cloneElement(item.icon, {
                                            width: 20,
                                            height: 20,
                                        })
                                    }
                                </span>
                                {item.name}
                            </NavLink>
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default Navigation;
