import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IRentalPricing } from '../../../interfaces/rental-pricing/IRentalPricing';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { IRentalPricingSettings } from '../../../interfaces/rental-pricing-settings/IRentalPricingSettings';
import {
    tryToDeleteRentalPricingSetting,
    tryToFetchRentalPricingSettings
} from '../../../store/brokers/admin/rental-pricing-settings/rentalPricingSettingsSlice';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import RentalPricingSettingsFilters from './details/RentalPricingSettingsFilters';
import RentalPricingSettingsTable from './tables/RentalPricingSettingsTable';
import RentalPricingSettingsModal from './modals/RentalPricingSettingsModal';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const RentalPricingSettings = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [rows, setRows] = useState<IRentalPricing[]>([]);
    const [showToast, setShowToast] = useState<any>({});
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [showRentalPricingSettingsModal, setShowRentalPricingSettingsModal] = useState<boolean>(false);
    const [showDeleteRentalPricingSettingsModal, setShowDeleteRentalPricingSettingsModal] = useState<boolean>(false);
    const [rentalPricingSettingId, setRentalPricingSettingId] = useState<string>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state.rentalPricingSettings.rentalPricingSettings?.data?.page);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state: any) => state.rentalPricingSettings);
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Rental Pricing')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Rental Pricing Settings')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        });
    };

    const getAllRentalPricingSettings = async () => {
        try {
            let data = {
                ...paginationState || {},
            }
            if (selectedSector) {
                data = {
                    ...data || {},
                    data: {
                        categoryId: selectedSector?.value
                    }
                }
            }
            if (selectedMarket) {
                data = {
                    ...data || {},
                    data: {
                        ...data?.data || {},
                        marketId: selectedMarket?.value
                    }
                }
            }
            await dispatch(tryToFetchRentalPricingSettings(data)).unwrap()
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (state.rentalPricingSettings) {
            const rows =
                state.rentalPricingSettings &&
                    Array.isArray(state.rentalPricingSettings?.data?.elements)
                    ? state.rentalPricingSettings?.data?.elements.map((r: IRentalPricingSettings) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state?.rentalPricingSettings]);

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const getMarketData = async () => {
        try {
            const marketResponse = await getAllMarkets(null).unwrap()
            const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id, displayName: market?.name }))
            setMarkets(formatMarkets || [])
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        setRentalPricingSettingId(undefined);
        getAllRentalPricingSettings();
    }, [paginationState]);

    useEffect(() => {
        dispatch(tryToFetchAllAssetsTemplates());
        getMarketData()
        getAllRentalPricingSettings()
    }, []);

    const onCloseRentalPricingSettingsModal = () => {
        setRentalPricingSettingId(undefined);
        setShowRentalPricingSettingsModal(false);
    };

    const onSaveRentalPricingSettingsChanges = (type: string, message: string) => {
        setShowToast({ type, message });
    };

    const onAddNewRentalPricingSetting = async () => {
        setRentalPricingSettingId(undefined);
        setShowRentalPricingSettingsModal(true);
    };

    const onEditRentalPricingSetting = async (id: string) => {
        setShowRentalPricingSettingsModal(true);
        setRentalPricingSettingId(id);
    };

    const onShowDeleteRentalPricingSettingsModal = (id: string) => {
        setRentalPricingSettingId(id);
        setShowDeleteRentalPricingSettingsModal(true);
    };

    const onCloseDeleteRentalPricingSettingsModal = () => {
        setRentalPricingSettingId(undefined);
        setShowDeleteRentalPricingSettingsModal(false);
    };

    const onDeleteRentalPricingSetting = async () => {
        try {
            if (rentalPricingSettingId) {
                await dispatch(tryToDeleteRentalPricingSetting(rentalPricingSettingId)).unwrap();
                setShowToast({
                    type: 'success',
                    message: `Rental Pricing Setting successfully deleted`,
                });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setRentalPricingSettingId(undefined);
        setShowDeleteRentalPricingSettingsModal(false);
    };

    const onSelectFilter = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'market':
                    setSelectedMarket(value)
                    break;
                case 'sector':
                    setSelectedSector(value)
                    break;
            }
        }
        onResetPaginationState()
    }

    const onGoBack = () => {
        navigate('/rental-pricing?type=pricing')
    }

    const onClearAllFilters = () => {
        setSelectedMarket(undefined)
        setSelectedSector(undefined)
        onResetPaginationState()
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Rental Price Settings</p>
                </div>
            </div>
            <div className='flex flex-row justify-end mb-2'>
                <Button
                    label={'Add New Rental Pricing Settings'}
                    dataQa={'add-new-rental-pricing-settings'}
                    className={(!accessControl || pageAccess?.actions?.['Add New Rental Pricing Settings']) ? 'btn-main' : 'btn-main-disable'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Rental Pricing Settings']) && onAddNewRentalPricingSetting()}
                />
            </div>
            <div>
                <RentalPricingSettingsFilters
                    sectors={sectors}
                    markets={markets}
                    onSelectFilter={onSelectFilter}
                    selectedSector={selectedSector}
                    selectedMarket={selectedMarket}
                />
            </div>
            <div className='flex flex-row justify-end my-3'>
                <Button
                    label={'Clear all filters'}
                    dataQa={'clear-all-filters'}
                    className={'btn-primary-text-underline flex flex-row justify-end bg-transparent mr-2'}
                    onClickButton={onClearAllFilters}
                />
            </div>
            <div>
                <RentalPricingSettingsTable
                    rows={rows}
                    loading={false}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    setPaginationState={setPaginationState}
                    onEditRentalPricingSetting={onEditRentalPricingSetting}
                    onDeleteRentalPricingSetting={onShowDeleteRentalPricingSettingsModal} />
            </div>
            <RentalPricingSettingsModal
                openRentalPricingSettingsModal={showRentalPricingSettingsModal}
                handleCloseRentalPricingSettingsModal={onCloseRentalPricingSettingsModal}
                onSaveChanges={onSaveRentalPricingSettingsChanges}
                rentalPricingSettingId={rentalPricingSettingId}
                sectors={sectors}
                markets={markets}
            />
            <DeleteModal
                openDeleteModal={showDeleteRentalPricingSettingsModal}
                handleCloseDeleteModal={onCloseDeleteRentalPricingSettingsModal}
                onApproveDeletion={onDeleteRentalPricingSetting}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    );
};

export default RentalPricingSettings;