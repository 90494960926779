import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IPayServices } from '../../../interfaces/pay-services/IPayServices';
import { tryToDeletePayService, tryToFetchPayServices } from '../../../store/brokers/admin/pay-services/payServicesSlice';
import PayServiceActionsModal from './modals/PayServiceActionsModal';
import PayServicesTable from './tables/PayServicesTable';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';


const PayServices = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IPayServices[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [payServiceItemId, setPayServiceItemId] = useState<string | null>();
    const [showPayServiceModal, setShowPayServiceModal] = useState<boolean>(false);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.payServices.payServices?.data?.page);
    const rowsLoading = useAppSelector((state) => state.payServices.payServicesAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.payServices);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Pay Services')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.payServices) {
            const rows =
                state.payServices?.data?.elements &&
                    Array.isArray(state.payServices?.data.elements)
                    ? state.payServices?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.payServices]);

    const getPayServicesData = async () => {
        const formatData: any = {
            ...paginationState,
        }
        await dispatch(tryToFetchPayServices(formatData));
    }

    useEffect(() => {
        getPayServicesData()
    }, [paginationState]);

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const onDeletePayService = async () => {
        try {
            await dispatch(tryToDeletePayService(payServiceItemId || ''));
            setShowToast({
                type: 'success',
                message: `Pay Service successfully deleted`,
            });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setPayServiceItemId(undefined);
        setShowDeleteModal(false);
    };

    const onAddPayService = (id?: string) => {
        if (id) {
            setPayServiceItemId(id);
        }
        setShowPayServiceModal(true);
    };

    const onClosePayServiceModal = () => {
        setPayServiceItemId(null);
        setShowPayServiceModal(false);
    };

    const onSavePayServiceChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        getPayServicesData()
    };

    const onDeletePayServiceRequest = (id: string) => {
        setShowDeleteModal(true);
        setPayServiceItemId(id);
    };

    return (
        <div>
            <div>
                <p className='page-title'>Services</p>
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New Pay Service'}
                    dataQa={'add-new-pay-service'}
                    className={(!accessControl || pageAccess?.actions?.['Add New Pay Service']) ? 'btn-main' : 'btn-main-disable'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Pay Service']) && onAddPayService()}
                />
            </div>
            <div className='my-5 relativ'>
                {(rows?.length > 0 || rowsLoading) ?
                    <PayServicesTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onDeletePayService={onDeletePayServiceRequest}
                        onEditPayService={onAddPayService}
                    />
                    : <EmptyContainer
                        text={'No Pay Service added yet.'}
                        showImage={true}
                    />
                }
            </div>
            <PayServiceActionsModal
                payServiceItemId={payServiceItemId}
                openActionsModal={showPayServiceModal}
                handleCloseModal={onClosePayServiceModal}
                onSaveChanges={onSavePayServiceChanges}
            />
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeletePayService}
            />
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
        </div>
    );
};

export default PayServices;