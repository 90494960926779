import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import brokersAxios from '../../../../utils/axios/assets.axios';
import { IEditSection } from '../../../../interfaces/sections/IEditSection';
import {
    ISection,
    ISectionChild,
} from '../../../../interfaces/sections/ISection';
import { IEditChild } from '../../../../interfaces/sections/IEditChild';
import { PaginatedSections } from '../../../../interfaces/sections/paginatedStore.type';

const tryToFetchAllSections = (filters?: any): Promise<AxiosResponse> => {
    const url = `${USERS_API_URL}/sections/`;
    return filters ? brokersAxios.put(url, { filters }) : brokersAxios.put(url);
};

const tryToFetchAllSectionsPaginated = (
    pageNumber: number,
    pageSize: number,
    filters?: any,
): Promise<AxiosResponse<PaginatedSections>> => {
    const url = `${USERS_API_URL}/sections/paginated?page=${pageNumber}&size=${pageSize}`
    return brokersAxios.put<PaginatedSections>(url, { filters });
};

const tryToEditSection = (id: string, payload: IEditSection) => {
    return brokersAxios.put(`${USERS_API_URL}/sections/${id}/edit`, payload);
};

const tryToCreateSection = (data: ISection): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/sections`, data);
};

const tryToDeleteSection = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/sections/${id}`);
};

const tryToFetchSingleSection = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/sections/${id}`);
};

const tryToCreateChild = (id: string, payload: ISectionChild) => {
    return brokersAxios.post(`${USERS_API_URL}/sections/${id}/child`, payload);
};

const tryToEnableDisableSection = (id: string, status: boolean): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/sections/${id}/active/${status}`);
};

const tryToEditChild = (id: string, payload: IEditChild) => {
    return brokersAxios.put(`${USERS_API_URL}/sections/child/${id}`, payload);
};

const tryToEditActions = (id: string, payload: { [key: string]: string }) => {
    return brokersAxios.put(`${USERS_API_URL}/sections/child/${id}/actions`, payload);
};

const tryToDeleteChild = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/sections/child/${id}`);
};

export const SectionsService = {
    tryToFetchAllSections,
    tryToEditSection,
    tryToCreateSection,
    tryToDeleteSection,
    tryToFetchSingleSection,
    tryToCreateChild,
    tryToEditChild,
    tryToEditActions,
    tryToDeleteChild,
    tryToFetchAllSectionsPaginated,
    tryToEnableDisableSection
};
