import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const ProductInfoContainer = () => {
    return (
        <WhiteContainer containerStyle='!h-auto mt-2 !rounded'>
            <div>
                <p className='font-bold text-[20px] mb-10'>Purchase Summary</p>
                <div className='grid grid-cols-2 gap-3 my-6 max-w-sm animate-pulse'>
                    <div className='h-5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                    <div className='h-6 bg-gray-200 rounded-full justify-self-end dark:bg-gray-200 w-[80%] mb-1'></div>
                </div>
                <div className='grid grid-cols-2 gap-3 my-2 max-w-sm animate-pulse'>
                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                    <div className='h-2.5 bg-gray-200 justify-self-end rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                </div>
                <div className='grid grid-cols-2 gap-3 my-2 max-w-sm animate-pulse'>
                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                    <div className='h-2.5 bg-gray-200 justify-self-end rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                </div>
                <div className='mb-5 mt-3 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                <div className='grid grid-cols-2 gap-3 my-2 max-w-sm animate-pulse'>
                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                    <div className='h-2.5 bg-gray-200 justify-self-end rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                </div>
            </div>
        </WhiteContainer>
    )
}

export default ProductInfoContainer;