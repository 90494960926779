import React from 'react';
import moment from 'moment';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPricingHistory } from '../../../../interfaces/pricing-history/IPricingHistory';
import Table from '../../../../shared/table';
import { IPartners } from '../../../../interfaces/partners/IPartners';

const tableColumns = ['Id', 'Date', 'User', 'Broker', 'Type', 'Import Status', 'Actions']

interface IPricingHistoryTable {
    rows: IPricingHistory[];
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    partners?: Array<IPartners>;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeDetails: (id: string) => void;
}

const PricingHistoryTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    partners,
    setPaginationState,
    onSeeDetails
}: IPricingHistoryTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rows?.length > 0 && rows?.map((row: IPricingHistory, idx: number) => {
                const findBroker: any = (partners && partners?.length>0) && partners?.find((item: IPartners)=> item?._id === row?.partnerId)
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?._id}</td>
                        <td className='px-6 py-4'>{row?.updatedAt && moment(row?.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                        <td className='px-6 py-4'>{row?.user}</td>
                        <td className='px-6 py-4'>{findBroker?.label || ''}</td>
                        <td className='px-6 py-4'>{row?.commandType === 'ImportOffersCommand' || row?.commandType === 'ImportOffersApiIntegrationCommand' ? 'IMPORT' : row?.commandType === 'ChangeOffersPricingCommand' ? 'CHANGE PRICE' : row?.commandType}</td>
                        <td className='px-6 py-4'>{row?.type === 'SUCCESS' ? 'SUCCEEDED' : row?.type === 'FAIL' ? 'FAILED' : row?.type}</td>
                        <td className='px-2 py-2 min-w-[150px]'>
                            {row?.type !== 'SUCCESS' && (!accessControl || actions?.['Download CSV']) ?
                                <div className={`btn-main !min-w-[100%] text-center ${row?.type === 'SUCCESS' ? 'opacity-70 !cursor-not-allowed' : 'cursor-pointer'}`}>
                                    <a
                                        data-qa={'download-cvs'}
                                        href={`${row?.type === 'SUCCESS' ? '' : row?.csv}`}
                                        rel='noreferrer'
                                        target={'_blank'}>
                                        Download CSV
                                    </a>
                                </div>
                                :
                                <div className={`btn-main opacity-70 !cursor-not-allowed text-center`}>
                                    Download CSV
                                </div>
                            }
                        </td>
                        <td className='px-6 py-4'>
                            <div className='flex flex-row items-center'>
                                <div data-qa={'see-details'} onClick={() => ((!accessControl || actions?.['See Details']) && row?._id) && onSeeDetails(row?._id)}>
                                    <img title={'View Details'} alt='pricing history details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Details']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default PricingHistoryTable;