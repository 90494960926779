import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToDownloadPurchases,
    tryToFetchPurchases
} from '../../../../store/brokers/admin/reports/reportsServiceSlice';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import { tryToFetchAllAssetsTemplates } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import DownloadDataModal from '../modals/DownloadDataModal';
import PurchasesTable from '../tables/PurchasesTable';
import TableFilters from '../details/TableFilters';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';


const PurchasesCategory = () => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<any>([]);
    const [search, setSearch] = useState<string>();
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [markets, setMarkets] = useState<any>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [dateFiltersValues, setDateFiltersValues] = useState<any>();
    const [templateData, setTemplateData] = useState<any>();
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false)
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [downloadError, setDownloadError] = useState<string>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const paginationData = useAppSelector((state) => state.reports.purchases?.data?.page);
    const state = useAppSelector((state) => state.reports);
    const loading = useAppSelector((state) => state?.reports?.purchasesAreLoading)
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const [getAllMarkets] = useGetAllMarketsMutation()

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, value: r?._id, label: r?.displayName }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const getPartnerAndMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        getPartnerAndMarketData()
        if (!stateAssets?.assetTemplates) {
            dispatch(tryToFetchAllAssetsTemplates());
        }
    }, [])

    useEffect(() => {
        if (state.purchases) {
            const rows =
                state.purchases?.data?.elements &&
                    Array.isArray(state.purchases?.data.elements)
                    ? state.purchases?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.purchases]);

    const getPurchaseData = async () => {
        let formatBody: any = {
            ...paginationState,
        }
        if (selectedSector) {
            formatBody = {
                ...formatBody || {},
                data: {
                    ...formatBody?.data || {},
                    category: selectedSector?.name || ''
                }
            }
        }
        if (selectedMarket) {
            formatBody = {
                ...formatBody || {},
                data: {
                    ...formatBody?.data || {},
                    market: selectedMarket?.name || ''
                }

            }
        }
        if (dateFiltersValues) {
            formatBody = {
                ...formatBody || {},
                data: {
                    ...formatBody?.data || {},
                    dateRange: dateFiltersValues
                }
            }
        }
        if (search) {
            formatBody = {
                ...formatBody || {},
                filters: search
            }
        }
        await dispatch(tryToFetchPurchases(formatBody));
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    useEffect(() => {
        getPurchaseData()
    }, [paginationState]);

    const onChangeDateFilters = (value: string | null | undefined, type: string) => {
        setDateFiltersValues({
            ...dateFiltersValues,
            [type]: value || undefined
        })
        onResetPaginationState()
    }

    const onChangeSelectedFilter = (e: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'sector':
                    setSelectedSector(e);
                    break;
                case 'market':
                    setSelectedMarket(e);
                    break;
            }
        }
        onResetPaginationState()
    }

    const onClearAllFilters = () => {
        setSelectedMarket(undefined)
        setSelectedSector(undefined)
        setDateFiltersValues(undefined)
        onResetPaginationState()
    }

    const onToggleDownloadModal = () => {
        setShowDownloadModal(!showDownloadModal)
    }

    const onDownloadData = async (filters?: any) => {
        setLoadingDownload(true)
        try {
            const response: any = await dispatch(tryToDownloadPurchases(filters)).unwrap()
            setTemplateData(response || [])
            onToggleDownloadModal()
        } catch (err) {
            setDownloadError(`${err}`)
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (templateData && loadingDownload) {
            csvLink.current.link.click()
        }
        setLoadingDownload(false)
    }, [templateData])

    return (
        <div>
            <TableFilters
                markets={markets}
                sectors={sectors}
                selectedMarket={selectedMarket}
                selectedSector={selectedSector}
                dateFiltersValues={dateFiltersValues}
                onChangeDateFilters={onChangeDateFilters}
                onChangeSelectedFilter={onChangeSelectedFilter}
            />
            <div className='flex flex-row justify-end flex-wrap'>
                <Button
                    label={'Clear All Filters'}
                    dataQa={'clear-all-filters'}
                    className='btn-primary-text-underline min-w-auto !mt-0 flex flex-row justify-end bg-transparent mr-2 mb-1'
                    onClickButton={onClearAllFilters}
                />
                <Button
                    label={'Download Purchases'}
                    dataQa={'download-purchases'}
                    className='btn-main !min-w-[150px] !mt-0 mr-2 mb-1'
                    onClickButton={onToggleDownloadModal}
                />
            </div>
            <CSVLink
                ref={csvLink}
                data={templateData?.filter((item: any, idx: number, arr: any) => { return idx > 0 }) || []}
                headers={templateData?.[0] || []}
                className={'none'}
                target={'_blank'}
                filename={`Purchases.csv`}
            >
            </CSVLink>
            <div>
                <div className={`min-w-[100px]`}>
                    <Input
                        placeholder={'Filter Table'}
                        dataQa={'filter-table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                <PurchasesTable
                    rows={rows}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                    rowsLoading={loading}
                />
            </div>
            <DownloadDataModal
                sectors={sectors}
                markets={markets}
                loadingDownload={loadingDownload}
                openDownloadModal={showDownloadModal}
                downloadError={downloadError}
                setDownloadError={setDownloadError}
                handleCloseDownloadModal={onToggleDownloadModal}
                setLoadingDownload={setLoadingDownload}
                onDownloadData={onDownloadData}
            />
        </div>
    )
}

export default PurchasesCategory;