import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { AdminGuard } from '../../../guards/RolesGuard';
import {
    tryToAddRole,
    tryToEditRole,
    tryToFetchAllSections,
    tryToFetchSingleRole
} from '../../../store/brokers/partners/user-access-control/userAccessSlice';
import { ROLE_TYPES_ITEMS } from '../../../utils/constants/user-access-admin-roles';
import Input from '../../../shared/input';
import Error from '../../../shared/error';
import Button from '../../../shared/button';
import Loading from '../../../shared/Loading';
import WhiteContainer from '../../../shared/white-container';
import SelectCheckbox from '../../../shared/select-checkbox';


const ManageRoles = () => {
    const isAdmin = AdminGuard()
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [sectionsData, setSections] = useState<any>()
    const [roleName, setRoleName] = useState<string>()
    const [roleType, setRoleType] = useState<any>()
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)
    const [submitError, setSubmitError] = useState<string>()
    const [activeSections, setActiveSections] = useState<any>()
    const [fullAccess, setFullAccess] = useState<boolean>(false)
    const [selectedToAccess, setSelectedToAccess] = useState<any>()
    const [fullSectionAccess, setFullSectionAccess] = useState<any>()
    const role = useAppSelector((state) => state?.userAccess?.roleDetails);
    const roleLoading = useAppSelector((state) => state?.userAccess?.roleIsLoading);

    const onGettingSectionsData = async () => {
        const response = await dispatch(tryToFetchAllSections(roleType?.value || 'agent')).unwrap()
        setSections(response?.data || [])
        if (response?.data && response?.data?.[0] && response?.data?.[0]?.name) {
            setActiveSections({
                ...activeSections || {},
                [response?.data?.[0]?.name]: true
            })
        }
    }

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleRole(id));
            if (!isAdmin && !sectionsData) {
                onGettingSectionsData()
            }
        }
    }, [id]);

    useEffect(() => {
        if (role && id) {
            setRoleName(role?.name)
            setSelectedToAccess(structuredClone(role?.sections))
            const findRoleSelected = ROLE_TYPES_ITEMS?.find((item: any) => item?.value === role?.type)
            setRoleType(findRoleSelected)
        }
        else {
            setRoleName(undefined)
            setSelectedToAccess(undefined)
        }
    }, [role, id])

    useEffect(() => {
        if (roleType) {
            onGettingSectionsData()
        }
    }, [roleType])

    useEffect(() => {
        onGettingSectionsData()
    }, [])

    useEffect(() => {
        if (isAdmin) {
            setRoleType(ROLE_TYPES_ITEMS?.[0])
        }
    }, [isAdmin])

    const onGoBack = () => {
        navigate(-1);
    };

    const onClickFullAccess = () => {
        setFullAccess(!fullAccess)
        if (!fullAccess) {
            setSelectedToAccess(structuredClone(sectionsData))
        } else {
            setSelectedToAccess(undefined)
        }
    }

    const onClickSelectFullSection = (section: any) => {
        const findSection = fullSectionAccess && fullSectionAccess?.[section]
        if (findSection) {
            const filterOthers = selectedToAccess && selectedToAccess?.length > 0 && selectedToAccess?.filter((item: any) => item?.name !== section)
            setSelectedToAccess([
                ...filterOthers || []
            ])
        } else {
            const findSectionData = sectionsData && sectionsData?.length > 0 && sectionsData?.find((item: any) => item?.name === section)
            const cloneFindSectionData = structuredClone(findSectionData)
            const filterSelectedToAccess = selectedToAccess && selectedToAccess?.length > 0 && selectedToAccess?.filter((item: any) => item?.name !== section)
            setSelectedToAccess([
                ...filterSelectedToAccess || [],
                cloneFindSectionData || {},
            ])
        }
        setFullSectionAccess({
            ...fullSectionAccess || {},
            [section]: !findSection
        })
    }

    const onClickPageAccess = (section: any, page: any) => {
        const findSection = selectedToAccess && selectedToAccess?.length > 0 && selectedToAccess?.find((item: any) => item?.name === section)
        const findSectionData = sectionsData && sectionsData?.length > 0 && sectionsData?.find((item: any) => item?.name === section)
        const findPageData = findSectionData && findSectionData?.children?.length > 0 && findSectionData?.children?.find((item: any) => item?.name === page)
        if (findSection) {
            const findPage = findSection && findSection?.children?.length > 0 && findSection?.children?.find((item: any) => item?.name === page)
            const filterOthers = selectedToAccess && selectedToAccess?.length > 0 && selectedToAccess?.filter((item: any) => item?.name !== section)
            const filterOtherPages = findSection && findSection?.children?.length > 0 && findSection?.children?.filter((item: any) => item?.name !== page)
            if (findPage && (!filterOtherPages || filterOtherPages?.length <= 0)) {
                setSelectedToAccess([
                    ...filterOthers || [],
                ])
            } else {
                setSelectedToAccess([
                    ...filterOthers || [],
                    {
                        ...findSection,
                        children: findPage ? filterOtherPages
                            : [
                                ...filterOtherPages,
                                {
                                    ...findPageData || {},
                                    actions: {}
                                }
                            ]
                    }
                ])
            }
            setFullSectionAccess({
                ...fullSectionAccess || {},
                [section]: false
            })
        } else {
            setSelectedToAccess([
                ...selectedToAccess || [],
                {
                    ...findSectionData || {},
                    children: [
                        {
                            ...findPageData || {},
                            actions: {}
                        }
                    ]
                }
            ])
        }
    }

    const onClickActionAccess = (section: any, page: any, action: any) => {
        const findSection = selectedToAccess && selectedToAccess?.length > 0 && selectedToAccess?.find((item: any) => item?.name === section)
        const findSectionDataCopy = sectionsData && sectionsData?.length > 0 && sectionsData?.find((item: any) => item?.name === section)
        const findSectionData = structuredClone(findSectionDataCopy)
        const findPageData = findSectionData && findSectionData?.children?.length > 0 && findSectionData?.children?.find((item: any) => item?.name === page)
        const findActionData = findPageData && findPageData?.actions && findPageData?.actions?.[action]
        if (findSection) {
            const findPage = findSection && findSection?.children?.length > 0 && findSection?.children?.find((item: any) => item?.name === page)
            const filterOtherPages = findSection && findSection?.children?.length > 0 && findSection?.children?.filter((item: any) => item?.name !== page)
            const filterOthers = selectedToAccess && selectedToAccess?.length > 0 && selectedToAccess?.filter((item: any) => item?.name !== section)

            if (findPage) {
                const findAction = findPage && findPage?.actions && findPage?.actions?.[action]
                if (findAction) {
                    if (findPage && findPage?.actions?.[action] && findPage?.actions?.[action]) {
                        delete findPage?.actions?.[action]
                    }
                    setSelectedToAccess([
                        ...filterOthers || [],
                        {
                            ...findSectionData || {},
                            children: [
                                ...filterOtherPages || [],
                                {
                                    ...findPage || {},
                                }
                            ]
                        }
                    ])
                } else {
                    setSelectedToAccess([
                        ...filterOthers || [],
                        {
                            ...findSectionData || {},
                            children: [
                                ...filterOtherPages || [],
                                {
                                    ...findPage || {},
                                    actions: {
                                        ...findPage?.actions || {},
                                        [action]: findActionData
                                    }
                                }
                            ]
                        }
                    ])
                }
            } else {
                setSelectedToAccess([
                    ...filterOthers || [],
                    {
                        ...findSectionData || {},
                        children: [
                            ...filterOtherPages || [],
                            {
                                ...findPageData || {},
                                actions: {
                                    [action]: findActionData
                                }
                            }
                        ]
                    }
                ])
            }
            setFullSectionAccess({
                ...fullSectionAccess || {},
                [section]: false
            })
        } else {
            setSelectedToAccess([
                ...selectedToAccess || [],
                {
                    ...findSectionData || {},
                    children: [
                        {
                            ...findPageData || {},
                            actions: {
                                [action]: findActionData
                            }
                        }
                    ]
                }
            ])
        }
    }

    const onSetActiveSection = (name: string) => {
        setActiveSections({
            ...activeSections || {},
            [name]: !activeSections?.[name]
        })
    }

    const onChangeRoleName = (value: string | null | undefined, type?: string) => {
        setRoleName(value || undefined)
    }

    const onSubmitRole = async () => {
        if (!roleName || roleName === '') {
            setSubmitError('Please write Role name!')
            return;
        }
        setSubmitLoading(true)
        setSubmitError(undefined)
        try {
            const data: any = {
                name: roleName,
                type: roleType?.value,
                sections: selectedToAccess
            }
            if (id) {
                await dispatch(tryToEditRole({ id, data })).unwrap()
            } else {
                await dispatch(tryToAddRole(data)).unwrap()
            }
            setSubmitLoading(false)
            setRoleName(undefined)
            setFullAccess(false)
            setFullSectionAccess(undefined)
            navigate('/user-access-control')
        } catch (err) {
            setSubmitError(`${err}`)
            setSubmitLoading(false)
        }
    }

    const onChangeRoleTypeSelected = (e: any, type?: string) => {
        setRoleType(e)
    };

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>{id ? 'Edit Role' : 'Create Role'}</p>
                </div>
            </div>
            {roleLoading ?
                <div className='my-3 flex flex-row justify-center items-center'>
                    <Loading />
                </div>
                : <div>
                    <WhiteContainer type={'rounded'} containerStyle='!p-0'>
                        <div className='py-5'>
                            <div className='grid grid-cols-2 gap-4 px-4'>
                                <p className='text-[#484e5e] font-bold text-[19px]'>Actions</p>
                            </div>
                            <div className='my-3 grid sm:grid-cols-1 lg:grid-cols-2 gap-4 px-4'>
                                <div></div>
                                <div>
                                    <Input
                                        placeholder='Role Name'
                                        dataQa={'role-name'}
                                        containerStyle={'max-w-[90%] w-auto'}
                                        onChangeInput={onChangeRoleName}
                                        inputUniqueName={'name'}
                                        inputStyle={'min-w-[350px] max-w-[350px]'}
                                        showValue={true}
                                        inputValue={roleName}
                                    />
                                </div>
                            </div>
                            <div className='my-3 grid grid-cols-2 gap-4 px-4'>
                                <div>
                                    <p>Role Type</p>
                                </div>
                                <div>
                                    <SelectCheckbox
                                        key={1}
                                        placeholder='Select Role Type'
                                        dataQa={'select-role-type'}
                                        containerStyle='mb-1'
                                        selectedOption={roleType}
                                        onChangeSelectedOption={onChangeRoleTypeSelected}
                                        options={isAdmin ? ROLE_TYPES_ITEMS?.filter((item: any) => !(['inventory-agent', 'agent']?.includes(item?.value))) || [] : ROLE_TYPES_ITEMS?.filter((item: any) => item?.value === 'agent' || item?.value === 'inventory-agent') || []}
                                    />
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-4 mb-3 px-4'>
                                <p className='font-semibold'>Full Access Rights</p>
                                <div className='flex items-center mr-2'>
                                    <input
                                        onChange={() => onClickFullAccess()}
                                        checked={fullAccess}
                                        id='checkbox-all'
                                        data-qa={'checkbox'}
                                        type='checkbox'
                                        className='w-5 h-5 !outline-none text-blue-600 bg-white-100 border-white-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                    />
                                    <label htmlFor='checkbox-all' className='sr-only'></label>
                                </div>
                            </div>
                            {(sectionsData && sectionsData?.length > 0) &&
                                sectionsData?.map((item: any, index: number) => {
                                    const active = activeSections?.[item?.name]
                                    const findIfSectionActive = selectedToAccess && selectedToAccess?.length > 0 && selectedToAccess?.find((section: any) => section?.name === item?.name)
                                    const findIfSelectedAll = fullSectionAccess && fullSectionAccess?.[item?.name]
                                    return (
                                        <div
                                            key={index}
                                            className={`${active ? 'h-auto' : 'h-[54px]'} transition-all delay-350 duration-500 overflow-hidden w-full mb-4`}>
                                            <div
                                                data-qa={'arrow-down-button'}
                                                onClick={() => onSetActiveSection(item?.name || '')}
                                                className='h-[40px] bg-gradient-to-tl from-[#FCEE21] to-[#F7931E] py-2 px-4 my-2 rounded flex flex-row justify-between cursor-pointer'>
                                                <p className='text-[#202020] font-semibold'>{item?.name}</p>
                                                <img src={'/assets/shared/arrow-down-white.svg'} className='w-[15px] object-contain' />
                                            </div>
                                            <div className='grid grid-cols-2 gap-4 px-4'>
                                                <p className='font-semibold'>Select All</p>
                                                <div
                                                    className='flex items-center mr-2 cursor-pointer'
                                                    onClick={() => onClickSelectFullSection(item?.name)}>
                                                    <input
                                                        onChange={() => onClickSelectFullSection(item?.name)}
                                                        checked={findIfSelectedAll}
                                                        id='checkbox-all'
                                                        type='checkbox'
                                                        data-qa={'checkbox'}
                                                        className='w-5 h-5 !outline-none text-blue-600 bg-white-100 border-white-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                                    />
                                                    <label htmlFor='checkbox-all' className='sr-only'></label>
                                                </div>
                                            </div>
                                            <div>
                                                {item?.children && item?.children?.length > 0 && item?.children?.map((child: any, idx: number) => {
                                                    const findActionKeys = child?.actions && Object.keys(child?.actions)
                                                    const findIfChildActive = findIfSectionActive && findIfSectionActive?.children && findIfSectionActive?.children?.length > 0 && findIfSectionActive?.children?.find((active: any) => active?.name === child?.name)
                                                    return (
                                                        <div key={idx}>
                                                            <div className='mb-2 mt-4 grid grid-cols-2 gap-4 cursor-pointer' onClick={() => onClickPageAccess(item?.name || '', child?.name || '')}>
                                                                <p className='pl-4'>{child?.name === '' ? `${item?.name} - Page` : child?.name}</p>
                                                                <div className='flex items-center mr-2'>
                                                                    <input
                                                                        onChange={() => onClickPageAccess(item?.name || '', child?.name || '')}
                                                                        checked={!!findIfChildActive}
                                                                        id='checkbox-all'
                                                                        data-qa={'checkbox'}
                                                                        type='checkbox'
                                                                        className='w-5 h-5 !outline-none text-blue-600 bg-white-100 border-white-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                                                    />
                                                                    <label htmlFor='checkbox-all' className='sr-only'></label>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {findActionKeys && findActionKeys?.length > 0
                                                                    && findActionKeys?.map((obj: any, id: number) => {
                                                                        const findIfActionActive = findIfChildActive && findIfChildActive?.actions && findIfChildActive?.actions?.[obj]
                                                                        return (
                                                                            <div key={id} className='grid grid-cols-2 gap-4 px-4 my-2' onClick={() => onClickActionAccess(item?.name, child?.name, obj?.name)}>
                                                                                <p className='pl-4'>{obj}</p>
                                                                                <div className='flex items-center mr-2'>
                                                                                    <input
                                                                                        onChange={() => onClickActionAccess(item?.name, child?.name, obj)}
                                                                                        checked={!!findIfActionActive}
                                                                                        id='checkbox-all'
                                                                                        type='checkbox'
                                                                                        data-qa={'checkbox'}
                                                                                        className='w-5 h-5 !outline-none text-blue-600 bg-white-100 border-white-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                                                                    />
                                                                                    <label htmlFor='checkbox-all' className='sr-only'></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {submitError && <div className={'pl-4 flex flex-row justify-center my-5'}><Error text={submitError} /></div>}
                            <div className='flex flex-row justify-end mt-8 mb-5'>
                                <Button
                                    label='Submit'
                                    dataQa={'submit'}
                                    className={`${!submitLoading ? 'btn-main' : 'btn-main-disable'} !shadow-none min-w-[180px]`}
                                    onClickButton={onSubmitRole}
                                />
                            </div>
                        </div>
                    </WhiteContainer>
                </div>
            }
        </div>
    )
}

export default ManageRoles;