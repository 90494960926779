import React from 'react';
import { INVENTORY_FILTERS } from '../../../../utils/constants/inventory-filter-values';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IRetailersFilters {
    markets?: Array<IAllMarkets>;
    sectors?: Array<IAssetTemplate>;
    selectedMarket?: string;
    selectedEnabled?: string;
    selectedSector?: string;
    onSelectMarket: (id: string) => void;
    onSelectSector: (id: string) => void;
    onChangeSelectedBooleanItems: (type: string, name?: string) => void;
}

const RetailersFilters = ({
    markets,
    sectors,
    selectedMarket,
    selectedSector,
    selectedEnabled,
    onSelectMarket,
    onSelectSector,
    onChangeSelectedBooleanItems
}: IRetailersFilters) => {
    return (
        <div>
            <div className='mb-2 grid grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4'>
                <div>
                    <SelectCheckbox
                        name={'Markets'}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        placeholder={' '}
                        selectedOption={selectedMarket}
                        options={markets || []}
                        onChangeSelectedOption={onSelectMarket}
                        dataQa={'retailer-markets-filter'}
                    />
                </div>
                <div>
                    <SelectCheckbox
                        name={'Categories'}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        placeholder={' '}
                        selectedOption={selectedSector}
                        options={sectors || []}
                        onChangeSelectedOption={onSelectSector}
                        dataQa={'retailer-categories-filter'}
                    />
                </div>
                <div>
                    <SelectCheckbox
                        name={'Enabled'}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        placeholder={' '}
                        options={INVENTORY_FILTERS}
                        selectedOption={selectedEnabled}
                        uniqueName='enabled'
                        onChangeSelectedOption={onChangeSelectedBooleanItems}
                        dataQa={'retailer-enabled-filter'}
                    />
                </div>
            </div>

        </div >
    )
}

export default RetailersFilters;