import React from 'react';

const Radio = ({
	register,
	title,
	description,
	id,
	value,
	checked
}: {
	register: any,
	title: string
	description: string,
	id: string,
	value: string
	checked: string
}) => (
	<label
		htmlFor={id}
		className={'w-full flex flex-row rounded px-4 py-3 bg-white mb-2 border cursor-pointer items-center' + (checked === value ? ' border-gradient-end' : '')}
	>
		<div className='mr-4'>
			<input
				value={value}
				className='radio-btn'
				{...register}
				id={id}
				type="radio"
			/>
		</div>
		<div className="ml-auto">
			<h4 className="dm_sansbold text-sm font-bold text-left text-black">{title}</h4>
			<p className="text-title text-opacity-90 text-sm font-normal">{description}</p>
		</div>
	</label>
)

export default Radio;