import React, { useEffect, useState } from 'react';
import AiGradingItemsModal from '../../../ai-agents/ai-grading/AiGradingItemsModal';
import { IUploadItems } from '../../../../interfaces/agents/IUploadItems';
import { AssetLibraryService } from '../../../../store/brokers/partners/asset-library/assetLibraryApi';

const BatchAssetBreakdown = ({
	id
}: {
	id: string
}) => {
	const [uploadedItems, setUploadedItems] = useState<IUploadItems[]>([])

	const getUploadItems = async () => {
		const response = await AssetLibraryService.tryToFetchBatchAssets(
			1,
			10,
			'',
			id
		);
		const data: IUploadItems[] = response.data.data.elements;
		setUploadedItems(data)
		// response.data.data.elements);
	}

	useEffect(()=>{
		getUploadItems();
	}, [id])
	// const uploadedItems: IUploadItems[] = [];
	return (
		<AiGradingItemsModal uploadedItems={uploadedItems} />
	)
}

export default BatchAssetBreakdown;