import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToDownloadWholesalePriceBuy,
    tryToDownloadWholesalePriceSell
} from '../../../../store/brokers/admin/reports/reportsServiceSlice';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import { tryToFetchAllAssetsTemplates } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import DownloadDataModal from '../modals/DownloadDataModal';
import Button from '../../../../shared/button';


const WholesalePriceCategory = () => {
    const dispatch = useAppDispatch();
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [markets, setMarkets] = useState<any>()
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false)
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [loadingDownloadSell, setLoadingDownloadSell] = useState<boolean>(false)
    const [downloadType, setDownloadType] = useState<any>()
    const [downloadError, setDownloadError] = useState<string>()
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, value: r?._id, label: r?.displayName }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const getPartnerAndMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        getPartnerAndMarketData()
        if (!stateAssets?.assetTemplates) {
            dispatch(tryToFetchAllAssetsTemplates());
        }
    }, [])

    const onToggleDownloadModal = (type?: string) => {
        setShowDownloadModal(!showDownloadModal)
        setDownloadType(type || undefined)
    }

    const onDownloadData = async (filters?: any) => {
        if (downloadType === 'sell') {
            setLoadingDownloadSell(true)
        } else {
            setLoadingDownload(true)
        }
        try {
            let response: any
            if (downloadType === 'buy') {
                response = await dispatch(tryToDownloadWholesalePriceBuy(filters)).unwrap()
            } else {
                response = await dispatch(tryToDownloadWholesalePriceSell(filters)).unwrap()
            }
            const file = new Blob([response], {
                type: 'text/csv',
            });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            setDownloadError(undefined)
            setDownloadType(undefined)
        } catch (err) {
            setDownloadError(`${err}`)
            setLoadingDownload(false)
            setLoadingDownloadSell(false)
        }
    }

    return (
        <div>
            <div className='flex flex-row justify-center my-10 flex-wrap'>
                <Button
                    label={'Download Buy Report'}
                    dataQa={'download-buy-report'}
                    className='btn-main w-[300px] !min-w-[150px] !mt-0 mr-2 mb-1'
                    onClickButton={() => onToggleDownloadModal('buy')}
                />
                <Button
                    label={'Download Sell Report'}
                    dataQa={'download-sell-report'}
                    className='btn-main w-[300px] !min-w-[150px] !mt-0 mr-2 mb-1'
                    onClickButton={() => onToggleDownloadModal('sell')}
                />
            </div>
            <DownloadDataModal
                sectors={sectors}
                markets={markets}
                hideFilters={true}
                loadingDownload={downloadType === 'buy' ? loadingDownload : loadingDownloadSell}
                openDownloadModal={showDownloadModal}
                downloadError={downloadError}
                setDownloadError={setDownloadError}
                handleCloseDownloadModal={onToggleDownloadModal}
                setLoadingDownload={downloadType === 'buy' ? setLoadingDownload : setLoadingDownloadSell}
                onDownloadData={onDownloadData}
            />
        </div>
    )
}

export default WholesalePriceCategory;