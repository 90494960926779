import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { tryToDownloadRentalPricing } from '../../../../store/brokers/admin/rental-pricing/rentalPricingSlice';
import { useAppDispatch } from '../../../../app/hooks';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IDownloadRentalPricingModal {
    openDownloadModal: boolean;
    sectors: Array<any>;
    markets: Array<any>;
    handleCloseDownloadModal: () => void;
}

const DownloadRentalPricingModal = ({
    openDownloadModal,
    sectors,
    markets,
    handleCloseDownloadModal,
}: IDownloadRentalPricingModal) => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [selectedSector, setSelectedSector] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [downloadError, setDownloadError] = useState<string>()
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [pricingTemplateData, setPricingTemplateData] = useState<Array<any>>()

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(e)
    }

    const onChangeSelectedMarket = (e: any) => {
        setSelectedMarket(e)
    }

    const onCloseModal = () => {
        handleCloseDownloadModal();
        setSelectedSector(undefined)
        setSelectedMarket(undefined)
        setDownloadError(undefined)
        setLoadingDownload(false)
    }

    const onDownloadTemplate = async () => {
        setLoadingDownload(true)
        if (!selectedSector || !selectedMarket) {
            setDownloadError('All fields must be completed to continue with the download.')
            setLoadingDownload(false)
            return;
        }
        try {
            const formatData = {
                templateId: selectedSector?.value || '',
                market: selectedMarket?.displayName || ''
            }
            const response = await dispatch(tryToDownloadRentalPricing(formatData)).unwrap()
            const templateUsed = response?.data?.template && response?.data?.template?.columns
            const findKeys = response?.data?.data && response?.data?.data?.length > 0 && response?.data?.data?.[0]
            const findColumnsToAvoid: any = []
            templateUsed && templateUsed?.length > 0 && templateUsed?.map((item: any) => {
                if (item?.mappedAsConditions) {
                    const findPlace = (findKeys && findKeys?.length > 0) && findKeys?.indexOf(item?.name)
                    if (findPlace || findPlace === 0) {
                        findColumnsToAvoid.push(findPlace)
                    }
                }
                return item
            })
            const format = response?.data?.data && response?.data?.data?.length > 0 && response?.data?.data?.map((item: any, index: number) => {
                if (index !== 0) {
                    const formatArrayItem: any = item && item?.length > 0 && item?.map((element: any, idx: number) => {
                        const findIndexIfTemplate = findColumnsToAvoid && findColumnsToAvoid?.length > 0 && findColumnsToAvoid?.find((next: any) => next === idx)
                        if (idx + 1 !== item?.length && /^\d+$/.test(element) && !findIndexIfTemplate) {
                            return element + '-Wing'
                        } else {
                            return element
                        }
                    })
                    return formatArrayItem
                } else {
                    return item
                }
            })
            setPricingTemplateData(format || [])
            setDownloadError(undefined)
        } catch (err: any) {
            setDownloadError(`${err?.response || err?.data?.message || err}`)
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (pricingTemplateData && loadingDownload) {
            csvLink.current.link.click()
            onCloseModal()
        }
        setLoadingDownload(false)
    }, [pricingTemplateData])

    return (
        <Modal
            open={openDownloadModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[350px] min-h-[300px]'}>
                <p className='mb-2 font-semibold text-sm'>Download Rental Pricing</p>
                <p className='mb-4 text-sm'>Complete all Fields to download Data as a CSV File</p>
                {downloadError && <Error text={downloadError} />}
                <div className='flex flex-col items-center'>
                    <SelectCheckbox
                        name='Category'
                        placeholder=' '
                        dataQa={'category'}
                        containerStyle='min-w-full mb-3'
                        selectedOption={selectedSector}
                        onChangeSelectedOption={onChangeSelectedSector}
                        options={sectors || []}
                    />
                    <SelectCheckbox
                        name='Market'
                        placeholder=' '
                        dataQa={'market'}
                        containerStyle='min-w-full mb-5'
                        selectedOption={selectedMarket}
                        onChangeSelectedOption={onChangeSelectedMarket}
                        options={markets || []}
                    />
                    <Button
                        label={loadingDownload ? 'Loading data...' : 'Download Rental Pricing Data'}
                        dataQa={loadingDownload ? 'loading-data...' : 'download-rental-pricing-data'}
                        onClickButton={() => !loadingDownload && onDownloadTemplate()}
                        disabled={loadingDownload}
                        className={`${loadingDownload ? 'btn-main-disable' : 'btn-main'} mb-2 mt-9 !py-2 !shadow-none`}
                    />
                    <CSVLink
                        ref={csvLink}
                        data={pricingTemplateData && pricingTemplateData?.length > 0 ? pricingTemplateData?.filter((item, idx) => idx > 0) : []}
                        headers={pricingTemplateData && pricingTemplateData?.length > 0 ? pricingTemplateData?.[0] : []}
                        className={'none'}
                        target={'_blank'}
                        filename={`Rental-Pricing-${selectedSector?.name || 'catalog'}-data.csv`}
                    >
                    </CSVLink>
                </div>
            </div>
        </Modal >
    )
}
export default DownloadRentalPricingModal;