import React, { useEffect, useRef } from 'react';
import Button from '../../../../shared/button';


interface ITableActions {
    onClickCSVButton: () => void;
    csvLabel: string;
    pdfLink?: string;
    accessCSV?: boolean;
    accessPDF?: boolean;
    showMarkAsPaid: boolean;
    accessMarkAsPaid: boolean;
    onClickMarkAsPaid: () => void

}

const TableActions = ({
    onClickCSVButton,
    pdfLink,
    accessCSV,
    accessPDF,
    showMarkAsPaid,
    accessMarkAsPaid,
    onClickMarkAsPaid
}: ITableActions) => {
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const dropdownMenu = useRef<any>(null);

    const closeDropdownPopover = (e: any) => {
        if (dropdownMenu?.current && !dropdownMenu?.current?.contains(e.target)) {
            setDropdownPopoverShow(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', closeDropdownPopover, false);
        return () => {
            document.removeEventListener('click', closeDropdownPopover, false);
        }
    }, [])

    const onClickDropDownItem = (item: string) => {
        if (item === 'pdf') {
            window.open(pdfLink, '_blank');
        }
        else if (item === 'csv') {
            onClickCSVButton()
        } else if (item === 'markAsPaid') {
            onClickMarkAsPaid()
        }
        setDropdownPopoverShow(false)
    }

    return (
        <div>
            <div>
                <button
                    onClick={(e) => setDropdownPopoverShow(!dropdownPopoverShow)}
                    type='button'
                    data-qa={'actions'}
                    id='menu-button' aria-expanded='true' aria-haspopup='true'>
                    <img title={'Action'} alt='items arrow' src={'/assets/pricing/see-more.svg'} className={'w-[7x] rotate-180 mb-2 cursor-pointer mr-2'} />
                </button>
            </div>
            <div
                className={`${dropdownPopoverShow ? '' : 'hidden'} shadow-md max-h-[150px] overflow-auto absolute right-0 z-10 mt-0 w-40 origin-top-right divide-y divide-[#a4a4a4] bg-white focus:outline-none`}
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='menu-button'
                tabIndex={-1}>
                    <div
                    onClick={() => accessPDF && onClickDropDownItem('pdf')}
                    className='py-1 px-4 cursor-pointer flex flex-row items-center align-flex-start justify-center hover:bg-[#f4f4f4]'
                    role='none'>
                    <Button
                        label={'Download PDF'}
                        dataQa={'pdf'}
                        className={`${accessPDF ? 'btn-primary-text-underline !no-underline !text-[#202020]' : 'btn-primary-text-underline-disable'}`}
                    />
                </div>
                <div
                    onClick={() => accessCSV && onClickDropDownItem('csv')}
                    className='py-1 px-4 cursor-pointer flex flex-row items-center align-flex-start justify-center  hover:bg-[#f4f4f4]'
                    role='none'>
                    <Button
                        label={'Download CSV'}
                        dataQa={'csv-details'}
                        className={`${accessCSV ? 'btn-primary-text-underline !no-underline !text-[#202020]' : 'btn-primary-text-underline-disable'}`}
                    />
                </div>
                {showMarkAsPaid && <div
                    onClick={() => accessMarkAsPaid && onClickDropDownItem('markAsPaid')}
                    className='py-1 px-4 cursor-pointer flex flex-row items-center align-flex-start justify-center hover:bg-[#f4f4f4]'
                    role='none'>
                    <Button
                        label={'Mark as paid'}
                        dataQa={'mark-as-paid'}
                        className={`${accessMarkAsPaid ? 'btn-primary-text-underline !no-underline !text-[#00B67A]' : 'btn-primary-text-underline-disable'}`}
                    />
                </div>}
            </div>
        </div>
    )
}

export default TableActions;