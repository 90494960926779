import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { PaginatedPartners } from '../../../../interfaces/partners/paginatedStore.type';
import { IPartnerSchedulers } from '../../../../interfaces/partners/IPartnerSchedulers';
import { IPartnersPriority } from '../../../../interfaces/partners/IPartnersPriority';
import { IAdminEditPartner } from '../../../../interfaces/partners/IAdminEditPartner';
import { PaginatedAgents } from '../../../../interfaces/agents/paginatedStore.type';
import { IPartnerSettings } from '../../../../interfaces/partners/IPartnerSettings';
import { IPartnersSchema } from '../../../../interfaces/partners/IPartnersSchema';
import { IFilterPartners } from '../../../../interfaces/partners/IFilterPartners';
import { IEditPartner } from '../../../../interfaces/partners/IEditPartner';
import { IAddress } from '../../../../interfaces/auth/ICreateUserSchema';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { IPartnerWarehouse } from '../../../../interfaces/partners/IPartnerWarehouse';

const tryToFetchPartners = (
    pageNumber: number,
    pageSize: number,
    data?: IFilterPartners,
    filters?: string,
): Promise<AxiosResponse<PaginatedPartners>> => {
    const url = filters
        ? `${USERS_API_URL}/partners?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${USERS_API_URL}/partners?page=${pageNumber}&size=${pageSize}`;
    return brokersAxios.put<PaginatedPartners>(url, { filters: data });
};

const tryToFetchAllPartners = (): Promise<AxiosResponse<IPartners[]>> => {
    return brokersAxios.put(`${USERS_API_URL}/partners?flag=true`);
};

const tryToFetchPartnerNavigation = (): Promise<AxiosResponse<any>> => {
    return brokersAxios.get(`${USERS_API_URL}/users/access-control`);
};

const tryToFetchUserNotifications = (data?: any, page?: number, size?: number): Promise<AxiosResponse<any>> => {
    return brokersAxios.put(`${USERS_API_URL}/notifications?page=${page}&skip=${size}`, data);
};

const tryToSetReadNotifications = (data?: any): Promise<AxiosResponse<any>> => {
    return brokersAxios.put(`${USERS_API_URL}/notifications/read`, data);
};

const tryToGenerateApiKey = (): Promise<AxiosResponse<any>> => {
    return brokersAxios.put(`${USERS_API_URL}/integration/generate/api-key`);
};

const tryToAddGeneratedApiKey = (data: any): Promise<AxiosResponse<any>> => {
    return brokersAxios.put(`${USERS_API_URL}/integration/user`, data);
};

const tryToFetchPartnerAgents = (
    id: string,
    pageNumber: number,
    pageSize: number,
    filters?: string,
): Promise<AxiosResponse<PaginatedAgents>> => {
    const url = filters
        ? `${USERS_API_URL}/partners/${id}/agents?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${USERS_API_URL}/partners/${id}/agents?page=${pageNumber}&size=${pageSize}`;
    return brokersAxios.get<PaginatedAgents>(url);
};

const tryToFetchPartnerCatalogs = (brokerUsername: string): Promise<AxiosResponse> => {
    return brokersAxios.get(
        `${USERS_API_URL}/partners/catalogs/${brokerUsername} `,
    );
};

const tryToAuthorizeUser = (data: { dashboard: string; }): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/users/authorization`, data);
};

const tryToAddPartner = (data: IPartners): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/partners`, data);
};

const tryToFetchUserData = (): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/dom/user`);
};

const tryToRequestToRegisterPartner = (data: IPartners): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/dom/partners`, data);
};

const tryToSetPartnerPriority = (payload: IPartnersPriority, id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${id}/priority`, payload);
};

const tryToEnablePartner = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${id}/enable`);
};

const tryToGetPrioritySettings = (partnerId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/partners/${partnerId}/priority-settings`);
};

const tryToSetPriorityOfBrokers = (partnerId: string, payload: { data: IPartnerSettings }): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${partnerId}/set-priority/settings`, payload);
};

const tryToEditPartnerTrustpilot = (partnerId: string, data: any): Promise<AxiosResponse> => {
    return brokersAxios.put(
        `${USERS_API_URL}/partners/${partnerId}/trustpilot`,
        data,
    );
};

const tryToEditPartner = (payload: IEditPartner): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/change-company-info`, payload);
};

const tryToDeletePartner = (partnerId: string): Promise<AxiosResponse<ApiResponse<IPartnersSchema>>> => {
    return brokersAxios.delete(`${USERS_API_URL}/partners/${partnerId}/delete`);
};

const tryToEditPartnerDetails = (partnerId: string, payload: IAdminEditPartner): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${partnerId}`, payload);
};

const tryToEditAddressPartner = (payload: IAddress): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/change-address/${payload?.id}`, payload);
};

const tryToSetAddressDefault = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/set-default-address/${id}`);
};

const tryToDisablePartner = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/partners/${id}`);
};

const tryToFetchSinglePartner = (id: string): Promise<AxiosResponse<ApiResponse<IPartnersSchema>>> => {
    return brokersAxios.get<ApiResponse<any>>(
        `${USERS_API_URL}/partners/${id}`,
    );
};

const tryToAddMarket = (data: { id: string; }): Promise<AxiosResponse<ApiResponse<IPartnersSchema>>> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/add-market`, data);
};

const tryToDisableMarket = (marketId: string): Promise<AxiosResponse<ApiResponse<IPartnersSchema>>> => {
    return brokersAxios.delete(`${USERS_API_URL}/partners/disable-market/${marketId}`);
};

const tryToFindOperatingMarkets = (): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/users/markets/operating`);
};

const tryToSetPartnerSchdulerSettings = (partnerId: string, data: IPartnerSchedulers): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${partnerId}/schedulers`, data);
};

const tryToGetPartnerSchdulerSettings = (partnerId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/partners/${partnerId}/schedulers`);
};

const tryToEnableInventoryFeature = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${id}/enable-inventory`);
};

const tryToDisableInventoryFeature = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${id}/disable-inventory`);
};

const tryToEnableLogisticFeature = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${id}/enable-logistic`);
};

const tryToDisableLogicticFeature = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${id}/disable-logistic`);
};

const tryToControlLogisticAndInventoryFeature = (partnerId: string, type: string, enable: boolean,): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/partners/${partnerId}/dashboard/${type}/${enable}`);
};

const tryToFetchAllWarehouseSections = (warehouseId: string, type?: string, category?: string): Promise<AxiosResponse> => {
    let url = `${USERS_API_URL}/spaces/all/${warehouseId}`;
    if (type) {
        url = `${USERS_API_URL}/spaces/all/${warehouseId}?type=${type}`;
    }
    if (category) {
        url = url + `${type ? '&' : '?'}category=${category}`;
    }
    return brokersAxios.get(url);
};

const tryToFetchPartnerWarehouses = (brokerId: string): Promise<AxiosResponse<IPartnerWarehouse[]>> => {
    return brokersAxios.put<IPartnerWarehouse[]>(`${USERS_API_URL}/dom/warehouses`, { brokerId });
};

const tryToAddPartnerWarehouse = (data: {
    name: string;
    markets: string[];
    partnerId: string;
    address: {
        phone: string;
        warehouseCountry: string;
        email: string;
        street: string;
        city: string;
        addressNo: string;
        country: string;
        state: string | undefined;
        default: boolean;
    };
}): Promise<AxiosResponse<IPartnerWarehouse>> => {
    return brokersAxios.post(`${USERS_API_URL}/warehouse/by-admin`, data);
};

const tryToEditPartnerWarehouse = (
    id: string,
    data: {
        name: string;
        markets: string[];
        partnerId: string;
        address: {
            phone: string;
            warehouseCountry: string;
            email: string;
            street: string;
            city: string;
            addressNo: string;
            country: string;
            state: string | undefined;
            default: boolean;
        };
    },
): Promise<AxiosResponse<IPartnerWarehouse>> => {
    return brokersAxios.put(`${USERS_API_URL}/warehouse/${id}/by-admin`, data);
};

const tryToDeletePartnerWarehouse = (
    id: string,
    data: {
        partnerId: string;
        country: string;
    },
): Promise<AxiosResponse<IPartnerWarehouse>> => {
    return brokersAxios.put(
        `${USERS_API_URL}/warehouse/delete/${id}/by-admin`,
        data,
    );
};

export const PartnersService = {
    tryToFetchPartners,
    tryToFetchAllPartners,
    tryToFetchSinglePartner,
    tryToFetchPartnerAgents,
    tryToFetchPartnerCatalogs,
    tryToFindOperatingMarkets,
    tryToAddPartner,
    tryToAddMarket,
    tryToSetPartnerPriority,
    tryToGetPrioritySettings,
    tryToSetPriorityOfBrokers,
    tryToEditPartner,
    tryToEditPartnerDetails,
    tryToEditAddressPartner,
    tryToSetAddressDefault,
    tryToEnablePartner,
    tryToEnableInventoryFeature,
    tryToEnableLogisticFeature,
    tryToDisableInventoryFeature,
    tryToDisableLogicticFeature,
    tryToDisablePartner,
    tryToDisableMarket,
    tryToDeletePartner,
    tryToRequestToRegisterPartner,
    tryToSetPartnerSchdulerSettings,
    tryToGetPartnerSchdulerSettings,
    tryToControlLogisticAndInventoryFeature,
    tryToAuthorizeUser,
    tryToFetchPartnerNavigation,
    tryToGenerateApiKey,
    tryToAddGeneratedApiKey,
    tryToEditPartnerTrustpilot,
    tryToFetchAllWarehouseSections,
    tryToFetchPartnerWarehouses,
    tryToAddPartnerWarehouse,
    tryToEditPartnerWarehouse,
    tryToDeletePartnerWarehouse,
    tryToFetchUserNotifications,
    tryToSetReadNotifications,
    tryToFetchUserData
};
