import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import { tryToFetchSingleWarehouse } from '../../../../store/brokers/admin/warehouses/warehousesSlice';
import SetupFields from '../warehouse-categories/SetupFields';
import WarehouseValues from '../warehouse-categories/WarehouseValues';
import CategoriesButtons from '../warehouse-categories/CategoriesButtons';

const categories = [
    { name: 'Setup Fields' },
    { name: 'Locations' },
]

const WarehouseManage = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [warehouseData, setWarehouseData] = useState<IWarehouse>()
    const [activeCategory, setActiveCategory] = useState<{ name: string }>(categories?.[0])
    const state = useAppSelector((state) => state.warehouses);
    const warehouseIsLoading = useAppSelector((state) => state.warehouses?.warehouseIsLoading);

    useEffect(() => {
        if (state.warehouse) {
            setWarehouseData(state.warehouse)
        }
    }, [state.warehouse]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleWarehouse(id));
        }
    }, []);

    const onGoBack = () => {
        navigate(-1)
    }

    const onCallActiveElement = () => {
        switch (activeCategory?.name) {
            case 'Setup Fields':
                return <SetupFields warehouseData={warehouseData} />
            case 'Locations':
                return <WarehouseValues warehouseData={warehouseData} />
        }
    }

    const onChangeActiveCategory = (category: any) => {
        setActiveCategory(category)
    }

    return (
        <div>
            <div>
                <div className={'flex flex-row items-center justify-between'}>
                    <div className='flex flex-row items-center'>
                        <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                            <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                        </div>
                        <p className='page-title'>Warehouse Details</p>
                    </div>
                </div>
                <div className='ml-[18px] mb-5'>
                    {!warehouseIsLoading
                        && <div>
                            <p className='font-medium'>Name: <span className='font-normal'>{warehouseData?.name}</span></p>
                            <p className='font-medium'>Details: <span className='font-normal'>{warehouseData?.address?.addressNo} {warehouseData?.address?.street}, {warehouseData?.address?.city} {warehouseData?.address?.postalCode}, {warehouseData?.address?.country}</span></p>
                        </div>
                    }
                </div>
            </div>
            <div>
                <CategoriesButtons
                    categories={categories}
                    activeCategory={activeCategory}
                    onChangeActiveCategory={onChangeActiveCategory}
                />
            </div>
            <div className='my-2 min-w-[100%]'>
                {onCallActiveElement()}
            </div>
        </div >
    )
}

export default WarehouseManage;