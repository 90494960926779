import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddNotificationSection,
    tryToEditNotificationSection,
    tryToFetchSingleNotificationSection
} from '../../../../store/brokers/admin/notification-sections/notificationSectionsSlice';
import SectionModalContainer from '../loading/SectionModalContainer';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface ISectionActionsModal {
    openActionsModal: boolean;
    sectionItemId?: string | null;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    displayName: string;
};

const SectionActionsModal = ({
    openActionsModal,
    sectionItemId,
    handleCloseModal,
    onSaveChanges,
}: ISectionActionsModal) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.notificationSections);
    const sectionLoading = useAppSelector((state) => state.notificationSections?.sectionIsLoading);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        if (state.section && sectionItemId) {
            setValue('displayName', sectionItemId ? state?.section?.displayName || '' : '');
            setValue('name', sectionItemId ? state?.section?.name || '' : '');
        }
    }, [state.section]);

    useEffect(() => {
        if (sectionItemId) {
            dispatch(tryToFetchSingleNotificationSection(sectionItemId));
        } else {
            reset();
        }
    }, [sectionItemId, openActionsModal]);

    const onSubmit = async (data: FormValues) => {
        const payload: any = {
            ...data,
        };
        try {
            if (sectionItemId) {
                payload._id = sectionItemId;
                await dispatch(tryToEditNotificationSection(payload)).unwrap();
            } else {
                await dispatch(tryToAddNotificationSection(payload)).unwrap();
            }
            onSaveChanges('success', `Section successfully ${sectionItemId ? 'changed' : 'added'}.`);
        } catch (error) {
            onSaveChanges('error', `${error}`);
        }
        reset();
        handleCloseModal();
    };

    const onCloseModal = () => {
        reset();
        handleCloseModal();
    };

    return (
        <Modal
            open={openActionsModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>Section</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(sectionItemId && !sectionLoading) || !sectionItemId ? (
                        <div className='flex flex-col my-4'>
                            <Input
                                label='Name'
                                dataQa={'name'}
                                disabled={!!sectionItemId}
                                containerStyle='max-w-[90%]'
                                type='text'
                                register={register('name', {
                                    required: {
                                        message: 'Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) => !!value.trim() || 'Name is required'
                                })}
                                error={errors.name?.message}
                            />
                            <Input
                                label='Display Name'
                                dataQa={'display-name'}
                                containerStyle='max-w-[90%]'
                                type='text'
                                register={register('displayName', {
                                    required: {
                                        message: 'Display Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) => !!value.trim() || 'Display Name is required'
                                })}
                                error={errors.displayName?.message}
                            />
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    dataQa={'submit'}
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='my-3 flex flex-row justify-center items-center'>
                            <SectionModalContainer />
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default SectionActionsModal;