import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IRetailers } from '../../../../interfaces/retailers/IRetailers';
import { IRetailersListState } from '../../../../interfaces/retailers/IRetailersList';
import { PaginatedRetailers } from '../../../../interfaces/retailers/paginatedStore.type';
import { RetailersService } from './RetailersApi';


const initialState: IRetailersListState = {
    retailerIsLoading: false,
    retailersAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchRetailers = createAsyncThunk<PaginatedRetailers, IPaginationPayload>(
    'partners/tryToFetchRetailers',
    async ({ pageNumber, pageSize, data }) => {
        const result = await RetailersService.tryToFetchRetailers(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToFetchSingleRetailers = createAsyncThunk<IRetailers, string>(
    'retailers/tryToFetchSingleRetailers',
    async (id: string) => {
        const result = await RetailersService.tryToFetchSingleRetailers(id);
        return result?.data?.data;
    },
);

export const tryToCreateRetailers = createAsyncThunk<ApiResponse<IRetailers>, any>(
    'retailers/tryToCreateRetailers',
    async (data: IRetailers, { rejectWithValue }) => {
        try {
            const result = await RetailersService.tryToCreateRetailers(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditRetailers = createAsyncThunk<ApiResponse<IRetailers>, { id: string, data: any }>(
    'retailers/tryToEditRetailers',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await RetailersService.tryToEditRetailers(id, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEnableDisableRetailers = createAsyncThunk<ApiResponse<any>, { id: string, status: boolean }>(
    'retailers/tryToEnableDisableRetailers',
    async ({ id, status }, { rejectWithValue }) => {
        try {
            const result = await RetailersService.tryToEnableDisableRetailers(id, status);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteRetailers = createAsyncThunk<ApiResponse<IRetailers>, string>(
    'retailers/tryToDeleteRetailers',
    async (id, { rejectWithValue }) => {
        try {
            const result = await RetailersService.tryToDeleteRetailers(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);


export const retailersSlice = createSlice({
    name: 'retailers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchRetailers
            .addCase(tryToFetchRetailers.pending, (state) => {
                state.retailersAreLoading = true;
            })
            .addCase(tryToFetchRetailers.rejected, (state) => {
                state.retailersAreLoading = false;
            })
            .addCase(tryToFetchRetailers.fulfilled, (state, action) => {
                state.retailersAreLoading = false;
                state.retailers = action.payload;
            })

            // tryToFetchSingleRetailers
            .addCase(tryToFetchSingleRetailers.pending, (state) => {
                state.retailerIsLoading = true;
            })
            .addCase(tryToFetchSingleRetailers.rejected, (state) => {
                state.retailerIsLoading = false;
            })
            .addCase(tryToFetchSingleRetailers.fulfilled, (state, action) => {
                state.retailerIsLoading = false;
                state.retailer = action.payload;
            })

            // tryToCreateRetailers
            .addCase(tryToCreateRetailers.pending, (state) => {
                state.retailerIsLoading = true;
            })
            .addCase(tryToCreateRetailers.rejected, (state) => {
                state.retailerIsLoading = false;
            })
            .addCase(tryToCreateRetailers.fulfilled, (state, action) => {
                state.retailerIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.retailers?.data) state.retailers.data.elements = [...state.retailers?.data.elements, data];
            })

            // tryToEditRetailers
            .addCase(tryToEditRetailers.pending, (state) => {
                state.retailerIsLoading = true;
            })
            .addCase(tryToEditRetailers.rejected, (state) => {
                state.retailerIsLoading = false;
            })
            .addCase(tryToEditRetailers.fulfilled, (state) => {
                state.retailerIsLoading = false;
            })

            // tryToEnableDisableRetailers
            .addCase(tryToEnableDisableRetailers.pending, (state) => {
                state.retailerIsLoading = true;
            })
            .addCase(tryToEnableDisableRetailers.rejected, (state) => {
                state.retailerIsLoading = false;
            })
            .addCase(tryToEnableDisableRetailers.fulfilled, (state, action) => {
                state.retailerIsLoading = false;
                state.requestStatus = 'success';
                if (state.retailers?.data.elements)
                    state.retailers.data.elements =
                        { ...state }.retailers?.data.elements.map((retailer) => {
                            if (retailer?._id === action.meta?.arg?.id) {
                                return {
                                    ...retailer,
                                    enabled: action?.meta?.arg?.status
                                }
                            } else {
                                return retailer
                            }
                        }) || [];
            })

            // tryToDeleteRetailers
            .addCase(tryToDeleteRetailers.pending, (state) => {
                state.retailerIsLoading = true;
            })
            .addCase(tryToDeleteRetailers.rejected, (state) => {
                state.retailerIsLoading = false;
            })
            .addCase(tryToDeleteRetailers.fulfilled, (state, action) => {
                state.retailerIsLoading = false;
                state.requestStatus = 'success';
                if (state.retailers?.data.elements)
                    state.retailers.data.elements = state.retailers?.data.elements.filter((retailer) => retailer?._id !== action.meta?.arg)
            })
    },
});

export default retailersSlice.reducer;