import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IUserAccessListState } from '../../../../interfaces/user-access-control/IUserAccessList';
import { PaginatedUserAccess } from '../../../../interfaces/user-access-control/paginatedStore.type';
import { UserAccessService } from './userAccessApi';

const initialState: IUserAccessListState = {
    rolesAreLoading: false,
    roleIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchRoles = createAsyncThunk<PaginatedUserAccess, IPaginationPayload>(
    'agents/tryToFetchRoles',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await UserAccessService.tryToFetchRoles(pageNumber, pageSize, filters, data);
        return result?.data;
    },
);

export const tryToFetchAllRoles = createAsyncThunk<any, any>(
    'agents/tryToFetchRoles',
    async (type?: string) => {
        const result = await UserAccessService.tryToFetchAllRoles(type);
        return result?.data?.data;
    },
);

export const tryToFetchAllSections = createAsyncThunk<any, string>(
    'agents/tryToFetchAllSections',
    async (type) => {
        const result = await UserAccessService.tryToFetchAllSections(type);
        return result?.data;
    },
);

export const tryToFetchSingleRole = createAsyncThunk<any, string>(
    'agents/tryToFetchSingleRole',
    async (id: string) => {
        const result = await UserAccessService.tryToFetchSingleRole(id);
        return result?.data?.data;
    },
);

export const tryToAddRole = createAsyncThunk<ApiResponse<any>, any>(
    'agents/tryToAddRole',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await UserAccessService.tryToAddRole(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditRole = createAsyncThunk<ApiResponse<any>, any>(
    'agents/tryToEditRole',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await UserAccessService.tryToEditRole(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteRole = createAsyncThunk<ApiResponse<any>, string>(
    'agents/tryToDeleteRole',
    async (id, { rejectWithValue }) => {
        try {
            const result = await UserAccessService.tryToDeleteRole(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const userAccessSlice = createSlice({
    name: 'userAccess',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchRoles
            .addCase(tryToFetchRoles.pending, (state) => {
                state.roleIsLoading = true;
            })
            .addCase(tryToFetchRoles.rejected, (state) => {
                state.roleIsLoading = false;
            })
            .addCase(tryToFetchRoles.fulfilled, (state, action) => {
                state.roleIsLoading = false;
                state.roles = action.payload;
            })

            // tryToFetchSingleRole
            .addCase(tryToFetchSingleRole.pending, (state) => {
                state.roleIsLoading = true;
            })
            .addCase(tryToFetchSingleRole.rejected, (state) => {
                state.roleIsLoading = false;
                state.roleDetails = undefined;
            })
            .addCase(tryToFetchSingleRole.fulfilled, (state, action) => {
                state.roleIsLoading = false;
                state.roleDetails = action.payload;
            })

            // tryToAddRole
            .addCase(tryToAddRole.pending, (state) => {
                state.roleIsLoading = true;
            })
            .addCase(tryToAddRole.rejected, (state) => {
                state.roleIsLoading = false;
            })
            .addCase(tryToAddRole.fulfilled, (state, action) => {
                state.roleIsLoading = false;
                state.requestStatus = 'success';
            })

            // tryToEditRole
            .addCase(tryToEditRole.pending, (state) => {
                state.roleIsLoading = true;
            })
            .addCase(tryToEditRole.rejected, (state) => {
                state.roleIsLoading = false;
            })
            .addCase(tryToEditRole.fulfilled, (state, action) => {
                state.roleIsLoading = false;
                state.requestStatus = 'success';
            })

            // tryToDeleteRole
            .addCase(tryToDeleteRole.pending, (state) => {
                state.roleIsLoading = true;
            })
            .addCase(tryToDeleteRole.rejected, (state) => {
                state.roleIsLoading = false;
                state.requestStatus = 'error';
            })
            .addCase(tryToDeleteRole.fulfilled, (state, action) => {
                state.roleIsLoading = false;
                state.requestStatus = 'success';
                if (state.roles?.data.elements)
                    state.roles.data.elements =
                        { ...state }.roles?.data.elements.filter((role) => role._id !== action.meta.arg) || [];
            });
    },
});

export default userAccessSlice.reducer;