export const MONTHLY_DATA = [
    { name: 'January', label: 'Jan' },
    { name: 'February', label: 'Feb' },
    { name: 'March', label: 'Mar' },
    { name: 'April', label: 'Apr' },
    { name: 'May', label: 'May' },
    { name: 'June', label: 'Jun' },
    { name: 'July', label: 'Jul' },
    { name: 'August', label: 'Aug' },
    { name: 'September', label: 'Sep' },
    { name: 'October', label: 'Oct' },
    { name: 'November', label: 'Nov' },
    { name: 'December', label: 'Dec' },
];