import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IAssetFilters } from '../../../../interfaces/asset-templates/IAssetFilters';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IAssetTemplateFrames } from '../../../../interfaces/asset-templates/IAssetTemplateFrames';
import {
    PaginatedAssetTemplate,
    PaginatedAssetTemplateFrames,
} from '../../../../interfaces/asset-templates/paginatedStore.type';
import {
    IAssetFields,
    IAssetTemplate,
} from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IAssetsTemplatesListState } from '../../../../interfaces/asset-templates/IAssetsTemplatesList';
import {
    IAssetFieldsSchema,
    IAssetTemplateSchema,
    ICreateAssetField,
    ISortAssetField,
} from '../../../../interfaces/asset-templates/IAssetTemplateSchema';
import { AssetsTemplatesService } from './assetsTemplatesApi';
import { ISalesGradeMapping } from '../../../../interfaces/asset-templates/ISalesGradeMapping';

const initialState: IAssetsTemplatesListState = {
    assetsTemplatesAreLoading: false,
    assetTemplateIsLoading: false,
    assetTemplateFieldsAreLoading: false,
    assetTemplateFieldIsLoading: false,
    allAssetsAreLoading: false,
    assetTemplateFiltersAreLoading: false,
    assetTemplateFramesAreLoading: false,
    assetTemplateFrameIsLoading: false,
    assetTemplateSalesGradeIsLoading: false,
    parentCategoriesAreLoading: false,
    parentCategories: [],
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAllAssetsTemplates = createAsyncThunk<any>(
    'assetTemplates/tryToFetchAllAssetsTemplates',
    async () => {
        const result =
            await AssetsTemplatesService.tryToFetchAllAssetTemplates();
        return result?.data?.data;
    },
);

export const tryToFetchAssetsTemplates = createAsyncThunk<
    PaginatedAssetTemplate,
    IPaginationPayload
>(
    'assetTemplates/tryToFetchAssetsTemplates',
    async ({ pageNumber, pageSize, filters }) => {
        const result = await AssetsTemplatesService.tryToFetchAssetsTemplates(
            pageNumber,
            pageSize,
            filters,
        );
        return result.data;
    },
);

export const tryToFetchAssetFieldsTemplate = createAsyncThunk<
    IAssetFields[],
    string
>('assetTemplates/tryToFetchAssetFieldsTemplate', async (id) => {
    const result = await AssetsTemplatesService.tryToFetchAssetFieldsTemplate(
        id,
    );
    return result?.data?.data;
});

export const tryToFetchAssetTemplateFieldsFilter = createAsyncThunk<
    { fields: IAssetFields[]; inventoryFields: IAssetFields[] },
    string
>('assetTemplates/tryToFetchAssetTemplateFieldsFilter', async (id) => {
    const result =
        await AssetsTemplatesService.tryToFetchAssetTemplateFieldsFilter(id);
    return result?.data?.data;
});

export const tryToFetchSingleAssetTemplate = createAsyncThunk<
    IAssetTemplate,
    string
>('assetTemplates/tryToFetchSingleAssetTemplate', async (id: string) => {
    const result = await AssetsTemplatesService.tryToFetchSingleAssetTemplate(
        id,
    );
    return result?.data?.data;
});

export const tryToFetchSingleAssetTemplateField = createAsyncThunk<
    any,
    { assetId: string; fieldId: string }
>(
    'assetTemplates/tryToFetchSingleAssetTemplateField',
    async ({ assetId, fieldId }) => {
        const result =
            await AssetsTemplatesService.tryToFetchSingleAssetTemplateField(
                assetId,
                fieldId,
            );
        return result?.data?.data;
    },
);

export const tryToAddAssetTemplate = createAsyncThunk<
    ApiResponse<IAssetTemplateSchema>,
    any
>(
    'assetTemplates/tryToAddAssetTemplate',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await AssetsTemplatesService.tryToAddAssetTemplate(
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAddAssetTemplateField = createAsyncThunk<
    ApiResponse<IAssetTemplate>,
    ICreateAssetField
>(
    'assetTemplates/tryToAddAssetTemplateField',
    async (data: ICreateAssetField, { rejectWithValue }) => {
        try {
            const assetId = data?.assetId;
            const formatData: any = data;
            delete formatData?.assetId;
            const result =
                await AssetsTemplatesService.tryToAddAssetTemplateField(
                    assetId,
                    formatData,
                );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEnableAssetTemplate = createAsyncThunk<
    ApiResponse<IAssetTemplate>,
    string
>(
    'assetTemplates/tryToEnableAssetTemplate',
    async (id, { rejectWithValue }) => {
        try {
            const result =
                await AssetsTemplatesService.tryToEnableAssetTemplate(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditAssetTemplate = createAsyncThunk<
    ApiResponse<IAssetTemplateSchema>,
    any
>(
    'assetTemplates/tryToEditAssetTemplate',
    async ({ data, id }, { rejectWithValue }) => {
        try {
            const result = await AssetsTemplatesService.tryToEditAssetTemplate(
                id,
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditAssetTemplateField = createAsyncThunk<
    ApiResponse<IAssetTemplate>,
    ICreateAssetField
>(
    'assetTemplates/tryToEditAssetTemplateField',
    async (data: ICreateAssetField, { rejectWithValue }) => {
        try {
            const result =
                await AssetsTemplatesService.tryToEditAssetTemplateField(
                    data?.assetId,
                    data.id || '',
                    data,
                );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToSortAssetTemplateField = createAsyncThunk<
    ApiResponse<IAssetTemplate>,
    ISortAssetField
>(
    'assetTemplates/tryToSortAssetTemplateField',
    async (data: ISortAssetField, { rejectWithValue }) => {
        try {
            const formatData = { sortedValues: data?.sortedValues };
            const result =
                await AssetsTemplatesService.tryToSortAssetTemplateField(
                    data?.assetId || '',
                    formatData,
                );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDisableAssetTemplate = createAsyncThunk<
    ApiResponse<IAssetTemplateSchema>,
    string
>(
    'assetTemplates/tryToDisableAssetTemplate',
    async (id, { rejectWithValue }) => {
        try {
            const results: any =
                await AssetsTemplatesService.tryToDisableAssetTemplate(id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDisableAssetTemplateField = createAsyncThunk<
    ApiResponse<IAssetFieldsSchema>,
    any
>(
    'assetTemplates/tryToDisableAssetTemplateField',
    async ({ assetId, fieldId }, { rejectWithValue }) => {
        try {
            const results =
                await AssetsTemplatesService.tryToDisableAssetTemplateField(
                    assetId,
                    fieldId,
                );
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDisableAssetInventoryField = createAsyncThunk<ApiResponse<IAssetFieldsSchema>, any>(
    'assetTemplates/tryToDisableAssetInventoryField',
    async ({ assetId, fieldId }, { rejectWithValue }) => {
        try {
            const results =
                await AssetsTemplatesService.tryToDisableAssetInventoryField(
                    assetId,
                    fieldId,
                );
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDisableAssetRentalField = createAsyncThunk<ApiResponse<IAssetFieldsSchema>, any>(
    'assetTemplates/tryToDisableAssetRentalField',
    async ({ assetId, fieldId }, { rejectWithValue }) => {
        try {
            const results =
                await AssetsTemplatesService.tryToDisableAssetRentalField(
                    assetId,
                    fieldId,
                );
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDisableAssetExtendedField = createAsyncThunk<ApiResponse<IAssetFieldsSchema>, any>(
    'assetTemplates/tryToDisableAssetExtendedField',
    async ({ assetId, fieldId }, { rejectWithValue }) => {
        try {
            const results =
                await AssetsTemplatesService.tryToDisableAssetExtendedField(
                    assetId,
                    fieldId,
                );
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToSetupAssetTemplateFilters = createAsyncThunk<
    ApiResponse<any>,
    IAssetFilters
>(
    'assetTemplates/tryToSetupAssetTemplateFilters',
    async (data: IAssetFilters, { rejectWithValue }) => {
        try {
            const assetId = data?.assetTemplateId;
            const formatData: any = data;
            delete formatData?.assetId;
            const result =
                await AssetsTemplatesService.tryToSetupAssetTemplateFilters(
                    assetId || '',
                    formatData,
                );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchAssetTemplateFilters = createAsyncThunk<
    any,
    { id: string; replacements?: boolean }
>(
    'assetTemplates/tryToFetchAssetTemplateFilters',
    async ({ id, replacements }) => {
        const result =
            await AssetsTemplatesService.tryToFetchAssetTemplateFilters(
                id,
                replacements,
            );
        return result?.data?.data;
    },
);

export const tryToDeleteAssetTemplateFilter = createAsyncThunk<
    ApiResponse<IAssetFieldsSchema>,
    any
>(
    'assetTemplates/tryToDeleteAssetTemplateFilter',
    async ({ assetId, fieldId }, { rejectWithValue }) => {
        try {
            const results =
                await AssetsTemplatesService.tryToDeleteAssetTemplateFilter(
                    assetId,
                    fieldId,
                );
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToSetupInventoryFieldsFilters = createAsyncThunk<
    ApiResponse<any>,
    IAssetFilters
>(
    'assetTemplates/tryToSetupInventoryFieldsFilters',
    async (data: IAssetFilters, { rejectWithValue }) => {
        try {
            const assetId = data?.assetTemplateId;
            const formatData: any = data;
            delete formatData?.assetId;
            const result =
                await AssetsTemplatesService.tryToSetupInventoryFieldsFilters(
                    assetId || '',
                    formatData,
                );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchInventoryFilters = createAsyncThunk<any, string>(
    'assetTemplates/tryToFetchInventoryFilters',
    async (assetId) => {
        const result = await AssetsTemplatesService.tryToFetchInventoryFilters(
            assetId,
        );
        return result?.data?.data;
    },
);

export const tryToDeleteInventoryFilter = createAsyncThunk<
    ApiResponse<IAssetFieldsSchema>,
    any
>(
    'assetTemplates/tryToDeleteInventoryFilter',
    async ({ assetId, fieldId }, { rejectWithValue }) => {
        try {
            const results =
                await AssetsTemplatesService.tryToDeleteInventoryFilter(
                    assetId,
                    fieldId,
                );
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchParentCategoriesByLevel = createAsyncThunk<any, string>(
    'assetTemplates/tryToFetchParentsFromLevel',
    async (level) => {
        const result =
            await AssetsTemplatesService.tryToFetchParentCategoriesByLevel(
                level,
            );
        return result?.data?.data;
    },
);

export const tryToFetchAssetTemplateFrames = createAsyncThunk<
    PaginatedAssetTemplateFrames,
    IPaginationPayload
>(
    'assetTemplates/tryToFetchAssetTemplateFrames',
    async ({ pageNumber, pageSize, data }) => {
        const result =
            await AssetsTemplatesService.tryToFetchAssetTemplateFrames(
                pageNumber,
                pageSize,
                data,
            );
        return result.data;
    },
);

export const tryToFetchSingleAssetTemplateFrame = createAsyncThunk<
    IAssetTemplateFrames,
    string
>('assetTemplates/tryToFetchSingleAssetTemplateFrame', async (id: string) => {
    const result =
        await AssetsTemplatesService.tryToFetchSingleAssetTemplateFrame(id);
    return result?.data?.data;
});

export const tryToAddAssetTemplateFrame = createAsyncThunk<
    ApiResponse<IAssetTemplateFrames>,
    IAssetTemplateFrames
>(
    'assetTemplates/tryToAddAssetTemplateFrame',
    async (data: IAssetTemplateFrames, { rejectWithValue }) => {
        try {
            const result =
                await AssetsTemplatesService.tryToAddAssetTemplateFrame(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditAssetTemplateFrame = createAsyncThunk<
    ApiResponse<IAssetTemplateFrames>,
    any
>(
    'assetTemplates/tryToEditAssetTemplateFrame',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result =
                await AssetsTemplatesService.tryToEditAssetTemplateFrame(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteAssetTemplateFrame = createAsyncThunk<
    ApiResponse<any>,
    string
>(
    'assetTemplates/tryToDeleteAssetTemplateFrame',
    async (id, { rejectWithValue }) => {
        try {
            const results: any =
                await AssetsTemplatesService.tryToDeleteAssetTemplateFrame(id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToChangeTemplateFieldDetails = createAsyncThunk<
    ApiResponse<any>,
    { assetTemplateId: string; fieldId: string; data: any }
>(
    'assetTemplates/tryToChangeTemplateFieldDetails',
    async ({ assetTemplateId, fieldId, data }, { rejectWithValue }) => {
        try {
            const results: any =
                await AssetsTemplatesService.tryToChangeTemplateFieldDetails(
                    assetTemplateId,
                    fieldId,
                    data,
                );
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToImportAssetsTemplateTranslations = createAsyncThunk<
    ApiResponse<any>,
    any
>(
    'assetTemplates/tryToImportAssetsTemplateTranslations',
    async (data, { rejectWithValue }) => {
        try {
            const result =
                await AssetsTemplatesService.tryToImportAssetsTemplateTranslations(
                    data,
                );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToChangeTemplatePricingField = createAsyncThunk<
    ApiResponse<any>,
    { assetTemplateId: string; data: any }
>(
    'assetTemplates/tryToChangeTemplatePricingField',
    async ({ assetTemplateId, data }, { rejectWithValue }) => {
        try {
            const results: any =
                await AssetsTemplatesService.tryToChangeTemplatePricingField(
                    assetTemplateId,
                    data,
                );
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchAssetTemplateMappings = createAsyncThunk<
    ApiResponse<{ mappings: ISalesGradeMapping[] }>,
    { assetTemplateId: string }
>(
    'assetTemplates/tryToFetchAssetTemplateMappings',
    async ({ assetTemplateId }, { rejectWithValue }) => {
        try {
            const results: any =
                await AssetsTemplatesService.tryToFetchAssetTemplateMappings(
                    assetTemplateId,
                );
            if (results?.data) return results.data;
        } catch (error) {
            return { data: { mappings: [] } };
        }
    },
);

export const tryToEditAssetTemplateMappings = createAsyncThunk<
    ApiResponse<{ mappings: ISalesGradeMapping[] }>,
    {
        assetTemplateId: string;
        deductionPercentageA?: string;
        deductionPercentageB?: string;
        deductionPercentageC?: string;
        deductionPercentageD?: string;
        deductionPercentageE?: string;
    }
>(
    'assetTemplates/tryToEditAssetTemplateMappings',
    async (
        {
            assetTemplateId,
            deductionPercentageA,
            deductionPercentageB,
            deductionPercentageC,
            deductionPercentageD,
            deductionPercentageE,
        },
        { rejectWithValue },
    ) => {
        try {
            const results: any =
                await AssetsTemplatesService.tryToEditAssetTemplateMappings(
                    assetTemplateId,
                    deductionPercentageA,
                    deductionPercentageB,
                    deductionPercentageC,
                    deductionPercentageD,
                    deductionPercentageE,
                );
            if (results?.data) return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAddAssetTemplateMappings = createAsyncThunk<
    ApiResponse<{ mappings: ISalesGradeMapping[] }>,
    {
        assetTemplateId: string;
        deductionPercentageA?: string;
        deductionPercentageB?: string;
        deductionPercentageC?: string;
        deductionPercentageD?: string;
        deductionPercentageE?: string;
    }
>(
    'assetTemplates/tryToAddAssetTemplateMappings',
    async (
        {
            assetTemplateId,
            deductionPercentageA,
            deductionPercentageB,
            deductionPercentageC,
            deductionPercentageD,
            deductionPercentageE,
        },
        { rejectWithValue },
    ) => {
        try {
            const results: any =
                await AssetsTemplatesService.tryToAddAssetTemplateMappings(
                    assetTemplateId,
                    deductionPercentageA,
                    deductionPercentageB,
                    deductionPercentageC,
                    deductionPercentageD,
                    deductionPercentageE,
                );
            if (results?.data) return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const assetTemplateSlice = createSlice({
    name: 'assetTemplates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAllAssetsTemplates
            .addCase(tryToFetchAllAssetsTemplates.pending, (state) => {
                state.allAssetsAreLoading = true;
            })
            .addCase(tryToFetchAllAssetsTemplates.rejected, (state) => {
                state.allAssetsAreLoading = false;
            })
            .addCase(
                tryToFetchAllAssetsTemplates.fulfilled,
                (state, action) => {
                    state.allAssetsAreLoading = false;
                    state.allAssetTemplates = action?.payload;
                },
            )

            // tryToFetchAssetsTemplates
            .addCase(tryToFetchAssetsTemplates.pending, (state) => {
                state.assetsTemplatesAreLoading = true;
            })
            .addCase(tryToFetchAssetsTemplates.rejected, (state) => {
                state.assetsTemplatesAreLoading = false;
            })
            .addCase(tryToFetchAssetsTemplates.fulfilled, (state, action) => {
                state.assetsTemplatesAreLoading = false;
                state.assetTemplates = action.payload;
            })

            // tryToFetchAssetFieldsTemplate
            .addCase(tryToFetchAssetFieldsTemplate.pending, (state) => {
                state.assetTemplateFieldsAreLoading = true;
            })
            .addCase(tryToFetchAssetFieldsTemplate.rejected, (state) => {
                state.assetTemplateFieldsAreLoading = false;
            })
            .addCase(
                tryToFetchAssetFieldsTemplate.fulfilled,
                (state, action) => {
                    state.assetTemplateFieldsAreLoading = false;
                    state.assetTemplateFields = action.payload;
                },
            )

            // tryToFetchSingleAssetTemplate
            .addCase(tryToFetchSingleAssetTemplate.pending, (state) => {
                state.assetTemplateIsLoading = true;
            })
            .addCase(tryToFetchSingleAssetTemplate.rejected, (state) => {
                state.assetTemplateIsLoading = false;
            })
            .addCase(
                tryToFetchSingleAssetTemplate.fulfilled,
                (state, action) => {
                    state.assetTemplateIsLoading = false;
                    state.assetTemplateDetails = action.payload;
                },
            )

            // tryToFetchSingleAssetTemplateField
            .addCase(tryToFetchSingleAssetTemplateField.pending, (state) => {
                state.assetTemplateFieldIsLoading = true;
            })
            .addCase(tryToFetchSingleAssetTemplateField.rejected, (state) => {
                state.assetTemplateFieldIsLoading = false;
            })
            .addCase(
                tryToFetchSingleAssetTemplateField.fulfilled,
                (state, action) => {
                    state.assetTemplateFieldIsLoading = false;
                    state.assetTemplateFieldsDetails = action.payload;
                },
            )

            // tryToAddAssetTemplate
            .addCase(tryToAddAssetTemplate.pending, (state) => {
                state.assetTemplateFieldIsLoading = true;
            })
            .addCase(tryToAddAssetTemplate.rejected, (state) => {
                state.assetTemplateFieldIsLoading = false;
            })
            .addCase(tryToAddAssetTemplate.fulfilled, (state, action) => {
                state.assetTemplateFieldIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.assetTemplates?.data)
                    state.assetTemplates.data.elements = [
                        data,
                        ...(state.assetTemplates?.data.elements || []),
                    ];
            })

            // tryToAddAssetTemplateField
            .addCase(tryToAddAssetTemplateField.pending, (state) => {
                state.assetTemplateFieldIsLoading = true;
            })
            .addCase(tryToAddAssetTemplateField.rejected, (state) => {
                state.assetTemplateFieldIsLoading = false;
            })
            .addCase(tryToAddAssetTemplateField.fulfilled, (state, action) => {
                state.assetTemplateFieldIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.assetTemplateDetails)
                    state.assetTemplateDetails = data;
            })

            // tryToChangeTemplatePricingField
            .addCase(tryToChangeTemplatePricingField.pending, (state) => {
                state.assetTemplateFieldIsLoading = true;
            })
            .addCase(tryToChangeTemplatePricingField.rejected, (state) => {
                state.assetTemplateFieldIsLoading = false;
            })
            .addCase(
                tryToChangeTemplatePricingField.fulfilled,
                (state, action) => {
                    state.assetTemplateFieldIsLoading = false;
                    state.requestStatus = 'success';
                    if (state.assetTemplateDetails)
                        state.assetTemplateDetails.defaultPriceField =
                            action.meta.arg.data;
                },
            )

            // tryToEnableAssetTemplate
            .addCase(tryToEnableAssetTemplate.pending, (state) => {
                state.assetTemplateIsLoading = true;
            })
            .addCase(tryToEnableAssetTemplate.rejected, (state) => {
                state.assetTemplateIsLoading = false;
            })
            .addCase(tryToEnableAssetTemplate.fulfilled, (state, action) => {
                state.assetTemplateIsLoading = false;
                state.requestStatus = 'success';
                if (state.assetTemplates?.data.elements)
                    state.assetTemplates.data.elements =
                        { ...state }.assetTemplates?.data.elements.map(
                            (assetTemplate) => {
                                if (assetTemplate?._id === action.meta?.arg) {
                                    return {
                                        ...assetTemplate,
                                        enabled: true,
                                    };
                                } else {
                                    return assetTemplate;
                                }
                            },
                        ) || [];
            })

            // tryToEditAssetTemplate
            .addCase(tryToEditAssetTemplate.pending, (state) => {
                state.assetTemplateFieldIsLoading = true;
            })
            .addCase(tryToEditAssetTemplate.rejected, (state) => {
                state.assetTemplateFieldIsLoading = false;
            })
            .addCase(tryToEditAssetTemplate.fulfilled, (state, action) => {
                state.assetTemplateFieldIsLoading = false;
                if (state.assetTemplates?.data.elements)
                    state.assetTemplates.data.elements =
                        { ...state }.assetTemplates?.data.elements.map(
                            (assetTemplate) => {
                                if (
                                    assetTemplate?._id ===
                                    action.payload?.data?._id
                                ) {
                                    return {
                                        ...action.payload.data,
                                    };
                                } else {
                                    return assetTemplate;
                                }
                            },
                        ) || [];
                state.assetTemplateDetails = action.payload.data;
            })

            // tryToEditAssetTemplateField
            .addCase(tryToEditAssetTemplateField.pending, (state) => {
                state.assetTemplateFieldIsLoading = true;
            })
            .addCase(tryToEditAssetTemplateField.rejected, (state) => {
                state.assetTemplateFieldIsLoading = false;
            })
            .addCase(tryToEditAssetTemplateField.fulfilled, (state, action) => {
                state.assetTemplateFieldIsLoading = false;
                if (state.assetTemplateDetails) {
                    state.assetTemplateDetails = action.payload.data;
                }
            })

            // tryToDisableAssetTemplateField
            .addCase(tryToDisableAssetTemplateField.pending, (state) => {
                state.assetTemplateFieldIsLoading = true;
            })
            .addCase(tryToDisableAssetTemplateField.rejected, (state) => {
                state.assetTemplateFieldIsLoading = false;
            })
            .addCase(tryToDisableAssetTemplateField.fulfilled, (state, action) => {
                state.assetTemplateFieldIsLoading = false;
                state.requestStatus = 'success';
                if (state.assetTemplateDetails)
                    state.assetTemplateDetails.fields =
                        { ...state }.assetTemplateDetails?.fields?.filter(
                            (assetTemplateField) =>
                                assetTemplateField?.id !==
                                action?.meta?.arg?.fieldId,
                        ) || [];
            })

            // tryToDisableAssetInventoryField
            .addCase(tryToDisableAssetInventoryField.pending, (state) => {
                state.assetTemplateFieldIsLoading = true;
            })
            .addCase(tryToDisableAssetInventoryField.rejected, (state) => {
                state.assetTemplateFieldIsLoading = false;
            })
            .addCase(tryToDisableAssetInventoryField.fulfilled, (state, action) => {
                state.assetTemplateFieldIsLoading = false;
                state.requestStatus = 'success';
                if (state.assetTemplateDetails)
                    state.assetTemplateDetails.inventoryFields =
                        {
                            ...state,
                        }.assetTemplateDetails?.inventoryFields?.filter(
                            (assetTemplateField) =>
                                assetTemplateField?.id !==
                                action?.meta?.arg?.fieldId,
                        ) || [];
            })

            // tryToDisableAssetRentalField
            .addCase(tryToDisableAssetRentalField.pending, (state) => {
                state.assetTemplateFieldIsLoading = true;
            })
            .addCase(tryToDisableAssetRentalField.rejected, (state) => {
                state.assetTemplateFieldIsLoading = false;
            })
            .addCase(tryToDisableAssetRentalField.fulfilled, (state, action) => {
                state.assetTemplateFieldIsLoading = false;
                state.requestStatus = 'success';
                if (state.assetTemplateDetails)
                    state.assetTemplateDetails.rentalFields =
                        {
                            ...state,
                        }.assetTemplateDetails?.rentalFields?.filter(
                            (assetTemplateField) =>
                                assetTemplateField?.id !==
                                action?.meta?.arg?.fieldId,
                        ) || [];
            })

            // tryToDisableAssetExtendedField
            .addCase(tryToDisableAssetExtendedField.pending, (state) => {
                state.assetTemplateFieldIsLoading = true;
            })
            .addCase(tryToDisableAssetExtendedField.rejected, (state) => {
                state.assetTemplateFieldIsLoading = false;
            })
            .addCase(tryToDisableAssetExtendedField.fulfilled, (state, action) => {
                state.assetTemplateFieldIsLoading = false;
                state.requestStatus = 'success';
                if (state.assetTemplateDetails)
                    state.assetTemplateDetails.extendedFields =
                        {
                            ...state,
                        }.assetTemplateDetails?.extendedFields?.filter(
                            (assetTemplateField) =>
                                assetTemplateField?.id !==
                                action?.meta?.arg?.fieldId,
                        ) || [];
            })

            // tryToDisableAssetTemplate
            .addCase(tryToDisableAssetTemplate.pending, (state) => {
                state.assetTemplateIsLoading = true;
            })
            .addCase(tryToDisableAssetTemplate.rejected, (state) => {
                state.assetTemplateIsLoading = false;
            })
            .addCase(tryToDisableAssetTemplate.fulfilled, (state, action) => {
                state.assetTemplateIsLoading = false;
                state.requestStatus = 'success';
                if (state.assetTemplates?.data.elements)
                    state.assetTemplates.data.elements =
                        { ...state }.assetTemplates?.data.elements.filter(
                            (assetTemplate) =>
                                assetTemplate?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToFetchAssetTemplateFilters
            .addCase(tryToFetchAssetTemplateFilters.pending, (state) => {
                state.assetTemplateFiltersAreLoading = true;
            })
            .addCase(tryToFetchAssetTemplateFilters.rejected, (state) => {
                state.assetTemplateFiltersAreLoading = false;
            })
            .addCase(
                tryToFetchAssetTemplateFilters.fulfilled,
                (state, action) => {
                    state.assetTemplateFiltersAreLoading = false;
                    state.assetTemplateFilters = action.payload;
                },
            )

            // tryToSetupAssetTemplateFilters
            .addCase(tryToSetupAssetTemplateFilters.pending, (state) => {
                state.assetTemplateFiltersAreLoading = true;
            })
            .addCase(tryToSetupAssetTemplateFilters.rejected, (state) => {
                state.assetTemplateFiltersAreLoading = false;
            })
            .addCase(
                tryToSetupAssetTemplateFilters.fulfilled,
                (state, action) => {
                    state.assetTemplateFiltersAreLoading = false;
                    state.requestStatus = 'success';
                    const data = action.payload.data || action.meta.arg;
                    if (state.assetTemplateFilters)
                        state.assetTemplateFilters = data?.filters;
                },
            )

            // tryToDeleteAssetTemplateFilter
            .addCase(tryToDeleteAssetTemplateFilter.pending, (state) => {
                state.assetTemplateFiltersAreLoading = true;
            })
            .addCase(tryToDeleteAssetTemplateFilter.rejected, (state) => {
                state.assetTemplateFiltersAreLoading = false;
            })
            .addCase(
                tryToDeleteAssetTemplateFilter.fulfilled,
                (state, action) => {
                    state.assetTemplateFiltersAreLoading = false;
                    state.requestStatus = 'success';
                    if (state.assetTemplateFilters)
                        state.assetTemplateFilters =
                            state.assetTemplateFilters?.filter(
                                (assetTemplateFilter) =>
                                    assetTemplateFilter?.fieldId !==
                                    action?.meta?.arg?.fieldId,
                            ) || [];
                },
            )

            // tryToFetchInventoryFilters
            .addCase(tryToFetchInventoryFilters.pending, (state) => {
                state.assetTemplateFiltersAreLoading = true;
            })
            .addCase(tryToFetchInventoryFilters.rejected, (state) => {
                state.assetTemplateFiltersAreLoading = false;
            })
            .addCase(tryToFetchInventoryFilters.fulfilled, (state, action) => {
                state.assetTemplateFiltersAreLoading = false;
                state.inventoryFieldFilters = action.payload;
            })

            // tryToSetupInventoryFieldsFilters
            .addCase(tryToSetupInventoryFieldsFilters.pending, (state) => {
                state.assetTemplateFiltersAreLoading = true;
            })
            .addCase(tryToSetupInventoryFieldsFilters.rejected, (state) => {
                state.assetTemplateFiltersAreLoading = false;
            })
            .addCase(
                tryToSetupInventoryFieldsFilters.fulfilled,
                (state, action) => {
                    state.assetTemplateFiltersAreLoading = false;
                    state.requestStatus = 'success';
                    const data = action.payload.data || action.meta.arg;
                    if (state.inventoryFieldFilters)
                        state.inventoryFieldFilters = data?.inventoryFilters;
                },
            )

            // tryToDeleteInventoryFilter
            .addCase(tryToDeleteInventoryFilter.pending, (state) => {
                state.assetTemplateFiltersAreLoading = true;
            })
            .addCase(tryToDeleteInventoryFilter.rejected, (state) => {
                state.assetTemplateFiltersAreLoading = false;
            })
            .addCase(tryToDeleteInventoryFilter.fulfilled, (state, action) => {
                state.assetTemplateFiltersAreLoading = false;
                state.requestStatus = 'success';
                if (state.inventoryFieldFilters)
                    state.inventoryFieldFilters =
                        state.inventoryFieldFilters?.filter(
                            (assetTemplateFilter) =>
                                assetTemplateFilter?.fieldId !==
                                action?.meta?.arg?.fieldId,
                        ) || [];
            })

            // tryToFetchParentCategoriesByLevel
            .addCase(tryToFetchParentCategoriesByLevel.pending, (state) => {
                state.parentCategoriesAreLoading = true;
            })
            .addCase(tryToFetchParentCategoriesByLevel.rejected, (state) => {
                state.parentCategoriesAreLoading = false;
            })
            .addCase(
                tryToFetchParentCategoriesByLevel.fulfilled,
                (state, action) => {
                    state.parentCategoriesAreLoading = false;
                    state.parentCategories = action.payload;
                },
            )

            // tryToFetchAssetTemplateFrames
            .addCase(tryToFetchAssetTemplateFrames.pending, (state) => {
                state.assetTemplateFramesAreLoading = true;
            })
            .addCase(tryToFetchAssetTemplateFrames.rejected, (state) => {
                state.assetTemplateFramesAreLoading = false;
            })
            .addCase(
                tryToFetchAssetTemplateFrames.fulfilled,
                (state, action) => {
                    state.assetTemplateFramesAreLoading = false;
                    state.assetTemplateFrames = action.payload;
                },
            )

            // tryToFetchSingleAssetTemplateFrame
            .addCase(tryToFetchSingleAssetTemplateFrame.pending, (state) => {
                state.assetTemplateFrameIsLoading = true;
            })
            .addCase(tryToFetchSingleAssetTemplateFrame.rejected, (state) => {
                state.assetTemplateFrameIsLoading = false;
            })
            .addCase(
                tryToFetchSingleAssetTemplateFrame.fulfilled,
                (state, action) => {
                    state.assetTemplateFrameIsLoading = false;
                    state.assetTemplateFrame = action.payload;
                },
            )

            // tryToEditAssetTemplateFrame
            .addCase(tryToEditAssetTemplateFrame.pending, (state) => {
                state.assetTemplateFrameIsLoading = true;
            })
            .addCase(tryToEditAssetTemplateFrame.rejected, (state) => {
                state.assetTemplateFrameIsLoading = false;
            })
            .addCase(tryToEditAssetTemplateFrame.fulfilled, (state, action) => {
                state.assetTemplateFrameIsLoading = false;
                if (state.assetTemplateFrames?.data.elements)
                    state.assetTemplateFrames.data.elements =
                        { ...state }.assetTemplateFrames?.data.elements.map(
                            (assetTemplate) => {
                                if (
                                    assetTemplate?.assetTemplateId ===
                                    action.meta?.arg?.id
                                ) {
                                    return {
                                        ...action.payload.data,
                                    };
                                } else {
                                    return assetTemplate;
                                }
                            },
                        ) || [];
                state.assetTemplateFrame = action.payload.data;
            })

            // tryToDeleteAssetTemplateFrame
            .addCase(tryToDeleteAssetTemplateFrame.pending, (state) => {
                state.assetTemplateFrameIsLoading = true;
            })
            .addCase(tryToDeleteAssetTemplateFrame.rejected, (state) => {
                state.assetTemplateFrameIsLoading = false;
            })
            .addCase(
                tryToDeleteAssetTemplateFrame.fulfilled,
                (state, action) => {
                    state.assetTemplateFrameIsLoading = false;
                    state.requestStatus = 'success';
                    if (state.assetTemplateFrames?.data.elements)
                        state.assetTemplateFrames.data.elements =
                            {
                                ...state,
                            }.assetTemplateFrames?.data.elements.filter(
                                (assetTemplate) =>
                                    assetTemplate?.assetTemplateId !==
                                    action.meta.arg,
                            ) || [];
                },
            )

            // tryToAddAssetTemplateFrame
            .addCase(tryToAddAssetTemplateFrame.pending, (state) => {
                state.assetTemplateFrameIsLoading = true;
            })
            .addCase(tryToAddAssetTemplateFrame.rejected, (state) => {
                state.assetTemplateFrameIsLoading = false;
            })
            .addCase(tryToAddAssetTemplateFrame.fulfilled, (state, action) => {
                state.assetTemplateFrameIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.assetTemplateFrames?.data)
                    state.assetTemplateFrames.data.elements = [
                        data,
                        ...(state.assetTemplateFrames?.data.elements || []),
                    ];
            })

            // tryToFetchAssetTemplateMappings
            .addCase(tryToFetchAssetTemplateMappings.pending, (state) => {
                state.assetTemplateSalesGradeIsLoading = true;
            })
            .addCase(tryToFetchAssetTemplateMappings.rejected, (state) => {
                state.assetTemplateSalesGradeIsLoading = false;
                state.assetTemplateSalesGradeMappings = [];
            })
            .addCase(
                tryToFetchAssetTemplateMappings.fulfilled,
                (state, action) => {
                    state.assetTemplateSalesGradeIsLoading = false;
                    state.assetTemplateSalesGradeMappings =
                        action.payload.data.mappings;
                },
            )

            // tryToEditAssetTemplateMappings
            .addCase(tryToEditAssetTemplateMappings.pending, (state) => {
                state.assetTemplateSalesGradeIsLoading = true;
            })
            .addCase(tryToEditAssetTemplateMappings.rejected, (state) => {
                state.assetTemplateSalesGradeIsLoading = false;
                state.assetTemplateSalesGradeMappings = undefined;
            })
            .addCase(
                tryToEditAssetTemplateMappings.fulfilled,
                (state, action) => {
                    state.assetTemplateSalesGradeIsLoading = false;
                    state.assetTemplateSalesGradeMappings =
                        action.payload.data.mappings;
                },
            )

            // tryToAddAssetTemplateMappings
            .addCase(tryToAddAssetTemplateMappings.pending, (state) => {
                state.assetTemplateSalesGradeIsLoading = true;
            })
            .addCase(tryToAddAssetTemplateMappings.rejected, (state) => {
                state.assetTemplateSalesGradeIsLoading = false;
                state.assetTemplateSalesGradeMappings = undefined;
            })
            .addCase(
                tryToAddAssetTemplateMappings.fulfilled,
                (state, action) => {
                    state.assetTemplateSalesGradeIsLoading = false;
                    state.assetTemplateSalesGradeMappings =
                        action.payload.data.mappings;
                },
            );
    },
});

export default assetTemplateSlice.reducer;
