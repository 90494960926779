import React from 'react';
import moment from 'moment';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['Item ID', 'Category', 'Item Name', 'Item Code', 'Sales Grade', 'Currency', 'Purchase Price', 'Gesture Amount', 'Total Purchase Price', 'Total Item Cost', 'Additional Cost', 'Sales Price', 'Total Sales Price', 'Shipping', 'Commission', 'Profit', 'Conversion Rate', 'Sales Entity', 'VAT Type', 'Broker', 'Broker ID', 'Invoice Number', 'Date', 'Purchase Date', 'VAT Payable', 'GBP Purchase', 'GBP Sales', 'GBP Total Sales Price']

interface ISalesTable {
    rows: any[];
    rowsLoading: boolean;
    paginationData?: IPage;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const SalesTable = ({
    rows,
    rowsLoading,
    paginationData,
    setPaginationState,
}: ISalesTable) => {
    return (
        <Table
            containerStyle={'mt-0'}
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.itemId || ''}</td>
                                <td className='px-6 py-4'>{row?.category || ''}</td>
                                <td className='px-6 py-4 min-w-[170px]'>{row?.itemName || ''}</td>
                                <td className='px-6 py-4'>{row?.itemCode || ''}</td>
                                <td className='px-6 py-4'>{row?.salesGrade || ''}</td>
                                <td className='px-6 py-4'>{row?.purchaseCurrency || ''}</td>
                                <td className='px-6 py-4'>{Number(row?.purchasePrice || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.gestureAmount || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{(Number(row?.purchasePrice || 0) + Number(row?.gestureAmount || 0)).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.totalItemCost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.additionalCost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.salesPrice || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.totalSalesPrice || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.shipping || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.commission || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.profit || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{row?.exchangeValue || ''}</td>
                                <td className='px-6 py-4'>{row?.salesEntity || ''}</td>
                                <td className='px-6 py-4'>{row?.vatType || ''}</td>
                                <td className='px-6 py-4'>{row?.broker || ''}</td>
                                <td className='px-6 py-4'>{row?.brokerId || ''}</td>
                                <td className='px-6 py-4'>{row?.invoiceNumber || ''}</td>
                                <td className='px-6 py-4'>{row?.date && moment(row?.date).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                <td className='px-6 py-4'>{row?.purchaseDate && moment(row?.purchaseDate).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                <td className='px-6 py-4'>{Number(row?.vatPayable || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.gbpPurchase || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.gbpSales || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.gbpTotalSalesPrice || 0).toFixed(2)}</td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default SalesTable;