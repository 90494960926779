import React from 'react';
import { PartnerGuard } from '../../../../guards/RolesGuard';
import WhiteContainer from '../../../../shared/white-container';
import Button from '../../../../shared/button';


interface IPersonalInformationDetails {
    userInfo: any;
    accessEditInformation?: any;
    onShowHidePersonalInformation: () => void;
}

const PersonalInformationDetails = ({
    userInfo,
    accessEditInformation,
    onShowHidePersonalInformation,
}: IPersonalInformationDetails) => {
    const isPartner = PartnerGuard()
    return (
        <WhiteContainer type='rounded'>
            <div className='mt-2 mb-3'>
                <p className='text-[#F7A21E] text-lg font-bold'>Main Contact Information</p>
                <div className='mt-7 mb-5 flex flex-row items-center'>
                    <img src={'/assets/profile/email.svg'} className={'h-[20px] object-contain mr-2'} />
                    <p >{userInfo?.email}</p>
                </div>
                <div className='mt-4 mb-5 flex flex-row items-center'>
                    <img src={'/assets/profile/phone.png'} className={'h-[24px] object-contain mr-2'} />
                    {userInfo?.phoneNumber && <p>{userInfo?.phoneNumber}</p>}
                </div>
            </div>
            <div className='flex flex-row item-center'>
                {isPartner &&
                    <Button
                        label={'Edit'}
                        className={`${accessEditInformation ? 'btn-primary-text-underline' : 'btn-primary-text-underline-disable'} mr-3`}
                        onClickButton={() => accessEditInformation && onShowHidePersonalInformation()}
                    />
                }
            </div>
        </WhiteContainer>
    )
}

export default PersonalInformationDetails;