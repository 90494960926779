import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Loading from '../../../shared/Loading';
import WhiteContainer from '../../../shared/white-container';
import { tryToFetchSingleBrokerComparison } from '../../../store/brokers/admin/brokers-comparison-sites/brokersComparisonSitesSlice';


const BrokerComparisonDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [brokerComparisonDetails, setBrokerComparisonDetails] = useState<any>()
    const brokerComparisonSites = useAppSelector((state) => state?.brokersComparison?.brokerComparison);
    const brokerComparisonLoading = useAppSelector((state) => state?.brokersComparison?.brokerComparisonIsLoading);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleBrokerComparison(id));
        }
    }, [id]);

    useEffect(() => {
        if (brokerComparisonSites) {
            setBrokerComparisonDetails(brokerComparisonSites)
        }
        else {
            setBrokerComparisonDetails(undefined)
        }
    }, [brokerComparisonSites])

    const onGoBack = () => {
        navigate(-1);
    };

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Broker Comparison Sites Details</p>
                </div>
            </div>
            {brokerComparisonLoading ?
                <div className='my-3 flex flex-row justify-center items-center'>
                    <Loading />
                </div>
                :
                <div>
                    <WhiteContainer type='rounded'>
                        <p className='text-primary-default text-lg font-bold'>Detailed Information</p>
                        <div className='mt-2 mb-3 grid grid-cols-2'>
                            <div className='mt-7 mb-5'>
                                {brokerComparisonDetails?.name && <p className='mb-3'>Name: {brokerComparisonDetails?.name}</p>}
                                {brokerComparisonDetails?.displayName && <p className='mb-3'>Display Name: {brokerComparisonDetails?.displayName}</p>}
                                {brokerComparisonDetails?.enabled && <p className='mb-3'>Is Enabled: {brokerComparisonDetails?.enabled ? 'Yes' : 'No'}</p>}
                                {brokerComparisonDetails?.updatedAt && <p className='mb-3'>Last Update: {brokerComparisonDetails?.updatedAt && moment(brokerComparisonDetails?.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</p>}
                            </div>
                            <div className='mt-7 mb-5 flex flex-col items-center'>
                                <p>Image:</p>
                                <div className='ml-4'>
                                    <img src={brokerComparisonDetails?.logoUrl} className={'h-[60px] object-contain'} />
                                </div>
                            </div>
                        </div>
                    </WhiteContainer>
                    <WhiteContainer type='rounded'>
                        <div className='mt-2 mb-3'>
                            <p className='text-primary-default text-lg font-bold'>Markets</p>
                            <div className='mt-7 mb-5 flex flex-row flex-wrap items-center'>
                                {(brokerComparisonDetails?.markets && brokerComparisonDetails?.markets?.length > 0) &&
                                    brokerComparisonDetails?.markets?.map((item: any, idx: number) => {
                                        return (
                                            <div
                                                className='mb-3 mr-4 py-1 px-3 rounded-full bg-[#F7A21E]'
                                                key={idx}>
                                                <p className='text-[#fff] font-medium'>{item?.label || item?.name}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </WhiteContainer>
                    <WhiteContainer type='rounded'>
                        <div className='mt-2 mb-3'>
                            <p className='text-primary-default text-lg font-bold'>Categories</p>
                            <div className='mt-7 mb-5 flex flex-row flex-wrap items-center'>
                                {(brokerComparisonDetails?.sectors && brokerComparisonDetails?.sectors?.length > 0) &&
                                    brokerComparisonDetails?.sectors?.map((item: any, idx: number) => {
                                        return (
                                            <div
                                                className='mb-3 mr-4 py-1 px-3 rounded-full bg-[#F7A21E]'
                                                key={idx}>
                                                <p className='text-[#fff] font-medium'>{item?.label || item?.name}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </WhiteContainer>
                </div>
            }
        </div>
    )
}

export default BrokerComparisonDetails;