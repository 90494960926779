import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { tryToFetchCarriers } from '../../../../store/brokers/partners/carriers/carriersSlice';
import {
    tryToAddMarketShippingServices,
    tryToDeleteMarketShippingServices,
    tryToEditMarketShippingServices,
    tryToFetchMarketShippingServices
} from '../../../../store/brokers/admin/shipping-services/shippingServicesSlice';
import MarketShippingServicesContainer from '../loading/MarketShippingServicesContainer';
import SelectSearchInside from '../../../../shared/market-select-search/select-inside-search';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Accordion from '../../../../shared/accordion';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';


interface IMarketShippingServicesModal {
    sectors?: any[];
    markets?: any[];
    openActionsModal: boolean;
    marketId?: string | null;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

const MarketShippingServicesModal = ({
    openActionsModal,
    marketId,
    markets,
    sectors,
    handleCloseModal,
    onSaveChanges,
}: IMarketShippingServicesModal) => {
    const dispatch = useAppDispatch();
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const [carriers, setCarriers] = useState<IAssetTemplate[]>()
    const [activeAccordion, setActiveAccordion] = useState<string>();
    const [saveError, setSaveError] = useState<string>()
    const [formData, setFormData] = useState<any>()
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)
    const shippingServiceLoading = useAppSelector((state) => state.shippingServices?.shippingServiceIsLoading);
    const state = useAppSelector((state) => state.shippingServices);

    useEffect(() => {
        const formatShippingConfigs = (state?.shippingService?.shippingConfigsData && state?.shippingService?.shippingConfigsData?.length > 0) && state?.shippingService?.shippingConfigsData?.map((item: any) => {
            const formatCarriersInfo = item?.carriersInfo && item?.carriersInfo?.length > 0 && item?.carriersInfo?.map((obj: any) => {
                const groupedByFee = (obj?.services && obj?.services?.length > 0) ? obj?.services?.reduce((acc: any, item: any) => {
                    const fee = item.fee;
                    if (acc[fee]) {
                        acc[fee].push(item);
                    } else {
                        acc[fee] = [item];
                    }
                    return acc;
                }, {}) : []
                const groupedServices = groupedByFee ? Object.keys(groupedByFee).map(fee => ({
                    fee: parseFloat(fee),
                    services: groupedByFee[fee]
                })) : undefined

                return {
                    ...obj || {},
                    services: groupedServices
                }
            })
            return {
                ...item || {},
                carriersInfo: formatCarriersInfo
            }
        })
        setFormData(formatShippingConfigs || undefined)
    }, [state?.shippingService])

    useEffect(() => {
        if (markets && markets?.length > 0) {
            const findUK = markets?.find((market: any) => market?.displayName === 'UK' || market?.displayName === 'GB')
            if (marketId) {
                const findMarket = markets?.find((market: any) => market?._id === marketId)
                setSelectedMarket(findMarket || findUK || markets?.[0])
            } else {
                setSelectedMarket(findUK || markets?.[0])
            }
        }
    }, [markets, marketId])

    useEffect(() => {
        if (selectedMarket) {
            dispatch(tryToFetchMarketShippingServices(selectedMarket?._id))
        }
    }, [selectedMarket])

    const onGettingCarriersData = async () => {
        const response: any = await dispatch(tryToFetchCarriers(null)).unwrap()
        const filterOutCarriersAndServices = (response && response?.length > 0) && response?.map((obj: any) => ({
            ...obj || {},
            services: (obj.services && obj.services?.length > 0) && obj.services?.filter((type: any) => (type?.status || [])?.includes('inbound') && type.status.length > 0).filter((type: any) => type.status.length > 0)
        })).filter((obj: any) => obj.services.length > 0);
        const formatCarriers = (filterOutCarriersAndServices && filterOutCarriersAndServices?.length > 0) && filterOutCarriersAndServices.map((item: any) => ({ ...item, label: item?.displayName, value: item?.name }))
        setCarriers(formatCarriers || undefined)
    }

    useEffect(() => {
        onGettingCarriersData()
    }, [])

    const onCloseModal = () => {
        setSelectedMarket(undefined)
        setSaveError(undefined)
        setActiveAccordion(undefined)
        setFormData(undefined)
        setSaveError(undefined)
        setSelectedMarket(undefined)
        setLoadingUpdate(false)
        handleCloseModal();
    };

    const onSubmit = async (type?: string) => {
        if (!selectedMarket || !formData || formData?.length <= 0) {
            setSaveError('Please Select Market and add data to submit')
            return;
        }
        setSaveError(undefined)
        setLoadingUpdate(true)
        try {
            if (type === 'delete') {
                await dispatch(tryToDeleteMarketShippingServices(selectedMarket?._id)).unwrap()
            } else {
                const formatData = formData && formData?.length > 0 && formData?.map((item: any) => {
                    const formatConfigCarriers = item?.carriersInfo && item?.carriersInfo?.length > 0 && item?.carriersInfo?.map((obj: any) => {
                        const groupedServices = (obj?.services && obj?.services?.length > 0) ? obj?.services?.flatMap((group: any) => {
                            const fee = group?.fee;
                            return group?.services?.map((service: any) => ({
                                ...service,
                                fee: parseFloat(fee)
                            }));
                        }) : []
                        return {
                            ...obj || {},
                            services: groupedServices || []
                        }
                    })
                    return {
                        ...item || {},
                        carriersInfo: formatConfigCarriers || []
                    }
                })
                if (state?.shippingService?.shippingConfigsData?.length > 0) {
                    await dispatch(tryToEditMarketShippingServices({ marketId: selectedMarket?._id, data: { data: formatData } })).unwrap()
                } else {
                    await dispatch(tryToAddMarketShippingServices({ marketId: selectedMarket?._id, data: { data: formatData } })).unwrap()
                }
            }
            onCloseModal();
            onSaveChanges('success', type === 'delete' ? 'Shipping Services deleted successfully' : 'Successfully saved changes for market')
        } catch (err) {
            setSaveError(`${err}`)
        }
        setLoadingUpdate(false)
    };

    const onChangeSelectedItems = (e: any, type?: string) => {
        setSelectedMarket(e || undefined)
    };

    const onToggleActiveCategory = (currency: any) => {

    }

    const onChangeActiveAccordion = (id: string) => {
        setActiveAccordion(activeAccordion === id ? undefined : id)
    }

    const onChangeCarrierSelected = (value: any, id?: string, index?: any) => {
        if ((index || index === 0) && activeAccordion) {
            const findFormDataAssetTemplate = formData && formData?.length > 0 && formData?.find((item: any) => item?.assetTemplateName === activeAccordion)
            if (findFormDataAssetTemplate) {
                const findIndexCarrier = findFormDataAssetTemplate?.carriersInfo && findFormDataAssetTemplate?.carriersInfo?.length > 0 && findFormDataAssetTemplate?.carriersInfo?.find((item: any, idx: number) => idx === index)
                const filterOtherCategories = formData && formData?.length > 0 && formData?.filter((item: any) => item?.assetTemplateName !== activeAccordion)
                if (findIndexCarrier) {
                    const formatCategoryCarriers = (findFormDataAssetTemplate?.carriersInfo && findFormDataAssetTemplate?.carriersInfo?.length > 0) && findFormDataAssetTemplate?.carriersInfo?.map((item: any, idx: number) => {
                        if (idx === index) {
                            return {
                                carrier: value?.name,
                                services: []
                            }
                        } else {
                            return item
                        }
                    })
                    const formatCategory = {
                        ...findFormDataAssetTemplate || {},
                        carriersInfo: formatCategoryCarriers || []
                    }
                    const formatFormData = [
                        ...filterOtherCategories || [],
                        {
                            ...formatCategory || {}
                        }
                    ]
                    setFormData(formatFormData)
                } else {
                    const formatCategoryCarriers = [
                        ...findFormDataAssetTemplate?.carriersInfo || [],
                        {
                            carrier: value?.name,
                            services: []
                        }
                    ]
                    const formatCategory = {
                        ...findFormDataAssetTemplate || {},
                        carriersInfo: formatCategoryCarriers || []
                    }
                    const formatFormData = [
                        ...filterOtherCategories || [],
                        {
                            ...formatCategory || {}
                        }
                    ]
                    setFormData(formatFormData)
                }
            } else {
                const formatFormData: any = [
                    ...formData || [],
                    {
                        assetTemplateName: activeAccordion,
                        carriersInfo: [
                            {
                                carrier: value?.name,
                            }
                        ]
                    }
                ]
                setFormData(formatFormData || [])
            }
        }
    }

    const onChangeSelectedService = (values: any, carrier?: string, index?: any) => {
        if ((index || index === 0) && activeAccordion && carrier) {
            const findFormDataAssetTemplate = formData && formData?.length > 0 && formData?.find((item: any) => item?.assetTemplateName === activeAccordion)
            if (findFormDataAssetTemplate) {
                const findCarrier = (findFormDataAssetTemplate?.carriersInfo && findFormDataAssetTemplate?.carriersInfo?.length > 0) && findFormDataAssetTemplate?.carriersInfo?.find((item: any) => item?.carrier === carrier)
                if (findCarrier) {
                    const formatCarriers = (findFormDataAssetTemplate?.carriersInfo && findFormDataAssetTemplate?.carriersInfo?.length > 0) && findFormDataAssetTemplate?.carriersInfo?.map((item: any) => {
                        if (item?.carrier === carrier) {
                            const formatServices = (item?.services && item?.services?.length > 0) ? item?.services?.map((service: any, idx: number) => {
                                if (idx === index) {
                                    return {
                                        ...service || {},
                                        services: values && values?.length > 0 ? values?.map((value: any) => ({ description: value?.description, enabled: value?.enabled, _id: value?._id, name: value?.name })) : []
                                    }
                                } else {
                                    return service
                                }
                            }) :
                                [
                                    {
                                        fee: 0,
                                        services: values && values?.length > 0 ? values?.map((value: any) => ({ description: value?.description, enabled: value?.enabled, _id: value?._id, name: value?.name })) : []
                                    }
                                ]
                            return {
                                ...item || {},
                                services: formatServices || []
                            }
                        } else {
                            return item
                        }
                    })
                    const formatCatalogCarriers = {
                        ...findFormDataAssetTemplate || {},
                        carriersInfo: formatCarriers || []
                    }
                    const filterOtherCategories = formData && formData?.length > 0 && formData?.filter((item: any) => item?.assetTemplateName !== activeAccordion)
                    const formatFormData = [
                        ...filterOtherCategories || [],
                        {
                            ...formatCatalogCarriers || {}
                        }
                    ]
                    setFormData(formatFormData)
                }
            }
        }
    }

    const onChangeFee = (value: any, carrier?: string, index?: any) => {
        if ((index || index === 0) && activeAccordion && carrier) {
            const findFormDataAssetTemplate = formData && formData?.length > 0 && formData?.find((item: any) => item?.assetTemplateName === activeAccordion)
            if (findFormDataAssetTemplate) {
                const findCarrier = (findFormDataAssetTemplate?.carriersInfo && findFormDataAssetTemplate?.carriersInfo?.length > 0) && findFormDataAssetTemplate?.carriersInfo?.find((item: any) => item?.carrier === carrier)
                if (findCarrier) {
                    const formatCarriers = (findFormDataAssetTemplate?.carriersInfo && findFormDataAssetTemplate?.carriersInfo?.length > 0) && findFormDataAssetTemplate?.carriersInfo?.map((item: any) => {
                        if (item?.carrier === carrier) {
                            const formatServices = (item?.services && item?.services?.length > 0) ? item?.services?.map((service: any, idx: number) => {
                                if (idx === index) {
                                    return {
                                        ...service || {},
                                        fee: value
                                    }
                                } else {
                                    return service
                                }
                            }) :
                                [
                                    {
                                        fee: value,
                                        services: []
                                    }
                                ]
                            return {
                                ...item || {},
                                services: formatServices || []
                            }
                        } else {
                            return item
                        }
                    })
                    const formatCatalogCarriers = {
                        ...findFormDataAssetTemplate || {},
                        carriersInfo: formatCarriers || []
                    }
                    const filterOtherCategories = formData && formData?.length > 0 && formData?.filter((item: any) => item?.assetTemplateName !== activeAccordion)
                    const formatFormData = [
                        ...filterOtherCategories || [],
                        {
                            ...formatCatalogCarriers || {}
                        }
                    ]
                    setFormData(formatFormData)
                }
            }
        }
    }

    const onAddNewCarrier = () => {
        if (activeAccordion) {
            const findFormDataAssetTemplate = (formData && formData?.length > 0) && formData?.find((item: any) => item?.assetTemplateName === activeAccordion)
            if (findFormDataAssetTemplate) {
                const reformatCarriers = {
                    ...findFormDataAssetTemplate || {},
                    carriersInfo: [
                        ...findFormDataAssetTemplate?.carriersInfo || [],
                        {}
                    ]
                }
                const filterOtherCategories = formData && formData?.length > 0 && formData?.filter((item: any) => item?.assetTemplateName !== activeAccordion)
                setFormData([
                    ...filterOtherCategories || [],
                    {
                        ...reformatCarriers || {}
                    }
                ])
            } else {
                setFormData([
                    ...formData || [],
                    {
                        assetTemplateName: activeAccordion,
                        carriersInfo: [
                            {},
                            {}
                        ]
                    }
                ])
            }
        }
    }

    const onAddNewService = (index: number) => {
        if (activeAccordion && (index || index === 0)) {
            const findFormDataAssetTemplate = (formData && formData?.length > 0) && formData?.find((item: any) => item?.assetTemplateName === activeAccordion)
            if (findFormDataAssetTemplate) {
                const findIndexCarrier = ((findFormDataAssetTemplate?.carriersInfo && findFormDataAssetTemplate?.carriersInfo?.length > 0) ? findFormDataAssetTemplate?.carriersInfo : [{}])?.find((item: any, idx: number) => idx === index)
                const filterOtherCategories = formData && formData?.length > 0 && formData?.filter((item: any) => item?.assetTemplateName !== activeAccordion)
                if (findIndexCarrier) {
                    const formatCarrierServices = findFormDataAssetTemplate?.carriersInfo && findFormDataAssetTemplate?.carriersInfo?.length > 0 && findFormDataAssetTemplate?.carriersInfo?.map((item: any, idx: number) => {
                        if (idx === index) {
                            return {
                                ...item || {},
                                services: [
                                    ...item?.services || [],
                                    {}
                                ]
                            }
                        } else {
                            return item
                        }
                    })
                    const formatCategory = {
                        ...findFormDataAssetTemplate || {},
                        carriersInfo: formatCarrierServices || []
                    }
                    const formatFormData = [
                        ...filterOtherCategories || [],
                        {
                            ...formatCategory || {}
                        }
                    ]
                    setFormData(formatFormData)
                } else {
                    const formatCategoryCarriers = [
                        ...findFormDataAssetTemplate?.carriersInfo || [],
                        {
                            carrier: undefined,
                            services: [
                                {},
                                {}
                            ]
                        }
                    ]
                    const formatCategory = {
                        ...findFormDataAssetTemplate || {},
                        carriersInfo: formatCategoryCarriers || []
                    }
                    const formatFormData = [
                        ...filterOtherCategories || [],
                        {
                            ...formatCategory || {}
                        }
                    ]
                    setFormData(formatFormData)
                }
            }
        }
    }

    const onDeleteCarrier = (index: number) => {
        if (activeAccordion && (index || index === 0)) {
            const findFormDataAssetTemplate = (formData && formData?.length > 0) && formData?.find((item: any) => item?.assetTemplateName === activeAccordion)
            if (findFormDataAssetTemplate) {
                const filterOtherCategories = formData && formData?.length > 0 && formData?.filter((item: any) => item?.assetTemplateName !== activeAccordion)
                const filterOtherCarriers = findFormDataAssetTemplate?.carriersInfo && findFormDataAssetTemplate?.carriersInfo?.length > 0 && findFormDataAssetTemplate?.carriersInfo?.filter((item: any, idx: number) => idx !== index)
                const formatCategory = {
                    ...findFormDataAssetTemplate || {},
                    carriersInfo: filterOtherCarriers || []
                }
                const formatFormData = [
                    ...filterOtherCategories || [],
                    {
                        ...formatCategory || {}
                    }
                ]
                setFormData(formatFormData)
            }
        }
    }

    const onDeleteCarrierService = (carrierIndex: number, index: number) => {
        if (activeAccordion && (index || index === 0) && (carrierIndex || carrierIndex === 0)) {
            const findFormDataAssetTemplate = (formData && formData?.length > 0) && formData?.find((item: any) => item?.assetTemplateName === activeAccordion)
            if (findFormDataAssetTemplate) {
                const filterOtherCategories = formData && formData?.length > 0 && formData?.filter((item: any) => item?.assetTemplateName !== activeAccordion)
                const formatCarriers = findFormDataAssetTemplate?.carriersInfo && findFormDataAssetTemplate?.carriersInfo?.length > 0 && findFormDataAssetTemplate?.carriersInfo?.map((item: any, idx: number) => {
                    if (idx === carrierIndex) {
                        const filterServices = item?.services && item?.services?.length > 0 && item?.services?.filter((service: any, id: number) => id !== index)
                        return {
                            ...item || {},
                            services: filterServices || []
                        }
                    } else {
                        return item
                    }
                })
                const formatCategory = {
                    ...findFormDataAssetTemplate || {},
                    carriersInfo: formatCarriers || []
                }
                const formatFormData = [
                    ...filterOtherCategories || [],
                    {
                        ...formatCategory || {}
                    }
                ]
                setFormData(formatFormData)
            }
        }
    }

    return (
        <Modal
            open={openActionsModal}
            onClose={onCloseModal}
            contentContainerStyle='z-100 min-h-[400px] min-w-[60vw]'>
            <div className={'p-2 min-w-[55vw] z-100'}>
                <div className='border-b-2 border-slate-200 pb-2 mt-2 mb-4'>
                    <p className='font-medium text-slate-700'>Market Shipping Services Configurations</p>
                </div>
                {shippingServiceLoading ?
                    <MarketShippingServicesContainer />
                    :
                    <div>
                        <div className='flex flex-row justify-center min-w-[100%]'>
                            <div className='min-w-[300px]'>
                                <SelectSearchInside
                                    selectedOption={{ label: selectedMarket?.label, value: selectedMarket?._id, icon: selectedMarket?.icon }}
                                    options={markets || []}
                                    dataQa={'markets'}
                                    hideChevron={true}
                                    onChangeSelectedOption={onChangeSelectedItems}
                                    iconContainer='!min-w-[20px] !object-contain'
                                    labelContainer={'flex flex-row mb-2'}
                                    contentContainer='flex flex-col-reverse justify-center items-center min-w-[100%]'
                                    placeholder={selectedMarket?.label || 'Markets'}
                                />
                            </div>
                        </div>
                        {(sectors && sectors?.length > 0) && sectors?.map((category: any, index: any) => {
                            const findSectorsForCategory = formData && formData?.length > 0 && formData?.find((item: any) => item?.assetTemplateName === category?.name)
                            const filterCarriersUsed = (findSectorsForCategory?.carriersInfo && findSectorsForCategory?.carriersInfo?.length > 0) ? findSectorsForCategory?.carriersInfo?.map((carrier: any) => carrier?.carrier) : []
                            return (
                                <Accordion
                                    key={index}
                                    icon={activeAccordion === category?.name ? category?.enabledIcon : category?.disabledIcon}
                                    title={category?.label}
                                    itemActive={true}
                                    hideSwitch={true}
                                    dataQa={category?.name}
                                    divIcon={true}
                                    onToggleSwitch={() => onToggleActiveCategory(category?.name)}
                                    active={activeAccordion === category?.name}
                                    onToggleAccordion={() => onChangeActiveAccordion(category?.name)} >
                                    <div className='mb-[100px] mt-10'>
                                        {(findSectorsForCategory?.carriersInfo && findSectorsForCategory?.carriersInfo?.length > 0 ? findSectorsForCategory?.carriersInfo : [{}])?.map((obj: any, i: number) => {
                                            const findSelectedCarrier = (obj?.carrier && carriers && carriers?.length > 0) && carriers?.find((carrier: any) => carrier?.name === obj?.carrier)
                                            const servicesUsed = obj?.services?.flatMap((group: any) => group?.services?.length > 0 && group?.services?.map((service: any) => service?._id))
                                            const filteredCarriersToSelect = carriers?.filter((item: any) => !filterCarriersUsed?.includes(item?.name));
                                            if (findSelectedCarrier) {
                                                filteredCarriersToSelect?.push(findSelectedCarrier)
                                            }
                                            return (
                                                <div key={i} className='grid grid-cols-3 gap-4'>
                                                    <SelectCheckbox
                                                        placeholder='Carrier'
                                                        dataQa={'carrier'}
                                                        options={filteredCarriersToSelect || []}
                                                        selectedOption={findSelectedCarrier || undefined}
                                                        onChangeSelectedOption={onChangeCarrierSelected}
                                                        uniqueName={category?.name}
                                                        index={i}
                                                    />
                                                    {((obj?.services && obj?.services?.length > 0) ? obj?.services : [{}])?.map((service: any, key: number) => {
                                                        const filteredServicesToSelect = (findSelectedCarrier?.services && findSelectedCarrier?.services?.length) ? findSelectedCarrier?.services?.filter((item: any) => !(servicesUsed || [])?.includes(item._id)) : [];
                                                        if (filteredServicesToSelect) {
                                                            (service?.services && service?.services?.length > 0) && service?.services?.map((serviceItem: any) => filteredServicesToSelect?.push(serviceItem))
                                                        }
                                                        return (
                                                            <>
                                                                {key > 0 && <div></div>}
                                                                <div key={key} className=' col-span-2 flex-flex-col mb-2'>
                                                                    <div className='flex flex-row'>
                                                                        <SelectCheckbox
                                                                            placeholder='Service'
                                                                            dataQa={'service'}
                                                                            options={(filteredServicesToSelect && filteredServicesToSelect?.length > 0) ? filteredServicesToSelect?.map((objService: any) => ({ ...objService || {}, value: objService?._id, label: objService?.name })) : []}
                                                                            selectedOption={(service?.services && service?.services?.length > 0) ? service?.services?.map((objService: any) => ({ ...objService || {}, value: objService?._id, label: objService?.name })) : undefined}
                                                                            containerStyle='min-w-[45%] w-[45%] max-w-[45%] mr-2'
                                                                            onChangeSelectedOption={onChangeSelectedService}
                                                                            uniqueName={obj?.carrier}
                                                                            index={key}
                                                                            multiple={true}
                                                                        />
                                                                        <Input
                                                                            placeholder='Fee'
                                                                            dataQa={'fee'}
                                                                            containerStyle={'min-w-[30%] w-[30%] max-w-[30%] mx-2'}
                                                                            showValue={true}
                                                                            inputValue={service?.fee || null}
                                                                            inputUniqueName={obj?.carrier}
                                                                            onChangeInput={onChangeFee}
                                                                            index={key}
                                                                        />
                                                                        <div className='cursor-pointer' onClick={() => onDeleteCarrierService(i, key)}>
                                                                            <img data-qa={'delete-carrier-dervice'} src={'/assets/shared/close-grey.svg'} className={'w-[22px] object-contain mt-3 hover:w-[22px] mx-2'} />
                                                                        </div>
                                                                        {key === 0 &&
                                                                            <div data-qa={'delete-service'} className='cursor-pointer' onClick={() => onDeleteCarrier(i)}>
                                                                                <img src={'/assets/shared/trash-gray.svg'} className={'w-[20px] object-contain mt-3 hover:w-[22px] mx-2'} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {(obj?.services?.length || 1) - 1 === key && findSelectedCarrier &&
                                                                        <div className='flex flex-row justify-end mr-[20%]'>
                                                                            <Button
                                                                                label={'Add Service'}
                                                                                dataQa={'add-service'}
                                                                                onClickButton={() => onAddNewService(i)}
                                                                                className={'!shadow-none text-[13px] btn-primary-text-underline'}
                                                                            />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        }
                                        )}
                                        {((carriers?.length || 0) > (findSectorsForCategory?.carriersInfo?.length || 0)) &&
                                            <div className='flex flex-row justify-start mt-2'>
                                                <Button
                                                    label={'Add Carrier'}
                                                    dataQa={'add-carrier'}
                                                    onClickButton={onAddNewCarrier}
                                                    className={'btn-main !shadow'}
                                                />
                                            </div>
                                        }
                                    </div>
                                </Accordion>
                            )
                        })
                        }
                    </div>
                }
                {saveError &&
                    <div className='flex flex-row justify-center my-2'>
                        <Error text={saveError} />
                    </div>
                }
                <div className='min-w-full flex flex-row justify-center my-3 '>
                    {(state?.shippingService?.shippingConfigsData?.length > 0) &&
                        <Button
                            label={'Delete Settings'}
                            dataQa={'delete-settings'}
                            className={`${loadingUpdate ? 'btn-main-disable' : 'btn-error'} !rounded flex flex-row mr-4 items-center justify-center min-w-[150px] !shadow-none`}
                            onClickButton={() => !loadingUpdate && onSubmit('delete')}
                        />
                    }
                    <Button
                        label={'Set Market Configurations'}
                        dataQa={'set-market-configuration'}
                        className={`${loadingUpdate ? 'btn-main-disable' : 'btn-main'} !rounded  flex flex-row mr-2 items-center justify-center min-w-[150px] !shadow-none`}
                        onClickButton={() => !loadingUpdate && onSubmit()}
                    />
                </div>
            </div>
        </Modal>
    );
};
export default MarketShippingServicesModal;
