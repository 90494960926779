import React from 'react'


interface ISelectFrameStep {
    frames: any;
    onClickFrame: (frameSelected: any) => void;
}

const SelectFrameStep = ({
    frames,
    onClickFrame,
}: ISelectFrameStep) => {
    return (
        <div>
            <p className='mb-4 font-semibold text-sm'>Please select frame to continue</p>
            <div className='grid grid-cols-3 gap-4'>
                {frames && frames?.length > 0 && frames?.map((item: any, index: number) => {
                    return (
                        <div
                            key={index}
                            data-qa={'frame'}
                            onClick={() => onClickFrame(item?.url)}
                            className='bg-white shadow rounded p-2 h-[130px] w-full cursor-pointer mr-3 mt-2 hover:bg-[#f3f3f9]'>
                            <div className='bg-[#f3f3f9] rounded h-full w-full flex flex-row justify-center items-center'>
                                <img src={item?.url} className='obj-contain w-[50px]' />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SelectFrameStep;