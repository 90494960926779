import React, { useState, useEffect } from 'react';
import moment from 'moment'
import WhiteContainer from '../../../../shared/white-container';


interface ISellerResponseDetails {
    reviewedAt?: string;
}

const SellerResponseDetails = ({
    reviewedAt
}: ISellerResponseDetails) => {
    const [daysLeft, setDaysLeft] = useState<string | boolean>();

    const calculateTimeLeft = () => {
        const currentDate = moment();
        const targetDate = moment(reviewedAt);

        const difference = targetDate.diff(currentDate);
        if (difference < -7 * 24 * 60 * 60 * 1000) {
            setDaysLeft(false);
        } else {
            const remainingTime = moment.duration(7, 'days').subtract(moment.duration(difference));
            const days = Math.floor(remainingTime.asDays());
            const hours = remainingTime.hours();
            const minutes = remainingTime.minutes();
            const seconds = remainingTime.seconds();

            if (days > 1) {
                setDaysLeft(`${days} days`);
            } else if (days === 1) {
                setDaysLeft(`${days} day`);
            } else if (hours > 0) {
                setDaysLeft(`${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds} second${seconds !== 1 ? 's' : ''}`);
            } else if (minutes > 0) {
                setDaysLeft(`${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds} second${seconds !== 1 ? 's' : ''}`);
            } else {
                setDaysLeft(`${seconds} second${seconds !== 1 ? 's' : ''}`);
            }
        }
    }

    useEffect(() => {
        calculateTimeLeft()
    }, [reviewedAt])

    return (
        <WhiteContainer containerStyle='!p-0 !rounded'>
            <div className='bg-gradient-to-br from-[#FCEE21] to-[#F7931E] py-10 px-3 flex flex-col items-center !rounded'>
                <p className='text-[18px] font-bold text-center'>Waiting for seller&apos;s response</p>
                {daysLeft ?
                    <p className='text-center mt-2 mb-4'>Time left: {daysLeft}</p>
                    : <p className='text-center mt-2 mb-4'>7 Days Rule expired!</p>
                }
                <div className='flex flex-row justify-center my-4 flex-wrap max-w-[85%]'>
                    <p className='text-center'>If the seller doesn&apos;t respond within the time left period it will count as automatically accepted.</p>
                </div>
            </div>
        </WhiteContainer>
    );
};

export default SellerResponseDetails;
