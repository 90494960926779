export const SECTION_BUSINESS_TYPE = [
    {
        value: 'seller',
        label: 'Seller',
    },
    {
        value: 'buyer',
        label: 'Buyer',
    },
    {
        value: 'b2b',
        label: 'B2B',
    },
    {
        value: 'c2b',
        label: 'C2B',
    },
    {
        value: 'c2b2b',
        label: 'C2B2B',
    },
    {
        value: 'wing',
        label: 'Wing',
    },
];
