export const CURRENCY_SYMBOLS: any = {
    'GBP': '£',
    'GB': '£',
    'gb': '£',
    'gbp': '£',
    'usd': '$',
    'USD': '$',
    'eur': '€',
    'EUR': '€',
    'cad': 'CAD$',
    'CAD': 'CAD$',
    'AED': 'AED',
    'AFN': 'Af',
    'ALL': 'ALL',
    'AMD': 'AMD',
    'ARS': 'AR$',
    'AUD': 'AU$',
    'AZN': 'man.',
    'BAM': 'KM',
    'BDT': 'Tk',
    'BGN': 'BGN',
    'BHD': 'BHD',
    'BRL': 'R$',
    'CHF': 'CHF',
    'CLP': 'CL$',
    'CNY': 'CN¥',
    'CRC': '₡',
    'CZK': 'Kč',
    'DKK': 'Dkr',
    'EEK': 'Ekr',
    'HRK': 'kn',
    'LTL': 'Lt',
    'LVL': 'Ls',
    'MKD': 'MKD',
    'MXN': 'MX$',
    'NOK': 'Nkr',
    'NZD': 'NZ$',
    'PLN': 'zł',
    'RON': 'RON',
    'RSD': 'din.',
    'RUB': 'RUB',
    'SEK': 'kr',
    'TRY': 'TL',
    'UAH': '₴',
    '': ''
};