import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { SECTION_BUSINESS_TYPE } from '../../../utils/constants/section-business-types';
import { IToast } from '../../../interfaces/components/IToast';
import { IAddress } from '../../../interfaces/auth/ICreateUserSchema';
import { IPartnerWarehouse } from '../../../interfaces/partners/IPartnerWarehouse';
import { IPartnersMarket, IPartnersSector } from '../../../interfaces/partners/IPartners';
import {
    tryToAddPartner,
    tryToEditPartnerDetails,
    tryToFetchSinglePartner,
    tryToFetchPartnerWarehouses,
    tryToDeletePartnerWarehouse,
} from '../../../store/brokers/admin/partners/partnersSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import AddWarehouseAddressModal from './modals/AddWarehouseAddressModal';
import PartnerWarehouseItem from './details/PartnerWarehouseItem';
import Input from '../../../shared/input';
import Error from '../../../shared/error';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import SelectCheckbox from '../../../shared/select-checkbox';
import EmptyContainer from '../../../shared/empty-container';


type FormValues = {
    name: string;
    email: string;
    username: string;
    password: string;
    country: string;
    type: string;
    addresses: Array<IAddress>;
    priority?: number;
    companyName: string;
    companyRegistrationNumber: string;
    vatNumber: string;
    annualGMV?: string;
    phoneNumber: string;
    sectors: IPartnersSector[];
    markets: IPartnersMarket[];
    confirmPassword: string;
    url?: string;
};

const CreatePartner = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [createError, setCreateError] = useState<string | null>()
    const [sectorItems, setSectorItems] = useState<Array<any>>([])
    const [marketItems, setMarketItems] = useState<Array<any>>([])
    const [selectedSectors, setSelectedSectors] = useState<any>()
    const [selectedMarkets, setSelectedMarkets] = useState<any>()
    const [selectedBusinessType, setSelectedBusinessType] = useState<any>([])
    const [showAddWarehouseAddressModal, setShowAddWarehouseAddressModal] = useState(false)
    const [showToast, setShowToast] = useState<IToast | null>();
    const [selectedWarehouse, setSelectedWarehouse] = useState<IPartnerWarehouse | null>(null)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [getAllMarkets] = useGetAllMarketsMutation()
    const state = useAppSelector((state) => state?.assetsTemplates);
    const partner = useAppSelector((state) => state?.partners?.partnerDetail);
    const warehouses = useAppSelector((state) => state?.partners?.partnerDetailWarehouses);

    const getMarketsData = async () => {
        const response = await getAllMarkets(null).unwrap()
        const formatMarkets = response && response?.length > 0 && response?.map((market: any) => ({ ...market, value: market?._id }))
        setMarketItems(formatMarkets || [])
    }

    useEffect(() => {
        dispatch(tryToFetchAllAssetsTemplates());
        getMarketsData()
    }, []);

    useEffect(() => {
        if (state.allAssetTemplates) {
            const rows =
                state.allAssetTemplates && Array.isArray(state.allAssetTemplates)
                    ? state.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setSectorItems(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [state.allAssetTemplates]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue
    } = useForm<FormValues>({});

    const getPartnerDetailsData = async () => {
        if (id) {
            dispatch(tryToFetchSinglePartner(id)).unwrap()
            dispatch(tryToFetchPartnerWarehouses(id)).unwrap()
        } else {
            setSelectedMarkets(undefined)
            setSelectedSectors(undefined)
            setCreateError(undefined)
            setSelectedBusinessType([])
            reset()
        }
    }

    const onSetPartnerMarketsAndSectors = () => {
        if (partner && marketItems && marketItems?.length > 0 && sectorItems && sectorItems?.length > 0 && id) {
            id && reset(partner)
            const findMarkets = partner?.markets && partner?.markets?.length > 0 && partner?.markets?.map((market) => {
                const findDetails = marketItems?.length > 0 && marketItems?.find((item) => item?._id === market?.id)
                return {
                    label: findDetails?.label,
                    value: findDetails?._id,
                }
            })
            const findSectors = (partner?.sectors && partner?.sectors?.length > 0) && partner?.sectors?.map((sector) => {
                const findDetails = sectorItems?.length > 0 && sectorItems?.find((item) => item?._id === sector?.id)
                return {
                    label: findDetails?.label,
                    value: findDetails?._id,
                }
            })
            setSelectedMarkets(findMarkets || [])
            setSelectedSectors(findSectors || [])
            setSelectedBusinessType(partner?.businessType?.map((type: any) => {
                return SECTION_BUSINESS_TYPE.find(businessType => businessType.value === type)
            }))
        } else {
            reset()
        }
    }

    useEffect(() => {
        getPartnerDetailsData()
    }, [])

    useEffect(() => {
        getPartnerDetailsData()
        onSetPartnerMarketsAndSectors()
    }, [id])

    useEffect(() => {
        onSetPartnerMarketsAndSectors()
    }, [partner, marketItems, sectorItems])

    const onSubmit = async (data: FormValues) => {
        const filterSectors = selectedSectors?.map((sector: any) => {
            return { name: sector?.label, id: sector?.value }
        })

        const formatMarkets = selectedMarkets && selectedMarkets?.length > 0 && selectedMarkets?.map((market: any) => ({ id: market?.value }))
        const payload: any = {
            ...data,
            enabled: false,
            sectors: filterSectors,
            markets: formatMarkets,
            username: (data?.username || '').toLowerCase(),
            type: 'organization-account',
            dashboard: 'brokers',
            businessType: selectedBusinessType?.map((type: any) => type.value)
        };
        delete payload.priority
        delete payload.confirmPassword
        try {
            if (id) {
                payload._id = id
                payload.createdAt && delete payload.createdAt
                payload.updatedAt && delete payload.updatedAt
                await dispatch(tryToEditPartnerDetails(payload)).unwrap();

            } else {
                await dispatch(tryToAddPartner(payload)).unwrap();
            }
            reset();
            navigate('/users');
            setCreateError(null)
        } catch (error) {
            setCreateError(`${error}`)
        }
    };

    const onChangeSelectedOption = (e: any) => {
        if (id) {
            let findIfAnyFixedIsMissing = false
            selectedSectors?.filter((sector: any) => sector?.isFixed)?.map((sector: any) => {
                const findNewSelected = e?.length > 0 && e?.find((newSelected: any) => newSelected?.value === sector?.value)
                if (!findNewSelected) findIfAnyFixedIsMissing = true
                return sector;
            })
            if (findIfAnyFixedIsMissing) {
                return;
            }
        }
        setSelectedSectors(e?.length > 0 ? e?.map((s: any) => ({ ...s })) : [])
        setValue('sectors', e?.length > 0 ? e?.map((s: any) => s?.value) : [])
    }

    const onChangeSelectedMarket = (e: any) => {
        if (id) {
            let findIfAnyFixedIsMissing = false
            selectedMarkets?.filter((market: any) => market?.isFixed)?.map((market: any) => {
                const findNewSelected = e?.length > 0 && e?.find((newSelected: any) => newSelected?.value === market?.value)
                if (!findNewSelected) findIfAnyFixedIsMissing = true
                return market;
            })
            if (findIfAnyFixedIsMissing) {
                return;
            }
        }
        setSelectedMarkets(e?.length > 0 ? e?.map((s: any) => ({ ...s })) : [])
        setValue('markets', e?.length > 0 ? e?.map((s: any) => s?.value) : [])
    }

    const onAddBusinessType = (options: any) => {
        setSelectedBusinessType(options)
    }

    const onGoBack = () => {
        navigate('/users?type=partners')
    }

    const handleCloseModal = () => {
        setShowAddWarehouseAddressModal(false)
    }
    const onAddNewWarehouse = () => {
        setSelectedWarehouse(null)
        setShowAddWarehouseAddressModal(true)
    }

    const onEditWarehouse = (warehouse: IPartnerWarehouse) => {
        setSelectedWarehouse(warehouse)
        setShowAddWarehouseAddressModal(true)
    }

    const onOpenDeleteModal = (warehouse: IPartnerWarehouse) => {
        setSelectedWarehouse(warehouse)
        setOpenDeleteModal(true)
    }

    const onApproveDeletion = async () => {
        try {
            await dispatch(tryToDeletePartnerWarehouse({ id: selectedWarehouse?._id || '', data: { partnerId: selectedWarehouse?.partnerId || '', country: selectedWarehouse?.address.country || '' } })).unwrap()
            setShowToast({ type: 'success', message: 'Warehouse Address successfully deleted' });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        } finally {
            setOpenDeleteModal(false)
        }
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>{id ? 'Edit Partner' : 'Create Partner'}</p>
                </div>
            </div>
            <div className='mt-5 mb-2'>
                {createError && <Error text={createError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-3 gap-4 content-center'>
                        <Input
                            placeholder='Name'
                            dataQa={'create-partner-name'}
                            disabled={!!id}
                            register={register('name', {
                                required: {
                                    message: 'Name is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Name is required'
                            })}
                            error={errors.name?.message}
                        />
                        <Input
                            placeholder='Username'
                            dataQa={'create-partner-username'}
                            disabled={!!id}
                            register={register('username', {
                                required: {
                                    message: 'Username is required',
                                    value: true,
                                },
                                pattern: {
                                    /* eslint-disable-next-line */
                                    value: /^[^@\s]+$/,
                                    message: 'Username should not include spaces'
                                },
                                validate: (value: string) => !!value.trim() || 'Username is required'
                            })}
                            error={errors.username?.message}
                        />
                        <Input
                            placeholder='Email'
                            dataQa={'create-partner-email'}
                            disabled={!!id}
                            register={register('email', {
                                required: {
                                    message: 'Email is required',
                                    value: true,
                                },
                                pattern: {
                                    /* eslint-disable-next-line */
                                    value: /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please write a valid email'
                                },
                                validate: (value: string) => !!value.trim() || 'Email is required'
                            })}
                            error={errors.email?.message}
                        />
                        <Input
                            placeholder='Password'
                            dataQa={'create-partner-password'}
                            disabled={!!id}
                            type={'password'}
                            register={register('password', {
                                required: {
                                    message: 'Password is required',
                                    value: !id,
                                },
                                minLength: {
                                    value: 8,
                                    message: 'Minimum number of characters is 8'
                                },
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                    message: 'Password should be at least 8 characters long, and must contain at least 1 number, 1 lower case, 1 upper case and 1 special character(@$!%*?&)'
                                },
                                validate: (value: string) => id ? true : !!value.trim() || 'Password is required and min length is 8'
                            },
                            )}
                            error={errors.password?.message}
                        />
                        <Input
                            placeholder='Repeat Password'
                            dataQa={'create-partner-repeat-password'}
                            disabled={!!id}
                            type={'password'}
                            register={register('confirmPassword', {
                                required: {
                                    message: 'Repeat password is required',
                                    value: !id,
                                },
                                validate: (val: string) => {
                                    if (watch('password') !== val) {
                                        return 'Repeat password do not match';
                                    }
                                }
                            })}
                            error={errors.confirmPassword?.message}
                        />
                        <Input
                            placeholder='Company Name'
                            dataQa={'create-partner-company-name'}
                            register={register('companyName', {
                                required: {
                                    message: 'Company Name is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Company Name is required'
                            })}
                            error={errors.companyName?.message}
                        />
                        <Input
                            placeholder='Country'
                            dataQa={'create-partner-country'}
                            register={register('country', {
                                required: {
                                    message: 'Country is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Country is required'
                            })}
                            error={errors.country?.message}
                        />
                        <Input
                            placeholder='Partner URL'
                            dataQa={'create-partner-url'}
                            register={register('url')}
                            error={errors.url?.message}
                        />
                        <Input
                            placeholder='Company Registration Number'
                            dataQa={'create-partner-registration-number'}
                            register={register('companyRegistrationNumber', {
                                required: {
                                    message: 'Company Registration Number is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Company Registration Number is required'
                            })}
                            error={errors.companyRegistrationNumber?.message}
                        />
                        <Input
                            placeholder='VAT Number'
                            dataQa={'create-partner-VAT'}
                            register={register('vatNumber', {
                                required: {
                                    message: 'VAT Number is required',
                                    value: true,
                                },
                                minLength: {
                                    value: 8,
                                    message: 'Minimum number of characters is 8'
                                },
                                validate: (value: string) => !!value.trim() || 'VAT Number is required'
                            })}
                            error={errors.vatNumber?.message}
                        />
                        <Input
                            placeholder='Annual Turnover'
                            dataQa={'create-partner-GMV'}
                            register={register('annualGMV')}
                            error={errors.annualGMV?.message}
                        />
                        <Input
                            placeholder='Phone Number'
                            dataQa={'create-partner-phone-number'}
                            register={register('phoneNumber', {
                                required: {
                                    message: 'Phone Number is required',
                                    value: true,
                                },
                                pattern: {
                                    value: /^\+[0-9 ]+$/,
                                    message: 'Please write a valid number ex. +123456789'
                                },
                                validate: (value: string) => !!value.trim() || 'Phone Number is required'
                            })}
                            error={errors.phoneNumber?.message}
                        />
                        <SelectCheckbox
                            placeholder='Category'
                            onChangeSelectedOption={onChangeSelectedOption}
                            options={sectorItems}
                            selectedOption={selectedSectors}
                            dataQa={'create-partner-category'}
                            multiple={true}
                            error={(Object.keys(errors).length !== 0 && (!watch('sectors') || watch('sectors')?.length <= 0)) ? 'Category is required' : undefined}
                        />
                        <SelectCheckbox
                            placeholder='Market'
                            onChangeSelectedOption={onChangeSelectedMarket}
                            dataQa={'create-partner-market'}
                            options={marketItems}
                            selectedOption={selectedMarkets}
                            multiple={true}
                            error={(Object.keys(errors).length !== 0 && (!watch('markets') || watch('markets')?.length <= 0)) ? 'Market is required' : undefined}
                        />
                        <SelectCheckbox
                            placeholder='Bussiness Type'
                            multiple={true}
                            dataQa={'bussiness-type'}
                            options={SECTION_BUSINESS_TYPE}
                            onChangeSelectedOption={onAddBusinessType}
                            selectedOption={selectedBusinessType}
                        />
                    </div>

                    <div className='flex flex-row justify-start py-2'>
                        <Button
                            label={id ? 'Save Changes' : 'Create Partner'}
                            type={'submit'}
                            className={'btn-primary'}
                            dataQa={'create-partner-button'}
                        />
                    </div>
                </form>
            </div>
            {id && <div className='mt-5 mb-2'>
                <p className='page-title'>Warehouses</p>
                <div className='flex flex-row justify-end py-2'>
                    <Button
                        label='Add Warehouse Address'
                        className={'btn-primary'}
                        dataQa={'add-partner-warehouse-button'}
                        onClickButton={() => onAddNewWarehouse()}
                    />
                </div>
                <div className='grid lg:grid-cols-2 gap-5 mt-2'>
                    {warehouses && warehouses.length > 0 ? warehouses.map(warehouse => <PartnerWarehouseItem key={warehouse._id} warehouse={warehouse} markets={marketItems} onEditWarehouse={onEditWarehouse} onOpenDeleteModal={onOpenDeleteModal} />) : <EmptyContainer text={'No warehouses added yet'} showImage={true} />}
                </div>
            </div>}
            {id &&
                <AddWarehouseAddressModal
                    openModal={showAddWarehouseAddressModal}
                    handleCloseModal={handleCloseModal}
                    partnerId={id}
                    marketItems={marketItems}
                    setShowToast={setShowToast}
                    warehouse={selectedWarehouse} />
            }
            <DeleteModal
                openDeleteModal={openDeleteModal}
                handleCloseDeleteModal={() => setOpenDeleteModal(false)}
                onApproveDeletion={onApproveDeletion} />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div >
    )
}

export default CreatePartner;