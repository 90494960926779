import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';
import Error from '../../../../shared/error';

interface IRevalueModal {
    openModal: boolean;
    text: JSX.Element;
    title: string;
    revalueLoading: boolean;
    handleCloseModal: () => void;
    onSubmitSuccessfully: (amount: number) => void;
    maxAmount: number;
}

const RevalueModal = ({
    openModal,
    text,
    title,
    revalueLoading,
    handleCloseModal,
    onSubmitSuccessfully,
    maxAmount,
}: IRevalueModal) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: { amount: '' } });

    const onSubmit = (data: { amount: string }) => {
        onSubmitSuccessfully(parseFloat(data.amount));
    };

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            dataQa={'close-button'}
            showInRight
            contentContainerStyle='p-6 min-w-[500px] bg-white rounded-lg shadow-lg'>
            <form onSubmit={revalueLoading ? () => { } : handleSubmit(onSubmit)}>
                <div className='mb-4'>
                    <p className='text-gray-600 text-sm text-center'>Discrepancy Action</p>
                    <hr className='mt-2' />
                </div>
                <div className='mb-4'>
                    <p className='text-lg font-semibold text-gray-900'>{title}</p>
                    <hr className='mt-2' />
                </div>
                <div className='bg-[#F8F9FC] p-4'>
                    <p className='text-gray-700 mb-4'>{text}</p>
                    <div className='mb-4'>
                        <p className='font-medium text-gray-900'>Revalue Price</p>
                    </div>
                    <div className='w-full mb-6'>
                        <Controller
                            name='amount'
                            control={control}
                            rules={{
                                required: { value: true, message: 'This field is required' },
                                validate: (value) => Number(value) < maxAmount || `The value should be lower than ${maxAmount}.`,
                            }}
                            render={({ field }) => (
                                <input
                                    className='border border-gray-300 rounded w-full px-3 py-2 text-gray-700'
                                    {...field}
                                    type='number'
                                    step='0.01'
                                    placeholder={`Set Amount (Max Amount: ${maxAmount})`}
                                />
                            )}
                        />
                        {errors.amount?.message && <Error text={errors.amount?.message} />}
                    </div>
                    <p className='text-gray-500 text-sm mb-4'>A Credit Note will be created based on the final price and this item will be added to inventory.</p>
                    <div className='flex justify-between'>
                        <Button
                            onClickButton={handleCloseModal}
                            label="No"
                            className="w-full mr-2 py-2 text-center rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300"
                        />
                        <Button
                            icon={revalueLoading && <Loading />}
                            label="Yes"
                            dataQa="revalue-return"
                            disabled={revalueLoading}
                            type="submit"
                            className={`w-full ml-2 py-2 rounded-md bg-gradient-to-r from-[#FCEE21] to-[#F7931E] text-[#202020]`}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default RevalueModal;
