import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrdersService } from './ordersAPI';
import { AxiosResponse, AxiosError } from 'axios';
import { ITicket } from '../../../../interfaces/orders/ITicket';
import { IOrderListState } from '../../../../interfaces/orders/IOrdersList';
import { PaginatedOrders, PaginatedShippedOrders } from '../../../../interfaces/orders/paginatedStore.type';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { ISingleOrderSchema } from '../../../../interfaces/orders/IOrdersSchema';
import { ChangeImeiInterface } from '../../../../interfaces/orders/IOrdersActions';
import { OrdersFilterView } from '../../../../interfaces/orders/OrdersTypes';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';

const initialState: IOrderListState = {
    ordersAreLoading: true,
    ordersFilterView: 'INBOUND_ORDERS',
    orderIsLoading: false,
    orderIsActioned: {
        loading: false,
        dirty: false,
    },
    orderDetails: {
        isInReview: true,
        isOrderAccepted: false,
        isOrderRejected: false,
        awaitingUserConfirmation: false,
        commission: 0,
        postageFee: 0,
        totalCosts: 0,
        csRequired: false,
        tickets: {
            loading: false,
        },
    },
    imeiSetterModal: {
        isModalOpen: false,
    },
    revalueModal: {
        isModalOpen: false,
        readyToSubmit: false,
    },
    rejectModal: {
        isModalOpen: false,
        readyToSubmit: false,
    },
};

export const tryToFetchOrders = createAsyncThunk<PaginatedOrders, IPaginationPayload>(
    'ordersList/tryToFetchOrders',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await OrdersService.tryToFetchOrders(pageNumber, pageSize, filters, data);
        return result.data;
    },
);

export const tryToFetchHighLevelOrders = createAsyncThunk<any, IPaginationPayload>(
    'ordersList/tryToFetchHighLevelOrders',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await OrdersService.tryToFetchHighLevelOrders(pageNumber, pageSize, filters, data);
        return result.data;
    },
);

export const tryToFetchInboundOrders = createAsyncThunk<PaginatedOrders, IPaginationPayload>(
    'ordersList/tryToFetchInboundOrders',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await OrdersService.tryToFetchInboundOrders(pageNumber, pageSize, filters, data);
        return result.data;
    },
);

export const tryToFetchCashouts = createAsyncThunk<PaginatedOrders, IPaginationPayload>(
    'ordersList/tryToFetchCashouts',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await OrdersService.tryToFetchCashouts(pageNumber, pageSize, filters, data);
        return result.data;
    },
);

export const tryToFetchOrdersInReview = createAsyncThunk<PaginatedOrders, IPaginationPayload>(
    'ordersList/tryToFetchOrdersInReview',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await OrdersService.tryToFetchOrdersInReview(pageNumber, pageSize, filters, data);
        return result.data;
    },
);

export const tryToFetchOrdersInCs = createAsyncThunk<PaginatedOrders, IPaginationPayload>(
    'ordersList/tryToFetchOrdersInCs',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await OrdersService.tryToFetchOrdersInCs(pageNumber, pageSize, filters, data);
        return result.data;
    },
);

export const tryToFetchResolvedOrders = createAsyncThunk<PaginatedOrders, any>(
    'ordersList/tryToFetchResolvedOrders',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await OrdersService.tryToFetchResolvedOrders(pageNumber, pageSize, filters, data);
        return result.data;
    },
);

export const tryToFetchRecentOrders = createAsyncThunk<PaginatedOrders, any>(
    'ordersList/tryToFetchRecentOrders',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await OrdersService.tryToFetchRecentOrders(pageNumber, pageSize, filters, data);
        return result.data;
    },
);

export const tryToFetchSingleOrder = createAsyncThunk<ApiResponse<ISingleOrderSchema>, any>(
    'ordersList/tryToFetchSingleOrder',
    async ({ orderId, data }, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToFetchSingleOrder(orderId, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }

    },
);

export const tryToFindItemByImei = createAsyncThunk<any, string>(
    'orderDetail/tryToFindItemByImei',
    async (imei, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToFindItemByImei(imei);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);


export const tryToFetchImeiStatus = createAsyncThunk<any, { itemId: string, orderId: string; data: any }>(
    'orderDetail/tryToFetchImeiStatus',
    async ({ itemId, orderId, data }, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToFetchImeiStatus(orderId, itemId, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchImeiStatusCheck = createAsyncThunk<any, { itemId: string, orderId: string; data: any }>(
    'orderDetail/tryToFetchImeiStatusCheck',
    async ({ itemId, orderId, data }, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToFetchImeiStatusCheck(orderId, itemId, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToGetCurrentImeiStatus = createAsyncThunk<any, string>(
    'orderDetail/tryToGetCurrentImeiStatus',
    async (imei, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToGetCurrentImeiStatus(imei);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFindItemByTrackingNumber = createAsyncThunk<any, string>(
    'orderDetail/tryToFindItemByTrackingNumber',
    async (trackingNumber, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToFindItemByTrackingNumber(trackingNumber);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchOrdersCount = createAsyncThunk<any, { country: string, marketId: string, isMultiple?: boolean }>(
    'orderDetail/tryToFetchOrdersCount',
    async (data) => {
        const result = await OrdersService.tryToFetchOrdersCount(data);
        return result.data;
    },
);

export const tryToFetchOrderInsightInfo = createAsyncThunk<any, any>(
    'orderDetail/tryToFetchOrderInsightInfo',
    async (data) => {
        const result = await OrdersService.tryToFetchOrderInsightInfo(data);
        return result.data?.data;
    },
);

export const tryToFetchOrderInsightDetails = createAsyncThunk<any, any>(
    'orderDetail/tryToFetchOrderInsightDetails',
    async (data) => {
        const result = await OrdersService.tryToFetchOrderInsightDetails(data);
        return result.data?.data;
    },
);

export const tryToSetMissingPackage = createAsyncThunk<any, { shipmentId: string, itemId: string }>(
    'orderDetail/tryToSetMissingPackage',
    async (data, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToSetMissingPackage(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAcceptOrder = createAsyncThunk<any, any>(
    'orderDetail/tryToAcceptOrder',
    async ({ orderId, data }, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToAcceptOrder(orderId, data);
            return result.data;
        }
        catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAcceptAllOrderItems = createAsyncThunk<any, any>(
    'orderDetail/tryToAcceptAllOrderItems',
    async (id, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToAcceptAllOrderItems(id);
            return result.data;
        }
        catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeliverOrder = createAsyncThunk<any, string>(
    'orderDetail/tryToDeliverOrder',
    async (trackingNumber, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToDeliverOrder(trackingNumber);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);


export const tryToDeliverOrdersMultiple = createAsyncThunk<any, any>(
    'orderDetail/tryToDeliverOrdersMultiple',
    async ({ data, type }, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToDeliverOrdersMultiple(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err);
        }
    },
);

export const tryToRejectOrder = createAsyncThunk<void, { orderId: string; data: any }>(
    'orderDetail/tryToRejectOrder',
    async ({ orderId, data }, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToRejectOrder(orderId, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToRevalueOrder = createAsyncThunk<any, { orderId: string; data: any }>(
    'orderDetail/tryToRevalueOrder',
    async ({ orderId, data }, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToRevalueOrder(orderId, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToReviewOrder = createAsyncThunk<any, { orderId: string; data: any }>(
    'orderDetail/tryToReviewOrder',
    async ({ orderId, data }, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToReviewOrder(orderId, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToGetTickets = createAsyncThunk<ITicket[], { orderId: string }>(
    'orderDetail/tryToGetTickets',
    async ({ orderId }) => {
        const result = await OrdersService.tryToGetTickets(orderId);
        return result.data;
    },
);

export const tryToAddLocationOnOrder = createAsyncThunk<void, any>(
    'orderDetail/tryToAddLocationOnOrder',
    async ({ trackingNumber, data }, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToAddLocationOnOrder(trackingNumber, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const openImeiModal = createAsyncThunk<string, string>('ordersList/openImeiModal', async (orderId: string) => {
    return orderId;
});

export const closeImeiModal = createAsyncThunk<void, void>('ordersList/closeImeiModal', async () => { });

export const tryToChangeImei = createAsyncThunk<AxiosResponse<void>, ChangeImeiInterface>(
    'ordersList/changeImei',
    async (payload) => {
        return await OrdersService.tryToChangeImei(payload.itemId, payload.imei);
    },
);

export const tryToAddReturnTrackingNumber = createAsyncThunk<AxiosResponse<any>, any>(
    'ordersList/tryToAddReturnTrackingNumber',
    async (payload, { rejectWithValue }) => {
        try {
            return await OrdersService.tryToAddReturnTrackingNumber(payload.itemId, payload.data);
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFindAvoidedEmissionMonthlyData = createAsyncThunk<any, any>(
    'orderDetail/tryToFindAvoidedEmissionMonthlyData',
    async (data, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToFindAvoidedEmissionMonthlyData(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToToggleFMI = createAsyncThunk<AxiosResponse<void>, { orderId: string; fmi: boolean }>(
    'ordersList/tryToToggleFMI',
    async (payload) => {
        return await OrdersService.tryToToggleFMI(payload.orderId, payload.fmi);
    },
);

export const tryToFetchShippedOrders = createAsyncThunk<PaginatedShippedOrders, any>(
    'ordersList/tryToFetchShippedOrders',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await OrdersService.tryToFetchShippedOrders(pageNumber, pageSize, filters, data);
        return result.data;
    },
);

export const tryToFetchShippedOrderDetails = createAsyncThunk<PaginatedShippedOrders, any>(
    'ordersList/tryToFetchShippedOrderDetails',
    async ({ pageNumber, pageSize, id, filters, data }) => {
        const result = await OrdersService.tryToFetchShippedOrderDetails(pageNumber, pageSize, id, filters, data);
        return result.data;
    },
);

export const openRevalueModal = createAction('orderDetails/openRevalueModal', (orderId: string) => ({
    payload: {
        orderId,
    },
}));

export const closeRevalueModal = createAction('orderDetails/closeRevalueModal');

export const openRejectModal = createAction('orderDetails/openRejectModal', (orderId: string) => ({
    payload: {
        orderId,
    },
}));
export const closeRejectModal = createAction('orderDetails/closeRejectModal');

export const setRevalueMessage = createAction('orderDetails/setRevalueMessage', (message: string) => ({
    payload: {
        message,
    },
}));

export const setRevalueNewPrice = createAction('orderDetails/setRevalueNewPrice', (newPrice: number) => ({
    payload: {
        newPrice,
    },
}));

export const setRejectReason = createAction('orderDetails/setRejectReason', (reason: string) => ({
    payload: {
        reason,
    },
}));

export const changeOrdersFilter = createAction('ordersList/changeOrdersFilter', (filter: OrdersFilterView) => ({
    payload: {
        filter,
    },
}));

export const tryToRaiseTicketSuccessfull = createAsyncThunk<any, any>(
    'ordersList/tryToRaiseTicketSuccessfull',
    async () => {
        return true;
    });

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(changeOrdersFilter, (state, action) => {
                state.ordersFilterView = action.payload.filter;
            })
            .addCase(openRevalueModal, (state, action) => {
                state.revalueModal.isModalOpen = true;
                state.revalueModal.shipmentId = action.payload.orderId;
            })
            .addCase(closeRevalueModal, (state) => {
                state.revalueModal.isModalOpen = false;
                state.revalueModal.message = undefined;
                state.revalueModal.newPrice = undefined;
                state.revalueModal.shipmentId = undefined;
            })
            .addCase(setRevalueNewPrice, (state, action) => {
                if (action.payload.newPrice !== null) {
                    state.revalueModal.newPrice = action.payload.newPrice;
                } else {
                    state.revalueModal.newPrice = undefined;
                }
                state.revalueModal.readyToSubmit = !!(
                    state.revalueModal.shipmentId &&
                    state.revalueModal.message &&
                    state.revalueModal.newPrice
                );
            })
            .addCase(setRevalueMessage, (state, action) => {
                if (action.payload.message !== null) {
                    state.revalueModal.message = action.payload.message;
                } else {
                    state.revalueModal.message = undefined;
                }
                state.revalueModal.readyToSubmit = !!(
                    state.revalueModal.shipmentId &&
                    state.revalueModal.newPrice &&
                    state.revalueModal.message
                );
            })
            .addCase(openRejectModal, (state, action) => {
                state.rejectModal.isModalOpen = true;
                state.rejectModal.shipmentId = action.payload.orderId;
            })
            .addCase(closeRejectModal, (state) => {
                state.rejectModal.isModalOpen = false;
                state.rejectModal.reason = undefined;
                state.rejectModal.shipmentId = undefined;
            })
            .addCase(setRejectReason, (state, action) => {
                if (action.payload.reason !== null) {
                    state.rejectModal.reason = action.payload.reason;
                } else {
                    state.rejectModal.reason = undefined;
                }
                state.rejectModal.readyToSubmit = !!(state.rejectModal.shipmentId && state.rejectModal.reason);
            })

            // tryToFetchOrders
            .addCase(tryToFetchOrders.pending, (state) => {
                state.ordersAreLoading = true;
            })
            .addCase(tryToFetchOrders.rejected, (state) => {
                state.ordersAreLoading = false;
            })
            .addCase(tryToFetchOrders.fulfilled, (state, action) => {
                state.ordersAreLoading = false;
                state.orders = action.payload;
            })

            // tryToFetchHighLevelOrders
            .addCase(tryToFetchHighLevelOrders.pending, (state) => {
                state.ordersAreLoading = true;
            })
            .addCase(tryToFetchHighLevelOrders.rejected, (state) => {
                state.ordersAreLoading = false;
            })
            .addCase(tryToFetchHighLevelOrders.fulfilled, (state, action) => {
                state.ordersAreLoading = false;
                state.highLevelOrders = action.payload;
            })


            // tryToAcceptAllOrderItems
            .addCase(tryToAcceptAllOrderItems.pending, (state) => {
                state.orderIsLoading = true;
            })
            .addCase(tryToAcceptAllOrderItems.rejected, (state) => {
                state.orderIsLoading = false;
            })
            .addCase(tryToAcceptAllOrderItems.fulfilled, (state, action) => {
                state.orderIsLoading = false;
                if (state.highLevelOrders?.data?.elements) {
                    state.highLevelOrders.data.elements =
                        { ...state }.highLevelOrders?.data.elements.map((order) => {
                            if (order?._id === action.meta?.arg) {
                                return {
                                    ...order || {},
                                    status: 'reviewed'
                                }
                            } else {
                                return order
                            }
                        }) || [];
                }
            })

            // tryToFetchShippedOrders
            .addCase(tryToFetchShippedOrders.pending, (state) => {
                state.ordersAreLoading = true;
            })
            .addCase(tryToFetchShippedOrders.rejected, (state) => {
                state.ordersAreLoading = false;
            })
            .addCase(tryToFetchShippedOrders.fulfilled, (state, action) => {
                state.ordersAreLoading = false;
                state.shippedOrders = action.payload;
            })

            // tryToFetchShippedOrderDetails
            .addCase(tryToFetchShippedOrderDetails.pending, (state) => {
                state.ordersAreLoading = true;
            })
            .addCase(tryToFetchShippedOrderDetails.rejected, (state) => {
                state.ordersAreLoading = false;
            })
            .addCase(tryToFetchShippedOrderDetails.fulfilled, (state, action) => {
                state.ordersAreLoading = false;
                state.shippedOrderDetails = action.payload;
            })

            // tryToFetchInboundOrders
            .addCase(tryToFetchInboundOrders.pending, (state) => {
                state.ordersAreLoading = true;
            })
            .addCase(tryToFetchInboundOrders.rejected, (state) => {
                state.ordersAreLoading = false;
            })
            .addCase(tryToFetchInboundOrders.fulfilled, (state, action) => {
                state.ordersAreLoading = false;
                state.orders = action.payload;
            })

            // tryToFetchCashouts
            .addCase(tryToFetchCashouts.pending, (state) => {
                state.ordersAreLoading = true;
            })
            .addCase(tryToFetchCashouts.rejected, (state) => {
                state.ordersAreLoading = false;
            })
            .addCase(tryToFetchCashouts.fulfilled, (state, action) => {
                state.ordersAreLoading = false;
                state.orders = action.payload;
            })

            // tryToFetchResolvedOrders
            .addCase(tryToFetchResolvedOrders.pending, (state) => {
                state.ordersAreLoading = true;
            })
            .addCase(tryToFetchResolvedOrders.rejected, (state) => {
                state.ordersAreLoading = false;
            })
            .addCase(tryToFetchResolvedOrders.fulfilled, (state, action) => {
                state.ordersAreLoading = false;
                state.orders = action.payload;
            })

            // tryToFetchRecentOrders
            .addCase(tryToFetchRecentOrders.pending, (state) => {
                state.ordersAreLoading = true;
            })
            .addCase(tryToFetchRecentOrders.rejected, (state) => {
                state.ordersAreLoading = false;
            })
            .addCase(tryToFetchRecentOrders.fulfilled, (state, action) => {
                state.ordersAreLoading = false;
                state.orders = action.payload;
            })

            // tryToFetchOrdersInReview
            .addCase(tryToFetchOrdersInReview.pending, (state) => {
                state.ordersAreLoading = true;
            })
            .addCase(tryToFetchOrdersInReview.rejected, (state) => {
                state.ordersAreLoading = false;
            })
            .addCase(tryToFetchOrdersInReview.fulfilled, (state, action) => {
                state.ordersAreLoading = false;
                state.orders = action.payload;
            })

            // tryToFetchOrdersInCs
            .addCase(tryToFetchOrdersInCs.pending, (state) => {
                state.ordersAreLoading = true;
            })
            .addCase(tryToFetchOrdersInCs.rejected, (state) => {
                state.ordersAreLoading = false;
            })
            .addCase(tryToFetchOrdersInCs.fulfilled, (state, action) => {
                state.ordersAreLoading = false;
                state.orders = action.payload;
            })

            // tryToFetchSingleOrder
            .addCase(tryToFetchSingleOrder.pending, (state) => {
                state.orderIsLoading = !state.orderIsActioned.dirty;
                state.orderIsActioned = {
                    loading: false,
                    dirty: false,
                    action: undefined,
                };
            })
            .addCase(tryToFetchSingleOrder.rejected, (state) => {
                state.orderIsLoading = false;
            })
            .addCase(tryToFetchSingleOrder.fulfilled, (state, action) => {
                state.orderIsLoading = false;
                state.orderDetail = action.payload;
                const orderStatus = action.payload.data.orderData.status;
                const saleData = action.payload.data.orderData;
                state.orderDetails.awaitingUserConfirmation = orderStatus === 'awaiting_user_response';
                state.orderDetails.isOrderAccepted = orderStatus === 'device_accepted' || orderStatus === 'user_accepted_offer';
                state.orderDetails.isOrderRejected = orderStatus === 'device_rejected' || orderStatus === 'user_rejected_offer';
                state.orderDetails.isInReview = orderStatus === 'device_in_review';
                state.orderDetails.commission = saleData.commission;
                state.orderDetails.postageFee = saleData.inboundCost;
                state.orderDetails.totalCosts =
                    state.orderDetails.commission + state.orderDetails.postageFee + saleData.salePrice;
                state.orderDetails.csRequired = state.orderDetail.data.shipment.csRequired;
            })

            // tryToFetchOrdersCount
            .addCase(tryToFetchOrdersCount.pending, (state) => {
                state.orderIsLoading = true;
            })
            .addCase(tryToFetchOrdersCount.rejected, (state) => {
                state.orderIsLoading = false;
            })
            .addCase(tryToFetchOrdersCount.fulfilled, (state, action) => {
                state.orderIsLoading = false;
                state.ordersCount = action.payload?.data;
            })


            // tryToSetMissingPackage
            .addCase(tryToSetMissingPackage.pending, (state) => {
                state.orderIsLoading = true;
            })
            .addCase(tryToSetMissingPackage.rejected, (state) => {
                state.orderIsLoading = false;
            })
            .addCase(tryToSetMissingPackage.fulfilled, (state, action) => {
                state.orderIsLoading = false;
                if (state.orderDetail) {
                    state.orderDetail.data = {
                        ...state.orderDetail?.data,
                        item: {
                            ...state.orderDetail?.data?.item,
                            showAccept: false,
                            showInventory: false,
                            showReview: false,
                            showRevaluate: false,
                            showReject: false,
                            warehouseImages: action?.payload?.data?.warehouseImages || state?.orderDetail?.data?.item?.warehouseImages || undefined
                        },
                        orderData: {
                            ...state?.orderDetail?.data?.orderData,
                            status: 'item_missing'
                        }
                    }
                }
            })

            // tryToAddReturnTrackingNumber
            .addCase(tryToAddReturnTrackingNumber.pending, (state) => {
                state.orderIsLoading = true;
            })
            .addCase(tryToAddReturnTrackingNumber.rejected, (state) => {
                state.orderIsLoading = false;
            })
            .addCase(tryToAddReturnTrackingNumber.fulfilled, (state, action) => {
                state.orderIsLoading = false;
                if (state.orderDetail) {
                    state.orderDetail.data = {
                        ...state.orderDetail?.data,
                        item: {
                            ...state?.orderDetail?.data?.item,
                            returnTrackingNumber: action?.meta?.arg?.data?.returnTrackingNumber,
                            returnShippingLabel: action?.meta?.arg?.returnShippingLabel
                        },
                        orderData: {
                            ...state?.orderDetail?.data?.orderData,
                            status: 'returned'
                        }
                    }
                }
            })

            // tryToAddLocationOnOrder
            .addCase(tryToAddLocationOnOrder.fulfilled, (state, action) => {
                if (state.orderDetail) {
                    state.orderDetail.data = {
                        ...state.orderDetail?.data,
                        item: {
                            ...state?.orderDetail?.data?.item,
                            location: action?.meta?.arg?.data?.locations,
                            showInventory: false,
                        }
                    }
                }
            })

            .addCase(openImeiModal.fulfilled, (state, action) => {
                state.imeiSetterModal.isModalOpen = true;
                state.imeiSetterModal.shipmentId = action.payload;
            })

            .addCase(closeImeiModal.fulfilled, (state) => {
                state.imeiSetterModal.isModalOpen = false;
                state.imeiSetterModal.shipmentId = undefined;
            })

            .addCase(tryToChangeImei.fulfilled, (state) => {
                state.imeiSetterModal.isModalOpen = false;
                state.imeiSetterModal.shipmentId = undefined;
                // window.location.reload();
            })
            .addCase(tryToChangeImei.rejected, (state) => {
                state.imeiSetterModal.isModalOpen = false;
                state.imeiSetterModal.shipmentId = undefined;
            })
            .addCase(tryToAcceptOrder.pending, (state) => {
                state.orderIsActioned = {
                    loading: true,
                    dirty: true,
                    action: 'ACCEPTED',
                };
            })
            .addCase(tryToRejectOrder.pending, (state) => {
                state.orderIsActioned = {
                    loading: true,
                    dirty: true,
                    action: 'REJECTED',
                };
            })
            .addCase(tryToRevalueOrder.pending, (state) => {
                state.orderIsActioned = {
                    loading: true,
                    dirty: true,
                    action: 'REVALUED',
                };
            })
            .addCase(tryToReviewOrder.pending, (state) => {
                state.orderIsActioned = {
                    loading: true,
                    dirty: true,
                    action: 'REVALUED',
                };
            })
            .addCase(tryToAcceptOrder.fulfilled, (state, action) => {
                if (state.orderDetail) {
                    state.orderDetail.data = {
                        ...state.orderDetail?.data,
                        item: {
                            ...state.orderDetail?.data?.item,
                            warehouseAccepted: true,
                            showAccept: false,
                            showInventory: true,
                            showReview: false,
                            showRevaluate: false,
                            showReject: false,
                        },
                        orderData: {
                            ...state?.orderDetail?.data?.orderData,
                            status: 'device_accepted',
                        }
                    }
                }
                if (state.orders?.data?.elements) {
                    state.orders.data.elements =
                        { ...state }.orders?.data.elements.map((order) => {
                            if (order?.id === action.meta?.arg?.orderId) {
                                return {
                                    ...order || {},
                                    orderStatus: 'device_accepted'
                                }
                            } else {
                                return order
                            }
                        }) || [];
                }
            })
            .addCase(tryToRejectOrder.fulfilled, (state, action) => {
                if (state.orderDetail) {
                    state.orderDetail.data = {
                        ...state.orderDetail?.data,
                        item: {
                            ...state.orderDetail?.data?.item,
                            message: action?.meta?.arg?.data?.reason,
                            showReject: false,
                            showReview: false,
                            showRevaluate: false,
                            showAccept: false,
                            showInventory: false
                        },
                        orderData: {
                            ...state?.orderDetail?.data?.orderData,
                            localizedSalePrice: { ...state?.orderDetail?.data?.orderData?.localizedSalePrice || {}, amount: 0 },
                            localizedCommission: { ...state?.orderDetail?.data?.orderData?.localizedCommission || {}, amount: 0 },
                            salePrice: 0,
                            status: 'required_return',
                        }
                    }
                    state.orderDetails.changedStatus = 'required_return'
                    state.orderDetails.totalCosts = 0 + state.orderDetails.postageFee + 0;

                }
            })
            .addCase(tryToRevalueOrder.fulfilled, (state, action) => {
                const object: any = {};
                action?.meta?.arg?.data && action?.meta?.arg?.data?.forEach((value: any, key: any) => { object[key] = value });
                const jsonData: any = object;
                if (state.orderDetail) {
                    state.orderDetail.data = {
                        ...state.orderDetail?.data,
                        item: {
                            ...state?.orderDetail?.data?.item,
                            revaluedPrice: Number(jsonData?.newPrice),
                            message: jsonData?.message,
                            showRevaluate: false,
                            showReview: false,
                            warehouseImages: action?.payload?.item?.warehouseImages || state?.orderDetail?.data?.item?.warehouseImages || undefined
                        },
                        orderData: {
                            ...state?.orderDetail?.data?.orderData,
                            localizedSalePrice: {
                                ...state?.orderDetail?.data?.orderData?.localizedSalePrice,
                                amount: Number(jsonData?.newPrice || 0),
                            },
                            status: 'awaiting_user_response'
                        }
                    }
                }
            })

            .addCase(tryToReviewOrder.fulfilled, (state, action) => {
                const object: any = {};
                action?.meta?.arg?.data && action?.meta?.arg?.data?.forEach((value: any, key: any) => { object[key] = value });
                if (state.orderDetail) {
                    state.orderDetail.data = {
                        ...state.orderDetail?.data,
                        item: {
                            ...state?.orderDetail?.data?.item,
                            showRevaluate: false,
                            showReview: false,
                            warehouseImages: action?.payload?.item?.warehouseImages || state?.orderDetail?.data?.item?.warehouseImages || undefined
                        },
                        orderData: {
                            ...state?.orderDetail?.data?.orderData,
                            status: 'device_accepted'
                        }
                    }
                }
            })

            // tryToDeliverOrdersMultiple
            .addCase(tryToDeliverOrdersMultiple.pending, (state) => {
                state.orderIsLoading = true
            })
            .addCase(tryToDeliverOrdersMultiple.rejected, (state, action) => {
                state.orderIsLoading = false
            })
            .addCase(tryToDeliverOrdersMultiple.fulfilled, (state, action) => {
                state.orderIsLoading = false
                if (action?.meta?.arg?.type === 'order_details') {
                    if (action?.payload?.data?.failedPackages || action?.payload?.data?.failedPackages?.length === 0) {
                        if (state.orderDetail) {
                            const currentDate = new Date();
                            state.orderDetail.data = {
                                ...state.orderDetail?.data,
                                orderData: {
                                    ...state?.orderDetail?.data?.orderData || {},
                                    status: 'device_in_review'
                                },
                                item: {
                                    ...state?.orderDetail?.data?.item || {},
                                    showReject: true
                                },
                                shipment: {
                                    ...state.orderDetail?.data?.shipment || {},
                                    receivedDate: currentDate?.toISOString() || ''
                                }
                            }
                        }
                    }
                }
            })

            // tryToToggleFMI
            .addCase(tryToToggleFMI.pending, (state) => {
                state.orderIsActioned = {
                    loading: true,
                    dirty: true,
                    action: 'FMI',
                };
            })
            .addCase(tryToToggleFMI.fulfilled, (state) => {
                state.orderIsActioned = {
                    loading: false,
                    dirty: true,
                    action: 'FMI',
                };
            })

            // tryToFetchImeiStatusCheck
            .addCase(tryToFetchImeiStatusCheck.pending, (state) => {
                state.acceptIsLoading = true
            })
            .addCase(tryToFetchImeiStatusCheck.rejected, (state, action) => {
                state.acceptIsLoading = false
            })
            .addCase(tryToFetchImeiStatusCheck.fulfilled, (state, action) => {
                state.acceptIsLoading = false
                if (state.orderDetail) {
                    state.orderDetail.data = {
                        ...state.orderDetail?.data,
                        item: {
                            ...state?.orderDetail?.data?.item,
                            blackListStatus: action?.payload?.data?.blackliststatus,
                            itemCode: action?.meta?.arg?.data?.imei
                        }
                    }
                }
                if (state.orders?.data?.elements) {
                    state.orders.data.elements =
                        { ...state }.orders?.data.elements.map((order) => {
                            if (order?.id === action.meta?.arg?.orderId) {
                                return {
                                    ...order || {},
                                    itemCode: action?.meta?.arg?.data?.imei
                                }
                            } else {
                                return order
                            }
                        }) || [];
                }
            })

            // tryToGetTickets
            .addCase(tryToGetTickets.pending, (state) => {
                state.orderDetails.tickets.loading = true;
            })
            .addCase(tryToGetTickets.rejected, (state) => {
                state.orderDetails.tickets.loading = false;
            })
            .addCase(tryToGetTickets.fulfilled, (state, action) => {
                state.orderDetails.tickets.loading = false;
                state.orderDetails.tickets.data = action.payload;
            });
    },
});

export default ordersSlice.reducer;
