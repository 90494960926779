import React from 'react';
import { AdminGuard } from '../../../../guards/RolesGuard';
import Button from '../../../../shared/button';
import WhiteContainer from '../../../../shared/white-container';


interface IMappingItem {
    mapping: any;
    key?: number;
    showActions?: boolean;
    marketName?: string;
    warehouseName?: string;
    accessControl?: any;
    actions?: any;
    onEditMapping?: () => void;
    onChangeStatusMapping: () => void;
    onShowMappingDetails?: () => void;
    onCopyMapping: () => void;
}

const MappingItem = ({
    mapping,
    key,
    showActions,
    accessControl,
    actions,
    onEditMapping,
    onChangeStatusMapping,
    onCopyMapping,
}: IMappingItem) => {
    const isAdmin = AdminGuard()
    return (
        <WhiteContainer
            containerStyle='max-w-[90%]'
            type='rounded'
            key={key}
            dataQa={mapping?.name}>
            <div className='w-full h-full flex flex-col items-start justify-start'>
                <p className='font-bold text-[17px] mt-2 mb-5 !break-all'>{mapping?.name}</p>
                <div>
                    <div className='flex flex-row item-center mb-4'>
                        <img src={'/assets/shared/category.svg'} className={'w-[14px] object-contain mr-3'} />
                        <p>Category: {mapping?.catalog?.name}</p>
                    </div>
                    {(isAdmin && (mapping?.broker?.companyName || mapping?.broker?.name)) &&
                        <div className='flex flex-row item-center mb-4'>
                            <img src={'/assets/shared/broker.svg'} className={'w-[16px] object-contain mr-3'} />
                            <p>Broker: {mapping?.broker?.companyName || mapping?.broker?.name || ''}</p>
                        </div>
                    }
                    <div className='flex flex-row item-end mb-4'>
                        <div className={`${mapping?.status === 'COMPLETED' ? 'bg-[#F7931E]' : 'bg-yellow-400'} mt-1 self-center inline-block w-[15px] h-[15px] rounded-full mr-3`}></div>
                        <p>Status: {mapping?.status}</p>
                    </div>
                    {mapping?.pricingType &&
                        <div className='flex flex-row item-center mb-4'>
                            <img src={'/assets/shared/pricing-type.svg'} className={'w-[14px] object-contain mr-3'} />
                            <p>Type: {mapping?.pricingType}</p>
                        </div>
                    }
                </div>
                {showActions &&
                    <div className='mt-4 mb-2'>
                        {mapping?.status === 'DRAFT' &&
                            <Button
                                label={'Continue Edit'}
                                dataQa={'continue-edit'}
                                className={`${(!accessControl || actions?.['Edit Mapping']) ? 'btn-primary-text-underline' : 'btn-primary-text-underline-disable'}`}
                                onClickButton={() => (!accessControl || actions?.['Edit Mapping']) && onEditMapping?.()}
                            />
                        }
                        <Button
                            label={'Copy Mapping'}
                            dataQa={'copy-mapping'}
                            className={`${(!accessControl || actions?.['Copy Mapping']) ? 'btn-primary-text-underline' : 'btn-primary-text-underline-disable'}`}
                            onClickButton={() => (!accessControl || actions?.['Copy Mapping']) && onCopyMapping()}
                        />
                        <Button
                            label={mapping?.enabled ? 'Disable' : 'Enable'}
                            dataQa={'enable-disable'}
                            className={`${(!accessControl || actions?.['Enable Disable']) ? 'btn-primary-text-underline' : 'btn-primary-text-underline-disable'}`}
                            onClickButton={() => (!accessControl || actions?.['Enable Disable']) && onChangeStatusMapping()}
                        />
                    </div>
                }
            </div>
        </WhiteContainer>
    )
}

export default MappingItem;