import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    tryToDeleteRetailers,
    tryToEnableDisableRetailers,
    tryToFetchRetailers
} from '../../../store/brokers/admin/retailers/RetailersSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IToast } from '../../../interfaces/components/IToast';
import { IRetailers } from '../../../interfaces/retailers/IRetailers';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import RetailersTable from './tables/RetailersTable';
import RetailersFilters from './details/RetailersFilters';
import SearchWithButton from '../../../shared/search-with-button';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const Retailers = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IRetailers[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [showDeleteRetailerModal, setShowDeleteRetailerModal] = useState<{ show: boolean, id: string }>()
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [selectedEnabled, setSelectedEnabled] = useState<any>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [search, setSearch] = useState<string>()
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.retailers.retailers?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const rowsLoading = useAppSelector((state) => state.retailers.retailersAreLoading);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state) => state.retailers);
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.displayName, value: r?._id, label: r?.displayName }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const onGettingAllMarkets = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        onGettingAllMarkets()
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    useEffect(() => {
        if (state.retailers) {
            const rows =
                state.retailers?.data?.elements && Array.isArray(state.retailers?.data.elements)
                    ? state.retailers?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.retailers]);

    const onGettingFilters = (type?: string) => {
        let settingsFilters: any
        if (!type || type !== 'download') {
            settingsFilters = {
                ...paginationState
            }
        }
        if (selectedMarket) {
            settingsFilters.data = { market: selectedMarket ? selectedMarket?._id : '' }
        }
        if (selectedSector) {
            settingsFilters.data = {
                ...settingsFilters.data,
                catalog: selectedSector ? (selectedSector?.name || '').toLowerCase() : ''
            }
        }
        if (selectedEnabled?.value) {
            const value = selectedEnabled?.value === 'Yes'
            settingsFilters.data = {
                ...settingsFilters.data,
                enabled: value
            }
        }
        if (search) {
            settingsFilters.data = {
                ...settingsFilters.data,
                search: search
            }
        }
        return settingsFilters
    }

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    const getRetailersData = async () => {
        const settingsFilters = onGettingFilters()
        dispatch(tryToFetchRetailers(settingsFilters));
    }

    useEffect(() => {
        getRetailersData();
    }, [paginationState]);

    const onAddNewRetailer = () => {
        navigate('/users/retailer/create');
    }

    const onSeeRetailerDetails = async (id: string) => {
        navigate(`/users/retailer/${id}`);
    }

    const onEnableDisableRetailer = async (id: string, currentStatus: boolean) => {
        try {
            await dispatch(tryToEnableDisableRetailers({ id, status: !currentStatus })).unwrap();
            setShowToast({ type: 'success', message: `Retailer status changed successfully` });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
    }

    const onEditRetailer = (retailerId: string) => {
        navigate(`/users/retailer/edit/${retailerId}`)
    }

    const onSelectMarket = (value: string) => {
        setSelectedMarket(value)
        onResetPaginationState()
    }

    const onChangeSelectedBooleanItems = (value: string, name?: string) => {
        switch (name) {
            case 'enabled':
                setSelectedEnabled(value)
                break;
        }
        onResetPaginationState()
    }

    const onSelectSector = (value: string) => {
        setSelectedSector(value)
        onResetPaginationState()
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const clearAllFilters = () => {
        setSelectedMarket(undefined)
        setSelectedEnabled(undefined)
        setSelectedSector(undefined)
        setSearch(undefined)
        onResetPaginationState()
    }

    const onDeleteRetailerRequest = (id: string) => {
        setShowDeleteRetailerModal({ show: true, id: id })
    }

    const onDeleteRetailer = async () => {
        try {
            await dispatch(tryToDeleteRetailers(showDeleteRetailerModal?.id || '')).unwrap()
            setShowToast({ type: 'success', message: `Retailer deleted successfully` })
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
        setShowDeleteRetailerModal(undefined)
    }

    return (
        <div>
            <div>
                <RetailersFilters
                    markets={markets}
                    sectors={sectors}
                    selectedMarket={selectedMarket}
                    selectedSector={selectedSector}
                    selectedEnabled={selectedEnabled}
                    onSelectMarket={onSelectMarket}
                    onSelectSector={onSelectSector}
                    onChangeSelectedBooleanItems={onChangeSelectedBooleanItems}
                />
            </div>
            <div className='my-7 flex flex-row justify-end min-w-[100%]'>
                {(!accessControl || pageAccess?.actions?.['Add New Retailer']) &&
                    <Button
                        label={'Add New Retailer'}
                        onClickButton={onAddNewRetailer}
                        className={'btn-main !col-span-2'}
                        dataQa={'add-new-retailer'}
                    />
                }
            </div>
            <div>
                <SearchWithButton
                    placeholder='Filter retailers'
                    dataQa={'filter-retailers'}
                    search={search}
                    mainContainerStyle={'!grid-cols-10'}
                    onChangeSearchValue={onChangeSearchValue}
                    buttonLabel={'Clear All Filters'}
                    onClickButton={clearAllFilters}
                    buttonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end bg-transparent mt-1'}
                />
            </div>
            <div className='my-5 relative'>
                <RetailersTable
                    rows={rows}
                    rowsLoading={rowsLoading}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    onSeeRetailerDetails={onSeeRetailerDetails}
                    onEnableDisableRetailer={onEnableDisableRetailer}
                    setPaginationState={setPaginationState}
                    onEditRetailer={onEditRetailer}
                    onDeleteRetailerRequest={onDeleteRetailerRequest}
                />
            </div>
            {showDeleteRetailerModal?.show &&
                <DeleteModal
                    openDeleteModal={showDeleteRetailerModal?.show}
                    onApproveDeletion={onDeleteRetailer}
                    handleCloseDeleteModal={() => setShowDeleteRetailerModal(undefined)}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }

        </div>
    )
}

export default Retailers;