import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import { IAssetFields } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { tryToChangeTemplatePricingField } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IFieldPricingModal {
    openModal: boolean;
    assetTemplateId: string;
    handleCloseModal: () => void;
}

const FieldPricingModal = ({
    openModal,
    assetTemplateId,
    handleCloseModal,
}: IFieldPricingModal) => {
    const dispatch = useAppDispatch();
    const [fields, setFields] = useState<Array<ISelectedValue & IAssetFields>>();
    const [fieldSelected, setFieldSelected] = useState<ISelectedValue & IAssetFields>();
    const [selectedValue, setSelectedValue] = useState<ISelectedValue>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [submitErr, setSubmitErr] = useState<string>()
    const state = useAppSelector((state) => state.assetsTemplates);

    useEffect(() => {
        if (state.assetTemplateDetails) {
            const rows =
                state.assetTemplateDetails && state.assetTemplateDetails.fields && Array.isArray(state.assetTemplateDetails.fields)
                    ? state.assetTemplateDetails.fields?.filter((r) => r?.enabled && r?.dataType === 'list').map((r) => ({ ...r, label: r?.name, value: r?.name }))
                    : [];
            setFields(rows);
            if (state.assetTemplateDetails?.defaultPriceField) {
                const readField = state.assetTemplateDetails?.defaultPriceField ? Object.keys(state.assetTemplateDetails?.defaultPriceField) : []
                const findFieldDetails = readField && readField[0] && rows && rows?.length > 0 && rows?.find((item: IAssetFields & ISelectedValue) => item?.name === readField[0])
                setFieldSelected(findFieldDetails || undefined)
                if (state.assetTemplateDetails?.defaultPriceField?.[readField[0]]) {
                    setSelectedValue({ value: state.assetTemplateDetails?.defaultPriceField?.[readField[0]], label: state.assetTemplateDetails?.defaultPriceField?.[readField[0]] })
                }
            } else {
                setFieldSelected(undefined)
                setSelectedValue(undefined)
            }
        }
    }, [state.assetTemplateDetails]);

    const onCloseModal = () => {
        handleCloseModal();
        setSubmitErr(undefined);
    }

    const onSubmit = async () => {
        setLoadingSubmit(true)
        try {
            const formatData = fieldSelected ? {
                [fieldSelected.value]: selectedValue?.value
            } : {}
            await dispatch(tryToChangeTemplatePricingField({ assetTemplateId, data: formatData }))
            onCloseModal()
        } catch (err) {
            setSubmitErr(`${err}`)
        }
        setLoadingSubmit(false)
    };

    const onChangeSelectedField = (e: any) => {
        setFieldSelected(e)
        setSelectedValue(undefined)
    }

    const onChangeSelectedFieldValue = (e: ISelectedValue | undefined) => {
        setSelectedValue(e)
    }

    const onDeleteValues = () => {
        setFieldSelected(undefined)
        setSelectedValue(undefined)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Asset Catalog Field Pricing</p>
                </div>
                {submitErr && <Error text={submitErr} />}
                <div>
                    <SelectCheckbox
                        name='Select Field'
                        placeholder=' '
                        containerStyle='mb-3'
                        selectedOption={fieldSelected}
                        options={fields || []}
                        onChangeSelectedOption={onChangeSelectedField}
                    />
                    {fieldSelected &&
                        <SelectCheckbox
                            name='Select Value'
                            placeholder=' '
                            selectedOption={selectedValue}
                            options={(fieldSelected?.values && fieldSelected?.values?.length > 0) ? fieldSelected?.values?.map((item: string) => ({ label: item, value: item })) : []}
                            onChangeSelectedOption={onChangeSelectedFieldValue}
                        />
                    }
                    <div className='flex flex-row justify-around my-4'>
                        <Button
                            label={'Delete Selected'}
                            className='btn-main !min-w-[200px]'
                            onClickButton={onDeleteValues}
                        />
                        <Button
                            label={'Save Changes'}
                            className='btn-main !min-w-[200px]'
                            onClickButton={() => !loadingSubmit && onSubmit()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default FieldPricingModal;