import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { authApi } from '../store/auth/authApi';
import { userAPI } from '../store/user/userApi';
import { userDomAPI } from '../store/user/userDomApi';
import { shipmentsAPI } from '../store/brokers/partners/orders/shippmentsAPI';
import { carbonImpactAPI } from '../store/brokers/partners/carbon-impact/CarbonImpactApi';
import { templatesAPI } from '../store/brokers/partners/download-templates/downloadTemplatesApi';
import { addressCheckDomAPI } from '../store/brokers/partners/address-check/addressCheckDomApi';
import tacListSlice from '../store/brokers/admin/tac/tacSlice';
import assetsSlice from '../store/brokers/admin/assets/assetsSlice';
import marketsSlice from '../store/brokers/admin/markets/marketsSlice';
import ordersSlice from '../store/brokers/partners/orders/ordersSlice';
import agentsSlice from '../store/brokers/partners/agents/agentsSlice';
import partnersSlice from '../store/brokers/admin/partners/partnersSlice';
import pricingSlice from '../store/brokers/partners/pricing/pricingSlice';
import sectionsSlice from '../store/brokers/admin/sections/sectionsSlice';
import csAgentsSlice from '../store/brokers/admin/cs-agents/CSAgentsSlice';
import retailersSlice from '../store/brokers/admin/retailers/RetailersSlice';
import invoicesSlice from '../store/brokers/partners/invoices/invoicesSlice';
import uiActionsSlice from '../store/brokers/admin/ui-actions/uiActionsSlice';
import subBrandsSlice from '../store/brokers/admin/sub-brands/subBrandsSlice';
import reportsSlice from '../store/brokers/admin/reports/reportsServiceSlice';
import warehouseSlice from '../store/brokers/admin/warehouses/warehousesSlice';
import postageFeesSlice from '../store/brokers/admin/postage-fees/postageFeesSlice';
import payServicesSlice from '../store/brokers/admin/pay-services/payServicesSlice';
import salesOrdersSlice from '../store/brokers/partners/sales-orders/salesOrdersSlice';
import scrapingFeesSlice from '../store/brokers/admin/scraping-fees/scrapingFeesSlice';
import questionsSlice from '../store/brokers/shared-endpoints/questions/questionsSlice';
import rentalPricingSlice from '../store/brokers/admin/rental-pricing/rentalPricingSlice';
import customerCareSlice from '../store/brokers/partners/customer-care/customerCareSlice';
import userAccessSlice from '../store/brokers/partners/user-access-control/userAccessSlice';
import pricingEffectsSlice from '../store/brokers/admin/pricing-effects/pricingEffectsSlice';
import itemAllocationSlice from '../store/brokers/admin/item-allocation/itemAllocationSlice';
import commissionFeesSlice from '../store/brokers/admin/commission-fees/commissionFeesSlice';
import assetsTemplatesSlice from '../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import purchaseOrdersSlice from '../store/brokers/partners/purchase-orders/purchaseOrdersSlice';
import comparisonSitesSlice from '../store/brokers/admin/comparison-sites/comparisonSitesSlice';
import invoiceTemplateSlice from '../store/brokers/admin/invoice-templates/invoiceTemplateSlice';
import fieldSettingsSlice from '../store/brokers/admin/broker-fields-settings/fieldSettingsSlice';
import multipleCashoutSlice from '../store/brokers/partners/multiple-cashout/multipleCashoutSlice';
import shippingServicesSlice from '../store/brokers/admin/shipping-services/shippingServicesSlice';
import pricingReviewsSlice from '../store/brokers/shared-endpoints/pricing-reviews/pricingReviewsSlice';
import wholesalePriceSlice from '../store/brokers/shared-endpoints/wholesale-price/wholesalePriceSlice';
import pricingHistorySlice from '../store/brokers/shared-endpoints/pricing-history/pricingHistorySlice';
import comparisonPricingSlice from '../store/brokers/admin/comparison-sites-pricing/comparisonPricingSlice';
import categoryMappingsSlice from '../store/brokers/shared-endpoints/category-mappings/categoryMappingsSlice';
import notificationSectionsSlice from '../store/brokers/admin/notification-sections/notificationSectionsSlice';
import brokerNotificationsSlice from '../store/brokers/partners/broker-notifications/brokerNotificationsSlice';
import rentalPricingSettingsSlice from '../store/brokers/admin/rental-pricing-settings/rentalPricingSettingsSlice';
import brokersComparisonSitesSlice from '../store/brokers/admin/brokers-comparison-sites/brokersComparisonSitesSlice';
import wholesalePriceHistorySlice from '../store/brokers/shared-endpoints/wholesale-price-history/wholesalePriceHistorySlice';
import RMAsSlice from '../store/brokers/admin/rma/RMAsSlice';
import partnerRMAsSlice from '../store/brokers/partners/rma/partnerRMAsSlice';
import aiAgentsSlice from '../store/brokers/ai-agents/aiAgentsSlice';
import assetLibrarySlice from '../store/brokers/partners/asset-library/assetLibrarySlice';

export const store = configureStore({
    reducer: {
        [userAPI.reducerPath]: userAPI.reducer,
        [shipmentsAPI.reducerPath]: shipmentsAPI.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [userDomAPI.reducerPath]: userDomAPI.reducer,
        [templatesAPI.reducerPath]: templatesAPI.reducer,
        [carbonImpactAPI.reducerPath]: carbonImpactAPI.reducer,
        [addressCheckDomAPI.reducerPath]: addressCheckDomAPI.reducer,
        partners: partnersSlice,
        assetsTemplates: assetsTemplatesSlice,
        agents: agentsSlice,
        assets: assetsSlice,
        cs: customerCareSlice,
        orders: ordersSlice,
        markets: marketsSlice,
        pricings: pricingSlice,
        warehouses: warehouseSlice,
        pricingReviews: pricingReviewsSlice,
        invoices: invoicesSlice,
        postageFees: postageFeesSlice,
        scrapingFees: scrapingFeesSlice,
        wholesale: wholesalePriceSlice,
        tacList: tacListSlice,
        commissionFees: commissionFeesSlice,
        payServices: payServicesSlice,
        csAgents: csAgentsSlice,
        pricingHistory: pricingHistorySlice,
        subBrands: subBrandsSlice,
        comparisonSites: comparisonSitesSlice,
        reports: reportsSlice,
        categoryMapping: categoryMappingsSlice,
        uiActions: uiActionsSlice,
        userAccess: userAccessSlice,
        sections: sectionsSlice,
        wholesaleHistory: wholesalePriceHistorySlice,
        invoiceTemplates: invoiceTemplateSlice,
        retailers: retailersSlice,
        questions: questionsSlice,
        rentalPricing: rentalPricingSlice,
        rentalPricingSettings: rentalPricingSettingsSlice,
        comparisonPricing: comparisonPricingSlice,
        brokersComparison: brokersComparisonSitesSlice,
        pricingEffects: pricingEffectsSlice,
        notificationSections: notificationSectionsSlice,
        brokerNotifications: brokerNotificationsSlice,
        shippingServices: shippingServicesSlice,
        RMAs: RMAsSlice,
        partnersRMAs: partnerRMAsSlice,
        itemAllocation: itemAllocationSlice,
        multipleCashout: multipleCashoutSlice,
        purchaseOrders: purchaseOrdersSlice,
        aiAgents: aiAgentsSlice,
        salesOrders: salesOrdersSlice,
        assetLibrary: assetLibrarySlice,
        fieldSettings: fieldSettingsSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            userAPI.middleware,
            authApi.middleware,
            userDomAPI.middleware,
            shipmentsAPI.middleware,
            templatesAPI.middleware,
            carbonImpactAPI.middleware,
            addressCheckDomAPI.middleware,
        ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
