import React from 'react'
import { useAppSelector } from '../../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import Textarea from '../../../../shared/textarea';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';


interface IRevisedOfferModal {
    reviseInfo: {
        revisedOffer: boolean
        revisedMessage: string
        initialPrice: number
        currentPrice: number
    }
    handleCloseModal: () => void
}

const RevisedOfferModal = ({
    reviseInfo,
    handleCloseModal
}: IRevisedOfferModal) => {
    const activeMarket = useAppSelector((state) => state?.partners?.partnerActiveMarket)
    return (
        <Modal
            open={reviseInfo.revisedOffer}
            onClose={handleCloseModal}
            dataQa={'close-button'}
            showInRight={true}
            contentContainerStyle='!min-w-[650px]'>
            <div className={'p-2 min-w-[600px] min-h-[500px] relative'}>
                <p className='mt-2 mb-1 !text-[18px] mr-10'>Sealing the deal</p>
                <div className='mt-2 mb-5 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                <div className='mt-3 bg-[#f8f9fc] rounded px-4 pt-3 pb-1'>
                    <p className='text-[#00B67A] text-[16px] mb-2'>Price Found</p>
                    <p className='mt-5 mb-3'>Set price</p>
                </div>
                <form>
                    <div className='bg-[#f8f9fc] rounded px-4 pt-3 pb-1'>
                        <Input
                            label={`Amount (Initial Price was ${CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.descriptor?.name || '')}`]}${reviseInfo.initialPrice}) `}
                            type='text'
                            showValue
                            inputValue={reviseInfo.currentPrice}
                            disabled
                        />
                        <Textarea
                            placeholder={reviseInfo?.revisedMessage || ''}
                            value={reviseInfo?.revisedMessage}
                            rows={4}
                            containerStyle=''
                            onChangeTextarea={() => { }}
                            disabled
                            className={'!bg-white w-full !resize-y'}
                        />
                    </div>
                    <div className='min-w-full flex flex-row my-3 justify-center'>
                        <Button
                            label={'Seal The Deal'}
                            
                            className='btn-main min-w-[150px] !shadow-none'
                            type='button'
                            onClickButton={handleCloseModal}
                        />
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default RevisedOfferModal;