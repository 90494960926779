import React from 'react';
import Table from '../../../../shared/table';

const tableColumns = ['', '', '', '', '']

const ShippingServicesTableContainer = () => {
    return (
        <Table
            containerStyle={'mt-0'}
            columns={tableColumns}
            hidePagination={true}
        >
            <>
                {[...Array(5)].map((x, i) => {
                    return (
                        <tr key={i}>
                            {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                return (
                                    <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                )
                            })}
                        </tr>
                    )
                })}
            </>
        </Table >
    )
}

export default ShippingServicesTableContainer;