import React, { useEffect, useState } from 'react'
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Textarea from '../../../../shared/textarea';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import { REVIEW_REASONS } from '../../../../utils/constants/review-reasons';


interface IRevaluateMessageModal {
    openModal: boolean;
    revaluateError?: string;
    priceFound?: number;
    register: any;
    watch: any;
    errors?: any;
    onSubmit: any;
    setValue: any;
    category?: string;
    loadingSubmit: boolean;
    activeMarket?: IActiveMarket;
    handleSubmit: any;
    handleCloseModal: () => void;
    onChangeValueMessage: (e: string) => void;
}

const RevaluateMessageModal = ({
    openModal,
    errors,
    watch,
    register,
    priceFound,
    category,
    setValue,
    revaluateError,
    activeMarket,
    loadingSubmit,
    handleSubmit,
    onSubmit,
    handleCloseModal,
    onChangeValueMessage
}: IRevaluateMessageModal) => {
    const [selectedReason, setSelectedReason] = useState<ISelectedValue>()

    useEffect(() => {
        if (watch('reason') && !selectedReason) {
            setSelectedReason({ value: watch('reason'), label: watch('reason') })
        }
    }, [watch('reason')])

    const onChangeReason = (e: ISelectedValue) => {
        setSelectedReason(e)
        setValue('reason', e?.value || undefined)
    }

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            dataQa={'close-button'}
            showInRight={true}
            contentContainerStyle='!min-w-[650px]'>
            <div className={'p-2 min-w-[600px] min-h-[500px] relative'}>
                <p className='mt-2 mb-1 !text-[18px] mr-10'>Sealing the deal</p>
                <div className='mt-2 mb-5 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                {revaluateError && <Error text={revaluateError} />}
                <div className='mt-3 bg-[#f8f9fc] rounded px-4 pt-3 pb-1'>
                    <p className='text-primary-default text-[16px] mb-2'>{priceFound ? 'Price found from your pricing' : 'No price found!'}</p>
                    <p className='mt-5 mb-3'>Set price</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='bg-[#f8f9fc] rounded px-4 pt-3 pb-1'>
                        <Input
                            label={`Amount (${CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.descriptor?.name || '')}`]})`}
                            type='text'
                            // disabled={!!priceFound}
                            register={register('newPrice', {
                                required: {
                                    message: 'Amount is required',
                                    value: true,
                                },
                                pattern: {
                                    value: /^-?[0-9]\d*\.?\d*$/,
                                    message: 'Only numbers are accepted.'
                                },
                                validate: (value: number) => (Number(value) >= 0) || 'Amount should be a positive number'
                            })}
                            error={errors.newPrice?.message}
                        />
                        {category === 'clothing' &&
                            <SelectCheckbox
                                selectedOption={selectedReason}
                                key={1}
                                placeholder=' '
                                name='Reason'
                                containerStyle='mb-1 w-full'
                                onChangeSelectedOption={onChangeReason}
                                options={REVIEW_REASONS}
                            />
                        }
                        <Textarea
                            placeholder='Message for seller'
                            rows={4}
                            containerStyle=''
                            value={watch('message')}
                            className={'!bg-white w-full !resize-y'}
                            onChangeTextarea={onChangeValueMessage}
                            error={errors.message?.message}
                        />
                    </div>
                    <div className='min-w-full flex flex-row my-3 justify-center'>
                        <Button
                            label={'Seal The Deal'}
                            disabled={loadingSubmit}
                            className={loadingSubmit ? 'btn-main-disable cursor-not-allowed min-w-[150px]' : 'btn-main min-w-[150px] !shadow-none'}
                            type='submit'
                        />
                    </div>
                </form>
            </div>
        </Modal >
    )
}

export default RevaluateMessageModal;