import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';


interface IShipOrdersModal {
    openModal: boolean;
    handleCloseModal: () => void;
    onApproveAction: () => void;
}

const ShipOrdersModal = ({
    openModal,
    handleCloseModal,
    onApproveAction,
}: IShipOrdersModal) => {
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            dataQa={'close-modal'}
        >
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>{'Ship Items'}</p>
                </div>
                <p className='my-2'>{'Are you sure you want to ship all the items in this invoice to the broker?'}</p>
                <div className='flex flex-row justify-end my-2'>
                    <Button
                        label='Continue'
                        dataQa={'continue-button'}
                        className={'btn-primary'}
                        onClickButton={onApproveAction}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default ShipOrdersModal;