import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import { ISalesGradeMapping } from '../../../../interfaces/asset-templates/ISalesGradeMapping';
import {
    tryToAddAssetTemplateMappings,
    tryToEditAssetTemplateMappings
} from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import Modal from '../../../../shared/modal'
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';

const tableColumns = ['Grade', 'Condition', 'Rank', 'Deduction Percentage']

interface IEditSalesGradeMappingsModal {
    open: boolean;
    rows: ISalesGradeMapping[];
    assetTemplateId: string;
    onClose: () => void;
    setShowToast: Dispatch<SetStateAction<IToast | null | undefined>>;
}

type FormValues = {
    deductionPercentageA: string;
    deductionPercentageB: string;
    deductionPercentageC: string;
    deductionPercentageD: string;
    deductionPercentageE: string;
}

const EditSalesGradeMappingsModal = ({
    open,
    rows,
    assetTemplateId,
    onClose,
    setShowToast
}: IEditSalesGradeMappingsModal) => {
    const [loading, setLoading] = useState(false)
    const [showConfirmSalesGradeChanges, setShowConfirmSalesGradeChanges] = useState(false)
    const dispatch = useAppDispatch();

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
        reset
    } = useForm<FormValues>();

    useEffect(() => {
        if (rows.length > 0) {
            if (rows[0].deductionPercentage)
                setValue('deductionPercentageA', rows[0].deductionPercentage.toString())
            else setValue('deductionPercentageA', '')
            if (rows[1].deductionPercentage)
                setValue('deductionPercentageB', rows[1].deductionPercentage.toString())
            else setValue('deductionPercentageB', '')
            if (rows[2].deductionPercentage)
                setValue('deductionPercentageC', rows[2].deductionPercentage.toString())
            else setValue('deductionPercentageC', '')
            if (rows[3].deductionPercentage)
                setValue('deductionPercentageD', rows[3].deductionPercentage.toString())
            else setValue('deductionPercentageD', '')
            if (rows[4].deductionPercentage)
                setValue('deductionPercentageE', rows[4].deductionPercentage.toString())
            else setValue('deductionPercentageE', '')
        } else {
            setValue('deductionPercentageA', '')
            setValue('deductionPercentageB', '')
            setValue('deductionPercentageC', '')
            setValue('deductionPercentageD', '')
            setValue('deductionPercentageE', '')
        }
    }, [rows, assetTemplateId])

    const onSubmit = (data: FormValues) => {
        setShowConfirmSalesGradeChanges(true)
    };

    const handleCloseModal = () => {
        setShowConfirmSalesGradeChanges(false)
    }

    const onApproveAction = async () => {
        const values = getValues()
        setLoading(true)
        const result = Object.fromEntries(Object.entries(values)?.filter(([_, v]) => v !== ''));
        try {
            if (rows.length === 0) {
                await dispatch(tryToAddAssetTemplateMappings({ assetTemplateId, ...result })).unwrap()
            } else {
                await dispatch(tryToEditAssetTemplateMappings({ assetTemplateId, ...result })).unwrap()
            }
            setShowToast({ type: 'success', message: 'Changes to sales grades successfully saved.' });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        finally {
            reset()
            setLoading(false)
            setShowConfirmSalesGradeChanges(false)
            onClose()
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            showInRight={true}
            contentContainerStyle='!min-w-[650px]'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Table columns={tableColumns} hidePagination>
                    <tr className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>A</td>
                        <td className='px-6 py-4'>Flawless</td>
                        <td className='px-6 py-4'>1</td>
                        <td className='px-6 py-4 flex flex-col'>
                            <Controller
                                name='deductionPercentageA'
                                control={control}
                                rules={{
                                    validate: value =>
                                        value === undefined || value === '' || (parseFloat(value) > 0 && parseFloat(value) < 1) || 'Value must be between 0 and 1'
                                }}
                                render={({ field }) => (
                                    <input
                                        type='number'
                                        step='0.01'
                                        {...field}
                                    />
                                )}
                            />
                            {errors.deductionPercentageA && <span className='block text-xs text-red-500'>{errors.deductionPercentageA.message}</span>}
                        </td>
                    </tr>
                    <tr className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>B</td>
                        <td className='px-6 py-4'>Excellent</td>
                        <td className='px-6 py-4'>2</td>
                        <td className='px-6 py-4 flex flex-col'>
                            <Controller
                                name='deductionPercentageB'
                                control={control}
                                rules={{
                                    validate: value =>
                                        value === undefined || value === '' || (parseFloat(value) > 0 && parseFloat(value) < 1) || 'Value must be between 0 and 1'
                                }}
                                render={({ field }) => (
                                    <input
                                        type='number'
                                        step='0.01'
                                        {...field}
                                    />
                                )}
                            />
                            {errors.deductionPercentageB && <span className='block text-xs text-red-500'>{errors.deductionPercentageB.message}</span>}
                        </td>
                    </tr>
                    <tr className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>C</td>
                        <td className='px-6 py-4'>Good</td>
                        <td className='px-6 py-4'>3</td>
                        <td className='px-6 py-4 flex flex-col'>
                            <Controller
                                name='deductionPercentageC'
                                control={control}
                                rules={{
                                    validate: value =>
                                        value === undefined || value === '' || (parseFloat(value) > 0 && parseFloat(value) < 1) || 'Value must be between 0 and 1'
                                }}
                                render={({ field }) => (
                                    <input
                                        type='number'
                                        step='0.01'
                                        {...field}
                                    />
                                )}
                            />
                            {errors.deductionPercentageC && <span className='block text-xs text-red-500'>{errors.deductionPercentageC.message}</span>}
                        </td>
                    </tr>
                    <tr className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>D</td>
                        <td className='px-6 py-4'>Damaged</td>
                        <td className='px-6 py-4'>4</td>
                        <td className='px-6 py-4 flex flex-col'>
                            <Controller
                                name='deductionPercentageD'
                                control={control}
                                rules={{
                                    validate: value =>
                                        value === undefined || value === '' || (parseFloat(value) > 0 && parseFloat(value) < 1) || 'Value must be between 0 and 1'
                                }}
                                render={({ field }) => (
                                    <input
                                        type='number'
                                        step='0.01'
                                        {...field}
                                    />
                                )}
                            />
                            {errors.deductionPercentageD && <span className='block text-xs text-red-500'>{errors.deductionPercentageD.message}</span>}
                        </td>
                    </tr>
                    <tr className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>E</td>
                        <td className='px-6 py-4'>Faulty</td>
                        <td className='px-6 py-4'>5</td>
                        <td className='px-6 py-4 flex flex-col'>
                            <Controller
                                name='deductionPercentageE'
                                control={control}
                                rules={{
                                    validate: value =>
                                        value === undefined || value === '' || (parseFloat(value) > 0 && parseFloat(value) < 1) || 'Value must be between 0 and 1'
                                }}

                                render={({ field }) => (
                                    <input
                                        type='number'
                                        step='0.01'
                                        {...field}
                                    />
                                )}
                            />
                            {errors.deductionPercentageE && <span className='block text-xs text-red-500'>{errors.deductionPercentageE.message}</span>}
                        </td>
                    </tr>
                </Table>
                <div className={'min-w-full py-2 px-2 flex flex-row justify-center items-center bg-[#F1F9F7]'}>
                    <Button
                        label={'Save Changes'}
                        dataQa={'save-changes-sales-grade'}
                        type='submit'
                        className={`btn-main !bg-black !text-white flex flex-row align-start my-2 !py-2`}
                    />
                </div>
            </form>
            <Modal
                open={showConfirmSalesGradeChanges}
                onClose={handleCloseModal}
                dataQa={'close-modal'}
            >
                <div className={'p-2 min-w-[500px]'}>
                    <div className='border-b-2 border-slate-200 pb-2 my-1'>
                        <p className='font-medium text-slate-700'>Sales Grade Mappings</p>
                    </div>
                    <p className='my-2'>Please note that these changes directly affect the pricing offered to the user. Are you sure you want to continue</p>
                    <div className='flex flex-row justify-end my-2'>
                        <Button
                            label='Continue'
                            dataQa={'continue-button'}
                            className={loading ? 'btn-primary-disable' : 'btn-primary'}
                            onClickButton={() => !loading && onApproveAction()}
                        />
                        <Button
                            label='Cancel'
                            dataQa={'cancel-button'}
                            className={loading ? 'btn-main-disable' : 'btn-error ml-2'}
                            onClickButton={() => !loading && handleCloseModal()}
                        />
                    </div>
                </div>
            </Modal>
        </Modal>
    )
}

export default EditSalesGradeMappingsModal