import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { tryToFetchSingleCsUser } from '../../../store/brokers/admin/cs-agents/CSAgentsSlice';
import Loading from '../../../shared/Loading';
import WhiteContainer from '../../../shared/white-container';


const CSAgentDetails = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const CSAgent = useAppSelector((state) => state?.csAgents?.csAgentsDetail);
    const CSAgentLoading = useAppSelector((state) => state?.csAgents?.csAgentIsLoading);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleCsUser(id));
        }
    }, []);

    return (
        <div>
            <div>
                <p className='page-title'>CS Agent Details</p>
            </div>
            {CSAgentLoading ?
                <div className='my-3 flex flex-row justify-center items-center'>
                    <Loading/>
                </div>
                : <div>
                    <WhiteContainer type={'rounded'}>
                        <div>
                            <p className='mb-1'><b>Name:</b> {CSAgent?.name}</p>
                            <p className='mb-1'><b>Username:</b> {CSAgent?.username}</p>
                            <p className='mb-1'><b>Email:</b> {CSAgent?.email}</p>
                            <p className='mb-1'><b>Is Enabled:</b> {CSAgent?.enabled ? 'Yes' : 'No'}</p>
                        </div>
                    </WhiteContainer>
                </div>
            }
        </div>
    )
}

export default CSAgentDetails;