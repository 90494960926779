import React from 'react';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import Button from '../../../../shared/button';


interface IAdminInvoicesState {
    selectedState?: string;
    accessControl?: IAccessControl;
    actions?: any;
    invoicesStates: Array<{ name: string, label: string }>;
    onChangeState: (status: string) => void;
    onClickInvoiceTemplates: () => void;
}

const AdminInvoicesState = ({
    selectedState,
    invoicesStates,
    accessControl,
    actions,
    onChangeState,
    onClickInvoiceTemplates
}: IAdminInvoicesState) => {
    return (
        <div className='flex flex-row justify-between  min-w-full  my-4 items-center'>
            <div className='flex flex-row justify-start items-center flex-wrap'>
                <p className='text-[#8F8F8F] text-sm font-bold ml-2 mr-4'>States:</p>
                {invoicesStates && invoicesStates?.length > 0 && invoicesStates?.map((state) => {
                    return (
                        <Button
                            key={state?.label}
                            label={state?.name}
                            dataQa={`${state?.name}`}
                            className={`${selectedState === state?.label ? 'btn-filters' : 'btn-noactive-filters'} mr-2 !font-bold`}
                            onClickButton={() => onChangeState(state?.label || '')}
                        />
                    )
                })}
            </div>
            <div>
                <Button
                    label={'Invoice Templates'}
                    dataQa={'invoice-template'}
                    className={(!accessControl || actions?.['Invoice Templates']) ? 'btn-main' : 'btn-main-disable'}
                    onClickButton={() => (!accessControl || actions?.['Invoice Templates']) && onClickInvoiceTemplates()}
                />
            </div>
        </div>
    )
}

export default AdminInvoicesState;