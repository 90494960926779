import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { IOrders } from '../../../../interfaces/orders/IOrders';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { onFindSourceName } from '../../../../utils/constants/order-source-format';
import { onFormatOrderStatus } from '../../../../utils/constants/format-order-statuses';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';


interface IOrdersTable {
    rows: Array<IOrders>;
    paginationData?: IPage;
    selectedFilter?: string;
    activeMarket?: IActiveMarket;
    isRecentChanges?: boolean;
    ordersLoading: boolean;
    categorySelected: string;
    pageAccess?: any;
    accessControl?: any;
    onClickAccept: (id: string, itemId: string, initialCategory: any, imei?: any, warehouseId?: string, descriptor?: any) => void;
    onClickAddImei: (orderId: string) => void;
    onSeeOrderDetails: (orderId: string, itemId: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const OrdersTable = ({
    rows,
    paginationData,
    selectedFilter,
    activeMarket,
    isRecentChanges,
    ordersLoading,
    categorySelected,
    pageAccess,
    accessControl,
    onClickAccept,
    onSeeOrderDetails,
    setPaginationState,
}: IOrdersTable) => {
    const [tableColumns, setTableColumns] = useState<any>(['Order ID', 'Shipped Date', 'Item ID', 'Item Name', 'Order Number', 'Purchase Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Actions'])
    const [tableKeys, setTableKeys] = useState<any>(['id', 'shippedDate', 'itemId', 'itemName', 'orderNumber', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus'])

    useEffect(() => {
        let tableColumnsFormat: any = tableColumns
        let tableKeysFormat: any = tableKeys
        if (selectedFilter === undefined) {
            tableColumnsFormat = ['Accept', 'Order ID', 'Updated Date', 'Item ID', 'Item Name', 'Order Number', 'Purchase Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Actions']
            tableKeysFormat = ['accept', 'id', 'shippedDate', 'itemId', 'itemName', 'orderNumber', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus']
        } else {
            switch (selectedFilter) {
                case 'Received':
                case 'Delivered': {
                    tableColumnsFormat = ['Accept', 'Order ID', 'Updated Date', 'Item ID', 'Item Name', 'Order Number', 'Purchase Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Actions']
                    tableKeysFormat = ['accept', 'id', 'shippedDate', 'itemId', 'itemName', 'orderNumber', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus']
                    break;
                }
                case 'Inbound':
                    tableColumnsFormat = ['Order ID', 'Updated Date', 'Item ID', 'Item Name', 'Order Number', 'Purchase Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Actions']
                    tableKeysFormat = ['id', 'shippedDate', 'itemId', 'itemName', 'orderNumber', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus']
                    break;
                case 'In Review':
                case 'CS': {
                    tableColumnsFormat = ['Order ID', 'Updated Date', 'Item ID', 'Item Name', 'Order Number', 'Purchase Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Imei', 'Actions']
                    tableKeysFormat = ['id', 'shippedDate', 'itemId', 'itemName', 'orderNumber', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus', 'imei']
                    if (!((categorySelected || '').toLowerCase()).includes('electronics')) {
                        tableColumns.splice(-2, 1)
                        tableKeys.splice(-1, 1)
                    }
                    break;
                }
                case 'History': {
                    tableColumnsFormat = ['Order ID', 'Updated Date', 'Item ID', 'Item Name', 'Order Number', 'Purchase Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Imei', 'Actions']
                    tableKeysFormat = ['id', 'shippedDate', 'itemId', 'itemName', 'orderNumber', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus', 'imei']
                    if (!((categorySelected || '').toLowerCase()).includes('electronics')) {
                        tableColumns.splice(-2, 1)
                        tableKeys.splice(-1, 1)
                    }
                    break;
                }
                case 'Accepted': {
                    tableColumnsFormat = ['Order ID', 'Updated Date', 'Item ID', 'Item Name', 'Order Number', 'Purchase Price', 'Boost', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Actions']
                    tableKeysFormat = ['id', 'shippedDate', 'itemId', 'itemName', 'orderNumber', 'localizedPrice', 'boost', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus']
                    break;
                }
                default:
                    tableColumnsFormat = ['Order ID', 'Updated Date', 'Item ID', 'Item Name', 'Order Number', 'Purchase Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Actions']
                    tableKeysFormat = ['id', 'shippedDate', 'itemId', 'itemName', 'orderNumber', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus']
                    break;
            }
        }
        setTableColumns(tableColumnsFormat)
        setTableKeys(tableKeysFormat)
    }, [selectedFilter])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {ordersLoading ?
                <>
                    {[...Array(5)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map((element: any) => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {tableKeys?.length > 0 && tableKeys?.map((key: any, index: number) => {
                                    if (key !== 'trackingNumber') {
                                        let value = row?.[key]
                                        let item = null
                                        if (key === 'accept') {
                                            value = <div className='min-w-[120px]'>
                                                <Button
                                                    className={`${(row?.orderStatus === 'device_in_review' && (!accessControl || (pageAccess?.actions && pageAccess?.actions?.Accept))) ? 'bg-gradient-to-r !from-[#fdf690] !to-[#fbd18f] font-semibold text-[#202020] py-1 px-3 rounded-[8px] border border-[#ebedef] hover:cursor-pointer' : 'btn-main-disable cursor-not-allowed'} !py-2 !shadow-none flex flex-row items-center`}
                                                    label={'Accept'}
                                                    onClickButton={() => (row?.orderStatus === 'device_in_review' && (!accessControl || (pageAccess?.actions && pageAccess?.actions?.Accept))) && onClickAccept(row?.id || '', row?.itemId || '', row?.initialCategory || '', row?.itemCode, row?.exchangeDefinition?.warehouse?._id, row?.exchangeDefinition?.asset?.descriptor)}
                                                    icon={<img src={'/assets/table-actions/accept-mark.svg'} className='w-[15px] mr-2 object-contain' />}
                                                    dataQa={'accept-button'}
                                                />
                                            </div>
                                        }
                                        if (key === 'orderStatus') {
                                            value = onFormatOrderStatus(row?.[key], selectedFilter, isRecentChanges)?.name
                                            const color = onFormatOrderStatus(row?.[key], selectedFilter, isRecentChanges)?.color
                                            const text = onFormatOrderStatus(row?.[key], selectedFilter, isRecentChanges)?.text || 'text-black'
                                            item = color && <div className={`${color} flex flex-row justify-center text-center px-1 min-w-[150px] ${text} text-sm py-1 font-medium rounded-2xl`}>{value}</div>
                                        }
                                        if (key === 'localizedPrice') {
                                            value = Number(row?.[key]?.amount || 0).toFixed(2)
                                            item = `${CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.descriptor?.name || '')}`]}${value}`
                                        }
                                        if (key === 'shippedDate') {
                                            value = moment(row?.[key]).fromNow()
                                        }
                                        if (key === 'receivedDate') {
                                            value = moment(row?.[key]).fromNow()
                                        }
                                        if (key === 'location') {
                                            value = row?.location ? 'Yes' : 'No'
                                        }
                                        if (key === 'sourceOrder') {
                                            value = onFindSourceName(row?.sourceOrder || '')
                                        }
                                        if (key === 'boost') {
                                            value = Number(row?.exchangeDefinition?.boost || 0).toFixed(2)
                                            item = `${CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.descriptor?.name || '')}`]}${value}`
                                        }
                                        return (
                                            <td
                                                key={index}
                                                className={`px-6 py-4`}>
                                                {item || value}
                                            </td>
                                        )
                                    } else {
                                        return (
                                            <td
                                                key={index}
                                                className='px-6 py-4'
                                                data-qa={'tracking-number'}
                                            >
                                                <a
                                                    rel='noreferrer'
                                                    href={`https://parcelsapp.com/en/tracking/${row?.[key]}`}
                                                    target='_blank'
                                                    className='text-primary-default'
                                                >
                                                    {row?.[key]}
                                                </a>
                                            </td>
                                        )
                                    }
                                })}
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <div className='flex flex-row justify-around'>
                                        <div data-qa={'view-details'} onClick={() => (row?.id && row?.itemId && (!accessControl || (pageAccess?.actions['See Details']))) && onSeeOrderDetails(row?.id, row?.itemId)}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={'h-[14px] object-contain cursor-pointer mr-2'} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default OrdersTable;