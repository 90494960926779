import React from 'react';
import moment from 'moment';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['Item ID', 'Order ID', 'Category', 'Item Name', 'Item Code', 'Sales Grade', 'Currency', 'Price Without Boost', 'Boost', 'Multiple Cashout Boost', 'Purchase Price', 'Gesture amount', 'Total purchase price', 'Total Item Cost', 'Additional Cost', 'Conversion Rate', 'Market', 'Purchase Date', 'GBP Price', 'Location', 'Customer Name', 'Customer Email']

interface IPurchasesTable {
    rows: any[];
    rowsLoading: boolean;
    paginationData?: IPage;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const PurchasesTable = ({
    rows,
    rowsLoading,
    paginationData,
    setPaginationState,
}: IPurchasesTable) => {
    return (
        <Table
            containerStyle={'mt-0'}
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.itemId || ''}</td>
                                <td className='px-6 py-4'>{row?.orderId || ''}</td>
                                <td className='px-6 py-4'>{row?.category || ''}</td>
                                <td className='px-6 py-4 min-w-[170px]'>{row?.itemName || ''}</td>
                                <td className='px-6 py-4'>{row?.itemCode || ''}</td>
                                <td className='px-6 py-4'>{row?.salesGrade || ''}</td>
                                <td className='px-6 py-4'>{row?.currency || ''}</td>
                                <td className='px-6 py-4'>{Number(row?.purchasePriceWithoutBoost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.boost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.multipleCashoutPriceBoost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.purchasePrice || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.gestureAmount || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{(Number(row?.purchasePrice || 0) + Number(row?.gestureAmount || 0)).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.totalItemCost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.additionalCost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{row?.exchangeValue || ''}</td>
                                <td className='px-6 py-4'>{row?.market || ''}</td>
                                <td className='px-6 py-4'>{row?.purchaseDate && moment(row?.purchaseDate).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                <td className='px-6 py-4'>{Number(row?.gbpPrice || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{row?.stockLocation || ''}</td>
                                <td className='px-6 py-4'>{row?.customerName || ''}</td>
                                <td className='px-6 py-4'>{row?.customerEmail || ''}</td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table >
    )
}

export default PurchasesTable;