import React from 'react'
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
interface IItemCreditsTable {
    rows: any[];
    paginationData?: IPage;
    rowsLoading: boolean;
    selectedRows: any[] | undefined;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSelectRow: (id?: string) => void;
    sectors: IAssetTemplate[];
    markets: IAllMarkets[]
}
const tableColumns = ['RMA ID', 'Category', 'Item Name', 'Item ID', 'Initial Price', 'Final Price', 'Credit Amount', 'Country', 'Reason For Credit']

const ItemCreditsTable = ({ rows,
    paginationData,
    rowsLoading,
    selectedRows,
    onClickSelectRow,
    setPaginationState,
    sectors,
    markets,
}: IItemCreditsTable) => {
    const getItemIcon = (c: string) => {
        const res = sectors.find((category) => category.name === c);
        return res?.disabledIcon;
    };
    return (
        <Table
            selectRows={true}
            areAllRowsSelected={(selectedRows && rows && rows.every(r => selectedRows.find(sr => sr._id === r?._id)))}
            columns={tableColumns}
            containerStyle={'mt-0'}
            paginationData={paginationData}
            onClickSelectAll={() => onClickSelectRow()}
            setPaginationState={setPaginationState}
            selectStyles='!accent-[#202020] !focus:accent-[#202020]'
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        const findIfRowIsSelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find(sr => sr._id === row._id)
                        const findMarket =
                            markets &&
                            markets?.length > 0 &&
                            markets?.find(
                                (market: any) =>
                                    market?.id ===
                                    row.market?._id
                            );
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>
                                    <div className='flex items-center justify-center'>
                                        <input
                                            onChange={() => onClickSelectRow(row?._id)}
                                            checked={!!findIfRowIsSelected}
                                            data-qa={'checkbox-type'}
                                            id='checkbox-all'
                                            type='checkbox'
                                            className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-[#202020] rounded focus:accent-[#202020] focus:ring-2' />
                                        <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                                    </div>
                                </td>
                                <td className='px-6 py-4'>{row?.rmaId}</td>
                                <td className='px-6 py-6 flex items-center'>
                                    <img className='w-6 h-6' src={getItemIcon(row?.category)} alt={row?.item?.category || ''} title={row?.item?.category || ''} />
                                </td>
                                <td className='px-6 py-4'>{row?.itemName}</td>
                                <td className='px-6 py-4'>{row?.itemId}</td>
                                <td className='px-6 py-4'>{findMarket && CURRENCY_SYMBOLS?.[`${(findMarket?.currency?.name || '')}`]}{row?.initialPrice}</td>
                                <td className='px-6 py-4'>{findMarket && CURRENCY_SYMBOLS?.[`${(findMarket?.currency?.name || '')}`]}{row?.finalPrice}</td>
                                <td className='px-6 py-4'>{findMarket && CURRENCY_SYMBOLS?.[`${(findMarket?.currency?.name || '')}`]}{row?.amount}</td>
                                <td className='px-6 py-4'>{row?.market?.name || ''}</td>
                                <td className='px-6 py-4'>{row?.reasonForCredit === 'accepted_return' ? <span
                                    className="text-[#00B67A] border-[#00B67A] text-nowrap">Accepted Return</span> :
                                    <span
                                        className="text-[#1389E6] border-[#1389E6] text-nowrap">Revalued Item</span>}
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default ItemCreditsTable