import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToAddRentalPricingSetting,
    tryToEditRentalPricingSetting,
    tryToFetchSingleRentalPricingSetting
} from '../../../../store/brokers/admin/rental-pricing-settings/rentalPricingSettingsSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';


interface IRentalPricingSettingsModal {
    openRentalPricingSettingsModal: boolean;
    rentalPricingSettingId?: string;
    sectors?: IAssetTemplate[];
    markets?: IAllMarkets[];
    handleCloseRentalPricingSettingsModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    financingCost: number,
    residualValue: number,
    termRentalWeeks: number,
};

const RentalPricingSettingsModal = ({
    openRentalPricingSettingsModal,
    rentalPricingSettingId,
    sectors,
    markets,
    handleCloseRentalPricingSettingsModal,
    onSaveChanges
}: IRentalPricingSettingsModal) => {
    const dispatch = useAppDispatch();
    const [createError, setCreateError] = useState<string | null>();
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedSector, setSelectedSector] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const rentalSettingDetails = useAppSelector(store => store?.rentalPricingSettings?.rentalPricingSettingsDetails)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        if (rentalPricingSettingId && rentalSettingDetails) {
            setValue('financingCost', rentalSettingDetails?.financingCost)
            setValue('residualValue', rentalSettingDetails?.residualValue)
            setValue('termRentalWeeks', rentalSettingDetails?.termRentalWeeks)
            if (markets && rentalSettingDetails?.marketId) {
                const findMarket = markets?.length > 0 && markets?.find((item: any) => item?.value === rentalSettingDetails?.marketId)
                setSelectedMarket(findMarket || undefined)
            }
            if (sectors && rentalSettingDetails?.category?.id) {
                const findSector = sectors?.length > 0 && sectors?.find((item: any) => item?.value === rentalSettingDetails?.category?.id)
                setSelectedSector(findSector || undefined)
            }
        }
    }, [rentalSettingDetails])

    const onCloseModal = () => {
        handleCloseRentalPricingSettingsModal();
        setLoading(false)
        reset();
        setSelectedMarket(undefined)
        setSelectedSector(undefined)
        setCreateError(undefined)
    };

    useEffect(() => {
        if (rentalPricingSettingId) {
            dispatch(tryToFetchSingleRentalPricingSetting(rentalPricingSettingId))
        } else {
            setLoading(false)
            reset();
            setSelectedMarket(undefined)
            setSelectedSector(undefined)
            setCreateError(undefined)
        }
    }, [rentalPricingSettingId])

    const onSubmit = async (data: FormValues) => {
        if (loading) {
            return;
        }
        if (!selectedMarket || !selectedSector) {
            setCreateError('Please select Category and Market to continue!')
            return;
        }
        setLoading(true)
        const payload: any = {
            ...data || {},
            assetTemplateId: selectedSector.value,
            marketId: selectedMarket.value,
        };
        try {
            if (rentalPricingSettingId) {
                payload._id = rentalPricingSettingId
                await dispatch(tryToEditRentalPricingSetting(payload)).unwrap();
            } else {
                await dispatch(tryToAddRentalPricingSetting(payload)).unwrap();
            }
            onSaveChanges('success', 'Setting saved successfully!')
            onCloseModal();
        } catch (error) {
            setCreateError(`${error}`)
        }
        setLoading(false)
    };

    const onSelectSector = (value: any, type?: string) => {
        setSelectedSector(value)
    }

    const onSelectMarket = (value: any, type?: string) => {
        setSelectedMarket(value)
    }

    return (
        <Modal
            open={openRentalPricingSettingsModal}
            onClose={onCloseModal}
            contentContainerStyle={'min-w-[600px]'}
        >
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4 font-bold'>
                    {rentalPricingSettingId ? 'Edit Rental Pricing Setting' : 'Add Rental Pricing Setting'}
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {createError && <Error text={createError} />}
                    <div className='flex flex-col my-4'>
                        <div className='flex flex-col gap-x-3 justify-items-stretch'>
                            <SelectCheckbox
                                name={'Category'}
                                placeholder={' '}
                                disabled={!!rentalPricingSettingId}
                                dataQa={'category-selected'}
                                containerStyle='w-[90%]'
                                selectedOption={selectedSector}
                                options={sectors || []}
                                uniqueName={'sector'}
                                onChangeSelectedOption={onSelectSector}
                            />
                            <SelectCheckbox
                                name={'Market'}
                                placeholder={' '}
                                disabled={!!rentalPricingSettingId}
                                dataQa={'market-selected'}
                                containerStyle='w-[90%]'
                                selectedOption={selectedMarket}
                                options={markets || []}
                                uniqueName={'market'}
                                onChangeSelectedOption={onSelectMarket}
                            />
                            <Input
                                dataQa={'rental-pricing-settings-financing-cost'}
                                label={'Financing Cost (%)'}
                                containerStyle='mb-1 max-w-[90%]'
                                type='text'
                                register={register('financingCost', {
                                    required: {
                                        message: 'Financing Cost is required',
                                        value: true,
                                    },
                                    pattern: {
                                        value: /^-?[0-9]\d*\.?\d*$/,
                                        message: 'Only numbers are accepted.'
                                    },
                                    validate: (value: number) => (Number(value) >= 0) || 'Value should be a positive number'
                                })}
                                error={errors?.financingCost?.message}
                            />
                            <Input
                                dataQa={'rental-pricing-settings-residual-value'}
                                label={'Residual Value (%)'}
                                containerStyle='mb-1 max-w-[90%]'
                                type='text'
                                register={register('residualValue', {
                                    required: {
                                        message: 'Residual Value is required',
                                        value: true,
                                    },
                                    pattern: {
                                        value: /^-?[0-9]\d*\.?\d*$/,
                                        message: 'Only numbers are accepted.'
                                    },
                                    validate: (value: number) => (Number(value) >= 0) || 'Value should be a positive number'
                                })}
                                error={errors?.residualValue?.message}
                            />
                            <Input
                                dataQa={'rental-pricing-settings-term-rental-weeks'}
                                label={'Rental Term ( weeks )'}
                                containerStyle='mb-1 max-w-[90%]'
                                type='text'
                                register={register('termRentalWeeks', {
                                    required: {
                                        message: 'Rental Term is required',
                                        value: true,
                                    },
                                    pattern: {
                                        value: /^-?[0-9]\d*\.?\d*$/,
                                        message: 'Only numbers are accepted.'
                                    },
                                    validate: (value: number) => (Number(value) >= 0) || 'Value should be a positive number'
                                })}
                                error={errors?.termRentalWeeks?.message}
                            />
                        </div>
                        <div className='flex flex-row justify-end mt-4'>
                            <Button
                                label='Save'
                                dataQa={'submit'}
                                type={'submit'}
                                className={loading ? 'btn-primary-disable' : 'btn-primary'}
                            />
                        </div>
                    </div>
                </form>
            </div>

        </Modal>
    );
};

export default RentalPricingSettingsModal;
