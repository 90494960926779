import React, { useState } from 'react';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import Table from '../../../../shared/table';
import moment from 'moment';


interface IAssetLibraryTable {
    rows: any[];
    rowsLoading: boolean;
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    onSeeDetails: (id: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const AssetLibraryTable = ({
    rows,
    rowsLoading,
    paginationData,
    accessControl,
    actions,
    setPaginationState,
    onSeeDetails
}: IAssetLibraryTable) => {
    const [tableColumns] = useState<Array<string>>(['Asset Upload', 'Upload Date', 'Category', 'Nr. Items', 'Batch Offer', 'AI Grading', 'Status', 'Action'])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}>
            {(!tableColumns || rowsLoading) ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {

                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.fileName || '--'}</td>
                                <td className='px-6 py-4'>{moment(row?.uploadDate).format('DD/MM/YYYY') || '--'}</td>
                                <td className='px-6 py-4'>{row?.category || '--'}</td>
                                <td className='px-6 py-4'>{row?.nrItems || '--'}</td>
                                <td className='px-6 py-4'>{row?.batchOffer || '--'}</td>
                                <td className='px-6 py-4'>{row?.aiGrading || '--'}</td>
                                <td className='px-6 py-4'>{row?.status || '--'}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-start'>
                                        <div onClick={() => row?._id && onSeeDetails(row?._id)}>
                                            <img title={'See Asset Library Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`w-[20px] min-w-[20px] mr-3 mt-1 object-contain cursor-pointer`} />
                                        </div>
                                        {/* <TableActions>
                                            <div
                                                onClick={() => { console.error('clicked') }}
                                                className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'
                                                role='none'>
                                                <Button
                                                    label={'Download Order CSV'}
                                                    dataQa={'download-order-csv'}
                                                    className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000]`}
                                                />
                                            </div>
                                        </TableActions> */}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default AssetLibraryTable;