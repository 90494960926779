import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IScrapingFees } from '../../../interfaces/scraping-fees/IScrapingFees';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import {
    tryToDeleteScrapingFee,
    tryToDownloadScrapingFees,
    tryToFetchScrapingFees
} from '../../../store/brokers/admin/scraping-fees/scrapingFeesSlice';
import ScrapingFeeActionsModal from './modals/ScrapingFeeActionsModal';
import ImportScrapingFeesModal from './modals/ImportScrapingFeesModal';
import ScrapingFeesTable from './tables/ScrapingFeesTable';
import ScrapingFeeFilters from './details/ScrapingFeeFilters';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';


const ScrapingFees = () => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const csvLink1 = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<IScrapingFees[]>([]);
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [selectedSector, setSelectedSector] = useState<any>()
    const [showImportModal, setShowImportModal] = useState<boolean>(false)
    const [scrapingFeeItemId, setScrapingFeeItemId] = useState<string | null>();
    const [showScrapingFeeModal, setShowSrapingFeeModal] = useState<boolean>(false);
    const [loadingDownloadTemplate, setLoadingDownloadTemplate] = useState<boolean>(false)
    const [loadingDownloadSample, setLoadingDownloadSample] = useState<boolean>(false)
    const [sampleData, setSampleData] = useState<any>()
    const [scrapingFeesData, setScrapingFeesData] = useState<any>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.scrapingFees.scrapingFees?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const rowsLoading = useAppSelector((state) => state.scrapingFees.scrapingsAreLoading);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state) => state.scrapingFees);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Scraping Fees')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.scrapingFees) {
            const rows =
                state.scrapingFees?.data?.elements &&
                    Array.isArray(state.scrapingFees?.data.elements)
                    ? state.scrapingFees?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.scrapingFees]);

    const getSrapingFeeData = async () => {
        const formatData: any = {
            ...paginationState,
            data: {
                'includePagination': true,
            }
        }
        if (selectedSector) {
            formatData.data.filters = {
                ...formatData.data.filters,
                assetTemplateId: selectedSector ? selectedSector?._id : ''
            }
        }
        dispatch(tryToFetchScrapingFees(formatData));
    }

    useEffect(() => {
        getSrapingFeeData()
    }, [paginationState]);

    useEffect(() => {
        dispatch(tryToFetchAllAssetsTemplates());
    }, []);

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const onDeleteScrapingFee = async () => {
        try {
            await dispatch(tryToDeleteScrapingFee(scrapingFeeItemId || ''));
            setShowToast({
                type: 'success',
                message: 'Scraping Fee successfully deleted',
            });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setScrapingFeeItemId(undefined);
        setShowDeleteModal(false);
    };

    const onAddScrapingFee = (id?: string) => {
        if (id) {
            setScrapingFeeItemId(id);
        }
        setShowSrapingFeeModal(true);
    };

    const onCloseScrapingFeeModal = () => {
        setScrapingFeeItemId(null);
        setShowSrapingFeeModal(false);
    };

    const onSaveScrapingChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        getSrapingFeeData()
    };

    const onDeleteScrapingRequest = (id: string) => {
        setShowDeleteModal(true);
        setScrapingFeeItemId(id);
    };

    const onSelectSector = (value: any) => {
        setSelectedSector(value)
        onResetPaginationState()
    }

    const onClearAllFilters = () => {
        setSelectedSector(undefined)
        onResetPaginationState()
    }

    const onDownloadScrapingFees = async () => {
        setLoadingDownloadTemplate(true)
        try {
            const response: any = await dispatch(tryToDownloadScrapingFees(null)).unwrap()
            const findKeys = ['assetTemplate', 'assetTemplateId', 'scrapingFee']
            const formatData = response && response?.length > 0 && response?.map((item: any) => {
                const formatedDataArray: any = []
                formatedDataArray.push(item?.assetTemplate?.displayName || ' ')
                formatedDataArray.push(item?.assetTemplate?.id || ' ')
                formatedDataArray.push(item?.scrapingFee)
                return formatedDataArray
            })
            const formatResponse = [
                findKeys || [],
                ...formatData || [],
            ]
            setScrapingFeesData(formatResponse || [])
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
            setLoadingDownloadTemplate(false)
        }
    }

    const onDownloadSampleFeesData = () => {
        setLoadingDownloadSample(true)
        const keys = ['assetTemplate', 'assetTemplateId', 'scrapingFee']
        const formatAssetTemplates: any = []
        formatAssetTemplates.push(keys)
        sectors && sectors?.length > 0 && sectors?.map((item: any) => {
            const sectorRow: any = []
            sectorRow?.push(item?.label)
            sectorRow?.push(item?.value)
            formatAssetTemplates?.push(sectorRow)
            return item
        })
        setSampleData(formatAssetTemplates || [])
    }

    const onImportSuccessfully = () => {
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
        })
        setShowImportModal(false)
    }

    useEffect(() => {
        if (scrapingFeesData && loadingDownloadTemplate) {
            csvLink.current.link.click()
        }
        setLoadingDownloadTemplate(false)
    }, [scrapingFeesData])

    useEffect(() => {
        if (sampleData && loadingDownloadSample) {
            csvLink1.current.link.click()
        }
        setLoadingDownloadSample(false)
    }, [sampleData])

    const onImportScrapingFeesRequest = () => {
        setShowImportModal(true)
    }

    return (
        <div>
            <div>
                <p className='page-title'>Scraping Fees</p>
            </div>
            <div>
                <ScrapingFeeFilters
                    sectors={sectors}
                    selectedSector={selectedSector}
                    onSelectSector={onSelectSector}
                />
            </div>
            <div className='flex flex-row justify-end flex-wrap'>
                <Button
                    label={'Clear all filters'}
                    dataQa={'clear-all-filters'}
                    className={'btn-primary-text-underline flex flex-row justify-end bg-transparent mr-2 mb-1'}
                    onClickButton={onClearAllFilters}
                />
                <Button
                    label={'Add New Scraping Fee'}
                    dataQa={'add-new-scraping-fee'}
                    className={`${(!accessControl || pageAccess?.actions?.['Add New Scraping Fee']) ? 'btn-main' : 'btn-main-disable'} mr-2 mb-1`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Scraping Fee']) && onAddScrapingFee()}
                />
                <Button
                    label={loadingDownloadSample ? 'Loading Data...' : 'Download Sample'}
                    dataQa={loadingDownloadSample ? 'loading-data...' : 'download-sample'}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Sample']) ? 'btn-main' : 'btn-main-disable'} mr-2 mb-1`}
                    onClickButton={() => (!loadingDownloadSample && (!accessControl || pageAccess?.actions?.['Download Sample'])) && onDownloadSampleFeesData()}
                />
                <CSVLink
                    ref={csvLink1}
                    data={(sampleData && sampleData?.length > 0) ? sampleData?.filter((item: any, idx: number) => idx > 0) || [] : []}
                    headers={(sampleData && sampleData?.length > 0) ? sampleData?.[0] || [] : []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={`Scraping-fees-sample.csv`}
                ></CSVLink>
                <Button
                    label={loadingDownloadTemplate ? 'Loading Data...' : 'Download Scraping Fees'}
                    dataQa={loadingDownloadTemplate ? 'loading-data...' : 'download-scraping-fees'}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Scraping Fees']) ? 'btn-main' : 'btn-main-disable'} mr-2 mb-1`}
                    onClickButton={() => (!loadingDownloadTemplate && (!accessControl || pageAccess?.actions?.['Download Scraping Fees'])) && onDownloadScrapingFees()}
                />
                <CSVLink
                    ref={csvLink}
                    data={(scrapingFeesData && scrapingFeesData?.length > 0) ? scrapingFeesData?.filter((item: any, idx: number) => idx > 0) || [] : []}
                    headers={(scrapingFeesData && scrapingFeesData?.length > 0) ? scrapingFeesData?.[0] || [] : []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={`Scraping-fees.csv`}
                ></CSVLink>
                <Button
                    label={'Import Scraping Fees'}
                    dataQa={'import-scraping-fees'}
                    className={(!accessControl || pageAccess?.actions?.['Import Scraping Fees']) ? 'btn-main mb-1' : 'btn-main-disable mb-1'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Scraping Fees']) && onImportScrapingFeesRequest()}
                />
            </div>
            <div className='my-5 relativ'>
                {(rows?.length > 0 || rowsLoading) ?
                    <ScrapingFeesTable
                        rows={rows}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        rowsLoading={rowsLoading}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onDeleteScrapingFee={onDeleteScrapingRequest}
                        onEditScrapingFee={onAddScrapingFee}
                    />
                    : <EmptyContainer
                        text={'No Scraping Fee added yet.'}
                        showImage={true}
                    />
                }
            </div>
            <ScrapingFeeActionsModal
                sectors={sectors}
                scrapingFeeItemId={scrapingFeeItemId}
                openActionsModal={showScrapingFeeModal}
                handleCloseModal={onCloseScrapingFeeModal}
                onSaveChanges={onSaveScrapingChanges}
            />
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeleteScrapingFee}
            />
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
            {showImportModal &&
                <ImportScrapingFeesModal
                    openImportModal={showImportModal}
                    handleCloseModal={() => setShowImportModal(false)}
                    onImportSuccessfully={onImportSuccessfully}
                />
            }
        </div>
    );
};

export default ScrapingFees;
