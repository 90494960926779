import React, { useState, useCallback, useEffect } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch } from '../../../../app/hooks';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import { tryToFetchSubBrandsByMarket } from '../../../../store/brokers/admin/sub-brands/subBrandsSlice';
import { tryToFetchAllCurrencies } from '../../../../store/brokers/shared-endpoints/wholesale-price/wholesalePriceSlice';
import { tryToUploadComparisonPricingCSV } from '../../../../store/brokers/admin/comparison-sites-pricing/comparisonPricingSlice';
import SuccessfullyUploadFile from '../details/SuccessfullyUploadedFile';
import UploadCsvFile from '../details/UploadCsvFile';
import Modal from '../../../../shared/modal';


interface IImportPricingCsvModal {
    openImportModal: boolean;
    sectors: Array<any>;
    brokers: Array<any>;
    marketSelected?: string;
    handleCloseModal: () => void;
}

const ImportPricingCsvModal = ({
    openImportModal,
    sectors,
    brokers,
    handleCloseModal,
}: IImportPricingCsvModal) => {
    const dispatch = useAppDispatch();
    const [subbrands, setSubbrands] = useState<any>();
    const [selectedSubbrands, setSelectedSubbrands] = useState<any>();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [selectedSector, setSelectedSector] = useState<any>()
    const [fileSelected, setFileSelected] = useState<any>()
    const [importLoading, setImportLoading] = useState<boolean>(false)
    const [markets, setMarkets] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [getAllMarkets] = useGetAllMarketsMutation()

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onGettingAllMarkets = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, label: market?.label, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    const onGettingSubbrandsData = async () => {
        try {
            const response = await dispatch(tryToFetchSubBrandsByMarket(selectedMarket?.value || '')).unwrap()
            const formatResponse = response?.data && response?.data?.length > 0 && response?.data?.map((item: any) => ({ ...item, value: item?.id, label: item?.displayName }))
            setSubbrands(formatResponse || [])
            setSelectedSubbrands(undefined)
        } catch (err) {
            setSelectedSubbrands(undefined)
            setSubbrands(undefined)
        }
    }

    const onCloseModal = () => {
        handleCloseModal();
        setImportError(undefined)
        setImportedSuccessfully(false)
        setFileSelected(undefined)
        setSubbrands(undefined)
        setSelectedSector(undefined)
        setSelectedSubbrands(undefined)
        setSelectedMarket(undefined)
    }

    const onUploadFile = async () => {
        if (!selectedSector || !selectedMarket || !selectedSubbrands) {
            setImportError('Please select market, subbrands and category first to continue with upload')
            return;
        }
        setImportLoading(true)
        try {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const formatResults = results?.data && results?.data?.length > 0 && results?.data?.map((item: any) => {
                        const asset = Object.assign(item)
                        const quantity = Number(item?.price || 0)
                        delete asset.price
                        return {
                            asset,
                            quantity
                        }
                    })
                    let payload: any = {
                        assetTemplateId: selectedSector?.value || '',
                        data: formatResults || [],
                        subBrandId: selectedSubbrands?.value || ''
                    }
                    if (selectedMarket) {
                        payload = {
                            ...payload || {},
                            marketId: selectedMarket?.value || ''
                        }
                    }
                    await dispatch(tryToUploadComparisonPricingCSV(payload)).unwrap()
                }
            }
            )
            setImportError(undefined)
            setImportedSuccessfully(true)
            setSelectedSector(undefined)
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
        setImportLoading(false)
    }

    useEffect(() => {
        dispatch(tryToFetchAllCurrencies()).unwrap()
        onGettingAllMarkets()
    }, [])

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(e)
    }

    const onChangeSelectedMarket = (e: any) => {
        setSelectedMarket(e)
    }

    const onChangeSelectedSubBrand = (e: any) => {
        setSelectedSubbrands(e)
    }

    useEffect(() => {
        if (selectedMarket) {
            onGettingSubbrandsData()
        } else {
            setSelectedMarket(undefined)
            setSubbrands(undefined)
        }
    }, [selectedMarket])

    return (
        <Modal
            open={openImportModal}
            onClose={() => !importLoading && onCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <SuccessfullyUploadFile
                        handleCloseModal={() => !importLoading && onCloseModal()}
                    />
                    : <UploadCsvFile
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                        sectors={sectors}
                        selectedMarket={selectedMarket}
                        selectedSector={selectedSector}
                        markets={markets}
                        subbrands={subbrands}
                        selectedSubbrand={selectedSubbrands}
                        onChangeSelectedSector={onChangeSelectedSector}
                        onChangeSelectedMarket={onChangeSelectedMarket}
                        onChangeSelectedSubBrand={onChangeSelectedSubBrand}
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                    />
                }
            </div>
        </Modal>
    )
}

export default ImportPricingCsvModal;