import React from 'react';
import { SUBJECT_ITEMS } from '../../utils/constants/raise-ticket-subject';
import OrderItemLocation from '../../features/dashboard/orders/details/OrderItemLocation';
import Button from '../button';
import Error from '../error';
import Input from '../input';
import Select from '../select';
import Textarea from '../textarea';

type FormValues = {
    description: string;
    subject: string;
    specialTrackingNumber: string;
};

interface IRaiseTicketForm {
    handleSubmit: any;
    register: any;
    errors: any;
    watch: any;
    getRootProps: any;
    getInputProps: any;
    filesSelected?: any;
    loading?: boolean;
    createError?: string;
    warehouseId?: any;
    userData?: any;
    showSubjectInput: boolean;
    showSpecialTrackingInput: boolean;
    loadingOptions?: boolean;
    filteredLocations?: any;
    searchInDBLocation?: any;
    selectedItemLocation?: any;
    sections?: any;
    selectedSection?: any;
    scannedLocation?: any;
    findBarcodeLocationsResponse?: string;
    onMenuCloseAfterSearch: () => void;
    onChangeSearchLocationField: (e: any, type?: string) => void;
    onClickSearchInDb: () => void;
    onEnterAction: (e: any, type?: string) => void;
    onChangedScannedLocation: (e: any, type?: string) => void;
    onChangeSelectedSection: (e: any, type?: string) => void;
    onClickClearLocationAndSection: () => void;
    onChangeSelectedLocation: (e: any, type?: string, index?: any) => void;
    onChangeValue: (e: any) => void;
    onSubmit: (data: FormValues) => void;
    onChangeSelectedSubject: (e: string) => void;
    onDeleteImage: (file: any) => void;
}


const RaiseTicketForm = ({
    handleSubmit,
    register,
    getRootProps,
    getInputProps,
    filesSelected,
    errors,
    loading,
    watch,
    warehouseId,
    userData,
    createError,
    showSubjectInput,
    showSpecialTrackingInput,
    loadingOptions,
    filteredLocations,
    searchInDBLocation,
    selectedItemLocation,
    sections,
    scannedLocation,
    selectedSection,
    findBarcodeLocationsResponse,
    onChangeSelectedLocation,
    onMenuCloseAfterSearch,
    onChangeSearchLocationField,
    onClickSearchInDb,
    onEnterAction,
    onChangeSelectedSection,
    onChangedScannedLocation,
    onClickClearLocationAndSection,
    onSubmit,
    onChangeValue,
    onChangeSelectedSubject,
    onDeleteImage,
}: IRaiseTicketForm) => {
    return (
        <div className={'p-2 w-full'}>
            <p className='text-md mt-4'>Raise a Ticket</p>
            {!userData?.inventory && <div className='mt-2 mb-5 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>}
            {userData?.inventory &&
                <OrderItemLocation
                    loadingOptions={loadingOptions}
                    filteredLocations={filteredLocations}
                    searchInDBLocation={searchInDBLocation}
                    selectedItemLocation={selectedItemLocation}
                    sections={sections}
                    inputStyle={'min-w-[200px]'}
                    selectedSection={selectedSection}
                    scannedLocation={scannedLocation}
                    findBarcodeLocationsResponse={findBarcodeLocationsResponse}
                    onChangeSearchLocationField={onChangeSearchLocationField}
                    onChangeSelectedLocation={onChangeSelectedLocation}
                    onClickSearchInDb={onClickSearchInDb}
                    onChangeSelectedSection={onChangeSelectedSection}
                    onEnterAction={onEnterAction}
                    onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                    onChangedScannedLocation={onChangedScannedLocation}
                    onClickClearLocationAndSection={onClickClearLocationAndSection}
                />
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                {createError && <Error text={createError} />}
                <Select
                    placeholder='Subject'
                    dataQa={'subject'}
                    containerStyle='mb-1'
                    value={showSubjectInput ? 'Other' : watch('subject')}
                    showValue={true}
                    onChangeSelectValue={onChangeSelectedSubject}
                    options={SUBJECT_ITEMS}
                    error={showSubjectInput ? '' : errors?.subject?.message}
                />
                {showSubjectInput && <Input
                    placeholder='Please write subject'
                    dataQa={'write-subject'}
                    register={register('subject', {
                        required: {
                            message: 'Subject is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'Subject is required'
                    })}
                    error={errors.subject?.message}
                />
                }
                {showSpecialTrackingInput && <Input
                    placeholder='Please write special tracking number'
                    dataQa={'write-specialTrackingNumber'}
                    register={register('specialTrackingNumber', {
                        required: {
                            message: 'Special Tracking Number is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'Special Tracking Number is required'
                    })}
                    error={errors.specialTrackingNumber?.message}
                />
                }
                <Textarea
                    placeholder='Query'
                    dataQa={'query'}
                    rows={4}
                    value={watch('description')}
                    onChangeTextarea={onChangeValue}
                    error={errors.description?.message}
                />
                <div className='mt-6 mb-10 bg-[#f8f9fc] rounded p-4 py-8'>
                    <p className='mb-10'>You can upload images too.</p>
                    <div className='flex flex-col items-start justify-center' {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button
                            icon={<img src='/assets/shared/add-plus.svg' className='w-[30px] object-contains pr-2' />}
                            className={'btn-main !bg-white !py-2 !pr-3 !shadow-none !border-none flex flex-row items-center'}
                            label={'Upload file'}
                            dataQa={'select-file'}
                        />
                    </div>
                    {filesSelected && filesSelected?.length > 0 &&
                        <div className='flex flex-rows items-center flex-wrap justify-start mt-6'>
                            {filesSelected?.map((item: any, key: number) => {
                                return (
                                    <div
                                        key={key}
                                        onClick={() => onDeleteImage(item)}
                                        className={'relative flex flex-col items-center mr-4 z-70'}>
                                        <div data-qa={'delete-image'} className='absolute top-[-7px] right-[-7px] cursor-pointer z-50'>
                                            <img src={'/assets/shared/cancel.svg'} className='max-w-[15px] object-contain z-70' />
                                        </div>
                                        <div className='border border-[#000] p-1 z-0'>
                                            <img src={item && URL.createObjectURL(item)} className='max-w-[70px] object-contain' />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
                <div className='min-w-full flex flex-row justify-center'>
                    <Button
                        label={'Raise a Ticket'}
                        dataQa={'submit-button'}
                        className={`${loading ? 'btn-main-disable' : 'btn-main'} my-3 min-w-[130px] !shadow-none`}
                        type='submit'
                    />
                </div>
            </form>

        </div>

    )
}

export default RaiseTicketForm;