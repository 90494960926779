import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToSetMissingPackage } from '../../../../store/brokers/partners/orders/ordersSlice';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IMissingItemModal {
    openModal: boolean;
    itemId: string;
    shipmentId: string;
    handleCloseModal: () => void;
}

const MissingItemModal = ({
    openModal,
    itemId,
    shipmentId,
    handleCloseModal
}: IMissingItemModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [filesSelected, setFilesSelected] = useState<any>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>()

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.map((file: any) => {
            const reader = new FileReader();
            reader.onload = async (event: any) => {
                await setFilesSelected((prevState: any) => {
                    return [...prevState || [], file]
                })
            };
            reader.readAsDataURL(file);
            return file
        });
    }, []
    )
    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true, })

    const onCloseModal = () => {
        handleCloseModal();
        setImportError(undefined)
        setLoadingSubmit(false)
        document.body.style.overflow = 'auto';
    }

    const onDeleteImage = (file: any) => {
        const filterOutFile = (filesSelected && filesSelected?.length > 0) && filesSelected?.filter((item: any) => item !== file)
        setFilesSelected(filterOutFile || undefined)
    }

    const onSubmit = async () => {
        setLoadingSubmit(true)
        if (!filesSelected || filesSelected?.length < 2) {
            setImportError('Please upload at least two images')
            setLoadingSubmit(false)
            return
        }
        try {
            const dataForm: any = new FormData()
            dataForm.append('shipmentId', shipmentId)
            dataForm.append('itemId', itemId)
            filesSelected && filesSelected?.length > 0 && filesSelected?.map((file: any, index: number) => {
                dataForm.append(`file${index + 1}`, file)
                return file
            })
            await dispatch(tryToSetMissingPackage(dataForm)).unwrap()
            onCloseModal()
        } catch (err) {
            setImportError(`${err}`)
            setLoadingSubmit(false)
        }
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            showInRight={true}
            contentContainerStyle={'min-w-[650px]'}
            dataQa={'close-button'}
        >
            <div className={'p-2 min-w-[570px]'}>
                <div className='flex flex-row items-center mb-5'>
                    <p className='mt-2 mb-4 page-title !text-[18px] font-bold mr-10'>Damaged Package</p>
                    <div>
                        <Button
                            label='Submit'
                            dataQa={'submit-button'}
                            className={`${loadingSubmit ? 'btn-primary-disable' : 'btn-primary'} !shadow-none min-w-[200px]`}
                            onClickButton={() => !loadingSubmit && onSubmit()} />
                    </div>
                </div>
                {importError && <Error text={importError} />}
                <div className='mt-6 mb-10 bg-[#f8f9fc] rounded p-4 py-8'>
                    <p className='mb-10'>Submit minimum of 2 images of the damaged package to showcase why the item is missing and/or damaged.</p>
                    <div className='flex flex-col items-start justify-center' {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button
                            icon={<img src='/assets/shared/add-plus.svg' className='w-[30px] object-contains pr-2' />}
                            className={'btn-main !bg-white !py-2 !pr-3 !shadow-none !border-none flex flex-row items-center'}
                            label={'Upload file'}
                            dataQa={'select-file'}
                        />
                    </div>
                    {filesSelected && filesSelected?.length > 0 &&
                        <div className='flex flex-rows items-center flex-wrap justify-start mt-6'>
                            {filesSelected?.map((item: any, key: number) => {
                                return (
                                    <div
                                        key={key}
                                        onClick={() => onDeleteImage(item)}
                                        className={'relative flex flex-col items-center mr-4 z-70'}>
                                        <div data-qa={'delete-image'} className='absolute top-[-7px] right-[-7px] cursor-pointer z-50'>
                                            <img src={'/assets/shared/cancel.svg'} className='max-w-[15px] object-contain z-70' />
                                        </div>
                                        <div className='border border-[#000] p-1 z-0'>
                                            <img src={item && URL.createObjectURL(item)} className='max-w-[70px] object-contain' />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default MissingItemModal;