import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import { PaginatedSubBrands } from '../../../../interfaces/sub-brands/paginatedStore.type';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';

const tryToFetchSubBrands = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
): Promise<AxiosResponse<PaginatedSubBrands>> => {
    const url = `${USERS_API_URL}/sub-brands/all?page=${pageNumber}&size=${pageSize}`
    return brokersAxios.put<PaginatedSubBrands>(url);
};

const tryToFetchPartnerSubBrands = (
    filters?: any,
): Promise<AxiosResponse<PaginatedSubBrands>> => {
    const url = `${USERS_API_URL}/sub-brands/all`
    return brokersAxios.put<PaginatedSubBrands>(url, filters);
};


const tryToAddSubBrands = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/sub-brands`, data);
};

const tryToDisableSubBrands = (id: string, partnerId: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/sub-brands/${id}/partner/${partnerId}`);
};

const tryToFetchSingleSubBrands = (id: string, partnerId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/sub-brands/${id}/partner/${partnerId}`);
};

const tryToEditSubBrands = (id: string, payload: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/sub-brands/${id}`, payload);
};

const tryToFetchSubBrandsByMarket = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/users/sub-brands/${id}`);
};

export const SubBrandsService = {
    tryToFetchSubBrands,
    tryToAddSubBrands,
    tryToDisableSubBrands,
    tryToFetchSingleSubBrands,
    tryToFetchPartnerSubBrands,
    tryToFetchSubBrandsByMarket,
    tryToEditSubBrands,
};