import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { AxiosError } from 'axios';
import { ICommissionFeesListState } from '../../../../interfaces/commission-fees/ICommissionFeesList';
import { CommissionFeesService } from './commissionFeesApi';
import { ICommissionFeesSchema } from '../../../../interfaces/commission-fees/ICommissionFeesSchema';
import { PaginatedCommissionFees } from '../../../../interfaces/commission-fees/paginatedStore.type';

const initialState: ICommissionFeesListState = {
    commissionIsLoading: false,
    commissionsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchCommissionFees = createAsyncThunk<
    PaginatedCommissionFees,
    IPaginationPayload
>('commission-fees/tryToFetchCommissionFees', async ({ pageNumber, pageSize, data }) => {
    const result = await CommissionFeesService.tryToFetchCommissionFees(
        pageNumber,
        pageSize,
        data,
    );
    return result?.data;
});

export const tryToDownloadCommissionFees = createAsyncThunk<any>(
    'commission-fees/tryToDownloadCommissionFees', async () => {
        const result = await CommissionFeesService.tryToDownloadCommissionFees();
        return result?.data;
    });

export const tryToFetchSingleCommissionFee = createAsyncThunk<ApiResponse<ICommissionFeesSchema>, string>(
    'commission-fees/tryToFetchSingleCommissionFee',
    async (id: string) => {
        const result = await CommissionFeesService.tryToFetchSingleCommissionFee(id);
        return result?.data;
    },
);

export const tryToEditCommissionFee = createAsyncThunk<
    ApiResponse<ICommissionFeesSchema>,
    ICommissionFeesSchema
>('commission-fees/tryToEditCommissionFee', async (data: ICommissionFeesSchema, { rejectWithValue }) => {
    try {
        const result = await CommissionFeesService.tryToEditCommissionFee(
            data._id || '',
            data,
        );
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToAddCommissionFee = createAsyncThunk<
    ApiResponse<ICommissionFeesSchema>,
    ICommissionFeesSchema
>('commission-fees/tryToAddCommissionFee', async (data: ICommissionFeesSchema, { rejectWithValue }) => {
    try {
        const result = await CommissionFeesService.tryToAddCommissionFee(data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToDeleteCommissionFee = createAsyncThunk<
    ApiResponse<ICommissionFeesSchema>,
    string
>('commission-fees/tryToDeleteCommissionFee', async (id, { rejectWithValue }) => {
    try {
        const results = await CommissionFeesService.tryToDeleteCommissionFee(id);
        return results.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const commissionFeesSlice = createSlice({
    name: 'commissionFees',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchCommissionFees
            .addCase(tryToFetchCommissionFees.pending, (state) => {
                state.commissionsAreLoading = true;
            })
            .addCase(tryToFetchCommissionFees.rejected, (state) => {
                state.commissionsAreLoading = false;
            })
            .addCase(tryToFetchCommissionFees.fulfilled, (state, action) => {
                state.commissionsAreLoading = false;
                state.commissionFees = action.payload;
            })

            // tryToAddCommissionFee
            .addCase(tryToAddCommissionFee.pending, (state) => {
                state.commissionIsLoading = true;
            })
            .addCase(tryToAddCommissionFee.rejected, (state) => {
                state.commissionIsLoading = false;
            })
            .addCase(tryToAddCommissionFee.fulfilled, (state, action) => {
                state.commissionIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.commissionFees?.data)
                    state.commissionFees.data.elements = [
                        ...state.commissionFees?.data.elements,
                        data,
                    ];
            })

            // tryToDeleteCommissionFee
            .addCase(tryToDeleteCommissionFee.pending, (state) => {
                state.commissionIsLoading = true;
            })
            .addCase(tryToDeleteCommissionFee.rejected, (state) => {
                state.commissionIsLoading = false;
            })
            .addCase(tryToDeleteCommissionFee.fulfilled, (state, action) => {
                state.commissionIsLoading = false;
                state.requestStatus = 'success';
                if (state.commissionFees?.data.elements)
                    state.commissionFees.data.elements =
                        { ...state }.commissionFees?.data.elements.filter(
                            (commissionFee) =>
                                commissionFee?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToEditCommissionFee
            .addCase(tryToEditCommissionFee.pending, (state) => {
                state.commissionIsLoading = true;
            })
            .addCase(tryToEditCommissionFee.rejected, (state) => {
                state.commissionIsLoading = false;
            })
            .addCase(tryToEditCommissionFee.fulfilled, (state, action) => {
                state.commissionIsLoading = false;
                if (state.commissionFees?.data.elements)
                    state.commissionFees.data.elements =
                        { ...state }.commissionFees?.data.elements.map((commissionFee) => {
                            if (commissionFee?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return commissionFee;
                            }
                        }) || [];
                state.commissionFee = action.payload.data;
            })

            // tryToFetchSingleCommissionFee
            .addCase(tryToFetchSingleCommissionFee.pending, (state) => {
                state.commissionIsLoading = true;
            })
            .addCase(tryToFetchSingleCommissionFee.rejected, (state) => {
                state.commissionIsLoading = false;
            })
            .addCase(tryToFetchSingleCommissionFee.fulfilled, (state, action) => {
                state.commissionIsLoading = false;
                state.commissionFee = action.payload?.data;
            });
    },
});

export default commissionFeesSlice.reducer;
