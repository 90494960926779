import React, { useEffect, useState } from 'react';
import Input from '../../../../shared/input';
import Button from '../../../../shared/button';
import { IItem } from '../../../../interfaces/items/IItem';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToFetchItemsToShip, tryToShipItems } from '../../../../store/brokers/partners/rma/partnerRMAsSlice';
import ReadyToShipItemsTable from '../tables/ReadyToShipItemsTable';
import EmptyContainer from '../../../../shared/empty-container';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import TrackingNumberModal from '../modals/TrackingNumberModal';
import Toast from '../../../../shared/toast';
import SelectCheckbox from '../../../../shared/select-checkbox';
import { useUserGetProfileQuery } from '../../../../store/user/userDomApi';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

interface IReadyToShipItemsTab {
    paginationState: IPaginationPayload;
    setPaginationState: React.Dispatch<React.SetStateAction<IPaginationPayload>>;
    pageAccess: any;
    sectors: IAssetTemplate[];
}

const ReadyToShipItemsTab = ({ paginationState, setPaginationState, pageAccess, sectors }: IReadyToShipItemsTab) => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IItem[]>([]);
    const [selectedRows, setSelectedRows] = useState<string[] | undefined>();
    const [categories, setCategories] = useState<IAssetTemplate[]>([]);
    const [showAddTrackingNumberModal, setShowAddTrackingNumberModal] = useState(false);
    const [sectorSelected, setSectorSelected] = useState<string>();
    const [loading, setLoading] = useState(false);
    const paginationData = useAppSelector((state) => state.partnersRMAs.items?.data?.page);
    const rowsLoading = useAppSelector((state) => state.partnersRMAs.itemsAreLoading);
    const state = useAppSelector((state) => state.partnersRMAs);
    const [showToast, setShowToast] = useState<IToast | null>();
    const [searchParams, setSearchParams] = useSearchParams();
    const query = useQuery();
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const findSector: any = (sectors && sectorSelected && sectors?.length > 0) && sectors?.find((item: any) => item?.name === sectorSelected)

    const { data: user } = useUserGetProfileQuery();

    useEffect(() => {
        if(user){
            const transformedSectors = user.data.sectors.map((item: IAssetTemplate) => ({
                _id: item.id,
                name: item.name,
                displayName: item.displayName,
                enabledIcon: item.enabledIcon,
                disabledIcon: item.disabledIcon,
                label: item.displayName,
            }));
    
            setCategories([ ...transformedSectors]);
        }
    }, [user])

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
            filters: value || undefined
        });
        if (value) searchParams.set('search', value);
        else searchParams.delete('search');
        searchParams.set('page', '1');
        setSearchParams(searchParams);
    };

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined,
        });
        searchParams.set('page', '1');
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    };

    const onToggleAddTrackingNumber = () => {
        setShowAddTrackingNumberModal(!showAddTrackingNumberModal);
    };

    const onClickSelectRow = (id?: string) => {
        if (!id) {
            const getAllIds = rows?.length > 0 && rows?.filter(row => !selectedRows?.includes(row?.item?._id || '')).map(r => r.item?._id || '');
            if ((selectedRows && rows && rows.every(r => selectedRows.includes(r?.item?._id || '')))) {
                setSelectedRows(sr => sr?.filter(sr => !rows.find(row => row?.item?._id === sr)));
            } else {
                setSelectedRows(sr => getAllIds ? [...(sr || []), ...getAllIds] : getAllIds || []);
            }
        } else {
            const findIfAlreadySelected = selectedRows && selectedRows?.length > 0 && selectedRows?.includes(id);
            if (findIfAlreadySelected) {
                const filterOthers = selectedRows && selectedRows?.length > 0 && selectedRows?.filter((row) => row !== id);
                setSelectedRows(filterOthers || undefined);
            } else {
                const rowToAdd = id;
                if (rowToAdd) {
                    setSelectedRows(sr => [...(sr || []), rowToAdd]);
                }
            }
        }
    };

    const handleTrackingSubmit = (trackingNumber: string, shippingProvider: string) => {
        const itemIds = selectedRows || [];
        try {
            setLoading(true)
            dispatch(tryToShipItems({ itemIds, shippingProvider, trackingNumber }));
            setShowToast({
                type: 'success',
                message: `Tracking Number was added successfully.`,
            });
            setSelectedRows(undefined)
            setShowAddTrackingNumberModal(false);
            setLoading(false);
            searchParams.set('page', '1')
            searchParams.set('size', `${paginationState?.pageSize}`)
            setSearchParams(searchParams)
            onResetPaginationSettings()
            dispatch(tryToFetchItemsToShip({...paginationState, data: { marketName: activeMarketState?.market?.name }}));

        } catch (err: any) {
            setShowAddTrackingNumberModal(false);
            setLoading(false);
            setShowToast({ type: 'error', message: `${err.message}` });
        }
        
    };

    useEffect(() => {
        const paginationStateFormat = paginationState;
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1);
        }
        if (query?.get('size') && Number(query?.get('size')) !== paginationState?.pageSize) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10);
        }
        if (query?.get('search')) {
            paginationStateFormat.filters = query.get('search') || undefined;
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat);
        }
    }, [
        query?.get('page'),
        query?.get('size'),
        query?.get('search'),
    ]);

    useEffect(() => {
        searchParams.set('page', `${paginationState?.pageNumber}`);
        searchParams.set('size', `${paginationState?.pageSize}`);
        setSearchParams(searchParams);
        dispatch(tryToFetchItemsToShip({...paginationState, data: { marketName: activeMarketState?.market?.name }, category: sectorSelected}));
    }, [paginationState, activeMarketState]);

    useEffect(() => {
        if (state.items) {
            const rows =
                state.items?.data?.elements &&
                Array.isArray(state.items?.data?.elements)
                    ? state.items?.data.elements.map((r) => ({
                        ...r,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.items]);

    const onChangeSelectedFilter = (value: any, type?: string) => {
        setSectorSelected(value?.name)
        if (value?.name) searchParams.set('category', value?.name)
        else searchParams.delete('category')
        onResetPaginationSettings()
      }

      
    const onClearAllFilters = () => {
        searchParams.delete('category');
        setSearchParams(searchParams)
        setSectorSelected(undefined)
        setPaginationState({ ...paginationState, filters:  '' })
    }

    return (
        <div>
             <div className="my-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 sm:gap-2 lg:gap-4">
               <SelectCheckbox
                    name='Category'
                    containerStyle={'!min-w-[200px]'}
                    placeholder={findSector?.label || 'All'}
                    dataQa={'Category'}
                    options={[{ name: undefined, displayName: 'All', label: 'All' }, ...categories] || []}
                    onChangeSelectedOption={onChangeSelectedFilter}
                />
            </div>
            <div className="bg-[#F7A21E1A] rounded-[10px] p-2 flex flex-row justify-between items-center">
                <div>
                    <p className="font-bold">
                        {selectedRows ? selectedRows?.length : 0} Items selected
                    </p>
                </div>
                <div className="flex flex-row gap-5 items-center">
                    <Input
                        placeholder={'Filter Table'}
                        containerStyle="mb-0 pb-0 relative flex-initial w-96"
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={paginationState?.filters}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                        dataQa={'filter-table'}
                    />
                    <Button
                        label={'Clear All Filters'}
                        dataQa={'clear-all-filters'}
                        className='btn-primary-text-underline min-w-auto !mt-0 flex flex-row justify-end bg-transparent mr-2 mb-1'
                        onClickButton={onClearAllFilters}
                    />
                    <Button
                        label={'Ship Items'}
                        dataQa={'ship-items'}
                        onClickButton={
                            selectedRows &&
                                selectedRows?.length > 0 &&
                                (!accessControl ||
                                    pageAccess?.actions?.['Ship Items'])
                                ? onToggleAddTrackingNumber
                                : () => { }
                        }
                        className={`${
                            selectedRows &&
                                selectedRows?.length > 0 &&
                                (!accessControl ||
                                    pageAccess?.actions?.['Ship Items'])
                                ? 'btn-main !bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !text-[#202020]'
                                : 'btn-main-disable cursor-not-allowed'
                            }   flex flex-row align-start my-2 !py-3 !px-5`}
                    />
                </div>
            </div>
            <div className="my-5 relativ">
                {rows?.length > 0 || rowsLoading ? (
                    <ReadyToShipItemsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        onClickSelectRow={onClickSelectRow}
                        selectedRows={selectedRows}
                        sectors={sectors}
                    />
                ) : (
                    <EmptyContainer text={'No items found.'} showImage={true} />
                )}
            </div>
            <TrackingNumberModal
                open={showAddTrackingNumberModal}
                onClose={onToggleAddTrackingNumber}
                onSubmit={handleTrackingSubmit}
                nrOfItems={selectedRows?.length || 0}
                loading={loading}
            />
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
        </div>
    );
}

export default ReadyToShipItemsTab;
