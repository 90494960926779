import React, { useState } from 'react';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';


interface IDetailsStep {
    personalData?: any;
    setPersonalData?: any;
    onGoToLogin: () => void;
    onGoToNext: () => void;
}

const DetailsStep = ({
    personalData,
    setPersonalData,
    onGoToLogin,
    onGoToNext
}: IDetailsStep) => {
    const [nextStepError, setNextStepError] = useState<any>();

    const onClickNext = () => {
        let errors: any
        /* eslint-disable-next-line */
        const regexPassword: any = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        /* eslint-disable-next-line */
        const regexUsername: any = /^[^@\s]+$/
        /* eslint-disable-next-line */
        const regexEmail: any = /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!personalData?.username) {
            errors = {
                ...errors || {},
                username: 'Username is required'
            }
        } else if (personalData?.username && !regexUsername.test(personalData?.username)) {
            errors = {
                ...errors || {},
                username: 'Username should not include spaces or @ symbol'
            }
        }
        if (!personalData?.name) {
            errors = {
                ...errors || {},
                name: 'Primary Contact Name is required'
            }
        }
        if (!personalData?.email) {
            errors = {
                ...errors || {},
                email: 'Primary Contact Email is required'
            }
        } else if (personalData?.email && !regexEmail.test(personalData?.email)) {
            errors = {
                ...errors || {},
                email: 'Please write a valid email'
            }
        }
        if (!personalData?.password) {
            errors = {
                ...errors || {},
                password: 'Password is required'
            }
        } else if (personalData?.password && !regexPassword.test(personalData?.password)) {
            errors = {
                ...errors || {},
                password: 'Password should be at least 8 characters long, and must contain at least 1 number, 1 lower case, 1 upper case and 1 special character(@$!%*?&) and spaces are not allowed'
            }
        }
        if (!personalData?.confirmPassword) {
            errors = {
                ...errors || {},
                confirmPassword: 'Repeat Password is required'
            }
        } else if (personalData?.confirmPassword && personalData?.password !== personalData?.confirmPassword) {
            errors = {
                ...errors || {},
                confirmPassword: 'Repeat Password does not match'
            }
        }
        
        if (errors) {
            setNextStepError(errors)
        } else {
            setNextStepError(undefined)
            onGoToNext()
        }
    };

    const onChangeInput = (e: any, type?: string) => {
        if (type) {
            setPersonalData((prevData: any) => ({
                ...prevData || {},
                [type]: e
            }))
        }
    }

    return (
        <div className={`block min-h-full flex flex-col py-4 px-10`}>
            <div className='flex flex-row justify-center my-5'>
                <p className='font-bold mb-2 text-[35px] text-center'>
                    Apply to be a broker on wingpro
                </p>
            </div>
            <p className='font-semibold mb-3'>Account Details</p>
            <div className='grid grid-cols-1 gap-4 mb-3'>
                <Input
                    containerStyle='mb-1'
                    placeholder={'Primary Contact Name'}
                    dataQa={'create-directors-name'}
                    inputValue={personalData?.name}
                    inputUniqueName='name'
                    onChangeInput={onChangeInput}
                    showValue={true}
                    error={nextStepError?.name}
                />
                <Input
                    containerStyle='mb-1'
                    placeholder='Primary Contact Email'
                    dataQa={'create-partner-email'}
                    showValue={true}
                    inputUniqueName='email'
                    onChangeInput={onChangeInput}
                    inputValue={personalData?.email}
                    error={nextStepError?.email}
                />
                <Input
                    containerStyle='mb-1'
                    placeholder='Username'
                    dataQa={'create-partner-username'}
                    inputUniqueName='username'
                    onChangeInput={onChangeInput}
                    showValue={true}
                    inputValue={personalData?.username}
                    error={nextStepError?.username}
                />
                <Input
                    placeholder='Set a password'
                    dataQa={'create-partner-password'}
                    containerStyle='mb-2'
                    type={'password'}
                    showValue={true}
                    onChangeInput={onChangeInput}
                    inputUniqueName={'password'}
                    inputValue={personalData?.password}
                    error={nextStepError?.password}
                />
                <Input
                    placeholder='Repeat Password'
                    dataQa={'create-partner-repeat-password'}
                    containerStyle='mb-2'
                    type={'password'}
                    showValue={true}
                    inputUniqueName='confirmPassword'
                    onChangeInput={onChangeInput}
                    inputValue={personalData?.confirmPassword}
                    error={nextStepError?.confirmPassword}
                />
            </div>
            <div className='flex flex-row justify-center py-2'>
                <Button
                    label='Next'
                    onClickButton={onClickNext}
                    className={'btn-primary !min-w-[200px] !py-2'}
                    dataQa={'next-partner-btn'}
                />
            </div>
            <div className='my-4 flex flex-row items-center justify-center'>
                <div className='min-w-[20%] h-[0.7px] m-1 inline-block bg-black'></div>
                <div className='inline-block mx-2 font-semibold'>Or</div>
                <div className='min-w-[20%] h-[0.7px] m-1 inline-block bg-black'></div>
            </div>
            <div className='my-3 flex flex-row justify-center items-center'>
                <p>Already have an account? </p>
                <Button
                    className='text-primary-default !pl-0 !ml-1 underline font-medium'
                    label='Log in'
                    dataQa={'login-button'}
                    onClickButton={onGoToLogin}
                />
            </div>
        </div >
    );
};

export default DetailsStep;
