import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IComparisonSitePricingListState } from '../../../../interfaces/comparison-sites-pricing/IComparisonSitePricingList';
import { PaginatedComparisonSitePricing } from '../../../../interfaces/comparison-sites-pricing/paginatedStore.type';
import { ComparisonPricingService } from './comparisonPricingApi';

const initialState: IComparisonSitePricingListState = {
    pricingsAreLoading: false,
    pricingIsLoading: false,
    requestStatus: 'default',
    pricingUploader: {
        uploading: false,
        success: false,
    },
    message: '',
};

export const tryToFetchFilteredComparisonPricings = createAsyncThunk<PaginatedComparisonSitePricing, IPaginationPayload>(
    'comparison-pricing/tryToFetchFilteredComparisonPricings',
    async ({ pageNumber, pageSize, data }) => {
        const result = await ComparisonPricingService.tryToFetchFilteredComparisonPricings(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToDownloadComparisonPricing = createAsyncThunk<any, any>(
    'comparison-pricing/tryToDownloadComparisonPricing',
    async (data: any, { rejectWithValue }) => {
        try {
            return await ComparisonPricingService.tryToDownloadComparisonPricing(data);
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToUploadComparisonPricingCSV = createAsyncThunk<Promise<AxiosResponse<void>>, { data: any, assetTemplateId: string, marketId?: string, currency?: string, subBrandId?: string, template?: any }>(
    'comparison-pricing/tryToUploadComparisonPricingCSV',
    async ({ data, assetTemplateId, marketId, currency, subBrandId, template }) => {
        return await ComparisonPricingService.tryToUploadComparisonPricingCSV(data, assetTemplateId, marketId, currency, subBrandId, template);
    },
);

export const comparisonPricingSlice = createSlice({
    name: 'comparisonPricing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchFilteredComparisonPricings
            .addCase(tryToFetchFilteredComparisonPricings.pending, (state) => {
                state.pricingsAreLoading = true;
            })
            .addCase(tryToFetchFilteredComparisonPricings.rejected, (state) => {
                state.pricingsAreLoading = false;
            })
            .addCase(tryToFetchFilteredComparisonPricings.fulfilled, (state, action) => {
                state.pricingsAreLoading = false;
                state.comparisonPricings = action.payload;
            });
    },
});

export default comparisonPricingSlice.reducer;