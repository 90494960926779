import React from 'react';
import { AdminGuard } from '../../../../guards/RolesGuard';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { INVENTORY_FILTERS } from '../../../../utils/constants/inventory-filter-values';
import { MAPPING_STATUS_ITEMS } from '../../../../utils/constants/mapping-status';
import { PRICING_TYPES } from '../../../../utils/constants/pricing-types';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IMappingFilters {
    sectors?: Array<IAssetTemplate>;
    partners?: any;
    selectedEnable?: any;
    selectedSector?: any;
    selectedPartner?: any;
    selectedStatus?: any;
    selectedType?: any;
    onSelectFilter: (id: string, type?: string) => void;
    onClearAllFilters: () => void;
}

const MappingFilters = ({
    sectors,
    selectedEnable,
    selectedSector,
    partners,
    selectedPartner,
    selectedStatus,
    selectedType,
    onSelectFilter,
}: IMappingFilters) => {
    const isAdmin = AdminGuard()
    return (
        <div>
            <div className={`mb-2 grid ${isAdmin ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-5' : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'} gap-1 md:gap-2 lg:gap-4`}>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Category'}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        placeholder={' '}
                        dataQa={selectedSector?.name === undefined ? 'category-selected' : selectedSector?.name.toLowerCase()}
                        selectedOption={selectedSector}
                        options={sectors || []}
                        uniqueName={'category'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Status'}
                        placeholder={' '}
                        dataQa={selectedStatus?.name === undefined ? 'status-selected' : selectedStatus?.name.toLowerCase()}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        options={MAPPING_STATUS_ITEMS || []}
                        uniqueName={'status'}
                        selectedOption={selectedStatus}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Enabled'}
                        placeholder={' '}
                        dataQa={selectedEnable?.value === undefined ? 'enabled-selected' : selectedEnable?.value.toLowerCase()}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        options={INVENTORY_FILTERS || []}
                        selectedOption={selectedEnable}
                        uniqueName={'enable'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Type'}
                        placeholder={' '}
                        dataQa={selectedType?.name === undefined ? 'type-selected': selectedType?.name.toLowerCase()}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        options={PRICING_TYPES || []}
                        selectedOption={selectedType}
                        uniqueName={'type'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                {isAdmin &&
                    <div className='my-4'>
                        <SelectCheckbox
                            name={'Broker'}
                            placeholder={' '}
                            dataQa={selectedPartner?.name === undefined ? 'broker-selected' : selectedPartner?.name.toLowerCase()}
                            selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                            options={partners || []}
                            selectedOption={selectedPartner}
                            uniqueName={'partner'}
                            onChangeSelectedOption={onSelectFilter}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default MappingFilters;