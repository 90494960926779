import { AxiosResponse } from 'axios';
import { DEVICE_INFO_API } from '../../../../config';
import aiAgentAxios from '../../../../utils/axios/aiAgent.axios';
import { PaginatedAssetBatch, PaginatedAssetTemplate } from '../../../../interfaces/asset-templates/paginatedStore.type';
import { IAssetDetails } from '../../../../interfaces/asset-templates/IAssetTemplateSchema';
import { IAgentCallProps, IAgentCallResponse } from '../../../../interfaces/ai-agents/IAgentCall';

const tryToFetchAssets = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
	sessionId: string = ''
): Promise<AxiosResponse<PaginatedAssetTemplate>> => {
    const url = filters
        ? `${DEVICE_INFO_API}onboarding/asset-library/${sessionId}?page=${pageNumber}&size=${pageSize}&search=${filters}&marketCountry=GB`
        : `${DEVICE_INFO_API}onboarding/asset-library/${sessionId}?page=${pageNumber}&size=${pageSize}&marketCountry=GB`;
    return aiAgentAxios.get<PaginatedAssetTemplate>(url);
};

const tryToFetchBatchAssets = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
	sessionId: string = ''
): Promise<AxiosResponse<PaginatedAssetBatch>> => {
    const url = filters
        ? `${DEVICE_INFO_API}onboarding/batch-asset-breakdown/${sessionId}?page=${pageNumber}&size=${pageSize}&search=${filters}&marketCountry=GB`
        : `${DEVICE_INFO_API}onboarding/batch-asset-breakdown/${sessionId}?page=${pageNumber}&size=${pageSize}&marketCountry=GB`;
    return aiAgentAxios.get<PaginatedAssetBatch>(url);
};

const tryToFetchAssetsDetails = (
    id: string,
): Promise<AxiosResponse<AxiosResponse<IAssetDetails>>> => {
    const url = `${DEVICE_INFO_API}onboarding/asset-item-details/${id}?marketCountry=GB`;
    return aiAgentAxios.get<AxiosResponse<IAssetDetails>>(url);
};

const tryToBrokerCall = (
    data: IAgentCallProps,
): Promise<AxiosResponse<{data: string}>> => {
    const url = `${DEVICE_INFO_API}ai-voice-calling/broker/call`;
    return aiAgentAxios.post<{data: string}>(url, data);
};

const tryToGetBrokerCallDetails = (
    id: string,
): Promise<AxiosResponse<AxiosResponse<IAgentCallResponse | null>>> => {
    const url = `${DEVICE_INFO_API}ai-voice-calling/call/${id}`;
    return aiAgentAxios.get<AxiosResponse<IAgentCallResponse | null>>(url);
};

export const AssetLibraryService = {
    tryToFetchAssets,
	tryToFetchBatchAssets,
	tryToFetchAssetsDetails,
	tryToBrokerCall,
	tryToGetBrokerCallDetails
};
