import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from './login-form';
import ForgotPassword from './modal/ForgotPasswordModal';
import LayoutLogin from './layout/LayoutLogin';
import Button from '../../../shared/button';


const Login = () => {
    const navigate = useNavigate()
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false)

    const onShowHideForgotPassword = () => {
        setShowForgotPasswordModal(!showForgotPasswordModal)
    }

    return (
        <LayoutLogin>
            <div className='min-w-[100%] min-h-full flex flex-col items-center justify-center'>
                {/* <NavLink to='/'> */}
                {/* Logo here */}
                {/* <img className='max-w-[290px]' alt='for the earth' src='/logo/logo.svg' /> */}
                {/* </NavLink> */}
                <div className='m-auto'>
                    <h1 className='font-dm_sansbold text-4xl'>Please fill your details</h1>
                    <LoginForm
                        onShowHideForgotPassword={onShowHideForgotPassword} />
                    <div className='my-4 flex flex-row items-center justify-center'>
                        <div className='min-w-[40%] h-[0.7px] m-1 inline-block bg-black'></div>
                        <div className='inline-block mx-2 font-semibold'>Or</div>
                        <div className='min-w-[40%] h-[0.7px] m-1 inline-block bg-black'></div>
                    </div>
                    <div className='my-3 flex flex-row justify-center items-center'>
                        <p>I don’t have an account. </p>
                        <Button
                            className='text-primary-default !pl-0 !ml-1 underline font-medium'
                            label='Apply Now'
                            dataQa={'create-acc-link'}
                            onClickButton={() => navigate('/apply')}
                        />
                    </div>
                </div>
                <ForgotPassword
                    openForgotModal={showForgotPasswordModal}
                    handleCloseForgotModal={onShowHideForgotPassword}
                />
            </div>
        </LayoutLogin>
    )
}

export default Login;