import { AxiosResponse } from 'axios';
import {
    ORDERS_URL,
} from '../../../../config';
import ordersAxios from '../../../../utils/axios/orders.axios';
import { PaginatedInvoices, PaginatedInvoicesDetails } from '../../../../interfaces/invoices/paginatedStore.type';
import { IInvoiceFilter } from '../../../../interfaces/invoices/IInvoiceFilter';
import { IMarkeInvoice } from '../../../../interfaces/invoices/IMarkeInvoice';
import { IGenerateInvoice } from '../../../../interfaces/invoices/IGenerateInvoice';

const tryToFetchFilteredInvoices = (
    pageNumber: number,
    pageSize: number,
    data?: IInvoiceFilter
): Promise<AxiosResponse<PaginatedInvoices>> => {
    const url = `${ORDERS_URL}/bills/history?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<PaginatedInvoices>(url, data);
};

const tryToFetchFilteredBills = (
    pageNumber: number,
    pageSize: number,
    data?: IInvoiceFilter
): Promise<AxiosResponse<PaginatedInvoicesDetails>> => {
    let url
    if (data?.invoiceNumberId) {
        url = data?.download ? `${ORDERS_URL}/bills/spread/${data.invoiceNumberId}` : `${ORDERS_URL}/bills/spread/${data.invoiceNumberId}?page=${pageNumber}&size=${pageSize}`
    } else {
        url = data?.download ? `${ORDERS_URL}/bills/spread` : `${ORDERS_URL}/bills/spread?page=${pageNumber}&size=${pageSize}`
    }
    return ordersAxios.put<PaginatedInvoicesDetails>(url, data);
};

const tryToDownloadInvoicesCSV = (payload: IInvoiceFilter): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/bills/history/download`, payload);
};

const tryToShipOrder = (id: string): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/bills/invoice-ship/${id}`);
};

const tryToDownloadInvoiceDetailsCSV = (invoiceNumber: string): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/bills/export/${invoiceNumber}`);
};

const tryToMarkInvoiceAsPaid = (payload: IMarkeInvoice): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/bills/mark-as-paid`, payload);
};

const tryToFetchItemsWithoutInvoice = (
    pageNumber: number,
    pageSize: number,
    data?: any,
    search?: string
): Promise<AxiosResponse<PaginatedInvoicesDetails>> => {
    const url = search ? `${ORDERS_URL}/bills/spread/unpaid?page=${pageNumber}&size=${pageSize}&search=${search}` : `${ORDERS_URL}/bills/spread/unpaid?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<PaginatedInvoicesDetails>(url, data);
};

const tryToGenerateInvoice = (payload: IGenerateInvoice): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/bills/generate`, payload);
};

const tryToGenerateMultipleInvoice = (payload: IGenerateInvoice): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/bills/generate/multiple-links`, payload);
};

const tryToDownloadInvoiceDetailsPdf = (invoiceNumber: string): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/bills/export/pdf/${invoiceNumber}`);
};

export const InvoicesService = {
    tryToFetchFilteredInvoices,
    tryToMarkInvoiceAsPaid,
    tryToDownloadInvoicesCSV,
    tryToDownloadInvoiceDetailsCSV,
    tryToFetchFilteredBills,
    tryToFetchItemsWithoutInvoice,
    tryToGenerateInvoice,
    tryToGenerateMultipleInvoice,
    tryToDownloadInvoiceDetailsPdf,
    tryToShipOrder
};
