import React from 'react';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IGenerateInvoiceFilters {
    markets?: Array<any>;
    partners?: Array<any>;
    sectors?: Array<any>;
    selectedMarket?: any;
    selectedPartner?: any;
    selectedSector?: any;
    selectedPurchaseMarket?: any;
    onChangeSelectedFilter: (e: any, name?: string) => void;
}

const GenerateInvoiceFilters = ({
    markets,
    partners,
    sectors,
    selectedMarket,
    selectedPartner,
    selectedSector,
    selectedPurchaseMarket,
    onChangeSelectedFilter
}: IGenerateInvoiceFilters) => {

    return (
        <div className='grid grid-cols-3 gap-5'>
            <SelectCheckbox
                name='Market'
                placeholder=' '
                dataQa={'market-selected'}
                containerStyle='mb-1 min-w-full'
                selectedOption={selectedMarket}
                onChangeSelectedOption={onChangeSelectedFilter}
                uniqueName={'market'}
                options={markets || []}
            />
            <SelectCheckbox
                name='Partner'
                placeholder={' '}
                dataQa={'parter'}
                containerStyle='mb-1 min-w-full'
                selectedOption={selectedPartner}
                onChangeSelectedOption={onChangeSelectedFilter}
                uniqueName={'partner'}
                options={partners || []}
            />
            <SelectCheckbox
                name='Category'
                placeholder='All Categories'
                dataQa={'category-selected'}
                containerStyle='mb-1 min-w-full'
                selectedOption={selectedSector}
                onChangeSelectedOption={onChangeSelectedFilter}
                uniqueName={'sector'}
                options={sectors ? [{ name: 'All Categories', label: 'All Categories', value: undefined }, ...sectors] : []}
            />
            {/* <SelectCheckbox
                name='Purchase Market'
                placeholder=' '
                dataQa={'purchase-market'}
                containerStyle='mb-1 min-w-full'
                selectedOption={selectedPurchaseMarket}
                onChangeSelectedOption={onChangeSelectedFilter}
                uniqueName={'purchaseMarket'}
                options={markets || []}
            /> */}
        </div>
    )
}
export default GenerateInvoiceFilters