import { AxiosResponse } from 'axios';
import {
    OFFER_API_URL,
    UPLOAD_PRICING_URL
} from '../../../../config';
import offersAxios from '../../../../utils/axios/offers.axios';
import uploadPricingAxios from '../../../../utils/axios/upload-pricing.axios';
import { PaginatedPricing } from '../../../../interfaces/pricing/paginatedStore.type';
import {
    IPricing,
    PricingRecordDto,
} from '../../../../interfaces/pricing/IPricing';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';


const tryToFetchFilteredPricings = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse<PaginatedPricing>> => {
    const url = `${OFFER_API_URL}/offers?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedPricing>(url, data);
};

const tryToFetchFilteredAdminPricings = (
    pageNumber: number,
    pageSize: number,
    data?: any,
    brokerId?: string,
): Promise<AxiosResponse<PaginatedPricing>> => {
    const url = `${OFFER_API_URL}/offers/user/${brokerId}?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedPricing>(url, data);
};

const tryToFetchBestPrice = (data: any): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/dom/price-revaluate`, data);
};

const tryToDownloadPricing = (assetTemplateId: string, market: string): Promise<AxiosResponse> => {
    return offersAxios.get(`${OFFER_API_URL}/offers/download/${assetTemplateId}/${market}`);
};

const tryToDownloadPricingAdmin = (data: any, brokerId: string): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/offers/user/${brokerId}`, data);
};

const tryToAddPricing = (data: IPricing): Promise<AxiosResponse> => {
    return offersAxios.post(`${OFFER_API_URL}/pricing`, data);
};

const tryToUploadPricingCSV = async (
    data: any,
    templateId: string,
    marketId?: string,
    currency?: string,
    brokerId?: string,
    template?: string
): Promise<any> => {
    const urlOrder = `${UPLOAD_PRICING_URL}/offers`;
    const formatData: any = {
        offers: {
            assetTemplateId: templateId,
            data: data,
        },
    };
    if (currency) {
        formatData.offers = {
            ...formatData.offers || {},
            currency: currency || ''
        }
    }
    if (marketId) {
        formatData.offers = {
            ...formatData.offers || {},
            marketId: marketId || ''
        }
    }
    if (brokerId) {
        formatData.offers = {
            ...formatData.offers || {},
            brokerId: brokerId || ''
        }
    }
    if (template) {
        formatData.offers = {
            ...formatData.offers || {},
            template: template || ''
        }
    }
    await uploadPricingAxios.post<ApiResponse<PricingRecordDto>>(urlOrder, formatData);
    return formatData;
};

const tryToDeleteBrokerPricing = (brokerId: string, data?: any): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/offers/batch/${brokerId}`, data);
};

const tryToChangePricingData = (data: any): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/offers/pricing`, data);
};

export const PricingService = {
    tryToDownloadPricing,
    tryToDownloadPricingAdmin,
    tryToFetchFilteredPricings,
    tryToFetchFilteredAdminPricings,
    tryToFetchBestPrice,
    tryToAddPricing,
    tryToUploadPricingCSV,
    tryToDeleteBrokerPricing,
    tryToChangePricingData
};
