import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../../config';
import ordersAxios from '../../../../utils/axios/orders.axios';
import { ITicket } from '../../../../interfaces/orders/ITicket';
import { readFromStorage } from '../../../../utils/webStorage';
import { PaginatedOrders, PaginatedShippedOrders } from '../../../../interfaces/orders/paginatedStore.type';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { ISingleOrderSchema } from '../../../../interfaces/orders/IOrdersSchema';
import { IOrderFilterBody } from '../../../../interfaces/orders/IOrderFilterBody';

const tryToFetchOrders = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: IOrderFilterBody,
): Promise<AxiosResponse<PaginatedOrders>> => {
    let url = `${ORDERS_URL}/shipments?page=${pageNumber}&size=${pageSize}`;
    if (filters) {
        url = `${ORDERS_URL}/shipments?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
    }
    return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchHighLevelOrders = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: IOrderFilterBody,
): Promise<AxiosResponse<PaginatedOrders>> => {
    let url = `${ORDERS_URL}/shipments/order-view?page=${pageNumber}&size=${pageSize}`;
    if (filters) {
        url = `${ORDERS_URL}/shipments/order-view?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
    }
    return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchInboundOrders = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: IOrderFilterBody
): Promise<AxiosResponse<PaginatedOrders>> => {
    const group = readFromStorage('group');
    let url: string;
    if (group === 'cs') {
        url = `${ORDERS_URL}/support/shipments?page=${pageNumber}&size=${pageSize}`;
        if (filters) {
            url = `${ORDERS_URL}/support/shipments?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
        }
    } else {
        url = `${ORDERS_URL}/shipments/inbound?page=${pageNumber}&size=${pageSize}`;
        if (filters) {
            url = `${ORDERS_URL}/shipments/inbound?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
        }
    }
    return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchOrdersInReview = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: IOrderFilterBody
): Promise<AxiosResponse<PaginatedOrders>> => {
    let url = `${ORDERS_URL}/shipments/in-review?page=${pageNumber}&size=${pageSize}`;
    if (filters) {
        url = `${ORDERS_URL}/shipments/in-review?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
    }
    return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchCashouts = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: IOrderFilterBody
): Promise<AxiosResponse<PaginatedOrders>> => {
    let url = `${ORDERS_URL}/shipments/cashouts?page=${pageNumber}&size=${pageSize}`;
    if (filters) {
        url = `${ORDERS_URL}/shipments/cashouts?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
    }
    return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchOrdersInCs = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: IOrderFilterBody
): Promise<AxiosResponse<PaginatedOrders>> => {
    let url = `${ORDERS_URL}/shipments/in-cs?page=${pageNumber}&size=${pageSize}`;
    if (filters) {
        url = `${ORDERS_URL}/shipments/in-cs?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
    }
    return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchResolvedOrders = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: IOrderFilterBody
): Promise<AxiosResponse<PaginatedOrders>> => {
    let url = `${ORDERS_URL}/shipments/resolved?page=${pageNumber}&size=${pageSize}`;
    if (filters) {
        url = `${ORDERS_URL}/shipments/resolved?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
    }
    return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchRecentOrders = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: IOrderFilterBody
): Promise<AxiosResponse<PaginatedOrders>> => {
    let url = `${ORDERS_URL}/shipments/recent-changes?page=${pageNumber}&size=${pageSize}`;
    if (filters) {
        url = `${ORDERS_URL}/shipments/recent-changes?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
    }
    return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchSingleOrder = (
    orderId: string,
    data: any
): Promise<AxiosResponse<ApiResponse<ISingleOrderSchema>>> => {
    const isCS = readFromStorage('group') === 'cs';
    const url = isCS
        ? `${ORDERS_URL}/support/shipments/${orderId}`
        : `${ORDERS_URL}/shipments/${orderId}`;
    return ordersAxios.put<ApiResponse<ISingleOrderSchema>>(url, data);
};

const tryToFindItemByImei = (imei: string): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/shipments/item-details`, { imei: imei, });
};

const tryToFindItemByTrackingNumber = (trackingNumber: string): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/shipments/shipment-filters/${trackingNumber}`);
};

const tryToChangeImei = (itemId: string, imei: string): Promise<AxiosResponse<void>> => {
    return ordersAxios.post<void>(`${ORDERS_URL}/shipments/${itemId}/imei`, {
        imei: imei,
    });
};

const tryToFetchImeiStatus = (orderId: string, itemId: string, data: any): Promise<AxiosResponse> => {
    return ordersAxios.put<void>(`${ORDERS_URL}/shipments/${orderId}/item/${itemId}/blacklist-status`, data)
}

const tryToGetCurrentImeiStatus = (imei: string): Promise<AxiosResponse> => {
    return ordersAxios.put<void>(`${ORDERS_URL}/shipments/item/black-list-status/${imei}`)
}

const tryToFetchImeiStatusCheck = (orderId: string, itemId: string, data: any): Promise<AxiosResponse> => {
    return ordersAxios.put<void>(`${ORDERS_URL}/shipments/${orderId}/item/${itemId}/blacklist-status/check`, data)
}

const tryToAddReturnTrackingNumber = (
    itemId: string,
    data: {
        returnTrackingNumber: string,
        carrier: string,
        carrierId: string
    },
): Promise<AxiosResponse<void>> => {
    return ordersAxios.put<void>(`${ORDERS_URL}/shipments/item/${itemId}/edit-return-tracking-number`, data);
};

const tryToFetchOrdersCount = (data: { country: string, marketId: string, isMultiple?: boolean }): Promise<AxiosResponse<any>> => {
    return ordersAxios.put<void>(`${ORDERS_URL}/shipments/count`, data);
};

const tryToFetchOrderInsightInfo = (data: any): Promise<AxiosResponse<any>> => {
    return ordersAxios.put<void>(`${ORDERS_URL}/shipments/insights/info`, data);
};

const tryToFetchOrderInsightDetails = (data: any): Promise<AxiosResponse<any>> => {
    return ordersAxios.put<void>(`${ORDERS_URL}/shipments/insights/info/detailed`, data);
};

const tryToAcceptOrder = (orderId: string, data: any): Promise<AxiosResponse<void>> => {
    return ordersAxios.post<void>(
        `${ORDERS_URL}/shipments/${orderId}/accept`,
        data,
    );
};

const tryToAcceptAllOrderItems = (id: string): Promise<AxiosResponse<void>> => {
    return ordersAxios.put<void>(`${ORDERS_URL}/shipments/${id}/accept-items/all`);
};

const tryToSetMissingPackage = (data: any): Promise<AxiosResponse<void>> => {
    return ordersAxios.put<void>(
        `${ORDERS_URL}/shipments/item/mark-as-missing`,
        data,
    );
};

const tryToDeliverOrder = (trackingNumber: string): Promise<AxiosResponse<void>> => {
    return ordersAxios.put<void>(`${ORDERS_URL}/shipments/${trackingNumber}/change-status`);
};

const tryToDeliverOrdersMultiple = (data: any): Promise<AxiosResponse<void>> => {
    return ordersAxios.put<void>(`${ORDERS_URL}/shipments/change-status/multiple`, data)
}

const tryToRejectOrder = (orderId: string, data: any): Promise<AxiosResponse<void>> => {
    return ordersAxios.post<void>(`${ORDERS_URL}/shipments/${orderId}/reject`, data);
};

const tryToRevalueOrder = (orderId: string, data: any): Promise<AxiosResponse<void>> => {
    return ordersAxios.post<void>(
        `${ORDERS_URL}/shipments/${orderId}/revalue`,
        data,
    );
};

const tryToReviewOrder = (orderId: string, data: any): Promise<AxiosResponse<void>> => {
    return ordersAxios.post<void>(
        `${ORDERS_URL}/shipments/${orderId}/review`,
        data,
    );
};

const tryToToggleFMI = (orderId: string, fmi: boolean): Promise<AxiosResponse<void>> => {
    return ordersAxios.post<void>(
        `${ORDERS_URL}/shipments/${orderId}/toggle-fmi`,
        { fmi: fmi },
    );
};

const tryToGetTickets = (orderId: string): Promise<AxiosResponse<ITicket[]>> => {
    const isCS = readFromStorage('group') === 'cs';
    const url = isCS
        ? `${ORDERS_URL}/support/shipments/${orderId}/tickets`
        : `${ORDERS_URL}/shipments/${orderId}/tickets`;
    return ordersAxios.get<ITicket[]>(url);
};

const tryToFetchAllInboundOrders = (): Promise<AxiosResponse<PaginatedOrders>> => {
    const url = `${ORDERS_URL}/shipments/inbound`;
    return ordersAxios.put<PaginatedOrders>(url);
};

const tryToFetchAllOrdersInReview = (): Promise<AxiosResponse<PaginatedOrders>> => {
    const url = `${ORDERS_URL}/shipments/in-review`;
    return ordersAxios.put<PaginatedOrders>(url);
};

const tryToAddLocationOnOrder = (trackingNumber: string, data: any): Promise<AxiosResponse<void>> => {
    return ordersAxios.put<void>(`${ORDERS_URL}/shipments/${trackingNumber}/inventory`, data);
};

const tryToFindAvoidedEmissionMonthlyData = (data: any): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/shipments/carbon-emission/graph-data`, data);
};

const tryToFetchShippedOrders = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any,
): Promise<AxiosResponse<PaginatedShippedOrders>> => {
    let url = `${ORDERS_URL}/shipments/orders/view-shipped-orders?page=${pageNumber}&size=${pageSize}`;
    if (filters) {
        url = `${ORDERS_URL}/shipments/orders/view-shipped-orders?page=${pageNumber}&size=${pageSize}&search=${filters}`;
    }
    return ordersAxios.put<PaginatedShippedOrders>(url, data);
};

const tryToFetchShippedOrderDetails = (
    pageNumber: number,
    pageSize: number,
    id: string,
    filters?: string,
    data?: any,
): Promise<AxiosResponse<PaginatedShippedOrders>> => {
    let url = `${ORDERS_URL}/shipments/orders/${id}/view-shipped-orders/details?page=${pageNumber}&size=${pageSize}`;
    if (filters) {
        url = `${ORDERS_URL}/shipments/orders/${id}/view-shipped-orders/details?page=${pageNumber}&size=${pageSize}&search=${filters}`;
    }
    return ordersAxios.put<PaginatedShippedOrders>(url, data);
};

export const OrdersService = {
    tryToFetchOrders,
    tryToFetchHighLevelOrders,
    tryToFetchInboundOrders,
    tryToFetchOrdersInReview,
    tryToFetchOrdersInCs,
    tryToFetchResolvedOrders,
    tryToFetchSingleOrder,
    tryToFetchRecentOrders,
    tryToFetchOrdersCount,
    tryToFindItemByImei,
    tryToFetchImeiStatus,
    tryToAddReturnTrackingNumber,
    tryToChangeImei,
    tryToAcceptOrder,
    tryToRejectOrder,
    tryToRevalueOrder,
    tryToReviewOrder,
    tryToSetMissingPackage,
    tryToToggleFMI,
    tryToGetTickets,
    tryToDeliverOrder,
    tryToDeliverOrdersMultiple,
    tryToFetchAllInboundOrders,
    tryToFetchAllOrdersInReview,
    tryToFindItemByTrackingNumber,
    tryToAddLocationOnOrder,
    tryToFindAvoidedEmissionMonthlyData,
    tryToFetchOrderInsightInfo,
    tryToFetchShippedOrders,
    tryToFetchOrderInsightDetails,
    tryToFetchImeiStatusCheck,
    tryToAcceptAllOrderItems,
    tryToFetchShippedOrderDetails,
    tryToFetchCashouts,
    tryToGetCurrentImeiStatus
};
