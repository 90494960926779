import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import { IOrderFilterBody } from '../../../interfaces/orders/IOrderFilterBody';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToAcceptAllOrderItems,
    tryToFetchHighLevelOrders,
} from '../../../store/brokers/partners/orders/ordersSlice';
import { useUserGetProfileQuery } from '../../../store/user/userDomApi';
import { tryToFindOperatingMarkets } from '../../../store/brokers/admin/partners/partnersSlice';
import ActionCheckModal from '../orders/modals/ActionCheckModal';
import OrderCategories from './details/OrderCategories';
import OrdersTable from './tables/OrdersTable';
import Input from '../../../shared/input';
import Toast from '../../../shared/toast';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const OrdersHighLevel = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const query = useQuery();
    const [rows, setRows] = useState<Array<any>>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: undefined,
    });
    const paginationData = useAppSelector(
        (state) => state?.orders?.highLevelOrders?.data?.page,
    );
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>();
    // const [ordersPerCategory, setOrdersPerCategory] = useState<any>()
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>();
    const [loadingAccept, setLoadingAccept] = useState<boolean>(false);
    const [showAcceptConfirmModal, setShowAcceptConfirmModal] = useState<{ show: boolean; orderId: string; }>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const state = useAppSelector((state) => state.orders);
    const ordersLoading = useAppSelector((state) => state?.orders?.ordersAreLoading);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket,);
    const { data: user } = useUserGetProfileQuery();

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined,
        });
        searchParams.set('page', '1');
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    };

    const getBrokerMarketsAndSectors = async () => {
        const formatSectors: any =
            user?.data?.sectors?.length > 0
                ? user?.data?.sectors?.map((s: any) => ({
                    ...s,
                    label: s?.displayName || s?.name,
                    value: s?.name,
                }))
                : [];
        const filterSectors: any = formatSectors && formatSectors?.length > 0 && formatSectors?.filter((s: any) =>
            (s?.label || '').toLowerCase() !== 'mens wear' &&
            (s?.label || '').toLowerCase() !== 'womens wear',
        );
        setBrokerCategories(filterSectors || []);
        setSelectedCategory(query?.get('category') || filterSectors?.[0]?.value);
        onResetPaginationSettings();
        // searchParams.set('category', query?.get('category') || formatSectors?.[0]?.value)
        // setSearchParams(searchParams)
    };

    useEffect(() => {
        getBrokerMarketsAndSectors();
        dispatch(tryToFindOperatingMarkets(null));
    }, []);

    useEffect(() => {
        getBrokerMarketsAndSectors();
        dispatch(tryToFindOperatingMarkets(null));
    }, [user])

    useEffect(() => {
        if (activeMarketState && JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket)) {
            setActiveMarket(activeMarketState || undefined);
            onResetPaginationSettings();
        }
    }, [activeMarketState]);

    // useEffect(() => {
    //     setOrdersPerCategory(ordersCount || undefined)
    // }, [ordersCount])

    useEffect(() => {
        const paginationStateFormat = paginationState;
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1);
        }
        if (query?.get('size') || query?.get('search')) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10);
            paginationStateFormat.filters = query.get('search') || undefined;
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat);
        }
        if (query?.get('category') && query?.get('category') !== selectedCategory) {
            setSelectedCategory(query?.get('category') || undefined);
        }
    }, [
        query?.get('page'),
        query?.get('size'),
        query?.get('search'),
        query?.get('category'),
    ]);

    const getOrdersData = async () => {
        if (!selectedCategory) {
            return;
        }
        const data: IOrderFilterBody = {};
        if (activeMarket) {
            data.country = activeMarket?.market?.name;
            data.marketId = activeMarket?.market?._id;
        }
        if (selectedCategory) {
            const findCategory = (brokerCategories && brokerCategories?.length > 0) && brokerCategories?.find((category) => category?.value === selectedCategory);
            data.category = {
                id: findCategory?.id,
                name: selectedCategory,
            };
        }
        const resolvedData = { ...paginationState, data };
        searchParams.set('page', `${paginationState?.pageNumber}`);
        searchParams.set('size', `${paginationState?.pageSize}`);
        setSearchParams(searchParams);
        dispatch(tryToFetchHighLevelOrders(resolvedData));
    };

    useEffect(() => {
        if (state.highLevelOrders) {
            const rowsData = state.highLevelOrders?.data?.elements &&
                Array.isArray(state.highLevelOrders?.data.elements)
                ? state.highLevelOrders?.data.elements.map((r) => ({
                    ...r,
                    id: r.id,
                }))
                : [];
            if (rowsData !== rows) {
                setRows(rowsData);
            }
        }
    }, [state.highLevelOrders]);

    useEffect(() => {
        if (activeMarket && paginationState) {
            getOrdersData();
        }
    }, [activeMarket, paginationState]);

    const onSeeOrderDetails = (orderId: string) => {
        navigate(`/order-items?search=${orderId}&category=${selectedCategory}`);
    };

    const onChangeCategorySelected = (value: string) => {
        setSelectedCategory(value);
        searchParams.set('category', value);
        setSearchParams(searchParams);
        onResetPaginationSettings();
    };

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setPaginationState({
            ...(paginationState || {}),
            pageNumber: 1,
            filters: value || undefined,
        });
        if (value) searchParams.set('search', value);
        else searchParams.delete('search');
        searchParams.set('page', '1');
        setSearchParams(searchParams);
    };

    const onClickAcceptAllItems = async () => {
        if (loadingAccept) {
            return;
        }
        try {
            setLoadingAccept(true);
            await dispatch(
                tryToAcceptAllOrderItems(showAcceptConfirmModal?.orderId || ''),
            ).unwrap();
            setShowToast({
                type: 'success',
                message: `Order Items were accepted successfully.`,
            });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        setShowAcceptConfirmModal(undefined);
        setLoadingAccept(false);
    };

    const onRequestAcceptAll = (orderId: string) => {
        setShowAcceptConfirmModal({
            show: true,
            orderId: orderId,
        });
    };

    return (
        <div>
            <div className='mb-4'>
                <p className='page-title'>Orders</p>
            </div>
            <div className='my-10'>
                <OrderCategories
                    items={brokerCategories || []}
                    // ordersPerCategory={ordersPerCategory}
                    activeButton={selectedCategory}
                    onClickCategory={onChangeCategorySelected}
                />
            </div>
            <div>
                <div className={`bg-white`}>
                    <Input
                        placeholder={'Filter table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={paginationState?.filters}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                        dataQa={'filter-table'}
                    />
                </div>
                {ordersLoading || rows?.length > 0 ? (
                    <OrdersTable
                        rows={rows}
                        acceptOrders={selectedCategory === 'bluray_dvd'}
                        loadingAccept={selectedCategory === 'bluray_dvd' && loadingAccept}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        onSeeOrderDetails={onSeeOrderDetails}
                        onClickAcceptAllItems={onRequestAcceptAll}
                        activeMarket={activeMarket}
                        ordersLoading={ordersLoading}
                    />
                ) : (
                    <div className='my-10 min-w-[100%] flex flex-col items-center'>
                        <img
                            src={'/assets/shared/no-content.svg'}
                            className={'w-[150px] mb-10 mt-4 object-contain'}
                        />
                        <p className='text-[#a4a4a4] text-center font-md'>
                            Orders not found for
                            {activeMarket?.market?.label
                                ? ` market ${activeMarket?.market?.label}${selectedCategory ? ' and ' : ''
                                }`
                                : ''}
                            {selectedCategory
                                ? `category ${selectedCategory}`
                                : ''}
                            .
                        </p>
                    </div>
                )}
            </div>
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
            <ActionCheckModal
                openMarketStatusModal={showAcceptConfirmModal?.show || false}
                handleCloseModal={() => setShowAcceptConfirmModal(undefined)}
                text={'Are you sure you want to accept all the items of this order?'}
                title={'Accept All Order Items'}
                errorType={false}
                action={''}
                loading={loadingAccept}
                onApproveAction={onClickAcceptAllItems}
            />
        </div>
    );
};

export default OrdersHighLevel;
