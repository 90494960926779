import React from 'react';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import GenerateInvoiceFilters from '../details/GenerateInvoiceFilters';
import Datepicker from '../../../../shared/datepicker';


interface IFilterStep {
    markets?: Array<any>;
    partners?: Array<any>;
    sectors?: Array<any>;
    dateFilters?: {
        minDateCreated?: string;
        maxDateCreated?: string;
    };
    selectedMarket?: any;
    selectedPartner?: any;
    selectedSector?: any;
    selectedPurchaseMarket?: any;
    error?: string;
    onChangeSelectedFilter: (e: any, name?: string) => void;
    onClickNextStep: () => void;
}

const FilterStep = ({
    markets,
    partners,
    sectors,
    selectedMarket,
    selectedPartner,
    selectedSector,
    dateFilters,
    selectedPurchaseMarket,
    error,
    onChangeSelectedFilter,
    onClickNextStep,
}: IFilterStep) => {
    return (
        <div className='pr-5'>
            <p className='text-[#a4a4a4] font-medium text-sm mt-2 mb-5'>Please select Market, Partner and Category to see items that aren`t part of any invoice yet.</p>
            {error && <Error text={error} />}
            <div className='mt-7 mb-3'>
                <GenerateInvoiceFilters
                    markets={markets}
                    partners={partners}
                    sectors={sectors}
                    selectedMarket={selectedMarket}
                    selectedPartner={selectedPartner}
                    selectedSector={selectedSector}
                    selectedPurchaseMarket={selectedPurchaseMarket}
                    onChangeSelectedFilter={onChangeSelectedFilter}
                />
                <div>
                    <p className='text-primary-light font-bold text-sm mt-2 mb-4'>Date Filters</p>
                    <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
                        <Datepicker
                            placeholder='Date From (min)'
                            containerStyle='mb-0'
                            dataQa={'min-date-created'}
                            inputValue={dateFilters?.minDateCreated}
                            onChangeInput={onChangeSelectedFilter}
                            inputUniqueName={'minDateCreated'}
                        />
                        <Datepicker
                            placeholder='Date To (max)'
                            containerStyle='mb-0'
                            dataQa={'max-date-created'}
                            inputValue={dateFilters?.maxDateCreated}
                            onChangeInput={onChangeSelectedFilter}
                            inputUniqueName={'maxDateCreated'}
                        />
                    </div>
                </div>
            </div>
            <div className='my-7 flex flex-row justify-end'>
                <Button
                    label={'Next'}
                    dataQa={'next'}
                    className={'btn-main min-w-[150px]'}
                    onClickButton={onClickNextStep}
                />
            </div>
        </div>
    )
}
export default FilterStep;