import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IMultipleCashout } from '../../../../interfaces/multiple-cashout/IMultipleCashout';
import { tryToFetchMultipleCashoutsReshipped } from '../../../../store/brokers/partners/multiple-cashout/multipleCashoutSlice';
import { tryToFetchAllAssetsTemplates } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToFetchAllPartners } from '../../../../store/brokers/admin/partners/partnersSlice';
import { tryToFetchOrdersCount } from '../../../../store/brokers/partners/orders/ordersSlice';
import MultipleItemDetailsModal from '../modals/MultipleItemDetailsModal';
import ItemsDetailsModal from '../modals/ItemsDetailsModal';
import CashoutFilters from '../details/CashoutFilters';
import ReshippedPackageTable from '../tables/ReshippedPackageTable';
import EmptyContainer from '../../../../shared/empty-container';
import Button from '../../../../shared/button';
import Toast from '../../../../shared/toast';
import Input from '../../../../shared/input';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ReshippedTab = () => {
    const query = useQuery();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [rows, setRows] = useState<IMultipleCashout[]>([]);
    const [search, setSearch] = useState<string>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [brokers, setBrokers] = useState<Array<IPartners & ISelectedValue>>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [loadingOrders, setLoadingOrders] = useState<boolean>(true);
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>();
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [selectedBroker, setSelectedBroker] = useState<ISelectedValue>();
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>();
    const [showItemsModal, setShowItemsModal] = useState<{ show: boolean, items: any }>();
    const [showItemDetailsModal, setShowItemDetailsModal] = useState<{ show: boolean, type: string, data: any }>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const paginationData = useAppSelector((state) => state?.multipleCashout?.multipleCashouts?.data?.page);
    const allAssetTemplates = useAppSelector((state) => state?.assetsTemplates?.allAssetTemplates);
    const ordersLoading = useAppSelector((state) => state?.multipleCashout?.cashoutsAreLoading);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const ordersCount = useAppSelector((state) => state?.orders.ordersCount);
    const state = useAppSelector((state) => state?.multipleCashout);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Multiple Cashouts')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.multipleCashouts) {
            const rows =
                state.multipleCashouts?.data?.elements && Array.isArray(state.multipleCashouts?.data.elements)
                    ? state.multipleCashouts?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
            setLoadingOrders(false);
        } else if (rows && !state.multipleCashouts) {
            setRows([]);
            setTimeout(() => {
                setLoadingOrders(false)
            }, 1000)
        }
    }, [state.multipleCashouts]);

    const getOrderCounts = async () => {
        await dispatch(tryToFetchOrdersCount({ marketId: activeMarket?.market?._id || '', country: activeMarket?.market?.name || '', isMultiple: true })).unwrap();
    };

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined,
        });
        searchParams.set('page', '1');
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    }

    useLayoutEffect(() => {
        const paginationStateFormat = paginationState;
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1);
        }
        if (query?.get('size') && Number(query?.get('size')) !== paginationState?.pageSize) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10);
        }
        if (query?.get('search') && query?.get('search') !== paginationState?.filters) {
            paginationStateFormat.filters = query.get('search') || undefined;
            setSearch(query.get('search') || undefined);
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat);
        }
        if (query?.get('category') && query?.get('category') !== selectedCategory) {
            setSelectedCategory(query?.get('category') || undefined);
        }
        if (query?.get('broker') && query?.get('broker') !== selectedBroker?.value) {
            const brokerSelected = (brokers && brokers?.length > 0) && brokers?.find((item: any) => item?.value === query?.get('broker'))
            setSelectedBroker(brokerSelected || undefined);
        }
    }, [
        query?.get('page'),
        query?.get('size'),
        query?.get('broker'),
        query?.get('search'),
        query?.get('category'),
    ]);

    const onEnterSearchInput = async (e: any) => {
        if (e?.key === 'Enter') {
            setLoadingOrders(true);
            setPaginationState({
                ...(paginationState || {}),
                pageNumber: 1,
                filters: search || undefined,
            });
            if (search) searchParams.set('search', search);
            else searchParams.delete('search');
            searchParams.set('page', '1');
            searchParams.delete('filter');
            setSearchParams(searchParams);
        }
    };

    const getBrokerSectors = async () => {
        const response = allAssetTemplates
        const formatSectors: any = (response?.length > 0) ? response?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?._id })) : [];
        const filterSectors: any = formatSectors && formatSectors?.length > 0 && formatSectors?.filter((s: any) => (s?.label || '').toLowerCase() !== 'mens wear' && (s?.label || '').toLowerCase() !== 'womens wear' && (s?.label || '').toLowerCase() !== 'currencies');
        setBrokerCategories(filterSectors || []);
        if (!selectedCategory) {
            setSelectedCategory(query?.get('category') || 'allCategories');
            onResetPaginationState();
        }
    }

    const getPayServices = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.companyName }))
        setBrokers(formatPartners || [])
        if (query?.get('broker') && query?.get('broker') !== selectedBroker?.value) {
            const selectedBroker = (formatPartners && formatPartners?.length > 0) && formatPartners?.find((item: ISelectedValue) => item?.value === query?.get('broker'))
            setSelectedBroker(selectedBroker)
            onResetPaginationState();
        }
    }

    useEffect(() => {
        if (allAssetTemplates && !brokerCategories) {
            getBrokerSectors();
        }
    }, [allAssetTemplates])

    useEffect(() => {
        if (!allAssetTemplates) {
            dispatch(tryToFetchAllAssetsTemplates()).unwrap();
        }
        getPayServices();
    }, []);

    useEffect(() => {
        if (activeMarketState && (JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket))) {
            setActiveMarket(activeMarketState || undefined)
            onResetPaginationState()
        }
    }, [activeMarketState])

    const onGettingFilters = (type?: string) => {
        let settingsFilters: any
        if (!type || type !== 'download') {
            settingsFilters = {
                ...paginationState,
            }
        }
        if (activeMarket && !type) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data || {},
                    marketId: activeMarket?.market?._id,
                    country: activeMarket?.market?.name
                }
            }
        }
        if (selectedCategory && selectedCategory !== 'allCategories') {
            const findCategory = brokerCategories && brokerCategories?.length > 0 && brokerCategories?.find((category) => category?.name === selectedCategory)
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    category: {
                        id: findCategory?.value,
                        name: findCategory?.name
                    }
                }
            }
        }
        if (selectedBroker) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    brokerId: selectedBroker?.value
                }
            }
        }
        searchParams.set('page', `${paginationState?.pageNumber}`);
        searchParams.set('size', `${paginationState?.pageSize}`);
        return settingsFilters
    }

    const getCashoutsData = async () => {
        setLoadingOrders(true);
        const settingsFilters = onGettingFilters()
        try {
            await dispatch(tryToFetchMultipleCashoutsReshipped(settingsFilters)).unwrap()
        } catch (err) {
            // error here
            setLoadingOrders(false)
            setRows([])
        }
    }

    useEffect(() => {
        if (paginationState && activeMarket) {
            getCashoutsData()
        }
        if (activeMarket) {
            getOrderCounts();
        }
    }, [paginationState, activeMarket]);

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const onChangeCategorySelected = (value: string) => {
        setSelectedCategory(value)
        onResetPaginationState()
    }

    const onClearFilters = () => {
        setPaginationState({
            ...paginationState,
            pageNumber: 1,
            filters: undefined,
        });
        setSearch(undefined);
        setSelectedBroker(undefined);
        setSelectedCategory('allCategories');
        searchParams.delete('search');
        searchParams.delete('broker');
        searchParams.set('page', '1');
        setSearchParams(searchParams);
    };

    const onClickBrokerFilter = (value: ISelectedValue) => {
        setSelectedBroker(value);
        searchParams.set('broker', `${value?.value}`);
        setSearchParams(searchParams);
        onResetPaginationState();
    };

    const onClickSeeMoreDetails = (type: string, data: any) => {
        setShowItemDetailsModal({
            show: true,
            type,
            data
        })
    }

    const onClickSeeItems = (items: any) => {
        setShowItemsModal({
            show: true,
            items
        })
    }

    return (
        <div className='my-6'>
            <div>
                <CashoutFilters
                    brokerCategories={brokerCategories}
                    partners={brokers}
                    selectedBroker={selectedBroker}
                    activeCategory={selectedCategory}
                    hideSource={true}
                    ordersCount={ordersCount}
                    onClickBrokerFilter={onClickBrokerFilter}
                    onClickCategory={onChangeCategorySelected}
                    onClickSourceFilter={onClickBrokerFilter}
                    onClickWarehouseFilter={onClickBrokerFilter}
                />
            </div>
            <div className={`grid sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-8 bg-white`}>
                <div className={`sm:col-span-5 md:col-span-5 lg:col-span-6`}>
                    <Input
                        placeholder={'Filter table'}
                        dataQa={'filter-table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        onHandleKeyDown={onEnterSearchInput}
                        hideInputSpace={true}
                    />
                </div>
                <Button
                    label={'Clear all filters'}
                    dataQa={'clear-all-filters'}
                    className={'btn-primary-text-underline min-w-auto flex flex-row col-span-1 md:col-span-1 lg:col-span-2 sm:justify-center md:justify-center lg:justify-end bg-transparent mt-1'}
                    onClickButton={onClearFilters}
                />
            </div>
            <div className='relative'>
                {(loadingOrders || rows?.length > 0) ?
                    <ReshippedPackageTable
                        rows={rows}
                        activeMarket={activeMarket}
                        paginationData={paginationData}
                        ordersLoading={loadingOrders || ordersLoading}
                        accessControl={accessControl}
                        pageAccess={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onClickSeeMoreDetails={onClickSeeMoreDetails}
                        onClickSeeItems={onClickSeeItems}
                    />
                    : <EmptyContainer
                        showImage={true}
                        image={<img alt='no results' src={'/assets/shared/no-content.svg'} className={'my-3 w-[100px] object-contain'} />}
                        text={'No shipped packages found.'} />
                }
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showItemDetailsModal && showItemDetailsModal?.show &&
                <MultipleItemDetailsModal
                    openModal={showItemDetailsModal?.show}
                    type={showItemDetailsModal?.type}
                    data={showItemDetailsModal?.data}
                    handleCloseModal={() => setShowItemDetailsModal(undefined)}
                />
            }
            {showItemsModal?.show &&
                <ItemsDetailsModal
                    openModal={showItemsModal?.show}
                    items={showItemsModal?.items}
                    orderId={' '}
                    handleCloseModal={() => setShowItemsModal(undefined)}
                />
            }
        </div>
    )
}

export default ReshippedTab;