import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Loading from '../../../../shared/Loading';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToFetchAllRMARejectReasons } from '../../../../store/brokers/admin/rma/RMAsSlice';

interface IRejectReturnModal {
    openModal: boolean;
    text: JSX.Element;
    title: string;
    rejectLoading: boolean;
    handleCloseModal: () => void;
    onSubmitSuccessfully: (rejectReason: string) => void;
}

const RejectReturnModal = ({
    openModal,
    text,
    title,
    rejectLoading,
    handleCloseModal,
    onSubmitSuccessfully,
}: IRejectReturnModal) => {
    const [reasons, setReasons] = useState<any[]>([]);
    const dispatch = useAppDispatch();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: { reason: '' } });

    const onSubmit = (data: { reason: string }) => {
        onSubmitSuccessfully(data.reason);
    };
    const getRMAReasons = useCallback(async () => {
        try {
            const res = await dispatch(tryToFetchAllRMARejectReasons()).unwrap();
            setReasons(res);
        } catch (error) {
            console.error('Failed to fetch RMA reasons:', error);
        }
    }, [dispatch]);
    useEffect(() => {
        getRMAReasons();
    }, [getRMAReasons]);

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            dataQa={'close-button'}
            showInRight
            contentContainerStyle='p-6 min-w-[500px] bg-white rounded-lg shadow-lg'>
            <form onSubmit={rejectLoading ? () => { } : handleSubmit(onSubmit)}>
                <div className='mb-4'>
                    <p className='text-gray-600 text-sm text-center'>RMA Item Actions</p>
                    <hr className='mt-2' />
                </div>
                <div className='mb-4'>
                    <p className='text-lg font-semibold text-gray-900'>{title}</p>
                    <hr className='mt-2' />
                </div>
                <div className='bg-[#F8F9FC] p-4'>
                    <p className='text-gray-700 mb-4'>{text}</p>
                    <div className='mb-4'>
                        <p className='font-medium text-gray-900'>Reject Reason</p>
                    </div>
                    <div className='w-full mb-6'>
                        <Controller
                            name='reason'
                            control={control}
                            rules={{ required: { value: true, message: 'Reason is required.' } }}
                            render={({ field }) => (
                                <select
                                    className='border border-gray-300 rounded w-full px-3 py-2 text-gray-700'
                                    {...field}
                                >
                                    <option value=''>Select Reason</option>
                                    {reasons.map((reason: any, i:number) => (
                                        <option key={i} value={reason.title}>
                                            {reason.title}
                                        </option>
                                    ))}
                                </select>
                            )}
                        />
                        {errors?.reason?.message && <Error text={errors.reason.message} />}
                    </div>
                    <p className='text-gray-500 text-sm mb-4'>Credit Note will not be created.</p>
                    <div className='flex justify-between'>
                        <Button
                            onClickButton={handleCloseModal}
                            label="No"
                            className="w-full mr-2 py-2 text-center rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300"
                        />
                        <Button
                            icon={rejectLoading && <Loading />}
                            label="Yes"
                            dataQa="reject-return"
                            disabled={rejectLoading}
                            type="submit"
                            className={`w-full ml-2 py-2 rounded-md bg-gradient-to-r from-[#FCEE21] to-[#F7931E] text-[#202020]`}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default RejectReturnModal;
