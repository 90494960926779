export const GRADE_DESCRIPTION = [
    { name: 'New', description: 'The device has never been used or activated and is in original packaging.' },
    { name: '14 Day', description: '14 Day items are returned by users to telecom companies or chain stores for different reasons within 14 days of purchase. Usually, this kind of devices is almost brand new and in an excellent condition with few accessories missing' },
    { name: 'CPO', description: 'Official Apple Certified Pre-Owned devices which are good as new in quality (Manufacturer Factory Refurbished), non activated and carry Apple\'s 1 Year International Warranty.' },
    { name: 'A+', description: 'The device is a Refurbished device that is \'As New\'. No scratches or blemishes, this unit is pristine' },
    { name: 'ASIS', description: 'This will be a mix of predominantly A and B units with the possibility of a small percent being Grade C. This stock is direct from Apple but has no manufacturer warranty atatched to it.' },
    { name: 'A', description: 'Grade A devices are in excellent condition, however are used and therefore may show light signs of use to the screen, back cover and/or chassis' },
    { name: 'A/B', description: 'Very good to Excellent condition, it show very small and light signs of wear an tear and few visible marks on the front/back/corner such as little dent, mixture of grade A and B' },
    { name: 'B', description: 'Grade B devices are in very good condition, however are used and therefore may show moderate signs of use to the screen, back cover and/or chassis.' },
    { name: 'B/C', description: 'Very good to average condition. Can show moderate to heavy signs of use.' },
    { name: 'C', description: 'Grade C devices are in good/average condition and will show moderate to heavy signs of use to the screen, back cover and/or chassis.' },
    { name: 'D', description: 'Grade D devices may be in any condition from As New to Grade C and may also show heavy cracks, dents and chips on the digitiser, back cover and/or chassis. Grade D devices will power on with a good LCD but may have functionality faults' },
    { name: 'E', description: 'Grade E devices may be in any physical condition from Grade A-D. Grade E devices may or may not power on and may or may not have a functional LCD.' },
];