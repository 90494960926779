import React from 'react';
import { IAgents } from '../../../../interfaces/agents/IAgents';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayloadWithId } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['Email', 'Username', 'Name', 'Enabled']

interface IAgentsTable {
    rows: IAgents[];
    paginationData?: IPage;
    rowsLoading: boolean;
    setPaginationState: ({ id, pageNumber, pageSize }: IPaginationPayloadWithId) => void;
}

const PartnerAgentsTable = ({
    rows,
    paginationData,
    rowsLoading,
    setPaginationState,
}: IAgentsTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}>
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IAgents, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.email}</td>
                                <td className='px-6 py-4'>{row?.username}</td>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>{row?.enabled ? 'Yes' : 'No'}</td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default PartnerAgentsTable;