import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToCreateChild,
    tryToEditChild
} from '../../../../store/brokers/admin/sections/sectionsSlice';
import { ISectionChild } from '../../../../interfaces/sections/ISection';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Table from '../../../../shared/table';


interface IPageDetailsModal {
    openPageDetailsModal: boolean;
    pageId?: string;
    childId?: string;
    handleClosePageDetailsModal: () => void;
    onSaveChanges: () => void;
}

type FormValues = {
    name: string;
    path?: string;
};

const PageDetailsModal = ({
    openPageDetailsModal,
    pageId,
    childId,
    handleClosePageDetailsModal,
    onSaveChanges,
}: IPageDetailsModal) => {
    const dispatch = useAppDispatch();
    const [newEndpoint, setNewEndpoint] = useState<string>();
    const [endpointsList, setEndpointsList] = useState<any>();
    const [listError, setListError] = useState<string>();
    const [saveError, setSaveError] = useState<string>();
    const [editEndpoint, setEditEndpoint] = useState<string>();
    const [newEditEndpoint, setNewEditEndpoint] = useState<string>()

    const childDetails = useAppSelector(state => state.sections.sectionDetails?.children?.find(child => child._id === childId))

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});


    useEffect(() => {
        if (childId) {
            setValue('name', childDetails ? childDetails?.name || '' : '');
            setValue('path', childDetails ? childDetails?.path : '');
            if (childId && childDetails?.endpoints && childDetails?.endpoints?.length > 0) {
                setEndpointsList(childDetails?.endpoints)
            }
        } else {
            setValue('name', '')
            setValue('path', '')
            setEditEndpoint(undefined)
            setNewEditEndpoint(undefined)
            setEndpointsList(undefined)
            setNewEndpoint(undefined)
            setListError(undefined)
        }
    }, [childDetails, childId]);

    const onAddNewEndpoint = () => {
        if (newEndpoint && newEndpoint !== '') {
            setEndpointsList([...endpointsList || [], newEndpoint])
            setNewEndpoint(undefined)
        }
    };

    const onChangeNewEndpoint = (endpoint: string | null | undefined, type: string) => {
        setNewEndpoint(endpoint || undefined);
        setListError(undefined);
    };

    const onDeleteEndpoint = (id: number) => {
        const filterEndpoints = (endpointsList && endpointsList?.length > 0) && endpointsList?.filter((item: any, index: number) => index !== id)
        setEndpointsList(filterEndpoints)
    };

    const onCloseModal = () => {
        reset();
        setSaveError(undefined)
        setListError(undefined)
        setNewEndpoint(undefined)
        setEditEndpoint(undefined)
        setNewEditEndpoint(undefined)
        setEndpointsList(undefined)
        handleClosePageDetailsModal();
    };

    const onSubmit = async (data: FormValues) => {
        const payload: ISectionChild = {
            ...data,
            endpoints: []
        };
        if (endpointsList && endpointsList.length > 0) {
            payload.endpoints = endpointsList
        }
        try {
            if (childId) {
                payload._id = childId
                if (childDetails?.actions) {
                    payload.actions = childDetails.actions
                }
                await dispatch(tryToEditChild(payload)).unwrap()
            }
            else {
                payload.sectionId = pageId
                await dispatch(tryToCreateChild(payload)).unwrap();
            }
            onSaveChanges()
            onCloseModal()
        } catch (error) {
            setSaveError(`${error}`)
        }
    };

    const onDoubleClickEndpoint = (endpoint: string) => {
        setEditEndpoint(endpoint)
        setNewEditEndpoint(endpoint)
    }

    const onEditEndpoint = (value: any, type?: string) => {
        setNewEditEndpoint(value)
    }

    const onEnterEndpoint = async (e: any) => {
        e.stopPropagation()
        if (e?.key === 'Enter') {
            const formatEndpoints = (endpointsList && endpointsList?.length > 0) && endpointsList?.map((item: any) => {
                return item === editEndpoint ? newEditEndpoint : item
            })
            setEndpointsList(formatEndpoints || [])
            setNewEditEndpoint(undefined)
            setEditEndpoint(undefined)
        }
    }

    return (
        <Modal open={openPageDetailsModal} onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4 font-bold'>{childId ? 'Edit Child' : 'Add Child'}</p>
                {saveError && <Error text={saveError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-col my-4'>
                        <div className='flex flex-row gap-x-3 justify-items-stretch'>
                            <Input
                                placeholder='Name'
                                label={'Name'}
                                dataQa={'name'}
                                register={register('name', {
                                    required: false
                                })}
                                error={errors.name?.message}
                            />
                            <Input
                                placeholder='/path'
                                label={'Path'}
                                dataQa={'path'}
                                register={register('path', {
                                    required: false
                                })}
                                error={errors.path?.message}
                            />
                        </div>
                        <div className='flex flex-row items-center'>
                            <Input
                                placeholder='List Value'
                                containerStyle={'w-auto min-w-[400px]'}
                                showValue={true}
                                inputValue={newEndpoint}
                                inputUniqueName={'newValue'}
                                onChangeInput={onChangeNewEndpoint}
                                dataQa={'list-value'}
                            />
                            <Button
                                label={'+'}
                                className={`btn-main rounded !mb-3 ml-2`}
                                onClickButton={onAddNewEndpoint}
                                dataQa={'add-value'}
                            />
                        </div>
                        {listError && <Error text={listError} />}
                        <div className='my-3'>
                            {(endpointsList && endpointsList?.length > 0) &&
                                <Table
                                    hidePagination={true}
                                    columns={['Endpoint', 'Action']}>
                                    {endpointsList.map((obj: any, idx: number) => {
                                        return (
                                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                                <td className='px-6 py-2'>
                                                    {editEndpoint === obj ?
                                                        <div onClick={(e) => e.stopPropagation()}>
                                                            <Input
                                                                placeholder='Endpoint'
                                                                showValue={true}
                                                                inputValue={newEditEndpoint}
                                                                onChangeInput={onEditEndpoint}
                                                                onHandleKeyDown={onEnterEndpoint}
                                                            />
                                                        </div>
                                                        : <div className='cursor-pointer' onDoubleClick={() => onDoubleClickEndpoint(obj || '')}>
                                                            {obj}
                                                        </div>
                                                    }
                                                </td>
                                                <td className='px-6 py-2'>
                                                    <div className='cursor-pointer' onClick={() => onDeleteEndpoint(idx)}>
                                                        <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] ml-4 hover:w-[17px] object-contain'} />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </Table>
                            }
                        </div>
                        <div className='flex flex-row justify-end mt-4'>
                            <Button
                                label='Save'
                                dataQa={'save'}
                                type={'submit'}
                                className={'btn-main min-w-[150px] '}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default PageDetailsModal;
