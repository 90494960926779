import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IRMA } from '../../../../interfaces/rma/IRMA';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToFetchRMAs } from '../../../../store/brokers/admin/rma/RMAsSlice';
import RMAsTable from '../tables/RMAsTable';
import EmptyContainer from '../../../../shared/empty-container';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { tryToFetchAllPartners } from '../../../../store/brokers/admin/partners/partnersSlice';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Input from '../../../../shared/input';
import Button from '../../../../shared/button';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';

const OPTIONS = [
    {
        label: 'All statuses',
        value: undefined,
    },
    {
        label: 'Created',
        value: 'created',
    },
    {
        label: 'Completed',
        value: 'completed',
    },
];
interface ISubmittedRMAsTab {
    paginationState: IPaginationPayload,
    setPaginationState: React.Dispatch<React.SetStateAction<IPaginationPayload>>
    pageAccess: any
    accessControl: any
    sectors: IAssetTemplate[]
    onResetPaginationSettings: () => void
    query: URLSearchParams
    searchParams: URLSearchParams
    setSearchParams: any
}

const SubmittedRMAsTab = ({ paginationState, setPaginationState, pageAccess,accessControl, sectors, onResetPaginationSettings, query, searchParams, setSearchParams }: ISubmittedRMAsTab) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IRMA[]>([]);
    const [partners, setPartners] = useState<IPartners[]>()
    const [markets, setMarkets] = useState<IMarket[]>([])
    const [selectedPartner, setSelectedPartner] = useState<string>()
    const [selectedMarket, setSelectedMarket] = useState<{label: string, value: string}>({label: '', value: ''})
    const [selectedStatus, setSelectedStatus] = useState(OPTIONS[0]);
    const [getAllMarkets] = useGetAllMarketsMutation()
    const paginationData = useAppSelector((state) => state.RMAs.RMAs?.data?.page);
    const rowsLoading = useAppSelector((state) => state.RMAs.RMAsAreLoading);
    const state = useAppSelector((state) => state.RMAs);
    const findPartner: any = (partners && selectedPartner && partners?.length > 0) && partners?.find((item: any) => item?.value === selectedPartner)
    const onSeeRMADetails = async (id: string) => {
        navigate(`/rma-management/${id}`);
    }
    const onChangeSearchValue = (
        value: string | null | undefined,
        type: string,
    ) => {
        setPaginationState({
            ...(paginationState || {}),
            pageNumber: 1,
            filters: value || undefined,
        });
        if (value) searchParams.set('search', value);
        else searchParams.delete('search');
        searchParams.set('page', '1');
        setSearchParams(searchParams);
    };

    const getFiltersData = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, value: partner?.username, label: partner?.companyName }))
        formatPartners.unshift({ value: undefined, label: 'All Customers' })
        setPartners(formatPartners || [])
        if (!selectedPartner) {
            setSelectedPartner(query?.get('partner') || formatPartners?.[0]?.value || undefined)
        }
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, displayName: market?.name }))

        setMarkets(formatMarkets || [])
    }

    const onSelectFilterOption = (value: any, name?: string) => {
        if (name) {
            switch (name) {
                case 'partner':
                    setSelectedPartner(value?.value)
                    if (value?.value) searchParams.set('partner', value?.value)
                    else searchParams.delete('partner')
            }
            setSearchParams(searchParams)
            onResetPaginationSettings()
        }
    }
    const onClickStatusFilter = (value: any, type?: string) => {
        setSelectedStatus(value);
        if (value.value) {
            searchParams.set('status', value?.value);
        } else searchParams.delete('status');
        setSearchParams(searchParams);
        onResetPaginationSettings();
    };

    const onClickMarketFilter = (value: any, name?: string) => {
        if (name) {
            setSelectedMarket({label: value?.name, value: value?.displayName})
            searchParams.set('country', value?.displayName)
            setSearchParams(searchParams)
            onResetPaginationSettings()
        }
    }

    useEffect(() => {
        if (query?.get('status')) {
            setSelectedStatus(OPTIONS.find(o => o.value === query?.get('status')) || OPTIONS[0])
        } else searchParams.delete('status')
        if (query?.get('search')) {
            setPaginationState({ ...paginationState, filters: query?.get('search') || undefined })
        } else searchParams.delete('search')
    }, [
        query?.get('status'), query?.get('search')
    ])

    useEffect(() => {
        getFiltersData()
    }, [])

    useEffect(() => {
        dispatch(tryToFetchRMAs({ ...paginationState, broker: selectedPartner, status: selectedStatus.value, country: selectedMarket.value }))
    }, [paginationState, selectedPartner, selectedStatus, selectedMarket])


    useEffect(() => {
        if (state.RMAs) {
            const rows =
                state.RMAs?.data?.elements &&
                    Array.isArray(state.RMAs?.data?.elements)
                    ? state.RMAs?.data?.elements?.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.RMAs]);

    const onClearAllFilters = () => {
        searchParams.delete('partner');
        searchParams.delete('country');
        searchParams.delete('status');
        setSearchParams(searchParams)
        setSelectedStatus(OPTIONS[0])
        setSelectedPartner(undefined)
        setSelectedMarket({label: '', value: ''}) 
        setPaginationState({ ...paginationState, filters:  '' })
    }

    return (
        <div>
            <div className='my-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
                <SelectCheckbox
                    name={'Market'}
                    dataQa={'market-selected'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={selectedMarket.label || 'All Markets'}
                    options={[{label: 'All Markets', value: undefined}, ...markets] || []}
                    uniqueName={'market'}
                    onChangeSelectedOption={onClickMarketFilter}
                />
                <SelectCheckbox
                    name={'Partner'}
                    dataQa={'partner-selected'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={findPartner?.label || 'All Customers'}
                    options={partners || []}
                    uniqueName={'partner'}
                    onChangeSelectedOption={onSelectFilterOption}
                />
                <SelectCheckbox
                    name="Status"
                    dataQa={'status-filter'}
                    placeholder={selectedStatus.label || ''}
                    options={OPTIONS}
                    containerStyle={'!min-w-[200px]'}
                    onChangeSelectedOption={onClickStatusFilter}
                    uniqueName={'Status'}
                />
            </div>
            <div className="bg-[#F7A21E1A] rounded-[10px] p-2 flex flex-row items-center justify-between">

                <Input
                    placeholder={'Filter Table'}
                    containerStyle="mb-0 pb-0 relative flex-initial w-96"
                    inputStyle={`mb-0 border-0 focus:outline-none`}
                    icon={'/assets/shared/search.svg'}
                    showValue={true}
                    inputValue={paginationState?.filters}
                    inputUniqueName={'search'}
                    onChangeInput={onChangeSearchValue}
                    hideInputSpace={true}
                    dataQa={'filter-table'}
                />
                <Button
                    label={'Clear All Filters'}
                    dataQa={'clear-all-filters'}
                    className='btn-primary-text-underline min-w-auto !mt-0 flex flex-row justify-end bg-transparent mr-2 mb-1'
                    onClickButton={onClearAllFilters}
                />
            </div>
            <div className='my-5 relativ'>
                {(rows?.length > 0 || rowsLoading) ?
                    <RMAsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        onSeeRMADetails={onSeeRMADetails}
                        sectors={sectors}
                        viewDetails={(!accessControl || (pageAccess?.actions?.['View RMA Details'])) || false}
                    />
                    : <EmptyContainer
                        text={'No RMA found.'}
                        showImage={true}
                    />
                }
            </div>
        </div>
    )
}

export default SubmittedRMAsTab