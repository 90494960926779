import React from 'react';
import moment from 'moment';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface IMissingDetailsModal {
    openModal: boolean;
    missingDate?: string;
    missingImages?: Array<string>;
    handleCloseModal: () => void;
}

const MissingDetailsModal = ({
    openModal,
    missingImages,
    missingDate,
    handleCloseModal
}: IMissingDetailsModal) => {
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            showInRight={true}
            contentContainerStyle={'min-w-[650px]'}
            dataQa={'close-button'}
        >
            <div className={'p-2 min-w-[570px]'}>
                <div className='flex flex-row items-center mb-2'>
                    <p className='mt-2 mb-1 !text-[18px] mr-10'>Missing Item</p>
                </div>
                <div className='mt-2 mb-5 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                <div className='mt-6 mb-10 bg-[#f8f9fc] rounded p-4 py-8'>
                    <p className='mb-4 mt-2'>Reported as missing</p>
                    <div className='bg-white !rounded py-2 px-3'>
                        <p>{moment(missingDate).format('Do MMM YYYY, h:mm a')}</p>
                    </div>
                </div>
                <div className='mt-6 mb-10 bg-[#f8f9fc] rounded p-4 py-8'>
                    <p className='mb-4 mt-2'>Missing images</p>
                    {missingImages && missingImages?.length > 0 &&
                        <div className='flex flex-rows items-center flex-wrap justify-start mt-6'>
                            {missingImages?.map((item: any, key: number) => {
                                return (
                                    <div
                                        key={key}
                                        className={'relative flex flex-col items-center mr-4 z-70'}>
                                        <div className='border border-[#000] p-1 z-0'>
                                            <img src={item} className='max-w-[120px] object-contain' />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
                <div className='my-5 flex flex-row justify-center'>
                    <Button
                        label={'Close'}
                        className='btn-main'
                        onClickButton={handleCloseModal}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default MissingDetailsModal;