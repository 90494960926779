import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { tryToDeleteAssetCatalogData } from '../../../store/brokers/admin/assets/assetsSlice';
import { tryToFetchAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IToast } from '../../../interfaces/components/IToast';
import EmptyContainer from '../../../shared/empty-container';
import DeleteModal from '../../../shared/delete-modal';
import AssetsTable from './tables/AssetsTable';
import Toast from '../../../shared/toast';
import Input from '../../../shared/input';


const Assets = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IAssetTemplate[]>([]);
    const [search, setSearch] = useState<string>();
    const [showToast, setShowToast] = useState<IToast | null>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [itemToDelete, setItemToDelete] = useState<string>()
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.assetsTemplates?.assetTemplates?.data?.page);
    const rowsLoading = useAppSelector((state) => state.assetsTemplates?.assetsTemplatesAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.assetsTemplates);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Assets')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.assetTemplates) {
            const rows =
                state.assetTemplates?.data?.elements && Array.isArray(state.assetTemplates?.data.elements)
                    ? state.assetTemplates?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.assetTemplates]);

    useEffect(() => {
        const settingsFilters: any = {
            ...paginationState
        }
        if (search) {
            settingsFilters.filters = search
        }
        dispatch(tryToFetchAssetsTemplates(settingsFilters));
    }, [paginationState]);

    const onSeeAssetDetails = async (id: string) => {
        navigate(`/assets/${id}`);
    }

    const onRequestDeleteData = (id: string) => {
        setShowDeleteModal(true)
        setItemToDelete(id)
    }

    const onDeleteAssetTemplateData = async () => {
        if (itemToDelete) {
            try {
                await dispatch(tryToDeleteAssetCatalogData(itemToDelete)).unwrap()
                setShowToast({ type: 'success', message: 'Asset Catalog data deleted successfully' });
            } catch (err) {
                setShowToast({ type: 'error', message: `${err}` });
            }
            setShowDeleteModal(false)
            setItemToDelete(undefined)
        }
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    return (
        <div>
            <div>
                <p className='page-title'>Assets</p>
            </div>
            <div className='my-5 relative'>
                <div className={'min-w-[100px]'}>
                    <Input
                        placeholder={'Filter Table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={'mb-0 border-0 focus:outline-none'}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                        dataQa={'filter-table-assets'}
                    />
                </div>
                {(rows?.length > 0 || rowsLoading) ?
                    <AssetsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onSeeAssetDetails={onSeeAssetDetails}
                        onRequestDeleteData={onRequestDeleteData}
                    />
                    : <EmptyContainer text={'No asset added yet.'} showImage={true} />
                }
                <DeleteModal
                    openDeleteModal={showDeleteModal}
                    handleCloseDeleteModal={() => {
                        setShowDeleteModal(false)
                        setItemToDelete(undefined)
                    }}
                    onApproveDeletion={onDeleteAssetTemplateData}
                />
                {showToast?.message &&
                    <Toast
                        type={showToast?.type}
                        text={showToast?.message}
                        onHandleCloseToast={() => setShowToast(null)}
                    />
                }
            </div>
        </div>
    )
}

export default Assets;