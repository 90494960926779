import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';


interface IBlacklistedImeiModal {
    openModal: boolean;
    loadingSubmit?: boolean;
    onGoBack: () => void;
    handleCloseModal: () => void;
}

const BlacklistedImeiModal = ({
    openModal,
    loadingSubmit,
    handleCloseModal,
    onGoBack,
}: IBlacklistedImeiModal) => {
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}>
            <div className={'p-2 max-w-[400px]'}>
                <p className='mb-4 font-bold text-center text-sm text-[#E50000] border-b-1'>IMEI Blacklisted</p>
                <div className='mt-2 mb-5 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                <div className='my-4'>
                    Certain actions cannot proceed due to the blacklisted IMEI associated with this item. To proceed, a change of IMEI is required.
                </div>
                <div className='flex flex-row justify-end mt-7 mb-5'>
                    <Button
                        label={'Change IMEI'}
                        className={`mr-3 ${loadingSubmit ? 'btn-main-disable' : 'btn-main'} !rounded`}
                        onClickButton={() => !loadingSubmit && handleCloseModal()}
                    />
                    <Button
                        label={'Keep Blacklisted IMEI'}
                        className={`${loadingSubmit ? 'btn-primary-disable' : 'btn-primary'}`}
                        onClickButton={() => !loadingSubmit && onGoBack()}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default BlacklistedImeiModal;