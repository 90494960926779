import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToFetchAllRoles } from '../../../../store/brokers/partners/user-access-control/userAccessSlice';
import { tryToAssignUserRole } from '../../../../store/brokers/partners/agents/agentsSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IRoleModal {
    openModal: boolean;
    id: string;
    agentType: string;
    role?: any;
    handleCloseModal: () => void;
}

const RoleModal = ({
    openModal,
    id,
    role,
    agentType,
    handleCloseModal,
}: IRoleModal) => {
    const dispatch = useAppDispatch()
    const [selectedRole, setSelectedRole] = useState<any>()
    const [roles, setRoles] = useState<any>()
    const [submitError, setSubmitError] = useState<string>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const onChangeSelectedRole = (e: any) => {
        setSelectedRole(e)
    }

    const onCloseModal = () => {
        handleCloseModal();
        setSelectedRole(undefined)
        setSubmitError(undefined)
        setLoadingSubmit(false)
    }

    const onGettingRolesData = async () => {
        const response = await dispatch(tryToFetchAllRoles(agentType)).unwrap()
        const formatRoles = response && response?.length > 0 && response?.map((item: any) => ({ label: item?.name, value: item?.name }))
        setRoles(formatRoles || [])
    }

    useEffect(() => {
        if (role) {
            setSelectedRole({ label: role, value: role })
        } else {
            setSelectedRole(undefined)
        }
    }, [role])

    useEffect(() => {
        onGettingRolesData()
    }, [])

    const onSubmit = async (type?: string) => {
        setLoadingSubmit(true)
        try {
            let body: any = {
                userId: id,
                role: type === 'delete' ? null : selectedRole?.value
            }
            if (agentType === 'inventory-agent') {
                body = {
                    ...body || {},
                    dashboard: 'inventory'
                }
            }
            await dispatch(tryToAssignUserRole(body)).unwrap()
            onCloseModal()
        } catch (err) {
            setSubmitError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            dataQa={'close-modal'}
        >
            <div className={'p-2 min-w-[350px] min-h-[300px]'}>
                <p className='mb-2 font-semibold text-sm'>Member Role</p>
                <p className='mb-4 text-sm'>Select role you want to assign to this member.</p>
                {submitError && <Error text={submitError} />}
                <div className='flex flex-col items-center'>
                    <SelectCheckbox
                        name='Role'
                        dataQa={`${(selectedRole?.label || '')?.toLowerCase().replaceAll(' ', '-')}`}
                        placeholder=' '
                        containerStyle='mb-8 min-w-full'
                        selectedOption={selectedRole}
                        onChangeSelectedOption={onChangeSelectedRole}
                        options={roles || []}
                    />
                    <div className='flex flex-rows justify-around items-center'>
                        {role &&
                            <Button
                                className='btn-error !shadow-none min-w-[130px] mr-3'
                                label='Delete Role'
                                dataQa={'delete-role'}
                                onClickButton={() => onSubmit('delete')}
                            />
                        }
                        <Button
                            className={`${loadingSubmit ? 'btn-main-disable' : 'btn-main'} !shadow-none min-w-[130px]`}
                            label='Submit'
                            dataQa={'submit'}
                            onClickButton={onSubmit}
                        />
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default RoleModal;