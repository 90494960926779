import React from 'react';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';
import { IDisplayQuestion } from '../../../../interfaces/orders/IOrdersSchema';
import Input from '../../../../shared/input';
import { IQuestion } from '../../../../interfaces/questions/IQuestions';


interface IRevaluateQuestions {
    primaryQuestions: any,
    secondaryQuestions: any;
    selectedPrimaryAnswers?: any;
    selectedSecondaryAnswers?: any;
    filteredFields?: any;
    items?: any;
    matchedItems?: any;
    filterErrors?: any;
    searchInDB?: any;
    loadingOptions?: any;
    loadingFields?: any;
    loadingValues?: any;
    questionsLoading: boolean;
    secondaryQuestionsErrors?: any;
    displayQuestions?: Array<IDisplayQuestion>;
    onMatchAllItems?: () => void;
    onMatchItem?: (question: string, type: boolean, sellerAnswer?: any, field?: string) => void;
    onMatchSecondaryItem?: (question: string, type: boolean, sellerAnswer?: any, ansType?: string) => void;
    onChangeSearchAssetFields: (e: any, type?: string) => void;
    onMenuCloseAfterSearch: (type?: string) => void;
    onClickSearchInDb: (type?: string, index?: any) => void;
    onSelectPrimaryQuestion: (value: any, type?: string, index?: string) => void;
    onSelectSecondaryQuestion: (value: any, type?: string, index?: string) => void;
    onChangeSecondaryInputValue: (value: any, type?: string, index?: string) => void;
    onShowDrawing: (questionId: string) => void;
}

const RevaluateQuestions = ({
    primaryQuestions,
    secondaryQuestions,
    selectedPrimaryAnswers,
    selectedSecondaryAnswers,
    filteredFields,
    matchedItems,
    items,
    filterErrors,
    searchInDB,
    loadingOptions,
    loadingFields,
    questionsLoading,
    displayQuestions,
    secondaryQuestionsErrors,
    onMatchSecondaryItem,
    onMatchItem,
    onMatchAllItems,
    onMenuCloseAfterSearch,
    onChangeSearchAssetFields,
    onClickSearchInDb,
    onSelectPrimaryQuestion,
    onSelectSecondaryQuestion,
    onChangeSecondaryInputValue,
    onShowDrawing,
}: IRevaluateQuestions) => {
    return (
        <Table
            columns={['Question', 'Seller\'s Answer', 'Match All', 'Your Response', '']}
            hidePagination={true}
            selectedColumns={['Match All']}
            headStyle='sticky top-0 z-10'
            containerStyle={'mt-0 max-h-[600px] overflow-y-auto custom-scrollbar'}
            onClickSelectedColumn={onMatchAllItems}>
            {questionsLoading ?
                <>
                    {[...Array(5)].map((x, i) => {
                        return (
                            <tr key={i}>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                            </tr>
                        )
                    })}
                </>
                : <>
                    {(primaryQuestions && primaryQuestions?.length > 0) && primaryQuestions?.map((item: any, index: number) => {
                        const filteredFieldsFind = filteredFields?.[item?.field]
                        const findError = filterErrors && filterErrors?.[item?.field]
                        const findLoadingOptions = !!loadingOptions?.[item?.field]
                        const findSellerAnswer: IDisplayQuestion | undefined = (displayQuestions && displayQuestions?.length > 0) ? displayQuestions?.find((question: IDisplayQuestion) => question?.id === item?._id) : undefined
                        const findIfMatched = matchedItems?.[item?.question]
                        const values = filteredFieldsFind ?
                            filteredFieldsFind?.length > 0 &&
                            filteredFieldsFind?.map((value: string) => {
                                return { ...item, value: value, label: value };
                            })
                            : items?.[item?.field] &&
                            items?.[item?.field]?.length > 0 &&
                            items?.[item?.field]?.map((value: string) => {
                                return { ...item, value: value, label: value };
                            });
                        return (
                            <tr key={index}>
                                <td className='py-2 px-4'>{item?.question}</td>
                                <td className='px-4 pt-2 pb-3 align-middle'>
                                    {(findSellerAnswer?.value) ?
                                        findSellerAnswer?.value || '' :
                                        ((findSellerAnswer?.answers && findSellerAnswer?.answers?.length > 0)
                                            ? findSellerAnswer?.answers?.map((answer: any, idx: number) => `${idx ? ', ' : ''}${answer?.answer || ''}`) : 'No answer')
                                    }
                                </td>
                                <td className='text-center px-4 pt-2 pb-3 align-middle'>
                                    <input
                                        onChange={() => onMatchItem?.(item?.question || '', findIfMatched, findSellerAnswer, item?.field)}
                                        checked={findIfMatched}
                                        id='checkbox-all'
                                        type='checkbox'
                                        className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-yellow-300 rounded focus:accent-yellow-300 focus:ring-2' />
                                    <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                                </td>
                                <td className='py-2 px-4'>
                                    <AsyncSelectCheckbox
                                        options={values || []}
                                        isLoading={(loadingFields && !selectedPrimaryAnswers?.[item?.question]) || false}
                                        dynamicOptions={true}
                                        showSearchDB={searchInDB?.[item?.field] || false}
                                        placeholder={selectedPrimaryAnswers?.[item?.question] ? selectedPrimaryAnswers?.[item?.question]?.label : `Select ${item?.field}`}
                                        loadingOptions={findLoadingOptions}
                                        selectedOption={selectedPrimaryAnswers?.[item?.question]}
                                        uniqueName={item?.question}
                                        index={item?.field}
                                        onChangeSelectedOption={onSelectPrimaryQuestion}
                                        onChangeValue={onChangeSearchAssetFields}
                                        onMenuClose={onMenuCloseAfterSearch}
                                        onClickSearchInDb={onClickSearchInDb}
                                        error={findError}
                                    />
                                </td>
                                <td>
                                    {item?.showDraw &&
                                        <Button
                                            label='Draw Issue'
                                            className='btn-primary-text-underline !text-black'
                                            onClickButton={() => onShowDrawing(item?._id || '')}
                                        />
                                    }
                                </td>
                            </tr>
                        )
                    })}
                    {(secondaryQuestions && secondaryQuestions?.length > 0) && secondaryQuestions?.map((item: IQuestion & { show: boolean }, index: number) => {
                        const findError = secondaryQuestionsErrors?.[item?.question]
                        const findIfMatched = matchedItems?.[item?.question]
                        const findSellerAnswer: IDisplayQuestion | undefined = (displayQuestions && displayQuestions?.length > 0) ? displayQuestions?.find((question: IDisplayQuestion) => question?.id === item?._id) : undefined
                        const values = ['MULTIPLE_CHOICE', 'SINGLE_CHOICE'].includes(item?.answers?.type) && item?.answers?.options && item?.answers?.options?.length > 0 ?
                            item?.answers?.options?.map((option: { answer: string }) => {
                                return { ...option, value: option?.answer, label: option?.answer, type: item?.answers?.type };
                            })
                            : undefined
                        return (
                            <tr key={index} className={`${item?.show ? '' : 'cursor-not-allowed opacity-80 bg-[#f4f4f4]'}`}>
                                <td className='py-2 px-4'>{item?.question}</td>
                                <td className='px-4 pt-2 pb-3 align-middle'>
                                    {(findSellerAnswer?.value) ?
                                        findSellerAnswer?.value :
                                        ((findSellerAnswer?.answers && findSellerAnswer?.answers?.length > 0)
                                            ? findSellerAnswer?.answers?.map((answer: any, idx: number) => `${idx ? ', ' : ''}${answer?.answer}`) : 'No answer')
                                    }
                                </td>
                                <td className='text-center px-4 pt-2 pb-3 align-middle'>
                                    <input
                                        onChange={() => item?.show && onMatchSecondaryItem?.(item?.question || '', findIfMatched, findSellerAnswer, item?.answers?.type)}
                                        checked={item?.show && findIfMatched}
                                        id='checkbox-all'
                                        type='checkbox'
                                        className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-yellow-300 rounded focus:accent-yellow-300 focus:ring-2' />
                                    <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                                </td>
                                <td className='py-2 px-4'>
                                    {['MULTIPLE_CHOICE', 'SINGLE_CHOICE'].includes(item?.answers?.type)
                                        ?
                                        <SelectCheckbox
                                            options={values || []}
                                            placeholder=' '
                                            selectedOption={selectedSecondaryAnswers?.[item?.question]}
                                            error={findError}
                                            multiple={item?.answers?.type === 'MULTIPLE_CHOICE'}
                                            disabled={!item?.show}
                                            containerStyle='w-full'
                                            uniqueName={item?.question}
                                            index={item?.answers?.type}
                                            onChangeSelectedOption={onSelectSecondaryQuestion}
                                        />
                                        : <Input
                                            error={findError}
                                            inputUniqueName={item?.question}
                                            showValue={true}
                                            inputValue={selectedSecondaryAnswers?.[item?.question]?.value || undefined}
                                            disabled={!item?.show}
                                            placeholder=' '
                                            index={item?.answers?.type}
                                            containerStyle='!mb-0 my-1'
                                            onChangeInput={onChangeSecondaryInputValue}
                                        />
                                    }
                                </td>
                                <td>
                                    {item?.showDraw &&
                                        <Button
                                            label='Draw Issue'
                                            className={`btn-primary-text-underline !text-black ${!item?.show && 'cursor-not-allowed'}`}
                                            onClickButton={() => onShowDrawing(item?._id || '')}
                                        />
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default RevaluateQuestions;