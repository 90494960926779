import React from 'react';
import { ISubBrand } from '../../../../interfaces/sub-brands/ISubBrand';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import Table from '../../../../shared/table';

const tableColumns = ['Name', 'Display Name', 'Markets', 'Logo', 'Actions']

interface ISubBrandsTable {
    rows: ISubBrand[];
    accessControl?: IAccessControl;
    actions?: IPageActions;
    rowsLoading: boolean;
    onDeleteSubBrandRequest: (id: string) => void;
    onEditSubBrand: (id: string) => void;
}

const SubBrandsTable = ({
    rows,
    accessControl,
    actions,
    rowsLoading,
    onDeleteSubBrandRequest,
    onEditSubBrand
}: ISubBrandsTable) => {
    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.subBrands?.name}</td>
                                <td className='px-6 py-4'>{row?.subBrands?.displayName}</td>
                                <td className='px-6 py-4'>
                                    {(row?.subBrands?.markets && row?.subBrands?.markets?.length > 0) ? row?.subBrands?.markets?.map((s: any, index: number) => { return index > 0 ? `, ${s?.label || s?.name}` : s?.label || s?.name }) : ''}
                                </td>
                                <td className='px-6 py-4'>{row?.subBrands?.logoUrl && <img src={row?.subBrands?.logoUrl} className={'mr-2 py-2 w-[20px]'} />}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa='edit-sub-brand' onClick={() => ((!accessControl || actions?.['Edit Sub Brand']) && row?.subBrands?.id) && onEditSubBrand(row?.subBrands?.id)}>
                                            <img title='Edit Sub Brand' alt='edit sub brand' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Sub Brand']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa='delete-sub-brand' onClick={() => ((!accessControl || actions?.['Delete Sub Brand']) && row?.subBrands?.id) && onDeleteSubBrandRequest(row?.subBrands?.id)}>
                                            <img title={'Delete Sub Brand'} alt='delete sub brand' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Sub Brand']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default SubBrandsTable;