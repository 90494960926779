import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IItemAllocation } from '../../../../interfaces/item-allocation/IItemAllocation';
import { ItemAllocationService } from './itemAllocationApi';
import { PaginatedItemAllocation } from '../../../../interfaces/item-allocation/paginatedStore.type';
import { IItemAllocationListState } from '../../../../interfaces/item-allocation/IItemAllocationList';


const initialState: IItemAllocationListState = {
    itemIsLoading: false,
    itemsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchItemAllocation = createAsyncThunk<PaginatedItemAllocation, IPaginationPayload>(
    'itemAllocation/tryToFetchItemAllocation',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await ItemAllocationService.tryToFetchItemAllocation(
            pageNumber,
            pageSize,
            filters,
            data
        );
        return result?.data;
    });

export const tryToAllocationItemsToHighestPrice = createAsyncThunk<ApiResponse<IItemAllocation>, any>(
    'itemAllocation/tryToAllocationItemsToHighestPrice',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ItemAllocationService.tryToAllocationItemsToHighestPrice(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToAllocateItemsToSpecificBrokers = createAsyncThunk<ApiResponse<IItemAllocation>, any>(
    'itemAllocation/tryToAllocateItemsToSpecificBrokers',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ItemAllocationService.tryToAllocateItemsToSpecificBrokers(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const itemAllocationSlice = createSlice({
    name: 'itemAllocation',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchItemAllocation
            .addCase(tryToFetchItemAllocation.pending, (state) => {
                state.itemsAreLoading = true;
            })
            .addCase(tryToFetchItemAllocation.rejected, (state) => {
                state.itemsAreLoading = false;
            })
            .addCase(tryToFetchItemAllocation.fulfilled, (state, action) => {
                state.itemsAreLoading = false;
                state.itemAllocations = action.payload;
            })
    }
});

export default itemAllocationSlice.reducer;
