import React from 'react';
import Button from '../../../../shared/button';


interface IAdminInvoicesState {
    selectedState?: string;
    invoicesStates: Array<{ name: string, label: string }>;
    onChangeState: (status: string) => void;
}

const AdminInvoicesState = ({
    selectedState,
    invoicesStates,
    onChangeState
}: IAdminInvoicesState) => {
    return (
        <div className='flex flex-row justify-start items-center flex-wrap min-w-full my-4'>
            <p className='text-[#8F8F8F] text-sm font-bold ml-2 mr-4'>States:</p>
            {invoicesStates && invoicesStates?.length > 0 && invoicesStates?.map((state) => {
                return (
                    <Button
                        key={state?.label}
                        label={state?.name}
                        dataQa={`${state?.name}`}
                        className={`${selectedState === state?.label ? 'btn-filters' : 'btn-noactive-filters'} mr-2 !font-bold`}
                        onClickButton={() => onChangeState(state?.label || '')}
                    />
                )
            })}
        </div>
    )
}

export default AdminInvoicesState;