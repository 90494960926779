import React from 'react';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';

const tableColumns = ['Id', 'Catalog', 'Frames', 'Frames Gallery', 'Actions']

interface IAssetTemplateFramesTable {
    rows?: Array<any>;
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    loading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onDeleteAssetFrame: (id: string) => void;
    onEditAssetFrame: (id: string) => void;
    onSeeAllFrameImages: (frames: any) => void;
}

const AssetTemplateFramesTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    loading,
    setPaginationState,
    onDeleteAssetFrame,
    onSeeAllFrameImages,
    onEditAssetFrame
}: IAssetTemplateFramesTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {loading ?
                <>
                    <>
                        {[...Array(4)].map((x, i) => {
                            return (
                                <tr key={i}>
                                    <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                </tr>
                            )
                        })}
                    </>
                </>
                : <>
                    {(rows && rows?.length > 0) && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?._id}</td>
                                <td className='px-6 py-4'>{row?.assetTemplateDisplayName || ''}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        {row?.frames && row?.frames?.length > 0 && row?.frames?.filter((item: any, index: number) => index < 3)?.map((item: any, index: number) => {
                                            return (
                                                <img key={index} src={item?.url} alt={`${row?.assetTemplateName}-frame-${index + 1}`} className={'mr-2 py-2 obj-contain h-auto max-w-[20px]'} />
                                            )
                                        })}
                                    </div>
                                </td>
                                <td className='px-6 py-4'>
                                    <Button
                                        label='All Frames'
                                        dataQa={'all-frames'}
                                        className={(!accessControl || actions?.['See All Frames']) ? 'btn-main' : 'btn-main-disable'}
                                        onClickButton={() => ((!accessControl || actions?.['See All Frames']) && row?.frames) && onSeeAllFrameImages(row?.frames)}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa={'edit-catalog-frames'} onClick={() => ((!accessControl || actions?.['Edit Catalog Frames']) && row?.assetTemplateId) && onEditAssetFrame(row?.assetTemplateId)}>
                                            <img title={'Edit Catalog Frames'} alt='edit item' data-qa='extended-asset-edit' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Catalog Frames']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div data-qa={'delete-catalog-frames'} onClick={() => ((!accessControl || actions?.['Delete Catalog Frames']) && row?.assetTemplateId) && onDeleteAssetFrame(row?.assetTemplateId)}>
                                            <img title={'Delete Catalog Frames'} alt='delete item' data-qa='extended-asset-delete' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Catalog Frames']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default AssetTemplateFramesTable;