import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToEditPartner } from '../../../../store/brokers/admin/partners/partnersSlice';
import Loading from '../../../../shared/Loading';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';


interface IEditPersonalInformation {
    userInfo: any;
    onShowHidePersonalInformation: () => void;
    profileUpdatedSuccessfully: () => void;
}

type FormValues = {
    companyName: string;
    companyRegistrationNumber: string;
    vatNumber?: string;
    annualGMV?: string;
    phoneNumber: string;
    url?: string;
};

const defaultValues = {
    companyName: '',
    companyRegistrationNumber: '',
    vatNumber: '',
    annualGMV: '',
    phoneNumber: '',
    url: ''
};

const EditPersonalInformation = ({
    userInfo,
    onShowHidePersonalInformation,
    profileUpdatedSuccessfully
}: IEditPersonalInformation) => {
    const dispatch = useAppDispatch()
    const [updateError, setUpdateError] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        setValue('companyName', userInfo?.companyName || defaultValues?.companyName)
        setValue('companyRegistrationNumber', userInfo?.companyRegistrationNumber || '')
        setValue('vatNumber', userInfo?.vatNumber || '')
        setValue('annualGMV', userInfo?.annualGMV || '')
        setValue('phoneNumber', userInfo?.phoneNumber || '')
        setValue('url', userInfo?.url || '')
        setIsLoading(false)
    }, [userInfo])

    const onUpdateUserProfile = async (data: FormValues) => {
        if (loadingSubmit) {
            return;
        }
        setLoadingSubmit(true)
        try {
            await dispatch(tryToEditPartner(data)).unwrap();
            profileUpdatedSuccessfully();
            reset();
        } catch (error) {
            setUpdateError(`${error}`)
        }
        setLoadingSubmit(false)
    }

    return (
        <div className='min-w-[100%]'>
            {updateError && <Error text={updateError} />}
            <form onSubmit={handleSubmit(onUpdateUserProfile)}>
                {(!isLoading && watch('companyName') || watch('companyName') === '') ?
                    <div className='grid grid-cols-2 gap-4'>
                        <Input
                            label='Company Name'
                            dataQa={'company-name'}
                            register={register('companyName', {
                                required: {
                                    message: 'Company Name is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Company Name is required'
                            })}
                            error={errors.companyName?.message}
                        />
                        <Input
                            label='Broker URL'
                            dataQa={'broker-url'}
                            register={register('url')}
                            error={errors.url?.message}
                        />
                        <Input
                            label='Company Registration Number'
                            dataQa={'company-registration-nr'}
                            register={register('companyRegistrationNumber', {
                                required: {
                                    message: 'Company Registration Number is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Company Registration Number is required'
                            })}
                            error={errors.companyRegistrationNumber?.message}
                        />
                        <Input
                            label='VAT Number'
                            dataQa={'vat-number'}
                            register={register('vatNumber', {})}
                            error={errors.vatNumber?.message}
                        />
                        <Input
                            label='Annual Turnover'
                            dataQa={'annual-gmv'}
                            register={register('annualGMV')}
                            error={errors.annualGMV?.message}
                        />
                        <Input
                            label='Phone Number'
                            dataQa={'phone-number'}
                            register={register('phoneNumber', {
                                required: {
                                    message: 'Phone Number is required',
                                    value: true,
                                },
                                pattern: {
                                    value: /^\+[0-9 ]+$/,
                                    message: 'Please write a valid number ex. +123456789'
                                },
                                validate: (value: string) => !!value.trim() || 'Phone Number is required'
                            })}
                            error={errors.phoneNumber?.message}
                        />
                    </div>
                    : <div className='my-3 flex flex-row justify-center items-center'>
                        <Loading />
                    </div>
                }
                <div className='flex flex-row justify-start my-6'>
                    <Button
                        label='Save Changes'
                        dataQa={'save-changes'}
                        type={'submit'}
                        className={`!shadow-none ${loadingSubmit ? 'btn-main-disable' : 'btn-main'} min-w-[150px] mr-4`}
                    />
                    <Button
                        label='Discard'
                        dataQa={'discard'}
                        className='!rounded-[4px] btn-categories min-w-[130px] '
                        onClickButton={onShowHidePersonalInformation}
                    />
                </div>
            </form>
        </div>
    )
}

export default EditPersonalInformation;