import React, { useCallback } from 'react';
import Button from '../button';
import { tryToAddConversationAiAgent, tryToAddNextConversationAiAgent } from '../../store/brokers/ai-agents/aiAgentsSlice';
import { useAppDispatch } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import { classNames } from '../../utils/classNames';

const LinkHighlighter = ({ text, buttons, isLoading, type, stepDetails }: { text: string, buttons?: string[], isLoading?: boolean, type?: 'seller' | 'buyer', stepDetails?: {stepNr: number, stepAnswer: string} }) => {
	const { id } = useParams();
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	const dispatch = useAppDispatch();
	const parts = text.split(urlRegex).map((part, index) => {
		if (urlRegex.test(part)) {
			return (
				<a key={index} className='text-blue-500 underline' href={part} target="_blank" rel="noopener noreferrer">
					click here
				</a>
			);
		}
		return part;
	});

	const handleConfirm = useCallback(async (answer: string) => {
		const addChat = await dispatch(tryToAddConversationAiAgent({ answer, chatId: id || '', type, userHasConfirmOrder: true })).unwrap();
		if (addChat.success && !addChat.finalStep) {
			await dispatch(tryToAddNextConversationAiAgent({ chatId: id || '', type })).unwrap();
		}
	}, [id]);

	return <div>
		{parts}
		{stepDetails?.stepAnswer ? buttons?.length ? <p className='text-[#00B67A] text-sm font-normal'>{stepDetails.stepAnswer}</p> : <></> : 
		buttons?.map((button, index) =>
			<td key={button} className='pt-5'>
				<Button
					disabled={isLoading}
					loading={isLoading}
					className={classNames('text-label text-sm py-3 px-7 rounded-xl mx-2 border ', (index % 2 === 0) ? 'bg-gradient-to-br from-gradient-start to-gradient-end border-from-gradient-start border-to-gradient-end hover:from-gradient-end hover:to-gradient-start' : 'border border-primary-dark bg-white')}
					label={button}
					onClickButton={() => handleConfirm(button)}
				/>
				
			</td>
		)}
		
	</div>;
};

export default LinkHighlighter;