export const LEVELS = [
    {
        value: -1,
        label: '-1',
    },
    {
        value: 0,
        label: '0',
    },
    {
        value: 1,
        label: '1',
    },
];
