import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useAppDispatch } from '../../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { IInvoices } from '../../../../interfaces/invoices/IInvoices';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { tryToDownloadInvoiceDetailsCSV } from '../../../../store/brokers/partners/invoices/invoicesSlice';
import TableActions from '../details/TableActions';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';

const tableColumns = ['Invoice Number', 'Market', 'Partner', 'Category', 'Amount', 'Postage Fee', 'State', 'Reminder', 'Ship Items', 'Actions']

interface IAdminInvoicesTable {
    rows: IInvoices[];
    paginationData?: IPage;
    markets?: IAllMarkets[];
    selectedRows?: Array<string>;
    partners?: IPartners[];
    categories?: IAssetTemplate[];
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSelectRow: (id?: string) => void;
    onClickItemToShip: (id: string) => void;
}

const AdminInvoicesTable = ({
    rows,
    paginationData,
    markets,
    selectedRows,
    partners,
    categories,
    onClickItemToShip,
    setPaginationState,
    onClickSelectRow,
}: IAdminInvoicesTable) => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [invoiceData, setInvoiceData] = useState<any>()

    const onFormatStatus = (status: string) => {
        switch (status) {
            case 'PAID':
                return { name: 'PAID', color: 'bg-[#a7eae0]' }
            case 'UNPAID':
                return { name: 'UNPAID', color: 'bg-[#ff3b30]' }
            default:
                return { name: 'NO STATUS', color: 'bg-[#dffffa]' }
        }
    }

    const onDownloadData = async (invoiceNumber: string) => {
        setLoadingDownload(true)
        try {
            const response = await dispatch(tryToDownloadInvoiceDetailsCSV(invoiceNumber)).unwrap()
            setInvoiceData(response?.data || [])
        } catch (err: any) {
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (invoiceData && loadingDownload) {
            csvLink.current.link.click()
        }
        setLoadingDownload(false)
    }, [invoiceData])

    return (
        <Table
            selectRows={true}
            areAllRowsSelected={((selectedRows && rows && rows.every(r => selectedRows.includes(r.invoiceNumber || ''))))}
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            onClickSelectAll={() => onClickSelectRow()}
            containerStyle={'mt-0'}
        >
            {rows && rows?.length > 0 &&
                rows?.map((row: IInvoices, idx: number) => {
                    const status = onFormatStatus(row?.state)
                    const marketName = markets && markets?.length > 0 && markets?.find((market) => market?._id === row?.marketId)
                    const findIfRowIsSelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find((item) => item === row?.invoiceNumber)
                    const findPartner = partners && partners?.length > 0 && partners?.find((partner) => partner?._id === row?.partnerId)
                    return (
                        <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                            <td className='px-6 py-4'>
                                <div className='flex items-center'>
                                    <input
                                        onChange={() => onClickSelectRow(row?.invoiceNumber)}
                                        checked={!!findIfRowIsSelected}
                                        data-qa={'checkbox-type'}
                                        id='checkbox-all'
                                        type='checkbox'
                                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2' />
                                    <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                                </div>
                            </td>
                            <td className='px-6 py-4'>{row?.invoiceNumber}</td>
                            <td className='px-6 py-4'>{marketName ? marketName?.label : ''}</td>
                            <td className='px-6 py-4'>{findPartner ? findPartner?.companyName || findPartner?.name : ''}</td>
                            <td className='px-6 py-4'>{row?.assetCatalog?.code || ''}</td>
                            <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(marketName && marketName?.currency?.name || '')}`]}{(row?.amount?.amount || 0).toFixed(2)}</td>
                            <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(marketName && marketName?.currency?.name || '')}`]}{(row?.amount?.totalPostageFee || 0).toFixed(2)}</td>
                            <td className='px-6 py-4'><div className={`${status ? status?.color : ''} flex flex-row justify-center text-center text-red px-1 min-w-[150px] text-black text-sm py-1 font-medium rounded-2xl`}>{status && status?.name}</div></td>
                            <td className='px-6 py-4'>
                                {row?.reminder && moment(row?.reminder).fromNow()}
                            </td>
                            <td className='px-6 py-4 min-w-[170px]'>
                                <Button
                                    label={(row?.shippedToBroker && row?.state === 'PAID') ? 'Shipped' : 'Ship'}
                                    className={(!row?.shippedToBroker && row?.state === 'PAID') ? 'btn-main min-w-[140px]' : 'btn-main-disable min-w-[140px]'}
                                    onClickButton={() => (!row?.shippedToBroker && row?.state === 'PAID') && onClickItemToShip(row?._id || '')}
                                />
                            </td>
                            <td className='px-6 py-4'>
                                <TableActions
                                    onClickCSVButton={() => row?.invoiceNumber && onDownloadData(row?.invoiceNumber)}
                                    excelUrl={row?.excelUrl}
                                    pdfLink={row?.pdfUrl}
                                    shippingUrl={row?.shippingLabelUrl}
                                />
                            </td>

                        </tr>
                    )
                })
            }
        </Table>
    )
}

export default AdminInvoicesTable;