export const USERS_API_URL = process.env.REACT_APP_USERS_API_URL;
export const ASSETS_API_URL = process.env.REACT_APP_ASSETS_API_URL;
export const CS_API_URL = process.env.REACT_APP_CS_API_URL;
export const OFFER_API_URL = process.env.REACT_APP_OFFER_API_URL;
export const ORDERS_URL = process.env.REACT_APP_ORDERS_API_URL;
export const UPLOAD_PRICING_URL = process.env.REACT_APP_UPLOAD_PRICING_API_URL;
export const CARRIERS_API_URL = process.env.REACT_APP_CARRIERS_API_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const SENTRY_URL = process.env.REACT_APP_SENTRY;
export const LOGISTIC_URL = process.env.REACT_APP_LOGISTIC_SERVICE_URL;
export const INVENTORY_SERVICE_URL = process.env.REACT_APP_INVENTORY_SERVICE_URL
export const ADDRESS_SERVICE_URL = process.env.REACT_APP_ADDRESS_SERVICE_URL
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
export const DEVICE_INFO_API = process.env.REACT_APP_DEVICE_INFO_API