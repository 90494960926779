import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToDeleteExtendedTacList,
    tryToDownloadExtendedTacListData,
    tryToFetchAllTacLists
} from '../../../store/brokers/admin/tac/tacSlice';
import ImportExtendedTacListModal from './modals/ImportExtendedTacListModal';
import TacListExtendedModal from './modals/TacListExtendedModal';
import TacListTable from './tables/TacListTable';
import Input from '../../../shared/input';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';


const Tac = () => {
    const dispatch = useAppDispatch();
    const csvLink2 = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<any>([]);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [search, setSearch] = useState<string>()
    const [tacListItemId, setTacListItemId] = useState<string | null>()
    const [showTacListItemModal, setShowTacListItemModal] = useState<boolean>(false)
    const [showDeleteTacListModal, setShowDeleteTacListModal] = useState<boolean>(false)
    const [loadingDownloadTacLists, setLoadingDownloadTacLists] = useState<boolean>(false)
    const [showImportTacListModal, setShowImportTacListModal] = useState<boolean>(false)
    const [tacListsToDownload, setTacListsToDownload] = useState<Array<any>>()
    const [showToast, setShowToast] = useState<IToast | null>()
    const [pageAccess, setPageAccess] = useState<any>()
    const state = useAppSelector((state) => state.tacList)
    const rowsLoading = useAppSelector((state) => state?.tacList?.tacListAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state?.tacList?.tacLists?.data.page);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'TAC')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state?.tacLists) {
            const rows =
                state.tacLists?.data?.elements && Array.isArray(state.tacLists?.data?.elements)
                    ? state.tacLists?.data?.elements.map((r: any) => ({ ...r }))
                    : [];
            setRows(rows);
        }
    }, [state?.tacLists]);

    useEffect(() => {
        let data: any = {
            ...paginationState || {}
        }
        if (search) {
            data = {
                ...data || {},
                filters: {
                    search: search
                }
            }
        }
        dispatch(tryToFetchAllTacLists(data));
    }, [paginationState]);

    const onCloseDeleteModal = () => {
        setTacListItemId(null)
        setShowDeleteTacListModal(false)
    }

    const onCloseTacListModal = () => {
        setTacListItemId(null)
        setShowTacListItemModal(false)
    }

    const onDisableTacList = async () => {
        try {
            if (tacListItemId) {
                await dispatch(tryToDeleteExtendedTacList(tacListItemId)).unwrap();
                setShowToast({ type: 'success', message: `Item successfully deleted` });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setTacListItemId(null)
        setShowDeleteTacListModal(false)
    }

    const onDownloadExtendedTacListData = async () => {
        setLoadingDownloadTacLists(true)
        try {
            const response = await dispatch(tryToDownloadExtendedTacListData()).unwrap()
            setTacListsToDownload(response || [])
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
            setLoadingDownloadTacLists(false)
        }
    }

    const onToggleImportTacListExtendedModal = () => {
        setShowImportTacListModal(!showImportTacListModal)
    }

    const onImportTacListSuccessfully = () => {
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
        })
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    useEffect(() => {
        if (tacListsToDownload && loadingDownloadTacLists) {
            csvLink2.current.link.click()
        }
        setLoadingDownloadTacLists(false)
    }, [tacListsToDownload])

    const onDeleteTacList = (id: string) => {
        setShowDeleteTacListModal(true)
        setTacListItemId(id)
    }

    const onSaveTacListChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        setTacListItemId(null)
    }

    const onAddNewTacList = (id?: string) => {
        if (id) {
            setTacListItemId(id)
        }
        setShowTacListItemModal(true)
    }

    return (
        <div>
            <div>
                <p className='page-title'>TAC Lists</p>
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={loadingDownloadTacLists ? 'Loading data...' : 'Download Tac Lists'}
                    dataQa={loadingDownloadTacLists ? 'loading-data...' : 'download-tac-lists'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Downlaod Tac Lists']) && onDownloadExtendedTacListData()}
                    className={`${(!accessControl || pageAccess?.actions?.['Downlaod Tac Lists']) ? 'btn-main' : 'btn-main-disable'} mr-2 !py-2 !shadow-none`}
                />
                <CSVLink
                    ref={csvLink2}
                    data={(tacListsToDownload && tacListsToDownload?.length > 0) ? tacListsToDownload?.filter((item, idx) => idx > 0) || [] : []}
                    headers={tacListsToDownload?.[0] || []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={`TAC-list.csv`}
                ></CSVLink>
                <Button
                    label='Import Tac Lists'
                    dataQa={'import-tac-lists'}
                    className={`!rounded ${(!accessControl || pageAccess?.actions?.['Import Tac Lists']) ? 'btn-main' : 'btn-main-disable'}  min-w-[150px] !shadow-none mr-2`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Tac Lists']) && onToggleImportTacListExtendedModal()}
                />
            </div>
            <div className='my-5 relative'>
                <div className={`min-w-[100px]`}>
                    <Input
                        placeholder={'Filter Table'}
                        dataQa={'filter-table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                {(rows?.length > 0 || rowsLoading) ?
                    <TacListTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onDisableTacList={onDeleteTacList}
                        onEditTacList={onAddNewTacList}
                    />
                    : <EmptyContainer
                        text={'No Item added yet.'}
                        showImage={true} />
                }
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            <TacListExtendedModal
                openCreateTacListModal={showTacListItemModal}
                handleCloseTacListModal={onCloseTacListModal}
                onSaveChanges={onSaveTacListChanges}
                tacListItemId={tacListItemId}
            />
            <DeleteModal
                openDeleteModal={showDeleteTacListModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDisableTacList}
            />
            <ImportExtendedTacListModal
                openImportModal={showImportTacListModal}
                handleCloseModal={onToggleImportTacListExtendedModal}
                onImportTacListsSuccessfully={onImportTacListSuccessfully}
            />
        </div>
    )
}

export default Tac;

