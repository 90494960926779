import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { PaginatedInvoices, PaginatedInvoicesDetails } from '../../../../interfaces/invoices/paginatedStore.type';
import { InvoicesService } from './invoicesApi';
import { IInvoicesListState } from '../../../../interfaces/invoices/IInvoicesList';
import { IMarkeInvoice } from '../../../../interfaces/invoices/IMarkeInvoice';
import { IInvoicesSchema } from '../../../../interfaces/invoices/IInvoicesSchema';
import { IInvoiceFilter } from '../../../../interfaces/invoices/IInvoiceFilter';
import { IInvoicesDetails } from '../../../../interfaces/invoices/IInvoicesDetails';
import { IGenerateInvoice } from '../../../../interfaces/invoices/IGenerateInvoice';

const initialState: IInvoicesListState = {
    invoicesAreLoading: false,
    invoiceIsLoading: false,
    billsAreLoading: false,
    billIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchFilteredInvoices = createAsyncThunk<PaginatedInvoices, IPaginationPayload>(
    'invoices/tryToFetchFilteredInvoices',
    async ({ pageNumber, pageSize, data }) => {
        const result = await InvoicesService.tryToFetchFilteredInvoices(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToFetchFilteredBills = createAsyncThunk<any, IPaginationPayload>(
    'invoices/tryToFetchFilteredBills',
    async ({ pageNumber, pageSize, data }) => {
        const result = await InvoicesService.tryToFetchFilteredBills(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToDownloadInvoicesCSV = createAsyncThunk<PaginatedInvoices, IInvoiceFilter>(
    'invoices/tryToDownloadInvoicesCSV',
    async (data) => {
        const result = await InvoicesService.tryToDownloadInvoicesCSV(data);
        return result?.data;
    },
);

export const tryToDownloadInvoiceDetailsCSV = createAsyncThunk<PaginatedInvoices, string>(
    'invoices/tryToDownloadInvoiceDetailsCSV',
    async (invoiceNumber) => {
        const result = await InvoicesService.tryToDownloadInvoiceDetailsCSV(invoiceNumber);
        return result?.data;
    },
);

export const tryToShipOrder = createAsyncThunk<ApiResponse<IInvoicesSchema>, any>(
    'invoices/tryToShipOrder',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await InvoicesService.tryToShipOrder(id);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToMarkInvoiceAsPaid = createAsyncThunk<ApiResponse<IInvoicesSchema>, any>(
    'invoices/tryToMarkInvoiceAsPaid',
    async (data: IMarkeInvoice, { rejectWithValue }) => {
        try {
            const result = await InvoicesService.tryToMarkInvoiceAsPaid(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchItemsWithoutInvoice = createAsyncThunk<PaginatedInvoicesDetails, IPaginationPayload>(
    'invoices/tryToFetchItemsWithoutInvoice',
    async ({ pageNumber, pageSize, data, search }) => {
        const result = await InvoicesService.tryToFetchItemsWithoutInvoice(pageNumber, pageSize, data, search);
        return result?.data;
    },
);

export const tryToGenerateInvoice = createAsyncThunk<ApiResponse<IInvoicesDetails>, any>(
    'invoices/tryToGenerateInvoice',
    async (data: IGenerateInvoice, { rejectWithValue }) => {
        try {
            const result = await InvoicesService.tryToGenerateInvoice(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToGenerateMultipleInvoice = createAsyncThunk<ApiResponse<IInvoicesDetails>, any>(
    'invoices/tryToGenerateMultipleInvoice',
    async (data: IGenerateInvoice, { rejectWithValue }) => {
        try {
            const result = await InvoicesService.tryToGenerateMultipleInvoice(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDownloadInvoiceDetailsPdf = createAsyncThunk<PaginatedInvoices, string>(
    'invoices/tryToDownloadInvoiceDetailsPdf',
    async (invoiceNumber) => {
        const result = await InvoicesService.tryToDownloadInvoiceDetailsPdf(invoiceNumber);
        return result?.data;
    },
);


export const invoicesSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchFilteredInvoices
            .addCase(tryToFetchFilteredInvoices.pending, (state) => {
                state.invoicesAreLoading = true;
            })
            .addCase(tryToFetchFilteredInvoices.rejected, (state) => {
                state.invoicesAreLoading = false;
            })
            .addCase(tryToFetchFilteredInvoices.fulfilled, (state, action) => {
                state.invoicesAreLoading = false;
                state.invoices = action.payload;
            })

            // tryToMarkInvoiceAsPaid
            .addCase(tryToMarkInvoiceAsPaid.pending, (state) => {
                state.invoiceIsLoading = true;
            })
            .addCase(tryToMarkInvoiceAsPaid.rejected, (state) => {
                state.invoiceIsLoading = false;
            })
            .addCase(tryToMarkInvoiceAsPaid.fulfilled, (state, action) => {
                state.invoiceIsLoading = false;
                if (state.invoices?.data.elements)
                    state.invoices.data.elements =
                        { ...state }.invoices?.data.elements.map((invoice) => {
                            const findInvoice = (action.meta?.arg?.invoiceNumbers && action.meta?.arg?.invoiceNumbers?.length > 0) && action.meta?.arg?.invoiceNumbers?.find((item: string) => item === invoice?.invoiceNumber)
                            if (findInvoice) {
                                return {
                                    ...invoice,
                                    state: 'PAID'
                                }
                            } else {
                                return invoice
                            }
                        }) || [];
            })

            // tryToShipOrder
            .addCase(tryToShipOrder.pending, (state) => {
                state.invoiceIsLoading = true;
            })
            .addCase(tryToShipOrder.rejected, (state) => {
                state.invoiceIsLoading = false;
            })
            .addCase(tryToShipOrder.fulfilled, (state, action) => {
                state.invoiceIsLoading = false;
                if (state.invoices?.data.elements)
                    state.invoices.data.elements =
                        { ...state }.invoices?.data.elements.map((invoice) => {
                            if (invoice?._id === action?.meta?.arg) {
                                return {
                                    ...invoice,
                                    shippedToBroker: true
                                }
                            } else {
                                return invoice
                            }
                        }) || [];
            })

            // tryToFetchFilteredBills
            .addCase(tryToFetchFilteredBills.pending, (state) => {
                state.billsAreLoading = true;
            })
            .addCase(tryToFetchFilteredBills.rejected, (state) => {
                state.billsAreLoading = false;
            })
            .addCase(tryToFetchFilteredBills.fulfilled, (state, action) => {
                state.billsAreLoading = false;
                if (!action?.meta?.arg?.data?.download) {
                    state.bills = action.payload;
                }
            })

            // tryToFetchItemsWithoutInvoice
            .addCase(tryToFetchItemsWithoutInvoice.pending, (state) => {
                state.invoicesAreLoading = true;
            })
            .addCase(tryToFetchItemsWithoutInvoice.rejected, (state) => {
                state.invoicesAreLoading = false;
            })
            .addCase(tryToFetchItemsWithoutInvoice.fulfilled, (state, action) => {
                state.invoicesAreLoading = false;
                state.items = action.payload;
            })
    },
});

export default invoicesSlice.reducer;