import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddSubBrands,
    tryToEditSubBrands,
    tryToFetchSingleSubBrands
} from '../../../../store/brokers/admin/sub-brands/subBrandsSlice';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface ISubBrandsModal {
    partners?: any[];
    markets?: any;
    openModal: boolean;
    partnerId: string;
    subBrandId?: string | null;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    displayName: string;
    // partnerId: string;
    markets?: any;
    logoUrl?: string;
};

const SubBrandsModal = ({
    openModal,
    subBrandId,
    partners,
    partnerId,
    markets,
    handleCloseModal,
    onSaveChanges,
}: ISubBrandsModal) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.subBrands);
    const [selectedMarkets, setSelectedMarkets] = useState<any>()
    const [fileSelected, setFileSelected] = useState<any>()
    const [updateError, setUpdateError] = useState<string>()
    const subBrandLoading = useAppSelector((state) => state.subBrands?.subBrandIsLoading);

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch
    } = useForm<FormValues>({});

    useEffect(() => {
        setValue('name', subBrandId ? state?.subBrandDetail?.name || '' : '');
        setValue('displayName', subBrandId ? state?.subBrandDetail?.displayName || '' : '');
        if (state?.subBrandDetail?.markets) {
            setValue('markets', state?.subBrandDetail?.markets || [])
            const formatSelectedMarkets: any = []
            state?.subBrandDetail?.markets?.length > 0 && state?.subBrandDetail?.markets?.map((market: any) => {
                const findMarket = markets && markets?.length > 0 && markets?.find((next: any) => next?.value === market?.id)
                if (findMarket) {
                    formatSelectedMarkets.push(findMarket)
                }
                return market
            })
            setSelectedMarkets(formatSelectedMarkets)
        }
        // setValue(
        //     'partnerId',
        //     subBrandId ? state?.subBrandDetail?.partnerId || '' : '',
        // );

    }, [state.subBrandDetail]);

    useEffect(() => {
        if (subBrandId) {
            dispatch(tryToFetchSingleSubBrands({ id: subBrandId, partnerId }));
        } else {
            setFileSelected(undefined)
            setSelectedMarkets(undefined)
            reset()
        }
    }, [subBrandId, openModal]);

    const onCloseModal = () => {
        reset();
        setUpdateError(undefined)
        setSelectedMarkets(undefined)
        setFileSelected(undefined)
        handleCloseModal();
    };

    const onSubmit = async (data: FormValues) => {
        if (!(fileSelected || state?.subBrandDetail?.logoUrl)) {
            // setUpdateError('Logo is required.')
            // return;
        }

        const formData = new FormData()
        formData.append('partnerId', partnerId)
        formData.append('displayName', data?.displayName)
        formData.append('markets', JSON.stringify(data?.markets))
        formData.append('name', data?.name)
        if (fileSelected) {
            formData.append('file', fileSelected)
        }
        if (!fileSelected && subBrandId) {
            formData.append('logoUrl', state?.subBrandDetail?.logoUrl)
        }
        const payload: any = {
            data: formData,
        };
        try {
            if (subBrandId) {
                payload._id = subBrandId;
                await dispatch(tryToEditSubBrands(payload)).unwrap();
            } else {
                await dispatch(tryToAddSubBrands(formData)).unwrap()
            }
            onSaveChanges(
                'success',
                `Sub Brand successfully ${subBrandId ? 'changed' : 'saved'}.`,
            );
        } catch (error) {
            onSaveChanges('error', `${error}`);
        }
        onCloseModal()
    };

    const onChangeSelectedMarket = (e: any) => {
        if (subBrandId) {
            let findIfAnyFixedIsMissing = false
            selectedMarkets?.filter((market: any) => market?.isFixed)?.map((market: any) => {
                const findNewSelected = e?.length > 0 && e?.find((newSelected: any) => newSelected?.value === market?.value)
                if (!findNewSelected) findIfAnyFixedIsMissing = true
                return market;
            })
            if (findIfAnyFixedIsMissing) {
                return;
            }
        }
        setSelectedMarkets(e?.length > 0 ? e?.map((s: any) => ({ ...s })) : [])
        setValue('markets', e?.length > 0 ? e?.map((s: any) => {
            return {
                id: s?._id,
                name: s?.displayName,
                label: s?.label,
                currency: s?.currency?.id
            }
        }
        ) : [])
    }

    return (
        <Modal open={openModal} onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>{subBrandId ? 'Edit Sub Brand' : 'Create Sub Brand'}</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(subBrandId && !subBrandLoading) || !subBrandId ? (
                        <div className='flex flex-col my-4'>
                            <Input
                                placeholder='Name'
                                dataQa={'edit-sub-name'}
                                label={subBrandId ? 'Name' : ''}
                                register={register('name', {
                                    required: {
                                        message: 'Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) =>
                                        !!value.trim() || 'Name is required',
                                })}
                                error={errors.name?.message}
                            />
                            <Input
                                placeholder='Display Name'
                                dataQa={'edit-sub-display-name'}
                                label={subBrandId ? 'Display Name' : ''}
                                register={register('displayName', {
                                    required: {
                                        message: 'Display Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) =>
                                        !!value.trim() || 'Display Name is required',
                                })}
                                error={errors.displayName?.message}
                            />
                            {/* <Select
                                key={1}
                                placeholder='Partner'
                                containerStyle='mb-1'
                                showValue={true}
                                register={register('partnerId', {
                                    required: {
                                        message: 'Partner is required',
                                        value: true,
                                    },
                                    validate: (value: string) =>
                                        !!value.trim() ||
                                        'Partner is required',
                                })}
                                value={selectedPartner}
                                onChangeSelectValue={(e) => onChangeSelectedPartner(e)}
                                options={partnersOptions || []}
                                error={errors?.partnerId?.message}
                            /> */}
                            {/* <SelectCheckbox
                                placeholder='Partner'
                                onChangeSelectedOption={onChangeSelectedPartner}
                                options={partners || []}
                                selectedOption={selectedPartner}
                                error={errors?.partnerId?.message}
                            /> */}
                            <SelectCheckbox
                                placeholder='Market'
                                dataQa={'sub-market'}
                                onChangeSelectedOption={onChangeSelectedMarket}
                                options={markets}
                                selectedOption={selectedMarkets}
                                multiple={true}
                                error={(Object.keys(errors).length !== 0 && (!watch('markets') || watch('markets')?.length <= 0)) ? 'Market is required' : undefined}
                            />
                            <div className='mb-6'>
                                <p className='mb-3 mt-4 font-medium'>Import logo:</p>
                                <div className='flex flex-col items-start' {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button
                                        icon={<img src='/assets/shared/upload-file.svg' className='w-[30px] object-contains pr-2' />}
                                        className={'btn-main !bg-[#202020] !text-white !py-1 !shadow-none flex flex-row items-center'}
                                        label={'Select Image'}
                                        dataQa={'select-image-sub'}
                                    />
                                    {fileSelected && fileSelected?.name
                                        ? <p className={'ml-3'}>{fileSelected?.name}</p>
                                        : subBrandId && <img src={state?.subBrandDetail?.logoUrl} className={'w-[40px] my-3'} />
                                    }
                                </div>
                            </div>
                            <div className='flex flex-row my-3 justify-center'>
                                {updateError && <Error text={updateError} />}
                            </div>
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    type={'submit'}
                                    className={'btn-primary'}
                                    dataQa={'edit-sub-save'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default SubBrandsModal;