import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MultipleCashoutService } from './multipleCashoutApi';
import { IMultipleCashoutListState } from '../../../../interfaces/multiple-cashout/IMultipleCashoutList';
import { PaginatedMultipleCashout } from '../../../../interfaces/multiple-cashout/paginatedStore.type';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IMultipleCashout } from '../../../../interfaces/multiple-cashout/IMultipleCashout';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { AxiosError } from 'axios';

const initialState: IMultipleCashoutListState = {
    cashoutIsLoading: false,
    cashoutsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchMultipleCashouts = createAsyncThunk<PaginatedMultipleCashout, IPaginationPayload>(
    'multipleCashout/tryToFetchMultipleCashouts',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await MultipleCashoutService.tryToFetchMultipleCashouts(pageNumber, pageSize, filters, data);
        return result?.data;
    },
);

export const tryToFetchMultipleCashoutsToReship = createAsyncThunk<PaginatedMultipleCashout, IPaginationPayload>(
    'multipleCashout/tryToFetchMultipleCashoutsToReship',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await MultipleCashoutService.tryToFetchMultipleCashoutsToReship(pageNumber, pageSize, filters, data);
        return result?.data;
    },
);

export const tryToFetchMultipleCashoutsReshipped = createAsyncThunk<PaginatedMultipleCashout, IPaginationPayload>(
    'multipleCashout/tryToFetchMultipleCashoutsReshipped',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await MultipleCashoutService.tryToFetchMultipleCashoutsReshipped(pageNumber, pageSize, filters, data);
        return result?.data;
    },
);

export const tryToSplitMultipleCashout = createAsyncThunk<ApiResponse<IMultipleCashout>, any>(
    'multipleCashout/tryToSplitMultipleCashout',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await MultipleCashoutService.tryToSplitMultipleCashout(id);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToShipPackageToBroker = createAsyncThunk<ApiResponse<IMultipleCashout>, any>(
    'multipleCashout/tryToShipPackageToBroker',
    async (data, { rejectWithValue }) => {
        try {
            const result = await MultipleCashoutService.tryToShipPackageToBroker(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const multipleCashoutSlice = createSlice({
    name: 'multipleCashout',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchMultipleCashouts
            .addCase(tryToFetchMultipleCashouts.pending, (state) => {
                state.cashoutsAreLoading = true;
            })
            .addCase(tryToFetchMultipleCashouts.rejected, (state) => {
                state.cashoutsAreLoading = false;
            })
            .addCase(tryToFetchMultipleCashouts.fulfilled, (state, action) => {
                state.cashoutsAreLoading = false;
                state.multipleCashouts = action.payload;
            })

            // tryToFetchMultipleCashoutsToReship
            .addCase(tryToFetchMultipleCashoutsToReship.pending, (state) => {
                state.cashoutsAreLoading = true;
            })
            .addCase(tryToFetchMultipleCashoutsToReship.rejected, (state) => {
                state.cashoutsAreLoading = false;
            })
            .addCase(tryToFetchMultipleCashoutsToReship.fulfilled, (state, action) => {
                state.cashoutsAreLoading = false;
                state.multipleCashouts = action.payload;
            })

            // tryToFetchMultipleCashoutsReshipped
            .addCase(tryToFetchMultipleCashoutsReshipped.pending, (state) => {
                state.cashoutsAreLoading = true;
            })
            .addCase(tryToFetchMultipleCashoutsReshipped.rejected, (state) => {
                state.cashoutsAreLoading = false;
            })
            .addCase(tryToFetchMultipleCashoutsReshipped.fulfilled, (state, action) => {
                state.cashoutsAreLoading = false;
                state.multipleCashouts = action.payload;
            })

            // tryToSplitMultipleCashout
            .addCase(tryToSplitMultipleCashout.pending, (state) => {
                state.cashoutIsLoading = true;
            })
            .addCase(tryToSplitMultipleCashout.rejected, (state) => {
                state.cashoutIsLoading = false;
            })
            .addCase(tryToSplitMultipleCashout.fulfilled, (state, action) => {
                state.cashoutIsLoading = false;
                state.requestStatus = 'success';
                if (state.multipleCashouts?.data.elements)
                    state.multipleCashouts.data.elements =
                        { ...state }.multipleCashouts?.data.elements.map((cashout) => {
                            if (cashout?._id === action.meta.arg) {
                                return {
                                    ...cashout || {},
                                    splited: true
                                }
                            } else {
                                return cashout
                            }
                        }
                        ) || [];
            })
    },
});

export default multipleCashoutSlice.reducer;