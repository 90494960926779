import React, { useEffect, useState } from 'react';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import SelectCheckbox from '../../../../shared/select-checkbox'; // Import the SelectCheckbox component
import { useAppDispatch } from '../../../../app/hooks';
import { tryToFetchCreditSummary } from '../../../../store/brokers/admin/rma/RMAsSlice';
import { IPartners } from '../../../../interfaces/partners/IPartners';

interface ICreditSummaryModal {
    isOpen: boolean
    onRequestClose: () => void 
    partners: IPartners[]
}

const CreditSummaryModal = ({ isOpen, onRequestClose, partners }: ICreditSummaryModal) => {
    const dispatch = useAppDispatch()
    const [selectedPartner, setSelectedPartner] = useState<string>()
    const findPartner: any = (partners && selectedPartner && partners?.length > 0) && partners?.find((item: any) => item?.value === selectedPartner)
    const [creditSummary, setCreditSummary] = useState<{
        pendingCredits: number;
        notPaidCredits: number;
        paidCredits: number;
    }>({ pendingCredits: 0, notPaidCredits: 0, paidCredits: 0 })

    function getWholePart(number: number) {
        return Math.floor(number).toLocaleString();
    }
    function getDecimalPart(number: number) {
        const decimalPart = (number % 1).toFixed(2).slice(2); // Keep only the decimal part
        return decimalPart;
    }


    const onSelectFilterOption = (value: any, name?: string) => {
        if (name) {
            switch (name) {
                case 'partner':
                    setSelectedPartner(value?.value)
            }
        }
    }
    const getCreditSummary = async () => {
        const resp = await dispatch(tryToFetchCreditSummary(selectedPartner)).unwrap()
        setCreditSummary(resp?.data)
    }

    useEffect(() => {
        getCreditSummary()
    }, [selectedPartner])

    return (
        <Modal open={isOpen} onClose={onRequestClose} showInRight contentContainerStyle='min-w-[70vw]'>
            <div className="credit-summary-modal">
                <div className='mb-4'>
                    <p className='text-gray-600 text-sm text-center'>Main Order View</p>
                    <hr className='mt-2' />
                </div>
                <div className='mb-4'>
                    <p className='text-lg font-semibold text-gray-900'>Credit Summary</p>
                    <hr className='mt-2' />
                </div>
                <div className="mb-4 bg-[#F8F9FC] p-4">
                    <p className="block text-gray-700 text-xl font-bold mb-2">What credit note summary do you want to see?</p>
                    <SelectCheckbox
                        name={'Customers (Brokers)'}
                        dataQa={'partner-selected'}
                        containerStyle={'!max-w-[300px]'}
                        placeholder={findPartner?.label || 'All Customers'}
                        options={[{ value: undefined, label: 'All Customers' }, ...partners]}
                        uniqueName={'partner'}
                        onChangeSelectedOption={onSelectFilterOption}
                    />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <div className="bg-[#F8F9FC] p-4 rounded">
                        <h3 className="text-lg font-bold">Pending Credit</h3>
                        <p className='text-sm text-[#8A8A8E]'>Total amount of the items not included on CNs</p>
                        <p className="text-4xl mt-4 text-[#202020]">£{getWholePart(creditSummary.pendingCredits)}.<span className='text-base'>{getDecimalPart(creditSummary.pendingCredits)}</span></p>
                    </div>
                    <div className="bg-[#F8F9FC] p-4 rounded">
                        <h3 className="text-lg font-bold">Not Paid Credit</h3>
                        <p className='text-sm text-[#8A8A8E]'>Total amount of credit owed to customers</p>
                        <p className="text-4xl mt-4 text-[#E50000]">£{getWholePart(creditSummary.notPaidCredits)}.<span className='text-base'>{getDecimalPart(creditSummary.notPaidCredits)}</span></p>
                    </div>
                    <div className="bg-[#F8F9FC] p-4 rounded">
                        <h3 className="text-lg font-bold">Paid Credit</h3>
                        <p className='text-sm text-[#8A8A8E]'>Total Credit used by customers</p>
                        <p className="text-4xl mt-4 text-[#00B67A]">£{getWholePart(creditSummary.paidCredits)}.<span className='text-base'>{getDecimalPart(creditSummary.paidCredits)}</span></p>
                    </div>
                </div>
                <div className="mt-8 flex justify-center">
                    <Button label="Go Back" onClickButton={onRequestClose}
                        className={`ml-2 py-2 px-10 rounded-md bg-gradient-to-r from-[#FCEE21] to-[#F7931E] text-[#202020]`} />
                </div>
            </div>
        </Modal>
    );
};

export default CreditSummaryModal;
