import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import { IBrokersComparisonSchema } from '../../../../interfaces/brokers-comparison-sites/IBrokersComparisonSchema';
import { PaginatedBrokersComparison } from '../../../../interfaces/brokers-comparison-sites/paginatedStore.type';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';


const tryToFetchBrokersComparisonSites = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any,
): Promise<AxiosResponse<any>> => {
    const url = filters
        ? `${USERS_API_URL}/comparison-site-brokers/all?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${USERS_API_URL}/comparison-site-brokers/all?page=${pageNumber}&size=${pageSize}`;
    return brokersAxios.put<PaginatedBrokersComparison>(url, data);
};

const tryToFetchAllBrokerComparisonSite = (): Promise<AxiosResponse<ApiResponse<IBrokersComparisonSchema>>> => {
    return brokersAxios.get<ApiResponse<any>>(`${USERS_API_URL}/comparison-site-brokers/all-brokers`);
};

const tryToFetchSingleBrokerComparison = (id: string): Promise<AxiosResponse<ApiResponse<IBrokersComparisonSchema>>> => {
    return brokersAxios.get<ApiResponse<any>>(`${USERS_API_URL}/comparison-site-brokers/${id}`);
};

const tryToAddBrokerComparison = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/comparison-site-brokers`, data);
};

const tryToEditBrokerComparison = (brokerId: string, payload: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/comparison-site-brokers/${brokerId}`, payload);
};

const tryToEnableBrokerComparisonSite = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/comparison-site-brokers/${id}/enable`);
};

const tryToDisableBrokerComparisonSite = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/comparison-site-brokers/${id}/disable`);
};

const tryToDeleteBrokerComparisonSite = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/comparison-site-brokers/${id}`);
};

export const BrokersComparisonService = {
    tryToFetchBrokersComparisonSites,
    tryToFetchAllBrokerComparisonSite,
    tryToFetchSingleBrokerComparison,
    tryToAddBrokerComparison,
    tryToEditBrokerComparison,
    tryToEnableBrokerComparisonSite,
    tryToDisableBrokerComparisonSite,
    tryToDeleteBrokerComparisonSite,
}