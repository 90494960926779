import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../../config';
import ordersAxios from '../../../../utils/axios/orders.axios';
import { PaginatedInvoiceTemplates } from '../../../../interfaces/invoice-templates/paginatedStore.type';
import { IInvoiceTemplate } from '../../../../interfaces/invoice-templates/IInvoiceTemplates';

const tryToFetchInvoiceTemplates = (
    pageNumber: number,
    pageSize: number,
    filters?: any,
): Promise<AxiosResponse<PaginatedInvoiceTemplates>> => {
    const url = filters ?
        `${ORDERS_URL}/invoice-templates/all?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${ORDERS_URL}/invoice-templates/all?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.get<PaginatedInvoiceTemplates>(url);
};

const tryToFetchSingleInvoiceTemplate = (id: string): Promise<AxiosResponse> => {
    return ordersAxios.get(`${ORDERS_URL}/invoice-templates/${id}`);
};

const tryToFetchAllInvoiceTemplates = (): Promise<AxiosResponse> => {
    return ordersAxios.get(`${ORDERS_URL}/invoice-templates/all/templates`);
};

const tryToAddInvoiceTemplate = (data: IInvoiceTemplate): Promise<AxiosResponse> => {
    return ordersAxios.post(`${ORDERS_URL}/invoice-templates`, data);
};

const tryToDeleteInvoiceTemplate = (id: string): Promise<AxiosResponse> => {
    return ordersAxios.delete(`${ORDERS_URL}/invoice-templates/${id}`);
};

const tryToEditInvoiceTemplate = (id: string, payload: IInvoiceTemplate): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/invoice-templates/${id}/edit`, payload);
};

export const InvoiceTemplatesService = {
    tryToFetchInvoiceTemplates,
    tryToFetchSingleInvoiceTemplate,
    tryToFetchAllInvoiceTemplates,
    tryToAddInvoiceTemplate,
    tryToDeleteInvoiceTemplate,
    tryToEditInvoiceTemplate,
};
