import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import { IAssetFields } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { tryToDisableAssetExtendedField } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import FieldsModal from '../modals/FieldsModal';
import MoreDetailsModal from '../modals/MoreDetailsModal';
import AssetTemplateFieldsTable from '../tables/AssetTemplateFieldsTable';
import Toast from '../../../../shared/toast';
import Button from '../../../../shared/button';
import DeleteModal from '../../../../shared/delete-modal';
import EmptyContainer from '../../../../shared/empty-container';


const ExtendedFields = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IAssetFields[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>()
    const [fieldItemId, setFieldItemId] = useState<string | null>()
    const [showFieldModal, setShowFieldModal] = useState<boolean>(false)
    const [showDeleteFieldModal, setShowDeleteFieldModal] = useState<boolean>(false)
    const [pageAccess, setPageAccess] = useState<any>()
    const [showMoreDetailsModal, setShowMoreDetailsModal] = useState<{ show: boolean, values?: any }>()
    const rowsLoading = useAppSelector((state) => state.assetsTemplates?.assetTemplateFieldsAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.assetsTemplates);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Asset Catalogs')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Asset Catalog Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.assetTemplateDetails) {
            const rows =
                state.assetTemplateDetails && state.assetTemplateDetails.extendedFields && Array.isArray(state.assetTemplateDetails?.extendedFields)
                    ? state.assetTemplateDetails?.extendedFields?.filter((r) => r?.enabled).map((r) => ({ ...r, id: r.id }))
                    : [];
            setRows(rows);
        }
    }, [state.assetTemplateDetails]);

    const onCloseFieldModal = () => {
        setFieldItemId(null)
        setShowFieldModal(false)
    }

    const onAddNewAssetTemplateField = (id?: string) => {
        if (id) {
            setFieldItemId(id)
        }
        setShowFieldModal(true)
    }

    const onDisableAssetField = async () => {
        try {
            if (fieldItemId) {
                const data = {
                    assetId: id,
                    fieldId: fieldItemId
                }
                await dispatch(tryToDisableAssetExtendedField(data)).unwrap();
                setShowToast({ type: 'error', message: 'Field deleted successfully' });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setFieldItemId(null)
        setShowDeleteFieldModal(false)
    }

    const onDeleteAssetField = (id: string) => {
        setFieldItemId(id)
        setShowDeleteFieldModal(true)
    }

    const onSaveFieldChanges = async (type: string, message: string) => {
        setShowToast({ type, message });
    }

    const onCloseDeleteFieldModal = () => {
        setFieldItemId(null)
        setShowDeleteFieldModal(false)
    }

    const onClickSeeAllValues = (values?: any) => {
        setShowMoreDetailsModal({
            show: true,
            values: values
        })
        document.body.style.overflow = 'hidden';
    }

    const onCloseSeeValues = () => {
        setShowMoreDetailsModal(undefined)
        document.body.style.overflow = 'auto';
    }

    return (
        <div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New Extended Fields'}
                    dataQa={'add-new-extended-fields'}
                    className={'btn-main'}
                    onClickButton={onAddNewAssetTemplateField}
                />
            </div>
            <div className='my-5 relative'>
                {(rows?.length > 0 || rowsLoading) ?
                    <AssetTemplateFieldsTable
                        rows={rows}
                        type={'extended'}
                        assetId={id || ''}
                        rowsLoading={rowsLoading}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        onDeleteAssetField={onDeleteAssetField}
                        onEditAssetField={onAddNewAssetTemplateField}
                        onClickSeeAllValues={onClickSeeAllValues}
                    />
                    : <EmptyContainer text={'No field added yet'} showImage={true} />
                }
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            <FieldsModal
                openFieldsModal={showFieldModal}
                handleCloseFieldsModal={onCloseFieldModal}
                onSaveChanges={onSaveFieldChanges}
                assetTemplateId={id || ''}
                fieldItemId={fieldItemId}
                type={'extended'}
            />
            <DeleteModal
                openDeleteModal={showDeleteFieldModal}
                handleCloseDeleteModal={onCloseDeleteFieldModal}
                onApproveDeletion={onDisableAssetField}
            />
            {showMoreDetailsModal?.show &&
                <MoreDetailsModal
                    openModal={showMoreDetailsModal?.show}
                    values={showMoreDetailsModal?.values}
                    handleCloseModal={onCloseSeeValues}
                />
            }
        </div >
    )
}

export default ExtendedFields;