import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import Tabs from './tabs/Tabs';
import Questions from './tabs/Questions';

const tabs = [
    { name: 'Questions', element: <Questions /> }
]

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CashoutFlow = () => {
    const query = useQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<any>(tabs[0])
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Cashout Flow')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
        if (findChild) {
            const findActiveCategory = findChild?.actions?.[tabs[0]?.name] ? tabs[0] : tabs?.find((item: any) => findChild?.actions?.[item?.name])
            setActiveTab(findActiveCategory)
        }
    }, [accessControl])

    useLayoutEffect(() => {
        if (query?.get('type') && query?.get('type') !== activeTab?.name) {
            const findActiveTab = tabs?.find((item) => item?.name === query?.get('type'))
            setActiveTab(findActiveTab || undefined)
        }
    }, [query?.get('type')])

    const onClickTab = (value: any) => {
        setActiveTab(value)
        setSearchParams({})
    }

    useEffect(() => {
        searchParams.set('type', activeTab?.name)
        setSearchParams(searchParams)
    }, [activeTab])

    return (
        <div>
            <div>
                <p className='page-title'>Cashout Flow</p>
            </div>
            <Tabs
                activeTab={activeTab.name}
                onClickTab={onClickTab}
                tabs={tabs}
            />
            <div>
                {(!accessControl || pageAccess?.actions?.[activeTab?.name]) && activeTab?.element}
            </div>
        </div>
    )
}
export default CashoutFlow