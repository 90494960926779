import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    tryToFetchSinglePartner,
    tryToFetchPartnerAgents
} from '../../../store/brokers/admin/partners/partnersSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { IAgents } from '../../../interfaces/agents/IAgents';
import { IPaginationPayloadWithId } from '../../../interfaces/shared/IPaginationPayload';
import PartnerAgentsTable from './tables/PartnerAgentsTable';
import Loading from '../../../shared/Loading';
import EmptyContainer from '../../../shared/empty-container';
import WhiteContainer from '../../../shared/white-container';


const PartnerDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [paginationState, setPaginationState] = useState<IPaginationPayloadWithId>({
        id: id || '',
        pageNumber: 1,
        pageSize: 10,
    });
    const [rows, setRows] = useState<IAgents[]>([]);
    const [allMarkets, setAllMarkets] = useState<Array<any>>()
    const state = useAppSelector((state) => state.partners);
    const partner = useAppSelector((state) => state?.partners?.partnerDetail);
    const rowsLoading = useAppSelector((state) => state.partners.agentsAreLoading);
    const paginationData = useAppSelector((state) => state.partners.agents?.data?.page);
    const partnerLoading = useAppSelector((state) => state?.partners?.partnerIsLoading);
    const [getAllMarkets] = useGetAllMarketsMutation()

    const getMarketsData = async () => {
        const response = await getAllMarkets(null).unwrap()
        setAllMarkets(response || [])
    }

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSinglePartner(id));
            dispatch(tryToFetchPartnerAgents(paginationState))
            getMarketsData()
        }
    }, []);

    useEffect(() => {
        if (state.agents) {
            const rows =
                state.agents?.data?.elements && Array.isArray(state.agents?.data.elements)
                    ? state.agents?.data.elements.map((r) => ({ ...r || {}, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.agents]);

    const onGoBack = () => {
        navigate('/users?type=partners')
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Partner Details</p>
                </div>
            </div>
            {partnerLoading ?
                <div className='my-3 flex flex-row justify-center items-center'>
                    <Loading />
                </div>
                : <div>
                    <WhiteContainer type={'rounded'}>
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-1'>
                            <div>
                                <p className='mb-1'><b>Name:</b> {partner?.name}</p>
                                <p className='mb-1'><b>Username:</b> {partner?.username}</p>
                                <p className='mb-1'><b>Email:</b> {partner?.email}</p>
                                <p className='mb-1'><b>Is Enabled:</b> {partner?.enabled ? 'Yes' : 'No'}</p>
                                <p className='mb-1'><b>Priority</b> {partner?.priority || 0}</p>
                            </div>
                            <div>
                                <p className='mb-1'><b>Company Name:</b> {partner?.companyName}</p>
                                <p className='mb-1'><b>Country:</b> {partner?.country}</p>
                                <p className='mb-1'><b>Markets: </b>
                                    {partner?.markets && partner?.markets?.length > 0 && partner?.markets?.map((market, index) => {
                                        const findMarket = allMarkets && allMarkets?.length > 0 && allMarkets?.find((item) => item?._id === market?.id)
                                        if (findMarket) {
                                            return index > 0 ? `, ${findMarket?.label || findMarket?.name}` : findMarket?.label || findMarket?.name
                                        } else {
                                            return ''
                                        }
                                    })}
                                </p>
                                <p className='mb-1'><b>Categories:</b> {(partner?.sectors && partner?.sectors?.length > 0) ? partner?.sectors?.map((s: any, index: number) => { return index > 0 ? `, ${s?.displayName || s?.name}` : s?.displayName || s?.name }) : ''}</p>
                                <p className='mb-1'><b>Company Registration Number:</b> {partner?.companyRegistrationNumber}</p>
                                <p className='mb-1'><b>Phone Number:</b> {partner?.phoneNumber}</p>
                                <p className='mb-1'><b>Annual Turnover:</b> {partner?.annualGMV}</p>
                                {partner?.vatNumber && <p className='mb-1'><b>VAT Number:</b> {partner?.vatNumber}</p>}
                            </div>
                        </div>
                    </WhiteContainer>
                    <p className='mt-4 mb-2 font-medium pl-1'>Partner agents</p>
                    {(rows?.length > 0 || rowsLoading) ?
                        <PartnerAgentsTable
                            rows={rows}
                            rowsLoading={rowsLoading}
                            paginationData={paginationData}
                            setPaginationState={setPaginationState}
                        /> :
                        <EmptyContainer
                            showImage={false}
                            text={'No agents added yet.'}
                        />
                    }
                </div>
            }
        </div>
    )
}

export default PartnerDetails;