import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { IPricingEffects, Source } from '../../../../../interfaces/pricing-effects/IPricingEffects';
import {
    tryToFetchSinglePricingEffect,
    tryToEditPricingEffect,
    tryToAddPricingEffect
} from '../../../../../store/brokers/admin/pricing-effects/pricingEffectsSlice';
import Error from '../../../../../shared/error';
import Input from '../../../../../shared/input';
import Modal from '../../../../../shared/modal';
import Button from '../../../../../shared/button';
import Loading from '../../../../../shared/Loading';
import SelectCheckbox from '../../../../../shared/select-checkbox';


interface IPricingEffectsModal {
    markets?: any[];
    services?: any[];
    sectors?: any[];
    partners?: any[];
    openActionsModal: boolean;
    itemId?: string | null;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    markets: Array<string>;
    assetTemplates?: Array<{
        id: string;
        name: string;
    }>;
    sources?: Array<Source>;
    partners?: Array<string>;
    value: number;
};

const PricingEffectsModal = ({
    openActionsModal,
    itemId,
    markets,
    services,
    partners,
    sectors,
    handleCloseModal,
    onSaveChanges,
}: IPricingEffectsModal) => {
    const dispatch = useAppDispatch();
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const [selectedSector, setSelectedSector] = useState<any>();
    const [selectedService, setSelectedService] = useState<any>();
    const [selectedPartner, setSelectedPartner] = useState<any>();
    const [saveError, setSaveError] = useState<string>()
    const state = useAppSelector((state) => state.pricingEffects);
    const pricingEffectLoading = useAppSelector((state) => state.pricingEffects?.effectIsLoading);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        if (state.pricingEffect && itemId) {
            setValue('markets', itemId ? state?.pricingEffect?.markets || [] : []); 
            setValue('sources', itemId ? state?.pricingEffect?.sources || [] : []);
            if (itemId && state?.pricingEffect?.assetTemplates) setValue('assetTemplates', state?.pricingEffect?.assetTemplates || [])
            setValue('value', itemId ? state?.pricingEffect?.value || 0 : 0);
            setValue('partners', itemId ? state?.pricingEffect?.partners || [] : []);
            if (itemId) {
                const findMarkets = (markets && markets?.length > 0 && state?.pricingEffect?.markets) && markets?.filter((item) => state?.pricingEffect?.markets?.includes(item?.displayName))
                setSelectedMarket(findMarkets)
                const findassetTemplates = sectors && sectors?.length > 0 && sectors?.filter((item) => state?.pricingEffect?.assetTemplates?.find(asset => asset.id === item?._id))
                setSelectedSector(findassetTemplates)
                const findSources = services && services?.length > 0 && services?.filter((item) => state.pricingEffect?.sources?.includes(item?.name))
                setSelectedService(findSources)
                const findPartners = partners && partners?.length > 0 && partners?.filter((item) => state.pricingEffect?.partners?.includes(item?.username))
                setSelectedPartner(findPartners)
            }
        } else {
            reset();
            setSelectedMarket(undefined)
            setSelectedSector(undefined)
            setSelectedService(undefined)
            setSelectedPartner(undefined)
        }
    }, [state.pricingEffect, itemId, openActionsModal]);

    useEffect(() => {
        if (itemId) {
            dispatch(tryToFetchSinglePricingEffect(itemId));
        }
    }, [itemId]);

    const onCloseModal = () => {
        reset();
        setSelectedMarket(undefined)
        setSelectedService(undefined)
        setSelectedSector(undefined)
        setSaveError(undefined)
        handleCloseModal();
    };

    const onSubmit = async (data: FormValues) => {
        if ((!selectedSector || !selectedService) && !state.pricingEffect?.type) {
            setSaveError('Please make sure you selected category and sources to continue')
            return;
        }
        const payload: IPricingEffects = {
            ...data,
            value: Number(data?.value || 0)
        };
        try {
            if (itemId) {
                payload._id = itemId;
                if (state.pricingEffect?.type) {
                    payload.type = state.pricingEffect?.type
                }
                await dispatch(tryToEditPricingEffect(payload)).unwrap();
            } else {
                await dispatch(tryToAddPricingEffect(payload)).unwrap();
            }
            onSaveChanges(
                'success',
                `Pricing Adjustment successfully ${itemId ? 'changed' : 'added'}.`,
            );
            reset();
            onCloseModal();
        } catch (error) {
            setSaveError(`${error}`)
        }
    };

    const onChangeSelectedMarket = (e: any) => {
        setSelectedMarket(
            e || undefined,
        );
        setValue('markets', (e && e?.length > 0) ? e?.map((item: any) => item?.displayName) : [])
    };

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(
            e || undefined,
        );
        setValue('assetTemplates', (e && e?.length > 0) ? e?.map((item: any) => ({ id: item?.value, name: item?.name })) : [])
    };

    const onChangeSelectedService = (e: any) => {
        setSelectedService(e)
        setValue('sources', (e && e?.length > 0) ? e?.map((item: any) => item?.name) : [])
    }

    const onChangeSelectedPartner = (e: any) => {
        setSelectedPartner(e)
        setValue('partners', (e && e?.length > 0) ? e?.map((item: any) => item?.username) : [])
    }

    return (
        <Modal
            open={openActionsModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>Pricing Adjustment</p>
                {saveError && <Error text={saveError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(itemId && !pricingEffectLoading) || !itemId ? (
                        <div className='flex flex-col my-4'>
                            <SelectCheckbox
                                key={1}
                                name='Market'
                                dataQa={'market-selected'}
                                placeholder=' '
                                multiple={true}
                                containerStyle='mb-1'
                                error={errors?.markets?.message}
                                selectedOption={selectedMarket}
                                onChangeSelectedOption={onChangeSelectedMarket}
                                options={markets || []}
                            />
                            <SelectCheckbox
                                key={2}
                                name='Category'
                                placeholder=' '
                                multiple
                                disabled={!!itemId}
                                dataQa={'category-selected'}
                                containerStyle='mb-1'
                                error={errors?.assetTemplates?.message}
                                selectedOption={selectedSector}
                                onChangeSelectedOption={onChangeSelectedSector}
                                options={sectors || []}
                            />
                            <SelectCheckbox
                                key={2}
                                name='Source'
                                placeholder=' '
                                multiple
                                disabled={!!itemId}
                                dataQa={'sources-selected'}
                                containerStyle='mb-1'
                                error={errors?.sources?.message}
                                selectedOption={selectedService}
                                onChangeSelectedOption={onChangeSelectedService}
                                options={services || []}
                            />
                            <SelectCheckbox
                                key={2}
                                name='Broker'
                                placeholder=' '
                                multiple
                                disabled={!!itemId}
                                dataQa={'brokers-selected'}
                                containerStyle='mb-1'
                                error={errors?.partners?.message}
                                selectedOption={selectedPartner}
                                onChangeSelectedOption={onChangeSelectedPartner}
                                options={partners || []}
                            />
                            <Input
                                label='Pricing Adjustment Value'
                                type='text'
                                dataQa={'pricing-adjustment-value'}
                                register={register('value', {
                                    required: {
                                        message: 'Adjustment Value is required',
                                        value: true,
                                    },
                                    pattern: {
                                        value: /^-?[0-9]\d*\.?\d*$/,
                                        message: 'Only numbers are accepted.'
                                    },
                                    validate: (value: number) => (Number(value) >= 0) || 'Pricing Adjustments Value should be a positive number'
                                })}
                                error={errors.value?.message}
                            />
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    dataQa={'submit'}
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default PricingEffectsModal;
