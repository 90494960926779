import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToDeliverOrdersMultiple } from '../../../../store/brokers/partners/orders/ordersSlice';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Table from '../../../../shared/table';


interface IAcceptPackageModal {
    openModal: boolean;
    multipleCashout?: boolean;
    handleCloseModal: () => void;
    onSubmitSuccessfully: (type: string, message: string, item?: any) => void;
}

const AcceptPackageModal = ({
    openModal,
    multipleCashout,
    handleCloseModal,
    onSubmitSuccessfully,
}: IAcceptPackageModal) => {
    const navigation = useNavigate()
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>()
    const [loadingAccept, setLoadingAccept] = useState<boolean>(false)
    const [currentTrackingNumber, setCurrentTrackingNumber] = useState<string>()
    const [trackingNumbers, setTrackingNumbers] = useState<any>([])
    const [editTrackingNumber, setEditTrackingNumber] = useState<string>()
    const [newEditTrackingNumber, setNewEditTrackingNumber] = useState<string>()
    const [packagesResponse, setPackagesResponse] = useState<any>({
        failedPackages: [],
        succeededPacakges: []
    })
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Multiple Cashouts')
        setPageAccess(findPage || undefined)
    }, [accessControl])

    const onCloseModal = () => {
        handleCloseModal();
        setSaveError(undefined)
        setLoadingAccept(false)
        setPackagesResponse({
            failedPackages: [],
            succeededPacakges: []
        })
        setCurrentTrackingNumber(undefined)
        setEditTrackingNumber(undefined)
        setNewEditTrackingNumber(undefined)
    }

    useEffect(() => {
        setTrackingNumbers([])
    }, [])

    const onChangeCurrentTrackingNumber = (value: any, type?: string) => {
        const findIfCurrentExist = trackingNumbers && trackingNumbers?.length > 0 && trackingNumbers?.find((item: any) => item?.type === 'current')
        if (findIfCurrentExist && value && value !== '') {
            const formatLatestItem = (trackingNumbers && trackingNumbers?.length > 0) && trackingNumbers?.map((item: any) => {
                if (item?.type === 'current') {
                    return {
                        trackingNumber: value,
                        type: 'current'
                    }
                }
                else {
                    return item
                }
            })
            setTrackingNumbers(formatLatestItem)
        } else if (!value || value === '') {
            const filterItems = (trackingNumbers && trackingNumbers?.length > 0) && trackingNumbers?.filter((item: any) => item?.type !== 'current')
            setTrackingNumbers(filterItems || [])
        } else {
            setTrackingNumbers([
                ...trackingNumbers || [],
                {
                    trackingNumber: value,
                    type: 'current'
                }
            ])
        }
        setCurrentTrackingNumber(value);
    }

    const onAddTrackingNumberOnList = async () => {
        if (currentTrackingNumber) {
            const formatLatestItem = (trackingNumbers && trackingNumbers?.length > 0) && trackingNumbers?.map((item: any) => {
                if (item?.type === 'current' && currentTrackingNumber === item?.trackingNumber) {
                    return {
                        ...item,
                        type: 'saved'
                    }
                }
                else {
                    return item
                }
            })
            setTrackingNumbers(formatLatestItem)
            setCurrentTrackingNumber(undefined)
            setNewEditTrackingNumber(undefined)
            setEditTrackingNumber(undefined)
        }
    }

    const onEnterMainTrackingInput = async (e: any) => {
        if (e?.key === 'Enter' && currentTrackingNumber) {
            const formatLatestItem = (trackingNumbers && trackingNumbers?.length > 0) && trackingNumbers?.map((item: any) => {
                if (item?.type === 'current') {
                    return {
                        ...item,
                        type: 'saved'
                    }
                }
                else {
                    return item
                }
            })
            setTrackingNumbers(formatLatestItem)
            setCurrentTrackingNumber(undefined)
            setNewEditTrackingNumber(undefined)
            setEditTrackingNumber(undefined)
        }
    }

    const onAcceptPackages = async () => {
        if (loadingAccept) {
            return;
        }
        setLoadingAccept(true)
        const data = {
            numbers: trackingNumbers?.length > 0 ? trackingNumbers?.map((item: any) => item?.trackingNumber) : []
        }
        setPackagesResponse({
            failedPackages: [],
            succeededPacakges: []
        })
        try {
            const response = await dispatch(tryToDeliverOrdersMultiple({ data: data })).unwrap()
            setCurrentTrackingNumber(undefined)
            setNewEditTrackingNumber(undefined)
            setEditTrackingNumber(undefined)
            onSubmitSuccessfully('success', `Order Shipment was changed to delivered successfully.`, 'ok')
            if (response) {
                setSaveError(undefined)
                setCurrentTrackingNumber(undefined)
                setEditTrackingNumber(undefined)
                setNewEditTrackingNumber(undefined)
                if (!response?.data?.failedPackages || response?.data?.failedPackages?.length === 0) {
                    onCloseModal()
                    setTrackingNumbers([])
                } else {
                    setPackagesResponse({
                        failedPackages: response.data.failedPackages,
                        succeededPackages: response.data.succeededPackages
                    })
                }
                setLoadingAccept(false)
            }
            // onCloseModal()
        } catch (err: any) {
            setSaveError(`${err?.response?.data?.message}`)
            setLoadingAccept(false)
            setPackagesResponse({
                failedPackages: err?.response?.data?.data?.failedPackages || [],
                succeededPackages: err?.response?.data?.data?.succeededPackages || []
            })
        }
    }

    const onRemoveTrackingNumber = (index: any) => {
        const newTrackingNumbers = (trackingNumbers && trackingNumbers?.length > 0) && trackingNumbers?.filter((item: any, idx: any) => idx !== index)
        setTrackingNumbers(newTrackingNumbers || [])
    }

    const onDoubleClickTrackingNumber = (trackingNumber: string) => {
        setEditTrackingNumber(trackingNumber)
        setNewEditTrackingNumber(trackingNumber)
    }

    const onEditTrackingNumber = (value: any, type?: string) => {
        setNewEditTrackingNumber(value)
    }

    const onEnterTrackingInput = async (e: any) => {
        e.stopPropagation()
        if (e?.key === 'Enter') {
            const formatTrackingNumbers = (trackingNumbers && trackingNumbers?.length > 0) && trackingNumbers?.map((item: any) => {
                return item?.trackingNumber === editTrackingNumber ? newEditTrackingNumber : item
            })
            setTrackingNumbers(formatTrackingNumbers || [])
            setNewEditTrackingNumber(undefined)
            setEditTrackingNumber(undefined)
        }
    }

    const onRedirectToMultiple = () => {
        navigation('/multiple-cashouts?type=Received')
        onCloseModal()
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            dataQa={'close-button'}
            contentContainerStyle='min-w-[70vw]'
        >
            <div className={'p-2 min-w-[65vw] min-h-[350px]'}>
                <p className='mb-4 font-bold text-sm'>Received Package</p>
                <p className='font-semibold text-[15px] mb-5 mt-3'>Submit the Tracking Number or Order Number of the Package to move its Status to ”Received”</p>
                {saveError && <Error text={saveError} />}
                <div className='flex flex-row gap-x-3 items-center justify-center' >
                    <Input
                        label='Tracking Number'
                        dataQa={'tracking-number'}
                        showValue={true}
                        inputValue={currentTrackingNumber}
                        disabled={(trackingNumbers.length >= 20 && !currentTrackingNumber)}
                        onChangeInput={onChangeCurrentTrackingNumber}
                        onHandleKeyDown={onEnterMainTrackingInput}
                        containerStyle='w-auto min-w-[400px] mb-3'
                    />
                    {!(trackingNumbers && trackingNumbers?.length >= 20 && !currentTrackingNumber) &&
                        <Button
                            label={'+'}
                            dataQa={'add-button'}
                            className={`btn-main rounded`}
                            onClickButton={onAddTrackingNumberOnList}
                        />
                    }
                </div>
                <div className='justify-start my-3'>
                    {trackingNumbers && trackingNumbers?.length > 0
                        &&
                        <Table
                            hidePagination={true}
                            columns={multipleCashout || !pageAccess ? ['Tracking Number', 'Shipping Labels', 'Actions', 'Message'] : ['Tracking Number', 'Shipping Labels', 'Is Multiple Cashout', 'Actions', 'Message', 'Multiple Page Redirect']}>
                            {trackingNumbers.map((obj: any, idx: number) => {
                                const findIfSucceeded = packagesResponse.succeededPackages && packagesResponse.succeededPackages?.length > 0 && packagesResponse.succeededPackages?.find((item: any) => item?.number === obj?.trackingNumber)
                                const findIfFailed = packagesResponse.failedPackages && packagesResponse.failedPackages?.length > 0 && packagesResponse.failedPackages?.find((item: any) => item?.number === obj?.trackingNumber)
                                return (
                                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                        <td className='px-6 py-2' onClick={() => setEditTrackingNumber(undefined)}>
                                            {editTrackingNumber !== obj.trackingNumber ?
                                                <div className='cursor-pointer' onDoubleClick={() => onDoubleClickTrackingNumber(obj.trackingNumber || '')}>
                                                    {obj.trackingNumber}
                                                </div>
                                                :
                                                <div onClick={(e) => e.stopPropagation()}>
                                                    <Input
                                                        placeholder='Tracking Number'
                                                        dataQa={'tracking-number'}
                                                        showValue={true}
                                                        inputValue={newEditTrackingNumber}
                                                        onChangeInput={onEditTrackingNumber}
                                                        onHandleKeyDown={onEnterTrackingInput}
                                                    />
                                                </div>
                                            }
                                        </td>
                                        <td className='px-6 py-4'>
                                            {findIfFailed && findIfFailed?.shippingLabels?.map((shippingLabel: any, idxKey: number) => (
                                                <a key={idxKey} href={shippingLabel} rel='noreferrer' className='underline underline-text-[#F7A21E] hover:text-[#F7A21E]' target='_blank'>
                                                    {shippingLabel}
                                                </a>
                                            )
                                            )}
                                        </td>
                                        {(!multipleCashout && pageAccess) &&
                                            <td className='px-6 py-2'>
                                                {findIfSucceeded ? findIfSucceeded?.multiple ? 'Yes' : 'No' : ''}
                                            </td>
                                        }
                                        <td className='px-6 py-2'>
                                            <div data-qa={'trash-icon'} className='cursor-pointer' onClick={() => onRemoveTrackingNumber(idx)}>
                                                <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] ml-4 hover:w-[17px] object-contain'} />
                                            </div>
                                        </td>
                                        <td className='px-6 py-2'>
                                            {findIfSucceeded ? <p className='text-[#198754]'>Success!</p> : findIfFailed ? <Error text={findIfFailed.errorMessage} /> : ''}
                                        </td>
                                        {(!multipleCashout && pageAccess) &&
                                            <td className='px-4 py-2'>
                                                <Button
                                                    label={'Go To Multiple'}
                                                    className='btn-main'
                                                    onClickButton={onRedirectToMultiple}
                                                />
                                            </td>
                                        }
                                    </tr>
                                );
                            })}
                        </Table>
                    }
                </div>
                <div className='mt-7 flex flex-row justify-center'>
                    <Button
                        label='Save'
                        dataQa={'save-button'}
                        className={`${loadingAccept ? 'btn-main-disable' : 'btn-main'} rounded min-w-[200px]`}
                        onClickButton={() => !loadingAccept && onAcceptPackages()}
                    />
                </div>
            </div>
        </Modal >
    )
}

export default AcceptPackageModal;