import React from 'react';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';

const tableColumns = ['Display Name', 'Name', 'Asset Data', 'Asset Delete Data']

interface IAssetsTable {
    rows: IAssetTemplate[];
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    rowsLoading: boolean;
    onSeeAssetDetails: (id: string) => void;
    onRequestDeleteData: (id: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const AssetsTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    rowsLoading,
    setPaginationState,
    onSeeAssetDetails,
    onRequestDeleteData
}: IAssetsTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}>
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.displayName}</td>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>
                                    <Button
                                        label={'See Data'}
                                        className={row?.enabled && (!accessControl || actions?.['See Catalog Data']) ? 'btn-filters' : 'btn-noactive-filters'}
                                        onClickButton={() => ((!accessControl || actions?.['See Catalog Data']) && row?._id) && onSeeAssetDetails(row?._id)}
                                        dataQa={'assets-see-data'}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <Button
                                        label={'Delete Data'}
                                        className={row?.enabled && (!accessControl || actions?.['Delete Catalog Data']) ? 'btn-primary-rounded !shadow-none' : 'btn-primary-rounded-disable'}
                                        onClickButton={() => ((!accessControl || actions?.['Delete Catalog Data']) && row?._id) && onRequestDeleteData(row?._id)}
                                        dataQa={'assets-delete-data'}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default AssetsTable;