import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';


interface IChangeStatusModal {
    openStatusModal: boolean;
    handleCloseStatusModal: () => void;
    onChangeStatus: (type: string) => void;
}

const ChangeStatusModal = ({
    openStatusModal,
    handleCloseStatusModal,
    onChangeStatus
}: IChangeStatusModal) => {
    return (
        <Modal
            open={openStatusModal}
            onClose={handleCloseStatusModal}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Change Status</p>
                </div>
                <p className='my-2'>By clicking Accept or Reject you can change the status of pricing?</p>
                <div className='flex flex-row item-center justify-end mt-7 mb-3'>
                    <Button
                        label='Accept'
                        dataQa={'accept'}
                        className='btn-primary mr-2 min-w-[140px]'
                        onClickButton={() => onChangeStatus('accepted')}
                    />
                    <Button
                        label='Reject'
                        dataQa={'reject'}
                        className='btn-error min-w-[140px]'
                        onClickButton={() => onChangeStatus('rejected')}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ChangeStatusModal;