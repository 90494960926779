import { AxiosResponse } from 'axios';
import { ASSETS_API_URL } from '../../../../config';
import assetsAxios from '../../../../utils/axios/assets.axios';
import { PaginatedQuestions } from '../../../../interfaces/questions/paginatedStore.type';
import { IQuestion, IQuestionService } from '../../../../interfaces/questions/IQuestions';

const tryToFetchQuestions = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedQuestions>> => {
    const url = `${ASSETS_API_URL}/questions?page=${pageNumber}&size=${pageSize}`
    return assetsAxios.put<PaginatedQuestions>(url, data);
};

const tryToFetchSingleQuestion = (questionId: string): Promise<AxiosResponse> => {
    return assetsAxios.get(`${ASSETS_API_URL}/questions/${questionId}`);
};

const tryToAddQuestion = (data: IQuestion): Promise<AxiosResponse> => {
    return assetsAxios.post(`${ASSETS_API_URL}/questions`, data);
};

const tryToDeleteQuestion = (questionId: string): Promise<AxiosResponse> => {
    return assetsAxios.delete(`${ASSETS_API_URL}/questions/${questionId}`);
};

const tryToEnableDisableQuestion = (questionId: string, status: boolean): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/questions/${questionId}/${status}`);
};

const tryToEnableDisableReviewQuestion = (questionId: string, status: boolean): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/questions/${questionId}/show-review/${status}`);
};

const tryToEditQuestion = (questionId: string, payload: IQuestion): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/questions/${questionId}`, payload);
};

const tryToAddQuestionService = (id: string, data: IQuestionService): Promise<AxiosResponse> => {
    return assetsAxios.post(`${ASSETS_API_URL}/questions/${id}/services`, data);
};

const tryToDeleteQuestionService = (questionId: string, serviceId: string): Promise<AxiosResponse> => {
    return assetsAxios.delete(`${ASSETS_API_URL}/questions/${questionId}/services/${serviceId}`);
};

const tryToEditQuestionService = (questionId: string, serviceId: string, payload: IQuestionService): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/questions/${questionId}/services/${serviceId}`, payload);
};

const tryToGetAllQuestions = (data?: {marketId?: string, categoryId?: string, service?: string}): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/questions/answers`, data);
};

const tryToGetAllLanguages = (): Promise<AxiosResponse> => {
    return assetsAxios.get(`${ASSETS_API_URL}/language`);
};

export const QuestionsService = {
    tryToFetchQuestions,
    tryToFetchSingleQuestion,
    tryToAddQuestion,
    tryToDeleteQuestion,
    tryToEditQuestion,
    tryToAddQuestionService,
    tryToDeleteQuestionService,
    tryToEditQuestionService,
    tryToGetAllQuestions,
    tryToGetAllLanguages,
    tryToEnableDisableQuestion,
    tryToEnableDisableReviewQuestion
};
