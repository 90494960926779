import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToAddLocationOnOrder } from '../../../../store/brokers/partners/orders/ordersSlice';
import {
    tryToFetchWarehouses,
    tryToGetWarehouseFields
} from '../../../../store/brokers/admin/warehouses/warehousesSlice';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Loading from '../../../../shared/Loading';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IInventoryModal {
    openModal: boolean;
    trackingNumber?: string;
    itemId: string;
    id: string;
    handleCloseModal: () => void;
    onSuccessfullyDelivered: () => void;
}

const InventoryModal = ({
    openModal,
    itemId,
    trackingNumber,
    handleCloseModal,
    onSuccessfullyDelivered,
}: IInventoryModal) => {
    const dispatch = useAppDispatch();
    const [deliveredError, setDeliveredError] = useState<string>()
    const [warehouses, setWarehouses] = useState<Array<any>>()
    const [warehouseSelected, setWarehouseSelected] = useState<string>()
    const [fields, setFields] = useState<any>()
    const [fieldsData, setFieldsData] = useState<any>()
    const [fieldsErrors, setFieldsErrors] = useState<any>()
    const [fieldsLoading, setFieldsLoading] = useState<boolean>(false)
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const warehouseLoading = useAppSelector((state) => state?.warehouses?.warehousesAreLoading)
    const activeMarket = useAppSelector((state) => state?.partners?.partnerActiveMarket)
    const stateWarehouses = useAppSelector((state) => state.warehouses);
    const stateFields = useAppSelector((state) => state?.warehouses)

    useEffect(() => {
        dispatch(tryToFetchWarehouses(null)).unwrap()
    }, [])

    useEffect(() => {
        const formatWarehouses = stateWarehouses.warehouses && stateWarehouses.warehouses?.length > 0 && stateWarehouses.warehouses?.map((warehouse: IWarehouse) => ({ ...warehouse, label: `${warehouse?.address?.street || ''}-${warehouse?.name}`, value: `${warehouse?.address?.street || ''}-${warehouse?.name}` }))
        const filterWarehouses = formatWarehouses && formatWarehouses?.length > 0 && activeMarket && formatWarehouses?.filter((warehouse: any) => warehouse?.marketIds && warehouse?.marketIds?.includes(activeMarket?.market?._id))
        setWarehouses(filterWarehouses || [])
    }, [stateWarehouses.warehouses, activeMarket])

    const onGettingFieldsData = async () => {
        if (warehouseSelected) {
            const findWarehouse = warehouses && warehouses?.length > 0 && warehouses.find((warehouse: any) => warehouse?.label === warehouseSelected)
            let formatData: any = {
                warehouseId: findWarehouse?._id || ''
            }
            if (fieldsData) {
                const fieldsDataKeys = fieldsData && Object.keys(fieldsData)
                const values = fieldsDataKeys && fieldsDataKeys?.map((field: any) => ({ [field]: fieldsData?.[field] }))
                formatData = {
                    ...formatData,
                    data: {
                        values: values
                    }
                }
            }
            await dispatch(tryToGetWarehouseFields(formatData))
            setFieldsLoading(false)
        }
    }

    useEffect(() => {
        if (stateFields.warehouseFields) {
            setFields(stateFields?.warehouseFields)
        }
    }, [stateFields?.warehouseFields])

    useEffect(() => {
        if (warehouseSelected) {
            onGettingFieldsData()
        } else {
            setFields(undefined)
        }
    }, [warehouseSelected])

    const onCloseModal = () => {
        handleCloseModal();
        setDeliveredError(undefined)
        setFields(undefined)
        setWarehouseSelected(undefined)
        setFieldsData(undefined)
        setFieldsErrors(undefined)
        setFieldsLoading(false)
    }

    const onSubmit = async () => {
        setLoadingSubmit(true)
        const fieldsDataKeys = fields && Object.keys(fields)
        let itemErrors: any
        if (fieldsDataKeys && fieldsDataKeys?.length > 0) {
            fieldsDataKeys?.map((item: any) => {
                if (!fieldsData?.[item]) {
                    itemErrors = {
                        ...(itemErrors || {}),
                        [item]: `${item} is required`
                    }
                }
                return item
            })

        } else {
            setDeliveredError('Please fill all the fields to continue')
            setLoadingSubmit(false)
            return;
        }
        if (itemErrors) {
            setFieldsErrors(itemErrors)
            setLoadingSubmit(false)
            return;
        }
        try {
            const findWarehouse = warehouseSelected && warehouses && warehouses?.length > 0 && warehouses?.find((warehouse: any) => warehouse?.label === warehouseSelected)
            const formatData = {
                trackingNumber: trackingNumber || '',
                data: {
                    itemId: itemId || '',
                    warehouse: {
                        id: findWarehouse?._id,
                        name: warehouseSelected
                    },
                    locations: fieldsData,
                    marketId: activeMarket?.market?._id || ''
                }
            }
            await dispatch(tryToAddLocationOnOrder(formatData)).unwrap()
            onSuccessfullyDelivered()
            onCloseModal()
        } catch (err) {
            setDeliveredError(`${err}`)
            setLoadingSubmit(false)
        }
    }

    const onClickWarehouse = (value: any, type?: string) => {
        setWarehouseSelected(value?.label || undefined)
        setFieldsLoading(true)
    }

    useEffect(() => {
        onGettingFieldsData()
    }, [fieldsData])

    const onChangeSelectedValue = (value: any, type?: string) => {
        if (type) {
            setFieldsData({
                ...(fieldsData || {}),
                [type]: value?.value || undefined,
            });
        }
    };

    const onClickClearFields = () => {
        setFieldsData(undefined)
    }

    const findFieldKeys = fields && Object.keys(fields)
    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle={'!min-w-[70vw]'}>
            <div className={'p-2 min-w-[600px] min-h-[350px]'}>
                <p className='mb-4 font-semibold text-sm'>Inventory Form</p>
                <p className='font-bold text-md mb-5'>Use this form to change package inventory.</p>
                {deliveredError && <Error text={deliveredError} />}
                <div>
                    {warehouseLoading ?
                        <div className='my-6 flex flex-row justify-center items-center min-w-[100%]'>
                            <Loading />
                        </div>
                        : <div className='min-h-[200px] flex flex-col justify-center items-center'>
                            <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Warehouses</p>
                            <div className='my-2 min-w-[60%]'>
                                <SelectCheckbox
                                    placeholder={warehouseSelected || 'Warehouse'}
                                    dataQa={'warehouse'}
                                    options={warehouses || []}
                                    selectStyle={{ backgroundColor: 'transparent', borderRadius: '4px', minWidth: 200 }}
                                    onChangeSelectedOption={onClickWarehouse}
                                    uniqueName={'warehouse'}
                                />
                            </div>
                            {fieldsLoading ?
                                <div className='my-6 flex flex-row justify-center items-center min-w-[100%]'>
                                    <Loading />
                                </div>
                                : <div className='grid grid-cols-3 gap-4 my-5 min-w-[100%]'>
                                    {fields && findFieldKeys && findFieldKeys?.length > 0 && findFieldKeys?.map((field: any, index: number) => {
                                        const values =
                                            fields?.[field] &&
                                            fields?.[field]?.length > 0 &&
                                            fields?.[field]?.map((value: string) => {
                                                return { value: value, label: value };
                                            });
                                        const findError = fieldsErrors && fieldsErrors?.[field]
                                        return (
                                            <SelectCheckbox
                                                key={index}
                                                name={field && `${field[0].toUpperCase()}${field.slice(1).toLowerCase()}`}
                                                placeholder={(fieldsData?.[field] || '')}
                                                options={values || []}
                                                selectedOption={fieldsData?.[field]}
                                                selectStyle={{ backgroundColor: 'transparent', borderRadius: '4px' }}
                                                onChangeSelectedOption={onChangeSelectedValue}
                                                uniqueName={field || ''}
                                                error={findError}
                                                dataQa={'data-selected'}
                                            />
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    }
                    <div className='min-w-full flex flex-row justify-center my-3'>
                        {!fieldsLoading && fields && findFieldKeys && findFieldKeys?.length > 0 &&
                            <Button
                                className={'!py-0 btn-main !shadow-none mr-4 min-w-[130px]'}
                                label={'Clear fields'}
                                onClickButton={onClickClearFields}
                            />
                        }
                        <Button
                            label={'Save'}
                            className={`${loadingSubmit ? 'btn-main-disable' : 'btn-main'} min-w-[130px] !shadow-none ${warehouseSelected ? '' : 'opacity-50'}`}
                            disabled={!warehouseSelected}
                            onClickButton={() => warehouseSelected && !fieldsErrors && !loadingSubmit && onSubmit()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default InventoryModal;