import React from 'react';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface IReturnDetailsModal {
    openModal: boolean;
    returnTrackingNumber?: string;
    carrier?: string;
    handleCloseModal: () => void;
}

const ReturnDetailsModal = ({
    openModal,
    carrier,
    returnTrackingNumber,
    handleCloseModal
}: IReturnDetailsModal) => {
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            showInRight={true}
            contentContainerStyle={'min-w-[650px]'}
            dataQa={'close-button'}
        >
            <div className={'p-2 min-w-[570px]'}>
                <div className='flex flex-row items-center mb-2'>
                    <p className='mt-2 mb-1 !text-[18px] mr-10'>Return Details</p>
                </div>
                <div className='mt-2 mb-5 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                <div className='mt-6 mb-10 bg-[#f8f9fc] rounded p-4 py-8'>
                    <p className='mb-4 mt-2'>Postal Service Provider</p>
                    <div className='bg-white !rounded py-2 px-3'>
                        <p>{carrier}</p>
                    </div>
                </div>
                <div className='mt-6 mb-10 bg-[#f8f9fc] rounded p-4 py-8'>
                    <p className='mb-4 mt-2'>Return Tracking Number</p>
                    <div className='bg-white !rounded py-2 px-3'>
                        <p>{returnTrackingNumber}</p>
                    </div>
                </div>
                <div className='my-5 flex flex-row justify-center'>
                    <Button
                        label={'Close'}
                        className='btn-main'
                        onClickButton={handleCloseModal}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ReturnDetailsModal;