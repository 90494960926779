import React, { useState, useEffect } from 'react';
import { formatOrdersMissingStatus } from '../../../../utils/constants/orders-items-consts';


interface IOrderNotFound {
    selectedMarket?: string;
    filterSelected?: string;
    historyFilterStatus?: string;
    recentFilterStatus?: string;
    selectedSector?: string;
}

const OrderNotFound = ({
    selectedMarket,
    filterSelected,
    historyFilterStatus,
    recentFilterStatus,
    selectedSector
}: IOrderNotFound) => {
    const [status, setStatus] = useState<string>()

    useEffect(() => {
        formatOrdersMissingStatus(setStatus, filterSelected, historyFilterStatus, recentFilterStatus)
    }, [])

    return (
        <div className='my-10 min-w-[100%] flex flex-col items-center'>
            <img src={'/assets/shared/no-content.svg'} className={'w-[150px] mb-10 mt-4 object-contain'} />
            <p className='text-[#a4a4a4] text-center font-md'>
                Orders not found for
                {selectedMarket ? ` market ${selectedMarket}${status ? ', ' : ' and '}` : ''}
                {selectedSector ? `category ${selectedSector}` : ''}
                {status ? ` and status ${status}` : ''}.</p>
        </div>
    )
}

export default OrderNotFound