import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { usePricingTemplateMutation } from '../../../../store/brokers/partners/download-templates/downloadTemplatesApi';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IDownloadSampleModal {
    openDownloadModal: boolean;
    sectors: Array<any>;
    handleCloseDownloadModal: () => void;
}

const DownloadSampleModal = ({
    openDownloadModal,
    sectors,
    handleCloseDownloadModal,
}: IDownloadSampleModal) => {
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [selectedSector, setSelectedSector] = useState<any>()
    const [downloadError, setDownloadError] = useState<string>()
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [pricingTemplateData, setPricingTemplateData] = useState<Array<any>>()
    const [partnerPricingTemplateMutation] = usePricingTemplateMutation()

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(e)
    }

    const onCloseModal = () => {
        handleCloseDownloadModal();
        setSelectedSector(undefined)
        setDownloadError(undefined)
        setLoadingDownload(false)
        setPricingTemplateData(undefined)
    }

    const onDownloadTemplate = async () => {
        if (loadingDownload) {
            return;
        }
        setLoadingDownload(true)
        if (!selectedSector) {
            setDownloadError('Please select a sector first to continue with download.')
            setLoadingDownload(false)
            return;
        }
        try {
            const response = await partnerPricingTemplateMutation({ assetTemplateId: selectedSector?.value || '' }).unwrap()
            const format = response && response?.length > 0 && response?.map((item: any, index: number) => {
                if (index !== 0) {
                    const formatArrayItem: any = item && item?.length > 0 && item?.map((element: any, idx: number) => {
                        if (idx + 1 !== item?.length && /^\d+$/.test(element)) {
                            return element + '-Wing'
                        } else {
                            return element
                        }
                    })
                    return formatArrayItem
                } else {
                    return item
                }
            })
            setPricingTemplateData(format || [])
            setDownloadError(undefined)
        } catch (err: any) {
            setDownloadError(`${err?.response || err?.data?.message || err}`)
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (pricingTemplateData && loadingDownload) {
            csvLink.current.link.click()
            onCloseModal()
        }
        setLoadingDownload(false)
    }, [pricingTemplateData])

    return (
        <Modal
            open={openDownloadModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[350px] min-h-[300px]'}>
                <p className='mb-2 font-semibold text-sm'>Catalog</p>
                <p className='mb-4 text-sm'>Please select a category to download catalog.</p>
                {downloadError && <Error text={downloadError} />}
                <div className='flex flex-col items-center'>
                    <SelectCheckbox
                        name='Category'
                        dataQa={'category'}
                        placeholder=' '
                        containerStyle='mb-1 min-w-full'
                        selectedOption={selectedSector}
                        onChangeSelectedOption={onChangeSelectedSector}
                        options={sectors || []}
                    />
                    <Button
                        label={loadingDownload ? 'Loading data...' : 'Download Catalog'}
                        dataQa={loadingDownload ? 'loading-data...' : 'download-catalog'}
                        onClickButton={() => !loadingDownload && onDownloadTemplate()}
                        disabled={loadingDownload}
                        className={`${loadingDownload ? 'btn-main-disable' : 'btn-main'} mb-2 mt-9 !py-2 !shadow-none`}
                    />
                    <CSVLink
                        ref={csvLink}
                        data={pricingTemplateData && pricingTemplateData?.length > 0 ? pricingTemplateData?.filter((item, idx) => idx > 0) : []}
                        headers={pricingTemplateData?.[0] || []}
                        className={'none'}
                        target={'_blank'}
                        filename={`Pricing-${selectedSector?.name || 'catalog'}-sample.csv`}
                    >
                    </CSVLink>
                </div>
            </div>
        </Modal >
    )
}
export default DownloadSampleModal;