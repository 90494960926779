import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import NotShippedTab from './tabs/NotShippedTab';
import ReshippedTab from './tabs/ReshippedTab';
import ReceivedTab from './tabs/ReceivedTab';
import ReshipTab from './tabs/ReshipTab';
import Button from '../../../shared/button';


interface ICashoutTabs {
    name: string;
    element?: React.ReactNode;
}

const cashoutTabs: ICashoutTabs[] = [
    { name: 'Multiple Cashouts', element: <NotShippedTab /> },
    { name: 'Received', element: <ReceivedTab /> },
    { name: 'To Reship', element: <ReshipTab /> },
    { name: 'Reshipped', element: <ReshippedTab/> },
];

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MultipleCashout = () => {
    const query = useQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageAccess, setPageAccess] = useState<any>()
    const [activeCategory, setActiveCategory] = useState<ICashoutTabs | undefined>(cashoutTabs[0]);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Multiple Cashouts')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
        if (findChild) {
            const findActiveCategory = findChild?.actions?.[cashoutTabs[0]?.name] ? cashoutTabs[0] : cashoutTabs?.find((item: any) => findChild?.actions?.[item?.name])
            setActiveCategory(findActiveCategory || undefined)
        }
    }, [accessControl])

    const onChangeActiveCategory = (item: ICashoutTabs) => {
        setActiveCategory(item);
        searchParams.set('type', item?.name)
        setSearchParams(searchParams)
    };

    useLayoutEffect(() => {
        if (query?.get('type') && query?.get('type') !== activeCategory?.name) {
            const findActiveTab = cashoutTabs?.find((item) => item?.name === query?.get('type'))
            setActiveCategory(findActiveTab || undefined)
        }
    }, [query?.get('type')])

    return (
        <div>
            <div className='mb-5'>
                <p className='page-title'>Multiple Cashout Items</p>
            </div>
            <div className='flex flex-row items-center my-5'>
                {cashoutTabs?.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name]))?.map((category, index) => {
                    return (
                        <Button
                            key={index}
                            label={<span className={`${activeCategory?.name === category?.name ? 'text-[#F7A21E]' : 'text-[#c2c6cc]'} text-[15px]`}>{category?.name}</span>}
                            dataQa={`${(category?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                            className={activeCategory?.name === category?.name ? 'btn-categories ml-2' : 'btn-noactive-categories ml-2'}
                            onClickButton={() => onChangeActiveCategory(category)}
                        />
                    );
                })}
            </div>
            {(!accessControl || pageAccess?.actions?.[activeCategory?.name || '']) ? activeCategory?.element : <></>}
        </div>
    )
}

export default MultipleCashout;