import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useAppDispatch,
    useAppSelector
} from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IInvoicesDetails } from '../../../interfaces/invoices/IInvoicesDetails';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { tryToFetchFilteredBills } from '../../../store/brokers/partners/invoices/invoicesSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import InvoiceDetailsTable from './tables/InvoiceDetailsTable';
import Input from '../../../shared/input';


const InvoiceDetails = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [rows, setRows] = useState<IInvoicesDetails[]>([]);
    const [search, setSearch] = useState<string>()
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const paginationData = useAppSelector((state) => state.invoices.bills?.data?.page);
    const state = useAppSelector((state) => state.invoices);
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        if (state.bills) {
            const rows =
                state.bills?.data?.elements && Array.isArray(state.bills?.data.elements)
                    ? state.bills?.data.elements.map((r) => ({ ...r }))
                    : [];
            setRows(rows);
        }
    }, [state.bills]);

    const getBillsData = () => {
        let settingsFilters: any = {
            ...paginationState || {},
            data: {
                invoiceNumberId: id
            }
        }
        if (search) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    search: search
                }
            }
        }
        dispatch(tryToFetchFilteredBills(settingsFilters));
    }

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        getBillsData()
    }, [paginationState]);

    const getMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label }))
        setMarkets(formatMarkets || [])
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    useEffect(() => {
        getMarketData()
    }, [])

    const onGoBack = () => {
        navigate(-1)
    }

    const onSeeOrderDetails = (orderId: string, itemId: string) => {
        navigate(`/order-items/${orderId}/${itemId}`)
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Invoice Details</p>
                </div>
            </div>
            <div className='mt-7 mb-6'>
                <div className={`min-w-[100px]`}>
                    <Input
                        placeholder={'Filter Table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                <InvoiceDetailsTable
                    rows={rows}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                    markets={markets}
                    onSeeOrderDetails={onSeeOrderDetails}
                />
            </div>
        </div>
    )
}

export default InvoiceDetails;