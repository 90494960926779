import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { TICKETS_STATUS } from '../../../utils/constants/tickets-status';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import { ITicketResponse } from '../../../interfaces/customer-care/ITicket';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchFilteredTickets } from '../../../store/brokers/partners/customer-care/customerCareSlice';
import TicketsTable from './tables/TicketsTable';
import TicketFilters from './details/TicketFilters';
import SearchWithButton from '../../../shared/search-with-button';
import Button from '../../../shared/button';

const customerCareCategories = [
    { name: 'Open Tickets', status: true },
    { name: 'Closed Tickets', status: false }
]

const CustomerCare = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [activeCategory, setActiveCategory] = useState<{ name: string, status: boolean }>(customerCareCategories?.[0]);
    const [filtersValues, setFiltersValues] = useState<any>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [markets, setMarkets] = useState<IAllMarkets[]>();
    const [selectedStatus, setSelectedStatus] = useState<any>(TICKETS_STATUS?.[0])
    const [rows, setRows] = useState<ITicketResponse[]>([]);
    const [search, setSearch] = useState<string | null>();
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket)
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state?.cs?.tickets?.data?.page);
    const ticketsLoading = useAppSelector((state) => state.cs.ticketsAreLoading)
    const state = useAppSelector((state) => state.cs);
    const [getAllMarkets] = useGetAllMarketsMutation()

    const getMarketsData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        setMarkets(marketResponse || [])
    }

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
        searchParams.set('page', '1')
        searchParams.set('size', `${paginationState?.pageSize || 10}`)
        setSearchParams(searchParams)
    }

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Customer Care')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        getMarketsData()
    }, [])

    useEffect(() => {
        if (activeMarketState && (JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket))) {
            setActiveMarket(activeMarketState || undefined)
            onResetPaginationState()
        }
    }, [activeMarketState])

    useEffect(() => {
        const paginationStateFormat = paginationState
        if (searchParams?.get('page') && Number(searchParams?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(searchParams?.get('page') || 1)
        }
        if (searchParams?.get('size')) {
            paginationStateFormat.pageSize = Number(searchParams.get('size') || 10)
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat)
        }
        if (searchParams.get('search')) {
            setSearch(searchParams.get('search') || undefined)
        }
        if (searchParams.get('status') && searchParams?.get('status') !== selectedStatus?.value) {
            const findStatus = TICKETS_STATUS?.find((item: any) => item?.value === searchParams.get('status'))
            setSelectedStatus(findStatus || TICKETS_STATUS?.[0])
        }
        if (searchParams.get('category')) {
            switch (searchParams.get('category')) {
                case 'open':
                case 'Open Tickets': {
                    const findOpenCategory = customerCareCategories?.find((item) => item?.name === 'Open Tickets')
                    setActiveCategory(findOpenCategory || customerCareCategories?.[0])
                    break;
                }
                case 'closed':
                case 'Closed Tickets': {
                    const findClosedCategory = customerCareCategories?.find((item) => item?.name === 'Closed Tickets')
                    setActiveCategory(findClosedCategory || customerCareCategories?.[0])
                    break;
                }
            }
        }
        const filtersValuesFormat = filtersValues || {}
        if (searchParams.get('orderNumber')) {
            filtersValuesFormat.orderId = searchParams.get('orderNumber')
        }
        if (searchParams.get('customer')) {
            filtersValuesFormat.name = searchParams.get('customer')
        }
        if (searchParams.get('minDate')) {
            filtersValuesFormat.minDateCreated = searchParams.get('minDate')
        }
        if (searchParams.get('maxDate')) {
            filtersValuesFormat.maxDateCreated = searchParams.get('maxDate')
        }
        if (filtersValuesFormat !== filtersValues) {
            setFiltersValues(filtersValuesFormat)
        }
    }, [
        searchParams.get('page'),
        searchParams.get('status'),
        searchParams.get('size'),
        searchParams.get('search'),
        searchParams.get('category'),
        searchParams.get('orderNumber'),
        searchParams.get('customer'),
        searchParams.get('minDate'),
        searchParams.get('maxDate')
    ])

    const onSetFiltersInUrlAsQuery = () => {
        if (filtersValues?.name) searchParams.set('customer', filtersValues?.name)
        else searchParams.delete('customer')

        if (filtersValues?.orderId) searchParams.set('orderNumber', `${filtersValues?.orderId}`)
        else searchParams.delete('orderNumber')

        if (filtersValues?.minDateCreated) searchParams.set('minDate', `${filtersValues?.minDateCreated}`)
        else searchParams.delete('minDate')

        if (filtersValues?.maxDateCreated) searchParams.set('maxDate', `${filtersValues?.maxDateCreated}`)
        else searchParams.delete('maxDate')
        setSearchParams(searchParams)
    }

    const onDeleteUrlFilterQuery = () => {
        searchParams.delete('customer')
        searchParams.delete('orderNumber')
        searchParams.delete('minDate')
        searchParams.delete('maxDate')
        setSearchParams(searchParams)
    }

    const onChangeActiveCategory = (data: { name: string, status: boolean }) => {
        setActiveCategory(data)
        setFiltersValues(undefined)
        setSearch(undefined)
        searchParams.set('category', data?.name)
        searchParams.delete('search')
        setSearchParams(searchParams)
        onResetPaginationState()
        onDeleteUrlFilterQuery()
    }

    const onChangeStatusSelected = (value: any, type?: string) => {
        setSelectedStatus(value)
        searchParams.set('status', value?.value)
        setSearchParams(searchParams)
        onResetPaginationState()
    }

    useEffect(() => {
        if (state.tickets) {
            const rows =
                state.tickets?.data?.elements && Array.isArray(state.tickets?.data.elements)
                    ? state.tickets?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.tickets]);

    const onGetTicketData = async () => {
        if (activeCategory) {
            const filters = filtersValues && Object.keys(filtersValues)
            let formatFilters
            const filtersValuesFormat = filters && filters?.length > 0 && filters?.filter((key: any) => key !== 'maxDateCreated' && key !== 'minDateCreated').map((item: any) => {
                return {
                    key: item,
                    value: Object.keys(filtersValues) ? filtersValues?.[item] || undefined : undefined
                }
            })
            searchParams.set('page', `${paginationState?.pageNumber}`)
            searchParams.set('size', `${paginationState?.pageSize}`)
            setSearchParams(searchParams)
            if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
                formatFilters = {
                    value: filtersValuesFormat?.filter((key: any) => key?.value)
                }
            }
            if (filtersValues?.maxDateCreated || filtersValues?.minDateCreated) {
                formatFilters = {
                    ...formatFilters || {},
                    range: [{
                        key: 'createdAt',
                        to: new Date(filtersValues?.maxDateCreated),
                        from: new Date(filtersValues?.minDateCreated)
                    }]
                }
            }
            let data: any = {
                opened: activeCategory?.status,
                readTickets: selectedStatus?.value === 'read'
            }
            if (activeMarket) {
                data.country = activeMarket?.market?.name
                data.marketId = activeMarket?.market?._id
            }
            if (formatFilters?.range || formatFilters?.value) {
                data = {
                    ...data,
                    filters: formatFilters
                }
            }
            const fetchData = {
                ...paginationState || {},
                filters: search,
                data
            }
            dispatch(tryToFetchFilteredTickets(fetchData));
            if (filtersValues) {
                onSetFiltersInUrlAsQuery()
            }
        }
    }

    useEffect(() => {
        if (activeMarket && paginationState && activeCategory) {
            onGetTicketData()
        }
    }, [activeCategory, activeMarket, paginationState, filtersValues])

    useEffect(() => {
        if (search !== undefined) {
            onGetTicketData()
        }
    }, [search])

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || null)
        if (value) searchParams.set('search', value)
        else searchParams.delete('search')
        searchParams.set('page', '1')
        setSearchParams(searchParams)
        onResetPaginationState()
    }

    const onChangeFilterValue = (value: string | null | undefined, type: string) => {
        setFiltersValues({
            ...filtersValues,
            [type]: value || undefined
        })
        onResetPaginationState()
    }

    const onClickClearAllFilters = () => {
        setFiltersValues(undefined)
        setSearch(undefined)
        searchParams.delete('search')
        searchParams.delete('orderNumber')
        searchParams.delete('customer')
        searchParams.delete('minDate')
        searchParams.delete('maxDate')
        setSearchParams(searchParams)
        onResetPaginationState()
    }

    const onSeeOrderDetails = (orderId: string, itemId: string) => {
        navigate(`/order-items/${orderId}/${itemId}`)
    }

    return (
        <div>
            <div className='flex flex-row justify-between items-center min-w-full'>
                <p className='page-title'>Customer Care</p>
            </div>
            <div className='flex flex-row items-center my-5'>
                {customerCareCategories?.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name]))?.map((category, index) => {
                    return (
                        <Button
                            key={index}
                            label={<span className={`${activeCategory?.name === category?.name ? 'text-[#F7A21E]' : 'text-[#c2c6cc]'} text-[15px]`}>{category?.name}</span>}
                            dataQa={`${(category?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                            className={activeCategory?.name === category?.name ? 'btn-categories ml-2 min-w-[80px]' : 'btn-noactive-categories ml-2 min-w-[80px]'}
                            onClickButton={() => onChangeActiveCategory(category)}
                        />
                    )
                })}
            </div>
            <div>
                <SearchWithButton
                    placeholder='Search'
                    buttonLabel='Clear all filters'
                    buttonStyle='btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end'
                    onChangeSearchValue={onChangeSearchValue}
                    search={search || undefined}
                    inputStyle={'bg-transparent'}
                    onClickButton={onClickClearAllFilters}
                    mainContainerStyle={'!bg-transparent !grid-cols-10'}
                />
                <div className='my-4'>
                    <TicketFilters
                        filters={filtersValues}
                        selectedStatus={selectedStatus}
                        onChangeStatusSelected={onChangeStatusSelected}
                        onChangeFilterValue={onChangeFilterValue}
                    />
                </div>
                <TicketsTable
                    rows={rows}
                    ticketsLoading={ticketsLoading}
                    paginationData={paginationData}
                    accessSeeDetails={!accessControl || pageAccess?.actions?.['See Order Details']}
                    setPaginationState={setPaginationState}
                    onSeeOrderDetails={onSeeOrderDetails}
                    markets={markets}
                />
            </div>
        </div>
    )
}

export default CustomerCare;