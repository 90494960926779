import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { CarriersService } from './carriersApi';

const initialState: any = {
    loading: false,
    isLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchCarriers = createAsyncThunk<any, any>(
    'carriers/tryToFetchCarriers',
    async () => {
        const result = await CarriersService.tryToFetchCarriers();
        return result?.data;
    },
);

export const tryToCreateReturnShippingNumber = createAsyncThunk<any, any>(
    'carriers/tryToCreateReturnShippingNumber',
    async (data, { rejectWithValue }) => {
        try {
            const result = await CarriersService.tryToCreateReturnShippingNumber(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchAllPartnerCarriersByMarket = createAsyncThunk<any, any>(
    'carriers/tryToFetchAllPartnerCarriersByMarket',
    async (data) => {
        const result = await CarriersService.tryToFetchAllPartnerCarriersByMarket(data);
        return result?.data;
    },
);


export const carriersSlice = createSlice({
    name: 'carriers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
    },
});

export default carriersSlice.reducer;