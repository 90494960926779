import React from 'react';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';
import Table from '../../../../shared/table';


interface INewRentalPricingTable {
    rows?: number;
    columns?: any;
    formValues?: any;
    templateFields?: any;
    errors?: any;
    filteredMultiFields?: any;
    loadingOptions?: any;
    loadingFields?: any;
    onAddNewRow: () => void;
    onDeleteItemRow: (type: string, index: number) => void;
    onChangeSelectedFieldValue: (e: any, type?: string, index?: any, item?: string) => void;
    onMenuCloseAfterSearchMulti: (type?: string, index?: any) => void;
    onChangeSearchFieldMultiple: (e: any, type?: string, index?: any) => void;
    onChangePrice: (e: any, type?: string, index?: any) => void;
}

const NewRentalPricingTable = ({
    rows,
    columns,
    formValues,
    errors,
    templateFields,
    filteredMultiFields,
    loadingOptions,
    loadingFields,
    onAddNewRow,
    onDeleteItemRow,
    onChangeSelectedFieldValue,
    onMenuCloseAfterSearchMulti,
    onChangeSearchFieldMultiple,
    onChangePrice
}: INewRentalPricingTable) => {
    return (
        <Table
            headerColsStyle='border-r-2 last:border-r-0 pl-3 pr-6'
            columns={columns || []}
            hidePagination={true}
        >
            {[...Array(rows || 1)]?.map((item: any, idx: number) => {
                const findSelected = formValues && formValues?.[idx]
                const findError = errors && errors?.[idx]
                const areOptionsLoading = loadingOptions?.index === idx
                const loadingRow = loadingFields?.index === idx
                return (
                    <tr key={idx} className='bg-white '>
                        <>
                            {templateFields && templateFields?.map((field: any, index: number) => {
                                const fieldValueSelected = findSelected && findSelected[field?.name]
                                const findValuesMulti = filteredMultiFields && filteredMultiFields?.length > 0 && filteredMultiFields?.find((obj: any) => obj?.index === idx)
                                const valuesMapped = findValuesMulti && findValuesMulti?.values ?
                                    findValuesMulti?.values?.[field?.name] &&
                                    findValuesMulti?.values?.[field?.name]?.length > 0 &&
                                    findValuesMulti?.values?.[field?.name]?.map((value: string) => {
                                        return { value: value, label: value };
                                    })
                                    :
                                    templateFields && templateFields?.[field?.name] &&
                                    templateFields?.[field?.name]?.length > 0 &&
                                    templateFields?.[field?.name]?.map((value: string) => {
                                        return { value: value, label: value };
                                    });
                                return (
                                    <td key={index} className='p-2 align-center border-r-2 border-b-2 max-w-[240px] w-[240px]'>
                                        <AsyncSelectCheckbox
                                            placeholder={fieldValueSelected || ``}
                                            isLoading={loadingRow}
                                            selectedOption={fieldValueSelected || undefined}
                                            options={valuesMapped || []}
                                            dataQa={'values-mapped'}
                                            uniqueName={field?.name}
                                            mainWrpStyle={'max-w-[240px]'}
                                            dropDownStyle={'max-w-[240px]'}
                                            loadingOptions={areOptionsLoading}
                                            onChangeValue={onChangeSearchFieldMultiple}
                                            onMenuClose={onMenuCloseAfterSearchMulti}
                                            hideRelative={true}
                                            dynamicOptions={true}
                                            index={idx}
                                            parent={item}
                                            onChangeSelectedOption={onChangeSelectedFieldValue}
                                            error={findError || undefined}
                                        />
                                    </td>
                                )
                            })}
                        </>
                        <td className='px-6 py-4 align-top border-r-2 border-b-2'>
                            <Input
                                placeholder='Price'
                                dataQa={'price'}
                                showValue={true}
                                containerStyle={'mb-0'}
                                inputStyle={'min-w-[140px] mb-1'}
                                inputUniqueName='price'
                                index={idx}
                                inputValue={findSelected?.price}
                                onChangeInput={onChangePrice}
                            />
                        </td>
                        <td className='p-2 border-b-2 w-[40px]'>
                            <div
                                data-qa={'trash'}
                                onClick={() => onDeleteItemRow('one', idx)}
                                className='cursor-pointer max-w-[40px] flex flex-row justify-center'>
                                <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] hover:w-[17px] object-contain'} />
                            </div>
                        </td>
                    </tr>
                )
            })}
            <tr>
                <td className='py-4 px-3'>
                    <Button
                        className='btn-main'
                        label='Add New Row'
                        dataQa={'add-new-row'}
                        onClickButton={onAddNewRow}
                    />
                </td>
                {[...Array(columns && columns?.length > 0 ? columns?.length - 2 || 0 : 0)].map((item: any, id: number) => {
                    return (
                        <td key={id}>
                        </td>
                    )
                })
                }

            </tr>
        </Table >
    )
}

export default NewRentalPricingTable;