import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useChangePasswordMutation } from '../../../../store/user/userDomApi';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';


interface IChangePassword {
    openChangePasswordModal: boolean;
    handleCloseModal: () => void;
    passwordChangedSuccessfully: () => void;
}

type FormValues = {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
};

const ChangePassword = ({
    openChangePasswordModal,
    handleCloseModal,
    passwordChangedSuccessfully
}: IChangePassword) => {
    const [changePasswordMutation] = useChangePasswordMutation()
    const [errorChanging, setErrorChanging] = useState<string>()
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch
    } = useForm<FormValues>({});

    const onCloseChangePasswordModal = () => {
        handleCloseModal()
        reset()
    }

    const onSubmit = async (data: FormValues) => {
        try {
            const formatData = {
                password: data?.newPassword,
                confirmPassword: data?.confirmNewPassword,
                previousPassword: data?.currentPassword,
                token: localStorage.getItem('cgToken') || ''
            }
            await changePasswordMutation(formatData).unwrap()
            passwordChangedSuccessfully()
            reset()
        } catch (error: any) {
            if (error?.data?.message && Array.isArray(error?.data?.message)) {
                const formatError = error?.data?.message?.length > 0 && error?.data?.message?.map((item: any, index: number) => `${index ? ', ' : ''}${item}`)
                setErrorChanging(`${formatError || error}`)
            } else {
                setErrorChanging(`${error?.data?.message || error}`)
            }
        }
    }

    return (
        <Modal
            open={openChangePasswordModal}
            onClose={onCloseChangePasswordModal}
            dataQa={'close-modal'}
        >
            <div className={'p-2 min-w-[500px]'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <p className='mb-6 font-bold text-md'>Change Password</p>
                    {errorChanging && <Error text={errorChanging} />}
                    <Input
                        label='Current Password'
                        dataQa={'current-password'}
                        type={'password'}
                        register={register('currentPassword', {
                            required: {
                                message: 'Current Password is required',
                                value: true,
                            },
                            minLength: {
                                value: 8,
                                message: 'Minimum number of characters is 8'
                            },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                message: 'Password should be at least 8 characters long, and must contain at least 1 number, 1 lower case, 1 upper case and 1 special character(@$!%*?&)'
                            },
                            validate: (value: string) => !!value.trim() || 'Current Password is required and min length is 8'
                        },
                        )}
                        error={errors.currentPassword?.message}
                    />
                    <Input
                        label='New Password'
                        dataQa={'new-password'}
                        type={'password'}
                        register={register('newPassword', {
                            required: {
                                message: 'Password is required',
                                value: true,
                            },
                            minLength: {
                                value: 8,
                                message: 'Minimum number of characters is 8'
                            },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                message: 'Password should be at least 8 characters long, and must contain at least 1 number, 1 lower case, 1 upper case and 1 special character(@$!%*?&)'
                            },
                            validate: (value: string) => !!value.trim() || 'New Password is required and min length is 8'
                        },
                        )}
                        error={errors.newPassword?.message}
                    />
                    <Input
                        label='Repeat Password'
                        dataQa={'repeat-password'}
                        type={'password'}
                        register={register('confirmNewPassword', {
                            required: {
                                message: 'Repeat password is required',
                                value: true,
                            },
                            validate: (val: string) => {
                                if (watch('confirmNewPassword') !== val) {
                                    return 'Repeat password do not match';
                                }
                            }
                        })}
                        error={errors.confirmNewPassword?.message}
                    />
                    <Button
                        label={'Change Password'}
                        dataQa={'change-password'}
                        className={'btn-main min-w-full my-2 !py-2'}
                        type={'submit'}
                    />
                </form>
            </div>
        </Modal>
    )
}
export default ChangePassword;