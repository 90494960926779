import React from 'react';
import moment from 'moment';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['Item Id', 'Date', 'Sales Entity', 'Price', 'Currency', 'Total Sales Price', 'VAT Type', 'Type']

interface ISalesHistoryDetailsTable {
    rows: Array<any>;
    paginationData?: IPage;
    state: any;
    loading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const SalesHistoryDetailsTable = ({
    rows,
    paginationData,
    loading,
    setPaginationState,
}: ISalesHistoryDetailsTable) => {
    return (
        <Table
            containerStyle={'mt-0'}
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {loading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.salesData?.itemId || ''}</td>
                                <td className='px-6 py-4'>{row?.salesData?.date && moment(row?.salesData?.date).format('MMMM Do YYYY, h:mm:ss a') || ''}</td>
                                <td className='px-6 py-4'>{row?.salesData?.salesEntity || ''}</td>
                                <td className='px-6 py-4'>{row?.salesData?.gbpPrice || ''}</td>
                                <td className='px-6 py-4'>{row?.salesData?.salesCurrency || ''}</td>
                                <td className='px-6 py-4'>{row?.salesData?.totalSalesPrice || ''}</td>
                                <td className='px-6 py-4'>{row?.salesData?.vatType || ''}</td>
                                <td className='px-6 py-4'>{row?.salesData?.type || ''}</td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table >
    )
}

export default SalesHistoryDetailsTable;