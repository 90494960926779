import React, { useState } from 'react';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';

const locationsType = [
    { name: 'Select Section', value: 'section' },
    { name: 'Scan Barcode', value: 'barcode' },
]

interface IOrderItemLocation {
    loadingOptions?: boolean;
    filteredLocations?: any;
    searchInDBLocation?: any;
    selectedItemLocation?: any;
    sections?: any;
    selectedSection?: any;
    scannedLocation?: any;
    findBarcodeLocationsResponse?: string;
    inputStyle?: any;
    searchLocations?: any;
    onMenuCloseAfterSearch: () => void;
    onChangeSearchLocationField: (e: any, type?: string) => void;
    onClickSearchInDb: () => void;
    onEnterAction: (e: any, type?: string) => void;
    onChangedScannedLocation: (e: any, type?: string) => void;
    onChangeSelectedSection: (e: any, type?: string) => void;
    onClickClearLocationAndSection: () => void;
    onChangeSelectedLocation: (e: any, type?: string, index?: any) => void;
}

const OrderItemLocation = ({
    loadingOptions,
    filteredLocations,
    searchInDBLocation,
    selectedItemLocation,
    sections,
    scannedLocation,
    selectedSection,
    searchLocations,
    findBarcodeLocationsResponse,
    inputStyle,
    onChangeSelectedLocation,
    onMenuCloseAfterSearch,
    onChangeSearchLocationField,
    onClickSearchInDb,
    onEnterAction,
    onChangeSelectedSection,
    onChangedScannedLocation,
    onClickClearLocationAndSection
}: IOrderItemLocation) => {
    const [selectedLocationType, setSelectedLocationType] = useState<string>()

    const onClickType = (value: string) => {
        setSelectedLocationType(value)
    }

    return (
        <div>
            {findBarcodeLocationsResponse && <Error text={findBarcodeLocationsResponse} />}
            <div className='bg-[#f8f9fc] rounded px-4 pt-3 pb-1'>
                <p className='font-bold text-[20px] mb-1'>Warehouse Placement</p>
                <p className='text-primary-light text-[13px] mb-2'>You can either scan Location Barcode or Select Section</p>
                {locationsType?.map((item: { name: string, value: string }, index: number) => {
                    return (
                        <div key={index} className='grid grid-cols-3 gap-3 p-2 bg-white mb-1 !rounded'>
                            <div className='col-span-2'>
                                <p className='text-primary'>{item?.name}</p>
                            </div>
                            <div>
                                <div className='flex flex-col items-center'>
                                    <label className='relative flex items-center p-1 rounded-full cursor-pointer' htmlFor='amber'>
                                        <input
                                            name='color'
                                            type='radio'
                                            onClick={() => onClickType(item?.value)}
                                            value={item?.value}
                                            checked={selectedLocationType === item?.value}
                                            className={'before:content[\'\'] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-amber-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-amber-500 checked:before:bg-amber-500 hover:before:opacity-10'}
                                            id='amber' />
                                        <span
                                            className='absolute transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-amber-500 peer-checked:opacity-100'>
                                            <svg xmlns='http://www.w3.org/2000/svg' className='h-3 w-3' viewBox='0 0 16 16' fill='currentColor'>
                                                <circle data-name='ellipse' cx='8' cy='8' r='8'></circle>
                                            </svg>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {selectedLocationType &&
                <div className='bg-[#f8f9fc] rounded px-4 pt-3 pb-1'>
                    <div className='my-2 flex flex-col justify-center'>
                        {selectedLocationType === 'barcode'
                            ? <Input
                                label='Location Barcode'
                                containerStyle={inputStyle || 'min-w-[300px]'}
                                showValue={true}
                                inputValue={scannedLocation}
                                onChangeInput={onChangedScannedLocation}
                                onHandleKeyDown={onEnterAction}
                            /> :
                            <SelectCheckbox
                                name='Select Section'
                                placeholder=' '
                                options={sections}
                                selectedOption={selectedSection}
                                containerStyle={inputStyle || 'min-w-[300px]'}
                                onChangeSelectedOption={onChangeSelectedSection}
                            />
                        }
                    </div>
                    {filteredLocations &&
                        <div className='mb-1'>
                            <AsyncSelectCheckbox
                                isLoading={false}
                                name='Item Warehouse Location'
                                options={filteredLocations || []}
                                placeholder={selectedItemLocation?.name || ''}
                                dynamicOptions={true}
                                loadingOptions={loadingOptions}
                                selectedOption={selectedItemLocation}
                                uniqueName='location'
                                showSearchDB={searchLocations || false}
                                onChangeValue={onChangeSearchLocationField}
                                onMenuClose={onMenuCloseAfterSearch}
                                onClickSearchInDb={onClickSearchInDb}
                                onChangeSelectedOption={onChangeSelectedLocation}
                            />
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default OrderItemLocation;