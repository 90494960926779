import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { WholesalePriceHistoryService } from './wholesalePriceHistoryApi';
import { IWholesalePriceHistoryListState } from '../../../../interfaces/wholesale-price-history/IWholesalePriceHistoryList';
import { PaginatedWholesalePriceHistory, PaginatedWholesalePriceHistoryDetails } from '../../../../interfaces/wholesale-price-history/paginatedStore.type';

const initialState: IWholesalePriceHistoryListState = {
    wholesaleIsLoading: false,
    wholesalesAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchWholesalePriceHistory = createAsyncThunk<PaginatedWholesalePriceHistory, IPaginationPayload>(
    'wholesale-price-history/tryToFetchWholesalePriceHistory',
    async ({ pageNumber, pageSize, data }) => {
        const result = await WholesalePriceHistoryService.tryToFetchWholesalePriceHistory(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToFetchDetailedWholesalePriceHistory = createAsyncThunk<PaginatedWholesalePriceHistoryDetails, IPaginationPayload>(
    'pricing-history/tryToFetchDetailedWholesalePriceHistory',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await WholesalePriceHistoryService.tryToFetchDetailedWholesalePriceHistory(pageNumber, pageSize, filters, data);
        return result?.data;
    },
);

export const wholesalePriceHistorySlice = createSlice({
    name: 'wholesalePriceHistory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchWholesalePriceHistory
            .addCase(tryToFetchWholesalePriceHistory.pending, (state) => {
                state.wholesalesAreLoading = true;
            })
            .addCase(tryToFetchWholesalePriceHistory.rejected, (state) => {
                state.wholesalesAreLoading = false;
            })
            .addCase(tryToFetchWholesalePriceHistory.fulfilled, (state, action) => {
                state.wholesalesAreLoading = false;
                state.wholesaleHistory = action.payload;
            })

            // tryToFetchDetailedWholesalePriceHistory
            .addCase(tryToFetchDetailedWholesalePriceHistory.pending, (state) => {
                state.wholesaleIsLoading = true;
            })
            .addCase(tryToFetchDetailedWholesalePriceHistory.rejected, (state) => {
                state.wholesaleIsLoading = false;
            })
            .addCase(tryToFetchDetailedWholesalePriceHistory.fulfilled, (state, action) => {
                state.wholesaleIsLoading = false;
                state.wholesaleHistoryDetails = action.payload;
            })
    },
});

export default wholesalePriceHistorySlice.reducer;