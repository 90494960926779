import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { MarketsService } from './marketsApi';
import { PaginatedMarkets } from '../../../../interfaces/markets/paginatedStore.type';
import { IMarketsListState } from '../../../../interfaces/markets/IMarketsList';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IMarketsSchema } from '../../../../interfaces/markets/IMarketsSchema';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { AxiosError } from 'axios';


const initialState: IMarketsListState = {
    marketsAreLoading: false,
    toastSuccessRequest: false,
    marketIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchMarkets = createAsyncThunk<
    PaginatedMarkets,
    IPaginationPayload
>('markets/tryToFetchMarkets', async ({ pageNumber, pageSize, filters }) => {
    const result = await MarketsService.tryToFetchMarkets(
        pageNumber,
        pageSize,
        filters,
    );
    return result?.data;
});

export const tryToFetchFilteredMarkets = createAsyncThunk<
    PaginatedMarkets,
    IPaginationPayload
>('markets/tryToFetchFilteredMarkets', async ({ pageNumber, pageSize, filters }) => {
    const result = await MarketsService.tryToFetchFilteredMarkets(
        pageNumber,
        pageSize,
        filters,
    );
    return result?.data;
});

export const tryToFetchSingleMarket = createAsyncThunk<IMarket, string>(
    'markets/tryToFetchSingleMarkets',
    async (id: string) => {
        const result = await MarketsService.tryToFetchSingleMarket(id);
        return result?.data;
    },
);

export const tryToEditMarket = createAsyncThunk<
    ApiResponse<IMarketsSchema>,
    any
>('markets/tryToEditMarket', async (data: any, { rejectWithValue }) => {
    try {
        const result = await MarketsService.tryToEditMarket(
            data._id || '',
            data?.data,
        );
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToAddMarket = createAsyncThunk<
    ApiResponse<IMarketsSchema>,
    any
>('markets/tryToAddMarket', async (data: any, { rejectWithValue }) => {
    try {
        const result = await MarketsService.tryToAddMarket(data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToFetchBrokerMarkets = createAsyncThunk<IMarket[], any>(
    'markets/tryToFetchBrokerMarkets',
    async () => {
        const result = await MarketsService.tryToFetchBrokerMarkets();
        return result?.data;
    },
);

export const tryToDisableMarket = createAsyncThunk<
    ApiResponse<IMarketsSchema>,
    { marketId: string }
>('stores/tryToDisableMarket', async (data, { rejectWithValue }) => {
    try {
        const results = await MarketsService.tryToDisableMarket(data?.marketId);
        return results.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToFetchActiveCatalogsForMarket = createAsyncThunk<any, string>(
    'markets/tryToFetchActiveCatalogsForMarket',
    async (marketId: string) => {
        const result = await MarketsService.tryToFetchActiveCatalogsForMarket(marketId);
        return result?.data
    },
);
export const tryToAddCatalogToMarket = createAsyncThunk<
    ApiResponse<any>,
    any
>('markets/tryToAddCatalogToMarket', async (data: any, { rejectWithValue }) => {
    try {
        const result = await MarketsService.tryToAddCatalogToMarket(
            data.marketId || '',
            data?.payload,
        );
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToRemoveCatalogFromMarket = createAsyncThunk<
    ApiResponse<any>,
    any
>('markets/tryToRemoveCatalogToMarket', async (data: any, { rejectWithValue }) => {
    try {
        const result = await MarketsService.tryToRemoveCatalogFromMarket(
            data.marketId || '',
            data?.payload,
        );
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const marketsSlice = createSlice({
    name: 'markets',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchMarkets
            .addCase(tryToFetchMarkets.pending, (state) => {
                state.marketsAreLoading = true;
            })
            .addCase(tryToFetchMarkets.rejected, (state) => {
                state.marketsAreLoading = false;
            })
            .addCase(tryToFetchMarkets.fulfilled, (state, action) => {
                state.marketsAreLoading = false;
                state.markets = action.payload;
            })

            // tryToFetchFilteredMarkets
            .addCase(tryToFetchFilteredMarkets.pending, (state) => {
                state.marketsAreLoading = true;
            })
            .addCase(tryToFetchFilteredMarkets.rejected, (state) => {
                state.marketsAreLoading = false;
            })
            .addCase(tryToFetchFilteredMarkets.fulfilled, (state, action) => {
                state.marketsAreLoading = false;
                state.markets = action.payload;
            })

            // tryToAddMarket
            .addCase(tryToAddMarket.pending, (state) => {
                state.marketIsLoading = true;
            })
            .addCase(tryToAddMarket.rejected, (state) => {
                state.marketIsLoading = false;
            })
            .addCase(tryToAddMarket.fulfilled, (state, action) => {
                state.marketIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.markets?.data)
                    state.markets.data.elements = [
                        data,
                        ...state.markets?.data.elements,
                    ];
            })

            // tryToDisableMarket
            .addCase(tryToDisableMarket.pending, (state) => {
                state.marketIsLoading = true;
            })
            .addCase(tryToDisableMarket.rejected, (state) => {
                state.marketIsLoading = false;
            })
            .addCase(tryToDisableMarket.fulfilled, (state, action) => {
                state.marketIsLoading = false;
                state.requestStatus = 'success';
                if (state.markets?.data.elements)
                    state.markets.data.elements =
                        { ...state }.markets?.data.elements.filter(
                            (market) =>
                                market?._id !== action.meta.arg?.marketId,
                        ) || [];
            })

            // tryToEditMarket
            .addCase(tryToEditMarket.pending, (state) => {
                state.marketIsLoading = true;
            })
            .addCase(tryToEditMarket.rejected, (state) => {
                state.marketIsLoading = false;
            })
            .addCase(tryToEditMarket.fulfilled, (state, action) => {
                state.marketIsLoading = false;
                if (state.markets?.data.elements) {
                    state.markets.data.elements =
                        { ...state }.markets?.data.elements.map((market) => {
                            if (market?._id === action.meta?.arg?._id) {
                                return {
                                    ...action.payload.data || {},
                                    flagEditUrl: action?.payload?.data?.flagUrl
                                };
                            } else {
                                return market;
                            }
                        }) || [];
                }
                state.marketDetail = action.payload.data;
            })

            // tryToFetchSingleMarket
            .addCase(tryToFetchSingleMarket.pending, (state) => {
                state.marketIsLoading = true;
            })
            .addCase(tryToFetchSingleMarket.rejected, (state) => {
                state.marketIsLoading = false;
            })
            .addCase(tryToFetchSingleMarket.fulfilled, (state, action) => {
                state.marketIsLoading = false;
                state.marketDetail = action.payload;
            })

            // tryToFetchCatalogsForMarket
            .addCase(tryToFetchActiveCatalogsForMarket.pending, (state) => {
                state.catalogsAreLoading = true;
            })
            .addCase(tryToFetchActiveCatalogsForMarket.rejected, (state) => {
                state.catalogsAreLoading = false;
            })
            .addCase(tryToFetchActiveCatalogsForMarket.fulfilled, (state, action) => {
                state.catalogsAreLoading = false;
                state.catalogsActive = action.payload;
            })

            // tryToAddCatalogToMarket
            .addCase(tryToAddCatalogToMarket.pending, (state) => {
                state.catalogsAreLoading = true;
            })
            .addCase(tryToAddCatalogToMarket.rejected, (state) => {
                state.catalogsAreLoading = false;
            })
            .addCase(tryToAddCatalogToMarket.fulfilled, (state, action) => {
                state.catalogsAreLoading = false;
            })

            // tryToRemoveCatalogsFromMarket
            .addCase(tryToRemoveCatalogFromMarket.pending, (state) => {
                state.catalogsAreLoading = true;
            })
            .addCase(tryToRemoveCatalogFromMarket.rejected, (state) => {
                state.catalogsAreLoading = false;
            })
            .addCase(tryToRemoveCatalogFromMarket.fulfilled, (state, action) => {
                state.catalogsAreLoading = false;
            });

    },
});

export default marketsSlice.reducer;
