import { AxiosResponse } from 'axios';
import {
    ASSETS_API_URL,
    OFFER_API_URL,
    UPLOAD_PRICING_URL,
} from '../../../../config';
import offersAxios from '../../../../utils/axios/offers.axios';
import uploadPricingAxios from '../../../../utils/axios/upload-pricing.axios';
import { IWholesalePrice } from '../../../../interfaces/wholesale-price/IWholesalePrice';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { PaginatedWholesalePrice } from '../../../../interfaces/wholesale-price/paginatedStore.type';
import { IAllWholepricePricing } from '../../../../interfaces/wholesale-price/IAllWholesalePricing';
import { IWholepriceDownloadData } from '../../../../interfaces/wholesale-price/IWholepriceDownloadData';


const tryToFetchFilteredWholesalePrice = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse<PaginatedWholesalePrice>> => {
    const url = `${OFFER_API_URL}/whole-sale?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedWholesalePrice>(url, data);
};

const tryToFetchFilteredAdminWholesalePrice = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse<PaginatedWholesalePrice>> => {
    const url = `${OFFER_API_URL}/whole-sale/all/view?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedWholesalePrice>(url, data);
};

const tryToDownloadFilteredAdminWholesalePrice = (brokerId: string, data?: any): Promise<AxiosResponse<PaginatedWholesalePrice>> => {
    const url = `${OFFER_API_URL}/whole-sale/user/${brokerId}/all`
    return offersAxios.put(url, data);
};

const tryToDownloadWholesaleSamples = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${ASSETS_API_URL}/brokers/${data.assetTemplateId}/download/whole-sales`
    return offersAxios.put(url, data.data)
};

const tryToDownloadPartnerWholesalePrice = (assetTemplateId: string, data?: any): Promise<AxiosResponse<IWholepriceDownloadData>> => {
    const url = `${OFFER_API_URL}/whole-sale/download/${assetTemplateId}/all-offers`
    return offersAxios.put(url, data);
};

const tryToEditWholesalePrice = (id: string, data: any): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/whole-sale/${id}/edit`, data);
};

const tryToDeleteWholesalePrice = (id: string): Promise<AxiosResponse> => {
    return offersAxios.delete(`${OFFER_API_URL}/whole-sale/${id}`);
};

const tryToEditWholesalePriceStatus = (data: any): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/whole-sale/match/status`, data);
};

const tryToFetchWholesaleAvailableStock = (data: any): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/whole-sale/available-stock/insights`, data);
};

const tryToFetchFilteredAdminWholesalePriceMatch = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse<PaginatedWholesalePrice>> => {
    const url = `${OFFER_API_URL}/whole-sale/high-level-view?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedWholesalePrice>(url, data);
};

const tryToFetchMatchedWholesalePrice = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse<PaginatedWholesalePrice>> => {
    const url = `${OFFER_API_URL}/wholesale-matches?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedWholesalePrice>(url, data);
};

const tryToAddWholesalePrice = (data?: any): Promise<AxiosResponse> => {
    const url = `${OFFER_API_URL}/whole-sale/submit`
    return offersAxios.post<any>(url, data);
};

const tryToUploadWholesalePricingCSV = async (
    data: any,
    templateId: string,
    marketId: string,
    type: any,
    currency: string,
    vat: any,
    brokerUserName?: string,
    template?: string
): Promise<any> => {
    const url = `${UPLOAD_PRICING_URL}/whole-sale`;
    let formatData: any = {
        assetTemplateId: templateId,
        marketId: marketId,
        data: data,
        type: type,
        currency: currency,
        vat: vat
    };
    if (brokerUserName) {
        formatData = {
            ...formatData || {},
            brokerUsername: brokerUserName
        }
    }
    if (template) {
        formatData = {
            ...formatData || {},
            template: template || ''
        }
    }
    await uploadPricingAxios.post<ApiResponse<IWholesalePrice>>(url, formatData);
    return formatData;
};

const tryToFetchAllCurrencies = async (): Promise<any> => {
    const url = `${ASSETS_API_URL}/dom/currency/assets`;
    return offersAxios.get(url)
}

const tryToFetchAllWholesalePricing = (
    data: IAllWholepricePricing
): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/whole-sale/all/view`, data);
};

const tryToDownloadAllWholesaleOffers = (data: IAllWholepricePricing): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/whole-sale/all/view/download`, data);
};

const tryToDownloadAllWholesaleOffersMatched = (data: any): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/whole-sale/high-level-view/download`, data);
};

const tryToMatchWholesalePrices = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${OFFER_API_URL}/wholesale-matches`
    return offersAxios.post(url, data);
};

const tryToFetchWholesalePriceAvailable = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/whole-sale/available-stock?page=${pageNumber}&size=${pageSize}`, data);
};

const tryToFetchWholesalePriceAvailableBuy = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/whole-sale/available-stock/buy?page=${pageNumber}&size=${pageSize}`, data);
};

const tryToFetchWholesalePriceSettings = (
    pageNumber: number,
    pageSize: number,
    data?: any
): Promise<AxiosResponse<any>> => {
    const url = `${OFFER_API_URL}/wholesale-price-fee?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<any>(url, data);
};

const tryToFetchSingleWholesalePriceSettings = (id: string,): Promise<AxiosResponse<any>> => {
    const url = `${OFFER_API_URL}/wholesale-price-fee/${id}`
    return offersAxios.get(url);
};

const tryToCreateWholesalePriceSettings = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${OFFER_API_URL}/wholesale-price-fee`
    return offersAxios.post(url, data);
};

const tryToEditWholesalePriceSettings = (id: string, data?: any): Promise<AxiosResponse<any>> => {
    const url = `${OFFER_API_URL}/wholesale-price-fee/${id}`
    return offersAxios.put(url, data);
};

const tryToDeleteWholesalePriceSettings = (id: string): Promise<AxiosResponse> => {
    return offersAxios.delete(`${OFFER_API_URL}/wholesale-price-fee/${id}`);
};

const tryToFetchBestOffer = (data?: any, type?: string): Promise<AxiosResponse<any>> => {
    const url = `${OFFER_API_URL}/whole-sale/available-stock/items/${type}`
    return offersAxios.put(url, data);
};

const tryToCreateOfferWholesalePrice = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${OFFER_API_URL}/whole-sale/offer`
    return offersAxios.post(url, data);
};

export const WholesaleService = {
    tryToFetchFilteredWholesalePrice,
    tryToFetchFilteredAdminWholesalePrice,
    tryToDownloadFilteredAdminWholesalePrice,
    tryToDownloadWholesaleSamples,
    tryToDownloadPartnerWholesalePrice,
    tryToUploadWholesalePricingCSV,
    tryToEditWholesalePrice,
    tryToFetchAllCurrencies,
    tryToFetchAllWholesalePricing,
    tryToDownloadAllWholesaleOffers,
    tryToFetchFilteredAdminWholesalePriceMatch,
    tryToMatchWholesalePrices,
    tryToFetchWholesalePriceAvailable,
    tryToFetchWholesaleAvailableStock,
    tryToFetchWholesalePriceSettings,
    tryToFetchSingleWholesalePriceSettings,
    tryToCreateWholesalePriceSettings,
    tryToEditWholesalePriceSettings,
    tryToDeleteWholesalePriceSettings,
    tryToFetchMatchedWholesalePrice,
    tryToFetchBestOffer,
    tryToCreateOfferWholesalePrice,
    tryToFetchWholesalePriceAvailableBuy,
    tryToEditWholesalePriceStatus,
    tryToDownloadAllWholesaleOffersMatched,
    tryToAddWholesalePrice,
    tryToDeleteWholesalePrice
};
