import React from 'react';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';


interface IPieChart {
    chartData: Array<{ value: number, name: string }>;
    colors?: Array<string>;
    chartName?: string;
}

const PieChart = ({
    chartData,
    colors,
    chartName
}: IPieChart) => {
    const gradients = [
        new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            { offset: 0, color: '#F7931E ' },
            { offset: 1, color: '#FCEE21' },
        ]),
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
            { offset: 0, color: '#8a8a8e' },
            { offset: 1, color: '#8a8a8e' },
        ]),
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
            { offset: 0, color: '#afc8db' },
            { offset: 1, color: '#afc8db' },
        ]),
    ];

    const getOptionBar = () => ({
        tooltip: {
            trigger: 'item',
            valueFormatter: (value: any) => chartName ? value : value + '%'
        },
        series: [
            {
                name: chartName || 'Markets',
                type: 'pie',
                radius: ['70%', '50%'],
                data: chartData.map((item, index) => ({
                    value: item.value,
                    name: item.name,
                    itemStyle: {
                        color: gradients[index],
                    },
                })),
                // color: colors || ['#F7931E', '#FCEE21', '#afc8db'],
                startAngle: 15,
                avoidLabelOverlap: true,
                label: {
                    show: false,
                    position: 'center'
                },
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 2
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 0,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.1)'
                    },
                    label: {
                        show: true,
                        fontSize: 10,
                        fontWeight: 'bold'
                    }
                }
            }
        ]
    })
    return (
        <div>
            <ReactEcharts option={getOptionBar()} style={{ height: 300, width: '100%' }} />
        </div>
    )
}
export default PieChart;