import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchSingleComparisonSite } from '../../../store/brokers/admin/comparison-sites/comparisonSitesSlice';
import ComparisonSiteDetailsTable from './tables/ComparisonSiteDetailsTable';
import EmptyContainer from '../../../shared/empty-container';


const ComparisonSiteDetails = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any[]>([]);
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const paginationData = useAppSelector((state) => state.comparisonSites.comparisonSite?.data?.page);
    const rowsLoading = useAppSelector((state) => state.comparisonSites.comparisonSiteIsLoading);
    const state = useAppSelector((state) => state.comparisonSites);
    const [getAllMarkets] = useGetAllMarketsMutation()

    const getMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, value: market?._id, displayName: market?.name }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        if (state.comparisonSite) {
            const rows =
                state.comparisonSite?.data?.elements &&
                    Array.isArray(state.comparisonSite?.data.elements)
                    ? state.comparisonSite?.data.elements.map((r: any) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.comparisonSite]);

    const getComparisonData = async () => {
        const formatData: any = {
            ...paginationState,
            name: id || ''
        }
        await dispatch(tryToFetchSingleComparisonSite(formatData));
    }

    useEffect(() => {
        getComparisonData()
    }, [paginationState]);

    useEffect(() => {
        getMarketData()
    }, [])

    return (
        <div>
            <div>
                <p className='page-title'>Comparison Site Details</p>
                <p className='mt-3 font-medium'>Name: {id}</p>
            </div>
            <div className='my-5 relativ'>
                {(rowsLoading || rows?.length > 0) ?
                    <ComparisonSiteDetailsTable
                        rows={rows}
                        state={state}
                        markets={markets}
                        dataLoading={rowsLoading}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                    />
                    : <EmptyContainer
                        text={'No Comparison Site to view yet.'}
                        showImage={true}
                    />
                }
            </div>
        </div>
    );
};

export default ComparisonSiteDetails;
