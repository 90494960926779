import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
// import Barcode from 'react-barcode';
import { useAppDispatch } from '../../../../app/hooks';
import { ORDERS_DROPDOWN_TYPES } from '../../../../utils/constants/order-details-actions';
import { tryToRejectOrder } from '../../../../store/brokers/partners/orders/ordersSlice';
import Textarea from '../../../../shared/textarea';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IRejectModal {
    openRejectModal: boolean;
    orderId: string;
    itemId: string;
    marketId: string;
    warehouseImages?: any;
    handleCloseModal: () => void;
}

type FormValues = {
    rejectionReason: string;
};

const RejectModal = ({
    openRejectModal,
    orderId,
    marketId,
    itemId,
    warehouseImages,
    handleCloseModal
}: IRejectModal) => {
    const dispatch = useAppDispatch();
    const [isFakeItem] = useState<boolean>(false);
    const [typeSelected, setTypeSelected] = useState<any>();
    const [rejectError, setRejectError] = useState<string>();
    const [filesSelected, setFilesSelected] = useState<any>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.map((file: any) => {
            const reader = new FileReader();
            reader.onload = async (event: any) => {
                await setFilesSelected((prevState: any) => {
                    return [...prevState || [], file]
                })
            };
            reader.readAsDataURL(file);
            return file
        });
    }, []
    )

    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true, })

    const onDeleteImage = (file: any) => {
        const filterOutFile = (filesSelected && filesSelected?.length > 0) && filesSelected?.filter((item: any) => item !== file)
        setFilesSelected(filterOutFile || undefined)
    }

    const {
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue
    } = useForm<FormValues>({});

    const onCloseModal = () => {
        handleCloseModal();
        setRejectError(undefined)
        setFilesSelected(undefined)
        setLoadingSubmit(false)
        reset();
        document.body.style.overflow = 'auto';
    }

    const onSubmit = async (data: FormValues) => {
        if (loadingSubmit) {
            return;
        }
        if (!data?.rejectionReason && !typeSelected) {
            setRejectError('Please select or write a reason to continue')
            setLoadingSubmit(false)
            return;
        }
        if (!warehouseImages && (!filesSelected || filesSelected?.length < 2)) {
            setRejectError('Please upload at least 2 images to continue')
            setLoadingSubmit(false)
            return;
        }
        try {
            setRejectError(undefined)
            setLoadingSubmit(true)
            const dataForm: any = new FormData()
            dataForm.append('reason', data?.rejectionReason || typeSelected)
            dataForm.append('fake', `${isFakeItem}`)
            dataForm.append('itemId', itemId)
            dataForm.append('marketId', marketId)
            if (filesSelected && filesSelected?.length > 0) {
                filesSelected?.map((file: any, index: number) => {
                    dataForm.append(`file${index + 1}`, file)
                    return file
                })
            }
            await dispatch(tryToRejectOrder({ orderId: orderId, data: dataForm })).unwrap()
            onCloseModal()
            handleCloseModal()
        } catch (err) {
            setRejectError(`${err}`)
            setLoadingSubmit(false)
        }
    }

    const onChangeValueMessage = (e: any) => {
        setValue('rejectionReason', e?.target?.value)
    }

    const onClickImportType = (value: any, name?: string) => {
        setTypeSelected(value?.label)
    }

    useEffect(() => {
        if (typeSelected === 'Other') {
            const textArea: HTMLTextAreaElement | null = document.querySelector('.textarea-primary')
            if (textArea) {
                textArea.focus()
            }
        }
    }, [typeSelected])

    return (
        <Modal
            open={openRejectModal}
            onClose={onCloseModal}
            dataQa={'close-button'}
            showInRight={true}
            contentContainerStyle='min-w-[700px]'
        >
            <div className={'p-2 min-w-[650px] min-h-[300px]'}>
                <p className='mt-2 mb-1 !text-[18px] mr-10'>Reason for Rejection</p>
                <div className='mt-2 mb-5 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                {rejectError && <Error text={rejectError} />}
                <div className='mt-3 bg-[#f8f9fc] rounded px-4 pt-3 pb-1'>
                    <p className='mb-5'>What is the issue</p>
                    {ORDERS_DROPDOWN_TYPES?.map((item: { value: string, label: string }, index: number) => {
                        return (
                            <div key={index} className='grid grid-cols-3 gap-3 p-2 bg-white mb-1 !rounded'>
                                <div className='col-span-2'>
                                    <p>{item?.label}</p>
                                </div>
                                <div>
                                    <div className='flex items-center'>
                                        <label className='relative flex items-center p-1 rounded-full cursor-pointer' htmlFor='amber'>
                                            <input
                                                name='color'
                                                type='radio'
                                                onClick={() => onClickImportType(item, item?.value)}
                                                value={item?.value}
                                                checked={typeSelected === item?.label}
                                                className={'before:content[\'\'] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-amber-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-amber-500 checked:before:bg-amber-500 hover:before:opacity-10'}
                                                id='amber' />
                                            <span
                                                className='absolute transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-amber-500 peer-checked:opacity-100'>
                                                <svg xmlns='http://www.w3.org/2000/svg' className='h-3 w-3' viewBox='0 0 16 16' fill='currentColor'>
                                                    <circle data-name='ellipse' cx='8' cy='8' r='8'></circle>
                                                </svg>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-4 bg-[#f8f9fc] rounded px-4 py-3'>
                        {typeSelected === 'Other' &&
                            <Textarea
                                placeholder='Rejection reason'
                                rows={4}
                                dataQa={'rejection-reason'}
                                // disabled={typeSelected !== 'Other'}
                                maxLength={160}
                                value={watch('rejectionReason')}
                                onChangeTextarea={onChangeValueMessage}
                                error={errors.rejectionReason?.message}
                            />
                        }
                    </div>
                    {!warehouseImages &&
                        <div className='mb-4 bg-[#f8f9fc] px-4 p-2'>
                            <div>
                                <p className='mb-2'>Insert Snapshot as proof</p>
                                <div className='flex flex-row items-center mr-10' {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button
                                        icon={<img src='/assets/shared/add-plus.svg' className='w-[30px] object-contains pr-2' />}
                                        className={'btn-main !bg-white !py-2 !pr-3 !shadow-none !border-none flex flex-row items-center'}
                                        label={'Upload file'}
                                        dataQa={'select-file'}
                                    />
                                </div>
                                {/* <div className='wrapper'>
                                    <div className='line'></div>
                                    <div className='wordwrapper'>
                                        <div className='word'>or</div>
                                    </div>
                                </div> */}
                                {/* <div className='ml-10'>
                                    <p className='mb-4 text-center'>
                                        Scan item barcodes to scan the item using the app
                                    </p>
                                    <div className='flex flex-col items-center'>
                                        <Barcode value={itemId} width={1.2} height={60} fontSize={14} displayValue={true} />
                                    </div>
                                </div> */}
                                {/* {fileSelected && <p>{fileSelected?.name}</p>} */}
                            </div>
                            {filesSelected && filesSelected?.length > 0 &&
                                <div className='flex flex-rows items-center flex-wrap justify-start rounded p-2 my-2'>
                                    {filesSelected?.map((item: any, key: number) => {
                                        return (
                                            <div
                                                key={key}
                                                onClick={() => onDeleteImage(item)}
                                                className={'relative flex flex-col items-center mr-4 mb-2 z-70'}>
                                                <div data-qa={'delete-image'} className='absolute top-[-7px] right-[-7px] cursor-pointer z-50'>
                                                    <img src={'/assets/shared/cancel.svg'} className='max-w-[15px] object-contain z-70' />
                                                </div>
                                                <div className='border border-[#000] p-1 z-0'>
                                                    <img src={item && URL.createObjectURL(item)} className='max-w-[70px] object-contain' />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    }
                    {/* <div className='flex flex-row items-center mb-6'>
                        <div className='flex items-center mr-2'>
                            <input
                                onChange={() => onClickFakeItem()}
                                checked={isFakeItem}
                                id='checkbox-all'
                                type='checkbox'
                                className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                            />
                            <label htmlFor='checkbox-all' className='sr-only'>
                                checkbox
                            </label>
                        </div>
                        <p>
                            Fake Item.
                        </p>
                    </div> */}
                    <div className='min-w-full flex flex-row pt-4 justify-center'>
                        <Button
                            label={'Submit'}
                            dataQa={'submit-button'}
                            className={`${loadingSubmit ? 'btn-main-disable' : 'btn-main'} my-3 min-w-[130px] !shadow-none`}
                            type='submit'
                        />
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default RejectModal;