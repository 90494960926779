import React from 'react';
// import moment from 'moment';
import PieChart from '../../../../shared/pie-chart';
import WhiteContainer from '../../../../shared/white-container';


interface IPieChartOrderDetails {
    acceptedValue: number;
    rejectedValue: number;
}

let chartData = [
    { name: 'Accepted', value: 0 },
    { name: 'Rejected', value: 0 }
]

const PieChartOrderDetails = ({
    acceptedValue,
    rejectedValue,
}: IPieChartOrderDetails) => {

    chartData = chartData?.map((data) => {
        return {
            ...data,
            value: data?.name === 'Accepted' ? acceptedValue : rejectedValue
        }
    })

    const colors = ['text-[#F7A21E]', 'text-[#8a8a8e]', 'text-[#afc8db]']
    return (
        <WhiteContainer type='rounded'>
            <div>
                <div className='flex flex-row justify-between'>
                    <p className='pl-2 text-primary-light font-bold text-xs mb-2'>Total number of items accepted / rejected / canceled</p>
                    {/* <img alt='items arrow' src={'/assets/pricing/see-more.svg'} className={'w-[7x] rotate-90 mb-2 cursor-pointer mr-2'} /> */}
                </div>
                {/* <p className='pl-2 font-bold'>{moment().format('MMMM')}</p> */}
                <div className='grid grid-cols-7'>
                    <div className='col-span-1 pl-2'>
                        {chartData && chartData?.length > 0 && chartData?.map((item, index) => {
                            return (
                                <div className='mt-2' key={index}>
                                    <p className='text-primary-light'>{item?.name}</p>
                                    {colors[index] && <p className={`${colors[index]} font-semibold text-xl`}>{item?.value}</p>}
                                </div>
                            )
                        })}
                    </div>
                    <div className='col-span-6'>
                        <PieChart
                            chartData={chartData}
                            chartName='Items'
                        />
                    </div>
                </div>
            </div>
        </WhiteContainer>
    )
}

export default PieChartOrderDetails;