import React from 'react';
import moment from 'moment'
import { IOrderDetailedStatuses } from '../../../../interfaces/orders/IOrdersSchema';
import WhiteContainer from '../../../../shared/white-container';


interface IOrderDatesDetails {
    detailedStatuses?: Array<IOrderDetailedStatuses>
}

const OrderDatesDetails = ({
    detailedStatuses
}: IOrderDatesDetails) => {
    const newDetailedStatuses = [...detailedStatuses || []].reverse()
    return (
        <WhiteContainer containerStyle='!h-[60%] mb-2 overflow-y-auto custom-scrollbar !max-h-[60%] !rounded'>
            <p className='font-bold text-[20px] mb-4'>Detailed Status View</p>
            <ul className=' overflow-hidden space-y-4'>
                {(detailedStatuses && detailedStatuses?.length > 0) && (newDetailedStatuses)?.map((item: IOrderDetailedStatuses, index: number) => {
                    return (
                        <li key={index} className={`relative flex-1 ${(index !== (newDetailedStatuses?.length - 1)) ? 'after:content-[\'\']  after:w-0.5 after:h-[105%]  after:bg-[#000000] after:inline-block after:absolute after:-bottom-11 after:left-1.5 lg:after:left-1.5' : ''}`}>
                            <a className='flex items-center font-medium w-full'>
                                <span className={`${(index === 0) ? 'bg-[#f7a21e]' : 'bg-[#8a8a8e]'} z-30 rounded-full flex justify-center items-center mr-3 text-sm text-indigo-600 w-4 h-4`}></span>
                                <div className='block'>
                                    <p className={`${(index === 0) ? 'text-[#f7a21e]' : 'text-[#8a8a8e]'} font-semibold`}>{item?.type}:</p>
                                    <p className='font-semibold'>{moment(item?.date).format('Do MMM YYYY, h:mm a')}</p>
                                    {item?.description && <p className='text-sm'>{item?.description}</p>}
                                </div>
                            </a>
                        </li>
                    )
                })
                }
            </ul>
        </WhiteContainer>
    );
};

export default OrderDatesDetails;
