import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToDeleteBrokerPricing } from '../../../../store/brokers/partners/pricing/pricingSlice';
import { tryToDeletePartner } from '../../../../store/brokers/admin/partners/partnersSlice';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Button from '../../../../shared/button';


interface IDeletePartnerModal {
    partnerId?: string;
    openModal: boolean;
    handleCloseModal: () => void;
}

const DeletePartnerModal = ({
    openModal,
    partnerId,
    handleCloseModal
}: IDeletePartnerModal) => {
    const dispatch = useAppDispatch();
    const [generalError, setGeneralError] = useState<string>()
    const [selectedOffers, setSelectedOffers] = useState<boolean>(false)
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const onCloseModal = () => {
        handleCloseModal();
        setSelectedOffers(false)
        setDeleteLoading(false)
        setGeneralError(undefined)
    }

    const onSubmit = async () => {
        setDeleteLoading(true)
        try {
            await dispatch(tryToDeletePartner(partnerId || '')).unwrap()
            if (selectedOffers) {
                const formatData = {
                    brokerId: partnerId || '',
                }
                await dispatch(tryToDeleteBrokerPricing(formatData)).unwrap()
            }
            onCloseModal()
            setGeneralError(undefined)
            setDeleteLoading(false)
        }
        catch (err) {
            setGeneralError(`${err || 'Something went wrong, please try again or contact our team'}`)
            setDeleteLoading(false)
        }
    }

    const onSelectOffers = () => {
        setSelectedOffers(!selectedOffers)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[400px]'}>
                <p className='mb-2 font-semibold text-sm'>Delete Partner</p>
                <p className='mb-6 text-sm'>If you select button below you can delete partner offers too.</p>
                {generalError && <Error text={generalError} />}

                <div className='mt-10 mb-5 flex flex-row justify-center'>
                    <Button
                        label='Delete Partner Offers'
                        dataQa={'delete-partner-offers'}
                        onClickButton={onSelectOffers}
                        className={`${selectedOffers ? 'btn-filters !rounded-none' : 'btn-noactive-filters !rounded-none'}`}
                    />
                </div>
                <div className='flex flex-col items-center'>
                    <Button
                        label={'Delete Partner'}
                        className={`${deleteLoading ? 'btn-main-disable !cursor-not-allowed' : 'btn-main'} my-8 min-w-[200px] !shadow-none`}
                        type='submit'
                        disabled={deleteLoading}
                        onClickButton={onSubmit}
                        dataQa={'delete-partner-btn'}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default DeletePartnerModal;