import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Loading from '../../../../shared/Loading';

interface ICreateCreditNoteModal {
    openModal: boolean;
    text: JSX.Element | string;
    loading?: boolean;
    handleCloseModal: () => void;
    onApproveAction: () => void;
}

const CreateCreditNoteModal = ({
    openModal,
    text,
    loading,
    handleCloseModal,
    onApproveAction,
}: ICreateCreditNoteModal) => {
    return (
        <Modal
            open={openModal}
            onClose={() => handleCloseModal()}
            dataQa={'close-button'}
            showInRight
            contentContainerStyle='p-6 min-w-[500px] bg-white rounded-lg shadow-lg'>
            <div className='mb-4'>
                <p className='text-gray-600 text-sm text-center'>Create Credit note</p>
                <hr className='mt-2' />
            </div>
            <div className='mb-4'>
                <p className='text-lg font-semibold text-gray-900'>Action Verification</p>
                <hr className='mt-2' />
            </div>
            <div className='bg-[#F8F9FC] p-4'>
                {text}
                <p className='text-gray-500 text-sm mb-4'>You can find the credit note under the Credit Notes tab.</p>
                <div className='flex justify-between'>
                    <Button
                        onClickButton={handleCloseModal}
                        label="No"
                        className="w-full mr-2 py-2 text-center rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300"
                    />
                    <Button
                        icon={loading && <Loading />}
                        label="Yes"
                        dataQa="approve-action"
                        disabled={loading}
                        onClickButton={() => !loading && onApproveAction()}
                        className={`w-full ml-2 py-2 rounded-md bg-gradient-to-r from-[#FCEE21] to-[#F7931E] text-[#202020]`}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default CreateCreditNoteModal;
