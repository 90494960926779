import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useAppDispatch } from '../../../../app/hooks';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import { tryToShipPackageToBroker } from '../../../../store/brokers/partners/multiple-cashout/multipleCashoutSlice';
import {
    tryToCreateReturnShippingNumber,
    tryToFetchAllPartnerCarriersByMarket,
    tryToFetchCarriers
} from '../../../../store/brokers/partners/carriers/carriersSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';


interface IShipItemsModal {
    openModal: boolean;
    assetTemplate?: string;
    items?: Array<any>;
    activeMarket?: IActiveMarket;
    warehouseSelected?: any;
    selectedBroker?: ISelectedValue;
    handleCloseModal: () => void;
    onSuccessfullyShipped: () => void;
}

type FormValues = {
    trackingNumber: string;
    carrier: string;
};

const ShipItemsModal = ({
    openModal,
    items,
    activeMarket,
    assetTemplate,
    warehouseSelected,
    selectedBroker,
    onSuccessfullyShipped,
    handleCloseModal,
}: IShipItemsModal) => {
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>()
    const [carriers, setCarriers] = useState<any>()
    const [trackingNumber, setTrackingNumber] = useState<string>()
    const [trackingNumberGenerated, setTrackingNumberGenerated] = useState<string>()
    const [carrierIsNotIntegrated, setCarrierIsNotIntegrated] = useState<boolean>(false)
    const [generateCarriers, setGenerateCarriers] = useState<any>()
    const [carrierSelected, setCarrierSelected] = useState<any>()
    const [generateCarrierSelected, setGenerateCarrierSelected] = useState<any>()
    const [loadingGenerate, setLoadingGenerate] = useState<boolean>(false)
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [invoiceUrl, setInvoiceUrl] = useState<any>()
    const [labelUrl, setLabelUrl] = useState<any>()
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    const {
        reset,
        formState: { errors },
    } = useForm<FormValues>({});

    const onGettingCarriersData = async () => {
        try {
            const response = await dispatch(tryToFetchCarriers(null)).unwrap()
            const formatCarriers = (response && response?.length > 0) && response?.map((item: any) => ({ ...item, label: item?.displayName, value: item?.name }))
            setCarriers(formatCarriers || undefined)
        } catch (err) {
            // error here
        }
    }

    const onGetttingPartnerCarriersData = async () => {
        try {
            const responseBrokers = await dispatch(tryToFetchAllPartnerCarriersByMarket({ marketId: activeMarket?.market?._id, type: 'outbound' })).unwrap()
            const formatBrokersCarriers = (responseBrokers && responseBrokers?.length > 0) && responseBrokers.map((item: any) => ({ ...item, name: item?.carrier?.displayName, label: item?.carrier?.displayName, value: item?.carrier?.name }))
            setGenerateCarriers(formatBrokersCarriers || undefined)
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        onGetttingPartnerCarriersData()
        onGettingCarriersData()
    }, [])

    const onCloseModal = () => {
        reset();
        handleCloseModal();
        setSaveError(undefined)
        setTrackingNumber(undefined)
        setTrackingNumberGenerated(undefined)
        setCarrierIsNotIntegrated(false)
        setGenerateCarrierSelected(undefined)
        setCarrierSelected(undefined)
        setLoadingGenerate(false)
        setLoadingSubmit(false)
        setInvoiceUrl(undefined)
        setLabelUrl(undefined)
        setShowSuccess(false)
    }

    const onSubmit = async () => {
        setLoadingSubmit(true)
        if ((!carrierIsNotIntegrated && (!trackingNumberGenerated || !generateCarrierSelected)) || (carrierIsNotIntegrated && (!trackingNumber || !carrierSelected))) {
            setSaveError('Please make sure you fill in all the fields to add return tracking number')
            setLoadingSubmit(false)
            return;
        }
        try {
            let formatData: any
            const itemIds = (items && items?.length > 0) && items?.map((item: any) => item?.itemId)
            if (carrierIsNotIntegrated) {
                formatData = {
                    trackingNumber: trackingNumber,
                    carrier: carrierSelected ? carrierSelected?.name || '' : '',
                    carrierId: carrierSelected ? carrierSelected?._id || '' : '',
                    invoiceUrl: invoiceUrl || undefined,
                    shippingUrl: labelUrl || undefined,
                    warehouseId: warehouseSelected?._id,
                    brokerId: selectedBroker?.value,
                    itemIds: itemIds
                }
            } else {
                formatData = {
                    trackingNumber: trackingNumberGenerated,
                    carrier: generateCarrierSelected ? generateCarrierSelected?.name || '' : '',
                    carrierId: generateCarrierSelected ? generateCarrierSelected?._id || '' : '',
                    invoiceUrl: invoiceUrl || undefined,
                    shippingUrl: labelUrl || undefined,
                    warehouseId: warehouseSelected?._id,
                    brokerId: selectedBroker?.value,
                    itemIds: itemIds
                }
            }
            await dispatch(tryToShipPackageToBroker(formatData)).unwrap()
            setShowSuccess(true)
            onSuccessfullyShipped();
        } catch (err) {
            setSaveError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const onGenerateReturnTracking = async () => {
        try {
            let totalWeight: any = 1000
            if (assetTemplate === 'clothing') {
                let weightCalculated = 0
                items && items?.length > 0 && items?.forEach((item: any) => {
                    weightCalculated = weightCalculated + Number(item?.displayQuestions?.[0]?.value || item?.exchangeDefinition?.counter?.computedWeight || 1)
                })
                totalWeight = weightCalculated * 1000
            }
            const itemIds = (items && items?.length > 0) ? items?.map((item) => item?.itemId) : []
            const itemsData = (items && items?.length > 0) ? items?.map((item: any) => {
                return {
                    'name': item?.itemName,
                    'qty': 1,
                    'price': Number(item?.price || 0.01).toFixed(2),
                    'currency': activeMarket?.currency?.descriptor?.name,
                    'hsCode': ''
                }
            }) : []
            let totalPrice = 0
            items && items?.length > 0 && items?.forEach((item: any) => {
                totalPrice = totalPrice + Number(item?.price || 0)
            })
            const shipmentDataFormat = {
                createShipment: {
                    'shipTo': {
                        'name': warehouseSelected?.name,
                        'contactName': warehouseSelected?.name,
                        'phone': warehouseSelected?.address?.phone,
                        'email': warehouseSelected?.address?.email,
                        'address1': warehouseSelected?.address?.street,
                        'address2': warehouseSelected?.address?.addressNo,
                        'city': warehouseSelected?.address?.city,
                        'country': warehouseSelected?.address?.country,
                        'postalCode': warehouseSelected?.address?.postalCode,
                        'shipperReference': warehouseSelected?._id
                    },
                    'shipmentInformation': {
                        'shipmentDate': moment(new Date()).format('YYYY-MM-DD'),
                        'packages': 1,
                        'totalWeight': totalWeight,
                        'weightUnitOfMeasure': 'grams',
                        'currency': activeMarket?.currency?.descriptor?.name,
                        'value': Number(totalPrice || 0.01).toFixed(2),
                        'description': 'wingpro',
                        'items': itemsData,
                        'serviceCode': activeMarket?.market?.name === 'US' ? 'ups' : 'TSN'
                    }
                },
                'labelType': 'Outbound',
                'url': true,
                'qrCode': false,
                'barcode': true,
                'orderId': warehouseSelected?._id,
                'itemIds': itemIds,
                'sourceRequesting': 'wingpro',
                'carrierLocation': {
                    warehouseId: warehouseSelected?._id,
                    country: activeMarket?.market?.name,
                    carrier: generateCarrierSelected ? generateCarrierSelected?.value || '' : '',
                },
            }
            const response = await dispatch(tryToCreateReturnShippingNumber(shipmentDataFormat)).unwrap()
            setInvoiceUrl(response?.invoiceUrl || undefined)
            setTrackingNumberGenerated(response?.trackingNumber || undefined)
            setLabelUrl(response?.imageUrl)
            setSaveError(undefined)
        } catch (err) {
            setSaveError(`${err}`)
        }
        setLoadingGenerate(false)
    }

    useEffect(() => {
        if (generateCarrierSelected && warehouseSelected) {
            onGenerateReturnTracking()
        }
    }, [generateCarrierSelected, warehouseSelected])

    const onChangeSelectedOption = (e: any) => {
        setCarrierSelected(e || undefined)
    }

    const onChangeSelectedOptionGenerate = (e: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'carrier':
                    setGenerateCarrierSelected(e || undefined)
                    setLoadingGenerate(true)
                    break;
            }
        }
    }

    const onClickCarrierNotCreated = () => {
        setCarrierIsNotIntegrated(!carrierIsNotIntegrated)
    }

    const onChangeReturnTrackingNumber = (e: any) => {
        setTrackingNumber(e || undefined)
    }

    return (
        <Modal
            open={openModal}
            showInRight={true}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[400px] max-w-[450px] mx-4'}>
                <p className='mt-2 mb-1 !text-[18px] mr-10'>Reship Selected Items</p>
                <div className='mt-2 mb-5 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                {saveError && <Error text={saveError} />}
                {showSuccess ?
                    <>
                        {carrierIsNotIntegrated ?
                            <div className='mt-3 mb-4 bg-[#f8f9fc] rounded px-4 py-3'>
                                <div className='flex flex-row justify-center mb-4'>
                                    <p>Package shipping process started successfully.</p>
                                </div>
                                <div className='flex flex-row justify-center my-5'>
                                    <Button
                                        label={'Close'}
                                        className={'btn-main'}
                                        onClickButton={onCloseModal}
                                    />
                                </div>
                            </div> :
                            <div className='mt-3 mb-4 bg-[#f8f9fc] rounded px-4 py-3'>
                                <div className='flex flex-row justify-center mb-4'>
                                    <p>Package shipping process started successfully, below you can see shipping label or later you can find in Reshipped Section if you generated tracking number.</p>
                                </div>
                                <div className='flex flex-row justify-center my-5'>
                                    <a
                                        rel='noreferrer'
                                        data-qa={'shipping-label'}
                                        href={`${labelUrl}`}
                                        target='_blank'
                                        className={carrierIsNotIntegrated ? 'btn-main-disable' : 'btn-main'}>
                                        Get Label
                                    </a>
                                </div>
                            </div>
                        }
                    </>
                    : <>
                        <div className='mt-3 mb-4 bg-[#f8f9fc] rounded px-4 py-3'>
                            <p className='font-medium text-sm mb-5 mt-3'>Confirm the Carrier and Create Tracking Number:</p>
                            <div>
                                <div className='my-4'>
                                    <p className='font-medium'>Warehouse: {warehouseSelected?.name}</p>
                                </div>
                                <SelectCheckbox
                                    selectedOption={generateCarrierSelected}
                                    key={1}
                                    disabled={carrierIsNotIntegrated}
                                    placeholder='Carrier'
                                    containerStyle='mb-1 max-w-[90%]'
                                    isLoading={loadingGenerate}
                                    onChangeSelectedOption={(e) => onChangeSelectedOptionGenerate(e, 'carrier')}
                                    options={generateCarriers || []}
                                    dataQa={'carrieer-selected'}
                                />
                                <Input
                                    placeholder='Tracking Number'
                                    dataQa={'return-tracking-number'}
                                    showValue={true}
                                    inputValue={trackingNumberGenerated}
                                    disabled={true}
                                    inputStyle={'max-w-[90%]'}
                                    error={errors.trackingNumber?.message}
                                />
                            </div>
                        </div>
                        <div className='mt-3 mb-4 bg-[#f8f9fc] rounded px-4 py-3'>
                            <div className='flex flex-row justify-start items-center my-4 min-w-[100%]'>
                                <div className='flex items-center mr-2'>
                                    <p className='font-semibold mr-1 text-[16px]'>
                                        Carrier is not Integrated?
                                    </p>
                                    <input
                                        onChange={onClickCarrierNotCreated}
                                        checked={carrierIsNotIntegrated}
                                        id='checkbox-all'
                                        type='checkbox'
                                        data-qa={'checkbox'}
                                        className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                    />
                                    <label htmlFor='checkbox-all' className='sr-only'>
                                        checkbox
                                    </label>
                                </div>
                            </div>
                            {carrierIsNotIntegrated &&
                                <>
                                    <SelectCheckbox
                                        selectedOption={carrierSelected}
                                        key={1}
                                        placeholder='Carrier'
                                        dataQa={'carrier-selected'}
                                        containerStyle='mb-1 max-w-[90%]'
                                        onChangeSelectedOption={(e) => onChangeSelectedOption(e)}
                                        options={carriers || []}
                                    />
                                    <Input
                                        placeholder='Tracking Number'
                                        dataQa={'tracking-number'}
                                        showValue={true}
                                        inputValue={trackingNumber}
                                        onChangeInput={onChangeReturnTrackingNumber}
                                        inputStyle={'max-w-[90%]'}
                                        error={errors.trackingNumber?.message}
                                    />
                                </>
                            }
                        </div>
                        <div className='min-w-full flex flex-row justify-center'>
                            <Button
                                label={'Reship Items To Broker'}
                                dataQa={'reship-items'}
                                className={`${loadingSubmit ? 'btn-main-disable' : 'btn-main'} my-3 min-w-[130px] !shadow-none`}
                                onClickButton={() => !loadingSubmit && onSubmit()}
                            />
                        </div>
                    </>}
            </div>
        </Modal>
    )
}

export default ShipItemsModal;