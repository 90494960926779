import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { tryToFetchAssetValues } from '../../../../store/brokers/admin/assets/assetsSlice';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToAcceptOrder, tryToFetchImeiStatusCheck } from '../../../../store/brokers/partners/orders/ordersSlice';
import { tryToFetchAllWarehouseSections } from '../../../../store/brokers/admin/partners/partnersSlice';
import { tryToFetchSectionLocations } from '../../../../store/brokers/admin/warehouses/warehousesSlice';
import RevaluateInventoryFields from '../details/RevaluateInventoryFields';
import OrderItemLocation from '../details/OrderItemLocation';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';


interface IAcceptWithImeiModal {
    openImeiModal: boolean;
    itemId: string;
    orderId: string;
    imeiData?: any;
    warehouseId?: any;
    activeMarket?: IActiveMarket;
    userData?: any;
    category?: string;
    descriptor?: any;
    handleCloseModal: () => void;
    setRevisedOfferModal: React.Dispatch<React.SetStateAction<undefined>>
}

const AcceptWithImeiModal = ({
    openImeiModal,
    itemId,
    orderId,
    warehouseId,
    imeiData,
    activeMarket,
    descriptor,
    userData,
    category,
    handleCloseModal,
    setRevisedOfferModal,
}: IAcceptWithImeiModal) => {
    const dispatch = useAppDispatch();
    const [imei, setImei] = useState<any>();
    const [sections, setSections] = useState<any>();
    const [imeiError, setImeiError] = useState<string>();
    const [imeiStatusRes, setImeiStatusRes] = useState<any>();
    const [extendedFields, setExtendedFields] = useState<any>();
    const [templateFields, setTemplateFields] = useState<any>();
    const [selectedSection, setSelectedSection] = useState<any>();
    const [scannedLocation, setScannedLocation] = useState<any>();
    const [invertoryFields, setInvertoryFields] = useState<any>();
    const [selectedLocation, setSelectedLocation] = useState<any>();
    const [extendedFormData, setExtendedFormData] = useState<any>();
    const [imeiNotExist, setImeiNotExist] = useState<boolean>(false);
    const [inventoryFormData, setInventoryFormData] = useState<any>();
    const [filteredLocations, setFilteredLocations] = useState<any>();
    const [searchInDBLocation, setSearchInDBLocation] = useState<any>();
    const [loadingShippment, setLoadingShippment] = useState<boolean>(false);
    const [inventoryFilterErrors, setInventoryFilterErrors] = useState<any>();
    const [loadingImeiStatus, setLoadingImeiStatus] = useState<boolean>(false);
    const [searchFieldLocation, setSearchFieldLocation] = useState<any | null>(null);
    const [loadingOptionsLocation, setLoadingOptionsLocation] = useState<boolean>(false);
    const [responseLocations, setResponseLocations] = useState<any>();
    const [findBarcodeLocationsResponse, setFindBarcodeLocationsResponse] = useState<any>();

    const onCloseModal = () => {
        handleCloseModal();
        setImeiError(undefined);
        setLoadingShippment(false);
        setImei(undefined);
        setImeiStatusRes(undefined);
        setImeiNotExist(false);
        setLoadingImeiStatus(false);
        setSearchFieldLocation(undefined)
        setSearchInDBLocation(undefined)
        setFilteredLocations(undefined)
        setResponseLocations(undefined)
        setSelectedSection(undefined)
        setScannedLocation(undefined)
        setSelectedLocation(undefined)
        document.body.style.overflow = 'auto';
    }

    const onResponseNotFound = () => {
        setSearchFieldLocation(undefined)
        setSearchInDBLocation(undefined)
        setFilteredLocations(undefined)
        setResponseLocations(undefined)
    }

    const onClickImeiNotExist = () => {
        if (!imeiNotExist) {
            setImei(undefined)
        }
        setImeiNotExist(!imeiNotExist)
        setImeiStatusRes(undefined)
    }

    const onGettingAllAssetFields = async () => {
        if (category) {
            try {
                const assetSelected = category
                const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(assetSelected)).unwrap()
                if (response?.inventoryFields) {
                    setInvertoryFields(response?.inventoryFields)
                    setExtendedFields(response?.extendedFields)
                }
            } catch (err) {
                // error here
            }
        }
    }


    const onGettingFieldValues = async () => {
        if (category) {
            try {
                let data: any = {
                    exclude: false,
                    values: []
                }
                if (descriptor) {
                    const keysArray = descriptor && Object.keys(descriptor)
                    const formatValues = keysArray && keysArray?.length > 0 && keysArray?.map((name: string) => { return { [name]: descriptor?.[name] } })
                    data = {
                        ...data || {},
                        values: formatValues || []
                    }
                }
                const response = await dispatch(tryToFetchAssetValues({ id: category, data })).unwrap()
                setTemplateFields(response || [])
            } catch (err) {
                // error here
            }
        }
    }

    useEffect(() => {
        if (category) {
            onGettingAllAssetFields()
        }
    }, [category])

    useEffect(() => {
        if (descriptor && category) {
            onGettingFieldValues()
        }
    }, [descriptor, category])

    useEffect(() => {
        setImei(imeiData)
    }, [imeiData])

    const onChangeImeiSearch = (value: string | null | undefined, type?: string) => {
        const noSpacesRegex: any = /^[^\s]*$/;
        // const min15DigitsRegex: any = /^\d{0,16}$/;
        if (!noSpacesRegex.test(value)) {
            setImeiError('Spaces are not allowed.');
            return;
        }
        // if (!min15DigitsRegex.test(value)) {
        //     setImeiError('Minimum number of characters is 14 and only numbers are allowed.');
        //     return;
        // }
        setImei(value || undefined)
        setImeiError(undefined)
    }

    const onCheckImeiStatus = async () => {
        if (!imei) {
            setImeiError('Please write IMEI or fill the checkbox that IMEI doesn\'t exists.')
            return
        }
        setLoadingImeiStatus(true)
        try {
            const formatData = {
                imei: imei
            }
            const res = await dispatch(tryToFetchImeiStatusCheck({ orderId, itemId, data: formatData })).unwrap()
            setImeiError(undefined)
            return res?.data?.blackliststatus
        } catch (err: any) {
            setImeiError(`${err}`)
            setImeiStatusRes(undefined)
        }
        setLoadingImeiStatus(false)
    }

    const onEnterImei = async (e: any) => {
        if (loadingImeiStatus) {
            return;
        }
        e.stopPropagation()
        if (e?.key === 'Enter') {
            const response = await onCheckImeiStatus()
            setImeiStatusRes(response)
            setLoadingImeiStatus(false)
        }
    }

    const onAcceptShipment = async () => {
        if (loadingShippment) {
            return;
        }
        if (!imeiNotExist && !imei) {
            setLoadingShippment(false)
            setImeiError('Please write IMEI or fill the checkbox that IMEI doesn\'t exists.')
            return
        } else if (!imeiNotExist && imeiStatusRes && (imeiStatusRes || '')?.toLowerCase() === 'yes') {
            setLoadingShippment(false)
            return;
        } else if (!imeiStatusRes && !imeiNotExist) {
            const response = await onCheckImeiStatus()
            setImeiStatusRes(response)
            if (response && (response || '')?.toLowerCase() === 'yes') {
                setLoadingShippment(false)
                return;
            }
        }
        if (userData?.inventory && sections && (responseLocations || filteredLocations) && !selectedLocation) {
            setImeiError('Please select item location.')
            setLoadingShippment(false)
            return;
        }
        let inventoryErrors: { [key: string]: string } | undefined;
        if (invertoryFields && invertoryFields?.length > 0) {
            invertoryFields?.map((item: any) => {
                if (!inventoryFormData?.[item?.name] && item?.required) {
                    inventoryErrors = {
                        ...(inventoryErrors || {}),
                        [item?.name]: `${item?.name} is required`
                    }
                }
                return item
            })
        }
        if (inventoryErrors) {
            setInventoryFilterErrors(inventoryErrors)
            return;
        }
        try {
            setInventoryFilterErrors(undefined)
            setLoadingShippment(true)
            let formatData: any = {
                orderId: orderId || '',
                data: {
                    itemId: itemId || '',
                    marketId: activeMarket?.market?._id,
                },
            };
            if (userData?.inventory && selectedLocation?._id) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        location: selectedLocation?._id
                    }
                }
            }
            if (imeiNotExist) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        imeiNotExisting: true
                    }
                }
            } else {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        itemCode: imei
                    }
                }
            }
            if (invertoryFields && inventoryFormData) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        inventory: inventoryFormData
                    }
                }
            }
            if (extendedFields && extendedFormData) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        extended: extendedFormData
                    }
                }
            }
            const data = itemId && orderId && await dispatch(tryToAcceptOrder(formatData)).unwrap();
            onCloseModal();
            if (data?.reviseInfo) {
                setRevisedOfferModal(data.reviseInfo)
            }
        } catch (err) {
            setImeiError(`${err}`)
        }
        setLoadingShippment(false)
    };

    const onGettingWarehouseSections = async () => {
        try {
            const response = await dispatch(tryToFetchAllWarehouseSections({ warehouseId: warehouseId || '', type: 'space', category: category })).unwrap()
            const formatResponse = response && response?.length > 0 && response?.map((item: any) => ({ ...item, value: item?._id, label: item?.name }))
            setSections(formatResponse || undefined)
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (userData?.inventory && warehouseId) {
            onGettingWarehouseSections()
        }
    }, [userData?.inventory, warehouseId])

    const onClickClearLocationAndSection = () => {
        setSelectedSection(undefined)
        setScannedLocation(undefined)
        setSearchFieldLocation(undefined)
        setFilteredLocations(undefined)
        setSearchInDBLocation(undefined)
        setResponseLocations(undefined)
        setSelectedLocation(undefined)
    }

    const onFindIfThereIsSpaceInSelectedLocation = async () => {
        if (selectedSection || scannedLocation) {
            try {
                const response: any = await dispatch(tryToFetchSectionLocations({ barcode: selectedSection?.barcode || scannedLocation }))?.unwrap()
                if (response && response?.length > 0) {
                    const formatResponse = response && response?.length > 0 && response?.map((item: any) => {
                        let formatLabel: any
                        item?.descriptor && (Object?.keys(item?.descriptor))?.forEach((obj: any, idx: number) => {
                            formatLabel = idx === 0 ? item?.descriptor?.[obj] : formatLabel + ` - ${item?.descriptor?.[obj]}`
                        })
                        return {
                            ...item,
                            value: item?._id,
                            label: formatLabel,
                            name: formatLabel,
                            fullName: item?.fullName
                        }
                    })
                    setResponseLocations(formatResponse || undefined)
                    setFindBarcodeLocationsResponse(undefined)
                } else if (response && response?.length === 0) {
                    onResponseNotFound()
                    setFindBarcodeLocationsResponse('No available capacity in this location!')
                } else {
                    onResponseNotFound()
                    setFindBarcodeLocationsResponse('Not found any location for the Barcode!')
                }
            } catch (err) {
                setFindBarcodeLocationsResponse(`${err}`)
            }
        }
    }

    const onChangeSelectedSection = (e: any, type?: string) => {
        setSelectedSection(e)
        setScannedLocation(undefined)
    }

    const onChangedScannedLocation = (e: any, type?: string) => {
        setScannedLocation(e)
        setSelectedSection(undefined)
        setResponseLocations(undefined)
    }

    const onEnterAction = async (e: any, type?: string) => {
        e.stopPropagation()
        if (e?.key === 'Enter') {
            if (scannedLocation) {
                onFindIfThereIsSpaceInSelectedLocation()
            }
        }
    }

    const onChangeSelectedLocation = (e: any, type?: string) => {
        if (type) {
            setSelectedLocation(e)
            if (searchFieldLocation) {
                setSearchFieldLocation(undefined)
            }
            setFindBarcodeLocationsResponse(undefined)
        }
    }

    const onMenuCloseAfterSearchLocation = () => {
        setSearchFieldLocation(undefined)
    }

    const onClickSearchInDbLocation = () => {
        if (searchInDBLocation) {
            setFilteredLocations(undefined)
            setLoadingOptionsLocation(true)
            setSearchFieldLocation(searchInDBLocation)
        }
    }

    const onChangeSearchLocationFields = (e: any, type?: string) => {
        setSearchInDBLocation(e)
        if (e) {
            if (responseLocations) {
                const formatSearch = e ? e?.split?.(' ').join('')?.replace('-', '') : null
                const formatFilteredFields = (responseLocations && responseLocations?.length > 0) && responseLocations?.filter((next: any) => (next?.fullName || '').toLowerCase().includes((formatSearch || '')?.toLowerCase()))
                if (formatFilteredFields && formatFilteredFields?.length > 0) {
                    setSearchInDBLocation(e)
                    setLoadingOptionsLocation(false)
                    setFilteredLocations(formatFilteredFields)
                }
                else {
                    setFilteredLocations(undefined)
                    setLoadingOptionsLocation(true)
                    setSearchFieldLocation(e)
                }
            } else {
                setSearchFieldLocation(e)
            }
        } else {
            setFilteredLocations(undefined)
        }
    }

    useEffect(() => {
        if (selectedSection) {
            onFindIfThereIsSpaceInSelectedLocation()
        }
    }, [selectedSection])

    const onGettingFilteredLocations = async () => {
        try {
            const formatSearch = searchFieldLocation ? searchFieldLocation?.split?.(' ').join('')?.replace('-', '') : searchFieldLocation
            const response: any = await dispatch(tryToFetchSectionLocations({ barcode: selectedSection?.value || scannedLocation, data: { search: formatSearch } }))?.unwrap()
            const formatResponse = (response && response?.length > 0) && response?.map((item: any) => {
                let formatLabel: any
                item?.descriptor && (Object?.keys(item?.descriptor))?.forEach((obj: any, idx: number) => {
                    formatLabel = idx === 0 ? item?.descriptor?.[obj] : formatLabel + ` - ${item?.descriptor?.[obj]}`
                })
                return {
                    ...item,
                    value: item?._id,
                    label: formatLabel,
                    name: formatLabel,
                    fullName: item?.fullName
                }
            })
            setFilteredLocations(formatResponse || [])
            setLoadingOptionsLocation(false)
        } catch (err) {
            // error here
        }
        setLoadingOptionsLocation(false)
        setSearchFieldLocation(null)
    }

    useEffect(() => {
        if (searchFieldLocation !== null) {
            onGettingFilteredLocations()
        }
    }, [searchFieldLocation])

    const onChangeSelectedInventoryValue = (value: any, type?: string) => {
        if (type) {
            const findField = invertoryFields && invertoryFields?.length > 0 && invertoryFields?.find((field: any) => field?.name === type);
            setInventoryFormData({
                ...(inventoryFormData || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
        }
    }

    const onChangeFilterInventoryInput = (value: string | null | undefined, type: string) => {
        const findField = invertoryFields && invertoryFields?.length > 0 && invertoryFields?.find((field: any) => field?.name === type);
        setInventoryFormData({
            ...(inventoryFormData || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
    };

    const onChangeSelectedExtendedValue = (value: any, type?: string) => {
        if (type) {
            const findField = extendedFields && extendedFields?.length > 0 && extendedFields?.find((field: any) => field?.name === type);
            setExtendedFormData({
                ...(extendedFormData || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
        }
    }

    const onChangeFilterExtendedInput = (value: string | null | undefined, type: string) => {
        const findField = extendedFields && extendedFields?.length > 0 && extendedFields?.find((field: any) => field?.name === type);
        setExtendedFormData({
            ...(extendedFormData || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
    };

    return (
        <Modal
            open={openImeiModal}
            onClose={onCloseModal}
            showInRight={true}
            contentContainerStyle={'min-w-[650px]'}>
            <div className={'p-2 min-w-[600px]'}>
                <p className='mt-2 mb-1 !text-[18px] mr-10'>Accept Form</p>
                <div className='mt-2 mb-5 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                {imeiError && <Error text={imeiError} />}
                {userData?.inventory &&
                    <OrderItemLocation
                        loadingOptions={loadingOptionsLocation}
                        filteredLocations={(filteredLocations && filteredLocations?.length > 0) ? filteredLocations : responseLocations}
                        searchInDBLocation={searchFieldLocation}
                        selectedItemLocation={selectedLocation}
                        sections={sections}
                        inputStyle={'min-w-[200px]'}
                        selectedSection={selectedSection}
                        scannedLocation={scannedLocation}
                        findBarcodeLocationsResponse={findBarcodeLocationsResponse}
                        onChangeSearchLocationField={onChangeSearchLocationFields}
                        onChangeSelectedLocation={onChangeSelectedLocation}
                        onClickSearchInDb={onClickSearchInDbLocation}
                        onChangeSelectedSection={onChangeSelectedSection}
                        onEnterAction={onEnterAction}
                        onMenuCloseAfterSearch={onMenuCloseAfterSearchLocation}
                        onChangedScannedLocation={onChangedScannedLocation}
                        onClickClearLocationAndSection={onClickClearLocationAndSection}
                    />
                }
                <div className='bg-[#f8f9fc] rounded px-4 pt-3 pb-1 my-4'>
                    {imeiStatusRes && <p className={imeiStatusRes === 'Yes' ? 'text-red-500' : 'text-black'}>{imeiStatusRes === 'Yes' ? 'Certain accept cannot proceed due to the blacklisted IMEI associated with this item. To proceed, a change of IMEI is required.' : 'IMEI status: clean.'}</p>}
                    <div className='flex flex-row items-center'>
                        <p className='mb-2 mr-3'>IMEI</p>
                        <Input
                            containerStyle='mb-0 mr-3 min-w-[300px]'
                            dataQa={'imei'}
                            showValue={true}
                            placeholder={' '}
                            disabled={imeiNotExist}
                            // error={imeiError}
                            inputValue={imei}
                            onHandleKeyDown={onEnterImei}
                            onChangeInput={onChangeImeiSearch}
                        />
                        <div className='flex flex-row items-center mb-6'>
                            <div className='flex items-center mr-2'>
                                <input
                                    onChange={() => onClickImeiNotExist()}
                                    checked={imeiNotExist}
                                    data-qa={'checkbox'}
                                    id='checkbox-all'
                                    type='checkbox'
                                    className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                />
                                <label htmlFor='checkbox-all' className='sr-only'>
                                    checkbox
                                </label>
                            </div>
                            <p>
                                IMEI doesn&apos;t exist.
                            </p>
                        </div>
                    </div>
                    {/* <Button
                        label={loadingImeiStatus ? 'Loading...' : 'Add'}
                        dataQa={loadingImeiStatus ? 'loading...' : 'add'}
                        className={loadingImeiStatus || imeiNotExist ? 'btn-main-disable mt-2 ml-3' : 'btn-main mt- ml-3'}
                        onClickButton={() => !loadingImeiStatus && !imeiNotExist && onCheckImeiStatus()}
                    /> */}
                </div>
                <div className='bg-[#f8f9fc] rounded px-4 pt-3 pb-1 my-4'>
                    <RevaluateInventoryFields
                        loadingValues={false}
                        filteredFields={templateFields}
                        inventoryItems={invertoryFields || []}
                        filterInventoryValues={inventoryFormData}
                        assetTemplateSelected={category}
                        filterExtendedValues={extendedFormData}
                        extendedItems={extendedFields || []}
                        inventoryFilterErrors={inventoryFilterErrors}
                        onChangeInventoryInput={onChangeFilterInventoryInput}
                        onChangeSelectedInventoryValue={onChangeSelectedInventoryValue}
                        onChangeExtendedInput={onChangeFilterExtendedInput}
                        onChangeSelectedExtendedValue={onChangeSelectedExtendedValue}
                    />
                </div>
                <div className='min-w-full flex flex-row justify-center'>
                    <Button
                        label={loadingShippment ? 'Accepting...' : 'Accept Order'}
                        dataQa={loadingShippment ? 'accepting...' : 'accept-order'}
                        className={`${loadingShippment ? 'btn-main-disable' : 'btn-main'} my-3 min-w-[130px] !shadow-none`}
                        onClickButton={() => !loadingShippment && onAcceptShipment()}
                    />
                </div>
            </div>
        </Modal >
    )
}

export default AcceptWithImeiModal;