import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { PRICE_EFFECT_TYPES, EFFECT_TYPES } from '../../../../utils/constants/price-effect-types';
import { ILanguage } from '../../../../interfaces/languages/ILanguage';
import { IPayServices } from '../../../../interfaces/pay-services/IPayServices';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import {
    tryToAddQuestionService,
    tryToEditQuestionService,
    tryToFetchSingleQuestion,
    tryToGetAllLanguages
} from '../../../../store/brokers/shared-endpoints/questions/questionsSlice';
import { tryToFetchAllPayServices } from '../../../../store/brokers/admin/pay-services/payServicesSlice';
import AnswerDescriptionModal from './AnswerDescriptionModal';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IQuestionDetailsModal {
    openQuestionDetailsModal: boolean;
    questionId?: string;
    serviceId?: string;
    isCopy: boolean;
    mainScreenRequest?: boolean;
    handleCloseQuestionDetailsModal: () => void;
    onSaveChanges: () => void;
}

type FormValues = {
    id: string;
    required: boolean;
    order: number;
};

const QuestionDetailsModal = ({
    openQuestionDetailsModal,
    questionId,
    serviceId,
    isCopy,
    mainScreenRequest,
    handleCloseQuestionDetailsModal,
    onSaveChanges,
}: IQuestionDetailsModal) => {
    const dispatch = useAppDispatch();
    const [selectedService, setSelectedService] = useState<any>()
    const [services, setServices] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [options, setOptions] = useState<any>()
    const [generalError, setGeneralError] = useState<string>()
    const [allLanguages, setAllLanguages] = useState<Array<ILanguage & ISelectedValue>>()
    const [showDescriptionModal, setShowDescriptionModal] = useState<{ show: boolean, itemId: string, answer?: string, languages?: { [key: string]: string }, description?: string }>()
    const questionDetails = useAppSelector(state => state.questions?.questionDetails)
    const serviceDetails = useAppSelector(state => state.questions.questionDetails?.services?.find(child => child.id === serviceId))

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue,
        watch,
    } = useForm<FormValues>({});

    const getQuestion = () => {
        if (questionId) {
            dispatch(tryToFetchSingleQuestion(questionId));
        }
    };

    const onGettingAllLanguages = async () => {
        try {
            const response = await dispatch(tryToGetAllLanguages()).unwrap()
            const formatResponse = response?.length > 0 && response?.map((item: ILanguage) => {
                return {
                    ...item || {},
                    value: item?.code,
                    label: item?.name
                }
            })
            setAllLanguages(formatResponse || undefined)
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (mainScreenRequest && questionId && openQuestionDetailsModal) {
            getQuestion()
        }
    }, [mainScreenRequest, openQuestionDetailsModal, questionId])

    const getPayServicesData = async () => {
        const payServicesResponse = await dispatch(tryToFetchAllPayServices()).unwrap()
        const formatPayServices = (payServicesResponse && payServicesResponse?.data && payServicesResponse?.data?.length > 0)
            && payServicesResponse?.data?.map((service: IPayServices) => ({ ...service, label: service?.displayName, value: service?._id, name: service?.displayName }))
        setServices(formatPayServices || [])
    }

    useEffect(() => {
        getPayServicesData()
        if (!allLanguages) {
            onGettingAllLanguages()
        }
    }, [])

    useEffect(() => {
        if (serviceId && serviceDetails) {
            setValue('id', serviceDetails?.id || '')
            setValue('required', serviceDetails?.required || false)
            setValue('order', serviceDetails?.order)
            if (serviceDetails?.id) {
                const findService = (services && services?.length > 0) && services?.find((item: any) => item?._id === serviceDetails?.id)
                setSelectedService(findService || undefined)
            }
            if (serviceDetails?.options) {
                setOptions(serviceDetails?.options)
            }
        } else {
            setSelectedService(undefined)
            setOptions(undefined);
            reset();
        }
    }, [serviceDetails, serviceId, openQuestionDetailsModal]);

    const onCloseModal = () => {
        reset();
        setOptions(undefined);
        setGeneralError(undefined);
        handleCloseQuestionDetailsModal();
        setSelectedService(undefined);
        document.body.style.overflow = 'auto';
    };

    const onSubmit = async (data: FormValues) => {
        if (loading) {
            return;
        }
        setLoading(true)
        const payload: any = {
            ...data,
            order: questionDetails?.type !== 'PRIMARY' ? data.order : undefined,
            required: data?.required || false,
            options
        };
        try {
            if (serviceId && !isCopy) {
                const dataFormat = {
                    data: {
                        ...payload || {},
                        id: data?.id,
                    },
                    serviceId,
                    id: questionId || ''
                }
                await dispatch(tryToEditQuestionService(dataFormat)).unwrap()
            }
            else {
                const findIfAllSelected = selectedService?.length > 0 && selectedService?.find((item: ISelectedValue) => item?.value === 'all')
                const dataFormat = {
                    data: {
                        ...payload || {},
                        id: undefined,
                        payServices: findIfAllSelected ? undefined : selectedService?.map((item: any) => item?.value) || undefined,
                        allServices: !!findIfAllSelected
                    },
                    id: questionId || ''
                }
                await dispatch(tryToAddQuestionService(dataFormat)).unwrap();
            }
            setGeneralError(undefined)
            onSaveChanges()
            reset();
            onCloseModal()
        } catch (error) {
            setGeneralError(`${error}`)
        }
        setLoading(false)
    };

    const onChangeSelectedService = (e: any) => {
        setSelectedService(e);
        setValue('id', serviceId && !isCopy ? e?.value : e && e?.[0] ? e?.[0]?.value : '')
    };

    const onClickCheckbox = (value: string) => {
        if (value === 'required') {
            setValue('required', !watch('required'))
        }
    }

    const onChangePricingEffectSelectedValue = (value: any, type?: string, index?: any) => {
        if (type) {
            const findAnswer: any = questionDetails?.answers?.options && questionDetails?.answers?.options?.length > 0 && questionDetails?.answers?.options?.find((item: any) => item?.id === index)
            const findIfOptionExist = options && options?.length > 0 && options?.find((item: any, idx: number) => item?.id === index)
            if (findIfOptionExist) {
                const formatOptions = options && options?.length > 0 && options?.map((item: any, idx: number) => {
                    if (item?.id === index) {
                        return {
                            ...item,
                            effect: {
                                ...item?.effect || {},
                                [type]: value?.value
                            }
                        }
                    } else {
                        return item
                    }
                })
                setOptions(formatOptions)
            } else {
                setOptions([
                    ...options || [],
                    {
                        id: index,
                        answer: findAnswer?.answer,
                        effect: {
                            [type]: value?.value
                        }
                    }
                ])
            }
        }
    }

    const onChangeInputPricingEffectValue = (value: string | undefined | null, type?: string, index?: any) => {
        if (type) {
            const findAnswer: any = questionDetails?.answers?.options && questionDetails?.answers?.options?.length > 0 && questionDetails?.answers?.options?.find((item: any) => item?.id === index)
            const findIfOptionExist = options && options?.length > 0 && options?.find((item: any, idx: number) => item?.id === index)
            if (findIfOptionExist) {
                const formatOptions = options && options?.length > 0 && options?.map((item: any, idx: number) => {
                    if (item?.id === index) {
                        return {
                            ...item,
                            effect: {
                                ...item?.effect || {},
                                [type]: type === 'description' ? value : value ? (/^\d*\.?\d*$/).test(value) ? value : item?.[type] : undefined
                            }
                        }
                    } else {
                        return item
                    }
                })
                setOptions(formatOptions)
            } else {
                setOptions([
                    ...options || [],
                    {
                        id: index,
                        answer: findAnswer?.answer,
                        effect: {
                            [type]: type === 'description' ? value : value && (/^\d*\.?\d*$/).test(value) ? value : undefined
                        }
                    }
                ])
            }
        }
    }

    const onSaveAnswerDescription = (index: string, description?: string, languages?: { [key: string]: string }) => {
        const findAnswer: any = questionDetails?.answers?.options && questionDetails?.answers?.options?.length > 0 && questionDetails?.answers?.options?.find((item: any) => item?.id === index)
        const findIfOptionExist = options && options?.length > 0 && options?.find((item: any, idx: number) => item?.id === index)
        if (findIfOptionExist) {
            const formatOptions = options && options?.length > 0 && options?.map((item: any, idx: number) => {
                if (item?.id === index) {
                    return {
                        ...item,
                        description: description,
                        descriptionLanguages: languages,
                    }
                } else {
                    return item
                }
            })
            setOptions(formatOptions)
        } else {
            setOptions([
                ...options || [],
                {
                    id: index,
                    answer: findAnswer?.answer,
                    description: description,
                    descriptionLanguages: languages,
                }
            ])
        }
    }

    const onClickManageAnswerDescription = (answer: string, itemId: string, description?: string, langDesc?: { [key: string]: string }) => {
        setShowDescriptionModal({
            show: true,
            itemId: itemId,
            answer: answer,
            description: description,
            languages: langDesc
        })
    }

    return (
        <Modal
            open={openQuestionDetailsModal}
            showInRight={true}
            onClose={onCloseModal}
            contentContainerStyle={'min-w-[650px]'}>
            <div className={'p-2 min-w-[600px]'}>
                <p className='mt-2 mb-4 page-title !text-[18px] font-bold mr-10'>{serviceId && !isCopy ? 'Edit Service' : 'Add Service'}</p>
                {generalError && <Error text={generalError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-col my-4'>
                        <div>
                            <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                                <Controller
                                    control={control}
                                    defaultValue={selectedService}
                                    name='id'
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Service is required.',
                                        },
                                    }}
                                    render={({ onChange, value, name, ref }: any) => (
                                        <SelectCheckbox
                                            ref={ref}
                                            selectedOption={selectedService}
                                            key={1}
                                            placeholder=' '
                                            disabled={!!serviceId && !isCopy}
                                            dataQa={'service'}
                                            name='Service'
                                            containerStyle='mb-1 max-w-[90%]'
                                            error={errors?.id?.message}
                                            multiple={(!serviceId || isCopy)}
                                            onChangeSelectedOption={(e) => onChangeSelectedService(e)}
                                            options={services && (!serviceId || (serviceId && isCopy)) ? [{ value: 'all', label: 'All Services' }, ...services || []] : services || []}
                                        />
                                    )} />
                            </div>
                            {questionDetails?.type !== 'PRIMARY' &&
                                <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                                    <Input
                                        placeholder='Order'
                                        dataQa={'question-order'}
                                        label={'Order'}
                                        type='text'
                                        containerStyle='mb-1 max-w-[90%]'
                                        register={register('order', {
                                            required: {
                                                message: 'Order is required',
                                                value: true,
                                            },
                                            pattern: {
                                                value: /^-?[0-9]\d*\.?\d*$/,
                                                message: 'Only numbers are accepted.'
                                            },
                                            validate: (value: number) => (Number(value) >= 0) || 'Order should be a positive number'
                                        })}
                                        error={errors.order?.message}
                                    />
                                </div>
                            }
                            <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                                <div className='flex flex-row justify-start items-center min-w-[100%]'>
                                    <div className='flex items-center mr-2'>
                                        <input
                                            onChange={() => onClickCheckbox('required')}
                                            checked={watch('required') || false}
                                            id='checkbox-all-1'
                                            type='checkbox'
                                            data-qa={'checkbox'}
                                            className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-yellow-300 rounded focus:accent-yellow-300 focus:ring-2'
                                        />
                                        <label htmlFor='checkbox-all-1' className='sr-only'>
                                        </label>
                                    </div>
                                    <p className='text-[#a4a4a4]'>
                                        Is this question required for this service?
                                    </p>
                                </div>
                            </div>
                            {(questionDetails && questionDetails?.answers && questionDetails?.answers?.options && questionDetails?.answers?.options?.length > 0) && <p className='block text-primary-light font-semibold text-base mt-5 mb-2'>Answers Price Effect</p>}
                            {(questionDetails && questionDetails?.answers && questionDetails?.answers?.options && questionDetails?.answers?.options?.length > 0)
                                && questionDetails?.answers?.options?.map((item: any, index: number) => {
                                    const findOptions = options && options?.length > 0 && options?.find((option: any) => option?.id === item?.id)
                                    return (
                                        <div key={index} className='bg-[#f8f9fc] rounded p-2 !mb-4'>
                                            <p className='block font-semibold mb-4'>Answer {index + 1}: {item?.answer}</p>
                                            <SelectCheckbox
                                                selectedOption={findOptions?.effect?.type ? PRICE_EFFECT_TYPES?.find((effect) => effect?.value === findOptions?.effect?.type) : undefined}
                                                placeholder=' '
                                                dataQa={'answer-price-effect-type-value'}
                                                name={`Answer ${index + 1} Price Effect Type Value`}
                                                containerStyle='mb-1 max-w-[90%]'
                                                onChangeSelectedOption={onChangePricingEffectSelectedValue}
                                                options={PRICE_EFFECT_TYPES || []}
                                                uniqueName='type'
                                                index={item?.id}
                                            />
                                            <Input
                                                showValue={true}
                                                inputValue={findOptions?.effect?.value}
                                                dataQa={`answer-${index + 1}-price-effect-value`}
                                                label={`Answer ${index + 1} Price Effect Value`}
                                                containerStyle='mb-1 max-w-[90%]'
                                                type='text'
                                                index={item?.id}
                                                onChangeInput={onChangeInputPricingEffectValue}
                                                inputUniqueName='value'
                                            />
                                            <SelectCheckbox
                                                selectedOption={findOptions?.effect?.effectType ? EFFECT_TYPES?.find((effect) => effect?.value === findOptions?.effect?.effectType) : undefined} key={1}
                                                placeholder=' '
                                                name={`Answer ${index + 1} Effect Type`}
                                                dataQa={'answer-effect-type'}
                                                containerStyle='mb-1 max-w-[90%]'
                                                onChangeSelectedOption={onChangePricingEffectSelectedValue}
                                                options={EFFECT_TYPES || []}
                                                uniqueName='effectType'
                                                index={item?.id}
                                            />
                                            <Input
                                                showValue={true}
                                                inputValue={findOptions?.effect?.minAmount}
                                                dataQa={`answer-${index + 1}-price-effect-min-amount`}
                                                label={`Answer ${index + 1} Price Effect Min Amount`}
                                                containerStyle='mb-1 max-w-[90%]'
                                                type='text'
                                                index={item?.id}
                                                onChangeInput={onChangeInputPricingEffectValue}
                                                inputUniqueName='minAmount'
                                            />
                                            <Input
                                                showValue={true}
                                                inputValue={findOptions?.effect?.maxAmount}
                                                dataQa={`answer-${index + 1}-price-effect-max-amount`}
                                                label={`Answer ${index + 1} Price Effect Max Amount`}
                                                containerStyle='mb-1 max-w-[90%]'
                                                type='text'
                                                index={item?.id}
                                                onChangeInput={onChangeInputPricingEffectValue}
                                                inputUniqueName='maxAmount'
                                            />
                                            <div className='flex flex-row justify-start my-3'>
                                                <Button
                                                    label={'Manage Answer Description'}
                                                    className='btn-main'
                                                    onClickButton={() => onClickManageAnswerDescription(item?.answer, item?.id, findOptions?.description, findOptions?.descriptionLanguages)}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                        <div className='flex flex-row justify-end mt-4'>
                            <Button
                                label='Save'
                                dataQa={'submit'}
                                type={'submit'}
                                className={'btn-primary'}
                            />
                        </div>
                    </div>
                </form>
                {showDescriptionModal?.show &&
                    <AnswerDescriptionModal
                        openModal={showDescriptionModal?.show}
                        itemId={showDescriptionModal?.itemId || ''}
                        data={showDescriptionModal?.languages}
                        languages={allLanguages}
                        description={showDescriptionModal?.description}
                        handleCloseModal={() => setShowDescriptionModal(undefined)}
                        onSaveLanguagesChanges={onSaveAnswerDescription}
                    />
                }
            </div>
        </Modal>
    );
};

export default QuestionDetailsModal;
