import React, { useState, useCallback } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone'
import { useAppDispatch } from '../../../../app/hooks';
import { tryToImportScrapingFees } from '../../../../store/brokers/admin/scraping-fees/scrapingFeesSlice';
import ImportScrapingFeesSuccess from '../details/ImportScrapingFeesSuccess';
import ImportScrapingFeesCsv from '../details/ImportScrapingFeesCsv';
import Modal from '../../../../shared/modal';


interface IImportScrapingFeesModal {
    openImportModal: boolean;
    handleCloseModal: () => void;
    onImportSuccessfully: () => void;
}

const ImportScrapingFeesModal = ({
    openImportModal,
    handleCloseModal,
    onImportSuccessfully
}: IImportScrapingFeesModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [fileSelected, setFileSelected] = useState<any>()

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onCloseModal = () => {
        handleCloseModal();
        setImportedSuccessfully(false);
        setImportError(undefined);
        setFileSelected(undefined)
    }

    const onUploadFile = async () => {
        try {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const payload = results && results?.data && results?.data?.length > 0 && results?.data?.map((item: any) => {
                        return {
                            assetTemplateId: item?.assetTemplateId,
                            fee: Number(item?.scrapingFee || 0)
                        }
                    })
                    await dispatch(tryToImportScrapingFees(payload)).unwrap()
                }
            })
            setImportError(undefined)
            setImportedSuccessfully(true)
            onImportSuccessfully()
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
    }

    return (
        <Modal
            open={openImportModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <ImportScrapingFeesSuccess
                        handleCloseModal={onCloseModal}
                    />
                    : <ImportScrapingFeesCsv
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                    />
                }
            </div>
        </Modal>
    )
}

export default ImportScrapingFeesModal;