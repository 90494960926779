import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import Radio from '../../../shared/radio/Radio';
import Input from '../../../shared/input';
import Button from '../../../shared/button';
import { IShippingInformation, IShippingInformationProps } from '../../../interfaces/ai-agents/IShippingInformation';
import { classNames } from '../../../utils/classNames';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IItemAiAgent } from '../../../interfaces/ai-agents/IItem';
import { tryToCloseTheDeal } from '../../../store/brokers/ai-agents/aiAgentsSlice';
import { useParams } from 'react-router-dom';

const CloseDealForm = ({
	handleCloseModal,
}: {
	handleCloseModal: () => void
}) => {
	const {id} = useParams();
	const dispatch = useAppDispatch();
	const { register, watch, handleSubmit, formState: { errors } } = useForm<IShippingInformation>();
	const [checkSameBilling, setCheckSameBilling] = useState<boolean>(true);
	const toggleCheckSameBilling = useCallback(() => setCheckSameBilling(billing => !billing), [setCheckSameBilling]);
	const aiAgentsItems = useAppSelector(state => state.aiAgents) as IItemAiAgent;
	const onSubmit = async (payload: IShippingInformation) => {
		try {
			const data: IShippingInformationProps = {
				shippingInfo: {
					shippingMethod: payload.shippingProvider,
					address: payload.address,
					country: payload.country,
					region: payload.region,
					city: payload.city,
					zipCode: payload.zipCode,
					email: payload.email,
					phoneNumber: payload.phoneNumber
				},
				billingInfo: {
					useShippingAddress: checkSameBilling,
					address: checkSameBilling ? payload.address : payload.billingAddress,
					country: checkSameBilling ? payload.country : payload.billingCountry,
					region: checkSameBilling ? payload.region : payload.billingRegion,
					city: checkSameBilling ? payload.city : payload.billingCity,
					zipCode: checkSameBilling ? payload.zipCode : payload.billingZipCode,
					email: checkSameBilling ? payload.email : payload.billingEmail,
					phoneNumber: checkSameBilling ? payload.phoneNumber : payload.billingPhoneNumber
				},
				paymentOption: {
					bankAccount: {
						beneficiaryName: payload.beneficiaryName,
						accountNumber: payload.accountNumber,
						sortCode: payload.sortCode,
						bankName: payload.bankName
					}
				},
				additionalInfo: {
					orderNotes: payload.orderNotes || ''
				}
			}
			await dispatch(tryToCloseTheDeal({ conversationId: id || '', data })).unwrap();
			handleCloseModal()
		} catch (e) {
			console.log({e})
		}
	}

	return (
		<form className='w-full' onSubmit={handleSubmit(onSubmit)}>
			<hr />
			<div className='w-full py-5'>
				<h2 className='font-dm_sansbold text-agentTitleColor text-2xl font-bold text-primary mb-2'>Shipping Information</h2>
				<div className='w-full py-3'>
					<h3 className='font-dm_sansbold text-agentTitleColor text-base font-bold mb-2'>Shipping method</h3>
					<Radio
						register={register('shippingProvider')}
						id='ship-yourself'
						value={'ship your self'}
						checked={watch('shippingProvider')}
						title='Ship yourself'
						description='We will provide you the details of where the items should be sent and we strongly advise the package to be insured.'
					/>
					<Radio
						register={register('shippingProvider')}
						id='free-shipping'
						value={'free shipping'}
						checked={watch('shippingProvider')}
						title='Free shipping'
						description='We will provide you with a shipping label that you need to stick on the box(es) and drop them to nearest post offices afterwards'
					/>
				</div>
				<div className='w-full py-3'>
					<h2 className='font-dm_sansbold text-agentTitleColor text-2xl font-bold text-primary mb-2'>Address</h2>
					<div className='-mx-2 flex flex-wrap'>
						<div className='w-full px-2'>
							<Input placeholder='Address' register={register('address', { required: 'Address is required' })} error={errors.address?.message} />
						</div>
						<div className='w-full md:w-6/12 lg:w-3/12 px-2'>
							<Input label='Country' placeholder='USA' register={register('country', { required: 'Country is required' })} error={errors.country?.message} />
						</div>
						<div className='w-full md:w-6/12 lg:w-3/12 px-2'>
							<Input label='Region/State' placeholder='New York' register={register('region', { required: 'Region is required' })} error={errors.region?.message} />
						</div>
						<div className='w-full md:w-6/12 lg:w-3/12 px-2'>
							<Input label='City' placeholder='Kingston' register={register('city', { required: 'City is required' })} error={errors.city?.message} />
						</div>
						<div className='w-full md:w-6/12 lg:w-3/12 px-2'>
							<Input label='Zip Code' placeholder='12401' register={register('zipCode', { required: 'Zip Code is required' })} error={errors.zipCode?.message} />
						</div>
						<div className='w-full md:w-6/12 px-2'>
							<Input label='Email' placeholder='Email' register={register('email', { 
								required: 'Email is required',
								pattern: {
									value:
									  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Email must be a valid email',
								},
							})} error={errors.email?.message} />
						</div>
						<div className='w-full md:w-6/12 px-2'>
							<Input label='Phone Number' placeholder='+1 5056 68987 15' register={register('phoneNumber', { required: 'Phone Number is required' })} error={errors.phoneNumber?.message} />
						</div>
					</div>
				</div>
			</div>
			<div className='w-full py-5'>
				<h2 className='font-dm_sansbold text-agentTitleColor text-2xl font-bold text-primary mb-5'>Billing Information</h2>
				<label htmlFor='sameBillingAddress' className='w-full flex pl-5'>
					<input id='sameBillingAddress' type='checkbox' defaultChecked={checkSameBilling} onChange={toggleCheckSameBilling} />
					<div className='text-sm ml-3'>
						<b className='font-dm_sansbold'>Use same billing address as shipping</b>
						<p>If you have a different billing address than shipping address please uncheck to fill in details.</p>
					</div>
				</label>
			</div>
			{checkSameBilling ? <></> :
				<div className='w-full py-3'>
					<h2 className='font-dm_sansbold text-agentTitleColor text-2xl font-bold text-primary mb-2'>Billing Address</h2>
					<div className='-mx-2 flex flex-wrap'>
						<div className='w-full px-2'>
							<Input placeholder='Address' register={register('billingAddress', { required: 'Address is required' })} error={errors.billingAddress?.message} />
						</div>
						<div className='w-full md:w-6/12 lg:w-3/12 px-2'>
							<Input label='Country' placeholder='USA' register={register('billingCountry', { required: 'Country is required' })} error={errors.billingCountry?.message} />
						</div>
						<div className='w-full md:w-6/12 lg:w-3/12 px-2'>
							<Input label='Region/State' placeholder='New York' register={register('billingRegion', { required: 'Region is required' })} error={errors.billingRegion?.message} />
						</div>
						<div className='w-full md:w-6/12 lg:w-3/12 px-2'>
							<Input label='City' placeholder='Kingston' register={register('billingCity', { required: 'City is required' })} error={errors.billingCity?.message} />
						</div>
						<div className='w-full md:w-6/12 lg:w-3/12 px-2'>
							<Input label='Zip Code' placeholder='12401' register={register('billingZipCode', { required: 'Zip Code is required' })} error={errors.billingZipCode?.message} />
						</div>
						<div className='w-full md:w-6/12 px-2'>
							<Input label='Email' placeholder='Email' register={register('billingEmail', { 
								required: 'Email is required',
								pattern: {
									value:
									  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Email must be a valid email',
								},
							})} error={errors.billingEmail?.message} />
						</div>
						<div className='w-full md:w-6/12 px-2'>
							<Input label='Phone Number' placeholder='+1 5056 68987 15' register={register('billingPhoneNumber', { required: 'Phone Number is required' })} error={errors.billingPhoneNumber?.message} />
						</div>
					</div>
				</div>}
			<div className='w-full py-3 border border-[#E4E7E9]'>
				<h2 className='text-agentTitleColor text-lg mb-2 pb-2 border-b px-5'>Payment Option</h2>
				<div className='w-32 flex flex-col justify-center items-center text-center mb-5 pt-2 pl-5'>
					<img alt='bank account' width={32} height={32} src='/assets/ai-agents/bank-account.svg' />
					<h5 className='text-sm font-medium text-agentTitleColor mb-2'>Bank Account</h5>
					<input className='radio-btn' checked type='radio' />
				</div>
				<hr />
				<div className='flex flex-wrap p-4'>
					<div className='w-full px-2'>
						<Input label='Beneficiary Name' register={register('beneficiaryName', { required: 'Beneficiary Name is required' })} error={errors.beneficiaryName?.message} />
					</div>
					<div className='w-full px-2'>
						<Input label='Account Number' register={register('accountNumber', { required: 'Account Number is required' })} error={errors.accountNumber?.message} />
					</div>
					<div className='w-full md:w-6/12 px-2'>
						<Input label='Sort Code' register={register('sortCode', { required: 'Sort Code is required' })} error={errors.sortCode?.message} />
					</div>
					<div className='w-full md:w-6/12 px-2'>
						<Input label='Bank Name' register={register('bankName', { required: 'Bank Name is required' })} error={errors.bankName?.message} />
					</div>
				</div>
			</div>
			<div className='w-full py-3'>
				<h2 className='text-agentTitleColor text-lg my-2 pb-2'>Additional Information</h2>
				<div>
					<label className='block text-primary-light font-normal text-base mb-1'><span className='text-agentTitleColor'>Order Notes </span> (Optional)</label>
					<textarea placeholder='Notes about your order, e.g. special notes for delivery' className={classNames('border border-borderColor rounded w-full px-3 py-2 h-32', errors.orderNotes ? 'border-red-500' : '')}></textarea>
				</div>
			</div>
			<div className='w-full flex justify-center my-5'>
				<Button
					loading={aiAgentsItems.itemsAreLoading}
					type={'submit'}
					className="text-label text-base py-3 px-7 min-w-[213px] rounded-xl bg-gradient-to-br from-gradient-start to-gradient-end hover:from-gradient-end hover:to-gradient-start mx-2"
					label={'Confirm'}
				/>
			</div>

		</form>
	)
}

export default CloseDealForm;