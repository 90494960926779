import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';


interface IMultipleItemDetailsModal {
    openModal: boolean;
    type: string;
    data: any;
    handleCloseModal: () => void;
}

const MultipleItemDetailsModal = ({
    openModal,
    type,
    data,
    handleCloseModal,
}: IMultipleItemDetailsModal) => {

    const onCloseModal = () => {
        handleCloseModal();
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle={'min-w-[650px]'}>
            <div className={`p-4 min-w-[600px]`}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>{type === 'categories' ? 'Categories' : 'Brokers'}</p>
                </div>
                {type === 'brokers' ?
                    data && data?.length > 0 && data?.map((item: any, index: number) => {
                        return (
                            <div key={index} className='mb-2 px-3 py-2 bg-[#f8f9fc] rounded'>
                                <div className='bg-white p-2'>{item?.companyName}</div>
                            </div>
                        )
                    })
                    : <div>
                        {data && data?.length > 0 && data?.map((item: any, index: number) => {
                            return (
                                <div key={index} className='mb-2 px-3 py-2 bg-[#f8f9fc] rounded'>
                                    <div className='bg-white p-2'>{item?.code}</div>
                                </div>
                            )
                        })}
                    </div>
                }
                <div className='flex flex-row justify-end'>
                    <Button
                        className='btn-main'
                        label={'Close'}
                        onClickButton={() => onCloseModal()}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default MultipleItemDetailsModal;