import React from 'react';
import { IPageActions } from '../../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../../interfaces/shared/IPaginationPayload';
import { IPricingEffects } from '../../../../../interfaces/pricing-effects/IPricingEffects';
import Table from '../../../../../shared/table';

const tableColumns = ['Asset Template', 'Markets', 'Source', 'Broker', 'Adjustments', 'Type', 'Actions']

interface IPricingEffectsTable {
    rows: IPricingEffects[];
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onDeletePricingEffect: (id: string) => void;
    onEditPricingEffect: (id: string) => void;
}

const PricingEffectsTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    rowsLoading,
    setPaginationState,
    onDeletePricingEffect,
    onEditPricingEffect
}: IPricingEffectsTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IPricingEffects, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'> {row?.assetTemplates && row?.assetTemplates?.map((item, index) => `${index ? ', ' : ''}${item.name}`)}</td>
                                <td className='px-6 py-4 flex-wrap max-w-[250px]'>
                                    {row?.markets && row?.markets?.map((item, index) => `${index ? ', ' : ''}${item}`)}
                                </td>
                                <td className='px-6 py-4'> {row?.sources && row?.sources?.map((item, index) => `${index ? ', ' : ''}${item}`)}</td>
                                <td className='px-6 py-4'> {row?.partners && row?.partners?.map((item, index) => `${index ? ', ' : ''}${item.companyName || ''}`)}</td>
                                <td className='px-6 py-4'>{row?.value}</td>
                                <td className='px-6 py-4'>{row?.type || ''}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa={'edit-pricing-effect'} onClick={() => ((!accessControl || actions?.['Edit Pricing Adjustment']) && row?._id) && onEditPricingEffect(row?._id)}>
                                            <img title='Edit Pricing Effect' alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Pricing Adjustment']) ? 'cursor-pointer' : 'cursor-not-allwed'} mr-3`} />
                                        </div>
                                        <div data-qa={'delete-pricing-effect'} onClick={() => ((!accessControl || actions?.['Delete Pricing Adjustment']) && row?._id) && onDeletePricingEffect(row?._id)}>
                                            <img title={'Delete Pricing Effect'} alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Pricing Adjustment']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default PricingEffectsTable;