import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToSortWarehouseFields } from '../../../../store/brokers/admin/warehouses/warehousesSlice';
import Table from '../../../../shared/table';

const tableColumns = ['Field Id', 'Name', 'Type', 'Values', 'Actions']

interface IWarehouseFieldsTable {
    rows: Array<any>;
    warehouseId: string;
    onEditField: (id: string) => void;
    onDeleteField: (id: string) => void;
}

const WarehouseFieldsTable = ({
    rows,
    warehouseId,
    onEditField,
    onDeleteField
}: IWarehouseFieldsTable) => {
    const dispatch = useAppDispatch();
    const [data, setData] = useState(rows);
    const [orderChanged, setOrderChanged] = useState<boolean>(false)
    const dragItem = React.useRef<any>(null)
    const dragOverItem = React.useRef<any>(null)

    useEffect(() => {
        setData(rows)
    }, [rows])

    const handleSort = (e: React.DragEvent<HTMLTableRowElement>) => {
        const allRows = [...data]
        const draggedItemContent = allRows.splice(dragItem.current, 1)[0]
        allRows.splice(dragOverItem.current, 0, draggedItemContent)
        dragItem.current = null
        dragOverItem.current = null
        e.currentTarget.style.opacity = '1'
        setOrderChanged(true)
        setData(allRows)
    }

    const onResortWarehouseFields = async () => {
        const formatData = (data && data?.length > 0) && data?.map((row, index) => {
            return row?.id || ''
        })
        if (formatData) {
            const dataParam: any = {
                warehouseId,
                sortedIds: formatData
            }
            await dispatch(tryToSortWarehouseFields(dataParam)).unwrap();
        }
        setOrderChanged(false)
    }

    useEffect(() => {
        if (orderChanged) {
            onResortWarehouseFields()
        }
    }, [orderChanged])

    const handleOnDragOver = (e: React.DragEvent<HTMLTableRowElement>) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.style.backgroundColor = '#e7e7e7';
        e.currentTarget.style.transition = '0s'
    }

    const handleOnDrop = (e: React.DragEvent<HTMLTableRowElement>) => {
        e.currentTarget.style.backgroundColor = '';
        e.currentTarget.style.transition = '3s'
    }

    const handleOnDragEnter = (e: React.DragEvent<HTMLTableRowElement>, idx: number) => {
        dragOverItem.current = idx;
        e.currentTarget.style.backgroundColor = '#e7e7e7';
        e.currentTarget.style.transition = '0s'
    }

    const handleOnDragStart = (e: React.DragEvent<HTMLTableRowElement>, idx: number) => {
        dragItem.current = idx;
        e.currentTarget.style.transition = '0s'
        e.currentTarget.style.opacity = '0.4'
    }

    return (
        <Table
            columns={tableColumns}
            hidePagination={true}>
            {data?.length > 0 && data?.map((row: any, idx: number) => {
                return (
                    <tr
                        key={idx}
                        draggable
                        onDragOver={(e) => handleOnDragOver(e)}
                        onDragLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                        onDrop={(e) => handleOnDrop(e)}
                        onDragStart={(e) => handleOnDragStart(e, idx)}
                        onDragEnter={(e) => handleOnDragEnter(e, idx)}
                        onDragEnd={(e) => handleSort(e)}
                        className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?.id}</td>
                        <td className='px-6 py-4'>{row?.name}</td>
                        <td className='px-6 py-4'>{row?.type}</td>
                        <td className='px-6 py-4'>{row?.values && row?.values?.map((item: any, index: number) => `${index ? ', ' : ''}${item}`)}</td>
                        <td className='px-6 py-4'>
                            <div data-qa={'edit-field'} className='flex flex-row items-center'>
                                <div onClick={() => row?.id && onEditField(row?.id)}>
                                    <img title={'Edit Field'} alt='edit item' src={'/assets/table-actions/pen.svg'} className={'h-[14px] object-contain cursor-pointer mr-3'} />
                                </div>
                                <div data-qa={'delete-field'} onClick={() => row?.id && onDeleteField(row?.id)}>
                                    <img title={'Delete Field'} alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={'h-[14px] object-contain cursor-pointer mr-1'} />
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default WarehouseFieldsTable;