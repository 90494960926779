import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IPricingReview } from '../../../interfaces/pricing-review/IPricingReview';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { tryToFetchAllPartners } from '../../../store/brokers/admin/partners/partnersSlice';
import {
    tryToChangePriceSettingStatus,
    tryToFetchFilteredPricingReviews
} from '../../../store/brokers/shared-endpoints/pricing-reviews/pricingReviewsSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import PricingReviewsFilters from './details/PricingReviewsFilters';
import PricingReviewsStatuses from './details/PricingReviewStatuses';
import ChangeStatusModal from './modals/ChangeStatusModal';
import PricingReviewsTable from './tables/PricingReviewsTable';
import Input from '../../../shared/input';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';

const pricingStatuses = [
    { name: 'In Review', label: 'in_review' },
    { name: 'Accepted', label: 'accepted' },
    { name: 'Rejected', label: 'rejected' },
]

const PricingReviews = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IPricingReview[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [search, setSearch] = useState<string>()
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [partners, setPartners] = useState<IPartners[]>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [selectedStatus, setSelectedStatus] = useState<any>()
    const [selectedPartner, setSelectedPartner] = useState<any>()
    const [selectedItem, setSelectedItem] = useState<IPricingReview>()
    const [showChangeStatusModal, setShowChangeStatusModal] = useState<boolean>(false)
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.pricingReviews.pricingReviews?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.pricingReviews);
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Pricing Reviews')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.pricingReviews) {
            const rows =
                state.pricingReviews?.data?.elements && Array.isArray(state.pricingReviews?.data.elements)
                    ? state.pricingReviews?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.pricingReviews]);

    const getPricingReviewsData = () => {
        const settingsFilters: any = {
            ...paginationState
        }
        if (selectedMarket) {
            settingsFilters.data = { market: selectedMarket ? selectedMarket?._id : '' }
        }
        if (selectedPartner) {
            settingsFilters.data = {
                ...settingsFilters?.data,
                partner: selectedPartner ? selectedPartner?._id : ''
            }
        }
        if (selectedStatus) {
            settingsFilters.data = {
                ...settingsFilters?.data,
                status: selectedStatus
            }
        }
        if (search) {
            settingsFilters.filters = search
        }
        dispatch(tryToFetchFilteredPricingReviews(settingsFilters));
    }

    useEffect(() => {
        getPricingReviewsData()
    }, [paginationState]);

    const getPartnerAndMarketData = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.companyName }))
        setPartners(formatPartners || [])
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        getPartnerAndMarketData()
    }, [])

    const onSelectMarket = (value: string) => {
        setSelectedMarket(value)
        onResetPaginationState()
    }

    const onSelectPartner = (value: any) => {
        setSelectedPartner(value)
        onResetPaginationState()
    }

    const onShowChangeStatus = async (item: IPricingReview) => {
        const findEnableOffer = item?.settings && item?.settings?.length > 0 && item?.settings?.find((offer) => offer?.enabled)
        if (findEnableOffer && findEnableOffer?.status === 'in_review') {
            setShowChangeStatusModal(true)
            setSelectedItem(item)
        }
    }

    const onCloseStatusModal = () => {
        setSelectedItem(undefined)
        setShowChangeStatusModal(false)
    }

    const onChangeStatus = async (status: string) => {
        try {
            const data = {
                status,
                _id: selectedItem?._id || ''
            }
            dispatch(tryToChangePriceSettingStatus(data)).unwrap()
            setShowToast({ type: 'success', message: `Price Review status changed successfully` });
        } catch (err: any) {
            setShowToast({ type: 'error', message: `${err?.response?.data || err?.response || err}` });
        }
        onCloseStatusModal()
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const onChangeActiveStatus = (status: string) => {
        setSelectedStatus(selectedStatus === status ? undefined : status)
        onResetPaginationState()
    }

    const onClearAllFilter = () => {
        setSearch(undefined)
        setSelectedStatus(undefined)
        setSelectedPartner(undefined)
        setSelectedMarket(undefined)
        onResetPaginationState()
    }

    return (
        <div>
            <div>
                <p className='page-title'>Pricing Reviews</p>
            </div>
            <div>
                <PricingReviewsFilters
                    markets={markets}
                    selectedMarket={selectedMarket}
                    partners={partners}
                    selectedPartner={selectedPartner}
                    onSelectMarket={onSelectMarket}
                    onSelectPartner={onSelectPartner}
                />
            </div>
            <div>
                <PricingReviewsStatuses
                    pricingStatuses={pricingStatuses}
                    selectedStatus={selectedStatus}
                    onChangeStatus={onChangeActiveStatus}
                />
            </div>
            <div>
                <div className={`grid grid-cols-9 bg-white mt-3`}>
                    <div className={`col-span-7`}>
                        <Input
                            placeholder={'Search'}
                            dataQa={'search'}
                            containerStyle='mb-0 pb-0 relative'
                            inputStyle={`mb-0 border-0 focus:outline-none`}
                            icon={'/assets/shared/search.svg'}
                            showValue={true}
                            inputValue={search}
                            inputUniqueName={'search'}
                            onChangeInput={onChangeSearchValue}
                            hideInputSpace={true}
                        />
                    </div>
                    <Button
                        label={'Clear all filters'}
                        dataQa={'clear-all-filters'}
                        className={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end bg-transparent mt-1'}
                        onClickButton={onClearAllFilter}
                    />
                </div>
                <PricingReviewsTable
                    rows={rows}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    setPaginationState={setPaginationState}
                    onShowChangeStatus={onShowChangeStatus}
                    markets={markets}
                />
            </div>
            <ChangeStatusModal
                openStatusModal={showChangeStatusModal}
                handleCloseStatusModal={onCloseStatusModal}
                onChangeStatus={onChangeStatus}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    )
}

export default PricingReviews;