import { createAsyncThunk, } from '@reduxjs/toolkit';
import { AxiosError, } from 'axios';
import { CurrencySettingsService } from './currencySettingsApi';
import { ICurrencySettings } from '../../../../interfaces/curreny-settings/ICurrencySettings';


export const tryToFetchCurrencySettings = createAsyncThunk<ICurrencySettings, any>(
    'pricing/tryToFetchCurrencySettings',
    async () => {
        const result = await CurrencySettingsService.tryToFetchCurrencySettings();
        return result?.data;
    },
);

export const tryToEditCurrencySettings = createAsyncThunk<any, any>(
    'pricing/tryToEditCurrencySettings',
    async (data: any, { rejectWithValue }) => {
        try {
            return await CurrencySettingsService.tryToEditCurrencySettings(data);
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToConvertCurrency = createAsyncThunk<any, any>(
    'pricing/tryToConvertCurrency',
    async (data: any, { rejectWithValue }) => {
        try {
            return await CurrencySettingsService.tryToConvertCurrency(data);
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);