import React, { useState, useRef, useEffect } from 'react';
import { IPage } from '../../interfaces/models/models/paginated-response.model';
import DropDown from '../dropdown';

interface ITable {
    columns?: Array<string>;
    selectRows?: boolean;
    children?: React.ReactNode;
    hidePagination?: boolean;
    paginationData?: IPage;
    setPaginationState?: any;
    paginationState?: any;
    containerStyle?: string;
    sortColumns?: Array<string>;
    selectedColumns?: Array<string>;
    headerColsStyle?: string;
    headStyle?: string;
    onClickSortColumn?: (name: string) => void;
    onClickSelectedColumn?: (name: string) => void;
    onClickSelectAll?: () => void;
    areAllRowsSelected?: boolean;
    sortStatus?: any;
    selectStyles?: string;
}

const itemsPerPage = [5, 10, 15, 20]

const Table = ({
    columns,
    selectRows,
    children,
    paginationData,
    setPaginationState,
    paginationState,
    hidePagination,
    containerStyle,
    sortColumns,
    selectedColumns,
    headerColsStyle,
    headStyle,
    onClickSortColumn,
    onClickSelectedColumn,
    onClickSelectAll,
    areAllRowsSelected,
    sortStatus,
    selectStyles
}: ITable) => {
    const [showDropDown, setShowDropDown] = useState<boolean>(false)
    const [showDropDownScnd, setShowDropDownScnd] = useState<boolean>(false)
    const node = useRef() as React.MutableRefObject<HTMLInputElement>;
    const nodeScnd = useRef() as React.MutableRefObject<HTMLInputElement>;

    const clickOutsideFirstDropDown = (e: any) => {
        if (!hidePagination && node?.current?.contains(e.target)) {
            setShowDropDown(true)
            return;
        }
        setShowDropDown(false)
    }

    const clickOutsideScndDropDown = (e: any) => {
        if (!hidePagination && nodeScnd?.current?.contains(e.target)) {
            setShowDropDownScnd(true)
            return;
        }
        setShowDropDownScnd(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', clickOutsideFirstDropDown);
        return () => {
            document.removeEventListener('mousedown', clickOutsideFirstDropDown);
        }
    }, [showDropDown])

    useEffect(() => {
        document.addEventListener('mousedown', clickOutsideScndDropDown);
        return () => {
            document.removeEventListener('mousedown', clickOutsideScndDropDown);
        }
    }, [showDropDownScnd])

    const onChangeItemsPerPage = (item: number) => {
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
            pageSize: item,
        })
        setShowDropDown(false)
    }

    const onChangePage = (page: number) => {
        setPaginationState({
            ...paginationState || {},
            pageNumber: page,
            pageSize: paginationData?.size || 1
        })
        setShowDropDownScnd(false)
    }

    const end = paginationData?.totalPages
        ? paginationData?.totalPages > 2000
            ? paginationData?.number + 2000 <= paginationData?.totalPages
                ? paginationData?.number + 2000 : paginationData?.totalPages
            : paginationData?.totalPages
        : 1

    const start = paginationData?.totalPages ?
        paginationData?.totalPages > 2000
            ? paginationData?.number
            : 1
        : 1

    return (
        <>
            <div className={`my-4 overflow-x-auto ${containerStyle}`}>
                <table data-qa='table-rows' className='w-full text-sm text-left text-gray-500'>
                    <thead className={`text-s text-black bg-[#FAFAFA] ${headStyle}`}>
                        <tr>
                            {selectRows && <th scope='col' className='px-6 py-4'>
                                <div className='flex items-center'>
                                    <input
                                        checked={areAllRowsSelected || false}
                                        onChange={() => onClickSelectAll?.()}
                                        id='checkbox-all'
                                        type='checkbox'
                                        className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2 ${selectStyles && selectStyles}`} />
                                    <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                                </div>
                            </th>
                            }
                            {columns && columns?.length > 0 && columns?.map((column: string, idx: number) => {
                                const columnSorted = sortColumns && sortColumns?.length > 0 && sortColumns?.find((item) => item === column)
                                const columnSelected = selectedColumns && selectedColumns?.length > 0 && selectedColumns?.find((item) => item === column)
                                const rotateImage = (columnSorted && sortStatus?.[columnSorted] === 'desc') ? '!rotate-[0deg]' : (columnSorted && sortStatus?.[columnSorted] === 'asc' ? '!rotate-[-180deg]' : '!rotate-0')
                                return (
                                    <>
                                        {columnSelected ?
                                            <th key={idx} scope='col' className={`px-6 py-3 ${headerColsStyle || ''}`}>
                                                <div data-qa={'sort-image'} onClick={() => columnSelected && onClickSelectedColumn?.(column)} className={`flex underline flex-row cursor-pointer items-center justify-center text-center ${columnSorted && 'cursor-pointer'}`}>
                                                    {column}
                                                </div>
                                            </th>
                                            : <th key={idx} scope='col' className={`px-6 py-3 ${headerColsStyle || ''}`}>
                                                <div data-qa={'sort-image'} onClick={() => columnSorted && onClickSortColumn?.(column)} className={`flex flex-row items-center ${columnSorted && 'cursor-pointer'}`}>
                                                    {column}
                                                    {columnSorted && <img src={'/assets/table-actions/sort.svg'} className={`ml-1 min-w-[16px] w-[16px] ${rotateImage}`} />}
                                                </div>
                                            </th>
                                        }
                                    </>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr className='bg-white border-b hover:bg-gray-50'> */}
                        {children}
                        {/* </tr> */}
                    </tbody>
                </table>
                {!hidePagination &&
                    <div className='bg-[#F0F0F0] sticky left-0 right-0 min-w-full flex flex-1 flex-row items-stretch justify-between pl-5 border-t-2 border-[#DCDCDD]'>
                        <div className='flex flex-row text-[#8F8F8F] font-medium text-sm divide-x-2 divide-[#DCDCDD]'>
                            <div className='py-2 pr-4 flex flex-row'>
                                <p>Items per page</p>
                                <div ref={node} className='relative flex flex-row text-black font-medium text-sm pl-3 cursor-pointer'>
                                    <p>{paginationData?.size}</p>
                                    <img alt='items arrow' src={'/assets/table-actions/arrow.svg'} className={'w-[12px] object-contain rotate-90 pl-[5px]'} />
                                    <DropDown
                                        open={showDropDown}
                                        items={itemsPerPage}
                                        onClickItem={onChangeItemsPerPage} />
                                </div>
                            </div>
                            <div className='py-2 pr-4'>
                                <p className='px-4'> {1 * (paginationData?.number && paginationData?.number > 1 ? (paginationData?.number * paginationData?.size) - paginationData?.size : 1)}-{paginationData?.number && paginationData?.number > 1 ? (paginationData?.number * paginationData?.size) : paginationData?.size} of {paginationData?.totalElements || 1} items</p>
                            </div>
                        </div>
                        <div className='flex flex-row text-[#8F8F8F] font-medium text-sm divide-x-2 divide-[#DCDCDD]'>
                            <div></div>
                            <div className='py-2 px-3 flex flex-row'>
                                <div ref={nodeScnd} className='flex flex-row text-black font-medium text-sm pl-3 cursor-pointer'>
                                    <p>{paginationData?.number}</p>
                                    <img alt='page arrow' src={'/assets/table-actions/arrow.svg'} className={'w-[12px] object-contain rotate-90 pl-[5px]'} />
                                    <DropDown
                                        open={showDropDownScnd}
                                        items={
                                            // Array.from({ length: paginationData?.totalPages ? paginationData?.totalPages > 2000 ? paginationData?.number + 2000 <= paginationData?.totalPages ? paginationData?.number + 2000 : || : paginationData?.totalPages : 1 }, (_, idx) => `${++idx}`)
                                            Array(end - start + 1).fill(null).map((_, idx) => start + idx)
                                        }
                                        onClickItem={onChangePage} />
                                </div>
                                <p>of {paginationData?.totalPages || 1} pages</p>
                            </div>
                            <div data-qa={'arrow-left'} onClick={() => (paginationData?.number && paginationData?.number > 1) && onChangePage(paginationData?.number ? (paginationData?.number - 1) : 1)} className='py-2 px-3 h-full flex flex-row items-center cursor-pointer hover:bg-stone-300'>
                                <img alt='previous' src={'/assets/table-actions/arrow.svg'} className={'w-[12px] object-contain rotate-180'} />
                            </div>
                            <div data-qa={'arrow-right'} onClick={() => paginationData?.hasNextPage && onChangePage(paginationData?.number ? (paginationData?.number + 1) : 1)} className='py-2 px-3 h-full flex flex-row items-center cursor-pointer hover:bg-stone-300'>
                                <img alt='next' src={'/assets/table-actions/arrow.svg'} className={'w-[12px] object-contain'} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default Table;