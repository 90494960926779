import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import {
    tryToChangeAssetsHiddenStatus,
    tryToChangeAssetsStatus
} from '../../../../store/brokers/admin/assets/assetsSlice';
import { tryToFetchAllPayServices } from '../../../../store/brokers/admin/pay-services/payServicesSlice';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import { IPayServices } from '../../../../interfaces/pay-services/IPayServices';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IChangeItemStatusModal {
    openModal: boolean;
    assetTemplateId: string;
    assetId: string;
    actualStatus: string;
    extendedExist?: any;
    hiddenServices?: any;
    handleCloseModal: () => void;
    onSaveSuccessfully: (message: string) => void;
}

const ChangeItemStatusModal = ({
    openModal,
    assetTemplateId,
    assetId,
    actualStatus,
    extendedExist,
    hiddenServices,
    handleCloseModal,
    onSaveSuccessfully
}: IChangeItemStatusModal) => {
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>()
    const [services, setServices] = useState<any>();
    const [servicesSelected, setServicesSelected] = useState<Array<ISelectedValue & { id: string }>>();

    const getPayServicesData = async () => {
        const payServicesResponse = await dispatch(tryToFetchAllPayServices()).unwrap()
        const formatPayServices = (payServicesResponse && payServicesResponse?.data && payServicesResponse?.data?.length > 0)
            && payServicesResponse?.data?.map((service: IPayServices) => ({ ...service, label: service?.displayName, value: service?._id }))
        setServices(formatPayServices || [])
    }

    useEffect(() => {
        if (!services) {
            getPayServicesData()
        }
    }, [])

    useEffect(() => {
        if (services && hiddenServices && services) {
            const findServices = hiddenServices && hiddenServices?.length > 0 &&
                hiddenServices?.map((item: any) => {
                    const findService = services && services?.length > 0 && services?.find((service: any) => service?.value === item?.serviceId)
                    return findService
                })
            setServicesSelected(findServices)
        }
    }, [services, hiddenServices, services])

    const onCloseModal = () => {
        handleCloseModal();
        setServicesSelected(undefined)
        setSaveError(undefined)
    }

    const onSubmit = async (status: any) => {
        try {
            const formData = {
                assetTemplateId: assetTemplateId,
                data: {
                    assets: [assetId],
                    status: status
                }
            }
            await dispatch(tryToChangeAssetsStatus(formData)).unwrap()
            onSaveSuccessfully('Asset status changed successfully')
            onCloseModal()
        } catch (err: any) {
            setSaveError(`${err?.response?.data?.message || err}`)
        }
    }

    const onChangeSelectedServices = (e: any, type?: string, index?: number) => {
        setServicesSelected(e)
    }

    const onSaveChanges = async () => {
        try {
            const formatServices = (servicesSelected && servicesSelected?.length > 0) ? servicesSelected?.map((item: any) => ({ serviceId: item?.value, serviceName: item?.name, displayName: item?.displayName })) : []
            const formData = {
                assetTemplateId: assetTemplateId,
                assetIds: [assetId],
                hiddenServices: formatServices || []
            }
            await dispatch(tryToChangeAssetsHiddenStatus(formData)).unwrap()
            onSaveSuccessfully('Asset status changed successfully')
            onCloseModal()
        } catch (err) {
            setSaveError(`${err}`)
        }
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            showInRight
            contentContainerStyle='min-w-[600px]'>
            <div className={'p-4 min-w-[550px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Asset Status</p>
                </div>
                {saveError && <Error text={saveError} />}
                <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                    <SelectCheckbox
                        name='Hidden in Services'
                        multiple={true}
                        selectedOption={servicesSelected}
                        options={services}
                        onChangeSelectedOption={onChangeSelectedServices}
                    />
                    {(!extendedExist && actualStatus === 'disabled') &&
                        <div className='my-5'>
                            <p className='italic font-bold'>You can&apos;t change Asset Status without adding Asset Extended Details first you only can change Hide In Services.</p>
                        </div>
                    }
                </div>
                <div className='flex flex-row justify-center'>
                    {(extendedExist) && (actualStatus === 'disabled' || actualStatus === 'new') &&
                        <Button
                            className='btn-main mr-3'
                            label={'Enable Asset'}
                            onClickButton={() => onSubmit('enabled')}
                        />
                    }
                    {(actualStatus === 'enabled' || actualStatus === 'new') &&
                        <Button
                            className='btn-error !rounded mr-3'
                            label={'Disable Asset'}
                            onClickButton={() => onSubmit('disabled')}
                        />
                    }
                    <Button
                        label={'Save Changes'}
                        className='btn-main !rounded shadow'
                        onClickButton={onSaveChanges}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ChangeItemStatusModal;