import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import Tabs from './tabs/Tabs';
import RentalPricingTab from './tabs/RentalPricing';
import RentalAssets from './tabs/RentalAssetCatalogs';

const tabs = [
    { name: 'assets', label: 'Assets', element: <RentalAssets /> },
    { name: 'pricing', label: 'Pricing', element: <RentalPricingTab /> }
]

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const RentalPricing = () => {
    const query = useQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<any>(tabs[0])
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Rental Pricing')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onClickTab = (value: any) => {
        setActiveTab(value)
        searchParams.set('type', value?.name)
        setSearchParams(searchParams)
    }

    useLayoutEffect(() => {
        if (query?.get('type') && query?.get('type') !== activeTab?.name) {
            const findActiveTab = tabs?.find((item) => item?.name === query?.get('type'))
            setActiveTab(findActiveTab || undefined)
        }
    }, [query?.get('type')])

    return (
        <div>
            <div>
                <p className='page-title'>Rental Pricing</p>
            </div>
            <Tabs
                activeTab={activeTab.name}
                onClickTab={onClickTab}
                tabs={tabs}
            />
            <div>
                {(!accessControl || pageAccess?.actions?.[activeTab?.label]) && activeTab?.element}
            </div>
        </div>
    )
}
export default RentalPricing