import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
    useLocation,
    useSearchParams
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IToast } from '../../../interfaces/components/IToast';
import { IPricing } from '../../../interfaces/pricing/IPricing';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IWarehouse } from '../../../interfaces/warehouses/IWarehouse';
import { IPayServices } from '../../../interfaces/pay-services/IPayServices';
import { ISelectedValue } from '../../../interfaces/components/ISelectedValue';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToFetchAllAssetsTemplates,
    tryToFetchAssetTemplateFilters
} from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import {
    tryToFetchAllPartners,
    tryToFetchPartnerCatalogs
} from '../../../store/brokers/admin/partners/partnersSlice';
import {
    tryToAllocateItemsToSpecificBrokers,
    tryToAllocationItemsToHighestPrice,
    tryToFetchItemAllocation
} from '../../../store/brokers/admin/item-allocation/itemAllocationSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchWarehouses } from '../../../store/brokers/admin/warehouses/warehousesSlice';
import { tryToFetchAllPayServices } from '../../../store/brokers/admin/pay-services/payServicesSlice';
import ConfirmHighestPriceModal from './modals/ConfirmHighestPriceModal';
import ItemsAllocationTable from './tables/ItemsAllocationTable';
import AllocationFilters from './details/AllocationFilters';
import Loading from '../../../shared/Loading';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';
import Input from '../../../shared/input';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ItemAllocationAdmin = () => {
    const dispatch = useAppDispatch();
    const query = useQuery();
    const [rows, setRows] = useState<IPricing[]>([]);
    const [templateFilters, setTemplateFilters] = useState<Array<any>>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [partners, setPartners] = useState<IPartners[]>()
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [payServices, setPayServices] = useState<IPayServices[]>()
    const [warehouses, setWarehouses] = useState<Array<IWarehouse>>()
    const [warehouseSelected, setWarehouseSelected] = useState<ISelectedValue>()
    const [selectedSource, setSelectedSource] = useState<ISelectedValue>();
    const [selectedAllocation, setSelectedAllocation] = useState<ISelectedValue>();
    const [selectedMarket, setSelectedMarket] = useState<ISelectedValue & IAllMarkets>()
    const [selectedPartner, setSelectedPartner] = useState<ISelectedValue & IPartners>()
    const [filterValues, setFilterValues] = useState<any>();
    const [selectedSector, setSelectedSector] = useState<ISelectedValue>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showAllocationConfirmModel, setShowAllocationConfirmModal] = useState<boolean>(false)
    const [loadingAllocate, setLoadingAllocate] = useState<boolean>(false)
    const [pageAccess, setPageAccess] = useState<any>();
    const [openedItems, setOpenedItems] = useState<Array<string>>()
    const [selectedNewPriceBrokers, setSelectedNewPriceBrokers] = useState<Array<{ id: string, itemId: string, data: any }>>()
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState<any>()
    const [getAllMarkets] = useGetAllMarketsMutation();
    const paginationData = useAppSelector((state) => state.itemAllocation.itemAllocations?.data?.page);
    const itemsLoading = useAppSelector((state) => state.itemAllocation?.itemsAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const stateWarehouses = useAppSelector((state) => state.warehouses);
    const state = useAppSelector((state) => state.itemAllocation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Item Allocation Admin')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        const formatWarehouses = stateWarehouses.warehouses && stateWarehouses.warehouses?.length > 0 && stateWarehouses.warehouses?.map((warehouse: IWarehouse) => ({ ...warehouse, label: `${warehouse?.address?.street || ''}-${warehouse?.name}`, value: `${warehouse?.address?.street || ''}-${warehouse?.name}` }))
        const filterWarehouses = formatWarehouses && formatWarehouses?.length > 0 && selectedMarket && formatWarehouses?.filter((warehouse: any) => warehouse?.marketIds && warehouse?.marketIds?.includes(selectedMarket?.value) && (warehouse?.partnerId === selectedPartner?.value))
        setWarehouses(filterWarehouses || [])
    }, [stateWarehouses.warehouses, selectedMarket, selectedPartner])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: undefined
        })
        searchParams.set('page', '1')
        searchParams.set('size', `${paginationState?.pageSize || 10}`)
        setSearchParams(searchParams)
    }

    useEffect(() => {
        if (stateAssets.assetTemplateFilters) {
            setTemplateFilters(stateAssets.assetTemplateFilters)
        }
    }, [stateAssets?.assetTemplateFilters]);

    useEffect(() => {
        if (selectedSector) {
            dispatch(tryToFetchAssetTemplateFilters({ id: `${selectedSector?.value || ''}`, replacements: true }))
        }
        setFilterValues(undefined)
    }, [selectedSector])

    useEffect(() => {
        if (state.itemAllocations) {
            const rows =
                state.itemAllocations?.data?.elements && Array.isArray(state.itemAllocations?.data.elements)
                    ? state.itemAllocations?.data.elements.map((r) => ({ ...r }))
                    : [];
            setRows(rows);
        }
    }, [state.itemAllocations]);

    const getPartnerAndMarketData = async () => {
        try {
            const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
            const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.companyName }))
            setPartners(formatPartners || [])
            // setSelectedPartner(formatPartners?.[0] || undefined)
            const marketResponse = await getAllMarkets(null).unwrap()
            const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id, displayName: market?.name }))
            setMarkets(formatMarkets || [])
            const findUK = formatMarkets && formatMarkets?.length > 0 && formatMarkets?.find((item: any) => (item?.displayName === 'GB' || item?.name === 'GB'))
            setSelectedMarket(findUK || markets?.[0] || undefined)
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.name, label: r?.displayName, value: r?._id, }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
            const findPhones = (rows && rows?.length > 0) && rows?.find((r) => (r?.name || '').toLowerCase() === 'phones')
            setSelectedSector(findPhones || rows?.[0] || undefined)
        } else dispatch(tryToFetchAllAssetsTemplates())
    }, [stateAssets.allAssetTemplates]);

    const getPayServicesData = async () => {
        const payServicesResponse = await dispatch(tryToFetchAllPayServices()).unwrap()
        const formatPayServices = payServicesResponse && payServicesResponse?.data && payServicesResponse?.data?.length > 0 && payServicesResponse?.data?.map((service: IPayServices) => ({ ...service, label: service?.displayName, value: service?._id, name: service?.displayName }))
        setPayServices(formatPayServices || [])
    }

    useEffect(() => {
        getPartnerAndMarketData()
        dispatch(tryToFetchWarehouses(null)).unwrap()
        getPayServicesData()
    }, [])

    const onGetPartnerSectors = async () => {
        if (selectedPartner) {
            const response: any = await dispatch(tryToFetchPartnerCatalogs(selectedPartner.username)).unwrap()
            if (response?.data) {
                const formatSectors = response?.data?.length > 0 && response?.data?.map((category: any) => ({ ...category, label: category?.displayName, value: category?._id }))
                setSectors(formatSectors || [])
                const findPhones = (formatSectors && formatSectors?.length > 0) && formatSectors?.find((r: any) => (r?.name || '').toLowerCase() === 'phones')
                setSelectedSector(findPhones || formatSectors?.[0] || undefined)
            }
        } else if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.name, label: r?.displayName, value: r?._id, }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies').map(r => ({ ...r, name: r.name.toLowerCase() })) : []);
            const findPhones = (rows && rows?.length > 0) && rows?.find((r) => (r?.name || '').toLowerCase() === 'phones')
            setSelectedSector(findPhones || rows?.[0] || undefined)
        }
    }

    const onClickShowAllPricings = (id: string) => {
        const findItemIfIsOpen = (openedItems && openedItems?.length > 0) && openedItems?.find((item: string) => item === id)
        if (findItemIfIsOpen) {
            const filterOut = (openedItems && openedItems?.length > 0) && openedItems?.filter((item: string) => item !== id)
            setOpenedItems(filterOut || undefined)
        } else {
            setOpenedItems((prevOpenedItems: Array<string> | undefined) => [
                ...prevOpenedItems || [],
                id
            ])
        }
    }

    useEffect(() => {
        onGetPartnerSectors()
    }, [selectedPartner])

    const onChangeSelectedFilter = (e: any, type?: string) => {
        switch (type) {
            case 'market':
                setSelectedMarket(e);
                break;
            case 'category':
                setSelectedSector(e)
                break;
            case 'partner':
                setSelectedPartner(e)
                break;
            case 'source':
                setSelectedSource(e)
                break;
            case 'warehouse':
                setWarehouseSelected(e)
                break;
            case 'allocated':
                setSelectedAllocation(e)
                break;
        }
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters
        })
    }

    const onGetItemsData = async () => {
        try {
            const filters = filterValues && Object.keys(filterValues)
            let formatFilters
            const filtersValuesFormat = filters && filters?.length > 0 && filters?.map((item: any) => {
                return {
                    key: item,
                    value: Object.keys(filterValues) ? filterValues?.[item] || undefined : undefined
                }
            })
            if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
                formatFilters = {
                    value: filtersValuesFormat?.filter((key: any) => key?.value)
                }
            }
            let formatData: any = {
                ...paginationState,
                data: {
                    marketName: selectedMarket ? selectedMarket?.displayName : '',
                    brokerId: selectedPartner?.value || '',
                    categoryName: selectedSector?.name || ''
                }
            }
            if (warehouseSelected) {
                formatData = {
                    ...formatData,
                    data: {
                        ...formatData?.data || {},
                        warehouseId: warehouseSelected?.value || ''
                    }
                }
            }
            if (selectedAllocation) {
                formatData = {
                    ...formatData,
                    data: {
                        ...formatData?.data || {},
                        allocated: selectedAllocation?.value === 'Yes'
                    }
                }
            }
            if (selectedSource) {
                formatData = {
                    ...formatData,
                    data: {
                        ...formatData?.data || {},
                        source: selectedSource?.value
                    }
                }
            }
            if (formatFilters && formatFilters?.value) {
                formatData = {
                    ...formatData,
                    data: {
                        ...formatData?.data || {},
                        ...formatFilters || {}
                    }
                }
            }
            searchParams.set('page', `${paginationState?.pageNumber}`)
            searchParams.set('size', `${paginationState?.pageSize}`)
            setSearchParams(searchParams)
            await dispatch(tryToFetchItemAllocation(formatData)).unwrap()
        } catch (err) {
            // error here
        }
        setOpenedItems(undefined)
    }

    useEffect(() => {
        if (selectedMarket && selectedSector) {
            onGetItemsData()
        }
    }, [selectedMarket, selectedPartner, selectedSector, searchValue, paginationState])

    const onRequestToAllocateToHighestPrice = () => {
        setShowAllocationConfirmModal(!showAllocationConfirmModel)
    }

    const onChangeFilterInput = (value: string | null | undefined, type: string) => {
        const findField = templateFilters?.find((field) => field?.name === type);
        setFilterValues({
            ...(filterValues || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
        onResetPaginationSettings()
    };

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        if (type) {
            const findField = templateFilters?.find((field) => field?.name === type);
            setFilterValues({
                ...(filterValues || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
            onResetPaginationSettings()
        }
    };

    const onClearAllFilters = () => {
        setSearchValue(undefined)
        setFilterValues(undefined)
        setSelectedPartner(undefined)
        setSelectedAllocation(undefined)
        setWarehouseSelected(undefined)
        setSelectedSource(undefined)
        searchParams.delete('search')
        onResetPaginationSettings()
    }

    const onAllocateToHighestPrice = async () => {
        setLoadingAllocate(true)
        try {
            const filters = filterValues && Object.keys(filterValues)
            let formatFilters
            const filtersValuesFormat = filters && filters?.length > 0 && filters?.map((item: any) => {
                return {
                    key: item,
                    value: Object.keys(filterValues) ? filterValues?.[item] || undefined : undefined
                }
            })
            if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
                formatFilters = {
                    value: filtersValuesFormat?.filter((key: any) => key?.value)
                }
            }
            let formatData: any = {
                marketName: selectedMarket ? selectedMarket?.displayName : '',
                brokerId: selectedPartner?.value,
                categoryName: selectedSector?.name || ''
            }
            if (warehouseSelected) {
                formatData = {
                    ...formatData,
                    warehouseId: warehouseSelected?.value || ''
                }
            }
            if (selectedAllocation) {
                formatData = {
                    ...formatData,
                    allocated: selectedAllocation?.value === 'Yes'
                }
            }
            if (selectedSource) {
                formatData = {
                    ...formatData || {},
                    source: selectedSource?.value
                }
            }
            if (formatFilters && formatFilters?.value) {
                formatData = {
                    ...formatData || {},
                    ...formatFilters || {}
                }
            }
            await dispatch(tryToAllocationItemsToHighestPrice(formatData)).unwrap()
            onGetItemsData();
            setShowToast({ type: 'success', message: 'Items Allocated successfully' })
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
        onRequestToAllocateToHighestPrice()
        setLoadingAllocate(false)
    }

    const onSelectBrokerPrice = (id: string, itemId: string, data: any) => {
        const findIfSelected = (selectedNewPriceBrokers && selectedNewPriceBrokers?.length > 0) && selectedNewPriceBrokers?.find((item: any) => item?.itemId === itemId)
        if (findIfSelected) {
            const filterOut = (selectedNewPriceBrokers && selectedNewPriceBrokers?.length > 0) && selectedNewPriceBrokers?.filter((item: any) => item?.itemId !== itemId)
            if (findIfSelected?.id === id) {
                setSelectedNewPriceBrokers(filterOut || undefined)
            } else {
                setSelectedNewPriceBrokers([
                    ...filterOut || [],
                    {
                        id,
                        itemId,
                        data
                    }
                ])
            }
        } else {
            setSelectedNewPriceBrokers((prevSelected) => [
                ...prevSelected || [],
                {
                    id,
                    itemId,
                    data
                }
            ])
        }
    }

    const onAllocateToSelectedBrokers = async () => {
        setLoadingAllocate(true)
        try {
            let formatData: any = {
                marketName: selectedMarket ? selectedMarket?.displayName : '',
                brokerId: selectedPartner?.value,
                categoryName: selectedSector?.name || ''
            }
            if (selectedNewPriceBrokers && selectedNewPriceBrokers?.length > 0) {
                const formatItemsForBroker = selectedNewPriceBrokers?.map((item: any) => {
                    const priceInfo = item?.data.priceInfo.find((el: any) => el.agent === item.id)
                    return {
                        itemId: item?.itemId,
                        brokerId: item?.id,
                        price: priceInfo.quantity,
                        ...priceInfo,
                        ...item?.data || {}
                    }
                })
                formatData = {
                    ...formatData || {},
                    items: formatItemsForBroker || []
                }
            }
            await dispatch(tryToAllocateItemsToSpecificBrokers(formatData)).unwrap()
            onGetItemsData();
            setShowToast({ type: 'success', message: 'Items Allocated successfully' })
            setSelectedNewPriceBrokers(undefined)
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
        setLoadingAllocate(false)
    }

    const onChangeSearchValue = async (value: string | null | undefined, type: string) => {
        setSearchValue(value)
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
            filters: value || undefined
        })
        if (value) searchParams.set('search', value)
        else searchParams.delete('search')
        searchParams.set('page', '1')
        searchParams.delete('filter')
        setSearchParams(searchParams)
    }

    useLayoutEffect(() => {
        const paginationStateFormat = paginationState
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1)
        }
        if (query?.get('size') && Number(query?.get('size')) !== paginationState?.pageSize) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10)
        }
        if (query?.get('search') && query?.get('search') !== paginationState?.filters) {
            paginationStateFormat.filters = query.get('search') || undefined
            setSearchValue(query.get('search') || undefined)
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat)
        }
    }, [
        query?.get('page'),
        query?.get('size'),
        query?.get('search'),
    ])

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <p className='page-title'>Item Allocation</p>
            </div>
            <div>
                <AllocationFilters
                    markets={markets}
                    sectors={sectors}
                    partners={partners}
                    warehouses={warehouses}
                    payServices={payServices}
                    selectedWarehouse={warehouseSelected}
                    selectedAllocation={selectedAllocation}
                    selectedSource={selectedSource}
                    selectedMarket={selectedMarket}
                    selectedPartner={selectedPartner}
                    selectedSector={selectedSector}
                    items={templateFilters || []}
                    filterValues={filterValues || null}
                    showCatalogFilters={!!(selectedSector && templateFilters && templateFilters?.length > 0)}
                    onSelectFilter={onChangeSelectedFilter}
                    onChangeInput={onChangeFilterInput}
                    onChangeSelectedValue={onChangeSelectedFilterValue}
                    onClearAllFilters={onClearAllFilters}
                />
            </div>
            <div className='flex flex-row justify-end my-4'>
                <Button
                    label={'Allocate to Highest Price'}
                    dataQa={'allocate-to-highest-price'}
                    className={`${(!accessControl || pageAccess?.actions?.['Allocate To Highest Price']) ? 'btn-main' : 'btn-main-disable'} mr-2`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Allocate To Highest Price']) && onRequestToAllocateToHighestPrice()}
                />
            </div>
            {(selectedNewPriceBrokers && selectedNewPriceBrokers?.length > 0) &&
                <div className={'min-w-full py-2 px-2 flex flex-row justify-between items-center bg-[#feeedb]'}>
                    <p className='font-bold'>{selectedNewPriceBrokers ? selectedNewPriceBrokers?.length : 0} Rows selected</p>
                    <Button
                        icon={(loadingAllocate) ? <Loading /> : <></>}
                        label={'Allocate To Selected Brokers'}
                        dataQa={'allocate-to-selected-brokers'}
                        disabled={loadingAllocate}
                        onClickButton={() => !(loadingAllocate) && (!accessControl || pageAccess?.actions?.['Allocate To Specific Broker']) && onAllocateToSelectedBrokers()}
                        className={`${(loadingAllocate) && !(!accessControl || pageAccess?.actions?.['Allocate To Specific Broker']) ? 'btn-main-disable' : 'btn-main !bg-black !text-white'}  flex flex-row align-start my-2 !py-2`}
                    />
                </div>
            }
            <div>
                <Input
                    placeholder={'Filter table'}
                    dataQa={'filter-table'}
                    containerStyle='mb-0 pb-0 relative'
                    inputStyle={'mb-0 border-0 focus:outline-none'}
                    icon={'/assets/shared/search.svg'}
                    showValue={true}
                    inputValue={searchValue}
                    inputUniqueName={'search'}
                    onChangeInput={onChangeSearchValue}
                    hideInputSpace={true}
                />
            </div>
            <div>
                <ItemsAllocationTable
                    rows={rows}
                    state={state}
                    rowsLoading={itemsLoading}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    warehouses={warehouses}
                    activeMarket={selectedMarket}
                    openedItems={openedItems}
                    selectedBrokerPrice={selectedNewPriceBrokers}
                    onSelectBrokerPrice={onSelectBrokerPrice}
                    setPaginationState={setPaginationState}
                    onClickShowAllPricings={onClickShowAllPricings}
                />
            </div>
            {showAllocationConfirmModel &&
                <ConfirmHighestPriceModal
                    openModal={showAllocationConfirmModel}
                    loadingAllocate={loadingAllocate}
                    onApproveAction={onAllocateToHighestPrice}
                    handleCloseModal={onRequestToAllocateToHighestPrice}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    );
};

export default ItemAllocationAdmin;