import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import { tryToFindOperatingMarkets } from '../../../store/brokers/admin/partners/partnersSlice';
import { tryToFetchAllPayServices } from '../../../store/brokers/admin/pay-services/payServicesSlice';
import { tryToFetchQuestions } from '../../../store/brokers/shared-endpoints/questions/questionsSlice';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IPayServices } from '../../../interfaces/pay-services/IPayServices';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import SearchWithButton from '../../../shared/search-with-button';
import PriceEffectFilters from './details/PriceEffectFilters';
import PriceEffectsTable from './tables/PriceEffectsTable';


const PriceEffects = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<Array<any>>([]);
    const [search, setSearch] = useState<string>();
    const [userSectors, setUserSectors] = useState<Array<any>>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [payServices, setPayServices] = useState<any>();
    const [selectedPayService, setSelectedPayService] = useState<any>();
    const [selectedSector, setSelectedSector] = useState<any>();
    const [sortedOrder, setSortedOrder] = useState<string>();
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state?.questions?.questions?.data?.page);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const state = useAppSelector((state) => state.questions);
    const questionsAreLoading = useAppSelector((state) => state.questions.questionsAreLoading);
    const [userProfileMutation] = useUserProfileMutation();

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        });
    };

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Pricing')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Price Effects')
        setPageAccess(findChild || undefined)
    }, [accessControl])


    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

    useEffect(() => {
        if (state.questions) {
            const rowsData =
                state.questions?.data?.elements && Array.isArray(state.questions?.data.elements)
                    ? state.questions?.data.elements.map((r) => ({ ...r }))
                    : [];
            if (rowsData !== rows) {
                setRows(rowsData);
            }
        }
    }, [state.questions]);

    const onGetUserData = async () => {
        const response = await userProfileMutation(null).unwrap()
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ label: s?.displayName || s?.name, value: s?.id })) : []
        // setCategorySelected(formatSectors?.[0]?.value)
        setUserSectors(formatSectors || [])
        const payServicesResponse = await dispatch(tryToFetchAllPayServices()).unwrap()
        const formatPayServices = payServicesResponse && payServicesResponse?.data && payServicesResponse?.data?.length > 0 && payServicesResponse?.data?.map((service: IPayServices) => ({ ...service, label: service?.displayName, value: service?._id }))
        setPayServices(formatPayServices || [])
        const findTwigPayService = formatPayServices && formatPayServices?.length > 0 && formatPayServices?.find((item: any) => item?.label.toLowerCase() === 'wing')
        setSelectedPayService(findTwigPayService || formatPayServices?.[0] || undefined)
        onResetPaginationSettings()
    }

    const onGetPriceEffectsData = async () => {
        if (selectedPayService) {
            try {
                let formatData: any = {
                    ...paginationState || {},
                    data: {
                        type: 'SECONDARY'
                    }
                }
                if (selectedSector) {
                    formatData = {
                        ...formatData || {},
                        data: {
                            ...formatData?.data || {},
                            assetTemplateId: selectedSector?.value
                        }
                    }
                }
                if (selectedPayService) {
                    formatData = {
                        ...formatData || {},
                        data: {
                            ...formatData?.data || {},
                            serviceId: selectedPayService?.value
                        }
                    }
                }
                if (activeMarket) {
                    formatData = {
                        ...formatData || {},
                        data: {
                            ...formatData?.data || {},
                            marketId: activeMarket?.market?._id
                        }
                    }
                }
                if (sortedOrder && selectedPayService) {
                    formatData = {
                        ...formatData || {},
                        data: {
                            ...formatData?.data || {},
                            sortOrder: sortedOrder
                        }
                    }
                }
                if (search && search !== '') {
                    formatData = {
                        ...formatData || {},
                        data: {
                            ...formatData?.data || {},
                            search: search
                        }
                    }
                }
                await dispatch(tryToFetchQuestions(formatData)).unwrap()
            } catch (err) {
                // error here
            }
        }
    }

    useEffect(() => {
        onGetUserData()
        dispatch(tryToFindOperatingMarkets(null))
    }, [])

    useEffect(() => {
        if (activeMarket) {
            onGetPriceEffectsData()
        }
    }, [paginationState, activeMarket])

    useEffect(() => {
        if (activeMarketState && ((JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket)))) {
            setActiveMarket(activeMarketState || undefined)
        }
    }, [activeMarketState])

    const onChangeSortOrder = (name?: string) => {
        setSortedOrder((!sortedOrder || sortedOrder === 'desc') ? 'asc' : 'desc')
        onResetPaginationState()
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationSettings()
    }

    const onClearFilters = () => {
        setSelectedSector(undefined)
        onResetPaginationState()
    };

    const onSelectFilter = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'payService':
                    setSelectedPayService(value)
                    break;
                case 'sector':
                    setSelectedSector(value)
                    break;
            }
        }
        onResetPaginationState()
    }

    return (
        <div>
            <div className='mb-5'>
                <p className='page-title'>Price Effects</p>
            </div>
            <div className='my-10'>
                <PriceEffectFilters
                    sectors={userSectors || []}
                    selectedSector={selectedSector}
                    payServices={payServices}
                    selectedPayService={selectedPayService}
                    onSelectFilter={onSelectFilter}
                />
            </div>
            <div>
                <SearchWithButton
                    placeholder='Filter Price Effects'
                    dataQa={'filter-price-effects'}
                    search={search}
                    mainContainerStyle={'!grid-cols-10'}
                    onChangeSearchValue={onChangeSearchValue}
                    buttonLabel={'Clear All Filters'}
                    onClickButton={onClearFilters}
                    buttonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end bg-transparent mt-1'}
                />
            </div>
            <div>
                <PriceEffectsTable
                    rows={rows}
                    loading={questionsAreLoading}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    selectedService={selectedPayService}
                    sortedOrder={sortedOrder}
                    setPaginationState={setPaginationState}
                    onChangeSortOrder={onChangeSortOrder}
                />
            </div>
        </div>
    )
}

export default PriceEffects;