import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { PricingService } from './pricingApi';
import { IPricingListState } from '../../../../interfaces/pricing/IPricingList';
import { PaginatedPricing } from '../../../../interfaces/pricing/paginatedStore.type';
import { IPricing } from '../../../../interfaces/pricing/IPricing';
import { IPricingSchema } from '../../../../interfaces/pricing/IPricingSchema';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';

const initialState: IPricingListState = {
    pricingsAreLoading: false,
    pricingIsLoading: false,
    requestStatus: 'default',
    pricingUploader: {
        uploading: false,
        success: false,
    },
    message: '',
};

export const tryToFetchFilteredPricings = createAsyncThunk<PaginatedPricing, IPaginationPayload>(
    'pricing/tryToFetchFilteredPricings',
    async ({ pageNumber, pageSize, data }) => {
        const result = await PricingService.tryToFetchFilteredPricings(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToFetchFilteredAdminPricings = createAsyncThunk<PaginatedPricing, IPaginationPayload>(
    'pricing/tryToFetchFilteredAdminPricings',
    async ({ pageNumber, pageSize, data, brokerId }) => {
        const result = await PricingService.tryToFetchFilteredAdminPricings(pageNumber, pageSize, data, brokerId);
        return result?.data;
    },
);

export const tryToFetchBestPrice = createAsyncThunk<any, any>(
    'pricing/tryToFetchBestPrice',
    async (data) => {
        const result = await PricingService.tryToFetchBestPrice(data);
        return result?.data;
    },
);

export const tryToAddPricing = createAsyncThunk<ApiResponse<IPricingSchema>, IPricing>(
    'pricing/tryToAddPricing',
    async (data: IPricing, { rejectWithValue }) => {
        try {
            const result = await PricingService.tryToAddPricing(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDownloadPricing = createAsyncThunk<any, { templateId: string, market: string }>(
    'pricing/tryToDownloadPricing',
    async ({ templateId, market }) => {
        return await PricingService.tryToDownloadPricing(templateId, market);
    },
);

export const tryToDownloadPricingAdmin = createAsyncThunk<any, any>(
    'pricing/tryToDownloadPricingAdmin',
    async ({ data, brokerId }) => {
        const result = await PricingService.tryToDownloadPricingAdmin(data, brokerId);
        return result?.data;
    },
);

export const tryToUploadPricingCSV = createAsyncThunk<Promise<AxiosResponse<void>>, { data: any, templateId: string, marketId?: string, currency?: string, brokerId?: string, template?: any }>(
    'pricing/tryToUploadPricingCSV',
    async ({ data, templateId, marketId, currency, brokerId, template }, { rejectWithValue }) => {
        try {
            return await PricingService.tryToUploadPricingCSV(data, templateId, marketId, currency, brokerId, template);
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteBrokerPricing = createAsyncThunk<any, { brokerId: string; data?: any }>(
    'pricing/tryToDeleteBrokerPricing',
    async ({ brokerId, data }) => {
        return await PricingService.tryToDeleteBrokerPricing(brokerId, data);
    },
);

export const tryToChangePricingData = createAsyncThunk<any, any>(
    'pricing/tryToChangePricingData',
    async (data, { rejectWithValue }) => {
        try {
            return await PricingService.tryToChangePricingData(data);
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const pricingSlice = createSlice({
    name: 'pricings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchFilteredPricings
            .addCase(tryToFetchFilteredPricings.pending, (state) => {
                state.pricingsAreLoading = true;
            })
            .addCase(tryToFetchFilteredPricings.rejected, (state) => {
                state.pricingsAreLoading = false;
                state.pricings = undefined
            })
            .addCase(tryToFetchFilteredPricings.fulfilled, (state, action) => {
                state.pricingsAreLoading = false;
                state.pricings = action.payload;
            })

            // tryToFetchFilteredAdminPricings
            .addCase(tryToFetchFilteredAdminPricings.pending, (state) => {
                state.pricingsAreLoading = true;
            })
            .addCase(tryToFetchFilteredAdminPricings.rejected, (state) => {
                state.pricingsAreLoading = false;
            })
            .addCase(tryToFetchFilteredAdminPricings.fulfilled, (state, action) => {
                state.pricingsAreLoading = false;
                state.pricings = action.payload;
            })

            // tryToAddPricing
            .addCase(tryToAddPricing.pending, (state) => {
                state.pricingIsLoading = true;
            })
            .addCase(tryToAddPricing.rejected, (state) => {
                state.pricingIsLoading = false;
            })
            .addCase(tryToAddPricing.fulfilled, (state, action) => {
                state.pricingIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.pricings?.data) state.pricings.data.elements = [...state.pricings?.data.elements, data];
            })

            // tryToUploadPricingCSV
            .addCase(tryToUploadPricingCSV.pending, (state) => {
                state.pricingUploader.uploading = true;
            })
            .addCase(tryToUploadPricingCSV.rejected, (state) => {
                state.pricingUploader.uploading = false;
                state.pricingUploader.success = false;
            })
            .addCase(tryToUploadPricingCSV.fulfilled, (state) => {
                state.pricingUploader.uploading = false;
                state.pricingUploader.success = true;
            });
    },
});

export default pricingSlice.reducer;