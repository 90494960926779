import React from 'react';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import OffersTable from '../../../../shared/offers/OffersTable';


interface IOffersHistoryModal {
    openModal: boolean;
    offers?: any;
    handleCloseModal: () => void;
}

const OffersHistoryModal = ({
    openModal,
    offers,
    handleCloseModal,
}: IOffersHistoryModal) => {

    return (
        <Modal
            open={openModal}
            // showInRight={true}
            // title='Order Offers History'
            contentContainerStyle='!min-w-[650px]'
            onClose={() => handleCloseModal()}>
            <div className={'p-2 min-w-[600px]'}>
                <div className='border-b-2 border-[#e4e4e4] mt-8 mb-4'>
                    <p className='mb-4 font-semibold text-md'>All Offers History Details</p>
                </div>
                <div className='my-4'>
                    <OffersTable
                        rows={offers}
                    />
                </div>
                <div className='my-4 flex flex-row justify-center'>
                    <Button
                        label='Close'
                        className='btn-primary min-w-[250px] mt-10'
                        onClickButton={handleCloseModal}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default OffersHistoryModal;