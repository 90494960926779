import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchSingleRetailers } from '../../../store/brokers/admin/retailers/RetailersSlice';
import Loading from '../../../shared/Loading';
import WhiteContainer from '../../../shared/white-container';

const RetailerDetails = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [allMarkets, setAllMarkets] = useState<Array<any>>()
    const retailer = useAppSelector((state) => state?.retailers?.retailer);
    const retailerLoading = useAppSelector((state) => state?.retailers?.retailerIsLoading);
    const [getAllMarkets] = useGetAllMarketsMutation()

    const getMarketsData = async () => {
        const response = await getAllMarkets(null).unwrap()
        setAllMarkets(response || [])
    }

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleRetailers(id));
            getMarketsData()
        }
    }, []);

    const onGoBack = () => {
        navigate('/users?type=retailers')
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Retailer Details</p>
                </div>
            </div>
            {retailerLoading ?
                <div className='my-3 flex flex-row justify-center items-center'>
                    <Loading />
                </div>
                : <div>
                    <WhiteContainer type={'rounded'}>
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-1'>
                            <div>
                                <p className='mb-1'><b>Name:</b> {retailer?.name}</p>
                                <p className='mb-1'><b>Username:</b> {retailer?.username}</p>
                                <p className='mb-1'><b>Email:</b> {retailer?.email}</p>
                                <p className='mb-1'><b>Is Enabled:</b> {retailer?.enabled ? 'Yes' : 'No'}</p>
                                <p className='mb-1'><b>Priority</b> {retailer?.priority || 0}</p>
                            </div>
                            <div>
                                <p className='mb-1'><b>Company Name:</b> {retailer?.companyName}</p>
                                <p className='mb-1'><b>Country:</b> {retailer?.country}</p>
                                <p className='mb-1'><b>Markets: </b>
                                    {retailer?.markets && retailer?.markets?.length > 0 && retailer?.markets?.map((market, index) => {
                                        const findMarket = allMarkets && allMarkets?.length > 0 && allMarkets?.find((item) => item?._id === market?.id)
                                        if (findMarket) {
                                            return index > 0 ? `, ${findMarket?.label || findMarket?.name}` : findMarket?.label || findMarket?.name
                                        } else {
                                            return ''
                                        }
                                    })}
                                </p>
                                <p className='mb-1'><b>Categories:</b> {(retailer?.sectors && retailer?.sectors?.length > 0) ? retailer?.sectors?.map((s: any, index: number) => { return index > 0 ? `, ${s?.displayName || s?.name}` : s?.displayName || s?.name }) : ''}</p>
                                <p className='mb-1'><b>Company Registration Number:</b> {retailer?.companyRegistrationNumber}</p>
                                <p className='mb-1'><b>Phone Number:</b> {retailer?.phoneNumber}</p>
                                <p className='mb-1'><b>Annual Turnover:</b> {retailer?.annualGMV}</p>
                                {retailer?.vatNumber && <p className='mb-1'><b>VAT Number:</b> {retailer?.vatNumber}</p>}
                            </div>
                            <div className='my-6'>
                                <p className='font-semibold'>Image:</p>
                                <img src={retailer?.picture} className={'w-[80px] object-contain my-3'} />
                            </div>
                        </div>
                    </WhiteContainer>
                </div>
            }
        </div>
    )
}

export default RetailerDetails;