import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import { tryToAddBrokerComparison, tryToEditBrokerComparison, tryToFetchSingleBrokerComparison } from '../../../../store/brokers/admin/brokers-comparison-sites/brokersComparisonSitesSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import { tryToFetchAllAssetsTemplates } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';
import Error from '../../../../shared/error';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';


interface ICreateBrokerModal {
    openCreateBrokerModal: boolean;
    brokerId?: string | null;
    handleCloseBrokerModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    _id?: string;
    name: string;
    displayName: string
    sectors: any;
    markets: any;
    logoUrl: string;
};

const BrokerComparisonModal = ({
    openCreateBrokerModal,
    brokerId,
    handleCloseBrokerModal,
    onSaveChanges,
}: ICreateBrokerModal) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedSectors, setSelectedSectors] = useState<any>()
    const [selectedMarkets, setSelectedMarkets] = useState<any>()
    const [sectorItems, setSectorItems] = useState<Array<any>>([])
    const [marketItems, setMarketItems] = useState<Array<any>>([])
    const [fileSelected, setFileSelected] = useState<any>()
    const [createError, setCreateError] = useState<string | null>()
    const [getAllMarkets] = useGetAllMarketsMutation()
    const state = useAppSelector((state) => state?.assetsTemplates);
    const brokerComparisonSites = useAppSelector((state) => state?.brokersComparison?.brokerComparison);
    const brokerLoading = useAppSelector((state) => state?.brokersComparison?.brokerComparisonIsLoading);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
    } = useForm<FormValues>({});

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const getMarketsData = async () => {
        const response = await getAllMarkets(null).unwrap()
        const formatMarkets = response && response?.length > 0 && response?.map((market: any) => ({ ...market, value: market?._id }))
        setMarketItems(formatMarkets || [])
    }

    useEffect(() => {
        dispatch(tryToFetchAllAssetsTemplates());
        getMarketsData()
    }, []);

    useEffect(() => {
        if (state.allAssetTemplates) {
            const rows =
                state.allAssetTemplates && Array.isArray(state.allAssetTemplates)
                    ? state.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setSectorItems(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [state.allAssetTemplates]);


    useEffect(() => {
        if (brokerComparisonSites && brokerId) {
            setValue('name', brokerId ? brokerComparisonSites?.name || '' : '')
            setValue('displayName', brokerId ? brokerComparisonSites?.displayName || '' : '')
            setValue('logoUrl', brokerId ? brokerComparisonSites?.logoUrl || '' : '')
            setValue('sectors', brokerId ? brokerComparisonSites?.sectors : [])
            setValue('markets', brokerId ? brokerComparisonSites?.markets : [])
            if (brokerComparisonSites?.sectors) {
                const findSelectedSectors = brokerComparisonSites?.sectors?.length > 0 && brokerComparisonSites?.sectors?.map((sector: any) => ({ value: sector?.id, label: sector?.name }))
                setSelectedSectors(findSelectedSectors || [])
            }
            if (brokerComparisonSites?.markets) {
                const findSelectedMarkets = brokerComparisonSites?.markets?.length > 0 && brokerComparisonSites?.markets?.map((market: any) => ({ value: market?.id, label: market?.label }))
                setSelectedMarkets(findSelectedMarkets || [])
            }
        } else {
            reset();
            setSelectedMarkets(undefined);
            setSelectedSectors(undefined);
            setFileSelected(undefined);
        }

    }, [brokerComparisonSites, brokerId]);

    useEffect(() => {
        if (brokerId) {
            dispatch(tryToFetchSingleBrokerComparison(brokerId));
        }
    }, [brokerId])

    const onChangeSelectedOption = (e: any) => {
        if (brokerId) {
            let findIfAnyFixedIsMissing = false
            selectedSectors?.filter((sector: any) => sector?.isFixed)?.map((sector: any) => {
                const findNewSelected = e?.length > 0 && e?.find((newSelected: any) => newSelected?.value === sector?.value)
                if (!findNewSelected) findIfAnyFixedIsMissing = true
                return sector;
            })
            if (findIfAnyFixedIsMissing) {
                return;
            }
        }
        setSelectedSectors(e?.length > 0 ? e?.map((s: any) => ({ ...s })) : [])
        setValue('sectors', e?.length > 0 ? e?.map((s: any) => s?.value) : [])
    }

    const onChangeSelectedMarket = (e: any) => {
        if (brokerId) {
            let findIfAnyFixedIsMissing = false
            selectedMarkets?.filter((market: any) => market?.isFixed)?.map((market: any) => {
                const findNewSelected = e?.length > 0 && e?.find((newSelected: any) => newSelected?.value === market?.value)
                if (!findNewSelected) findIfAnyFixedIsMissing = true
                return market;
            })
            if (findIfAnyFixedIsMissing) {
                return;
            }
        }
        setSelectedMarkets(e?.length > 0 ? e?.map((s: any) => ({ ...s })) : [])
        setValue('markets', e?.length > 0 ? e?.map((s: any) => s?.value) : [])
    }

    const onSubmit = async (data: FormValues) => {
        const filterSectors = selectedSectors?.map((sector: any) => {
            return { name: sector?.label, id: sector?.value }
        })
        if (!(fileSelected || brokerComparisonSites?.logoUrl)) {
            setCreateError('Image is required.')
            return;
        }
        const formatMarkets = selectedMarkets && selectedMarkets?.length > 0 && selectedMarkets?.map((market: any) => (market?.value))
        const formData = new FormData()
        formData.append('name', data?.name)
        formData.append('displayName', data?.displayName)
        formData.append('markets', JSON.stringify(formatMarkets))
        formData.append('sectors', JSON.stringify(filterSectors))
        if (fileSelected) {
            formData.append('file', fileSelected)
        }
        try {
            if (brokerId) {
                const payload: any = {
                    data: formData,
                };
                payload.id = brokerId
                await dispatch(tryToEditBrokerComparison(payload)).unwrap();
            } else {
                await dispatch(tryToAddBrokerComparison(formData)).unwrap();
                setCreateError(null)
            }
            onSaveChanges('success', `Broker Comparison successfully ${brokerId ? 'changed' : 'added'}.`)
        } catch (error) {
            onSaveChanges('error', `${error}`)
        }
        reset();
        handleCloseBrokerModal()
        navigate('/brokers-comparison-sites');
    };

    const onCloseModal = () => {
        reset()
        handleCloseBrokerModal()
    }

    return (
        <Modal
            open={openCreateBrokerModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>{brokerId ? 'Edit Broker' : 'Create Broker'}</p>
                {createError && <Error text={createError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    {((brokerId && !brokerLoading) || !brokerId) ?
                        <div className='flex flex-col my-4'>
                            <div className='flex flex-col gap-x-3 justify-items-stretch'>
                                <Input
                                    placeholder='Name'
                                    dataQa={'name'}
                                    label={brokerId ? 'Name' : ''}
                                    disabled={!!brokerId}
                                    register={register('name', {
                                        required: {
                                            message: 'Name is required',
                                            value: true,
                                        },
                                        validate: (value: string) => !!value.trim() || 'Name is required'
                                    })}
                                    error={errors.name?.message}
                                />
                                <Input
                                    placeholder='Display Name'
                                    dataQa={'display-name'}
                                    label={brokerId ? 'Display Name' : ''}
                                    register={register('displayName', {
                                        required: {
                                            message: 'Display Name is required',
                                            value: true,
                                        },
                                        validate: (value: string) => !!value.trim() || 'Display Name is required'
                                    })}
                                    error={errors.displayName?.message}
                                />
                                <SelectCheckbox
                                    name={brokerId ? 'Category' : undefined}
                                    placeholder='Category'
                                    onChangeSelectedOption={onChangeSelectedOption}
                                    options={sectorItems}
                                    selectedOption={selectedSectors}
                                    dataQa={'create-category'}
                                    multiple={true}
                                    error={(Object.keys(errors).length !== 0 && (!watch('sectors') || watch('sectors')?.length <= 0)) ? 'Category is required' : undefined}
                                />
                                <SelectCheckbox
                                    name={brokerId ? 'Market' : undefined}
                                    placeholder='Market'
                                    onChangeSelectedOption={onChangeSelectedMarket}
                                    dataQa={'create-market'}
                                    options={marketItems}
                                    selectedOption={selectedMarkets}
                                    multiple={true}
                                    error={(Object.keys(errors).length !== 0 && (!watch('markets') || watch('markets')?.length <= 0)) ? 'Market is required' : undefined}
                                />
                            </div>
                            <div className='mb-6'>
                                <p className='mb-3 mt-4 font-medium'>Import broker image:</p>
                                <div className='flex flex-col items-start' {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button
                                        icon={<img alt='' src='/assets/shared/upload-file.svg' className='w-[30px] object-contains pr-2' />}
                                        className={'btn-main !bg-[#202020] !text-white !py-1 !shadow-none flex flex-row items-center'}
                                        label={'Select Image'}
                                        dataQa={'select-image'}
                                    />
                                    {fileSelected && fileSelected?.name
                                        ? <p className={'ml-3'}>{fileSelected?.name}</p>
                                        :
                                        <>
                                            {brokerId && <img alt='' src={brokerComparisonSites?.logoUrl} className={'w-[40px] my-3'} />}
                                        </>
                                    }
                                </div>
                            </div>
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    type={'submit'}
                                    dataQa={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                        : <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    }
                </form>
            </div>
        </Modal>
    )
}
export default BrokerComparisonModal;