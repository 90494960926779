import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { AdminGuard } from '../guards/RolesGuard';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    tryToFetchUserNotifications,
    tryToSetReadNotifications
} from '../store/brokers/admin/partners/partnersSlice';
import NotificationsCategorized from './notifications/NotificationsCategorized';
import EmptyNotifications from './notifications/EmptyNotifications';
import Navigation from './layout-components-no-auth/Navigation';
import Sidebar from './layout-components-no-auth/Sidebar';
import Profile from './layout-components-no-auth/Profile';
import Button from '../shared/button';

const LayoutNoAuth = ({
	noAuth,
}: {
    noAuth?: boolean
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [showNotifications, setShowNotifications] = useState<boolean>(false);
    const [notificationsData, setNotificationsData] = useState<any>();
    const [noNotificationsAllowed, setNoNotificationsAllowed] = useState<boolean>(false);
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [notificationTypes, setNotificationTypes] = useState<any>();
    const [page, setPage] = useState<number>(1);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const notificationsMenu = useRef<any>(null);
    const isAdmin = AdminGuard()

    const onChangeNotifications = (e?: any) => {
        e?.preventDefault()
        e?.stopPropagation()
        setShowNotifications(!showNotifications)
    }

    const uniqueArray = (array: any) =>
        array?.reduce((acc: any, item: any) => {
            if (!acc.some((el: any) => el?._id === item?._id)) {
                acc.push(item);
            }
            return acc;
        }, []);

    const onGetNotificationsData = async (types?: Array<string>, load?: boolean) => {
        try {
            const response = await dispatch(tryToFetchUserNotifications({ data: { types: types, dashboard: 'brokers' }, page: load ? page + 1 : page, size: notificationsData?.length || 0 })).unwrap();
            const allData = [
                ...notificationsData || [],
                ...response?.elements || [],
            ]
            setNotificationsData(uniqueArray(allData) || undefined)
            if (load) {
                setPage(page + 1)
            }
            setLoadMore(response?.page?.hasNextPage || false)
        } catch (err) {
            // err here
        }
    }

    const onClickLoadMore = () => {
        if (loadMore) {
            onGetNotificationsData(notificationTypes, true)
        }
    }

    const onMarkAsReadNotifications = async () => {
        const filterUnreadNotifications = notificationsData && notificationsData?.length > 0 && notificationsData?.filter((item: any) => (item?.status).toLowerCase() === 'unread')
        if (!filterUnreadNotifications || !(filterUnreadNotifications?.length > 0)) {
            return;
        }
        try {
            const formatData = filterUnreadNotifications && filterUnreadNotifications?.length > 0 && filterUnreadNotifications?.map((item: any) => item?._id || item?.id)
            await dispatch(tryToSetReadNotifications({ dashboard: 'brokers', items: formatData })).unwrap();
            const formatNotifications = notificationsData && notificationsData?.length > 0 && notificationsData?.map((item: any) => {
                return {
                    ...item || {},
                    status: 'READ'
                }
            })
            setNotificationsData(formatNotifications)
        } catch (err) {
            // err here
        }
    }

    useEffect(() => {
        if (showNotifications && notificationsData) {
            onMarkAsReadNotifications()
        }
    }, [showNotifications, notificationsData])

    useEffect(() => {
        if (!isAdmin && !noAuth) {
            const findPurchase = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Purchase Orders');
            const findSales = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Sales Orders');
            if (findPurchase || findSales || !accessControl) {
                const typesArray: any = [];
                if (findPurchase || !accessControl) typesArray.push('purchase-request')
                if (findSales || !accessControl) typesArray.push('sale-request')
                setNotificationTypes(typesArray)
                onGetNotificationsData(typesArray)
            } else {
                setNoNotificationsAllowed(true)
            }
        }
    }, [accessControl])

    const closeNotificationsPopover = (e: any) => {
        if (notificationsMenu?.current && !notificationsMenu?.current?.contains(e.target)) {
            setShowNotifications(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', closeNotificationsPopover, true);
        return () => {
            document.removeEventListener('click', closeNotificationsPopover, true);
        };
    }, []);

    const onClickSeeNotificationDetails = (id: string, type?: string, action?: string) => {
        setShowNotifications(false)
        if (type === 'purchase-request' && action === 'offer_submitted') {
            navigate(`/purchase-orders?search=${id}`)
        }
        if (type === 'sale-request' && action === 'offer_submitted') {
            navigate(`/sales-orders-broker?search=${id}`)
        }
    }

    return (
        <div className='bg-bgColor-primary'>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as='div' className='relative z-40 md:hidden' onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter='transition-opacity ease-linear duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='transition-opacity ease-linear duration-300'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
                    </Transition.Child>

                    <div className='fixed inset-0 flex z-40'>
                        <Transition.Child
                            as={Fragment}
                            enter='transition ease-in-out duration-300 transform'
                            enterFrom='-translate-x-full'
                            enterTo='translate-x-0'
                            leave='transition ease-in-out duration-300 transform'
                            leaveFrom='translate-x-0'
                            leaveTo='-translate-x-full'
                        >
                            <Dialog.Panel className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='ease-in-out duration-300'
                                    enterFrom='opacity-0'
                                    enterTo='opacity-100'
                                    leave='ease-in-out duration-300'
                                    leaveFrom='opacity-100'
                                    leaveTo='opacity-0'
                                >
                                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                                        <button
                                            type='button'
                                            className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className='sr-only'>Close sidebar</span>
                                            {/* <XIcon className='h-6 w-6 text-white' aria-hidden='true' /> */}
                                            <span className='h-6 w-6 text-white' aria-hidden='true' />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className='flex-shrink-0 flex items-center px-4'>
                                    <NavLink to='/apply'>
                                        {/* Logo here */}
                                        {/* <img
                                            className='h-8 w-auto'
                                            src='/logo/logo.svg'
                                            alt='Workflow'
                                        /> */}
                                    </NavLink>
                                </div>
                                <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                                    <nav className='px-2 space-y-1'>
                                        {!noAuth && <Navigation />}
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className='flex-shrink-0 w-14' aria-hidden='true'>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <Sidebar noAuth={noAuth} />
            <div className='flex flex-col flex-1 min-h-screen'>
                <div className='sticky top-0 z-50 m-4 h-16 bg-[#f7f9fb]'>
                    <div className='fixed left-0 top-1 right-0 z-20 flex-shrink-0 flex h-16 bg-white m-4 rounded-xl'>
                        <button
                            type='button'
                            className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden'
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className='sr-only'>Open sidebar</span>
                            {/* <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' /> */}
                        </button>
                        <div className='flex-1 px-4 flex justify-between'>
                            <div className='flex-1 flex'>
                                <NavLink
                                    to='/home'
                                    className='hidden md:block h-11 w-auto my-auto mx-3'
                                >
                                    {/* Logo here */}
                                    {/* <img
                                    className='h-11 w-auto'
                                    src='/logo/logo.svg'
                                    alt='Workflow'
                                /> */}
                                </NavLink>

                                {<Profile noAuth={noAuth} />}
                                <div className='flex ml-auto'>
                                    {/* <form className='hidden w-full md:flex md:ml-0 my-auto' action='#' method='GET'>
                                        <label htmlFor='search-field' className='sr-only'>
                                            Search
                                        </label>
                                        <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
                                            <div className='absolute inset-y-0 left-2 flex items-center pointer-events-none'>
                                                <img alt='search' className='h-4 w-4' src='/svg/layout/search-icon.svg' />
                                            </div>
                                            <input
                                                id='search-field'
                                                className='bg-bgColor-search block h-full pl-8 p-3 rounded-xl border-transparent placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm'
                                                placeholder='Search'
                                                type='search'
                                                name='search'
                                            />
                                        </div>
                                    </form> */}
                                    <button
                                        type='button'
                                        className='ml-2 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none'
                                        onClick={(e) => !noNotificationsAllowed && onChangeNotifications(e)}
                                    >
                                        <span className='sr-only'>View notifications</span>
                                        <img alt='search' className='h-6 w-6' src='/svg/layout/notifications-icon-new.svg' />
                                        {/* <BellIcon className='h-6 w-6' aria-hidden='true' /> */}
                                        {notificationsData?.filter((item: any) => item?.status === 'UNREAD')?.length > 0 &&
                                            <span className={`absolute top-3 right-4 inline-flex items-center justify-center ${notificationsData?.filter((item: any) => item?.status === 'UNREAD')?.length > 9 ? 'px-[5px]' : 'px-[7px]'} py-1 text-[10px] font-bold leading-none text-white bg-red-600 rounded-full`}>
                                                {notificationsData?.filter((item: any) => item?.status === 'UNREAD')?.length || 0}
                                            </span>
                                        }
                                    </button>
                                    {showNotifications &&
                                        <div
                                            ref={notificationsMenu}
                                            className="absolute right-0 mt-[60px] w-[550px] max-h-[350px] bg-white shadow-xl rounded-lg overflow-y-auto z-50"
                                            style={{ maxWidth: '550px', maxHeight: '350px', height: '350px' }}>
                                            {(notificationsData && notificationsData?.length > 0) ?
                                                <div className={'p-3'}>
                                                    <NotificationsCategorized
                                                        notificationsData={notificationsData}
                                                        onClickSeeNotificationDetails={onClickSeeNotificationDetails}
                                                    />
                                                    {loadMore &&
                                                        <div className='my-3 flex flex-row justify-center'>
                                                            <Button
                                                                label={'Load more'}
                                                                className='btn-primary-text-underline !text-[#000000]'
                                                                onClickButton={onClickLoadMore}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                : <EmptyNotifications />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <main className='md:pl-64 w-full h-full md:min-h-[calc(100vh-6rem)]'>
                    <div className='md:ml-4 xl:ml-20 px-5 py-4 pb-[70px]'>
                        <Outlet />
                    </div>
                </main>
            </div>
        </div >
    )
}

export default LayoutNoAuth;
