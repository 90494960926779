import React, { useState, useCallback, useEffect } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { VAT_TYPE_ITEMS } from '../../../../utils/constants/vat-type';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToImportBatchPurchase, tryToValidatedImportedItems } from '../../../../store/brokers/partners/purchase-orders/purchaseOrdersSlice';
import ImportCategoryFile from '../details/ImportCategoryFile';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Switch from '../../../../shared/switch';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';


interface IImportOrderModal {
    openImportModal: boolean;
    categories?: any;
    handleCloseModal: () => void;
    onSuccessfullyUploadItems: () => void;
}

const ImportOrderModal = ({
    openImportModal,
    categories,
    handleCloseModal,
    onSuccessfullyUploadItems
}: IImportOrderModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>();
    const [stockSelected, setStockSelected] = useState<any>();
    const [categoriesSelected, setCategoriesSelected] = useState<any>();
    const [totalPrice, setTotalPrice] = useState<any>();
    const [templateFields, setTemplateFields] = useState<any>();
    const [inventoryFields, setInventoryFields] = useState<any>();
    const [extendedFields, setExtendedFields] = useState<any>();
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [fileSelected, setFileSelected] = useState<any>();
    const [negotiate, setNegotiate] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [showTotalPrice, setShowTotalPrice] = useState<boolean>(false);
    const [successRows, setSuccessRows] = useState<any>();
    const [rowsError, setRowsError] = useState<any>()
    const [showReplacements, setShowReplacements] = useState<{ show: boolean, data?: any }>();
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);

    const onDrop = useCallback((acceptedFiles: any, categoryId: string) => {
        setFileSelected((prevState: any) => ({
            ...prevState,
            [categoryId]: acceptedFiles?.[0],
        }));
        setSuccessRows(undefined)
        setShowReplacements(undefined)
    }, []);

    const onCloseModal = () => {
        handleCloseModal();
        setImportedSuccessfully(false);
        setLoadingSubmit(false)
        setImportError(undefined);
        setFileSelected(undefined);
        setStockSelected(undefined)
        setTotalPrice(undefined)
        setTemplateFields(undefined)
        setInventoryFields(undefined)
        setCategoriesSelected(undefined)
        setExtendedFields(undefined)
        setNegotiate(false)
        setRowsError(undefined)
        setShowReplacements(undefined)
        setSuccessRows(undefined)
    }

    const onGettingAllAssetFields = async () => {
        if (categoriesSelected && categoriesSelected?.length > 0) {
            try {
                await Promise.all(categoriesSelected && categoriesSelected?.length > 0 && categoriesSelected?.map(async (item: any) => {
                    if (!templateFields?.[item?.name]) {
                        const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(item?.id)).unwrap();
                        if (response?.fields) {
                            setTemplateFields((prevTemplateFields: any) => ({
                                ...prevTemplateFields || {},
                                [item?.id || '']: response?.fields,
                            }));
                            setExtendedFields((prevFields: any) => ({
                                ...prevFields || {},
                                [item?.id || '']: response?.extendedFields,
                            }));
                            setInventoryFields((prevInventoryFields: any) => ({
                                ...prevInventoryFields || {},
                                [item?.id || '']: response?.inventoryFields
                            }));
                        }
                    }
                    return item;
                }))
            } catch (err) {
                // error here
            }
        }
    };

    useEffect(() => {
        if (categoriesSelected) {
            onGettingAllAssetFields()
        }
    }, [categoriesSelected])

    const onChangeShowTotalPrice = () => {
        setShowTotalPrice(!showTotalPrice)
    }

    const parseData = (assets: any) => {
        const headers = assets?.[0];
        const keys = Object.values(headers).map((header: any) => header?.toLowerCase());
        const result = assets?.slice(1)
            .filter((asset: any) => Object.keys(asset).length > 0)
            .map((asset: any) => {
                return keys?.reduce((obj, key, index) => {
                    if (typeof asset[`Column${index + 1}`] === 'string') {
                        const trimmedValue = asset[`Column${index + 1}`]?.trim();
                        obj[key] = trimmedValue;
                    } else if (asset[`Column${index + 1}`] !== null && asset[`Column${index + 1}`] !== undefined) {
                        obj[key] = asset[`Column${index + 1}`] || undefined;
                    }
                    return obj;
                }, {});
            });
        return result;
    };

    const onUploadFile = async () => {
        setLoadingSubmit(true)
        let errorInRows: any;
        try {
            const items: any = [];
            let validationItems: any;
            await Promise.all(
                categoriesSelected?.map(async (item: any) => {
                    if (fileSelected?.[item?.id]) {
                        const fileExtension = fileSelected?.[item?.id]?.name.split('.').pop().toLowerCase();
                        if (fileExtension === 'csv') {
                            await new Promise<void>((resolve) => {
                                Papa.parse(fileSelected?.[item?.id], {
                                    header: true,
                                    skipEmptyLines: true,
                                    complete: (results: any) => {
                                        if (results?.data && results?.data?.length > 0) {
                                            results?.data?.forEach((data: any, index: number) => {
                                                let templateFieldsFormated = {}
                                                let extendedFieldsFormated = {}
                                                let inventoryFieldsFormated = {}
                                                templateFields?.[item?.id] && templateFields?.[item?.id]?.length > 0 && templateFields?.[item?.id]?.map((field: any) => {
                                                    if (field?.name) {
                                                        templateFieldsFormated = {
                                                            ...templateFieldsFormated || {},
                                                            [field?.name]: data?.[field?.name] && typeof data?.[field?.name] === 'string' ? (data?.[field?.name]).trim() : data?.[field?.name]
                                                        }
                                                    }
                                                    return field
                                                })
                                                extendedFields?.[item?.id] && extendedFields?.[item?.id]?.length > 0 && extendedFields?.[item?.id]?.map((field: any) => {
                                                    if (field?.name) {
                                                        extendedFieldsFormated = {
                                                            ...extendedFieldsFormated || {},
                                                            [field?.name]: data?.[field?.name] && typeof data?.[field?.name] === 'string' ? (data?.[field?.name]).trim() : data?.[field?.name]
                                                        }
                                                    }
                                                    return field
                                                })
                                                inventoryFields?.[item?.id] && inventoryFields?.[item?.id]?.length > 0 && inventoryFields?.[item?.id]?.map((field: any) => {
                                                    if (field?.name) {
                                                        inventoryFieldsFormated = {
                                                            ...inventoryFieldsFormated || {},
                                                            [field?.name]: data?.[field?.name] && typeof data?.[field?.name] === 'string' ? (data?.[field?.name]).trim() : data?.[field?.name]
                                                        }
                                                    }
                                                    return field
                                                })
                                                validationItems = {
                                                    ...validationItems || {},
                                                    [item?.id || '']: [
                                                        ...validationItems?.[item?.id] || [],
                                                        {
                                                            ...templateFieldsFormated || {},
                                                            ...extendedFieldsFormated || {},
                                                            ...inventoryFieldsFormated || {},
                                                            index: index + 2
                                                        }
                                                    ]
                                                }
                                                items.push({
                                                    descriptor: {
                                                        ...templateFieldsFormated || {},
                                                        ...extendedFieldsFormated || {},
                                                    },
                                                    inventory: inventoryFieldsFormated,
                                                    price: data?.price ? Number(data?.price || 0) : undefined,
                                                    qty: Number(data?.quantity || 1),
                                                    categoryId: item?.id,
                                                    currency: activeMarketState?.currency?.descriptor?.name
                                                });
                                            });
                                        }
                                        resolve();
                                    },
                                });
                            });
                        } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                            await new Promise<void>((resolve, reject) => {
                                const reader = new FileReader();
                                reader.onload = async (e: any) => {
                                    try {
                                        const data = new Uint8Array(e?.target?.result);
                                        const workbook = XLSX.read(data, { type: 'array' });
                                        const sheetName = workbook.SheetNames[0];
                                        const sheet = workbook.Sheets[sheetName];
                                        const mergedRanges = sheet['!merges'] || [];
                                        const rawExcelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                                        const excelData = rawExcelData.map((row: any, rowIndex) => {
                                            const isMergedRow = mergedRanges.some((merge) =>
                                                rowIndex >= merge.s.r && rowIndex <= merge.e.r
                                            );
                                            if (isMergedRow) return null;
                                            return row?.reduce((acc: any, cell: any, index: any) => {
                                                const columnKey = `Column${index + 1}`;
                                                acc[columnKey] = cell !== '' && cell != null ? cell : undefined;
                                                return acc;
                                            }, {});
                                        }).filter((row) => row !== null);
                                        const formatData = parseData(excelData || []);
                                        (formatData && formatData?.length > 0) && formatData?.map((data: any, index: number) => {
                                            let templateFieldsFormated = {}
                                            let extendedFieldsFormated = {}
                                            let inventoryFieldsFormated = {}
                                            templateFields?.[item?.id] && templateFields?.[item?.id]?.length > 0 && templateFields?.[item?.id]?.map((field: any) => {
                                                if (field?.name) {
                                                    templateFieldsFormated = {
                                                        ...templateFieldsFormated || {},
                                                        [field?.name]: data?.[field?.name] && typeof data?.[field?.name] === 'string' ? (data?.[field?.name]).trim() : data?.[field?.name]
                                                    }
                                                }
                                                return field
                                            })
                                            extendedFields?.[item?.id] && extendedFields?.[item?.id]?.length > 0 && extendedFields?.[item?.id]?.map((field: any) => {
                                                if (field?.name) {
                                                    extendedFieldsFormated = {
                                                        ...extendedFieldsFormated || {},
                                                        [field?.name]: data?.[field?.name] && typeof data?.[field?.name] === 'string' ? (data?.[field?.name]).trim() : data?.[field?.name]
                                                    }
                                                }
                                                return field
                                            })
                                            inventoryFields?.[item?.id] && inventoryFields?.[item?.id]?.length > 0 && inventoryFields?.[item?.id]?.map((field: any) => {
                                                if (field?.name) {
                                                    inventoryFieldsFormated = {
                                                        ...inventoryFieldsFormated || {},
                                                        [field?.name]: data?.[field?.name] && typeof data?.[field?.name] === 'string' ? (data?.[field?.name]).trim() : data?.[field?.name]
                                                    }
                                                }
                                                return field
                                            })
                                            validationItems = {
                                                ...validationItems || {},
                                                [item?.id || '']: [
                                                    ...validationItems?.[item?.id] || [],
                                                    {
                                                        ...templateFieldsFormated || {},
                                                        ...extendedFieldsFormated || {},
                                                        ...inventoryFieldsFormated || {},
                                                        index: index + 2
                                                    }
                                                ]
                                            }
                                            items.push({
                                                descriptor: {
                                                    ...templateFieldsFormated || {},
                                                    ...extendedFieldsFormated || {},
                                                },
                                                inventory: inventoryFieldsFormated,
                                                price: data?.price ? Number(data?.price || 0) : undefined,
                                                qty: Number(data?.quantity || 1),
                                                categoryId: item?.id,
                                                currency: activeMarketState?.currency?.descriptor?.name
                                            });
                                            return item;
                                        });
                                        resolve();
                                    } catch (error) {
                                        reject(error);
                                    }
                                };
                                reader.onerror = (error) => reject(error);
                                reader.readAsArrayBuffer(fileSelected?.[item?.id]);
                            });
                        }
                    }
                    return item;
                })
            );
            setSuccessRows(items)
            const formatDataSuccess: any = []
            if (validationItems) {
                let replacementErrors: any;
                const categoriesToValidate = Object.keys(validationItems)
                await Promise.all(
                    categoriesToValidate?.map(async (category: any, idx: number) => {
                        const itemsToValidate = {
                            assetTemplateId: category,
                            data: validationItems?.[category] || []
                        }
                        const response = await dispatch(tryToValidatedImportedItems(itemsToValidate)).unwrap();
                        response?.data && response?.data?.length > 0 && response?.data?.map((responseItem: any) => {
                            if (responseItem?.succeed) {
                                let templateFieldsFormated = {}
                                let extendedFieldsFormated = {}
                                let inventoryFieldsFormated = {}
                                templateFields?.[category] && templateFields?.[category]?.length > 0 && templateFields?.[category]?.map((field: any) => {
                                    templateFieldsFormated = {
                                        ...templateFieldsFormated || {},
                                        [field?.name || '']: responseItem?.[field?.name || '']
                                    }
                                    return field
                                })
                                extendedFields?.[category] && extendedFields?.[category]?.length > 0 && extendedFields?.[category]?.map((field: any) => {
                                    extendedFieldsFormated = {
                                        ...extendedFieldsFormated || {},
                                        [field?.name || '']: responseItem?.[field?.name || '']
                                    }
                                    return field
                                })
                                inventoryFields?.[category] && inventoryFields?.[category]?.length > 0 && inventoryFields?.[category]?.map((field: any) => {
                                    inventoryFieldsFormated = {
                                        ...inventoryFieldsFormated || {},
                                        [field?.name || '']: responseItem?.[field?.name || '']
                                    }
                                    return field
                                })
                                formatDataSuccess.push({
                                    descriptor: {
                                        ...templateFieldsFormated || {},
                                        ...extendedFieldsFormated || {},
                                    },
                                    inventory: inventoryFieldsFormated,
                                    price: responseItem?.price,
                                    qty: Number(responseItem?.quantity || 1),
                                    categoryId: category,
                                    currency: activeMarketState?.currency?.descriptor?.name
                                })
                            } else {
                                errorInRows = {
                                    ...errorInRows || {},
                                    [category]: [
                                        ...errorInRows?.[category] || [],
                                        responseItem || {},
                                    ]
                                }
                            }
                            return responseItem
                        })
                        if (response?.replacements && response?.replacements?.length > 0) {
                            replacementErrors = {
                                ...replacementErrors || {},
                                [category]: response?.replacements
                            }
                        }
                    })
                )
                setSuccessRows(formatDataSuccess)
                if (replacementErrors) {
                    setShowReplacements({ show: true, data: replacementErrors })
                } else {
                    setShowReplacements(undefined)
                }
                if (!errorInRows) {
                    setImportError(undefined)
                    setFileSelected(undefined)
                    setRowsError(undefined)
                } else {
                    setImportError(undefined)
                    setRowsError(errorInRows || undefined)
                }
                if (replacementErrors || errorInRows) {
                    return;
                }
            }
            const payload = {
                categories: (categoriesSelected && categoriesSelected?.length > 0) && categoriesSelected?.map((item: any) => ({ id: item?.id, code: item?.name })),
                items: formatDataSuccess?.length > 0 ? formatDataSuccess : items || [],
                totalPrice: Number(totalPrice || 0),
                marketId: activeMarketState?.market?._id,
                negotiateOffer: negotiate,
                stockVat: stockSelected?.value,
            }
            await dispatch(tryToImportBatchPurchase(payload)).unwrap();
            onSuccessfullyUploadItems()
            setImportError(undefined)
            setSuccessRows(undefined)
            onCloseModal()
            // setImportedSuccessfully(true)
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const onAcceptChanges = async () => {
        try {
            const payload = {
                categories: (categoriesSelected && categoriesSelected?.length > 0) && categoriesSelected?.map((item: any) => ({ id: item?.id, code: item?.name })),
                items: successRows || [],
                totalPrice: Number(totalPrice || 0),
                marketId: activeMarketState?.market?._id,
                negotiateOffer: negotiate,
                stockVat: stockSelected?.value,
            }
            await dispatch(tryToImportBatchPurchase(payload)).unwrap();
            onSuccessfullyUploadItems()
            setImportError(undefined)
            setShowReplacements(undefined)
            onCloseModal()
            // setImportedSuccessfully(true)
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const onValidateImport = (acceptChanges?: boolean) => {
        setLoadingSubmit(true)
        if (!stockSelected || !(categoriesSelected && categoriesSelected?.length > 0) || !fileSelected) {
            setImportError('Please fill all data and import at least one file to continue!')
            setLoadingSubmit(false)
            return;
        }
        let foundError: boolean = false
        categoriesSelected?.forEach((item: any) => {
            if (!fileSelected?.[item?.id]) {
                foundError = true
            }
        })
        if (foundError) {
            setImportError('Please upload file for each selected category!')
            setLoadingSubmit(false)
            return;
        }
        setImportError(undefined)
        if (acceptChanges) {
            onAcceptChanges()
        } else {
            onUploadFile()
        }
    }

    const onChangeSelectedVat = (e: any, type?: string) => {
        setStockSelected(e)
    }

    const onChangeCategoriesSelected = (e: any, type?: string) => {
        setCategoriesSelected(e)
    }

    const onChangeTotalPrice = (value: any, type?: string) => {
        setTotalPrice(/^\d*\.?\d*$/.test(value) ? value : totalPrice)
    }

    const onEnableNegotiations = () => {
        setNegotiate(!negotiate)
    }

    return (
        <Modal
            open={openImportModal}
            showInRight={true}
            title='Upload Items by CSV or Excel'
            contentContainerStyle='!min-w-[700px]'
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[650px]'}>
                {importedSuccessfully ?
                    <div className={'w-full flex flex-col items-center justify-between'}>
                        <div>
                            <p className='text-md font-medium'>Order has been successfully imported.</p>
                        </div>
                        <img src={'/assets/shared/success-mark.svg'} className={'h-[100px] object-contain my-10'} />
                        <Button
                            label='Close'
                            className='btn-primary-rounded-less my-3 min-w-[150px] !shadow-none'
                            onClickButton={handleCloseModal}
                        />
                    </div>
                    : <div>
                        <div className='border-b-2 border-[#e4e4e4] mb-4'>
                            <p className='text-sm font-semibold'>Import CSV or Excel File</p>
                        </div>
                        {importError && <Error text={importError} />}
                        {(rowsError && Object.keys(rowsError)?.length > 0) &&
                            <div className="flex flex-col !text-red-600">
                                <p className="mb-2">These rows were not valid and can&apos;t be imported:</p>
                                <div className="flex flex-wrap">
                                    {Object.keys(rowsError)?.map((key: string, keyIndex: number) => {
                                        const findCategory = categories && categories?.length > 0 && categories?.find((category: any) => category?.value === key)
                                        return (
                                            <div key={keyIndex} className="mb-1">
                                                <p className="font-bold">{findCategory?.label || findCategory?.name}:</p>
                                                {rowsError?.[key]?.map((item: any, index: number) => (
                                                    <span key={index}>
                                                        {index === 0 ? '' : ', '}
                                                        {item?.index}
                                                    </span>
                                                ))}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                        <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                            <SelectCheckbox
                                name='Stock VAT'
                                containerStyle='max-w-[90%]'
                                options={VAT_TYPE_ITEMS}
                                selectedOption={stockSelected}
                                onChangeSelectedOption={onChangeSelectedVat}
                            />
                            <SelectCheckbox
                                name='Categories'
                                containerStyle='max-w-[90%]'
                                options={categories || []}
                                multiple={true}
                                selectedOption={categoriesSelected}
                                onChangeSelectedOption={onChangeCategoriesSelected}
                            />
                            {categoriesSelected && categoriesSelected?.length > 0 && categoriesSelected?.map((item: any, idx: number) => {
                                return (
                                    <div key={idx} className='flex flex-row my-4'>
                                        <p className='mr-3 font-bold mt-2'>{item?.label}:</p>
                                        <ImportCategoryFile
                                            categoryId={item?.id || ''}
                                            onDrop={onDrop}
                                        />
                                        {fileSelected?.[item?.id] && <p className='mt-2 mb-3 truncate'>{fileSelected?.[item?.id]?.name}</p>}
                                    </div>
                                )
                            })}
                            {categoriesSelected && categoriesSelected?.length > 0 &&
                                <div>
                                    <div className='flex flex-row justify-start items-center mb-6 min-w-[100%] mt-5 mb-3'>
                                        <div className='flex items-center mr-2'>
                                            <input
                                                onChange={() => onChangeShowTotalPrice()}
                                                checked={showTotalPrice}
                                                id='checkbox-all-1'
                                                data-qa={'checkbox'}
                                                type='checkbox'
                                                className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-yellow-300 rounded focus:accent-yellow-300 focus:ring-2' />
                                            <label htmlFor='checkbox-all-1' className='sr-only'>
                                            </label>
                                        </div>
                                        <div>
                                            <p className='font-bold'>Set a batch price for the total purchase order.</p>
                                            <p className='text-[#a4a4a4]'>By doing this you don&apos;t need to add prices on csv file.</p>
                                        </div>
                                    </div>
                                    <Input
                                        label={`Amount (${activeMarketState?.currency?.descriptor?.name})`}
                                        showValue={true}
                                        containerStyle='max-w-[90%]'
                                        disabled={!showTotalPrice}
                                        inputValue={totalPrice}
                                        onChangeInput={onChangeTotalPrice}
                                    />
                                </div>
                            }
                            <div className='flex flex-row items-center my-4 justify-between max-w-[90%]'>
                                <p className='font-semibold mr-4'>Would you be willing to engage in negotiations?</p>
                                <Switch
                                    checked={negotiate}
                                    onToggleSwitch={() => onEnableNegotiations()}
                                    dataQa={'enabled-negotiation'}
                                />
                            </div>
                            {showReplacements?.show &&
                                <div className="my-5 border-t-2 pt-3">
                                    <p className="font-semibold text-[16px] mb-2">Suggested Replacements for File Columns</p>
                                    {showReplacements?.data &&
                                        Object.keys(showReplacements.data).map((key: string, index: number) => {
                                            const findCategory = categories && categories?.length > 0 && categories?.find((category: any) => category?.value === key)
                                            return (
                                                <div key={index} className="my-2">
                                                    <p className="font-bold text-[16px] mb-1">{findCategory?.label || findCategory?.name}</p>
                                                    {showReplacements?.data?.[key].map((item: any, subIndex: number) => (
                                                        <div key={subIndex} className="flex flex-row items-center my-1 mx-1">
                                                            <p className="font-bold">- {item?.name}</p>
                                                            <p className="mx-1">with</p>
                                                            <p className="font-bold">{item?.replaceWith}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        })
                                    }
                                    <p className="italic">You can choose to accept or decline these suggestions as needed.</p>
                                </div>
                            }
                            <div className='flex flex-row items-center justify-center my-4'>
                                <Button
                                    label={'Close'}
                                    className='btn-primary-rounded-less mr-4 min-w-[120px] !shadow-none min-w-[150px]'
                                    onClickButton={onCloseModal}
                                />
                                {showReplacements?.show ?
                                    <Button
                                        label={'Accept Suggestions'}
                                        onClickButton={() => fileSelected && !loadingSubmit && !rowsError && onValidateImport(true)}
                                        className={'btn-primary-rounded-less !shadow-none min-w-[150px]'}
                                    />
                                    : <Button
                                        label='Submit'
                                        onClickButton={() => fileSelected && !loadingSubmit && !rowsError && onValidateImport()}
                                        className={`${fileSelected && !loadingSubmit ? 'btn-primary-rounded-less' : 'btn-primary-rounded-less-disable'} !shadow-none min-w-[150px]`}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Modal>
    )
}

export default ImportOrderModal;
