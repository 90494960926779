import React from 'react';
import Button from '../../../../shared/button';

interface IImportCsvSuccess {
    handleCloseModal: () => void;
}

const ImportCsvSuccess = ({
    handleCloseModal
}: IImportCsvSuccess) => {
    return (
        <div className={'w-full flex flex-col items-center justify-between'}>
            <div>
                <p className='text-xl font-bold text-center'>Postage Fees uploaded successfully</p>
            </div>
            <img src={'/assets/shared/success-mark.svg'} className={'h-[150px] object-contain my-6'} />
            <Button
                label='Close'
                dataQa={'close'}
                className='btn-main my-3 min-w-[150px] !shadow-none'
                onClickButton={handleCloseModal}
            />
        </div>
    )
}

export default ImportCsvSuccess;