import React from 'react';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IPricingReviewsFilters {
    markets?: Array<IAllMarkets>;
    partners?: Array<IPartners>;
    selectedMarket?: any;
    selectedPartner?: any;
    onSelectPartner: (id: string) => void;
    onSelectMarket: (id: string) => void;
}

const PricingReviewsFilters = ({
    markets,
    partners,
    selectedMarket,
    selectedPartner,
    onSelectPartner,
    onSelectMarket
}: IPricingReviewsFilters) => {
    return (
        <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
            <div className='my-4'>
                <SelectCheckbox
                    name={'Markets'}
                    placeholder={''}
                    dataQa={'market-selected'}
                    selectStyle={{backgroundColor:'transparent', borderRadius:'15px'}}
                    selectedOption={selectedMarket}
                    options={markets || []}
                    onChangeSelectedOption={onSelectMarket}
                />
            </div>
            <div className='my-4'>
                <SelectCheckbox
                    name={'Partners'}
                    placeholder={' '}
                    dataQa={'partner-selected'}
                    selectStyle={{backgroundColor:'transparent', borderRadius:'15px'}}
                    selectedOption={selectedPartner}
                    options={partners || []}
                    onChangeSelectedOption={onSelectPartner}
                />
            </div>
        </div>
    )
}

export default PricingReviewsFilters;