import { AxiosResponse } from 'axios';
import { OFFER_API_URL, ORDERS_URL } from '../../../../config';
import ordersAxios from '../../../../utils/axios/orders.axios';
import offersAxios from '../../../../utils/axios/offers.axios';
import { PaginatedItemAllocation } from '../../../../interfaces/item-allocation/paginatedStore.type';

const tryToFetchItemAllocation = (
    pageNumber: number,
    pageSize: number,
    filters?: any,
    data?: any
): Promise<AxiosResponse<PaginatedItemAllocation>> => {
    const url = filters ?
        `${ORDERS_URL}/items/inventory?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${ORDERS_URL}/items/inventory?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<PaginatedItemAllocation>(url, data);
};

const tryToAllocationItemsToHighestPrice = (data: any): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/offers/inventory/allocate-items`, data);
};

const tryToAllocateItemsToSpecificBrokers = (data: any): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/offers/inventory/allocate-specific-items`, data);
};

export const ItemAllocationService = {
    tryToFetchItemAllocation,
    tryToAllocationItemsToHighestPrice,
    tryToAllocateItemsToSpecificBrokers,
};
