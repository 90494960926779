import React from 'react';
import { ITicketResponse, ITicketThread } from '../../../../interfaces/customer-care/ITicket';
import Button from '../../../../shared/button';
import Textarea from '../../../../shared/textarea';
import WhiteContainer from '../../../../shared/white-container';


interface IContactHistory {
    history?: ITicketResponse;
    threadReply?: string;
    loadingThread?: boolean;
    onAddNewThread: () => void;
    onChangeValueReplyThread: (e: any) => void;
}

const ContactHistory = ({
    history,
    threadReply,
    loadingThread,
    onAddNewThread,
    onChangeValueReplyThread
}: IContactHistory) => {
    return (
        <WhiteContainer>
            <div className='p-4'>
                <p className='font-bold text-md'>CS History</p>
                {(history && history?.ticket?.threads && history?.ticket?.threads.length > 0) ?
                    <div className='flex flex-col'>
                        {history?.ticket?.threads?.map((ticket: ITicketThread, idx: number) => {
                            return (
                                <div key={idx} className='my-1'>
                                    <div className={`w-full flex flex-row ${ticket?.type === 'cs' ? 'justify-left' : 'justify-right'}`}>
                                        <p className='text-[#8c8c90] font-bold text-[13px] mb-1'>{ticket?.type === 'cs' ? 'Response' : 'Query'}</p>
                                    </div>
                                    <div className={`${ticket?.type === 'cs' ? 'speech-bubble-admin' : 'speech-bubble-user text-[#fff]'} p-2`}>
                                        {ticket?.content}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    : <div className='my-4'>
                        <p>No History to show</p>
                    </div>
                }
                {(history && history?.ticket?.threads && history?.ticket?.threads.length > 0) &&
                    < div className='mt-4'>
                        <hr className='mb-2' />
                        <Textarea
                            placeholder='Reply'
                            dataQa={'replay'}
                            containerStyle='!mb-0'
                            rows={2}
                            value={threadReply}
                            onChangeTextarea={onChangeValueReplyThread}
                        />
                        <div className='flex flex-row justify-end'>
                            <Button
                                label={'Send'}
                                dataQa={'send-button'}
                                className={`!shadow-none ${loadingThread ? 'btn-main-disable' : 'btn-main'} !mt-0 min-w-[150px]`}
                                onClickButton={() => !loadingThread && onAddNewThread()}
                            />
                        </div>
                    </div>
                }
            </div>
        </WhiteContainer >
    )
}

export default ContactHistory;