import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import {
    tryToAddGeneratedApiKey,
    tryToGenerateApiKey
} from '../../../../store/brokers/admin/partners/partnersSlice';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';

interface IApiGenerateModal {
    openModal: boolean;
    apiKey?: string;
    partnerId: string;
    handleCloseModal: () => void;
}

const ApiGenerateModal = ({
    openModal,
    apiKey,
    partnerId,
    handleCloseModal,
}: IApiGenerateModal) => {
    const dispatch = useAppDispatch()
    const [generatedKey, setGeneratedKey] = useState<any>()
    const [generalError, setGeneralError] = useState<string>()
    const [loadingGenerate, setLoadingGenerate] = useState<boolean>(false)
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<number>(1)

    const onCloseModal = () => {
        handleCloseModal()
        setGeneratedKey(undefined)
        setGeneralError(undefined)
        setLoadingGenerate(false)
        setLoadingSubmit(false)
        setActiveTab(1)
    }

    useEffect(() => {
        if (apiKey) {
            setGeneratedKey(apiKey)
            setActiveTab(2)
        }
    }, [apiKey])

    const onGenerateKey = async () => {
        setLoadingGenerate(true)
        try {
            const response = await dispatch(tryToGenerateApiKey()).unwrap()
            setGeneratedKey(response?.data || undefined)
            setGeneralError(undefined)
            setActiveTab(2)
        } catch (err) {
            setGeneralError(`Something went wrong please try again!`)
        }
        setLoadingGenerate(false)
    }

    const onSubmitKey = async () => {
        if (!generatedKey) {
            setGeneralError('Generated Key Missing')
            return
        }
        setLoadingSubmit(true)
        try {
            const formatData = {
                partnerId: partnerId,
                apiIntegrationKey: generatedKey
            }
            await dispatch(tryToAddGeneratedApiKey(formatData)).unwrap()
            onCloseModal()
        } catch (err) {
            setGeneralError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const onDeleteApiKey = async () => {
        setLoadingSubmit(true)
        try {
            const formatData = {
                partnerId: partnerId,
                apiIntegrationKey: undefined
            }
            await dispatch(tryToAddGeneratedApiKey(formatData)).unwrap()
            onCloseModal()
        } catch (err) {
            setGeneralError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const onClickCopy = async () => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(generatedKey);
        }
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4 font-semibold font-md'>Generate API Key for Broker</p>
                {generalError && <Error text={generalError} />}
                {activeTab === 1 ?
                    <div className='flex flex-row justify-center my-10'>
                        <Button
                            label={!loadingGenerate ? 'Generate Key' : 'Generating...'}
                            dataQa={!loadingGenerate ? 'generate-key' : 'generating...'}
                            onClickButton={() => !loadingGenerate && onGenerateKey()}
                            className={`${(!loadingGenerate) ? 'btn-main' : 'btn-main-disable'} min-w-[200px]`}
                        />
                    </div>
                    : <div className='flex flex-col my-4'>
                        <div className='flex flex-row justify-start items-center'>
                            <Input
                                containerStyle='mb-0 min-w-[350px]'
                                label='Key Generated'
                                dataQa={'key-generated'}
                                placeholder=' '
                                showValue={true}
                                inputValue={generatedKey}
                                disabled={true}
                                inputUniqueName={'key'}
                            
                            />
                            <div data-qa={'generate-api'} onClick={onClickCopy} className='cursor-pointer mt-2'>
                                <img src={'/assets/shared/clipboard.svg'} className='w-[30px] hover:w-[32px] object-contain ml-5' />
                            </div>
                        </div>
                        <div className='flex flex-row justify-center mt-4'>
                            <Button
                                label='Submit'
                                dataQa={'submit'}
                                className={!loadingSubmit ? 'btn-primary min-w-[150px]' : 'btn-primary-disable min-w-[150px]'}
                                onClickButton={() => !loadingSubmit && onSubmitKey()}
                            />
                            {apiKey &&
                                <Button
                                    label='Delete Api Key'
                                    dataQa={'delete-api-key'}
                                    className={!loadingSubmit ? 'btn-error ml-2' : 'btn-primary-disable ml-2'}
                                    onClickButton={() => !loadingSubmit && onDeleteApiKey()}
                                />
                            }
                        </div>
                    </div>
                }
            </div>
        </Modal>
    )
}
export default ApiGenerateModal;