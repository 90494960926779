import React from 'react';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IAssetsFilters {
    items: Array<any>;
    filterValues: any;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onChangeInput: (value: string | null | undefined, type: string) => void;
}

const AssetsFilters = ({
    items,
    filterValues,
    onChangeSelectedValue,
}: IAssetsFilters) => {
    return (
        <>
            {items && items?.length > 0 && items?.filter((item) => item?.type === 'boolean' || item?.type === 'list')?.map((item, index) => {
                let values: any = item?.options;
                if (item?.type === 'boolean') {
                    values = [
                        { value: true, name: 'True' },
                        { value: false, name: 'False' },
                    ];
                } else if (item?.type === 'list') {
                    values =
                        item?.options &&
                        item?.options?.length > 0 &&
                        values?.map((value: string) => {
                            return { value: value, label: value };
                        });
                }
                return (
                    <SelectCheckbox
                        key={index}
                        name={item?.fieldName && `${item?.fieldName?.[0].toUpperCase()}${item?.fieldName?.slice(1).toLowerCase()}`}
                        dataQa={`${filterValues?.[item?.fieldName]}`}
                        placeholder={filterValues?.[item?.fieldName] || ' '}
                        options={values}
                        onChangeSelectedOption={onChangeSelectedValue}
                        uniqueName={item?.fieldName || ''}
                    />
                )
            })}
        </>
    )
}

export default AssetsFilters;