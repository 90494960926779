import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { QUESTIONS_ANSWER_TYPE } from '../../../../utils/constants/question-types';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { IQuestion } from '../../../../interfaces/questions/IQuestions';
import { IPayServices } from '../../../../interfaces/pay-services/IPayServices';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import {
    tryToDeleteQuestion,
    tryToFetchQuestions,
    tryToEnableDisableQuestion,
    tryToEnableDisableReviewQuestion
} from '../../../../store/brokers/shared-endpoints/questions/questionsSlice';
import { tryToFetchAllPayServices } from '../../../../store/brokers/admin/pay-services/payServicesSlice';
import { tryToFetchAllAssetsTemplates } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import MultipleItemDetailsModal from '../modals/MultipleItemDetailsModal';
import QuestionDetailsModal from '../modals/QuestionDetailsModal';
import QuestionModal from '../modals/QuestionModal';
import QuestionsFilters from '../details/QuestionsFilters';
import QuestionsTable from '../tables/QuestionsTable';
import SearchWithButton from '../../../../shared/search-with-button';
import DeleteModal from '../../../../shared/delete-modal';
import Button from '../../../../shared/button';
import Toast from '../../../../shared/toast';


const Questions = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showQuestionModal, setShowQuestionModal] = useState<boolean>(false);
    const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState<boolean>(false);
    const [quest, setQuestionId] = useState<string>();
    const [rows, setRows] = useState<IQuestion[]>([]);
    const [showToast, setShowToast] = useState<any>({});
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>();
    const [sectors, setSectors] = useState<IAssetTemplate[]>();
    const [payServices, setPayServices] = useState<any>();
    const [selectedPayService, setSelectedPayService] = useState<any>();
    const [selectedSector, setSelectedSector] = useState<any>();
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const [markets, setMarkets] = useState<any>();
    const [search, setSearch] = useState<string>();
    const [sortedOrder, setSortedOrder] = useState<string>();
    const [selectedType, setSelectedType] = useState<any>();
    const [showItemDetailsModal, setShowItemDetailsModal] = useState<{ show: boolean, type: string, data: any }>()
    const [showQuestionDetailsModal, setShowQuestionDetailsModal] = useState<{ show: boolean, id: string, serviceId: string }>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state.questions.questions?.data?.page);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state: any) => state.questions);
    const loading = useAppSelector((state) => state?.questions?.questionsAreLoading);
    const [getAllMarkets] = useGetAllMarketsMutation();

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        });
        searchParams.set('page', '1')
        setSearchParams(searchParams)
    };

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Cashout Flow')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const getMarketData = async () => {
        try {
            const marketResponse = await getAllMarkets(null).unwrap()
            const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id, displayName: market?.name }))
            setMarkets(formatMarkets || [])
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        const paginationStateFormat = paginationState
        if (searchParams?.get('page') && Number(searchParams?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(searchParams?.get('page') || 1)
        }
        if (searchParams?.get('size')) {
            paginationStateFormat.pageSize = Number(searchParams.get('size') || 10)
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat)
        }
        if (searchParams.get('search')) {
            setSearch(searchParams.get('search') || undefined)
        }
        if (searchParams.get('answer') && selectedType?.name !== searchParams.get('answer')) {
            const findAnswerType = QUESTIONS_ANSWER_TYPE?.find((item) => item?.name === searchParams.get('answer'))
            setSelectedType(findAnswerType || undefined)
        }
    }, [
        searchParams.get('page'),
        searchParams.get('size'),
        searchParams.get('search'),
        searchParams.get('answer'),
    ])

    useEffect(() => {
        if (payServices) {
            if (searchParams.get('service') && selectedPayService?.name !== searchParams.get('service')) {
                const findService = (payServices && payServices?.length > 0) && payServices?.find((item: any) => item?.name === searchParams.get('service'))
                setSelectedPayService(findService || undefined)
            }
        }
    }, [searchParams.get('service'), payServices])

    useEffect(() => {
        if (markets) {
            if (searchParams.get('market') && selectedMarket?.name !== searchParams.get('market')) {
                const findMarket = (markets && markets?.length > 0) && markets?.find((item: any) => item?.name === searchParams.get('market'))
                setSelectedMarket(findMarket || undefined)
            }
        }
    }, [searchParams.get('market'), markets])

    useEffect(() => {
        if (sectors) {
            if (searchParams.get('category') && selectedSector?.name !== searchParams.get('category')) {
                const findOpenCategory = sectors?.find((item) => item?.name === searchParams.get('category'))
                setSelectedSector(findOpenCategory || undefined)
            }
        }
    }, [searchParams.get('category'), sectors])

    useEffect(() => {
        if (state.questions) {
            const rows =
                state.questions &&
                    Array.isArray(state.questions?.data?.elements)
                    ? state.questions?.data?.elements.map((r: IQuestion) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state?.questions]);

    const getAllQuestionsPaginated = async () => {
        try {
            let formatData = {
                ...paginationState || {},
            }
            if (selectedSector) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        assetTemplateId: selectedSector?.value
                    }
                }
            }
            if (selectedPayService) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        serviceId: selectedPayService?.value
                    }
                }
            }
            if (selectedMarket) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        marketId: selectedMarket?.value
                    }
                }
            }
            if (selectedType) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        type: selectedType?.value
                    }
                }
            }
            if (sortedOrder && selectedPayService) {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        sortOrder: sortedOrder
                    }
                }
            }
            if (search && search !== '') {
                formatData = {
                    ...formatData || {},
                    data: {
                        ...formatData?.data || {},
                        search: search
                    }
                }
            }
            await dispatch(tryToFetchQuestions(formatData)).unwrap()
        } catch (err) {
            // error here
        }
    }

    const getPayServicesData = async () => {
        const payServicesResponse = await dispatch(tryToFetchAllPayServices()).unwrap()
        const formatPayServices = payServicesResponse && payServicesResponse?.data && payServicesResponse?.data?.length > 0 && payServicesResponse?.data?.map((service: IPayServices) => ({ ...service, label: service?.displayName, value: service?._id, name: service?.displayName }))
        setPayServices(formatPayServices || [])
    }

    useEffect(() => {
        dispatch(tryToFetchAllAssetsTemplates());
        getPayServicesData()
        getMarketData()
    }, []);

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    useEffect(() => {
        setQuestionId(undefined);
        if (
            ((searchParams.get('market') && selectedMarket?.name) || !searchParams.get('market'))
            && ((searchParams.get('service') && selectedPayService?.name) || !searchParams.get('service'))
            && ((searchParams.get('answer') && selectedType?.name) || !searchParams.get('answer'))
            && ((searchParams.get('category') && selectedSector?.name) || !searchParams.get('category'))
        ) {
            getAllQuestionsPaginated();
        }
    }, [paginationState]);

    const onViewQuestion = (id: string) => {
        navigate(`/cashout-flow/question/${id}`);
    };

    const onAddNewQuestion = async () => {
        setQuestionId(undefined);
        document.body.style.overflow = 'hidden';
        setShowQuestionModal(true);
    };

    const onEditQuestion = async (id: string) => {
        setShowQuestionModal(true);
        document.body.style.overflow = 'hidden';
        setQuestionId(id);
    };

    const onShowDeleteQuestionModal = (id: string) => {
        setQuestionId(id);
        setShowDeleteQuestionModal(true);
    };

    const onDeleteQuestion = async () => {
        try {
            if (quest) {
                await dispatch(tryToDeleteQuestion(quest)).unwrap();
                setShowToast({
                    type: 'success',
                    message: 'Question successfully deleted',
                });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setQuestionId(undefined);
        setShowDeleteQuestionModal(false);
    };

    const onCloseDeleteQuestionModal = () => {
        setQuestionId(undefined);
        setShowDeleteQuestionModal(false);
    };

    const onCloseQuestionModal = () => {
        setQuestionId(undefined);
        document.body.style.overflow = 'auto';
        setShowQuestionModal(false);
    };

    const onSaveQuestionChanges = (type: string, message: string) => {
        setShowToast({ type, message });
    };

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        if (value) searchParams.set('search', value)
        else searchParams.delete('search')
        onResetPaginationState()
    }

    useEffect(() => {
        if (selectedMarket) {
            onResetPaginationState()
        }
    }, [selectedMarket])

    useEffect(() => {
        if (selectedPayService) {
            onResetPaginationState()
        }
    }, [selectedPayService])

    useEffect(() => {
        if (selectedSector) {
            onResetPaginationState()
        }
    }, [selectedSector])

    useEffect(() => {
        if (selectedType) {
            onResetPaginationState()
        }
    }, [selectedType])

    const onClearFilters = () => {
        setSelectedPayService(undefined);
        setSelectedSector(undefined)
        setSelectedMarket(undefined)
        setSelectedType(undefined)
        setSearch(undefined)
        searchParams.delete('search')
        searchParams.delete('category')
        searchParams.delete('service')
        searchParams.delete('market')
        searchParams.delete('answer')
        searchParams.set('page', '1')
        setSearchParams(searchParams)
        onResetPaginationState()
    };

    const onSelectFilter = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'payService': {
                    searchParams.set('service', value?.name)
                    setSelectedPayService(value)
                    break;
                }
                case 'sector': {
                    searchParams.set('category', value?.name)
                    setSelectedSector(value)
                    break;
                }
                case 'market': {
                    searchParams.set('market', value?.name)
                    setSelectedMarket(value)
                    break;
                }
                case 'answerType': {
                    searchParams.set('answer', value?.name)
                    setSelectedType(value)
                    break;
                }
            }
            setSearchParams(searchParams)
        }
    }

    const onChangeSortOrder = (name?: string) => {
        setSortedOrder((!sortedOrder || sortedOrder === 'desc') ? 'asc' : 'desc')
        onResetPaginationState()
    }

    const onClickSeeMoreDetails = (type: string, data: any) => {
        setShowItemDetailsModal({
            show: true,
            type,
            data
        })
    }

    const onEnableDisableQuestion = async (id: string, currentStatus: boolean) => {
        try {
            await dispatch(tryToEnableDisableQuestion({ id: id, status: !currentStatus })).unwrap();
            if (currentStatus) {
                setShowToast({ type: 'success', message: `Question disabled successfully` });
            } else {
                setShowToast({ type: 'success', message: `Question enabled successfully` });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
    }

    const onEnableDisableReviewQuestion = async (id: string, currentStatus: boolean) => {
        try {
            await dispatch(tryToEnableDisableReviewQuestion({ id: id, status: !currentStatus })).unwrap();
            if (currentStatus) {
                setShowToast({ type: 'success', message: `Question disabled in review successfully` });
            } else {
                setShowToast({ type: 'success', message: `Question enabled in review successfully` });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
    }

    const onEditServiceDetails = (id: string, serviceId: string) => {
        document.body.style.overflow = 'hidden';
        setShowQuestionDetailsModal({
            show: true,
            id: id,
            serviceId: serviceId
        })
    }

    const onSaveQuestionDetailsChanges = () => {
        document.body.style.overflow = 'auto';
        setShowQuestionDetailsModal(undefined);
    };

    return (
        <div>
            <div className='flex flex-row justify-end mb-2'>
                <Button
                    label={'Add New Question'}
                    dataQa={'add-new-question'}
                    className={(!accessControl || pageAccess?.actions?.['Add New Question']) ? 'btn-main' : 'btn-main-disable'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Question']) && onAddNewQuestion()}
                />
            </div>
            <div>
                <QuestionsFilters
                    sectors={sectors}
                    payServices={payServices}
                    markets={markets}
                    selectedMarket={selectedMarket}
                    selectedSector={selectedSector}
                    selectedType={selectedType}
                    selectedPayService={selectedPayService}
                    onSelectFilter={onSelectFilter}
                />
            </div>
            <div>
                <SearchWithButton
                    placeholder='Filter Questions'
                    dataQa={'filter-questions'}
                    search={search}
                    mainContainerStyle={'!grid-cols-10'}
                    onChangeSearchValue={onChangeSearchValue}
                    buttonLabel={'Clear All Filters'}
                    onClickButton={onClearFilters}
                    buttonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end bg-transparent mt-1'}
                />
            </div>
            <div>
                <QuestionsTable
                    rows={rows}
                    loading={loading}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    selectedService={selectedPayService}
                    sortedOrder={sortedOrder}
                    onEnableDisableQuestion={onEnableDisableQuestion}
                    onEnableDisableReviewQuestion={onEnableDisableReviewQuestion}
                    setPaginationState={setPaginationState}
                    onChangeSortOrder={onChangeSortOrder}
                    onViewQuestion={onViewQuestion}
                    onEditQuestion={onEditQuestion}
                    onClickSeeMoreDetails={onClickSeeMoreDetails}
                    onDeleteQuestion={onShowDeleteQuestionModal}
                    onEditServiceDetails={onEditServiceDetails}
                />
            </div>
            <QuestionModal
                openQuestionModal={showQuestionModal}
                handleCloseQuestionModal={onCloseQuestionModal}
                onSaveChanges={onSaveQuestionChanges}
                questionId={quest}
                sectors={sectors}
                markets={markets}
            />
            <DeleteModal
                openDeleteModal={showDeleteQuestionModal}
                handleCloseDeleteModal={onCloseDeleteQuestionModal}
                onApproveDeletion={onDeleteQuestion}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showItemDetailsModal && showItemDetailsModal?.show &&
                <MultipleItemDetailsModal
                    openModal={showItemDetailsModal?.show}
                    type={showItemDetailsModal?.type}
                    data={showItemDetailsModal?.data}
                    handleCloseModal={() => setShowItemDetailsModal(undefined)}
                />
            }
            {showQuestionDetailsModal?.show &&
                <QuestionDetailsModal
                    openQuestionDetailsModal={showQuestionDetailsModal?.show}
                    handleCloseQuestionDetailsModal={onSaveQuestionDetailsChanges}
                    onSaveChanges={onSaveQuestionDetailsChanges}
                    serviceId={showQuestionDetailsModal?.serviceId || ''}
                    questionId={showQuestionDetailsModal?.id || ''}
                    isCopy={false}
                    mainScreenRequest={true}
                />
            }
        </div>
    );
};

export default Questions;
