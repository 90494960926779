import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const AcceptedItemDetails = () => {
    return (
        <WhiteContainer containerStyle='col-span-1 !rounded'>
            <div className='flex flex-col items-center py-4'>
                <img src={'/assets/order-actions/accepted.svg'} className='w-[45%] object-contain' />
                <p className='text-3xl font-bold text-center my-3'>This item was bought successfully.</p>
            </div>
        </WhiteContainer>
    );
};

export default AcceptedItemDetails;
