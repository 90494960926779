import React, { useCallback } from 'react';

const FileItem = ({
	file,
	message,
}: {
	file?: File,
	message: string
}) => {
	const handleDownload = useCallback(() => {
		if (file || message.includes('.csv') || message.includes('.pdf')) {
			let url = '';
			const fileName = file?.name || message;
			if (file) {
				url = URL.createObjectURL(file);
			} else {
				url = message;
			}
			const link = document.createElement('a');
			link.href = url;
			link.download = fileName;
			link.target = '_blank';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);
		}
	}, [file])
	return (
		<button onClick={handleDownload} className='text-[#1389E6] underline bg-transparent outline-none' type='button'>
			{file?.name || message} 
		</button>
	)
}

export default FileItem;