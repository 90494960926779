import React from 'react'
import { IRMA } from '../../../../interfaces/rma/IRMA';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import moment from 'moment';

const tableColumns = ['RMA ID', 'Customer (Broker)', 'Category', 'Created Date (dd/mm/yyy)', 'Total nr. of items', 'Total amount', 'Total Credit', 'Country', 'RMA Status', 'Actions']

interface IRMAsTable {
    rows: IRMA[];
    paginationData?: IPage;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeRMADetails: (id: string) => void;
    sectors: IAssetTemplate[];
    viewDetails: boolean;
}
const RMAsTable = ({
    rows,
    paginationData,
    rowsLoading,
    setPaginationState,
    onSeeRMADetails,
    sectors,
    viewDetails
}: IRMAsTable) => {
    const getCategoryIcon = (category: string) => {
        const res = sectors.find(sector => sector.name === category)
        return res?.disabledIcon
    }
    const capitalizeFirstLetter = (word: string) => {
        return word?.charAt(0).toUpperCase() + word?.slice(1);
    }

    return (
        <Table
            columns={tableColumns}
            containerStyle={'mt-0'}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IRMA, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row._id}</td>
                                <td className='px-6 py-4'>{row.broker}</td>
                                <td className='px-6 py-4 flex'> 
                                    {row.categories?.slice(0,2).map((category: string, index: number) => <img key={index} className='w-6 h-6' src={getCategoryIcon(category)} alt={category} title={category} />)} 
                                    {row?.categories?.length > 2 && (
                                            <span className="ml-1 hover:cursor-pointer relative" title={row?.categories?.slice(2).join(', ')}>
                                                ...
                                            </span>
                                        )}
                                </td>
                                <td className='px-6 py-4'>{moment(row.createdAt).format('DD-MM-YYYY')}</td>
                                <td className='px-6 py-4'>{row.nrItems}</td>
                                <td className='px-6 py-4'>£{row.totalAmount}</td>
                                <td className='px-6 py-4'>{typeof row.totalCreditAmount === 'number' ? `£${row.totalCreditAmount}` : '- -'}</td>
                                <td className='px-6 py-4'>{row?.country || ''}</td>
                                <td className={`px-6 py-4 ${row?.status === 'created' ? 'text-[#F7A21E]' : 'text-[#00B67A]'}`}>{capitalizeFirstLetter(row?.status)}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div onClick={() => (row?._id) && viewDetails && onSeeRMADetails(row?._id)}>
                                            <img title={'View Details'} alt='view details' data-qa='details-rma' src={'/assets/table-actions/view.svg'} className={`h-[22px] object-contain ${viewDetails ? 'cursor-pointer' :'cursor-not-allowed'}`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default RMAsTable