import React from 'react'
import Table from '../../../../shared/table';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import EmptyContainer from '../../../../shared/empty-container';

const tableColumns = ['Category', 'Name', 'Item Status', 'Item ID', 'Item Code', 'Initial Price', 'Final Price', 'Credit Amount']

interface ICreditNoteDetailsTable {
    rows: any[];
    markets: IAllMarkets[]
}
const CreditNoteDetailsTable = ({ rows, markets }: ICreditNoteDetailsTable) => {
    if (!rows || rows.length === 0) return (<EmptyContainer
        text={'No Item found.'}
        showImage={true}
    />)
    return (
        <Table columns={tableColumns}
            hidePagination
            headStyle='!bg-[#202020] !text-[#F8F9FC]'>

            {rows?.map((row: any, idx: number) => {
                const findMarket =
                    markets &&
                    markets?.length > 0 &&
                    markets?.find(
                        (market: any) =>
                            market?.name ===
                            row?.market?.name
                    );
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className="px-6 py-6 flex gap-1 items-center">
                            <img
                                className="w-6 h-6"
                                src={row.enabledIcon}
                                alt={row.category}
                                title={row.category}
                            />
                        </td>
                        <td className='px-6 py-4'>{row.itemName}</td>
                        <td className='px-6 py-4'>{row?.reasonForCredit === 'accepted_return' ? <span
                            className="text-[#00B67A] border-[#00B67A] border py-1 px-3 rounded-[10px] text-nowrap">Accepted Return</span> :
                            <span
                                className="text-[#1389E6] border-[#1389E6] border py-1 px-3 rounded-[10px] text-nowrap">Revalued Item</span>}
                        </td>
                        <td className='px-6 py-4'>{row?._id}</td>
                        <td className='px-6 py-4'>{row?.itemCode || '- -'}</td>
                        <td className='px-6 py-4'>
                            {row?.initialPrice && findMarket &&
                                CURRENCY_SYMBOLS?.[
                                `${findMarket?.currency
                                    ?.name || ''
                                }`
                                ]}
                            {row?.initialPrice || '- -'}
                        </td>
                        <td className='px-6 py-4'>
                            {row?.finalPrice && findMarket &&
                                CURRENCY_SYMBOLS?.[
                                `${findMarket?.currency
                                    ?.name || ''
                                }`
                                ]}
                            {row?.finalPrice || '- -'}
                        </td>
                        <td className='px-6 py-4'>
                            {row?.amount && findMarket &&
                                CURRENCY_SYMBOLS?.[
                                `${findMarket?.currency
                                    ?.name || ''
                                }`
                                ]}
                            {row?.amount || '- -'}
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default CreditNoteDetailsTable