import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToSplitMultipleCashout } from '../../../../store/brokers/partners/multiple-cashout/multipleCashoutSlice';
import SuccessSplitOrder from '../split-details/SuccessSplitOrder';
import ConfirmSplitOrder from '../split-details/ConfirmSplitOrder';
import Modal from '../../../../shared/modal';


interface ISplitOrderModal {
    openModal: boolean;
    orderId: string;
    handleCloseModal: () => void;
}

const SplitOrderModal = ({
    openModal,
    orderId,
    handleCloseModal,
}: ISplitOrderModal) => {
    const dispatch = useAppDispatch();
    const [successfullySplit, setSuccessfullySplit] = useState<boolean>(false)
    const [createError, setCreateError] = useState<string>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const onSplitOrder = async () => {
        if (loadingSubmit) {
            return;
        }
        setLoadingSubmit(true)
        try {
            await dispatch(tryToSplitMultipleCashout(orderId)).unwrap();
            setCreateError(undefined)
            setSuccessfullySplit(true)
        } catch (error) {
            setCreateError(`${error}`)
        }
        setLoadingSubmit(false)
    }

    const onCloseModal = () => {
        handleCloseModal();
        setCreateError(undefined)
        setSuccessfullySplit(false)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle={'min-w-[650px]'}>
            <div className={'p-2 min-w-[600px]'}>
                {successfullySplit ?
                    <SuccessSplitOrder
                        handleCloseModal={onCloseModal} />
                    : <ConfirmSplitOrder
                        createError={createError}
                        loadingSplit={loadingSubmit}
                        handleCloseModal={onCloseModal}
                        onApproveAction={onSplitOrder}
                    />
                }
            </div>
        </Modal>
    )
}

export default SplitOrderModal;