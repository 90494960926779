import React from 'react';
import { TICKETS_STATUS } from '../../../../utils/constants/tickets-status';
import { ITicketFilters } from '../../../../interfaces/components/ITicketFilters';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Datepicker from '../../../../shared/datepicker';
import Input from '../../../../shared/input';


interface IFilters {
    filters?: ITicketFilters;
    errors?: ITicketFilters;
    selectedStatus?: any;
    onChangeFilterValue: (value: string | null | undefined, type: string) => void;
    onChangeStatusSelected: (value: any, type?: string) => void;
}

const TicketFilters = ({
    filters,
    errors,
    selectedStatus,
    onChangeStatusSelected,
    onChangeFilterValue,
}: IFilters) => {
    return (
        <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            <SelectCheckbox
                placeholder='Tickets Status'
                selectedOption={selectedStatus}
                options={TICKETS_STATUS}
                uniqueName={'status'}
                onChangeSelectedOption={onChangeStatusSelected}
            />
            <Input
                placeholder='Order Number'
                containerStyle='mb-0'
                dataQa={'order-number'}
                showValue={true}
                inputValue={filters?.orderId}
                error={errors?.orderId || ''}
                onChangeInput={onChangeFilterValue}
                inputUniqueName={'orderId'}
            />
            <Input
                placeholder='Customer'
                containerStyle='mb-0'
                showValue={true}
                dataQa={'customer'}
                inputValue={filters?.name}
                error={errors?.name || ''}
                onChangeInput={onChangeFilterValue}
                inputUniqueName={'name'}
            />
            <Datepicker
                placeholder='Date Created (min)'
                containerStyle='mb-0'
                dataQa={'date-created-min'}
                inputValue={filters?.minDateCreated}
                error={errors?.minDateCreated || ''}
                onChangeInput={onChangeFilterValue}
                inputUniqueName={'minDateCreated'}
            />
            <Datepicker
                placeholder='Date Created (max)'
                containerStyle='mb-0'
                dataQa={'data-created-max'}
                inputValue={filters?.maxDateCreated}
                error={errors?.maxDateCreated || ''}
                onChangeInput={onChangeFilterValue}
                inputUniqueName={'maxDateCreated'}
            />
        </div>
    )
}

export default TicketFilters;