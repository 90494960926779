import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';


interface IConfirmSubmitModal {
    openModal: boolean;
    loading?: boolean;
    handleCloseModal: () => void;
    onApproveAction: () => void;
}

const ConfirmSubmitModal = ({
    openModal,
    loading,
    handleCloseModal,
    onApproveAction,
}: IConfirmSubmitModal) => {
    return (
        <Modal
            open={openModal}
            showInRight
            title={'Submit Purchase Order'}
            onClose={() => handleCloseModal()}
        >
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Submit Purchase</p>
                </div>
                <div className='p-2 !rounded bg-[#f8f9fc]'>
                    <p className='mt-2 mb-5 text-[17px] font-medium'>Are you sure you want to submit this purchase order?</p>
                    <p className='mb-3 text-[13px]'>This purchase order will be sent to the broker, who will proceed with the remaining processes.</p>
                    <div className='flex flex-row justify-around items-center mt-7 mb-3'>
                        <Button
                            label='No'
                            dataQa={'continue-button'}
                            className={`${loading ? 'btn-main-disable' : 'btn-main !bg-white !border-0'} min-w-[150px]`}
                            onClickButton={() => !loading && handleCloseModal()}
                        />
                        <Button
                            label='Yes'
                            dataQa={'continue-button'}
                            className={`${loading ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} min-w-[150px]`}
                            onClickButton={() => !loading && onApproveAction()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default ConfirmSubmitModal;