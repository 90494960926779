import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddTacList,
    tryToEditExtendedTacList,
    tryToFetchSingleTacList
} from '../../../../store/brokers/admin/tac/tacSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';


interface ITacListExtendedModal {
    openCreateTacListModal: boolean;
    tacListItemId?: string | null;
    handleCloseTacListModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    code?: string;
    // device?: string;
    descriptor?: {
        make: string,
        model: string
    }
};

const TacListExtendedModal = ({
    openCreateTacListModal,
    tacListItemId,
    handleCloseTacListModal,
    onSaveChanges,
}: ITacListExtendedModal) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state?.tacList);
    const tacListLoading = useAppSelector((state) => state.tacList.tacListIsLoading);
    const [generalError, setGeneralError] = useState<string>()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        if (state.tacList) {
            setValue('code', tacListItemId ? state?.tacList?.code || '' : '')
            setValue('descriptor', tacListItemId ? state?.tacList?.descriptor : { make: '', model: '' })
        }
    }, [state.tacList]);

    useEffect(() => {
        if (tacListItemId) {
            dispatch(tryToFetchSingleTacList(tacListItemId));
        }
    }, [tacListItemId])

    const onCloseModal = () => {
        setGeneralError(undefined)
        reset()
        handleCloseTacListModal()
    }

    const onSubmit = async (data: FormValues) => {
        const formatData: any = {
            code: data?.code,
            descriptor: {
                make: data?.descriptor?.make,
                model: data?.descriptor?.model
            },
        }
        try {
            if (tacListItemId) {
                const payload = {
                    id: tacListItemId || '',
                    data: formatData
                }
                await dispatch(tryToEditExtendedTacList(payload)).unwrap();
            }
            else {
                await dispatch(tryToAddTacList(formatData)).unwrap();
            }
            onSaveChanges('success', `TAC successfully ${tacListItemId ? 'edited' : 'added'}`)
            onCloseModal()
        } catch (error) {
            onSaveChanges('error', `${error}`)
        }
        reset();
        handleCloseTacListModal()
    }

    return (
        <Modal
            open={openCreateTacListModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>{tacListItemId ? 'Edit' : 'Add'} TAC Item</p>
                {generalError && <Error text={generalError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    {((tacListItemId && !tacListLoading) || !tacListItemId) ?
                        <div className='flex flex-col my-4'>
                            <Input
                                placeholder='Code'
                                dataQa={'code'}
                                label={tacListItemId ? 'Code' : ''}
                                register={register('code', {
                                    required: {
                                        message: 'Code is required',
                                        value: true,
                                    },
                                })}
                                error={errors.code?.message}
                            />
                            <Input
                                placeholder='Make'
                                dataQa={'make'}
                                label={tacListItemId ? 'Make' : ''}
                                register={register('descriptor.make', {
                                    required: {
                                        message: 'Make is required',
                                        value: true,
                                    },
                                })}
                                error={errors.descriptor?.make?.message}
                            />
                            <Input
                                placeholder='Model'
                                dataQa={'model'}
                                label={tacListItemId ? 'Model' : ''}
                                register={register('descriptor.model', {
                                    required: {
                                        message: 'Model is required',
                                        value: true,
                                    },
                                })}
                                error={errors.descriptor?.model?.message}
                            />
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    dataQa={'submit'}
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                        : <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    }
                </form>
            </div>
        </Modal>
    )
}
export default TacListExtendedModal;