import React, { useEffect, useState } from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';


interface IItemsAllocationTable {
    rows: Array<any>;
    paginationData?: IPage;
    activeMarket?: IAllMarkets & ISelectedValue;
    rowsLoading: boolean;
    state: any;
    accessControl?: any;
    actions?: any;
    openedItems?: Array<string>;
    warehouses?: Array<IWarehouse>;
    selectedBrokerPrice?: Array<{ id: string, data: any }>
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickShowAllPricings: (id: string) => void;
    onSelectBrokerPrice: (id: string, itemId: string, data: any) => void;
}

const ItemsAllocationTable = ({
    rows,
    paginationData,
    activeMarket,
    rowsLoading,
    state,
    warehouses,
    openedItems,
    selectedBrokerPrice,
    onSelectBrokerPrice,
    setPaginationState,
    onClickShowAllPricings
}: IItemsAllocationTable) => {
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Item ID', 'Item Name', 'Sales Grade', 'Tracking Number', 'Purchase Price', 'Gesture Amount', 'Total Purchase Price', 'Allocated', 'Allocated Broker', 'Allocated Broker Price', 'Warehouse', 'Other Pricings'])
    const [tableExtraKeys, setTableExtraKeys] = useState<Array<any>>()

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const formatTableColumnsAndKeys = () => {
        setTableLoading(true)
        const allKeysArray: any = (rows && rows?.length > 0) && rows.flatMap(obj =>
            Object.keys(obj?.asset || {})
        );
        const findKeys = (allKeysArray && allKeysArray?.length > 0) && allKeysArray?.filter((key: any, index: any, array: any) => array?.indexOf(key) === index);
        const formatTableColumns = []
        const formatTableExtraKeys: any = []
        formatTableColumns.push('Item ID')
        formatTableColumns.push('Item Name')
        if ((rows?.[0]?.category || '').toLowerCase() === 'phones' || (rows?.[0]?.category || '').toLowerCase() === 'tablets') {
            formatTableColumns.push('IMEI')
        }
        findKeys && findKeys?.length > 0 && findKeys?.map((key: string) => {
            if (key !== 'assetTemplate') {
                const formatKey = capitalizeFirstLetter(key)
                const formatElement = (formatKey || '').replaceAll('_', ' ')
                formatTableExtraKeys.push(key)
                formatTableColumns.push(formatElement)
            }
            return key
        })
        formatTableColumns.push('Sales Grade')
        formatTableColumns.push('Tracking Number')
        formatTableColumns.push('Purchase Price')
        formatTableColumns.push('Gesture Amount')
        formatTableColumns.push('Total Purchase Price')
        formatTableColumns.push('Allocated')
        formatTableColumns.push('Allocated Broker')
        formatTableColumns.push('Allocated Broker Price')
        formatTableColumns.push('Warehouse')
        formatTableColumns.push('Other Pricing')
        setTableColumns(formatTableColumns || [])
        setTableExtraKeys(formatTableExtraKeys || [])
    }

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [rows, paginationData, state])

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [])

    useEffect(() => {
        if (tableExtraKeys) {
            setTableLoading(false)
        }
    }, [tableExtraKeys])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}>
            {rowsLoading || (tableLoading && !tableColumns) ?
                <>
                    <>{[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                {tableExtraKeys && tableExtraKeys?.length > 0 && tableExtraKeys?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                            </tr>
                        )
                    })}
                    </>
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        const findWarehouse = (row?.warehouse && warehouses && warehouses?.length > 0) && warehouses?.find((item: IWarehouse) => item?._id === row?.warehouse)
                        const findIfOpenedItem = openedItems && openedItems?.length > 0 && openedItems?.find((item: string) => item === row?._id)
                        return (
                            <>
                                <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                    <td className='px-6 py-4'>{row?.itemId}</td>
                                    <td className='px-6 py-4'>{row?.itemName}</td>
                                    {((rows?.[0]?.category || '').toLowerCase() === 'phones' || (rows?.[0]?.category || '').toLowerCase() === 'tablets')
                                        && <td className='px-6 py-4'>{row?.itemCode}</td>
                                    }
                                    {tableExtraKeys && tableExtraKeys?.length > 0 && tableExtraKeys?.map((key: string, index: number) => {
                                        return (
                                            <td
                                                key={index}
                                                className={`px-6 py-4`}>
                                                {row?.asset?.[key]}
                                            </td>
                                        )
                                    })}
                                    <td className='px-6 py-4'>{row?.salesGrade || ''}</td>
                                    <td className='px-6 py-4'>
                                        <a
                                            rel='noreferrer'
                                            href={`https://parcelsapp.com/en/tracking/${row?.trackingNumber}`}
                                            target='_blank'
                                            className='text-primary-default'
                                        >
                                            {row?.trackingNumber}
                                        </a>
                                    </td>
                                    <td className='px-6 py-4 min-w-[120px]'>
                                        <div className='flex flex-row items-center '>
                                            {activeMarket?.currency?.name && CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.name || '')}`]}
                                            {Number(row?.totalPurchasePrice || 0).toFixed(2)}
                                        </div>
                                    </td>
                                    <td className='px-6 py-4 min-w-[120px]'>
                                        <div className='flex flex-row items-center '>
                                            {activeMarket?.currency?.name && CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.name || '')}`]}
                                            {Number(row?.gestureAmount || 0).toFixed(2)}
                                        </div>
                                    </td>
                                    <td className='px-6 py-4 min-w-[120px]'>
                                        <div className='flex flex-row items-center '>
                                            {activeMarket?.currency?.name && CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.name || '')}`]}
                                            {Number((row?.gestureAmount || 0) + (row?.totalPurchasePrice || 0)).toFixed(2)}
                                        </div>
                                    </td>
                                    <td className='px-6 py-4'>{row?.allocated ? 'Yes' : 'No'}</td>
                                    <td className='px-6 py-4'>{row?.allocatedBroker?.companyName || ''}</td>
                                    <td className='px-6 py-4'>
                                        {activeMarket?.currency?.name && CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.name || '')}`]}
                                        {Number(row?.allocatedBroker?.price || 0).toFixed(2)}
                                    </td>
                                    <td className='px-6 py-4'>{findWarehouse?.name || ''}</td>
                                    <td className='px-6 py-4 min-w-[140px]'>
                                        <Button
                                            label={'See All Pricings'}
                                            className={(row?.priceInfo && row?.priceInfo?.length > 0) ? 'btn-main' : 'btn-main-disable'}
                                            onClickButton={() => (row?.priceInfo && row?.priceInfo?.length > 0) && onClickShowAllPricings(row?._id)}
                                        />
                                    </td>
                                </tr>
                                {(findIfOpenedItem && row?.priceInfo && row?.priceInfo?.length > 0) &&
                                    <tr className='bg-[#f8f8f8] shadow my-3'>
                                        <td></td>
                                        <td colSpan={(tableColumns?.length - 1) || 3} className='px-4 py-3 border-r border-slate-100'>
                                            <table className='w-[500px] bg-white shadow-md p-3'>
                                                <thead className='z-50'>
                                                    <tr className='border-b'>
                                                        <th className='max-w-[100px] px-2 py-1'>Select</th>
                                                        <th className='max-w-[100px] px-2 py-1'>Broker</th>
                                                        <th className='max-w-[100px] px-2 py-1'>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row?.priceInfo?.map((item: any, idx: number) => {
                                                        const findIfSelected = (selectedBrokerPrice && selectedBrokerPrice?.length > 0) && selectedBrokerPrice?.find((selected: any) => (selected?.id === item?.agent) && (selected?.itemId === row?.itemId))
                                                        return (
                                                            <tr key={idx} className='border-b'>
                                                                <td className='max-w-[100px] py-2 px-2'>
                                                                    <div className='flex items-center'>
                                                                        <input
                                                                            checked={!!findIfSelected || false}
                                                                            onChange={() => onSelectBrokerPrice(item?.agent || '', row?.itemId, row)}
                                                                            id='checkbox-all'
                                                                            type='checkbox'
                                                                            className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-yellow-300 rounded focus:accent-yellow-300 focus:ring-2'
                                                                        />
                                                                        <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                                                                    </div>
                                                                </td>
                                                                <td className='max-w-[120px] py-2 px-2'>
                                                                    {item?.companyName}
                                                                </td>
                                                                <td className='max-w-[120px] py-2 px-2'>
                                                                    {activeMarket?.currency?.name && CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.name || '')}`]}
                                                                    {Number(item?.quantity || 0).toFixed(2)}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                }
                            </>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default ItemsAllocationTable;