import React from 'react';
import moment from 'moment';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['Item ID', 'Category', 'Item Name', 'Item Code', 'Sales Grade', 'Order Number', 'Tracking Number', 'Market', 'Broker', 'Original Broker', 'Location', 'Purchase Currency', 'Purchase Price', 'Gesture amount', 'Total purchase price', 'Multiple Cashout Boost', 'Conversion Rate', 'Purchase Date', 'GBP Price']

interface IInventoryTable {
    rows: any[];
    rowsLoading: boolean;
    paginationData?: IPage;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const InventoryTable = ({
    rows,
    paginationData,
    rowsLoading,
    setPaginationState,
}: IInventoryTable) => {
    return (
        <Table
            containerStyle={'mt-0'}
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.itemId || ''}</td>
                                <td className='px-6 py-4'>{row?.category || ''}</td>
                                <td className='px-6 py-4 min-w-[170px]'>{row?.itemName || ''}</td>
                                <td className='px-6 py-4'>{row?.itemCode || ''}</td>
                                <td className='px-6 py-4'>{row?.salesGrade || ''}</td>
                                <td className='px-6 py-4'>{row?.orderNumber || ''}</td>
                                <td className='px-6 py-4'>
                                    <a
                                        rel='noreferrer'
                                        href={`https://parcelsapp.com/en/tracking/${row?.trackingNumber}`}
                                        target='_blank'
                                        className='text-primary-default'
                                    >
                                        {row?.trackingNumber}
                                    </a>
                                </td>
                                <td className='px-6 py-4'>{row?.market || ''}</td>
                                <td className='px-6 py-4'>{row?.broker || ''}</td>
                                <td className='px-6 py-4'>{row?.originalBroker || ''}</td>
                                <td className='px-6 py-4'>{row?.stockLocation || ''}</td>
                                <td className='px-6 py-4'>{row?.purchaseCurrency || ''}</td>
                                <td className='px-6 py-4'>{Number(row?.purchasePrice || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.gestureAmount || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{(Number(row?.purchasePrice || 0) + Number(row?.gestureAmount || 0)).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.multipleCashoutPriceBoost || 0).toFixed(2)}</td>
                                {/* <td className='px-6 py-4'>{Number(row?.additionalCost || 0).toFixed(2)}</td> */}
                                <td className='px-6 py-4'>{row?.exchangeValue || ''}</td>
                                <td className='px-6 py-4'>{row?.purchaseDate && moment(row?.purchaseDate).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                <td className='px-6 py-4'>{Number(row?.gbpPrice || 0).toFixed(2)}</td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default InventoryTable;