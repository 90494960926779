import React from 'react';
import { IPageAccess } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import WhiteContainer from '../../../../shared/white-container';
import Button from '../../../../shared/button';


interface IShippedItemDetails {
    pageAccess?: IPageAccess;
    accessControl?: IAccessControl;
    onClickConfirm: () => void;
}

const ShippedItemDetails = ({
    pageAccess,
    accessControl,
    onClickConfirm
}: IShippedItemDetails) => {
    return (
        <WhiteContainer containerStyle='col-span-1 !rounded'>
            <p className='font-semibold text-[17px] text-[#f7a21e]'>Did the item arrive in your warehouse?</p>
            <p className='text-[14px] my-1'>By clicking &apos;Confirm&apos; you confirm the arrival of the described item</p>
            <div className='w-full flex flex-col items-center'>
                <div className='bg-[#fbfbfb] rounded-md p-4 my-3 w-full'>
                    <p className='text-center mb-3'>Select one if the item has arrived</p>
                    <div className={'flex flex-row justify-center'}>
                        <Button
                            label='Confirm'
                            className='btn-main mr-4 min-w-[100px]'
                            onClickButton={() => (!accessControl || (pageAccess?.actions?.['Accept Package'])) && onClickConfirm()}
                        />
                    </div>
                </div>
            </div>
        </WhiteContainer>
    );
};

export default ShippedItemDetails;
