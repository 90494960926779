import React, { useState } from 'react';
import { IPageAccess } from '../../../../interfaces/components/IPageAccess';
import { ISingleOrderSchema } from '../../../../interfaces/orders/IOrdersSchema';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import ReturnTrackingNumberModal from '../modals/ReturnTrackingNumberModal';
import SellerAddressDetails from '../details/SellerAddressDetails';
import WhiteContainer from '../../../../shared/white-container';
import Button from '../../../../shared/button';


interface IRequiredReturnDetails {
    orderDetails?: ISingleOrderSchema;
    packageId: string;
    pageAccess?: IPageAccess;
    accessControl?: IAccessControl;
}

const RequiredReturnDetails = ({
    orderDetails,
    packageId,
    pageAccess,
    accessControl,
}: IRequiredReturnDetails) => {
    const [showReturnModal, setShowReturnModal] = useState<boolean>(false)

    const onToggleReturnModal = () => {
        if (showReturnModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowReturnModal(!showReturnModal)
    }

    return (
        <WhiteContainer containerStyle='!bg-[#202020] !rounded'>
            <p className='font-semibold text-[20px] text-white mb-3'>Return Details</p>
            <SellerAddressDetails
                orderDetails={orderDetails}
            />
            <div className='flex flex-row justify-end'>
                <Button
                    label='Input Return Tracking Number'
                    className={(!accessControl || (pageAccess?.actions?.['Add Return Tracking Number'])) ? 'btn-primary' : 'btn-primary-disable'}
                    onClickButton={() => (!accessControl || (pageAccess?.actions?.['Add Return Tracking Number'])) && onToggleReturnModal()}
                />
            </div>
            {showReturnModal &&
                <ReturnTrackingNumberModal
                    openModal={showReturnModal}
                    orderId={packageId}
                    orderDetails={orderDetails}
                    assetTemplate={orderDetails?.item?.catalog?.code}
                    itemId={orderDetails?.item?.id || ''}
                    handleCloseModal={onToggleReturnModal}
                />
            }
        </WhiteContainer>
    );
};

export default RequiredReturnDetails;
