import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IComparisonSitesListState } from '../../../../interfaces/comparison-sites/IComparisonSitesList';
import { PaginatedComparisonSites } from '../../../../interfaces/comparison-sites/paginatedStore.type';
import { ComparisonSitesService } from './comparisonSitesApi';

const initialState: IComparisonSitesListState = {
    comparisonSiteIsLoading: false,
    comparisonSitesAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchComparisonSites = createAsyncThunk<
    PaginatedComparisonSites,
    IPaginationPayload
>('comparisonSites/tryToFetchComparisonSites', async ({ pageNumber, pageSize, data }) => {
    const result = await ComparisonSitesService.tryToFetchComparisonSites(
        pageNumber,
        pageSize,
        data,
    );
    return result?.data;
});

export const tryToFetchSingleComparisonSite = createAsyncThunk<
    PaginatedComparisonSites,
    any
>('comparisonSites/tryToFetchSingleComparisonSite', async ({ pageNumber, pageSize, name }) => {
    const result = await ComparisonSitesService.tryToFetchSingleComparisonSite(
        pageNumber,
        pageSize,
        name,
    );
    return result?.data;
});


export const comparisonSitesSlice = createSlice({
    name: 'comparisonSites',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchComparisonSites
            .addCase(tryToFetchComparisonSites.pending, (state) => {
                state.comparisonSitesAreLoading = true;
            })
            .addCase(tryToFetchComparisonSites.rejected, (state) => {
                state.comparisonSitesAreLoading = false;
            })
            .addCase(tryToFetchComparisonSites.fulfilled, (state, action) => {
                state.comparisonSitesAreLoading = false;
                state.comparisonSites = action.payload;
            })

            // tryToFetchSingleComparisonSite
            .addCase(tryToFetchSingleComparisonSite.pending, (state) => {
                state.comparisonSiteIsLoading = true;
            })
            .addCase(tryToFetchSingleComparisonSite.rejected, (state) => {
                state.comparisonSiteIsLoading = false;
            })
            .addCase(tryToFetchSingleComparisonSite.fulfilled, (state, action) => {
                state.comparisonSiteIsLoading = false;
                state.comparisonSite = action.payload;
            });
    },
});

export default comparisonSitesSlice.reducer;
