import React from 'react';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface ICategoriesModal {
    openModal: boolean;
    selectedCategory?: any;
    categories?: Array<any>;
    handleCloseModal: () => void;
    onClickCategory: (item: any) => void;
}

const CategoriesModal = ({
    openModal,
    categories,
    selectedCategory,
    onClickCategory,
    handleCloseModal,
}: ICategoriesModal) => {

    const onCloseModal = () => {
        handleCloseModal();
    }

    return (
        <Modal
            open={openModal}
            showInRight={true}
            title='Category of Purchase Order'
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[600px]'}>
                <div className='border-b-2 border-[#e4e4e4] mt-8 mb-4'>
                    <p className='mb-4 font-semibold text-md'>Pick Category</p>
                </div>
                <div className='my-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                    {categories && categories?.length > 0 && categories?.map((item: any, index: number) => {
                        const findIfSelected = selectedCategory && selectedCategory?.length>0 && selectedCategory?.find((category: any)=> category?.value === item?.value)
                        let buttonClassName = 'btn-noactive-categories border'
                        if (findIfSelected?.value === item?.value) buttonClassName = 'btn-categories-list !rounded-[6px]';
                        else buttonClassName = 'btn-noactive-categories-list border !border-[#a0a0a0] !text-[#a0a0a0] !rounded-[6px]';
                        return (
                            <Button
                                key={index}
                                label={item?.label}
                                className={`${buttonClassName} !min-w-[70px] text-sm`}
                                onClickButton={() => onClickCategory(item)}
                                dataQa={`${item?.label && item?.label?.toLowerCase()?.replaceAll(' ', '-')}`}
                                icon={item.enabledIcon ? <div className={'w-[23px] h-[23px]'}><img src={findIfSelected?.value === item?.value ? item.enabledIcon : item.disabledIcon} className={'max-w-[100%] max-h-[100%] min-w-[100%] min-h-[100%] object-contain'} /></div> : ''}
                            />
                        )
                    })}
                </div>
                <div className='my-4 flex flex-row justify-center'>
                    <Button
                        label='Confirm'
                        className='btn-primary min-w-[250px] mt-10'
                        onClickButton={onCloseModal}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default CategoriesModal;