import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { ICategoryMappings } from '../../../../interfaces/category-mappings/ICategoryMappings';
import {
    tryFetchAssetFieldValues,
    tryFetchAssetFieldValuesDependent
} from '../../../../store/brokers/admin/assets/assetsSlice';
import {
    tryToCreateOrUpdateCategoryMapping
} from '../../../../store/brokers/shared-endpoints/category-mappings/categoryMappingsSlice';
import Button from '../../../../shared/button';
import MappingNotListFileds from '../details/MappingNotListFields';
import MappingListFields from '../details/MappingListFields';


interface IMappingValuesStep {
    draftMapping?: ICategoryMappings;
    activeStep: number;
    setActiveStep: any;
    categoryFields?: any;
    setMappingDraftDetails: any;
    setGeneralError: any;
}

const MappingValuesStep = ({
    draftMapping,
    activeStep,
    setActiveStep,
    categoryFields,
    setMappingDraftDetails,
    setGeneralError
}: IMappingValuesStep) => {
    const navigation = useNavigate()
    const dispatch = useAppDispatch();
    const [mappingDetails, setMappingDetails] = useState<ICategoryMappings>()
    const [twigCategoryValues, setTwigCategoryValues] = useState<any>()
    const [mappedValues, setMappedValues] = useState<any>()
    const [activeField, setActiveField] = useState<any>()
    const [twigCategoryField, setTwigCategoryField] = useState<any>()
    const [totalSteps, setTotalSteps] = useState<number>()
    const [mappedCurrentType, setMappedCurrentType] = useState<any>()
    const [filteredFields, setFilteredFields] = useState<any>()
    const [filteredMultiFields, setFilteredMultiFields] = useState<any>()
    const [searchField, setSearchField] = useState<any>()
    const [searchFieldMulti, setSearchFieldMulti] = useState<any>()
    const [loadingOptions, setLoadingOptions] = useState<any>()
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)

    const onGettingFieldDetails = () => {
        setMappingDetails(draftMapping)
        const filterOnlyNormalColumns: any = draftMapping?.columns && draftMapping?.columns?.length > 0 && draftMapping?.columns?.filter((item: any) => (!item?.mappedAsConditions && item?.isMapped === true && !(item?.mappedAs || []).includes('number_of_items')))
        const findActiveField: any = filterOnlyNormalColumns && filterOnlyNormalColumns?.length > 0 && filterOnlyNormalColumns?.[activeStep - 2]
        setActiveField(findActiveField)
        setTotalSteps(((filterOnlyNormalColumns?.length || 0) + 1))
        const findTwigFieldData = categoryFields && findActiveField && findActiveField?.mappedAs && categoryFields?.length > 0 && categoryFields?.filter((item: any) => (findActiveField?.mappedAs?.includes(item?.name) || (mappingDetails?.pricingType === 'WHOLESALE_PRICING' && findActiveField?.mappedAs?.includes(item?.replaceField?.name))))
        setTwigCategoryField(findActiveField?.mappedAs?.length > 1 ? findTwigFieldData : findTwigFieldData?.[0] || undefined)
        setMappedValues(findActiveField?.mappedValues || undefined)
    }

    const onGettingFieldValues = async () => {
        let data: any = {
            id: mappingDetails?.catalog?.id,
            field: mappingDetails?.pricingType === 'WHOLESALE_PRICING' ? twigCategoryField?.name || activeField?.mappedAs?.[0] : activeField?.mappedAs?.[0] || '',
        }
        if (searchField && searchField?.value) {
            data = {
                ...data || {},
                search: searchField?.value,
            }
        }
        if (mappingDetails?.pricingType && mappingDetails?.pricingType === 'WHOLESALE_PRICING' && twigCategoryField?.replaceField?.name) {
            data = {
                ...data || {},
                replacements: true
            }
        }
        const response = await dispatch(tryFetchAssetFieldValues(data)).unwrap()
        if (searchField && searchField?.index) {
            const findFilterFields = (filteredFields && filteredFields?.length > 0) && filteredFields?.find((item: any) => item?.index === searchField?.index)
            if (!findFilterFields) {
                setFilteredFields([
                    ...filteredFields || [],
                    {
                        index: searchField?.index,
                        values: response
                    }
                ])
            } else {
                const filterFilterFields = filteredFields && filteredFields?.length > 0 && filteredFields?.filter((item: any) => item?.index !== searchField?.index)
                setFilteredFields([
                    ...filterFilterFields || [],
                    {
                        index: searchField?.index,
                        values: response
                    }
                ])
            }
        }
        else {
            setTwigCategoryValues(response || '')
        }
    }

    const onGettingMultipleFieldValues = async () => {
        let data: any = {
            id: mappingDetails?.catalog?.id,
            data: {
                includeFields: twigCategoryField && twigCategoryField?.length > 0 ? twigCategoryField?.map((item: any) => item?.name) || [] : []
            }
        }
        if (searchFieldMulti && searchFieldMulti?.search) {
            data = {
                ...data || {},
                data: {
                    ...data?.data || {},
                    search: searchFieldMulti?.search,
                }
            }
        }
        const findAlreadyMappedValues = mappedCurrentType?.type && mappedValues && mappedValues?.[mappedCurrentType?.type]
        if (findAlreadyMappedValues) {
            const searchFieldMultiKey = searchFieldMulti?.search && Object.keys(searchFieldMulti?.search)?.[0]
            findAlreadyMappedValues && findAlreadyMappedValues?.values && searchFieldMulti?.search && searchFieldMultiKey && findAlreadyMappedValues?.[searchFieldMultiKey] && delete findAlreadyMappedValues?.[searchFieldMultiKey]
            data = {
                ...data || {},
                data: {
                    ...data?.data || {},
                    values: [findAlreadyMappedValues] || []
                }
            }
        }
        if (mappingDetails?.pricingType && mappingDetails?.pricingType === 'WHOLESALE_PRICING') {
            data = {
                ...data || {},
                replacements: true
            }
        }
        const response = await dispatch(tryFetchAssetFieldValuesDependent(data)).unwrap()
        if (searchFieldMulti && (searchFieldMulti?.index || searchFieldMulti?.index === 0)) {
            const findAlreadyFilledValues = ((searchFieldMulti?.index || searchFieldMulti?.index === 0) && filteredMultiFields && filteredMultiFields?.length > 0) && filteredMultiFields?.find((item: any) => item?.index === searchFieldMulti?.index)
            if (!findAlreadyFilledValues) {
                setFilteredMultiFields([
                    ...filteredMultiFields || [],
                    {
                        index: searchFieldMulti?.index,
                        values: response
                    }
                ])
            } else {
                const filterFilterFields = filteredMultiFields && filteredMultiFields?.length > 0 && filteredMultiFields?.filter((item: any) => item?.index !== searchFieldMulti?.index)
                setFilteredMultiFields([
                    ...filterFilterFields || [],
                    {
                        index: searchFieldMulti?.index,
                        values: response
                    }
                ])
            }
        } else if (mappedCurrentType?.type) {
            const filterFilterFields = filteredMultiFields && filteredMultiFields?.length > 0 && filteredMultiFields?.filter((item: any) => item?.index !== mappedCurrentType?.index)
            setFilteredMultiFields([
                ...filterFilterFields || [],
                {
                    index: mappedCurrentType?.index,
                    values: response
                }
            ])
        } else {
            setTwigCategoryValues(response || '')
        }
        setLoadingOptions(undefined)
    }

    useEffect(() => {
        if (activeField) {
            if (activeField?.mappedAs?.length > 1) {
                onGettingMultipleFieldValues()
            } else {
                onGettingFieldValues()
            }
        }
    }, [activeField])

    useEffect(() => {
        if (activeStep && draftMapping) {
            onGettingFieldDetails()
        }
    }, [activeStep, draftMapping])

    useEffect(() => {
        if (searchField) {
            onGettingFieldValues()
        }
    }, [searchField])

    useEffect(() => {
        if (searchFieldMulti && searchFieldMulti?.search && activeField?.mappedAs && activeField?.mappedAs?.length > 1) {
            onGettingMultipleFieldValues()
        }
    }, [searchFieldMulti])

    useEffect(() => {
        if (mappedCurrentType && mappedCurrentType?.type && activeField?.mappedAs && activeField?.mappedAs?.length > 1) {
            onGettingMultipleFieldValues()
        }
    }, [mappedCurrentType, mappedValues])

    const onChangeSearchFields = (e: any, type?: string, index?: any) => {
        if (type) {
            const findIfFiltered = filteredFields && filteredFields?.length > 0 && filteredFields?.find((item: any) => item?.index === index)
            const formatFilteredFields = findIfFiltered ? findIfFiltered?.values && findIfFiltered?.values?.length > 0 && findIfFiltered?.values?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
                : twigCategoryValues && twigCategoryValues?.length > 0 && twigCategoryValues?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
            if (formatFilteredFields && formatFilteredFields?.length > 0) {
                const filterFilterFields = filteredFields && filteredFields?.length > 0 && filteredFields?.filter((item: any) => item?.index !== index)
                setFilteredFields([
                    ...filterFilterFields || [],
                    {
                        index: index,
                        values: formatFilteredFields
                    }
                ])
            }
            else {
                setFilteredFields(undefined)
                setLoadingOptions({ [type]: true, index })
                setSearchField({
                    value: e,
                    index,
                    name: type
                })
            }
        } else {
            setFilteredFields(undefined)
        }
    }

    const onChangeSearchFieldMultiple = (e: any, type?: string, index?: any) => {
        const findCSVField = activeField?.inputValues && activeField?.inputValues?.length > 0 && activeField?.inputValues?.find((item: any, idx: number) => idx === index)
        if (type && (index || index === 0)) {
            const findIfFiltered = filteredMultiFields && filteredMultiFields?.length > 0 && filteredMultiFields?.find((item: any) => item?.index === index)
            const findIfAnyMappedValues = mappedValues && findCSVField && mappedValues?.[findCSVField]
            const formatFilteredFields = (findIfFiltered || findIfAnyMappedValues) ? (findIfFiltered?.values && findIfFiltered?.values?.[type] && findIfFiltered?.values?.[type]?.length > 0) && findIfFiltered?.values?.[type]?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
                : twigCategoryValues?.[type] && twigCategoryValues?.[type]?.length > 0 && twigCategoryValues?.[type]?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
            if (formatFilteredFields && formatFilteredFields?.length > 0 && e) {
                const filterFilterFields = filteredMultiFields && filteredMultiFields?.length > 0 && filteredMultiFields?.filter((item: any) => item?.index !== index)
                setFilteredMultiFields([
                    ...filterFilterFields || [],
                    {
                        index: index,
                        values: {
                            ...findIfFiltered?.values || {},
                            [type]: formatFilteredFields || []
                        }
                    }
                ])
            }
            else {
                setFilteredMultiFields(undefined)
                setLoadingOptions({ [findCSVField]: true, index })
                setSearchFieldMulti({
                    index,
                    search: {
                        [type]: e
                    }
                })
            }
        } else {
            setFilteredMultiFields(undefined)
        }
    }

    const onMenuCloseAfterSearch = (type?: string, index?: any) => {
        if (type) {
            setSearchField(undefined)
            const filterFilterFields = filteredFields && filteredFields?.length > 0 && filteredFields?.filter((item: any) => item?.index !== index)
            setFilteredFields(filterFilterFields || [])
        }
    }

    const onMenuCloseAfterSearchMulti = (type?: string, index?: any) => {
        if (type) {
            setSearchFieldMulti(undefined)
            // const filterFilterFields = filteredMultiFields && filteredMultiFields?.length > 0 && filteredMultiFields?.filter((item: any) => item?.index !== index)
            // setFilteredMultiFields(filterFilterFields || [])
        }
    }

    const onSubmit = async (step?: string, type?: string) => {
        try {
            setSubmitLoading(true)
            const formatColumns = mappingDetails?.columns && mappingDetails?.columns?.length > 0 && mappingDetails?.columns?.map((item: any) => {
                if (item?.name === activeField?.name) {
                    return {
                        ...item || {},
                        mappedValues: type === 'without-map' ? undefined : mappedValues
                    }
                } else {
                    return item
                }
            })
            let mappingDetailsFormat: any = {
                ...mappingDetails || {},
                columns: formatColumns
            }
            if (type === 'finish' || (type === 'without-map' && activeStep === totalSteps)) {
                mappingDetailsFormat = {
                    ...mappingDetailsFormat || {},
                    status: 'COMPLETED'
                }
            }
            const response = await dispatch(tryToCreateOrUpdateCategoryMapping(mappingDetailsFormat)).unwrap()
            setMappingDraftDetails(response?.data || mappingDetailsFormat)
            if (mappingDetailsFormat?.status === 'COMPLETED') {
                navigation('/category-mapping')
            }
            if (type === 'without-map') {
                setActiveStep(activeStep + 1)
            }
            else {
                setActiveStep(step === 'next' ? activeStep + 1 : activeStep - 1)
            }
        } catch (err) {
            setGeneralError(`${err || 'Something went wrong!'}`)
        }
        setSubmitLoading(false)
    }

    const onChangeStep = (type: string) => {
        if (type === 'back') {
            setActiveStep(activeStep > 2 ? activeStep - 1 : 1)
            return
        }
        if (type === 'without-map') {
            onSubmit('next', type)
            return
        }
        onSubmit(type)
    }

    const onAddNewMappedValue = (value: any, type?: string, old?: string, index?: any) => {
        if (activeField?.mappedAs && activeField?.mappedAs?.length > 1) {
            if (type && old) {
                const findIfMappedValue = type && mappedValues && mappedValues?.[type]
                setMappedValues({
                    ...mappedValues || {},
                    [type]: {
                        ...findIfMappedValue || {},
                        ...value || {}
                    }
                })
                setMappedCurrentType({ type: type, index })
                setLoadingOptions({ [type]: true, index })
            }
        } else if (type === 'changed' && old) {
            const cloneMappedValues = mappedValues && JSON.parse(JSON.stringify(mappedValues))
            cloneMappedValues && cloneMappedValues[old] && delete cloneMappedValues[old]
            setMappedValues({
                ...cloneMappedValues || {},
                ...value || {}
            })
        } else {
            setMappedValues({
                ...mappedValues || {},
                ...value || {}
            })
        }
    }

    const onDeleteMappedValues = (type: string, value?: any) => {
        if (type === 'all') {
            setMappedValues(undefined)
        } else if (value) {
            if (mappedValues) {
                const cloneMappedValues = mappedValues && JSON.parse(JSON.stringify(mappedValues))
                cloneMappedValues && cloneMappedValues[value] && delete cloneMappedValues[value]
                setMappedValues(cloneMappedValues || undefined)
            }
        }
    }

    return (
        <div className='my-10'>
            {activeField &&
                <div className='flex flex-row items-center'>
                    <p className='mr-3 font-md text-[22px]'>{`${(activeField?.name)[0].toUpperCase()}${(activeField?.name).slice(1).toLowerCase()}`}</p>
                    <img src={'/assets/shared/right-arrow.svg'} className={'w-[20px] object-contain mr-3'} />
                    <p className='font-md text-[22px] text-primary-light'>
                        {activeField?.mappedAs && activeField?.mappedAs?.length > 0 && activeField?.mappedAs?.map((item: string, id: number) => {
                            return id > 0 ? `, ${item[0].toUpperCase()}${item.slice(1).toLowerCase()}` : `${item[0].toUpperCase()}${item.slice(1).toLowerCase()}` || ''
                        })}
                    </p>
                </div>
            }
            {(twigCategoryField && twigCategoryField?.dataType && !(twigCategoryField?.dataType === 'number' || twigCategoryField?.dataType === 'string') || activeField?.mappedAs?.[0] === 'colour')
                || (activeField?.mappedAs?.length > 1) ?
                <div>
                    <MappingListFields
                        fileValues={activeField?.inputValues}
                        mappedValues={mappedValues}
                        categoryValues={twigCategoryValues}
                        activeField={activeField}
                        filteredFields={filteredFields}
                        filteredMultiFields={filteredMultiFields}
                        twigCategoryField={twigCategoryField}
                        loadingOptions={loadingOptions}
                        mappingDetails={mappingDetails}
                        onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                        onMenuCloseAfterSearchMulti={onMenuCloseAfterSearchMulti}
                        onChangeSearchFieldValues={onChangeSearchFields}
                        onDeleteMappedValues={onDeleteMappedValues}
                        onChangeSearchFieldMultiple={onChangeSearchFieldMultiple}
                        onContinueAsSameValue={() => activeStep === totalSteps ? onSubmit('next', 'without-map') : onChangeStep('without-map')}
                        onAddNewMappedValue={onAddNewMappedValue}
                    />
                </div>
                : <div>
                    <MappingNotListFileds
                        fileValues={activeField?.inputValues}
                        mappedValues={mappedValues}
                        categoryValues={twigCategoryValues}
                        activeField={activeField}
                        filteredFields={filteredFields}
                        onAddNewMappedValue={onAddNewMappedValue}
                        onDeleteMappedValues={onDeleteMappedValues}
                        onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                        onChangeSearchFieldValues={onChangeSearchFields}
                        onContinueAsSameValue={() => activeStep === totalSteps ? onSubmit('next', 'without-map') : onChangeStep('without-map')}
                    />
                </div>
            }
            <div className={`mt-8 flex flex-row justify-between mt-5`}>
                <Button
                    label={'Back'}
                    dataQa={'back-button'}
                    className={'btn-main !shadow-none min-w-[200px]'}
                    onClickButton={() => onChangeStep('back')}
                />
                <Button
                    label={totalSteps === activeStep ? 'Submit' : 'Next'}
                    dataQa={'submit-button'}
                    className={`${submitLoading ? 'btn-main-disable' : 'btn-main'} !shadow-none min-w-[200px]`}
                    onClickButton={() => totalSteps === activeStep ? onSubmit('next', 'finish') : onChangeStep('next')}
                />
            </div>
        </div>
    )
}

export default MappingValuesStep;