export const QUESTION_TYPES = [
    { name: 'Single Choice', label: 'Single Choice', value: 'SINGLE_CHOICE', showAnswers: true },
    { name: 'Multiple Choice', label: 'Multiple Choice', value: 'MULTIPLE_CHOICE', showAnswers: true },
    { name: 'Number Input', label: 'Number Input', value: 'NUMBER_INPUT', showAnswers: false },
    { name: 'Text Input', label: 'Text Input', value: 'TEXT_INPUT', showAnswers: false },
]

export const QUESTIONS_ANSWER_TYPE = [
    { name: 'PRIMARY', label: 'Primary', value: 'PRIMARY' },
    { name: 'SECONDARY', label: 'Secondary', value: 'SECONDARY' },
]

export const DEPENDENCIES_TYPE = [
    { label: 'Match All', value: 'match_all' },
    { label: 'Match At Least One', value: 'match_one' },
]