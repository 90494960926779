import { AxiosResponse } from 'axios';
import { OFFER_API_URL } from '../../../../config';
import offersAxios from '../../../../utils/axios/offers.axios';
import { PaginatedPricingEffects } from '../../../../interfaces/pricing-effects/paginatedStore.type';
import { IPricingEffects } from '../../../../interfaces/pricing-effects/IPricingEffects';

const tryToFetchPricingEffects = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedPricingEffects>> => {
    const url = `${OFFER_API_URL}/pricing-effects/all?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedPricingEffects>(url, data);
};

const tryToFetchSinglePricingEffect = (id: string): Promise<AxiosResponse> => {
    return offersAxios.get(`${OFFER_API_URL}/pricing-effects/${id}`);
};

const tryToDownloadPricingEffects = (): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/pricing-effects/download`);
};

const tryToAddPricingEffect = (data: IPricingEffects): Promise<AxiosResponse> => {
    return offersAxios.post(`${OFFER_API_URL}/pricing-effects`, data);
};

const tryToDeletePricingEffect = (id: string): Promise<AxiosResponse> => {
    return offersAxios.delete(`${OFFER_API_URL}/pricing-effects/${id}`);
};

const tryToEditPricingEffect = (id: string, payload: IPricingEffects): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/pricing-effects/${id}/edit`, payload);
};

export const PricingEffectsService = {
    tryToFetchPricingEffects,
    tryToFetchSinglePricingEffect,
    tryToDownloadPricingEffects,
    tryToAddPricingEffect,
    tryToDeletePricingEffect,
    tryToEditPricingEffect
};
