import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToDeleteInvoiceTemplate,
    tryToFetchInvoiceTemplates
} from '../../../store/brokers/admin/invoice-templates/invoiceTemplateSlice';
import { tryToFetchAssetCurrencies } from '../../../store/brokers/admin/assets/assetsSlice';
import InvoiceTemplatesTable from './tables/InvoiceTemplatesTable';
import ManageTemplatesModal from './modals/ManageTemplatesModal';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import SearchWithButton from '../../../shared/search-with-button';


const InvoiceTemplates = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any[]>([]);
    const [currencies, setCurrencies] = useState<Array<any>>();
    const [showToast, setShowToast] = useState<IToast | null>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [search, setSearch] = useState<string>()
    const [pageAccess, setPageAccess] = useState<any>()
    const [showDeleteItem, setShowDeleteItem] = useState<{ show: boolean, id: string }>()
    const [showManageItemModal, setShowManageItemModal] = useState<{ show: boolean, id?: string }>()
    const stateTemplates = useAppSelector((state) => state.invoiceTemplates)
    const paginationData = useAppSelector((state) => state.invoiceTemplates.invoiceTemplates?.data?.page);
    const loading = useAppSelector((state) => state.invoiceTemplates.templatesAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const stateCurrencies: any = useAppSelector((state) => state.assets.currencies);

    useEffect(() => {
        if (stateCurrencies) {
            const formatCurrencies = stateCurrencies && stateCurrencies?.length > 0 && stateCurrencies?.map((currency: any) => {
                return {
                    ...currency,
                    label: currency?.descriptor?.name,
                    name: currency?.descriptor?.name,
                    value: currency?._id
                }
            })
            setCurrencies(formatCurrencies || undefined)
        }
    }, [stateCurrencies]);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Invoices')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Invoice Templates')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (stateTemplates?.invoiceTemplates) {
            const rows =
                stateTemplates?.invoiceTemplates?.data?.elements && Array.isArray(stateTemplates?.invoiceTemplates?.data.elements)
                    ? stateTemplates?.invoiceTemplates?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [stateTemplates?.invoiceTemplates]);

    const getInvoiceTemplateData = async () => {
        let data = {
            ...paginationState || {},
        }
        if (search) {
            data = {
                ...data || {},
                filters: search
            }
        }
        await dispatch(tryToFetchInvoiceTemplates(data)).unwrap()
    }

    useEffect(() => {
        getInvoiceTemplateData()
    }, [paginationState]);

    useEffect(() => {
        dispatch(tryToFetchAssetCurrencies());
    }, []);

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const onGoBack = () => {
        navigate('/admin-invoices')
    }

    const onDeleteRequest = (id: string) => {
        setShowDeleteItem({ show: true, id })
    }

    const onDeleteConfirm = async () => {
        if (showDeleteItem?.id) {
            try {
                await dispatch(tryToDeleteInvoiceTemplate(showDeleteItem?.id)).unwrap()
                setShowToast({ type: 'success', message: 'Successfully deleted invoice template!' })

            } catch (err) {
                setShowToast({ type: 'error', message: `${err}` })
            }
            setShowDeleteItem(undefined)
        }
    }

    const onSaveChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        setShowManageItemModal(undefined)
    }

    const onAddEditRequest = (id?: string) => {
        setShowManageItemModal({ show: true, id: id })
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Invoice Templates</p>
                </div>
            </div>
            <div className='my-5 flex flex-row justify-end'>
                <Button
                    label={'Add New Template'}
                    dataQa={'add-new-template'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Template']) && onAddEditRequest()}
                    className={(!accessControl || pageAccess?.actions?.['Add New Template']) ? 'btn-main' : 'btn-main-disable'}
                />
            </div>
            <div>
                <SearchWithButton
                    placeholder='Filter templates'
                    dataQa={'filter-templates'}
                    search={search}
                    mainContainerStyle={'!grid-cols-10'}
                    onChangeSearchValue={onChangeSearchValue}
                />
                <InvoiceTemplatesTable
                    rows={rows}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    loading={loading}
                    setPaginationState={setPaginationState}
                    onDeleteTemplate={onDeleteRequest}
                    onEditTemplate={onAddEditRequest}
                />
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showDeleteItem?.show &&
                <DeleteModal
                    onApproveDeletion={onDeleteConfirm}
                    handleCloseDeleteModal={() => setShowDeleteItem(undefined)}
                    openDeleteModal={showDeleteItem?.show}
                />
            }
            {showManageItemModal?.show &&
                <ManageTemplatesModal
                    openModal={showManageItemModal?.show}
                    currencies={currencies}
                    id={showManageItemModal?.id}
                    onSaveChanges={onSaveChanges}
                    handleCloseModal={() => setShowManageItemModal(undefined)}
                />
            }
        </div>
    );
};

export default InvoiceTemplates;