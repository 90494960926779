import React, { useEffect, useState } from 'react';
import Button from '../../../../shared/button';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';


interface IMappingNotListFileds {
    mappedValues?: any;
    fileValues?: any;
    categoryValues?: any;
    activeField?: any;
    filteredFields?: any;
    loadingOptions?: any;
    onContinueAsSameValue: () => void;
    onAddNewMappedValue: (value: any, type?: string, old?: string) => void;
    onDeleteMappedValues: (type: string, value?: any) => void;
    onChangeSearchFieldValues: (e: any, type?: string, index?: any) => void;
    onMenuCloseAfterSearch: (type?: string, index?: any) => void;
}

const MappingNotListFileds = ({
    mappedValues,
    fileValues,
    categoryValues,
    activeField,
    filteredFields,
    loadingOptions,
    onContinueAsSameValue,
    onAddNewMappedValue,
    onDeleteMappedValues,
    onChangeSearchFieldValues,
    onMenuCloseAfterSearch
}: IMappingNotListFileds) => {
    const [totalRows, setTotalRows] = useState<number>(1)
    const [selectedValues, setSelectedValues] = useState<any>()
    const [fileFieldsValues, setFileFieldsValues] = useState<any>()
    const [activeFieldTemp, setActiveFieldTemp] = useState<any>()

    useEffect(() => {
        if (mappedValues) {
            const findMappedNames = mappedValues && Object.keys(mappedValues)
            const findSelectedValues = findMappedNames && findMappedNames?.length > 0 && findMappedNames?.map((item: any) => {
                return {
                    newField: item || undefined,
                    field: mappedValues?.[item] || undefined
                }
            })
            if (findSelectedValues) {
                setSelectedValues(findSelectedValues)
            }
            const findTotalRows = findMappedNames?.length || 1
            if (totalRows < findTotalRows || (totalRows - 1 > mappedValues?.length)) {
                setTotalRows(findTotalRows || 1)
            }
        } else {
            setTotalRows(1)
            setSelectedValues(undefined)
        }
    }, [mappedValues, activeField])

    const onChangeSelectedFieldValue = (e: any, type?: string, index?: any) => {
        if (type) {
            const findIfRowExist = selectedValues?.[index]
            if (findIfRowExist) {
                let isCompleted = false
                const formatSelectedValues = selectedValues && selectedValues?.length > 0 && selectedValues?.map((item: any, idx: number) => {
                    if (idx === index) {
                        isCompleted = (type === 'field' && findIfRowExist?.newField) || (type === 'newField' && findIfRowExist?.field)
                        return {
                            ...item || {},
                            [type]: e?.label,
                        }
                    } else {
                        return item
                    }
                })
                setSelectedValues(formatSelectedValues)
                if (isCompleted) {
                    if (findIfRowExist.newField) {
                        onAddNewMappedValue(type === 'newField' ? { [`${e?.label}`]: findIfRowExist?.field } : { [`${findIfRowExist.newField}`]: e?.label }, 'changed', findIfRowExist.newField)
                    } else {
                        onAddNewMappedValue(type === 'newField' ? { [`${e?.label}`]: findIfRowExist?.field } : { [`${findIfRowExist.newField}`]: e?.label })
                    }
                }
            } else {
                setSelectedValues([
                    ...selectedValues || [],
                    {
                        [type]: e?.label,
                    }
                ])
            }
        }
    }

    const onAddNewMappingRow = () => {
        setTotalRows(totalRows + 1)
    }

    const onDeleteRows = (type: string, index?: number) => {
        setTotalRows(type === 'all' ? 1 : totalRows - 1)
        if (type === 'one' && (index || index === 0)) {
            const findSelectedValues = selectedValues && selectedValues?.[index]
            if (findSelectedValues) {
                const filterValues = selectedValues && selectedValues?.length > 0 && selectedValues?.filter((item: any, idx: number) => idx !== index)
                setSelectedValues(filterValues)
            }
            findSelectedValues?.newField && onDeleteMappedValues(type, findSelectedValues?.newField)
        } else {
            setSelectedValues(undefined)
            onDeleteMappedValues(type)
        }
    }

    useEffect(() => {
        if (activeFieldTemp && !mappedValues) {
            setFileFieldsValues(undefined)
            setSelectedValues(undefined)
            setTotalRows(1)
        }
    }, [activeFieldTemp])

    useEffect(() => {
        if (activeField?.name !== activeFieldTemp) {
            setActiveFieldTemp(activeField)
        }
    }, [activeField])

    const onChangeSearchUnique = (e: any, type?: string, index?: any) => {
        const formatFilteredFields = fileValues && fileValues?.length > 0 && fileValues?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
        const filterFilterFields = fileFieldsValues && fileFieldsValues?.length > 0 && fileFieldsValues?.filter((item: any) => item?.index !== index)
        setFileFieldsValues([
            ...filterFilterFields || [],
            {
                index: index,
                values: formatFilteredFields
            }
        ])
    }

    const onMenuCloseAfterSearchFile = (type?: any, index?: any) => {
        const filterFilterFields = fileFieldsValues && fileFieldsValues?.length > 0 && fileFieldsValues?.filter((item: any) => item?.index !== index)
        setFileFieldsValues(filterFilterFields || [])
    }

    return (
        <div className='w-full grid grid-cols-3 gap-4 my-8'>
            <div className='col-span-2'>
                {[...Array(totalRows || 1)].map((x, i) => {
                    const findSelected = selectedValues?.[i]
                    const areOptionsLoading = loadingOptions?.index === i
                    const findValues = filteredFields && filteredFields?.length > 0 && filteredFields?.find((obj: any) => obj?.index === i)
                    const values = findValues ?
                        findValues?.values &&
                        findValues?.values?.length > 0 &&
                        findValues?.values?.map((value: string) => {
                            return { value: value, label: value };
                        })
                        :
                        categoryValues &&
                        categoryValues?.length > 0 &&
                        categoryValues?.map((value: string) => {
                            return { value: value, label: value };
                        });
                    const findFileValues = fileFieldsValues && fileFieldsValues?.length > 0 && fileFieldsValues?.find((obj: any) => obj?.index === i)
                    const fileValuesData = findFileValues ?
                        findFileValues?.values &&
                        findFileValues?.values?.length > 0 &&
                        findFileValues?.values?.map((value: string) => {
                            return { value: value, label: value };
                        })
                        : fileValues &&
                        fileValues?.length > 0 &&
                        fileValues?.map((value: string) => {
                            return { value: value, label: value };
                        });
                    return (
                        <div key={i} className='flex flex-row items-center'>
                            <AsyncSelectCheckbox
                                name={`Select ${activeField?.name} value`}
                                isLoading={false}
                                dataQa={activeField?.name === undefined ? 'select-value' : activeField?.name}
                                placeholder={findSelected?.newField || ''}
                                selectedOption={findSelected?.newField || undefined}
                                options={fileValuesData || []}
                                uniqueName={'newField'}
                                // loadingOptions={areOptionsLoading && false}
                                onChangeValue={onChangeSearchUnique}
                                onMenuClose={onMenuCloseAfterSearchFile}
                                dynamicOptions={true}
                                index={i}
                                onChangeSelectedOption={onChangeSelectedFieldValue}
                            />
                            <img src={'/assets/shared/right-arrow-gray.svg'} className={'w-[15px] object-contain mx-4 mt-1'} />
                            <AsyncSelectCheckbox
                                name={`Select ${activeField?.mappedAs?.[0]} value`}
                                isLoading={false}
                                dataQa={'select-value'}
                                placeholder={findSelected?.field || ''}
                                selectedOption={findSelected?.field || undefined}
                                options={values || []}
                                uniqueName={'field'}
                                loadingOptions={areOptionsLoading && false}
                                onChangeValue={onChangeSearchFieldValues}
                                onMenuClose={onMenuCloseAfterSearch}
                                dynamicOptions={true}
                                index={i}
                                onChangeSelectedOption={onChangeSelectedFieldValue}
                            />
                            <div
                                onClick={() => onDeleteRows('one', i)}
                                data-qa={'delete-rows'}
                                className='ml-4 mt-2 cursor-pointer'>
                                <img src={'/assets/shared/trash-gray.svg'} className={'w-[16px] hover:w-[17px] object-contain'} />
                            </div>
                        </div>
                    )
                })
                }
            </div>
            <div className='mt-3'>
                <Button
                    label={'Add New Row'}
                    dataQa={'add-new-row'}
                    className={'btn-main mb-4 min-w-[80%]'}
                    onClickButton={onAddNewMappingRow}
                />
                <Button
                    label={'Reset Mapped Values'}
                    dataQa={'reset-mapped-values'}
                    className={'btn-main mb-4 min-w-[80%]'}
                    onClickButton={() => onDeleteRows('all')}
                />
                <Button
                    label={'Same Values As Wing Field'}
                    dataQa={'same-values-as-wing'}
                    className={'btn-main mb-4 min-w-[80%]'}
                    onClickButton={onContinueAsSameValue}
                />
            </div>
        </div>
    )
}

export default MappingNotListFileds;