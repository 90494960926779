import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import { PaginatedRetailers } from '../../../../interfaces/retailers/paginatedStore.type';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';

const tryToFetchRetailers = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedRetailers>> => {
    const url = `${USERS_API_URL}/retailers/paginated?page=${pageNumber}&size=${pageSize}`;
    return brokersAxios.put<PaginatedRetailers>(url, { filters: data })
}

const tryToCreateRetailers = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/retailers`, data);
};

const tryToEditRetailers = (id: string, data: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/retailers/${id}`, data);
};

const tryToFetchSingleRetailers = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/retailers/${id}`)
};

const tryToEnableDisableRetailers = (id: string, status: boolean): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/retailers/${id}/${status}`);
};

const tryToDeleteRetailers = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/retailers/${id}`);
};

export const RetailersService = {
    tryToFetchRetailers,
    tryToCreateRetailers,
    tryToEditRetailers,
    tryToFetchSingleRetailers,
    tryToEnableDisableRetailers,
    tryToDeleteRetailers
}