import React from 'react';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';

interface ILineChart {
    xAxisData: Array<string | number>;
    chartData: Array<number>;
}

const LineChart = ({xAxisData, chartData}:ILineChart) => {
    const getOptionBar = () => ({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: xAxisData,
                splitLine: {
                    lineStyle: {
                        type: 'dashed',
                        dashOffset: 5
                    }
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                axisTick: {
                    show: false,
                    lineStyle: {
                        color: 'transparent'
                    }
                },

                minorTick: {
                    show: false
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    lineStyle: {
                        type: 'dashed',
                        dashOffset: 1,
                        width: 1.4
                    }
                },
                axisTick: {
                    show: false,
                    lineStyle: {
                        color: 'transparent'
                    }
                },
                minorTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
            }
        ],
        series: [
            {
                name: 'Line 1',
                type: 'line',
                stack: 'Total',
                smooth: true,
                lineStyle: {
                    width: 2,
                    color: '#F7931E',
                },

                showSymbol: false,
                areaStyle: {
                    opacity: 1,
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                        {
                            offset: 0,
                            color: '#F7931E'
                        },
                        {
                            offset: 0.4,
                            color: '#FCEE21'
                        },
                        {
                            offset: 1,
                            color: '#FCEE21'
                        }
                    ])
                },
                emphasis: {
                    focus: 'series'
                },
                itemStyle: {
                    borderWidth: 4,
                    borderColor: '#FCEE21',
                    // color: '#F7931E'
                },
                symbolSize: 7,
                data: chartData
            }
        ]
    })
    return (
        <div>
            <ReactEcharts option={getOptionBar()} style={{ height: 400, width: '100%' }} />
        </div>
    )
}
export default LineChart;