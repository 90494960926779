import React from 'react';


const AutomatedConvertLoadingContainer = () => {
    return (
        <div className='my-5'>
            <div className='mb-6'>
                <p className='font-semibold text-md mb-4'>Please select the base currency</p>
                <div className='h-[40px] animate-pulse bg-gray-200 rounded dark:bg-gray-200 w-[90%] mb-1'></div>
            </div>
            <div className='grid grid-cols-3 mb-6 items-center gap-4 px-5'>
                <p>Currency</p>
                <p className='text-center mr-10'>Automated</p>
            </div>
            {[...Array(4)].map((x, i) => {
                return (
                    <div key={i} className='px-6 shadow-md bg-white mb-2 text-left items-center h-20 select-none flex justify-between flex-row'>
                        <div className='grid grid-cols-2 gap-4 min-w-[85%] max-w-sm '>
                            <div className='flex flex-row item-center animate-pulse'>
                                <div className='h-[25px] bg-gray-200 rounded-full dark:bg-gray-200 w-[25px]  mr-2'></div>
                                <div className='h-4 bg-gray-200 rounded dark:bg-gray-200 w-[60px] mt-1'></div>
                            </div>
                            <div className='animate-pulse'>
                                <div className='h-[25px] bg-gray-200 rounded-full dark:bg-gray-200 w-[50px] mb-1'></div>
                            </div>
                        </div>
                        <div>
                            <img src={'/assets/shared/arrow-down-gray.svg'} className={'w-[20px] object-contain'} />
                        </div>
                    </div>
                )
            })
            }
        </div>
    )
}

export default AutomatedConvertLoadingContainer;