import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import { IWarehouseLocation } from '../../../../interfaces/warehouses/IWarehouseLocation';
import { IPaginationPayloadWithId } from '../../../../interfaces/shared/IPaginationPayload';
import {
    tryToDeleteWarehouseLocation,
    tryToFetchSingleWarehouse,
    tryToFetchWarehouseLocations
} from '../../../../store/brokers/admin/warehouses/warehousesSlice';
import WarehouseLocationModal from '../modals/WarehouseLocationModal';
import WarehouseValuesTable from '../tables/WarehouseValuesTable';
import EmptyContainer from '../../../../shared/empty-container';
import DeleteModal from '../../../../shared/delete-modal';
import Loading from '../../../../shared/Loading';
import Button from '../../../../shared/button';
import Toast from '../../../../shared/toast';


interface IWarehouseValues {
    warehouseData?: IWarehouse
}

const WarehouseValues = ({
    warehouseData
}: IWarehouseValues) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IWarehouseLocation[]>([]);
    const [paginationState, setPaginationState] = useState<IPaginationPayloadWithId>({
        pageNumber: 1,
        pageSize: 10,
        id: id || ''
    });
    const [showToast, setShowToast] = useState<IToast | null>()
    const [showValuesModal, setShowValuesModal] = useState<boolean>(false)
    const [locationSelected, setLocationSelected] = useState<string>()
    const [showDeleteItemModal, setShowDeleteItemModal] = useState<boolean>(false)
    const paginationData = useAppSelector((state) => state.warehouses?.warehouseLocations?.data?.page);
    const warehouseIsLoading = useAppSelector((state) => state.warehouses?.warehouseIsLoading)
    const stateWarehouseFields = useAppSelector((state) => state.warehouses?.warehouse);
    const state = useAppSelector((state) => state?.warehouses)

    useEffect(() => {
        if (state.warehouseLocations) {
            const rows =
                state.warehouseLocations?.data?.elements && Array.isArray(state.warehouseLocations?.data.elements)
                    ? state.warehouseLocations?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.warehouseLocations]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchWarehouseLocations(paginationState))
            dispatch(tryToFetchSingleWarehouse(id));
        }
    }, []);

    useEffect(() => {
        if (id) {
            const formatPagination = {
                ...paginationState || {},
                id: id || ''
            }
            dispatch(tryToFetchWarehouseLocations(formatPagination))
        }
    }, [paginationState]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchWarehouseLocations(paginationState))
            dispatch(tryToFetchSingleWarehouse(id));
        }
    }, [id]);

    const onToggleLocationModal = (id?: string) => {
        if (id) {
            setLocationSelected(id)
        } else {
            setLocationSelected(undefined)
        }
        setShowValuesModal(!showValuesModal)
    }

    const onRequestToDeleteItem = (id: string) => {
        setShowDeleteItemModal(true)
        setLocationSelected(id)
    }

    const onCloseDeleteItemModal = () => {
        setLocationSelected(undefined)
        setShowDeleteItemModal(false)
    }

    const onDeleteWarehouseItem = async () => {
        try {
            if (locationSelected) {
                const data = {
                    warehouseId: id,
                    locationId: locationSelected
                }
                await dispatch(tryToDeleteWarehouseLocation(data)).unwrap();
                setShowToast({ type: 'error', message: 'Location deleted successfully' });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        onCloseDeleteItemModal()
    }

    return (
        <div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New Location'}
                    dataQa={'add-new-location'}
                    className={'btn-main'}
                    onClickButton={onToggleLocationModal}
                />
            </div>
            <div className='my-5 relative'>
                {warehouseIsLoading ?
                    <div className='my-3 flex flex-row justify-center items-center'>
                        <Loading />
                    </div>
                    :
                    <>
                        {(warehouseData?.locations && warehouseData?.locations?.length > 0) ?
                            <WarehouseValuesTable
                                rows={rows || []}
                                warehouseFields={stateWarehouseFields?.locations || []}
                                paginationData={paginationData}
                                setPaginationState={setPaginationState}
                                onEditItem={onToggleLocationModal}
                                onDeleteRequest={onRequestToDeleteItem}
                            />
                            : <EmptyContainer text={'No warehouse location added yet'} showImage={true} />}
                    </>
                }
            </div>
            <DeleteModal
                openDeleteModal={showDeleteItemModal}
                handleCloseDeleteModal={onCloseDeleteItemModal}
                onApproveDeletion={onDeleteWarehouseItem}
            />
            {showValuesModal &&
                <WarehouseLocationModal
                    openModal={showValuesModal}
                    handleCloseModal={onToggleLocationModal}
                    locationId={locationSelected}
                    id={id || ''}
                    warehouseFieldsData={warehouseData?.locations || []}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div >
    )
}

export default WarehouseValues;