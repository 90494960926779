import React, { useEffect, useState } from 'react';
import {
    useNavigate,
    useLocation,
    useSearchParams
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { tryToFetchShippedOrders } from '../../../store/brokers/partners/orders/ordersSlice';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import { tryToFindOperatingMarkets } from '../../../store/brokers/admin/partners/partnersSlice';
import OrderCategories from './details/OrderCategories';
import OrdersTable from './tables/OrdersTable';
import Input from '../../../shared/input';


const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ShippedOrders = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const query = useQuery();
    const [rows, setRows] = useState<Array<any>>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: undefined
    });
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>();
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>();
    const [pageAccess, setPageAccess] = useState<any>();
    const state = useAppSelector((state) => state.orders);
    const ordersLoading = useAppSelector((state) => state?.orders?.ordersAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const paginationData = useAppSelector((state) => state?.orders?.shippedOrders?.data?.page);
    const [userProfileMutation] = useUserProfileMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Wing Packages')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined
        })
        searchParams.set('page', '1')
        searchParams.set('size', `${paginationState?.pageSize || 10}`)
        setSearchParams(searchParams)
    }

    const getBrokerMarketsAndSectors = async () => {
        const response = await userProfileMutation(null).unwrap()
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : []
        const filterSectors: any = formatSectors && formatSectors?.length > 0 && formatSectors?.filter((s: any) => (s?.label || '').toLowerCase() !== 'mens wear' && (s?.label || '').toLowerCase() !== 'womens wear')
        setBrokerCategories(filterSectors || [])
        setSelectedCategory(query?.get('category') || filterSectors?.[0]?.value)
        onResetPaginationSettings()
        // searchParams.set('category', query?.get('category') || formatSectors?.[0]?.value)
        // setSearchParams(searchParams)
    }

    useEffect(() => {
        getBrokerMarketsAndSectors()
        dispatch(tryToFindOperatingMarkets(null))
    }, [])

    useEffect(() => {
        if (activeMarketState && ((JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket)))) {
            setActiveMarket(activeMarketState || undefined)
            onResetPaginationSettings()
        }
    }, [activeMarketState])

    // useEffect(() => {
    //     setOrdersPerCategory(ordersCount || undefined)
    // }, [ordersCount])

    useEffect(() => {
        const paginationStateFormat = paginationState
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1)
        }
        if (query?.get('size') || query?.get('search')) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10)
            paginationStateFormat.filters = query.get('search') || undefined
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat)
        }
        if (query?.get('category') && query?.get('category') !== selectedCategory) {
            setSelectedCategory(query?.get('category') || undefined)
        }
    }, [
        query?.get('page'),
        query?.get('size'),
        query?.get('search'),
        query?.get('category')
    ])

    const getOrdersData = async () => {
        if (!selectedCategory) {
            return;
        }
        const data: any = {}
        if (activeMarket) {
            data.country = activeMarket?.market?.name
        }
        if (selectedCategory) {
            const findCategory = brokerCategories && brokerCategories?.length > 0 && brokerCategories?.find((category) => category?.value === selectedCategory)
            data.assetTemplateId = findCategory?.id
        }
        const resolvedData = { ...paginationState, data }
        searchParams.set('page', `${paginationState?.pageNumber}`)
        searchParams.set('size', `${paginationState?.pageSize}`)
        setSearchParams(searchParams)
        dispatch(tryToFetchShippedOrders(resolvedData))
    }

    useEffect(() => {
        if (state.shippedOrders) {
            const rowsData =
                state.shippedOrders?.data?.elements && Array.isArray(state.shippedOrders?.data.elements)
                    ? state.shippedOrders?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            if (rowsData !== rows) {
                setRows(rowsData);
            }
        }
    }, [state.shippedOrders]);

    useEffect(() => {
        if (activeMarket && paginationState) {
            getOrdersData()
        }
    }, [activeMarket, paginationState])

    const onSeeOrderDetails = (orderId: string) => {
        navigate(`/wing-packages/${orderId}`)
    }

    const onChangeCategorySelected = (value: string) => {
        setSelectedCategory(value)
        searchParams.set('category', value)
        setSearchParams(searchParams)
        onResetPaginationSettings()
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
            filters: value || undefined
        })
        if (value) searchParams.set('search', value)
        else searchParams.delete('search')
        searchParams.set('page', '1')
        setSearchParams(searchParams)
    }

    return (
        <div>
            <div className='mb-4'>
                <p className='page-title'>Wing Packages</p>
            </div>
            <div className='my-10'>
                <OrderCategories
                    items={brokerCategories || []}
                    activeButton={selectedCategory}
                    onClickCategory={onChangeCategorySelected}
                />
            </div>
            <div>
                <div className={`bg-white`}>
                    <Input
                        placeholder={'Filter table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={paginationState?.filters}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                        dataQa={'filter-table'}
                    />
                </div>
                {(ordersLoading || rows?.length > 0)
                    ? <OrdersTable
                        rows={rows}
                        accessControl={accessControl}
                        pageAccess={pageAccess?.actions}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        onSeeOrderDetails={onSeeOrderDetails}
                        activeMarket={activeMarket}
                        ordersLoading={ordersLoading}
                    />
                    : <div className='my-10 min-w-[100%] flex flex-col items-center'>
                        <img src={'/assets/shared/no-content.svg'} className={'w-[150px] mb-10 mt-4 object-contain'} />
                        <p className='text-[#a4a4a4] text-center font-md'>
                            Wing Packages not found
                            {activeMarket?.market?.label ? ` market ${activeMarket?.market?.label}${selectedCategory ? ' and ' : ''}` : ''}
                            {selectedCategory ? `category ${selectedCategory}` : ''}.</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default ShippedOrders;