import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import {
    tryToFetchRentalAssetFieldValuesDependent,
    tryToUploadRentalPricingCSV
} from '../../../../store/brokers/admin/rental-pricing/rentalPricingSlice';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import NewRentalPricingFields from '../details/NewRentalPricingFields';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Button from '../../../../shared/button';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface INewRentalPricingItemModal {
    openModal: boolean;
    markets?: Array<any>;
    sectors?: Array<any>;
    handleCloseModal: () => void;
    onSaveSuccessfully: (type: string, message: string) => void;
}

const NewRentalPricingItemModal = ({
    openModal,
    markets,
    sectors,
    handleCloseModal,
    onSaveSuccessfully,
}: INewRentalPricingItemModal) => {
    const dispatch = useAppDispatch();
    const [generalError, setGeneralError] = useState<string>()
    const [activeStep, setActiveStep] = useState<number>(1)
    const [loadingFields, setLoadingFields] = useState<any>()
    const [loadingOptions, setLoadingOptions] = useState<any>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [filteredFields, setFilteredFields] = useState<any>()
    const [fieldDetails, setFieldDetails] = useState<any>()
    const [searchField, setSearchField] = useState<any>()
    const [formData, setFormData] = useState<any>([{}])
    const [assetTemplateSelected, setAssetTemplateSelected] = useState<any>()
    const [marketSelected, setMarketSelected] = useState<any>()
    const [currentRow, setCurrentRow] = useState<number>()
    const [totalRows, setTotalRows] = useState<number>(1)

    const onCloseModal = () => {
        handleCloseModal();
        setGeneralError(undefined)
        setAssetTemplateSelected(undefined)
        setMarketSelected(undefined)
        setFormData(undefined)
        setFieldDetails(undefined)
        setSearchField(undefined)
        setLoadingFields(undefined)
        setLoadingOptions(undefined)
        setLoadingSubmit(false)
    }

    const onGettingAllAssetFields = async () => {
        const assetSelected = assetTemplateSelected?.value
        const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(assetSelected)).unwrap()
        if (response?.fields) {
            setFieldDetails(response?.fields)
        }
    }

    const onGettingFieldValues = async () => {
        if (assetTemplateSelected?.value) {
            let data: any = {
                values: [],
                includeFields: fieldDetails?.length > 0 && fieldDetails?.map((item: any, idx: number) => item?.name) || []
            }
            const findAlreadyMappedValues = (formData && (currentRow || currentRow === 0) && formData?.[currentRow]) && Object.assign(formData?.[currentRow])
            if (findAlreadyMappedValues) {
                const searchFieldMultiKey = searchField?.search && Object.keys(searchField?.search)?.[0]
                findAlreadyMappedValues && searchField?.search && searchFieldMultiKey && findAlreadyMappedValues?.[searchFieldMultiKey] && delete findAlreadyMappedValues?.[searchFieldMultiKey]
                data = {
                    ...data || {},
                    values: [findAlreadyMappedValues] || []
                }
            }
            if (searchField && searchField?.index) {
                data = {
                    ...data || {},
                    search: searchField?.search,
                }
            }
            const response = await dispatch(tryToFetchRentalAssetFieldValuesDependent({ id: assetTemplateSelected?.value, data, replacements: true })).unwrap()
            if (searchField && (searchField?.index || searchField?.index === 0)) {
                const findAlreadyFilledValues = ((searchField?.index || searchField?.index === 0) && filteredFields && filteredFields?.length > 0) && filteredFields?.find((item: any) => item?.index === searchField?.index)
                if (!findAlreadyFilledValues) {
                    setFilteredFields([
                        ...filteredFields || [],
                        {
                            index: searchField?.index,
                            values: response
                        }
                    ])
                } else {
                    const filterFilterFields = filteredFields && filteredFields?.length > 0 && filteredFields?.filter((item: any) => item?.index !== searchField?.index)
                    setFilteredFields([
                        ...filterFilterFields || [],
                        {
                            index: searchField?.index,
                            values: response
                        }
                    ])
                }
            }
            else if (currentRow || currentRow === 0) {
                const findAlreadyFilledValues = ((currentRow || currentRow === 0) && filteredFields && filteredFields?.length > 0) && filteredFields?.find((item: any) => item?.index === currentRow)
                if (!findAlreadyFilledValues) {
                    setFilteredFields([
                        ...filteredFields || [],
                        {
                            index: currentRow,
                            values: response
                        }
                    ])
                } else {
                    const filterFilterFields = filteredFields && filteredFields?.length > 0 && filteredFields?.filter((item: any) => item?.index !== currentRow)
                    setFilteredFields([
                        ...filterFilterFields || [],
                        {
                            index: currentRow,
                            values: response
                        }
                    ])
                }
            } else {
                setFilteredFields([
                    {
                        index: 0,
                        values: response
                    }
                ])
            }
            setLoadingFields(undefined)
            setLoadingOptions(undefined)
        }
    }

    useEffect(() => {
        if (assetTemplateSelected?.value) {
            onGettingAllAssetFields()
        }
    }, [assetTemplateSelected])

    useEffect(() => {
        if (fieldDetails) {
            onGettingFieldValues()
        }
    }, [fieldDetails])

    useEffect(() => {
        onGettingFieldValues()
    }, [searchField, formData])

    const onChangeActiveStep = (type?: string) => {
        if (type !== 'back' && activeStep === 1) {
            if (!assetTemplateSelected || !marketSelected) {
                setGeneralError('Please select all fields to continue!')
                return;
            }
        }
        setGeneralError(undefined)
        setActiveStep(type === 'back' ? activeStep - 1 : activeStep + 1)
    }

    const onChangeSelectedFields = (e: any, name?: string) => {
        if (name) {
            switch (name) {
                case 'market':
                    setMarketSelected(e)
                    break;
                case 'category':
                    setAssetTemplateSelected(e)
                    break;
            }
        }
    }

    const onChangeSearchAssetFields = (e: any, type?: string, index?: any) => {
        if (type) {
            const findIfFiltered = filteredFields && filteredFields?.length > 0 && filteredFields?.find((item: any) => item?.index === index)
            const formatFilteredFields = findIfFiltered && findIfFiltered?.values && findIfFiltered?.values?.[type] && findIfFiltered?.values?.[type]?.length > 0 && findIfFiltered?.values?.[type]?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
            if (formatFilteredFields && formatFilteredFields?.length > 0) {
                const filterFilterFields = filteredFields && filteredFields?.length > 0 && filteredFields?.filter((item: any) => item?.index !== index)
                setFilteredFields([
                    ...filterFilterFields || [],
                    {
                        index: index,
                        values: {
                            ...findIfFiltered?.values || {},
                            [type]: formatFilteredFields || []
                        }
                    }
                ])
            }
            else {
                setFilteredFields(undefined)
                setLoadingOptions({ [type]: true, index })
                setSearchField({
                    index,
                    search: {
                        [type]: e
                    }
                })
            }
        } else {
            setFilteredFields(undefined)
        }
    }

    const onMenuCloseAfterSearch = (type?: string) => {
        if (type && searchField) {
            setSearchField(undefined)
        }
    }

    const onDeleteItemRow = (type: string, value?: any) => {
        setTotalRows(totalRows - 1 === 0 ? 1 : totalRows - 1)
        if (value || value === 0) {
            const filterFormData = formData && formData?.length > 0 && formData?.filter((item: any, idx: number) => idx !== value)
            setFormData(filterFormData)
            const formatFilteredValues = filteredFields && filteredFields?.length > 0 && filteredFields?.filter((item: any, idx: number) => item?.index !== value)
            const reFormatFilteredValues = formatFilteredValues && formatFilteredValues?.length > 0 && formatFilteredValues?.map((item: any, idx: number) => ({ ...item, index: idx }))
            setFilteredFields(reFormatFilteredValues)
        }
    }

    const onAddNewRow = () => {
        setTotalRows(totalRows + 1)
        setCurrentRow(totalRows)
        setFormData([
            ...formData || [],
            {}
        ])
        setSearchField(undefined)
    }

    const onSelectFieldValue = (value: any, type?: string, index?: any) => {
        setLoadingFields({ index })
        if (type) {
            const formatSavedValues = (formData && formData?.length > 0) ?
                formData?.map((item: any, idx: number) => {
                    if (idx === index) {
                        return {
                            ...item || {},
                            [type]: value?.value
                        }
                    } else {
                        return item
                    }
                })
                : [Array(index)].map((item: any, idx: number) => {
                    if (idx === index) {
                        return {
                            [type]: value?.value
                        }
                    } else {
                        return {}
                    }
                })
            setFormData(formatSavedValues);
            if (searchField) {
                setSearchField(undefined)
            }
            setCurrentRow(index)
            setLoadingOptions({ [type]: true, index })
        }
    }

    const onSubmit = async () => {
        if (!formData || formData?.length <= 0 || (formData?.find((item: any) => !Object.keys(item).length))) {
            setGeneralError('Please check items and their fields to continue ')
            return;
        }
        try {
            setLoadingSubmit(true)
            const newFormatFormData = formData && formData?.map((item: any) => {
                const allItemObj = JSON.parse(JSON.stringify(item))
                allItemObj.number_of_items && delete allItemObj.number_of_items
                allItemObj.price && delete allItemObj.price
                return {
                    asset: allItemObj,
                    quantity: Number(item?.price || 0)
                }
            })
            const formatData = {
                assetTemplateId: assetTemplateSelected?.value || '',
                data: newFormatFormData || [],
                marketId: marketSelected?.value || '',
            }
            await dispatch(tryToUploadRentalPricingCSV({ offers: formatData })).unwrap()
            onCloseModal()
            onSaveSuccessfully('success', 'Items added successfully')
        } catch (err) {
            setGeneralError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const onChangePrice = async (value: any, type?: string, index?: any) => {
        if (type && (index || index === 0)) {
            const changeFormData = (formData && formData?.length > 0) ?
                formData?.map((item: any, idx: number) => {
                    if (idx === index) {
                        return {
                            ...item || {},
                            [type]: value
                        }
                    }
                    else {
                        return item
                    }
                })
                : [Array(index)].map((item: any, idx: number) => {
                    if (idx === index) {
                        return {
                            [type]: value
                        }
                    } else {
                        return {}
                    }
                })
            setFormData(changeFormData)
        }
    }

    return (
        <Modal
            open={openModal}
            contentContainerStyle={'!min-w-[85vw]'}
            onClose={() => !loadingSubmit && onCloseModal()}>
            <div className={'p-2 !max-w-[80w] min-h-[300px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Submit New Item</p>
                </div>
                {generalError && <Error text={generalError} />}
                {activeStep === 1 &&
                    <div className='grid grid-cols-4 gap-4 mt-11'>
                        <SelectCheckbox
                            name='Category'
                            placeholder=' '
                            dataQa={'category-selected'}
                            uniqueName='category'
                            containerStyle='mb-1 min-w-full'
                            selectedOption={assetTemplateSelected}
                            onChangeSelectedOption={onChangeSelectedFields}
                            options={sectors || []}
                        />
                        <SelectCheckbox
                            name='Market'
                            placeholder=' '
                            dataQa={'market-selected'}
                            containerStyle='mb-1 min-w-full'
                            selectedOption={marketSelected}
                            uniqueName='market'
                            onChangeSelectedOption={onChangeSelectedFields}
                            options={markets || []}
                        />
                    </div>
                }
                {activeStep === 2 &&
                    <NewRentalPricingFields
                        formData={formData}
                        totalRows={totalRows}
                        loadingFields={loadingFields}
                        templateFields={fieldDetails}
                        loadingOptions={loadingOptions}
                        filteredFields={filteredFields}
                        onMenuCloseAfterSearchMulti={onMenuCloseAfterSearch}
                        onChangeSearchFieldMultiple={onChangeSearchAssetFields}
                        onDeleteItemRow={onDeleteItemRow}
                        onAddNewRow={onAddNewRow}
                        onSelectFieldValue={onSelectFieldValue}
                        onChangePrice={onChangePrice}
                    />
                }
            </div>
            <div className='mb-7 mt-4 flex flex-row justify-center'>
                {activeStep === 2 &&
                    <Button
                        label={'Submit'}
                        dataQa={'submit'}
                        className={!loadingSubmit ? `btn-main min-w-[200px]` : `btn-main-disable min-w-[200px]`}
                        onClickButton={() => !loadingSubmit && onSubmit()}
                    />
                }
                {activeStep === 1 &&
                    <Button
                        label={'Continue'}
                        dataQa={'continue'}
                        className={!loadingSubmit ? `btn-main min-w-[200px] mb-4` : `btn-main-disable min-w-[200px] mb-4`}
                        onClickButton={onChangeActiveStep}
                    />
                }
            </div>
        </Modal>
    )
}

export default NewRentalPricingItemModal;