import React from 'react'
import { ISalesGradeMapping } from '../../../../interfaces/asset-templates/ISalesGradeMapping';
import Table from '../../../../shared/table';


interface IAssetsTemplatesTable {
    rows: ISalesGradeMapping[];
    rowsLoading: boolean;
    assetId: string;
}
const tableColumns = ['Grade', 'Condition', 'Rank', 'Deduction Percentage']

const SalesGradeMappingsTable = ({ rows, rowsLoading, assetId }: IAssetsTemplatesTable) => {
    return (
        <Table columns={tableColumns} hidePagination>
            {rowsLoading ?
                <>
                    <>
                        {[...Array(5)].map((x, i) => {
                            return (
                                <tr key={i}>
                                    <td className='td-1 !min-w-full text-center py-5'><span></span></td>
                                    <td className='td-1 !min-w-full text-center py-5'><span></span></td>
                                    <td className='td-1 !min-w-full text-center py-5'><span></span></td>
                                    <td className='td-1 !min-w-full text-center py-5'><span></span></td>
                                </tr>
                            )
                        })}
                    </>
                </>
                : <>
                    {(rows && rows?.length > 0) && rows?.map((row: ISalesGradeMapping, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.grade}</td>
                                <td className='px-6 py-4'>{row?.condition}</td>
                                <td className='px-6 py-4'>{row?.rank}</td>
                                <td className='px-6 py-4'>{row?.deductionPercentage || ''}</td>

                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default SalesGradeMappingsTable