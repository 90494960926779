import React, { useRef, useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import RangeSlider from '../range-slider';
import Button from '../button';
import Error from '../error';


interface ICanvasDrawing {
    bgImage?: string
    closeModal: () => void;
    onSaveDrawingImage: (image: any) => void;
}

const CanvasDrawing = ({
    bgImage,
    closeModal,
    onSaveDrawingImage
}: ICanvasDrawing) => {
    const canvasRef = useRef(null) as React.MutableRefObject<any>;
    const [context, setContext] = useState<any>(null);
    const [drawing, setDrawing] = useState<boolean>(false);
    const [hovered, setHovered] = useState<boolean>(false);
    const [hoverX, setHoverX] = useState<any>(0);
    const [hoverY, setHoverY] = useState<any>(0);
    const [paths, setPaths] = useState<any>([]);
    const [errorOnSave, setErrorOnSave] = useState<any>();
    const [brushColor, setBrushColor] = useState<string>('#000000')
    const [brushRadius, setBrushRadius] = useState<number>(1)
    const [currentPath, setCurrentPath] = useState<any>({ color: 'black', width: 1, points: [] });
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false)
    const [backgroundImage, setBackgroundImage] = useState<any>(null);
    const [showBrushRadiusSlider, setShowBrushRadiusSlider] = useState<boolean>(false)

    useEffect(() => {
        const canvas: any = canvasRef.current;
        const ctx = canvas.getContext('2d');
        setContext(ctx);

        const img: any = new Image();
        img.src = bgImage;
        img.crossOrigin = 'anonymous'
        img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            setBackgroundImage(img);
        };
    }, [bgImage]);

    const onChangeBrushColor = (color: any) => {
        setBrushColor(color.hex);
    }

    const onClickShowColorPicker = () => {
        setShowColorPicker(!showColorPicker)
        setShowBrushRadiusSlider(false)
    }

    const onClickShowBrushRadiusSlider = () => {
        setShowBrushRadiusSlider(!showBrushRadiusSlider)
        setShowColorPicker(false)
    }

    const onChangeRangeSliderValue = (value: any) => {
        setBrushRadius(Number(value?.target?.value))
    }

    useEffect(() => {
        if (context && backgroundImage) {
            context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            context.drawImage(backgroundImage, 0, 0, canvasRef.current.width, canvasRef.current.height);

            context.lineJoin = 'round';
            context.lineCap = 'round';

            paths.forEach((path: any) => {
                context.strokeStyle = path.color
                context.lineWidth = path.width;
                context.beginPath();
                context.moveTo(path.points[0]?.x, path.points[0]?.y);
                path.points.forEach((point: any) => {
                    context.lineTo(point?.x, point?.y);
                });
                context.stroke();
            });
        }
    }, [paths, backgroundImage, context]);

    const startDrawing = (e: any) => {
        e.preventDefault();
        const { offsetX, offsetY } = e.nativeEvent;
        setDrawing(true);
        const newPath = {
            color: brushColor,
            width: brushRadius,
            points: [{ x: offsetX, y: offsetY }]
        };
        setCurrentPath(newPath);
    };

    const drawPath = (path: any) => {
        context.strokeStyle = path?.color;
        context.lineWidth = path?.width;
        context.lineJoin = 'round';
        context.lineCap = 'round';
        context.beginPath();
        context.moveTo(path?.points[0]?.x, path?.points[0]?.y);
        path.points.forEach((point: any) => {
            context.lineTo(point?.x, point?.y);
        });
        context.stroke();
        setHovered(true)
    };

    const draw = (e: any) => {
        e?.preventDefault()
        if (!drawing) {
            if (hovered) {
                const { clientX, clientY } = e;
                const canvasRect = canvasRef.current.getBoundingClientRect();
                setHoverX(clientX - canvasRect.left);
                setHoverY(clientY - canvasRect.top);
            }
            return;
        }
        setHovered(false)
        const { offsetX, offsetY } = e.nativeEvent;
        const updatedPath = { ...currentPath, points: [...currentPath.points, { x: offsetX, y: offsetY }] };
        setCurrentPath(updatedPath);
        drawPath(updatedPath);
    };

    const endDrawing = (e: any) => {
        e?.preventDefault()
        if (currentPath?.points?.length > 0) {
            setDrawing(false);
            setPaths([...paths, currentPath]);
            setCurrentPath({ color: brushColor, width: brushRadius, points: [] });
        }
    };

    const redrawPaths = (pathsToDraw: any) => {
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        pathsToDraw.forEach(drawPath);
    };

    const handleHover = (e: any) => {
        const { offsetX, offsetY } = e.nativeEvent;
        setHovered(true);
        setHoverX(offsetX);
        setHoverY(offsetY);
    };

    const handleMouseOut = () => {
        setHovered(false);
    };

    const onClickUndo = () => {
        if (paths?.length > 0) {
            const updatedPaths = [...paths || []];
            updatedPaths.pop();
            setPaths(updatedPaths);
            redrawPaths(updatedPaths);
        }
    };

    const onClickErase = () => {
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        setPaths([]);
    };

    const onSaveImage = () => {
        try {
            const width = canvasRef.current.width;
            const height = canvasRef.current.height;

            const mergedCanvas: any = document.createElement('canvas');
            mergedCanvas.width = width;
            mergedCanvas.height = height;
            const mergedContext = mergedCanvas.getContext('2d');

            mergedContext.drawImage(backgroundImage, 0, 0, width, height);

            paths.forEach((path: any) => {
                mergedContext.beginPath();
                mergedContext.strokeStyle = path.color;
                mergedContext.lineWidth = path.width;
                mergedContext.lineJoin = 'round';
                mergedContext.lineCap = 'round';
                mergedContext.moveTo(path.points[0].x, path.points[0].y);
                path.points.forEach((point: any) => {
                    mergedContext.lineTo(point.x, point.y);
                });
                mergedContext.stroke();
            });

            const dataUri = mergedCanvas.toDataURL('image/png', 1.0);

            const byteString = atob(dataUri.split(',')[1]);
            const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            const blob = new Blob([ab], { type: mimeString });

            onSaveDrawingImage({ blob: blob, dataUri })
            closeModal()
            setErrorOnSave(undefined)
        } catch (err) {
            setErrorOnSave(`${err}`)
        }
    }

    return (
        <div>
            <p className='mb-4 font-semibold text-sm'>Please draw the scratches on the device</p>
            <p className='font-semibold text-sm mb-5'></p>
            {errorOnSave && <Error text={errorOnSave} />}
            <div className='flex flex-row items-center bg-[#75b4d414] shadow mb-3 px-2 min-h-[55px] '>
                <div onClick={onClickShowColorPicker} title={'Brush Color'} className='flex flex-row items-center min-w-[45px] cursor-pointer px-2'>
                    <img src={'/assets/drawer/pallete-gradient.svg'} className='object-contain w-[25px]' />
                    <img src={'/assets/table-actions/arrow-down.svg'} className='object-contain w-[14px] ml-2' />
                </div>
                <div onClick={onClickShowBrushRadiusSlider} title={'Brush Size'} className='flex flex-row items-center min-w-[45px] cursor-pointer ml-5'>
                    <div className='flex flex-row items-center'>
                        <img src={'/assets/drawer/brush-size-gradient-1.svg'} className='object-contain w-[22px]' />
                        <p className='text-primary-light font-bold text-[14px] ml-2'>{brushRadius}</p>
                    </div>
                    <img src={'/assets/table-actions/arrow-down.svg'} className='object-contain w-[14px] ml-2' />
                </div>
                <div onClick={onClickUndo} className='cursor-pointer ml-7'>
                    <img title={'Undo'} src={'/assets/drawer/undo-gradient.svg'} className='object-contain w-[22px] hover:w-[24px]' />
                </div>
                <div onClick={onClickErase} className='cursor-pointer ml-7'>
                    <img title={'Erase All'} src={'/assets/drawer/eraser-gradient.svg'} className='object-contain w-[25px] hover:w-[26px]' />
                </div>
                {/* <div onClick={onClickErase} className='cursor-pointer ml-7'>
                    <img title={'Reset View'} src={'/assets/drawer/reset-view-gradient.svg'} className='object-contain w-[25px] hover:w-[26px]' />
                </div> */}
                {showBrushRadiusSlider &&
                    <div className={`absolute z-50 bg-[#fefefe] rounded shadow px-3 py-4 left-10 top-24`}>
                        <RangeSlider
                            currentValue={brushRadius}
                            minValue={1}
                            steps='1'
                            maxValue={10}
                            onChangeRangeSliderValue={onChangeRangeSliderValue}
                        />
                    </div>
                }
                {showColorPicker &&
                    <div className={`absolute z-50 top-24`}>
                        <SketchPicker
                            color={brushColor}
                            onChangeComplete={onChangeBrushColor}
                        />
                    </div>
                }
            </div>
            <div className='canvas-container relative flex flex-col items-center'>
                <canvas
                    ref={canvasRef}
                    width={500}
                    height={500}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={endDrawing}
                    onMouseOut={endDrawing}
                    onMouseOver={handleHover}
                    onMouseLeave={handleMouseOut}
                />
                {hovered && (
                    <div
                        style={{
                            position: 'absolute',
                            top: hoverY - 5, // Adjust the position as needed
                            left: hoverX + 40, // Adjust the position as needed
                            width: brushRadius,
                            height: brushRadius,
                            borderRadius: '50%',
                            border: `2px solid ${brushColor || 'black'}`,
                            backgroundColor: brushColor || 'black',
                        }}
                    />
                )}
                <div className={'flex flex-row justify-center my-4'}>
                    <Button
                        label='Save Drawing'
                        className='btn-main'
                        onClickButton={onSaveImage}
                    />
                </div>
            </div>
        </div>
    )
};

export default CanvasDrawing;