import React from 'react';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Datepicker from '../../../../shared/datepicker';


interface ITableFilters {
    sectors?: any;
    markets?: any;
    vat?: any;
    selectedSector?: any;
    selectedMarket?: any;
    dateFiltersValues?: any;
    onChangeSelectedFilter: (e: any, type?: string, index?: any) => void;
    onChangeDateFilters: (value: string | null | undefined, type: string) => void;
}

const TableFilters = ({
    sectors,
    markets,
    selectedSector,
    selectedMarket,
    dateFiltersValues,
    onChangeSelectedFilter,
    onChangeDateFilters
}: ITableFilters) => {
    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4 mt-4 mb-7'>
            <SelectCheckbox
                name='Category'
                containerStyle='mb-1 min-w-[90%]'
                dataQa={`${(selectedSector?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                selectedOption={selectedSector}
                placeholder=' '
                uniqueName='sector'
                onChangeSelectedOption={onChangeSelectedFilter}
                options={sectors || []}
            />
            <SelectCheckbox
                name='Market'
                containerStyle='mb-1 min-w-[90%]'
                dataQa={'market-selected'}
                selectedOption={selectedMarket}
                placeholder=' '
                uniqueName='market'
                onChangeSelectedOption={onChangeSelectedFilter}
                options={markets || []}
            />
            <Datepicker
                label='Date From'
                placeholder=' '
                dataQa={'date-from'}
                containerStyle='mb-1 min-w-[90%]'
                inputValue={dateFiltersValues?.from}
                onChangeInput={onChangeDateFilters}
                inputUniqueName={'from'}
            />
            <Datepicker
                label='Date To'
                placeholder=' '
                dataQa={'date-to'}
                containerStyle='mb-2 min-w-[90%]'
                inputValue={dateFiltersValues?.to}
                onChangeInput={onChangeDateFilters}
                inputUniqueName={'to'}
            />
        </div>
    )
}
export default TableFilters;