import React from 'react';
import Button from '../../../../shared/button';


interface ITabs {
    tabs: any[];
    activeTab: string;
    onClickTab: (value: any) => void;
}

const Tabs = ({
    tabs,
    activeTab,
    onClickTab
}: ITabs) => {
    return (
        <div className='my-8'>
            <div className='my-2 grid grid-cols-5 gap-4 '>
                {tabs && tabs?.length > 0 && tabs?.map((tab, index) => {
                    let buttonClassName = 'btn-categories'
                    if (activeTab === tab?.name) buttonClassName = 'btn-categories';
                    else buttonClassName = 'btn-noactive-categories border';
                    return (
                        <Button
                            key={index}
                            label={tab?.label}
                            dataQa={`${tab?.label}`}
                            className={`${buttonClassName} !min-w-[70px]`}
                            onClickButton={() => onClickTab(tab)}
                        />
                    )
                })}
            </div>
        </div>
    )
}
export default Tabs