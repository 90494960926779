import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { PaginatedUserAccess } from '../../../../interfaces/user-access-control/paginatedStore.type';


const tryToFetchRoles = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any
): Promise<AxiosResponse<PaginatedUserAccess>> => {
    const url = filters
        ? `${USERS_API_URL}/roles/paginated?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${USERS_API_URL}/roles/paginated?page=${pageNumber}&size=${pageSize}`;
    return brokersAxios.put<PaginatedUserAccess>(url, data);
};

const tryToFetchAllRoles = (type?: string): Promise<AxiosResponse<ApiResponse<any>>> => {
    return brokersAxios.get<ApiResponse<any>>(`${USERS_API_URL}/roles?type=${type}`);
};

const tryToFetchAllSections = (type: string): Promise<AxiosResponse<ApiResponse<any>>> => {
    return brokersAxios.put<ApiResponse<any>>(`${USERS_API_URL}/sections`, { filters: { type } });
};

const tryToAddRole = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/roles`, data);
};

const tryToEditRole = (id: string, data: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/roles/${id}`, data);
};

const tryToFetchSingleRole = (id: string): Promise<AxiosResponse<ApiResponse<any>>> => {
    return brokersAxios.get<ApiResponse<any>>(`${USERS_API_URL}/roles/${id}`);
};

const tryToDeleteRole = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/roles/${id}`);
};

export const UserAccessService = {
    tryToFetchRoles,
    tryToAddRole,
    tryToEditRole,
    tryToFetchSingleRole,
    tryToFetchAllSections,
    tryToFetchAllRoles,
    tryToDeleteRole
};