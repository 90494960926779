import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { PartnersService } from './partnersApi';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { IAddress } from '../../../../interfaces/auth/ICreateUserSchema';
import { IEditPartner } from '../../../../interfaces/partners/IEditPartner';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { IPartnersSchema } from '../../../../interfaces/partners/IPartnersSchema';
import { IPartnersListState } from '../../../../interfaces/partners/IPartnersList';
import { PaginatedAgents } from '../../../../interfaces/agents/paginatedStore.type';
import { IPartnerSettings } from '../../../../interfaces/partners/IPartnerSettings';
import { IPartnersPriority } from '../../../../interfaces/partners/IPartnersPriority';
import { IAdminEditPartner } from '../../../../interfaces/partners/IAdminEditPartner';
import { IPartnerSchedulers } from '../../../../interfaces/partners/IPartnerSchedulers';
import { PaginatedPartners } from '../../../../interfaces/partners/paginatedStore.type';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import {
    IPaginationPayload,
    IPaginationPayloadWithId,
} from '../../../../interfaces/shared/IPaginationPayload';
import { IPartnerWarehouse } from '../../../../interfaces/partners/IPartnerWarehouse';

const initialState: IPartnersListState = {
    partnersAreLoading: false,
    toastSuccessRequest: false,
    partnerIsLoading: false,
    partnerNavigationLoading: false,
    requestStatus: 'default',
    agentsAreLoading: false,
    partnerMarketsAreLoading: false,
    message: '',
};

export const tryToFetchPartners = createAsyncThunk<PaginatedPartners, IPaginationPayload>(
    'partners/tryToFetchPartners',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await PartnersService.tryToFetchPartners(
            pageNumber,
            pageSize,
            data,
            filters,
        );
        return result?.data;
    },
);

export const tryToFetchPartnerNavigation = createAsyncThunk<any, undefined | null>(
    'partners/tryToFetchPartnerNavigation',
    async () => {
        const result = await PartnersService.tryToFetchPartnerNavigation();
        return result?.data;
    }
);

export const tryToFetchUserNotifications = createAsyncThunk<any, any>(
    'partners/tryToFetchUserNotifications',
    async ({ data, page, size }) => {
        const result = await PartnersService.tryToFetchUserNotifications(data, page, size);
        return result?.data?.data;
    }
);

export const tryToSetReadNotifications = createAsyncThunk<any, any>(
    'partners/tryToSetReadNotifications',
    async (data, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToSetReadNotifications(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchSinglePartner = createAsyncThunk<IPartners, string>(
    'partners/tryToFetchSinglePartner',
    async (id: string) => {
        const result = await PartnersService.tryToFetchSinglePartner(id);
        return result?.data?.data;
    },
);

export const tryToFetchAllPartners = createAsyncThunk<IPartners[], any>(
    'partners/tryToFetchAllPartners',
    async () => {
        const result = await PartnersService.tryToFetchAllPartners();
        return result?.data;
    },
);

export const tryToFetchPartnerCatalogs = createAsyncThunk<any, string>(
    'partners/tryToFetchPartnerCatalogs',
    async (brokerUSername, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToFetchPartnerCatalogs(
                brokerUSername,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAddPartner = createAsyncThunk<ApiResponse<IPartnersSchema>, IPartners>(
    'partners/tryToAddPartner',
    async (data: IPartners, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToAddPartner(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToRequestToRegisterPartner = createAsyncThunk<ApiResponse<IPartnersSchema>, IPartners>(
    'partners/tryToRequestToRegisterPartner',
    async (data: IPartners, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToRequestToRegisterPartner(
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToSetPartnerPriority = createAsyncThunk<ApiResponse<IPartnersSchema>, IPartnersPriority>(
    'partners/tryToSetPartnerPriority',
    async (data: IPartnersPriority, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToSetPartnerPriority(
                data,
                data._id || '',
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchUserData = createAsyncThunk<any, undefined>(
    'partners/tryToFetchUserData',
    async () => {
        const result = await PartnersService.tryToFetchUserData();
        return result?.data;
    },
);

export const tryToEditPartner = createAsyncThunk<ApiResponse<IPartnersSchema>, IEditPartner>(
    'partners/tryToEditPartner',
    async (data: IEditPartner, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToEditPartner(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditPartnerDetails = createAsyncThunk<ApiResponse<IPartnersSchema>, IAdminEditPartner>(
    'partners/tryToEditPartnerDetails',
    async (data: IAdminEditPartner, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToEditPartnerDetails(
                data?._id || '',
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditAddressPartner = createAsyncThunk<ApiResponse<IPartnersSchema>, IAddress>(
    'partners/tryToEditAddressPartner',
    async (data: IAddress, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToEditAddressPartner(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditPartnerTrustpilot = createAsyncThunk<ApiResponse<IPartnersSchema>, { id: string; data: any }>(
    'partners/tryToEditPartnerTrustpilot',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToEditPartnerTrustpilot(
                id,
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToGetPrioritySettings = createAsyncThunk<any, string>(
    'partners/tryToGetPrioritySettings',
    async (partnerId) => {
        const result = await PartnersService.tryToGetPrioritySettings(
            partnerId,
        );
        return result.data;
    },
);

export const tryToSetPriorityOfBrokers = createAsyncThunk<ApiResponse<IPartnerSettings>, { formatData: IPartnerSettings; id: string }>(
    'partners/tryToSetPriorityOfBrokers',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToSetPriorityOfBrokers(
                data?.id || '',
                data.formatData,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);
export const tryToSetAddressDefault = createAsyncThunk<ApiResponse<IPartnersSchema>, string>(
    'partners/tryToSetAddressDefault',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToSetAddressDefault(id);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEnablePartner = createAsyncThunk<ApiResponse<IPartnersSchema>, string>(
    'partners/tryToEnablePartner',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToEnablePartner(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDisablePartner = createAsyncThunk<ApiResponse<IPartnersSchema>, string>(
    'partners/tryToDisablePartner',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToDisablePartner(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDeletePartner = createAsyncThunk<ApiResponse<IPartnersSchema>, string>(
    'partners/tryToDeletePartner',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToDeletePartner(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToEnableInventoryFeature = createAsyncThunk<ApiResponse<IPartnersSchema>, string>(
    'partners/tryToEnableInventoryFeature',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToEnableInventoryFeature(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDisableInventoryFeature = createAsyncThunk<ApiResponse<IPartnersSchema>, string>(
    'partners/tryToDisableInventoryFeature',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToDisableInventoryFeature(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToEnableLogisticFeature = createAsyncThunk<ApiResponse<IPartnersSchema>, string>(
    'partners/tryToEnableLogisticFeature',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToEnableLogisticFeature(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDisableLogicticFeature = createAsyncThunk<ApiResponse<IPartnersSchema>, string>(
    'partners/tryToDisableLogicticFeature',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToDisableLogicticFeature(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToFetchPartnerAgents = createAsyncThunk<PaginatedAgents,
    IPaginationPayloadWithId
>(
    'agents/tryToFetchPartnerAgents',
    async ({ id, pageNumber, pageSize, filters }) => {
        const result = await PartnersService.tryToFetchPartnerAgents(
            id,
            pageNumber,
            pageSize,
            filters,
        );
        return result?.data;
    },
);

export const tryToAddMarket = createAsyncThunk<ApiResponse<any>, { id: string }>(
    'partners/tryToAddMarket',
    async (data, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToAddMarket(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDisableMarket = createAsyncThunk<ApiResponse<any>, string>(
    'partners/tryToDisableMarket',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToDisableMarket(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFindOperatingMarkets = createAsyncThunk<Array<any>, null>(
    'agents/tryToFindOperatingMarkets',
    async () => {
        const result = await PartnersService.tryToFindOperatingMarkets();
        return result?.data;
    },
);

export const tryToChangeActiveMarket = createAsyncThunk<any, { marketId: string }>(
    'stores/tryToChangeActiveMarket',
    async (data) => {
        return data?.marketId;
    }
);

export const tryToSetPartnerSchdulerSettings = createAsyncThunk<any, { partnerId: string; data: IPartnerSchedulers }>(
    'partners/tryToSetPartnerSchdulerSettings',
    async ({ partnerId, data }, { rejectWithValue }) => {
        try {
            const result =
                await PartnersService.tryToSetPartnerSchdulerSettings(
                    partnerId,
                    data,
                );
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToGetPartnerSchdulerSettings = createAsyncThunk<any, string>(
    'partners/tryToGetPartnerSchdulerSettings',
    async (partnerId) => {
        const result = await PartnersService.tryToGetPartnerSchdulerSettings(
            partnerId,
        );
        return result?.data;
    },
);

export const tryToControlLogisticAndInventoryFeature = createAsyncThunk<any, { partnerId: string; type: string; enable: boolean }>(
    'partners/tryToControlLogisticAndInventoryFeature',
    async ({ partnerId, type, enable }) => {
        const result =
            await PartnersService.tryToControlLogisticAndInventoryFeature(
                partnerId,
                type,
                enable,
            );
        return result?.data;
    },
);

export const tryToAuthorizeUser = createAsyncThunk<any, { dashboard: string }>(
    'partners/tryToAuthorizeUser',
    async (data) => {
        const result = await PartnersService.tryToAuthorizeUser(data);
        return result?.data;
    },
);

export const tryToGenerateApiKey = createAsyncThunk<any>(
    'partners/tryToGenerateApiKey',
    async () => {
        const result = await PartnersService.tryToGenerateApiKey();
        return result?.data;
    },
);

export const tryToAddGeneratedApiKey = createAsyncThunk<any, any>(
    'partners/tryToAddGeneratedApiKey',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToAddGeneratedApiKey(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchAllWarehouseSections = createAsyncThunk<any, any>(
    'warehouseLocations/tryToFetchAllWarehouseSections',
    async ({ warehouseId, type, category }) => {
        const result = await PartnersService.tryToFetchAllWarehouseSections(
            warehouseId,
            type,
            category,
        );
        return result?.data?.data;
    },
);

export const tryToFetchPartnerWarehouses = createAsyncThunk<IPartnerWarehouse[], string>(
    'partners/tryToFetchPartnerWarehouses',
    async (id: string) => {
        const result = await PartnersService.tryToFetchPartnerWarehouses(id);
        return result?.data;
    }
);

export const tryToAddPartnerWarehouse = createAsyncThunk<
    any,
    {
        name: string;
        markets: string[];
        partnerId: string;
        address: {
            phone: string;
            warehouseCountry: string;
            email: string;
            street: string;
            city: string;
            postalCode: string;
            addressNo: string;
            country: string;
            state: string | undefined;
            default: boolean;
        };
    }
>(
    'partners/tryToAddPartnerWarehouse',
    async (
        data: {
            name: string;
            markets: string[];
            partnerId: string;
            address: {
                phone: string;
                warehouseCountry: string;
                email: string;
                street: string;
                city: string;
                postalCode: string;
                addressNo: string;
                country: string;
                state: string | undefined;
                default: boolean;
            };
        },
        { rejectWithValue },
    ) => {
        try {
            const result = await PartnersService.tryToAddPartnerWarehouse(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);
export const tryToEditPartnerWarehouse = createAsyncThunk<
    any,
    {
        data: {
            name: string;
            markets: string[];
            partnerId: string;
            address: {
                phone: string;
                warehouseCountry: string;
                email: string;
                street: string;
                city: string;
                postalCode: string;
                addressNo: string;
                country: string;
                state: string | undefined;
                default: boolean;
            };
        };
        _id: string;
    }
>(
    'partners/tryToEditPartnerWarehouse',
    async (
        data: {
            data: {
                name: string;
                markets: string[];
                partnerId: string;
                address: {
                    phone: string;
                    warehouseCountry: string;
                    email: string;
                    street: string;
                    city: string;
                    postalCode: string;
                    addressNo: string;
                    country: string;
                    state: string | undefined;
                    default: boolean;
                };
            };
            _id: string;
        },
        { rejectWithValue },
    ) => {
        try {
            const result = await PartnersService.tryToEditPartnerWarehouse(
                data._id,
                data.data,
            );
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeletePartnerWarehouse = createAsyncThunk<any, any>(
    'partners/tryToDeletePartnerWarehouse',
    async (
        data: {
            id: string;
            data: {
                partnerId: string;
                country: string;
            };
        },
        { rejectWithValue },
    ) => {
        try {
            const result = await PartnersService.tryToDeletePartnerWarehouse(
                data.id,
                data.data,
            );
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const partnersSlice = createSlice({
    name: 'partners',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchPartners
            .addCase(tryToFetchPartners.pending, (state) => {
                state.partnersAreLoading = true;
            })
            .addCase(tryToFetchPartners.rejected, (state) => {
                state.partnersAreLoading = false;
            })
            .addCase(tryToFetchPartners.fulfilled, (state, action) => {
                state.partnersAreLoading = false;
                state.partners = action.payload;
            })

            // tryToFetchPartnerAgents
            .addCase(tryToFetchPartnerAgents.pending, (state) => {
                state.agentsAreLoading = true;
            })
            .addCase(tryToFetchPartnerAgents.rejected, (state) => {
                state.agentsAreLoading = false;
            })
            .addCase(tryToFetchPartnerAgents.fulfilled, (state, action) => {
                state.agentsAreLoading = false;
                state.agents = action.payload;
            })

            // tryToFetchUserData
            .addCase(tryToFetchUserData.rejected, (state) => {
                state.userData = undefined;
            })
            .addCase(tryToFetchUserData.fulfilled, (state, action) => {
                state.userData = action.payload?.data;
            })

            // tryToFetchSinglePartner
            .addCase(tryToFetchSinglePartner.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToFetchSinglePartner.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToFetchSinglePartner.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.partnerDetail = action.payload;
            })

            // tryToAddPartner
            .addCase(tryToAddPartner.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToAddPartner.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToAddPartner.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.partners?.data)
                    state.partners.data.elements = [
                        ...state.partners?.data.elements,
                        data,
                    ];
            })

            // tryToRequestToRegisterPartner
            .addCase(tryToRequestToRegisterPartner.pending, (state) => {
                state.partnerIsLoading = true;
                state.toastSuccessRequest = false;
            })
            .addCase(tryToRequestToRegisterPartner.rejected, (state) => {
                state.partnerIsLoading = false;
                state.toastSuccessRequest = false;
            })
            .addCase(
                tryToRequestToRegisterPartner.fulfilled,
                (state, action) => {
                    state.partnerIsLoading = false;
                    state.requestStatus = 'success';
                    state.toastSuccessRequest = true;
                },
            )

            // tryToEditPartner
            .addCase(tryToEditPartner.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToEditPartner.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToEditPartner.fulfilled, (state) => {
                state.partnerIsLoading = false;
            })

            // tryToSetPriorityOfBrokers
            .addCase(tryToSetPriorityOfBrokers.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToSetPriorityOfBrokers.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToSetPriorityOfBrokers.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.requestStatus = 'success';
            })

            // tryToGetPrioritySettings
            .addCase(tryToGetPrioritySettings.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToGetPrioritySettings.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToGetPrioritySettings.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.prioritySettings = action.payload?.data;
            })

            // tryToEditAddressPartner
            .addCase(tryToEditAddressPartner.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToEditAddressPartner.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToEditAddressPartner.fulfilled, (state) => {
                state.partnerIsLoading = false;
            })

            // tryToSetAddressDefault
            .addCase(tryToSetAddressDefault.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToSetAddressDefault.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToSetAddressDefault.fulfilled, (state) => {
                state.partnerIsLoading = false;
            })

            // tryToSetPartnerPriority
            .addCase(tryToSetPartnerPriority.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToSetPartnerPriority.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToSetPartnerPriority.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.requestStatus = 'success';
                if (state.partners?.data.elements)
                    state.partners.data.elements =
                        { ...state }.partners?.data.elements.map((partner) => {
                            if (partner?._id === action.meta?.arg?._id) {
                                return {
                                    ...partner,
                                    priority: action.meta.arg.priority,
                                };
                            } else {
                                return partner;
                            }
                        }) || [];
            })

            // tryToEditPartnerTrustpilot
            .addCase(tryToEditPartnerTrustpilot.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToEditPartnerTrustpilot.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToEditPartnerTrustpilot.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.requestStatus = 'success';
                if (state.partners?.data.elements)
                    state.partners.data.elements =
                        { ...state }.partners?.data.elements.map((partner) => {
                            if (partner?._id === action.meta?.arg?.id) {
                                return {
                                    ...partner,
                                    trustPilotKey:
                                        action.meta.arg.data?.trustPilotKey,
                                };
                            } else {
                                return partner;
                            }
                        }) || [];
            })

            // tryToAddGeneratedApiKey
            .addCase(tryToAddGeneratedApiKey.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToAddGeneratedApiKey.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToAddGeneratedApiKey.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.requestStatus = 'success';
                if (state.partners?.data.elements)
                    state.partners.data.elements =
                        { ...state }.partners?.data.elements.map((partner) => {
                            if (partner?._id === action.meta?.arg?.partnerId) {
                                return {
                                    ...partner,
                                    apiIntegrationKey:
                                        action.meta.arg.apiIntegrationKey,
                                };
                            } else {
                                return partner;
                            }
                        }) || [];
            })

            // tryToDisablePartner
            .addCase(tryToDisablePartner.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToDisablePartner.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToDisablePartner.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.requestStatus = 'success';
                if (state.partners?.data.elements)
                    state.partners.data.elements =
                        { ...state }.partners?.data.elements.map((partner) => {
                            if (partner?._id === action.meta?.arg) {
                                return {
                                    ...partner,
                                    enabled: false,
                                };
                            } else {
                                return partner;
                            }
                        }) || [];
            })

            // tryToEnablePartner
            .addCase(tryToEnablePartner.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToEnablePartner.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToEnablePartner.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.requestStatus = 'success';
                if (state.partners?.data.elements)
                    state.partners.data.elements =
                        { ...state }.partners?.data.elements.map((partner) => {
                            if (partner?._id === action.meta?.arg) {
                                return {
                                    ...partner,
                                    enabled: true,
                                };
                            } else {
                                return partner;
                            }
                        }) || [];
            })

            // tryToDeletePartner
            .addCase(tryToDeletePartner.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToDeletePartner.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToDeletePartner.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.requestStatus = 'success';
                if (state.partners?.data.elements)
                    state.partners.data.elements =
                        state.partners?.data.elements.filter(
                            (partner) => partner?._id !== action.meta?.arg,
                        );
            })

            // tryToControlLogisticAndInventoryFeature
            .addCase(
                tryToControlLogisticAndInventoryFeature.pending,
                (state) => {
                    state.partnerIsLoading = true;
                },
            )
            .addCase(
                tryToControlLogisticAndInventoryFeature.rejected,
                (state) => {
                    state.partnerIsLoading = false;
                },
            )
            .addCase(
                tryToControlLogisticAndInventoryFeature.fulfilled,
                (state, action) => {
                    state.partnerIsLoading = false;
                    state.requestStatus = 'success';
                    if (state.partners?.data.elements)
                        state.partners.data.elements =
                            { ...state }.partners?.data.elements.map(
                                (partner) => {
                                    if (
                                        partner?._id ===
                                        action.meta?.arg.partnerId
                                    ) {
                                        return {
                                            ...partner,
                                            [action.meta.arg.type ===
                                                'logistics'
                                                ? 'logistic'
                                                : action.meta.arg.type]:
                                                action?.meta?.arg?.enable,
                                        };
                                    } else {
                                        return partner;
                                    }
                                },
                            ) || [];
                },
            )

            // tryToFindOperatingMarkets
            .addCase(tryToFindOperatingMarkets.pending, (state) => {
                state.partnerMarketsAreLoading = true;
            })
            .addCase(tryToFindOperatingMarkets.rejected, (state) => {
                state.partnerMarketsAreLoading = false;
            })
            .addCase(tryToFindOperatingMarkets.fulfilled, (state, action) => {
                state.partnerMarketsAreLoading = false;
                state.partnerMarkets = action.payload;
                const getActiveMarket = localStorage.getItem('active-market');
                const activeMarket =
                    getActiveMarket &&
                    getActiveMarket !== 'undefined' &&
                    JSON.parse(getActiveMarket);
                const findActiveMarket =
                    action.payload &&
                    activeMarket &&
                    action.payload?.find(
                        (market) =>
                            market?.market?._id === activeMarket?.market?._id,
                    );
                state.partnerActiveMarket =
                    findActiveMarket || action?.payload?.[0];
                if (!activeMarket) {
                    localStorage.setItem(
                        'active-market',
                        JSON.stringify(action?.payload?.[0]),
                    );
                }
            })

            // tryToAddMarket
            .addCase(tryToAddMarket.pending, (state) => {
                state.partnerMarketsAreLoading = true;
            })
            .addCase(tryToAddMarket.rejected, (state) => {
                state.partnerMarketsAreLoading = false;
            })
            .addCase(tryToAddMarket.fulfilled, (state, action) => {
                state.partnerMarketsAreLoading = false;
                const data = action.payload.data || action.meta.arg;
                state.partnerMarkets = [...(state?.partnerMarkets || []), data];
            })

            // tryToDisableMarket
            .addCase(tryToDisableMarket.pending, (state) => {
                state.partnerMarketsAreLoading = true;
            })
            .addCase(tryToDisableMarket.rejected, (state) => {
                state.partnerMarketsAreLoading = false;
            })
            .addCase(tryToDisableMarket.fulfilled, (state, action) => {
                state.partnerMarketsAreLoading = false;
                state.partnerMarkets = state?.partnerMarkets?.filter(
                    (market: IActiveMarket) =>
                        market?.market?._id !== action?.meta?.arg,
                );
            })

            // tryToChangeActiveMarket
            .addCase(tryToChangeActiveMarket.pending, (state) => {
                state.partnerMarketsAreLoading = true;
            })
            .addCase(tryToChangeActiveMarket.rejected, (state) => {
                state.partnerMarketsAreLoading = false;
            })
            .addCase(tryToChangeActiveMarket.fulfilled, (state, action) => {
                state.partnerMarketsAreLoading = false;
                const findActiveMarket =
                    state?.partnerMarkets &&
                    state?.partnerMarkets?.length > 0 &&
                    state?.partnerMarkets?.find(
                        (market: IActiveMarket) =>
                            market?.market?._id === action?.meta?.arg?.marketId,
                    );
                state.partnerActiveMarket = findActiveMarket || undefined;
                localStorage.setItem(
                    'active-market',
                    JSON.stringify(findActiveMarket),
                );
            })

            // tryToSetPartnerSchdulerSettings
            .addCase(tryToSetPartnerSchdulerSettings.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToSetPartnerSchdulerSettings.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToSetPartnerSchdulerSettings.fulfilled, (state) => {
                state.partnerIsLoading = false;
            })

            // tryToFetchPartnerNavigation
            .addCase(tryToFetchPartnerNavigation.pending, (state) => {
                state.partnerNavigationLoading = true;
            })
            .addCase(tryToFetchPartnerNavigation.rejected, (state) => {
                state.partnerNavigationLoading = false;
            })
            .addCase(tryToFetchPartnerNavigation.fulfilled, (state, action) => {
                state.partnerNavigationLoading = false;
                const data = action.payload.data;
                state.partnerNavigation = data;
            })

            // tryToFetchPartnerWarehouses
            .addCase(tryToFetchPartnerWarehouses.pending, (state) => { })
            .addCase(tryToFetchPartnerWarehouses.rejected, (state) => { })
            .addCase(tryToFetchPartnerWarehouses.fulfilled, (state, action) => {
                state.partnerDetailWarehouses = action.payload;
            })

            // tryToAddPartnerWarehouse
            .addCase(tryToAddPartnerWarehouse.pending, (state) => { })
            .addCase(tryToAddPartnerWarehouse.rejected, (state) => { })
            .addCase(tryToAddPartnerWarehouse.fulfilled, (state, action) => {
                const newWarehouseAddress = action.payload.data;
                newWarehouseAddress.markets = newWarehouseAddress.marketIds;
                state.partnerDetailWarehouses = [
                    ...(state.partnerDetailWarehouses || []),
                    newWarehouseAddress,
                ];
            })

            // tryToEditPartnerWarehouse
            .addCase(tryToEditPartnerWarehouse.pending, (state) => { })
            .addCase(tryToEditPartnerWarehouse.rejected, (state) => { })
            .addCase(tryToEditPartnerWarehouse.fulfilled, (state, action) => {
                const updatedWarehouseAddress = action.payload.data;
                updatedWarehouseAddress.markets =
                    updatedWarehouseAddress.marketIds;
                state.partnerDetailWarehouses =
                    state.partnerDetailWarehouses?.map((warehouse) =>
                        warehouse._id === updatedWarehouseAddress._id
                            ? updatedWarehouseAddress
                            : warehouse,
                    );
            })

            // tryToDeletePartnerWarehouse
            .addCase(tryToDeletePartnerWarehouse.pending, (state) => { })
            .addCase(tryToDeletePartnerWarehouse.rejected, (state) => { })
            .addCase(tryToDeletePartnerWarehouse.fulfilled, (state, action) => {
                if (state.partnerDetailWarehouses) {
                    const deletedWarehouseAddress = action.payload.data;
                    state.partnerDetailWarehouses =
                        state.partnerDetailWarehouses.filter(
                            (warehouse) =>
                                warehouse._id !== deletedWarehouseAddress._id,
                        );
                }
            });
    },
});

export default partnersSlice.reducer;
