import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { tryToFetchComparisonSites } from '../../../store/brokers/admin/comparison-sites/comparisonSitesSlice';
import ComparisonSitesTable from './tables/ComparisonSitesTable';
import Input from '../../../shared/input';
import EmptyContainer from '../../../shared/empty-container';


const ComparisonSites = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any[]>([]);
    const [search, setSearch] = useState<string>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.comparisonSites.comparisonSites?.data?.page);
    const rowsLoading = useAppSelector((state) => state.comparisonSites.comparisonSitesAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.comparisonSites);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Comparison Sites')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.comparisonSites) {
            const rows =
                state.comparisonSites?.data?.elements &&
                    Array.isArray(state.comparisonSites?.data.elements)
                    ? state.comparisonSites?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.comparisonSites]);

    const getComparisonData = async () => {
        const formatData: any = {
            ...paginationState,
        }
        if (search) {
            formatData.data = { filters: { search: search } }
        }
        await dispatch(tryToFetchComparisonSites(formatData));
    }

    useEffect(() => {
        getComparisonData()
    }, [paginationState]);

    const onSeeComparisonDetails = (name: string) => {
        navigate(`/comparison-sites/${name}`)
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    return (
        <div>
            <div>
                <p className='page-title'>Comparison Sites</p>
            </div>
            <div className='my-5 relativ'>
                <div className={'min-w-[100px]'}>
                    <Input
                        placeholder={'Filter Table'}
                        dataQa={'comparison-sites-filter-table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={'mb-0 border-0 focus:outline-none'}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                {(rows?.length > 0 || rowsLoading) ?
                    <ComparisonSitesTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onSeeComparisonDetails={onSeeComparisonDetails}
                    />
                    : <EmptyContainer
                        text={'No Comparison Site to view yet.'}
                        showImage={true}
                    />
                }
            </div>
        </div>
    );
};

export default ComparisonSites;
