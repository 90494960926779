import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IToast } from '../../../interfaces/components/IToast';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IInvoices } from '../../../interfaces/invoices/IInvoices';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import {
    tryToFetchAllPartners,
    tryToFetchPartnerCatalogs
} from '../../../store/brokers/admin/partners/partnersSlice';
import {
    tryToDownloadInvoicesCSV,
    tryToFetchFilteredInvoices,
    tryToMarkInvoiceAsPaid,
    tryToShipOrder
} from '../../../store/brokers/partners/invoices/invoicesSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { INVOICE_STATES } from '../../../utils/constants/invoices-states';
import AdminInvoicesFilters from './details/AdminInvoicesFilters';
import AdminInvoicesState from './details/AdminInvoicesState';
import AdminInvoicesTable from './tables/AdminInvoicesTable';
import ShipOrdersModal from './modals/ShipOrdersModal';
import SearchWithButton from '../../../shared/search-with-button';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const AdminInvoices = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<IInvoices[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [search, setSearch] = useState<string>()
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [partners, setPartners] = useState<IPartners[]>()
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [selectedState, setSelectedState] = useState<string>()
    const [selectedPartner, setSelectedPartner] = useState<any>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [selectedRows, setSelectedRows] = useState<Array<string>>()
    const [invoiceData, setInvoiceData] = useState<Array<any>>()
    const [showShippedModal, setShowShippedModal] = useState<{ show: boolean, id: string }>()
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [dateFiltersValues, setDateFiltersValues] = useState<any>();
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.invoices.invoices?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state) => state.invoices);
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Invoices')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.invoices) {
            const rows =
                state.invoices?.data?.elements && Array.isArray(state.invoices?.data.elements)
                    ? state.invoices?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.invoices]);

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    const onGettingFilters = (type?: string) => {
        let settingsFilters: any
        if (!type || type !== 'download') {
            settingsFilters = {
                ...paginationState
            }
        }
        if (selectedMarket) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    market: selectedMarket ? selectedMarket?._id : ''
                }
            }
        }
        if (selectedSector) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters.data || {},
                    categoryId: selectedSector ? selectedSector?._id : ''
                }
            }
        }
        if (selectedPartner) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data || {},
                    partnerId: selectedPartner ? selectedPartner?._id : ''
                }
            }
        }
        if (selectedState) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    state: selectedState
                }
            }
        }
        if (search) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    search: search
                }
            }
        }
        if (dateFiltersValues) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    range: {
                        to: new Date(dateFiltersValues?.maxDateCreated),
                        from: new Date(dateFiltersValues?.minDateCreated)
                    }
                }
            }
        }
        return settingsFilters
    }

    const getInvoicesData = () => {
        const settingsFilters = onGettingFilters()
        dispatch(tryToFetchFilteredInvoices(settingsFilters));
    }
    useEffect(() => {
        getInvoicesData()
    }, [paginationState]);


    const getPartnerAndMarketData = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.companyName }))
        setPartners(formatPartners || [])
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.displayName, value: r?._id, label: r?.displayName }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    useEffect(() => {
        getPartnerAndMarketData()
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    const onSelectMarket = (value: string) => {
        setSelectedMarket(value)
        onResetPaginationState()
    }

    const onGetPartnerSectors = async () => {
        const response: any = await dispatch(tryToFetchPartnerCatalogs(selectedPartner?.username || '')).unwrap()
        if (response?.data) {
            const formatSectors = response?.data?.length > 0 && response?.data?.map((category: any) => ({ ...category, label: category?.displayName, name: category?.displayName, value: category?._id }))
            setSectors(formatSectors || [])
        }
    }

    useEffect(() => {
        if (selectedPartner) {
            onGetPartnerSectors()
        }
    }, [selectedPartner])

    const onSelectPartner = (value: any) => {
        setSelectedPartner(value)
        onResetPaginationState()
    }

    const onSelectSector = (value: string) => {
        setSelectedSector(value)
        onResetPaginationState()
    }

    const onChangeInvoicesState = async () => {
        try {
            const data = {
                invoiceNumbers: selectedRows
            }
            dispatch(tryToMarkInvoiceAsPaid(data)).unwrap();
            setShowToast({ type: 'success', message: 'Invoices state changed successfully' });
        } catch (err: any) {
            setShowToast({ type: 'error', message: `${err?.response?.data || err?.response || err}` });
        }
        setSelectedRows(undefined)
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const onChangeActiveState = (status: string) => {
        setSelectedState(selectedState === status ? undefined : status)
        onResetPaginationState()
    }

    const onClickSelectRow = (id?: string) => {
        if (!id) {
            const getAllIds = rows?.length > 0 && rows?.filter(row => !selectedRows?.includes(row.invoiceNumber || '')).map((row) => row.invoiceNumber || '')
            if ((selectedRows && rows && rows.every(r => selectedRows.includes(r.invoiceNumber || '')))) {
                setSelectedRows(sr => sr?.filter(sr => !rows.find(row => row.invoiceNumber === sr)))
            } else {
                setSelectedRows(sr => getAllIds ? [...(sr || []), ...getAllIds] : getAllIds || [])
            }
        }
        else {
            const findIfAlreadySelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find((row) => row === id)
            if (findIfAlreadySelected) {
                const filterOthers = selectedRows && selectedRows?.length > 0 && selectedRows?.filter((row) => row !== id)
                setSelectedRows(filterOthers || undefined)
            } else {
                const formatNew = [
                    ...selectedRows || [],
                    id || ''
                ]
                setSelectedRows(formatNew || undefined)
            }
        }
    }

    const onDownloadTemplate = async () => {
        setInvoiceData(undefined)
        setLoadingDownload(true)
        try {
            const settingsFilters = onGettingFilters('download')
            const response: any = await dispatch(tryToDownloadInvoicesCSV(settingsFilters?.data)).unwrap()
            setInvoiceData(response?.data || [])
        } catch (err: any) {
            setShowToast({ type: 'error', message: `${err?.response || err?.data?.message || err}` })
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (invoiceData && loadingDownload) {
            csvLink.current.link.click()
            setLoadingDownload(false)
        }
    }, [invoiceData])

    const onChangeDateFilters = (value: string | null | undefined, type: string) => {
        setDateFiltersValues({
            ...dateFiltersValues,
            [type]: value || undefined
        })
        onResetPaginationState()
    }

    const clearAllFilters = () => {
        setSelectedMarket(undefined)
        setSelectedPartner(undefined)
        setSelectedSector(undefined)
        setDateFiltersValues(undefined)
        setSelectedState(undefined)
        setSearch(undefined)
        onResetPaginationState()
    }

    const onClickItemToShip = (id: string) => {
        setShowShippedModal({
            show: true,
            id: id
        })
    }

    const onApproveShipItems = async () => {
        if (showShippedModal?.id) {
            try {
                await dispatch(tryToShipOrder(showShippedModal?.id || '')).unwrap()
                setShowToast({ type: 'success', message: 'Successfully shipped!' })
                setShowShippedModal(undefined)
            } catch (err) {
                setShowToast({ type: 'error', message: `${err}` })
            }
        }
    }

    const onClickInvoiceTemplates = () => {
        navigate('/admin-invoices/invoice-templates')
    }

    return (
        <div>
            <div>
                <p className='page-title'>Invoices</p>
            </div>
            <div>
                <AdminInvoicesFilters
                    markets={markets}
                    selectedMarket={selectedMarket}
                    partners={partners}
                    selectedPartner={selectedPartner}
                    sectors={sectors}
                    selectedSector={selectedSector}
                    dateFilters={dateFiltersValues}
                    onChangeFilterValue={onChangeDateFilters}
                    onSelectMarket={onSelectMarket}
                    onSelectPartner={onSelectPartner}
                    onSelectSector={onSelectSector}
                />
            </div>
            <div>
                <AdminInvoicesState
                    invoicesStates={INVOICE_STATES}
                    selectedState={selectedState}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    onChangeState={onChangeActiveState}
                    onClickInvoiceTemplates={onClickInvoiceTemplates}
                />
            </div>
            <div className='relative'>
                <SearchWithButton
                    placeholder='Filter table'
                    search={search}
                    onChangeSearchValue={onChangeSearchValue}
                    buttonLabel={loadingDownload ? 'Loading data...' : 'Export CSV'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Export CSV']) && onDownloadTemplate()}
                    buttonStyle={(!accessControl || pageAccess?.actions?.['Export CSV']) ? 'btn-main' : 'btn-main-disable'}
                    scndButtonLabel={'Clear Filters'}
                    showScndBtn={true}
                    scndButtonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end bg-transparent mt-1'}
                    onClickSecondButton={clearAllFilters}
                />
                <CSVLink
                    ref={csvLink}
                    data={(invoiceData && invoiceData?.length > 0) ? invoiceData?.filter((item, idx) => idx > 0) || [] : []}
                    headers={invoiceData?.[0] || []}
                    className={'none'}
                    target={'_blank'}
                    filename={'Invoices-data-sample.csv'}
                >
                </CSVLink>
                {selectedRows && selectedRows?.length > 0 &&
                    <div className={'min-w-full py-2 px-2 flex flex-row justify-between items-center bg-[#F1F9F7]'}>
                        <p className='font-bold'>{selectedRows ? selectedRows?.length : 0} Rows selected</p>
                        <Button
                            icon={<img src={'/assets/invoices/mark-paid.svg'} className={'w-[20px] object-contain mr-2'} />}
                            label={'Mark as paid'}
                            dataQa={'mark-as-paid'}
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Mark as paid']) && onChangeInvoicesState()}
                            className={`${(!accessControl || pageAccess?.actions?.['Mark as paid']) ? 'btn-main !bg-black !text-white' : 'btn-main-disable'} flex flex-row align-start my-2 !py-2`}
                        />
                    </div>
                }
                <AdminInvoicesTable
                    rows={rows}
                    partners={partners}
                    paginationData={paginationData}
                    markets={markets}
                    selectedRows={selectedRows}
                    setPaginationState={setPaginationState}
                    onClickSelectRow={onClickSelectRow}
                    onClickItemToShip={onClickItemToShip}
                />
            </div>
            {showShippedModal?.show &&
                <ShipOrdersModal
                    openModal={showShippedModal?.show}
                    handleCloseModal={() => setShowShippedModal(undefined)}
                    onApproveAction={onApproveShipItems}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    )
}

export default AdminInvoices;
