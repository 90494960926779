import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { SCHEDULER_TYPES } from '../../../../utils/constants/scheduler-types';
import { SCHEDULE_PERIODS } from '../../../../utils/constants/schedule-periods';
import {
    tryToGetPartnerSchdulerSettings,
    tryToSetPartnerSchdulerSettings
} from '../../../../store/brokers/admin/partners/partnersSlice';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface ISchedulerModal {
    partnerId?: string;
    openModal: boolean;
    handleCloseModal: () => void;
    onFinishSuccesfully: () => void;
}

const SchedulerModal = ({
    openModal,
    partnerId,
    handleCloseModal,
    onFinishSuccesfully,
}: ISchedulerModal) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false)
    const [formData, setFormData] = useState<any>()
    const [generalError, setGeneralError] = useState<string>()

    const onSubmit = async () => {
        try {
            if (partnerId) {
                const formatSchedulerData = SCHEDULER_TYPES?.map((item) => {
                    return {
                        type: item?.value,
                        period: formData?.[item?.value]?.value
                    }
                })
                const formatData = {
                    schedulers: formatSchedulerData
                }
                await dispatch(tryToSetPartnerSchdulerSettings({ partnerId, data: formatData })).unwrap()
                onFinishSuccesfully()
            }
        }
        catch (err) {
            setGeneralError('Something went wrong, please try again or contact our team')
        }
    }

    const onCloseModal = () => {
        handleCloseModal();
        setFormData(undefined)
    }

    const onChangeSelectedValue = (e: any, type: string) => {
        setFormData({
            ...formData || {},
            [type]: e
        })
    }

    const onGettingExistingData = async () => {
        if (partnerId) {
            try {
                const existingData: any = await dispatch(tryToGetPartnerSchdulerSettings(partnerId)).unwrap();
                if (existingData && existingData?.schedulers) {
                    let formatSchedulerData: any
                    existingData?.schedulers?.length > 0 && existingData?.schedulers?.map((item: any) => {
                        const findPeriod = SCHEDULE_PERIODS?.find((period: any) => period?.value === item?.period)
                        formatSchedulerData = {
                            ...formatSchedulerData,
                            [item.type]: findPeriod
                        }
                        return item
                    })
                    setFormData(formatSchedulerData)
                } else {
                    setFormData(undefined)
                }
            } catch (err) {
                setFormData(undefined)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        onGettingExistingData()
        setLoading(true)
    }, [partnerId, openModal])

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[400px]'}>
                <p className='mb-4 font-semibold text-sm'>Scheduler Settings</p>
                {generalError && <Error text={generalError} />}
                {loading ?
                    <div className='min-h-[150px] flex flex-row justify-center items-center'>
                        <Loading />
                    </div> :
                    <div>
                        {SCHEDULER_TYPES?.map((item, index) => {
                            return (
                                <div key={index}>
                                    <p className='font-bold text-md mb-2 mt-3'>{item?.name}</p>
                                    <SelectCheckbox
                                        placeholder={'Select period'}
                                        dataQa={'select-period'}
                                        containerStyle='mb-1'
                                        selectedOption={formData?.[item?.value] || null}
                                        onChangeSelectedOption={(e) =>
                                            onChangeSelectedValue(
                                                e,
                                                item?.value,
                                            )
                                        }
                                        options={SCHEDULE_PERIODS}
                                    />
                                </div>
                            )
                        })}
                        <Button
                            label={'Save changes'}
                            dataQa={'select-period-button'}
                            className={'btn-main my-9 min-w-full !shadow-none'}
                            type='submit'
                            onClickButton={onSubmit}
                        />
                    </div>
                }
            </div>
        </Modal>
    )
}
export default SchedulerModal;