import React from 'react';

interface ILayoutLogin {
    children?: React.ReactNode
}

const LayoutLogin = ({
    children
}: ILayoutLogin) => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-11 lg:grid-cols-10 gap-4 min-w-full min-h-full'>
            <div className='hidden md:flex md:col-span-4 lg:col-span-3 h-screen sticky bottom-0 top-0 max-w-full'>
                <div className='absolute top-10 left-0 right-0'>
                    <div className='flex flex-row justify-center'>
                        <img alt='wingpro logo' src={'/assets/auth/wingpro-black.svg'} className={'object-contain w-[80%]'} />
                    </div>
                </div>
                <img alt='register process' src={'/assets/auth/register-image-yellow.png'} className={'w-full h-full object-bottom object-cover'} />
                <div className='absolute bottom-4 left-0 right-0'>
                    <div className='flex flex-row justify-center'>
                        <p>© 2024 Wing Group Ltd</p>
                    </div>
                </div>
            </div>
            <div className='col-span-1 md:col-span-7 min-h-full p-6'>
                {children}
            </div>
        </div >
    )
}

export default LayoutLogin;