import React from 'react';

interface IEmptyContainer {
    text: string;
    showImage?: boolean;
    image?: React.ReactNode;
    containerStyle?: string
}

const EmptyContainer = ({
    text,
    showImage,
    image,
    containerStyle
}: IEmptyContainer) => {
    return (
        <div data-qa={'containerStyle'} className={`flex flex-col items-center justify-center my-6 ${containerStyle}`}>
            {showImage && (!image ? <img alt='no results' src={'/assets/shared/no-results-1.svg'} className={'my-3 w-[100px] object-contain'}/> : image)}
            <div className='min-w-full my-2 flex flex-row justify-center items-center'>
                <p className='align-center text-zinc-400 font-medium'>
                    {text}
                </p>
            </div>
        </div >
    );
};

export default EmptyContainer;