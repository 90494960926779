import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    IParseCurrency
} from '../../../../interfaces/curreny-settings/ICurrencySettings';
import { tryToFindOperatingMarkets } from '../../../../store/brokers/admin/partners/partnersSlice';
import {
    tryToEditCurrencySettings,
    tryToFetchCurrencySettings
} from '../../../../store/brokers/partners/currency-settings/currencySettingsSlice';
import AutomatedConvertLoadingContainer from '../loading/AutomatedConvertLoadingContainer';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Accordion from '../../../../shared/accordion';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface ICurrencyConverterModal {
    categories?: any;
    openModal: boolean;
    handleCloseModal: () => void;
}

const CurrencyConverterModal = ({
    categories,
    openModal,
    handleCloseModal,
}: ICurrencyConverterModal) => {
    const dispatch = useAppDispatch();
    const [generalError, setGeneralError] = useState<string>();
    const [settingsData, setSettingsData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [baseCurrency, setBaseCurrency] = useState<any>();
    const [selectedMarkets, setSelectedMarkets] = useState<any>();
    const [activeAccordion, setActiveAccordion] = useState<string>();
    const [currencySettings, setCurrencySettings] = useState<any>({ parseCurrency: [] });
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
    const state = useAppSelector((state) => state.partners);

    const getPartnerCurrencies = () => {
        const rows = state.partnerMarkets && Array.isArray(state.partnerMarkets)
            ? state.partnerMarkets.map((r: any) => {
                const currency = {
                    flag: r.market.flagUrl,
                    name: r.currency.descriptor?.name,
                    id: r.currency._id,
                    market: r.market,
                }
                if (r.currency?.descriptor?.name === 'EUR') {
                    currency.flag = '/assets/pricing/european-flag.svg'
                }
                return currency
            })
            : [];
        const uniqueMarkets = new Map();
        const groupedData = rows.reduce((acc: any, obj) => {
            const key: any = obj.name;
            if (!acc[key]) {
                acc[key] = { currency: key, markets: [], ...obj || {} };
            }
            const marketId = obj.market._id;
            if (!uniqueMarkets.has(marketId)) {
                uniqueMarkets.set(marketId, obj.market);
                acc[key].markets.push({ ...obj.market, value: obj.market._id });
                acc[key]?.market && delete acc[key].market
            }
            return acc;
        }, {});

        const result: any = Object.values(groupedData)
        setCurrencySettings({
            parseCurrency: result
        })
    }

    useEffect(() => {
        getPartnerCurrencies()
    }, [state.partnerMarkets])

    const tryToGetCurrencySettings = async () => {
        const response = await dispatch(tryToFetchCurrencySettings(null)).unwrap()
        if (response) {
            if (response?.baseCurrency) {
                const formatBaseCurrency = {
                    ...response?.baseCurrency || {},
                    value: response?.baseCurrency?.id,
                    label: response?.baseCurrency?.name
                }
                setBaseCurrency(formatBaseCurrency)
            }
            const findSelectedMarkets: any = [];
            (response?.parseCurrency && response?.parseCurrency?.length > 0) && response?.parseCurrency?.map(parentObj => {
                const parentId = parentObj.id;
                const parentMarkets: any = [];
                (parentObj?.markets && parentObj?.markets?.length > 0) && parentObj?.markets?.map((market: any) => {
                    const selectedCatalogs = categories && categories?.length > 0 && categories?.filter((category: any) => market?.catalogs?.includes(category?.value))
                    const findMarket: any = state?.partnerMarkets && state.partnerMarkets?.length > 0 && state?.partnerMarkets?.find((item: any) => item?.market?._id === market?.id)
                    if (market?.allMarkets) {
                        parentMarkets.push({
                            market: {
                                label: 'All Markets',
                                value: 'all'
                            },
                            catalogs: selectedCatalogs
                        })
                    } else {
                        parentMarkets.push({
                            market: {
                                ...findMarket?.market || {},
                                value: findMarket?.market?._id
                            },
                            catalogs: selectedCatalogs
                        })
                    }

                    return market
                });
                findSelectedMarkets.push({
                    id: parentId,
                    markets: parentMarkets
                })
                return parentObj
            });
            setSelectedMarkets(findSelectedMarkets)
            setSettingsData(response?.parseCurrency)
        }
        setLoading(false)
    }

    useEffect(() => {
        tryToGetCurrencySettings()
        dispatch(tryToFindOperatingMarkets(null))
    }, [])

    const onCloseModal = () => {
        handleCloseModal();
        setBaseCurrency(undefined)
        setSettingsData(undefined)
        setActiveAccordion(undefined)
        setLoading(false)
        setLoadingUpdate(false)
        setGeneralError(undefined)
    }

    const isEmptyObject = (obj: any) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    const hasOnlyEmptyObjects = (arr: any) => {
        return arr.every((item: any) => isEmptyObject(item));
    }

    const onSubmit = async (type?: string) => {
        if (!baseCurrency && type !== 'delete') {
            setGeneralError('Please make sure you select a base currency.')
            return;
        }
        if ((!settingsData || settingsData?.length === 0 || !selectedMarkets || selectedMarkets?.length === 0) && type !== 'delete') {
            setGeneralError('Please make sure you have selected at least one currency with market and category.')
            return;
        }
        let showErrorMarket = false
        const formatParseCurrency = (settingsData && settingsData?.length > 0) && settingsData?.map((item: any) => {
            const findMarkets = selectedMarkets && selectedMarkets?.length > 0 && selectedMarkets?.find((obj: any) => obj?.id === item?.id)
            let formatBody: any = {
                id: item?.id,
                name: item?.name
            }
            if (!findMarkets || !findMarkets?.markets || findMarkets?.markets?.length === 0 || hasOnlyEmptyObjects(findMarkets?.markets)) {
                showErrorMarket = true
            } else {
                const markets: any = [];
                (findMarkets?.markets && findMarkets?.markets?.length > 0) && findMarkets?.markets?.map((market: any) => {
                    if (Object.keys(market).length === 0 || market?.catalogs?.length < 0) {
                        showErrorMarket = true
                    } else {
                        if (market?.market?.value === 'all') {
                            markets.push({
                                allMarkets: true,
                                catalogs: market?.catalogs?.map((catalog: any) => catalog?.value)
                            })
                        } else {
                            markets.push({
                                id: market?.market?.value,
                                catalogs: market?.catalogs?.map((catalog: any) => catalog?.value)
                            })
                        }
                    }
                    return market
                })
                formatBody = {
                    ...formatBody || {},
                    markets,
                }
            }
            return formatBody
        })
        if (showErrorMarket) {
            setGeneralError('For each currency selected to automate, please select at least one market and category')
            return;
        }
        setLoadingUpdate(true)
        const data = {
            baseCurrency: type === 'delete' ? null : {
                id: baseCurrency?.id,
                name: baseCurrency?.name
            },
            parseCurrency: type === 'delete' ? null : formatParseCurrency
        }
        try {
            await dispatch(tryToEditCurrencySettings(data)).unwrap()
            onCloseModal()
        } catch (err: any) {
            setGeneralError(`${err || 'Something went wrong!'}`)
        }
        setLoadingUpdate(false)
    }

    const onChangeSelectedBaseCurrency = (e: any) => {
        setBaseCurrency(e)
    }

    useEffect(() => {
        if (baseCurrency) {
            setSettingsData(settingsData?.filter((item: any) => item?.id !== baseCurrency?.id))
            setSelectedMarkets(selectedMarkets?.filter((item: any) => item?.id !== baseCurrency?.id))
        }
    }, [baseCurrency])

    const onToggleActiveCurrency = (currency: any) => {
        const findIfInSettings = settingsData?.length > 0 && settingsData?.find((item: any) => item?.id === currency.id)
        if (findIfInSettings) {
            const filterSettings = settingsData?.filter((item: any) => item?.id !== currency?.id)
            setSettingsData(filterSettings)
            setActiveAccordion(undefined)
        } else {
            setSettingsData([
                ...settingsData || [],
                {
                    id: currency?.id,
                    name: currency?.name,
                    markets: []
                }
            ])
            setActiveAccordion(currency?.id)
        }
    }

    const onChangeSelectedDetails = (value: any, id?: string, index?: any) => {
        if (id && (index || index === 0)) {
            const findParentCurrency = selectedMarkets && selectedMarkets?.length > 0 && selectedMarkets?.find((item: any, idx: number) => item?.id === id)
            const formatMarkets = (findParentCurrency && findParentCurrency?.markets && findParentCurrency?.markets?.length > 0) ? findParentCurrency?.markets?.map((item: any, idx: number) => {
                if (idx === index) {
                    return {
                        market: item?.market,
                        catalogs: value || []
                    }
                }
                else {
                    return item
                }
            }) :
                [{
                    market: undefined,
                    catalogs: value || []
                }]
            const filterCurrencies = selectedMarkets && selectedMarkets?.length > 0 && selectedMarkets?.filter((item: any, idx: number) => item?.id !== id)
            setSelectedMarkets([
                ...filterCurrencies || [],
                {
                    id,
                    markets: formatMarkets
                }
            ])
        }
    }

    const onChangeMarketSelected = (value: any, id?: string, index?: any) => {
        if (id && (index || index === 0)) {
            const findParentCurrency = selectedMarkets && selectedMarkets?.length > 0 && selectedMarkets?.find((item: any, idx: number) => item?.id === id)
            const formatMarkets = (findParentCurrency && findParentCurrency?.markets && findParentCurrency?.markets?.length > 0) ? findParentCurrency?.markets?.map((item: any, idx: number) => {
                if (idx === index) {
                    return {
                        market: value,
                        catalogs: item?.catalogs || []
                    }
                }
                else {
                    return item
                }
            }) :
                [{
                    market: value,
                    catalogs: []
                }]
            const filterCurrencies = selectedMarkets && selectedMarkets?.length > 0 && selectedMarkets?.filter((item: any, idx: number) => item?.id !== id)
            setSelectedMarkets([
                ...filterCurrencies || [],
                {
                    id,
                    markets: formatMarkets
                }
            ])
        }
    }

    const onChangeActiveAccordion = (id: string) => {
        setActiveAccordion(activeAccordion === id ? undefined : id)
    }

    const onAddNewRowForCurrency = (currencyId: string) => {
        const formatCurrencySettings = selectedMarkets && selectedMarkets?.length > 0 && selectedMarkets?.map((item: any) => {
            if (item?.id === currencyId) {
                return {
                    ...item,
                    markets: [
                        ...item?.markets || [],
                        {}
                    ]
                }
            } else {
                return item
            }
        })
        setSelectedMarkets(formatCurrencySettings)
    }

    const onClickDeleteRow = (currencyId: string, id: number) => {
        const findParentCurrency = selectedMarkets && selectedMarkets?.length > 0 && selectedMarkets?.find((item: any, idx: number) => item?.id === currencyId)
        const filterMarkets = (findParentCurrency && findParentCurrency?.markets && findParentCurrency?.markets?.length > 0) && findParentCurrency?.markets?.filter((item: any, idx: number) => idx !== id)
        const filterCurrencies = selectedMarkets && selectedMarkets?.length > 0 && selectedMarkets?.filter((item: any, idx: number) => item?.id !== currencyId)
        setSelectedMarkets([
            ...filterCurrencies || [],
            {
                id: currencyId,
                markets: filterMarkets?.length > 0 ? filterMarkets : [{}]
            }
        ])
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle={'!min-w-[70vw] min-h-[350px]'}
        >
            <div className={'p-4 !min-w-[65vw] !min-h-[inherit] flex flex-col justify-between'}>
                <div>
                    <p className='mb-4 font-semibold text-sm'>Current Convertion Settings</p>
                    <p className='font-bold text-md mb-4'>Use this form to setup currency convertion</p>
                </div>
                {loading ?
                    <AutomatedConvertLoadingContainer /> :
                    <div className='my-5'>
                        <div>
                            <p className='font-semibold text-md mb-4'>Please select the base currency</p>
                            <SelectCheckbox
                                name={'Currency'}
                                dataQa={'currency'}
                                placeholder={currencySettings?.baseCurrency?.name || 'Select'}
                                selectedOption={baseCurrency}
                                options={
                                    currencySettings?.parseCurrency?.map((currency: IParseCurrency) => {
                                        return { ...currency, label: currency.name, value: currency.id }
                                    }) || []
                                }
                                containerStyle={'mb-0 max-w-[90%]'}
                                selectStyle={{ backgroundColor: 'transparent', marginBottom: 0, borderRadius: '4px' }}
                                onChangeSelectedOption={onChangeSelectedBaseCurrency}
                                uniqueName={'currency'}
                            />
                            {currencySettings.parseCurrency &&
                                <div className='grid grid-cols-3 mb-6 items-center gap-4 px-5'>
                                    <p>Currency</p>
                                    <p className='text-center mr-10'>Automated</p>
                                </div>
                            }
                            {currencySettings?.parseCurrency && currencySettings?.parseCurrency.filter((item: any) => item?.id !== baseCurrency?.value)?.map((currency: any, index: any) => {
                                const findSelectedCurrency = settingsData && settingsData?.length > 0 && settingsData?.find((item: any) => item?.id === currency?.id)
                                const filterMarketsSelected = (selectedMarkets && selectedMarkets?.length > 0) && selectedMarkets?.find((item: any) => item?.id === currency?.id)
                                const currencyMarketsUsed = (filterMarketsSelected?.markets) ? filterMarketsSelected?.markets?.map((item: any) => item.market?.value) : [];
                                return (
                                    <Accordion
                                        key={index}
                                        icon={currency?.flag}
                                        title={currency?.name}
                                        itemActive={!!findSelectedCurrency}
                                        onToggleSwitch={() => onToggleActiveCurrency(currency)}
                                        active={activeAccordion === currency?.id}
                                        onToggleAccordion={() => onChangeActiveAccordion(currency?.id)} >
                                        <div className='mb-[100px] mt-10'>
                                            {(filterMarketsSelected?.markets?.length > 0 ? filterMarketsSelected?.markets : [{}])?.map((obj: any, i: number) => {
                                                const filteredMarketsToSelect = currency?.markets?.filter((item: any) => !currencyMarketsUsed.includes(item.value));
                                                const marketsToAdd: any = [{ value: 'all', label: 'All Markets' }]
                                                if (obj?.market && obj?.market?.value !== 'all') {
                                                    marketsToAdd.push(obj?.market)
                                                }
                                                return (
                                                    <div key={i} className='grid grid-cols-3 gap-4'>
                                                        <SelectCheckbox
                                                            placeholder='Market'
                                                            dataQa={'market'}
                                                            options={[...marketsToAdd || [], ...filteredMarketsToSelect || []]}
                                                            selectedOption={obj?.market}
                                                            onChangeSelectedOption={onChangeMarketSelected}
                                                            uniqueName={currency?.id}
                                                            index={i}
                                                        />
                                                        <SelectCheckbox
                                                            placeholder='Categories'
                                                            dataQa={'categories'}
                                                            options={categories}
                                                            selectedOption={obj?.catalogs}
                                                            onChangeSelectedOption={onChangeSelectedDetails}
                                                            uniqueName={currency?.id}
                                                            index={i}
                                                            multiple={true}
                                                        />
                                                        <div
                                                            className='w-[100%] flex flex-row items-start justify-center cursor-pointer'
                                                            data-qa={'delete-row'}
                                                            onClick={() => onClickDeleteRow(currency?.id, i)}>
                                                            <img src={'/assets/shared/trash-gray.svg'} className={'w-[20px] object-contain mt-3 hover:w-[22px]'} />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )}
                                            {((currency?.markets?.length > (filterMarketsSelected?.markets?.length || 0)) && (currency?.markets?.length !== 1)) &&
                                                <div className='my-3 flex flex-row justify-end'>
                                                    <Button
                                                        label='Add Market'
                                                        dataQa={'add-market'}
                                                        className='btn-main'
                                                        onClickButton={() => onAddNewRowForCurrency(currency?.id)}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </Accordion>
                                )
                            })
                            }
                        </div>
                    </div>
                }
                {generalError &&
                    <div className='flex flex-row justify-center my-4'>
                        <Error text={generalError} />
                    </div>
                }
                <div className='min-w-full flex flex-row justify-center my-3 '>
                    {currencySettings?.parseCurrency
                        && <Button
                            label={'Delete Settings'}
                            dataQa={'delete-settings'}
                            className={`${loadingUpdate ? 'btn-main-disable' : 'btn-error'} !rounded flex flex-row mr-4 items-center justify-center min-w-[150px] !shadow-none`}
                            onClickButton={() => !loadingUpdate && onSubmit('delete')}
                        />
                    }
                    <Button
                        label={'Set Currency Convertions'}
                        dataQa={'set-currency-convertions'}
                        className={`${loadingUpdate ? 'btn-main-disable' : 'btn-main'} !rounded  flex flex-row mr-2 items-center justify-center min-w-[150px] !shadow-none`}
                        onClickButton={() => !loadingUpdate && onSubmit()}
                    />
                </div>
            </div>
        </Modal >
    )
}

export default CurrencyConverterModal;