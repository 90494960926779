import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddScrapingFee,
    tryToEditScrapingFee,
    tryToFetchSingleScrapingFee
} from '../../../../store/brokers/admin/scraping-fees/scrapingFeesSlice';
import { IScrapingFees } from '../../../../interfaces/scraping-fees/IScrapingFees';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';
import SelectCheckbox from '../../../../shared/select-checkbox';

interface IScrapingFeeActionsModal {
    sectors?: any[];
    openActionsModal: boolean;
    scrapingFeeItemId?: string | null;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    assetTemplateId: string;
    scrapingFee: number;
};

const ScrapingFeeActionsModal = ({
    openActionsModal,
    scrapingFeeItemId,
    sectors,
    handleCloseModal,
    onSaveChanges,
}: IScrapingFeeActionsModal) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.scrapingFees);
    const [selectedSector, setSelectedSector] = useState<any>();
    const [saveError, setSaveError] = useState<string>()

    const scrapingFeeLoading = useAppSelector(
        (state) => state.scrapingFees?.scrapingIsLoading,
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        setValue('assetTemplateId', scrapingFeeItemId ? state?.scrapingFee?.assetTemplate?.id || '' : '');
        setValue('scrapingFee', scrapingFeeItemId ? state?.scrapingFee?.scrapingFee || 0 : 0);
        if (scrapingFeeItemId) {
            const findAssetTemplate = sectors && sectors?.length > 0 && sectors?.find((item) => item?._id === state?.scrapingFee?.assetTemplate?.id)
            setSelectedSector(findAssetTemplate)
        }
    }, [state.scrapingFee]);

    useEffect(() => {
        if (scrapingFeeItemId) {
            dispatch(tryToFetchSingleScrapingFee(scrapingFeeItemId));
        }
    }, [scrapingFeeItemId]);

    const onCloseModal = () => {
        reset();
        setSaveError(undefined)
        handleCloseModal();
    };

    const onSubmit = async (data: FormValues) => {
        const payload: IScrapingFees = {
            ...data,
            fee: Number(data?.scrapingFee || 0)
        };
        try {
            if (scrapingFeeItemId) {
                payload._id = scrapingFeeItemId;
                await dispatch(tryToEditScrapingFee(payload)).unwrap();
            } else {
                await dispatch(tryToAddScrapingFee(payload)).unwrap();
            }
            onSaveChanges(
                'success',
                `Scraping Fee successfully ${scrapingFeeItemId ? 'changed' : 'added'}.`,
            );
        } catch (error) {
            onSaveChanges('error', `${error}`);
        }
        reset();
        onCloseModal();
    };

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(
            e || undefined,
        );
        setValue('assetTemplateId', e?.value)
    };

    return (
        <Modal
            open={openActionsModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>Scraping Fee</p>
                {saveError && <Error text={saveError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(scrapingFeeItemId && !scrapingFeeLoading) || !scrapingFeeItemId ? (
                        <div className='flex flex-col my-4'>
                            <SelectCheckbox
                                key={1}
                                name='Category'
                                placeholder=' '
                                dataQa={`${(selectedSector?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                                containerStyle='mb-1'
                                error={errors?.assetTemplateId?.message}
                                selectedOption={selectedSector}
                                onChangeSelectedOption={onChangeSelectedSector}
                                options={sectors || []}
                                disabled={!!scrapingFeeItemId}
                            />
                            <Input
                                label='Scraping Fee'
                                type='text'
                                dataQa={'scraping-fee'}
                                register={register('scrapingFee', {
                                    required: {
                                        message: 'Scraping Fee is required',
                                        value: true,
                                    },
                                    pattern: {
                                        value: /^-?[0-9]\d*\.?\d*$/,
                                        message: 'Only numbers are accepted.'
                                    },
                                    validate: (value: number) => (Number(value) >= 0) || 'Scraping fee should be a positive number'
                                })}
                                error={errors.scrapingFee?.message}
                            />
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    dataQa={'submit'}
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default ScrapingFeeActionsModal;
