import React from 'react';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';


interface IChangeMultipleStatusErrorStep {
    errorResponse?: any;
    onClickAddAssetsExtendedModal: (name: string) => void;
}

const ChangeMultipleStatusErrorStep = ({
    errorResponse,
    onClickAddAssetsExtendedModal,
}: IChangeMultipleStatusErrorStep) => {
    return (
        <div className={'w-full my-5'}>
            <p className='font-bold mb-4'>Please add Extended Assets for items below to change Asset Current Status.</p>
            <Table
                hidePagination={true}
                columns={['Name', 'Add Extended Asset']}>
                {(errorResponse && errorResponse?.length > 0) && errorResponse.map((obj: any, idx: number) => {
                    return (
                        <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                            <td className='px-6 py-2'>
                                {obj}
                            </td>
                            <td className='px-6 py-2'>
                                <Button
                                    label={'Add Extended Details'}
                                    onClickButton={() => onClickAddAssetsExtendedModal(obj || '')}
                                    className={'btn-filters !rounded'}
                                />
                            </td>
                        </tr>
                    );
                })}
            </Table>
        </div>
    )
}

export default ChangeMultipleStatusErrorStep;