import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import SplitOrderModal from './SplitOrderModal';
import PrintLabel from '../details/PrintLabel';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface IItemsDetailsModal {
    openModal: boolean;
    items?: Array<any>;
    orderId: string;
    received?: boolean;
    handleCloseModal: () => void;
}

const ItemsDetailsModal = ({
    openModal,
    items,
    orderId,
    received,
    handleCloseModal
}: IItemsDetailsModal) => {
    const [showSplitModal, setShowSplitModal] = useState<{ show: boolean, orderId: string }>();
    const [showDetailsForItems, setShowDetailsForItems] = useState<Array<string>>()
    const [itemsDetails, setItemsDetails] = useState<any>();
    const activeMarket = useAppSelector((state) => state?.partners?.partnerActiveMarket)

    useEffect(() => {
        const groupedByWarehouseArray = (items && items?.length > 0) && Object.values(items?.reduce((acc: any, obj) => {
            const warehouseId = obj.exchangeDefinition.warehouse._id;
            const warehouseName = obj.exchangeDefinition.warehouse.name;
            if (!acc[warehouseId]) {
                acc[warehouseId] = { warehouse: { warehouseId, name: warehouseName }, items: [] };
            }
            acc[warehouseId].items.push(obj);
            return acc;
        }, {}));
        setItemsDetails(groupedByWarehouseArray)
    }, [openModal, items])

    const onCloseModal = () => {
        handleCloseModal();
    }

    const onToggleDetails = (itemId: string) => {
        const findIfItemSelected = showDetailsForItems && showDetailsForItems?.length > 0 && showDetailsForItems?.find((item: string) => item === itemId)
        if (findIfItemSelected) {
            const filterOutItem = showDetailsForItems && showDetailsForItems?.length > 0 && showDetailsForItems?.filter((item: string) => item !== itemId)
            setShowDetailsForItems(filterOutItem || undefined)
        } else {
            setShowDetailsForItems([...showDetailsForItems || [], itemId])
        }
    }

    const onShowSplitOrderModal = () => {
        setShowSplitModal({
            show: true,
            orderId,
        })
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            showInRight={true}
            contentContainerStyle={'min-w-[650px]'}>
            <div className={'p-2 min-w-[600px]'}>
                <div className='flex flex-cols items-center mb-5'>
                    <p className='mt-2 mb-4 page-title !text-[18px] font-bold mr-10'>
                        Item Details
                    </p>
                    {received &&
                        <div className='my-3'>
                            <Button
                                label={'Split Order'}
                                className={`btn-primary`}
                                onClickButton={onShowSplitOrderModal}
                            />
                        </div>
                    }
                </div>
                {(itemsDetails && itemsDetails?.length > 0) &&
                    itemsDetails?.map((warehouse: any, idx: number) => {
                        const broker = warehouse?.items?.[0]?.broker?.name || warehouse?.items?.[0]?.broker?.username
                        const warehouseName = warehouse?.items?.[0]?.exchangeDefinition?.warehouse?.name
                        return (
                            <div key={idx}>
                                <div className='flex flex-row items-center'>
                                    <p><span className='font-bold text-lg mt-7 mb-3'>Broker: </span> {broker}</p>
                                    <p className='ml-5'><span className='font-bold text-lg mt-7 mb-3'>Warehouse: </span> {warehouseName}</p>
                                </div>
                                {(warehouse?.items && warehouse?.items?.length > 0) &&
                                    warehouse?.items?.map((item: any, id: number) => {
                                        const showDetails = showDetailsForItems && showDetailsForItems?.length > 0 && showDetailsForItems?.find((show: string) => show === item?._id)
                                        const keys = item?.exchangeDefinition?.asset?.descriptor && Object.keys(item?.exchangeDefinition?.asset?.descriptor);
                                        return (
                                            <div key={id} className='bg-[#f8f9fc] rounded p-3 mb-2'>
                                                {item?._id &&
                                                    <PrintLabel itemId={item?._id} index={id} />
                                                }
                                                {item?._id &&
                                                    <>
                                                        <div className='grid grid-cols-2 gap-3 my-2'>
                                                            <p className=''>Item ID</p>
                                                            <p>{item?._id}</p>
                                                        </div>
                                                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                                    </>
                                                }
                                                {item?.name &&
                                                    <>
                                                        <div className='grid grid-cols-2 gap-3 my-2'>
                                                            <p className=''>Item Name</p>
                                                            <p>{item?.name}</p>
                                                        </div>
                                                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                                    </>
                                                }
                                                {item?.category &&
                                                    <>
                                                        <div className='grid grid-cols-2 gap-3 my-2'>
                                                            <p className=''>Category</p>
                                                            <p>{item?.category}</p>
                                                        </div>
                                                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                                    </>
                                                }
                                                {item?.price &&
                                                    <>
                                                        <div className='grid grid-cols-2 gap-3 my-2'>
                                                            <p className=''>Price</p>
                                                            <p>{CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.name || '')}`]}{item?.price}</p>
                                                        </div>
                                                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                                    </>
                                                }
                                                <div className={`mt-2 overflow-hidden transition-max-h ease-out duration-300 ${showDetails ? 'max-h-auto' : 'max-h-0'}`}>
                                                    {keys?.length > 0 &&
                                                        keys?.map((key: any) => {
                                                            return (
                                                                <>
                                                                    <div className='grid grid-cols-2 gap-3 my-2'>
                                                                        <p className=''>{`${key[0].toUpperCase()}${key?.slice(1).toLowerCase()}`}</p>
                                                                        <p className='break-words'>
                                                                            {item?.exchangeDefinition?.asset?.descriptor?.[key] || ''}
                                                                        </p>
                                                                    </div>
                                                                    <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                                                </>
                                                            );
                                                        })
                                                    }
                                                    {item?.exchangeDefinition?.questions?.length > 0 &&
                                                        item?.exchangeDefinition?.questions?.map((item: any) => {
                                                            return (
                                                                <>
                                                                    <div className='grid grid-cols-2 gap-3 my-2'>
                                                                        <p>{item?.question}</p>
                                                                        <p className='break-words'>
                                                                            {(item?.displayValue) ?
                                                                                item?.displayValue :
                                                                                (item?.value) ?
                                                                                    item?.value :
                                                                                    ((item?.answers && item?.answers?.length > 0)
                                                                                        ? item?.answers?.map((answer: { answer: string }, index: number) => `${index ? ', ' : ''}${answer?.answer}`) : 'No answer')
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                                                </>
                                                            );
                                                        })}
                                                </div>
                                                <div className='flex flex-row justify-center my-3'>
                                                    <button className='text-black underline' onClick={() => onToggleDetails(item?._id)}>
                                                        {showDetails ? 'Hide Details' : 'Read More'}
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            </div>
                        )
                    })
                }
                {showSplitModal?.show &&
                    <SplitOrderModal
                        openModal={showSplitModal?.show}
                        orderId={showSplitModal?.orderId || ''}
                        handleCloseModal={() => setShowSplitModal(undefined)}
                    />
                }
            </div>
        </Modal >
    )
}

export default ItemsDetailsModal;