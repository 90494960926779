import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IPayServices } from '../../../interfaces/pay-services/IPayServices';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { ICommissionFees } from '../../../interfaces/commission-fees/ICommissionFees';
import {
    tryToDeleteCommissionFee,
    tryToDownloadCommissionFees,
    tryToFetchCommissionFees
} from '../../../store/brokers/admin/commission-fees/commissionFeesSlice';
import {
    tryToFetchAllPartners,
    tryToFetchPartnerCatalogs
} from '../../../store/brokers/admin/partners/partnersSlice';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToFetchAllPayServices } from '../../../store/brokers/admin/pay-services/payServicesSlice';
import CommissionFeesTable from './tables/CommissionFeesTable';
import CommissionFeeFilters from './details/CommissionFeeFilters';
import CommissionFeeActionsModal from './modals/CommissionFeeActionsModal';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';


const CommissionFees = () => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<ICommissionFees[]>([]);
    const [partners, setPartners] = useState<IPartners[]>();
    const [payServices, setPayServices] = useState<any>();
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [selectedPartner, setSelectedPartner] = useState<any>();
    const [selectedPayService, setSelectedPayService] = useState<any>();
    const [selectedSector, setSelectedSector] = useState<any>()
    const [commissionFeeItemId, setCommissionFeeItemId] = useState<string | null>();
    const [showCommissionFeeModal, setShowCommissionFeeModal] = useState<boolean>(false);
    const [loadingDownloadTemplate, setLoadingDownloadTemplate] = useState<boolean>(false);
    const [commissionFeesData, setCommissionFeesData] = useState<any>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>();
    const paginationData = useAppSelector((state) => state.commissionFees.commissionFees?.data?.page);
    const rowsLoading = useAppSelector((state) => state.commissionFees.commissionsAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state) => state.commissionFees);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Commission Fees')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.commissionFees) {
            const rows =
                state.commissionFees?.data?.elements &&
                    Array.isArray(state.commissionFees?.data.elements)
                    ? state.commissionFees?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.commissionFees]);

    const getCommissionFeeData = async () => {
        const formatData: any = {
            ...paginationState,
        }
        if (selectedPartner) {
            formatData.data = {
                ...formatData.data,
                partnerId: selectedPartner ? selectedPartner?._id : ''
            }
        }
        if (selectedPayService) {
            formatData.data = {
                ...formatData.data,
                payServiceId: selectedPayService ? selectedPayService?._id : ''
            }
        }
        if (selectedSector) {
            formatData.data = {
                ...formatData.data,
                assetTemplateId: selectedSector ? selectedSector?._id : ''
            }
        }
        await dispatch(tryToFetchCommissionFees(formatData));
    }

    useEffect(() => {
        getCommissionFeeData()
    }, [paginationState]);

    const getPartnersAndPayServicesData = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.companyName }))
        setPartners(formatPartners || [])
        const payServicesResponse = await dispatch(tryToFetchAllPayServices()).unwrap()
        const formatPayServices = payServicesResponse && payServicesResponse?.data && payServicesResponse?.data?.length > 0 && payServicesResponse?.data?.map((service: IPayServices) => ({ ...service, label: service?.displayName, value: service?._id, name: service?.displayName }))
        setPayServices(formatPayServices || [])
    }

    useEffect(() => {
        getPartnersAndPayServicesData()
        dispatch(tryToFetchAllAssetsTemplates());
    }, []);

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const onGetPartnerSectors = async () => {
        const response: any = await dispatch(tryToFetchPartnerCatalogs(selectedPartner?.username || '')).unwrap()
        if (response?.data) {
            const formatSectors = response?.data?.length > 0 && response?.data?.map((category: any) => ({ ...category, label: category?.displayName, name: category?.displayName, value: category?._id }))
            setSectors(formatSectors || [])
        }
    }

    useEffect(() => {
        if (selectedPartner) {
            onGetPartnerSectors()
        }
    }, [selectedPartner])

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const onDeleteCommissionFee = async () => {
        try {
            await dispatch(tryToDeleteCommissionFee(commissionFeeItemId || ''));
            setShowToast({
                type: 'success',
                message: 'Commission Fee successfully deleted',
            });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setCommissionFeeItemId(undefined);
        setShowDeleteModal(false);
    };

    const onAddCommissionFee = (id?: string) => {
        if (id) {
            setCommissionFeeItemId(id);
        }
        setShowCommissionFeeModal(true);
    };

    const onCloseCommissionFeeModal = () => {
        setCommissionFeeItemId(null);
        setShowCommissionFeeModal(false);
    };

    const onSaveCommissionChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        getCommissionFeeData()
    };

    const onDeleteCommissionRequest = (id: string) => {
        setShowDeleteModal(true);
        setCommissionFeeItemId(id);
    };

    const onSelectFilter = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'partner':
                    setSelectedPartner(value)
                    break;
                case 'payService':
                    setSelectedPayService(value)
                    break;
                case 'sector':
                    setSelectedSector(value)
                    break;
            }
        }
        onResetPaginationState()
    }

    const onClearAllFilters = () => {
        setSelectedPartner(undefined)
        setSelectedPayService(undefined)
        setSelectedSector(undefined)
        onResetPaginationState()
    }

    const onDownloadCommissionFees = async () => {
        if (loadingDownloadTemplate) {
            return;
        }
        setLoadingDownloadTemplate(true)
        try {
            const response: any = await dispatch(tryToDownloadCommissionFees()).unwrap()
            setCommissionFeesData(response || [])
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
            setLoadingDownloadTemplate(false)
        }
    }

    useEffect(() => {
        if (commissionFeesData && loadingDownloadTemplate) {
            csvLink.current.link.click()
        }
        setLoadingDownloadTemplate(false)
    }, [commissionFeesData])

    return (
        <div>
            <div>
                <p className='page-title'>Commission Fees</p>
            </div>
            <div>
                <CommissionFeeFilters
                    partners={partners}
                    sectors={sectors}
                    payServices={payServices}
                    selectedPayService={selectedPayService}
                    selectedPartner={selectedPartner}
                    selectedSector={selectedSector}
                    onSelectFilter={onSelectFilter}
                />
            </div>
            <div className='flex flex-row flex-wrap justify-end'>
                <Button
                    label={'Clear all filters'}
                    dataQa={'clear-all-filters'}
                    className={'btn-primary-text-underline flex flex-row justify-end bg-transparent mr-2 mb-1'}
                    onClickButton={onClearAllFilters}
                />
                <Button
                    label={'Add New Commission Fee'}
                    dataQa={'add-new-commission-fee'}
                    className={`${(!accessControl || pageAccess?.actions?.['Add New Commission Fee']) ? 'btn-main' : 'btn-main-disable'} mr-2 mb-1`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Commission Fee']) && onAddCommissionFee()}
                />
                <Button
                    label={loadingDownloadTemplate ? 'Loading Data...' : 'Download Commission Fees'}
                    dataQa={loadingDownloadTemplate ? 'loading-data...' : 'download-commission-fees'}
                    className={(!accessControl || pageAccess?.actions?.['Download Commission Fees']) ? 'btn-main mb-1' : 'btn-main-disable mb-1'}
                    onClickButton={() => ((!accessControl || pageAccess?.actions?.['Download Commission Fees']) && !loadingDownloadTemplate) && onDownloadCommissionFees()}
                />
                <CSVLink
                    ref={csvLink}
                    data={(commissionFeesData && commissionFeesData?.length > 0) ? commissionFeesData?.filter((item: any, idx: number) => idx > 0) || [] : []}
                    headers={(commissionFeesData && commissionFeesData?.length > 0) ? commissionFeesData?.[0] || [] : []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={'Commission-fees.csv'}
                ></CSVLink>
            </div>
            <div className='my-5 relativ'>
                {(rows?.length > 0 || rowsLoading) ?
                    <CommissionFeesTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onDeleteCommissionFee={onDeleteCommissionRequest}
                        onEditCommissionFee={onAddCommissionFee}
                    />
                    : <EmptyContainer
                        text={'No Commission Fee added yet.'}
                        showImage={true}
                    />
                }
            </div>
            <CommissionFeeActionsModal
                partners={partners}
                sectors={sectors}
                payServices={payServices}
                commissionFeeItemId={commissionFeeItemId}
                openActionsModal={showCommissionFeeModal}
                handleCloseModal={onCloseCommissionFeeModal}
                onSaveChanges={onSaveCommissionChanges}
            />
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeleteCommissionFee}
            />
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
        </div>
    );
};

export default CommissionFees;
