import React from 'react';
import { GRADES_ITEMS } from '../../../../utils/constants/grades';
import Loading from '../../../../shared/Loading';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';


interface IChangePricesForm {
    items: Array<any>;
    templateId?: string;
    filterValues: any;
    filterErrors?: any;
    loadingValues: boolean;
    loadingFields: boolean;
    loadingOptions?: any;
    assetTemplateSelected?: any;
    filteredFields?: any;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onChangeInput: (value: string | null | undefined, type: string) => void;
    onChangeSearchAssetFields: (e: any, type?: string) => void;
    onMenuCloseAfterSearch: (type?: string) => void;
}

const ChangePricesForm = ({
    items,
    filterValues,
    templateId,
    filterErrors,
    loadingValues,
    loadingFields,
    loadingOptions,
    filteredFields,
    onChangeSelectedValue,
    onChangeSearchAssetFields,
    onMenuCloseAfterSearch
}: IChangePricesForm) => {
    const findTemplateFields = items && Object.keys(items)
    return (
        <div>
            {templateId && <>
                <p className='text-primary-light font-bold text-sm mb-4'>Fields</p>
                <div className='my-2 grid grid-cols-2 gap-4 mb-[30px] content-start'>
                    {!loadingValues && findTemplateFields && findTemplateFields?.length > 0 ? findTemplateFields?.map((field: any, index: number) => {
                        let values: any = items?.[field];
                        const findLoadingOptions = !!loadingOptions?.[field]
                        const findError = filterErrors && filterErrors?.[field]
                        const filteredFieldsFind = filteredFields?.[field]
                        const gradeSelected = (field || '').toLowerCase() === 'grade' && GRADES_ITEMS?.find((grade) => grade?.value === filterValues?.[field])
                        if ((field || '').toLowerCase() === 'grade') {
                            values =
                                items?.[field] &&
                                items?.[field]?.length > 0 &&
                                items?.[field]?.map((value: string) => {
                                    const findGrade = GRADES_ITEMS?.find((grade) => grade?.value === value)
                                    return { value, label: findGrade?.name || value }
                                })
                        } else {
                            values = filteredFieldsFind ?
                                filteredFieldsFind?.length > 0 &&
                                filteredFieldsFind?.map((value: string) => {
                                    return { value: value, label: value };
                                })
                                :
                                items?.[field] &&
                                items?.[field]?.length > 0 &&
                                items?.[field]?.map((value: string) => {
                                    return { value: value, label: value };
                                });
                        }
                        return (
                            <>
                                <AsyncSelectCheckbox
                                    key={index}
                                    isLoading={(loadingFields && !filterValues?.[field]) || false}
                                    name={field && `${field?.[0].toUpperCase()}${field?.slice(1).toLowerCase()}`}
                                    placeholder={(field || '').toLowerCase() === 'grade' ? gradeSelected ? gradeSelected?.name : filterValues?.[field] || '' : (filterValues?.[field] || '')}
                                    options={values}
                                    dataQa={filterValues?.[field] ===  undefined ? field : filterValues?.[field]}
                                    dynamicOptions={true}
                                    loadingOptions={findLoadingOptions}
                                    selectedOption={filterValues?.[field]}
                                    selectStyle={'max-w-[200px]'}
                                    onMenuClose={onMenuCloseAfterSearch}
                                    onChangeValue={onChangeSearchAssetFields}
                                    onChangeSelectedOption={onChangeSelectedValue}
                                    uniqueName={field || ''}
                                    error={findError}
                                />
                            </>
                        )
                    })
                        : <div className='flex flex-rows col-span-4 justify-center items-center my-7'>
                            <Loading />
                        </div>
                    }
                </div>
            </>
            }
        </div>
    )
}

export default ChangePricesForm;