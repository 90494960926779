import { AxiosResponse } from 'axios';
import { OFFER_API_URL } from '../../../../config';
import offersAxios from '../../../../utils/axios/offers.axios';
import { PaginatedComparisonSites } from '../../../../interfaces/comparison-sites/paginatedStore.type';

const tryToFetchComparisonSites = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedComparisonSites>> => {
    const url = `${OFFER_API_URL}/comparison-site/all?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedComparisonSites>(url, data);
};

const tryToFetchSingleComparisonSite = (
    pageNumber: number,
    pageSize: number,
    name: any,
): Promise<AxiosResponse<PaginatedComparisonSites>> => {
    const url = `${OFFER_API_URL}/comparison-site/${name}?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedComparisonSites>(url);
};

export const ComparisonSitesService = {
    tryToFetchComparisonSites,
    tryToFetchSingleComparisonSite,
};
