import React from 'react';
import { INVENTORY_FILTERS } from '../../../../utils/constants/inventory-filter-values';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import { IPayServices } from '../../../../interfaces/pay-services/IPayServices';
import { IOrderCounts } from '../../../../interfaces/orders/IOrderCounts';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';


interface ICashoutFilters {
    brokerCategories?: Array<any>;
    activeCategory?: string;
    selectedSource?: ISelectedValue;
    payServices?: IPayServices[];
    partners?: IPartners[];
    warehouses?: IWarehouse[];
    selectedBroker?: ISelectedValue;
    selectedWarehouse?: ISelectedValue;
    selectedSplited?: ISelectedValue;
    showWarehouse?: boolean;
    hideSource?: boolean;
    ordersCount?: IOrderCounts[];
    onClickCategory: (id: string) => void;
    onClickBrokerFilter: (value: ISelectedValue, type?: string) => void;
    onClickSourceFilter: (value: ISelectedValue, type?: string) => void;
    onClickWarehouseFilter: (value: ISelectedValue, type?: string) => void;
    onClickSplitedFilter?: (value: ISelectedValue, type?: string) => void;
}

const CashoutFilters = ({
    brokerCategories,
    warehouses,
    selectedSource,
    activeCategory,
    selectedSplited,
    payServices,
    partners,
    hideSource,
    selectedBroker,
    selectedWarehouse,
    showWarehouse,
    ordersCount,
    onClickCategory,
    onClickBrokerFilter,
    onClickSourceFilter,
    onClickWarehouseFilter,
    onClickSplitedFilter,
}: ICashoutFilters) => {
    return (
        <div>
            <div className='mb-10'>
                <p className='text-primary-light font-bold text-sm mt-4 mb-2'>
                    Categories
                </p>
                <div className='my-2 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 gap-4'>
                    <Button
                        label={<span className={`${activeCategory === 'allCategories' ? 'text-[#202020]' : 'text-[#8a8a8e]'} text-[15px]`}>All Categories</span>}
                        dataQa={'all-categories'}
                        className={`${activeCategory === 'allCategories' ? 'btn-categories-list' : 'btn-noactive-categories-list border'} !min-w-[70px] text-sm`}
                        onClickButton={() => onClickCategory('allCategories')}
                    />
                    {(brokerCategories && brokerCategories?.length > 0) && brokerCategories?.map((item, index) => {
                        let buttonClassName = 'btn-noactive-categories-list border';
                        const { totalReceived } = ordersCount?.find((count) => count.category.code === item.name) || {};
                        if (activeCategory === item?.name) buttonClassName = 'btn-categories-list';
                        else buttonClassName = 'btn-noactive-categories-list border';
                        return (
                            <Button
                                key={index}
                                label={
                                    <>
                                        <span className={`${activeCategory === item?.name ? 'text-[#202020]' : 'text-[#8a8a8e]'} text-[15px]`}>{item?.label}</span>
                                        {totalReceived ?
                                            <span className={`ml-auto shrink-0 rounded-full !text-[12px] p-1 text-xs flex justify-center items-center ${activeCategory === item?.name ? 'text-[#202020]' : 'text-[#8a8a8e]'}`}>
                                                {totalReceived}{' '}
                                            </span>
                                            : ''
                                        }
                                    </>
                                }
                                dataQa={`${item?.label?.toLowerCase().replaceAll(' ', '-')}`}
                                className={`${buttonClassName} !min-w-[70px] text-sm relative`}
                                onClickButton={() => onClickCategory(item?.name)}
                                icon={
                                    item.enabledIcon ?
                                        <div className={'w-[23px] h-[23px]'}>
                                            <img src={activeCategory === item?.name ? item.enabledIcon : item.disabledIcon} className={'max-w-[100%] max-h-[100%] min-w-[100%] min-h-[100%] object-contain'} />
                                        </div>
                                        : ''
                                }
                            />
                        );
                    })}
                </div>
            </div>
            <div className='grid grid-cols-4 gap-4'>
                <SelectCheckbox
                    name='Brokers'
                    dataQa={'broker-filter'}
                    placeholder={''}
                    options={partners || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                    onChangeSelectedOption={onClickBrokerFilter}
                    selectedOption={selectedBroker}
                    uniqueName={'broker'}
                />
                {showWarehouse &&
                    <SelectCheckbox
                        name='Warehouse'
                        dataQa={'warehouse-filter'}
                        placeholder={''}
                        options={warehouses || []}
                        selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                        onChangeSelectedOption={onClickWarehouseFilter}
                        selectedOption={selectedWarehouse}
                        uniqueName={'warehouse'}
                    />
                }
                {!hideSource &&
                    <SelectCheckbox
                        name='Order Source'
                        dataQa={'open-source-filter'}
                        placeholder={''}
                        options={payServices || []}
                        selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                        onChangeSelectedOption={onClickSourceFilter}
                        selectedOption={selectedSource}
                        uniqueName={'payService'}
                    />
                }
                {!!onClickSplitedFilter &&
                    <SelectCheckbox
                        name='Filter By Splited Orders'
                        dataQa={'open-source-filter'}
                        placeholder={''}
                        options={INVENTORY_FILTERS || []}
                        selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                        onChangeSelectedOption={onClickSplitedFilter}
                        selectedOption={selectedSplited}
                        uniqueName={'splitOrders'}
                    />
                }
            </div>
        </div>
    );
};

export default CashoutFilters;
