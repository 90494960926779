import React from 'react';
import Button from '../../../../shared/button';
import SelectCheckbox from '../../../../shared/select-checkbox';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';
import WhiteContainer from '../../../../shared/white-container';


interface IMultiplePriceColumnsComp {
    fileColumns?: any;
    categoryColumns?: any;
    selectedType?: any;
    pricingMappedCategories?: any;
    categoryFieldValues?: any;
    filteredFields?: any;
    loadingOptions?: any;
    onChangeSelectedPriceFileColumn: (e: any, type?: string, index?: any) => void;
    onChangeSelectedFieldValue: (e: any, type?: string, index?: any) => void;
    onAddingNewPricingColumn: () => void;
    onDeletePricingIndexItem: (index: any) => void;
    onChangeSearchFieldValues: (e: any, type?: string) => void;
    onMenuCloseAfterSearch: (type?: string) => void;
    onClickMultiQuantity: (index: any) => void;
}

const MultiplePriceColumnsComp = ({
    fileColumns,
    categoryColumns,
    selectedType,
    pricingMappedCategories,
    categoryFieldValues,
    filteredFields,
    loadingOptions,
    onClickMultiQuantity,
    onChangeSelectedPriceFileColumn,
    onChangeSelectedFieldValue,
    onAddingNewPricingColumn,
    onDeletePricingIndexItem,
    onChangeSearchFieldValues,
    onMenuCloseAfterSearch
}: IMultiplePriceColumnsComp) => {
    return (
        <div className='min-w-[100%]'>
            <div className='my-4 flex flex-row items-center'>
                <img src={'/assets/shared/info-circle.svg'} className={'w-[15px] mr-2 object-contain'} />
                <p className='text-primary-light'>This step will override the selected price column in the table above.</p>
            </div>
            {[...Array(pricingMappedCategories?.length || 1)].map((x, i) => {
                const findSelected = pricingMappedCategories && pricingMappedCategories?.length > 0 && pricingMappedCategories.find((item: any, idx: number) => idx === i)
                return (
                    <WhiteContainer key={i} containerStyle={'w-fit shadow-xl h-fit flex flex-col mb-7 min-w-[100%]'} type='rounded'>
                        <div className={''}>
                            {selectedType?.value === 'WHOLESALE_PRICING' &&
                                <div className='flex flex-row justify-start items-center mb-6 min-w-[100%]'>
                                    <div className='flex items-center mr-2'>
                                        <input
                                            onChange={() => onClickMultiQuantity(i)}
                                            checked={findSelected?.showExtras}
                                            id='checkbox-all'
                                            type='checkbox'
                                            data-qa={'checkbox'}
                                            className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                        />
                                        <label htmlFor='checkbox-all' className='sr-only'>
                                        </label>
                                    </div>
                                    <p className='text-[#a4a4a4]'>
                                        Number of items is linked with price column
                                    </p>
                                </div>
                            }
                            <div className='flex flex-row align-center mb-4'>
                                <SelectCheckbox
                                    name={'Your Field'}
                                    dataQa={findSelected?.newField?.label === undefined ? 'your-field' : findSelected?.newField?.label}
                                    containerStyle={'w-auto min-w-[250px]'}
                                    placeholder={'Select Field'}
                                    selectedOption={findSelected?.newField || undefined}
                                    options={fileColumns || []}
                                    uniqueName={'file'}
                                    index={i}
                                    onChangeSelectedOption={onChangeSelectedPriceFileColumn}
                                />
                                <SelectCheckbox
                                    name={'Wing Fields'}
                                    dataQa={'wing-field'}
                                    placeholder={'Select Fields'}
                                    containerStyle={'ml-6 w-auto min-w-[350px]'}
                                    selectedOption={findSelected?.categories || undefined}
                                    options={categoryColumns || []}
                                    uniqueName={'category'}
                                    index={i}
                                    multiple={true}
                                    onChangeSelectedOption={onChangeSelectedPriceFileColumn}
                                />
                                {findSelected?.showExtras &&
                                    <SelectCheckbox
                                        name={'Number Of Items Field'}
                                        dataQa={'nr-of-items-field'}
                                        placeholder={'Select Fields'}
                                        containerStyle={'ml-6 w-auto min-w-[350px]'}
                                        selectedOption={findSelected?.extras || undefined}
                                        options={fileColumns || []}
                                        uniqueName={'extras'}
                                        index={i}
                                        onChangeSelectedOption={onChangeSelectedPriceFileColumn}
                                    />
                                }
                                {!(i === 0 && (pricingMappedCategories?.length === 0 || pricingMappedCategories?.length === 1)) && <div
                                    onClick={() => onDeletePricingIndexItem(i)}
                                    data-qa={'delete-button'}
                                    className='self-start ml-auto cursor-pointer'>
                                    <img src={'/assets/shared/trash-gray.svg'} className={'object-contain w-[20px] hover:w-[22px]'} />
                                </div>
                                }
                            </div>
                            {findSelected?.categories && findSelected?.categories?.length > 0 &&
                                <div className=' grid grid-cols-4 gap-4'>
                                    {findSelected?.categories?.map((item: any, idx: number) => {
                                        const filteredFieldsFind = (filteredFields && filteredFields?.index === i) && filteredFields?.[item?.name]
                                        const areOptionsLoading = loadingOptions?.index === i && loadingOptions?.[item?.name]
                                        const findCategoryValues = categoryFieldValues && categoryFieldValues?.length > 0 && categoryFieldValues?.find((next: any, idx: number) => idx === i)
                                        const values = filteredFieldsFind ?
                                            filteredFieldsFind?.length > 0 &&
                                            filteredFieldsFind?.map((value: string) => {
                                                return { value: value, label: value };
                                            })
                                            :
                                            findCategoryValues?.[item?.name] &&
                                            findCategoryValues?.[item?.name]?.length > 0 &&
                                            findCategoryValues?.[item?.name]?.map((value: string) => {
                                                return { value: value, label: value };
                                            });
                                        return (
                                            <div key={idx}>
                                                <AsyncSelectCheckbox
                                                    name={`Select ${item?.name} value`}
                                                    isLoading={false}
                                                    dataQa={item?.name === undefined ? 'select-value' : item?.name}
                                                    placeholder={findSelected?.selectedValues?.[item?.name] || ''}
                                                    selectedOption={findSelected?.selectedValues?.[item?.name] || undefined}
                                                    options={values || []}
                                                    uniqueName={item?.name}
                                                    loadingOptions={areOptionsLoading && false}
                                                    onChangeValue={onChangeSearchFieldValues}
                                                    onMenuClose={onMenuCloseAfterSearch}
                                                    dynamicOptions={true}
                                                    index={i}
                                                    onChangeSelectedOption={onChangeSelectedFieldValue}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </WhiteContainer>
                )
            })}
            <div className='mt-4'>
                <Button
                    label={'Add New Price Column'}
                    dataQa={'add-new-price-column'}
                    className={'btn-main'}
                    onClickButton={onAddingNewPricingColumn}
                />
            </div>
        </div>

    )
}

export default MultiplePriceColumnsComp;