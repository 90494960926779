import React from 'react';
import Table from '../../../../shared/table';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IFieldsMappingTable {
    columns: any;
    fields?: any;
    mappingFields?: any;
    mappingFieldsErrors?: any;
    onChangeFieldSelected: (value: any, type?: string) => void;
    onClearMappedColumns: () => void;
}

const FieldsMappingTable = ({
    columns,
    fields,
    mappingFields,
    mappingFieldsErrors,
    onChangeFieldSelected,
}: IFieldsMappingTable) => {
    return (
        <Table
            headerColsStyle='border-r-2 last:border-r-0 pl-3 pr-6'
            columns={columns && columns?.length > 0 ? columns?.map((item: any) => item?.name) : []}
            hidePagination={true}
        >
            <tr className='bg-white '>
                {columns && columns?.length > 0 && columns?.map((item: any, idx: number) => {
                    const findSelected = (mappingFields && mappingFields?.length > 0) && mappingFields?.find((field: any) => field?.newName === item?.name)
                    const errorFound = mappingFieldsErrors?.[item?.name]
                    return (
                        <td data-qa={'select-field'} key={idx} className='p-2 align-top border-r-2 last:border-r-0'>
                            <SelectCheckbox
                                placeholder='Select Field'
                                name=''
                                dataQa={item?.name === undefined ? 'select-button' : item?.name }
                                multiple={true}
                                showFixed={true}
                                containerStyle='max-w-[200px] w-[200px]'
                                onChangeSelectedOption={onChangeFieldSelected}
                                uniqueName={item?.name}
                                options={fields || []}
                                error={errorFound}
                                selectedOption={findSelected?.selected || undefined}
                            />
                        </td>
                    )
                })}
            </tr>
        </Table >
    )
}

export default FieldsMappingTable;