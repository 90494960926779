import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminGuard } from '../../../guards/RolesGuard';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToFetchAllPartners } from '../../../store/brokers/admin/partners/partnersSlice';
import {
    tryToEnableDisableCategoryMapping,
    tryToFetchCategoryMappingsWithoutPagination
} from '../../../store/brokers/shared-endpoints/category-mappings/categoryMappingsSlice';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import WholesaleItemLoading from './loading/LoadingWholesaleItem';
import AddNewMappingComp from './details/AddNewMappingComp';
import MappingFilters from './details/MappingFilters';
import MappingItem from './details/MappingItem';
import Toast from '../../../shared/toast';
import SearchWithButton from '../../../shared/search-with-button';


const CategoryMapping = () => {
    const navigation = useNavigate()
    const dispatch = useAppDispatch();
    const isAdmin = AdminGuard();
    const [mappings, setMappings] = useState<any>()
    const [search, setSearch] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>()
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>()
    const [selectedCategory, setSelectedCategory] = useState<any>()
    const [selectedStatus, setSelectedStatus] = useState<any>()
    const [selectedEnable, setSelectedEnable] = useState<any>()
    const [selectedType, setSelectedType] = useState<any>()
    const [selectedPartner, setSelectedPartner] = useState<any>()
    const [pageAccess, setPageAccess] = useState<any>()
    const [partners, setPartners] = useState<any>()
    const state = useAppSelector((state) => state.categoryMapping);
    const loading = useAppSelector((state) => state.categoryMapping?.mappingsAreLoading)
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const [userProfileMutation] = useUserProfileMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Category Mapping')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.categoryMappings) {
            if(isAdmin){
                const formatMapping = (state?.categoryMappings || [])?.filter((item: any)=> ((item?.name)?.toLowerCase() === 'default' &&  (item?.broker?.companyName || item?.broker?.name) === 'wingpay') || (item?.name).toLowerCase() !== 'default')
                setMappings(formatMapping || [])

            }else{
                setMappings(state?.categoryMappings || [])
            }
        }
    }, [state?.categoryMappings])

    const getBrokerSectors = async () => {
        const response = await userProfileMutation(null).unwrap()
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : []
        setBrokerCategories(formatSectors || [])
    }

    const onGetMappingData = async () => {
        let filters: any = {}
        if (selectedCategory) {
            filters = {
                ...filters || {},
                assetTemplateId: selectedCategory?.id || selectedCategory?.value,
            }
        }
        if (selectedEnable) {
            filters = {
                ...filters || {},
                enabled: selectedEnable?.value === 'Yes',
            }
        }
        if (selectedStatus) {
            filters = {
                ...filters || {},
                status: selectedStatus?.value,
            }
        }
        if (selectedPartner && isAdmin) {
            filters = {
                ...filters || {},
                brokerId: selectedPartner?.value,
            }
        }
        if (selectedType) {
            filters = {
                ...filters || {},
                pricingType: selectedType?.value,
            }
        }
        if (search) {
            filters = {
                ...filters || {},
                search: search,
            }
        }
        await dispatch(tryToFetchCategoryMappingsWithoutPagination(filters))
    }

    const getPartnersData = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.companyName }))
        setPartners(formatPartners || [])
    }

    useEffect(() => {
        if (stateAssets.allAssetTemplates && isAdmin) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setBrokerCategories(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    useEffect(() => {
        if (isAdmin) {
            getPartnersData()
            dispatch(tryToFetchAllAssetsTemplates());
        } else {
            getBrokerSectors()
        }
        onGetMappingData()
    }, [])

    useEffect(() => {
        onGetMappingData()
    }, [search])

    const onChangeStatusMapping = async (active: boolean, id: string) => {
        await dispatch(tryToEnableDisableCategoryMapping({ active: !active, id }))
    }

    const onCreateEditMapping = (id?: string) => {
        navigation(`/category-mapping/create${id ? `/${id}` : ''}`)
    }

    const onCopyMapping = (id: string) => {
        navigation(`/category-mapping/create/copy/${id}`)
    }

    useEffect(() => {
        onGetMappingData()
    }, [selectedCategory, selectedEnable, selectedStatus, selectedType, selectedPartner])

    const onChangeFilterSelected = async (e: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'category':
                    setSelectedCategory(e)
                    break;
                case 'enable':
                    setSelectedEnable(e)
                    break;
                case 'status':
                    setSelectedStatus(e)
                    break;
                case 'type':
                    setSelectedType(e)
                    break;
                case 'partner':
                    setSelectedPartner(e)
                    break;
            }
        }
    }

    const onClearAllFilters = () => {
        setSelectedCategory(undefined)
        setSelectedEnable(undefined)
        setSelectedStatus(undefined)
        setSelectedType(undefined)
        setSelectedPartner(undefined)
        setSearch(undefined)
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
    }

    return (
        <div>
            <div className='flex flex-row justify-between items-center min-w-full mb-10'>
                <p className='page-title'>Category Mapping</p>
            </div>
            <div className='mt-8 mb-4'>
                <MappingFilters
                    sectors={brokerCategories}
                    partners={partners}
                    selectedEnable={selectedEnable}
                    selectedType={selectedType}
                    selectedSector={selectedCategory}
                    selectedStatus={selectedStatus}
                    selectedPartner={selectedPartner}
                    onClearAllFilters={onClearAllFilters}
                    onSelectFilter={onChangeFilterSelected}
                />
            </div>
            <div className='mb-4'>
                <SearchWithButton
                    placeholder='Filter by name'
                    search={search}
                    mainContainerStyle={'!grid-cols-10'}
                    onChangeSearchValue={onChangeSearchValue}
                    buttonLabel={'Clear All Filters'}
                    onClickButton={onClearAllFilters}
                    buttonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end bg-transparent mt-1'}
                />
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 lg:gap-4' id='category-mapping-container'>
                {(!accessControl || pageAccess?.actions?.['Add new mapping']) &&
                    <AddNewMappingComp
                        title={'Add new mapping'}
                        onClickNewMapping={() => onCreateEditMapping()}
                    />
                }
                {loading ?
                    <>
                        <WholesaleItemLoading />
                        <WholesaleItemLoading />
                    </>
                    : <>
                        {mappings && mappings?.length > 0 && mappings?.map((item: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className='cursor-pointer hover:opacity-90'>
                                    <MappingItem
                                        showActions={true}
                                        key={index}
                                        mapping={item}
                                        actions={pageAccess?.actions}
                                        accessControl={accessControl}
                                        onChangeStatusMapping={() => onChangeStatusMapping(item?.enabled || false, item?._id || item?.id || '')}
                                        onEditMapping={() => onCreateEditMapping(item?._id || item?.id || '')}
                                        onCopyMapping={() => onCopyMapping(item?._id || item?.id || '')}
                                    />
                                </div>
                            )
                        })}
                    </>
                }
                {showToast?.message &&
                    <Toast
                        type={showToast?.type}
                        text={showToast?.message}
                        onHandleCloseToast={() => setShowToast(null)}
                    />
                }
            </div>
        </div>
    )
}

export default CategoryMapping;