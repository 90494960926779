export const validatePurchaseFields = (formFieldsData: any, setFormFieldsErrors: any) => {
    let fieldErrors: any
    if (!formFieldsData?.orderCreator) {
        fieldErrors = {
            ...(fieldErrors || {}),
            orderCreator: 'Order Creator is required'
        }
    }
    if (!formFieldsData?.dueDate) {
        fieldErrors = {
            ...(fieldErrors || {}),
            dueDate: 'Due Date is required'
        }
    }
    // if (!formFieldsData?.customerInvoice) {
    //     fieldErrors = {
    //         ...(fieldErrors || {}),
    //         customerInvoice: 'Customer Invoice is required'
    //     }
    // }
    if (!formFieldsData?.stockVat) {
        fieldErrors = {
            ...(fieldErrors || {}),
            stockVat: 'Stock VAT is required'
        }
    }
    if (!formFieldsData?.status) {
        fieldErrors = {
            ...(fieldErrors || {}),
            status: 'Status is required'
        }
    }
    // if (!formFieldsData?.invoiceVat) {
    //     fieldErrors = {
    //         ...(fieldErrors || {}),
    //         invoiceVat: 'Invoice VAT is required'
    //     }
    // }
    // if (!formFieldsData?.bankAccount) {
    //     fieldErrors = {
    //         ...(fieldErrors || {}),
    //         bankAccount: 'Bank Account is required'
    //     }
    // }
    // if (!formFieldsData?.isTempExport && formFieldsData?.isTempExport !== false) {
    //     fieldErrors = {
    //         ...(fieldErrors || {}),
    //         isTempExport: 'Is Temp Export is required'
    //     }
    // }
    if (!formFieldsData?.warranty) {
        fieldErrors = {
            ...(fieldErrors || {}),
            warranty: 'Warranty is required'
        }
    }

    if (fieldErrors) {
        setFormFieldsErrors(fieldErrors)
        return false
    }
    else {
        setFormFieldsErrors(undefined)
        return true
    }
}