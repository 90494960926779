import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Barcode from 'react-barcode';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import { ISingleOrderSchema } from '../../../../interfaces/orders/IOrdersSchema';
import { tryToFetchWarehouses } from '../../../../store/brokers/admin/warehouses/warehousesSlice';
import { tryToAddReturnTrackingNumber } from '../../../../store/brokers/partners/orders/ordersSlice';
import {
    tryToCreateReturnShippingNumber,
    tryToFetchAllPartnerCarriersByMarket,
    tryToFetchCarriers,
} from '../../../../store/brokers/partners/carriers/carriersSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';


interface IReturnTrackingNumberModal {
    openModal: boolean;
    itemId: string;
    orderId: string;
    assetTemplate?: string;
    orderDetails?: ISingleOrderSchema;
    handleCloseModal: () => void;
}

type FormValues = {
    returnTrackingNumber: string;
    carrier: string;
    warehouseId: string;
};

const ReturnTrackingNumberModal = ({
    openModal,
    itemId,
    orderId,
    assetTemplate,
    orderDetails,
    handleCloseModal,
}: IReturnTrackingNumberModal) => {
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>();
    const [carriers, setCarriers] = useState<any>();
    const [returnTrackingNumber, setReturnTrackingNumber] = useState<string>();
    const [returnTrackingNumberGenerated, setReturnTrackingNumberGenerated] = useState<string>();
    const [carrierIsNotIntegrated, setCarrierIsNotIntegrated] = useState<boolean>(false);
    const [generateCarriers, setGenerateCarriers] = useState<any>();
    const [carrierSelected, setCarrierSelected] = useState<any>();
    const [generateCarrierSelected, setGenerateCarrierSelected] = useState<any>();
    const [warehouses, setWarehouses] = useState<Array<any>>();
    const [warehouseSelected, setWarehouseSelected] = useState<any>();
    const [loadingGenerate, setLoadingGenerate] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [invoiceUrl, setInvoiceUrl] = useState<any>();
    const stateWarehouses = useAppSelector((state) => state.warehouses);
    const activeMarket = useAppSelector((state) => state?.partners?.partnerActiveMarket,);

    const {
        reset,
        formState: { errors },
        setValue,
    } = useForm<FormValues>({});

    const onGettingCarriersData = async () => {
        try {
            const response = await dispatch(tryToFetchCarriers(null)).unwrap();
            const formatCarriers = response && response?.length > 0 && response?.map((item: any) => ({ ...item, label: item?.displayName, value: item?.name, name: item?.displayName }));
            setCarriers(formatCarriers || undefined);
        } catch (err) {
            // error here
        }
    };

    const onGetttingPartnerCarriersData = async () => {
        try {
            const responseBrokers = await dispatch(tryToFetchAllPartnerCarriersByMarket({ marketId: activeMarket?.market?._id, type: 'outbound' })).unwrap();
            const formatBrokersCarriers = responseBrokers && responseBrokers?.length > 0 && responseBrokers.map((item: any) => ({ ...item, name: item?.carrier?.displayName, label: item?.carrier?.displayName, value: item?.carrier?.name }));
            setGenerateCarriers(formatBrokersCarriers || undefined);
        } catch (err) {
            // error here
        }
    };

    useEffect(() => {
        dispatch(tryToFetchWarehouses(null)).unwrap();
    }, []);

    useEffect(() => {
        if (activeMarket) {
            onGetttingPartnerCarriersData();
        }
    }, [activeMarket]);

    useEffect(() => {
        if (itemId) {
            onGettingCarriersData();
        }
    }, [itemId]);

    const onCloseModal = () => {
        reset();
        handleCloseModal();
        setSaveError(undefined);
        setReturnTrackingNumber(undefined);
        setReturnTrackingNumberGenerated(undefined);
        setCarrierIsNotIntegrated(false);
        setGenerateCarrierSelected(undefined);
        setCarrierSelected(undefined);
        setLoadingGenerate(false);
        setLoadingSubmit(false);
    };

    useEffect(() => {
        const formatWarehouses = stateWarehouses.warehouses && stateWarehouses.warehouses?.length > 0 && stateWarehouses.warehouses?.map((warehouse: IWarehouse) => ({ ...warehouse, label: `${warehouse?.address?.street || ''}-${warehouse?.name}`, value: warehouse?._id }));
        const filterWarehouses: any = formatWarehouses && formatWarehouses?.length > 0 && orderDetails?.orderData?.country && formatWarehouses?.filter((warehouse: any) => warehouse?.markets && warehouse?.markets?.find((item: any) => item?.name === orderDetails?.orderData?.country));
        if (filterWarehouses && filterWarehouses?.length === 1) {
            setValue('warehouseId', filterWarehouses?.[0]?.value);
            setWarehouseSelected(filterWarehouses?.[0]);
        }
        setWarehouses(filterWarehouses || []);
    }, [stateWarehouses.warehouses, orderDetails]);

    const onSubmit = async () => {
        setLoadingSubmit(true);
        if ((!carrierIsNotIntegrated && (!returnTrackingNumberGenerated || !generateCarrierSelected)) || (carrierIsNotIntegrated && (!returnTrackingNumber || !carrierSelected))) {
            setSaveError('Please make sure you fill in all the fields to add return tracking number');
            setLoadingSubmit(false);
            return;
        }
        try {
            let formatData: any;
            if (carrierIsNotIntegrated) {
                formatData = {
                    returnTrackingNumber: returnTrackingNumber,
                    carrier: carrierSelected ? carrierSelected?.name || '' : '',
                    carrierId: carrierSelected
                        ? carrierSelected?._id || ''
                        : '',
                    invoiceUrl: invoiceUrl || undefined,
                };
            } else {
                formatData = {
                    returnTrackingNumber: returnTrackingNumberGenerated,
                    carrier: generateCarrierSelected ? generateCarrierSelected?.name || '' : '',
                    carrierId: generateCarrierSelected ? generateCarrierSelected?._id || '': '',
                    invoiceUrl: invoiceUrl || undefined,
                };
            }
            await dispatch(tryToAddReturnTrackingNumber({ itemId, data: formatData })).unwrap();
            onCloseModal();
        } catch (err) {
            setSaveError(`${err}`);
            setLoadingSubmit(false);
        }
    };

    const onGenerateReturnTracking = async () => {
        try {
            let totalWeight: any = 1000;
            if (assetTemplate === 'clothing') {
                totalWeight = Number(orderDetails?.item?.revaluatedDisplayQuestions?.[0]?.value || orderDetails?.item?.exchangeDefinition?.counter?.computedWeight || 1) * 1000;
            }
            const shipmentDataFormat = {
                createShipment: {
                    shipTo: {
                        name: orderDetails?.seller?.name,
                        contactName: orderDetails?.seller?.name,
                        phone: orderDetails?.seller?.phone,
                        email: orderDetails?.seller?.email,
                        address1: orderDetails?.seller?.street,
                        address2: orderDetails?.seller?.addressNo,
                        city: orderDetails?.seller?.city,
                        country: orderDetails?.seller?.country,
                        postalCode: orderDetails?.seller?.postcode,
                        shipperReference: orderId,
                    },
                    shipmentInformation: {
                        shipmentDate: moment(new Date()).format('YYYY-MM-DD'),
                        packages: 1,
                        totalWeight: totalWeight,
                        weightUnitOfMeasure: 'grams',
                        currency: orderDetails?.orderData?.localizedSalePrice?.currencyCode,
                        value: Number(orderDetails?.item?.revaluedPrice || orderDetails?.orderData?.localizedSalePrice?.amount || 0.01).toFixed(2),
                        description: 'wingpro',
                        items: [
                            {
                                itemId: itemId,
                                name: orderDetails?.item?.name,
                                qty: 1,
                                price: Number(orderDetails?.item?.revaluedPrice || orderDetails?.orderData?.localizedSalePrice?.amount || 0.01).toFixed(2),
                                currency: orderDetails?.orderData?.localizedSalePrice?.currencyCode,
                                hsCode: '',
                            },
                        ],
                        serviceCode: activeMarket?.market?.name === 'US' ? 'ups' : 'TSN',
                    },
                },
                labelType: 'Outbound',
                url: true,
                qrCode: false,
                barcode: true,
                orderId: orderId,
                itemIds: [itemId],
                sourceRequesting: 'wingpro',
                carrierLocation: {
                    warehouseId: warehouseSelected?.value,
                    country: orderDetails?.orderData?.country,
                    carrier: generateCarrierSelected ? generateCarrierSelected?.value || '' : '',
                },
            };
            const response = await dispatch(tryToCreateReturnShippingNumber(shipmentDataFormat)).unwrap();
            setInvoiceUrl(response?.invoiceUrl || undefined);
            setReturnTrackingNumberGenerated(response?.trackingNumber || undefined);
            // const formatData = {
            //     returnTrackingNumber: ,
            //     carrier: findCarrier ? findCarrier?.name || '' : '',
            //     carrierId: findCarrier ? findCarrier?._id || '' : ''
            // }
            // await dispatch(tryToAddReturnTrackingNumber({ itemId, data: formatData, returnShippingLabel: response?.imageUrl })).unwrap()
            // onCloseModal()
            setSaveError(undefined);
        } catch (err) {
            setSaveError(`${err}`);
        }
        setLoadingGenerate(false);
    };

    useEffect(() => {
        if (generateCarrierSelected && warehouseSelected) {
            onGenerateReturnTracking();
        }
    }, [generateCarrierSelected, warehouseSelected]);

    const onChangeSelectedOption = (e: any) => {
        setCarrierSelected(e || undefined);
    };

    const onChangeSelectedOptionGenerate = (e: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'carrier':
                    setGenerateCarrierSelected(e || undefined);
                    setLoadingGenerate(true);
                    break;
                case 'warehouse':
                    setWarehouseSelected(e || undefined);
                    break;
            }
        }
    };

    const onClickCarrierNotCreated = () => {
        setCarrierIsNotIntegrated(!carrierIsNotIntegrated);
    };

    const onChangeReturnTrackingNumber = (e: any) => {
        setReturnTrackingNumber(e || undefined);
    };

    return (
        <Modal open={openModal} showInRight={true} onClose={onCloseModal}>
            <div className={'p-2 min-w-[400px] max-w-[450px] mx-10'}>
                <p className='mt-2 mb-1 !text-[18px] mr-10'>
                    Return Tracking Details
                </p>
                <div className='mt-2 mb-5 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                {saveError && <Error text={saveError} />}
                <div className='mt-3 mb-4 bg-[#f8f9fc] rounded px-4 py-3'>
                    <p className='font-medium text-sm mb-5 mt-3'>
                        Confirm the Carrier and Create Return Tracking:
                    </p>
                    <div>
                        {warehouses && warehouses?.length > 1 ? (
                            <SelectCheckbox
                                selectedOption={warehouseSelected}
                                key={1}
                                disabled={carrierIsNotIntegrated}
                                placeholder='Warehouse'
                                containerStyle='mb-1 max-w-[90%]'
                                onChangeSelectedOption={(e) => onChangeSelectedOptionGenerate(e, 'warehouse')}
                                options={warehouses || []}
                                dataQa={'warehouse-selected'}
                            />
                        ) : (
                            <div className='my-4'>
                                <p className='font-medium'>
                                    Warehouse: {warehouseSelected?.name}
                                </p>
                            </div>
                        )}
                        <SelectCheckbox
                            selectedOption={generateCarrierSelected}
                            key={1}
                            disabled={carrierIsNotIntegrated}
                            placeholder='Carrier'
                            containerStyle='mb-1 max-w-[90%]'
                            isLoading={loadingGenerate}
                            onChangeSelectedOption={(e) => onChangeSelectedOptionGenerate(e, 'carrier')}
                            options={generateCarriers || []}
                            dataQa={'carrieer-selected'}
                        />
                        <Input
                            placeholder='Return Tracking Number'
                            dataQa={'return-tracking-number'}
                            showValue={true}
                            inputValue={returnTrackingNumberGenerated}
                            disabled={true}
                            inputStyle={'max-w-[90%]'}
                            error={errors.returnTrackingNumber?.message}
                        />
                    </div>
                </div>
                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                <div className='mt-3 mb-4 bg-[#f8f9fc] rounded px-4 py-3'>
                    <div className='flex flex-row justify-start items-center my-4 min-w-[100%]'>
                        <div className='flex items-center mr-2'>
                            <p className='font-semibold mr-1 text-[16px]'>
                                Carrier is not Integrated?
                            </p>
                            <input
                                onChange={onClickCarrierNotCreated}
                                checked={carrierIsNotIntegrated}
                                id='checkbox-all'
                                type='checkbox'
                                data-qa={'checkbox'}
                                className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                            />
                            <label htmlFor='checkbox-all' className='sr-only'>
                                checkbox
                            </label>
                        </div>
                    </div>
                    {carrierIsNotIntegrated && (
                        <>
                            <SelectCheckbox
                                selectedOption={carrierSelected}
                                key={1}
                                placeholder='Carrier'
                                dataQa={'carrier-selected'}
                                containerStyle='mb-1 max-w-[90%]'
                                onChangeSelectedOption={(e) => onChangeSelectedOption(e)}
                                options={carriers || []}
                            />
                            <Input
                                placeholder='Return Tracking Number'
                                dataQa={'return-tracking-number'}
                                showValue={true}
                                inputValue={returnTrackingNumber}
                                onChangeInput={onChangeReturnTrackingNumber}
                                inputStyle={'max-w-[90%]'}
                                error={errors.returnTrackingNumber?.message}
                            />
                        </>
                    )}
                </div>
                <div className='mt-3 mb-4 bg-[#f8f9fc] rounded px-4 py-3'>
                    <div className='flex flex-col mb-3 mt-5'>
                        <p className='text-[14px] font-medium mb-3 mr-5'>
                            Alternatively in the Broker App scan barcode below
                            to open the order and scan the label of the return
                            tracking and this will automate this step for you.
                        </p>
                        <div className='flex flex-col items-center'>
                            <Barcode value={itemId || 'null'} width={1.4} height={60} fontSize={14} displayValue={true} textAlign={'center'} />
                        </div>
                    </div>
                </div>
                <div className='min-w-full flex flex-row justify-center'>
                    <Button
                        label={'Add Tracking Number'}
                        dataQa={'add-tracking-number'}
                        className={`${loadingSubmit ? 'btn-main-disable' : 'btn-main'} my-3 min-w-[130px] !shadow-none`}
                        onClickButton={() => !loadingSubmit && onSubmit()}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ReturnTrackingNumberModal;
