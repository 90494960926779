import React from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPricingReview } from '../../../../interfaces/pricing-review/IPricingReview';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';

const tableColumns = ['Offer id', 'Item name', 'Partner', 'Price', 'Price per unit', 'Number of items', 'Status', 'Actions']

interface IPricingReviewsTable {
    rows: IPricingReview[];
    paginationData?: IPage;
    markets?: IAllMarkets[];
    accessControl?: IAccessControl;
    actions?: IPageActions;
    onShowChangeStatus: (item: IPricingReview) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const PricingReviewsTable = ({
    rows,
    paginationData,
    markets,
    accessControl,
    actions,
    onShowChangeStatus,
    setPaginationState
}: IPricingReviewsTable) => {

    const onFormatOrderStatus = (status: string) => {
        switch (status) {
            case 'in_review':
                return { name: 'IN REVIEW', color: 'bg-[#a7eae0]' }
            case 'accepted':
                return { name: 'ACCEPTED', color: 'bg-[#a7eae0]' }
            case 'rejected':
                return { name: 'REJECTED', color: 'bg-[#ff3b30]' }
            default:
                return { name: 'NOT SET', color: 'bg-[#dffffa]' }
        }
    }

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
        >
            {rows?.length > 0 && rows?.map((row: IPricingReview, idx: number) => {
                const findEnableOffer = row?.settings && row?.settings?.length > 0 && row?.settings?.find((offer) => offer?.enabled)
                const status = findEnableOffer && findEnableOffer?.status && onFormatOrderStatus(findEnableOffer?.status)
                const currencyName = markets && markets?.length > 0 && markets?.find((market) => market?._id === row?.marketId)
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?.offerId}</td>
                        <td className='px-6 py-4'>{row?.offer?.display_name}</td>
                        <td className='px-6 py-4'>{row?.partner?.name || ''}</td>
                        <td className='px-6 py-4'>{currencyName && CURRENCY_SYMBOLS?.[`${(currencyName?.currency?.name || '')}`]}{row?.offer?.quantity}</td>
                        <td className='px-6 py-4'>{currencyName && CURRENCY_SYMBOLS?.[`${(currencyName?.currency?.name || '')}`]}{findEnableOffer ? findEnableOffer?.amount : ''}</td>
                        <td className='px-6 py-4'>{findEnableOffer ? findEnableOffer?.numberOfItems : ''}</td>
                        <td className='px-6 py-4'><div className={`${status ? status?.color : ''} flex flex-row justify-center text-center text-red px-1 min-w-[150px] text-black text-sm py-1 font-medium rounded-2xl`}>{status && status?.name}</div></td>
                        <td className='px-6 py-4'>
                            <Button
                                label={'Change Status'}
                                dataQa={'change-status'}
                                className={(findEnableOffer && findEnableOffer?.status === 'in_review' && (!accessControl || actions?.['Change Status'])) ? 'btn-filters' : 'btn-noactive-filters'}
                                onClickButton={() => (findEnableOffer && findEnableOffer?.status === 'in_review' && (!accessControl || actions?.['Change Status'])) && onShowChangeStatus(row)}
                            />
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default PricingReviewsTable;