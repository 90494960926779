export const VAT_TYPE = [
    { name: 'EU_margin', label: 'EU Margin', value: 'EU Margin' },
    { name: 'UK_margin', label: 'UK Margin', value: 'UK Margin' },
    { name: 'Standard', label: 'Standard', value: 'Standard' },
    { name: 'Zero Rated', label: 'Zero Rated', value: 'Zero Rated' },
];

export const VAT_TYPE_ITEMS = [
    { value: 'Standard', label: 'Standard', name: 'Standard' },
    { value: 'UK Margin', label: 'UK Margin', name: 'UK Margin' },
    { value: 'EU Margin', label: 'EU Margin', name: 'EU Margin' },
    { value: 'EU Standard', label: 'EU Standard', name: 'EU Standard' },
    // { value: 'B2B Margin', label: 'B2B Margin' },
];

export const VAT_REGIONS = [
    { name: 'EU', label: 'EU', value: 'EU' },
    { name: 'UK', label: 'UK', value: 'UK' },
    { name: 'US', label: 'US', value: 'US' },
    { name: 'Other', label: 'Other', value: 'Other' }
];