import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AgentsTable from './tables/AgentsTable';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    tryToControlInventoryStatus,
    tryToDeleteAgent,
    tryToDisableAgent,
    tryToEnableAgent,
    tryToFetchAgents
} from '../../../store/brokers/partners/agents/agentsSlice';
import { IAgents } from '../../../interfaces/agents/IAgents';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import NotificationSettingsModal from './modals/NotificationSettingsModal';
import RoleModal from './modals/RoleModal';
import EmptyContainer from '../../../shared/empty-container';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const Agents = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IAgents[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const [showNotificationsModal, setShowNotificationsModal] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<{ show: boolean, id: string }>()
    const [showRoleModal, setShowRoleModal] = useState<{ show: boolean, id: string, type: string, role?: any }>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state.agents.agents?.data?.page);
    const rowsLoading = useAppSelector((state) => state.agents.agentsAreLoading);
    const state = useAppSelector((state) => state.agents);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Members')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.agents) {
            const rows =
                state.agents?.data?.elements && Array.isArray(state.agents?.data.elements)
                    ? state.agents?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.agents]);

    useEffect(() => {
        dispatch(tryToFetchAgents(paginationState));
    }, [paginationState]);

    const onAddNewAgent = () => {
        navigate('/members/create');
    }

    const onSeeAgentDetails = async (id: string) => {
        navigate(`/members/${id}`);
    }

    const onEnableDisableAgent = async (id: string, currentStatus: boolean) => {
        try {
            if (currentStatus) {
                await dispatch(tryToDisableAgent(id)).unwrap();
                setShowToast({ type: 'success', message: `Member disabled successfully` });
            } else {
                await dispatch(tryToEnableAgent(id)).unwrap();
                setShowToast({ type: 'success', message: `Member enabled successfully` });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
    }

    const onDeleteAgent = async () => {
        try {
            await dispatch(tryToDeleteAgent(showDeleteModal?.id || ''))
            setShowToast({ type: 'success', message: `Member successfully deleted` });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setShowDeleteModal(undefined)
    }

    const onRequestDeleteAgent = (id: string) => {
        setShowDeleteModal({ show: true, id })
    }

    const onCloseDeleteModal = () => {
        setShowDeleteModal(undefined)
    }

    const onOpenAgentRole = (userId: string, type: string, role?: any) => {
        setShowRoleModal({
            show: true,
            id: userId,
            type: type,
            role: role
        })
    }

    const onToggleNotificationsModal = () => {
        setShowNotificationsModal(!showNotificationsModal)
    }

    const onEnableDisableFeatures = async (id: string, value: boolean, dashboard: string) => {
        try {
            await dispatch(tryToControlInventoryStatus({ agentId: id, dashboard, value: !value })).unwrap();
            setShowToast({ type: 'success', message: `Inventory Status ${value ? 'disabled' : 'enabled'} successfully` });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
    }

    return (
        <div>
            <div>
                <p className='page-title'>Members</p>
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Manage Members Notifications'}
                    dataQa={'manage-members-notifications'}
                    className={`${(!accessControl || pageAccess?.actions?.['Manage Members Notifications']) ? 'btn-main' : 'btn-main-disable'} mr-2`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Manage Members Notifications']) && onToggleNotificationsModal()}
                />
                <Button
                    label={'Add New Member'}
                    dataQa={'add-new-member'}
                    className={`${(!accessControl || pageAccess?.actions?.['Add New Member']) ? 'btn-main' : 'btn-main-disable'}`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Member']) && onAddNewAgent()}
                />
            </div>
            <div className='my-5 relative'>
                {(rows?.length > 0 || rowsLoading) ?
                    <AgentsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        accessManageInventoryRole={!accessControl || pageAccess?.actions?.['Manage Inventory Role']}
                        accessSeeDetails={!accessControl || pageAccess?.actions?.['See Details']}
                        accessEnableDisable={!accessControl || pageAccess?.actions?.['Enable Disable']}
                        accessInventoryStatus={!accessControl || pageAccess?.actions?.['Inventory Status']}
                        accessDelete={!accessControl || pageAccess?.actions?.Delete}
                        accessManageRole={!accessControl || pageAccess?.actions?.['Manage Role']}
                        onSeeAgentDetails={onSeeAgentDetails}
                        onEnableDisableAgent={onEnableDisableAgent}
                        onEnableDisableFeatures={onEnableDisableFeatures}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        onDeleteAgent={onRequestDeleteAgent}
                        onOpenAgentRole={onOpenAgentRole}
                    />
                    : <EmptyContainer text={'No member added yet.'} showImage={true} />
                }
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            <DeleteModal
                openDeleteModal={showDeleteModal?.show || false}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeleteAgent}
            />
            {showRoleModal?.show &&
                <RoleModal
                    openModal={showRoleModal?.show}
                    id={showRoleModal?.id || ''}
                    role={showRoleModal?.role}
                    agentType={showRoleModal?.type || ''}
                    handleCloseModal={() => setShowRoleModal(undefined)}
                />
            }
            {showNotificationsModal &&
                <NotificationSettingsModal
                    openModal={showNotificationsModal}
                    handleCloseModal={onToggleNotificationsModal}
                />
            }
        </div>
    )
}

export default Agents;