import React from 'react';
import Button from '../../../../shared/button';


interface ISuccessfullyUploadFile {
    text?: string;
    handleCloseModal: () => void;
}

const ChangePricesSuccessfully = ({
    text,
    handleCloseModal
}: ISuccessfullyUploadFile) => {
    return (
        <div className={'w-full h-full min-h-[300px] flex flex-col items-center justify-between'}>
            <div>
                <p className='text-md font-medium text-center'>{text || 'Thank you for providing this info. Prices have been updated, please reload the page to see the changes.'}</p>
            </div>
            <img alt='success-mark' src={'/assets/shared/success-mark.svg'} className={'h-[100px] object-contain my-10'} />
            <Button
                label='Close'
                dataQa={'close-button'}
                className='btn-main my-3 min-w-[150px] !shadow-none'
                onClickButton={handleCloseModal}
            />
        </div>
    )
}

export default ChangePricesSuccessfully;