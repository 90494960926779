import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IFieldSettingsFilters {
    partners?: Array<IPartners>;
    sectors?: Array<IAssetTemplate>;
    fields?: Array<any>;
    selectedPartner?: any;
    selectedSector?: any;
    selectedField?: any;
    onSelectFilter: (id: string, type?: string) => void;
}

const FieldSettingsFilters = ({
    partners,
    sectors,
    fields,
    selectedPartner,
    selectedSector,
    selectedField,
    onSelectFilter
}: IFieldSettingsFilters) => {
    return (
        <div>
            <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Partner'}
                        placeholder={' '}
                        dataQa={'partner-selected'}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        options={partners || []}
                        uniqueName={'partner'}
                        selectedOption={selectedPartner}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Catalog'}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        placeholder={' '}
                        dataQa={'category-selected'}
                        selectedOption={selectedSector}
                        options={sectors || []}
                        uniqueName={'sector'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Catalog Field'}
                        placeholder={' '}
                        dataQa={'catalog-field'}
                        selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                        options={fields || []}
                        selectedOption={selectedField}
                        uniqueName={'field'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
            </div>
        </div>
    )
}

export default FieldSettingsFilters;