import React from 'react';
import { PURCHASE_ORDER_STATUS } from '../../../../utils/constants/purchase-order-status';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IPurchaseFilters {
    brokerCategories?: Array<any>;
    selectedCategory?: ISelectedValue;
    selectedStatus?: ISelectedValue;
    onClickFilter: (value: ISelectedValue, type?: string) => void;
}

const PurchaseFilters = ({
    brokerCategories,
    selectedCategory,
    selectedStatus,
    onClickFilter
}: IPurchaseFilters) => {
    return (
        <div>
            <div className='grid grid-cols-4 gap-4'>
                <SelectCheckbox
                    name='Status'
                    dataQa={'status-filter'}
                    placeholder={''}
                    options={PURCHASE_ORDER_STATUS || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                    onChangeSelectedOption={onClickFilter}
                    selectedOption={selectedStatus}
                    uniqueName={'status'}
                />
                <SelectCheckbox
                    name='Category'
                    dataQa={'category-filter'}
                    placeholder={''}
                    options={brokerCategories || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                    onChangeSelectedOption={onClickFilter}
                    selectedOption={selectedCategory}
                    uniqueName={'category'}
                />
            </div>
        </div>
    );
};

export default PurchaseFilters;
