import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Loading from '../../../../shared/Loading';

interface IActionCheckModal {
    openMarketStatusModal: boolean;
    errorType?: boolean;
    text: JSX.Element | string;
    title: string;
    action: string;
    loading?: boolean;
    handleCloseModal: () => void;
    onApproveAction: (type: string) => void;
}

const ActionCheckModal = ({
    openMarketStatusModal,
    errorType,
    text,
    title,
    action,
    loading,
    handleCloseModal,
    onApproveAction,
}: IActionCheckModal) => {
    return (
        <Modal
            open={openMarketStatusModal}
            onClose={() => handleCloseModal()}
            dataQa={'close-button'}
            showInRight
            contentContainerStyle='p-6 min-w-[500px] bg-white rounded-lg shadow-lg'>
            <div className='mb-4'>
                <p className='text-gray-600 text-sm text-center'>RMA Item Actions</p>
                <hr className='mt-2' />
            </div>
            <div className='mb-4'>
                <p className='text-lg font-semibold text-gray-900'>{title}</p>
                <hr className='mt-2' />
            </div>
            <div className='bg-[#F8F9FC] p-4'>
                <p className='text-gray-700 mb-4'>{text}</p>
                <p className='text-gray-500 text-sm mb-4'>This action cannot be undone!</p>
                <p className='text-gray-500 text-sm mb-4'>A Credit Note will be created based on the final price and this item will be added to inventory.</p>
                <div className='flex justify-between'>
                    <Button
                        onClickButton={handleCloseModal}
                        label="No"
                        className="w-full mr-2 py-2 text-center rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300"
                    />
                    <Button
                        icon={loading && <Loading />}
                        label="Yes"
                        dataQa="approve-action"
                        disabled={loading}
                        onClickButton={() => !loading && onApproveAction(action)}
                        className={`w-full ml-2 py-2 rounded-md bg-gradient-to-r from-[#FCEE21] to-[#F7931E] text-[#202020]`}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ActionCheckModal;
