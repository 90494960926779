import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    tryToDeleteRentalAsset,
    tryToDownloadRentalAssets,
    tryToFetchRentalAssets
} from '../../../store/brokers/admin/rental-pricing/rentalPricingSlice';
import {
    tryToFetchAssetFieldsTemplate,
    tryToFetchSingleAssetTemplate
} from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { usePricingTemplateMutation } from '../../../store/brokers/partners/download-templates/downloadTemplatesApi';
import { IPaginationPayloadWithId } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetFields } from '../../../interfaces/asset-templates/IAssetTemplate';
import { IToast } from '../../../interfaces/components/IToast';
import { IAssets } from '../../../interfaces/assets/IAssets';
import RentalAssetsDetailsTable from './tables/RentalAssetsDetailsTable';
import ImportRentalAssetsImagesModal from './modals/ImportRentalAssetsImagesModal';
import ManageRentalAssetModal from './modals/ManageRentalAssetModal';
import ImportAssetsModal from './modals/ImportRentalAssetsModal';
import EmptyContainer from '../../../shared/empty-container';
import DeleteModal from '../../../shared/delete-modal';
import Loading from '../../../shared/Loading';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const RentalAssetsData = () => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const csvLink2 = React.useRef() as React.MutableRefObject<any>;
    const { id } = useParams();
    const [rows, setRows] = useState<IAssets[]>([]);
    const [paginationState, setPaginationState] = useState<IPaginationPayloadWithId>({
        pageNumber: 1,
        pageSize: 10,
        id: id || ''
    });
    const [assetFields, setAssetFields] = useState<IAssetFields[]>([])
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showToast, setShowToast] = useState<IToast | null>()
    const [rentalAssetId, setRentalAssetId] = useState<string>()
    const [showImportAssetsModal, setShowImportAssetsModal] = useState<boolean>(false)
    const [assetTemplateSample, setAssetTemplateSample] = useState<Array<any>>()
    const [assetsToDownload, setAssetsToDownload] = useState<Array<any>>()
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [loadingDownloadAssets, setLoadingDownloadAssets] = useState<boolean>(false)
    const [showManageAssetModal, setShowManageAssetModal] = useState<{ show: boolean, assetId?: string }>()
    const [showImportRentalAssetsImagesModal, setShowImportRentalAssetsImagesModal] = useState<boolean>(false)
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.rentalPricing?.rentalAssets?.data?.page);
    const rowsLoading = useAppSelector((state) => state.assets?.assetsAreLoading);
    const state = useAppSelector((state) => state.rentalPricing);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const stateAssetFields = useAppSelector((state) => state.assetsTemplates);
    const [pricingTemplateMutation] = usePricingTemplateMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Rental Pricing')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Rental Assets Data')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.rentalAssets) {
            const rowsFormat =
                state.rentalAssets?.data?.elements && Array.isArray(state.rentalAssets?.data.elements)
                    ? state.rentalAssets?.data.elements.map((r: any) => ({ ...r, id: r._id }))
                    : [];
            if (JSON.stringify(rowsFormat) !== JSON.stringify(rows)) {
                setRows(rowsFormat);
            }
        }
    }, [state.rentalAssets]);

    useEffect(() => {
        if (stateAssetFields.assetTemplateDetails?.rentalFields) {
            const rows =
                stateAssetFields.assetTemplateDetails?.rentalFields && Array.isArray(stateAssetFields.assetTemplateDetails?.rentalFields)
                    ? stateAssetFields.assetTemplateDetails?.rentalFields?.filter((r) => r?.enabled).map((r) => ({ ...r, id: r.id }))
                    : [];
            if (JSON.stringify(rows) !== JSON.stringify(assetFields)) {
                setAssetFields(rows);
            }
        }
    }, [stateAssetFields.assetTemplateDetails]);

    useEffect(() => {
        if (id && paginationState) {
            dispatch(tryToFetchRentalAssets(paginationState));
        }
    }, [paginationState, id]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchAssetFieldsTemplate(id));
            dispatch(tryToFetchSingleAssetTemplate(id))
        }
    }, [id]);

    const onAddNewAsset = () => {
        setShowManageAssetModal({
            show: true,
        })
    }

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false)
        setRentalAssetId(undefined)
    }

    const onDeleteAsset = async () => {
        try {
            await dispatch(tryToDeleteRentalAsset(rentalAssetId || '')).unwrap()
            setShowToast({ type: 'success', message: `Asset successfully deleted` });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setShowDeleteModal(false)
        setRentalAssetId(undefined)
    }

    const onDeleteAssetRequest = (id: string) => {
        setShowDeleteModal(true)
        setRentalAssetId(id)
    }

    const onToggleImportAssetsModal = () => {
        setShowImportAssetsModal(!showImportAssetsModal)
    }

    const onToggleImportAssetsImagesModal = () => {
        setShowImportRentalAssetsImagesModal(!showImportRentalAssetsImagesModal)
    }

    const onDownloadTemplate = async () => {
        setLoadingDownload(true)
        if (!id || id === '') {
            setShowToast({ type: 'error', message: 'Asset must be specified to download sample.' })
            return;
        }
        try {
            const response = await pricingTemplateMutation({ assetTemplateId: id || '' }).unwrap()
            setAssetTemplateSample(response || [])
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (assetTemplateSample && loadingDownload) {
            csvLink.current.link.click()
        }
        setLoadingDownload(false)
    }, [assetTemplateSample])

    const onDownloadAssets = async () => {
        setLoadingDownloadAssets(true)
        if (!id || id === '') {
            setShowToast({ type: 'error', message: 'Asset must be specified to download sample.' })
            return;
        }
        try {
            const response: any = await dispatch(tryToDownloadRentalAssets({ id: id || '' })).unwrap()
            setAssetsToDownload(response || [])
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
            setLoadingDownloadAssets(false)
        }
    }

    useEffect(() => {
        if (assetsToDownload && loadingDownloadAssets) {
            csvLink2.current.link.click()
        }
        setLoadingDownloadAssets(false)
    }, [assetsToDownload])

    const onImportAssetsSuccessfully = () => {
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
        })
    }

    const onEditAsset = async (assetId: string) => {
        setShowManageAssetModal({
            show: true,
            assetId,
        })
    }

    const onFinishManageSuccessfully = () => {
        setShowToast({ type: 'success', message: 'Asset saved successfully' })
    }

    return (
        <div>
            <div>
                <p className='page-title'>Rental Assets Details</p>
                <p className='mt-3 font-medium'>Asset Catalog: {stateAssetFields?.assetTemplateDetails?.displayName}</p>
            </div>
            <div className='flex flex-row justify-end mt-6 flex-wrap'>
                <Button
                    label={loadingDownloadAssets ? 'Loading data...' : 'Download Rental Assets'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Download Rental Assets']) && onDownloadAssets()}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Rental Assets']) ? 'btn-main' : 'btn-main-disable'} mr-2 !py-2 mb-1 !shadow-none`}
                    dataQa={'download-rental-asset-btn'}
                />
                <CSVLink
                    ref={csvLink2}
                    data={assetsToDownload?.filter((item, idx) => idx > 0) || []}
                    headers={assetsToDownload?.[0] || []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={`Assets-data.csv`}
                >
                    {loadingDownload ? 'Loading data...' : 'Download Sample'}
                </CSVLink>
                <Button
                    label='Import Rental Assets'
                    className={`'!rounded ${(!accessControl || pageAccess?.actions?.['Import Rental Assets']) ? 'btn-main' : 'btn-main-disable'} min-w-[150px] !shadow-none mr-2 mb-1`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Rental Assets']) && onToggleImportAssetsModal()}
                    dataQa={'import-rental-asset-btn'}
                />
                <Button
                    label='Import Rental Assets Images'
                    className={`'!rounded ${(!accessControl || pageAccess?.actions?.['Import Assets Images']) ? 'btn-main' : 'btn-main-disable'} min-w-[150px] mb-1 !rounded !shadow-none mr-2`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Assets Images']) && onToggleImportAssetsImagesModal()}
                    dataQa={'import-asset-btn'}
                />
                <Button
                    label={loadingDownload ? 'Loading data...' : 'Download Rental Sample'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Download Rental Sample']) && onDownloadTemplate()}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Rental Sample']) ? 'btn-main' : 'btn-main-disable'} mr-2 !py-2 !shadow-none mb-1`}
                    dataQa={'download-sample-rental-asset-btn'}
                />
                <CSVLink
                    ref={csvLink}
                    data={[]}
                    headers={assetTemplateSample?.[0] || []}
                    className={'hidden'}
                    target={'_blank'}
                    // asyncOnClick={true}
                    filename={`Asset-catalog-sample.csv`}
                >
                    {loadingDownload ? 'Loading data...' : 'Download Sample'}
                </CSVLink>
                <Button
                    label={'Add New Rental Asset'}
                    className={`!py-2 ${(!accessControl || pageAccess?.actions?.['Add New Asset']) ? 'btn-main' : 'btn-main-disable'} !shadow-none mb-1`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Asset']) && onAddNewAsset()}
                    dataQa={'add-new-rental-asset-btn'}
                />
            </div>
            <div className='my-5 relative'>
                {rowsLoading ?
                    <div className='my-3 flex flex-row justify-center items-center'>
                        <Loading />
                    </div>
                    : <>
                        {rows?.length > 0 ?
                            <RentalAssetsDetailsTable
                                rows={rows}
                                assetTemplateFields={assetFields}
                                paginationData={paginationData}
                                paginationState={paginationState}
                                accessControl={accessControl}
                                actions={pageAccess?.actions}
                                setPaginationState={setPaginationState}
                                onDeleteAssetRequest={onDeleteAssetRequest}
                                onEditAsset={onEditAsset}
                            />
                            : <EmptyContainer text={'No asset data added yet.'} showImage={true} />
                        }
                    </>
                }
            </div>
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeleteAsset}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            <ImportAssetsModal
                openImportModal={showImportAssetsModal}
                handleCloseModal={onToggleImportAssetsModal}
                onImportAssetsSuccessfully={onImportAssetsSuccessfully}
                assetTemplateId={id || ''}
            />
            {showManageAssetModal?.show &&
                <ManageRentalAssetModal
                    openModal={showManageAssetModal?.show}
                    id={id || ''}
                    assetId={showManageAssetModal?.assetId}
                    handleCloseModal={() => setShowManageAssetModal(undefined)}
                    onFinishSuccessfully={onFinishManageSuccessfully}
                />
            }
            {showImportRentalAssetsImagesModal &&
                <ImportRentalAssetsImagesModal
                    openImportModal={showImportRentalAssetsImagesModal}
                    handleCloseModal={onToggleImportAssetsImagesModal}
                    onImportAssetsSuccessfully={onImportAssetsSuccessfully}
                    assetTemplateId={id || ''}
                />
            }
        </div>
    )
}

export default RentalAssetsData;