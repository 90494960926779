import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToFetchImeiStatus } from '../../../../store/brokers/partners/orders/ordersSlice';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';


interface IImeiCheckModal {
    openModal: boolean;
    itemId?: any;
    orderId?: any;
    imeiSearch?: string;
    formData: any,
    onApproveAction: (type: string, message: string) => void;
    handleCloseModal: () => void;
}

const ImeiCheckModal = ({
    openModal,
    formData,
    itemId,
    orderId,
    imeiSearch,
    handleCloseModal,
    onApproveAction,
}: IImeiCheckModal) => {
    const dispatch = useAppDispatch();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const getImeiStatus = async () => {
        try {
            const formatData = {
                itemId: itemId,
                orderId: orderId,
                data: {
                    blackListStatus: formData?.blackliststatus,
                    imei: imeiSearch
                }
            }
            await dispatch(tryToFetchImeiStatus(formatData)).unwrap();
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (orderId && itemId && formData?.blackliststatus) {
            getImeiStatus()
        }
    }, [])

    const onCloseModal = () => {
        handleCloseModal();
        setLoadingSubmit(false)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mb-4 font-bold text-center text-sm border-b-1'>IMEI Check</p>
                {formData?.blackliststatus === 'Yes' ?
                        (
                            <div>
                                <div className='flex flex-row gap-4 text-center place-content-center'>
                                    <img src={'/assets/order-status/warning.png'} className={'w-[30px] object-contain cursor-pointer'} />
                                    <p className='font-semibold text-[20px] mb-2 mt-3'>Status: Blacklisted</p>
                                </div>
                                <div className='min-w-full flex flex-row pt-4 justify-center'>
                                    <Button
                                        label={'Close'}
                                        dataQa={'close'}
                                        className={'btn-main min-w-[150px]'}
                                        onClickButton={() => !loadingSubmit && onCloseModal()}
                                    />
                                </div>
                            </div>
                        ) : formData?.blackliststatus === 'No' ? (
                            <div>
                                <div className='flex flex-row gap-6 text-center place-content-center'>
                                    <img src={'/assets/order-status/check-mark.png'} className={'w-[30px] object-contain cursor-pointer'} />
                                    <p className='font-semibold text-[20px] mb-2 mt-3'>Status: Clean</p>
                                </div>
                                <div className='min-w-full flex flex-row pt-4 justify-center'>
                                    <Button
                                        label={'Close'}
                                        dataQa={'close'}
                                        className={'btn-main min-w-[150px]'}
                                        onClickButton={() => !loadingSubmit && onCloseModal()}
                                    />
                                </div>
                            </div>
                        ) : !formData?.blackliststatus && (
                            <div>
                                <div className='flex flex-row gap-6 text-center place-content-center'>
                                    {/* <img src={'/assets/order-status/check-mark.png'} className={'w-[30px] object-contain cursor-pointer'} /> */}
                                    <p className='font-semibold text-[20px] mb-2 mt-3'>Status: Not Found</p>
                                </div>
                                <div className='min-w-full flex flex-row pt-4 justify-center'>
                                    <Button
                                        label={'Close'}
                                        dataQa={'close'}
                                        className={'btn-main min-w-[150px]'}
                                        onClickButton={() => !loadingSubmit && onCloseModal()}
                                    />
                                </div>
                            </div>
                        )
                }
            </div>
        </Modal>
    )
}

export default ImeiCheckModal;