import React, { useState } from 'react';
import Input from '../../shared/input';
import { Controller, useForm } from 'react-hook-form';
import { IAgentCallProps, IAgentCallResponse } from '../../interfaces/ai-agents/IAgentCall';
import Datepicker from '../../shared/datepicker';
import Button from '../../shared/button';
import { AssetLibraryService } from '../../store/brokers/partners/asset-library/assetLibraryApi';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const AICall = () => {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [showMoreTranscript, setShowMoreTranscript] = useState<boolean>();
	const [callInProgress, setCallInProgress] = useState<boolean>();
	const [callResponse, setCallResponse] = useState<IAgentCallResponse>()
	const { register, control, formState: { errors }, handleSubmit } = useForm<IAgentCallProps>();

	const handleShowMoreTranscript = () => setShowMoreTranscript(showMore => !showMore)

	const getBrokerCallDetails = async (id: string) => {
		try {
			setCallInProgress(true)
			const { data: callDetails } = await AssetLibraryService.tryToGetBrokerCallDetails(id)
			if (callDetails.data) {
				setCallResponse(callDetails.data);
				setCallInProgress(false)
			} else {
				setTimeout(() => getBrokerCallDetails(id), 5000);
			}
		} catch (e) {
			console.log({ e });
			setCallInProgress(false);
		}
	}

	const onSubmit = async (payload: IAgentCallProps) => {
		setIsLoading(true);
		try {
			const { data } = await AssetLibraryService.tryToBrokerCall({
				...payload, 
				phoneNumber: payload.phoneNumber.startsWith('+') ? payload.phoneNumber : `+${payload.phoneNumber}`
			});
			getBrokerCallDetails(data.data)
			setIsLoading(false);
		} catch (e) {
			console.log({ e });
			setIsLoading(false);
		}
	}

	const handleReload = () => {
		setShowMoreTranscript(false);
		setCallResponse(undefined)
	}

	return (
		<>
			<h1 className='page-title'>Agent Call </h1>
			<form onSubmit={handleSubmit(onSubmit)} className='bg-white p-5 flex flex-wrap'>
				<div className='w-full md:w-6/12 px-2'>
					<Input
						register={register('firstName', {required: 'First Name is required'})}
						containerStyle='mb-0'
						label='First Name'
						dataQa={'firstName'}
						error={errors?.firstName?.message}
						inputUniqueName={'firstName'}
					/>
				</div>
				<div className='w-full md:w-6/12 px-2'>
					<Input
						register={register('lastName', {required: 'Last Name is required'})}
						containerStyle='mb-0'
						label='Last Name'
						dataQa={'lastName'}
						error={errors?.firstName?.message}
						inputUniqueName={'lastName'}
					/>
				</div>
				<div className='w-full md:w-6/12 px-2'>
					{/* <Input
						register={register('phoneNumber', {required: 'Phone Number is required'})}
						containerStyle='mb-0'
						label='Phone Name'
						dataQa={'phoneNumber'}
						error={errors?.phoneNumber?.message}
						inputUniqueName={'phoneNumber'}
					/> */}
					<label className="block text-primary-light font-normal text-base mb-1">Phone Number</label>
					<Controller
						control={control}
						name="phoneNumber"
						rules={{required: 'Phone Number is required'}}
						render={({ field: { onChange, value } }) => (
							<PhoneInput
								country='gb'
								value={value}
								onChange={onChange}
								inputClass="phone-input w-full"
								inputStyle={{width: '100%', border: '1px solid #e5e7eb', paddingTop: 8, paddingBottom: 8, height: 'auto'}}
							/>
						)}
					/>
					<p data-cy="error-message" className="text-xs text-red-500 my-2">
						{errors.phoneNumber?.message?.toString()}
					</p>
				</div>
				<div className='w-full md:w-6/12 px-2'>
					<Input
						register={register('orderNumber', {required: 'Order Number is required'})}
						containerStyle='mb-0'
						label='Order Number'
						dataQa={'orderNumber'}
						error={errors?.orderNumber?.message}
						inputUniqueName={'orderNumber'}
					/>
				</div>
				<div className='w-full md:w-6/12 px-2'>
					<Input
						register={register('companyBuyer', {required: 'Company Buyer is required'})}
						containerStyle='mb-0'
						label='Company Buyer'
						dataQa={'companyBuyer'}
						error={errors?.companyBuyer?.message}
						inputUniqueName={'companyBuyer'}
					/>
				</div>
				<div className='w-full md:w-6/12 px-2'>
					<Input
						register={register('deviceBought', {required: 'Device bought is required'})}
						containerStyle='mb-0'
						label='Device Bought'
						dataQa={'deviceBought'}
						error={errors?.deviceBought?.message}
						inputUniqueName={'deviceBought'}
					/>
				</div>
				<div className='w-full md:w-6/12 px-2'>
					<Controller
						control={control}
						name='dateOfPurchase'
						render={({ field: { onChange, value } }) => (
							<Datepicker
								placeholder='Date of Purchase'
								containerStyle='mb-0'
								dataQa={'date-of-purchase'}
								inputValue={value}
								onChangeInput={onChange}
								inputUniqueName={'dateOfPurchase'}
							/>
						)}
					/>
				</div>
				<div className='w-full md:w-6/12 px-2'>
					<Controller
						control={control}
						name='expiryDate'
						render={({ field: { onChange, value } }) => (
							<Datepicker
								placeholder='Expiry Date'
								containerStyle='mb-0'
								dataQa={'expiry-date'}
								inputValue={value}
								onChangeInput={onChange}
								inputUniqueName={'expiryDate'}
							/>
						)}
					/>
				</div>
				<div className='w-full'>
					{callResponse ? <>
						<hr className='my-5' />
						<div className='w-full flex justify-center'>
							<Button
								type='button'
								label={'Reload'}
								onClickButton={handleReload}
								loading={isLoading}
								className="text-label text-sm py-3 px-7 rounded-xl bg-gradient-to-br from-gradient-start to-gradient-end hover:from-gradient-end hover:to-gradient-start mx-auto"
							/>
						</div>

						<hr className='my-5' />
						<div className='w-full flex flex-wrap my-3'>
							<h4 className='w-2/12'>Summary</h4>
							<p className='w-10/12 pl-3 whitespace-pre-wrap break-words'>{callResponse.summary}</p>
						</div>
						<div className='w-full flex flex-wrap my-3'>
							<h4 className='w-2/12'>Transcript</h4>
							<p className={'w-10/12 pl-3 whitespace-pre-wrap break-words'}>
								{(callResponse.transcript.length > 500 && !showMoreTranscript) ?
									<>
										{callResponse.transcript.slice(0, 500)}
										<button type='button' onClick={handleShowMoreTranscript} className='underline text-xs ml-auto'>Show more</button>
									</>
									:
									callResponse.transcript
								}
								{showMoreTranscript && <button type='button' onClick={handleShowMoreTranscript} className='underline text-xs ml-auto'>Show less</button>}

							</p>
						</div>
						<div className='w-full flex flex-wrap my-5'>
							<h4 className='w-2/12'>Call Started</h4>
							<p className='w-10/12 pl-3'>{moment(callResponse.callStarted).format('DD/MM/YYYY HH:mm:ss')}</p>
						</div>
						<div className='w-full flex flex-wrap my-5'>
							<h4 className='w-2/12'>Call Ended</h4>
							<p className='w-10/12 pl-3'>{moment(callResponse.callEnded).format('DD/MM/YYYY HH:mm:ss')}</p>
						</div>
						<div className='w-full flex flex-wrap my-5'>
							<h4 className='w-2/12'>Ended Reason</h4>
							<p className='w-10/12 pl-3'>{callResponse.endedReason}</p>
						</div>
						<div className='w-full flex flex-wrap my-5'>
							<h4 className='w-2/12'>Success Evaluation</h4>
							<p className='w-10/12 pl-3'>{callResponse.successEvaluation}</p>
						</div>
						<div className='w-full flex flex-wrap my-5'>
							<h4 className='w-2/12'>Next Step</h4>
							<p className='w-10/12 pl-3'>{callResponse.structuredData.next_steps}</p>
						</div>
						<div className='w-full flex flex-wrap my-5'>
							<h4 className='w-2/12'>Order Status</h4>
							<p className='w-10/12 pl-3'>{callResponse.structuredData.order_status}</p>
						</div>
						<div className='w-full flex flex-wrap my-5'>
							<h4 className='w-2/12'>Why Not Shipping</h4>
							<p className='w-10/12 pl-3'>{callResponse.structuredData.why_not_shipping}</p>
						</div>
						<div className='w-full flex flex-wrap my-5'>
							<h4 className='w-2/12'>Customer Shipping</h4>
							<p className='w-10/12 pl-3'>{callResponse.structuredData.customer_shipping}</p>
						</div>
						<div className='w-full flex flex-wrap my-5'>
							<h4 className='w-2/12 py-3'>Recording Url</h4>
							<p className='w-10/12 pl-3'>
								<audio controls>
									<source src={callResponse.recordingUrl} type="audio/wav"></source>
								</audio>

							</p>
						</div>
					</> :
						callInProgress ? <>
							<p className='my-5 text-center'>Call in Progress..</p>
						</> :
							<Button
								type='submit'
								label={'Call'}
								loading={isLoading}
								className="text-label text-sm py-3 px-7 rounded-xl bg-gradient-to-br from-gradient-start to-gradient-end hover:from-gradient-end hover:to-gradient-start mx-2"
							/>}

				</div>
			</form>
		</>
	)
}

export default AICall;