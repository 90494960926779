import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import SelectCheckbox from '../../../../shared/select-checkbox';
import { QUESTIONS_ANSWER_TYPE } from '../../../../utils/constants/question-types';


interface IQuestionsFilters {
    sectors?: Array<IAssetTemplate>;
    payServices?: Array<any>;
    markets?: Array<any>;
    selectedSector?: any;
    selectedMarket?: any;
    selectedPayService?: any;
    selectedType?: any;
    onSelectFilter: (id: string, type?: string) => void;
}

const QuestionsFilters = ({
    sectors,
    payServices,
    markets,
    selectedSector,
    selectedMarket,
    selectedPayService,
    selectedType,
    onSelectFilter
}: IQuestionsFilters) => {
    return (
        <div>
            <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Category'}
                        placeholder={' '}
                        selectedOption={selectedSector}
                        dataQa={'category-filters'}
                        options={sectors || []}
                        uniqueName={'sector'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Service'}
                        placeholder={' '}
                        dataQa={'service-filters'}
                        options={payServices || []}
                        selectedOption={selectedPayService}
                        uniqueName={'payService'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Market'}
                        placeholder={' '}
                        options={markets || []}
                        dataQa={'market-filters'}
                        selectedOption={selectedMarket}
                        uniqueName={'market'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Answer Type'}
                        placeholder={' '}
                        options={QUESTIONS_ANSWER_TYPE || []}
                        selectedOption={selectedType}
                        dataQa={'answer-type'}
                        uniqueName={'answerType'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
            </div>
        </div>
    )
}

export default QuestionsFilters;