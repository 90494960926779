import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';
import { PaginatedNotificationSections } from '../../../../interfaces/notification-sections/paginatedStore.type';
import { INotificationSection } from '../../../../interfaces/notification-sections/INotificationSection';

const tryToFetchNotificationSections = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedNotificationSections>> => {
    const url = `${USERS_API_URL}/notifications/section/paginated?page=${pageNumber}&size=${pageSize}`
    return brokersAxios.put<PaginatedNotificationSections>(url, data);
};

const tryToFetchSingleNotificationSection = (sectionId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/notifications/section/${sectionId}`);
};

const tryToFetchAllNotificationSection = (): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/notifications/section`);
};

const tryToAddNotificationSection = (data: INotificationSection): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/notifications/section`, data);
};

const tryToEditNotificationSection = (sectionId: string, payload: INotificationSection): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/notifications/section/${sectionId}`, payload);
};

const tryToDeleteNotificationSection = (sectionId: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/notifications/section/${sectionId}`);
};

export const NotificationSectionsService = {
    tryToFetchNotificationSections,
    tryToFetchSingleNotificationSection,
    tryToFetchAllNotificationSection,
    tryToAddNotificationSection,
    tryToEditNotificationSection,
    tryToDeleteNotificationSection
};
