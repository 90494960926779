import React from 'react';
import ProductInfoContainer from './ProductInfoContainer';
import WhiteContainer from '../../../../shared/white-container';


const RevaluateContainerLoading = () => {
    return (
        <div className='grid grid-cols-3 gap-4'>
            <div className='col-span-2'>
                <WhiteContainer containerStyle='!rounded mb-2 !h-auto'>
                    <div className='flex flex-row items-center mb-5'>
                        <div className='font-semibold text-[16px]'>Item inspection</div>
                        <div className='after:block after:bg-black after:w-[1.5px] after:h-[16px] after:mx-1'></div>
                        <div className='text-[13px]'><span className='font-semibold'>Order ID:</span></div>
                        <div className='after:block after:bg-black after:w-[1.5px] after:h-[16px] after:mx-1'></div>
                        <div className='text-[13px]'><span className='font-semibold'>Item ID:</span></div>
                    </div>
                    <div className='flex flex-row justify-between items-center'>
                        <div className='flex flex-row items-center animate-pulse'>
                            <p className='mr-3 min-w-[120px]'>Category: </p>
                            <div className='h-8 bg-gray-200 !rounded dark:bg-gray-200 w-[150px] mb-1'></div>
                        </div>
                        <div className='animate-pulse'>
                            <div className='h-8 bg-gray-200 !rounded dark:bg-gray-200 w-[70px] mb-1'></div>
                        </div>
                    </div>
                    <div className='flex flex-row items-center animate-pulse my-4'>
                        <p className='mr-3 min-w-[120px]'>IMEI: </p>
                        <div className='h-8 bg-gray-200 !rounded dark:bg-gray-200 w-[150px] mb-1'></div>
                        <div className='h-4 bg-gray-200 !rounded dark:bg-gray-200 w-4 mb-1 ml-5'></div>
                        <div className='h-3 bg-gray-200 !rounded dark:bg-gray-200 w-[100px] mb-1 ml-2'></div>
                    </div>
                    <div className='w-full mt-5'>
                        {[...Array(4)].map((x, i) => {
                            return (
                                <>
                                    <div className='grid grid-cols-4 gap-3 my-2 animate-pulse'>
                                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                                    </div>
                                    {i !== 3 &&
                                        <div className='mb-5 mt-3 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                    }
                                </>
                            )
                        })
                        }
                    </div>
                </WhiteContainer>
                <WhiteContainer containerStyle='!rounded mb-2 !h-auto'>
                    <div className='mt-5 mb-10'>
                        <p className='font-semibold text-[16px]'>Other Item Attributes <span className='font-normal'>(Broker Defined)</span></p>
                        <div className='mt-5 mb-2 grid grid-cols-3 gap-4 content-start'>
                            {[...Array(3)].map((x, i) => {
                                return (
                                    <div key={i} className=' animate-pulse'>
                                        <div className='h-8 bg-gray-200 !rounded dark:bg-gray-200 w-[80%] mb-1'></div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </WhiteContainer>
            </div>
            <div>
                <WhiteContainer containerStyle='!rounded !h-auto'>
                    <div className='bg-[#f8f9fc] rounded px-4 pt-3 pb-1'>
                        <p className='font-bold text-[20px] mb-1'>Warehouse Placement</p>
                        <div className='animate-pulse'>
                            <div className='h-8 bg-gray-200 !rounded dark:bg-gray-200 w-[80%] mb-5'></div>
                            <div className='h-8 bg-gray-200 !rounded dark:bg-gray-200 w-[80%] mb-5'></div>
                        </div>
                    </div>
                </WhiteContainer>
                <WhiteContainer containerStyle='!p-0 !rounded mt-2 !h-auto'>
                    <ProductInfoContainer />
                </WhiteContainer>
            </div>
        </div>
    )
}

export default RevaluateContainerLoading;