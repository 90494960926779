import React from 'react';
import { IOrderCounts } from '../../../../interfaces/orders/IOrderCounts';
import Button from '../../../../shared/button';


interface IOrderCategories {
    items: Array<any>;
    activeButton?: string;
    ordersPerCategory?: Array<{ count: number; category: string }>;
    ordersCount?: IOrderCounts[];
    onClickCategory: (item: string) => void;
}

const OrderCategories = ({
    items,
    activeButton,
    ordersCount,
    onClickCategory,
}: IOrderCategories) => {
    return (
        <div>
            <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Categories</p>
            <div className='my-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-4'>
                {(items && items?.length > 0) && items?.map((item, index) => {
                    let buttonClassName = 'btn-noactive-categories border';
                    const { totalReceived } = ordersCount?.find((count) => count.category.code === item.value) || {};
                    if (activeButton === item?.value) buttonClassName = 'btn-categories-list';
                    else buttonClassName = 'btn-noactive-categories-list border';
                    return (
                        <Button
                            key={index}
                            label={
                                <>
                                    <span className={`${activeButton === item?.value ? 'text-[#202020]' : 'text-[#8a8a8e]'} text-[15px]`}>{item?.label}</span>
                                    {totalReceived ?
                                        <span className={`ml-auto shrink-0 w-5 h-5 rounded-full !text-[12px] p-1 text-xs flex justify-center items-center ${activeButton === item?.value ? 'bg-[#202020] text-white' : 'bg-[#F7931E] text-black'}`}>
                                            {totalReceived}{' '}
                                        </span>
                                        : ''
                                    }
                                </>
                            }
                            className={`${buttonClassName} !min-w-[70px] text-sm`}
                            onClickButton={() => onClickCategory(item?.value)}
                            dataQa={`${item?.label && item?.label?.toLowerCase()?.replaceAll(' ', '-')}`}
                            icon={item.enabledIcon ?
                                <div className={'w-[23px] h-[23px]'}>
                                    <img src={activeButton === item?.value ? item.enabledIcon : item.disabledIcon} className={'max-w-[100%] max-h-[100%] min-w-[100%] min-h-[100%] object-contain'} />
                                </div>
                                : ''
                            }
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default OrderCategories;
