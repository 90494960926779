import React from 'react';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IInvoicesDetails } from '../../../../interfaces/invoices/IInvoicesDetails';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import Table from '../../../../shared/table';

const tableColumns = ['Bill Id', 'Item Id', 'Item Code (IMEI)', 'Order Id', 'Item Name', 'Location', 'Invoice Number', 'Partner', 'Catalog', 'Partner Price', 'Commission', 'Market', 'State']

interface IItemsTable {
    rows: IInvoicesDetails[];
    paginationData?: IPage;
    selectedRows?: Array<string>;
    markets?: IAllMarkets[];
    partners?: IPartners[];
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSelectRow: (id?: string) => void;
}

const ItemsTable = ({
    rows,
    paginationData,
    markets,
    partners,
    selectedRows,
    setPaginationState,
    onClickSelectRow
}: IItemsTable) => {

    const onFormatStatus = (status: string) => {
        switch (status) {
            case 'PAID':
                return { name: 'PAID', color: 'bg-[#a7eae0]' }
            case 'UNPAID':
                return { name: 'UNPAID', color: 'bg-[#ff3b30]' }
            default:
                return { name: status, color: 'bg-[#dffffa]' }
        }
    }

    return (
        <Table
            selectRows={true}
            areAllRowsSelected={(selectedRows && rows && rows.every(r => selectedRows.includes(r.id || '')))}
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            onClickSelectAll={() => onClickSelectRow()}
            containerStyle={'mt-0'}
        >
            {rows?.length > 0 && rows?.map((row: IInvoicesDetails, idx: number) => {
                const status = onFormatStatus(row?.paid ? 'PAID' : 'UNPAID')
                const marketName = markets && markets?.length > 0 && markets?.find((market) => market?._id === row?.marketId)
                const findIfRowIsSelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find((item) => item === row?.id)
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>
                            <div className='flex items-center'>
                                <input
                                    onChange={() => onClickSelectRow(row?.id)}
                                    checked={!!findIfRowIsSelected}
                                    id='checkbox-all'
                                    data-qa={'checkbox'}
                                    type='checkbox'
                                    className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2' />
                                <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                            </div>
                        </td>
                        <td className='px-6 py-4'>{row?._id}</td>
                        <td className='px-6 py-4'>{row?.id}</td>
                        <td className='px-6 py-4'>{row?.itemDetails.itemCode || 'No IMEI'}</td>
                        <td className='px-6 py-4'>{row?.referenceId || ''}</td>
                        <td className='px-6 py-4'>{row?.name || ''}</td>
                        <td className='px-6 py-4'>
                            {row?.location && (Object?.keys(row?.location))?.map((loc: any, index: number) => {
                                return `${index === 0 ? `${row?.location?.[loc]}` : ` - ${row?.location?.[loc]}`}`
                            })}
                        </td>
                        <td className='px-6 py-4'>{row?.invoiceNumber || ''}</td>
                        <td className='px-6 py-4'>{row?.originalBroker?.companyName || row?.originalBroker?.username}</td>
                        <td className='px-6 py-4'>{`${row?.assetCatalog?.code[0]?.toUpperCase()}${row?.assetCatalog?.code?.slice(1).toLowerCase()}` || ''}</td>
                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(marketName && marketName?.currency?.name || '')}`]}{(row?.value?.amount || 0).toFixed(2)}</td>
                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(marketName && marketName?.currency?.name || '')}`]}{Number(row?.value?.commission || 0).toFixed(2)}</td>
                        <td className='px-6 py-4'>{marketName ? marketName?.label : ''}</td>
                        <td className='px-6 py-4'><div className={`${status ? status?.color : ''} flex flex-row justify-center text-center text-red px-1 min-w-[150px] text-black text-sm py-1 font-medium rounded-2xl`}>{status && status?.name}</div></td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default ItemsTable;