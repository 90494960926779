import { AxiosResponse } from 'axios';
import aiAgentAxios from '../../../utils/axios/aiAgent.axios';
import { AiAgentSession, INextQuestions } from '../../../interfaces/ai-agents/aiAgentSession';
import { IMatches } from '../../../interfaces/ai-agents/IItem';
import { IShippingInformationProps } from '../../../interfaces/ai-agents/IShippingInformation';

const tryToFetchConversations = async (
	brokerId: string,
	type?: 'seller' | 'buyer'
): Promise<AxiosResponse<AiAgentSession>> => {
	return aiAgentAxios.get(type ? `/signup/get-session/${brokerId}?type=${type}` : `/signup/get-session/${brokerId}`)
};

const tryToCreateSessionConversations = async (
	sessionId: string,
	type?: 'seller' | 'buyer'
): Promise<AxiosResponse<AiAgentSession>> => {
	return aiAgentAxios.post(`/signup/create-session`, {sessionId, type: type === 'seller' ? 'seller-side' : type})
};

const tryToAddConversation = async ({chatId, answer, files, type}: { chatId: string, answer: string, files?: File, type?: 'seller' | 'buyer' }) => {
	const formData = new FormData();
	formData.append('chatId', chatId);
	formData.append('answer', answer);
	if (type) formData.append('type', type);
	if (files) formData.append('file', files);

	return aiAgentAxios.post(type ? `/onboarding/answer?type=${type}` : '/onboarding/answer', formData, {
		headers: {
			'Content-type': 'multipart/form-data',
		},                    
	})
};
const tryToAddNextConversation = async (chatId: string, type?: 'seller' | 'buyer'): Promise<AxiosResponse<INextQuestions>> => {
	return aiAgentAxios.get(type ? `/onboarding/next-question?id=${chatId}&type=${type}` : `/onboarding/next-question?id=${chatId}`)
};

const tryToDownloadConfirmedItems = async (data: IMatches[]): Promise<AxiosResponse<INextQuestions>> => {
	return aiAgentAxios.post(`/onboarding/download/confirmed-items`, {data})
};

const tryToRequestSupport = async ({conversationId, userId}: {conversationId: string, userId: string}): Promise<{data: any}> => {
	return aiAgentAxios.post(`/onboarding/conversations/request-support/${conversationId}`, {userId})
};
const tryToGetAllConversations = async ({ page, size }: {page: number, size: number}): Promise<any> => {
	return aiAgentAxios.put(`/onboarding/conversations/cs?page=${page}&size=${size}`)
};


const tryToCloseTheDeal = async ({conversationId, data}: {conversationId: string, data: IShippingInformationProps}): Promise<{data: any}> => {
	return aiAgentAxios.post(`/onboarding/close-the-deal/${conversationId}`, data)
};

export const aiAgentService = {
	tryToFetchConversations,
	tryToAddConversation,
	tryToAddNextConversation,
	tryToDownloadConfirmedItems,
	tryToCreateSessionConversations,
	tryToRequestSupport,
	tryToGetAllConversations,
	tryToCloseTheDeal
};
