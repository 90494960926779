import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IToast } from '../../../../interfaces/components/IToast';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import {
    tryToFetchAllAssetsTemplates,
    tryToFetchAssetTemplateFilters
} from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import { tryToFetchRentalPricings } from '../../../../store/brokers/admin/rental-pricing/rentalPricingSlice';
import RentalPricingTable from '../tables/RentalPrincingTable';
import RentalPricingFilters from '../details/RentalPricingFilters';
import DownloadSampleModal from '../modals/DownloadSampleModal';
import NewRentalPricingItemModal from '../modals/NewRentalPricingItemModal';
import DownloadRentalPricingModal from '../modals/DownloadRentalPricingModal';
import ImportRentalPricingCsvModal from '../modals/ImportRentalPricingCsvModal';
import SearchWithButton from '../../../../shared/search-with-button';
import Button from '../../../../shared/button';
import Toast from '../../../../shared/toast';


const RentalPricingTab = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [rows, setRows] = useState<Array<any>>([]);
    const [search, setSearch] = useState<string>();
    const [templateFilters, setTemplateFilters] = useState<Array<any>>();
    const [categorySelected, setCategorySelected] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [filterValues, setFilterValues] = useState<any>();
    const [showImportCsvModal, setShowImportCsvModal] = useState<boolean>(false);
    const [showDownloadSampleModal, setShowDownloadSampleModal] = useState<boolean>(false);
    const [showDownloadPricingModal, setShowDownloadPricingModal] = useState<boolean>(false);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [showNewItemModal, setShowNewItemModal] = useState<boolean>(false)
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state?.rentalPricing?.rentalPricings?.data?.page);
    const stateFilters = useAppSelector((state) => state.assetsTemplates);
    const state = useAppSelector((state) => state.rentalPricing);
    const priceLoading = useAppSelector((state) => state.rentalPricing.rentalsAreLoading)
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Rental Pricing')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

    const getMarketData = async () => {
        try {
            const marketResponse = await getAllMarkets(null).unwrap()
            const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id, displayName: market?.name }))
            setMarkets(formatMarkets || [])
            const findUK = formatMarkets && formatMarkets?.length > 0 && formatMarkets?.find((item: any) => (item?.displayName === 'GB' || item?.name === 'GB'))
            setSelectedMarket(findUK || markets?.[0] || undefined)
            onResetPaginationSettings()
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (stateFilters.allAssetTemplates) {
            const rows =
                stateFilters.allAssetTemplates && Array.isArray(stateFilters.allAssetTemplates)
                    ? stateFilters.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.displayName, label: r?.displayName, value: r?._id, }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
            const findPhones = rows?.length > 0 && rows?.find((item) => item?.name?.toLowerCase() === 'phones')
            setCategorySelected(findPhones || rows?.[0] || undefined)
            onResetPaginationSettings()
        }
    }, [stateFilters.allAssetTemplates]);

    useEffect(() => {
        if (stateFilters.assetTemplateFilters) {
            setTemplateFilters(stateFilters.assetTemplateFilters)
        }
    }, [stateFilters?.assetTemplateFilters]);

    useEffect(() => {
        if (state.rentalPricings) {
            const rowsData =
                state.rentalPricings?.data?.elements && Array.isArray(state.rentalPricings?.data.elements)
                    ? state.rentalPricings?.data.elements.map((r) => ({ ...r }))
                    : [];
            if (rowsData !== rows) {
                setRows(rowsData);
            }
        }
    }, [state.rentalPricings]);

    const onGettingPricingData = async () => {
        if (categorySelected && selectedMarket) {
            try {
                const filters = filterValues && Object.keys(filterValues)
                let formatFilters
                const filtersValuesFormat = filters && filters?.length > 0 && filters?.map((item: any) => {
                    return {
                        key: item,
                        value: Object.keys(filterValues) ? filterValues?.[item] || undefined : undefined
                    }
                })
                if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
                    formatFilters = {
                        value: filtersValuesFormat?.filter((key: any) => key?.value)
                    }
                }
                let data: any = {
                    filters: {
                        assetTemplateId: categorySelected?.value,
                    }
                }
                if (selectedMarket) {
                    data = {
                        ...data || {},
                        filters: {
                            ...data?.filters || {},
                            country: selectedMarket?.displayName
                        }
                    }
                }
                if (search) {
                    data = {
                        ...data,
                        filters: {
                            ...data?.filters || {},
                            search: search
                        }
                    }
                }
                if (formatFilters && formatFilters?.value) {
                    data = {
                        ...data,
                        filters: {
                            ...data?.filters || {},
                            ...formatFilters || {}
                        }
                    }
                }
                const fetchData = {
                    ...paginationState || {},
                    data
                }
                await dispatch(tryToFetchRentalPricings(fetchData)).unwrap()
            } catch (err) {
                // error here
            }
        }
    }

    useEffect(() => {
        getMarketData()
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    useEffect(() => {
        onGettingPricingData()
    }, [paginationState])

    useEffect(() => {
        if (categorySelected) {
            dispatch(tryToFetchAssetTemplateFilters({ id: categorySelected?.value, replacements: true }))
        }
        setFilterValues(undefined)
    }, [categorySelected])

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationSettings()
    }

    const onToggleDownloadSampleModal = () => {
        setShowDownloadSampleModal(!showDownloadSampleModal)
    }

    const onToggleDownloadPricingModal = () => {
        setShowDownloadPricingModal(!showDownloadPricingModal)
    }

    const onToggleImportCsvModal = () => {
        setShowImportCsvModal(!showImportCsvModal)
    }

    const onSelectFilter = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'market':
                    setSelectedMarket(value);
                    break;
                case 'category':
                    setCategorySelected(value)
                    break;
            }
        }
        onResetPaginationSettings()
    }

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        if (type) {
            const findField = templateFilters?.find((field) => field?.name === type);
            setFilterValues({
                ...(filterValues || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
            onResetPaginationSettings()
        }
    };

    const onChangeFilterInput = (value: string | null | undefined, type: string) => {
        const findField = templateFilters?.find((field) => field?.name === type);
        setFilterValues({
            ...(filterValues || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
        onResetPaginationSettings()
    };

    const onImportSuccessfully = () => {
        // onGettingPricingData()
    }

    const onClearAllFilters = () => {
        setFilterValues(undefined)
        onResetPaginationSettings()
    }

    const onClickRentalSettings = () => {
        navigate('/rental-pricing/settings')
    }

    const onClickAddNewItem = () => {
        setShowNewItemModal(true)
    }

    const onSaveNewItemsSuccessfully = (type: string, message: string) => {
        setShowToast({ type, message })
        onResetPaginationSettings()
    }

    return (
        <div>
            <div className='mb-7 mt-3'>
                <RentalPricingFilters
                    markets={markets}
                    sectors={sectors}
                    selectedMarket={selectedMarket}
                    selectedSector={categorySelected}
                    items={templateFilters || []}
                    filterValues={filterValues || null}
                    showCatalogFilters={categorySelected && templateFilters && templateFilters?.length > 0}
                    onSelectFilter={onSelectFilter}
                    onChangeInput={onChangeFilterInput}
                    onChangeSelectedValue={onChangeSelectedFilterValue}
                    onClearAllFilters={onClearAllFilters}
                />
            </div>
            <div className='my-5 flex flex-row justify-end flex-wrap'>
                <Button
                    label={'Rental Price Settings'}
                    dataQa={'rental-price-settings'}
                    className={`${(!accessControl || pageAccess?.actions?.['Rental Price Settings']) ? 'btn-main' : 'btn-main-disable'} min-w-[200px] mr-2 mb-1`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Rental Price Settings']) && onClickRentalSettings()}
                />
                <Button
                    label={'Submit New Item'}
                    dataQa={'submit-new-item'}
                    className={`${(!accessControl || pageAccess?.actions?.['Submit New Item']) ? 'btn-main' : 'btn-main-disable'} min-w-[200px] mr-2 mb-1`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Submit New Item']) && onClickAddNewItem()}
                />
            </div>
            <div className='mb-4'>
                <div className='bg-white pl-4'>
                    <SearchWithButton
                        buttonLabel='Import Rental Pricing'
                        placeholder='Filter table'
                        onChangeSearchValue={onChangeSearchValue}
                        search={search}
                        showScndBtn={true}
                        showThirdBtn={true}
                        scndButtonLabel={'Download Catalog'}
                        thirdButtonLabel={'Download Rental Pricing'}
                        onClickThirdButton={() => (!accessControl || pageAccess?.actions?.['Download Rental Pricing']) && onToggleDownloadPricingModal()}
                        onClickSecondButton={() => (!accessControl || pageAccess?.actions?.['Download Catalog']) && onToggleDownloadSampleModal()}
                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Rental Pricing']) && onToggleImportCsvModal()}
                        scndButtonStyle={`${(!accessControl || pageAccess?.actions?.['Download Catalog']) ? 'btn-main' : 'btn-main-disable'} col-span-10 lg:col-span-2 pb-2 mb-1 lg:mb-0 mr-0 lg:mr-2 !min-w-[90%]`}
                        thirdButtonStyle={`${(!accessControl || pageAccess?.actions?.['Download Rental Pricing']) ? 'btn-main' : 'btn-main-disable'} col-span-10 lg:col-span-2 pb-2 mb-1 lg:mb-0 mr-0 lg:mr-2 !min-w-[90%]`}
                        buttonStyle={`${(!accessControl || pageAccess?.actions?.['Import Rental Pricing']) ? 'btn-main' : 'btn-main-disable'} col-span-10 lg:col-span-2 pb-2 mb-1 lg:mb-0 !min-w-[90%]`}
                        mainContainerStyle={'!grid-cols-10'}
                    />
                </div>
                <div>
                    <RentalPricingTable
                        rows={rows}
                        pricingLoading={priceLoading}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        state={state}
                        activeMarket={selectedMarket}
                    />
                </div>
                {showDownloadSampleModal &&
                    <DownloadSampleModal
                        openDownloadModal={showDownloadSampleModal}
                        handleCloseDownloadModal={onToggleDownloadSampleModal}
                        sectors={sectors || []}
                    />
                }
                {showDownloadPricingModal &&
                    <DownloadRentalPricingModal
                        openDownloadModal={showDownloadPricingModal}
                        handleCloseDownloadModal={onToggleDownloadPricingModal}
                        sectors={sectors || []}
                        markets={markets || []}
                    />
                }
                {showImportCsvModal &&
                    <ImportRentalPricingCsvModal
                        openImportModal={showImportCsvModal}
                        handleCloseModal={onToggleImportCsvModal}
                        onImportSuccessfully={onImportSuccessfully}
                        sectors={sectors || []}
                        markets={markets || []}
                    />
                }
                {showToast?.message &&
                    <Toast
                        type={showToast?.type}
                        text={showToast?.message}
                        onHandleCloseToast={() => setShowToast(null)}
                    />
                }
                {showNewItemModal &&
                    <NewRentalPricingItemModal
                        openModal={showNewItemModal}
                        markets={markets}
                        sectors={sectors}
                        handleCloseModal={() => setShowNewItemModal(false)}
                        onSaveSuccessfully={onSaveNewItemsSuccessfully}
                    />
                }
            </div>
        </div>
    )
}

export default RentalPricingTab;