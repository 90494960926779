import React from 'react';
import { AdminGuard } from '../../../../guards/RolesGuard';
import WhiteContainer from '../../../../shared/white-container';


const WholesaleItemLoading = () => {
    const isAdmin = AdminGuard()
    return (
        <WhiteContainer containerStyle='max-w-[90%]' type='rounded'>
            <div className='w-full h-full flex flex-col items-start justify-start'>
                <div className='font-bold text-[17px] mt-2 mb-5'>
                    <div className='h-[15px] bg-gray-200 rounded-full dark:bg-gray-200 w-[150px] mb-1'></div>
                </div>
                <div>
                    <div className='flex flex-row item-center mb-4'>
                        <img src={'/assets/shared/category.svg'} className={'w-[14px] object-contain mr-3'} />
                        <div className='flex flex-row items-center'>
                            <p>Category: </p>
                            <div className='h-[15px] bg-gray-200 rounded-full dark:bg-gray-200 w-[150px] ml-1'></div>
                        </div>
                    </div>
                    {(isAdmin) &&
                        <div className='flex flex-row item-center mb-4'>
                            <img src={'/assets/shared/broker.svg'} className={'w-[16px] object-contain mr-3'} />
                            <div className='flex flex-row items-center'>
                                <p>Broker: </p>
                                <div className='h-[15px] bg-gray-200 rounded-full dark:bg-gray-200 w-[150px] ml-1'></div>
                            </div>
                        </div>
                    }
                    <div className='flex flex-row item-end mb-4'>
                        <div className={`${'bg-yellow-400'} mt-1 self-center inline-block w-[15px] h-[15px] rounded-full mr-3`}></div>
                        <div className='flex flex-row items-center'>
                            <p>Status: </p>
                            <div className='h-[15px] bg-gray-200 rounded-full dark:bg-gray-200 w-[150px] ml-1'></div>
                        </div>
                    </div>
                    <div className='flex flex-row item-center mb-4'>
                        <img src={'/assets/shared/pricing-type.svg'} className={'w-[14px] object-contain mr-3'} />
                        <div className='flex flex-row items-center'>
                            <p>Type: </p>
                            <div className='h-[15px] bg-gray-200 rounded-full dark:bg-gray-200 w-[150px] ml-1'></div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row justify-between items-center min-w-[100%] mt-5'>
                    <div className='h-[13px] bg-gray-200 rounded-full dark:bg-gray-200 w-[60px] mb-2'></div>
                    <div className='h-[13px] bg-gray-200 rounded-full dark:bg-gray-200 w-[60px] mb-2'></div>
                    <div className='h-[13px] bg-gray-200 rounded-full dark:bg-gray-200 w-[60px] mb-2'></div>
                </div>

            </div>
        </WhiteContainer >
    )
}

export default WholesaleItemLoading;