import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../../config';
import orderAxios from '../../../../utils/axios/orders.axios';
import { PaginatedItems } from '../../../../interfaces/items/paginatedStore.type';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
const tryToFetchShippedItems = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    status?: string,
    data?: { marketName: string },
    category?: string
): Promise<AxiosResponse<PaginatedItems>> => {
    const url = filters
        ? `${ORDERS_URL}/rma/broker-items?page=${pageNumber}&size=${pageSize}&filter=${filters}&marketCountry=${data?.marketName}`
        : `${ORDERS_URL}/rma/broker-items?page=${pageNumber}&size=${pageSize}&marketCountry=${data?.marketName}`;
    let finalUrl = status ? `${url}&status=${status}` : url
    if(category) finalUrl = `${finalUrl}&category=${category}`
    return orderAxios.get<PaginatedItems>(finalUrl);
};

const tryToCreateRMA = (data: any, brokerId: string) => {
    const url = `${ORDERS_URL}/rma`;
    return orderAxios.post<any>(url, { items: data, brokerId });
};
const tryToFetchAllRMAReasons = (): Promise<AxiosResponse<PaginatedItems>> => {
    const url = `${ORDERS_URL}/rma/reasons/broker`;
    return orderAxios.get<PaginatedItems>(url);
};

const tryToFetchAllCreatedRMAs = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    status?: string,
    data?: { marketName: string }
): Promise<AxiosResponse<any>> => {
    const url = filters
        ? `${ORDERS_URL}/rma/brokers?page=${pageNumber}&size=${pageSize}&filter=${filters}&marketCountry=${data?.marketName}`
        : `${ORDERS_URL}/rma/brokers?page=${pageNumber}&size=${pageSize}&marketCountry=${data?.marketName}`;
    const finalUrl = status ? `${url}&status=${status}` : url
    return orderAxios.get<any>(finalUrl);
};

const tryToFetchSingleRMADetails = (
    id: string,
    { pageNumber, pageSize, filters }: IPaginationPayload): Promise<AxiosResponse<any>> => {
    const url = filters ? `${ORDERS_URL}/rma/brokers/${id}?page=${pageNumber}&size=${pageSize}&filter=${filters}`
        : `${ORDERS_URL}/rma/brokers/${id}?page=${pageNumber}&size=${pageSize}`
    return orderAxios.get<any>(url);
};

const tryToFetchItemsToShip = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: {marketName: string},
    category?: string
): Promise<AxiosResponse<PaginatedItems>> => {
    let url = filters
        ? `${ORDERS_URL}/rma/get-by-status?status=approved_return&page=${pageNumber}&size=${pageSize}&filter=${filters}&marketCountry=${data?.marketName}`
        : `${ORDERS_URL}/rma/get-by-status?status=approved_return&page=${pageNumber}&size=${pageSize}&marketCountry=${data?.marketName}`;

    if(category) url = `${url}&category=${category}`
    return orderAxios.get<PaginatedItems>(url);
};

const shipItems = (data: { itemIds: string[], shippingProvider: string, trackingNumber: string }) => {
    const url = `${ORDERS_URL}/rma/shipping-details`;
    return orderAxios.put(url, data);
};


const tryToAcceptRevaluation = (id: string): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/rma/accept-offer/${id}`
    return orderAxios.put(url)
}

const tryToRejectRevaluation = (id: string): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/rma/reject-offer/${id}`
    return orderAxios.put(url)
}

const tryToAcceptRevaluationFromIMS = (rmaId: string, id: string): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/rma/${rmaId}/item/${id}/accept-revaluation/broker`
    return orderAxios.put(url)
}

const tryToRejectRevaluationFromIMS = (rmaId:string, id: string): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/rma/${rmaId}/item/${id}/reject-revaluation/broker`
    return orderAxios.put(url)
}

export const partnerRMAsService = {
    tryToFetchShippedItems,
    tryToCreateRMA,
    tryToFetchAllRMAReasons,
    tryToFetchAllCreatedRMAs,
    tryToFetchSingleRMADetails,
    tryToFetchItemsToShip,
    shipItems,
    tryToAcceptRevaluation,
    tryToRejectRevaluation,
    tryToAcceptRevaluationFromIMS,
    tryToRejectRevaluationFromIMS
};
