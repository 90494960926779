import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RentalPricingService } from './rentalPricingApi';
import { IAssets } from '../../../../interfaces/assets/IAssets';
import { IAssetSchema } from '../../../../interfaces/assets/IAssetsSchema';
import { PaginatedAsset } from '../../../../interfaces/assets/paginatedStore.type';
import { IPaginationPayload, IPaginationPayloadWithId } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IRentalPricingListState } from '../../../../interfaces/rental-pricing/IRentalPricingList';
import { PaginatedRentalPricing } from '../../../../interfaces/rental-pricing/paginatedStore.type';


const initialState: IRentalPricingListState = {
    assetIsLoading: false,
    assetsAreLoading: false,
    rentalIsLoading: false,
    rentalsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchRentalAssets = createAsyncThunk<
    PaginatedAsset,
    IPaginationPayloadWithId
>('rental-pricing/tryToFetchRentalAssets',
    async ({ pageNumber, pageSize, filters, id }) => {
        const result = await RentalPricingService.tryToFetchRentalAssets(
            pageNumber,
            pageSize,
            id,
            filters,
        );
        return result.data;
    },
);

export const tryToFetchSingleRentalAsset = createAsyncThunk<IAssets, any>(
    'rental-pricing/tryToFetchSingleRentalAsset',
    async ({ id, assetId }) => {
        const result = await RentalPricingService.tryToFetchSingleRentalAsset(id, assetId);
        return result?.data?.data;
    },
);

export const tryToAddRentalAsset = createAsyncThunk<ApiResponse<IAssetSchema>, IAssets>(
    'rental-pricing/tryToAddRentalAsset',
    async (data: IAssets, { rejectWithValue }) => {
        try {
            const result = await RentalPricingService.tryToAddRentalAsset(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditRentalAsset = createAsyncThunk<ApiResponse<IAssetSchema>, IAssets>(
    'rental-pricing/tryToEditRentalAsset',
    async (data: IAssets, { rejectWithValue }) => {
        try {
            const result = await RentalPricingService.tryToEditRentalAsset(
                data._id || '',
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteRentalAsset = createAsyncThunk<ApiResponse<any>, any>(
    'rental-pricing/tryToDeleteRentalAsset',
    async (assetId, { rejectWithValue }) => {
        try {
            const results = await RentalPricingService.tryToDeleteRentalAsset(assetId);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToImportRentalAssetData = createAsyncThunk<ApiResponse<IAssetSchema[]>, { assetTemplateId: string; assets: Array<any> }>(
    'rental-pricing/tryToImportRentalAssetData',
    async (data: { assetTemplateId: string; assets: Array<any> }, { rejectWithValue }) => {
        try {
            const result = await RentalPricingService.tryToImportRentalAssetData(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDownloadRentalAssets = createAsyncThunk<any, any>(
    'rental-pricing/tryToDownloadRentalAssets',
    async ({ id, pricing }) => {
        const result = await RentalPricingService.tryToDownloadRentalAssets(id, pricing);
        return result?.data;
    },
);

export const tryToDeleteCatalogRentalAssets = createAsyncThunk<ApiResponse<any>, any>(
    'rental-pricing/tryToDeleteCatalogRentalAssets',
    async (id, { rejectWithValue }) => {
        try {
            const results = await RentalPricingService.tryToDeleteCatalogRentalAssets(id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToFetchRentalAssetFieldValuesDependent = createAsyncThunk<any, any>(
    'rental-pricing/tryToFetchRentalAssetFieldValuesDependent',
    async ({ id, data, replacements }) => {
        const result = await RentalPricingService.tryToFetchRentalAssetFieldValuesDependent(id, data, replacements);
        return result?.data?.data;
    },
);

// --------------------------- Rental Pricing ----------------------------

export const tryToFetchRentalPricings = createAsyncThunk<PaginatedRentalPricing, IPaginationPayload>(
    'rental-pricing/tryToFetchRentalPricings',
    async ({ pageNumber, pageSize, data }) => {
        const result = await RentalPricingService.tryToFetchRentalPricings(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToDownloadRentalPricing = createAsyncThunk<any, { templateId: string, market: string }>(
    'rental-pricing/tryToDownloadRentalPricing',
    async ({ templateId, market }) => {
        return await RentalPricingService.tryToDownloadRentalPricing(templateId, market);
    },
);

export const tryToUploadRentalPricingCSV = createAsyncThunk<Promise<AxiosResponse<void>>, any>(
    'rental-pricing/tryToUploadRentalPricingCSV',
    async (data, { rejectWithValue }) => {
        try {
            return await RentalPricingService.tryToUploadRentalPricingCSV(data);
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const rentalPricingSlice = createSlice({
    name: 'rentalPricing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchRentalAssets
            .addCase(tryToFetchRentalAssets.pending, (state) => {
                state.assetsAreLoading = true;
            })
            .addCase(tryToFetchRentalAssets.rejected, (state) => {
                state.assetsAreLoading = false;
            })
            .addCase(tryToFetchRentalAssets.fulfilled, (state, action) => {
                state.assetsAreLoading = false;
                state.rentalAssets = action.payload;
            })

            // tryToFetchSingleRentalAsset
            .addCase(tryToFetchSingleRentalAsset.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToFetchSingleRentalAsset.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToFetchSingleRentalAsset.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                state.rentalAssetDetails = action.payload;
            })

            // tryToAddRentalAsset
            .addCase(tryToAddRentalAsset.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToAddRentalAsset.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToAddRentalAsset.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.rentalAssets?.data)
                    state.rentalAssets.data.elements = [
                        ...state.rentalAssets?.data.elements,
                        data,
                    ];
            })

            // tryToImportRentalAssetData
            .addCase(tryToImportRentalAssetData.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToImportRentalAssetData.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToImportRentalAssetData.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.rentalAssets?.data)
                    state.rentalAssets.data.elements = [
                        ...(data || []),
                        ...state.rentalAssets?.data.elements,
                    ];
            })

            // tryToEditRentalAsset
            .addCase(tryToEditRentalAsset.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToEditRentalAsset.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToEditRentalAsset.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                if (state.rentalAssets?.data.elements)
                    state.rentalAssets.data.elements =
                        { ...state }.rentalAssets?.data.elements.map((asset: any) => {
                            if (asset?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return asset;
                            }
                        }) || [];
                state.rentalAssetDetails = action.payload.data;
            })

            // tryToDeleteRentalAsset
            .addCase(tryToDeleteRentalAsset.pending, (state) => {
                state.assetIsLoading = true;
            })
            .addCase(tryToDeleteRentalAsset.rejected, (state) => {
                state.assetIsLoading = false;
            })
            .addCase(tryToDeleteRentalAsset.fulfilled, (state, action) => {
                state.assetIsLoading = false;
                state.requestStatus = 'success';
                if (state.rentalAssets?.data.elements)
                    state.rentalAssets.data.elements =
                        { ...state }.rentalAssets?.data.elements.filter(
                            (asset: any) => asset?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToFetchRentalPricings
            .addCase(tryToFetchRentalPricings.pending, (state) => {
                state.rentalsAreLoading = true;
            })
            .addCase(tryToFetchRentalPricings.rejected, (state) => {
                state.rentalsAreLoading = false;
            })
            .addCase(tryToFetchRentalPricings.fulfilled, (state, action) => {
                state.rentalsAreLoading = false;
                state.rentalPricings = action.payload;
            })
    },
});

export default rentalPricingSlice.reducer;
