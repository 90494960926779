import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch } from '../../../../app/hooks';
import { IPricing } from '../../../../interfaces/pricing/IPricing';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IPageAccess } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { usePricingTemplateMutation } from '../../../../store/brokers/partners/download-templates/downloadTemplatesApi';
import { tryToDownloadPricingAdmin } from '../../../../store/brokers/partners/pricing/pricingSlice';
import ImportPricingCsvModal from '../modals/ImportPricingCsvModal';
import AdminPricingTable from '../tables/AdminPricingTable';
import Button from '../../../../shared/button';


interface IDetailsStep {
    rows: IPricing[];
    paginationData?: IPage;
    markets?: IAllMarkets[];
    sectors?: IAssetTemplate[];
    pricingLoading: boolean;
    state: any;
    accessControl?: IAccessControl;
    pageAccess?: IPageAccess;
    marketSelected?: any;
    templateSelected?: any;
    partnerSelected?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const DetailsStep = ({
    rows,
    paginationData,
    markets,
    state,
    accessControl,
    pageAccess,
    marketSelected,
    templateSelected,
    pricingLoading,
    sectors,
    partnerSelected,
    setPaginationState,
}: IDetailsStep) => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const csvLink2 = React.useRef() as React.MutableRefObject<any>;
    const [dataPricing, setDataPricing] = useState<any>();
    const [templateData, setTemplateData] = useState<any>();
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [showImportCsvModal, setShowImportCsvModal] = useState<boolean>(false);
    const [loadingDownloadPricing, setLoadingDownloadPricing] = useState<boolean>(false);
    const [partnerPricingTemplateMutation] = usePricingTemplateMutation();

    const onDownloadPricing = async () => {
        setLoadingDownloadPricing(true)
        try {
            const formatData = {
                brokerId: partnerSelected?.value || '',
                data: {
                    filters: {
                        country: marketSelected ? marketSelected?.displayName : '',
                        assetTemplateId: templateSelected?.value,
                        download: true
                    }
                }
            }
            const response = await dispatch(tryToDownloadPricingAdmin(formatData)).unwrap()
            setDataPricing(response || [])
        } catch (err) {
            // error here
            setLoadingDownloadPricing(false)
        }
    }

    const onDownloadTemplate = async () => {
        setLoadingDownload(true)
        try {
            const response = await partnerPricingTemplateMutation({ assetTemplateId: templateSelected?.value || '' }).unwrap()
            setTemplateData(response || [])
        } catch (err) {
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (dataPricing && loadingDownloadPricing) {
            csvLink2.current.link.click()
            setLoadingDownloadPricing(false)
        }
    }, [dataPricing])

    useEffect(() => {
        if (templateData && loadingDownload) {
            csvLink.current.link.click()
            setLoadingDownload(false)
        }
    }, [templateData])

    const onToggleImportCsvModal = () => {
        setShowImportCsvModal(!showImportCsvModal)
    }

    return (
        <div className='pr-5'>
            <div className='mt-7 mb-3'>
                <div>
                    <div className={`min-w-full py-1 px-2 flex flex-row justify-end items-center bg-[#fefefe]`}>
                        <Button
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Download Sample']) && onDownloadTemplate()}
                            label={loadingDownload ? 'Loading data...' : 'Download Sample'}
                            dataQa={loadingDownload ? 'loading-data...' : 'download-sample'}
                            className={`${(!accessControl || pageAccess?.actions?.['Download Sample']) ? 'btn-main' : 'btn-main-disable'} flex flex-row align-start my-2 !py-2`}
                        />
                        <CSVLink
                            ref={csvLink}
                            data={(templateData && templateData?.length > 0) && templateData?.filter((item: any, idx: number) => idx > 0) || []}
                            headers={templateData?.[0] ? [...templateData?.[0], 'pricing'] : []}
                            className={'none'}
                            target={'_blank'}
                            filename={`Pricing-catalog-sample.csv`}
                        >
                        </CSVLink>
                        <Button
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Download Pricing']) && onDownloadPricing()}
                            label={loadingDownloadPricing ? 'Loading data...' : 'Download Pricing'}
                            dataQa={loadingDownloadPricing ? 'loading-data...' : 'download-pricing'}
                            className={`${(!accessControl || pageAccess?.actions?.['Download Pricing']) ? 'btn-main' : 'btn-main-disable'} flex flex-row align-start my-2 ml-2 !py-2`}
                        />
                        <CSVLink
                            ref={csvLink2}
                            data={dataPricing?.filter((item: any, idx: number) => idx > 0) || []}
                            headers={dataPricing?.[0] || []}
                            className={'none'}
                            target={'_blank'}
                            filename={`Pricing-admin-broker.csv`}
                        >
                        </CSVLink>
                        <Button
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Pricing']) && onToggleImportCsvModal()}
                            label={'Import Pricing'}
                            dataQa={'import-pricing'}
                            className={`${(!accessControl || pageAccess?.actions?.['Import Pricing']) ? 'btn-main' : 'btn-main-disable'} flex flex-row align-start my-2 ml-2 !py-2`}
                        />
                    </div>
                    <AdminPricingTable
                        rows={rows}
                        paginationData={paginationData}
                        pricingLoading={pricingLoading}
                        markets={markets}
                        state={state}
                        selectedMarket={marketSelected?.value || ''}
                        setPaginationState={setPaginationState}
                    />
                    <ImportPricingCsvModal
                        openImportModal={showImportCsvModal}
                        handleCloseModal={onToggleImportCsvModal}
                        sectors={sectors || []}
                        partnerId={partnerSelected?.value || ''}
                        marketSelected={marketSelected?.value}
                    />
                </div>
            </div>
        </div>
    )
}
export default DetailsStep;