import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToEditPartnerTrustpilot } from '../../../../store/brokers/admin/partners/partnersSlice';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';
import Button from '../../../../shared/button';


interface IPartnerTrustpilotModal {
    id: string;
    openModal: boolean;
    actualValue?: any;
    onSuccessfullySaved: () => void;
    handleCloseModal: () => void;
}

const PartnerTrustpilotModal = ({
    id,
    openModal,
    actualValue,
    onSuccessfullySaved,
    handleCloseModal,
}: IPartnerTrustpilotModal) => {
    const dispatch = useAppDispatch();
    const [trustValue, setTrustValue] = useState<any>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [saveError, setSaveError] = useState<string>()

    useEffect(() => {
        if (actualValue && trustValue !== actualValue) {
            setTrustValue(actualValue)
        }
    }, [id, openModal, actualValue])

    const onSubmitData = async () => {
        setSaveError(undefined)
        setLoadingSubmit(true);
        try {
            const formatData = {
                trustPilotKey: trustValue || trustValue === 0 ? parseFloat(trustValue) : undefined
            }
            await dispatch(tryToEditPartnerTrustpilot({ id: id, data: formatData })).unwrap();
            onSuccessfullySaved();
            handleCloseModal();
        } catch (err) {
            setSaveError(`${err}`);
        }
        setLoadingSubmit(false);
    };

    const onChangeTrustValue = (value: string | null | undefined, type: string) => {
        setTrustValue(value ? (/^\d*\.?\d*$/).test(value) ? value : trustValue : undefined)
    }

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            contentContainerStyle='min-w-[450px]'>
            <div className={'p-2 min-w-[400px]'}>
                <p className='mt-2 mb-10'>Partner Trustpilot Score</p>
                {saveError &&
                    <div className='flex flex-row justify-start my-2'>
                        <Error text={saveError} />
                    </div>
                }
                <Input
                    label='Score'
                    dataQa={'score'}
                    showValue={true}
                    inputStyle={'max-w-[90%] mb-1'}
                    inputValue={trustValue}
                    onChangeInput={onChangeTrustValue}
                />
                <div className='flex flex-row justify-center mt-10 mb-4'>
                    <Button
                        className={loadingSubmit ? 'btn-main-disable' : 'btn-main'}
                        label='Save Changes'
                        dataQa={'save-changes'}
                        onClickButton={() => !loadingSubmit && onSubmitData()}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default PartnerTrustpilotModal;