import React from 'react';
import moment from 'moment';
import Button from '../../shared/button';


interface INotificationMessage {
    data: any;
    action: string;
    type: string;
    createdAt: string;
    readStatus: string;
    onClickSeeDetails: (id: string, type?: string, action?: string) => void;
}

const NotificationMessage = ({
    data,
    createdAt,
    action,
    type,
    readStatus,
    onClickSeeDetails
}: INotificationMessage) => {

    const onFormatTitle = (type: string, action: string) => {
        let message = '';
        if (type === 'purchase-request' && action === 'offer_submitted') {
            message = 'Offer Available for Your Purchase Order'
        }
        if (type === 'sale-request' && action === 'offer_submitted') {
            message = 'Offer Available for Your Sales Order'
        }
        if (type === 'purchase-request' && action === 'offer_accepted') {
            message = 'Offer Accepted for Your Purchase Order'
        }
        if (type === 'sale-request' && action === 'offer_accepted') {
            message = 'Offer Accepted for Your Sales Order'
        }
        if (type === 'purchase-request' && action === 'offer_rejected') {
            message = 'Offer Rejected for Your Purchase Order'
        }
        if (type === 'sale-request' && action === 'offer_rejected') {
            message = 'Offer Rejected for Your Sales Order'
        }
        if (type === 'sale-order' && action === 'invoice_generated') {
            message = 'Invoice Available for Your Purchase Order'
        }
        if (type === 'sale-order' && action === 'proforma_generated') {
            message = 'Proforma Available for Your Purchase Order'
        }
        return message
    }

    const onFormatMessage = (type: string, action: string) => {
        let message: any;
        if (type === 'purchase-request' && action === 'offer_submitted') {
            message = <p className='text-[13px]'>An offer has been made for your purchase order, <span className='font-bold'>{data?.quoteId || data?._id || ''}</span>. Click here to view the offer details.</p>
        }
        if (type === 'sale-request' && action === 'offer_submitted') {
            message = <p className='text-[13px]'>An offer has been made for your sales order, <span className='font-bold'>{data?.quoteId || data?._id || ''}</span>. Click here to view the offer details.</p>
        }
        if (type === 'purchase-request' && action === 'offer_accepted') {
            message = <p className='text-[13px]'>Your offer has been accepted for your purchase order, <span className='font-bold'>{data?.quoteId || data?._id || ''}</span>. Click here to view the offer details.</p>
        }
        if (type === 'sale-request' && action === 'offer_accepted') {
            message = <p className='text-[13px]'>Your offer has been accepted for your sales order, <span className='font-bold'>{data?.quoteId || data?._id || ''}</span>. Click here to view the offer details.</p>
        }
        if (type === 'purchase-request' && action === 'offer_rejected') {
            message = <p className='text-[13px]'>Your offer has been rejected for your purchase order, <span className='font-bold'>{data?.quoteId || data?._id || ''}</span>. Click here to view the offer details.</p>
        }
        if (type === 'sale-request' && action === 'offer_rejected') {
            message = <p className='text-[13px]'>Your offer has been rejected for your sales order, <span className='font-bold'>{data?.quoteId || data?._id || ''}</span>. Click here to view the offer details.</p>
        }
        if (type === 'sale-order' && action === 'invoice_generated') {
            message = <p className='text-[13px]'>Invoice has been generated for your purchase order, <span className='font-bold'>{data?.quoteId || data?.purchaseRequestId || ''}</span>. Click here to view the invoice.</p>
        }
        if (type === 'sale-order' && action === 'proforma_generated') {
            message = <p className='text-[13px]'>Proforma has been generated for your purchase order, <span className='font-bold'>{data?.quoteId || data?.purchaseRequestId || ''}</span>. Click here to view the proforma.</p>
        }
        return message
    }

    return (
        <div className='bg-[#f8f9fc] !rounded p-2 my-1 grid grid-cols-8'>
            <div className='col-span-5'>
                <p className={'text-primary-light mb-1'}>{onFormatTitle(type, action)}</p>
                {onFormatMessage(type, action)}
                <Button
                    label={'View Details'}
                    className='btn-primary-text-underline !text-[13px] !pl-0 !ml-0'
                    onClickButton={() => onClickSeeDetails(type === 'sale-order' ? action === 'invoice_generated' ? data?.invoiceData?.pdfLink : data?.proFormData?.pdfLink : data?.quoteId || data?._id, type, action)}
                />
            </div>
            <div className='col-span-3'>
                <p className='font-bold text-[13px] mb-2'>System Message</p>
                <p className='text-primary-light text-[12px]'>{moment(createdAt)?.format('h:mm:ss, dddd DD/MM/YYYY')}</p>
                <div className='flex flex-row items-center'>
                    <div className={`w-3 h-3 ${(readStatus || '').toLowerCase() === 'unread' ? 'bg-[#e50000]' : 'bg-[#00b67a]'} rounded-full mr-2`}></div>
                    <p className='text-primary-light text-[13px]'>{(readStatus || '').toLowerCase() === 'unread' ? 'Unread' : 'Read'}</p>
                </div>
            </div>
        </div>
    )
}

export default NotificationMessage;