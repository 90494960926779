import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddCommissionFee,
    tryToEditCommissionFee,
    tryToFetchSingleCommissionFee
} from '../../../../store/brokers/admin/commission-fees/commissionFeesSlice';
import { tryToFetchPartnerCatalogs } from '../../../../store/brokers/admin/partners/partnersSlice';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface ICommissionFeeActionsModal {
    partners?: any[];
    sectors?: any[];
    payServices?: any[];
    openActionsModal: boolean;
    commissionFeeItemId?: string | null;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    assetTemplateId: string;
    payServices: any;
    partnerId: string;
    commissionFee: number;
    minValue: number;
    maxValue: number;
};

const CommissionFeeActionsModal = ({
    openActionsModal,
    commissionFeeItemId,
    partners,
    payServices,
    handleCloseModal,
    onSaveChanges,
}: ICommissionFeeActionsModal) => {
    const dispatch = useAppDispatch();
    const [selectedPartner, setSelectedPartner] = useState<any>();
    const [selectedSector, setSelectedSector] = useState<any>();
    const [selectedPayService, setSelectedPayService] = useState<any>();
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [saveError, setSaveError] = useState<string>()
    const commisssionFeeLoading = useAppSelector((state) => state.commissionFees?.commissionIsLoading);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state) => state.commissionFees);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const onGetPartnerSectors = async () => {
        const response: any = await dispatch(tryToFetchPartnerCatalogs(selectedPartner?.username || '')).unwrap()
        if (response?.data) {
            const formatSectors = response?.data?.length > 0 && response?.data?.map((category: any) => ({ ...category, label: category?.displayName, name: category?.displayName, value: category?._id }))
            setSectors(formatSectors || [])
        }
    }

    useEffect(() => {
        if (selectedPartner) {
            onGetPartnerSectors()
        }
    }, [selectedPartner])

    useEffect(() => {
        setValue('partnerId', commissionFeeItemId ? state?.commissionFee?.partner?.id || '' : '');
        setValue('payServices', commissionFeeItemId ? state?.commissionFee?.payServices?.map((item: any) => item?.id) || [] : []);
        setValue('assetTemplateId', commissionFeeItemId ? state?.commissionFee?.assetTemplate?.id || '' : '');
        setValue('commissionFee', commissionFeeItemId ? state?.commissionFee?.commissionFee || 0 : 0);
        setValue('minValue', commissionFeeItemId ? state?.commissionFee?.minValue || 0 : 0);
        setValue('maxValue', commissionFeeItemId ? state?.commissionFee?.maxValue || 0 : 0);
        if (commissionFeeItemId) {
            const findPartner = partners && partners?.length > 0 && partners?.find((item) => item?._id === state?.commissionFee?.partner?.id)
            setSelectedPartner(findPartner || undefined)
            const payServiceIds = (state?.commissionFee?.payServices && state?.commissionFee?.payServices?.length > 0) && state?.commissionFee?.payServices?.map((item: any) => item?.id)
            const findPayService = (payServices && payServices?.length > 0) && payServices?.filter((item) => payServiceIds.includes(item?._id))
            setSelectedPayService(findPayService || undefined)
            const findSector = sectors && sectors?.length > 0 && sectors?.find((item) => item?._id === state?.commissionFee?.assetTemplate?.id)
            setSelectedSector(findSector || undefined)
        }
    }, [state.commissionFee]);

    useEffect(() => {
        if (commissionFeeItemId) {
            dispatch(tryToFetchSingleCommissionFee(commissionFeeItemId));
        }
    }, [commissionFeeItemId]);

    const onCloseModal = () => {
        reset();
        setSelectedPartner(undefined)
        setSelectedPayService(undefined)
        setSelectedSector(undefined)
        setSaveError(undefined)
        handleCloseModal();
    };

    const onSubmit = async (data: FormValues) => {
        if (!selectedSector || !selectedPartner || (!selectedPayService || !(selectedPayService?.length > 0))) {
            setSaveError('Please select all fields to continue')
            return;
        }
        const payload: any = {
            ...data,
            payServices: selectedPayService?.length > 0 && selectedPayService?.map((item: any) => item?._id),
            minValue: Number(data?.minValue || 0),
            maxValue: Number(data?.maxValue || 0),
            commissionFee: Number(data?.commissionFee || 0)
        };
        try {
            if (commissionFeeItemId) {
                payload._id = commissionFeeItemId;
                await dispatch(tryToEditCommissionFee(payload)).unwrap();
            } else {
                await dispatch(tryToAddCommissionFee(payload)).unwrap();
            }
            onSaveChanges('success', `Commission Fee successfully ${commissionFeeItemId ? 'changed' : 'added'}.`);
        } catch (error) {
            onSaveChanges('error', `${error}`);
        }
        reset();
        onCloseModal();
    };

    const onChangeSelectedItems = (e: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'partner':
                    setSelectedPartner(e || undefined)
                    setValue('partnerId', e?.value)
                    break;
                case 'sector':
                    setSelectedSector(e || undefined)
                    setValue('assetTemplateId', e?.value)
                    break;
                case 'payServices':
                    setSelectedPayService(e || undefined)
                    setValue('payServices', e?.value && e?.value?.length > 0 && e?.value?.map((item: any) => item?._id))
                    break;
            }
        }
    };

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(
            e || undefined,
        );
        setValue('assetTemplateId', e?.value)
    };

    return (
        <Modal
            open={openActionsModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>Commission Fee</p>
                {saveError && <Error text={saveError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(commissionFeeItemId && !commisssionFeeLoading) || !commissionFeeItemId ? (
                        <div className='flex flex-col my-4'>
                            <SelectCheckbox
                                name='Partner'
                                key={1}
                                placeholder=' '
                                dataQa={`${(selectedPartner?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                                disabled={!!commissionFeeItemId}
                                containerStyle='mb-1'
                                error={errors?.partnerId?.message}
                                selectedOption={selectedPartner}
                                onChangeSelectedOption={(e) => onChangeSelectedItems(e, 'partner')}
                                options={partners || []}
                            />
                            <SelectCheckbox
                                name='Pay Services'
                                key={2}
                                placeholder=' '
                                dataQa={`${(selectedPayService?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                                containerStyle='mb-1'
                                // disabled={!!commissionFeeItemId}
                                // error={errors?.payServices?.message}
                                selectedOption={selectedPayService}
                                onChangeSelectedOption={(e) => onChangeSelectedItems(e, 'payServices')}
                                options={payServices || []}
                                multiple={true}
                            />
                            <SelectCheckbox
                                name='Category'
                                key={3}
                                disabled={!!commissionFeeItemId}
                                placeholder=' '
                                dataQa={`${(selectedSector?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                                containerStyle='mb-1'
                                error={errors?.assetTemplateId?.message}
                                selectedOption={selectedSector}
                                onChangeSelectedOption={(e) => onChangeSelectedSector(e)}
                                options={sectors || []}
                            />
                            <Input
                                label='Commission Fee'
                                type='text'
                                dataQa={'commission-fee'}
                                register={register('commissionFee', {
                                    required: {
                                        message: 'Postage Fee is required',
                                        value: true,
                                    },
                                    pattern: {
                                        value: /^-?[0-9]\d*\.?\d*$/,
                                        message: 'Only numbers are accepted.'
                                    },
                                    validate: (value: number) => (Number(value) >= 0) || 'Postage fee should be a positive number'
                                })}
                                error={errors.commissionFee?.message}
                            />
                            <div className='flex flex-row gap-x-3 justify-items-stretch'>
                                <Input
                                    placeholder='Min Value'
                                    dataQa={'min-value'}
                                    label={'Min Value'}
                                    type='text'
                                    register={register('minValue', {
                                        required: {
                                            message: 'Min Value is required',
                                            value: true,
                                        },
                                        pattern: {
                                            value: /^-?[0-9]\d*\.?\d*$/,
                                            message: 'Only numbers are accepted.'
                                        },
                                        validate: (value: number) => (Number(value) >= 0) || 'Min Value should be a positive number'
                                    })}
                                    error={errors.minValue?.message}
                                />
                                <Input
                                    placeholder='Max Value'
                                    dataQa={'max-value'}
                                    label={'Max Value'}
                                    type='text'
                                    register={register('maxValue', {
                                        required: {
                                            message: 'Max Value is required',
                                            value: true,
                                        },
                                        pattern: {
                                            value: /^-?[0-9]\d*\.?\d*$/,
                                            message: 'Only numbers are accepted.'
                                        },
                                        validate: (value: number) => (Number(value) >= 0) || 'Max Value should be a positive number'
                                    })}
                                    error={errors.maxValue?.message}
                                />
                            </div>
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    dataQa={'submit'}
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default CommissionFeeActionsModal;
