import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../../config';
import ordersAxios from '../../../../utils/axios/orders.axios';
import { PaginatedMultipleCashout } from '../../../../interfaces/multiple-cashout/paginatedStore.type';

const tryToFetchMultipleCashouts = (
    pageNumber: number,
    pageSize: number,
    filter?: string,
    data?: any
): Promise<AxiosResponse<PaginatedMultipleCashout>> => {
    const url = filter ? `${ORDERS_URL}/shipments/multiple-cashouts?page=${pageNumber}&size=${pageSize}&filters=${filter}`
        : `${ORDERS_URL}/shipments/multiple-cashouts?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<PaginatedMultipleCashout>(url, data);
};

const tryToFetchMultipleCashoutsToReship = (
    pageNumber: number,
    pageSize: number,
    filter?: string,
    data?: any
): Promise<AxiosResponse<PaginatedMultipleCashout>> => {
    const url = filter ? `${ORDERS_URL}/shipments/multiple-cashouts/reship?page=${pageNumber}&size=${pageSize}&filters=${filter}`
        : `${ORDERS_URL}/shipments/multiple-cashouts/reship?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<PaginatedMultipleCashout>(url, data);
};

const tryToFetchMultipleCashoutsReshipped = (
    pageNumber: number,
    pageSize: number,
    filter?: string,
    data?: any
): Promise<AxiosResponse<PaginatedMultipleCashout>> => {
    const url = filter ? `${ORDERS_URL}/shipments/multiple-cashouts/reshipped?page=${pageNumber}&size=${pageSize}&filters=${filter}`
        : `${ORDERS_URL}/shipments/multiple-cashouts/reshipped?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<PaginatedMultipleCashout>(url, data);
};

const tryToSplitMultipleCashout = (orderId: string): Promise<AxiosResponse> => {
    const url = `${ORDERS_URL}/shipments/multiple-cashouts/split`
    return ordersAxios.put<PaginatedMultipleCashout>(url, { orderId });
};

const tryToShipPackageToBroker = (data: any): Promise<AxiosResponse> => {
    const url = `${ORDERS_URL}/shipments/multiple-cashouts/ship`
    return ordersAxios.put<PaginatedMultipleCashout>(url, data);
};

export const MultipleCashoutService = {
    tryToFetchMultipleCashouts,
    tryToFetchMultipleCashoutsToReship,
    tryToFetchMultipleCashoutsReshipped,
    tryToSplitMultipleCashout,
    tryToShipPackageToBroker
};
