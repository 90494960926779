import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Loading from '../../../../shared/Loading';

interface IMarkAsPaidModal {
    open: boolean;
    loading?: boolean;
    handleCloseModal: () => void;
    onApproveAction: () => void;
    creditAmount: number;
    currency: string;
}

const MarkAsPaidModal = ({
    open,
    loading,
    handleCloseModal,
    onApproveAction,
    creditAmount,
    currency
}: IMarkAsPaidModal) => {
    return (
        <Modal
            open={open}
            onClose={() => handleCloseModal()}
            dataQa={'close-button'}
            showInRight
            contentContainerStyle='p-6 min-w-[500px] bg-white rounded-lg shadow-lg'>
            <div className='mb-4'>
                <p className='text-gray-600 text-sm text-center'>Mark as Paid</p>
                <hr className='mt-2' />
            </div>
            <div className='mb-4'>
                <p className='text-lg font-semibold text-gray-900'>Action Confirmation</p>
                <hr className='mt-2' />
            </div>
            <div className='bg-[#F8F9FC] p-4'>
                <p className='text-gray-700 mb-4 text-xl'>Are you sure you want to mark this credit note with total of {currency}{creditAmount} as <span className='text-[#00B67A]'>paid</span>?</p>
                <p className='text-gray-500 text-sm mb-4'>By marking this credit note as paid, means that the credit note has been used by the customer (broker).<br /> Doing this action will be irreversible.</p>
                <hr className='my-6' />
                <div className='flex justify-between'>
                    <Button
                        onClickButton={handleCloseModal}
                        label="No"
                        className="w-full mr-2 py-2 text-center rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300"
                    />
                    <Button
                        icon={loading && <Loading />}
                        label="Yes"
                        dataQa="approve-action"
                        disabled={loading}
                        onClickButton={() => !loading && onApproveAction()}
                        className={`w-full ml-2 py-2 rounded-md bg-gradient-to-r from-[#FCEE21] to-[#F7931E] text-[#202020]`}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default MarkAsPaidModal;
