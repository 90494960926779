import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import { ITicketResponse } from '../../../interfaces/customer-care/ITicket';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IOrderDetailedStatuses, ISingleOrderSchema } from '../../../interfaces/orders/IOrdersSchema';
import {
    tryToAcceptOrder,
    tryToDeliverOrder,
    tryToDeliverOrdersMultiple,
    tryToFetchSingleOrder,
    tryToSetMissingPackage,
} from '../../../store/brokers/partners/orders/ordersSlice';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import {
    tryToAddTicketThread,
    tryToFetchFilteredTickets,
} from '../../../store/brokers/partners/customer-care/customerCareSlice';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
// import { useCarbonImpactForItemMutation } from '../../../store/brokers/partners/carbon-impact/CarbonImpactApi';
import ReviewChooseActionModal from './modals/ReviewChooseActionModal';
// import EmissionAvoidedModal from './modals/EmissionAvoidedModal';
import AcceptWithImeiModal from './modals/AcceptWithImeiModal';
import RevisedOfferModal from './modals/RevisedOfferModal';
import ActionCheckModal from './modals/ActionCheckModal';
import MissingItemModal from './modals/MissingItemModal';
import InventoryModal from './modals/InventoryModal';
import RejectModal from './modals/RejectModal';
import LoadingPage from './loading/LoadingPage';
import ItemInfo from './details/ItemInfo';
import Countdown from './details/CountDown';
import ProductInfo from './details/ProductInfo';
import ContactHistory from './details/ContactHistory';
import DetailsActions from './item-details/DetailsActions';
import OrderDetailsInfo from './item-details/OrderDetailsInfo';
import OrderDatesDetails from './item-details/OrderDatesDetails';
import ShippedItemDetails from './item-details/ShippedItemDetails';
import MissingItemDetails from './item-details/MissingItemDetails';
import AcceptedItemDetails from './item-details/AcceptedItemDetails';
import ReceivedItemDetails from './item-details/ReceivedItemDetails';
import ReturnedItemDetails from './item-details/ReturnedItemDetails';
import SellerResponseDetails from './item-details/SellerResponseDetails';
import RequiredReturnDetails from './item-details/RequiredReturnDetails';
import Toast from '../../../shared/toast';
import WhiteContainer from '../../../shared/white-container';
import ImagesCarousel from '../../../shared/images-carousel';
import RaiseTicketModal from '../../../shared/raise-ticket-modal';


const OrderDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id, itemId } = useParams();
    const [showRaiseTicketModal, setShowRaiseTicketModal] = useState<boolean>(false);
    const [paginationStateTickets] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [ticketsHistory, setTicketsHistory] = useState<ITicketResponse>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [threadReply, setThreadReply] = useState<string>();
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
    const [showInventoryModal, setShowInventoryModal] = useState<boolean>(false);
    const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
    const [showRevaluateImages, setShowRevaluateImages] = useState<boolean>(false)
    const [revaluateImages, setRevaluateImages] = useState<any>()
    const [showMissingModal, setShowMissingModal] = useState<boolean>(false)
    // const [showEmissionModal, setShowEmissionModal] = useState<boolean>(false)
    const [orderDetails, setOrderDetails] = useState<ISingleOrderSchema>()
    const [showActionCheckModal, setShowActionCheckModal] = useState<{
        show: boolean;
        text: string;
        title: string;
        type: string;
        error: boolean;
    }>();
    const [loadingOrder, setLoadingOrder] = useState<boolean>(true);
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>();
    const [userData, setUserData] = useState<any>();
    const [orderNotFound, setOrderNotFound] = useState<any>();
    const [extendedFields, setExtendedFields] = useState<any>();
    // const [emissionValue, setEmissionValue] = useState<any>()
    const [acceptLoading, setAcceptLoading] = useState<boolean>(false);
    const [newThreadLoading, setNewThreadLoading] = useState<boolean>(false);
    const [pageAccess, setPageAccess] = useState<any>();
    const [showFailuresDraw, setShowFailuresDraw] = useState<boolean>(false);
    const [revisedOfferModal, setRevisedOfferModal] = useState()
    const stateTicket = useAppSelector((state) => state.cs);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const orderDetailsInfo = useAppSelector((state) => state?.orders?.orderDetail?.data);
    const loadingOrderDetails = useAppSelector((state) => state?.orders?.orderIsLoading);
    const totalCosts = useAppSelector((state) => state?.orders?.orderDetails?.totalCosts);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const [userProfileMutation] = useUserProfileMutation();
    // const [carbonImpactForItem] = useCarbonImpactForItemMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Order Items')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Order Details')
        setPageAccess(findChild || undefined)
        // if (!accessControl) {
        //     setShowAllActions(true)
        // } else {
        //     const findIfAnyActionIsPossible = ORDER_DETAILS_ACTIONS?.find((item: any) => findChild?.actions[item?.label] || item?.label === 'Review')
        //     setShowAllActions(!!findIfAnyActionIsPossible)
        // }
    }, [accessControl])

    const getUserData = async () => {
        const response: any = await userProfileMutation(null).unwrap();
        setUserData(response?.data || undefined);
    };

    const onGettingAllAssetFields = async () => {
        if (orderDetailsInfo?.item?.catalog?.id) {
            try {
                const assetSelected = orderDetailsInfo?.item?.catalog?.id
                const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(assetSelected)).unwrap()
                if (response?.fields) {
                    setExtendedFields(response?.extendedFields)
                }
            } catch (err) {
                // error here
            }
        }
    }

    // const getCarbonImpactForItem = async () => {
    //     const response: any = await carbonImpactForItem(orderDetails?.item?.initialCategory?.code || '').unwrap()
    //     setEmissionValue(response?.basketEmission?.items?.[0]?.emissionValue || 0)
    // }

    const getOrderDetailsData = async () => {
        try {
            if (id && itemId && activeMarket?.market?._id) {
                const formatData = {
                    orderId: id,
                    data: {
                        itemId,
                        marketId: activeMarket?.market?._id,
                    },
                };
                await dispatch(tryToFetchSingleOrder(formatData)).unwrap();
                setOrderNotFound(undefined);
            }
        } catch (err) {
            setLoadingOrder(false);
            setOrderNotFound({ show: true, message: `${err}` });
        }
    };

    useEffect(() => {
        if (
            orderDetailsInfo &&
            orderDetailsInfo?.item?.warehouseImages &&
            orderDetailsInfo?.item?.warehouseImages?.length > 0
        ) {
            const formatImages = orderDetailsInfo?.item?.warehouseImages?.map(
                (item) => ({ url: item }),
            );
            setRevaluateImages(formatImages || undefined);
        } else {
            setRevaluateImages(undefined);
        }
        if (orderDetailsInfo) {
            if (JSON.stringify(orderDetailsInfo) !== JSON.stringify(orderDetails)) {
                setOrderDetails(orderDetailsInfo || undefined);
            }
            setLoadingOrder(false);
            onGettingAllAssetFields();
        }
    }, [orderDetailsInfo]);

    useEffect(() => {
        if (id && itemId && activeMarket?.market?._id) {
            getOrderDetailsData();
        }
        getUserData();
    }, []);

    useEffect(() => {
        if (id && itemId && activeMarket?.market?._id) {
            getOrderDetailsData();
        }
    }, [activeMarket]);

    useEffect(() => {
        if (activeMarketState && JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket)) {
            setLoadingOrder(true);
            setActiveMarket(activeMarketState || undefined);
        }
    }, [activeMarketState]);

    useEffect(() => {
        if (id && itemId) {
            const fetchData = {
                ...(paginationStateTickets || {}),
                data: {
                    ticketReadByBroker: true,
                    filters: {
                        value: [
                            {
                                key: 'orderId',
                                value: id || '',
                            },
                            {
                                key: 'itemId',
                                value: itemId || '',
                            },
                        ],
                    },
                },
            };
            dispatch(tryToFetchFilteredTickets(fetchData));
        }
    }, [paginationStateTickets, id, itemId, orderDetails?.orderData?.status]);

    useEffect(() => {
        if (stateTicket.tickets) {
            const ticketsData =
                stateTicket.tickets?.data?.elements &&
                    Array.isArray(stateTicket.tickets?.data.elements)
                    ? stateTicket.tickets?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setTicketsHistory(ticketsData?.[0]);
        }
    }, [stateTicket.tickets]);

    const onShowHideRaiseTicketModal = () => {
        if (showRaiseTicketModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowRaiseTicketModal(!showRaiseTicketModal);
    };

    const onShowHideRejectModal = () => {
        if (showRejectModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowRejectModal(!showRejectModal);
    };

    const onToggleActionModal = (type?: string) => {
        if (type) {
            if (type === 'accept') {
                document.body.style.overflow = 'hidden';
                setShowActionCheckModal({
                    show: true,
                    text: 'The item is an exact match of the order placed. Confirm to accept the item.',
                    title: 'Item Matching',
                    error: false,
                    type: 'accept',
                });
            } else if (type === 'delivered') {
                setShowActionCheckModal({
                    show: true,
                    text: 'Are you sure you want to change this item status to delivered?',
                    title: 'Item Delivered',
                    error: false,
                    type: 'delivered',
                });
            } else if (type === 'missing') {
                setShowMissingModal(true)
            } else if (type === 'inventory') {
                setShowInventoryModal(true);
            } else if (type === 'confirm_accept_package') {
                setShowActionCheckModal({
                    show: true,
                    text: 'Are you sure you accepted the package with this item?',
                    title: 'Received Package',
                    error: false,
                    type: 'confirm_accept_package',
                });
            }
        } else {
            document.body.style.overflow = 'auto';
            setShowActionCheckModal(undefined);
        }
    };

    const onAcceptShipment = async () => {
        setAcceptLoading(true)
        try {
            if (id && itemId) {
                const formatData = {
                    orderId: id,
                    data: {
                        itemId,
                        marketId: activeMarket?.market?._id,
                    },
                };
                const data = await dispatch(tryToAcceptOrder(formatData)).unwrap();
                if (data?.reviseInfo) {
                    setRevisedOfferModal(data.reviseInfo)
                }
                setShowToast({
                    type: 'success',
                    message: `Order Shipment was accepted successfully.`,
                });
                // getCarbonImpactForItem()
                // setShowEmissionModal(true)
            }
            else {
                setShowToast({ type: 'error', message: 'Details are missing' });
            }
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        setAcceptLoading(false)
        onToggleActionModal();
    };

    const onMissingPackage = async () => {
        try {
            const formatData = {
                shipmentId: id || '',
                itemId: itemId || '',
            };
            id && itemId && (await dispatch(tryToSetMissingPackage(formatData)).unwrap());
            setShowToast({
                type: 'success',
                message: `Order status was changed to missing item successfully.`,
            });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onToggleActionModal();
        setAcceptLoading(false);
    };

    const onDeliveredOrder = async () => {
        try {
            if (orderDetails?.shipment?.trackingNumber) {
                dispatch(
                    tryToDeliverOrder(orderDetails?.shipment?.trackingNumber),
                );
                setShowToast({
                    type: 'success',
                    message: `Order Shipment was changed to delivered successfully.`,
                });
            } else {
                setShowToast({
                    type: 'success',
                    message: `Tracking number missing.`,
                });
            }
        } catch (err) {
            setShowToast({
                type: 'error',
                message: `Order status couldn't change to delivered.`,
            });
        }
        onToggleActionModal();
        setAcceptLoading(false)
    };

    const onAcceptReceivedPackage = async () => {
        if (!orderDetails?.shipment?.trackingNumber && !orderDetails?.shipment?.orderNumber) {
            setAcceptLoading(false)
            setShowToast({
                type: 'error',
                message: `Missing Tracking Number and Order Number.`,
            });
            onToggleActionModal();
            return;
        }
        try {
            const data = {
                numbers: [orderDetails?.shipment?.trackingNumber || orderDetails?.shipment?.orderNumber]
            }
            await dispatch(tryToDeliverOrdersMultiple({ data: data, type: 'order_details' })).unwrap()
            setShowToast({
                type: 'success',
                message: `Item successfully confirmed as delivered.`,
            });
        } catch (err) {
            setShowToast({
                type: 'error',
                message: `Package could not confirmed as delivered.`,
            });
        }
        setAcceptLoading(false)
        onToggleActionModal();
    }

    const onApproveActionCheck = (action: string) => {
        if (acceptLoading) {
            return;
        }
        setAcceptLoading(true)
        switch (action) {
            case 'accept':
                onAcceptShipment();
                break;
            case 'missing':
                onMissingPackage();
                break;
            case 'delivered':
                onDeliveredOrder();
                break;
            case 'confirm_accept_package':
                onAcceptReceivedPackage();
                break;
        }
    };

    const onClickOrderDetailsAction = (action: string) => {
        switch (action) {
            case 'Accept':
                onToggleActionModal('accept');
                break;
            case 'Missing Item':
                onToggleActionModal('missing');
                break;
            case 'Reject':
                onShowHideRejectModal();
                break;
            case 'Contact CS':
                onShowHideRaiseTicketModal();
                break;
            case 'Delivered':
                onToggleActionModal('delivered');
                break;
            case 'Inventory':
                onToggleActionModal('inventory');
                break;
            case 'Review':
                setShowReviewModal(true);
                // navigate(`/order-items/review/${id}/${itemId}`);
                break;
            case 'Confirm':
                onToggleActionModal('confirm_accept_package');
                break;
        }
    };

    const onGoBack = () => {
        navigate(-1);
    };

    const onAddNewThreadCS = async () => {
        if (newThreadLoading) {
            return;
        }
        if (threadReply) {
            setNewThreadLoading(true)
            try {
                const formatData = {
                    ticketId: ticketsHistory?.ticket?._id || '',
                    data: {
                        content: threadReply,
                    },
                };
                await dispatch(tryToAddTicketThread(formatData)).unwrap();
                setThreadReply(undefined);
                setShowToast({
                    type: 'success',
                    message: `Your reply saved successfully.`,
                });
            } catch (err: any) {
                setShowToast({
                    type: 'error',
                    message: `${err?.response || err}`,
                });
            }
            setNewThreadLoading(false)
        }
    };

    const onChangeValueReplyThread = (e: any) => {
        e.persist();
        setThreadReply(e?.target?.value);
    };

    const onSuccessSettingInventory = () => {
        setShowInventoryModal(false);
        setShowToast({
            type: 'success',
            message: `Warehouse location was saved successfully.`,
        });
    };

    const onClickRevaluateImages = () => {
        setShowRevaluateImages(true);
    };

    const onClickFailuresImages = () => {
        setShowFailuresDraw(!showFailuresDraw)
    };

    const showStatusDetailsSection = (status: string) => {
        switch (status) {
            case 'device_accepted':
            case 'user_accepted_offer':
                return <AcceptedItemDetails />;
            case 'shipped':
            case 'not_shipped':
                return <ShippedItemDetails
                    pageAccess={pageAccess}
                    accessControl={accessControl}
                    onClickConfirm={() => onClickOrderDetailsAction('Confirm')} />;
            case 'device_in_review':
                return <ReceivedItemDetails
                    pageAccess={pageAccess}
                    accessControl={accessControl}
                    receivedDate={orderDetails?.shipment?.receivedDate}
                    onClickOrderDetailsAction={() => onClickOrderDetailsAction('Review')} />;
            case 'awaiting_user_response':
                return <SellerResponseDetails
                    reviewedAt={
                        (orderDetails?.orderData?.detailedStatuses && orderDetails?.orderData?.detailedStatuses?.length > 0)
                            ? orderDetails?.orderData?.detailedStatuses?.find((item: IOrderDetailedStatuses) => (item?.type || '').toLowerCase() === 'revalued at')?.date || ''
                            : ''
                    }
                />;
            case 'item_missing':
                return <MissingItemDetails
                    missingDate={
                        (orderDetails?.orderData?.detailedStatuses && orderDetails?.orderData?.detailedStatuses?.length > 0)
                            ? orderDetails?.orderData?.detailedStatuses?.find((item: IOrderDetailedStatuses) => (item?.type || '').toLowerCase() === 'item missing')?.date || ''
                            : ''
                    }
                    missingImages={orderDetails?.item?.warehouseImages}
                />;
            case 'required_return':
            case 'user_rejected_offer':
            case 'cs_required_return':
                return <RequiredReturnDetails
                    pageAccess={pageAccess}
                    accessControl={accessControl}
                    orderDetails={orderDetails}
                    packageId={id || ''} />;
            case 'returned':
                return <ReturnedItemDetails
                    orderDetails={orderDetails} />
            case 'blacklisted':
            case 'awaiting_cs_response':
                return <WhiteContainer containerStyle='!rounded !h-auto' />
        }
    }

    const onCloseAcceptOrderModal = () => {
        document.body.style.overflow = 'auto';
        setShowActionCheckModal(undefined)
    }

    const onGoToReview = (categoryId: string) => {
        navigate(`/order-items/review/${id}/${itemId}?category=${categoryId}&source=${orderDetails?.item?.sourceShipment}`);
    }


    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-8'>
                        <img src={'/assets/shared/back.svg'} className={'w-[22px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title !text-3xl'>Item Detailed View</p>
                    {(userData?.businessType.includes('wing') && ['not_shipped', 'device_in_review', 'shipped'].includes(orderDetails?.orderData?.status || '')) && <Countdown cashedOutDate={orderDetails?.orderData.cashedOutDate || ''} />}
                </div>
                {(loadingOrder || loadingOrderDetails) ?
                    <div className='flex flex-row flex-wrap'>
                        <div className='mr-2 py-1 px-1 cursor-pointer rounded-md animate-pulse'>
                            <div className='h-8 bg-gray-200 dark:bg-gray-200 w-[90px] !rounded-md'></div>
                        </div>
                        <div className='py-1 px-1 cursor-pointer rounded-md animate-pulse'>
                            <div className='h-8 bg-gray-200 dark:bg-gray-200 w-[90px] !rounded-md'></div>
                        </div>
                    </div>
                    : <div>
                        {(!loadingOrderDetails && orderDetails) &&
                            <DetailsActions
                                pageAccess={pageAccess}
                                accessControl={accessControl}
                                showContactCS={!(orderDetails?.orderData?.status === 'shipped' || orderDetails?.shipment?.deliveryStatus === 'not_shipped') && !ticketsHistory}
                                showReject={orderDetails?.item?.showReject}
                                showMissing={(['device_in_review'].includes(orderDetails?.orderData?.status)) && !(!!orderDetails?.item?.revaluedPrice || !!orderDetails?.item?.warehouseAccepted)}
                                onClickOrderDetailsAction={onClickOrderDetailsAction}
                            />
                        }
                    </div>
                }
            </div>
            {loadingOrder ?
                <LoadingPage />
                : <>
                    {orderNotFound?.show ?
                        <>
                            <div className='my-10 min-w-[100%] flex flex-col items-center'>
                                <img src={'/assets/shared/no-content.svg'} className={'w-[150px] mb-10 mt-4 object-contain'} />
                                <p className='text-[#a4a4a4] text-center font-md'>
                                    {orderNotFound?.message}
                                </p>
                            </div>
                        </>
                        : <>
                            {orderDetails &&
                                <OrderDetailsInfo
                                    orderDetails={orderDetails}
                                />
                            }
                            <div className='grid grid-cols-4 lg:grid-cols-11 content-stretch gap-0 lg:gap-2 mt-8 mb-2'>
                                <div className='col-span-3'>
                                    <div>
                                        <OrderDatesDetails
                                            detailedStatuses={orderDetails?.orderData?.detailedStatuses}
                                        />
                                        <ProductInfo
                                            orderDetails={orderDetails}
                                            totalCost={totalCosts}
                                        />
                                    </div>
                                </div>
                                <div className='col-span-8 grid grid-cols-2 gap-2'>
                                    {showStatusDetailsSection(orderDetails?.orderData?.status || '')}
                                    <div className='col-span-1'>
                                        <ItemInfo
                                            carbonImpact={(orderDetails?.orderData?.status === 'user_accepted_offer' || orderDetails?.orderData?.status === 'device_accepted') && (orderDetails?.item?.carbonImpact) ? orderDetails?.item?.carbonImpact : undefined}
                                            displayQuestion={orderDetails?.item?.displayQuestions}
                                            revaluateMessage={orderDetails?.item?.message}
                                            revaluationData={orderDetails?.item?.revaluatedDisplayQuestions}
                                            reviewed={!!orderDetails?.item?.revaluation}
                                            revaluation={orderDetails?.item?.revaluation}
                                            extendedFields={extendedFields}
                                            revaluateImages={revaluateImages}
                                            failuresDraw={orderDetails?.item?.failuresDraw}
                                            itemCode={orderDetails?.item?.itemCode}
                                            returnShippingLabel={orderDetails?.item?.returnShippingLabel}
                                            invoiceUrl={orderDetails?.item?.invoiceUrl}
                                            onClickRevaluateImages={onClickRevaluateImages}
                                            onClickFailuresImages={onClickFailuresImages}
                                            salesGrade={orderDetails?.item?.inventory?.grade}
                                        />
                                    </div>
                                </div>
                                <div className='my-2 col-span-4 lg:col-span-11'>
                                    {(!accessControl || (pageAccess?.actions['Contact CS'])) &&
                                        <div className='mb-4'>
                                            <ContactHistory
                                                history={ticketsHistory}
                                                loadingThread={newThreadLoading}
                                                threadReply={threadReply}
                                                onAddNewThread={onAddNewThreadCS}
                                                onChangeValueReplyThread={onChangeValueReplyThread}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </>
            }
            {showRaiseTicketModal &&
                <RaiseTicketModal
                    handleCloseModal={onShowHideRaiseTicketModal}
                    openRaiseTicketModal={showRaiseTicketModal}
                    orderId={id || ''}
                    userData={userData}
                    categoryId={orderDetails?.item?.catalog?.id}
                    warehouseId={orderDetails?.item?.exchangeDefinition?.warehouse?._id}
                    itemId={itemId || ''}
                    orderDetails={orderDetails}
                />
            }
            {showRejectModal &&
                <RejectModal
                    openRejectModal={showRejectModal}
                    handleCloseModal={onShowHideRejectModal}
                    orderId={id || ''}
                    itemId={itemId || ''}
                    warehouseImages={revaluateImages}
                    marketId={activeMarket?.market?._id || ''}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showActionCheckModal?.show && showActionCheckModal?.type === 'accept' && ((orderDetails?.item?.catalog?.code) === 'phones' || (orderDetails?.item?.catalog?.code) === 'tablets')
                ? <AcceptWithImeiModal
                    openImeiModal={showActionCheckModal?.show || false}
                    itemId={itemId || ''}
                    orderId={id || ''}
                    userData={userData}
                    category={orderDetails?.item?.catalog?.id}
                    activeMarket={activeMarket}
                    imeiData={orderDetails?.item?.itemCode}
                    descriptor={orderDetails?.item?.exchangeDefinition?.asset?.descriptor}
                    warehouseId={orderDetails?.item?.exchangeDefinition?.warehouse?._id}
                    handleCloseModal={onCloseAcceptOrderModal}
                    setRevisedOfferModal={setRevisedOfferModal}
                />
                : <ActionCheckModal
                    openMarketStatusModal={showActionCheckModal?.show || false}
                    handleCloseModal={onToggleActionModal}
                    text={showActionCheckModal?.text || ''}
                    title={showActionCheckModal?.title || ''}
                    errorType={showActionCheckModal?.error || false}
                    action={showActionCheckModal?.type || ''}
                    loading={acceptLoading}
                    onApproveAction={onApproveActionCheck}
                />
            }
            {showInventoryModal &&
                <InventoryModal
                    openModal={showInventoryModal}
                    handleCloseModal={() => setShowInventoryModal(false)}
                    itemId={itemId || ''}
                    trackingNumber={orderDetails?.shipment?.trackingNumber}
                    id={id || ''}
                    onSuccessfullyDelivered={onSuccessSettingInventory}
                />
            }
            {(revaluateImages && revaluateImages?.length > 0 && showRevaluateImages) &&
                <ImagesCarousel
                    images={revaluateImages || []}
                    showModal={showRevaluateImages}
                    handleClose={() => setShowRevaluateImages(false)}
                    active={0}
                />
            }
            {(orderDetails?.item?.failuresDraw && showFailuresDraw) &&
                <ImagesCarousel
                    images={[{ url: orderDetails?.item?.failuresDraw }]}
                    showModal={showFailuresDraw}
                    handleClose={onClickFailuresImages}
                    active={0}
                />
            }
            <MissingItemModal
                shipmentId={id || ''}
                itemId={itemId || ''}
                openModal={showMissingModal}
                handleCloseModal={() => setShowMissingModal(false)}
            />
            {/* {showEmissionModal &&
                <EmissionAvoidedModal
                    openModal={showEmissionModal}
                    handleCloseModal={() => setShowEmissionModal(false)}
                    emission={emissionValue || 0}
                />
            } */}
            {showReviewModal &&
                <ReviewChooseActionModal
                    openModal={showReviewModal}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    handleCloseModal={() => setShowReviewModal(false)}
                    onGoToReview={onGoToReview}
                />
            }
            {revisedOfferModal &&
                <RevisedOfferModal
                    reviseInfo={revisedOfferModal}
                    handleCloseModal={() => setRevisedOfferModal(undefined)}
                />
            }
        </div >
    )
}

export default OrderDetails;
