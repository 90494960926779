import React, { useState, useEffect } from 'react';
import { useUserProfileMutation } from '../../../../store/user/userDomApi';
// import { REVIEW_OPTIONS } from '../../../../utils/constants/review-types';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IReviewChooseActionModal {
    openModal: boolean;
    actions?: any;
    accessControl?: any;
    onGoToReview: (categoryId: string) => void;
    handleCloseModal: () => void;
}

const ReviewChooseActionModal = ({
    openModal,
    actions,
    accessControl,
    onGoToReview,
    handleCloseModal
}: IReviewChooseActionModal) => {
    const [categories, setCategories] = useState<any>()
    const [selectedCategory, setSelectedCategory] = useState<any>()
    // const [actionSelected, setActionSelected] = useState<any>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [errorSubmit, setErrorSubmit] = useState<string>()
    const [userProfileMutation] = useUserProfileMutation()

    const getBrokerMarketsAndSectors = async () => {
        if (!categories) {
            const response = await userProfileMutation(null).unwrap()
            const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : []
            setCategories(formatSectors || [])
        }
    }

    useEffect(() => {
        getBrokerMarketsAndSectors()
    }, [])

    const onCloseModal = () => {
        handleCloseModal();
    }

    const onSubmit = () => {
        setLoadingSubmit(true)
        if (!selectedCategory) {
            setErrorSubmit('Please select category to continue.')
            setLoadingSubmit(false)
            return;
        }
        onGoToReview(selectedCategory?.id || '')
        setLoadingSubmit(false)
    }

    const onChangeCategorySelected = (e: any, type?: string) => {
        setSelectedCategory(e)
    }

    // const onClickAction = (type: string) => {
    //     setActionSelected(type)
    // }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            dataQa={'close-button'}>
            <div className={'p-2 min-w-[450px] min-h-[300px]'}>
                <p className='mb-4 font-semibold text-sm'>Review Select Action</p>
                <p className='font-semibold  text-sm mb-5'>Choose category and action to continue</p>
                {errorSubmit && <Error text={errorSubmit} />}
                <div className='grid grid-rows gap-4 mt-4'>
                    <SelectCheckbox
                        name={'Category'}
                        placeholder={' '}
                        dataQa={'category-checkbox'}
                        options={categories || []}
                        containerStyle={'max-w-[90%]'}
                        selectedOption={selectedCategory}
                        selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200, }}
                        onChangeSelectedOption={onChangeCategorySelected}
                        uniqueName={'category'}
                    />
                    {/* <div className='my-4'>
                        <p className='mb-2 ml-1 text-[#a4a4a4]'>Action</p>
                        <div data-qa={'actions'} className='flex flex-row items-center'>
                            {REVIEW_OPTIONS.filter((item) => (!accessControl || actions?.[item?.name]))?.map((state, idx: number) => {
                                const isActive = actionSelected === state?.name
                                return (
                                    <div
                                        key={idx}
                                        onClick={() => onClickAction(state?.name)}
                                        className={`flex flex-row justify-center items-center cursor-pointer min-w-[170px] py-2 px-3 ${isActive ? 'bg-[#F7931E]' : 'border border-[#e4e4e4]'}`}>
                                        <p className={`${isActive ? 'text-white' : 'text-[#3c3c434d]'} font-medium`}>{state?.name}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div> */}
                </div>
                <div className='min-w-full flex flex-row pt-4 justify-center'>
                    <Button
                        label={'Continue'}
                        dataQa={'continue-button'}
                        className={`${loadingSubmit ? 'btn-main-disable' : 'btn-main'} my-3 min-w-[130px] !shadow-none`}
                        onClickButton={() => !loadingSubmit && onSubmit()}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ReviewChooseActionModal;