import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { IPartners } from '../../../interfaces/partners/IPartners';
// import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToAddPartner } from '../../../store/brokers/admin/partners/partnersSlice';
// import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import DetailsStep from './steps/DetailsStep';
import CompanyDetails from './steps/CompanyDetails';
import LayoutRegister from './Layout/LayoutRegister';
import SuccessRegisterModal from './modals/SuccessRegisterModal';


const Register = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    // const [sectors, setSectors] = useState<Array<any>>([]);
    // const [markets, setMarkets] = useState<Array<any>>([]);
    const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false)
    const [userAgreed, setUserAgreed] = useState<boolean>(false)
    const [createError, setCreateError] = useState<string>()
    const [personalDetails, setPersonalDetails] = useState<any>();
    const [companyDetails, setCompanyDetails] = useState<any>();
    const [activeStep, setActiveStep] = useState<number>(1)
    // const [getAllMarkets] = useGetAllMarketsMutation()
    // const state = useAppSelector((state) => state?.assetsTemplates);

    // const getMarketsData = async () => {
    //     const response = await getAllMarkets(null).unwrap()
    //     const formatMarkets = response && response?.length > 0 && response?.map((market: any) => ({ ...market, value: market?._id }))
    //     setMarkets(formatMarkets || [])
    // }

    useEffect(() => {
        // dispatch(tryToFetchAllAssetsTemplates());
        // getMarketsData()
        // (window as any).Intercom('shutdown');
    }, []);

    // useEffect(() => {
    //     if (state.allAssetTemplates) {
    //         const rows =
    //             state.allAssetTemplates && Array.isArray(state.allAssetTemplates)
    //                 ? state.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
    //                 : [];
    //         setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
    //     }
    // }, [state.allAssetTemplates]);

    const onGoToLogin = () => {
        navigate('/login')
    }

    const onRequestToRegisterPartner = async (data: IPartners): Promise<boolean> => {
        if (!userAgreed) {
            setCreateError('Please Agree to continue register!')
            return false;
        }
        const payload = {
            ...data,
        }
        try {
            await dispatch(tryToAddPartner(payload)).unwrap();
            setCreateError(undefined)
            setShowRegisterModal(true)
            return true
        } catch (error) {
            setCreateError(`${error}`)
            return false
        }
    }

    const onClickAgreeWithTerms = () => {
        setUserAgreed(!userAgreed)
        if (!userAgreed) {
            setCreateError(undefined)
        }
    }

    const onGoToNext = () => {
        setActiveStep(2)
    }

    return (
        <LayoutRegister>
            {activeStep === 1 ?
                <DetailsStep
                    personalData={personalDetails}
                    setPersonalData={setPersonalDetails}
                    onGoToNext={onGoToNext}
                    onGoToLogin={onGoToLogin}
                /> :
                <CompanyDetails
                    personalData={personalDetails}
                    companyData={companyDetails}
                    setCompanyData={setCompanyDetails}
                    createError={createError}
                    userAgreed={userAgreed}
                    onGoToLogin={onGoToLogin}
                    onClickAgreeWithTerms={onClickAgreeWithTerms}
                    onRequestToRegisterPartner={onRequestToRegisterPartner}
                />
            }
            <div className={`w-[100%] grid ${activeStep === 1 ? 'grid-cols-2' : 'grid-cols-1'} min-h-[10px]`}>
                {activeStep === 1 ?
                    <>
                        <div className='bg-gradient-to-br from-[#FCEE21] to-[#F7931E] rounded-l-lg h-[10px]'>
                        </div>
                        <div className={'bg-[#d9d9d9] rounded-[10px] h-[10px] min-w-[52%] ml-[-1%]'}>
                        </div>
                    </> :
                    <div className='bg-gradient-to-br from-[#FCEE21] to-[#F7931E] rounded-lg h-[10px]'>
                    </div>
                }
            </div>
            <SuccessRegisterModal
                openModal={showRegisterModal}
                handleCloseModal={() => setShowRegisterModal(false)}
            />
        </LayoutRegister>
    )
}

export default Register;