import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


interface IAddNewMappingComp {
    title?: string;
    onClickNewMapping: () => void;
}

const AddNewMappingComp = ({
    onClickNewMapping,
    title
}: IAddNewMappingComp) => {
    return (
        <WhiteContainer containerStyle={'w-fit h-fit flex flex-col items-center justify-center max-w-[90%] w-[90%] min-h-[170px] h-full'} type='rounded'>
            <div data-qa={'add-new-mapping-button'} onClick={onClickNewMapping} className='w-full min-w-[100%] h-full flex flex-col justify-center items-center cursor-pointer'>
                <img alt='plus' src={'/assets/shared/add-new.svg'} className='mb-2 w-[50px] object-contain' />
                <p className={'text-center mb-2'}>{title || 'Add new Mapping'}</p>
            </div>
        </WhiteContainer>
    )
}

export default AddNewMappingComp;