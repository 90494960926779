import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { RMAsService } from './RMAsApi';
import { PaginatedRMAs } from '../../../../interfaces/rma/paginatedStore.type';
import { IRMAsListState } from '../../../../interfaces/rma/IRMAsList';
import { ApiResponse, PaginatedApiResponse } from '../../../../interfaces/models/models/paginated-response.model';

const initialState: IRMAsListState = {
    RMAsAreLoading: false,
    RMAIsLoading: false,
    creditNotesLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchRMAs = createAsyncThunk<
    PaginatedRMAs,
    IPaginationPayload
>('RMAs/tryToFetchRMAs', async ({ pageNumber, pageSize, filters, broker, status, market, country }) => {
    const result = await RMAsService.tryToFetchRMAs(
        pageNumber,
        pageSize,
        filters,
        broker,
        status,
        country,
        market
    );
    return result?.data;
});

export const tryToFetchRMADetails = createAsyncThunk<ApiResponse<any>, { id: string, paginationState: IPaginationPayload }>(
    'RMAs/tryToFetchRMADetails',
    async ({ id, paginationState }) => {
        const result = await RMAsService.tryToFetchRMADetails(id, paginationState);
        return result?.data;
    },
);

export const tryToReviewReturn = createAsyncThunk<ApiResponse<any>, any>(
    'RMAs/tryToReviewReturn',
    async ({ itemId, type, rejectReason, revaluePrice }) => {
        const result = await RMAsService.tryToReviewReturn(
            itemId,
            type,
            rejectReason || undefined,
            revaluePrice,
        );
        return result?.data;
    },
);

export const tryToFetchAllRMARejectReasons = createAsyncThunk<any, undefined>(
    'RMAs/tryToFetchAllRMARejectReasons',
    async () => {
        const result = await RMAsService.tryToFetchAllRMARejectReasons();
        return result?.data;
    },
);

export const tryToFetchAllItemCredits = createAsyncThunk<PaginatedRMAs, IPaginationPayload>(
    'itemAllocation/tryToFetchAllItemCredits',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await RMAsService.tryToFetchAllItemCredits(
            pageNumber,
            pageSize,
            filters,
            data
        );
        return result?.data;
    });

export const tryToCreateCreditNote = createAsyncThunk<ApiResponse<any>, any>(
    'RMAs/tryToCreateCreditNote',
    async (data) => {
        const result = await RMAsService.tryToCreateCreditNote(data);
        return result?.data;
    },
);

export const tryToFetchCreditSummary = createAsyncThunk<any, string | undefined>(
    'RMAs/tryToFetchCreditSummary',
    async (brokerId) => {
        const result = await RMAsService.tryToFetchCreditSummary(brokerId);
        return result?.data;
    },
);

export const tryToFetchAllCreditNotes = createAsyncThunk<PaginatedApiResponse<any[]>, IPaginationPayload>(
    'itemAllocation/tryToFetchAllCreditNotes',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await RMAsService.tryToFetchAllCreditNotes(
            pageNumber,
            pageSize,
            filters,
            data
        );
        return result?.data;
    });

export const tryToDownloadCreditNoteCSV = createAsyncThunk<any, string>(
    'RMAs/tryToDownloadCreditNoteCSV',
    async (creditNoteId) => {
        const result = await RMAsService.tryToDownloadCreditNoteCSV(creditNoteId);
        return result?.data;
    },
);
export const tryToMarkCreditNoteAsPaid = createAsyncThunk<any, string>(
    'RMAs/tryToMarkCreditNoteAsPaid',
    async (creditNoteId) => {
        const result = await RMAsService.tryToMarkCreditNoteAsPaid(creditNoteId);
        return result?.data;
    },
);
export const tryToFetchCreditNoteDetails = createAsyncThunk<any, string>(
    'RMAs/tryToFetchCreditNoteDetails',
    async (creditNoteId) => {
        const result = await RMAsService.tryToFetchCreditNoteDetails(creditNoteId);
        return result?.data;
    },
);

export const RMAsSlice = createSlice({
    name: 'RMAs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchRMAs
            .addCase(tryToFetchRMAs.pending, (state) => {
                state.RMAsAreLoading = true;
            })
            .addCase(tryToFetchRMAs.rejected, (state) => {
                state.RMAsAreLoading = false;
            })
            .addCase(tryToFetchRMAs.fulfilled, (state, action) => {
                state.RMAsAreLoading = false;
                state.RMAs = action.payload;
            })

            // tryToFetchRMADetails
            .addCase(tryToFetchRMADetails.pending, (state) => {
                state.RMAIsLoading = true;
            })
            .addCase(tryToFetchRMADetails.rejected, (state) => {
                state.RMAIsLoading = false;
            })
            .addCase(tryToFetchRMADetails.fulfilled, (state, action) => {
                state.RMAIsLoading = false;
                state.RMADetails = action.payload.data;
            })

            // tryToReviewReturn
            .addCase(tryToReviewReturn.pending, (state) => {
                state.RMAIsLoading = true;
            })
            .addCase(tryToReviewReturn.rejected, (state) => {
                state.RMAIsLoading = false;
            })
            .addCase(tryToReviewReturn.fulfilled, (state, action) => {
                state.RMAIsLoading = false;
            })

            // tryToFetchAllItemCredits
            .addCase(tryToFetchAllItemCredits.pending, (state) => {
                state.RMAsAreLoading = true;
            })
            .addCase(tryToFetchAllItemCredits.rejected, (state) => {
                state.RMAsAreLoading = false;
            })
            .addCase(tryToFetchAllItemCredits.fulfilled, (state, action) => {
                state.RMAsAreLoading = false;
                state.RMAs = action.payload;
            })
            // tryToFetchAllCreditNotes
            .addCase(tryToFetchAllCreditNotes.pending, (state) => {
                state.creditNotesLoading = true;
            })
            .addCase(tryToFetchAllCreditNotes.rejected, (state) => {
                state.creditNotesLoading = false;
            })
            .addCase(tryToFetchAllCreditNotes.fulfilled, (state, action) => {
                state.creditNotesLoading = false;
                state.creditNotes = action.payload;
            });
    },
});

export default RMAsSlice.reducer;
