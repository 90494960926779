import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import Switch from '../../../../shared/switch';
import Table from '../../../../shared/table';

const tableColumns = ['Email', 'Username', 'Name', 'Company Name', 'Enabled', 'Actions']

interface IRetailersTable {
    rows: IPartners[];
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    rowsLoading: boolean;
    onSeeRetailerDetails: (id: string) => void;
    onEditRetailer: (partnerId: string) => void;
    onEnableDisableRetailer: (id: string, currentStatus: boolean) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onDeleteRetailerRequest: (id: string) => void;

}

const RetailersTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    rowsLoading,
    onEditRetailer,
    setPaginationState,
    onDeleteRetailerRequest,
    onEnableDisableRetailer,
    onSeeRetailerDetails
}: IRetailersTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IPartners, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.email}</td>
                                <td className='px-6 py-4'>{row?.username}</td>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>{row?.companyName}</td>
                                <td className='px-6 py-4'>
                                    <Switch
                                        checked={!!row?.enabled}
                                        onToggleSwitch={() => ((!accessControl || actions?.['Retailer Enable Disable']) && row?._id) && onEnableDisableRetailer(row?._id, row?.enabled || false)}
                                        dataQa={'enabled-retailer'}
                                    />
                                </td>
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <div className='flex flex-row'>
                                        <div data-qa='retailer-details' onClick={() => ((!accessControl || actions?.['Retailer Details']) && row?._id) && onSeeRetailerDetails(row?._id)}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Retailer Details']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-2`} />
                                        </div>
                                        <div data-qa='retailer-edit' onClick={() => ((!accessControl || actions?.['Retailer Edit']) && row?._id) && onEditRetailer(row?._id)}>
                                            <img title={'Edit Retailer'} alt='edit retailer' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Retailer Edit']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-2`} />
                                        </div>
                                        <div data-qa='retailer-delete' onClick={() => ((!accessControl || actions?.['Retailer Delete']) && row?._id) && onDeleteRetailerRequest(row?._id)}>
                                            <img title={'Delete Retailer'} alt='delete retailer' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Retailer Delete']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-2`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default RetailersTable;