import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const OrderInfoContainer = () => {
    return (
        <WhiteContainer containerStyle='!h-auto !rounded'>
            <div>
                <p className='font-bold text-[20px] mb-6'>Detailed Status View</p>
                {[...Array(4)].map((x, i) => {
                    return (
                        <>
                            <div className='mb-4 mt-2 max-w-sm animate-pulse'>
                                <div className='h-3 bg-gray-200 rounded-full dark:bg-gray-200 w-[50%] mb-1'></div>
                                <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                            </div>
                        </>
                    )
                })
                }
            </div>
        </WhiteContainer>
    )
}

export default OrderInfoContainer;