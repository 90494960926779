import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IQuestion } from '../../../../interfaces/questions/IQuestions';
import Table from '../../../../shared/table';

const tableColumns = ['Question', 'Category', 'Markets', 'Answers', 'Price Effect Type', 'Price Effect Value', 'Max Amount', 'Min Amount']
const tableColumnsOrder = ['Question', 'Order', 'Category', 'Markets', 'Answers', 'Price Effect Type', 'Price Effect Value', 'Max Amount', 'Min Amount']

interface IPriceEffectsTable {
    rows?: Array<any>;
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    loading: boolean;
    selectedService?: any;
    sortedOrder?: string;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onChangeSortOrder: (name?: string) => void;
}

const PriceEffectsTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    loading,
    selectedService,
    sortedOrder,
    setPaginationState,
    onChangeSortOrder
}: IPriceEffectsTable) => {
    return (
        <Table
            columns={selectedService ? tableColumnsOrder : tableColumns}
            paginationData={paginationData}
            sortColumns={['Order']}
            sortStatus={{ 'Order': sortedOrder }}
            onClickSortColumn={onChangeSortOrder}
            setPaginationState={setPaginationState}
        >
            {loading ?
                <>
                    <>{[...Array(4)].map((x, i) => {
                        return (

                            <tr key={i}>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                            </tr>
                        )
                    })}
                    </>
                </>
                : <>
                    {(rows && rows?.length > 0) && rows?.map((row: IQuestion, idx: number) => {
                        const findService = selectedService && row?.services && row?.services?.length > 0 && row?.services?.find((item: any) => item?.name === selectedService?.label)
                        return (
                            <>
                                <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                    <td className='px-6 py-4' rowSpan={row?.answers?.options ? row?.answers?.options?.length || 1 : 1}>{row?.question}</td>
                                    {selectedService && <td className='px-6 py-4' rowSpan={row?.answers?.options ? row?.answers?.options?.length || 1 : 1}>{findService?.order || ''}</td>}
                                    <td className='px-6 py-4' rowSpan={row?.answers?.options ? row?.answers?.options?.length || 1 : 1}>{row?.category?.displayName || ''}</td>
                                    <td className='px-6 py-4' rowSpan={row?.answers?.options ? row?.answers?.options?.length || 1 : 1}>
                                        {
                                            (row?.markets && row?.markets.length > 0 && row?.markets?.[0]) ? row?.markets?.map((item: any, index: number) => {
                                                return <p key={index}>{item.label}</p>
                                            })
                                                : <p>All Markets</p>
                                        }
                                    </td>
                                    {(row?.answers?.options && row?.answers?.options?.length > 0) &&
                                        row?.answers?.options.filter((item: any, index: number) => index === 0)?.map((item: any, index: number) => {
                                            const findOption = findService && findService?.options && findService?.options?.length > 0 && findService?.options?.find((option: any) => option?.answer === item.answer)
                                            return (
                                                <>
                                                    <td className='py-2 px-4 align-top'>{item.answer}</td>
                                                    <td className='py-2 px-4 align-top'>{findOption?.effect?.type || ''}</td>
                                                    <td className='py-2 px-4 align-top'>{findOption?.effect?.value || ' '}</td>
                                                    <td className='py-2 px-4 align-top'>{findOption?.effect?.maxAmount || ' '}</td>
                                                    <td className='py-2 px-4 align-top'>{findOption?.effect?.minAmount || ' '}</td>
                                                </>
                                            )
                                        })
                                    }
                                </tr>
                                {(row?.answers?.options && row?.answers?.options?.length > 1) &&
                                    row?.answers?.options.filter((item: any, index: number) => index !== 0)?.map((item: any, index: number) => {
                                        const findOption = findService && findService?.options && findService?.options?.length > 0 && findService?.options?.find((option: any) => option?.answer === item.answer)
                                        return (
                                            <tr key={index} className='bg-white border-b hover:bg-gray-50'>
                                                <td className='py-2 px-4 align-top'>{item.answer}</td>
                                                <td className='py-2 px-4 align-top'>{findOption?.effect?.type || ''}</td>
                                                <td className='py-2 px-4 align-top'>{findOption?.effect?.value || ' '}</td>
                                                <td className='py-2 px-4 align-top'>{findOption?.effect?.maxAmount || ' '}</td>
                                                <td className='py-2 px-4 align-top'>{findOption?.effect?.minAmount || ' '}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </>
                        )
                    })}
                </>
            }
        </Table >
    )
}

export default PriceEffectsTable;