import React, { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import Button from '../button';

interface ISelectCheckbox {
    options: Array<any>;
    selectedOption?: string | string[] | any;
    onChangeSelectedOption: (e: any, type?: string, index?: any, parent?: any) => void;
    multiple?: boolean;
    placeholder?: string;
    error?: string;
    selectStyle?: any;
    containerStyle?: string;
    uniqueName?: string;
    name?: string;
    isLoading?: boolean;
    mainWrpStyle?: string;
    dropDownStyle?: string;
    optionsItemStyle?: string;
    index?: any;
    loadingOptions?: boolean;
    dynamicOptions?: boolean;
    dataQa?: string;
    hideRelative?: boolean;
    parent?: any;
    showSearchDB?: boolean;
    onChangeValue?: (e: any, type?: string, index?: any, parent?: any) => void;
    onMenuClose?: (type?: string, index?: any, parent?: any) => void;
    onClickSearchInDb?: (type?: string, index?: any, parent?: any) => void;
}

function AsyncSelectCheckbox({
    options,
    selectedOption,
    placeholder,
    error,
    parent,
    containerStyle,
    selectStyle,
    dropDownStyle,
    uniqueName,
    mainWrpStyle,
    name,
    isLoading,
    index,
    showSearchDB,
    dataQa,
    loadingOptions,
    hideRelative,
    optionsItemStyle,
    onChangeSelectedOption,
    onChangeValue,
    onMenuClose,
    onClickSearchInDb
}: ISelectCheckbox) {
    const [isOpen, setIsOpen] = useState(false);

    const onHandleMenuClose = () => {
        onMenuClose?.(uniqueName, index, parent)
        setIsOpen(false);
    }

    const handleChange = (e: any) => {
        onChangeSelectedOption(e, uniqueName, index, parent);
        setIsOpen(false);
    };

    return (
        <div className={`w-auto ${mainWrpStyle || ''}`}>
            <Combobox disabled={isLoading || false} value={selectedOption} onChange={(e) => handleChange(e)}>
                <div className={`${hideRelative ? '' : 'relative'}`}>
                    {name && <p className='mb-1 text-[#a4a4a4]'>{name}</p>}
                    <div className={`relative z-0 w-full cursor-pointer border-solid !border-[1px] ${error ? '!border-red-400' : '!border-[#e5e7eb]'} bg-white !rounded-[4px] ${containerStyle || ''}`}>
                        <Combobox.Input
                            data-qa={dataQa || ''}
                            className={`w-full relative z-0 !border-1 ${error ? '!border-red-400' : '!border-[#e5e7eb]'} !rounded-[4px] py-2 pl-3 pr-10 text-md text-[#747474] ${selectStyle || ''}`}
                            displayValue={(option: any) => placeholder || ''}
                            onChange={(event) => onChangeValue?.(event.target.value, uniqueName, index, parent)}
                        />
                        <Combobox.Button onClick={() => setIsOpen(!isOpen)} className='absolute inset-y-0 right-0 flex items-center pr-2'>
                            {isLoading && <div className='flex justify-center items-center h-auto mr-1'>
                                <div className='grid gap-1'>
                                    <div className='flex items-center justify-center space-x-1 animate-pulse'>
                                        <div className='w-[3px] h-[3px] bg-[#b4b4b4] rounded-full'></div>
                                        <div className='w-[3px] h-[3px] bg-[#b4b4b4] rounded-full'></div>
                                        <div className='w-[3px] h-[3px] bg-[#b4b4b4] rounded-full'></div>
                                    </div>
                                </div>
                            </div>
                            }
                            <img src={'/assets/shared/arrow-down-gray.svg'} className={'h-[15px] object-contain'} />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                        show={isOpen}
                        afterLeave={() => onHandleMenuClose?.()}
                    >
                        <Combobox.Options className={`absolute z-[100] mt-1 max-h-[150px] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${dropDownStyle || ''}`}>
                            {showSearchDB &&
                                <div className='flex flex-row justify-center'>
                                    <Button
                                        label='Search More Values'
                                        dataQa={'search-more-values'}
                                        onClickButton={() => onClickSearchInDb?.(uniqueName, index, parent)}
                                        className='btn-filters !rounded min-w-[90%] mb-2 mt-1'
                                    />
                                </div>
                            }
                            {loadingOptions ?
                                <div className='relative cursor-default text-center select-none py-2 px-4 text-gray-700'>
                                    Loading...
                                </div> :
                                <>
                                    {!(options && options?.length > 0) ? (
                                        <div className='relative cursor-default text-center select-none py-2 px-4 text-gray-700'>
                                            No Option
                                        </div>
                                    ) : (options?.map((option: any, idx: number) => {
                                        return (
                                            <Combobox.Option
                                                key={idx}
                                                className={'relative cursor-pointer select-none py-2 pl-4 pr-4 hover:bg-[#deebff] hover:text-[#484c52] text-gray-900'}
                                                value={option}>
                                                <span className={optionsItemStyle || 'block truncate text-[16px] font-normal'}>
                                                    {option.label}
                                                </span>
                                            </Combobox.Option>
                                        )
                                    })
                                    )}
                                </>
                            }

                        </Combobox.Options>
                    </Transition>
                    {error ? <span className='block text-xs text-red-500'>{error}</span> : <span className='block h-2 mt-2' />}
                </div>
            </Combobox>
        </div>
    )
}

export default AsyncSelectCheckbox;