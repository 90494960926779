import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { GRADES_ITEMS } from '../../../../utils/constants/grades';
import Loading from '../../../../shared/Loading';
import Table from '../../../../shared/table';


interface IAdminPricingTable {
    rows: Array<any>;
    paginationData?: IPage;
    state: any;
    markets?: IAllMarkets[];
    selectedMarket: string;
    pricingLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const AdminPricingTable = ({
    rows,
    paginationData,
    markets,
    state,
    selectedMarket,
    pricingLoading,
    setPaginationState,
}: IAdminPricingTable) => {
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Last Update', 'Pricing'])
    const [tableExtraKeys, setTableExtraKeys] = useState<Array<any>>()

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const formatTableColumnsAndKeys = () => {
        setTableLoading(true)
        const findKeys = rows && rows?.length > 0 && rows?.[0]?.value && Object?.keys(rows?.[0]?.value)
        const formatTableColumns = []
        const formatTableExtraKeys: any = []
        formatTableColumns.push('Last updated')
        findKeys && findKeys?.length > 0 && findKeys?.map((key: string) => {
            const formatKey = capitalizeFirstLetter(key)
            const formatElement = (formatKey || '').replaceAll('_', ' ')
            formatTableExtraKeys.push(key)
            formatTableColumns.push(formatElement)
            return key
        })

        formatTableColumns.push('Price')
        setTableColumns(formatTableColumns || [])
        setTableExtraKeys(formatTableExtraKeys || [])
    }

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [rows, paginationData, state])

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [])

    useEffect(() => {
        if (tableExtraKeys) {
            setTableLoading(false)
        }
    }, [tableExtraKeys])

    return (
        <>
            {tableLoading && !tableColumns ?
                <div className='flex flex-row min-w-full justify-center my-3'>
                    <Loading />
                </div>
                :
                <Table
                    columns={tableColumns}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                    containerStyle={'mt-0'}
                >
                    {pricingLoading ?
                        <>
                            <>{[...Array(4)].map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                        {tableExtraKeys && tableExtraKeys?.length > 0 && tableExtraKeys?.map(element => {
                                            return (
                                                <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                            )
                                        })
                                        }
                                        <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                        <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    </tr>
                                )
                            })}
                            </>
                        </>
                        : <>
                            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                                const lastUploaded = moment(row?.updatedAt || '').fromNow()
                                const findMarket = markets && markets?.length > 0 && markets?.find((market) => market?._id === selectedMarket)
                                return (
                                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                        <td className='px-6 py-4'>{lastUploaded}</td>

                                        {tableExtraKeys && tableExtraKeys?.length > 0 && tableExtraKeys?.map((key: string, index: number) => {
                                            const findGrade = (key || '')?.toLowerCase() === 'grade' && GRADES_ITEMS.find((grade: any) => (grade.value || '').toLowerCase() === (row?.value?.[key] || '').toLowerCase())
                                            return (
                                                <td
                                                    key={index}
                                                    className={`px-6 py-4`}>
                                                    {(key || '')?.toLowerCase() === 'grade' ?
                                                        (findGrade ? findGrade?.name : row?.value?.[key])
                                                        : row?.value?.[key]}
                                                </td>
                                            )
                                        })}
                                        <td className='px-6 py-4 min-w-[120px]'>
                                            <div className='flex flex-row items-center '>
                                                {findMarket && CURRENCY_SYMBOLS?.[`${(findMarket?.currency?.name || '')}`]}
                                                {Number(row?.quantity || 0).toFixed(2)}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    }
                </Table>
            }
        </>
    )
}

export default AdminPricingTable;