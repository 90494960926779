import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../../config';
import { PaginatedRMAs } from '../../../../interfaces/rma/paginatedStore.type';
import orderAxios from '../../../../utils/axios/orders.axios';
import { ApiResponse, PaginatedApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';

const tryToFetchRMAs = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    broker?: string,
    status?: string,
    country?: string,
    market?: string,
): Promise<AxiosResponse<PaginatedRMAs>> => {
    let url = `${ORDERS_URL}/rma?page=${pageNumber}&size=${pageSize}`;
    if (filters) url = url + `&filter=${filters}`
    if (broker) url = url + `&broker=${broker}`
    if (status) url = url + `&status=${status}`
    if (market) url = url + `&market=${market}`
    if (country) url = url + `&country=${country}`
    return orderAxios.get<PaginatedRMAs>(url);
};

const tryToFetchRMADetails = (
    id: string,
    { pageNumber, pageSize, filters }: IPaginationPayload): Promise<AxiosResponse<any>> => {
    const url = filters ? `${ORDERS_URL}/rma/${id}?page=${pageNumber}&size=${pageSize}&filter=${filters}`
        : `${ORDERS_URL}/rma/${id}?page=${pageNumber}&size=${pageSize}`

    return orderAxios.get<ApiResponse<any>>(url);
};

const tryToReviewReturn = (
    itemId: string,
    type: string,
    rejectReason?: string,
    revaluePrice?: number,
): Promise<any> => {
    const url = `${ORDERS_URL}/rma/review`;
    return orderAxios.post<ApiResponse<any>>(url, {
        itemId,
        type,
        rejectReason,
        revaluePrice,
    });
};

const tryToFetchAllRMARejectReasons = (): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/rma/reasons`;
    return orderAxios.get<any>(url);
};

const tryToFetchAllItemCredits = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any
): Promise<AxiosResponse<PaginatedRMAs>> => {
    const url = filters ?
        `${ORDERS_URL}/item-credits/all?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${ORDERS_URL}/item-credits/all?page=${pageNumber}&size=${pageSize}`
    return orderAxios.put<PaginatedRMAs>(url, data);
};
const tryToCreateCreditNote = (
    data: { creditItemIds: string[], brokerId: string, marketId: string }
): Promise<any> => {
    const url = `${ORDERS_URL}/credit-notes`;
    return orderAxios.post<ApiResponse<any>>(url, data);
};

const tryToFetchCreditSummary = (
    brokerId?: string
): Promise<AxiosResponse<any>> => {
    const url = brokerId ?
        `${ORDERS_URL}/credit-notes/summary?search=${brokerId}`
        : `${ORDERS_URL}/credit-notes/summary`
    return orderAxios.get<any>(url);
};

const tryToFetchAllCreditNotes = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any
): Promise<AxiosResponse<PaginatedApiResponse<any>>> => {
    const url = filters ?
        `${ORDERS_URL}/credit-notes/all?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${ORDERS_URL}/credit-notes/all?page=${pageNumber}&size=${pageSize}`
    return orderAxios.put<PaginatedApiResponse<any>>(url, data);
}

const tryToDownloadCreditNoteCSV = (
    creditNoteId: string
): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/credit-notes/download?creditNoteId=${creditNoteId}`
    return orderAxios.get<any>(url);
};

const tryToMarkCreditNoteAsPaid = (
    creditNoteId: string
): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/credit-notes/mark-as-paid`
    return orderAxios.put<any>(url,{
        creditNotes: [creditNoteId]
    });
};

const tryToFetchCreditNoteDetails = (
    creditNoteId: string
): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/credit-notes/${creditNoteId}/details`
    return orderAxios.get<any>(url);
};

export const RMAsService = {
    tryToFetchRMAs,
    tryToFetchRMADetails,
    tryToReviewReturn,
    tryToFetchAllRMARejectReasons,
    tryToFetchAllItemCredits,
    tryToCreateCreditNote,
    tryToFetchCreditSummary,
    tryToFetchAllCreditNotes,
    tryToDownloadCreditNoteCSV,
    tryToMarkCreditNoteAsPaid,
    tryToFetchCreditNoteDetails,
};
