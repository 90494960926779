import { AxiosResponse } from 'axios';
import { OFFER_API_URL } from '../../../../config';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { PaginatedTacList } from '../../../../interfaces/tac-list/paginatedStore.type';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';

const tryToAddTacList = (data: {
	data: Array<any>;
}): Promise<AxiosResponse> => {
	return brokersAxios.post(`${OFFER_API_URL}/tac-list`, data);
};

const tryToFetchAllTacLists = (
	pageNumber: number,
	pageSize: number,
	filters?: string,
): Promise<AxiosResponse<PaginatedTacList>> => {
	const url = filters
		? `${OFFER_API_URL}/tac-list?page=${pageNumber}&size=${pageSize}&search=${filters?.search}`
		: `${OFFER_API_URL}/tac-list?page=${pageNumber}&size=${pageSize}`;
	return brokersAxios.put<PaginatedTacList>(url, { filters });
};

// Find one tac list entry:
const tryToFetchSingleTacList = (
	id: string,
): Promise<AxiosResponse<ApiResponse<any>>> => {
	return brokersAxios.get(`${OFFER_API_URL}/tac-list/${id}`);
};

// Download tac list entries:
const tryToDownloadExtendedTacListData = (): Promise<AxiosResponse> => {
	return brokersAxios.put(`${OFFER_API_URL}/tac-list/download/all`);
};

// Delete tac list entry:
const tryToDeleteExtendedTacList = (id: string): Promise<AxiosResponse> => {
	return brokersAxios.delete(`${OFFER_API_URL}/tac-list/${id}`);
};

// Edit tac list entry:
const tryToEditExtendedTacList = (
	id: string,
	data: any,
): Promise<AxiosResponse> => {
	return brokersAxios.put(`${OFFER_API_URL}/tac-list/${id}/edit`, data);
};

// Find item model by imei
const tryToFetchItemModelByIMEI = (
	imei: string,
): Promise<AxiosResponse<ApiResponse<any>>> => {
	return brokersAxios.get(`${OFFER_API_URL}/dom/item/imei/${imei}`);
};

export const TacListService = {
	tryToAddTacList,
	tryToFetchAllTacLists,
	tryToFetchSingleTacList,
	tryToDownloadExtendedTacListData,
	tryToDeleteExtendedTacList,
	tryToEditExtendedTacList,
	tryToFetchItemModelByIMEI,
};
