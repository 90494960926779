import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch } from '../../app/hooks';
import { tryToAddTicket } from '../../store/brokers/partners/customer-care/customerCareSlice';
import { tryToFetchAllWarehouseSections } from '../../store/brokers/admin/partners/partnersSlice';
import { tryToFetchSectionLocations } from '../../store/brokers/admin/warehouses/warehousesSlice';
import Modal from '../modal';
import RaiseTicketForm from './form';
import SuccessRaised from './raised-successfully';


interface IRaiseTicketModal {
    openRaiseTicketModal: boolean;
    handleCloseModal: () => void;
    orderId: string;
    itemId: string;
    userData?: any;
    warehouseId?: string;
    orderDetails: any;
    categoryId?: string;
}

type FormValues = {
    description: string;
    subject: string;
    specialTrackingNumber: string;
};

const RaiseTicketModal = ({
    openRaiseTicketModal,
    handleCloseModal,
    orderDetails,
    orderId,
    warehouseId,
    userData,
    categoryId,
    itemId
}: IRaiseTicketModal) => {
    const dispatch = useAppDispatch();
    const [successfullyRaised, setSuccessfullyRaised] = useState<boolean>(false)
    const [createError, setCreateError] = useState<string>()
    const [showSubjectInput, setShowSubjectInput] = useState<boolean>(false)
    const [showSpecialTrackingInput, setShowSpecialTrackingInput] = useState<boolean>(false)
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [loadingOptionsLocation, setLoadingOptionsLocation] = useState<boolean>(false);
    const [responseLocations, setResponseLocations] = useState<any>();
    const [filesSelected, setFilesSelected] = useState<any>()
    const [searchFieldLocation, setSearchFieldLocation] = useState<any | null>(null);
    const [findBarcodeLocationsResponse, setFindBarcodeLocationsResponse] = useState<any>();
    const [searchInDBLocation, setSearchInDBLocation] = useState<any>();
    const [filteredLocations, setFilteredLocations] = useState<any>();
    const [selectedLocation, setSelectedLocation] = useState<any>();
    const [scannedLocation, setScannedLocation] = useState<any>();
    const [selectedSection, setSelectedSection] = useState<any>();
    const [sections, setSections] = useState<any>();

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.map((file: any) => {
            const reader = new FileReader();
            reader.onload = async (event: any) => {
                await setFilesSelected((prevState: any) => {
                    return [...prevState || [], file]
                })
            };
            reader.readAsDataURL(file);
            return file
        });
    }, []
    )
    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true, })

    const onDeleteImage = (file: any) => {
        const filterOutFile = (filesSelected && filesSelected?.length > 0) && filesSelected?.filter((item: any) => item !== file)
        setFilesSelected(filterOutFile || undefined)
    }

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    const onChangeValue = (e: any) => {
        const trimmedValue = e?.target?.value?.trim();
        const noSpacesAtTheBeginning = /^\s/;
        if (noSpacesAtTheBeginning.test(e?.target?.value)) {
            setValue('description', trimmedValue);
        } else {
            setValue('description', e?.target?.value);
        }
    }

    const onResponseNotFound = () => {
        setSearchFieldLocation(undefined)
        setSearchInDBLocation(undefined)
        setFilteredLocations(undefined)
        setResponseLocations(undefined)
    }

    const onSubmit = async (data: FormValues) => {
        if (loadingSubmit) {
            return;
        }
        if (userData?.inventory && sections && (responseLocations || filteredLocations) && !selectedLocation) {
            setCreateError('Please select item location.')
            setLoadingSubmit(false)
            return;
        }
        if (!data?.subject) {
            setCreateError('Please select a subject.')
            setLoadingSubmit(false)
            return;
        }
        setLoadingSubmit(true)
        try {
            const dataForm: any = new FormData()
            dataForm.append('orderId', orderId)
            dataForm.append('dashboard', 'brokers')
            dataForm.append('orderStatus', orderDetails?.orderData?.status)
            dataForm.append('itemId', itemId)
            dataForm.append('description', data?.description)
            dataForm.append('subject', data?.subject)
            data?.specialTrackingNumber && dataForm.append('specialTrackingNumber', data?.specialTrackingNumber)
            filesSelected && filesSelected?.length > 0 && filesSelected?.map((file: any, index: number) => {
                dataForm.append(`file${index + 1}`, file)
                return file
            })
            if (userData?.inventory && selectedLocation?._id) {
                dataForm.append('location', selectedLocation?._id)
            }
            await dispatch(tryToAddTicket(dataForm)).unwrap();
            reset();
            setCreateError(undefined)
            setSuccessfullyRaised(true)
        } catch (error) {
            setCreateError(`${error}`)
        }
        setLoadingSubmit(false)
    }

    const onCloseRaiseModal = () => {
        handleCloseModal();
        setShowSubjectInput(false)
        setShowSpecialTrackingInput(false)
        setSuccessfullyRaised(false)
        setSearchFieldLocation(undefined)
        setSearchInDBLocation(undefined)
        setFilteredLocations(undefined)
        setResponseLocations(undefined)
        setSelectedSection(undefined)
        setScannedLocation(undefined)
        setSelectedLocation(undefined)
        reset();
    }

    const onGettingWarehouseSections = async () => {
        try {
            const response = await dispatch(tryToFetchAllWarehouseSections({ warehouseId: warehouseId || '', type: 'space', category: categoryId })).unwrap()
            const formatResponse = response && response?.length > 0 && response?.map((item: any) => ({ ...item, value: item?._id, label: item?.name }))
            setSections(formatResponse || undefined)
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (userData?.inventory && warehouseId) {
            onGettingWarehouseSections()
        }
    }, [userData?.inventory, warehouseId, openRaiseTicketModal])

    const onClickClearLocationAndSection = () => {
        setSelectedSection(undefined)
        setScannedLocation(undefined)
        setSearchFieldLocation(undefined)
        setFilteredLocations(undefined)
        setSearchInDBLocation(undefined)
        setResponseLocations(undefined)
        setSelectedLocation(undefined)
    }

    const onFindIfThereIsSpaceInSelectedLocation = async () => {
        if (selectedSection || scannedLocation) {
            try {
                const response: any = await dispatch(tryToFetchSectionLocations({ barcode: selectedSection?.barcode || scannedLocation }))?.unwrap()
                if (response && response?.length > 0) {
                    const formatResponse = response && response?.length > 0 && response?.map((item: any) => {
                        let formatLabel: any
                        item?.descriptor && (Object?.keys(item?.descriptor))?.forEach((obj: any, idx: number) => {
                            formatLabel = idx === 0 ? item?.descriptor?.[obj] : formatLabel + ` - ${item?.descriptor?.[obj]}`
                        })
                        return {
                            ...item,
                            value: item?._id,
                            label: formatLabel,
                            name: formatLabel,
                            fullName: item?.fullName
                        }
                    })
                    setResponseLocations(formatResponse || undefined)
                    setFindBarcodeLocationsResponse(undefined)
                } else if (response && response?.length === 0) {
                    onResponseNotFound()
                    setFindBarcodeLocationsResponse('No available capacity in this location!')
                } else {
                    onResponseNotFound()
                    setFindBarcodeLocationsResponse('Not found any location for the Barcode!')
                }
            } catch (err) {
                setFindBarcodeLocationsResponse(`${err}`)
            }
        }
    }

    const onChangeSelectedSection = (e: any, type?: string) => {
        setSelectedSection(e)
        setScannedLocation(undefined)
    }

    const onChangedScannedLocation = (e: any, type?: string) => {
        setScannedLocation(e)
        setSelectedSection(undefined)
        setResponseLocations(undefined)
    }

    const onEnterAction = async (e: any, type?: string) => {
        e.stopPropagation()
        if (e?.key === 'Enter') {
            if (scannedLocation) {
                onFindIfThereIsSpaceInSelectedLocation()
            }
        }
    }

    const onChangeSelectedLocation = (e: any, type?: string) => {
        if (type) {
            setSelectedLocation(e)
            if (searchFieldLocation) {
                setSearchFieldLocation(undefined)
            }
            setFindBarcodeLocationsResponse(undefined)
        }
    }

    const onMenuCloseAfterSearchLocation = () => {
        setSearchFieldLocation(undefined)
    }

    const onClickSearchInDbLocation = () => {
        if (searchInDBLocation) {
            setFilteredLocations(undefined)
            setLoadingOptionsLocation(true)
            setSearchFieldLocation(searchInDBLocation)
        }
    }

    const onChangeSearchLocationFields = (e: any, type?: string) => {
        setSearchInDBLocation(e)
        if (e) {
            if (responseLocations) {
                const formatSearch = e ? e?.split?.(' ').join('')?.replace('-', '') : null
                const formatFilteredFields = (responseLocations && responseLocations?.length > 0) && responseLocations?.filter((next: any) => (next?.fullName || '').toLowerCase().includes((formatSearch || '')?.toLowerCase()))
                if (formatFilteredFields && formatFilteredFields?.length > 0) {
                    setSearchInDBLocation(e)
                    setLoadingOptionsLocation(false)
                    setFilteredLocations(formatFilteredFields)
                }
                else {
                    setFilteredLocations(undefined)
                    setLoadingOptionsLocation(true)
                    setSearchFieldLocation(e)
                }
            } else {
                setSearchFieldLocation(e)
            }
        } else {
            setFilteredLocations(undefined)
        }
    }

    useEffect(() => {
        if (selectedSection) {
            onFindIfThereIsSpaceInSelectedLocation()
        }
    }, [selectedSection])

    const onGettingFilteredLocations = async () => {
        try {
            const formatSearch = searchFieldLocation ? searchFieldLocation?.split?.(' ').join('')?.replace('-', '') : searchFieldLocation
            const response: any = await dispatch(tryToFetchSectionLocations({ barcode: selectedSection?.value || scannedLocation, data: { search: formatSearch } }))?.unwrap()
            const formatResponse: any = (response && response?.length > 0) && response?.map((item: any) => {
                let formatLabel: any
                item?.descriptor && (Object?.keys(item?.descriptor))?.forEach((obj: any, idx: number) => {
                    formatLabel = idx === 0 ? item?.descriptor?.[obj] : formatLabel + ` - ${item?.descriptor?.[obj]}`
                })
                return {
                    ...item,
                    value: item?._id,
                    label: formatLabel,
                    name: formatLabel,
                    fullName: item?.fullName
                }
            })
            setFilteredLocations(formatResponse || [])
            setLoadingOptionsLocation(false)
        } catch (err) {
            // error here
        }
        setLoadingOptionsLocation(false)
        setSearchFieldLocation(null)
    }

    useEffect(() => {
        if (searchFieldLocation !== null) {
            onGettingFilteredLocations()
        }
    }, [searchFieldLocation])


    const onChangeSelectedSubject = (e: any) => {

        if (e?.target?.value === 'Special Tracking Request') {
            setShowSpecialTrackingInput(true)
            reset({ 'specialTrackingNumber': undefined })
        }
        if (e?.target?.value === 'Other') {
            setShowSubjectInput(true)
            reset({ 'subject': undefined })
        } else {
            setValue('subject', e?.target?.value)
            setShowSubjectInput(false)
        }
    }

    return (
        <Modal
            open={openRaiseTicketModal}
            onClose={onCloseRaiseModal}
            showInRight={true}
            contentContainerStyle={'min-w-[650px]'}>
            <div className={'p-2 min-w-[600px]'}>
                {successfullyRaised ?
                    <SuccessRaised
                        handleCloseModal={onCloseRaiseModal} />
                    : <RaiseTicketForm
                        onChangeValue={onChangeValue}
                        onSubmit={onSubmit}
                        handleSubmit={handleSubmit}
                        watch={watch}
                        register={register}
                        onDeleteImage={onDeleteImage}
                        filesSelected={filesSelected}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        loadingOptions={loadingOptionsLocation}
                        filteredLocations={(filteredLocations && filteredLocations?.length > 0) ? filteredLocations : responseLocations}
                        errors={errors}
                        sections={sections}
                        userData={userData}
                        loading={loadingSubmit}
                        createError={createError}
                        selectedSection={selectedSection}
                        scannedLocation={scannedLocation}
                        showSubjectInput={showSubjectInput}
                        showSpecialTrackingInput={showSpecialTrackingInput}
                        selectedItemLocation={selectedLocation}
                        searchInDBLocation={searchFieldLocation}
                        findBarcodeLocationsResponse={findBarcodeLocationsResponse}
                        onChangeSearchLocationField={onChangeSearchLocationFields}
                        onChangeSelectedLocation={onChangeSelectedLocation}
                        onClickSearchInDb={onClickSearchInDbLocation}
                        onChangeSelectedSection={onChangeSelectedSection}
                        onEnterAction={onEnterAction}
                        onMenuCloseAfterSearch={onMenuCloseAfterSearchLocation}
                        onChangedScannedLocation={onChangedScannedLocation}
                        onClickClearLocationAndSection={onClickClearLocationAndSection}
                        onChangeSelectedSubject={onChangeSelectedSubject}
                    />
                }
            </div>
        </Modal>
    )
}

export default RaiseTicketModal;