import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { IToast } from '../../../../interfaces/components/IToast';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IPayServices } from '../../../../interfaces/pay-services/IPayServices';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IPricingEffects } from '../../../../interfaces/pricing-effects/IPricingEffects';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import { tryToFetchAllPartners } from '../../../../store/brokers/admin/partners/partnersSlice';
import { tryToFetchAllPayServices } from '../../../../store/brokers/admin/pay-services/payServicesSlice';
import { tryToFetchAllAssetsTemplates } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import {
    tryToDeletePricingEffect,
    tryToDownloadPricingEffects,
    tryToFetchPricingEffects
} from '../../../../store/brokers/admin/pricing-effects/pricingEffectsSlice';
import PricingEffectsFilters from './details/PricingEffectsFilters';
import PricingEffectsModal from './modals/PricingEffectsModal';
import PricingEffectsTable from './tables/PricingEffectsTable';
import Toast from '../../../../shared/toast';
import Button from '../../../../shared/button';
import DeleteModal from '../../../../shared/delete-modal';
import EmptyContainer from '../../../../shared/empty-container';


const PricingEffects = () => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<IPricingEffects[]>([]);
    const [markets, setMarkets] = useState<IAllMarkets[]>();
    const [payServices, setPayServices] = useState<any>();
    const [sectors, setSectors] = useState<IAssetTemplate[]>();
    const [partners, setPartners] = useState<IPartners[]>()
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const [selectedSector, setSelectedSector] = useState<any>();
    const [selectedService, setSelectedService] = useState<any>();
    const [pricingEffectItemId, setPricingEffectItemId] = useState<string | null>();
    const [showPricingEffectModal, setShowPricingEffectModal] = useState<boolean>(false);
    const [loadingDownloadTemplate, setLoadingDownloadTemplate] = useState<boolean>(false)
    const [pricingEffectsData, setPricingEffectsData] = useState<any>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>();
    const paginationData = useAppSelector((state) => state.pricingEffects.pricingEffects?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const rowsLoading = useAppSelector((state) => state.pricingEffects.effectsAreLoading);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const state = useAppSelector((state) => state.pricingEffects);
    const [getAllMarkets] = useGetAllMarketsMutation();

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Pricing Admin')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Manage Pricing Adjustments')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.pricingEffects) {
            const rows =
                state.pricingEffects?.data?.elements &&
                    Array.isArray(state.pricingEffects?.data.elements)
                    ? state.pricingEffects?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.pricingEffects]);

    const getPricingEffectsData = async () => {
        const formatData: any = {
            ...paginationState,
        }
        if (selectedMarket) {
            formatData.data = {
                ...formatData.data || {},
                market: selectedMarket ? selectedMarket?.displayName : ''
            }
        }
        if (selectedSector) {
            formatData.data = {
                ...formatData.data || {},
                assetTemplateId: selectedSector ? selectedSector?._id : ''
            }
        }
        if (selectedService) {
            formatData.data = {
                ...formatData.data || {},
                source: selectedService ? selectedService?.name : ''
            }
        }
        dispatch(tryToFetchPricingEffects(formatData));
    }

    useEffect(() => {
        getPricingEffectsData()
    }, [paginationState]);

    const getMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, displayName: market?.name, name: market?.label, value: market?._id }))
        setMarkets(formatMarkets || [])
        const payServicesResponse = await dispatch(tryToFetchAllPayServices()).unwrap()
        const formatPayServices = payServicesResponse && payServicesResponse?.data && payServicesResponse?.data?.length > 0 && payServicesResponse?.data?.map((service: IPayServices) => ({ ...service, label: service?.displayName, value: service?._id, name: service?.name }))
        setPayServices(formatPayServices || [])
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.companyName }))
        setPartners(formatPartners || [])
    }

    useEffect(() => {
        getMarketData()
        dispatch(tryToFetchAllAssetsTemplates());
    }, []);

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const onDeletePricingEffect = async () => {
        try {
            await dispatch(tryToDeletePricingEffect(pricingEffectItemId || ''));
            setShowToast({
                type: 'success',
                message: `Pricing Effect successfully deleted`,
            });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setPricingEffectItemId(undefined);
        setShowDeleteModal(false);
    };

    const onAddPricingEffect = (id?: string) => {
        if (id) {
            setPricingEffectItemId(id);
        }
        setShowPricingEffectModal(true);
    };

    const onClosePricingEffectModal = () => {
        setPricingEffectItemId(null);
        setShowPricingEffectModal(false);
    };

    const onSavePricingEffectChanges = (type: string, message: string) => {
        setShowToast({ type, message });
    };

    const onDeletePricingEffectRequest = (id: string) => {
        setShowDeleteModal(true);
        setPricingEffectItemId(id);
    };

    const onSelectFilters = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'market':
                    setSelectedMarket(value);
                    break;
                case 'sector':
                    setSelectedSector(value);
                    break;
                case 'service':
                    setSelectedService(value);
                    break;
            }
        }
        onResetPaginationState()
    }

    const onClearAllFilters = () => {
        setSelectedMarket(undefined)
        setSelectedSector(undefined)
        setSelectedService(undefined)
        onResetPaginationState()
    }

    const onDownloadPricingEffects = async () => {
        setLoadingDownloadTemplate(true)
        try {
            const response: any = await dispatch(tryToDownloadPricingEffects(null)).unwrap()
            setPricingEffectsData(response || [])
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
            setLoadingDownloadTemplate(false)
        }
    }

    useEffect(() => {
        if (pricingEffectsData && loadingDownloadTemplate) {
            csvLink.current.link.click()
        }
        setLoadingDownloadTemplate(false)
    }, [pricingEffectsData])

    return (
        <div>
            <div>
                <p className='page-title'>Pricing Adjustments</p>
            </div>
            <div className='my-5'>
                <PricingEffectsFilters
                    markets={markets}
                    sectors={sectors}
                    services={payServices}
                    selectedService={selectedService}
                    selectedMarket={selectedMarket}
                    selectedSector={selectedSector}
                    onChangeSelectedFilter={onSelectFilters}
                />
            </div>
            <div className='flex flex-row justify-end flex-wrap'>
                <Button
                    label={'Clear all filters'}
                    dataQa={'clear-all-filters'}
                    className={'btn-primary-text-underline flex flex-row justify-end bg-transparent mr-2 mb-1'}
                    onClickButton={onClearAllFilters}
                />
                <Button
                    label={'Add New Pricing Adjustments'}
                    dataQa={'add-new-pricing-adjustments'}
                    className={`${(!accessControl || pageAccess?.actions?.['Add New Pricing Adjustments']) ? 'btn-main' : 'btn-main-disable'} mr-2 mb-1`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Pricing Adjustments']) && onAddPricingEffect()}
                />
                <Button
                    label={loadingDownloadTemplate ? 'Loading Data...' : 'Download Pricing Adjustments'}
                    dataQa={'download-pricing-adjustments'}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Pricing Adjustments']) ? 'btn-main' : 'btn-main-disable'} mr-2 mb-1`}
                    onClickButton={() => (!loadingDownloadTemplate && (!accessControl || pageAccess?.actions?.['Download Pricing Adjustments'])) && onDownloadPricingEffects()}
                />
                <CSVLink
                    ref={csvLink}
                    data={(pricingEffectsData && pricingEffectsData?.length > 0) ? pricingEffectsData?.filter((item: any, idx: number) => idx > 0) || [] : []}
                    headers={(pricingEffectsData && pricingEffectsData?.length > 0) ? pricingEffectsData?.[0] || [] : []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={`Pricing-adjustments.csv`}
                ></CSVLink>
            </div>
            <div className='my-5 relativ'>
                {(rows?.length > 0 || rowsLoading) ?
                    <PricingEffectsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onDeletePricingEffect={onDeletePricingEffectRequest}
                        onEditPricingEffect={onAddPricingEffect}
                    />
                    : <EmptyContainer
                        text={'No Pricing Adjustments added yet.'}
                        showImage={true}
                    />
                }
            </div>
            <PricingEffectsModal
                markets={markets}
                sectors={sectors}
                services={payServices}
                partners={partners}
                itemId={pricingEffectItemId}
                openActionsModal={showPricingEffectModal}
                handleCloseModal={onClosePricingEffectModal}
                onSaveChanges={onSavePricingEffectChanges}
            />
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeletePricingEffect}
            />
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
        </div>
    );
};

export default PricingEffects;