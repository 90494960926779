import { createApi } from '@reduxjs/toolkit/query/react';
import { logisticAxiosQuery } from '../../../../utils/axios/logistic.axios';

export const carbonImpactAPI = createApi({
    reducerPath: 'carbonImpact',
    baseQuery: logisticAxiosQuery(),
    tagTypes: ['Posts'],
    endpoints: (builder) => ({
        carbonImpactForItem: builder.mutation<any, string>({
            query: (category: string) => ({
                url: '/carbon-accounting/calculate/basket-emission/used',
                method: 'POST',
                data: {
                    items: [
                        { category }
                    ]
                }
            }),
        }),
    }),
});

export const {
    useCarbonImpactForItemMutation,
} = carbonImpactAPI;
