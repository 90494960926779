import React from 'react';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';


interface IConfirmSplitOrder {
    loadingSplit: boolean;
    createError?: string;
    handleCloseModal: () => void;
    onApproveAction: () => void;
}

const ConfirmSplitOrder = ({
    loadingSplit,
    createError,
    handleCloseModal,
    onApproveAction,
}: IConfirmSplitOrder) => {
    return (
        <div className={'w-full'}>
            <div className='border-b-2 border-slate-200 pb-2 my-1'>
                <p className='font-medium text-slate-700'>Split Order</p>
            </div>
            <p className='my-2'>Are you sure you want to split order items based on their respective brokers?</p>
            {createError &&
                <div className='my-2'>
                    <Error text={createError} />
                </div>
            }
            <div className='flex flex-row justify-end mt-2 mb-5'>
                <Button
                    label='Continue'
                    dataQa={'continue-button'}
                    className={loadingSplit ? 'btn-primary-disable' : 'btn-primary'}
                    onClickButton={() => !loadingSplit && onApproveAction()}
                />
                <Button
                    label='Cancel'
                    dataQa={'cancel-button'}
                    className={loadingSplit ? 'btn-main-disable' : 'btn-error ml-2  !shadow-md !rounded'}
                    onClickButton={() => !loadingSplit && handleCloseModal()}
                />
            </div>
        </div>
    )
}
export default ConfirmSplitOrder;