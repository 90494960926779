import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import { IScrapingFees } from '../../../../interfaces/scraping-fees/IScrapingFees';
import { PaginatedScrapingFees } from '../../../../interfaces/scraping-fees/paginatedStore.type';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';

const tryToFetchScrapingFees = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedScrapingFees>> => {
    const url = `${USERS_API_URL}/scraping-fees/all?page=${pageNumber}&size=${pageSize}`
    return brokersAxios.put<PaginatedScrapingFees>(url, data);
};

const tryToFetchSingleScrapingFee = (scrapingId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/scraping-fees/${scrapingId}`);
};

const tryToDownloadScrapingFees = (): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/scraping-fees/all`, { includePagination: false });
};

const tryToAddScrapingFee = (data: IScrapingFees): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/scraping-fees`, data);
};

const tryToDeleteScrapingFee = (scrapingFeeId: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/scraping-fees/${scrapingFeeId}/delete`);
};

const tryToEnableScrapingFee = (scrapingFeeId: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/scraping-fees/${scrapingFeeId}/enable`);
};

const tryToEditScrapingFee = (scrapingFeeId: string, payload: IScrapingFees): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/scraping-fees/${scrapingFeeId}/edit`, payload);
};

const tryToImportScrapingFees = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/scraping-fees/import`, { data });
};

export const ScrapingFeesService = {
    tryToFetchScrapingFees,
    tryToFetchSingleScrapingFee,
    tryToDownloadScrapingFees,
    tryToAddScrapingFee,
    tryToImportScrapingFees,
    tryToDeleteScrapingFee,
    tryToEnableScrapingFee,
    tryToEditScrapingFee
};
