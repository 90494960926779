import React from 'react';
import moment from 'moment';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IInvoiceTemplate } from '../../../../interfaces/invoice-templates/IInvoiceTemplates';
import Table from '../../../../shared/table';

const tableColumns = ['ID', 'Title', 'Display Name', 'Template Name', 'VAT Number', 'Currency', 'Contact Name', 'Company', 'Country', 'Address', 'Email', 'Phone Number', 'Updated Date', 'Actions']

interface IInvoiceTemplatesTable {
    rows: IInvoiceTemplate[];
    paginationData?: IPage;
    loading?: boolean;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onEditTemplate: (id: string) => void;
    onDeleteTemplate: (id: string) => void;
}

const InvoiceTemplatesTable = ({
    rows,
    paginationData,
    loading,
    accessControl,
    actions,
    setPaginationState,
    onDeleteTemplate,
    onEditTemplate
}: IInvoiceTemplatesTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
        >
            {loading ?
                <>
                    <>{[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                            </tr>
                        )
                    })}
                    </>
                </>
                : <>
                    {rows && rows?.length > 0 &&
                        rows?.map((row: IInvoiceTemplate, idx: number) => {
                            return (
                                <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                    <td className='px-6 py-4'>{row?._id || ''}</td>
                                    <td className='px-6 py-4'>{row?.title || ''}</td>
                                    <td className='px-6 py-4'>{row?.displayName || ''}</td>
                                    <td className='px-6 py-4'>{row?.templateName || ''}</td>
                                    <td className='px-6 py-4'>{row?.vatNumber || ''}</td>
                                    <td className='px-6 py-4'>{row?.currency || ''}</td>
                                    <td className='px-6 py-4'>{row?.contactInfo?.informationName || ''}</td>
                                    <td className='px-6 py-4'>{row?.contactInfo?.informationCompany || ''}</td>
                                    <td className='px-6 py-4'>{row?.contactInfo?.informationCountry || ''}</td>
                                    <td className='px-6 py-4'>{row?.contactInfo?.informationAddress || ''}</td>
                                    <td className='px-6 py-4'>{row?.contactInfo?.informationEmail || ''}</td>
                                    <td className='px-6 py-4'>{row?.contactInfo?.informationPhoneNumber || ''}</td>
                                    <td className='px-6 py-4'>{row?.updatedAt && moment(row?.updatedAt).fromNow()}</td>
                                    <td className='px-6 py-4'>
                                        <div className='flex flex-row items-center justify-start'>
                                            <div data-qa={'edit-item'} onClick={() => (!accessControl || actions?.['Edit Template']) && onEditTemplate(row?._id || '')}>
                                                <img title='Edit Item' alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Template']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                            </div>
                                            <div data-qa={'delete-item'} onClick={() => (!accessControl || actions?.['Delete Template']) && onDeleteTemplate(row?._id || '')}>
                                                <img title='Delete Item' alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Template']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </>
            }
        </Table >
    )
}

export default InvoiceTemplatesTable;