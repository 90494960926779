import React, { useState, useEffect } from 'react';
import {
    IQuestion,
    IQuestionService
} from '../../../../interfaces/questions/IQuestions';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';


interface IQuestionDetailsTable {
    rows?: Array<any>;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    questionDetails?: IQuestion;
    onEditService: (id: string) => void;
    onDeleteService: (id: string) => void;
    onCopyService: (id: string) => void;
}

const QuestionDetailsTable = ({
    rows,
    accessControl,
    actions,
    questionDetails,
    onEditService,
    onDeleteService,
    onCopyService
}: IQuestionDetailsTable) => {
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Service', 'Required', 'Order', 'Actions'])

    useEffect(() => {
        if (questionDetails?.answers && questionDetails?.answers?.options && questionDetails?.answers?.options?.length > 0) {
            const tableColumnsFormat = ['Service', 'Required', 'Order']
            questionDetails?.answers?.options?.map((field) => tableColumnsFormat.push(field?.answer))
            tableColumnsFormat.push('Actions')
            setTableColumns(tableColumnsFormat)
        }
    }, [questionDetails])

    return (
        <Table
            columns={tableColumns}
            hidePagination={true}>
            {(rows && rows?.length > 0) && rows?.map((row: IQuestionService, idx: number) => {
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?.name}</td>
                        <td className='px-6 py-4'>{row?.required ? 'Yes' : 'No'}</td>
                        <td className='px-6 py-4'>{row?.order}</td>
                        {(questionDetails?.answers && questionDetails?.answers?.options && questionDetails?.answers?.options?.length > 0) &&
                            questionDetails?.answers?.options?.map((item: any, index: number) => {
                                const findPricingEffects: any = (row?.options && row?.options?.length > 0) && row?.options?.find((option: any) => option?.id === item?.id)
                                return (
                                    <td key={index} className='px-6 py-4'>
                                        {findPricingEffects?.effect?.type && <p>Pricing Effect Type: {findPricingEffects?.effect?.type}</p>}
                                        {findPricingEffects?.effect?.value && <p>Pricing Effect Value: {findPricingEffects?.effect?.value}</p>}
                                        {findPricingEffects?.effect?.effectType && <p>Effect Type: {findPricingEffects?.effect?.effectType}</p>}
                                        {findPricingEffects?.effect?.minAmount && <p>Min Amount: {findPricingEffects?.effect?.minAmount}</p>}
                                        {findPricingEffects?.effect?.maxAmount && <p>Max Amount: {findPricingEffects?.effect?.maxAmount}</p>}
                                        {findPricingEffects?.description && <p>Description:{findPricingEffects?.description}</p>}
                                    </td>
                                )
                            })}
                        <td className='px-6 py-4'>
                            <div className='flex flex-row items-center'>
                                <Button
                                    label='Copy'
                                    data-qa={'copy-question-service'}
                                    onClickButton={() => ((!accessControl || actions?.['Copy Question Service']) && row?.id) && onCopyService(row?.id)}
                                    className={(!accessControl || actions?.['Copy Question Service']) ? 'btn-main mr-2' : 'btn-main-disable mr-2'}
                                />
                                <Button
                                    label='Edit'
                                    data-qa={'edit-question-service'}
                                    onClickButton={() => ((!accessControl || actions?.['Edit Question Service']) && row?.id) && onEditService(row?.id)}
                                    className={(!accessControl || actions?.['Edit Question Service']) ? 'btn-main mr-2' : 'btn-main-disable mr-2'}
                                />
                                <Button
                                    label='Delete'
                                    data-qa={'delete-question-service'}
                                    onClickButton={() => ((!accessControl || actions?.['Delete Question Service']) && row?.id) && onDeleteService(row?.id)}
                                    className={(!accessControl || actions?.['Delete Question Service']) ? 'btn-main mr-2' : 'btn-main-disable mr-2'}
                                />
                            </div>
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default QuestionDetailsTable;