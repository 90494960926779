import React from 'react';
import { INVENTORY_FILTERS } from '../../../../utils/constants/inventory-filter-values';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import Datepicker from '../../../../shared/datepicker';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IAdminInvoicesFilters {
    markets?: Array<IAllMarkets>;
    partners?: Array<IPartners>;
    sectors?: Array<IAssetTemplate>;
    selectedMarket?: any;
    selectedPartner?: any;
    selectedSector?: any;
    dateFilters: any;
    selectedInvoiceState?: any;
    onSelectPartner: (id: string) => void;
    onSelectMarket: (id: string) => void;
    onSelectSector: (id: string) => void;
    onSelectInvoice: (value: any) => void;
    onChangeFilterValue: (value: string | null | undefined, type: string) => void;
}

const AdminInvoicesFilters = ({
    markets,
    partners,
    sectors,
    selectedMarket,
    selectedPartner,
    selectedSector,
    dateFilters,
    selectedInvoiceState,
    onSelectPartner,
    onSelectMarket,
    onSelectSector,
    onSelectInvoice,
    onChangeFilterValue
}: IAdminInvoicesFilters) => {
    return (
        <div>
            <div className='mb-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
                <div>
                    <SelectCheckbox
                        name={'Markets'}
                        placeholder={' '}
                        dataQa={'markets-selected'}
                        selectedOption={selectedMarket}
                        options={markets || []}
                        onChangeSelectedOption={onSelectMarket}
                    />
                </div>
                <div>
                    <SelectCheckbox
                        name={'Partners'}
                        placeholder={' '}
                        dataQa={'partner-selected'}
                        selectedOption={selectedPartner}
                        options={partners || []}
                        onChangeSelectedOption={onSelectPartner}
                    />
                </div>
                <div>
                    <SelectCheckbox
                        name={'Categories'}
                        placeholder={'All Categories '}
                        dataQa={'categories-selected'}
                        selectedOption={selectedSector}
                        options={sectors || []}
                        onChangeSelectedOption={onSelectSector}
                    />
                </div>
                <div>
                    <SelectCheckbox
                        name={'Invoice Number'}
                        placeholder={' '}
                        dataQa={'invoice-number'}
                        selectedOption={selectedInvoiceState}
                        options={INVENTORY_FILTERS || []}
                        onChangeSelectedOption={onSelectInvoice}
                    />
                </div>
            </div>
            <div>
                <p className='text-primary-light font-bold text-sm mt-2 mb-4'>Date Filters</p>
                <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
                    <Datepicker
                        placeholder='Date From (min)'
                        containerStyle='mb-0'
                        dataQa={'min-date-created'}
                        inputValue={dateFilters?.minDateCreated}
                        onChangeInput={onChangeFilterValue}
                        inputUniqueName={'minDateCreated'}
                    />
                    <Datepicker
                        placeholder='Date To (max)'
                        containerStyle='mb-0'
                        dataQa={'max-date-created'}
                        inputValue={dateFilters?.maxDateCreated}
                        onChangeInput={onChangeFilterValue}
                        inputUniqueName={'maxDateCreated'}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminInvoicesFilters;