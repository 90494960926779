import React from 'react';
import { VAT_TYPE } from '../../../../utils/constants/vat-type';
import { SALES_HISTORY_FILTERS } from '../../../../utils/constants/sales-history';
import { ISalesHistory } from '../../../../interfaces/reports/ISalesHistory';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Datepicker from '../../../../shared/datepicker';
import Input from '../../../../shared/input';


interface IFilters {
    filters?: ISalesHistory;
    errors?: ISalesHistory;
    typeSelected?: any;
    selectedVAT?: any;
    itemIdValue?: any;
    onChangeFilterValue: (value: string | null | undefined, type: string) => void;
    onChangeItemValue: (value: string | null | undefined, type: string) => void;
    onSelectFilter: (value: any, type?: string) => void;
}

const SalesHistoryFilterDetails = ({
    filters,
    errors,
    typeSelected,
    selectedVAT,
    itemIdValue,
    onChangeFilterValue,
    onChangeItemValue,
    onSelectFilter
}: IFilters) => {
    return (
        <div className='grid grid-cols-4 gap-4'>
            <Input
                label='Item ID'
                containerStyle='mb-0'
                dataQa={'item-id'}
                showValue={true}
                inputValue={itemIdValue}
                onChangeInput={onChangeItemValue}
                inputUniqueName={'itemId'}
            />
            <SelectCheckbox
                name={'VAT Type'}
                placeholder={' '}
                dataQa={'vat-type'}
                selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200, }}
                options={VAT_TYPE || []}
                selectedOption={selectedVAT}
                uniqueName={'vat'}
                onChangeSelectedOption={onSelectFilter}
            />
            <Datepicker
                label='Date From'
                placeholder=' '
                dataQa={'date-from'}
                containerStyle='mb-0'
                inputValue={filters?.from}
                error={errors?.from || ''}
                onChangeInput={onChangeFilterValue}
                inputUniqueName={'from'}
            />
            <Datepicker
                label='Date To'
                placeholder=' '
                dataQa={'date-to'}
                containerStyle='mb-0'
                inputValue={filters?.to}
                error={errors?.to || ''}
                onChangeInput={onChangeFilterValue}
                inputUniqueName={'to'}
            />
            <SelectCheckbox
                name='Status'
                placeholder={' '}
                dataQa={'status'}
                options={SALES_HISTORY_FILTERS || []}
                selectedOption={typeSelected}
                selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200, }}
                onChangeSelectedOption={onSelectFilter}
                uniqueName={'type'}
            />
        </div>
    )
}

export default SalesHistoryFilterDetails;