import React, { useState, useEffect, useCallback } from 'react';
import Papa from 'papaparse';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { AdminGuard } from '../../../guards/RolesGuard';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { PRICING_TYPES } from '../../../utils/constants/pricing-types';
import {
    tryToCreateOrUpdateCategoryMapping,
    tryToFetchSingleCategoryMapping
} from '../../../store/brokers/shared-endpoints/category-mappings/categoryMappingsSlice';
import {
    tryToFetchAllPartners,
    tryToFetchPartnerCatalogs
} from '../../../store/brokers/admin/partners/partnersSlice';
import {
    tryToFetchAllAssetsTemplates,
    tryToFetchAssetFieldsTemplate
} from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import { tryFetchAssetFieldValues } from '../../../store/brokers/admin/assets/assetsSlice';
import MappingGeneralStep from './steps/MappingGeneralStep';
import MappingValuesStep from './steps/MappingValuesStep';
import Error from '../../../shared/error';


const CreateCategoryMapping = () => {
    const { id, copyId } = useParams()
    const dispatch = useAppDispatch();
    const isAdmin = AdminGuard()
    const [partners, setPartners] = useState<any>()
    const [selectedPartner, setSelectedPartner] = useState<any>()
    const [mappingDetails, setMappingDetails] = useState<any>()
    const [mappingName, setMappingName] = useState<string | null>()
    const [categories, setCategories] = useState<any>()
    const [selectedCategory, setSelectedCategory] = useState<any>()
    const [selectedType, setSelectedType] = useState<any>()
    const [fileSelected, setFileSelected] = useState<any>()
    const [newColumns, setNewColumns] = useState<any>()
    const [categoryFields, setCategoryFields] = useState<any>()
    const [mappingFields, setMappingFields] = useState<any>()
    const [generalError, setGeneralError] = useState<string>()
    const [mappingFieldsErrors, setMappingFieldsErrors] = useState<any>()
    const [multiplePricingColumns, setMultiplePricingColumns] = useState<boolean>(false)
    const [priceMappingColumns, setPriceMappingColumns] = useState<any>()
    const [categoryValues, setCategoryValues] = useState<any>()
    const [searchField, setSearchField] = useState<any>()
    const [filteredFields, setFilteredFields] = useState<any>()
    const [loadingOptions, setLoadingOptions] = useState<any>()
    const [activeStep, setActiveStep] = useState<number>(1)
    const [columnsFormated, setColumnsFormated] = useState<any>()
    const [loadingDraft, setLoadingDraft] = useState<boolean>(false)
    const stateAssetFields = useAppSelector((state) => state.assetsTemplates);
    const state = useAppSelector((state) => state.categoryMapping);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket)
    const [userProfileMutation] = useUserProfileMutation()

    useEffect(() => {
        if (selectedCategory && selectedCategory?.id) {
            dispatch(tryToFetchAssetFieldsTemplate(selectedCategory?.id));
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (stateAssetFields.assetTemplateFields) {
            const rows =
                stateAssetFields.assetTemplateFields &&
                    Array.isArray(stateAssetFields.assetTemplateFields)
                    ? stateAssetFields.assetTemplateFields
                        ?.filter((r) => r?.enabled)
                        .map((r) => ({ ...r, label: selectedType?.value === 'WHOLESALE_PRICING' ? r?.replaceField?.name || r?.name : r?.name, value: r?.id }))
                    : [];
            const formatCategoryFields = [
                ...rows || [],
                { name: 'price', label: 'price', value: 'price' }
            ]
            if (selectedType?.value === 'WHOLESALE_PRICING') {
                formatCategoryFields.push({ name: 'number_of_items', label: 'number_of_items', value: 'number_of_items' })
            }
            setCategoryFields(formatCategoryFields);
        }
    }, [stateAssetFields.assetTemplateFields]);

    useEffect(() => {
        if (selectedType && selectedCategory) {
            let formatCategoryFields = categoryFields && categoryFields?.length > 0 && categoryFields?.map((item: any) => ({ ...item, label: selectedType?.value === 'WHOLESALE_PRICING' ? item?.replaceField?.name || item?.name : item?.name, }))
            const findCategoryItems = formatCategoryFields && formatCategoryFields?.length > 0 && formatCategoryFields?.find((item: any) => item?.value === 'number_of_items')
            const findCategoryColor = formatCategoryFields && formatCategoryFields?.length > 0 && formatCategoryFields?.find((item: any) => item?.value === 'colour')
            if (selectedType?.value === 'WHOLESALE_PRICING') {
                if (!findCategoryItems) {
                    formatCategoryFields.push({ name: 'number_of_items', label: 'number_of_items', value: 'number_of_items' })
                }
                if (!findCategoryColor) {
                    formatCategoryFields.push({ name: 'colour', label: 'colour', value: 'colour' })
                }
            } else if (findCategoryItems) {
                formatCategoryFields = formatCategoryFields && formatCategoryFields?.length > 0 && formatCategoryFields?.filter((item: any) => item?.value !== 'number_of_items' && item?.value !== 'colour')
            }
            setCategoryFields(formatCategoryFields)
        }
    }, [selectedType, selectedCategory])

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const getBrokerMarketsAndSectors = async () => {
        const response = await userProfileMutation(null).unwrap()
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : []
        setCategories(formatSectors || [])
    }

    useEffect(() => {
        if (stateAssetFields.allAssetTemplates && isAdmin) {
            const rows =
                stateAssetFields.allAssetTemplates && Array.isArray(stateAssetFields.allAssetTemplates)
                    ? stateAssetFields.allAssetTemplates.map((r) => ({ label: r?.displayName, value: r?._id, ...r || {} }))
                    : [];
            setCategories(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssetFields.allAssetTemplates]);

    const onGetPartnerSectors = async () => {
        const response: any = await dispatch(tryToFetchPartnerCatalogs(selectedPartner?.username || '')).unwrap()
        if (response?.data) {
            const formatSectors = response?.data?.length > 0 && response?.data?.map((category: any) => ({ ...category, label: category?.displayName, name: category?.displayName, value: category?._id, id: category?._id }))
            setCategories(formatSectors || [])
        }
    }

    const getPartnersData = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.companyName }))
        setPartners(formatPartners || [])
    }

    useEffect(() => {
        if (selectedPartner) {
            onGetPartnerSectors()
        }
    }, [selectedPartner])

    useEffect(() => {
        if (isAdmin) {
            dispatch(tryToFetchAllAssetsTemplates());
            getPartnersData()
        } else {
            getBrokerMarketsAndSectors()
        }
    }, [])

    const onGettingMappingDetails = (mappingDetailsData: any) => {
        if (mappingDetailsData?.columns && categoryFields) {
            const formatMappedFields: any = []
            const formatPriceMappedFields: any = []
            let pricingShow = false
            mappingDetailsData?.columns?.length > 0 && mappingDetailsData?.columns?.map((item: any) => {
                if (!item?.mappedAsConditions || item?.mappedAsConditions?.price === null) {
                    const findCategoryData = categoryFields && categoryFields?.length > 0 && categoryFields?.filter((obj: any) => (item?.mappedAs?.includes(obj?.name) || (mappingDetailsData?.pricingType === 'WHOLESALE_PRICING' && item?.mappedAs?.includes(obj?.replaceField?.name))))
                    formatMappedFields.push({
                        selected: (item?.mappedAsConditions && item?.mappedAsConditions?.price === null)
                            ? [{ label: 'price', value: 'price', name: 'price' }] : item?.mappedAs?.[0] === 'number_of_items'
                                ? [{ name: 'number_of_items', label: 'number_of_items', value: 'number_of_items' }]
                                : item?.mappedAs?.[0] === 'colour' && item?.mappedAs?.length <= 1
                                    ? [{ name: 'colour', label: 'colour', value: 'colour' }]
                                    : item?.isMapped ? findCategoryData : null,
                        newName: item?.name
                    })
                } else {
                    const categoriesFound = item?.mappedAsConditions && Object.keys(item?.mappedAsConditions)
                    const filterCategories = categoryFields?.length > 0 && categoryFields?.filter((obj: any) => categoriesFound?.includes(obj?.name))
                    formatPriceMappedFields.push(
                        {
                            newField: { value: item?.name, label: item?.name, name: item?.name },
                            categories: filterCategories,
                            selectedValues: item?.mappedAsConditions,
                            showExtras: !!item?.extras,
                            extras: item?.extras ? { value: item?.extras, label: item?.extras, name: item?.extras } : undefined
                        }
                    )
                    pricingShow = true
                }
                return item;
            })
            if (formatPriceMappedFields && formatPriceMappedFields?.length > 0) {
                setPriceMappingColumns(formatPriceMappedFields)
            } else {
                setPriceMappingColumns(undefined)
                setMultiplePricingColumns(false)
            }
            if (pricingShow) {
                setMultiplePricingColumns(true)
            }
            setMappingFields(formatMappedFields || undefined)
        }
        if (mappingDetailsData?.pricingType) {
            const formatPricingType = PRICING_TYPES?.find((item: any) => item?.value === mappingDetailsData?.pricingType)
            setSelectedType(formatPricingType)
        }
    }

    useEffect(() => {
        if (id && state?.categoryMappingsDetails && id === state?.categoryMappingsDetails?._id) {
            setMappingDetails(state?.categoryMappingsDetails)
            setMappingName(state?.categoryMappingsDetails?.name)
        }
        else if (copyId && state?.categoryMappingsDetails) {
            setMappingName(state?.categoryMappingsDetails?.name + '-copy')
            setMappingDetails({
                ...mappingDetails || {},
                columns: state?.categoryMappingsDetails?.columns || []
            })
        }
        if (state?.categoryMappingsDetails) {

            if (state?.categoryMappingsDetails?.columns) {
                setNewColumns(state?.categoryMappingsDetails?.columns)
            }
        }
    }, [state?.categoryMappingsDetails])

    useEffect(() => {
        if (mappingDetails && mappingDetails?.catalog && !selectedCategory && categories) {
            const findCategorySelected = categories?.length > 0 && categories?.find((item: any) => item?.id === mappingDetails?.catalog?.id)
            if (findCategorySelected) {
                setSelectedCategory(findCategorySelected)
            }
        }
    }, [mappingDetails, categories])

    useEffect(() => {
        if (mappingDetails && mappingDetails?.catalog && !selectedPartner && partners && isAdmin) {
            const findPartnerSelected = partners?.length > 0 && partners?.find((item: any) => item?.value === mappingDetails?.brokerId)
            if (findPartnerSelected) {
                setSelectedPartner(findPartnerSelected)
            }
        }
    }, [mappingDetails, partners])

    useEffect(() => {
        if (copyId && copyId === state?.categoryMappingsDetails?._id && state?.categoryMappingsDetails && state?.categoryMappingsDetails?.catalog && !selectedCategory && categories && copyId) {
            const findCategorySelected = categories?.length > 0 && categories?.find((item: any) => item?.id === state?.categoryMappingsDetails?.catalog?.id)
            if (findCategorySelected) {
                setSelectedCategory(findCategorySelected)
            }
        }
    }, [state?.categoryMappingsDetails, categories])

    useEffect(() => {
        if (copyId && copyId === state?.categoryMappingsDetails?._id && state?.categoryMappingsDetails && state?.categoryMappingsDetails?.brokerId && !selectedPartner && partners && isAdmin) {
            const findPartnerSelected = partners?.length > 0 && partners?.find((item: any) => item?.value === state?.categoryMappingsDetails?.brokerId)
            if (findPartnerSelected) {
                setSelectedPartner(findPartnerSelected)
            }
        }
    }, [state?.categoryMappingsDetails, partners])

    useEffect(() => {
        if (copyId && state?.categoryMappingsDetails && copyId === state?.categoryMappingsDetails?._id && categoryFields && !selectedType) {
            onGettingMappingDetails(state?.categoryMappingsDetails)
        }
    }, [state?.categoryMappingsDetails, categoryFields, copyId])

    useEffect(() => {
        if (mappingDetails && categoryFields && !selectedType) {
            onGettingMappingDetails(mappingDetails)
        }
    }, [mappingDetails, categoryFields])

    useEffect(() => {
        if (id || copyId) {
            dispatch(tryToFetchSingleCategoryMapping(id || copyId || ''))
        } else {
            setMappingName(undefined)
            setSelectedCategory(undefined)
            setSelectedPartner(undefined)
            setFileSelected(undefined)
            setSelectedType(undefined)
            setMappingFields(undefined)
            setMappingDetails(undefined)
            setNewColumns(undefined)
        }
    }, [id, copyId])

    const onClickPriceMultipleColumns = () => {
        setMultiplePricingColumns(!multiplePricingColumns)
        if (multiplePricingColumns) {
            setPriceMappingColumns(undefined)
        } else {
            setPriceMappingColumns([])
        }
    }

    const onChangeMappingName = (value: string | null | undefined, type?: string) => {
        setMappingName(value || undefined)
    }

    const onSelectMappingCategory = (value: any, type?: string) => {
        setSelectedCategory(value || undefined)
    }

    const onSelectMappingType = (value: any, type?: string) => {
        setSelectedType(value || undefined)
        setPriceMappingColumns(undefined)
        // setMappingFields(undefined)
        setMultiplePricingColumns(false)
    }

    const onUploadFile = async () => {
        Papa.parse(fileSelected, {
            header: true,
            skipEmptyLines: true,
            complete: async function (results: any) {
                const newColumnNames = results?.data && results?.data?.length > 0 && Object.keys(results?.data?.[0])
                const formatNewColumnNames = newColumnNames && newColumnNames?.length > 0 && newColumnNames?.filter((item: any) => item !== '' && item !== ' ')
                if (formatNewColumnNames && formatNewColumnNames?.length > 0) {
                    const formatFileColumns: any = []
                    formatNewColumnNames?.map((column: string) => {
                        const uniqueValues = column && results?.data && results?.data?.map((item: any) => item?.[column]).filter((value: any, index: any, self: any) => self.indexOf(value) === index && value !== '')
                        formatFileColumns.push({
                            name: column,
                            inputValues: uniqueValues || [],
                        })
                        return column
                    })
                    setNewColumns(formatFileColumns)
                }
            }
        })
    }

    useEffect(() => {
        if (fileSelected) {
            onUploadFile()
        }
    }, [fileSelected])

    const onChangeFieldSelected = (value: any, type?: string) => {
        if (type) {
            const filterOutSelectedField = (mappingFields && mappingFields?.length > 0) && mappingFields?.filter((item: any) => item?.newName !== type)
            setMappingFields([
                ...filterOutSelectedField || [],
                {
                    selected: value,
                    newName: type
                }
            ])
            setMappingFieldsErrors(undefined)
        }
    }

    const onGettingFieldValues = async (type: string, fieldsSelected?: any, index?: any) => {
        if (selectedCategory && fieldsSelected && fieldsSelected?.length > 0 && type !== 'search') {
            let rowCategoryPushValues: any;
            await Promise.all(fieldsSelected && fieldsSelected?.length > 0 && fieldsSelected?.map(async (item: any) => {
                let data: any = {
                    id: selectedCategory?.id,
                    field: item?.name || ''
                }
                if (searchField && searchField?.value && searchField?.index === index && searchField?.name === item?.name) {
                    data = {
                        ...data || {},
                        search: searchField?.value
                    }
                }
                if (selectedType && selectedType?.value === 'WHOLESALE_PRICING' && item?.replaceField?.name) {
                    data = {
                        ...data || {},
                        replacements: true
                    }
                }
                const response = await dispatch(tryFetchAssetFieldValues(data)).unwrap()
                rowCategoryPushValues = { ...rowCategoryPushValues, [item?.name]: response }
            }))
            if (rowCategoryPushValues) {
                const filterCategoryValues = categoryValues && categoryValues?.length > 0 && categoryValues?.filter((item: any, idx: number) => idx !== index)
                setCategoryValues([
                    ...filterCategoryValues || [],
                    {
                        ...rowCategoryPushValues || {},
                    }
                ])
            }
        }
        else if (type === 'search' && searchField) {
            let data: any = {
                id: selectedCategory?.id,
                field: searchField?.name || '',
                search: searchField?.value
            }
            if (selectedType && selectedType?.value === 'WHOLESALE_PRICING') {
                data = {
                    ...data || {},
                    replacements: true
                }
            }
            const response = await dispatch(tryFetchAssetFieldValues(data)).unwrap()
            const findIndexValues = categoryValues?.length > 0 && categoryValues?.find((item: any, idx: number) => idx === searchField?.index)
            const filterIndexValues = categoryValues?.length > 0 && categoryValues?.filter((item: any, idx: number) => idx !== searchField?.index)
            setCategoryValues([
                ...filterIndexValues || [],
                {
                    ...findIndexValues || {},
                    [searchField.name]: response,
                }
            ])
        }
        setLoadingOptions(undefined)
    }

    useEffect(() => {
        if (searchField) {
            onGettingFieldValues('search')
        }
    }, [searchField])

    const onChangeSearchFields = (e: any, type?: string, index?: any) => {
        if (type) {
            const findRowCategoryValues = categoryValues && categoryValues?.find((category: any, idx: number) => idx === index)
            if (findRowCategoryValues && findRowCategoryValues?.[type]) {
                const formatFilteredFields = findRowCategoryValues?.[type] && findRowCategoryValues?.[type]?.length > 0 && findRowCategoryValues?.[type]?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
                if (formatFilteredFields && formatFilteredFields?.length > 0) {
                    setFilteredFields({ [type]: formatFilteredFields, index })
                }
                else {
                    setFilteredFields(undefined)
                    setLoadingOptions({ [type]: true, index })
                    setSearchField({
                        value: e,
                        index,
                        name: type
                    })
                }
            } else {
                setFilteredFields(undefined)
                setLoadingOptions({ [type]: true, index })
                setSearchField({
                    value: e,
                    index,
                    name: type
                })
            }
        } else {
            setFilteredFields(undefined)
        }
    }

    const onChangeSelectedPriceFileColumn = (value: any, type?: string, index?: any) => {
        const findIfAlreadyExist = priceMappingColumns && priceMappingColumns?.length > 0 && priceMappingColumns?.find((item: any, idx: number) => idx === index)
        if (findIfAlreadyExist) {
            const formatPricingColsData = priceMappingColumns && priceMappingColumns?.length > 0 && priceMappingColumns?.map((item: any, idx: number) => {
                if (idx === index) {
                    let selectedValuesFormat: any;
                    type === 'category' && value && value?.length > 0 && value?.map((obj: any) => {
                        if (findIfAlreadyExist?.selectedValues?.[obj?.name]) {
                            selectedValuesFormat = {
                                ...selectedValuesFormat || {},
                                [obj?.name]: findIfAlreadyExist?.selectedValues?.[obj?.name]
                            }
                        }
                        return obj
                    })
                    return {
                        ...item || {},
                        newField: type === 'file' ? value : findIfAlreadyExist?.newField,
                        categories: type === 'category' ? value : findIfAlreadyExist?.categories,
                        seletedValues: type === 'category' ? selectedValuesFormat : findIfAlreadyExist?.seletedValues,
                        extras: type === 'extras' ? value : findIfAlreadyExist?.extras,
                        showExtras: findIfAlreadyExist?.showExtras
                    }
                } else {
                    return item
                }
            })
            setPriceMappingColumns(formatPricingColsData)
        } else {
            setPriceMappingColumns([
                ...priceMappingColumns || [],
                {
                    newField: type === 'file' ? value : findIfAlreadyExist?.newField,
                    categories: type === 'category' ? value : findIfAlreadyExist?.categories,
                    extras: type === 'extras' ? value : findIfAlreadyExist?.extras,
                    showExtras: findIfAlreadyExist?.showExtras
                }
            ])
        }
        if (type === 'category' && value) {
            setSearchField(undefined)
            setFilteredFields(undefined)
            onGettingFieldValues('no-search', value, index)
        }
    }

    const onChangeSelectedFieldValue = (value: any, type?: string, index?: any) => {
        if (type) {
            const formatColumnValues = priceMappingColumns && priceMappingColumns?.length > 0 && priceMappingColumns?.map((item: any, idx: number) => {
                if (idx === index) {
                    return {
                        ...item || {},
                        selectedValues: {
                            ...item?.selectedValues,
                            [type]: value?.label
                        }
                    }
                } else {
                    return item
                }
            })
            setPriceMappingColumns(formatColumnValues)
        }
    }

    const onClearMappedColumns = () => {
        setMappingFields(undefined)
    }

    const onAddingNewPricingColumn = () => {
        setPriceMappingColumns([
            ...priceMappingColumns || [],
            {}
        ])
    }

    const onDeletePricingIndexItem = (index: any) => {
        const filterPriceMappingColumns = priceMappingColumns && priceMappingColumns?.length > 0 && priceMappingColumns?.filter((item: any, idx: number) => idx !== index)
        const formatCategoriesFieldValues = categoryValues && categoryValues?.length > 0 && categoryValues?.filter((item: any, idx: number) => idx !== index)
        setPriceMappingColumns(filterPriceMappingColumns || undefined)
        setCategoryValues(formatCategoriesFieldValues || undefined)
        setSearchField(undefined)
    }

    const onMenuCloseAfterSearch = (type?: string) => {
        if (type && searchField?.[type]) {
            setSearchField(undefined)
        }
    }

    const onContinueToNextStep = async () => {
        setLoadingDraft(true)
        try {
            let formatColumns: any;
            let mappedExist = false;
            if (activeStep === 1) {
                if (!mappingName || mappingName === '') {
                    setGeneralError('Please write a unique mapping name!')
                    setLoadingDraft(false)
                    return;
                }
                if (!selectedCategory) {
                    setGeneralError('Please select category!')
                    setLoadingDraft(false)
                    return;
                }
                if (!selectedType) {
                    setGeneralError('Please select type!')
                    setLoadingDraft(false)
                    return;
                }
                if (!selectedPartner && isAdmin) {
                    setGeneralError('Please select broker!')
                    setLoadingDraft(false)
                    return;
                }
                if (!id && !fileSelected && !copyId) {
                    setGeneralError('Please upload your template csv!')
                    setLoadingDraft(false)
                    return;
                }

                formatColumns = (newColumns && newColumns?.length > 0) && newColumns?.map((item: any) => {
                    const findIfIsInPricing = priceMappingColumns && priceMappingColumns?.length > 0 && priceMappingColumns?.find((col: any) => col?.newField?.name === item?.name)
                    const findOnMapping = mappingFields && mappingFields?.length > 0 && mappingFields?.find((col: any) => col?.newName === item?.name)
                    const findColumnIfExist = mappingDetails && mappingDetails?.columns && mappingDetails?.columns?.find((col: any) => col?.name === item?.name)
                    const findIfExtras = priceMappingColumns && priceMappingColumns?.length > 0 && priceMappingColumns?.find((col: any) => col?.extras)
                    const findNameMapping: any = []
                    findOnMapping?.selected && findOnMapping?.selected?.length > 0 && findOnMapping?.selected?.map((next: any) => {
                        findNameMapping.push(selectedType?.value === 'WHOLESALE_PRICING' ? next?.replaceField?.name || next?.name : next?.name)
                        return next;
                    })
                    let formResponse: any = {
                        name: item?.name,
                        inputValues: findIfIsInPricing ? [] : item?.inputValues,
                        mappedAs: findIfIsInPricing ? ['quantity'] : !findOnMapping?.selected ? undefined : findNameMapping,
                        isMapped: (findOnMapping?.selected && findOnMapping?.selected?.length > 0),
                        mappedValues: findColumnIfExist ? findColumnIfExist?.mappedValues : undefined
                    }
                    if (findOnMapping?.selected?.length > 0 || findIfIsInPricing) {
                        mappedExist = true
                    }
                    if (findOnMapping?.selected?.[0]?.label === 'price') {
                        formResponse = {
                            ...formResponse || {},
                            inputValues: [],
                            mappedAs: (priceMappingColumns && priceMappingColumns?.length > 0) ? undefined : ['quantity'],
                            isMapped: !(priceMappingColumns && priceMappingColumns?.length > 0),
                            mappedValues: undefined,
                            mappedAsConditions: {
                                price: null
                            }
                        }
                    }
                    if (selectedType?.value === 'WHOLESALE_PRICING' && findOnMapping?.selected?.[0]?.value === 'number_of_items') {
                        formResponse = {
                            ...formResponse || {},
                            inputValues: [],
                            mappedAs: findIfExtras ? undefined : ['number_of_items'],
                            isMapped: !findIfExtras,
                            mappedValues: undefined,
                        }
                    }
                    if (findIfIsInPricing) {
                        formResponse = {
                            ...formResponse || {},
                            mappedAs: findIfIsInPricing?.extras?.value ? ['quantity', 'number_of_items'] : ['quantity'],
                            isMapped: true,
                            extras: findIfIsInPricing?.extras?.value,
                            mappedAsConditions: {
                                ...findIfIsInPricing?.selectedValues || {},
                            }
                        }
                    }
                    return formResponse
                })
                setColumnsFormated(formatColumns)
            }
            else {
                if (columnsFormated && columnsFormated?.length > 0) {
                    mappedExist = true;
                }
                formatColumns = columnsFormated && columnsFormated?.length > 0 && columnsFormated?.map((col: any) => {
                    return {
                        ...col || {},
                        mappedValues: {}
                    }
                })
            }
            if (!mappedExist) {
                setGeneralError('Please map at least one field to continue.')
                setLoadingDraft(false)
                return;
            }
            setGeneralError(undefined)
            let formatData: any = {
                marketId: activeMarketState?.market?._id || '',
                catalog: {
                    id: selectedCategory?.id,
                    name: selectedCategory?.name
                },
                pricingType: selectedType?.value,
                columns: formatColumns,
                name: mappingName,
                status: 'DRAFT'
            }
            if (isAdmin && selectedPartner) {
                formatData = {
                    ...formatData || {},
                    brokerId: selectedPartner?.value,
                }
            }
            if (mappingDetails?._id) {
                formatData = {
                    ...formatData || {},
                    _id: mappingDetails?._id
                }
            }
            if (id) {
                formatData = {
                    ...formatData || {},
                    _id: id
                }
            } else {
                setMappingDetails(formatData)
            }
            const findTotalSteps = (newColumns?.length || 0) - (priceMappingColumns?.length || 0) + 1
            if (activeStep + 1 >= findTotalSteps) {
                formatData = {
                    ...formatData || {},
                    status: 'COMPLETE'
                }
            }
            const response = await dispatch(tryToCreateOrUpdateCategoryMapping(formatData)).unwrap();
            setMappingDetails(response?.data || formatData)
            if (!(activeStep + 1 >= findTotalSteps)) {
                setActiveStep(activeStep + 1)
            }
            setLoadingDraft(false)
        } catch (err) {
            setGeneralError(`${err || 'Something went wrong saving mapping!'}`)
            setLoadingDraft(false)
        }
    }

    const onChangeSelectedPartner = (value: any, type?: string) => {
        setSelectedPartner(value)
    }

    const onClickMultiQuantity = (index: any) => {
        const formatPricingWithNumber = (priceMappingColumns && priceMappingColumns?.length > 0) ? priceMappingColumns?.map((item: any, idx: number) => {
            if (idx === index) {
                return {
                    ...item,
                    showExtras: !item?.showExtras
                }
            } else {
                return item
            }
        })
            : [{ showExtras: true }]
        setPriceMappingColumns(formatPricingWithNumber || [])
    }

    return (
        <div>
            <div className='flex flex-row justify-between items-center min-w-full mb-10'>
                <p className='page-title'>{id ? 'Complete Mapping' : 'Create Mapping'}</p>
            </div>
            {generalError && <Error text={generalError} />}
            <div className='w-auto'>
                {activeStep === 1 &&
                    <MappingGeneralStep
                        id={id}
                        copyId={copyId}
                        name={mappingName}
                        categories={categories}
                        selectedCategory={selectedCategory}
                        selectedType={selectedType}
                        newColumns={newColumns}
                        selectedPartner={selectedPartner}
                        partners={partners}
                        categoryFields={categoryFields}
                        multiplePricingColumns={multiplePricingColumns}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        fileSelected={fileSelected}
                        isDragActive={isDragActive}
                        mappingFields={mappingFields}
                        categoryValues={categoryValues}
                        mappingFieldsErrors={mappingFieldsErrors}
                        priceMappingColumns={priceMappingColumns}
                        loadingOptions={loadingOptions}
                        filteredFields={filteredFields}
                        loading={loadingDraft}
                        onClickMultiQuantity={onClickMultiQuantity}
                        onChangeMappingName={onChangeMappingName}
                        onChangeSelectedPartner={onChangeSelectedPartner}
                        onChangeFieldSelected={onChangeFieldSelected}
                        onClickPriceMultipleColumns={onClickPriceMultipleColumns}
                        onSelectMappingCategory={onSelectMappingCategory}
                        onSelectMappingType={onSelectMappingType}
                        onChangeSelectedPriceFileColumn={onChangeSelectedPriceFileColumn}
                        onChangeSelectedFieldValue={onChangeSelectedFieldValue}
                        onClearMappedColumns={onClearMappedColumns}
                        onAddingNewPricingColumn={onAddingNewPricingColumn}
                        onDeletePricingIndexItem={onDeletePricingIndexItem}
                        onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                        onChangeSearchFieldValues={onChangeSearchFields}
                        onContinue={onContinueToNextStep}
                    />
                }
                {activeStep !== 1 &&
                    <MappingValuesStep
                        activeStep={activeStep}
                        setGeneralError={setGeneralError}
                        setActiveStep={setActiveStep}
                        draftMapping={mappingDetails}
                        setMappingDraftDetails={setMappingDetails}
                        categoryFields={categoryFields}
                    />
                }
            </div>
        </div>
    )
}

export default CreateCategoryMapping;