import React, { useEffect, useLayoutEffect, useState } from 'react'
import SubmittedRMAsTab from './tabs/SubmittedRMAsTab'
import ItemCreditsTab from './tabs/ItemCreditsTab'
import CreditNotesTab from './tabs/CreditNotesTab'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { useLocation, useSearchParams } from 'react-router-dom'
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload'
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice'
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate'
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets'
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi'
import Button from '../../../shared/button'
const RMAsTabs = [
    { name: 'Submitted RMAs', element: SubmittedRMAsTab },
    { name: 'Item Credits', element: ItemCreditsTab },
    { name: 'Credit Notes', element: CreditNotesTab },
]

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const RMAManagement = () => {
    const [activeTab, setActiveTab] = useState<any>(RMAsTabs[0])
    const [sectors, setSectors] = useState<IAssetTemplate[]>([]);
    const [pageAccess, setPageAccess] = useState<any>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: undefined
    });
    const [markets, setMarkets] = useState<IAllMarkets[]>();
    const [getAllMarkets] = useGetAllMarketsMutation();
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const [searchParams, setSearchParams] = useSearchParams();
    const query = useQuery();
    const state = useAppSelector((state) => state.assetsTemplates);
    const dispatch = useAppDispatch()

    const getMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap();
        const formatMarkets =
            marketResponse &&
            marketResponse?.length > 0 &&
            marketResponse?.map((market: IAllMarkets) => ({
                ...market,
                value: market?._id,
                displayName: market?.name,
            }));
        setMarkets(formatMarkets || []);
    };

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined,
        });
        searchParams.set('page', '1');
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    };

    const onChangeActiveTab = (item: any) => {
        if (activeTab.name !== item.name) {
            setActiveTab(item)
            searchParams.delete('partner')
            searchParams.delete('status')
            searchParams.delete('category')
            searchParams.delete('partnerId')
            searchParams.delete('paid')
            searchParams.delete('search')
            searchParams.set('type', item?.name)
            setSearchParams(searchParams)
            onResetPaginationSettings()
            setPaginationState(current => ({ ...current, filters: undefined }))
        }
    }

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Return Merchandise Authorization')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        searchParams.set('page', `${paginationState?.pageNumber}`);
        searchParams.set('size', `${paginationState?.pageSize}`);
        setSearchParams(searchParams);
    }, [paginationState]);

    useEffect(() => {
        getMarketData()
    }, [])

    useLayoutEffect(() => {
        const paginationStateFormat = paginationState
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1)
        }
        if (query?.get('size') && Number(query?.get('size')) !== paginationState?.pageSize) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10)
        }
        if (query?.get('type') && query?.get('type') !== activeTab?.name) {
            const findActiveTab = RMAsTabs.find((item) => item?.name === query?.get('type'))
            setActiveTab(findActiveTab || undefined)
            searchParams.set('type', findActiveTab?.name || RMAsTabs[0].name)
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat)
        }

        searchParams.set('page', `${paginationState?.pageNumber || 1}`);
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    }, [query?.get('type'), query?.get('page'), query?.get('size')])

    useEffect(() => {
        if (state.allAssetTemplates) {
            const rows =
                state.allAssetTemplates && Array.isArray(state.allAssetTemplates)
                    ? state.allAssetTemplates?.map((r) => ({ ...r || {}, name: r?.name, label: r?.displayName, value: r?._id, }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
            searchParams.set('page', `${paginationState?.pageNumber}`)
            searchParams.set('size', `${paginationState?.pageSize}`)
        } else {
            dispatch(tryToFetchAllAssetsTemplates())
        }
    }, [state.allAssetTemplates])
    return (
        <div>
            <div>
                <p className='page-title'>RMA Management</p>
            </div>
            <div className='flex flex-row flex-wrap gap-3 items-center my-5'>
                {RMAsTabs.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name]))?.map((tab: { name: string, element: any }, index) => {
                    return (
                        <Button
                            key={index}
                            dataQa={`${index}`}                            
                            className={`box-border ${activeTab.name === tab?.name ? 'btn-tab-rounded-less' : 'btn-tab-disable '}`}
                            onClickButton={() => onChangeActiveTab(tab)}
                            label={tab.name}/>
                    )
                })}
            </div>
            <div className='my-2'>
                {(!accessControl || pageAccess?.actions?.[activeTab?.name]) ? React.createElement(activeTab.element, { paginationState, setPaginationState, pageAccess, accessControl, sectors, onResetPaginationSettings, query, searchParams, setSearchParams, markets }) : <></>}
            </div>
        </div>
    )
}

export default RMAManagement