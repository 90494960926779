import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToDeleteExtendedAsset,
    tryToDownloadExtendedAssetData,
    tryToDownloadExtendedAssetTemplate,
    tryToFetchExtendedAssets
} from '../../../store/brokers/admin/assets/assetsSlice';
import AssetsExtendedTable from './tables/AssetsExtendedTable';
import AssetExtendedModal from './modals/AssetExtendedModal';
import ImportAssetsExtendedModal from './modals/ImportExtendedAssetsModal';
import Input from '../../../shared/input';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';


const ExtendedAssets = () => {
    const dispatch = useAppDispatch();
    const csvLink3 = React.useRef() as React.MutableRefObject<any>;
    const csvLink2 = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<Array<any>>([]);
    const [search, setSearch] = useState<string>();
    const [showToast, setShowToast] = useState<IToast | null>()
    const [showAssetItemModal, setShowAssetItemModal] = useState<boolean>(false)
    const [assetItemId, setAssetItemId] = useState<string | null>()
    const [showDeleteAssetModal, setShowDeleteAssetModal] = useState<boolean>(false)
    const [showImportAssetsExtendedModal, setShowImportAssetsExtendedModal] = useState<boolean>(false)
    const [loadingDownloadExtendedAssets, setLoadingDownloadExtendedAssets] = useState<boolean>(false)
    const [loadingDownloadTemplate, setLoadingDownloadTemplate] = useState<boolean>(false)
    const [assetsExtendedToDownload, setAssetsExtendedToDownload] = useState<Array<any>>()
    const [assetsExtendedTemplate, setAssetsExtendedTemplate] = useState<Array<any>>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.assets?.extendedAssets?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const rowsLoading = useAppSelector((state) => state.assets?.assetsAreLoading);
    const state = useAppSelector((state) => state.assets);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Assets Extended')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.extendedAssets) {
            const rows =
                state.extendedAssets?.data?.elements && Array.isArray(state.extendedAssets?.data.elements)
                    ? state.extendedAssets?.data.elements.map((r: any) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.extendedAssets]);

    useEffect(() => {
        const settingsFilters: any = {
            ...paginationState
        }
        if (search) {
            settingsFilters.filters = search
        }
        dispatch(tryToFetchExtendedAssets(settingsFilters));
    }, [paginationState]);

    const onAddNewAssetTemplate = (id?: string) => {
        if (id) {
            setAssetItemId(id)
        }
        setShowAssetItemModal(true)
    }

    const onCloseAssetModal = () => {
        setAssetItemId(null)
        setShowAssetItemModal(false)
    }

    const onDisableAsset = async () => {
        try {
            if (assetItemId) {
                await dispatch(tryToDeleteExtendedAsset(assetItemId)).unwrap();
                setShowToast({ type: 'success', message: 'Item successfully deleted' });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setAssetItemId(null)
        setShowDeleteAssetModal(false)
    }

    const onSaveAssetChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        setAssetItemId(null)
    }

    const onDeleteAssetField = (id: string) => {
        setShowDeleteAssetModal(true)
        setAssetItemId(id)
    }

    const onCloseDeleteAssetModal = () => {
        setAssetItemId(null)
        setShowDeleteAssetModal(false)
    }

    const onToggleImportAssetsExtendedModal = () => {
        setShowImportAssetsExtendedModal(!showImportAssetsExtendedModal)
    }

    const onDownloadTemplate = async () => {
        if (loadingDownloadTemplate) {
            return;
        }
        setLoadingDownloadTemplate(true)
        try {
            const response = await dispatch(tryToDownloadExtendedAssetTemplate()).unwrap()
            setAssetsExtendedTemplate(response?.data ? [response.data] : [])
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
            setLoadingDownloadTemplate(false)
        }
    }

    const onDownloadExtendedAssets = async () => {
        if (loadingDownloadExtendedAssets) {
            return;
        }
        setLoadingDownloadExtendedAssets(true)
        try {
            const response = await dispatch(tryToDownloadExtendedAssetData()).unwrap()
            setAssetsExtendedToDownload(response || [])
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
            setLoadingDownloadExtendedAssets(false)
        }
    }

    useEffect(() => {
        if (assetsExtendedTemplate && loadingDownloadTemplate) {
            csvLink3.current.link.click()
        }
        setLoadingDownloadTemplate(false)
    }, [assetsExtendedTemplate])

    useEffect(() => {
        if (assetsExtendedToDownload && loadingDownloadExtendedAssets) {
            csvLink2.current.link.click()
        }
        setLoadingDownloadExtendedAssets(false)
    }, [assetsExtendedToDownload])

    const onImportAssetsSuccessfully = () => {
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
        })
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    return (
        <div>
            <div>
                <p className='page-title'>Extended Assets</p>
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={loadingDownloadExtendedAssets ? 'Loading data...' : 'Download Extended Assets'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Download Extended Assets']) && onDownloadExtendedAssets()}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Extended Assets']) ? 'btn-main' : 'btn-main-disable'} mr-2 !py-2 !shadow-none`}
                    dataQa={'extended-assets-download-btn'}
                />
                <CSVLink
                    ref={csvLink2}
                    data={(assetsExtendedToDownload && assetsExtendedToDownload?.length > 0) ? assetsExtendedToDownload?.filter((item, idx) => idx > 0) || [] : []}
                    headers={assetsExtendedToDownload?.[0] || []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={'Assets-extended-data.csv'}
                ></CSVLink>
                <Button
                    label={loadingDownloadTemplate ? 'Loading data...' : 'Download Template'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Download Template']) && onDownloadTemplate()}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Template']) ? 'btn-main' : 'btn-main-disable'} mr-2 !py-2 !shadow-none`}
                    dataQa={'extended-assets-download-template-btn'}
                />
                <CSVLink
                    ref={csvLink3}
                    data={(assetsExtendedTemplate && assetsExtendedTemplate?.length > 0) ? assetsExtendedTemplate?.filter((item, idx) => idx > 0) || [] : []}
                    headers={assetsExtendedTemplate?.[0] || []}
                    className={'hidden'}
                    target={'_blank'}
                    filename={'Assets-extended-template-data.csv'}
                ></CSVLink>
                <Button
                    label='Import Assets Extended'
                    className={`!rounded ${(!accessControl || pageAccess?.actions?.['Import Assets Extended']) ? 'btn-main' : 'btn-main-disable'}  min-w-[150px] !shadow-none mr-2`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Assets Extended']) && onToggleImportAssetsExtendedModal()}
                    dataQa={'extended-assets-import-btn'}
                />
                <Button
                    label={'Add New Item'}
                    className={`${(!accessControl || pageAccess?.actions?.['Add New Item']) ? 'btn-main' : 'btn-main-disable'}`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Item']) && onAddNewAssetTemplate()}
                    dataQa={'extended-assets-add-new-item-btn'}
                />
            </div>
            <div className='my-5 relative'>
                <div className={'min-w-[100px]'}>
                    <Input
                        placeholder={'Filter Table'}
                        dataQa={'extended-assets-filter-table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={'mb-0 border-0 focus:outline-none'}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                {(rows?.length > 0 || rowsLoading) ?
                    <AssetsExtendedTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onDisableAsset={onDeleteAssetField}
                        onEditAsset={onAddNewAssetTemplate}
                    />
                    : <EmptyContainer text={'No Item added yet.'} showImage={true} />
                }
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            <AssetExtendedModal
                openCreateAssetModal={showAssetItemModal}
                handleCloseAssetModal={onCloseAssetModal}
                onSaveChanges={onSaveAssetChanges}
                assetItemId={assetItemId}
            />
            <DeleteModal
                openDeleteModal={showDeleteAssetModal}
                handleCloseDeleteModal={onCloseDeleteAssetModal}
                onApproveDeletion={onDisableAsset}
            />
            <ImportAssetsExtendedModal
                openImportModal={showImportAssetsExtendedModal}
                handleCloseModal={onToggleImportAssetsExtendedModal}
                onImportAssetsSuccessfully={onImportAssetsSuccessfully}
            />
        </div>
    )
}

export default ExtendedAssets;