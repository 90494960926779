import React, { useState } from 'react';
import ExcelJS from 'exceljs';
import { CSVLink } from 'react-csv';
import Button from '../../../../shared/button';


interface IDownloadSampleItems {
    allFields: any;
    templateFields?: any;
    inventoryFields?: any;
    extendedFields?: any;
    item: any
}

const DownloadSampleItems = ({
    allFields,
    templateFields,
    extendedFields,
    inventoryFields,
    item
}: IDownloadSampleItems) => {
    const [loadingDownload, setLoadingDownload] = useState<boolean>();
    const csvLink = React.useRef() as React.MutableRefObject<any>;

    const onDownloadTemplate = async () => {
        setLoadingDownload(true)
        csvLink.current.link.click()
        setLoadingDownload(false)
    }

    const columnNumberToLetter = (colNum: number) => {
        let letter = '';
        while (colNum > 0) {
            const remainder = (colNum - 1) % 26;
            letter = String.fromCharCode(65 + remainder) + letter;
            colNum = Math.floor((colNum - 1) / 26);
        }
        return letter;
    };

    const processData = (data: any, isExtended = false) => {
        const requiredFields: any = [];
        const optionalFields: any = [];
        let hasColor = false;

        data.forEach((item: any) => {
            if (isExtended && item?.name?.toLowerCase() === 'color') {
                hasColor = true;
                return;
            }

            const formattedName = item.name
                .split('_')
                .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');

            if (item?.required) {
                requiredFields.push(formattedName);
            } else {
                optionalFields.push(formattedName);
            }
        });

        return { requiredFields, optionalFields, hasColor };
    };

    const formatString = (item: string) => {
        return item
            .replace(/_/g, ' ')
            .toLowerCase()
            .split(' ')
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const downloadExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Styled Sheet');

        worksheet.getRow(1).height = 100;

        worksheet.getCell('A1').value = 'wingpro';
        worksheet.getCell('A1').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'F7931E' },
        };
        worksheet.getCell('A1').font = { size: 30, bold: true, name: 'Docs-Figtree' };
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'left' };

        const columnCount = allFields?.length > 0 ? allFields?.length : 2
        const endColumnLetter = columnNumberToLetter(columnCount)
        worksheet.mergeCells(`B1:${endColumnLetter}1`);
        const mergedCell = worksheet.getCell('B1');
        mergedCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'F7931E' },
        };
        const result1 = processData(templateFields);
        const result2 = processData(inventoryFields);
        const result3 = processData(extendedFields, true);

        const allRequiredFields = [...result1.requiredFields, ...result2.requiredFields, ...result3.requiredFields];
        const allOptionalFields = [...result1.optionalFields, ...result2.optionalFields, ...result3.optionalFields];
        const hasColorInData2 = result3?.hasColor;
        let formRequiredText: any;
        let formOptionalText: any;
        let colorText: any;
        if (allRequiredFields && allRequiredFields?.length > 0) {
            const fieldsJoin = allRequiredFields?.join(', ');
            formRequiredText = [
                { text: `${fieldsJoin} `, font: { bold: true, name: 'Figtree' } },
                { text: `(Required): Enter the item's details in ${allRequiredFields?.length === 1 ? 'this field' : 'these fields'}.\n`, font: { bold: false, name: 'Figtree' } },
            ]
        }
        if (allOptionalFields && allOptionalFields?.length > 0) {
            const fieldsJoin = allOptionalFields?.join(', ');
            formOptionalText = [
                { text: `${fieldsJoin} `, font: { bold: true, name: 'Figtree' } },
                { text: `(Optional): You can leave ${allOptionalFields?.length === 1 ? 'this field' : 'these fields'} blank.\n`, font: { bold: false, name: 'Figtree' } },
            ]
        }
        if (hasColorInData2) {
            colorText = [
                { text: 'Color ', font: { bold: true, name: 'Figtree' } },
                { text: '(Optional): If you don\'t need a specific color, you can leave this field blank. We\'ll assume \'any color\' for that item.\n', font: { bold: false, name: 'Figtree' } },
            ]
        }
        mergedCell.value = {
            richText: [
                ...formRequiredText || [],
                ...formOptionalText || [],
                ...colorText || [],
                { text: 'Quantity ', font: { bold: true, name: 'Figtree' } },
                { text: '(Required): Enter the number of items. If left blank, we\'ll automatically set the quantity to 1.', font: { bold: false, name: 'Figtree' } },
                { text: '\nPrice ', font: { bold: true, name: 'Figtree' } },
                { text: '(Optional): You can fill this in if you want to set a price per item. If left blank, you\'ll have the option to set a total price for all the items on the platform later.', font: { bold: false, name: 'Figtree' } },
            ]
        }
        mergedCell.font = { size: 10 };
        mergedCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'right' };

        for (let i = 1; i <= columnCount; i++) {
            worksheet.getColumn(i).width = i === 1 ? 30 : 13;
            const cell = worksheet.getCell(`${columnNumberToLetter(i)}2`);
            cell.value = allFields?.[i - 1] ? formatString(allFields?.[i - 1]) : allFields?.[i - 1];
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '202020' },
            };
            cell.border = {
                left: { style: 'thin', color: { argb: 'FFFFFFFF' } }
            };
            cell.alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            cell.font = {
                color: { argb: 'FFFFFF' },
                name: 'Avenir',
                bold: true,
                size: 12
            };
        }

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${item?.name}_items_sample.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        });
    };

    return (
        <>
            <Button
                label={loadingDownload ? 'Loading...' : 'Download Sample CSV'}
                className='btn-main mr-3'
                onClickButton={() => !loadingDownload && onDownloadTemplate()}
            />
            <CSVLink
                ref={csvLink}
                data={[]}
                headers={(allFields && allFields?.length > 0) ? allFields : []}
                className={'none'}
                target={'_blank'}
                filename={`${item?.name}_items_sample.csv`}>
            </CSVLink>
            <Button
                label={loadingDownload ? 'Loading...' : 'Download Sample Excel'}
                className='btn-main'
                onClickButton={() => downloadExcel()}
            />
        </>
    )
}
export default DownloadSampleItems;