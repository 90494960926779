import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryFetchAssetFieldValues } from '../../../../store/brokers/admin/assets/assetsSlice';
import MappingListFieldsTable from '../tables/MappingListFieldsTable';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';


interface IMappingListFields {
    mappedValues?: any;
    fileValues?: any;
    categoryValues?: any;
    activeField?: any;
    filteredFields?: any;
    filteredMultiFields?: any;
    loadingOptions?: any;
    mappingDetails?: any;
    twigCategoryField?: any;
    onContinueAsSameValue: () => void;
    onDeleteMappedValues: (type: string, value?: any) => void;
    onAddNewMappedValue: (value: any, type?: string, item?: string, index?: any) => void;
    onMenuCloseAfterSearch: (type?: string, index?: any) => void;
    onChangeSearchFieldValues: (e: any, type?: string, index?: any) => void;
    onChangeSearchFieldMultiple: (e: any, type?: string, index?: any) => void;
    onMenuCloseAfterSearchMulti: (type?: string, index?: any) => void;
}

const MappingListFields = ({
    mappedValues,
    filteredMultiFields,
    categoryValues,
    activeField,
    mappingDetails,
    filteredFields,
    loadingOptions,
    twigCategoryField,
    onContinueAsSameValue,
    onDeleteMappedValues,
    onAddNewMappedValue,
    onMenuCloseAfterSearch,
    onChangeSearchFieldValues,
    onMenuCloseAfterSearchMulti,
    onChangeSearchFieldMultiple,
}: IMappingListFields) => {
    const dispatch = useAppDispatch()
    const [activeFieldTemp, setActiveFieldTemp] = useState<any>()
    const [uniqueValues, setUniqueValues] = useState<any>()
    const [fieldsErrors, setFieldsErrors] = useState<any>()
    const [tempRows, setTempRows] = useState<any>()
    const [analyzeLoading, setAnalyzeLoading] = useState<boolean>(false)

    const onDeleteRows = (type: string, index?: string) => {
        if (type === 'one' && index) {
            onDeleteMappedValues(type, index)
            setFieldsErrors(undefined)
        } else {
            setFieldsErrors(undefined)
            onDeleteMappedValues(type)
        }
    }

    useEffect(() => {
        if (activeField?.name !== activeFieldTemp) {
            setActiveFieldTemp(activeField)
            setUniqueValues(activeField?.inputValues || [])
            setTempRows(undefined)
        }
    }, [activeField])

    const onChangeSelectedFieldValue = (e: any, type?: string, index?: any, item?: string) => {
        if (type) {
            if (activeField?.mappedAs && activeField?.mappedAs?.length > 1 && item) {
                onAddNewMappedValue({ [type]: e?.label }, item, type, index)
                return
            }
            onAddNewMappedValue({ [type]: e?.label })
        }
    }

    const onSetTwigValuesThatMatches = async () => {
        if (uniqueValues && uniqueValues?.length > 0) {
            setAnalyzeLoading(true)
            const uniqueValuesFound: any = []
            let tempRowsData: any
            await Promise.all(uniqueValues?.map(async (item: any) => {
                const response: any = await dispatch(tryFetchAssetFieldValues({ id: mappingDetails?.catalog?.id || '', field: mappingDetails?.pricingType === 'WHOLESALE_PRICING' ? twigCategoryField?.name || activeField?.mappedAs?.[0] : activeField?.mappedAs?.[0] || '', search: item, replacements: mappingDetails?.pricingType === 'WHOLESALE_PRICING' && twigCategoryField?.replaceField?.name || false })).unwrap()
                if (response && response?.length > 0) {
                    const findExactValue = response?.find((obj: any) => obj === item)
                    if (findExactValue) {
                        uniqueValuesFound.push(item)
                        tempRowsData = {
                            ...tempRowsData || {},
                            [item]: item
                        }
                    }
                }
            }))
            if (tempRowsData) {
                onAddNewMappedValue(tempRowsData)
            }
            setTempRows(tempRowsData || undefined)
            const uniqueValuesFoundSort = uniqueValuesFound && uniqueValuesFound?.length > 0 ? uniqueValuesFound.sort() : []
            const filterOtherUniqueValues = uniqueValues?.filter((obj: any) => !uniqueValuesFoundSort?.includes(obj))
            setUniqueValues([
                ...uniqueValuesFoundSort || [],
                ...filterOtherUniqueValues || []
            ])
            setAnalyzeLoading(false)
        }
    }

    return (
        <div className='w-full grid grid-cols-7 gap-4 my-8 relative'>
            <div className='col-span-5'>
                <MappingListFieldsTable
                    rows={uniqueValues}
                    mappedValues={mappedValues}
                    columns={tempRows && Object.keys(tempRows) ? ['Mapped', activeField?.name, ...activeField?.mappedAs?.map((item: any) => item), 'Delete Data'] : [activeField?.name, ...activeField?.mappedAs?.map((item: any) => item), 'Delete Data']}
                    categoryValues={categoryValues}
                    errors={fieldsErrors}
                    filteredFields={filteredFields}
                    filteredMultiFields={filteredMultiFields}
                    loadingOptions={loadingOptions}
                    mappedAsFields={activeField?.mappedAs}
                    tempRows={tempRows}
                    onDeleteMappedValues={onDeleteMappedValues}
                    onChangeSelectedFieldValue={onChangeSelectedFieldValue}
                    onChangeSearchFieldValues={onChangeSearchFieldValues}
                    onChangeSearchFieldMultiple={onChangeSearchFieldMultiple}
                    onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                    onMenuCloseAfterSearchMulti={onMenuCloseAfterSearchMulti}
                />
            </div>
            <div className='mt-3 pl-2 col-span-2'>
                <Button
                    label={'Reset Mapped Values'}
                    dataQa={'reset-values-button'}
                    className={'btn-main mb-4 min-w-[100%]'}
                    onClickButton={() => onDeleteRows('all')}
                />
                <Button
                    label={'Same Values As Wing Field'}
                    dataQa={'wing-field-button'}
                    disabled={activeField?.mappedAs?.length > 1}
                    className={`${(activeField?.mappedAs?.length > 1) ? 'btn-main-disable' : 'btn-main'} mb-4 min-w-[100%]`}
                    onClickButton={onContinueAsSameValue}
                />
                <Button
                    icon={analyzeLoading ? <Loading /> : <span></span>}
                    label={'Analyze Values Compared With Wing'}
                    dataQa={'compared-with-wing-button'}
                    disabled={activeField?.mappedAs?.length > 1}
                    className={`${analyzeLoading || (activeField?.mappedAs?.length > 1) ? 'btn-main-disable' : 'btn-main'} mb-4 min-w-[100%]`}
                    onClickButton={() => !analyzeLoading && !(activeField?.mappedAs?.length > 1) && onSetTwigValuesThatMatches()}
                />
                {tempRows && Object.keys(tempRows) &&
                    <div className='my-4 flex flex-row items-start max-w-[87%]'>
                        <img src={'/assets/shared/info-circle.svg'} className={'w-[17px] mt-2 mr-2 object-contain'} />
                        <p className='text-primary-light'>Mapped values will be sorted first and not found values will be sorted next.</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default MappingListFields;