import React from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToCreateAdmin } from '../../../../store/brokers/admin/cs-agents/CSAgentsSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface ICreateAdminModal {
    openModal: boolean;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    username: string;
    email: string;
    country: string;
    password: string;
};

const CreateAdminModal = ({
    openModal,
    handleCloseModal,
    onSaveChanges,
}: ICreateAdminModal) => {
    const dispatch = useAppDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormValues>({});

    const onCloseModal = () => {
        reset();
        handleCloseModal();
    };

    const onSubmit = async (data: FormValues) => {
        try {
            const payload: any = {
                ...data
            }
            await dispatch(tryToCreateAdmin(payload)).unwrap();
            onSaveChanges('success', ` changed.`);
        } catch (error) {
            onSaveChanges('error', `${error}`);
        }
        onCloseModal();
    };

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>Create Admin</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-col my-4'>
                        <Input
                            label={'Name'}
                            dataQa={'create-admin-name'}
                            register={register('name', {
                                required: {
                                    message: 'Name is required',
                                    value: true,
                                },
                                validate: (value: string) =>
                                    !!value.trim() || 'Name is required',
                            })}
                            error={errors.name?.message}
                        />
                        <Input
                            label={'Username'}
                            dataQa={'create-admin-username'}
                            register={register('username', {
                                required: {
                                    message: 'Username is required',
                                    value: true,
                                },
                                validate: (value: string) =>
                                    !!value.trim() || 'Username is required',
                            })}
                            error={errors.username?.message}
                        />
                        <Input
                            label='Email'
                            dataQa={'create-admin-email'}
                            register={register('email', {
                                required: {
                                    message: 'Email is required',
                                    value: true,
                                },
                                pattern: {
                                    /* eslint-disable-next-line */
                                    value: /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please write a valid email'
                                },
                                validate: (value: string) => !!value.trim() || 'Email is required'
                            })}
                            error={errors.email?.message}
                        />
                        <Input
                            placeholder='Password'
                            dataQa={'create-admin-password'}
                            type={'password'}
                            register={register('password', {
                                required: {
                                    message: 'Password is required',
                                    value: true,
                                },
                                minLength: {
                                    value: 8,
                                    message: 'Minimum number of characters is 8'
                                },
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                    message: 'Password should be at least 8 characters long, and must contain at least 1 number, 1 lower case, 1 upper case and 1 special character(@$!%*?&)'
                                },
                                validate: (value: string) => !!value.trim() || 'Password is required and min length is 8'
                            },
                            )}
                            error={errors.password?.message}
                        />
                        <Input
                            label={'Country'}
                            dataQa={'create-admin-country'}
                            register={register('country', {
                                required: {
                                    message: 'Country is required',
                                    value: true,
                                },
                                validate: (value: string) =>
                                    !!value.trim() || 'Country is required',
                            })}
                            error={errors.country?.message}
                        />
                        <div className='flex flex-row justify-end mt-4'>
                            <Button
                                label='Create'
                                type={'submit'}
                                className={'btn-primary'}
                                dataQa={'create-admin-btn'}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default CreateAdminModal;
