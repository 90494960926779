import React from 'react';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['Name', 'Label', 'Flag', 'Currency', 'Buying Entity', 'Selling Entity', 'VAT Region', 'UK VAT Type', 'EU VAT Type', 'US VAT Type', 'Actions']

interface IMarketsTable {
    rows: IMarket[];
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    currencies: any[];
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onDeleteMarketRequest: (id: string) => void;
    onEditMarketField: (id: string) => void;
    onSeeMarketDetails: (id: string) => void;
}

const MarketsTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    currencies,
    rowsLoading,
    setPaginationState,
    onDeleteMarketRequest,
    onEditMarketField,
    onSeeMarketDetails,
}: IMarketsTable) => {
    return (
        <Table
            columns={tableColumns}
            containerStyle={'mt-0'}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IMarket, idx: number) => {
                        const findCurrency: any = (currencies && currencies?.length > 0) && currencies?.find((item: any) => item?._id === row?.currency)
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>{row?.label}</td>
                                <td className='px-6 py-4'>{(row?.flagUrl) ? <img src={`${row?.flagUrl}?cache_buster=${Math.random()}`} alt='flag' className={'mr-2 py-2 w-[20px]'} /> : <></>}</td>
                                <td className='px-6 py-4'>{findCurrency ? findCurrency?.descriptor?.name || '' : ''}</td>
                                <td className='px-6 py-4'>{row?.buyingEntity || ''}</td>
                                <td className='px-6 py-4'>{row?.sellingEntity || ''}</td>
                                <td className='px-6 py-4'>{row?.vatRegion || ''}</td>
                                <td className='px-6 py-4'>{row?.vatTypes?.UK || ''}</td>
                                <td className='px-6 py-4'>{row?.vatTypes?.EU || ''}</td>
                                <td className='px-6 py-4'>{row?.vatTypes?.US || ''}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div onClick={() => ((!accessControl || actions?.['Edit Market']) && row?._id) && onEditMarketField(row?._id)}>
                                            <img title='Edit Market' data-qa='edit-market' alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Market']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div onClick={() => ((!accessControl || actions?.['Delete Market']) && row?._id) && onDeleteMarketRequest(row?._id)}>
                                            <img title={'Delete Market'} alt='delete market' data-qa='delete-market' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Market']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                        </div>
                                        <div onClick={() => ((!accessControl || actions?.['See Market Details']) && row?._id) && onSeeMarketDetails(row?._id)}>
                                            <img title={'View Market'} alt='view market' data-qa='details-market' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Market Details']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default MarketsTable;