import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


interface IPaymentMethod {
    selected: boolean;
    image?: string;
    name: string;
    countriesAvailable: Array<string>;
}

const PaymentMethod = ({
    selected,
    image,
    name,
    countriesAvailable
}: IPaymentMethod) => {
    return (
        <WhiteContainer
            type={'rounded'}
            containerStyle={'flex flex-col justify-between min-h-[200px]'}
        >
            <div className='flex flex-row justify-between items-center w-full my-2'>
                <p className='text-primary-light font-bold text-sm'>Payment method</p>
                {selected && <img src={'/assets/profile/open-success.svg'} className={'w-[23px] object-contain'} />}
            </div>
            <div className='flex flex-row items-center w-full my-2'>
                <img src={image || ''} className={'w-[50px] min-h-[50px] bg-[#D9D9D9] object-contain mr-2'} />
                <p className='font-bold text-2xl'>{name}</p>
            </div>
            <div className='my-2'>
                <p className='text-primary-light'>Available in:</p>
                <p>{countriesAvailable?.length > 0 && countriesAvailable?.map((country: string, index: number) => `${index ? ', ':''}${country}`)}</p>
            </div>
        </WhiteContainer>
    )
}

export default PaymentMethod;