import React, { useCallback, useState } from 'react';
import Modal from '../../../shared/modal';
import CloseDealForm from './CloseDealForm';

const CloseDealModal = ({
	socketId
} : {
	socketId: string
}) => {
	const [showModal, setShowModal] = useState<boolean>(true)
	const handleCloseModal = useCallback(() => setShowModal(show => !show), []);
	
	return (
		<>
			<Modal
				open={showModal}
				onClose={handleCloseModal}
				showInRight
				title={'Close the deal'}
				contentContainerStyle={'w-full'}
			>
				<CloseDealForm handleCloseModal={handleCloseModal} />
			</Modal>
		</>
	)
}

export default CloseDealModal;