import React, { useEffect } from 'react';

interface IToast {
    type: string;
    text: string;
    onHandleCloseToast: () => void;
}

const Success = () => {
    return (
        <div className='inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200'>
            <svg aria-hidden='true' className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clipRule='evenodd'></path></svg>
            <span className='sr-only'>Check icon</span>
        </div>
    )
}

const Error = () => {
    return (
        <div className='inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200'>
            <svg aria-hidden='true' className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clipRule='evenodd'></path></svg>
            <span className='sr-only'>Error icon</span>
        </div>
    )
}

const Info = () => {
    return (
        <div className='inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-blue-500 bg-red-100 rounded-lg dark:bg-blue-800 dark:text-blue-200'>
            <svg aria-hidden='true' className='w-6 h-6' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z' clipRule='evenodd'></path></svg>
            <span className='sr-only'>Info icon</span>
        </div>
    )
}

const Toast = ({ type, text, onHandleCloseToast }: IToast) => {
    let iconType = <Info />
    switch (type) {
        case 'success':
            iconType = <Success />
            break;
        case 'error':
            iconType = <Error />
            break;
        case 'info':
            iconType = <Info />
    }

    useEffect(() => {
        setInterval(
            onHandleCloseToast,
            6000
        );
    }, [])

    return (
        <div id='toast-default' className={`${!text && 'hidden'} flex justify-between fixed bottom-5 left-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-500 bg-white rounded-lg divide-x shadow`} role='alert'>
            <div className='flex flex-row items-center'>
                {iconType}
                <div className='ml-3 border-white text-sm font-normal'>{text}</div>
            </div>
            <button type='button' className='border-white ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8'
                onClick={() => onHandleCloseToast()}
                aria-label='Close'>
                <span className='sr-only'>Close</span>
                <svg aria-hidden='true' className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clipRule='evenodd'></path></svg>
            </button>
        </div >
    );
};

export default Toast;