import { AxiosResponse } from 'axios';
import { ASSETS_API_URL } from '../../../../config';
import assetsAxios from '../../../../utils/axios/assets.axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IRentalPricingSettings } from '../../../../interfaces/rental-pricing-settings/IRentalPricingSettings';
import { PaginatedRentalPricingSettings } from '../../../../interfaces/rental-pricing-settings/paginatedStore.type';


const tryToFetchRentalPricingSettings = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedRentalPricingSettings>> => {
    const url = `${ASSETS_API_URL}/rental-offers-settings?page=${pageNumber}&size=${pageSize}`
    return assetsAxios.put<PaginatedRentalPricingSettings>(url, data);
};

const tryToFetchSingleRentalPricingSetting = (id: string): Promise<AxiosResponse<ApiResponse<any>>> => {
    return assetsAxios.get(`${ASSETS_API_URL}/rental-offers-settings/${id}`);
};

const tryToEditRentalPricingSetting = (id: string, payload: IRentalPricingSettings): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/rental-offers-settings/${id}`, payload);
};

const tryToAddRentalPricingSetting = (data: IRentalPricingSettings): Promise<AxiosResponse> => {
    return assetsAxios.post(`${ASSETS_API_URL}/rental-offers-settings`, data);
};

const tryToDeleteRentalPricingSetting = (id: string): Promise<AxiosResponse> => {
    return assetsAxios.delete(`${ASSETS_API_URL}/rental-offers-settings/${id}`);
};


export const RentalPricingSettingsService = {
    tryToFetchRentalPricingSettings,
    tryToFetchSingleRentalPricingSetting,
    tryToEditRentalPricingSetting,
    tryToAddRentalPricingSetting,
    tryToDeleteRentalPricingSetting,
};
