import React, { useState, useCallback, useEffect } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToFetchCategoryMappingsWithoutPagination } from '../../../../store/brokers/shared-endpoints/category-mappings/categoryMappingsSlice';
import { tryToFetchAllCurrencies } from '../../../../store/brokers/shared-endpoints/wholesale-price/wholesalePriceSlice';
import { tryToFindOperatingMarkets } from '../../../../store/brokers/admin/partners/partnersSlice';
import { tryToUploadPricingCSV } from '../../../../store/brokers/partners/pricing/pricingSlice';
import SuccessfullyUploadFile from '../details/SuccessfullyUploadedFile';
import UploadCsvFile from '../details/UploadCsvFile';
import Modal from '../../../../shared/modal';


interface IImportPricingCsvModal {
    openImportModal: boolean;
    sectors: Array<any>;
    handleCloseModal: () => void;
    onImportSuccessfully: () => void;
}

const ImportPricingCsvModal = ({
    openImportModal,
    sectors,
    handleCloseModal,
    onImportSuccessfully,
}: IImportPricingCsvModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [selectedSector, setSelectedSector] = useState<any>()
    const [fileSelected, setFileSelected] = useState<any>()
    const [importLoading, setImportLoading] = useState<boolean>(false)
    const [allCurrencies, setAllCurrencies] = useState<any>()
    const [selectedCurrency, setSelectedCurrency] = useState<any>()
    const [showMarkets, setShowMarkets] = useState<boolean>(false)
    const [markets, setMarkets] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [templates, setTemplates] = useState<any>()
    const [selectedTemplate, setSelectedTemplate] = useState<any>()
    const activeMarket = useAppSelector((state) => state?.partners?.partnerActiveMarket)
    const currencies = useAppSelector((state: any) => state?.wholesale?.wholesaleCurrencies);
    const state = useAppSelector((state) => state.partners);
    const templateState = useAppSelector((state) => state.categoryMapping);

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onCloseModal = () => {
        handleCloseModal();
        setImportError(undefined)
        setImportedSuccessfully(false)
        setFileSelected(undefined)
        setSelectedSector(undefined)
        setSelectedCurrency(undefined)
        setSelectedMarket(undefined)
        setShowMarkets(false)
    }

    useEffect(() => {
        if (state.partnerMarkets) {
            const rows =
                state.partnerMarkets && Array.isArray(state.partnerMarkets)
                    ? state.partnerMarkets.map((r) => ({ ...r, name: r?.market?.label, label: r?.market?.name, value: r?.market?._id }))
                    : [];
            const formatWithIcon = rows && rows?.length > 0 && rows?.map((row) => {
                return {
                    ...row,
                    name: row?.market?.label,
                    label: row?.market?.label,
                    value: row?.market?._id
                }
            })
            setMarkets(formatWithIcon || []);
        }
    }, [state?.partnerMarkets])

    useEffect(() => {
        if (selectedSector) {
            const filtersFormat = {
                assetTemplateId: selectedSector?.value,
                enabled: true,
                status: 'COMPLETED',
                pricingType: 'PRICING'
            }
            dispatch(tryToFetchCategoryMappingsWithoutPagination(filtersFormat))
        }
    }, [selectedSector])

    useEffect(() => {
        if (currencies) {
            const formatCurrencies = currencies && currencies?.length > 0 && currencies?.map((currency: any) => ({ label: currency?.descriptor?.name, value: currency?._id, name: currency?.descriptor?.name }))
            setAllCurrencies(formatCurrencies || undefined)
        }
    }, [currencies])

    useEffect(() => {
        if (templateState?.categoryMappings) {
            const formatTemplates = templateState?.categoryMappings && templateState?.categoryMappings?.length > 0 && templateState?.categoryMappings?.map((item: any) => ({ label: item?.name, value: item?._id, name: item?.name }))
            setTemplates(formatTemplates || undefined)
        }
    }, [templateState?.categoryMappings])

    const onUploadFile = async () => {
        if (!selectedSector || (showMarkets && !selectedMarket) || (!showMarkets && !selectedCurrency)) {
            setImportError('Please make sure you select all fields in order to upload pricing.')
            return;
        }
        setImportLoading(true)
        try {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const formatResults = results?.data && results?.data?.length > 0 && results?.data?.map((item: any) => {
                        const asset = Object.assign(item)
                        // eslint-disable-next-line
                        const quantity = item?.price ? Number(item?.price?.replace(/[^0-9\.]+/g, '')) : 0
                        delete asset.price
                        const objectKeysFormat = asset && Object.keys(asset)
                        objectKeysFormat.forEach((element: any) => {
                            if (asset?.[element] && element !== 'price' && /^\d+(\s?\d+)*\s?-Wing$/.test(asset?.[element])) {
                                const formatElement = asset?.[element]?.replace('-Wing', '')
                                asset[element] = formatElement
                            }
                        });
                        return {
                            asset,
                            quantity
                        }
                    })
                    let payload: any = {
                        templateId: selectedSector?.value || '',
                        data: formatResults || []
                    }
                    if (selectedCurrency && !showMarkets) {
                        payload = {
                            ...payload || {},
                            currency: selectedCurrency?.value || ''
                        }
                    }
                    if (selectedTemplate) {
                        payload = {
                            ...payload || {},
                            template: selectedTemplate?.name || ''
                        }
                    }
                    if (showMarkets && selectedMarket) {
                        payload = {
                            ...payload || {},
                            marketId: selectedMarket?.value || ''
                        }
                    }
                    await dispatch(tryToUploadPricingCSV(payload)).unwrap()
                }
            })
            setImportError(undefined)
            setImportedSuccessfully(true)
            setSelectedSector(undefined)
            onImportSuccessfully()
            setShowMarkets(false)
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
        setImportLoading(false)
    }

    useEffect(() => {
        dispatch(tryToFetchAllCurrencies()).unwrap()
        dispatch(tryToFindOperatingMarkets(null)).unwrap()

    }, [])

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(e)
        setSelectedTemplate(undefined)
    }

    const onChangeSelectedCurrency = (e: any) => {
        if (e?.value === 'market') {
            setShowMarkets(true)
        } else {
            setShowMarkets(false)
            setSelectedMarket(undefined)
        }
        setSelectedCurrency(e)
    }

    const onChangeSelectedMarket = (e: any) => {
        setSelectedMarket(e)
    }

    const onClickBasedOnMarket = () => {
        setShowMarkets(!showMarkets)
    }

    const onChangeSelectedTemplate = (e: any) => {
        setSelectedTemplate(e)
    }

    return (
        <Modal
            open={openImportModal}
            onClose={() => !importLoading && onCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <SuccessfullyUploadFile
                        handleCloseModal={() => !importLoading && onCloseModal()}
                    />
                    : <UploadCsvFile
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                        activeMarket={activeMarket}
                        currencies={allCurrencies}
                        importLoading={importLoading}
                        templates={templates}
                        selectedCurrency={selectedCurrency}
                        selectedTemplate={selectedTemplate}
                        selectedSector={selectedSector}
                        sectors={sectors}
                        showMarket={showMarkets}
                        selectedMarket={selectedMarket}
                        markets={markets}
                        onClickBasedOnMarket={onClickBasedOnMarket}
                        onChangeSelectedSector={onChangeSelectedSector}
                        onChangeSelectedTemplate={onChangeSelectedTemplate}
                        onChangeSelectedCurrency={onChangeSelectedCurrency}
                        onChangeSelectedMarket={onChangeSelectedMarket}
                    />
                }
            </div>
        </Modal>
    )
}

export default ImportPricingCsvModal;