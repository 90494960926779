import React from 'react';
import { PartnerGuard } from '../../../../guards/RolesGuard';
import WhiteContainer from '../../../../shared/white-container';
import Button from '../../../../shared/button';


interface ICompanyInformations {
    userInfo: any;
    accessChangePassword?: any;
    accessEditInformation?: any;
    onShowHideChangePasswordModal: () => void;
    onShowHidePersonalInformation: () => void;
}

const CompanyInformations = ({
    userInfo,
    accessChangePassword,
    accessEditInformation,
    onShowHidePersonalInformation,
    onShowHideChangePasswordModal
}: ICompanyInformations) => {
    const isPartner = PartnerGuard()
    return (
        <WhiteContainer type='rounded'>
            <div className='mt-2 mb-3'>
                <p className='text-[#F7A21E] text-lg font-bold'>Company Information</p>
                <div className='mt-7 mb-5'>
                    {userInfo?.companyName && <p className='mb-3'>Company Name: {userInfo?.companyName}</p>}
                    <p className='mb-3'>Username: {userInfo?.username}</p>
                    {userInfo?.vatNumber && <p className='mb-3'>VAT Number: {userInfo?.vatNumber}</p>}
                    {userInfo?.companyRegistrationNumber && <p className='mb-3'>Company Registration Number: {userInfo?.companyRegistrationNumber}</p>}
                    {userInfo?.annualGMV && <p className='mb-3'>Annual Turnover: {userInfo?.annualGMV}</p>}
                </div>
            </div>
            <div className='flex flex-row item-center'>
                {isPartner &&
                    <Button
                        label={'Edit'}
                        dataQa={'edit'}
                        className={`${accessEditInformation ? 'btn-primary-text-underline' : 'btn-primary-text-underline-disable'} mr-3`}
                        onClickButton={() => accessEditInformation && onShowHidePersonalInformation()}
                    />
                }
                <Button
                    label={'Change Password'}
                    dataQa={'change-password'}
                    className={`${accessChangePassword ? 'btn-primary-text-underline' : 'btn-primary-text-underline-disable'}`}
                    onClickButton={() => accessChangePassword && onShowHideChangePasswordModal()}
                />
            </div>
        </WhiteContainer>
    )
}

export default CompanyInformations;