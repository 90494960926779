import React from 'react';

const SectionModalContainer = () => {
    return (
        <div className='min-w-[100%]'>
            <div className='flex flex-col my-4 my-2 mb-[30px] content-start'>
                <p className='block text-primary-light font-normal text-base mb-2'>Name</p>
                <div className='h-[40px] animate-pulse bg-gray-200 rounded dark:bg-gray-200 w-[90%] mb-3'></div>
                <p className='block text-primary-light font-normal text-base mb-2'>Display Name</p>
                <div className='h-[40px] animate-pulse bg-gray-200 rounded dark:bg-gray-200 w-[90%] mb-3'></div>
            </div>
            <div className='flex flex-row justify-end mt-4 animate-pulse'>
                <div className='h-[35px] bg-gray-200 rounded dark:bg-gray-200 w-[120px] mb-1'></div>
            </div>
        </div>
    )
}

export default SectionModalContainer;