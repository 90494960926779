import React from 'react';

const EmptyNotifications = () => {

    return (
        <div className='flex flex-col p-5 bg-[#f8f9fc] items-center !rounded h-[350px]'>
            <img src={'/svg/layout/empty-notifications.svg'} className={'object-contain w-[100px]'} />
            <p className='mt-3 mb-2 text-[18px] font-bold text-center'>No Notifications Yet</p>
            <p className='text-center text-[13px] mb-1 text-primary-light'>You have no notifications right now.</p>
            <p className='text-center text-[13px] mb-4 text-primary-light'>Come back later</p>
        </div>
    )
}

export default EmptyNotifications;