import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToCreateUIAction,
    tryToEditUIAction,
    tryToFetchSingleUIAction
} from '../../../../store/brokers/admin/ui-actions/uiActionsSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';


interface ICreateAssetModal {
    openCreateAssetModal: boolean;
    uiActionId?: string | null;
    handleCloseAssetModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    displayName: string;
    _id?: string;
};

const UIActionModal = ({
    openCreateAssetModal,
    uiActionId,
    handleCloseAssetModal,
    onSaveChanges,
}: ICreateAssetModal) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.uiActions);
    const assetLoading = useAppSelector((state) => state.uiActions?.uiActionIsLoading);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        setValue('name', uiActionId ? state?.uiActionDetails?.name || '' : '')
        setValue('displayName', uiActionId ? state?.uiActionDetails?.displayName || '' : '')
    }, [state.uiActionDetails]);

    useEffect(() => {
        if (uiActionId) {
            dispatch(tryToFetchSingleUIAction(uiActionId));
        }
    }, [uiActionId])

    const onSubmit = async (data: FormValues) => {
        try {
            if (uiActionId) {
                data._id = uiActionId
                await dispatch(tryToEditUIAction(data)).unwrap();
            } else {
                await dispatch(tryToCreateUIAction(data)).unwrap();
            }
            onSaveChanges('success', `Asset Catalog successfully ${uiActionId ? 'changed' : 'added'}.`)
        } catch (error) {
            onSaveChanges('error', `${error}`)
        }
        reset();
        handleCloseAssetModal()
    };

    const onCloseModal = () => {
        reset()
        handleCloseAssetModal()
    }

    return (
        <Modal
            open={openCreateAssetModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>UI Action</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {((uiActionId && !assetLoading) || !uiActionId) ?
                        <div className='flex flex-col my-4'>
                            <div className='flex flex-row gap-x-3 justify-items-stretch'>
                                <Input
                                    placeholder='Display Name'
                                    dataQa={'display-name'}
                                    label={uiActionId ? 'Display Name' : ''}
                                    register={register('displayName', {
                                        required: {
                                            message: 'Display Name is required',
                                            value: true,
                                        },
                                        validate: (value: string) => !!value.trim() || 'Display Name is required'
                                    })}
                                    error={errors.displayName?.message}
                                />
                                <Input
                                    placeholder='Name'
                                    dataQa={'name'}
                                    label={uiActionId ? 'Name' : ''}
                                    register={register('name', {
                                        required: {
                                            message: 'Name is required',
                                            value: true,
                                        },
                                        validate: (value: string) => !!value.trim() || 'Name is required'
                                    })}
                                    error={errors.name?.message}
                                />
                            </div>
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    dataQa={'submit'}
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                        : <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    }
                </form>
            </div>
        </Modal>
    )
}
export default UIActionModal;