import React from 'react';
import Button from '../../../../shared/button';


interface ISuccessfullyUploadFile {
    handleCloseModal: () => void;
}

const SuccessfullyUploadFile = ({
    handleCloseModal
}: ISuccessfullyUploadFile) => {
    return (
        <div className={'w-full flex flex-col items-center justify-between'}>
            <div>
                <p className='text-md font-medium text-center'>Thank you for providing this info. The file has been submitted and will be reviewed shortly. You will receive a confirmation message in your email address.</p>
            </div>
            <img src={'/assets/shared/success-mark.svg'} className={'h-[100px] object-contain my-10'} />
            <Button
                label='Close'
                dataQa={'close-button'}
                className='btn-main my-3 min-w-[150px] !shadow-none'
                onClickButton={handleCloseModal}
            />
        </div>
    )
}

export default SuccessfullyUploadFile;