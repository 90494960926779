import React, { useEffect, useRef } from 'react';
import Button from '../../../../shared/button';


interface ITableActions {
    onClickCSVButton: () => void;
    excelUrl?: string;
    pdfLink?: string;
    shippingUrl?: string;
}

const TableActions = ({
    excelUrl,
    pdfLink,
    shippingUrl
}: ITableActions) => {
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const dropdownMenu = useRef<any>(null);

    const closeDropdownPopover = (e: any) => {
        if (dropdownMenu?.current && !dropdownMenu?.current?.contains(e.target)) {
            setDropdownPopoverShow(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', closeDropdownPopover, false);
        return () => {
            document.removeEventListener('click', closeDropdownPopover, false);
        }
    }, [])

    const onClickDropDownItem = (item: string) => {
        if (item === 'pdf') {
            window.open(pdfLink, '_blank');
        }
        else if (item === 'csv') {
            window.open(excelUrl, '_blank');
        } else {
            window.open(shippingUrl, '_blank');
        }
        setDropdownPopoverShow(false)
    }

    return (
        <div>
            <div>
                <button
                    onClick={(e) => setDropdownPopoverShow(!dropdownPopoverShow)}
                    type='button'
                    data-qa={'actions'}
                    className={'!px-4'} id='menu-button' aria-expanded='true' aria-haspopup='true'>
                    <img title={'Action'} alt='items arrow' src={'/assets/pricing/see-more.svg'} className={'w-[7x] rotate-180 mb-2 cursor-pointer mr-2'} />
                </button>
            </div>
            <div
                className={`${dropdownPopoverShow ? '' : 'hidden'} shadow-md max-h-[150px] overflow-auto absolute right-0 z-10 mt-0 w-40 origin-top-right divide-y divide-[#a4a4a4] bg-white focus:outline-none`}
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='menu-button'
                tabIndex={-1}>
                <div
                    onClick={() => onClickDropDownItem('pdf')}
                    className='py-1 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                    role='none'>
                    <Button
                        dataQa={'pdf'}
                        label={'PDF'}
                        className={'btn-primary-text-underline'}
                    />
                </div>
                <div
                    onClick={() => onClickDropDownItem('csv')}
                    className='py-1 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                    role='none'>
                    <Button
                        label={'CSV Details'}
                        dataQa={'csv-details'}
                        className={'btn-primary-text-underline'}
                    />
                </div>
                <div
                    onClick={() => onClickDropDownItem('shippingUrl')}
                    className='py-1 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                    role='none'>
                    <Button
                        label={'Shipping Label'}
                        dataQa={'shipping-label'}
                        className={'btn-primary-text-underline'}
                    />
                </div>
            </div>
        </div>
    )
}

export default TableActions;