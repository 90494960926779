import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPricingHistoryListState } from '../../../../interfaces/pricing-history/IPricingHistoryList';
import { PricingHistoryService } from './pricingHistoryApi';
import { PaginatedPricingHistory, PaginatedPricingHistoryDetails } from '../../../../interfaces/pricing-history/paginatedStore.type';

const initialState: IPricingHistoryListState = {
    pricingIsLoading: false,
    pricingsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAggregatedPricingHistory = createAsyncThunk<PaginatedPricingHistory, IPaginationPayload>(
    'pricing-history/tryToFetchAggregatedPricingHistory',
    async ({ pageNumber, pageSize, data }) => {
        const result = await PricingHistoryService.tryToFetchAggregatedPricingHistory(pageNumber, pageSize, data);
        return result?.data;
    },
);

export const tryToFetchDetailedPricingHistory = createAsyncThunk<PaginatedPricingHistoryDetails, IPaginationPayload>(
    'pricing-history/tryToFetchDetailedPricingHistory',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await PricingHistoryService.tryToFetchDetailedPricingHistory(pageNumber, pageSize, filters, data);
        return result?.data;
    },
);

export const pricingHistorySlice = createSlice({
    name: 'pricingHisotry',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAggregatedPricingHistory
            .addCase(tryToFetchAggregatedPricingHistory.pending, (state) => {
                state.pricingsAreLoading = true;
            })
            .addCase(tryToFetchAggregatedPricingHistory.rejected, (state) => {
                state.pricingsAreLoading = false;
            })
            .addCase(tryToFetchAggregatedPricingHistory.fulfilled, (state, action) => {
                state.pricingsAreLoading = false;
                state.pricingsHistory = action.payload;
            })

            // tryToFetchDetailedPricingHistory
            .addCase(tryToFetchDetailedPricingHistory.pending, (state) => {
                state.pricingIsLoading = true;
            })
            .addCase(tryToFetchDetailedPricingHistory.rejected, (state) => {
                state.pricingIsLoading = false;
            })
            .addCase(tryToFetchDetailedPricingHistory.fulfilled, (state, action) => {
                state.pricingIsLoading = false;
                state.pricingHistoryDetails = action.payload;
            })
    },
});

export default pricingHistorySlice.reducer;