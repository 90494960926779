import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';
import { IAgentsSchema } from '../../../../interfaces/agents/IAgentsSchema';
import { PaginatedAgents } from '../../../../interfaces/agents/paginatedStore.type';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';


const tryToFetchAgents = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
): Promise<AxiosResponse<PaginatedAgents>> => {
    const url = filters
        ? `${USERS_API_URL}/agents?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${USERS_API_URL}/agents?page=${pageNumber}&size=${pageSize}`;
    return brokersAxios.get<PaginatedAgents>(url);
};

const tryToAddAgent = (data: IAgentsSchema): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/agents`, data);
};

const tryToEnableAgent = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/agents/${id}/enable`);
};

const tryToDisableAgent = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/agents/${id}/disable`);
};

const tryToFetchSingleAgent = (id: string): Promise<AxiosResponse<ApiResponse<IAgentsSchema>>> => {
    return brokersAxios.get<ApiResponse<any>>(`${USERS_API_URL}/agents/${id}`);
};

const tryToDeleteAgent = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/agents/${id}/delete`);
};

const tryToAssignUserRole = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/users/assign/role`, data);
};

const tryToControlInventoryStatus = (agentId: string, dashboard: string, value: boolean): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/agents/${agentId}/dashboard/${dashboard}/${value}`);
};

const tryToAssignInventoryRole = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/users/assign/role`, data);
};

export const AgentsService = {
    tryToFetchAgents,
    tryToAddAgent,
    tryToEnableAgent,
    tryToDisableAgent,
    tryToFetchSingleAgent,
    tryToDeleteAgent,
    tryToAssignUserRole,
    tryToControlInventoryStatus,
    tryToAssignInventoryRole
};