import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddPostageFee,
    tryToEditPostageFee,
    tryToFetchSinglePostageFee
} from '../../../../store/brokers/admin/postage-fees/postageFeesSlice';
import { IPostageFees } from '../../../../interfaces/postage-fees/IPostageFees';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IPostageFeeActionsModal {
    markets?: any[];
    sectors?: any[];
    openActionsModal: boolean;
    postageFeeItemId?: string | null;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    marketId: string;
    assetTemplateId?: string;
    postageFee: number;
};

const PostageFeeActionsModal = ({
    openActionsModal,
    postageFeeItemId,
    markets,
    sectors,
    handleCloseModal,
    onSaveChanges,
}: IPostageFeeActionsModal) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.postageFees);
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const [selectedSector, setSelectedSector] = useState<any>();
    const [saveError, setSaveError] = useState<string>()

    const postageFeeLoading = useAppSelector(
        (state) => state.postageFees?.postageIsLoading,
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        setValue('marketId', postageFeeItemId ? state?.postageFee?.market?.id || '' : '');
        setValue('assetTemplateId', postageFeeItemId ? state?.postageFee?.assetTemplate?.id || '' : '');
        setValue(
            'postageFee',
            postageFeeItemId ? state?.postageFee?.postageFee || 0 : 0
        );
        if (postageFeeItemId) {
            const findMarket = markets && markets?.length > 0 && markets?.find((item) => item?._id === state?.postageFee?.market?.id)
            setSelectedMarket(findMarket)
            const findAssetTemplate = sectors && sectors?.length > 0 && sectors?.find((item) => item?._id === state?.postageFee?.assetTemplate?.id)
            setSelectedSector(findAssetTemplate)
        }
    }, [state.postageFee]);

    useEffect(() => {
        if (postageFeeItemId) {
            dispatch(tryToFetchSinglePostageFee(postageFeeItemId));
        }
    }, [postageFeeItemId]);

    const onCloseModal = () => {
        reset();
        setSelectedMarket(undefined)
        setSelectedSector(undefined)
        setSaveError(undefined)
        handleCloseModal();
    };

    const onSubmit = async (data: FormValues) => {
        if (!selectedMarket) {
            setSaveError('Please make sure you select market to continue')
            return;
        }
        const payload: IPostageFees = {
            ...data,
            postageFee: Number(data?.postageFee || 0)
        };
        try {
            if (postageFeeItemId) {
                payload._id = postageFeeItemId;
                await dispatch(tryToEditPostageFee(payload)).unwrap();
            } else {
                await dispatch(tryToAddPostageFee(payload)).unwrap();
            }
            onSaveChanges(
                'success',
                `Postage Fee successfully ${postageFeeItemId ? 'changed' : 'added'}.`,
            );
            reset();
            onCloseModal();
        } catch (error) {
            setSaveError(`${error}`);
        }
    };

    const onChangeSelectedMarket = (e: any) => {
        setSelectedMarket(
            e || undefined,
        );
        setValue('marketId', e?.value)
    };

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(
            e || undefined,
        );
        setValue('assetTemplateId', e?.value)
    };

    return (
        <Modal
            open={openActionsModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>Postage Fee</p>
                {saveError && <Error text={saveError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(postageFeeItemId && !postageFeeLoading) || !postageFeeItemId ? (
                        <div className='flex flex-col my-4'>
                            <SelectCheckbox
                                key={1}
                                name='Market'
                                dataQa={'market-selected'}
                                disabled={!!postageFeeItemId}
                                placeholder=' '
                                containerStyle='mb-1'
                                error={errors?.marketId?.message}
                                selectedOption={selectedMarket}
                                onChangeSelectedOption={onChangeSelectedMarket}
                                options={markets || []}
                            />
                            <SelectCheckbox
                                key={2}
                                name='Category'
                                placeholder=' '
                                dataQa={`${(selectedSector?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                                containerStyle='mb-1'
                                selectedOption={selectedSector}
                                onChangeSelectedOption={onChangeSelectedSector}
                                options={sectors || []}
                            />
                            <Input
                                label='Postage Fee'
                                type='text'
                                dataQa={'postage-fee'}
                                register={register('postageFee', {
                                    required: {
                                        message: 'Postage Fee is required',
                                        value: true,
                                    },
                                    pattern: {
                                        value: /^-?[0-9]\d*\.?\d*$/,
                                        message: 'Only numbers are accepted.'
                                    },
                                    validate: (value: number) => (Number(value) >= 0) || 'Postage fee should be a positive number'
                                })}
                                error={errors.postageFee?.message}
                            />
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    dataQa={'submit'}
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default PostageFeeActionsModal;
