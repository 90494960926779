import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';
import { IScrapingFees } from '../../../../interfaces/scraping-fees/IScrapingFees';

const tableColumns = ['Category', 'Sraping Fee', 'Actions']

interface IScrapingFeesTable {
    rows: IScrapingFees[];
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onDeleteScrapingFee: (id: string) => void;
    onEditScrapingFee: (id: string) => void;
}

const ScrapingFeesTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    rowsLoading,
    setPaginationState,
    onDeleteScrapingFee,
    onEditScrapingFee
}: IScrapingFeesTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IScrapingFees, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.assetTemplate?.displayName || ''}</td>
                                <td className='px-6 py-4'>{Number(row?.scrapingFee || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div data-qa={'edit-scraping-fee'} onClick={() => ((!accessControl || actions?.['Edit Scraping Fee']) && row?._id) && onEditScrapingFee(row?._id)}>
                                            <img title='Edit Scraping Fee' alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Scraping Fee']) ? 'cursor-pointer' : 'cursor-not-allwed'} mr-3`} />
                                        </div>
                                        <div data-qa={'delete-scraping-fee'} onClick={() => ((!accessControl || actions?.['Delete Scraping Fee']) && row?._id) && onDeleteScrapingFee(row?._id)}>
                                            <img title={'Delete Scraping Fee'} alt='delete market' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Scraping Fee']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default ScrapingFeesTable;