import { useAppSelector } from '../app/hooks';
import { useGetCurrentUserQuery, useUserNavigationQuery } from '../store/user/userApi';
import { readFromStorage } from '../utils/webStorage';


export const GuestGuard = (): boolean => {
    const token = readFromStorage('accessToken');
    if (token) {
        return false
    }
    else {
        return true
    }
};

export const AdminGuard = (): boolean => {
    const role = localStorage.getItem('roles')
    const { data }: any = !role && useGetCurrentUserQuery();
    const token = readFromStorage('accessToken');
    return token ? role ? localStorage.getItem('roles') === 'admins' : (data && data?.groups && data?.groups?.length) && Boolean(data?.groups?.includes('admins')) : false;
};

export const PartnerAgentGuard = (): boolean => {
    const role = localStorage.getItem('roles')
    const { data }: any = !role && useGetCurrentUserQuery();
    const token = readFromStorage('accessToken');
    return token ? role ? (role === 'partners' || role === 'partner' || role === 'agents' || role === 'agent') : (data && data?.groups && data?.groups?.length) ? Boolean(data?.groups?.includes('partners') || data?.groups?.includes('agents') || data?.groups?.includes('partner') || data?.groups?.includes('agent')) : false : false;
};

export const PartnerGuard = (): boolean => {
    const role = localStorage.getItem('roles')
    const { data }: any = !role && useGetCurrentUserQuery();
    const token = readFromStorage('accessToken');
    return token ? role ? (role === 'partners' || role === 'partner') : (data && data?.groups && data?.groups?.length) ? Boolean(data?.groups?.includes('partners') || Boolean(data?.groups?.includes('partner'))) : false : false;
};

export const PartnerRoutesGuard = (path: any): boolean => {
    const token = readFromStorage('accessToken');
    const dataRead = useUserNavigationQuery();
    const formatData = dataRead?.data
    const findParent = (formatData && formatData?.data && formatData?.data?.length > 0) ? formatData?.data?.find((item: any) => item?.path === path) : false
    if ((token ? localStorage.getItem('roles') === 'admins' : false) || !token) {
        return true
    }
    return !dataRead?.data?.data || !!findParent
};

export const PartnerChildRoutesGuard = (parentPath: any, childPath: any) => {
    const token = readFromStorage('accessToken');
    if ((token ? localStorage.getItem('roles') === 'admins' : false) || !token) {
        return true
    }
    const dataRead: any = useUserNavigationQuery();
    const formatData = dataRead?.data
    const findParent = (formatData?.data && formatData?.data?.length > 0) && formatData?.data?.find((item: any) => item?.path === parentPath)
    const findChild = findParent && findParent?.children && findParent?.children?.length > 0 && findParent?.children?.find((item: any) => item?.path === childPath)
    return !dataRead?.data?.data || !!findChild
}

export const PartnerChildRoutesGuardData = () => {
    const dataRead = useAppSelector(state => state?.partners?.partnerNavigation)
    return dataRead
}