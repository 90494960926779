import React from 'react';
import { useDropzone } from 'react-dropzone'
import Button from '../../../../shared/button';

interface IDropzoneComponent {
    index: number;
    title: string;
    type: string;
    fieldDetails?: any;
    formData?: any;
    onChangeFileUploaded: (file: any, type: string, index: number) => void;
}

const DropzoneComponent = ({
    index,
    title,
    type,
    fieldDetails,
    formData,
    onChangeFileUploaded
}: IDropzoneComponent) => {

    const { getRootProps, getInputProps } = useDropzone({ onDrop: (acceptedFiles) => onChangeFileUploaded(acceptedFiles?.[0], type, index) })

    return (
        <div
            key={index}
            className='flex flex-row items-center mb-4'
            data-index={'123'}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            <Button
                icon={
                    <img
                        alt='upload-file-icon'
                        src='/assets/shared/upload-file.svg'
                        className='w-[30px] object-contains pr-2'
                    />
                }
                className={
                    'btn-main !bg-[#202020] !text-white !py-1 !shadow-none flex flex-row items-center'
                }
                label={title}
            />
            {formData?.[index]?.[type] && formData?.[index]?.[type]?.name
                ? <p className={'ml-3'}>{formData?.[index]?.[type]?.name}</p>
                : fieldDetails?.details?.[index]?.[type] ? <img alt='Section Icon' src={fieldDetails?.details?.[index]?.[type]} className={'w-[40px] mx-3'} /> : <></>
            }
        </div>
    )
}

export default DropzoneComponent;