import React from 'react';
import List from './categories/List';


interface IInvoiceCategories {
    name: string,
    element: React.ReactNode
}

const invoiceCategories: IInvoiceCategories[] = [
    { name: 'List', element: <List /> },
]

const Invoices = () => {
    return (
        <div>
            <div className='mb-5'>
                <p className='page-title'>Invoices</p>
            </div>
            <div className='my-2'>
                {invoiceCategories[0]?.element}
            </div>
        </div>
    )
}

export default Invoices;