import React, { useEffect, useState } from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayloadWithId } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';


interface IWarehouseValuesTable {
    rows: Array<any>;
    warehouseFields: Array<any>;
    paginationData?: IPage;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayloadWithId) => void;
    onDeleteRequest: (id: string) => void;
    onEditItem: (id: string) => void;
}

const WarehouseValuesTable = ({
    rows,
    paginationData,
    warehouseFields,
    onDeleteRequest,
    setPaginationState,
    onEditItem
}: IWarehouseValuesTable) => {
    const [tableColumns, setTableColumns] = useState<any>([])

    useEffect(() => {
        if (warehouseFields && warehouseFields?.length > 0) {
            const tableColumnsFormat = warehouseFields?.map((field) => field?.name)
            tableColumnsFormat.push('Actions')
            setTableColumns(tableColumnsFormat)
        }
    }, [warehouseFields])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
        >
            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        {tableColumns?.length > 0 && tableColumns?.map((column: any, id: number) => {
                            return (
                                <td key={id} className='px-6 py-4'>{(row?.descriptor?.[column] !== true && row?.descriptor?.[column] !== false) ? row?.descriptor?.[column] : (row?.descriptor?.[column] ? 'True' : 'False')}</td>
                            )
                        })}
                        < td className='px-6 py-4'>
                            <div className='flex flex-row items-center justify-start'>
                                <div onClick={() => onEditItem(row?._id || '')}>
                                    <img title='Edit Item' alt='edit item' src={'/assets/table-actions/pen.svg'} className={'h-[14px] object-contain cursor-pointer mr-3'} />
                                </div>
                                <div onClick={() => onDeleteRequest(row?._id)}>
                                    <img title='Delete Item' alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={'h-[14px] object-contain cursor-pointer mr-1'} />
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })}
        </Table >
    )
}

export default WarehouseValuesTable;