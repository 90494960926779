import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddAssetTemplateFrame,
    tryToEditAssetTemplateFrame,
    tryToFetchSingleAssetTemplateFrame
} from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IAssetTemplateFrameModal {
    openModal: boolean;
    itemId: string;
    sectors?: any;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

const AssetTemplateFrameModal = ({
    openModal,
    itemId,
    sectors,
    handleCloseModal,
    onSaveChanges
}: IAssetTemplateFrameModal) => {
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [filesSelected, setFilesSelected] = useState<any>()
    const [existingFrames, setExistingFrames] = useState<any>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const frameLoading = useAppSelector((state) => state?.assetsTemplates.assetTemplateFrameIsLoading)
    const frameDetails = useAppSelector((state) => state?.assetsTemplates?.assetTemplateFrame)

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.map((file: any) => {
            const reader = new FileReader();
            reader.onload = async (event: any) => {
                await setFilesSelected((prevState: any) => {
                    return [...prevState || [], file]
                })
            };
            reader.readAsDataURL(file);
            return file
        });
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true, })

    useEffect(() => {
        if (itemId && frameDetails && sectors) {
            const findSector = frameDetails?.assetTemplateId && sectors && sectors?.length > 0 && sectors?.find((item: any) => item?.value === frameDetails?.assetTemplateId)
            setSelectedSector(findSector || undefined)
            setExistingFrames(frameDetails?.frames || [])
        } else {
            setSelectedSector(undefined)
            setFilesSelected(undefined)
        }
    }, [frameDetails, sectors])

    useEffect(() => {
        if (itemId) {
            dispatch(tryToFetchSingleAssetTemplateFrame(itemId)).unwrap()
        }
    }, [itemId])

    const onCloseModal = () => {
        handleCloseModal();
        setSaveError(undefined)
        setSelectedSector(undefined)
        setFilesSelected(undefined)
        setLoadingSubmit(false)
    }

    const onDeleteImage = (file: any) => {
        const filterOutFile = (filesSelected && filesSelected?.length > 0) && filesSelected?.filter((item: any) => item !== file)
        setFilesSelected(filterOutFile || undefined)
    }

    const onDeleteExistingImage = (file: any) => {
        const filterOutFile = (existingFrames && existingFrames?.length > 0) && existingFrames?.filter((item: any) => item !== file)
        setExistingFrames(filterOutFile || undefined)
    }

    const onSubmit = async () => {
        setLoadingSubmit(true)
        if ((!filesSelected || filesSelected?.length < 1) && (!existingFrames || existingFrames?.length < 1)) {
            setSaveError('Please upload at least one image')
            setLoadingSubmit(false)
            return
        }
        if (!selectedSector) {
            setSaveError('Please select Category to continue')
            setLoadingSubmit(false)
            return
        }
        try {
            const dataForm: any = new FormData()
            dataForm.append('assetTemplateId', selectedSector?.value)
            filesSelected && filesSelected?.length > 0 && filesSelected?.map((file: any, index: number) => {
                dataForm.append(`file${index + 1}`, file)
                return file
            })
            if (itemId && existingFrames) {
                dataForm.append('oldImages', JSON.stringify(existingFrames))
            }
            if (itemId) {
                await dispatch(tryToEditAssetTemplateFrame({ id: itemId, data: dataForm})).unwrap()
            } else {
                await dispatch(tryToAddAssetTemplateFrame(dataForm)).unwrap()
            }
            onSaveChanges('success', 'Catalog Frames successfully saved!')
            onCloseModal()
        } catch (err) {
            setSaveError(`${err}`)
            setLoadingSubmit(false)
        }
    }

    const onChangeSelectedSector = (value: any, type?: string) => {
        setSelectedSector(value)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle={'!min-w-[700px]'}
            dataQa={'close-button'}>
            <div className={'p-2 min-w-[550px] mx-10'}>
                <p className='mb-4 font-semibold text-sm'>Catalog Frames</p>
                <div className='mt-6 mb-10'>
                    <p className='mb-4'>Submit minimum of 1 image.</p>
                    {saveError && <Error text={saveError} />}
                    <SelectCheckbox
                        name={'Category'}
                        placeholder=' '
                        dataQa={'category'}
                        disabled={frameLoading || !!itemId}
                        isLoading={frameLoading}
                        containerStyle='mb-1 max-w-[90%] z-50'
                        options={sectors || []}
                        selectedOption={selectedSector}
                        onChangeSelectedOption={onChangeSelectedSector}
                    />
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button
                            icon={<img src='/assets/shared/upload-file.svg' className='w-[30px] object-contains pr-2' />}
                            className={'btn-main !py-2 !shadow-none flex flex-row items-center'}
                            label={'Select File'}
                            dataQa={'select-file'}
                        />
                    </div>
                    <div className='flex flex-rows items-center flex-wrap mt-6'>
                        {filesSelected && filesSelected?.length > 0 && filesSelected?.map((item: any, key: number) => {
                            return (
                                <div
                                    key={key}
                                    onClick={() => onDeleteImage(item)}
                                    className={'relative flex flex-col items-center mr-4 z-70'}>
                                    <div data-qa={'delete-image'} className='absolute top-[-7px] right-[-7px] cursor-pointer z-40'>
                                        <img src={'/assets/shared/cancel.svg'} className='max-w-[15px] object-contain z-70' />
                                    </div>
                                    <div className='border border-[#000] p-1 z-0'>
                                        <img src={item && URL.createObjectURL(item)} className='max-w-[70px] object-contain' />
                                    </div>
                                </div>
                            )
                        })}
                        {existingFrames && existingFrames?.length > 0 && existingFrames?.map((item: any, key: number) => {
                            return (
                                <div
                                    key={key}
                                    onClick={() => onDeleteExistingImage(item)}
                                    className={'relative flex flex-col items-center mr-4 z-70'}>
                                    <div data-qa={'delete-image'} className='absolute top-[-7px] right-[-7px] cursor-pointer z-40'>
                                        <img src={'/assets/shared/cancel.svg'} className='max-w-[15px] object-contain z-70' />
                                    </div>
                                    <div className='border border-[#000] p-1 z-0'>
                                        <img src={item?.url} className='max-w-[70px] object-contain' />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='flex flex-row justify-center mb-2'>
                    <Button
                        label='Submit'
                        dataQa={'submit-button'}
                        className={`${loadingSubmit ? 'btn-main-disable' : 'btn-main'} !shadow-none min-w-[120px]`}
                        onClickButton={() => !loadingSubmit && onSubmit()}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default AssetTemplateFrameModal;