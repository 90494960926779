import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayloadWithId } from '../../../../interfaces/shared/IPaginationPayload';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import { IWarehouseField } from '../../../../interfaces/warehouses/IWarehouseField';
import { IWarehousesListState } from '../../../../interfaces/warehouses/IWarehouseList';
import { IWarehouseLocation } from '../../../../interfaces/warehouses/IWarehouseLocation';
import { WarehousesService } from './warehousesApi';

const initialState: IWarehousesListState = {
    warehousesAreLoading: false,
    warehouseIsLoading: false,
    fieldIsLoading: false,
    fieldsAreLoading: false,
    warehouseLocationsAreLoading: false,
    warehouseLocationIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchWarehouses = createAsyncThunk<ApiResponse<IWarehouse[]>, any>(
    'warehouse/tryToFetchWarehouses',
    async (showAll) => {
        const result = await WarehousesService.tryToFetchWarehouses(showAll);
        return result?.data;
    },
);

export const tryToFetchSingleWarehouse = createAsyncThunk<
    any,
    string
>('warehouse/tryToFetchSingleWarehouse', async (warehouseId: string, { rejectWithValue }) => {
    try {
        const result = await WarehousesService.tryToFetchSingleWarehouse(warehouseId);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToEditWarehouse = createAsyncThunk<ApiResponse<IWarehouse>, IWarehouse>(
    'warehouse/tryToEditWarehouse',
    async (data: IWarehouse, { rejectWithValue }) => {
        try {

            const result = await WarehousesService.tryToEditWarehouse(data?._id || '', data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEnableWarehouse = createAsyncThunk<ApiResponse<IWarehouse>, string>(
    'warehouse/tryToEnableWarehouse',
    async (id, { rejectWithValue }) => {
        try {
            const result = await WarehousesService.tryToEnableWarehouse(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDisableWarehouse = createAsyncThunk<ApiResponse<IWarehouse>, string>(
    'warehouse/tryToDisableWarehouse',
    async (id, { rejectWithValue }) => {
        try {
            const result = await WarehousesService.tryToDisableWarehouse(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAddWarehouse = createAsyncThunk<ApiResponse<any>, IWarehouse>(
    'warehouse/tryToAddWarehouse',
    async (data, { rejectWithValue }) => {
        try {
            const result = await WarehousesService.tryToAddWarehouse(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAddBillingAddress = createAsyncThunk<ApiResponse<any>, any>(
    'warehouse/tryToAddBillingAddress',
    async (data, { rejectWithValue }) => {
        try {
            const result = await WarehousesService.tryToAddBillingAddress(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditWarehouseField = createAsyncThunk<
    ApiResponse<IWarehouse>,
    any
>('warehouse/tryToEditWarehouseField', async ({ warehouseId, fieldId, data }, { rejectWithValue }) => {
    try {
        const result = await WarehousesService.tryToEditWarehouseField(warehouseId, fieldId, data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToFetchWarehouseField = createAsyncThunk<
    any,
    { warehouseId: string, fieldId: string }
>('warehouse/tryToFetchWarehouseField', async ({ warehouseId, fieldId }, { rejectWithValue }) => {
    try {
        const result = await WarehousesService.tryToFetchWarehouseField(warehouseId, fieldId);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToAddWarehouseFields = createAsyncThunk<
    ApiResponse<IWarehouse>,
    { warehouseId: string, data: any }
>('warehouse/tryToAddWarehouseFields', async ({ warehouseId, data }, { rejectWithValue }) => {
    try {
        const result = await WarehousesService.tryToAddWarehouseFields(warehouseId, data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToDeleteWarehouseField = createAsyncThunk<ApiResponse<IWarehouseField>, any>(
    'warehouse/tryToDeleteWarehouseField',
    async ({ warehouseId, fieldId }, { rejectWithValue }) => {
        try {
            const results: any = await WarehousesService.tryToDeleteWarehouseField(warehouseId, fieldId);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchWarehouseLocations = createAsyncThunk<any, IPaginationPayloadWithId>(
    'warehouse/tryToFetchWarehouseLocations', async ({ pageNumber, pageSize, filters, id }) => {
        const result = await WarehousesService.tryToFetchWarehouseLocations(pageNumber, pageSize, id, filters);
        return result.data;
    }
);

export const tryToFetchWarehouseSingleLocation = createAsyncThunk<any, { warehouseId: string, locationId: string }>(
    'warehouse/tryToFetchWarehouseSingleLocation', async ({ warehouseId, locationId }, { rejectWithValue }) => {
        try {
            const result = await WarehousesService.tryToFetchWarehouseSingleLocation(warehouseId, locationId);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToAddWarehouseLocation = createAsyncThunk<ApiResponse<IWarehouseLocation>, any>(
    'warehouse/tryToAddWarehouseLocation', async (data, { rejectWithValue }) => {
        try {
            const result = await WarehousesService.tryToAddWarehouseLocation(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToEditWarehouseLocation = createAsyncThunk<ApiResponse<IWarehouseLocation>, { locationId: string, data: any }>(
    'warehouse/tryToEditWarehouseLocation', async ({ locationId, data }, { rejectWithValue }) => {
        try {
            const result = await WarehousesService.tryToEditWarehouseLocation(locationId, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDeleteWarehouseLocation = createAsyncThunk<ApiResponse<IWarehouseField>, any>(
    'warehouse/tryToDeleteWarehouseLocation',
    async ({ warehouseId, locationId }, { rejectWithValue }) => {
        try {
            const results: any = await WarehousesService.tryToDeleteWarehouseLocation(warehouseId, locationId);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToGetWarehouseFields = createAsyncThunk<any, any>(
    'warehouse/tryToGetWarehouseFields',
    async ({ warehouseId, data }) => {
        const result = await WarehousesService.tryToGetWarehouseFields(warehouseId, data);
        return result.data;
    }
)

export const tryToSortWarehouseFields = createAsyncThunk<ApiResponse<any>, { warehouseId: string, sortedIds: Array<string> }>(
    'assetTemplates/tryToSortWarehouseFields',
    async (data: any, { rejectWithValue }) => {
        try {
            const formatData = { sortedIds: data?.sortedIds }
            const result = await WarehousesService.tryToSortWarehouseFields(data?.warehouseId || '', formatData);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchAllWarehouseSections = createAsyncThunk<any, any>(
    'warehouseLocations/tryToFetchAllWarehouseSections',
    async ({warehouseId, type}) => {
        const result = await WarehousesService.tryToFetchAllWarehouseSections(warehouseId, type);
        return result?.data?.data;
    },
);

export const tryToFetchSectionLocations = createAsyncThunk<any, any>(
    'warehouseLocations/tryToFetchSectionLocations',
    async ({ barcode, data }) => {
        const result = await WarehousesService.tryToFetchSectionLocations(barcode, data);
        return result?.data?.data;
    }
);

export const warehouseSlice = createSlice({
    name: 'warehouses',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchWarehouses
            .addCase(tryToFetchWarehouses.pending, (state) => {
                state.warehousesAreLoading = true;
            })
            .addCase(tryToFetchWarehouses.rejected, (state) => {
                state.warehousesAreLoading = false;
            })
            .addCase(tryToFetchWarehouses.fulfilled, (state, action) => {
                state.warehousesAreLoading = false;
                state.warehouses = action.payload?.data;
            })

            // tryToFetchSingleWarehouse
            .addCase(tryToFetchSingleWarehouse.pending, (state) => {
                state.warehouseIsLoading = true;
            })
            .addCase(tryToFetchSingleWarehouse.rejected, (state) => {
                state.warehouseIsLoading = false;
            })
            .addCase(tryToFetchSingleWarehouse.fulfilled, (state, action) => {
                state.warehouseIsLoading = false;
                state.warehouse = action.payload?.data;
            })

            // tryToAddWarehouse
            .addCase(tryToAddWarehouse.pending, (state) => {
                state.warehouseIsLoading = true;
            })
            .addCase(tryToAddWarehouse.rejected, (state) => {
                state.warehouseIsLoading = false;
            })
            .addCase(tryToAddWarehouse.fulfilled, (state, action) => {
                state.warehouseIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.warehouses) state.warehouses = [...state.warehouses || [], data];
            })

            // tryToEditWarehouse
            .addCase(tryToEditWarehouse.pending, (state) => {
                state.warehouseIsLoading = true;
            })
            .addCase(tryToEditWarehouse.rejected, (state) => {
                state.warehouseIsLoading = false;
            })
            .addCase(tryToEditWarehouse.fulfilled, (state, action) => {
                state.warehouseIsLoading = false;
                state.warehouses =
                    { ...state }.warehouses?.map((warehouse) => {
                        if (warehouse?._id === action.meta?.arg?._id) {
                            return {
                                ...action.payload?.data
                            }
                        } else {
                            return warehouse
                        }
                    }) || [];
            })

            // tryToDisableWarehouse
            .addCase(tryToDisableWarehouse.pending, (state) => {
                state.warehouseIsLoading = true;
            })
            .addCase(tryToDisableWarehouse.rejected, (state) => {
                state.warehouseIsLoading = false;
            })
            .addCase(tryToDisableWarehouse.fulfilled, (state, action) => {
                state.warehouseIsLoading = false;
                state.requestStatus = 'success';
                if (state.warehouses)
                    state.warehouses =
                        { ...state }.warehouses?.filter((warehouse) => warehouse?._id !== action.meta?.arg) || [];
            })

            // tryToEnableWarehouse
            .addCase(tryToEnableWarehouse.pending, (state) => {
                state.warehouseIsLoading = true;
            })
            .addCase(tryToEnableWarehouse.rejected, (state) => {
                state.warehouseIsLoading = false;
            })
            .addCase(tryToEnableWarehouse.fulfilled, (state, action) => {
                state.warehouseIsLoading = false;
                state.requestStatus = 'success';
                if (state.warehouses)
                    state.warehouses =
                        { ...state }.warehouses?.map((warehouse) => {
                            if (warehouse?._id === action.meta?.arg) {
                                return {
                                    ...warehouse,
                                    enabled: true
                                }
                            } else {
                                return warehouse
                            }
                        }) || [];
            })

            // tryToFetchWarehouseField
            .addCase(tryToFetchWarehouseField.pending, (state) => {
                state.fieldIsLoading = true;
            })
            .addCase(tryToFetchWarehouseField.rejected, (state) => {
                state.fieldIsLoading = false;
            })
            .addCase(tryToFetchWarehouseField.fulfilled, (state, action) => {
                state.fieldIsLoading = false;
                state.warehouseField = action.payload?.data;
            })

            // tryToAddWarehouseFields
            .addCase(tryToAddWarehouseFields.pending, (state) => {
                state.fieldIsLoading = true;
            })
            .addCase(tryToAddWarehouseFields.rejected, (state) => {
                state.fieldIsLoading = false;
            })
            .addCase(tryToAddWarehouseFields.fulfilled, (state, action) => {
                state.fieldIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload?.data || action.meta.arg;
                if (state.warehouse) state.warehouse = data;
            })

            // tryToEditWarehouseField
            .addCase(tryToEditWarehouseField.pending, (state) => {
                state.fieldIsLoading = true;
            })
            .addCase(tryToEditWarehouseField.rejected, (state) => {
                state.fieldIsLoading = false;
            })
            .addCase(tryToEditWarehouseField.fulfilled, (state, action) => {
                state.fieldIsLoading = false;
                if (state.warehouse) {
                    state.warehouse = action.payload?.data
                }
            })

            // tryToDeleteWarehouseField
            .addCase(tryToDeleteWarehouseField.pending, (state) => {
                state.fieldIsLoading = true;
            })
            .addCase(tryToDeleteWarehouseField.rejected, (state) => {
                state.fieldIsLoading = false;
            })
            .addCase(tryToDeleteWarehouseField.fulfilled, (state, action) => {
                state.fieldIsLoading = false;
                state.requestStatus = 'success';
                if (state.warehouse)
                    state.warehouse.locations =
                        { ...state }.warehouse?.locations?.filter((field) => field?.id !== action?.meta?.arg?.fieldId) || [];
            })

            // tryToFetchWarehouseLocations
            .addCase(tryToFetchWarehouseLocations.pending, (state) => {
                state.warehouseLocationsAreLoading = true;
            })
            .addCase(tryToFetchWarehouseLocations.rejected, (state) => {
                state.warehouseLocationsAreLoading = false;
            })
            .addCase(tryToFetchWarehouseLocations.fulfilled, (state, action) => {
                state.warehouseLocationsAreLoading = false;
                state.warehouseLocations = action.payload;
            })

            // tryToFetchWarehouseSingleLocation
            .addCase(tryToFetchWarehouseSingleLocation.pending, (state) => {
                state.warehouseLocationIsLoading = true;
            })
            .addCase(tryToFetchWarehouseSingleLocation.rejected, (state) => {
                state.warehouseLocationIsLoading = false;
            })
            .addCase(tryToFetchWarehouseSingleLocation.fulfilled, (state, action) => {
                state.warehouseLocationIsLoading = false;
                state.warehouseLocationDetails = action.payload?.data;
            })

            // tryToAddWarehouseLocation
            .addCase(tryToAddWarehouseLocation.pending, (state) => {
                state.warehouseLocationIsLoading = true;
            })
            .addCase(tryToAddWarehouseLocation.rejected, (state) => {
                state.warehouseLocationIsLoading = false;
            })
            .addCase(tryToAddWarehouseLocation.fulfilled, (state, action) => {
                state.warehouseLocationIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.warehouseLocations?.data)
                    state.warehouseLocations.data.elements = [
                        ...state.warehouseLocations?.data.elements || [],
                        data,
                    ];
            })

            // tryToEditWarehouseLocation
            .addCase(tryToEditWarehouseLocation.pending, (state) => {
                state.warehouseLocationIsLoading = true;
            })
            .addCase(tryToEditWarehouseLocation.rejected, (state) => {
                state.warehouseLocationIsLoading = false;
            })
            .addCase(tryToEditWarehouseLocation.fulfilled, (state, action) => {
                state.warehouseLocationIsLoading = false;
                if (state.warehouseLocations?.data.elements)
                    state.warehouseLocations.data.elements =
                        { ...state }.warehouseLocations?.data.elements.map((location) => {
                            if (location?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return location;
                            }
                        }) || [];
                state.warehouseLocationDetails = action.payload.data;
            })

            // tryToDeleteWarehouseLocation
            .addCase(tryToDeleteWarehouseLocation.pending, (state) => {
                state.warehouseLocationIsLoading = true;
            })
            .addCase(tryToDeleteWarehouseLocation.rejected, (state) => {
                state.warehouseLocationIsLoading = false;
            })
            .addCase(tryToDeleteWarehouseLocation.fulfilled, (state, action) => {
                state.warehouseLocationIsLoading = false;
                state.requestStatus = 'success';
                if (state.warehouseLocations?.data.elements)
                    state.warehouseLocations.data.elements =
                        { ...state }.warehouseLocations?.data.elements.filter(
                            (location) => location?._id !== action.meta.arg.locationId,
                        ) || [];
            })

            // tryToGetWarehouseFields
            .addCase(tryToGetWarehouseFields.pending, (state) => {
                state.fieldsAreLoading = true;
            })
            .addCase(tryToGetWarehouseFields.rejected, (state) => {
                state.fieldsAreLoading = false;
            })
            .addCase(tryToGetWarehouseFields.fulfilled, (state, action) => {
                state.fieldsAreLoading = false;
                state.warehouseFields = action.payload?.data;
            })
    },
});

export default warehouseSlice.reducer;