import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { AxiosError } from 'axios';
import { IScrapingFeesListState } from '../../../../interfaces/scraping-fees/IScrapingFeesList';
import { ScrapingFeesService } from './scrapingFeesApi';
import { PaginatedScrapingFees } from '../../../../interfaces/scraping-fees/paginatedStore.type';
import { IScrapingFees } from '../../../../interfaces/scraping-fees/IScrapingFees';
import { IScrapingFeesSchema } from '../../../../interfaces/scraping-fees/IScrapingFeesSchema';

const initialState: IScrapingFeesListState = {
    scrapingsAreLoading: false,
    scrapingIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchScrapingFees = createAsyncThunk<
    PaginatedScrapingFees,
    IPaginationPayload
>('postage-fees/tryToFetchScrapingFees', async ({ pageNumber, pageSize, data }) => {
    const result = await ScrapingFeesService.tryToFetchScrapingFees(
        pageNumber,
        pageSize,
        data,
    );
    return result?.data;
});

export const tryToFetchSingleScrapingFee = createAsyncThunk<IScrapingFees, string>(
    'postage-fees/tryToFetchSingleScrapingFee',
    async (id: string) => {
        const result = await ScrapingFeesService.tryToFetchSingleScrapingFee(id);
        return result?.data;
    },
);

export const tryToDownloadScrapingFees = createAsyncThunk<IScrapingFees, any>(
    'postage-fees/tryToDownloadScrapingFees',
    async () => {
        const result = await ScrapingFeesService.tryToDownloadScrapingFees();
        return result?.data;
    },
);

export const tryToImportScrapingFees = createAsyncThunk<ApiResponse<any>, any>(
    'postage-fees/tryToImportScrapingFees',
    async (data, { rejectWithValue }) => {
        try {
            const result = await ScrapingFeesService.tryToImportScrapingFees(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditScrapingFee = createAsyncThunk<
    ApiResponse<IScrapingFeesSchema>,
    IScrapingFees
>('postage-fees/tryToEditScrapingFee', async (data: IScrapingFees, { rejectWithValue }) => {
    try {
        const result = await ScrapingFeesService.tryToEditScrapingFee(
            data._id || '',
            data,
        );
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToAddScrapingFee = createAsyncThunk<
    ApiResponse<IScrapingFeesSchema>,
    IScrapingFees
>('postage-fees/tryToAddScrapingFee', async (data: IScrapingFees, { rejectWithValue }) => {
    try {
        const result = await ScrapingFeesService.tryToAddScrapingFee(data);
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const tryToEnableScrapingFee = createAsyncThunk<IScrapingFees, string>(
    'postage-fees/tryToEnableScrapingFee',
    async (id: string) => {
        const result = await ScrapingFeesService.tryToEnableScrapingFee(id);
        return result?.data;
    },
);

export const tryToDeleteScrapingFee = createAsyncThunk<
    ApiResponse<IScrapingFeesSchema>,
    string
>('postage-fees/tryToDeleteScrapingFee', async (id, { rejectWithValue }) => {
    try {
        const results = await ScrapingFeesService.tryToDeleteScrapingFee(id);
        return results.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const scrapingFeesSlice = createSlice({
    name: 'scrapingFees',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchScrapingFees
            .addCase(tryToFetchScrapingFees.pending, (state) => {
                state.scrapingsAreLoading = true;
            })
            .addCase(tryToFetchScrapingFees.rejected, (state) => {
                state.scrapingsAreLoading = false;
            })
            .addCase(tryToFetchScrapingFees.fulfilled, (state, action) => {
                state.scrapingsAreLoading = false;
                state.scrapingFees = action.payload;
            })

            // tryToAddScrapingFee
            .addCase(tryToAddScrapingFee.pending, (state) => {
                state.scrapingIsLoading = true;
            })
            .addCase(tryToAddScrapingFee.rejected, (state) => {
                state.scrapingIsLoading = false;
            })
            .addCase(tryToAddScrapingFee.fulfilled, (state, action) => {
                state.scrapingIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.scrapingFees?.data)
                    state.scrapingFees.data.elements = [
                        ...state.scrapingFees?.data.elements,
                        data,
                    ];
            })
            // tryToImportScrapingFee
            // .addCase(tryToImportScrapingFees.pending, (state) => {
            //     state.scrapingIsLoading = true;
            // })
            // .addCase(tryToImportScrapingFees.rejected, (state) => {
            //     state.scrapingIsLoading = false;
            // })
            // .addCase(tryToImportScrapingFees.fulfilled, (state, action) => {
            //     state.scrapingIsLoading = false;
            //     state.requestStatus = 'success';
            //     const data = action.payload.data || action.meta.arg;
            //     if (state.scrapingFees?.data)
            //         state.scrapingFees.data.elements = [
            //             ...state.scrapingFees?.data.elements,
            //             data,
            //         ];
            // })

            // tryToDeleteScrapingFee
            .addCase(tryToDeleteScrapingFee.pending, (state) => {
                state.scrapingIsLoading = true;
            })
            .addCase(tryToDeleteScrapingFee.rejected, (state) => {
                state.scrapingIsLoading = false;
            })
            .addCase(tryToDeleteScrapingFee.fulfilled, (state, action) => {
                state.scrapingIsLoading = false;
                state.requestStatus = 'success';
                if (state.scrapingFees?.data.elements)
                    state.scrapingFees.data.elements =
                        { ...state }.scrapingFees?.data.elements.filter(
                            (scrapingFee) =>
                                scrapingFee?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToEnableScrapingFee
            .addCase(tryToEnableScrapingFee.pending, (state) => {
                state.scrapingIsLoading = true;
            })
            .addCase(tryToEnableScrapingFee.rejected, (state) => {
                state.scrapingIsLoading = false;
            })
            .addCase(tryToEnableScrapingFee.fulfilled, (state, action) => {
                state.scrapingIsLoading = false;
                if (state.scrapingFees?.data.elements)
                    state.scrapingFees.data.elements =
                        { ...state }.scrapingFees?.data.elements.map((scrapingFee) => {
                            if (scrapingFee?._id === action.meta?.arg) {
                                return {
                                    ...scrapingFee,
                                    enabled: true
                                };
                            } else {
                                return scrapingFee;
                            }
                        }) || [];
                state.scrapingFee = action.payload;
            })

            // tryToEditScrapingFee
            .addCase(tryToEditScrapingFee.pending, (state) => {
                state.scrapingIsLoading = true;
            })
            .addCase(tryToEditScrapingFee.rejected, (state) => {
                state.scrapingIsLoading = false;
            })
            .addCase(tryToEditScrapingFee.fulfilled, (state, action) => {
                state.scrapingIsLoading = false;
                if (state.scrapingFees?.data.elements)
                    state.scrapingFees.data.elements =
                        { ...state }.scrapingFees?.data.elements.map((scrapingFee) => {
                            if (scrapingFee?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return scrapingFee;
                            }
                        }) || [];
                state.scrapingFee = action.payload.data;
            })

            // tryToFetchSingleScrapingFee
            .addCase(tryToFetchSingleScrapingFee.pending, (state) => {
                state.scrapingIsLoading = true;
            })
            .addCase(tryToFetchSingleScrapingFee.rejected, (state) => {
                state.scrapingIsLoading = false;
            })
            .addCase(tryToFetchSingleScrapingFee.fulfilled, (state, action) => {
                state.scrapingIsLoading = false;
                state.scrapingFee = action.payload;
            });
    },
});

export default scrapingFeesSlice.reducer;
