import React from 'react';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import Table from '../../../../shared/table';

const tableColumns = ['Name', 'Path', 'Endpoints', 'Actions', 'Edit']

interface IPageDetailsTable {
    rows?: Array<any>;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    onAddAction: (id: string) => void;
    onEditChild: (id: string) => void;
    onDeleteChild: (id: string) => void;
}

const PageDetailsTable = ({
    rows,
    accessControl,
    actions,
    onAddAction,
    onEditChild,
    onDeleteChild,
}: IPageDetailsTable) => {
    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
        >
            {(rows && rows?.length > 0) && rows?.map((row: any, idx: number) => {
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?.name}</td>
                        <td className='px-6 py-4'>{row?.path}</td>
                        <td className='px-6 py-4 '>
                            <div className='grid'>
                                {
                                    row?.endpoints && row?.endpoints?.map((item: any, idx: string) => {
                                        return <p key={idx}>{item}</p>
                                    }
                                    )}
                            </div>
                        </td>
                        <td className='px-6 py-4'>
                            <div className='grid'>
                                {
                                    row?.actions && Object.keys(row?.actions).map((actionKey, idx) => {
                                        return <p key={idx}>{actionKey}</p>
                                    })
                                }
                            </div>
                        </td>
                        <td className='px-6 py-4'>
                            <div className='flex flex-row items-center'>
                                <div
                                    data-qa={'add-new-action'}
                                    className={`flex flex-row items-center ${(!accessControl || actions?.['Add Action']) ? 'cursor-pointer' : ''} mr-3`}
                                    onClick={() => ((!accessControl || actions?.['Add Action']) && row?._id) && onAddAction(row?._id)}>
                                    <img title={'Add new Action'} alt='Add Action' src={'/assets/shared/add-new.svg'} className={`h-[20px] object-contain`} />
                                    {/* <span className=''>Add New Action</span> */}
                                </div>
                                <div data-qa={'edit-page-child'} onClick={() => ((!accessControl || actions?.['Edit Page Child']) && row?._id) && onEditChild(row?._id)}>
                                    <img title={'Edit Page Child'} alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Page Child']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                </div>
                                <div data-qa={'delete-page-child'} onClick={() => ((!accessControl || actions?.['Delete Page Child']) && row?._id) && onDeleteChild(row?._id)}>
                                    <img title={'Delete Page Child'} alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Page Child']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default PageDetailsTable;