import React, { useState } from 'react';
import { AdminGuard } from '../../../../guards/RolesGuard';
import { PRICING_TYPES } from '../../../../utils/constants/pricing-types';
import MultiplePriceColumnsComp from '../details/MultiplePriceColumnsComp';
import FieldsMappingTable from '../tables/FieldsMappingTable';
import GradeDetailsModal from '../modals/GradeDetailsModal';
import Input from '../../../../shared/input';
import Button from '../../../../shared/button';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IMappingGeneralStep {
    id?: any;
    copyId?: any;
    name?: any;
    categories?: any;
    partners?: any;
    selectedCategory?: any;
    selectedType?: any;
    newColumns?: any;
    multiplePricingColumns: boolean;
    getRootProps: any;
    getInputProps: any;
    loading?: boolean;
    isDragActive: boolean;
    fileSelected?: any;
    selectedPartner?: any;
    categoryFields?: any;
    mappingFields?: any;
    mappingFieldsErrors?: any;
    priceMappingColumns?: any;
    categoryValues?: any;
    loadingOptions?: any;
    filteredFields?: any;
    onClickPriceMultipleColumns: () => void;
    onChangeMappingName: (value: string | null | undefined, type?: string) => void;
    onSelectMappingCategory: (value: any, type?: string) => void;
    onSelectMappingType: (value: any, type?: string) => void;
    onChangeFieldSelected: (value: any, type?: string) => void;
    onChangeSelectedPriceFileColumn: (value: any, type?: string, index?: any) => void;
    onChangeSelectedFieldValue: (value: any, type?: string, index?: any) => void;
    onClearMappedColumns: () => void;
    onAddingNewPricingColumn: () => void;
    onDeletePricingIndexItem: (index: any) => void;
    onChangeSearchFieldValues: (e: any, type?: string, index?: any) => void;
    onMenuCloseAfterSearch: (type?: string) => void;
    onContinue: () => void;
    onClickMultiQuantity: (index: any) => void;
    onChangeSelectedPartner: (value: any, type?: string) => void;
}

const MappingGeneralStep = ({
    id,
    copyId,
    name,
    categories,
    partners,
    selectedCategory,
    selectedPartner,
    newColumns,
    multiplePricingColumns,
    getRootProps,
    selectedType,
    getInputProps,
    fileSelected,
    categoryFields,
    mappingFields,
    mappingFieldsErrors,
    priceMappingColumns,
    categoryValues,
    loadingOptions,
    filteredFields,
    loading,
    onClickPriceMultipleColumns,
    onChangeSelectedPartner,
    onChangeMappingName,
    onSelectMappingCategory,
    onSelectMappingType,
    onChangeFieldSelected,
    onChangeSelectedPriceFileColumn,
    onChangeSelectedFieldValue,
    onClearMappedColumns,
    onAddingNewPricingColumn,
    onDeletePricingIndexItem,
    onChangeSearchFieldValues,
    onMenuCloseAfterSearch,
    onClickMultiQuantity,
    onContinue
}: IMappingGeneralStep) => {
    const isAdmin = AdminGuard()
    const [showGradeModal, setShowGradeModal] = useState<boolean>(false)
    const newColumnsFormated = newColumns && newColumns && newColumns?.map((column: any) => ({ ...column, label: column?.name, value: column?.name }))

    const onToggleGradeModal = () => {
        setShowGradeModal(!showGradeModal)
    }

    return (
        <div className='my-10'>
            <div className='grid grid-cols-3 gap-4'>
                <Input
                    placeholder=' '
                    label={'Mapping Name'}
                    dataQa={'mapping-name'}
                    onChangeInput={onChangeMappingName}
                    inputUniqueName={'name'}
                    inputStyle={'min-w-[350px] max-w-[350px]'}
                    showValue={true}
                    inputValue={name}
                />
                {isAdmin &&
                    <SelectCheckbox
                        name={'Broker'}
                        placeholder={' '}
                        dataQa={selectedPartner?.label === undefined ? 'broker' : selectedPartner?.label.toLowerCase()}
                        containerStyle='min-w-[350px] max-w-[350px]'
                        options={partners || []}
                        uniqueName={'partner'}
                        selectedOption={selectedPartner}
                        onChangeSelectedOption={onChangeSelectedPartner}
                    />
                }
                <SelectCheckbox
                    name={'Category'}
                    placeholder={' '}
                    dataQa={selectedCategory?.label === undefined ? 'category-to-select' : selectedCategory?.label.toLowerCase()}
                    containerStyle='min-w-[350px] max-w-[350px]'
                    options={categories || []}
                    uniqueName={'partner'}
                    selectedOption={selectedCategory}
                    onChangeSelectedOption={onSelectMappingCategory}
                />
                <SelectCheckbox
                    name={'Type'}
                    placeholder={' '}
                    dataQa={selectedType?.label === undefined ? 'type-to-select' : selectedType?.label.toLowerCase()}
                    containerStyle='min-w-[350px] max-w-[350px]'
                    options={PRICING_TYPES || []}
                    uniqueName={'type'}
                    selectedOption={selectedType}
                    onChangeSelectedOption={onSelectMappingType}
                />
            </div>
            {selectedType?.value === 'WHOLESALE_PRICING' &&
                <div className='flex flex-row justify-end mt-3 mb-5'>
                    <Button
                        className='btn-primary-text'
                        label='Grade Details'
                        dataQa={'grade-details'}
                        onClickButton={onToggleGradeModal}
                    />
                </div>
            }
            {!id && !copyId && <div className='my-8 flex flex-row items-center' {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                    icon={<img src='/assets/shared/upload-file.svg' className='w-[25px] object-contains pr-2' />}
                    className={'btn-main !py-1 !shadow-none flex flex-row items-center'}
                    label={'Upload Template'}
                    dataQa={'upload-template'}
                />
                <p className='text-primary-light font-medium ml-4'>{fileSelected?.name}</p>
            </div>
            }
            {newColumns && selectedCategory && selectedType &&
                <>
                    <div>
                        <p className='text-primary-light'>Map your csv columns with wing columns.</p>
                        <div className='flex flex-row justify-end'>
                            <Button
                                label='Clear Mapped Columns'
                                dataQa={'clear-mapped-columns'}
                                className='btn-primary-text'
                                onClickButton={onClearMappedColumns}
                            />
                        </div>
                        <FieldsMappingTable
                            columns={newColumns}
                            fields={categoryFields}
                            mappingFields={mappingFields}
                            mappingFieldsErrors={mappingFieldsErrors}
                            onChangeFieldSelected={onChangeFieldSelected}
                            onClearMappedColumns={onClearMappedColumns}
                        />
                    </div>
                    <div className='flex flex-row justify-start items-center mb-6 min-w-[100%]'>
                        <div className='flex items-center mr-2'>
                            <input
                                onChange={onClickPriceMultipleColumns}
                                checked={multiplePricingColumns}
                                id='checkbox-all'
                                type='checkbox'
                                data-qa={'checkbox'}
                                className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                            />
                            <label htmlFor='checkbox-all' className='sr-only'>

                            </label>
                        </div>
                        <p className='text-[#a4a4a4]'>
                            Price is in multiple columns.
                        </p>
                    </div>
                    {multiplePricingColumns &&
                        <MultiplePriceColumnsComp
                            fileColumns={newColumnsFormated}
                            selectedType={selectedType}
                            categoryColumns={categoryFields}
                            categoryFieldValues={categoryValues}
                            pricingMappedCategories={priceMappingColumns}
                            loadingOptions={loadingOptions}
                            filteredFields={filteredFields}
                            onClickMultiQuantity={onClickMultiQuantity}
                            onChangeSelectedFieldValue={onChangeSelectedFieldValue}
                            onChangeSelectedPriceFileColumn={onChangeSelectedPriceFileColumn}
                            onAddingNewPricingColumn={onAddingNewPricingColumn}
                            onDeletePricingIndexItem={onDeletePricingIndexItem}
                            onChangeSearchFieldValues={onChangeSearchFieldValues}
                            onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                        />
                    }
                </>
            }
            <div className='mt-8 flex flex-row justify-end'>
                <Button
                    label={'Continue Mapping Values'}
                    dataQa={'continue-mapping-values'}
                    className={`${loading ? 'btn-main-disable' : 'btn-main'} !shadow-none min-w-[200px]`}
                    onClickButton={() => !loading && onContinue()}
                />
            </div>
            <GradeDetailsModal
                openModal={showGradeModal}
                handleCloseModal={onToggleGradeModal}
            />
        </div>
    )
}

export default MappingGeneralStep;