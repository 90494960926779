import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { PRICING_HISTORY_TYPES } from '../../../utils/constants/pricing-history-types';
import { tryToFetchAggregatedPricingHistory } from '../../../store/brokers/shared-endpoints/pricing-history/pricingHistorySlice';
import { tryToFindOperatingMarkets } from '../../../store/brokers/admin/partners/partnersSlice';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IActiveMarket } from '../../../interfaces/markets/IActiveMarket';
import PricingClassification from './details/PricingClassification';
import PricingHistoryTable from './tables/PricingHistoryTable';
import SearchWithButton from '../../../shared/search-with-button';
import SelectCheckbox from '../../../shared/select-checkbox';


const PricingHistory = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<Array<any>>([]);
    const [search, setSearch] = useState<string>();
    const [categorySelected, setCategorySelected] = useState<string>();
    const [typeSelected, setTypeSelected] = useState<any>();
    const [userSectors, setUserSectors] = useState<Array<any>>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const [activeMarket, setActiveMarket] = useState<IActiveMarket>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state?.pricingHistory?.pricingsHistory?.data?.page);
    const activeMarketState = useAppSelector((state) => state?.partners?.partnerActiveMarket);
    const state = useAppSelector((state) => state.pricingHistory);
    const [userProfileMutation] = useUserProfileMutation();

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Pricing History')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

    useEffect(() => {
        if (state.pricingsHistory) {
            const rowsData =
                state.pricingsHistory?.data?.elements && Array.isArray(state.pricingsHistory?.data.elements)
                    ? state.pricingsHistory?.data.elements.map((r) => ({ ...r }))
                    : [];
            if (rowsData !== rows) {
                setRows(rowsData);
            }
        }
    }, [state.pricingsHistory]);

    const onGetUserSectorsAndMarkets = async () => {
        const response = await userProfileMutation(null).unwrap()
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ label: s?.displayName || s?.name, value: s?.id, enabledIcon: s?.enabledIcon, disabledIcon: s?.disabledIcon })) : []
        setCategorySelected(formatSectors?.[0]?.value)
        setUserSectors(formatSectors || [])
    }

    const onGettingPricingData = async () => {
        if (categorySelected) {
            let data: any = {
                assetTemplateId: categorySelected,
            }
            if (activeMarket) {
                data = {
                    ...data || {},
                    marketId: activeMarket?.market?._id
                }
            }
            if (search) {
                data = {
                    ...data,
                    search: search
                }
            }
            if (typeSelected) {
                const findType = PRICING_HISTORY_TYPES?.find((item) => item?.label === typeSelected)
                data = {
                    ...data,
                    type: findType?.value
                }
            }
            const fetchData = {
                ...paginationState || {},
                data
            }
            dispatch(tryToFetchAggregatedPricingHistory(fetchData)).unwrap()
        }
    }

    useEffect(() => {
        if (activeMarketState && ((JSON.stringify(activeMarketState) !== JSON.stringify(activeMarket)))) {
            setActiveMarket(activeMarketState || undefined)
            onResetPaginationSettings()
        }
    }, [activeMarketState])

    useEffect(() => {
        onGetUserSectorsAndMarkets()
        dispatch(tryToFindOperatingMarkets(null))
    }, [])

    useEffect(() => {
        onGettingPricingData()
    }, [paginationState, activeMarket])

    useEffect(() => {
        if (categorySelected) {
            onGettingPricingData()
        }
    }, [categorySelected])

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationSettings()
    }

    const onClickCategory = (categoryId: string) => {
        setCategorySelected(categoryId)
        onResetPaginationSettings()
    }

    const onClearAllFilters = () => {
        setTypeSelected(undefined)
        onResetPaginationSettings()
    }

    const onSeePricingHistoryDetails = (id: string) => {
        navigate(`/pricing-history/${id}`)
    }

    const onClickImportType = (value: any, type?: string) => {
        setTypeSelected(value?.label)
        onResetPaginationSettings()
    }

    return (
        <div>
            <div className='mb-5'>
                <p className='page-title'>Pricing History</p>
            </div>
            <div className='mt-10 mb-5'>
                <PricingClassification
                    items={userSectors || []}
                    activeButton={categorySelected}
                    onClickCategory={onClickCategory}
                />
            </div>
            <div className='grid sm:grid-cols-1 lg:grid-cols-3 gap-4 mt-4 mb-8'>
                <SelectCheckbox
                    name='Import Type'
                    placeholder={typeSelected || ''}
                    dataQa={typeSelected === undefined ? 'import-price' : typeSelected.toLowerCase()}
                    options={PRICING_HISTORY_TYPES || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200, }}
                    onChangeSelectedOption={onClickImportType}
                    uniqueName={'type'}
                />
            </div>
            <div className='mb-4 mt-2'>
                <div className='bg-white pl-4 pt-2'>
                    <SearchWithButton
                        buttonLabel='Clear All Filters'
                        placeholder='Filter table'
                        onChangeSearchValue={onChangeSearchValue}
                        search={search}
                        onClickButton={onClearAllFilters}
                        buttonStyle={'btn-primary-text-underline !min-w-auto sm:col-span-10 lg:col-span-2 flex flex-row sm:justify-center lg:justify-end bg-transparent mt-1'}
                        mainContainerStyle={'!grid-cols-10'}
                    />
                </div>
                <div>
                    <PricingHistoryTable
                        rows={rows}
                        paginationData={paginationData}
                        accessDetails={!accessControl || pageAccess?.actions?.['See Details']}
                        accessDownloadCSV={!accessControl || pageAccess?.actions?.['Download CSV']}
                        setPaginationState={setPaginationState}
                        onSeeDetails={onSeePricingHistoryDetails}
                    />
                </div>
            </div>
        </div>
    )
}

export default PricingHistory;