import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { GRADES_ITEMS } from '../../../../utils/constants/grades';
import Loading from '../../../../shared/Loading';
import Table from '../../../../shared/table';


interface IPricingTable {
    rows: Array<any>;
    paginationData?: IPage;
    showHighestPrice: boolean;
    state: any;
    activeMarket?: IActiveMarket;
    pricingLoading: boolean;
    accessPricingAction?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSortPricing: (name?: string) => void;
    onOpenActionModal: (offerId: string) => void;
}

const PricingTable = ({
    rows,
    paginationData,
    showHighestPrice,
    state,
    activeMarket,
    pricingLoading,
    accessPricingAction,
    setPaginationState,
    onClickSortPricing,
    onOpenActionModal
}: IPricingTable) => {
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Last Update', 'Pricing', 'Actions'])
    const [tableExtraKeys, setTableExtraKeys] = useState<Array<any>>()

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const formatTableColumnsAndKeys = () => {
        setTableLoading(true)
        const allKeysArray: any =
        rows &&
        rows?.length > 0 &&
        rows.flatMap((obj) => Object.keys(obj?.value));


        const uniqueKeysArray =
        allKeysArray &&
        allKeysArray?.length > 0 &&
        allKeysArray?.filter(
            (key: any, index: any, array: any) => array?.indexOf(key) === index
        );
        const formatTableColumns = []
        const formatTableExtraKeys: any = []
        formatTableColumns.push('Last updated')
        if (uniqueKeysArray && uniqueKeysArray?.length > 0) {
            uniqueKeysArray
              ?.filter((key: string) => (key || '').toLowerCase() !== 'condition')
              ?.map((key: string) => {
               
                  const formatKey = capitalizeFirstLetter(key);
                  const formatElement = (formatKey || '').replaceAll('_', ' ');
                  formatTableExtraKeys.push(key);
                  formatTableColumns.push(formatElement);
                return key;
              });
          }

        formatTableColumns.push('Price')
        formatTableColumns.push('Actions')
        setTableColumns(formatTableColumns || [])
        setTableExtraKeys(formatTableExtraKeys || [])
    }

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [rows, paginationData, state])

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [])

    useEffect(() => {
        if (tableExtraKeys) {
            setTableLoading(false)
        }
    }, [tableExtraKeys])

    return (
        <>
            {tableLoading ?
                <div className='flex flex-row min-w-full justify-center my-3'>
                    <Loading />
                </div>
                :
                <Table
                    columns={tableColumns}
                    paginationData={paginationData}
                    sortColumns={['Price']}
                    sortStatus={{ 'Price': showHighestPrice ? 'desc' : 'asc' }}
                    onClickSortColumn={onClickSortPricing}
                    setPaginationState={setPaginationState}
                    containerStyle={'mt-0'}
                >
                    {pricingLoading ?
                        <>
                            <>{[...Array(6)].map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                        {tableExtraKeys?.map(element => {
                                            return (
                                                <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                            )
                                        })
                                        }
                                        <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                        <td className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    </tr>
                                )
                            })}
                            </>
                        </>
                        : <>
                            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                                const lastUploaded = moment(row?.updatedAt).fromNow()
                                return (
                                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                        <td className='px-6 py-4'>{lastUploaded}</td>
                                        {tableExtraKeys && tableExtraKeys?.length > 0 && tableExtraKeys?.map((key: string, index: number) => {
                                            const findGrade = (key || '')?.toLowerCase() === 'grade' && GRADES_ITEMS.find((grade: any) => (grade.value || '').toLowerCase() === (row?.value?.[key] || '').toLowerCase())
                                            return (
                                                <td
                                                    key={index}
                                                    className={'px-6 py-4'}>
                                                    {(key || '')?.toLowerCase() === 'grade' ? (findGrade ? findGrade?.name : row?.value?.[key]) : row?.value?.[key]}
                                                </td>
                                            )
                                        })}
                                        <td className='px-6 py-4 min-w-[120px]'>
                                            <div className='flex flex-row items-center'>
                                                {CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.descriptor?.name || '')}`]}{Number(row?.quantity || 0).toFixed(2)}
                                                {idx === 0 && showHighestPrice && <img title={'Highest Price'} alt={'highest price'} src={'/assets/pricing/highest-price.svg'} className={'w-[17px] ml-2'} />}
                                            </div>
                                        </td>
                                        <td className='px-6 py-4'>
                                            <div data-qa={'action'} onClick={() => accessPricingAction && row?._id && onOpenActionModal(row?._id)}>
                                                <img title={'Action'} alt='items arrow' src={'/assets/pricing/see-more.svg'} className={`w-[7x] rotate-180 mb-2 ${accessPricingAction ? 'cursor-pointer' : 'cursor-not-allowed'} mr-2`} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    }
                </Table>
            }
        </>
    )
}

export default PricingTable;