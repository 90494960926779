import React from 'react'
import { IPartnerWarehouse } from '../../../../interfaces/partners/IPartnerWarehouse'
import Button from '../../../../shared/button';


interface IPartnerWarehouseItem {
    warehouse: IPartnerWarehouse;
    markets: Array<any>;
    onEditWarehouse: (warehouse: IPartnerWarehouse) => void
    onOpenDeleteModal: (warehouse: IPartnerWarehouse) => void
}

const PartnerWarehouseItem = ({ warehouse, markets, onEditWarehouse, onOpenDeleteModal }: IPartnerWarehouseItem) => {
    const marketDetails = markets.filter(market => warehouse.markets?.includes(market._id))
    const { address, name } = warehouse;

    return (
        <div className='border border-gray-300 bg-white rounded-lg p-6 mb-6 shadow-lg'>
            <div className='flex justify-between border-b border-gray-300 pb-2 mb-4 '>
                <h2 className='text-xl font-semibold'>{name}</h2>
                <div className='flex justify-start items-start gap-2'>
                    <Button
                        label='Edit Warehouse'
                        className={'btn-primary'}
                        dataQa={'edit-partner-warehouse-button'}
                        onClickButton={() => onEditWarehouse(warehouse)}
                    />
                    <Button
                        label='Delete Warehouse'
                        className={'btn-error'}
                        dataQa={'edit-partner-warehouse-button'}
                        onClickButton={() => onOpenDeleteModal(warehouse)}
                    />
                </div>
            </div>
            <div className='mb-4'>
                <h3 className='text-lg font-medium mb-2'>Address:</h3>
                <p><strong>Street:</strong> {address.street}</p>
                <p><strong>Address Nr:</strong> {address.addressNo}</p>
                <p><strong>City:</strong> {address.city}</p>
                <p><strong>Postal Code:</strong> {address.postalCode}</p>
                {address.state && <p><strong>State:</strong> {address.state}</p>}
                <p><strong>Country:</strong> {address.country}</p>
                <p><strong>Warehouse Country:</strong> {address.warehouseCountry}</p>
                <p><strong>Phone:</strong> {address.phone}</p>
                <p><strong>Email:</strong> {address.email}</p>
            </div>
            <div>
                <h3 className='text-lg font-medium mb-2'>Markets:</h3>
                <ul className='list-disc list-inside'>
                    {marketDetails.map((market, index) => (
                        <li key={index}>{market.label}</li>
                    ))}
                </ul>
            </div>
        </div>

    )
}

export default PartnerWarehouseItem