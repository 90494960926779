import React from 'react';
import { IOrders } from '../../../../interfaces/orders/IOrders';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { onFormatPackageStatus } from '../../../../utils/constants/format-order-statuses';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';

const tableColumns = ['Order ID', 'Tracking Number', 'Total Items', 'Purchase Price', 'Shipping Fee', 'Package Status', 'Total Accepted Items', 'Actions']

interface IOrdersTable {
    rows: Array<IOrders>;
    paginationData?: IPage;
    activeMarket?: IActiveMarket;
    ordersLoading: boolean;
    acceptOrders?: boolean;
    loadingAccept?: boolean;
    pageAccess?: any;
    accessControl?: any;
    onSeeOrderDetails: (orderId: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickAcceptAllItems: (orderId: string) => void;
}

const OrdersTable = ({
    rows,
    paginationData,
    activeMarket,
    ordersLoading,
    acceptOrders,
    loadingAccept,
    accessControl,
    pageAccess,
    onSeeOrderDetails,
    setPaginationState,
    onClickAcceptAllItems
}: IOrdersTable) => {
    return (
        <Table
            columns={acceptOrders ? ['Accept', ...tableColumns || []] : tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {ordersLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        const value = onFormatPackageStatus(row?.status)?.name
                        const color = onFormatPackageStatus(row?.status)?.color
                        const text = onFormatPackageStatus(row?.status)?.text || 'text-black'
                        const item = color && <div className={`${color} flex flex-row justify-center text-center px-1 min-w-[150px] ${text} text-sm py-1 font-medium rounded-2xl`}>{value}</div>
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {acceptOrders &&
                                    <td className='px-2'>
                                        <div className='min-w-[120px]'>
                                            <Button
                                                className={`${(!loadingAccept && row?.status === 'delivered' && (!accessControl || (pageAccess?.actions && pageAccess?.actions?.Accept))) ? 'btn-main' : 'btn-main-disable text-[#a4a4a4] cursor-not-allowed'} !py-2 !shadow-none flex flex-row items-center`}
                                                label={'Accept'}
                                                onClickButton={() => (!loadingAccept && row?.status === 'delivered' && (!accessControl || (pageAccess?.actions && pageAccess?.actions?.Accept))) && onClickAcceptAllItems(row?._id || '')}
                                                icon={<img src={'/assets/table-actions/check-mark.svg'} className='w-[15px] mr-2 object-contain' />}
                                            />
                                        </div>
                                    </td>
                                }
                                <td className={`px-6 py-4`}>{row?._id}</td>
                                <td className='px-6 py-4'>
                                    <a
                                        rel='noreferrer'
                                        href={`https://parcelsapp.com/en/tracking/${row?.trackingNumber}`}
                                        target='_blank'
                                        className='text-primary-default'
                                    >
                                        {row?.trackingNumber}
                                    </a>
                                </td>
                                <td className={`px-6 py-4`}>{row?.items?.length || 0}</td>
                                <td className={`px-6 py-4`}>{CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.descriptor?.name || '')}`]}{row?.price || row?.initialPrice || 0}</td>
                                <td className={`px-6 py-4`}>{CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.descriptor?.name || '')}`]}{row?.shippingFee || 0}</td>
                                <td className={`px-6 py-4`}>{item}</td>
                                <td className={`px-6 py-4`}>{row?.totalAcceptedItems || 0}</td>
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <div className='flex flex-row justify-around'>
                                        <div data-qa={'view-details'} onClick={() => row?._id && onSeeOrderDetails(row?._id)}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={'h-[14px] object-contain cursor-pointer mr-2'} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default OrdersTable;