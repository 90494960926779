import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { ITacListState } from '../../../../interfaces/tac-list/ITacListState';
import { TacListService } from './tacApi';

const initialState: ITacListState = {
    tacListAreLoading: false,
    tacListIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToAddTacList = createAsyncThunk<ApiResponse<any>, { data: Array<any> }>(
    'tac-list/tryToAddTacList',
    async (data: { data: Array<any> }, { rejectWithValue }) => {
        try {
            const result = await TacListService.tryToAddTacList(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchAllTacLists = createAsyncThunk<any, { pageNumber: number, pageSize: number, filters?: any }
>('tac-list/tryToFetchAllTacLists',
    async ({ pageNumber, pageSize, filters }) => {
        const result = await TacListService.tryToFetchAllTacLists(
            pageNumber,
            pageSize,
            filters,
        );
        return result.data;
    });


export const tryToFetchSingleTacList = createAsyncThunk<any, string>(
    'tac-list/tryToFetchSingleTacList',
    async (id) => {
        const result = await TacListService.tryToFetchSingleTacList(id);
        return result?.data;
    },
);

// tryToFetchItemModelByIMEI
export const tryToFetchItemModelByIMEI = createAsyncThunk<any, string>(
    'tac-list/tryToFetchItemModelByIMEI',
    async (imei) => {
        const result = await TacListService.tryToFetchItemModelByIMEI(imei);
        return result?.data;
    },
);


export const tryToDownloadExtendedTacListData = createAsyncThunk(
    'tac-list/tryToDownloadExtendedTacListData',
    async () => {
        const result = await TacListService.tryToDownloadExtendedTacListData();
        return result?.data;
    },
);


export const tryToDeleteExtendedTacList = createAsyncThunk<ApiResponse<any>, any>(
    'tac-list/tryToDeleteExtendedTacList',
    async (id, { rejectWithValue }) => {
        try {
            const results = await TacListService.tryToDeleteExtendedTacList(id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);


export const tryToEditExtendedTacList = createAsyncThunk<ApiResponse<any>, any>(
    'tac-list/tryToEditExtendedTacList',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await TacListService.tryToEditExtendedTacList(id, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tacListSlice = createSlice({
    name: 'tacList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToAddTacList
            .addCase(tryToAddTacList.pending, (state) => {
                state.tacListIsLoading = true;
            })
            .addCase(tryToAddTacList.rejected, (state) => {
                state.tacListIsLoading = false;
            })
            .addCase(tryToAddTacList.fulfilled, (state, action) => {
                state.tacListIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.tacLists?.data) state.tacLists.data.elements = [...state.tacLists?.data.elements, data];
            })

            // tryToFetchAllTacLists
            .addCase(tryToFetchAllTacLists.pending, (state) => {
                state.tacListAreLoading = true;
            })
            .addCase(tryToFetchAllTacLists.rejected, (state) => {
                state.tacListAreLoading = false;
            })
            .addCase(tryToFetchAllTacLists.fulfilled, (state, action) => {
                state.tacListAreLoading = false;
                state.tacLists = action.payload;
            })

            // tryToFetchSingleTacList
            .addCase(tryToFetchSingleTacList.pending, (state) => {
                state.tacListIsLoading = true;
            })
            .addCase(tryToFetchSingleTacList.rejected, (state) => {
                state.tacListIsLoading = false;
            })
            .addCase(tryToFetchSingleTacList.fulfilled, (state, action) => {
                state.tacListIsLoading = false;
                state.tacList = action.payload;
            })

            // tryToEditExtendedTacList
            .addCase(tryToEditExtendedTacList.pending, (state) => {
                state.tacListIsLoading = true;
            })
            .addCase(tryToEditExtendedTacList.rejected, (state) => {
                state.tacListIsLoading = false;
            })
            .addCase(tryToEditExtendedTacList.fulfilled, (state, action) => {
                state.tacListIsLoading = false;
                if (state.tacLists?.data.elements)
                    state.tacLists.data.elements =
                        { ...state }.tacLists?.data.elements.map((tacList) => {
                            if (tacList?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return tacList;
                            }
                        }) || [];
                state.tacList = action.payload.data;
            })

            // tryToFetchItemModelByIMEI
            .addCase(tryToFetchItemModelByIMEI.pending, (state) => {
                state.tacListIsLoading = true;
            })
            .addCase(tryToFetchItemModelByIMEI.rejected, (state) => {
                state.tacListIsLoading = false;
            })
            .addCase(tryToFetchItemModelByIMEI.fulfilled, (state, action) => {
                state.tacListIsLoading = false;
                state.extendedTacLists = action.payload;
            })

            // tryToDeleteExtendedTacList
            .addCase(tryToDeleteExtendedTacList.pending, (state) => {
                state.tacListIsLoading = true;
            })
            .addCase(tryToDeleteExtendedTacList.rejected, (state) => {
                state.tacListIsLoading = false;
            })
            .addCase(tryToDeleteExtendedTacList.fulfilled, (state, action) => {
                state.tacListIsLoading = false;
                state.requestStatus = 'success';
                if (state.tacLists?.data.elements)
                    state.tacLists.data.elements =
                        { ...state }.tacLists?.data.elements.filter((tacList) => tacList?._id !== action?.meta?.arg) || [];
            })

            // tryToDownloadExtendedTacListData
            .addCase(tryToDownloadExtendedTacListData.pending, (state) => {
                state.tacListIsLoading = true;
            })
            .addCase(tryToDownloadExtendedTacListData.rejected, (state) => {
                state.tacListIsLoading = false;
            })
            .addCase(tryToDownloadExtendedTacListData.fulfilled, (state, action) => {
                state.tacListIsLoading = false;
                state.requestStatus = 'success';
            })
    },
});

export default tacListSlice.reducer;