import React from 'react';
import { ISalesHistory } from '../../../../interfaces/reports/ISalesHistory';
import Datepicker from '../../../../shared/datepicker';
import Input from '../../../../shared/input';


interface IFilters {
    filters?: ISalesHistory;
    errors?: ISalesHistory;
    onChangeFilterValue: (value: string | null | undefined, type: string) => void;
}

const SalesHistoryTableFilters = ({
    filters,
    errors,
    onChangeFilterValue,
}: IFilters) => {
    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
            <Input
                label='User'
                containerStyle='mb-0'
                dataQa={'user-filter'}
                showValue={true}
                inputValue={filters?.user}
                error={errors?.user || ''}
                onChangeInput={onChangeFilterValue}
                inputUniqueName={'user'}
            />
            <Datepicker
                label='Date From'
                placeholder=' '
                dataQa={'date-from'}
                containerStyle='mb-0'
                inputValue={filters?.from}
                error={errors?.from || ''}
                onChangeInput={onChangeFilterValue}
                inputUniqueName={'from'}
            />
            <Datepicker
                label='Date To'
                placeholder=' '
                dataQa={'date-to'}
                containerStyle='mb-0'
                inputValue={filters?.to}
                error={errors?.to || ''}
                onChangeInput={onChangeFilterValue}
                inputUniqueName={'to'}
            />
        </div>
    )
}

export default SalesHistoryTableFilters;