import React from 'react';
import ShippingProvider from '../details/ShippingProvider';

const shippingProviders = [
    { name: 'ZigZag', selected: true, image: '/assets/profile/shipping-providers/zigzag.png' }
]

const Shipping = () => {
    return (
        <div className='my-3'>
            <div className='my-3'>
                <p className='text-md font-bold'>Choose a shipping provider!</p>
                <div className='grid grid-cols-3 gap-4 my-5'>
                    {shippingProviders && shippingProviders?.length > 0 && shippingProviders?.map((provider, index) => {
                        return (
                            <ShippingProvider
                                name={provider?.name}
                                selected={provider?.selected}
                                key={index}
                                image={provider?.image}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Shipping;