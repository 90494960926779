import React from 'react';
import Modal from '../../../../shared/modal'; // Adjust the import path as needed
import Input from '../../../../shared/input';
import Button from '../../../../shared/button';
import { useForm } from 'react-hook-form';

interface ITrackingNumberModal {
    open: boolean;
    onClose: () => void;
    onSubmit: (trackingNumber: string, shippingProvider: string) => void;
    nrOfItems: number;
    loading: boolean;
}
interface FormData {
    trackingNumber: string;
    shippingProvider: string;
}


const TrackingNumberModal = ({ open, onClose, onSubmit, nrOfItems, loading }: ITrackingNumberModal) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm<FormData>();

    const onSubmitForm = (data: { trackingNumber: string; shippingProvider: string }) => {
        onSubmit(data.trackingNumber, data.shippingProvider);
        reset()
    };

    return (
        <Modal open={open} onClose={onClose} showInRight>
            <form onSubmit={loading ? () => { } : handleSubmit(onSubmitForm)} className="flex flex-col gap-4">
                <div>
                    <p className='font-semibold text-sm text-center'>Add Tracking Number</p>
                    <hr className='my-4' />
                    <p className='text-xl mb-4'>Ship the items you have been authorised to return</p>
                </div>
                <p className='border-b border-[#D9D9D9] '>{`${nrOfItems} Item(s) selected to ship`}</p>
                <div className="bg-gray-100 p-4 rounded-md">
                    <p className="font-dm_sansbold text-xl" >Action Confirmation</p>
                    <p className="text-sm mb-4">We just need some information to confirm that these items are shipped</p>
                    <div className="mb-4">
                        <Input
                            label="Tracking Number"
                            placeholder="123456789"
                            register={register('trackingNumber', { required: 'Tracking number is required' })}
                            error={errors.trackingNumber && errors.trackingNumber.message}
                            dataQa="tracking-number-input"
                        />
                    </div>
                    <div className="mb-4">
                        <Input
                            label="Shipping Provider"
                            placeholder="Royal Mail"
                            register={register('shippingProvider', { required: 'Shipping provider is required' })}
                            error={errors.shippingProvider && errors.shippingProvider.message}
                            dataQa="tracking-provider-input"
                        />
                    </div>
                    <p className="text-sm text-gray-600">
                        Please make sure that the details provided are correct, as confirming this action cannot be changed later.
                    </p>
                </div>
                <div className="flex justify-center">
                    <Button
                        label="Confirm"
                        type="submit"
                        className="btn-main !bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !text-[#202020] min-w-[250px]"
                        dataQa="submit-tracking-info"
                    />
                </div>
            </form>
        </Modal>
    );
};

export default TrackingNumberModal;
