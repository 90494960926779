import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddRentalAsset,
    tryToEditRentalAsset,
    tryToFetchSingleRentalAsset
} from '../../../../store/brokers/admin/rental-pricing/rentalPricingSlice';
import { tryToFetchAssetFieldsTemplate } from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { IAssetFields } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IAssets } from '../../../../interfaces/assets/IAssets';
import Button from '../../../../shared/button';
import Select from '../../../../shared/select';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IManageRentalAssetModal {
    id: string;
    assetId?: string;
    openModal: boolean;
    handleCloseModal: () => void;
    onFinishSuccessfully: () => void;
}

const ManageRentalAssetModal = ({
    id,
    assetId,
    openModal,
    handleCloseModal,
    onFinishSuccessfully
}: IManageRentalAssetModal) => {
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<any>();
    const [formErrors, setFormErrors] = useState<any>();
    const [generalError, setGeneralError] = useState<string>();
    const [assetFields, setAssetFields] = useState<IAssetFields[]>([]);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)
    const stateAssetFields = useAppSelector((state) => state.assetsTemplates);
    const assetDetails = useAppSelector((state) => state.rentalPricing?.rentalAssetDetails);

    const onCloseModal = () => {
        handleCloseModal();
        setGeneralError(undefined);
        setFormData(undefined);
        setFormErrors(undefined);
        setSubmitLoading(false);
    }

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchAssetFieldsTemplate(id));
        }
    }, []);

    useEffect(() => {
        if (assetId) {
            const data = {
                id,
                assetId
            }
            dispatch(tryToFetchSingleRentalAsset(data))
        } else {
            setFormData(undefined)
        }
    }, [assetId])

    useEffect(() => {
        if (assetDetails && assetId) {
            setFormData(assetDetails?.descriptor)
        } else {
            setFormData(undefined)
        }
    }, [assetDetails])

    useEffect(() => {
        if (stateAssetFields.assetTemplateFields) {
            const rows =
                stateAssetFields.assetTemplateFields &&
                    Array.isArray(stateAssetFields.assetTemplateFields)
                    ? stateAssetFields.assetTemplateFields
                        ?.filter((r) => r?.enabled)
                        .map((r) => ({ ...r, id: r.id }))
                    : [];
            setAssetFields(rows);
        }
    }, [stateAssetFields.assetTemplateFields]);

    const onSubmit = async () => {
        setSubmitLoading(true)
        const formatDescriptor = formData;
        const data: IAssets = {
            assetTemplateId: id || '',
            descriptor: formatDescriptor,
        };
        try {
            if (assetId) {
                data._id = assetId
                await dispatch(tryToEditRentalAsset(data)).unwrap()
            } else {
                await dispatch(tryToAddRentalAsset(data)).unwrap();
            }
            setGeneralError(undefined);
            setFormData(undefined);
            onCloseModal();
            onFinishSuccessfully();
        } catch (err) {
            setGeneralError(`${err}`);
        }
        setSubmitLoading(false)
    };

    const onChangeInput = (value: string | null | undefined, type: string) => {
        const findField = assetFields?.find((field) => field?.name === type);
        setFormData({
            ...(formData || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
    };

    const onChangeSelectedValue = (e: any, type: string) => {
        const findField = assetFields?.find((field) => field?.name === type);
        setFormData({
            ...(formData || {}),
            [type]: findField?.dataType === 'boolean' ? e?.target?.value === 'true' : e?.target?.value || undefined,
        });
    };

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle='min-w-[75vw]'>
            <div className={'p-2 min-w-[65vw] max-w-[66vw] min-h-[350px] mx-10'}>
                <p className='mb-4 font-bold text-xl'>{assetId ? 'Edit Asset' : 'Create Asset'}</p>
                <p className='font-medium text-sm mb-5 mt-3'>Fill form to {assetId ? 'edit asset details' : 'create asset'}:</p>
                {generalError && <Error text={generalError} />}
                <div className='grid grid-cols-4 gap-4 mt-6 min-h-[200px]'>
                    {assetFields?.length > 0 &&
                        assetFields?.map((field, idx) => {
                            let values: any = field?.name === 'condition' ? field?.replaceField?.values || field?.values : field?.values;
                            if (field?.dataType === 'boolean') {
                                values = [
                                    { value: true, label: 'True' },
                                    { value: false, label: 'False' },
                                ];
                            } else if (field?.dataType === 'list') {
                                values = ((field?.name === 'condition' && (field?.replaceField?.values || field?.values)) ||
                                    (field?.values &&
                                        field?.values?.length > 0)) &&
                                    values?.map((value: string) => {
                                        return { value: value, label: value };
                                    });
                            }
                            return (
                                <>
                                    {field?.dataType === 'number' ||
                                        field?.dataType === 'string' ? (
                                        <Input
                                            key={idx}
                                            containerStyle='mb-0'
                                            label={field?.name && `${field?.name[0].toUpperCase()}${field?.name?.slice(1)?.toLowerCase()}`}
                                            showValue={true}
                                            type={field?.dataType}
                                            dataQa={'input-data'}
                                            placeholder={' '}
                                            inputValue={formData?.[field?.name]}
                                            error={formErrors?.[field?.name] || ''}
                                            onChangeInput={onChangeInput}
                                            inputUniqueName={field?.name}
                                        />
                                    ) : (
                                        <Select
                                            key={idx}
                                            name={field?.name && `${field?.name[0].toUpperCase()}${field?.name.slice(1).toLowerCase()}`}
                                            dataQa={'selected-data'}
                                            containerStyle='mb-1'
                                            {...formData?.[field?.name] && ({
                                                value: formData?.[field?.name] || undefined
                                            })}
                                            placeholder={' '}
                                            showValue={true}
                                            onChangeSelectValue={(e) =>
                                                onChangeSelectedValue(
                                                    e,
                                                    field?.name,
                                                )
                                            }
                                            options={values}
                                            error={formErrors?.[field?.name] || ''}
                                        />
                                    )}
                                </>
                            );
                        })}
                </div>
                <div className='min-w-full flex flex-row justify-center mt-11 mb-5'>
                    <Button
                        label='Save'
                        dataQa={'create-rental-asset-save-btn'}
                        onClickButton={() => !submitLoading && onSubmit()}
                        className={`!shadow-none ${submitLoading ? 'btn-main-disable' : 'btn-main'} min-w-[150px] mr-4`}
                    />
                </div>
            </div>
        </Modal >
    );
};

export default ManageRentalAssetModal;
