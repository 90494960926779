import React, { useEffect, useRef } from 'react';


interface ITableActions {
    children?: React.ReactNode;
}

const TableActions = ({
   children,
}: ITableActions) => {
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const dropdownMenu = useRef<any>(null);

    const closeDropdownPopover = (e: any) => {
        if (dropdownMenu?.current && !dropdownMenu?.current?.contains(e.target)) {
            setDropdownPopoverShow(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', closeDropdownPopover, true);
        return () => {
          document.removeEventListener('click', closeDropdownPopover, true);
        };
      }, []);

    return (
        <div>
            <div>
                <button
                    onClick={(e) => setDropdownPopoverShow(!dropdownPopoverShow)}
                    type='button'
                    data-qa={'actions'}
                    className={'!px-4'} id='menu-button' aria-expanded='true' aria-haspopup='true'>
                    <img title={'Action'} alt='items arrow' src={'/assets/table-actions/see-more.svg'} className={'w-[7x] rotate-180 cursor-pointer mr-2'} />
                </button>
            </div>
            <div
                ref={dropdownMenu}
                className={`${dropdownPopoverShow ? '' : 'hidden'} shadow-md max-h-[150px] overflow-auto absolute right-0 z-10 mt-0 w-45 origin-top-right divide-y divide-[#a4a4a4] bg-white focus:outline-none`}
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='menu-button'
                tabIndex={-1}>
                    {children}
            </div>
        </div>
    )
}

export default TableActions;