import React from 'react';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';
import { GRADES_ITEMS } from '../../../../utils/constants/grades';


interface ICatalogFiltersSample {
    templateFields: Array<any>;
    filterValues: any;
    loadingFields: boolean;
    fieldValues?: any;
    loadingOptions?: any;
    filteredFields?: any;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onClearAllFilters: () => void;
    onMenuCloseAfterSearch: (type?: string) => void;
    onChangeSearchAssetFields: (e: any, type?: string) => void;
}

const CatalogFiltersSample = ({
    templateFields,
    filterValues,
    loadingFields,
    fieldValues,
    loadingOptions,
    filteredFields,
    onChangeSelectedValue,
    onMenuCloseAfterSearch,
    onChangeSearchAssetFields
}: ICatalogFiltersSample) => {
    const items = templateFields && Object.keys(templateFields)
    return (
        <div>
            <div className='my-2 grid grid-cols-3 gap-4'>
                {(items && items?.length > 0) && items?.map((item, index) => {
                    let values: any = fieldValues?.[item];
                    const findLoadingOptions = !!loadingOptions?.[item]
                    const filteredFieldsFind = filteredFields?.[item]
                    if ((item || '').toLowerCase() === 'grade') {
                        values =
                            fieldValues?.[item] &&
                            fieldValues?.[item]?.length > 0 &&
                            fieldValues?.[item]?.map((value: string) => {
                                const findGrade = GRADES_ITEMS?.find((grade) => grade?.value === value)
                                return { value, label: findGrade?.name || value }
                            })
                    } else {
                        values = filteredFieldsFind ?
                            filteredFieldsFind?.length > 0 &&
                            filteredFieldsFind?.map((value: string) => {
                                return { value: value, label: value };
                            })
                            :
                            fieldValues?.[item] &&
                            fieldValues?.[item]?.length > 0 &&
                            fieldValues?.[item]?.map((value: string) => {
                                return { value: value, label: value };
                            });
                    }
                    return (
                        <AsyncSelectCheckbox
                            key={index}
                            isLoading={(loadingFields && !filterValues?.[item]) || false}
                            name={item && `${item?.[0].toUpperCase()}${item?.slice(1).toLowerCase()}`}
                            dataQa={filterValues?.[item] === undefined ? item : filterValues?.[item]}
                            placeholder={filterValues?.[item] || ' '}
                            options={values}
                            dynamicOptions={true}
                            loadingOptions={findLoadingOptions}
                            onChangeValue={onChangeSearchAssetFields}
                            onMenuClose={onMenuCloseAfterSearch}
                            selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                            onChangeSelectedOption={onChangeSelectedValue}
                            uniqueName={item || ''}
                        />
                    )
                })
                }
            </div>
        </div>
    )
}

export default CatalogFiltersSample;