import React from 'react';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';

const tableColumns = ['Name', 'Actions']

interface IComparisonSitesTable {
    rows: any[];
    paginationData?: IPage;
    accessControl?: IAccessControl;
    actions?: IPageActions;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeComparisonDetails: (id: string) => void;
}

const ComparisonSitesTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    rowsLoading,
    setPaginationState,
    onSeeComparisonDetails
}: IComparisonSitesTable) => {
    return (
        <Table
            columns={tableColumns}
            containerStyle={'mt-0'}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.name || ''}</td>
                                <td className='px-6 py-4'>
                                    <Button
                                        label={'See Details'}
                                        dataQa={'see-details'}
                                        className={(!accessControl || actions?.['See Details']) ? 'btn-filters' : 'btn-noactive-filters'}
                                        onClickButton={() => ((!accessControl || actions?.['See Details']) && row?.name) && onSeeComparisonDetails(row?.name)}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default ComparisonSitesTable;