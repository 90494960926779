import React, { useState, useCallback } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToUploadRentalPricingCSV } from '../../../../store/brokers/admin/rental-pricing/rentalPricingSlice';
import SuccessfullyUploadFile from '../details/SuccessfullyUploadedFile';
import UploadCsvFile from '../details/UploadCsvFile';
import Modal from '../../../../shared/modal';


interface IImportRentalPricingCsvModal {
    openImportModal: boolean;
    sectors: Array<any>;
    markets: Array<any>;
    handleCloseModal: () => void;
    onImportSuccessfully: () => void;
}

const ImportRentalPricingCsvModal = ({
    openImportModal,
    sectors,
    markets,
    handleCloseModal,
    onImportSuccessfully,
}: IImportRentalPricingCsvModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [selectedSector, setSelectedSector] = useState<any>()
    const [fileSelected, setFileSelected] = useState<any>()
    const [importLoading, setImportLoading] = useState<boolean>(false)
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onCloseModal = () => {
        handleCloseModal();
        setImportError(undefined)
        setImportedSuccessfully(false)
        setFileSelected(undefined)
        setSelectedSector(undefined)
        setSelectedMarket(undefined)
    }

    const onUploadFile = async () => {
        if (!selectedSector || !selectedMarket) {
            setImportError('Please make sure you select all fields in order to upload rental pricing.')
            return;
        }
        setImportLoading(true)
        try {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const formatResults = results?.data && results?.data?.length > 0 && results?.data?.map((item: any) => {
                        const asset = Object.assign(item)
                        // eslint-disable-next-line
                        const quantity = item?.price ? Number(item?.price?.replace(/[^0-9\.]+/g, '')) : 0
                        delete asset.price
                        const objectKeysFormat = asset && Object.keys(asset)
                        objectKeysFormat.forEach((element: any) => {
                            if (asset?.[element] && element !== 'price' && /^\d+(\s?\d+)*\s?-Wing$/.test(asset?.[element])) {
                                const formatElement = asset?.[element]?.replace('-Wing', '')
                                asset[element] = formatElement
                            }
                        });
                        return {
                            asset,
                            quantity
                        }
                    })
                    let payload: any = {
                        assetTemplateId: selectedSector?.value || '',
                        data: formatResults || []
                    }
                    if (selectedMarket) {
                        payload = {
                            ...payload || {},
                            marketId: selectedMarket?.value || ''
                        }
                    }
                    const formatBody = {
                        offers: payload
                    }
                    await dispatch(tryToUploadRentalPricingCSV(formatBody)).unwrap()
                }
            })
            setImportError(undefined)
            setImportedSuccessfully(true)
            setSelectedSector(undefined)
            onImportSuccessfully()
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
        setImportLoading(false)
    }

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(e)
    }

    const onChangeSelectedMarket = (e: any) => {
        setSelectedMarket(e)
    }

    return (
        <Modal
            open={openImportModal}
            onClose={() => !importLoading && onCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <SuccessfullyUploadFile
                        handleCloseModal={() => !importLoading && onCloseModal()}
                    />
                    : <UploadCsvFile
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                        importLoading={importLoading}
                        selectedSector={selectedSector}
                        sectors={sectors}
                        selectedMarket={selectedMarket}
                        markets={markets}
                        onChangeSelectedSector={onChangeSelectedSector}
                        onChangeSelectedMarket={onChangeSelectedMarket}
                    />
                }
            </div>
        </Modal>
    )
}
export default ImportRentalPricingCsvModal;