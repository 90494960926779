import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse';
import { useAppDispatch } from '../../../../app/hooks';
import ImportTacListCsv from '../details/ImportTacListsCVS';
import ImportTacListsSuccess from '../details/ImportTacListsSuccess';
import { tryToAddTacList } from '../../../../store/brokers/admin/tac/tacSlice';
import Modal from '../../../../shared/modal';


interface IImportAssetsModal {
    openImportModal: boolean;
    handleCloseModal: () => void;
    onImportTacListsSuccessfully: () => void;
}

const ImportExtendedTacListModal = ({
    openImportModal,
    handleCloseModal,
    onImportTacListsSuccessfully
}: IImportAssetsModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [fileSelected, setFileSelected] = useState<any>()

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onCloseModal = () => {
        handleCloseModal();
        setImportedSuccessfully(false);
        setImportError(undefined);
        setFileSelected(undefined)
    }

    const onUploadFile = async () => {
        try {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                const payload={ data: [] } as any
                   for (const data of results.data) {
                       payload.data.push({
                           id :data.id,
                           code :data.code,
                           descriptor : {
                               make: data.make,
                               model: data.model
                           }
                       })
                   }
                    await dispatch(tryToAddTacList(payload)).unwrap()
                }
            }
            )
            setImportError(undefined)
            setImportedSuccessfully(true)
            onImportTacListsSuccessfully()
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
    }

    return (
        <Modal
            open={openImportModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <ImportTacListsSuccess
                        handleCloseModal={onCloseModal}
                    />
                    : <ImportTacListCsv
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                    />
                }
            </div>
        </Modal>
    )
}

export default ImportExtendedTacListModal;