import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { tryToFetchShippedOrderDetails } from '../../../store/brokers/partners/orders/ordersSlice';
import { tryToFetchAssetTemplateFilters } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import OrderItemsFilters from './details/OrderItemsFilters';
import OrderDetailsTable from './tables/OrderDetailsTable';
import Button from '../../../shared/button';
import Input from '../../../shared/input';


const ShippedOrderDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [rows, setRows] = useState<Array<any>>([]);
    const [filterValues, setFilterValues] = useState<any>();
    const [categorySelected, setCategorySelected] = useState<string>();
    const [templateFilters, setTemplateFilters] = useState<Array<any>>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: undefined
    });
    const [pageAccess, setPageAccess] = useState<any>();
    const state = useAppSelector((state) => state.orders);
    const stateFilters = useAppSelector((state) => state.assetsTemplates);
    const itemsLoading = useAppSelector((state) => state?.orders?.orderIsLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const paginationData = useAppSelector((state) => state?.orders?.shippedOrderDetails?.data?.page);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Wing Packages')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Wing Package Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined
        })
    }

    const getOrdersData = async () => {
        if (!id) {
            return;
        }
        const filters = filterValues && Object.keys(filterValues)
        let formatFilters
        const filtersValuesFormat = filters && filters?.length > 0 && filters?.map((item: any) => {
            return {
                key: item,
                value: Object.keys(filterValues) ? filterValues?.[item] || undefined : undefined
            }
        })
        if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
            formatFilters = {
                value: filtersValuesFormat?.filter((key: any) => key?.value)
            }
        }
        let data: any = {}
        if (formatFilters && formatFilters?.value) {
            data = {
                ...formatFilters || {}
            }
        }
        const resolvedData = { ...paginationState, id: id, data }
        dispatch(tryToFetchShippedOrderDetails(resolvedData))
    }

    useEffect(() => {
        if (state.shippedOrderDetails) {
            const rowsData =
                state.shippedOrderDetails?.data?.elements && Array.isArray(state.shippedOrderDetails?.data.elements)
                    ? state.shippedOrderDetails?.data.elements.map((r: any) => ({ ...r, id: r?._id }))
                    : [];
            if (rowsData !== rows) {
                setRows(rowsData);
                setCategorySelected(rowsData?.[0]?.categoryId)
            }
        }
    }, [state.shippedOrderDetails]);

    useEffect(() => {
        if (stateFilters.assetTemplateFilters) {
            setTemplateFilters(stateFilters.assetTemplateFilters)
        }
    }, [stateFilters?.assetTemplateFilters]);

    useEffect(() => {
        if (categorySelected) {
            dispatch(tryToFetchAssetTemplateFilters({ id: categorySelected }))
        }
        setFilterValues(undefined)
    }, [categorySelected])

    useEffect(() => {
        if (paginationState && id) {
            getOrdersData()
        }
    }, [paginationState, id])

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setPaginationState({
            ...paginationState || {},
            pageNumber: 1,
            filters: value || undefined
        })
    }

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        if (type) {
            const findField = templateFilters?.find((field) => field?.name === type);
            setFilterValues({
                ...(filterValues || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
            onResetPaginationSettings()
        }
    };

    const onChangeFilterInput = (value: string | null | undefined, type: string) => {
        const findField = templateFilters?.find((field) => field?.name === type);
        setFilterValues({
            ...(filterValues || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
        onResetPaginationSettings()
    };

    const onClearAllFilters = () => {
        setFilterValues(undefined)
        onResetPaginationSettings()
    };

    const onGoBack = () => {
        navigate(-1);
    };

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Wing Package Details</p>
                </div>
            </div>
            <div className='my-5'>
                {categorySelected && templateFilters && templateFilters?.length > 0 &&
                    <OrderItemsFilters
                        items={templateFilters || []}
                        filterValues={filterValues || null}
                        onChangeInput={onChangeFilterInput}
                        onChangeSelectedValue={onChangeSelectedFilterValue}
                    />
                }
            </div>
            <div className='flex sm:flex-col md:flex-row  sm:justify-center md:justify-end lg:justify-end flex-wrap'>
                <Button
                    label='Clear all filters'
                    dataQa={'clear-all-filters'}
                    className='btn-primary-text-underline min-w-auto'
                    onClickButton={onClearAllFilters}
                />
            </div>
            <div>
                <div className={`bg-white`}>
                    <Input
                        placeholder={'Filter table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={paginationState?.filters}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                        dataQa={'filter-table'}
                    />
                </div>
                {(itemsLoading || rows?.length > 0)
                    ? <OrderDetailsTable
                        rows={rows}
                        accessControl={accessControl}
                        state={state}
                        actions={pageAccess?.actions}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        ordersLoading={itemsLoading}
                    />
                    : <div className='my-10 min-w-[100%] flex flex-col items-center'>
                        <img src={'/assets/shared/no-content.svg'} className={'w-[150px] mb-10 mt-4 object-contain'} />
                        <p className='text-[#a4a4a4] text-center font-md'>No items found</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default ShippedOrderDetails;