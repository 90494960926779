import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPurchaseList } from '../../../../interfaces/purchases/IPurchaseList';
import { IPurchase } from '../../../../interfaces/purchases/IPurchase';
import { PurchaseOrdersService } from './purchaseOrdersApi';


const initialState: IPurchaseList = {
    purchaseIsLoading: false,
    purchasesAreLoading: false,
    purchaseNotesAreLoading: true,
    purchaseContentIsLoading: false,
    mainOrderLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchPurchases = createAsyncThunk<any, IPaginationPayload>(
    'purchaseOrders/tryToFetchPurchases',
    async ({ pageNumber, pageSize, data }) => {
        const result = await PurchaseOrdersService.tryToFetchPurchases(
            pageNumber,
            pageSize,
            data,
        );
        return result?.data;
    }
);

export const tryToFetchSinglePurchase = createAsyncThunk<any, string>(
    'purchaseOrders/tryToFetchSinglePurchase',
    async (id: string) => {
        const result = await PurchaseOrdersService.tryToFetchSinglePurchase(id);
        return result?.data;
    },
);

export const tryToCreatePurchase = createAsyncThunk<any, IPurchase>(
    'purchaseOrders/tryToCreatePurchase',
    async (data) => {
        const result = await PurchaseOrdersService.tryToCreatePurchase(data);
        return result?.data?.data;
    },
);

export const tryToEditPurchase = createAsyncThunk<any, { id: string, data: IPurchase }>(
    'purchaseOrders/tryToEditPurchase',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await PurchaseOrdersService.tryToEditPurchase(id, data);
            return result?.data?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDeletePurchase = createAsyncThunk<ApiResponse<IPurchase>, string>(
    'purchaseOrders/tryToDeletePurchase',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PurchaseOrdersService.tryToDeletePurchase(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToSubmitPurchase = createAsyncThunk<any, string>(
    'purchaseOrders/tryToSubmitPurchase',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PurchaseOrdersService.tryToSubmitPurchase(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToValidatedImportedItems = createAsyncThunk<any, { assetTemplateId: string, data: any }>(
    'purchaseOrders/tryToValidatedImportedItems',
    async ({ assetTemplateId, data }, { rejectWithValue }) => {
        try {
            const result = await PurchaseOrdersService.tryToValidatedImportedItems(assetTemplateId, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDownloadPurchaseSamples = createAsyncThunk<any, { id: string, data: any }>(
    'purchaseOrders/tryToDownloadPurchaseSamples',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await PurchaseOrdersService.tryToDownloadPurchaseSamples(id, data);
            return result?.data?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToImportBatchPurchase = createAsyncThunk<any, any>(
    'purchaseOrders/tryToImportBatchPurchase',
    async (data, { rejectWithValue }) => {
        try {
            const result = await PurchaseOrdersService.tryToImportBatchPurchase(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToAcceptOrRejectPurchase = createAsyncThunk<any, any>(
    'purchaseOrders/tryToAcceptOrRejectPurchase',
    async ({ id, status, data }, { rejectWithValue }) => {
        try {
            const result = await PurchaseOrdersService.tryToAcceptOrRejectPurchase(id, status, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const purchaseOrdersSlice = createSlice({
    name: 'purchaseOrders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchPurchases
            .addCase(tryToFetchPurchases.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToFetchPurchases.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToFetchPurchases.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                state.purchases = action.payload;
            })

            // tryToFetchSinglePurchase
            .addCase(tryToFetchSinglePurchase.pending, (state) => {
                state.purchaseIsLoading = true;
            })
            .addCase(tryToFetchSinglePurchase.rejected, (state) => {
                state.purchaseIsLoading = false;
            })
            .addCase(tryToFetchSinglePurchase.fulfilled, (state, action) => {
                state.purchaseIsLoading = false;
                state.purchaseDetails = action.payload.data;
            })

            // tryToEditPurchase
            .addCase(tryToEditPurchase.pending, (state) => {
                state.purchaseIsLoading = true;
            })
            .addCase(tryToEditPurchase.rejected, (state) => {
                state.purchaseIsLoading = false;
            })
            .addCase(tryToEditPurchase.fulfilled, (state, action) => {
                state.purchaseIsLoading = false;
                state.purchaseDetails = action?.payload?.data;
            })

            // tryToCreatePurchase
            .addCase(tryToCreatePurchase.pending, (state) => {
                state.purchaseIsLoading = true;
            })
            .addCase(tryToCreatePurchase.rejected, (state) => {
                state.purchaseIsLoading = false;
            })
            .addCase(tryToCreatePurchase.fulfilled, (state, action) => {
                state.purchaseIsLoading = false;
                state.purchaseDetails = action.payload;
            })

            // tryToDeletePurchase
            .addCase(tryToDeletePurchase.pending, (state) => {
                state.purchaseIsLoading = true;
            })
            .addCase(tryToDeletePurchase.rejected, (state) => {
                state.purchaseIsLoading = false;
            })
            .addCase(tryToDeletePurchase.fulfilled, (state, action) => {
                state.purchaseIsLoading = false;
                state.requestStatus = 'success';
                if (state.purchases?.data.elements)
                    state.purchases.data.elements =
                        { ...state }.purchases?.data.elements.filter(
                            (purchase) => purchase?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToSubmitPurchase
            .addCase(tryToSubmitPurchase.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToSubmitPurchase.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToSubmitPurchase.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                state.requestStatus = 'success';
                if (state.purchases?.data.elements)
                    state.purchases.data.elements =
                        { ...state }.purchases?.data.elements.map((purchase) => {
                            if (purchase?._id === action.meta.arg) {
                                return {
                                    ...purchase || {},
                                    status: 'submitted'
                                }
                            } else {
                                return purchase
                            }
                        }
                        ) || [];
            })

            // tryToAcceptOrRejectPurchase
            .addCase(tryToAcceptOrRejectPurchase.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToAcceptOrRejectPurchase.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToAcceptOrRejectPurchase.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                state.requestStatus = 'success';
                if (state.purchases?.data.elements)
                    state.purchases.data.elements =
                        { ...state }.purchases?.data.elements.map((purchase) => {
                            if (purchase?._id === action.meta.arg?.id) {
                                return {
                                    ...purchase || {},
                                    status: action?.meta?.arg?.status,
                                    offers: action?.payload?.data?.offers
                                }
                            } else {
                                return purchase
                            }
                        }
                        ) || [];
            })
    },
});

export default purchaseOrdersSlice.reducer;
