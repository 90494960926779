import React from 'react'
import { IItem } from '../../../../interfaces/items/IItem'
import { IPage } from '../../../../interfaces/models/models/paginated-response.model'
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload'
import Table from '../../../../shared/table'
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate'
import { useNavigate } from 'react-router-dom'
import moment from 'moment';


const tableColumns = ['RMA ID', 'Created Date (dd/mm/yyyy)', 'Nr. of Items', 'Category', 'Total amount', 'RMA Status', 'Credit Amount', 'Actions']

interface IItemsTable {
    rows: IItem[];
    paginationData?: IPage;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    sectors: IAssetTemplate[];
    pageAccess: any;
    accessControl: any;
    currency: string;
}

const ItemsTable = ({ rows, paginationData, rowsLoading, setPaginationState, sectors, pageAccess, accessControl, currency }: IItemsTable) => {
    const navigate = useNavigate();
    const getCategoryIcon = (category: string) => {
        const res = sectors.find(sector => sector.name === category)
        return res?.disabledIcon
    }
    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
    const onView = (id: string) => {
        navigate(`/rma/${id}`)
    }
    return (
        <Table
            columns={tableColumns}
            containerStyle={'mt-0'}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?._id}</td>
                                <td className='px-6 py-4'>{moment(row.createdAt).format('DD-MM-YYYY')}</td>

                                <td className='px-6 py-4'>{row?.nrItems}</td>
                                <td className='px-6 py-6 flex gap-1 items-center'>
                                    {row.categories?.slice(0, 2).map((category: string, index: number) => <img key={index} className='w-6 h-6' src={getCategoryIcon(category)} alt={category} title={category} />)}
                                    {row?.categories?.length > 2 && (
                                        <span className="ml-1 hover:cursor-pointer relative" title={row?.categories?.slice(2).join(', ')}>
                                            ...
                                        </span>
                                    )}
                                </td>
                                <td className='px-6 py-4'>{currency}{row?.totalAmount}</td>
                                <td className={`px-6 py-4 ${row?.status === 'created' ? 'text-[#F7A21E]' : 'text-[#00B67A]'}`}>{capitalizeFirstLetter(row?.status)}</td>
                                <td className='px-6 py-4'>{row?.status === 'created' ? '- -' : currency + row?.totalCreditAmount}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row justify-start'>
                                        <div data-qa={'view-details'} onClick={() => (row?._id && (!accessControl || (pageAccess?.actions.View))) && onView(row?._id)}>
                                            <img title={'View Details'} alt='rma details' src={'/assets/table-actions/view.svg'} className={'h-[24px] object-contain cursor-pointer mr-2'} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default ItemsTable