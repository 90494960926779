import React, { useEffect, useState } from 'react';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import { ILanguage } from '../../../../interfaces/languages/ILanguage';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Textarea from '../../../../shared/textarea';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IAnswerDescriptionModal {
    openModal: boolean;
    description?: string;
    languages?: Array<ILanguage & ISelectedValue>;
    data?: {
        [key: string]: string
    };
    itemId: string;
    handleCloseModal: () => void;
    onSaveLanguagesChanges: (itemId: string, description?: string, data?: { [key: string]: string } | {} | undefined) => void;
}

const AnswerDescriptionModal = ({
    openModal,
    languages,
    data,
    description,
    itemId,
    onSaveLanguagesChanges,
    handleCloseModal,
}: IAnswerDescriptionModal) => {
    const [answerDescription, setAnswerDescription] = useState<string>()
    const [languagesData, setLanguagesData] = useState<Array<{ question?: string, language?: ILanguage & ISelectedValue }>>();
    const [languagesError, setLanguagesError] = useState<string>()

    const onCloseModal = () => {
        handleCloseModal();
        setLanguagesData(undefined)
        setLanguagesError(undefined)
    }

    useEffect(() => {
        if (openModal && data) {
            const keyLanguages = data && Object.keys(data)
            const formatLanguages = keyLanguages && keyLanguages?.length > 0 ? keyLanguages?.map((item: string) => {
                const findLanguage = (languages && languages?.length > 0) ? languages?.find((language: ILanguage & ISelectedValue) => language?.code === item) : undefined
                return {
                    language: findLanguage,
                    question: data?.[item] || ''
                }
            }) : undefined
            setLanguagesData(formatLanguages || undefined)
        }
    }, [data, openModal])

    useEffect(() => {
        setAnswerDescription(description)
    }, [openModal, description])

    const onChangeSelectedLanguage = (value: ISelectedValue, type?: string, index?: number) => {
        const formatLanguages = (languagesData && languagesData?.length > 0) ? languagesData?.map((item: any, idx: number) => {
            if (index === idx) {
                return {
                    ...item || {},
                    language: value
                }
            } else {
                return item
            }
        }) :
            [{
                language: value
            }]
        setLanguagesData(formatLanguages)
    }

    const onChangeQuestionDescriptionValue = (value: any, type?: string, index?: number) => {
        const formatLanguages = (languagesData && languagesData?.length > 0) ? languagesData?.map((item: any, idx: number) => {
            if (index === idx) {
                return {
                    ...item || {},
                    question: value?.target?.value
                }
            } else {
                return item
            }
        }) :
            [{
                question: value?.target?.value
            }]
        setLanguagesData(formatLanguages)
    }

    const onAddNewLanguage = () => {
        setLanguagesData([
            ...languagesData || [],
            {}
        ])
    }

    const onDeleteLanguage = (index: number) => {
        const filterData = (languagesData && languagesData?.length > 1) ? languagesData?.filter((item: any, idx: number) => idx !== index) : []
        setLanguagesData(filterData)
    }

    const onSaveChanges = () => {
        if (languagesData && languagesData?.length > 0) {
            let error = false
            languagesData?.forEach((item: { question?: string, language?: ILanguage & ISelectedValue }) => {
                if ((!item?.question || item?.question === '') && item?.language) {
                    error = true
                }
            })
            if (error) {
                setLanguagesError('Please write Answer Description for all languages selected or delete language!')
                return;
            }
        }
        setLanguagesError(undefined)
        let formatAnswerDescriptions = {};
        ((answerDescription && answerDescription !== '') && languagesData && languagesData?.length > 0) && languagesData?.forEach((item: { question?: string, language?: ILanguage & ISelectedValue }) => {
            if (item && Object.keys(item) && item?.language?.code) {
                formatAnswerDescriptions = {
                    ...formatAnswerDescriptions || {},
                    [item.language.code]: item?.question
                }
            }
        })
        onSaveLanguagesChanges(itemId, answerDescription, formatAnswerDescriptions)
        onCloseModal()
    }

    const onChangeAnswerDescription = (e: any) => {
        e.persist();
        setAnswerDescription(e?.target?.value);
    };

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle='min-w-[600px]'>
            <div className={'p-4 min-w-[550px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Answer Description</p>
                </div>
                {languagesError && <Error text={languagesError} />}
                <div className='my-5 bg-[#f8f9fc] rounded p-2 mb-2 mb-3'>
                    <p className='mb-2 text-[#a4a4a4]'>Default Answer Description</p>
                    <Textarea
                        placeholder='Answer Description'
                        dataQa={'description'}
                        className={'!bg-white w-full !resize-y'}
                        rows={2}
                        value={answerDescription}
                        onChangeTextarea={onChangeAnswerDescription}
                    />
                </div>
                <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                    {languagesData && languagesData?.length > 0 && languagesData?.map((data: any, id: number) => {
                        let filteredAllLanguages = (languagesData && languagesData?.length > 0 && languages && languages?.length > 0) ?
                            languages?.filter(item1 => {
                                return !languagesData?.some(item2 => item2.language?.code === item1.code);
                            })
                            : languages
                        if (languagesData && languagesData?.length > 0 && languages && languages?.length > 0 && data?.language) {
                            filteredAllLanguages = [
                                data?.language,
                                ...filteredAllLanguages || [],
                            ]
                        }
                        return (
                            <div key={id} className='mb-4'>
                                <div className='flex flex-row justify-start items-center'>
                                    <SelectCheckbox
                                        name={`Language ${id + 1}`}
                                        placeholder=' '
                                        options={filteredAllLanguages || []}
                                        containerStyle='max-w-[85%] w-[250px]'
                                        selectedOption={data?.language}
                                        index={id}
                                        onChangeSelectedOption={onChangeSelectedLanguage}
                                    />
                                    <div data-qa={'delete-dependencie'} className='max-w-[12%] ml-5 pt-5 cursor-pointer' onClick={() => onDeleteLanguage(id)}>
                                        <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] mb-4 hover:w-[17px] object-contain'} />
                                    </div>
                                </div>
                                {data?.language &&
                                    <>
                                        <p className='mb-2 text-[#a4a4a4]'>Description in {data?.language?.name}</p>
                                        <Textarea
                                            placeholder=' '
                                            value={data?.question}
                                            index={id}
                                            className={'!bg-white w-full !resize-y'}
                                            rows={2}
                                            textareaType='description-language'
                                            onChangeTextarea={onChangeQuestionDescriptionValue}
                                        />
                                    </>
                                }
                            </div>
                        )
                    })}
                    {((languagesData?.length || 0) < (languages?.length || 0)) &&
                        <div>
                            <Button
                                label={'Add New Language'}
                                dataQa={'add-new-language'}
                                onClickButton={onAddNewLanguage}
                                className='btn-main mb-5 mt-2' />
                        </div>
                    }
                </div>
                <div className='flex flex-row justify-end'>
                    <Button
                        className='btn-main !rounded ml-3'
                        label={'Close'}
                        onClickButton={() => onCloseModal()}
                    />
                    <Button
                        className='btn-primary !rounded ml-3'
                        label={'Save Changes'}
                        onClickButton={onSaveChanges}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default AnswerDescriptionModal;