import React from 'react';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IComparisonPricingFilters {
    markets?: Array<any>;
    subbrands?: Array<any>;
    sectors?: Array<any>;
    selectedMarket?: any;
    selectedSubbrands?: any;
    selectedSector?: any;
    onChangeSelectedFilter: (e: any, name?: string) => void;
}

const ComparisonPricingFilters = ({
    markets,
    subbrands,
    sectors,
    selectedMarket,
    selectedSubbrands,
    selectedSector,
    onChangeSelectedFilter
}: IComparisonPricingFilters) => {
    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-2 lg:gap-4'>
            <SelectCheckbox
                name='Category'
                dataQa={'category-selected'}
                placeholder=' '
                containerStyle='mb-1 min-w-full'
                selectedOption={selectedSector}
                onChangeSelectedOption={onChangeSelectedFilter}
                uniqueName={'sector'}
                options={sectors || []}
            />
            <SelectCheckbox
                name='Market'
                dataQa={'market-selected'}
                placeholder=' '
                containerStyle='mb-1 min-w-full'
                selectedOption={selectedMarket}
                onChangeSelectedOption={onChangeSelectedFilter}
                uniqueName={'market'}
                options={markets || []}
            />
            <SelectCheckbox
                name='Sub Brands'
                dataQa={'subbrands-selected'}
                placeholder=' '
                containerStyle='mb-1 min-w-full'
                selectedOption={selectedSubbrands}
                onChangeSelectedOption={onChangeSelectedFilter}
                uniqueName={'subbrands'}
                options={subbrands || []}
            />
        </div>
    )
}
export default ComparisonPricingFilters