import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToEditActions } from '../../../../store/brokers/admin/sections/sectionsSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';
import Error from '../../../../shared/error';


interface IAddActionModal {
    openAddActionModal: boolean;
    pageId?: string;
    childId?: string;
    handleCloseAddActionModal: () => void;
    onSaveChanges: () => void;
}

const AddActionModal = ({
    openAddActionModal,
    childId,
    handleCloseAddActionModal,
    onSaveChanges,
}: IAddActionModal) => {
    const dispatch = useAppDispatch()
    const [newActionName, setNewActionName] = useState<string>();
    const [newEndpoint, setNewEndpoint] = useState<string>()
    const [editEndpoint, setEditEndpoint] = useState<string>();
    const [editAction, setEditAction] = useState<string>();
    const [newEditAction, setNewEditAction] = useState<string>()
    const [editEndpointAction, setEditEndpointAction] = useState<string>();
    const [newEditEndpoint, setNewEditEndpoint] = useState<string>()
    const [actions, setActions] = useState<any>(null)
    const [generalError, setGeneralError] = useState<string>()
    const childDetails = useAppSelector(state => state?.sections?.sectionDetails?.children?.find(child => child._id === childId))

    useEffect(() => {
        setActions(null)
        if (childDetails?.actions) {
            setActions({ ...childDetails.actions })
        }
    }, [childDetails, childId])

    const onAddAction = async () => {
        if (newActionName && actions && actions?.[newActionName]) {
            setGeneralError('Action Name should be unique!')
            return;
        }
        if (newActionName && newEndpoint) {
            setActions({ ...actions, [newActionName]: newEndpoint })
            setNewActionName(undefined)
            setNewEndpoint(undefined)
            setGeneralError(undefined)
        }else{
            setGeneralError('You need to write Actiona name and Endpoint to continue')
        }
    }

    const onDeleteAction = (key: string) => {
        const newActions = { ...actions }
        delete newActions[key]
        setActions(newActions)
    }

    const onCloseModal = () => {
        setNewActionName(undefined)
        setNewEndpoint(undefined)
        setActions(undefined)
        setEditAction(undefined)
        setEditEndpoint(undefined)
        setGeneralError(undefined)
        setEditEndpointAction(undefined)
        handleCloseAddActionModal()
    }

    const editActions = async () => {
        const payload = {
            id: childId,
            data: actions
        }
        try {
            await dispatch(tryToEditActions(payload)).unwrap()
            onCloseModal()
            onSaveChanges()
        } catch (err) {
            setGeneralError(`${err}`)
        }
    }

    const onEditAction = (value: any, type?: string) => {
        if (type === 'actionName') {
            setNewEditAction(value)
        } else {
            setNewEditEndpoint(value)
        }
    }

    const onEnterAction = async (e: any, type?: string) => {
        e.stopPropagation()
        if (e?.key === 'Enter') {
            if (type === 'actionName') {
                if (editAction && newEditAction && actions) {
                    const newActions: any = Object.assign(actions)
                    delete Object.assign(newActions, { [newEditAction]: newActions[editAction] })[editAction];
                    setActions(newActions || [])
                }
            } else {
                const actionsAsArray = (actions) && Object.keys(actions)
                const findActionEndpoint = actionsAsArray && actionsAsArray?.length > 0 && actionsAsArray?.find((item: any) => actions[item] === editEndpoint)
                const newActions: any = Object.assign(actions)
                newActions[findActionEndpoint] = newEditEndpoint
                setActions(newActions || [])
            }
            setNewEditAction(undefined)
            setEditAction(undefined)
            setEditEndpointAction(undefined)
            setNewEditEndpoint(undefined)
            setEditEndpoint(undefined)
        }
    }

    const onDoubleClickAction = (value: string, type: string) => {
        if (type === 'actionName') {
            setNewEditAction(value)
            setEditAction(value)
        } else {
            setEditEndpointAction(value)
            setEditEndpoint(actions?.[value])
            setNewEditEndpoint(actions?.[value])
        }
    }

    const onChangeMainItemInput = (value: any, type?: string) => {
        if (type === 'actionName') {
            setNewActionName(value)
        } else {
            setNewEndpoint(value)
        }

    }

    const actionsArray = (actions) && Object.keys(actions)
    return (
        <Modal
            open={openAddActionModal}
            onClose={onCloseModal}
        >
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4 font-bold'>Add Action</p>
                {generalError && <Error text={generalError} />}
                <div className='flex flex-col my-4'>
                    <div className='flex flex-row gap-x-3 items-center justify-items-stretch'>
                        <Input
                            placeholder='Action Name'
                            dataQa={'name'}
                            label={'Action Name'}
                            showValue={true}
                            inputValue={newActionName}
                            inputUniqueName='actionName'
                            onChangeInput={onChangeMainItemInput}
                        />
                        <Input
                            placeholder='Endpoint'
                            dataQa={'action'}
                            label={'Endpoint'}
                            showValue={true}
                            inputValue={newEndpoint}
                            inputUniqueName='endpoint'
                            onChangeInput={onChangeMainItemInput}
                        />
                        <Button
                            label='+'
                            dataQa={'submit'}
                            className={'btn-primary'}
                            onClickButton={onAddAction}
                        />
                    </div>
                    <div className='my-3'>
                        {(actionsArray && actionsArray?.length > 0) &&
                            <Table
                                hidePagination={true}
                                columns={['Action Name', 'Endpoint', 'Delete']}>
                                {actionsArray.map((obj: any, idx: number) => {
                                    return (
                                        <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                            <td className='px-6 py-2'>
                                                {editAction === obj ?
                                                    <div onClick={(e) => e.stopPropagation()}>
                                                        <Input
                                                            placeholder='Action Name'
                                                            showValue={true}
                                                            inputUniqueName='actionName'
                                                            inputValue={newEditAction}
                                                            onChangeInput={onEditAction}
                                                            onHandleKeyDown={onEnterAction}
                                                        />
                                                    </div>
                                                    : <div className='cursor-pointer' onDoubleClick={() => onDoubleClickAction(obj || '', 'actionName')}>
                                                        {obj}
                                                    </div>
                                                }
                                            </td>
                                            <td className='px-6 py-2'>
                                                {editEndpointAction === obj ?
                                                    <div onClick={(e) => e.stopPropagation()}>
                                                        <Input
                                                            placeholder='Endpoint'
                                                            showValue={true}
                                                            inputUniqueName='endpoint'
                                                            inputValue={newEditEndpoint}
                                                            onChangeInput={onEditAction}
                                                            onHandleKeyDown={onEnterAction}
                                                        />
                                                    </div>
                                                    : <div className='cursor-pointer' onDoubleClick={() => onDoubleClickAction(obj || '', 'endpoint')}>
                                                        {actions?.[obj]}
                                                    </div>
                                                }
                                            </td>
                                            <td className='px-6 py-2'>
                                                <div className='cursor-pointer' onClick={() => onDeleteAction(obj)}>
                                                    <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] ml-4 hover:w-[17px] object-contain'} />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </Table>
                        }
                    </div>
                    <div className='flex flex-row justify-end mt-4'>
                        <Button
                            label='Save'
                            dataQa={'save'}
                            className={'btn-main min-w-[150px]'}
                            onClickButton={() => editActions()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AddActionModal