import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Switch from '../../../../shared/switch';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';

const tableColumns = ['Email', 'Username', 'Name', 'Country', 'Access Role', 'Manage Role', 'Inventory Access Role', 'Manage Inventory Role', 'Status Enabled']

interface IAdminUsersTable {
    rows: Array<any>;
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    rowsLoading: boolean;
    onOpenAdminRole: (id: string, type: string, role?: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onEnableDisableAdmin: (id: string, type: boolean) => void;
}

const AdminUsersTable = ({
    rows,
    paginationData,
    accessControl,
    actions,
    rowsLoading,
    onOpenAdminRole,
    setPaginationState,
    onEnableDisableAdmin
}: IAdminUsersTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.email || ''}</td>
                                <td className='px-6 py-4'>{row?.username || ''}</td>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>{row?.country}</td>
                                <td className='px-6 py-4'>{row?.role}</td>
                                <td className='px-6'>
                                    <Button
                                        label={'Manage Role'}
                                        onClickButton={() => (!accessControl || actions?.['Admin Manage Role']) && onOpenAdminRole(row?._id || '', 'admins', row?.role)}
                                        className={`${(!accessControl || actions?.['Admin Manage Role']) ? 'btn-main' : ''} !shadow-none`}
                                        dataQa={'manage-role-admin'}
                                    />
                                </td>
                                <td className='px-6 py-4'>{row?.inventoryRole}</td>
                                <td className='px-6'>
                                    <Button
                                        label={'Manage Inventory Role'}
                                        onClickButton={() => (!accessControl || actions?.['Admin Manage Inventory Role']) && onOpenAdminRole(row?._id || '', 'inventory-admin', row?.inventoryRole)}
                                        className={`${(!accessControl || actions?.['Admin Manage Inventory Role']) ? 'btn-main' : ''} !shadow-none`}
                                        dataQa={'admin-manage-inventory-role'}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <Switch
                                        checked={!!row?.enabled}
                                        onToggleSwitch={() => ((!accessControl || actions?.['Admin Enable Disable']) && row?._id) && onEnableDisableAdmin(row?._id, !row?.enabled)}
                                        dataQa={'status-enabled-admin'}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default AdminUsersTable;