import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Table from '../../../../shared/table';
import Error from '../../../../shared/error';


interface IBreakCashoutModal {
    openModal: boolean;
    loadingSubmit?: boolean;
    questions?: Array<{ question: string, answer: any }>;
    rejectError?: string;
    onRejectItem: () => void;
    handleCloseModal: () => void;
}

const BreakCashoutModal = ({
    openModal,
    loadingSubmit,
    questions,
    rejectError,
    handleCloseModal,
    onRejectItem,
}: IBreakCashoutModal) => {
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mb-4 font-bold text-center text-sm border-b-1'>Break Cashout</p>
                <div className='mt-2 mb-5 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                {rejectError && <Error text={rejectError} />}
                <div className='my-4'>
                    We can&apos;t process your review due to certain answers. You have the option to either reject the item or change your answers accordingly.
                </div>
                {questions && questions?.length > 0 &&
                    <Table
                        hidePagination={true}
                        columns={['Question', 'Answer', 'Message']}>
                        {questions?.map((item: { question: string, answer: any }, index: number) => {
                            return (
                                <tr key={index} className='bg-white border-b hover:bg-gray-50'>
                                    <td className='px-6 py-2'>
                                        {item?.question}
                                    </td>
                                    <td className='px-6 py-2'>
                                        {item?.answer?.answer}
                                    </td>
                                    <td className='px-6 py-2'>
                                        {item?.answer?.breakCashoutDescription}
                                    </td>
                                </tr>
                            )
                        })}
                    </Table>
                }
                <div className='flex flex-row justify-end mt-7 mb-5'>
                    <Button
                        label={'Continue'}
                        className={`mr-3 ${loadingSubmit ? 'btn-main-disable' : 'btn-main'} !rounded`}
                        onClickButton={() => !loadingSubmit && handleCloseModal()}
                    />
                    <Button
                        label={'Reject Item'}
                        className={`${loadingSubmit ? 'btn-primary-disable' : 'btn-primary'}`}
                        onClickButton={() => !loadingSubmit && onRejectItem()}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default BreakCashoutModal;