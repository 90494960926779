import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import { PaginatedMarkets } from '../../../../interfaces/markets/paginatedStore.type';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';

const tryToFetchMarkets = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
): Promise<AxiosResponse<PaginatedMarkets>> => {
    const url = `${USERS_API_URL}/dom/markets/all?page=${pageNumber}&size=${pageSize}`
    return brokersAxios.get<PaginatedMarkets>(url);
};

const tryToFetchFilteredMarkets = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
): Promise<AxiosResponse<PaginatedMarkets>> => {
    const url = `${USERS_API_URL}/dom/markets/all/filter?page=${pageNumber}&size=${pageSize}`
    return brokersAxios.put<PaginatedMarkets>(url, { filters: { search: filters } });
};

const tryToFetchBrokerMarkets = (): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/users/markets/operating`);
};

const tryToAddMarket = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/markets`, data);
};

const tryToDisableMarket = (marketId: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/markets/${marketId}`);
};

const tryToFetchSingleMarket = (marketId: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/dom/markets/${marketId}`);
};

const tryToEditMarket = (marketId: string, payload: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/markets/${marketId}`, payload);
};

const tryToFetchActiveCatalogsForMarket = (marketId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/markets/${marketId}/catalogs`);
};
const tryToAddCatalogToMarket = (marketId: string, payload: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/markets/${marketId}/add-catalog`, payload);
};
const tryToRemoveCatalogFromMarket = (marketId: string, payload: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/markets/${marketId}/remove-catalog`, payload);
};

export const MarketsService = {
    tryToFetchMarkets,
    tryToAddMarket,
    tryToDisableMarket,
    tryToFetchSingleMarket,
    tryToFetchBrokerMarkets,
    tryToFetchFilteredMarkets,
    tryToEditMarket,
    tryToFetchActiveCatalogsForMarket,
    tryToAddCatalogToMarket,
    tryToRemoveCatalogFromMarket
};
