import { AxiosResponse } from 'axios';
import { ASSETS_API_URL, INVENTORY_SERVICE_URL } from '../../../../config';
import { IPurchase } from '../../../../interfaces/purchases/IPurchase';
import { PaginatedPurchases } from '../../../../interfaces/purchases/paginatedStore.type';
import inventoryAxios from '../../../../utils/axios/inventory.axios';
import assetsAxios from '../../../../utils/axios/assets.axios';

const tryToFetchPurchases = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedPurchases>> => {
    const url = `${INVENTORY_SERVICE_URL}/broker-purchases?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedPurchases>(url, data);
};

const tryToFetchSinglePurchase = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_SERVICE_URL}/broker-purchases/${id}`);
};

const tryToEditPurchase = (id: string, data: IPurchase): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_SERVICE_URL}/broker-purchases/${id}`, data);
};

const tryToCreatePurchase = (data: any): Promise<AxiosResponse> => {
    return inventoryAxios.post(`${INVENTORY_SERVICE_URL}/broker-purchases`, data);
};

const tryToDeletePurchase = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.delete(`${INVENTORY_SERVICE_URL}/broker-purchases/${id}`);
};

const tryToFetchMainOrder = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_SERVICE_URL}/broker-purchases/${id}/main-view`)
};

const tryToSubmitPurchase = (purchaseId: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_SERVICE_URL}/broker-purchases/${purchaseId}/status/submitted`)
};

const tryToAcceptOrRejectPurchase = (purchaseId: string, status: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_SERVICE_URL}/broker-purchases/${purchaseId}/status/${status}`, data)
};

const tryToValidatedImportedItems = (assetTemplateId: string, data: any): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/assets/${assetTemplateId}/values/validate`, data)
};

const tryToDownloadPurchaseSamples = (id: string, data: any): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/assets/${id}/values/download`, data)
};

const tryToImportBatchPurchase = (data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_SERVICE_URL}/broker-purchases/import/batch`, data)
};

export const PurchaseOrdersService = {
    tryToFetchPurchases,
    tryToFetchSinglePurchase,
    tryToEditPurchase,
    tryToCreatePurchase,
    tryToDeletePurchase,
    tryToSubmitPurchase,
    tryToValidatedImportedItems,
    tryToDownloadPurchaseSamples,
    tryToFetchMainOrder,
    tryToImportBatchPurchase,
    tryToAcceptOrRejectPurchase
};