import React, { useState } from 'react';
import moment from 'moment';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { onFormatPurchaseStatus } from '../../../../utils/constants/purchase-statuses';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import TableActions from '../../../../shared/table/table-actions';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';


interface IPurchaseOrdersTable {
    rows: any[];
    rowsLoading: boolean;
    brokerCategories?: Array<any>;
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    onSeeOffersHistory: (id: string, offers: any) => void;
    onClickStatusChange: (id: string, type: string, data?: any) => void;
    onSeeDetails: (id: string) => void;
    onClickDownloadOrder: (items: any) => void;
    onClickSeeOffer: (item: any) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSeeAllCategories: (categories: any) => void
}

const PurchaseOrdersTable = ({
    rows,
    brokerCategories,
    rowsLoading,
    paginationData,
    accessControl,
    actions,
    onClickSeeOffer,
    onSeeOffersHistory,
    setPaginationState,
    onClickStatusChange,
    onClickDownloadOrder,
    onSeeDetails,
    onClickSeeAllCategories
}: IPurchaseOrdersTable) => {
    const [tableColumns] = useState<Array<string>>(['Purchase Quote ID', 'Categories', 'VAT Scheme', 'Status', 'Willing to Negotiate', 'Total Price', 'Quantity', 'Due Date (dd-mm-yyyy)', 'Warranty', 'Manage Offers', 'Action'])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}>
            {(!tableColumns || rowsLoading) ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        const totalPrice = row?.expectedItems && row?.expectedItems?.length > 0 && row?.expectedItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.price * currentValue?.qty), 0)
                        const findCurrency = (row?.expectedItems && row?.expectedItems?.length > 0) && row?.expectedItems?.[0]?.currency
                        const value = onFormatPurchaseStatus(row?.status)?.name
                        const color = onFormatPurchaseStatus(row?.status)?.color
                        const text = onFormatPurchaseStatus(row?.status)?.text || 'text-black'
                        const item = color && <div className={`${color} border flex flex-row justify-center text-center px-1 min-w-[150px] ${text} text-sm py-1 font-medium !rounded-[6px]`}>{value}</div>
                        const totalQuantity = (row?.expectedItems && row?.expectedItems?.length > 0) && row?.expectedItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.qty), 0)
                        const latestOffer = (row?.offers && row?.offers?.length > 0) && row?.offers.reduce((max: any, obj: any) => { return obj.counter > max.counter ? obj : max });
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.quoteId || '--'}</td>
                                <td className='pl-6 pr-2 py-4'>
                                    <div
                                        onClick={() => row?.categories?.length > 2 && onClickSeeAllCategories(row?.categories)}
                                        className='flex flex-row flex-wrap items-center'>
                                        {(row?.categories && row?.categories?.length > 0 && brokerCategories && brokerCategories?.length > 0) && row?.categories?.filter((item: any, index: number) => index < 2)?.map((item: any) => {
                                            const findCategory = (brokerCategories && brokerCategories?.length > 0) && brokerCategories?.find((category: any) => category?.id === item?.id)
                                            return (
                                                <img src={findCategory?.enabledIcon} className='object-contain h-[23px] min-h-[23px] mr-2' key={item?.id} />
                                            )
                                        })}
                                        {row?.categories?.length > 2 ? <p className='font-bold'>...</p> : ''}
                                    </div>
                                </td>
                                <td className='px-6 py-4'>{row?.purchaseInfo?.stockVat || '--'}</td>
                                <td className='px-6 py-4'>{item}</td>
                                <td className='px-6 py-4'>{row?.negotiateOffer ? 'Yes' : 'No'}</td>
                                <td className={`px-6 py-4 ${!row?.batch ? 'min-w-[150px]' : 'min-w-[200px]'}`}>
                                    {!row?.batch ?
                                        <>
                                            {CURRENCY_SYMBOLS?.[`${(findCurrency || '')?.toLowerCase()}`]}{(totalPrice || 0).toFixed(2)}
                                        </>
                                        : <div>
                                            <p>Initial Offer: {totalPrice || row?.offerPrice ? `${CURRENCY_SYMBOLS?.[`${(findCurrency || '')?.toLowerCase()}`]}${Number(row?.offerPrice || totalPrice || 0).toFixed(2)}` : '--'}</p>
                                            {!row?.totalOfferPrice && latestOffer && latestOffer?.price && <p>Latest Offer: {CURRENCY_SYMBOLS?.[`${(latestOffer?.currency || findCurrency || '')?.toLowerCase()}`]}{Number(latestOffer?.price || 0)?.toFixed(2)}</p>}
                                            {row?.totalOfferPrice && <p>Final Offer: {CURRENCY_SYMBOLS?.[`${(findCurrency || '')?.toLowerCase()}`]}{Number(row?.totalOfferPrice || 0)?.toFixed(2)}</p>}
                                        </div>
                                    }
                                </td>
                                <td className='px-6 py-4'>{totalQuantity || '--'}</td>
                                <td className='px-6 py-4 min-w-[120px]'>{row?.purchaseInfo?.dueDate ? moment(row?.purchaseInfo?.dueDate).format('DD-MM-YYYY') : '--'}</td>
                                <td className='px-6 py-4'>{row?.purchaseInfo?.warranty || '--'}</td>
                                <td className='px-6 py-2 min-w-[140px]'>
                                    <Button
                                        label={'See Offer'}
                                        className={`${row?.batch && row?.status === 'offer_submitted' ? 'btn-main' : 'btn-primary-disable'} !rounded !shadow-none`}
                                        onClickButton={() => (row?.batch && row?.status === 'offer_submitted' && row?._id && (!accessControl || actions?.['Manage Offer'])) && onClickSeeOffer(row)}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-start'>
                                        <div onClick={() => row?._id && (!accessControl || actions?.['See Purchase Order Details']) && onSeeDetails(row?._id)}>
                                            <img title={'See Purchase Order Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`w-[20px] min-w-[20px] mr-3 mt-1 object-contain ${(!accessControl || actions?.['See Purchase Order Details']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                        </div>
                                        <TableActions>
                                            {row?.status === 'draft' &&
                                                <>
                                                    <div
                                                        onClick={() => (!accessControl || actions?.['Submit Purchase Order']) && onClickStatusChange(row?._id || '', 'submit_draft')}
                                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                                        role='none'>
                                                        <Button
                                                            label={'Submit Purchase Order'}
                                                            dataQa={'submit-purchase'}
                                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Submit Purchase Order']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={() => (!accessControl || actions?.['Edit Purchase Order']) && onClickStatusChange(row?._id || '', 'edit_draft')}
                                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                                        role='none'>
                                                        <Button
                                                            label={'Edit Purchase Order'}
                                                            dataQa={'received'}
                                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Edit Purchase Order']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                        />
                                                    </div>
                                                </>
                                            }
                                            {(['broker_offer_submitted', 'offer_submitted']?.includes(row?.status) && row?.batch && row?.negotiateOffer && row?.offers && row?.offers?.length > 0) &&
                                                <div
                                                    onClick={() => (!accessControl || actions?.['Offers History']) && onSeeOffersHistory(row?._id || '', row?.offers)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                                    role='none'>
                                                    <Button
                                                        label={'See Offers History'}
                                                        dataQa={'offers'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal word-wrap !text-[#000000] ${(!accessControl || actions?.['Offers History']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {(row?.status !== 'draft' && !row?.batch) &&
                                                <div
                                                    onClick={() => (!accessControl || actions?.['Create Draft Order']) && onClickStatusChange(row?._id || '', 'create_copy')}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                                    role='none'>
                                                    <Button
                                                        label={'Create Draft Order with same Items'}
                                                        dataQa={'copy-order'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal word-wrap !text-[#000000] ${(!accessControl || actions?.['Create Draft Order']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            <div
                                                onClick={() => (!accessControl || actions?.['Download Order CSV']) && onClickDownloadOrder(row?.expectedItems)}
                                                className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'
                                                role='none'>
                                                <Button
                                                    label={'Download Order CSV'}
                                                    dataQa={'download-order-csv'}
                                                    className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Download Order CSV']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                />
                                            </div>
                                            {row?.status === 'draft' &&
                                                <div
                                                    onClick={() => (!accessControl || actions?.['Delete Draft']) && onClickStatusChange(row?._id || '', 'delete_draft')}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                                    role='none'>
                                                    <Button
                                                        label={'Delete Draft'}
                                                        dataQa={'received'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Delete Draft']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                        </TableActions>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default PurchaseOrdersTable;