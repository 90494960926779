import React from 'react';
import NewRentalPricingTable from '../tables/NewRentalPricingTable';


interface INewRentalPricingFields {
    formData?: any;
    totalRows?: number;
    filteredFields?: any;
    loadingOptions?: any;
    templateFields?: any;
    loadingFields?: any;
    onDeleteItemRow: (type: string, value?: any) => void;
    onSelectFieldValue: (value: any, type?: string, item?: string, index?: any) => void;
    onAddNewRow: () => void;
    onChangeSearchFieldMultiple: (e: any, type?: string, index?: any) => void;
    onMenuCloseAfterSearchMulti: (type?: string, index?: any) => void;
    onChangePrice: (e: any, type?: string, index?: any) => void;
}

const NewRentalPricingFields = ({
    formData,
    totalRows,
    filteredFields,
    loadingOptions,
    templateFields,
    loadingFields,
    onDeleteItemRow,
    onAddNewRow,
    onSelectFieldValue,
    onMenuCloseAfterSearchMulti,
    onChangeSearchFieldMultiple,
    onChangePrice
}: INewRentalPricingFields) => {
    return (
        <div className='mt-5 mb-10'>
            <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Fields</p>
            <div className='my-2 mb-[30px] content-start'>
                <NewRentalPricingTable
                    rows={totalRows}
                    templateFields={templateFields}
                    loadingOptions={loadingOptions}
                    filteredMultiFields={filteredFields}
                    formValues={formData}
                    loadingFields={loadingFields}
                    columns={templateFields && templateFields?.length > 0 && [...templateFields?.map?.((item: any) => item?.name) || [], 'Price', 'Delete Row']}
                    onDeleteItemRow={onDeleteItemRow}
                    onChangeSearchFieldMultiple={onChangeSearchFieldMultiple}
                    onMenuCloseAfterSearchMulti={onMenuCloseAfterSearchMulti}
                    onChangeSelectedFieldValue={onSelectFieldValue}
                    onChangePrice={onChangePrice}
                    onAddNewRow={onAddNewRow}
                />
            </div>
        </div>
    )
}

export default NewRentalPricingFields;