
import { createApi } from '@reduxjs/toolkit/query/react';
import { IOrders } from '../../../../interfaces/orders/IOrders';
import { PaginatedOrders } from '../../../../interfaces/orders/paginatedStore.type';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ordersAxiosQuery } from '../../../../utils/axios/orders.axios';

const shipmentsUrl = (payload: any, path: string = '', cs: boolean = false) => {
    const { pageNumber, pageSize, filters } = payload;

    let url: string;

    if (!cs) {
        url = `/shipments${path}?page=${pageNumber}&size=${pageSize}`;
    } else {
        url = `/support/shipments${path}?page=${pageNumber}&size=${pageSize}`;
    }

    if (filters) {
        url += `&filters=${filters}`;
    }
    return url;
};

const shipmentsUrlNoPagination = (path: string = '', cs: boolean = false) => {
    let url: string;

    if (!cs) {
        url = `/shipments${path}`;
    } else {
        url = `/support/shipments${path}`;
    }
    return url;
};

export const shipmentsAPI = createApi({
    reducerPath: 'shipmentsAPI',
    baseQuery: ordersAxiosQuery(),
    endpoints: (builder) => ({
        getShipmentsForCS: builder.mutation<PaginatedOrders, IPaginationPayload>({
            query: (payload: IPaginationPayload) => ({
                url: shipmentsUrl(payload, '', true),
                method: 'PUT',
                data: payload?.data
            }),
        }),
        getAllShipments: builder.mutation<PaginatedOrders, IPaginationPayload>({
            query: (payload: IPaginationPayload) => ({
                url: shipmentsUrl(payload, '/all'),
                method: 'PUT',
                data: payload?.data
            }),
        }),
        getAllInboundShipments: builder.mutation<PaginatedOrders, IPaginationPayload>({
            query: (payload: IPaginationPayload) => ({
                url: shipmentsUrl(payload, '/inbound'),
                method: 'PUT',
                data: payload?.data
            }),
        }),
        getAllCashouts: builder.mutation<PaginatedOrders, IPaginationPayload>({
            query: (payload: IPaginationPayload) => ({
                url: shipmentsUrl(payload, '/cashouts'),
                method: 'PUT',
                data: payload?.data
            }),
        }),
        getAllShipmentsInReview: builder.mutation<PaginatedOrders, IPaginationPayload>({
            query: (payload: IPaginationPayload) => ({
                url: shipmentsUrl(payload, '/in-review'),
                method: 'PUT',
                data: payload?.data
            }),
        }),
        getAllShipmentsInCs: builder.mutation<PaginatedOrders, IPaginationPayload>({
            query: (payload: IPaginationPayload) => ({
                url: shipmentsUrl(payload, '/in-cs'),
                method: 'PUT',
                data: payload?.data
            }),
        }),
        getAllResolvedOrders: builder.mutation<PaginatedOrders, IPaginationPayload>({
            query: (payload: IPaginationPayload) => ({
                url: shipmentsUrl(payload, '/resolved'),
                method: 'PUT',
                data: payload?.data
            }),
        }),
        getAllRecentOrders: builder.mutation<PaginatedOrders, IPaginationPayload>({
            query: (payload: IPaginationPayload) => ({
                url: shipmentsUrl(payload, '/recent-changes'),
                method: 'PUT',
                data: payload?.data
            }),
        }),
        getAllOrdersNoPagination: builder.mutation<IOrders[], any>({
            query: (payload?: any) => ({
                url: shipmentsUrlNoPagination('/all'),
                method: 'PUT',
                data: {
                    download: true
                }
            }),
        }),
        getAllOrdersInCsNoPagination: builder.mutation<IOrders[], any>({
            query: (data?: any) => ({
                url: shipmentsUrlNoPagination('/in-cs'),
                method: 'PUT',
                data: {
                    download: true
                }
            }),
        }),
        getAllOrdersInReviewNoPagination: builder.mutation<IOrders[], any>({
            query: (data?: any) => ({
                url: shipmentsUrlNoPagination('/in-review'),
                method: 'PUT',
                data: {
                    download: true
                }
            }),
        }),
        getAllResolvedOrdersNoPagination: builder.mutation<IOrders[], any>({
            query: (data?: any) => ({
                url: shipmentsUrlNoPagination('/resolved'),
                method: 'PUT',
                data: {
                    download: true
                }
            }),
        }),
        getAllRecentOrdersNoPagination: builder.mutation<IOrders[], any>({
            query: (data?: any) => ({
                url: shipmentsUrlNoPagination('/recent-changes'),
                method: 'PUT',
                data: {
                    download: true
                }
            }),
        }),
        getAllInboundOrdersNoPagination: builder.mutation<IOrders[], any>({
            query: (data?: any) => ({
                url: shipmentsUrlNoPagination('/inbound'),
                method: 'PUT',
                data: {
                    download: true
                }
            }),
        }),
        getAllRejectedOrders: builder.mutation<PaginatedOrders, any>({
            query: (payload: any) => ({
                url: shipmentsUrlNoPagination('/resolved'),
                method: 'PUT',
                data: payload?.data
            }),
        }),
    }),
});

export const {
    useGetAllShipmentsInCsMutation,
    useGetAllShipmentsMutation,
    useGetAllInboundShipmentsMutation,
    useGetShipmentsForCSMutation,
    useGetAllShipmentsInReviewMutation,
    useGetAllResolvedOrdersMutation,
    useGetAllOrdersNoPaginationMutation,
    useGetAllResolvedOrdersNoPaginationMutation,
    useGetAllOrdersInReviewNoPaginationMutation,
    useGetAllInboundOrdersNoPaginationMutation,
    useGetAllOrdersInCsNoPaginationMutation,
    useGetAllRejectedOrdersMutation,
    useGetAllCashoutsMutation
} = shipmentsAPI;