import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ICSAgents } from '../../../../interfaces/cs-agents/cs-agents';
import { ICSAgentsListState } from '../../../../interfaces/cs-agents/ICSAgentsList';
import { ICSAgentsSchema } from '../../../../interfaces/cs-agents/ICSAgentsSchema';
import { PaginatedCSAgents } from '../../../../interfaces/cs-agents/paginatedStore.type';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { csAgentsService } from './CSAgentsApi';

const initialState: ICSAgentsListState = {
    csAgentsAreLoading: false,
    adminIsLoading: false,
    adminsAreLoading: false,
    toastSuccessRequest: false,
    csAgentIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToCreateCsUser = createAsyncThunk<
    ApiResponse<ICSAgentsSchema>,
    ICSAgents
>(
    'csAgents/tryToCreateCsUser',
    async (data: ICSAgents, { rejectWithValue }) => {
        try {
            const result = await csAgentsService.tryToCreateCsUser(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

// tryToFindAllCsUsers
export const tryToFindAllCsUsers = createAsyncThunk<
    PaginatedCSAgents,
    IPaginationPayload
>('csAgents/tryToFindAllCsUsers', async ({ pageNumber, pageSize, filters }) => {
    const result = await csAgentsService.tryToFindAllCsUsers(
        pageNumber,
        pageSize,
        filters,
    );
    return result?.data;
});

// tryToFetchSingleCsUser
export const tryToFetchSingleCsUser = createAsyncThunk<ICSAgents, string>(
    'csAgents/tryToFetchSingleCsUser',
    async (id: string) => {
        const result = await csAgentsService.tryToFetchSingleCsUser(id);
        return result?.data?.data;
    },
);

export const tryToEnableCsUsers = createAsyncThunk<
    ApiResponse<ICSAgentsSchema>,
    any
>(
    'csAgents/tryToEnableCsUsers',
    async ({ id, data }: any, { rejectWithValue }) => {
        try {
            const result = await csAgentsService.tryToEnableCsUsers({
                id,
                data,
            });
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

// tryToDeleteCsUser
export const tryToDeleteCsUser = createAsyncThunk<
    ApiResponse<ICSAgentsSchema>,
    string
>('csAgents/tryToDeleteCsUser', async (id, { rejectWithValue }) => {
    try {
        const result = await csAgentsService.tryToDeleteCsUser(id);
        return result?.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});


export const tryToFindAllAdmins = createAsyncThunk<PaginatedCSAgents, IPaginationPayload>(
    'csAgents/tryToFindAllAdmins',
    async ({ pageNumber, pageSize, filters }) => {
        const result = await csAgentsService.tryToFindAllAdmins(
            pageNumber,
            pageSize,
            filters,
        );
        return result?.data;
    }
);

export const tryToFetchSingleAdmin = createAsyncThunk<ICSAgents, string>(
    'csAgents/tryToFetchSingleAdmin',
    async (id: string) => {
        const result = await csAgentsService.tryToFetchSingleAdmin(id);
        return result?.data?.data;
    },
);

export const tryToCreateAdmin = createAsyncThunk<ApiResponse<ICSAgentsSchema>, ICSAgents>(
    'csAgents/tryToCreateAdmin',
    async (data: ICSAgents, { rejectWithValue }) => {
        try {
            const result = await csAgentsService.tryToCreateAdmin(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEnableDisableAdmin = createAsyncThunk<ApiResponse<ICSAgentsSchema>,any>(
    'csAgents/tryToEnableDisableAdmin',
    async ({ id, data }: any, { rejectWithValue }) => {
        try {
            const result = await csAgentsService.tryToEnableDisableAdmin({
                id,
                data,
            });
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const csAgentsSlice = createSlice({
    name: 'csAgents',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToCreateCsUser
            .addCase(tryToCreateCsUser.pending, (state) => {
                state.csAgentIsLoading = true;
            })
            .addCase(tryToCreateCsUser.rejected, (state) => {
                state.csAgentIsLoading = false;
            })
            .addCase(tryToCreateCsUser.fulfilled, (state, action) => {
                state.csAgentIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.csAgents?.data)
                    state.csAgents.data.elements = [
                        ...state.csAgents?.data.elements,
                        data,
                    ];
            })

            // tryToFindAllCsUsers
            .addCase(tryToFindAllCsUsers.pending, (state) => {
                state.csAgentsAreLoading = true;
            })
            .addCase(tryToFindAllCsUsers.rejected, (state) => {
                state.csAgentsAreLoading = false;
            })
            .addCase(tryToFindAllCsUsers.fulfilled, (state, action) => {
                state.csAgentsAreLoading = false;
                state.csAgents = action.payload;
            })

            // tryToFindAllAdmins
            .addCase(tryToFindAllAdmins.pending, (state) => {
                state.adminsAreLoading = true;
            })
            .addCase(tryToFindAllAdmins.rejected, (state) => {
                state.adminsAreLoading = false;
            })
            .addCase(tryToFindAllAdmins.fulfilled, (state, action) => {
                state.adminsAreLoading = false;
                state.admins = action.payload;
            })

            // tryToFetchSingleAdmin
            .addCase(tryToFetchSingleAdmin.pending, (state) => {
                state.adminIsLoading = true;
            })
            .addCase(tryToFetchSingleAdmin.rejected, (state) => {
                state.adminIsLoading = false;
            })
            .addCase(tryToFetchSingleAdmin.fulfilled, (state, action) => {
                state.adminIsLoading = false;
                state.admin = action.payload;
            })

            // tryToCreateAdmin
            .addCase(tryToCreateAdmin.pending, (state) => {
                state.adminIsLoading = true;
            })
            .addCase(tryToCreateAdmin.rejected, (state) => {
                state.adminIsLoading = false;
            })
            .addCase(tryToCreateAdmin.fulfilled, (state, action) => {
                state.adminIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.admins?.data)
                    state.admins.data.elements = [
                        data || {},
                        ...state.admins?.data.elements || [],
                    ];
            })

             // tryToEnableDisableAdmin
             .addCase(tryToEnableDisableAdmin.pending, (state) => {
                state.adminIsLoading = true;
            })
            .addCase(tryToEnableDisableAdmin.rejected, (state) => {
                state.adminIsLoading = false;
            })
            .addCase(tryToEnableDisableAdmin.fulfilled, (state, action) => {
                state.adminIsLoading = false;
                state.requestStatus = 'success';
                if (state.admins?.data.elements) {
                    state.admins.data.elements =
                        { ...state }.admins?.data.elements.map(
                            (csAgent: any) => {
                                if (csAgent?._id === action.meta?.arg.id) {
                                    return {
                                        ...csAgent,
                                        enabled: action.meta.arg.data.enabled,
                                    };
                                } else {
                                    return csAgent;
                                }
                            },
                        ) || [];
                }
            })

            // tryToFetchSingleCsUser
            .addCase(tryToFetchSingleCsUser.pending, (state) => {
                state.csAgentIsLoading = true;
            })
            .addCase(tryToFetchSingleCsUser.rejected, (state) => {
                state.csAgentIsLoading = false;
            })
            .addCase(tryToFetchSingleCsUser.fulfilled, (state, action) => {
                state.csAgentIsLoading = false;
                state.csAgentsDetail = action.payload;
            })

            // tryToEnableCsUsers
            .addCase(tryToEnableCsUsers.pending, (state) => {
                state.csAgentIsLoading = true;
            })
            .addCase(tryToEnableCsUsers.rejected, (state) => {
                state.csAgentIsLoading = false;
            })
            .addCase(tryToEnableCsUsers.fulfilled, (state, action) => {
                state.csAgentIsLoading = false;
                state.requestStatus = 'success';
                if (state.csAgents?.data.elements) {
                    state.csAgents.data.elements =
                        { ...state }.csAgents?.data.elements.map(
                            (csAgent: any) => {
                                if (csAgent?._id === action.meta?.arg.id) {
                                    return {
                                        ...csAgent,
                                        enabled: action.meta.arg.data.enable,
                                    };
                                } else {
                                    return csAgent;
                                }
                            },
                        ) || [];
                }
            })

            // tryToDeleteCSUser
            .addCase(tryToDeleteCsUser.rejected, (state) => {
                state.csAgentIsLoading = false;
                state.requestStatus = 'error';
            })
            .addCase(tryToDeleteCsUser.fulfilled, (state, action) => {
                state.csAgentIsLoading = false;
                state.requestStatus = 'success';
                if (state.csAgents?.data.elements)
                    state.csAgents.data.elements =
                        { ...state }.csAgents?.data.elements.filter(
                            (CSAgent) => CSAgent._id !== action.meta.arg,
                        ) || [];
            });
    },
});

export default csAgentsSlice.reducer;
