import React from 'react';
import Table from '../../../../shared/table';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';


interface IMappingListFieldsTable {
    rows?: any;
    columns?: any;
    categoryValues?: any;
    mappedValues?: any;
    mappedAsFields?: any;
    errors?: any;
    filteredFields?: any;
    filteredMultiFields?: any;
    loadingOptions?: any;
    tempRows?: any;
    onDeleteMappedValues: (type: string, index: string) => void;
    onChangeSelectedFieldValue: (e: any, type?: string, index?: any, item?: string) => void;
    onMenuCloseAfterSearch: (type?: string, index?: any) => void;
    onMenuCloseAfterSearchMulti: (type?: string, index?: any) => void;
    onChangeSearchFieldValues: (e: any, type?: string, index?: any) => void;
    onChangeSearchFieldMultiple: (e: any, type?: string, index?: any) => void;
}

const MappingListFieldsTable = ({
    rows,
    columns,
    categoryValues,
    mappedValues,
    errors,
    mappedAsFields,
    tempRows,
    filteredFields,
    filteredMultiFields,
    loadingOptions,
    onDeleteMappedValues,
    onChangeSelectedFieldValue,
    onMenuCloseAfterSearch,
    onMenuCloseAfterSearchMulti,
    onChangeSearchFieldValues,
    onChangeSearchFieldMultiple
}: IMappingListFieldsTable) => {
    return (
        <Table
            headerColsStyle='border-r-2 last:border-r-0 pl-3 pr-6'
            columns={columns || []}
            hidePagination={true}
        >
            {rows && rows?.length > 0 && rows?.map((item: any, idx: number) => {
                const findSelected = mappedValues && mappedValues?.[item]
                const findError = errors && errors?.[item]
                const areOptionsLoading = loadingOptions?.index === idx && loadingOptions?.[item]
                const findValues = filteredFields && filteredFields?.length > 0 && filteredFields?.find((obj: any) => obj?.index === idx)
                const values = (mappedAsFields && mappedAsFields?.length === 1) ? (findValues ?
                    findValues?.values &&
                    findValues?.values?.length > 0 &&
                    findValues?.values?.map((value: string) => {
                        return { value: value, label: value };
                    })
                    :
                    categoryValues &&
                    categoryValues?.length > 0 &&
                    categoryValues?.map((value: string) => {
                        return { value: value, label: value };
                    })
                ) : []
                return (
                    <tr key={idx} className='bg-white '>
                        {tempRows && Object.keys(tempRows) &&
                            <td className='max-w-[16px] w-[15px] p-1 border-r-2 border-b-2 '>
                                {tempRows?.[item] && <img src={'/assets/shared/check-mark-green.svg'} className={'mx-auto w-[14px] object-contain'} />}
                            </td>
                        }
                        <td className='p-2 align-center border-r-2 border-b-2 '>{item}</td>
                        {mappedAsFields && mappedAsFields?.length > 1 ?
                            <>
                                {mappedAsFields?.map((field: any, index: number) => {
                                    const fieldValueSelected = findSelected && findSelected[field]
                                    const findValuesMulti = (filteredMultiFields && filteredMultiFields?.length > 0) && filteredMultiFields?.find((obj: any) => obj?.index === idx)
                                    const valuesMapped = ((findValuesMulti && findValuesMulti?.values) || findSelected) ?
                                        findValuesMulti?.values?.[field] &&
                                        findValuesMulti?.values?.[field]?.length > 0 &&
                                        findValuesMulti?.values?.[field]?.map((value: string) => {
                                            return { value: value, label: value };
                                        })
                                        :
                                        categoryValues && categoryValues?.[field] &&
                                        categoryValues?.[field]?.length > 0 &&
                                        categoryValues?.[field]?.map((value: string) => {
                                            return { value: value, label: value };
                                        });
                                    return (
                                        <td key={index} className='p-2 align-center border-r-2 border-b-2 max-w-[240px] w-[240px]'>
                                            <AsyncSelectCheckbox
                                                placeholder={fieldValueSelected || ``}
                                                isLoading={false}
                                                dataQa={'select-options'}
                                                selectedOption={fieldValueSelected || undefined}
                                                options={valuesMapped || []}
                                                uniqueName={field}
                                                mainWrpStyle={'max-w-[240px]'}
                                                dropDownStyle={'max-w-[240px]'}
                                                loadingOptions={areOptionsLoading}
                                                onChangeValue={onChangeSearchFieldMultiple}
                                                onMenuClose={onMenuCloseAfterSearchMulti}
                                                hideRelative={true}
                                                dynamicOptions={true}
                                                index={idx}
                                                parent={item}
                                                onChangeSelectedOption={onChangeSelectedFieldValue}
                                                error={findError || undefined}
                                            />
                                        </td>
                                    )
                                })}
                            </>
                            :
                            <td data-qa={'model-placeholder'} className='p-2 align-center border-r-2 border-b-2 max-w-[240px] w-[240px]'>
                                <AsyncSelectCheckbox
                                    placeholder={findSelected || ``}
                                    isLoading={false}
                                    selectedOption={findSelected || undefined}
                                    options={values || []}
                                    dataQa={findSelected ===  undefined ? 'select' : findSelected}
                                    uniqueName={item}
                                    mainWrpStyle={'max-w-[240px]'}
                                    dropDownStyle={'max-w-[240px]'}
                                    loadingOptions={areOptionsLoading && false}
                                    onChangeValue={onChangeSearchFieldValues}
                                    onMenuClose={onMenuCloseAfterSearch}
                                    hideRelative={true}
                                    dynamicOptions={true}
                                    index={idx}
                                    onChangeSelectedOption={onChangeSelectedFieldValue}
                                    error={findError || undefined}
                                />
                            </td>
                        }
                        <td className='p-2 border-b-2 w-[40px]'>
                            <div
                                onClick={() => onDeleteMappedValues('one', item)}
                                data-qa={'delete-data'}
                                className='cursor-pointer max-w-[40px] flex flex-row justify-center'>
                                <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] hover:w-[17px] object-contain'} />
                            </div>
                        </td>
                    </tr>
                )
            })}
        </Table >
    )
}

export default MappingListFieldsTable;