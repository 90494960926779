import React from 'react';
import { IAddress } from '../../interfaces/auth/ICreateUserSchema';
import Button from '../button';
import WhiteContainer from '../white-container';

interface IAddressItem {
    address: IAddress;
    key?: number;
    onEditAddress?: () => void;
    onDeleteAddress?: () => void;
    onSetAddressAsDefault?: () => void;
    showActions?: boolean;
    hideDelete?: boolean;
}

const AddressItem = ({
    address,
    key,
    onEditAddress,
    onDeleteAddress,
    onSetAddressAsDefault,
    showActions,
    hideDelete
}: IAddressItem) => {
    return (
        <WhiteContainer containerStyle='max-w-[250px]' type='rounded' key={key}>
            <div className='w-full h-full flex flex-col items-start justify-start'>
                {showActions && <>
                    {address?.default ?
                        <p className='mb-2 font-md font-medium text-[#F7A21E] line-2'>Default</p>
                        : <Button
                            label='Set as default'
                            className={'btn-primary-text-underline !p-0 mb-2'}
                            onClickButton={onSetAddressAsDefault}
                        />
                    }
                </>
                }
                <p>{address?.street},{address?.addressNo}</p>
                <p>{address?.city},{address?.postalCode}</p>
                <p>{address?.country},{address?.state}</p>
                {showActions && <div className='mt-4 mb-2'>
                    <Button
                        label={'Edit'}
                        className={'btn-primary-text-underline'}
                        onClickButton={onEditAddress}
                    />
                    {!hideDelete && <Button
                        label={'Delete'}
                        className={'btn-primary-text-underline'}
                        onClickButton={onDeleteAddress}
                    />
                    }
                </div>
                }
            </div>
        </WhiteContainer>
    )
}

export default AddressItem;