import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { AxiosError } from 'axios';
import { ShippingServicesService } from './shippingServicesApi';
import { IShippingServicesListState } from '../../../../interfaces/shipping-services/IShippingServicesList';
import { IShippingServices } from '../../../../interfaces/shipping-services/IShippingServices';

const initialState: IShippingServicesListState = {
    shippingServiceIsLoading: false,
    shippingServicesAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchShippingServices = createAsyncThunk<any, any>(
    'shipping-services/tryToFetchShippingServices',
    async (data?: any) => {
        const result = await ShippingServicesService.tryToFetchShippingServices(data);
        return result?.data;
    }
);

export const tryToFetchShippingServicesGrid = createAsyncThunk<any>(
    'shipping-services/tryToFetchShippingServicesGrid', async () => {
        const result = await ShippingServicesService.tryToFetchShippingServicesGrid();
        return result?.data;
    }
);

export const tryToFetchMarketShippingServices = createAsyncThunk<ApiResponse<IShippingServices>, string>(
    'shipping-services/tryToFetchMarketShippingServices',
    async (id: string) => {
        const result = await ShippingServicesService.tryToFetchMarketShippingServices(id);
        return result?.data;
    },
);

export const tryToEditMarketShippingServices = createAsyncThunk<
    ApiResponse<any>,
    any
>('shipping-services/tryToEditMarketShippingServices',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ShippingServicesService.tryToEditMarketShippingServices(data.marketId || '', data?.data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToAddMarketShippingServices = createAsyncThunk<
    ApiResponse<any>,
    { marketId: string, data: any }
>('shipping-services/tryToAddMarketShippingServices',
    async (data: { marketId: string, data: IShippingServices }, { rejectWithValue }) => {
        try {
            const result = await ShippingServicesService.tryToAddMarketShippingServices(data?.marketId, data?.data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDeleteMarketShippingServices = createAsyncThunk<
    ApiResponse<IShippingServices>,
    string
>('shipping-services/tryToDeleteMarketShippingServices',
    async (id, { rejectWithValue }) => {
        try {
            const results = await ShippingServicesService.tryToDeleteMarketShippingServices(id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const shippingServicesSlice = createSlice({
    name: 'shippingServices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchShippingServices
            .addCase(tryToFetchShippingServices.pending, (state) => {
                state.shippingServicesAreLoading = true;
            })
            .addCase(tryToFetchShippingServices.rejected, (state) => {
                state.shippingServicesAreLoading = false;
            })
            .addCase(tryToFetchShippingServices.fulfilled, (state, action) => {
                state.shippingServicesAreLoading = false;
                state.shippingServicesAll = action.payload?.data;
            })

            // tryToFetchShippingServicesGrid
            .addCase(tryToFetchShippingServicesGrid.pending, (state) => {
                state.shippingServicesAreLoading = true;
            })
            .addCase(tryToFetchShippingServicesGrid.rejected, (state) => {
                state.shippingServicesAreLoading = false;
            })
            .addCase(tryToFetchShippingServicesGrid.fulfilled, (state, action) => {
                state.shippingServicesAreLoading = false;
                state.shippingServicesAll = action.payload?.data?.elements;
            })

            // tryToAddMarketShippingServices
            .addCase(tryToAddMarketShippingServices.pending, (state) => {
                state.shippingServiceIsLoading = false;
            })
            .addCase(tryToAddMarketShippingServices.rejected, (state) => {
                state.shippingServiceIsLoading = false;
            })
            .addCase(tryToAddMarketShippingServices.fulfilled, (state, action) => {
                state.requestStatus = 'success';
                state.shippingServicesAll = [
                    ...state.shippingServicesAll || [],
                    {
                        market: action?.payload?.data?.name,
                        marketId: action?.meta?.arg?.marketId,
                        shippingConfigsData: [
                            ...action.meta?.arg?.data?.data || [],
                        ]
                    },
                ];
            })

            // tryToDeleteMarketShippingServices
            .addCase(tryToDeleteMarketShippingServices.pending, (state) => {
                state.shippingServiceIsLoading = false;
            })
            .addCase(tryToDeleteMarketShippingServices.rejected, (state) => {
                state.shippingServiceIsLoading = false;
            })
            .addCase(tryToDeleteMarketShippingServices.fulfilled, (state, action) => {
                state.requestStatus = 'success';
                if (state.shippingServicesAll)
                    state.shippingServicesAll =
                        { ...state }.shippingServicesAll.filter(
                            (shippingService: any) =>
                                shippingService?.marketId !== action.meta.arg,
                        ) || [];
            })

            // tryToEditMarketShippingServices
            .addCase(tryToEditMarketShippingServices.pending, (state) => {
                state.shippingServiceIsLoading = false;
            })
            .addCase(tryToEditMarketShippingServices.rejected, (state) => {
                state.shippingServiceIsLoading = false;
            })
            .addCase(tryToEditMarketShippingServices.fulfilled, (state, action) => {
                if (state.shippingServicesAll) {
                    state.shippingServicesAll =
                        { ...state }.shippingServicesAll?.map((shippingService: any) => {
                            if (shippingService?.marketId === action.meta?.arg?.marketId) {
                                return {
                                    market: action?.payload?.data?.name,
                                    marketId: action?.meta?.arg?.marketId,
                                    shippingConfigsData: [
                                        ...action.meta?.arg?.data?.data || [],
                                    ]
                                };
                            } else {
                                return shippingService;
                            }
                        }) || [];
                }
            })

            // tryToFetchMarketShippingServices
            .addCase(tryToFetchMarketShippingServices.pending, (state) => {
                state.shippingServiceIsLoading = true;
            })
            .addCase(tryToFetchMarketShippingServices.rejected, (state) => {
                state.shippingServiceIsLoading = false;
            })
            .addCase(tryToFetchMarketShippingServices.fulfilled, (state, action) => {
                state.shippingServiceIsLoading = false;
                state.shippingService = action.payload?.data;
            });
    },
});

export default shippingServicesSlice.reducer;
