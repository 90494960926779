import React, { useEffect, useState } from 'react';
import moment from 'moment';
import NotificationMessage from './NotificationMessage';


interface INotificationsCategorized {
    notificationsData: any;
    onClickSeeNotificationDetails: (id: string, type?: string, action?: string) => void;
}

const NotificationsCategorized = ({
    notificationsData,
    onClickSeeNotificationDetails
}: INotificationsCategorized) => {
    const [formatedNotifications, setFormatedNotifications] = useState<any>();

    const categorizeData = (data: any) => {
        const today = moment();
        const categories: any = {};
        const sortedData = data.sort((a: any, b: any) => moment(b?.createdAt)?.valueOf() - moment(a?.createdAt)?.valueOf());

        sortedData.forEach((item: any) => {
            const dateMoment = moment(item.createdAt);
            let categoryKey = '';
            if (dateMoment.isSame(today, 'day')) {
                categoryKey = 'Today';
            } else if (dateMoment.isSame(today.clone().subtract(1, 'days'), 'day')) {
                categoryKey = 'Yesterday';
            } else {
                categoryKey = dateMoment.isSame(today, 'year')
                    ? dateMoment.format('D MMM')
                    : dateMoment.format('D MMM YYYY');
            }
            if (!categories[categoryKey]) {
                categories[categoryKey] = [];
            }
            categories[categoryKey].push(item);
        });
        return categories;
    };

    useEffect(() => {
        setFormatedNotifications(categorizeData(notificationsData) || undefined)
    }, [notificationsData])

    return (
        <div>
            {formatedNotifications && Object.keys(formatedNotifications).map((category) => (
                <div key={category} className='my-2'>
                    <p className='text-[16px] my-1 text-primary-light'>{category}</p>
                    {formatedNotifications[category]?.map((item: any, idx: number) => {
                        return (
                            <NotificationMessage
                                key={idx}
                                data={item?.reference}
                                action={item?.action || ''}
                                type={item?.type || ''}
                                readStatus={item?.status || ''}
                                createdAt={item?.createdAt || ''}
                                onClickSeeDetails={onClickSeeNotificationDetails}
                            />
                        )
                    })}
                </div>
            ))}
        </div>
    )

}

export default NotificationsCategorized;