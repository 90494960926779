import React from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currency-symbols';
import { IOrders } from '../../../../interfaces/orders/IOrders';
import { IActiveMarket } from '../../../../interfaces/markets/IActiveMarket';
import { IPageActions } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import ItemBarcode from '../details/ItemBarcode';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';

const tableColumns = ['Item ID', 'Parent Order ID', 'Parent Order Number', 'Item Name', 'Parent Tracking Number', 'Price', 'Order Source', 'Category', 'Broker', 'Warehouse', 'Item Details', 'Item Barcode']

interface IReshipCashoutTable {
    rows: Array<IOrders>;
    paginationData?: IPage;
    activeMarket?: IActiveMarket;
    ordersLoading: boolean;
    pageAccess?: IPageActions;
    accessControl?: IAccessControl;
    showSelect?: boolean;
    selectedRows?: Array<any>;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSeeItems: (data: any) => void;
    onClickSelectRow: (id?: string) => void;
}

const ReshipCashoutTable = ({
    rows,
    paginationData,
    activeMarket,
    ordersLoading,
    accessControl,
    pageAccess,
    selectedRows,
    showSelect,
    setPaginationState,
    onClickSeeItems,
    onClickSelectRow,
}: IReshipCashoutTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            selectRows={showSelect}
            onClickSelectAll={() => onClickSelectRow()}
            areAllRowsSelected={(selectedRows && rows && paginationData?.size ? (selectedRows?.length === (paginationData?.size * paginationData?.number)) : false)}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}>
            {ordersLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        const findIfRowIsSelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find((item) => item?.itemId === row?.itemId)
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {showSelect &&
                                    <td className='px-6 py-4'>
                                        <div className='flex items-center'>
                                            <input
                                                onChange={() => onClickSelectRow(row?.itemId || '')}
                                                checked={!!findIfRowIsSelected}
                                                id='checkbox-all'
                                                data-qa={'checkbox'}
                                                type='checkbox'
                                                className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2' />
                                            <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                                        </div>
                                    </td>
                                }
                                <td className={`px-6 py-4`}>{row?.itemId}</td>
                                <td className={`px-6 py-4`}>{row?.parent?.id}</td>
                                <td className={`px-6 py-4`}>{row?.parent?.orderNumber}</td>
                                <td className={`px-6 py-4`}>{row?.itemName}</td>
                                <td className='px-6 py-4'>
                                    <a
                                        rel='noreferrer'
                                        href={`https://parcelsapp.com/en/tracking/${row?.parent?.trackingNumber}`}
                                        target='_blank'
                                        className='text-primary-default'
                                    >
                                        {row?.parent?.trackingNumber}
                                    </a>
                                </td>
                                <td className={`px-6 py-4`}>{CURRENCY_SYMBOLS?.[`${(activeMarket?.currency?.descriptor?.name || '')}`]}{row?.price || row?.initialPrice || 0}</td>
                                <td className={`px-6 py-4`}>{row?.sourceOrder}</td>
                                <td className={`px-6 py-4`}>{row?.category}</td>
                                <td className={`px-6 py-4`}>{row?.exchangeDefinition?.originalBroker?.companyName}</td>
                                <td className={`px-6 py-4`}>{row?.exchangeDefinition?.warehouse?.name}</td>
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <Button
                                        label='See Details'
                                        className='btn-main'
                                        onClickButton={() => onClickSeeItems(row)}
                                    />
                                </td>
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <ItemBarcode itemId={row?.itemId} />
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default ReshipCashoutTable;