import React, { useState } from 'react';
import CategoriesModal from '../modals/CategoriesModal';
import Button from '../../../../shared/button';


interface ICategoryStep {
    categories?: Array<any>;
    selectedCategory?: any;
    onClickCategory: (item: any) => void;
}

const CategoryStep = ({
    categories,
    selectedCategory,
    onClickCategory
}: ICategoryStep) => {
    const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false)

    const onClickAddCategory = () => {
        if (!showCategoryModal) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
        setShowCategoryModal(!showCategoryModal)
    }

    return (
        <div className='min-w-[100%] mt-10 bg-white border-b-2 border-[#e4e4e4] p-3'>
            <div className='flex flex-row items-center my-5'>
                <div className='mr-4'>
                    <p className='font-bold'>Choose Category</p>
                </div>
                <div className='mr-3'>
                    <Button
                        label='Add Category'
                        icon={<img src='/assets/shared/add-box.svg' className='w-[30px] object-contains pr-2' />}
                        className='btn-primary flex flex-row items-center'
                        onClickButton={onClickAddCategory}
                    />
                </div>
                {selectedCategory && selectedCategory?.length > 0 && selectedCategory?.map((item: any, index: number) => {
                    return (
                        <>
                            <div key={index} className='flex flex-row items-center mr-2'>
                                <img src={item?.enabledIcon} className='object-contains h-[23px] min-h-[23px]' />
                                <p>{item?.label}</p>
                            </div>
                        </>
                    )
                })}
            </div>
            {showCategoryModal &&
                <CategoriesModal
                    openModal={showCategoryModal}
                    categories={categories}
                    selectedCategory={selectedCategory}
                    onClickCategory={onClickCategory}
                    handleCloseModal={onClickAddCategory}
                />
            }
        </div>
    );
};

export default CategoryStep;
