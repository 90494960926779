import React from 'react';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IPricingFilters {
    items: Array<any>;
    filterValues: any;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onChangeInput: (value: string | null | undefined, type: string) => void;
    onClearAllFilters: () => void;
}

const PricingFilters = ({
    items,
    filterValues,
    onChangeSelectedValue,
    onClearAllFilters
}: IPricingFilters) => {
    return (
        <div>
            <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Filters</p>
            <div className='my-2 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-7  sm:gap-2 lg:gap-4'>
                {items && items?.length > 0 && items?.filter((item) => item?.type === 'boolean' || item?.type === 'list')?.map((item, index) => {
                    let values: any = item?.options;
                    if (item?.type === 'boolean') {
                        values = [
                            { value: true, name: 'True' },
                            { value: false, name: 'False' },
                        ];
                    } else if (item?.type === 'list') {
                        values =
                            item?.options &&
                            item?.options?.length > 0 &&
                            values?.map((value: string) => {
                                return { value: value, label: value };
                            });
                    }
                    return (
                        <SelectCheckbox
                            key={index}
                            name={item?.fieldName && `${item?.fieldName?.[0].toUpperCase()}${item?.fieldName?.slice(1).toLowerCase()}`}
                            dataQa={filterValues?.[item?.fieldName] === undefined ? item?.fieldName.toLowerCase().replaceAll(' ', '-') :  filterValues?.[item?.fieldName].toLowerCase().replaceAll(' ', '-')}
                            placeholder={filterValues?.[item?.fieldName] || ' '}
                            options={values}
                            selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                            onChangeSelectedOption={onChangeSelectedValue}
                            uniqueName={item?.fieldName || ''}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default PricingFilters;