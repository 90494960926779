import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToDisableAssetTemplate,
    tryToFetchAssetsTemplates,
} from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import Toast from '../../../shared/toast';
import Input from '../../../shared/input';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';
import AssetsTemplatesTable from './tables/AssetsTemplateTable';
import AssetTemplateModal from './modals/AssetTemplateModal';
import ImportTranslationsModal from './modals/ImportTranslationsModal';


const AssetsTemplates = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IAssetTemplate[]>([]);
    const [search, setSearch] = useState<string>();
    const [showToast, setShowToast] = useState<IToast | null>()
    const [showAssetItemModal, setShowAssetItemModal] = useState<boolean>(false)
    const [assetItemId, setAssetItemId] = useState<string | null>()
    const [showDeleteAssetModal, setShowDeleteAssetModal] = useState<boolean>(false)
    const [showImportTranslationsModal, setShowImportTranslationsModal] = useState<boolean>(false)
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.assetsTemplates?.assetTemplates?.data?.page);
    const rowsLoading = useAppSelector((state) => state.assetsTemplates?.assetsTemplatesAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const state = useAppSelector((state) => state.assetsTemplates);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Asset Catalogs')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.assetTemplates) {
            const rows =
                state.assetTemplates?.data?.elements && Array.isArray(state.assetTemplates?.data.elements)
                    ? state.assetTemplates?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.assetTemplates]);

    useEffect(() => {
        const settingsFilters: any = {
            ...paginationState
        }
        if (search) {
            settingsFilters.filters = search
        }
        dispatch(tryToFetchAssetsTemplates(settingsFilters));
    }, [paginationState]);

    const onAddNewAssetTemplate = (id?: string) => {
        if (id) {
            setAssetItemId(id)
        }
        document.body.style.overflow = 'hidden';
        setShowAssetItemModal(true)
    }

    const onCloseAssetModal = () => {
        setAssetItemId(null)
        document.body.style.overflow = 'auto';
        setShowAssetItemModal(false)
    }

    const onSeeAssetTemplateDetails = async (id: string) => {
        navigate(`/asset-catalogs/${id}`);
    }

    const onDisableAsset = async () => {
        try {
            if (assetItemId) {
                await dispatch(tryToDisableAssetTemplate(assetItemId)).unwrap();
                setShowToast({ type: 'success', message: 'Asset Catalog successfully deleted' });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setAssetItemId(null)
        setShowDeleteAssetModal(false)
    }

    const onSaveAssetChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        setAssetItemId(null)
    }

    const onDeleteAssetField = (id: string) => {
        setShowDeleteAssetModal(true)
        setAssetItemId(id)
    }

    const onCloseDeleteAssetModal = () => {
        setAssetItemId(null)
        setShowDeleteAssetModal(false)
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const onManageCatalogFrames = () => {
        navigate('/asset-catalogs/frames')
    }

    const onToggleImportTranslationsModal = () => {
        setShowImportTranslationsModal(!showImportTranslationsModal)
    }

    const onImportTranslatesSuccessfully = () => {
        setShowImportTranslationsModal(false)
    }

    return (
        <div>
            <div>
                <p className='page-title'>Manage Assets</p>
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Import Translations'}
                    className={`${(!accessControl || pageAccess?.actions?.['Import Translations']) ? 'btn-main mr-3 shadow' : 'btn-main-disable mr-3'}`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Import Translations']) && onToggleImportTranslationsModal()}
                    dataQa={'import-translations'}
                />
                <Button
                    label={'Manage Catalogs Frames'}
                    className={`${(!accessControl || pageAccess?.actions?.['Manage Catalogs Frames']) ? 'btn-main mr-3 shadow' : 'btn-main-disable mr-3'}`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Manage Catalogs Frames']) && onManageCatalogFrames()}
                    dataQa={'manage-catalog-frames'}
                />
                <Button
                    label={'Add New Asset Catalog'}
                    className={`${(!accessControl || pageAccess?.actions?.['Add New Asset Catalog']) ? 'btn-main' : 'btn-main-disable'}`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Asset Catalog']) && onAddNewAssetTemplate()}
                    dataQa={'add-new-asset'}
                />
            </div>
            <div className='my-5 relative'>
                <div className={'min-w-[100px]'}>
                    <Input
                        placeholder={'Filter Table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                        dataQa={'manage-assets-filter-table'}
                    />
                </div>
                {(rows?.length > 0 || rowsLoading) ?
                    <AssetsTemplatesTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onSeeAssetTemplateDetails={onSeeAssetTemplateDetails}
                        onDisableAsset={onDeleteAssetField}
                        onEditAsset={onAddNewAssetTemplate}
                    />
                    : <EmptyContainer text={'No Asset Catalog added yet.'} showImage={true} />
                }
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            <AssetTemplateModal
                openCreateAssetModal={showAssetItemModal}
                handleCloseAssetModal={onCloseAssetModal}
                onSaveChanges={onSaveAssetChanges}
                assetItemId={assetItemId}
            />
            <DeleteModal
                openDeleteModal={showDeleteAssetModal}
                handleCloseDeleteModal={onCloseDeleteAssetModal}
                onApproveDeletion={onDisableAsset}
            />
            {showImportTranslationsModal &&
                <ImportTranslationsModal
                    openImportModal={showImportTranslationsModal}
                    handleCloseModal={onToggleImportTranslationsModal}
                    onImportSuccessfully={onImportTranslatesSuccessfully}
                />
            }
        </div >
    )
}

export default AssetsTemplates;