import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IInvoicesDetails } from '../../../interfaces/invoices/IInvoicesDetails';
import { IGenerateInvoice } from '../../../interfaces/invoices/IGenerateInvoice';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import {
    tryToFetchAllPartners,
    tryToFetchPartnerCatalogs
} from '../../../store/brokers/admin/partners/partnersSlice';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import {
    tryToFetchItemsWithoutInvoice,
    tryToGenerateInvoice
} from '../../../store/brokers/partners/invoices/invoicesSlice';
import FilterStep from './generate-invoice-steps/FilterStep';
import ItemsStep from './generate-invoice-steps/ItemsStep';
import Modal from '../../../shared/modal';
import Button from '../../../shared/button';


const GenerateInvoice = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IInvoicesDetails[]>([]);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [partners, setPartners] = useState<IPartners[]>()
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [selectedPurchaseMarket, setSelectedPurchaseMarket] = useState<any>()
    const [selectedPartner, setSelectedPartner] = useState<any>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [selectedRows, setSelectedRows] = useState<Array<string>>()
    const [createInvoiceError, setCreateInvoiceError] = useState<string>()
    const [filterStepError, setFilterStepError] = useState<string>()
    const [activeStep, setActiveStep] = useState<number>(1)
    const [dateFilters, setDateFilters] = useState<{
        maxDateCreated?: string,
        minDateCreated?: string
    }>();
    const [loadingCreate, setLoadingCreate] = useState<boolean>(false)
    const [loadingGenerateAll, setLoadingGenerateAll] = useState<boolean>(false)
    const [showInvoiceModal, setShowInvoiceModal] = useState<{show: boolean, type?: 'createInvoice' | 'generateAll'}>({show: false})
    const [loadingIncludeShipping, setLoadingIncludeShipping] = useState<boolean | null>(null)
    const [search, setSearch] = useState<string>()
    const state = useAppSelector((state) => state.invoices)
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const paginationData = useAppSelector((state) => state.invoices.items?.data?.page);
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        if (state.items) {
            const rows =
                state.items?.data?.elements && Array.isArray(state.items?.data.elements)
                    ? state.items?.data.elements.map((r) => ({ ...r }))
                    : [];
            setRows(rows);
        }
    }, [state.items]);

    const getPartnerAndMarketData = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.companyName }))
        setPartners(formatPartners || [])
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.displayName, label: r?.displayName, value: r?._id, }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    useEffect(() => {
        getPartnerAndMarketData()
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    const onChangeSelectedFilter = (e: any, type?: string) => {
        setFilterStepError(undefined)
        switch (type) {
            case 'market':
                setSelectedMarket(e);
                break;
            case 'purchaseMarket':
                setSelectedPurchaseMarket(e);
                break;
            case 'sector':
                setSelectedSector(e)
                break;
            case 'partner':
                setSelectedPartner(e)
                break;
            case 'minDateCreated':
            case 'maxDateCreated':
                setDateFilters((prevFilters) => ({
                    ...prevFilters,
                    [type]: e || undefined
                }))
                break;
        }
    }

    const onClickNextStep = () => {
        if (activeStep === 1) {
            if (!selectedMarket || !selectedPartner) {
                setFilterStepError('Please select all to continue on the next step.')
                return;
            }
            setActiveStep(activeStep + 1)
        }
    }

    const onGoBack = () => {
        if (activeStep > 1) {
            setActiveStep(activeStep - 1)
            setCreateInvoiceError(undefined)
        }
        else {
            navigate('/admin-invoices-details')
        }
    }

    const onGetItemsData = async () => {
        let formatData: any = {
            ...paginationState,
            data: {
                market: selectedMarket?.value,
                partnerId: selectedPartner?.value,
                categoryId: selectedSector?.value
            },
            search
        }
        if (selectedPurchaseMarket) {
            formatData = {
                ...paginationState,
                data: {
                    ...formatData?.data || {},
                    purchaseMarket: selectedPurchaseMarket?.value
                },
                search
            }
        }
        if (dateFilters && (dateFilters?.maxDateCreated || dateFilters?.minDateCreated)) {
            formatData = {
                ...paginationState,
                data: {
                    ...formatData?.data || {},
                    range: {
                        to: dateFilters?.maxDateCreated ? new Date(dateFilters?.maxDateCreated) : undefined,
                        from: dateFilters?.minDateCreated ? new Date(dateFilters?.minDateCreated) : undefined
                    }
                },
                search
            }
        }
        dispatch(tryToFetchItemsWithoutInvoice(formatData))
    }

    useEffect(() => {
        if (activeStep === 2) {
            onGetItemsData()
        }
    }, [activeStep, paginationState, selectedSector])

    const onClickSelectRow = (id?: string) => {
        if (!id) {
            const getAllIds = rows?.length > 0 && rows?.filter(row => !selectedRows?.includes(row.id || '')).map((row) => row.id || '')

            if ((selectedRows && rows && rows.every(r => selectedRows.includes(r.id || '')))) {
                setSelectedRows(sr => sr?.filter(sr => !rows.find(row => row.id === sr)))
            } else {
                setSelectedRows(sr => getAllIds ? [...(sr || []), ...getAllIds] : getAllIds || [])
            }
        }
        else {
            const findIfAlreadySelected = selectedRows && selectedRows?.length > 0 && selectedRows?.find((row) => row === id)
            if (findIfAlreadySelected) {
                const filterOthers = selectedRows && selectedRows?.length > 0 && selectedRows?.filter((row) => row !== id)
                setSelectedRows(filterOthers || undefined)
            } else {
                const formatNew = [
                    ...selectedRows || [],
                    id || ''
                ]
                setSelectedRows(formatNew || undefined)
            }
        }
    }

    const onGetPartnerSectors = async () => {
        const response: any = await dispatch(tryToFetchPartnerCatalogs(selectedPartner?.username || '')).unwrap()
        if (response?.data) {
            const formatSectors = response?.data?.length > 0 && response?.data?.map((category: any) => ({ ...category, label: category?.displayName, name: category?.displayName, value: category?._id }))
            setSectors(formatSectors || [])
        }
    }

    useEffect(() => {
        if (selectedPartner) {
            onGetPartnerSectors()
        }
    }, [selectedPartner])

    const sleep = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms))

    const onCreateInvoice = async (includePostage: boolean) => {
        setLoadingIncludeShipping(includePostage) 
        try {
            if (selectedRows) {
                const formatData: IGenerateInvoice = {
                    items: selectedRows,
                    marketId: selectedMarket?.value || '',
                    partnerId: selectedPartner?.value || '',
                    category: {
                        id: selectedSector ? selectedSector?._id || '' : '',
                        code: selectedSector ? selectedSector?.name || '' : ''
                    },
                    includePostage
                }
                const response: any = await dispatch(tryToGenerateInvoice(formatData)).unwrap()
                if (response?.data && response?.data?.length > 0) {
                    const pushLinks: any = []
                    response?.data?.map((item: any) => {
                        // (item?.pdfData && item?.pdfData?.length > 0) && item?.pdfData?.map((pdfLink: any) => {
                        //     pushLinks.push(pdfLink)
                        //     return pdfLink
                        // })
                        pushLinks.push(item?.pdfData)
                        pushLinks.push(item?.excel)
                        return item;
                    })
                    pushLinks && pushLinks?.length > 0 && await Promise.all(pushLinks?.map(async (item: any) => {
                        window.open(item, '_blank');
                        await sleep(1000);
                        return item;
                    }))
                }
                navigate('/admin-invoices')
                setCreateInvoiceError(undefined)
            }
        } catch (err) {
            setCreateInvoiceError(`${err}`)
        }
        includePostage && setLoadingIncludeShipping(null)
        setLoadingCreate(false)
        setShowInvoiceModal({show: false})
    }

    const onPressCreateInvoice = () => setShowInvoiceModal({show: true, type: 'createInvoice'})

    const onClickGenerateAll = async (includePostage: boolean) => {
        setLoadingIncludeShipping(includePostage)
        setLoadingGenerateAll(true)
        try {
            const formatData: IGenerateInvoice = {
                selectAll: true,
                marketId: selectedMarket?.value || '',
                partnerId: selectedPartner?.value || '',
                category: {
                    id: selectedSector ? selectedSector?._id || '' : '',
                    code: selectedSector ? selectedSector?.name || '' : ''
                },
                includePostage
            }
            const response: any = await dispatch(tryToGenerateInvoice(formatData)).unwrap()
            if (response?.data && response?.data?.length > 0) {
                const pushLinks: any = []
                response?.data?.map((item: any) => {
                    // (item?.pdfData && item?.pdfData?.length > 0) && item?.pdfData?.map((pdfLink: any) => {
                    //     pushLinks.push(pdfLink)
                    //     return pdfLink
                    // })
                    pushLinks.push(item?.pdfData)
                    pushLinks.push(item?.excel)
                    return item;
                })
                pushLinks && pushLinks?.length > 0 && await Promise.all(pushLinks?.map(async (item: any) => {
                    window.open(item, '_blank');
                    await sleep(1000);
                    return item;
                }))
            }
            navigate('/admin-invoices')
            setCreateInvoiceError(undefined)
        } catch (err) {
            setCreateInvoiceError(`${err}`)
        }
        setLoadingIncludeShipping(null) 
        setLoadingGenerateAll(false)
        setShowInvoiceModal({show: false})
    }

    const onPressGenerateAll = () => setShowInvoiceModal({show: true, type: 'generateAll'})

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    const onChangeSearchValue = (value: string | null | undefined) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Generate Invoice</p>
                </div>
            </div>
            <div>
                {activeStep === 1 ?
                    <FilterStep
                        markets={markets}
                        sectors={sectors}
                        partners={partners}
                        selectedMarket={selectedMarket}
                        selectedPartner={selectedPartner}
                        selectedSector={selectedSector}
                        selectedPurchaseMarket={selectedPurchaseMarket}
                        error={filterStepError}
                        dateFilters={dateFilters}
                        onChangeSelectedFilter={onChangeSelectedFilter}
                        onClickNextStep={onClickNextStep}
                    />
                    :
                    <ItemsStep
                        rows={rows}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        selectedRows={selectedRows}
                        partners={partners}
                        markets={markets}
                        loadingCreate={loadingCreate}
                        loadingGenerateAll={loadingGenerateAll}
                        error={createInvoiceError}
                        onCreateInvoice={onPressCreateInvoice}
                        onClickGenerateAll={onPressGenerateAll}
                        onClickSelectRow={onClickSelectRow}
                        search={search}
                        onChangeSearchValue={onChangeSearchValue}
                        selectedSector={selectedSector}
                        sectors={sectors}
                        onChangeSelectedFilter={onChangeSelectedFilter}
                    />
                }
            </div>
            <Modal contentContainerStyle='w-[629px]' titleContainerStyle='flex w-[100%] items-center justify-center' showInRight open={showInvoiceModal.show} onClose={() => setShowInvoiceModal({show: false})} title='Create Invoice'>
                <div className='border-b border-borderColor-secondary'></div>
                <div className='border-b border-borderColor-secondary mt-[40px]'>
                    <p className='font-sans text-xl'>Action confirmation</p>
                </div>
                <div className='h-[120px] bg-[#F8F9FC] rounded mt-[40px] px-[20px] py-[16px] items-center justify-center'>
                    <p className='text-center font-sans text-xl'>Do you want to include shipping costs for this invoice?</p>
                    <div className='flex justify-center gap-[24px] mt-[16px]'>
                        <Button loading={loadingIncludeShipping === false} onClickButton={() => {
                            showInvoiceModal.type === 'createInvoice' ? onCreateInvoice(false) : onClickGenerateAll(false);
                        }} label={'No & Create Invoice'} className='bg-white w-[216px] rounded-[10px] h-[38px] hover:opacity-75'/>
                        <Button loading={loadingIncludeShipping} onClickButton={() => {
                            showInvoiceModal.type === 'createInvoice' ? onCreateInvoice(true) : onClickGenerateAll(true);
                        }} label={'Yes & Create Invoice'} className='bg-gradient-to-br from-[#FCEE21] to-[#F7931E] w-[216px] rounded-[10px] h-[38px] hover:opacity-75'/>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default GenerateInvoice;