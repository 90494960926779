import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IToast } from '../../../interfaces/components/IToast';
import { IMarket } from '../../../interfaces/markets/IMarket';
import {
    tryToDisableMarket,
    tryToFetchFilteredMarkets,
} from '../../../store/brokers/admin/markets/marketsSlice';
import { tryToFetchAssetCurrencies } from '../../../store/brokers/admin/assets/assetsSlice';
import MarketModal from './modals/MarketModal';
import MarketsTable from './tables/MarketsTable';
import Toast from '../../../shared/toast';
import Input from '../../../shared/input';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';


const Markets = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IMarket[]>([]);
    const [search, setSearch] = useState<string>();
    const [currencies, setCurrencies] = useState<Array<any>>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [marketItem, setMarketItem] = useState<string>();
    const [marketItemId, setMarketItemId] = useState<string | null>();
    const [showMarketItemModal, setShowMarketItemModal] = useState<boolean>(false);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.markets.markets?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const stateCurrencies: any = useAppSelector((state) => state.assets.currencies);
    const rowsLoading = useAppSelector((state) => state.markets.marketsAreLoading);
    const state = useAppSelector((state) => state.markets);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Markets')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.markets) {
            const rows =
                state.markets?.data?.elements &&
                    Array.isArray(state.markets?.data.elements)
                    ? state.markets?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.markets]);

    useEffect(() => {
        const settingsFilters: any = {
            ...paginationState
        }
        if (search) {
            settingsFilters.filters = search
        }
        dispatch(tryToFetchFilteredMarkets(settingsFilters));
    }, [paginationState]);

    useEffect(() => {
        dispatch(tryToFetchAssetCurrencies());
    }, [])

    useEffect(() => {
        setCurrencies(stateCurrencies);
    }, [stateCurrencies]);

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const onDeleteMarket = async () => {
        try {
            const data = {
                marketId: marketItem || '',
            };
            await dispatch(tryToDisableMarket(data));
            setShowToast({
                type: 'success',
                message: 'Market successfully deleted',
            });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setMarketItem(undefined);
        setShowDeleteModal(false);
    };

    const onAddMarket = (id?: string) => {
        if (id) {
            setMarketItemId(id);
        }
        setShowMarketItemModal(true);
    };

    const onCloseMarketModal = () => {
        setMarketItemId(null);
        setShowMarketItemModal(false);
    };

    const onSaveMarketChanges = (type: string, message: string) => {
        setShowToast({ type, message });
    };

    const onDeleteMarketRequest = (id: string) => {
        setShowDeleteModal(true);
        setMarketItem(id);
    };

    // useEffect(() => {
    //     dispatch(tryToFetchMarkets(paginationState));
    // }, [paginationState]);

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const onSeeMarketDetails = async (id: string) => {
        navigate(`/markets/${id}`);
    }

    return (
        <div>
            <div>
                <p className='page-title'>Markets</p>
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New Market'}
                    className={(!accessControl || pageAccess?.actions?.['Add New Market']) ? 'btn-main' : 'btn-main-disable'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Market']) && onAddMarket()}
                    dataQa={'markets-add-new'}
                />
            </div>
            <div className='my-5 relativ'>
                <div className={'min-w-[100px]'}>
                    <Input
                        placeholder={'Filter Table'}
                        dataQa={'markets-filter-table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={'mb-0 border-0 focus:outline-none'}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                {(rows?.length > 0 || rowsLoading) ?
                    <MarketsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        currencies={currencies || []}
                        setPaginationState={setPaginationState}
                        onDeleteMarketRequest={onDeleteMarketRequest}
                        onEditMarketField={onAddMarket}
                        onSeeMarketDetails={onSeeMarketDetails}
                    />
                    : <EmptyContainer
                        text={'No market added yet.'}
                        showImage={true}
                    />
                }
            </div>
            {showMarketItemModal &&
                <MarketModal
                    currencies={currencies || []}
                    openCreateMarketModal={showMarketItemModal}
                    handleCloseMarketModal={onCloseMarketModal}
                    onSaveChanges={onSaveMarketChanges}
                    marketItemId={marketItemId}
                />
            }
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeleteMarket}
            />
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
        </div>
    );
};

export default Markets;
