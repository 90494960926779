import axios, { AxiosRequestConfig } from 'axios';
import { DEVICE_INFO_API } from '../../config';
import { readFromStorage, removeFromStorage } from '../webStorage';
import userAxios from './user.axios';

console.log({DEVICE_INFO_API})

const axiosConfig: AxiosRequestConfig = {
    baseURL:  'https://device-info-ai.dev.wingpay.co/',
    headers: {
        'X-Wing-Client': 'exchange-dashboard',
    },
};
const aiAgentAxios = axios.create(axiosConfig);

const logout = () => {
    removeFromStorage('accessToken');
    removeFromStorage('refreshToken');
    removeFromStorage('user')
    removeFromStorage('role')
    window.location.reload();
    // (window as any).Intercom('shutdown');
};

// reqeust intercpetor
aiAgentAxios.interceptors.request.use((config) => {
    const token = readFromStorage('accessToken');
    // const isValid = validateJWT(token);
    if (token) {
        return {
            ...config,
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
                'X-Wing-Client': 'exchange-dashboard',
            },
        };
    }
    return config;
});

// Add a response interceptor
aiAgentAxios.interceptors.response.use(
    (response) => {
        if (response?.data?.error === 'No permission!') logout();
        return response;
    },
    async (error) => {
        const err = { ...error };
        if (error.response.status === 401) {
            const err = { ...error };
            const originalRequest = err.config;
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const token = readFromStorage('refreshToken');
                    const response = await userAxios.post('/dom/refresh-token', { token });
                    const accessToken = response.data.data.token;
                    localStorage.setItem('accessToken', accessToken);
                    axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
                    return aiAgentAxios(originalRequest);
                } catch (e) {
                    console.error(e);
                    logout();
                }
            } else {
                logout();
            }
        }
        return Promise.reject(err);
    },
);

export default aiAgentAxios;