import React, {  useState } from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';
import moment from 'moment';
import TableActions from '../details/TableActions';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToDownloadCreditNoteCSV } from '../../../../store/brokers/admin/rma/RMAsSlice';

const tableColumns = [
  'Credit Note ID', 'Broker', 'Category', 'Created Date (dd/mm/yyyy)', 'Nr. of items',
  'Total Credit', 'Country','Payment Status', 'Actions'
];

interface ICreditNotesTable {
  rows: any[];
  paginationData?: IPage;
  rowsLoading: boolean;
  setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
  onSeeCreditNoteDetails: (id: string) => void;
  viewDetails: boolean;
  accessPDF: boolean;
  accessCSV: boolean;
  accessMarkAsPaid: boolean;
  onClickMarkAsPaid: (id: string, creditAmount: number, currency: string) => void;
}

const CreditNotesTable = ({
  rows, paginationData, rowsLoading, setPaginationState, onSeeCreditNoteDetails,
  viewDetails, accessCSV, accessPDF, accessMarkAsPaid, onClickMarkAsPaid
}: ICreditNotesTable) => {
  const dispatch = useAppDispatch();
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  const onDownloadData = async (creditNoteId: string) => {
    setLoadingDownload(true);
    try {
      const response = await dispatch(tryToDownloadCreditNoteCSV(creditNoteId)).unwrap();
      const url = response.data
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoadingDownload(false);
    }
  };

  return (
    <Table
      columns={tableColumns}
      containerStyle={'mt-0'}
      paginationData={paginationData}
      setPaginationState={setPaginationState}
    >
      {rowsLoading ? (
        <>
          {[...Array(4)].map((_, i) => (
            <tr key={i}>
              {[...Array(tableColumns.length)].map((_, j) => (
                <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
              ))}
            </tr>
          ))}
        </>
      ) : (
        <>
          {rows.length > 0 && rows.map((row: any, idx: number) => (
            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
              <td className='px-6 py-4'>{row._id}</td>
              <td className='px-6 py-4'>{row.broker}</td>
              <td className='px-6 py-4 flex'>
                {row.categories.slice(0, 2).map((c: any, index: number) => (
                  <img
                    key={index}
                    className="w-6 h-6"
                    src={c.enabledIcon}
                    alt={c.category}
                    title={c.category} // Show category name as tooltip
                  />
                ))}
                {row.categories.length > 2 && (
                  <span className="ml-1 hover:cursor-pointer relative" title={row.categories.slice(2).map((category: any) => category.category).join(', ')}>
                    ...
                  </span>
                )}
              </td>
              <td className='px-6 py-4'>{moment(row.createdAt).format('DD-MM-YYYY')}</td>
              <td className='px-6 py-4'>{row.numberOfItems}</td>
              <td className='px-6 py-4'>£{row.totalCredit}</td>
              <td className='px-6 py-4'>{row?.market || ''}</td>
              <td className={`px-6 py-4 ${row.paidStatus ? 'text-[#00B67A]' : 'text-[#E50000]'}`}>{row.paidStatus ? 'Paid' : 'Not paid'}</td>
              <td className='px-6 py-4'>
                <div className='flex flex-row justify-center gap-3'>
                  <div onClick={() => (row?._id) && viewDetails && onSeeCreditNoteDetails(row?._id)}>
                    <img title={'View Details'} alt='view details' data-qa='details-rma' src={'/assets/table-actions/view.svg'} className={`w-[22px] object-contain ${viewDetails ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                  </div>
                  <TableActions
                    onClickCSVButton={() => row?._id && onDownloadData(row?._id)}
                    csvLabel={loadingDownload ? 'Loading data...' : 'CSV Details'}
                    pdfLink={row?.pdfLink}
                    accessCSV={accessCSV}
                    accessPDF={accessPDF}
                    showMarkAsPaid={!row.paidStatus}
                    accessMarkAsPaid={accessMarkAsPaid}
                    onClickMarkAsPaid={() => onClickMarkAsPaid(row._id, row.totalCredit, '£')}
                  />
                </div>
              </td>
            </tr>
          ))}
        </>
      )}
    </Table>
  );
};

export default CreditNotesTable;
