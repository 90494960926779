import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IInvoicesDetails } from '../../../interfaces/invoices/IInvoicesDetails';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToFetchAllPartners,
    tryToFetchPartnerCatalogs
} from '../../../store/brokers/admin/partners/partnersSlice';
import { tryToFetchFilteredBills } from '../../../store/brokers/partners/invoices/invoicesSlice';
import { tryToFetchAllAssetsTemplates } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { INVOICE_STATES } from '../../../utils/constants/invoices-states';
import AdminInvoicesFilters from './details/AdminInvoicesFilters';
import AdminInvoicesState from './details/AdminInvoicesState';
import AdminInvoicesTable from './tables/AdminInvoicesTable';
import SearchWithButton from '../../../shared/search-with-button';
import Toast from '../../../shared/toast';


const AdminInvoicesDetails = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IInvoicesDetails[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [search, setSearch] = useState<string>()
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [partners, setPartners] = useState<IPartners[]>()
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [selectedState, setSelectedState] = useState<string>()
    const [selectedPartner, setSelectedPartner] = useState<any>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [dateFiltersValues, setDateFiltersValues] = useState<any>();
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [selectedInvoiceState, setSelectedInvoiceState] = useState<any>()
    const [pageAccess, setPageAccess] = useState<any>()
    const state = useAppSelector((state) => state.invoices);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const paginationData = useAppSelector((state) => state.invoices.bills?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Invoice Details')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.bills) {
            const rows =
                state.bills?.data?.elements && Array.isArray(state.bills?.data.elements)
                    ? state.bills?.data.elements.map((r) => ({ ...r }))
                    : [];
            setRows(rows);
        }
    }, [state.bills]);

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    const onGettingFilters = (type?: string) => {
        let settingsFilters: any
        if (!type || type !== 'download') {
            settingsFilters = {
                ...paginationState
            }
        }
        if (selectedMarket) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    market: selectedMarket ? selectedMarket?._id : ''
                }
            }
        }
        if (selectedSector) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    categoryId: selectedSector ? selectedSector?._id : ''
                }
            }
        }
        if (selectedPartner) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    partnerId: selectedPartner ? selectedPartner?._id : ''
                }
            }
        }
        if (selectedInvoiceState) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    invoiceNumber: selectedInvoiceState?.value === 'Yes'
                }
            }
        }
        if (selectedState) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    state: selectedState
                }
            }
        }
        if (search) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    search: search
                }
            }
        }
        if (dateFiltersValues) {
            settingsFilters = {
                ...settingsFilters || {},
                data: {
                    ...settingsFilters?.data,
                    range: {
                        to: new Date(dateFiltersValues?.maxDateCreated),
                        from: new Date(dateFiltersValues?.minDateCreated)
                    }
                }
            }
        }
        return settingsFilters
    }

    const getBillsData = () => {
        const settingsFilters = onGettingFilters()
        dispatch(tryToFetchFilteredBills(settingsFilters));
    }

    useEffect(() => {
        getBillsData()
    }, [paginationState]);

    const getPartnerAndMarketData = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.companyName }))
        setPartners(formatPartners || [])
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.displayName, value: r?._id, label: r?.displayName }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const onGetPartnerSectors = async () => {
        const response: any = await dispatch(tryToFetchPartnerCatalogs(selectedPartner?.username || '')).unwrap()
        if (response?.data) {
            const formatSectors = response?.data?.length > 0 && response?.data?.map((category: any) => ({ ...category, label: category?.displayName, name: category?.displayName, value: category?._id }))
            setSectors(formatSectors || [])
        }
    }

    useEffect(() => {
        if (selectedPartner) {
            onGetPartnerSectors()
        }
    }, [selectedPartner])

    useEffect(() => {
        getPartnerAndMarketData()
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    const onSelectMarket = (value: any) => {
        setSelectedMarket(value)
        onResetPaginationState()
    }

    const onSelectPartner = (value: any) => {
        setSelectedPartner(value)
        onResetPaginationState()
    }

    const onSelectSector = (value: any) => {
        setSelectedSector(value)
        onResetPaginationState()
    }

    const onSelectInvoice = (value: any) => {
        setSelectedInvoiceState(value)
        onResetPaginationState()
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    const onChangeActiveState = (status: string) => {
        setSelectedState(selectedState === status ? undefined : status)
        onResetPaginationState()
    }

    const onChangeDateFilters = (value: string | null | undefined, type: string) => {
        setDateFiltersValues({
            ...dateFiltersValues,
            [type]: value || undefined
        })
        onResetPaginationState()
    }

    const onClickGenerateInvoice = () => {
        navigate('/admin-invoices-details/generate')
    }

    const onClearAllFilters = () => {
        setSelectedMarket(undefined)
        setSelectedPartner(undefined)
        setSelectedSector(undefined)
        setDateFiltersValues(undefined)
        setSelectedInvoiceState(undefined)
        setSearch(undefined)
        onResetPaginationState()
    }

    const onDownloadCsvData = async () => {
        setLoadingDownload(true)
        const settingsFilters = onGettingFilters('download')
        const formatFilters = {
            ...settingsFilters || {},
            data: {
                ...settingsFilters?.data || {},
                download: true
            }
        }
        const response: any = await dispatch(tryToFetchFilteredBills(formatFilters)).unwrap();
        if (response && response?.data) {
            window.open(response?.data, '_blank');
        }
        setLoadingDownload(false)
    }

    return (
        <div>
            <div>
                <p className='page-title'>Invoices Details</p>
                {(!accessControl || pageAccess?.actions?.['Download CSV']) && <p className='text-primary-light mt-1 mb-3'>Please Select a Market first in order to download the CSV.</p>}
            </div>
            <div>
                <AdminInvoicesFilters
                    markets={markets}
                    selectedMarket={selectedMarket}
                    partners={partners}
                    selectedPartner={selectedPartner}
                    sectors={sectors}
                    selectedSector={selectedSector}
                    dateFilters={dateFiltersValues}
                    selectedInvoiceState={selectedInvoiceState}
                    onChangeFilterValue={onChangeDateFilters}
                    onSelectMarket={onSelectMarket}
                    onSelectPartner={onSelectPartner}
                    onSelectSector={onSelectSector}
                    onSelectInvoice={onSelectInvoice}
                />
            </div>
            <div>
                <AdminInvoicesState
                    invoicesStates={INVOICE_STATES}
                    selectedState={selectedState}
                    onChangeState={onChangeActiveState}
                />
            </div>
            <div>
                <div className={`min-w-[100px]`}>
                    <SearchWithButton
                        placeholder='Filter table'
                        search={search}
                        onChangeSearchValue={onChangeSearchValue}
                        mainContainerStyle={'!grid-cols-10'}
                        buttonLabel={'Generate New Invoice'}
                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Generate New Invoice']) && onClickGenerateInvoice()}
                        buttonStyle={`${(!accessControl || pageAccess?.actions?.['Generate New Invoice']) ? 'btn-main' : 'btn-main-disable'} min-w-[150px] col-span-10 lg:col-span-2 mb-1 lg:mb-0 !mt-0`}
                        scndButtonLabel={'Download CSV'}
                        isLoadingScndButton={loadingDownload}
                        showScndBtn={true}
                        scndButtonStyle={`${(loadingDownload || !(!accessControl || pageAccess?.actions?.['Download CSV']) || !selectedMarket) ? 'btn-main-disable' : 'btn-main'} !min-w-[150px] col-span-10 lg:col-span-2 mb-1 lg:mb-0 !mt-0 mr-0 lg:mr-1`}
                        thirdButtonStyle={'btn-primary-text-underline !min-w-auto col-span-10 lg:col-span-2 mb-1 lg:mb-0 !mt-0 flex flex-row justify-center lg:justify-end bg-transparent'}
                        onClickSecondButton={() => ((!accessControl || pageAccess?.actions?.['Download CSV']) && selectedMarket && !loadingDownload) && onDownloadCsvData()}
                        onClickThirdButton={onClearAllFilters}
                        showThirdBtn={true}
                        thirdButtonLabel={'Clear All Filters'}
                    />
                </div>
                <AdminInvoicesTable
                    rows={rows}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                    markets={markets}
                    partners={partners}
                />
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    )
}

export default AdminInvoicesDetails;