import React from 'react';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import WhiteContainer from '../../../../shared/white-container';


interface IWarehouseItem {
    warehouse: IWarehouse;
    key?: number;
    showActions?: boolean;
    isEnable?: boolean;
    marketName?: string;
    warehouseName?: string;
    accessWarehouseDetails?: any;
    accessDeleteWarehouse?: any;
    accessEditWarehouse?: any;
    markets?: any;
    onEditWarehouse?: () => void;
    onChangeStatusWarehouse?: () => void;
    onShowWarehouseDetails?: () => void;
}

const BillingAddressItem = ({
    warehouse,
    key,
    markets
}: IWarehouseItem) => {
    return (
        <WhiteContainer containerStyle='max-w-[90%]' type='rounded' key={key}>
            <div className='w-full h-full flex flex-col items-start justify-start'>
                <p className='font-bold text-[17px] mt-2 mb-5'>Billing Address</p>
                <div className='flex flex-row items-start'>
                    <img src={'/assets/profile/warehouse-address.png'} className={'h-[24px] object-contain mr-2 mt-2'} />
                    <div>
                        <p>{warehouse?.address?.street}</p>
                        <p>{warehouse?.address?.addressNo}</p>
                        <p>{warehouse?.address?.city}</p>
                        <p>{warehouse?.address?.postalCode}</p>
                        <p>{warehouse?.address?.country}</p>
                        <p>{warehouse?.address?.state}</p>
                    </div>
                </div>
                {warehouse?.address?.phone ?
                    <div className='flex flex-row items-center mt-3 mb-5'>
                        <img src={'/assets/profile/phone.png'} className={'h-[24px] object-contain mr-2'} />
                        <p>{warehouse?.address?.phone}</p>
                    </div>
                    : <div className='flex flex-row items-center mt-6 mb-6'>
                    </div>
                }
                <p className='font-bold text-[16px] mt-4 mb-2'>Markets</p>
                <div className='flex flex-row flex-wrap items-center'>
                    {(markets && markets?.length > 0)
                        && markets?.map((item: any, index: any) => {
                            return (
                                <div key={index}>
                                    {item?.[0]?.market?.flagUrl && <img
                                        src={`${item?.[0]?.market?.flagUrl}`}
                                        title={item?.[0]?.market?.label}
                                        className={'w-[16px] object-contain mr-1 mb-2'}
                                    />}
                                </div>
                            )
                        })
                    }
                </div>
              
            </div>
        </WhiteContainer>
    )
}

export default BillingAddressItem;