import React, { useEffect, useState, useCallback } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone'
import { useForm, useFieldArray } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ICreateAssetField } from '../../../../interfaces/asset-templates/IAssetTemplateSchema';
import {
    tryToAddAssetTemplateField,
    tryToEditAssetTemplateField,
    tryToFetchSingleAssetTemplateField
} from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Switch from '../../../../shared/switch';
import Select from '../../../../shared/select';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';


interface IFieldsModal {
    openFieldsModal: boolean;
    fieldItemId?: string | null;
    assetTemplateId: string;
    type?: string;
    handleCloseFieldsModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    dataType: string;
    values?: { name: string }[];
    required?: boolean;
};

const dataTypes = [
    { label: 'String', value: 'string' },
    { label: 'List', value: 'list' },
]

const FieldsModal = ({
    openFieldsModal,
    fieldItemId,
    assetTemplateId,
    type,
    handleCloseFieldsModal,
    onSaveChanges,
}: IFieldsModal) => {
    const dispatch = useAppDispatch();
    const [newValue, setNewValue] = useState<string>()
    const [listError, setListError] = useState<string>()
    const [fileSelected, setFileSelected] = useState<any>()
    const [requiredField, setRequiredField] = useState<boolean>(false)
    const [showFieldInInventory, setShowFieldInInventory] = useState<boolean>(false)
    const [hideFieldInApp, setHideFieldInApp] = useState<boolean>(false)
    const state = useAppSelector((state) => state.assetsTemplates);
    const fieldLoading = useAppSelector((state) => state.assetsTemplates?.assetTemplateFieldIsLoading);

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'values'
    })

    useEffect(() => {
        setValue('name', fieldItemId ? state?.assetTemplateFieldsDetails?.name || '' : '')
        setValue('dataType', fieldItemId ? state?.assetTemplateFieldsDetails?.dataType || '' : '')
        const formatValuesData = fieldItemId && state?.assetTemplateFieldsDetails?.values && state?.assetTemplateFieldsDetails?.values?.length > 0 && state?.assetTemplateFieldsDetails?.values?.map((v) => { return { name: v } })
        setValue('values', fieldItemId ? formatValuesData || [{ name: '' }] : [{ name: '' }])
        setValue('required', fieldItemId ? state?.assetTemplateFieldsDetails?.required || false : false)
        setRequiredField(fieldItemId ? state?.assetTemplateFieldsDetails?.required || false : false)
        if (type !== 'rental' && type !== 'extended') {
            setShowFieldInInventory(state?.assetTemplateFieldsDetails?.showInInventory || false)
        }
        !type && setHideFieldInApp(state?.assetTemplateFieldsDetails?.hiddenInApp || false)
    }, [state.assetTemplateFieldsDetails]);

    useEffect(() => {
        if (fieldItemId) {
            const data = {
                assetId: assetTemplateId,
                fieldId: fieldItemId
            }
            dispatch(tryToFetchSingleAssetTemplateField(data));
        } else {
            setValue('dataType', '')
            setRequiredField(false)
            setHideFieldInApp(false)
            setShowFieldInInventory(false)
            setNewValue(undefined)
            setListError(undefined)
            reset();
        }
    }, [fieldItemId])

    useEffect(() => {
        setValue('dataType', '')
        setNewValue(undefined)
        setRequiredField(false)
        setShowFieldInInventory(false)
        setHideFieldInApp(false);
    }, [])

    const onCloseModal = () => {
        setListError(undefined);
        setFileSelected(undefined);
        setHideFieldInApp(false);
        reset();
        setNewValue(undefined)
        setRequiredField(false)
        setShowFieldInInventory(false)
        handleCloseFieldsModal()
    }

    const watchDataType = watch('dataType');
    const onSubmit = async (data: FormValues) => {
        if (watchDataType === 'list' && (!fields || fields?.length <= 0)) {
            setListError('Please write at least one list item.')
            return;
        }
        const formatData: any = {
            name: data?.name,
            dataType: data?.dataType
        }
        if (data?.values && data?.values?.length > 0 && watchDataType === 'list') {
            formatData.values = data?.values?.map((value) => value?.name)
        }
        if (fieldItemId && state?.assetTemplateFieldsDetails?.replaceField) {
            formatData.replaceField = state?.assetTemplateFieldsDetails?.replaceField
        }
        const payload: ICreateAssetField = {
            ...formatData,
            required: requiredField || false,
            assetId: assetTemplateId,
            showInInventory: showFieldInInventory || false
        };

        if (type === 'inventory') {
            payload.inventory = true
        } else if (type === 'rental') {
            payload.rental = true
        } else if (type === 'extended') {
            payload.extended = true
        } else {
            payload.hiddenInApp = hideFieldInApp || false
        }
        try {
            if (fieldItemId) {
                payload.id = fieldItemId
                await dispatch(tryToEditAssetTemplateField(payload)).unwrap();
            } else {
                await dispatch(tryToAddAssetTemplateField(payload)).unwrap();
            }
            onSaveChanges('success', `Asset Field successfully ${fieldItemId ? 'changed' : 'added'}.`)
        } catch (error) {
            onSaveChanges('error', `${error}`)
        }
        onCloseModal()
        setRequiredField(false)
        setShowFieldInInventory(false)
        setListError(undefined)
        setFileSelected(undefined)
        reset();
    };

    const onChangeNewValue = (value: string | null | undefined, type: string) => {
        setNewValue(value || undefined)
        setListError(undefined)
    }

    const onAddNewValue = () => {
        if (newValue && newValue !== '') {
            const appendData = { name: newValue }
            append(appendData)
            setNewValue('')
        }
    }

    const onDeleteValue = (id: number) => {
        remove(id)
    }

    useEffect(() => {
        if (watchDataType !== 'list') {
            remove()
        }
    }, [watchDataType])

    const onChangeFieldRequired = () => {
        setRequiredField(!requiredField)
    }

    const onChangeInventoryField = () => {
        setShowFieldInInventory(!showFieldInInventory)
    }

    const onChangeAppField = () => {
        setHideFieldInApp(!hideFieldInApp)
    }

    const onGettingListValuesFromFile = () => {
        if (fileSelected) {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const key = results?.data?.[0] && Object.keys(results?.data?.[0])
                    const unique = results && results?.data && results?.data?.length > 0 && results?.data?.map((item: any) => item?.[key?.[0]])
                        .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
                    if (unique && unique?.length > 0) {
                        unique?.map((item: any) => {
                            append({ name: item })
                            return item;
                        })
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (fileSelected) {
            onGettingListValuesFromFile()
        }
    }, [fileSelected])

    return (
        <Modal
            open={openFieldsModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Asset Field</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {((!fieldLoading && fieldItemId) || !fieldItemId) ?
                        <div className='flex flex-col my-4'>
                            <Input
                                placeholder='Name'
                                dataQa={'asset-field-name'}
                                register={register('name', {
                                    required: {
                                        message: 'Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) => !!value.trim() || 'Name is required'
                                })}
                                error={errors.name?.message}
                            />
                            {type !== 'rental' && type !== 'extended' &&
                                <div>
                                    <div className='flex flex-row justify-start items-center mb-6 min-w-[100%]'>
                                        <div className='flex items-center mr-1'>
                                            <input
                                                onChange={() => onChangeInventoryField()}
                                                checked={showFieldInInventory}
                                                id='checkbox-all-1'
                                                data-qa={'checkbox'}
                                                type='checkbox'
                                                className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                            />
                                            <label htmlFor='checkbox-all-1' className='sr-only'>
                                            </label>
                                        </div>
                                        <p className='text-[#a4a4a4]'>
                                            Show Field In Inventory
                                        </p>
                                    </div>
                                </div>
                            }
                            {!type &&
                                <div>
                                    <div className='flex flex-row justify-start items-center mb-6 min-w-[100%]'>
                                        <div className='flex items-center mr-1'>
                                            <input
                                                onChange={() => onChangeAppField()}
                                                checked={hideFieldInApp}
                                                id='checkbox-all-2'
                                                data-qa={'checkbox'}
                                                type='checkbox'
                                                className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                            />
                                            <label htmlFor='checkbox-all-2' className='sr-only'>
                                            </label>
                                        </div>
                                        <p className='text-[#a4a4a4]'>
                                            Hide Field In App
                                        </p>
                                    </div>
                                </div>
                            }
                            <div className={'my-3'}>
                                <Switch
                                    onToggleSwitch={onChangeFieldRequired}
                                    checked={requiredField}
                                    beforeText={'Required Field'}
                                    dataQa={'asset-field-switch'}
                                />
                            </div>
                            <Select
                                placeholder='Data Type'
                                dataQa={'data-type'}
                                register={register('dataType', {
                                    required: {
                                        message: 'Data type is required',
                                        value: true,
                                    },
                                    validate: (value: string) => !!value.trim() || 'Data type is required'
                                })}
                                options={dataTypes}
                                error={errors?.dataType?.message}
                            />
                            {watchDataType === 'list' &&
                                <>
                                    <div className='flex flex-row items-center'>
                                        <Input
                                            placeholder='List Value'
                                            containerStyle={'mt-0 flex flex-row h-full items-center'}
                                            showValue={true}
                                            inputValue={newValue}
                                            inputUniqueName={'newValue'}
                                            onChangeInput={onChangeNewValue}
                                            dataQa={'list-value'}
                                        />
                                        <Button
                                            label='Add Value'
                                            className='btn-filters ml-2 mt-0'
                                            onClickButton={onAddNewValue}
                                            dataQa={'add-value'}
                                        />
                                        <div className='flex flex-col items-center justify-center ml-2' {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Button
                                                icon={<img alt='upload' src='/assets/shared/upload-file.svg' className='w-[20px] object-contains pr-2' />}
                                                className={'btn-main !bg-[#202020] !text-white !py-1 !shadow-none flex flex-row items-center'}
                                                label={'Select File'}
                                                dataQa={'select-file'}
                                            />
                                        </div>
                                    </div>
                                    {listError && <Error text={listError} />}
                                    <div className='flex flex-row items-center flex-wrap my-3'>
                                        {fields && fields?.length > 0 && fields?.map((item: any, idx: number) => {
                                            return (
                                                <div key={idx} className={'flex flex-row items-center justify-between border border-solid border-[#F7A21E] rounded-md px-1 mr-1 mb-1'}>
                                                    <p className='text-slate-400'>{item?.name}</p>
                                                    <div data-qa={'delete-value'} className='pl-1 flex flex-row items-center h-full' onClick={() => onDeleteValue(idx)}>
                                                        <img alt='close' src={'/assets/shared/close-grey.svg'} className={'w-[20px] mt-1 object-contain cursor-pointer'} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                            }
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    type={'submit'}
                                    className={'btn-primary'}
                                    dataQa={'save-btn'}
                                />
                            </div>
                        </div>
                        : <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    }
                </form>
            </div>
        </Modal>
    )
}
export default FieldsModal;