import React, { useState } from 'react';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Loading from '../../../../shared/Loading';


interface ICompanyDetails {
    setCompanyData: any;
    companyData: any;
    createError?: string;
    personalData: any;
    userAgreed?: boolean;
    onGoToLogin: () => void;
    onClickAgreeWithTerms: () => void;
    onRequestToRegisterPartner: (data: IPartners) => Promise<boolean>;
}

const CompanyDetails = ({
    setCompanyData,
    createError,
    companyData,
    personalData,
    userAgreed,
    onGoToLogin,
    onRequestToRegisterPartner,
    onClickAgreeWithTerms
}: ICompanyDetails) => {
    const [errorCompanyData, setErrorCompanyData] = useState<any>();
    const [loadingRegister, setLoadingRegister] = useState<boolean>(false);

    const onSubmit = async () => {
        const payload: any = {
            ...personalData || {},
            ...companyData || {},
            enabled: false,
            username: (personalData?.username || '').toLowerCase(),
            type: 'organization-account',
            dashboard: 'brokers'
        };
        delete payload.confirmPassword;
        const response = await onRequestToRegisterPartner(payload);
        if (response) {
            setCompanyData(undefined)
        }
        setLoadingRegister(false)
    };

    const onChangeInput = (e: any, type?: string) => {
        if (type) {
            setCompanyData((prevData: any) => ({
                ...prevData || {},
                [type]: e
            }))
        }
    }

    const onValidateSubmit = () => {
        setLoadingRegister(true)
        let errors: any
        if (!companyData?.country) {
            errors = {
                ...errors || {},
                country: 'Country is required'
            }
        }
        if (!companyData?.companyName) {
            errors = {
                ...errors || {},
                companyName: 'Company Name is required'
            }
        }
        if (!companyData?.companyRegistrationNumber) {
            errors = {
                ...errors || {},
                companyRegistrationNumber: 'Company Registration Number is required'
            }
        }
        if (!companyData?.url) {
            errors = {
                ...errors || {},
                url: 'Company URL is required'
            }
        }
        if (errors) {
            setErrorCompanyData(errors)
            setLoadingRegister(false)
        } else {
            setErrorCompanyData(undefined)
            onSubmit()
        }
    }

    return (
        <div className={`block min-h-full flex flex-col py-4 px-10`}>
            <div className='flex flex-row justify-center my-5'>
                <p className='font-bold mb-2 text-[35px] text-center'>
                    Apply to be a broker on wingpro
                </p>
            </div>
            <p className='font-semibold mb-3'>Company Details</p>
            <div className='grid grid-cols-1 mb-3'>
                <Input
                    placeholder='Country'
                    dataQa={'create-partner-country'}
                    containerStyle='mb-1'
                    inputUniqueName='country'
                    showValue={true}
                    onChangeInput={onChangeInput}
                    inputValue={companyData?.country}
                    error={errorCompanyData?.country}
                />
                <Input
                    placeholder='Company Registration Number'
                    dataQa={'company-registation-number'}
                    containerStyle='mb-1'
                    inputUniqueName='companyRegistrationNumber'
                    showValue={true}
                    onChangeInput={onChangeInput}
                    inputValue={companyData?.companyRegistrationNumber}
                    error={errorCompanyData?.companyRegistrationNumber}
                />
                <Input
                    placeholder='Company Name'
                    dataQa={'create-partner-name'}
                    containerStyle='mb-1'
                    inputUniqueName='companyName'
                    showValue={true}
                    onChangeInput={onChangeInput}
                    inputValue={companyData?.companyName}
                    error={errorCompanyData?.companyName}
                />
                <Input
                    placeholder='Company URL'
                    dataQa={'company-url'}
                    containerStyle='mb-1'
                    inputUniqueName='url'
                    showValue={true}
                    inputValue={companyData?.url}
                    onChangeInput={onChangeInput}
                    error={errorCompanyData?.url}
                />
            </div>
            <div className='flex flex-row justify-center items-center my-2'>
                <div className='flex items-center mr-2'>
                    <input
                        onChange={() => onClickAgreeWithTerms()}
                        checked={userAgreed}
                        data-qa={'create-partner-checkbox'}
                        id='checkbox-all'
                        type='checkbox'
                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                    />
                    <label htmlFor='checkbox-all' className='sr-only'>
                        checkbox
                    </label>
                </div>
                <p>
                    I agree to{' '}
                    <span className='text-[#F7A21E] cursor-pointer'>
                        <a
                            href='https://wing-broker-engine.s3.eu-west-1.amazonaws.com/Broker+Partner+Terms+of+Engagement.pdf'
                            target={'_blank'}
                            data-qa={'crate-partner-terms-link'}
                            rel='noreferrer'
                        >
                            terms and conditions
                        </a>
                    </span>
                    .
                    {/* To find out what personal data we collect and how we use it, please visit our <span className='text-[#F7931E]'>Privacy Policy</span>. */}
                </p>
            </div>
            {createError && <Error text={createError} />}
            <div className='flex flex-row justify-center py-2'>
                <Button
                    label='Apply'
                    onClickButton={onValidateSubmit}
                    disabled={loadingRegister}
                    icon={(loadingRegister) && <Loading />}
                    className={'btn-primary !min-w-[200px] !py-2'}
                    dataQa={'create-partner-btn'}
                />
            </div>
            <div className='my-4 flex flex-row items-center justify-center'>
                <div className='min-w-[20%] h-[0.7px] m-1 inline-block bg-black'></div>
                <div className='inline-block mx-2 font-semibold'>Or</div>
                <div className='min-w-[20%] h-[0.7px] m-1 inline-block bg-black'></div>
            </div>
            <div className='my-3 flex flex-row justify-center items-center'>
                <p>Already have an account? </p>
                <Button
                    className='text-primary-default !pl-0 !ml-1 underline font-medium'
                    label='Log in'
                    dataQa={'login-button'}
                    onClickButton={onGoToLogin}
                />
            </div>
        </div>
    );
};

export default CompanyDetails;