import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useAppDispatch,
    useAppSelector
} from '../../../app/hooks';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { tryToFetchAssetTemplateFilters } from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { tryToFetchDetailedPricingHistory } from '../../../store/brokers/shared-endpoints/pricing-history/pricingHistorySlice';
import { tryToFetchAllCurrencies } from '../../../store/brokers/shared-endpoints/wholesale-price/wholesalePriceSlice';
import { INVENTORY_FILTERS } from '../../../utils/constants/inventory-filter-values';
import PricingFilters from './details/PricingFilters';
import PricingHistoryDetailsTable from './tables/PricingHistoryDetailsTable';
import Button from '../../../shared/button';
import SelectCheckbox from '../../../shared/select-checkbox';


const PricingHistoryDetails = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [rows, setRows] = useState<Array<any>>([]);
    const [templateFilters, setTemplateFilters] = useState<Array<any>>();
    const [categorySelected, setCategorySelected] = useState<string>();
    const [typeSelected, setTypeSelected] = useState<any>()
    const [filterValues, setFilterValues] = useState<any>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [allCurrencies, setAllCurrencies] = useState<any>()
    const paginationData = useAppSelector((state) => state?.pricingHistory?.pricingHistoryDetails?.data?.page);
    const stateFilters = useAppSelector((state) => state.assetsTemplates);
    const state = useAppSelector((state) => state.pricingHistory);
    const priceLoading = useAppSelector((state) => state.pricingHistory.pricingIsLoading)
    const currencies = useAppSelector((state: any) => state?.wholesale?.wholesaleCurrencies);

    useEffect(() => {
        if (stateFilters.assetTemplateFilters) {
            setTemplateFilters(stateFilters.assetTemplateFilters)
        }
    }, [stateFilters?.assetTemplateFilters]);

    useEffect(() => {
        if (currencies) {
            const formatCurrencies = currencies && currencies?.length > 0 && currencies?.map((currency: any) => ({ label: currency?.descriptor?.name, value: currency?._id, name: currency?.descriptor?.name }))
            setAllCurrencies(formatCurrencies || undefined)
        }
    }, [currencies])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

    useEffect(() => {
        if (state.pricingHistoryDetails) {
            const rowsData =
                state.pricingHistoryDetails?.data?.elements && Array.isArray(state.pricingHistoryDetails?.data.elements)
                    ? state.pricingHistoryDetails?.data.elements.map((r) => ({ ...r }))
                    : [];
            if (rowsData !== rows) {
                setRows(rowsData);
            }
            if (!categorySelected) {
                setCategorySelected(rowsData?.[0]?.assetTemplateId || undefined)
            }
        }
    }, [state.pricingHistoryDetails]);

    const onGettingPricingData = async () => {
        const filters = filterValues && Object.keys(filterValues)
        let formatFilters
        const filtersValuesFormat = filters && filters?.length > 0 && filters?.map((item: any) => {
            return {
                key: item,
                value: Object.keys(filterValues) ? filterValues?.[item] || undefined : undefined
            }
        })
        if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
            formatFilters = {
                value: filtersValuesFormat?.filter((key: any) => key?.value)
            }
        }
        let data: any = {}
        if (formatFilters && formatFilters?.value) {
            data = {
                ...data || {},
                ...formatFilters || {}
            }
        }
        if (typeSelected) {
            data = {
                ...data,
                succeeded: typeSelected === 'Yes'
            }
        }
        const fetchData = {
            ...paginationState || {},
            data,
            filters: id || ''
        }
        dispatch(tryToFetchDetailedPricingHistory(fetchData)).unwrap()
    }

    useEffect(() => {
        dispatch(tryToFetchAllCurrencies())
    }, [])

    useEffect(() => {
        onGettingPricingData()
    }, [paginationState])

    useEffect(() => {
        if (categorySelected) {
            dispatch(tryToFetchAssetTemplateFilters({ id: categorySelected }))
        }
        setFilterValues(undefined)
    }, [categorySelected])

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        if (type) {
            const findField = templateFilters?.find((field) => field?.name === type);
            setFilterValues({
                ...(filterValues || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
            onResetPaginationSettings()
        }
    };

    const onChangeFilterInput = (value: string | null | undefined, type: string) => {
        const findField = templateFilters?.find((field) => field?.name === type);
        setFilterValues({
            ...(filterValues || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
        onResetPaginationSettings()
    };

    const onGoBack = () => {
        navigate(-1)
    }

    const onClearAllFilters = () => {
        setTypeSelected(undefined)
        setFilterValues(undefined)
        onResetPaginationSettings()
    }

    const onSelectSucceededType = (value: any, type?: string) => {
        setTypeSelected(value?.value)
        onResetPaginationSettings()
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Pricing History Details</p>
                </div>
            </div>
            <div>
                {categorySelected && templateFilters && templateFilters?.length > 0 &&
                    <PricingFilters
                        items={templateFilters || []}
                        filterValues={filterValues || null}
                        onChangeInput={onChangeFilterInput}
                        onChangeSelectedValue={onChangeSelectedFilterValue}
                    />
                }
            </div>
            <div className='mb-5 grid grid-cols-3 gap-4'>
                <SelectCheckbox
                    name='Status Succeeded'
                    placeholder={typeSelected || ''}
                    dataQa={typeSelected === undefined ? 'status-succeedded' : typeSelected.toLowerCase()}
                    options={INVENTORY_FILTERS || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200, }}
                    onChangeSelectedOption={onSelectSucceededType}
                    uniqueName={'succeeded'}
                />
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label='Clear all filters'
                    dataQa={'clear-all-filters'}
                    className='btn-primary-text-underline min-w-auto col-span-1 col-end-8 flex flex-row justify-end items-center'
                    onClickButton={onClearAllFilters}
                />
            </div>
            <div>
                <PricingHistoryDetailsTable
                    rows={rows}
                    state={state}
                    currencies={allCurrencies}
                    pricingLoading={priceLoading}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                />
            </div>
        </div >
    )
}

export default PricingHistoryDetails;