import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { SalesOrdersService } from './salesOrdersApi';
import { ISaleList } from '../../../../interfaces/sales/ISalesList';
import { ISale } from '../../../../interfaces/sales/ISales';


const initialState: ISaleList = {
    saleIsLoading: false,
    salesAreLoading: false,
    saleNotesAreLoading: true,
    saleContentIsLoading: false,
    mainOrderLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchSales = createAsyncThunk<any, IPaginationPayload>(
    'salesOrders/tryToFetchSales',
    async ({ pageNumber, pageSize, data }) => {
        const result = await SalesOrdersService.tryToFetchSales(
            pageNumber,
            pageSize,
            data,
        );
        return result?.data;
    }
);

export const tryToFetchProformas = createAsyncThunk<any, { pageNumber: number, pageSize: number, search: string }>(
    'salesOrders/tryToFetchProformas',
    async ({ pageNumber, pageSize, search }) => {
        const result = await SalesOrdersService.tryToFetchProformas(pageNumber, pageSize, search);
        return result?.data;
    }
);


export const tryToFetchInvoices = createAsyncThunk<any, { pageNumber: number, pageSize: number, search: string }>(
    'salesOrders/tryToFetchInvoices',
    async ({ pageNumber, pageSize, search }) => {
        const result = await SalesOrdersService.tryToFetchInvoices(pageNumber, pageSize, search);
        return result?.data;
    }
);

export const tryToFetchSingleSale = createAsyncThunk<any, string>(
    'salesOrders/tryToFetchSingleSale',
    async (id: string) => {
        const result = await SalesOrdersService.tryToFetchSingleSale(id);
        return result?.data;
    },
);

export const tryToCreateSale = createAsyncThunk<any, ISale>(
    'salesOrders/tryToCreateSale',
    async (data) => {
        const result = await SalesOrdersService.tryToCreateSale(data);
        return result?.data?.data;
    },
);

export const tryToEditSale = createAsyncThunk<any, { id: string, data: ISale }>(
    'salesOrders/tryToEditSale',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await SalesOrdersService.tryToEditSale(id, data);
            return result?.data?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDeleteSale = createAsyncThunk<ApiResponse<ISale>, string>(
    'salesOrders/tryToDeleteSale',
    async (id, { rejectWithValue }) => {
        try {
            const result = await SalesOrdersService.tryToDeleteSale(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToSubmitSale = createAsyncThunk<any, string>(
    'salesOrders/tryToSubmitSale',
    async (id, { rejectWithValue }) => {
        try {
            const result = await SalesOrdersService.tryToSubmitSale(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToImportBatchSale = createAsyncThunk<any, any>(
    'salesOrders/tryToImportBatchSale',
    async (data, { rejectWithValue }) => {
        try {
            const result = await SalesOrdersService.tryToImportBatchSale(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToAcceptOrRejectSale = createAsyncThunk<any, any>(
    'salesOrders/tryToAcceptOrRejectSale',
    async ({ id, status, data }, { rejectWithValue }) => {
        try {
            const result = await SalesOrdersService.tryToAcceptOrRejectSale(id, status, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const salesOrdersSlice = createSlice({
    name: 'salesOrders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchSales
            .addCase(tryToFetchSales.pending, (state) => {
                state.salesAreLoading = true;
            })
            .addCase(tryToFetchSales.rejected, (state) => {
                state.salesAreLoading = false;
            })
            .addCase(tryToFetchSales.fulfilled, (state, action) => {
                state.salesAreLoading = false;
                state.sales = action.payload;
            })

            // tryToFetchSingleSale
            .addCase(tryToFetchSingleSale.pending, (state) => {
                state.saleIsLoading = true;
            })
            .addCase(tryToFetchSingleSale.rejected, (state) => {
                state.saleIsLoading = false;
            })
            .addCase(tryToFetchSingleSale.fulfilled, (state, action) => {
                state.saleIsLoading = false;
                state.saleDetails = action.payload.data;
            })

            // tryToEditSale
            .addCase(tryToEditSale.pending, (state) => {
                state.saleIsLoading = true;
            })
            .addCase(tryToEditSale.rejected, (state) => {
                state.saleIsLoading = false;
            })
            .addCase(tryToEditSale.fulfilled, (state, action) => {
                state.saleIsLoading = false;
                state.saleDetails = action?.payload?.data;
            })

            // tryToCreateSale
            .addCase(tryToCreateSale.pending, (state) => {
                state.saleIsLoading = true;
            })
            .addCase(tryToCreateSale.rejected, (state) => {
                state.saleIsLoading = false;
            })
            .addCase(tryToCreateSale.fulfilled, (state, action) => {
                state.saleIsLoading = false;
                state.saleDetails = action.payload;
            })

            // tryToDeleteSale
            .addCase(tryToDeleteSale.pending, (state) => {
                state.saleIsLoading = true;
            })
            .addCase(tryToDeleteSale.rejected, (state) => {
                state.saleIsLoading = false;
            })
            .addCase(tryToDeleteSale.fulfilled, (state, action) => {
                state.saleIsLoading = false;
                state.requestStatus = 'success';
                if (state.sales?.data.elements)
                    state.sales.data.elements =
                        { ...state }.sales?.data.elements.filter(
                            (sale) => sale?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToSubmitSale
            .addCase(tryToSubmitSale.pending, (state) => {
                state.salesAreLoading = true;
            })
            .addCase(tryToSubmitSale.rejected, (state) => {
                state.salesAreLoading = false;
            })
            .addCase(tryToSubmitSale.fulfilled, (state, action) => {
                state.salesAreLoading = false;
                state.requestStatus = 'success';
                if (state.sales?.data.elements)
                    state.sales.data.elements =
                        { ...state }.sales?.data.elements.map((sale) => {
                            if (sale?._id === action.meta.arg) {
                                return {
                                    ...sale || {},
                                    status: 'submitted'
                                }
                            } else {
                                return sale
                            }
                        }
                        ) || [];
            })

            // tryToAcceptOrRejectSale
            .addCase(tryToAcceptOrRejectSale.pending, (state) => {
                state.salesAreLoading = true;
            })
            .addCase(tryToAcceptOrRejectSale.rejected, (state) => {
                state.salesAreLoading = false;
            })
            .addCase(tryToAcceptOrRejectSale.fulfilled, (state, action) => {
                state.salesAreLoading = false;
                state.requestStatus = 'success';
                if (state.sales?.data.elements)
                    state.sales.data.elements =
                        { ...state }.sales?.data.elements.map((sale) => {
                            if (sale?._id === action.meta.arg?.id) {
                                return {
                                    ...sale || {},
                                    status: action?.meta?.arg?.status,
                                    offers: action?.payload?.data?.offers
                                }
                            } else {
                                return sale
                            }
                        }
                        ) || [];
            })
    },
});

export default salesOrdersSlice.reducer;
