import React, { useEffect, useState, useCallback } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddWarehouseFields,
    tryToEditWarehouseField,
    tryToFetchWarehouseField
} from '../../../../store/brokers/admin/warehouses/warehousesSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Table from '../../../../shared/table';
import Select from '../../../../shared/select';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';


interface IWarehouseFieldModal {
    openFieldsModal: boolean;
    fieldItemId?: string | null;
    warehouseId: string;
    handleCloseFieldsModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    type: string;
};

const dataTypes = [
    { label: 'String', value: 'string' },
    { label: 'List', value: 'list' },
]

const WarehouseFieldModal = ({
    openFieldsModal,
    fieldItemId,
    warehouseId,
    handleCloseFieldsModal,
    onSaveChanges,
}: IWarehouseFieldModal) => {
    const dispatch = useAppDispatch();
    const [listError, setListError] = useState<string>()
    const [listValues, setListValues] = useState<any>([])
    const [newEditListValue, setNewEditListValue] = useState<string>()
    const [currentListValue, setCurrentListValue] = useState<string>()
    const [editListValue, setEditListValue] = useState<{ value: any, index: number }>()
    const [fileSelected, setFileSelected] = useState<any>()
    const state = useAppSelector((state) => state.warehouses);
    const fieldLoading = useAppSelector((state) => state.warehouses?.fieldIsLoading);

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        setValue('name', fieldItemId ? state?.warehouseField?.name || '' : '')
        setValue('type', fieldItemId ? state?.warehouseField?.type || '' : '')
        const formatValuesData = fieldItemId && state?.warehouseField?.values && state?.warehouseField?.values?.length > 0 && state?.warehouseField?.values?.map((v) => { return { value: v } })
        setListValues(formatValuesData || undefined)
    }, [state.warehouseField]);

    useEffect(() => {
        if (fieldItemId) {
            const data = {
                warehouseId: warehouseId,
                fieldId: fieldItemId
            }
            dispatch(tryToFetchWarehouseField(data));
        }
    }, [fieldItemId])

    const onCloseModal = () => {
        setListError(undefined)
        setFileSelected(undefined)
        setCurrentListValue(undefined)
        setEditListValue(undefined)
        setListValues(undefined)
        setNewEditListValue(undefined)
        reset();
        handleCloseFieldsModal()
    }

    const watchDataType = watch('type');
    const onSubmit = async (data: FormValues) => {
        if (watchDataType === 'list' && (!listValues || listValues?.length <= 0)) {
            setListError('Please write at least one list item.')
            return;
        }
        const formatData: any = {
            name: data?.name,
            type: data?.type
        }
        if (listValues && listValues?.length > 0 && watchDataType === 'list') {
            formatData.values = listValues?.map((value: any) => value?.value)
        }
        const payload: any = {
            data: {
                ...formatData,
            },
            warehouseId: warehouseId || ''
        }
        try {
            if (fieldItemId) {
                payload.fieldId = fieldItemId
                await dispatch(tryToEditWarehouseField(payload)).unwrap();
            } else {
                await dispatch(tryToAddWarehouseFields(payload)).unwrap();
            }
            onSaveChanges('success', `Field successfully ${fieldItemId ? 'changed' : 'added'}.`)
        } catch (error) {
            onSaveChanges('error', `${error}`)
        }
        onCloseModal();
    };

    const onGettingListValuesFromFile = () => {
        if (fileSelected) {
            const valuesList: any = []
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const key = results?.data?.[0] && Object.keys(results?.data?.[0])
                    const unique = results && results?.data && results?.data?.length > 0 && results?.data?.map((item: any) => item?.[key?.[0]])
                        .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
                    if (unique && unique?.length > 0) {
                        unique?.map((item: any) => {
                            valuesList.push({ value: item })
                            return item;
                        })
                    }
                }
            })
            setListValues(valuesList || undefined)
        }
    }

    useEffect(() => {
        if (fileSelected) {
            onGettingListValuesFromFile()
        }
    }, [fileSelected])

    const onChangeCurrentListValue = (value: any, type?: string) => {
        const findIfCurrentExist = listValues && listValues?.length > 0 && listValues?.find((item: any) => item?.type === 'current')
        if (findIfCurrentExist && value && value !== '') {
            const formatLatestItem = (listValues && listValues?.length > 0) && listValues?.map((item: any) => {
                if (item?.type === 'current') {
                    return {
                        value: value,
                        type: 'current'
                    }
                }
                else {
                    return item
                }
            })
            setListValues(formatLatestItem)
        } else if (!value || value === '') {
            const filterItems = (listValues && listValues?.length > 0) && listValues?.filter((item: any) => item?.type !== 'current')
            setListValues(filterItems || [])
        } else {
            setListValues([
                ...listValues || [],
                {
                    value: value,
                    type: 'current'
                }
            ])
        }
        setCurrentListValue(value);
    }

    const onRemoveListValue = (index: any) => {
        const newValues = (listValues && listValues?.length > 0) && listValues?.filter((item: any, idx: any) => idx !== index)
        setListValues(newValues || [])
    }

    const onDoubleClickListValue = (value: any, idx: any) => {
        setEditListValue({ value: value, index: idx })
        setNewEditListValue(value)
    }

    const onEditListValue = (value: any, type?: string) => {
        setNewEditListValue(value)
    }

    const onEnterListValueInput = async (e: any) => {
        e.stopPropagation()
        if (e?.key === 'Enter') {
            const formatListValues = (listValues && listValues?.length > 0) && listValues?.map((item: any, index: number) => {
                return index === editListValue?.index ? { value: newEditListValue } : item
            })
            setListValues(formatListValues || [])
            setNewEditListValue(undefined)
            setEditListValue(undefined)
        }
    }

    const onEnterMainListValueInput = async (e: any) => {
        if (e?.key === 'Enter' && currentListValue) {
            const formatLatestItem = (listValues && listValues?.length > 0) && listValues?.map((item: any) => {
                if (item?.type === 'current') {
                    return {
                        ...item,
                        type: 'saved'
                    }
                }
                else {
                    return item
                }
            })
            setListValues(formatLatestItem)
            setCurrentListValue(undefined)
            setNewEditListValue(undefined)
            setEditListValue(undefined)
        }
    }

    const onAddValueOnList = async () => {
        if (currentListValue) {
            const formatLatestItem = (listValues && listValues?.length > 0) && listValues?.map((item: any) => {
                if (item?.type === 'current' && currentListValue === item?.value) {
                    return {
                        ...item,
                        type: 'saved'
                    }
                }
                else {
                    return item
                }
            })
            setListValues(formatLatestItem)
            setCurrentListValue(undefined)
            setNewEditListValue(undefined)
            setEditListValue(undefined)
        }
    }

    return (
        <Modal
            open={openFieldsModal}
            onClose={onCloseModal}
            contentContainerStyle='!min-w-[65vw]'>
            <div className={'p-2 min-w-[60vw]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>{!fieldItemId ? 'Warehouse Add Field' : 'Warehouse Edit Field'}</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e: any) => { e.key === 'Enter' && e?.preventDefault(); }}>
                    {((!fieldLoading && fieldItemId) || !fieldItemId) ?
                        <div className='flex flex-col my-4'>
                            <div className='grid grid-cols-2'>
                                <Input
                                    placeholder='Name'
                                    dataQa={'name'}
                                    register={register('name', {
                                        required: {
                                            message: 'Name is required',
                                            value: true,
                                        },
                                        validate: (value: string) => !!value.trim() || 'Name is required'
                                    })}
                                    error={errors.name?.message}
                                />
                            </div>
                            <div className='grid grid-cols-2'>
                                <Select
                                    placeholder='Data Type'
                                    dataQa={'data-type'}
                                    register={register('type', {
                                        required: {
                                            message: 'Data type is required',
                                            value: true,
                                        },
                                        validate: (value: string) => !!value.trim() || 'Data type is required'
                                    })}
                                    options={dataTypes}
                                    error={errors?.type?.message}
                                />
                            </div>
                            {watchDataType === 'list' &&
                                <>
                                    <div className='grid grid-cols-4 items-center'>
                                        <Input
                                            placeholder='List Value'
                                            dataQa={'list-value'}
                                            containerStyle={'mt-0 flex flex-row h-full items-center col-span-2'}
                                            showValue={true}
                                            inputValue={currentListValue}
                                            inputUniqueName={'newValue'}
                                            onChangeInput={onChangeCurrentListValue}
                                            onHandleKeyDown={onEnterMainListValueInput}
                                        />
                                        <Button
                                            label='Add Value'
                                            dataQa={'add-value'}
                                            className='btn-filters !rounded-none ml-2 mt-0'
                                            onClickButton={onAddValueOnList}
                                        />
                                        <div className='flex flex-col items-center justify-center ml-2' {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Button
                                                icon={<img src='/assets/shared/upload-file.svg' className='w-[20px] object-contains pr-2' />}
                                                className={'btn-main !bg-[#202020] !text-white !py-1 w-[90%] text-center !shadow-none flex flex-row justify-center items-center'}
                                                label={'Select File'}
                                                dataQa={'select-file'}
                                            />
                                        </div>
                                    </div>
                                    {listError && <Error text={listError} />}
                                    <div className=''>
                                        {(listValues && listValues?.length > 0) &&
                                            <Table
                                                hidePagination={true}
                                                columns={['Value', 'Actions']}>
                                                {listValues.map((obj: any, idx: number) => {
                                                    return (
                                                        <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                                            <td className='px-6 py-2' onClick={() => setEditListValue(undefined)}>
                                                                {editListValue?.index === idx ?
                                                                    <div onClick={(e) => e.stopPropagation()}>
                                                                        <Input
                                                                            placeholder='Item Value'
                                                                            showValue={true}
                                                                            inputValue={newEditListValue}
                                                                            onChangeInput={onEditListValue}
                                                                            onHandleKeyDown={onEnterListValueInput}
                                                                        />
                                                                    </div>
                                                                    : <div onDoubleClick={() => onDoubleClickListValue(obj.value || '', idx)}>
                                                                        {obj.value}
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td className='px-6 py-2 cursor-pointer'>
                                                                <div onClick={() => onRemoveListValue(idx)}>
                                                                    <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] ml-4 hover:w-[17px] object-contain'} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </Table>
                                        }
                                    </div>
                                </>
                            }
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    dataQa={'save-bttn'}
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                        : <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    }
                </form>
            </div>
        </Modal>
    )
}
export default WarehouseFieldModal;