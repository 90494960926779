import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { ITicketListState } from '../../../../interfaces/customer-care/ITicketList';
import { CustomerCareService } from './customerCareApi';
import { PaginatedTicket } from '../../../../interfaces/customer-care/paginatedStore.type';
import { ITicketThread } from '../../../../interfaces/customer-care/ITicket';
import { ITicketSchema } from '../../../../interfaces/customer-care/ITicketSchema';

const initialState: ITicketListState = {
    ticketsAreLoading: false,
    ticketIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchFilteredTickets = createAsyncThunk<PaginatedTicket, any>(
    'cs/tryToFetchFilteredTickets',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await CustomerCareService.tryToFetchFilteredTickets(pageNumber, pageSize, filters, data);
        return result?.data;
    },
);

export const tryToFetchUserTickets = createAsyncThunk<PaginatedTicket, any>(
    'cs/tryToFetchUserTickets',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await CustomerCareService.tryToFetchUserTickets(pageNumber, pageSize, filters, data);
        return result?.data;
    },
);

export const tryToAddTicket = createAsyncThunk<any, any>(
    'cs/tryToAddTicket',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await CustomerCareService.tryToAddTicket(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAddTicketThread = createAsyncThunk<ApiResponse<ITicketSchema>, { ticketId: string; data: ITicketThread }>(
    'cs/tryToAddTicketThread',
    async ({ ticketId, data }, { rejectWithValue }) => {
        try {
            const result = await CustomerCareService.tryToAddTicketThread(ticketId, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const csSlice = createSlice({
    name: 'cs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchFilteredTickets
            .addCase(tryToFetchFilteredTickets.pending, (state) => {
                state.ticketsAreLoading = true;
            })
            .addCase(tryToFetchFilteredTickets.rejected, (state) => {
                state.ticketsAreLoading = false;
            })
            .addCase(tryToFetchFilteredTickets.fulfilled, (state, action) => {
                state.ticketsAreLoading = false;
                state.tickets = action.payload;
            })

            // tryToFetchUserTickets
            .addCase(tryToFetchUserTickets.pending, (state) => {
                state.ticketsAreLoading = true;
            })
            .addCase(tryToFetchUserTickets.rejected, (state) => {
                state.ticketsAreLoading = false;
            })
            .addCase(tryToFetchUserTickets.fulfilled, (state, action) => {
                state.ticketsAreLoading = false;
                state.tickets = action.payload;
            })

            // tryToAddTicket
            .addCase(tryToAddTicket.pending, (state) => {
                state.ticketIsLoading = true;
            })
            .addCase(tryToAddTicket.rejected, (state) => {
                state.ticketIsLoading = false;
            })
            .addCase(tryToAddTicket.fulfilled, (state, action) => {
                state.ticketIsLoading = false;
                state.requestStatus = 'success';
                const data: any = { ticket: action.payload.data }
                if (state.tickets?.data.elements) state.tickets.data.elements = [data, ...state.tickets?.data.elements];
                else {
                    state.tickets = {
                        data: {
                            elements: [
                                {
                                    ticket: {
                                        ...action?.payload?.data || {}
                                    },
                                }
                            ],
                            page: {
                                size: 1,
                                totalElements: 1,
                                totalPages: 1,
                                number: 1,
                                hasNextPage: false,
                            },
                            _links: {
                                first: '',
                                prev: '',
                                self: '',
                                next: '',
                                last: ''
                            }
                        },
                        success: true,
                        status: 201,
                        aggregationId: ''
                    }
                }
            })


            // tryToAddTicketThread
            .addCase(tryToAddTicketThread.pending, (state) => {
                state.ticketIsLoading = true;
            })
            .addCase(tryToAddTicketThread.rejected, (state) => {
                state.ticketIsLoading = false;
            })
            .addCase(tryToAddTicketThread.fulfilled, (state, action) => {
                state.ticketIsLoading = false;
                state.requestStatus = 'success';
                if (state.tickets?.data) state.tickets.data.elements =
                    { ...state }.tickets?.data.elements.map((ticket) => {
                        if (ticket?.ticket?._id === action.meta?.arg?.ticketId) {
                            return {
                                ...ticket,
                                ticket: {
                                    ...ticket?.ticket || {},
                                    threads: action?.payload?.data?.threads
                                }
                            };
                        } else {
                            return ticket;
                        }
                    }) || [];
            })
    },
});

export default csSlice.reducer;