import React from 'react';
import Button from '../button';


interface ISuccessRaised {
    handleCloseModal: () => void;
}

const SuccessRaised = ({
    handleCloseModal
}: ISuccessRaised) => {
    return (
        <div className={'w-full flex flex-col items-center justify-between'}>
            <div>
                <p className='text-xl font-bold text-center'>Thank you for contacting Customer Support.</p>
                <p className='text-xl font-bold text-center'>We’ll come back to you shortly.</p>
            </div>
            <img src={'/assets/auth/register-phone.svg'} className={'h-[150px] object-contain my-6'} />
            <Button
                label='Close'
                className='btn-main my-3 min-w-[150px] !shadow-none'
                onClickButton={handleCloseModal}
            />
        </div>
    )
}
export default SuccessRaised;