import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddInvoiceTemplate,
    tryToEditInvoiceTemplate,
    tryToFetchSingleInvoiceTemplate
} from '../../../../store/brokers/admin/invoice-templates/invoiceTemplateSlice';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IManageTemplatesModal {
    currencies?: any[];
    openModal: boolean;
    id?: string | null;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    displayName: string;
    title: string;
    templateName: string;
    vatNumber?: string;
    currency: string;
    informationName?: string;
    informationCompany?: string;
    informationAddress?: string;
    informationCountry?: string;
    informationPhoneNumber?: string;
    informationEmail?: string;
    bankAccountName?: string;
    bankAccountSortCode?: string;
    bankAccountNumber?: string;
    iban?: string;
    bic?: string;
    bankName?: string;
    wireRoutingNumber?: string;
    achRoutingNumber?: string;
};

const ManageTemplatesModal = ({
    openModal,
    id,
    currencies,
    handleCloseModal,
    onSaveChanges,
}: IManageTemplatesModal) => {
    const dispatch = useAppDispatch();
    const [selectedCurrency, setSelectedCurrency] = useState<any>();
    const [saveError, setSaveError] = useState<string>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const templateLoading = useAppSelector((state) => state.invoiceTemplates?.templateIsLoading);
    const state = useAppSelector((state) => state.invoiceTemplates);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        if (id) {
            setValue('displayName', id ? state?.invoiceTemplate?.displayName || '' : '');
            setValue('title', id ? state?.invoiceTemplate?.title || '' : '');
            setValue('templateName', id ? state?.invoiceTemplate?.templateName || '' : '');
            setValue('vatNumber', id ? state?.invoiceTemplate?.vatNumber || undefined : undefined);
            setValue('currency', id ? state?.invoiceTemplate?.currency || '' : '');
            setValue('informationName', id ? state?.invoiceTemplate?.contactInfo?.informationName || undefined : undefined);
            setValue('informationCompany', id ? state?.invoiceTemplate?.contactInfo?.informationCompany || undefined : undefined);
            setValue('informationAddress', id ? state?.invoiceTemplate?.contactInfo?.informationAddress || undefined : undefined);
            setValue('informationCountry', id ? state?.invoiceTemplate?.contactInfo?.informationCountry || undefined : undefined);
            setValue('informationPhoneNumber', id ? state?.invoiceTemplate?.contactInfo?.informationPhoneNumber || undefined : undefined);
            setValue('informationEmail', id ? state?.invoiceTemplate?.contactInfo?.informationEmail || undefined : undefined);
            setValue('bankAccountName', id ? state?.invoiceTemplate?.mainData?.bankAccountName || undefined : undefined);
            setValue('bankAccountNumber', id ? state?.invoiceTemplate?.mainData?.bankAccountNumber || undefined : undefined);
            setValue('bankAccountSortCode', id ? state?.invoiceTemplate?.mainData?.bankAccountSortCode || undefined : undefined);
            setValue('iban', id ? state?.invoiceTemplate?.mainData?.iban || undefined : undefined);
            setValue('bic', id ? state?.invoiceTemplate?.mainData?.bic || undefined : undefined);
            setValue('bankName', id ? state?.invoiceTemplate?.mainData?.bankName || undefined : undefined);
            setValue('wireRoutingNumber', id ? state?.invoiceTemplate?.mainData?.wireRoutingNumber || undefined : undefined);
            setValue('achRoutingNumber', id ? state?.invoiceTemplate?.mainData?.achRoutingNumber || undefined : undefined);
            if (id) {
                const findCurrency = currencies && currencies?.length > 0 && currencies?.find((item) => item?.label === state?.invoiceTemplate?.currency)
                setSelectedCurrency(findCurrency || undefined)
            }
        }
    }, [state.invoiceTemplate]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleInvoiceTemplate(id));
        }
    }, [id]);

    const onCloseModal = () => {
        reset();
        setSelectedCurrency(undefined)
        setSaveError(undefined)
        handleCloseModal();
    };

    const onSubmit = async (data: FormValues) => {
        if (loadingSubmit) {
            return;
        }
        if (!selectedCurrency) {
            setSaveError('Please select currency to continue')
            return;
        }
        setLoadingSubmit(true)
        const payload: any = {
            templateName: data?.templateName,
            displayName: data?.displayName,
            title: data?.title,
            vatNumber: data?.vatNumber || undefined,
            currency: data?.currency,
            contactInfo: {
                informationName: data?.informationName || undefined,
                informationCompany: data?.informationCompany || undefined,
                informationAddress: data?.informationAddress || undefined,
                informationCountry: data?.informationCountry || undefined,
                informationPhoneNumber: data?.informationPhoneNumber || undefined,
                informationEmail: data?.informationEmail || undefined
            },
            mainData: {
                bankAccountName: data?.bankAccountName || undefined,
                bankName: data?.bankName || undefined,
                bankAccountSortCode: data?.bankAccountSortCode || undefined,
                bankAccountNumber: data?.bankAccountNumber || undefined,
                iban: data?.iban || undefined,
                bic: data?.bic || undefined,
                wireRoutingNumber: data?.wireRoutingNumber || undefined,
                achRoutingNumber: data?.achRoutingNumber || undefined
            }
        };
        try {
            if (id) {
                payload._id = id;
                await dispatch(tryToEditInvoiceTemplate(payload)).unwrap();
            } else {
                await dispatch(tryToAddInvoiceTemplate(payload)).unwrap();
            }
            onSaveChanges('success', `Invoice Template successfully ${id ? 'changed' : 'added'}.`);
            reset();
            onCloseModal();
        } catch (error) {
            onSaveChanges('error', `${error}`);
        }
        setLoadingSubmit(false)
    };

    const onChangeSelectedItems = (e: any, type?: string) => {
        setSelectedCurrency(e)
        setValue('currency', e?.label)

    };

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            contentContainerStyle={'!min-w-[700px]'}>
            <div className={'p-2 min-w-[650px]'}>
                <p className='mt-2 mb-4'>Invoice Template</p>
                {saveError && <Error text={saveError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(id && !templateLoading) || !id ? (
                        <div className='flex flex-col my-4'>
                            <Input
                                label={'Title'}
                                register={register('title', {
                                    required: {
                                        message: 'Title is required',
                                        value: true,
                                    },
                                    validate: (value: string) => !!value.trim() || 'Title is required'
                                })}
                                error={errors.title?.message}
                                dataQa={'title'}
                            />
                            <Input
                                label={'Display Name'}
                                register={register('displayName', {
                                    required: {
                                        message: 'Display Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) => !!value.trim() || 'Display Name is required'
                                })}
                                error={errors.displayName?.message}
                                dataQa={'display-name'}
                            />
                            <Input
                                label={'Template Name'}
                                disabled={!!id}
                                register={register('templateName', {
                                    required: {
                                        message: 'Template Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) => !!value.trim() || 'Template Name is required'
                                })}
                                error={errors.templateName?.message}
                                dataQa={'template-name'}
                            />
                            <Input
                                label={'VAT Number'}
                                register={register('vatNumber')}
                                dataQa={'vat-number'}
                                error={errors.templateName?.message}
                            />
                            <Controller
                                control={control}
                                defaultValue={selectedCurrency}
                                name='currency'
                                render={({ ref }: any) => (
                                    <SelectCheckbox
                                        ref={ref}
                                        selectedOption={selectedCurrency}
                                        dataQa={`${(selectedCurrency?.name || '').toLowerCase().replaceAll(' ', '-')}`}
                                        key={1}
                                        name='Currency'
                                        placeholder=' '
                                        containerStyle='mb-1'
                                        error={errors?.currency?.message}
                                        onChangeSelectedOption={(e) => onChangeSelectedItems(e)}
                                        options={currencies || []}
                                    />
                                )} />
                            <p className='mt-7 mb-2 font-semibold font-small'>Contact Info</p>
                            <div className='grid grid-cols-2 gap-4'>
                                <Input
                                    label={'Name'}
                                    dataQa={'name'}
                                    register={register('informationName')}
                                    error={errors.informationName?.message}
                                />
                                <Input
                                    label={'Company'}
                                    dataQa={'company'}
                                    register={register('informationCompany')}
                                    error={errors.informationCompany?.message}
                                />
                                <Input
                                    label={'Address'}
                                    dataQa={'address'}
                                    register={register('informationAddress')}
                                    error={errors.informationAddress?.message}
                                />
                                <Input
                                    label={'Country'}
                                    dataQa={'country'}
                                    register={register('informationCountry')}
                                    error={errors.informationCountry?.message}
                                />
                                <Input
                                    label={'Phone Number'}
                                    dataQa={'phone-number'}
                                    register={register('informationPhoneNumber')}
                                    error={errors.informationPhoneNumber?.message}
                                />
                                <Input
                                    label={'Email'}
                                    dataQa={'email'}
                                    register={register('informationEmail')}
                                    error={errors.informationEmail?.message}
                                />
                            </div>
                            <p className='mt-7 mb-2 font-semibold font-small'>Bank Info</p>
                            <div className='grid grid-cols-2 gap-4'>
                                <Input
                                    label={'Bank Account Name'}
                                    dataQa={'bank-account-name'}
                                    register={register('bankAccountName')}
                                    error={errors.bankAccountName?.message}
                                />
                                <Input
                                    label={'Bank Name'}
                                    dataQa={'bank-name'}
                                    register={register('bankName')}
                                    error={errors.bankName?.message}
                                />
                                <Input
                                    label={'Account Number'}
                                    dataQa={'account-number'}
                                    register={register('bankAccountNumber')}
                                    error={errors.bankAccountNumber?.message}
                                />
                                <Input
                                    label={'Sort Code'}
                                    dataQa={'sort-code'}
                                    register={register('bankAccountSortCode')}
                                    error={errors.bankAccountSortCode?.message}
                                />
                                <Input
                                    label={'IBAN'}
                                    dataQa={'iban'}
                                    register={register('iban')}
                                    error={errors.iban?.message}
                                />
                                <Input
                                    label={'BIC'}
                                    dataQa={'bic'}
                                    register={register('bic')}
                                    error={errors.bic?.message}
                                />
                                <Input
                                    label={'ACH Routing Number'}
                                    dataQa={'ach-routing-number'}
                                    register={register('achRoutingNumber')}
                                    error={errors.achRoutingNumber?.message}
                                />
                                <Input
                                    label={'Wire Routing Number'}
                                    dataQa={'wire-routing-number'}
                                    register={register('wireRoutingNumber')}
                                    error={errors.wireRoutingNumber?.message}
                                />
                            </div>
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    dataQa={'submit'}
                                    type={'submit'}
                                    className={loadingSubmit ? 'btn-main-disable min-w-[130px]' : 'btn-main min-w-[130px]'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default ManageTemplatesModal;
