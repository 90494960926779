import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';


interface IAllFeeConfigurations {
    markets?: Array<any>;
    sectors?: Array<IAssetTemplate>;
    selectedTemplateToShow?: Array<string>;
    data?: any;
    accessControl?: any;
    pageAccess?: any;
    onClickEditMarketServices: (marketId: string) => void;
    onChangeSelectedTemplateToShow: (name: string) => void;
}

const AllFeeConfigurations = ({
    markets,
    sectors,
    selectedTemplateToShow,
    data,
    accessControl,
    pageAccess,
    onClickEditMarketServices,
    onChangeSelectedTemplateToShow
}: IAllFeeConfigurations) => {

    const categoriesSelected = (sectors && sectors?.length > 0 && data && data?.length > 0) && sectors?.filter((sector: any) => {
        return data?.some((obj: any) => {
            return obj?.shippingConfigsData?.some((item: any) => item?.assetTemplateName === sector?.name);
        });
    });

    const usedMarkets: any = (markets && markets?.length > 0 && data && data?.length > 0) && markets.filter((market: any) =>
        data.some((item: any) => item?.marketId === market._id)
    );

    return (
        <div className='custom-scrollbar overflow-auto max-w-[100%] max-h-[80vh]'>
            <table className='min-w-full relative'>
                <thead className='z-50'>
                    <tr className='border-r'>
                        <th className='bg-white sticky top-0 left-0 bg-white z-40 min-w-[130px] w-[130px] h-[70px] max-h-[70px]'></th>
                        {(usedMarkets && usedMarkets?.length > 0) &&
                            usedMarkets?.map((item: any, index: number) => {
                                return (
                                    <th key={index} colSpan={3} className='bg-slate-50 freeze-first-row min-w-[120px] h-[70px] max-h-[70px] z-30 border-r border-slate-100 whitespace-nowrap py-2 px-2 mr-3'>
                                        <div className='flex flex-row items-center justify-between'>
                                            <div></div>
                                            <div className='flex flex-row items-center justify-center'>
                                                {item?.flagUrl && <img src={item?.flagUrl} className='object-contain w-[22px] mr-2' />}
                                                <p className='text-[13px] whitespace-nowrap font-medium'>{item?.name}</p>
                                            </div>
                                            <div
                                                data-qa={'edit-config'}
                                                onClick={() => (!accessControl || pageAccess?.actions?.['Manage Shipping Services']) && onClickEditMarketServices(item?._id)}
                                                className='flex flex-row justify-center ml-2'>
                                                <img title='Edit Market Configs' alt='edit config' src={'/assets/table-actions/edit.svg'} className={`h-[14px] object-contain ${(!accessControl || pageAccess?.actions?.['Manage Shipping Services']) ? 'cursor-pointer' :'cursor-not-allowed'} mr-3`} />
                                            </div>
                                        </div>
                                    </th>
                                )
                            })
                        }
                    </tr>
                    <tr>
                        <th className='sticky top-0 left-0 bg-white z-40 !top-[70px] bg-white min-w-[130px] w-[130px]'>
                        </th>
                        {(usedMarkets && usedMarkets?.length > 0) &&
                            usedMarkets?.map((item: any, index: number) => {
                                return (
                                    <>
                                        <th key={index} className='bg-slate-50 freeze-first-row !top-[70px] min-w-[120px] whitespace-nowrap py-2 px-2'>
                                            Carrier
                                        </th>
                                        <th key={index} className='bg-slate-50 freeze-first-row !top-[70px] min-w-[120px] whitespace-nowrap py-2 px-2'>
                                            Services
                                        </th>
                                        <th key={index} className='bg-slate-50 freeze-first-row !top-[70px] border-r border-slate-100 min-w-[120px] whitespace-nowrap py-2 px-2'>
                                            Fees
                                        </th>
                                    </>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody className='bg-white relative z-0'>
                    {(categoriesSelected && categoriesSelected?.length > 0) &&
                        categoriesSelected?.map((item: any, index: number) => {
                            return (
                                <>
                                    <tr key={index} className=' bg-white'>
                                        <td className='freeze-first-column py-4 px-2 min-w-[150px]'>
                                            <div
                                                className='grid grid-cols-4 gap-1 items-center cursor-pointer hover:bg-slate-[50]'
                                                onClick={() => onChangeSelectedTemplateToShow(item?.name)}>
                                                <div className='w-[21px] h-[21px] mr-3'>
                                                    {selectedTemplateToShow && selectedTemplateToShow?.length > 0 && selectedTemplateToShow?.includes(item?.name || '') ?
                                                        <>
                                                            {item?.enabledIcon && <img src={item?.enabledIcon} className={'max-w-[100%] max-h-[100%] min-w-[100%] min-h-[100%] object-contain'} />}
                                                        </>
                                                        :
                                                        <>
                                                            {item?.disabledIcon && <img src={item?.disabledIcon} className={'max-w-[100%] max-h-[100%] min-w-[100%] min-h-[100%] object-contain'} />}
                                                        </>
                                                    }
                                                </div>
                                                <p className='col-span-2 text-[13px] break-words mb-2 font-medium'>{item?.label}</p>
                                                <div className='flex flex-row justify-center ml-2 mb-1'>
                                                    <img
                                                        src={'/assets/shared/arrow-down-gray.svg'}
                                                        className={`w-[20px] object-contain ${selectedTemplateToShow && selectedTemplateToShow?.length > 0 && selectedTemplateToShow?.includes(item?.name || '') ? 'rotate-180' : ''}`} />
                                                </div>
                                            </div>
                                        </td>
                                        {(usedMarkets && usedMarkets?.length > 0) && usedMarkets?.map((market: any, id: number) => {
                                            const findMarketData = (data && data?.length > 0) && data?.find((marketItem: any) => marketItem?.marketId === market?._id)
                                            const findShippingForCategory: any = (findMarketData && findMarketData?.shippingConfigsData && findMarketData?.shippingConfigsData?.length > 0) && findMarketData?.shippingConfigsData?.find((config: any) => (config?.assetTemplateName || '').toLowerCase() === (item?.name || '').toLowerCase())
                                            return (
                                                <>
                                                    <td key={id} className='py-4 px-2 border-b border-slate-100 text-primary-light text-center'>
                                                        {findShippingForCategory?.carriersInfo?.[0]?.carrier || ''}
                                                    </td>
                                                    <td key={id} className='py-4 px-2 border-b border-slate-100 text-primary-light text-center'>
                                                        {findShippingForCategory?.carriersInfo?.[0]?.services?.[0]?.name || ''}
                                                    </td>
                                                    <td key={id} className='py-4 px-2 border-b border-slate-100 text-primary-light text-center'>
                                                        {findShippingForCategory?.carriersInfo?.[0]?.services?.[0]?.fee || findShippingForCategory?.carriersInfo?.[0]?.services?.[0]?.fee === 0 ? findShippingForCategory?.carriersInfo?.[0]?.services?.[0]?.fee : ''}
                                                    </td>
                                                </>
                                            )
                                        })}
                                    </tr>
                                    {selectedTemplateToShow && selectedTemplateToShow?.length > 0 && selectedTemplateToShow?.includes(item?.name || '') &&
                                        <tr className='bg-slate-50 shadow'>
                                            <td className='freeze-first-column !bg-slate-50 py-4 px-2'></td>
                                            {(usedMarkets && usedMarkets?.length > 0) && usedMarkets?.map((market: any, id: number) => {
                                                const findMarketData = (data && data?.length > 0) && data?.find((marketItem: any) => marketItem?.marketId === market?._id)
                                                const findShippingForCategory: any = (findMarketData && findMarketData?.shippingConfigsData && findMarketData?.shippingConfigsData?.length > 0) && findMarketData?.shippingConfigsData?.find((config: any) => (config?.assetTemplateName || '').toLowerCase() === (item?.name || '').toLowerCase())
                                                return (
                                                    <td key={id} colSpan={3} className='px-4 py-3 border-r border-slate-100'>
                                                        {(findShippingForCategory?.carriersInfo && findShippingForCategory?.carriersInfo?.length > 0) &&
                                                            <div className='min-h-[200px] border border-slate-100 rounded p-2 shadow bg-white'>
                                                                <div className='grid grid-cols-3 gap-3 text-primary-light font-medium border-b border-slate-100'>
                                                                    <p>Carrier</p>
                                                                    <p>Services</p>
                                                                    <p>Fee</p>
                                                                </div>
                                                                {findShippingForCategory?.carriersInfo?.map((shipping: any, idx: number) => {
                                                                    return (
                                                                        <div key={idx} className='grid grid-cols-3 gap-3 items-stretch text-primary-light mt-3'>
                                                                            <p key={idx} className='p-2 border-b border-slate-100 text-primary-light break-all'>
                                                                                {shipping.carrier || ''}
                                                                            </p>
                                                                            <div className='py-2 border-b border-slate-100 min-w-[80px]'>
                                                                                {(shipping?.services && shipping?.services?.length > 0) &&
                                                                                    shipping?.services?.map((service: any, key: number) => {
                                                                                        return (
                                                                                            <p key={key} className='mb-1 break-all'>{service?.name}</p>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <div className='py-2 border-b border-slate-100'>
                                                                                {(shipping?.services && shipping?.services?.length > 0) &&
                                                                                    shipping?.services?.map((service: any, key: number) => {
                                                                                        return (
                                                                                            <p key={key} className='mb-1'>{service?.fee}</p>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        }
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    }
                                </>
                            )
                        })
                    }
                </tbody>
            </table>
        </div >
    )
}

export default AllFeeConfigurations;