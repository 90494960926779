import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { ICSAgents } from '../../../interfaces/cs-agents/cs-agents';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToDeleteCsUser,
    tryToEnableCsUsers,
    tryToFindAllCsUsers,
} from '../../../store/brokers/admin/cs-agents/CSAgentsSlice';
import AdminRoleModal from '../users/modals/AdminRoleModal';
import CSAgentsTable from './tables/CSAgentsTable';
import EmptyContainer from '../../../shared/empty-container';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';
import Input from '../../../shared/input';


const CSAgents = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<ICSAgents[]>([]);
    const [showToast, setShowToast] = useState<IToast | null>();
    const [search, setSearch] = useState<string>();
    const [showRoleModal, setShowRoleModal] = useState<{ show: boolean, id: string, role?: any }>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [showDeleteCsModal, setShowDeleteCsModal] = useState<boolean>(false)
    const [csAgentId, setCsAgentId] = useState<string | null>()
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.csAgents.csAgents?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const rowsLoading = useAppSelector((state) => state.csAgents.csAgentsAreLoading);
    const state = useAppSelector((state) => state.csAgents);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        });
    };

    useEffect(() => {
        if (state.csAgents) {
            const rows =
                state.csAgents?.data?.elements &&
                    Array.isArray(state.csAgents?.data.elements)
                    ? state.csAgents?.data.elements.map((r: any) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.csAgents]);

    useEffect(() => {
        let data: any = {
            ...(paginationState || {}),
        };
        if (search) {
            data = {
                ...(data || {}),
                filters: {
                    search: search,
                },
            };
        }
        dispatch(tryToFindAllCsUsers(data));
    }, [paginationState]);

    const onAddNewCSAgent = () => {
        navigate('/users/cs-agent/create');
    };

    const onSeeCSAgentDetails = async (id: string) => {
        navigate(`/users/cs-agent/${id}`);
    };

    const onEnableDisableCSAgent = async (id: string, currentStatus: boolean) => {
        try {
            await dispatch(tryToEnableCsUsers({ id, data: { enable: !currentStatus } })).unwrap();
            if (currentStatus) {
                setShowToast({
                    type: 'success',
                    message: 'CS Agent disabled successfully',
                });
            } else {
                setShowToast({
                    type: 'success',
                    message: 'CS Agent enabled successfully',
                });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
    };

    const onDeleteCSAgent = async () => {
        try {
            if (csAgentId) {
                await dispatch(tryToDeleteCsUser(csAgentId));
                setShowToast({
                    type: 'success',
                    message: `CS Agent successfully deleted`,
                });
            }

        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setCsAgentId(null)
        setShowDeleteCsModal(false)
    };

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined);
        onResetPaginationState();
    };

    const onCloseDeleteCsModal = () => {
        setCsAgentId(null)
        setShowDeleteCsModal(false)
    }

    const onShowCSAgentModal = (id: string) => {
        setShowDeleteCsModal(true)
        setCsAgentId(id)
    }

    const onOpenCSRole = (userId: string, role?: any) => {
        setShowRoleModal({
            show: true,
            id: userId,
            role: role,
        })
    }

    const onSuccessfullySavedRole = (body: any) => {
        const updateRows = (rows && rows?.length > 0) && rows?.map((item: any) => {
            if (item?._id === body?.userId) {
                return {
                    ...item,
                    role: body?.role || undefined
                }
            } else {
                return item
            }
        })
        setRows(updateRows || [])
    }

    return (
        <div>
            <div className='flex h-10'>
                <div className='w-1/2 flex justify-start'>
                </div>
                <div className='w-1/2 flex justify-end'>
                    <Button
                        label={'Add New CS Agent'}
                        onClickButton={() => (!accessControl || pageAccess?.actions?.['CS Add New Agent']) && onAddNewCSAgent()}
                        className={`${(!accessControl || pageAccess?.actions?.['CS Add New Agent']) ? 'btn-main' : 'btn-main-disable'} !col-span-2`}
                        dataQa={'add-new-cs-btn'}
                    />
                </div>
            </div>
            <div className='my-5 relative'>
                <div className={'min-w-[100px]'}>
                    <Input
                        placeholder={'Filter CS Agents'}
                        dataQa={'filter-table-cs'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={'mb-0 border-0 focus:outline-none'}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                {(rows?.length > 0 || rowsLoading) ?
                    <CSAgentsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        onSeeCSAgentDetails={onSeeCSAgentDetails}
                        onEnableDisableCSAgent={onEnableDisableCSAgent}
                        setPaginationState={setPaginationState}
                        onClickDeleteCsButton={onShowCSAgentModal}
                        onDeleteCSAgent={onDeleteCSAgent}
                        onOpenCSRole={onOpenCSRole}
                    />
                    : <EmptyContainer
                        text={'No agent added yet.'}
                        showImage={true}
                    />
                }
            </div>
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
            {showRoleModal?.show &&
                <AdminRoleModal
                    openModal={showRoleModal?.show}
                    accessType={'cs'}
                    id={showRoleModal?.id || ''}
                    role={showRoleModal?.role}
                    onSuccessfullySaved={onSuccessfullySavedRole}
                    handleCloseModal={() => setShowRoleModal(undefined)}
                />
            }
            {showDeleteCsModal &&
                <DeleteModal
                    openDeleteModal={showDeleteCsModal}
                    handleCloseDeleteModal={onCloseDeleteCsModal}
                    onApproveDeletion={onDeleteCSAgent}
                />
            }

        </div>
    );
};

export default CSAgents;
