import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IQuestion, IQuestionService } from '../../../../interfaces/questions/IQuestions';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IQuestionListState } from '../../../../interfaces/questions/IQuestionListState';
import { PaginatedQuestions } from '../../../../interfaces/questions/paginatedStore.type';
import { QuestionsService } from './questionsApi';
import { ILanguage } from '../../../../interfaces/languages/ILanguage';


const initialState: IQuestionListState = {
    questionIsLoading: false,
    questionsAreLoading: false,
    questionsLoading: false,
    serviceQuestionLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchQuestions = createAsyncThunk<
    PaginatedQuestions,
    IPaginationPayload
>('questions/tryToFetchQuestions', async ({ pageNumber, pageSize, data }) => {
    const result = await QuestionsService.tryToFetchQuestions(
        pageNumber,
        pageSize,
        data,
    );
    return result?.data;
});


export const tryToFetchSingleQuestion = createAsyncThunk<IQuestion, string>(
    'questions/tryToFetchSingleQuestion',
    async (id: string) => {
        const result = await QuestionsService.tryToFetchSingleQuestion(id);
        return result?.data;
    },
);

export const tryToGetAllQuestions = createAsyncThunk<IQuestion, { marketId?: string, categoryId?: string, service?: string } | undefined>(
    'questions/tryToGetAllQuestions',
    async (data) => {
        const result = await QuestionsService.tryToGetAllQuestions(data);
        return result?.data?.data;
    },
);

export const tryToEditQuestion = createAsyncThunk<ApiResponse<IQuestion>, IQuestion>(
    'questions/tryToEditQuestion', async (data: IQuestion, { rejectWithValue }) => {
        try {
            const result = await QuestionsService.tryToEditQuestion(
                data._id || '',
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToAddQuestion = createAsyncThunk<ApiResponse<IQuestion>, IQuestion>(
    'questions/tryToAddQuestion', async (data: IQuestion, { rejectWithValue }) => {
        try {
            const result = await QuestionsService.tryToAddQuestion(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToDeleteQuestion = createAsyncThunk<ApiResponse<IQuestion>, string>(
    'questions/tryToDeleteQuestion', async (id, { rejectWithValue }) => {
        try {
            const results = await QuestionsService.tryToDeleteQuestion(id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToEnableDisableQuestion = createAsyncThunk<ApiResponse<IQuestion>, { id: string, status: boolean }>(
    'questions/tryToEnableDisableQuestion',
    async ({ id, status }, { rejectWithValue }) => {
        try {
            const results = await QuestionsService.tryToEnableDisableQuestion(id, status);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToEnableDisableReviewQuestion = createAsyncThunk<ApiResponse<IQuestion>, { id: string, status: boolean }>(
    'questions/tryToEnableDisableReviewQuestion',
    async ({ id, status }, { rejectWithValue }) => {
        try {
            const results = await QuestionsService.tryToEnableDisableReviewQuestion(id, status);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToAddQuestionService = createAsyncThunk<ApiResponse<IQuestion>, { id: string, data: IQuestionService }>(
    'questions/tryToAddQuestionService', async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await QuestionsService.tryToAddQuestionService(id, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToEditQuestionService = createAsyncThunk<ApiResponse<IQuestion>, { id: string, serviceId: string, data: IQuestionService }>(
    'questions/tryToEditQuestionService', async ({ id, serviceId, data }, { rejectWithValue }) => {
        try {
            const result = await QuestionsService.tryToEditQuestionService(
                id,
                serviceId,
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToDeleteQuestionService = createAsyncThunk<ApiResponse<IQuestionService>, { id: string, serviceId: string }>(
    'questions/tryToDeleteQuestionService', async ({ id, serviceId }, { rejectWithValue }) => {
        try {
            const results = await QuestionsService.tryToDeleteQuestionService(id, serviceId);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToGetAllLanguages = createAsyncThunk<Array<ILanguage>, null | undefined>(
    'questions/tryToGetAllLanguages',
    async () => {
        const result = await QuestionsService.tryToGetAllLanguages();
        return result?.data;
    },
);

export const questionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchQuestions
            .addCase(tryToFetchQuestions.pending, (state) => {
                state.questionsAreLoading = true;
            })
            .addCase(tryToFetchQuestions.rejected, (state) => {
                state.questionsAreLoading = false;
            })
            .addCase(tryToFetchQuestions.fulfilled, (state, action) => {
                state.questionsAreLoading = false;
                state.questions = action.payload;
            })

            // tryToAddQuestion
            .addCase(tryToAddQuestion.pending, (state) => {
                state.questionIsLoading = true;
            })
            .addCase(tryToAddQuestion.rejected, (state) => {
                state.questionIsLoading = false;
            })
            .addCase(tryToAddQuestion.fulfilled, (state, action) => {
                state.questionIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.questions?.data)
                    state.questions.data.elements = [
                        data,
                        ...state.questions?.data.elements,
                    ];
            })

            // tryToDeleteQuestion
            .addCase(tryToDeleteQuestion.pending, (state) => {
                state.questionIsLoading = true;
            })
            .addCase(tryToDeleteQuestion.rejected, (state) => {
                state.questionIsLoading = false;
            })
            .addCase(tryToDeleteQuestion.fulfilled, (state, action) => {
                state.questionIsLoading = false;
                state.requestStatus = 'success';
                if (state.questions?.data.elements)
                    state.questions.data.elements =
                        { ...state }.questions?.data.elements.filter(
                            (question) =>
                                question?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToEditQuestion
            .addCase(tryToEditQuestion.pending, (state) => {
                state.questionIsLoading = true;
            })
            .addCase(tryToEditQuestion.rejected, (state) => {
                state.questionIsLoading = false;
            })
            .addCase(tryToEditQuestion.fulfilled, (state, action) => {
                state.questionIsLoading = false;
                if (state.questions?.data.elements)
                    state.questions.data.elements =
                        { ...state }.questions?.data.elements.map((question) => {
                            if (question?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return question;
                            }
                        }) || [];
                state.questionDetails = action.payload.data;
            })

            // tryToEnableDisableQuestion
            .addCase(tryToEnableDisableQuestion.pending, (state) => {
                state.questionIsLoading = true;
            })
            .addCase(tryToEnableDisableQuestion.rejected, (state) => {
                state.questionIsLoading = false;
            })
            .addCase(tryToEnableDisableQuestion.fulfilled, (state, action) => {
                state.questionIsLoading = false;
                if (state.questions?.data.elements)
                    state.questions.data.elements =
                        { ...state }.questions?.data.elements.map((question) => {
                            if (question?._id === action.meta?.arg?.id) {
                                return {
                                    ...question || {},
                                    enabled: action?.meta?.arg?.status
                                };
                            } else {
                                return question;
                            }
                        }) || [];
                state.questionDetails = action.payload.data;
            })

            // tryToEnableDisableReviewQuestion
            .addCase(tryToEnableDisableReviewQuestion.pending, (state) => {
                state.questionIsLoading = true;
            })
            .addCase(tryToEnableDisableReviewQuestion.rejected, (state) => {
                state.questionIsLoading = false;
            })
            .addCase(tryToEnableDisableReviewQuestion.fulfilled, (state, action) => {
                state.questionIsLoading = false;
                if (state.questions?.data.elements)
                    state.questions.data.elements =
                        { ...state }.questions?.data.elements.map((question) => {
                            if (question?._id === action.meta?.arg?.id) {
                                return {
                                    ...question || {},
                                    showInReview: action?.meta?.arg?.status
                                };
                            } else {
                                return question;
                            }
                        }) || [];
                state.questionDetails = action.payload.data;
            })

            // tryToFetchSingleQuestion
            .addCase(tryToFetchSingleQuestion.pending, (state) => {
                state.questionIsLoading = true;
            })
            .addCase(tryToFetchSingleQuestion.rejected, (state) => {
                state.questionIsLoading = false;
            })
            .addCase(tryToFetchSingleQuestion.fulfilled, (state, action) => {
                state.questionIsLoading = false;
                state.questionDetails = action.payload;
            })

            // tryToGetAllQuestions
            .addCase(tryToGetAllQuestions.pending, (state) => {
                state.serviceQuestionLoading = true;
            })
            .addCase(tryToGetAllQuestions.rejected, (state) => {
                state.serviceQuestionLoading = false;
            })
            .addCase(tryToGetAllQuestions.fulfilled, (state, action) => {
                state.serviceQuestionLoading = false;
            })

            // tryToAddQuestionService
            .addCase(tryToAddQuestionService.pending, (state) => {
                state.questionsAreLoading = true;
            })
            .addCase(tryToAddQuestionService.rejected, (state) => {
                state.questionsAreLoading = false;
            })
            .addCase(tryToAddQuestionService.fulfilled, (state, action) => {
                state.questionsAreLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.questionDetails?.services)
                    state.questionDetails = data
            })

            // tryToEditQuestionService
            .addCase(tryToEditQuestionService.pending, (state) => {
                state.questionsLoading = true;
            })
            .addCase(tryToEditQuestionService.rejected, (state) => {
                state.questionsLoading = false;
            })
            .addCase(tryToEditQuestionService.fulfilled, (state, action) => {
                state.questionsLoading = false;
                if (state.questionDetails?.services)
                    state.questionDetails = action?.payload?.data
            })

            // tryToDeleteQuestionService
            .addCase(tryToDeleteQuestionService.pending, (state) => {
                state.questionsAreLoading = true;
            })
            .addCase(tryToDeleteQuestionService.rejected, (state) => {
                state.questionsAreLoading = false;
            })
            .addCase(tryToDeleteQuestionService.fulfilled, (state, action) => {
                state.questionsAreLoading = false;
                state.requestStatus = 'success';
                if (state.questionDetails?.services) {
                    state.questionDetails.services =
                        { ...state }.questionDetails?.services?.filter(
                            (service) =>
                                service?.id !== action.meta.arg?.serviceId,
                        ) || [];
                }
            })
    },
});

export default questionsSlice.reducer;
