import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    tryToChangeTemplateFieldDetails,
    tryToFetchSingleAssetTemplate
} from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { IAssetFields, IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import DropzoneComponent from './details/DropzoneComponent';
import SelectCheckbox from '../../../shared/select-checkbox';
import Textarea from '../../../shared/textarea';
import Button from '../../../shared/button';
import Input from '../../../shared/input';
import Error from '../../../shared/error';


const TemplateFieldDetails = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { id, fieldId } = useParams();
    const [assetTemplate, setAssetTemplate] = useState<IAssetTemplate>()
    const [formData, setFormData] = useState<any>();
    const [submitError, setSubmitError] = useState<string>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [fieldDetails, setFieldDetails] = useState<IAssetFields>()
    const state = useAppSelector((state) => state.assetsTemplates);
    const assetTemplateLoading = useAppSelector((state) => state.assetsTemplates?.assetTemplateIsLoading);

    useEffect(() => {
        if (state.assetTemplateDetails) {
            const findRows = (state.assetTemplateDetails && state.assetTemplateDetails.fields) && state.assetTemplateDetails.fields?.find((item: IAssetFields) => item?.id === fieldId)
            setFieldDetails(findRows)
            setFormData(findRows?.details || undefined)
        }
    }, [state.assetTemplateDetails]);

    useEffect(() => {
        if (state.assetTemplateDetails) {
            setAssetTemplate(state.assetTemplateDetails)
        }
    }, [state.assetTemplateDetails]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleAssetTemplate(id));
        }
    }, []);

    const onGoToAssetTemplate = async () => {
        navigate(`/asset-catalogs/${id}`);
    }

    const onChangeSelectFormField = (value: any, type?: string, index?: number) => {
        if (type && (index || index === 0)) {
            const formatFormData = formData && formData?.length > 0 ?
                formData?.map((item: any, i: number) => {
                    if (i === index) {
                        return {
                            ...item || {},
                            [type]: value?.value
                        }
                    } else {
                        return item
                    }
                })
                :
                [
                    {
                        [type]: value?.value,
                    }
                ]
            setFormData(formatFormData)
        }
    }

    const onChangeDescription = (e: any, type?: string, index?: any) => {
        if (type && (index || index === 0)) {
            const formatFormData = formData && formData?.length > 0 ?
                formData?.map((item: any, i: number) => {
                    if (i === index) {
                        return {
                            ...item || {},
                            description: e?.target?.value
                        }
                    } else {
                        return item
                    }
                })
                :
                [
                    {
                        description: e?.target?.value
                    }
                ]
            setFormData(formatFormData)
        }
    }

    const onChangeFieldValues = (value: any, type?: string, index?: number) => {
        if (type && (index || index === 0)) {
            const formatFormData = formData && formData?.length > 0 ?
                formData?.map((item: any, i: number) => {
                    if (i === index) {
                        return {
                            ...item || {},
                            [type]: value
                        }
                    } else {
                        return item
                    }
                })
                :
                [
                    {
                        [type]: value,
                    }
                ]
            setFormData(formatFormData)
        }
    }

    const onAddNewRow = () => {
        setFormData((prevFormData: any) => ([
            ...prevFormData || [],
            {}
        ]))
    }

    const onSubmit = async () => {
        if (formData && formData?.length > 0) {
            setLoadingSubmit(true)
            try {
                const dataForm = new FormData();
                formData && formData?.length > 0 && formData?.forEach((item: any, index: number) => {
                    let formatItem = Object.assign(item)
                    formatItem = {
                        ...formatItem || {},
                        active: {
                            key: item?.active ? `active_${index}` : undefined
                        },
                        notActive: {
                            key: item?.notActive ? `notActive_${index}` : undefined
                        },
                    }
                    dataForm.append(`object_${index}`, JSON.stringify(formatItem))
                    if (item?.active) {
                        dataForm.append(`active_${index}`, item?.active)
                    }
                    if (item?.notActive) {
                        dataForm.append(`notActive_${index}`, item?.notActive)
                    }
                })
                await dispatch(tryToChangeTemplateFieldDetails({ assetTemplateId: id || '', fieldId: fieldId || '', data: dataForm })).unwrap()
                setSubmitError(undefined)
                onGoToAssetTemplate()
            } catch (err) {
                setSubmitError(`${err}`)
            }
            setLoadingSubmit(false)
        } else {
            setSubmitError('Please fill the data to continue!')
        }
    }

    const onChangeFileUploaded = (file: any, type: string, index: number) => {
        const formatFormData = (formData && formData?.length > 0) ?
            formData?.map((item: any, i: any) => {
                if (i === index) {
                    return {
                        ...item || {},
                        [type]: file
                    }
                } else {
                    return item
                }
            })
            :
            [
                {
                    [type]: file
                }
            ]
        setFormData(formatFormData)
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoToAssetTemplate} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Field Details</p>
                </div>
            </div>
            <div>
                {!assetTemplateLoading &&
                    <>
                        <p className='mt-3 font-medium'>Catalog Name: {assetTemplate?.displayName}</p>
                        <p className='mt-3 font-medium'>Field Name: {fieldDetails?.name}</p>
                    </>
                }
                {(formData || [{}])?.map((x: null, i: number) => {
                    return (
                        <div key={i} className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-4 my-4'>
                            <SelectCheckbox
                                name='Field Value'
                                placeholder=' '
                                options={fieldDetails?.values ? fieldDetails?.values?.map((value: string) => ({ label: value, value: value })) : []}
                                selectedOption={formData?.[i]?.fieldValue ? { label: formData?.[i]?.fieldValue, value: formData?.[i]?.fieldValue } : undefined}
                                onChangeSelectedOption={onChangeSelectFormField}
                                uniqueName='fieldValue'
                                index={i}
                            />
                            <Input
                                label='Name'
                                showValue={true}
                                placeholder=' '
                                containerStyle={'mb-0'}
                                inputValue={formData?.[i]?.name || null}
                                inputUniqueName={'name'}
                                onChangeInput={onChangeFieldValues}
                                index={i}
                            />
                            <Input
                                label='Type'
                                showValue={true}
                                placeholder=' '
                                containerStyle={'mb-0'}
                                inputValue={formData?.[i]?.type || null}
                                inputUniqueName={'type'}
                                onChangeInput={onChangeFieldValues}
                                index={i}
                            />
                            <Input
                                label='Id'
                                showValue={true}
                                placeholder=' '
                                containerStyle={'mb-0'}
                                inputValue={formData?.[i]?.id || null}
                                inputUniqueName={'id'}
                                onChangeInput={onChangeFieldValues}
                                index={i}
                            />
                            <div className='col-span-1 md:col-span-2 lg:col-span-4'>
                                <div>
                                    <DropzoneComponent
                                        title={'Select Active Icon'}
                                        index={i}
                                        type={'active'}
                                        formData={formData}
                                        onChangeFileUploaded={onChangeFileUploaded}
                                        fieldDetails={fieldDetails}
                                    />
                                    <DropzoneComponent
                                        title={'Select Not Active Icon'}
                                        index={i}
                                        type={'notActive'}
                                        formData={formData}
                                        onChangeFileUploaded={onChangeFileUploaded}
                                        fieldDetails={fieldDetails}
                                    />
                                </div>
                            </div>
                            <div className='col-span-1 md:col-span-2 lg:col-span-4'>
                                <p className='block text-primary-light font-normal text-base mb-1'>Description</p>
                                <Textarea
                                    name={''}
                                    placeholder=' '
                                    containerStyle='!mb-0'
                                    rows={3}
                                    value={formData?.[i]?.description}
                                    index={i}
                                    hideMaxLength={true}
                                    textareaType='description'
                                    onChangeTextarea={onChangeDescription}
                                />
                                <hr className='min-w-full mt-3 mb-6' />
                            </div>
                        </div>
                    )
                })}
                {formData && ((formData?.length) < (fieldDetails?.values?.length || 0)) &&
                    <div className='flex flex-row justify-end my-4'>
                        <Button
                            className='btn-main'
                            label='Add New Row'
                            onClickButton={onAddNewRow}
                        />
                    </div>
                }
                <div className='flex flex-col items-center my-4'>
                    {submitError && <Error text={submitError} />}
                    <Button
                        className={`${!loadingSubmit ? 'btn-primary' : 'btn-primary-disabled'} min-w-[250px]`}
                        label='Submit'
                        onClickButton={() => !loadingSubmit && onSubmit()}
                    />
                </div>
            </div>
        </div >
    )
}

export default TemplateFieldDetails;