import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import {
    tryToChangeAssetsHiddenStatus,
    tryToChangeAssetsStatus
} from '../../../../store/brokers/admin/assets/assetsSlice';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import ChangeMultipleStatusErrorStep from '../multiple-status-change/ChangeMultipleStatusErrorStep';
import AssetExtendedModal from '../../assets-extended/modals/AssetExtendedModal';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IChangeBatchItemsStatusModal {
    openModal: boolean;
    assetTemplateId: string;
    assetIds: Array<string>;
    services?: any;
    handleCloseModal: () => void;
    onSaveSuccessfully: (message: string) => void;
}

const ChangeBatchItemsStatusModal = ({
    openModal,
    assetTemplateId,
    assetIds,
    services,
    handleCloseModal,
    onSaveSuccessfully
}: IChangeBatchItemsStatusModal) => {
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>();
    const [errorResponse, setErrorResponse] = useState<any>();
    const [assetToDelete, setAssetToDelete] = useState<string>();
    const [showAssetExtended, setShowAssetExtended] = useState<{ show: boolean, asset: string }>();
    const [servicesSelected, setServicesSelected] = useState<Array<ISelectedValue & { id: string }>>();

    const onCloseModal = () => {
        handleCloseModal();
        setErrorResponse(undefined)
        setAssetToDelete(undefined)
        setShowAssetExtended(undefined)
        setServicesSelected(undefined)
        setSaveError(undefined)
    }

    const onSubmit = async (status: any) => {
        try {
            const formData = {
                assetTemplateId: assetTemplateId,
                data: {
                    assets: assetIds,
                    status: status
                }
            }
            await dispatch(tryToChangeAssetsStatus(formData)).unwrap()
            onSaveSuccessfully('Assets statuses changed successfully')
            onCloseModal()
        } catch (err: any) {
            setSaveError(`${err?.response?.data?.message}`)
            setErrorResponse(err?.response?.data?.models)
        }
    }

    const onSaveChanges = async () => {
        try {
            const formatServices = (servicesSelected && servicesSelected?.length > 0) ? servicesSelected?.map((item: any) => ({ serviceId: item?.value, serviceName: item?.name, displayName: item?.displayName })) : []
            const formData = {
                assetTemplateId: assetTemplateId,
                assetIds: assetIds,
                hiddenServices: formatServices || []
            }
            await dispatch(tryToChangeAssetsHiddenStatus(formData)).unwrap()
            onSaveSuccessfully('Assets statuses changed successfully')
            onCloseModal()
        } catch (err) {
            setSaveError(`${err}`)
        }
    }

    const onClickAddAssetsExtendedModal = (name: string) => {
        setShowAssetExtended({
            show: true,
            asset: name
        })
        setAssetToDelete(name)
    }

    const onChangeSelectedServices = (e: any, type?: string, index?: number) => {
        setServicesSelected(e)
    }

    const onSaveSuccessfullyExteneded = (type: string, message: string) => {
        const filterErrorResponse = (errorResponse && errorResponse?.length > 0) && errorResponse?.filter((item: any) => item !== assetToDelete)
        setErrorResponse(filterErrorResponse)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            showInRight
            contentContainerStyle={errorResponse ? 'min-w-[700px]' : 'min-w-[650px]'}>
            <div className={`p-4 ${errorResponse ? 'min-w-[650px] min-h-[500px]' : 'min-w-[600px]'} `}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Assets Statuses</p>
                </div>
                {saveError && <Error text={saveError} />}
                {errorResponse &&
                    <ChangeMultipleStatusErrorStep
                        errorResponse={errorResponse}
                        onClickAddAssetsExtendedModal={onClickAddAssetsExtendedModal}
                    />
                }
                <div className='bg-[#f8f9fc] rounded p-2 my-5'>
                    <SelectCheckbox
                        name='Hidden in Services'
                        multiple={true}
                        selectedOption={servicesSelected}
                        options={services}
                        onChangeSelectedOption={onChangeSelectedServices}
                    />
                </div>
                <div className='flex flex-row justify-center my-4'>
                    <Button
                        className='btn-main !rounded mr-3 !shadow-none'
                        label={'Enable Asset'}
                        onClickButton={() => onSubmit('enabled')}
                    />
                    <Button
                        className='btn-error !rounded mr-3 !shadow-none'
                        label={'Disable Asset'}
                        onClickButton={() => onSubmit('disabled')}
                    />
                    <Button
                        label={'Save Changes'}
                        className='btn-primary-rounded-less !rounded !shadow-none'
                        onClickButton={onSaveChanges}
                    />
                </div>
                {showAssetExtended?.show &&
                    <AssetExtendedModal
                        openCreateAssetModal={showAssetExtended?.show}
                        name={showAssetExtended?.asset}
                        handleCloseAssetModal={() => setShowAssetExtended(undefined)}
                        onSaveChanges={onSaveSuccessfullyExteneded}
                    />
                }
            </div>
        </Modal>
    )
}

export default ChangeBatchItemsStatusModal;