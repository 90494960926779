import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IPriceEffectFilters {
    sectors?: Array<IAssetTemplate>;
    payServices?: Array<any>;
    selectedSector?: any;
    selectedPayService?: any;
    onSelectFilter: (id: string, type?: string) => void;
}

const PriceEffectFilters = ({
    sectors,
    payServices,
    selectedSector,
    selectedPayService,
    onSelectFilter
}: IPriceEffectFilters) => {
    return (
        <div>
            <div className='mb-6 grid grid-cols-4 gap-4'>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Category'}
                        dataQa={selectedSector?.label === undefined ? 'category' : selectedSector?.label.toLowerCase()}
                        placeholder={' '}
                        selectedOption={selectedSector}
                        options={sectors || []}
                        uniqueName={'sector'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Service'}
                        dataQa={selectedPayService?.label === undefined ? 'service' : selectedPayService?.label.toLowerCase()}
                        placeholder={' '}
                        options={payServices || []}
                        selectedOption={selectedPayService}
                        uniqueName={'payService'}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
            </div>
        </div>
    )
}

export default PriceEffectFilters;