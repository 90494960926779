import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import { IPayServices } from '../../../../interfaces/pay-services/IPayServices';
import { PaginatedPayServices } from '../../../../interfaces/pay-services/paginatedStore.type';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';

const tryToFetchPayServices = (
    pageNumber: number,
    pageSize: number
): Promise<AxiosResponse<PaginatedPayServices>> => {
    const url = `${USERS_API_URL}/pay-service?page=${pageNumber}&size=${pageSize}`
    return brokersAxios.get<PaginatedPayServices>(url);
};

const tryToFetchSinglePayService = (payServiceId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/pay-service/${payServiceId}`);
};

const tryToFetchAllPayServices = (): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/pay-service/all`);
};

const tryToAddPayService = (data: IPayServices): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/pay-service`, data);
};

const tryToDeletePayService = (payServiceId: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/pay-service/${payServiceId}`);
};

const tryToEditPayService = (payServiceId: string, payload: IPayServices): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/pay-service/${payServiceId}`, payload);
};

export const PayServicesService = {
    tryToFetchPayServices,
    tryToFetchSinglePayService,
    tryToFetchAllPayServices,
    tryToAddPayService,
    tryToDeletePayService,
    tryToEditPayService
};
