import { AxiosResponse } from 'axios';
import { OFFER_API_URL } from '../../../../config';
import offersAxios from '../../../../utils/axios/offers.axios';
import { PaginatedPricingReviews } from '../../../../interfaces/pricing-review/paginatedStore.type';
import { IPricingReview } from '../../../../interfaces/pricing-review/IPricingReview';
import { IEditPricingReview } from '../../../../interfaces/pricing-review/IEditPricingReview';
import { IChangePricingReviewStatus } from '../../../../interfaces/pricing-review/IChangePricingReviewStatus';

const tryToFetchFilteredPricingReviews = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any
): Promise<AxiosResponse<PaginatedPricingReviews>> => {
    const url = filters
    ? `${OFFER_API_URL}/price-settings/all?page=${pageNumber}&size=${pageSize}&search=${filters}`
    : `${OFFER_API_URL}/price-settings/all?page=${pageNumber}&size=${pageSize}`;
    return offersAxios.put<PaginatedPricingReviews>(url, data);
};

const tryToAddPriceSettings = (data: IPricingReview): Promise<AxiosResponse> => {
    return offersAxios.post(`${OFFER_API_URL}/price-settings`, data);
};

const tryToFetchSinglePriceSetting = (id: string): Promise<AxiosResponse<IPricingReview>> => {
    return offersAxios.get(`${OFFER_API_URL}/price-settings/${id}`);
};

const tryToChangePriceSettingStatus = (id: string, data:IChangePricingReviewStatus): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/price-settings/${id}/status/change`, data);
};

const tryToEditPriceSetting = (id: string, data: IEditPricingReview): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/price-settings/${id}/edit`, data);
};

export const PricingReviewsService = {
    tryToFetchFilteredPricingReviews,
    tryToAddPriceSettings,
    tryToFetchSinglePriceSetting,
    tryToChangePriceSettingStatus,
    tryToEditPriceSetting
};
