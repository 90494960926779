import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IUIActionsListState } from '../../../../interfaces/ui-actions/IUIActionsListState';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IUIActionSchema } from '../../../../interfaces/ui-actions/IUIActionsSchema';
import { IUIAction } from '../../../../interfaces/ui-actions/IUIAction';
import { uiActionsService } from './uiActionsApi';
import { PaginatedUIActions } from '../../../../interfaces/ui-actions/paginatedStore.type';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { AxiosError } from 'axios';

const initialState: IUIActionsListState = {
    uiActionIsLoading: false,
    uiActionsAreLoading: false,
    requestStatus: 'default',
    message: '',
    toastSuccessRequest: false,
}

export const tryToFetchAllUIActions = createAsyncThunk<any, any
>('uiActions/tryToFetchAllUIActions', async ({ filters }) => {
    const result = await uiActionsService.tryToFetchAllUIActions(filters)
    return result?.data
});

export const tryToFetchUIActions = createAsyncThunk<
    PaginatedUIActions, IPaginationPayload
>('uiActions/tryToFetchUIActions', async ({ pageNumber, pageSize, filters }) => {
    const result = await uiActionsService.tryToFetchUIActions(pageNumber, pageSize, filters)
    return result?.data
});

export const tryToCreateUIAction = createAsyncThunk<ApiResponse<IUIActionSchema>, IUIAction>(
    'uiActions/tryToCreateUIAction',
    async (data: IUIAction, { rejectWithValue }) => {
        try {
            const result = await uiActionsService.tryToCreateUIAction(data);
            return result?.data
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message)
        }
    }
)

export const tryToEditUIAction = createAsyncThunk<ApiResponse<IUIActionSchema>, IUIAction>(
    'uiActions/tryToEditUIAction',
    async (data: IUIAction, { rejectWithValue }) => {
        try {
            const result = await uiActionsService.tryToEditUIAction(data._id || '', data);
            return result?.data
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message)
        }
    }
)


export const tryToFetchSingleUIAction = createAsyncThunk<any, string>(
    'uiActions/tryToFetchSingleUIAction',
    async (uiActionId) => {
        const result = await uiActionsService.tryToFetchSingleUIAction(uiActionId);
        return result?.data?.data;
    },
);


export const tryToDeleteUIAction = createAsyncThunk<any, string>(
    'uiActions/tryToDeleteUIAction',
    async (uiActionId, { rejectWithValue }) => {
        try {
            const result = await uiActionsService.tryToDeleteUIAction(uiActionId);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);


export const uiActionsSlice = createSlice({
    name: 'uiActions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAllUIActions
            .addCase(tryToFetchAllUIActions.pending, (state) => {
                state.uiActionsAreLoading = true
            })
            .addCase(tryToFetchAllUIActions.rejected, (state) => {
                state.uiActionsAreLoading = false
            })
            .addCase(tryToFetchAllUIActions.fulfilled, (state, action) => {
                state.uiActionsAreLoading = false
                state.allUiActions = action.payload
            })

            // tryToFetchUIActions
            .addCase(tryToFetchUIActions.pending, (state) => {
                state.uiActionsAreLoading = true
            })
            .addCase(tryToFetchUIActions.rejected, (state) => {
                state.uiActionsAreLoading = false
            })
            .addCase(tryToFetchUIActions.fulfilled, (state, action) => {
                state.uiActionsAreLoading = false
                state.uiActions = action.payload
            })

            // tryToCreateUIAction
            .addCase(tryToCreateUIAction.pending, (state) => {
                state.uiActionsAreLoading = true
            })
            .addCase(tryToCreateUIAction.rejected, (state) => {
                state.uiActionsAreLoading = false
            })
            .addCase(tryToCreateUIAction.fulfilled, (state, action) => {
                state.uiActionsAreLoading = false
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.uiActions?.data) {
                    state.uiActions.data.elements = [...state.uiActions?.data.elements, data];
                }
            })

            // tryToEditUIAction
            .addCase(tryToEditUIAction.pending, (state) => {
                state.uiActionsAreLoading = true
            })
            .addCase(tryToEditUIAction.rejected, (state) => {
                state.uiActionsAreLoading = false
            })
            .addCase(tryToEditUIAction.fulfilled, (state, action) => {
                state.uiActionsAreLoading = false
                if (state.uiActions?.data.elements)
                    state.uiActions.data.elements =
                        { ...state }.uiActions?.data.elements.map((uiAction) => {
                            if (uiAction?._id === action.payload?.data?._id) {
                                return {
                                    ...action.payload.data,
                                }
                            } else {
                                return uiAction
                            }
                        }) || [];
                state.uiActionDetails = action.payload.data;
            })

            // tryToFetchSingleUIAction
            .addCase(tryToFetchSingleUIAction.pending, (state) => {
                state.uiActionIsLoading = true
            })
            .addCase(tryToFetchSingleUIAction.rejected, (state) => {
                state.uiActionIsLoading = false
            })
            .addCase(tryToFetchSingleUIAction.fulfilled, (state, action) => {
                state.uiActionIsLoading = false
                state.uiActionDetails = action.payload
            })

            // tryToDeleteUIAction
            .addCase(tryToDeleteUIAction.pending, (state) => {
                state.uiActionsAreLoading = true
            })
            .addCase(tryToDeleteUIAction.rejected, (state) => {
                state.uiActionsAreLoading = false
            })
            .addCase(tryToDeleteUIAction.fulfilled, (state, action) => {
                state.uiActionsAreLoading = false
            })
    }
})

export default uiActionsSlice.reducer;