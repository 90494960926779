import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import { ICommissionFees } from '../../../../interfaces/commission-fees/ICommissionFees';
import { PaginatedCommissionFees } from '../../../../interfaces/commission-fees/paginatedStore.type';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';

const tryToFetchCommissionFees = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedCommissionFees>> => {
    const url = `${USERS_API_URL}/commission-fees?page=${pageNumber}&size=${pageSize}`
    return brokersAxios.put<PaginatedCommissionFees>(url, data);
};

const tryToDownloadCommissionFees = (): Promise<AxiosResponse<PaginatedCommissionFees>> => {
    const url = `${USERS_API_URL}/commission-fees`
    return brokersAxios.put<any>(url, { includePagination: false });
};

const tryToFetchSingleCommissionFee = (CommissionId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/commission-fees/${CommissionId}`);
};

const tryToAddCommissionFee = (data: ICommissionFees): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/commission-fees`, data);
};

const tryToDeleteCommissionFee = (commissionId: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/commission-fees/${commissionId}`);
};

const tryToEditCommissionFee = (commissionId: string, payload: ICommissionFees): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/commission-fees/${commissionId}`, payload);
};

export const CommissionFeesService = {
    tryToFetchCommissionFees,
    tryToFetchSingleCommissionFee,
    tryToDownloadCommissionFees,
    tryToAddCommissionFee,
    tryToDeleteCommissionFee,
    tryToEditCommissionFee
};
