import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IOrderCategories {
    markets?: Array<IAllMarkets>;
    partners?: Array<IPartners>;
    sectors?: Array<IAssetTemplate>;
    selectedMarket?: string;
    selectedPartner?: string;
    selectedSector?: string;
    onSelectFilterOption: (value: any, name?: string) => void;
}

const OrderCategories = ({
    markets,
    partners,
    sectors,
    selectedMarket,
    selectedPartner,
    selectedSector,
    onSelectFilterOption
}: IOrderCategories) => {
    const findPartner: any = (partners && selectedPartner && partners?.length > 0)&& partners?.find((item: any) => item?.value === selectedPartner)
    return (
        <div>
            <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
                <SelectCheckbox
                    name={'Market'}
                    dataQa={'market-selected'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={selectedMarket || 'Select'}
                    options={markets || []}
                    uniqueName={'market'}
                    onChangeSelectedOption={onSelectFilterOption}
                />
                <SelectCheckbox
                    name={'Partner'}
                    dataQa={'partner-selected'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={findPartner?.companyName || 'Select'}
                    options={partners || []}
                    uniqueName={'partner'}
                    onChangeSelectedOption={onSelectFilterOption}
                />
                <SelectCheckbox
                    name={'Category'}
                    dataQa={'category-selected'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={selectedSector || 'Select'}
                    options={sectors || []}
                    uniqueName={'category'}
                    onChangeSelectedOption={onSelectFilterOption}
                />
            </div>
        </div>
    )
}

export default OrderCategories;