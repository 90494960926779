import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ADDRESS_SERVICE_URL } from '../../config';

const axiosConfig: AxiosRequestConfig = {
    baseURL: `${ADDRESS_SERVICE_URL}` || 'http://localhost:3000/',
};
const addressAxios = axios.create(axiosConfig);

const logout = () => {
    // here
}

// Add a response interceptor
addressAxios.interceptors.response.use(
    (response) => {
        if (response?.data?.error === 'No permission!') logout();
        return response;
    },
    async (error) => {
        const err = { ...error };
        return Promise.reject(err);
    },
);

export const addressAxiosQuery =
    (): BaseQueryFn<{
        url: string;
        method: AxiosRequestConfig['method'];
        data?: AxiosRequestConfig['data'];
    }> =>
        async ({ url, method, data }) => {
            const baseUrl = axiosConfig.baseURL;
            try {
                const result = await addressAxios({ url: baseUrl + url, method, data });
                return { data: result.data };
            } catch (axiosError) {
                const err = axiosError as AxiosError;
                return {
                    error: { status: err.response?.status, data: err.response?.data },
                };
            }
        };

export default addressAxios;
