import React from 'react';
import FormSalesItemsTable from '../tables/FormSalesItemTable';
import DownloadSampleItems from '../details/DownloadSampleItems';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';


interface IItemsStep {
    selectedCategory?: any;
    templateFields?: any;
    inventoryFields?: any;
    filteredFields?: any;
    allRowsItems?: any;
    loadingOptions?: any;
    loadingValues?: any;
    loadingFields?: any;
    allRowsData?: any;
    filterErrors?: any;
    itemsLoading?: { row: number, loading: boolean };
    itemsGeneralError?: string;
    selectedMarket?: any;
    extendedFields?: any;
    searchInDB?: any;
    categoryFieldsWithoutExtended?: any;
    onClickImportItems: (category: any) => void;
    onChangeSelectedInventoryValue: (value: string, type?: string, index?: any) => void;
    onChangeInventoryInput: (value: string | null | undefined, type?: string, index?: any) => void;
    onMenuCloseAfterSearch: (type?: string, index?: any) => void;
    onClickSearchInDb: (type?: string) => void;
    onChangeSearchAssetFields: (e: any, type?: string, index?: any) => void;
    onChangeSelectedValue: (e: any, type?: string, index?: any) => void;
    onChangeInputValue: (e: any, type?: string, index?: any) => void;
    onClickAddRow: (category: any) => void;
    onDeleteItemRow: (id: any, categoryId: string) => void;
    onCloneRow: (id: any, categoryId: string) => void;
}

const ItemsStep = ({
    selectedCategory,
    templateFields,
    inventoryFields,
    filteredFields,
    filterErrors,
    allRowsItems,
    allRowsData,
    itemsLoading,
    itemsGeneralError,
    selectedMarket,
    loadingOptions,
    loadingValues,
    loadingFields,
    searchInDB,
    extendedFields,
    categoryFieldsWithoutExtended,
    onClickImportItems,
    onChangeSelectedInventoryValue,
    onChangeSearchAssetFields,
    onChangeInventoryInput,
    onChangeSelectedValue,
    onChangeInputValue,
    onClickAddRow,
    onClickSearchInDb,
    onDeleteItemRow,
    onCloneRow,
    onMenuCloseAfterSearch
}: IItemsStep) => {
    return (
        <div className='min-w-[100%] bg-white p-3'>
            <p className='font-bold mb-2'>Items</p>
            <p className='mb-5'>
                {!(selectedCategory && selectedCategory?.length > 0)
                    ? 'To continue adding items, please select categories first.'
                    : 'Register all items and their informations. On the table below you can write item details and add multiple items if needed.'
                }
            </p>
            {itemsGeneralError && <Error text={itemsGeneralError} />}
            <div>
                {(selectedCategory && selectedCategory?.length > 0) && selectedCategory?.map((item: any, index: number) => {
                    const indexedItems = allRowsItems && allRowsItems?.length > 0 && allRowsItems?.map((row: any, idx: number) => ({ ...row, index: idx }))
                    const indexedData = allRowsData && allRowsData?.length > 0 && allRowsData?.map((row: any, idx: number) => ({ ...row, index: idx }))
                    const rowItems = indexedItems && indexedItems?.length > 0 && indexedItems?.filter((row: any) => row?.categoryId === item?.id)
                    const rowsData = indexedData && indexedData?.length > 0 && indexedData?.filter((row: any) => row?.categoryId === item?.id)
                    const categoryInventoryFields = inventoryFields?.[item?.name]
                    const categoryTemplateFields = templateFields?.[item?.name]
                    const fieldsNameInventory = categoryInventoryFields && categoryInventoryFields?.length > 0 && categoryInventoryFields?.map((category: any) => category?.name)
                    const fieldsNames = templateFields?.[item?.name] && Object.keys(templateFields?.[item?.name])
                    const allFields = fieldsNames ? [...fieldsNames || [], ...fieldsNameInventory || [], 'quantity', 'price'] : []
                    const categoryErrors = filterErrors && filterErrors?.length > 0 && filterErrors?.filter((error: any) => error?.categoryId === item?.id)
                    return (
                        <div key={index} className='border-b-2 border-[#e4e4e4]'>
                            <div className='flex flex-row items-center justify-between mt-3'>
                                <div className='flex flex-row items-center'>
                                    <img src={item?.enabledIcon} className='object-contain h-[26px] min-h-[26px]' />
                                    <p className='text-[16px] ml-1'>{item?.label}</p>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <DownloadSampleItems
                                        allFields={allFields}
                                        templateFields={categoryFieldsWithoutExtended?.[item?.name]}
                                        inventoryFields={categoryInventoryFields}
                                        extendedFields={extendedFields?.[item?.name]}
                                        item={item}
                                    />
                                    <Button
                                        label='Import Items'
                                        className='btn-main ml-3'
                                        onClickButton={() => onClickImportItems(item)}
                                    />
                                </div>
                            </div>
                            <FormSalesItemsTable
                                category={item}
                                columns={fieldsNames}
                                categoryFieldsWithoutExtended={categoryFieldsWithoutExtended}
                                templateData={categoryTemplateFields}
                                inventoryFields={categoryInventoryFields}
                                selectedMarket={selectedMarket}
                                itemsLoading={itemsLoading}
                                filterErrors={categoryErrors}
                                filterValues={filteredFields}
                                allRowsItems={rowItems}
                                allRowsData={rowsData}
                                loadingFields={loadingFields}
                                loadingValues={loadingValues}
                                loadingOptions={loadingOptions}
                                searchInDB={searchInDB}
                                onChangeSelectedInventoryValue={onChangeSelectedInventoryValue}
                                onChangeSearchAssetFields={onChangeSearchAssetFields}
                                onChangeInventoryInput={onChangeInventoryInput}
                                onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                                onChangeSelectedValue={onChangeSelectedValue}
                                onChangeInputValue={onChangeInputValue}
                                onClickSearchInDb={onClickSearchInDb}
                                onDeleteItemRow={onDeleteItemRow}
                                onClickAddRow={onClickAddRow}
                                onCloneRow={onCloneRow}
                                rowsLength={1}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default ItemsStep;
