import React from 'react';
import Button from '../../../../shared/button';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IComparisonPricingAssetFilters {
    items: Array<any>;
    filterValues: any;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onChangeInput: (value: string | null | undefined, type: string) => void;
    onClearAllFilters: () => void;
}

const ComparisonPricingAssetFilters = ({
    items,
    filterValues,
    onChangeSelectedValue,
    onClearAllFilters
}: IComparisonPricingAssetFilters) => {
    return (
        <div>
            <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Filters</p>
            <div className='my-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-7 gap-1 sm:gap-2 lg:gap-4'>
                {items && items?.length > 0 && items?.filter((item) => item?.type === 'boolean' || item?.type === 'list')?.map((item, index) => {
                    let values: any = item?.options;
                    if (item?.type === 'boolean') {
                        values = [
                            { value: true, name: 'True' },
                            { value: false, name: 'False' },
                        ];
                    } else if (item?.type === 'list') {
                        values =
                            item?.options &&
                            item?.options?.length > 0 &&
                            values?.map((value: string) => {
                                return { value: value, label: value };
                            });
                    }
                    return (
                        <SelectCheckbox
                            key={index}
                            name={item?.fieldName && `${item?.fieldName?.[0].toUpperCase()}${item?.fieldName?.slice(1).toLowerCase()}`}
                            dataQa={item?.fieldName}
                            placeholder={filterValues?.[item?.fieldName] || ' '}
                            options={values}
                            selectStyle={{ backgroundColor: 'transparent', borderRadius: '15px' }}
                            onChangeSelectedOption={onChangeSelectedValue}
                            uniqueName={item?.fieldName || ''}
                        />
                    )
                })}
            </div>
            <div className='flex flex-row justify-end my-1'>
                <Button
                    label='Clear all filters'
                    dataQa={'clear-all-filters'}
                    className='btn-primary-text-underline min-w-auto col-span-1 col-end-8 flex flex-row justify-end items-center'
                    onClickButton={onClearAllFilters}
                />
            </div>
        </div>
    )
}

export default ComparisonPricingAssetFilters;