import React, { useState, useCallback } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone'
import { useAppDispatch } from '../../../../app/hooks';
import { tryToImportAssetData } from '../../../../store/brokers/admin/assets/assetsSlice';
import ImportAssetsSuccess from '../details/ImportAssetsSuccess';
import ImportAssetsCsv from '../details/ImportAssetsCsv';
import Modal from '../../../../shared/modal';


interface IImportAssetsModal {
    openImportModal: boolean;
    assetTemplateId: string;
    handleCloseModal: () => void;
    onImportAssetsSuccessfully: () => void;
}

const ImportAssetsModal = ({
    openImportModal,
    assetTemplateId,
    handleCloseModal,
    onImportAssetsSuccessfully
}: IImportAssetsModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [fileSelected, setFileSelected] = useState<any>()

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onCloseModal = () => {
        handleCloseModal();
        setImportedSuccessfully(false);
        setImportError(undefined);
        setFileSelected(undefined)
    }

    const parseData = (assets: any) => {
        const headers = assets?.[0];
        const keys = Object.values(headers).map((header: any) => header?.toLowerCase());
        const result = assets?.slice(1)
            .filter((asset: any) => Object.keys(asset).length > 0)
            .map((asset: any) => {
                return keys?.reduce((obj, key, index) => {
                    obj[key] = asset[`Column${index + 1}`] || undefined;
                    return obj;
                }, {});
            });
        return result;
    };

    const onUploadFile = async () => {
        try {
            const fileExtension = fileSelected.name.split('.').pop().toLowerCase();
            if (fileExtension === 'csv') {
                Papa.parse(fileSelected, {
                    header: true,
                    skipEmptyLines: true,
                    complete: async function (results) {
                        const payload = {
                            assetTemplateId: assetTemplateId,
                            assets: results?.data || []
                        };
                        await dispatch(tryToImportAssetData(payload)).unwrap();
                        setImportedSuccessfully(true);
                        onImportAssetsSuccessfully();
                        setImportError(undefined)
                    }
                });
            } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                const reader = new FileReader();
                reader.onload = async (e: any) => {
                    const data = new Uint8Array(e?.target?.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const mergedRanges = sheet['!merges'] || [];
                    const rawExcelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    const excelData = rawExcelData.map((row: any, rowIndex: any) => {
                        const isMergedRow = mergedRanges.some(merge =>
                            rowIndex >= merge.s.r && rowIndex <= merge.e.r
                        );
                        if (isMergedRow) return null;
                        return row?.reduce((acc: any, cell: any, index: any) => {
                            const columnKey = `Column${index + 1}`;
                            acc[columnKey] = cell !== '' && cell != null ? cell : undefined;
                            return acc;
                        }, {});
                    })?.filter(row => row !== null);
                    const payload = {
                        assetTemplateId: assetTemplateId,
                        assets: parseData(excelData || [])
                    };
                    await dispatch(tryToImportAssetData(payload)).unwrap();
                    setImportedSuccessfully(true);
                    onImportAssetsSuccessfully();
                    setImportError(undefined)
                };
                reader.readAsArrayBuffer(fileSelected);
            } else {
                setImportError('Unsupported file format. Please upload a CSV or Excel file.');
            }
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
    }

    return (
        <Modal
            open={openImportModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <ImportAssetsSuccess
                        handleCloseModal={onCloseModal}
                    />
                    : <ImportAssetsCsv
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                    />
                }
            </div>
        </Modal>
    )
}

export default ImportAssetsModal;