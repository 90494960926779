import React from 'react';


const NotificationSettingsContainer = () => {
    return (
        <div>
            {[...Array(2)].map((x, i) => {
                return (
                    <>
                        <div className='my-2 w-full animate-pulse'>
                            <div className='h-[30px] animate-pulse bg-gray-200 rounded dark:bg-gray-200 w-[100px] mb-4'></div>
                            <div className='grid grid-cols-2 items-center animate-pulse mb-2'>
                                <div className='h-[30px] bg-gray-200 rounded dark:bg-gray-200 w-[80%] mb-1'></div>
                                <div className='h-[30px] bg-gray-200 rounded-full dark:bg-gray-200 w-[30px] mb-1'></div>
                            </div>
                            <div className='grid grid-cols-2 items-center animate-pulse mb-2'>
                                <div className='h-[30px] bg-gray-200 rounded dark:bg-gray-200 w-[80%] mb-1'></div>
                                <div className='h-[30px] bg-gray-200 rounded-full dark:bg-gray-200 w-[30px] mb-1'></div>
                            </div>
                        </div>
                        <div className='mb-5 mt-3 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )
            })
            }
        </div>
    )
}

export default NotificationSettingsContainer;