import React from 'react';
import Button from '../../../../shared/button';


interface ICategoriesButtons {
    categories?: Array<any>;
    activeCategory?: any;
    onChangeActiveCategory: (category: any) => void;
}

const CategoriesButtons = ({
    categories,
    activeCategory,
    onChangeActiveCategory
}: ICategoriesButtons) => {
    return (
        <div className='flex flex-row items-center my-5'>
            {categories && categories?.length > 0 && categories?.map((category, index) => {
                return (
                    <Button
                        key={index}
                        label={category?.name}
                        dataQa={`${category?.name}`}
                        className={activeCategory?.name === category?.name ? 'btn-categories ml-2' : 'btn-noactive-categories ml-2'}
                        onClickButton={() => onChangeActiveCategory(category)}
                    />
                )
            })}
        </div>
    )
}

export default CategoriesButtons;