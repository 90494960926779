import React from 'react';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface ISuccessRegisterModal {
    openModal: boolean;
    handleCloseModal: () => void;
}

const SuccessRegisterModal = ({
    openModal,
    handleCloseModal
}: ISuccessRegisterModal) => {
    return (
        <Modal
            open={openModal}
            dataQa={'success-register-modal'}
            contentContainerStyle={'!bg-gradient-to-tl from-[#FCEE21] to-[#F7931E] rounded-[8px]'}
            newClose={<img src={'/assets/modals/close-button-black.svg'} className='object-contain w-[22px]' />}
            onClose={handleCloseModal}>
            <div className={'p-2 min-w-[400px] max-w-[450px] flex flex-col items-center justify-between'}>
                <img src={'/assets/auth/wingpro-black.svg'} className={'w-[150px] object-contain my-1'} />
                <img src={'/assets/modals/money-icon.svg'} className={'h-[150px] object-contain'} />
                <div>
                    <p className='text-md font-bold text-black text-center mb-5'>Thank you for applying!</p>
                    <p className='text-md text-black text-center mb-5'>We’re reviewing your account and one of our agents will send you an email shortly on your application.</p>
                </div>
                <Button
                    label={<span className='text-[#FBDD21]'>Close</span>}
                    data-qa={'close'}
                    className='btn-black-rounded-full my-3 min-w-[150px] !shadow-none'
                    onClickButton={handleCloseModal}
                />
            </div>
        </Modal>
    )
}
export default SuccessRegisterModal;