import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IToast } from '../../../interfaces/components/IToast';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { ISubBrand } from '../../../interfaces/sub-brands/ISubBrand';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import {
    tryToDisableSubBrands,
    tryToFetchPartnerSubBrands
} from '../../../store/brokers/admin/sub-brands/subBrandsSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFetchAllPartners } from '../../../store/brokers/admin/partners/partnersSlice';
import SubBrandsTable from './tables/SubBrandsTable';
import SubBrandsModal from './modals/SubBrandsModal';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import DeleteModal from '../../../shared/delete-modal';
import EmptyContainer from '../../../shared/empty-container';


const SubBrands = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams()
    const [rows, setRows] = useState<ISubBrand[]>([]);
    const [partners, setPartners] = useState<IPartners[]>()
    const [markets, setMarkets] = useState<IAllMarkets[]>()
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [brandItemId, setBrandItemId] = useState<string | null>();
    const [showSubBrandItemModal, setShowSubBrandItemModal] = useState<boolean>(false);
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.partnerNavigation);
    const rowsLoading = useAppSelector((state) => state.subBrands.subBrandAreLoading);
    const state = useAppSelector((state) => state.subBrands);
    const [getAllMarkets] = useGetAllMarketsMutation()

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Partner Sub Brands')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.partnerSubBrands) {
            const rows = state.partnerSubBrands
            setRows(rows);
        }
    }, [state.partnerSubBrands]);

    const getPartnerAndMarketData = async () => {
        try {
            const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
            const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.username, value: partner?._id, name: partner?.name }))
            setPartners(formatPartners || [])
            const marketResponse = await getAllMarkets(null).unwrap()
            const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id, displayName: market?.name }))
            setMarkets(formatMarkets || [])
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        getPartnerAndMarketData()
    }, [])

    const onAddNewSubBrandItem = () => {
        setShowSubBrandItemModal(true)
        setBrandItemId(undefined)
    };

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const onDeleteSubBrand = async () => {
        try {
            await dispatch(tryToDisableSubBrands({ id: brandItemId || '', partnerId: id }));
            setShowToast({
                type: 'success',
                message: `Sub Brand successfully deleted`,
            });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setBrandItemId(undefined);
        setShowDeleteModal(false);
    };

    const onAddSubBrand = (id?: string) => {
        if (id) {
            setBrandItemId(id);
        }
        setShowSubBrandItemModal(true);
    };

    const onCloseSubBrandModal = () => {
        setBrandItemId(null);
        setShowSubBrandItemModal(false);
    };

    const onSaveChanges = (type: string, message: string) => {
        setShowToast({ type, message });
    };

    const onDeleteRequest = (id: string) => {
        setShowDeleteModal(true);
        setBrandItemId(id);
    };

    useEffect(() => {
        dispatch(tryToFetchPartnerSubBrands({ partnerId: id || '' }));
    }, [id]);

    return (
        <div>
            <div>
                <p className='page-title'>Sub Brands</p>
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New Sub Brand'}
                    className={`${(!accessControl || pageAccess?.actions?.['Add New Sub Brand']) ? 'btn-main' : 'btn-main-disable'}`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Sub Brand']) && onAddNewSubBrandItem()}
                    dataQa={'add-sub-button'}
                />
            </div>
            <div className='my-5 relativ'>
                {(rows?.length > 0 || rowsLoading) ?
                    <SubBrandsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        onDeleteSubBrandRequest={onDeleteRequest}
                        onEditSubBrand={onAddSubBrand}
                    />
                    : <EmptyContainer
                        text={'No Sub Brand added yet.'}
                        showImage={true}
                    />
                }
            </div>
            <SubBrandsModal
                partners={partners}
                partnerId={id || ''}
                subBrandId={brandItemId}
                markets={markets}
                openModal={showSubBrandItemModal}
                handleCloseModal={onCloseSubBrandModal}
                onSaveChanges={onSaveChanges}
            />
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeleteSubBrand}
            />
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
        </div>
    );
};

export default SubBrands;