import { AxiosResponse } from 'axios';
import { OFFER_API_URL } from '../../../../config';
import { PaginatedCategoryMappings } from '../../../../interfaces/category-mappings/paginatedStore.type';
import offersAxios from '../../../../utils/axios/offers.axios';


const tryToFetchCategoryMappingsWithoutPagination = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${OFFER_API_URL}/offers-settings/all`
    return offersAxios.put<any>(url, data);
};

const tryToFetchCategoryMappings = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any
): Promise<AxiosResponse<PaginatedCategoryMappings>> => {
    const url = `${OFFER_API_URL}/offers-settings/data?page=${pageNumber}&size=${pageSize}`
    return offersAxios.put<PaginatedCategoryMappings>(url, data);
};

const tryToCreateOrUpdateCategoryMapping = (data: any): Promise<AxiosResponse<any>> => {
    const url = `${OFFER_API_URL}/offers-settings`
    return offersAxios.put(url, data);
};

const tryToEnableDisableCategoryMapping = (id: string, active: boolean): Promise<AxiosResponse> => {
    return offersAxios.put(`${OFFER_API_URL}/offers-settings/${id}/${active}`);
};

const tryToFetchSingleCategoryMapping = (id: string): Promise<AxiosResponse> => {
    return offersAxios.get(`${OFFER_API_URL}/offers-settings/${id}`);
};

export const CategoryMappingsService = {
    tryToFetchCategoryMappingsWithoutPagination,
    tryToFetchCategoryMappings,
    tryToCreateOrUpdateCategoryMapping,
    tryToEnableDisableCategoryMapping,
    tryToFetchSingleCategoryMapping
};