import React, { useState } from 'react';
import MissingDetailsModal from '../modals/MissingDetailsModal';
import WhiteContainer from '../../../../shared/white-container';
import Button from '../../../../shared/button';


interface IMissingItemDetails {
    missingImages?: Array<string>;
    missingDate?: string;
}

const MissingItemDetails = ({
    missingImages,
    missingDate
}: IMissingItemDetails) => {
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false)

    const onToggleMissingDetailsModal = () => {
        if (showDetailsModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowDetailsModal(!showDetailsModal)
    }

    return (
        <WhiteContainer containerStyle='col-span-1 !rounded'>
            <div className='flex flex-col items-center py-4'>
                <img src={'/assets/order-actions/missing.svg'} className='w-[45%] object-contain' />
                <p className='text-2xl font-bold text-center my-4'>Package sent didn&apos;t have this item included in it.</p>
                <Button
                    label={'More Details'}
                    className={'btn-primary-text-underline !text-[#000000]'}
                    onClickButton={onToggleMissingDetailsModal}
                />
            </div>
            {showDetailsModal &&
                <MissingDetailsModal
                    openModal={showDetailsModal}
                    missingDate={missingDate}
                    missingImages={missingImages}
                    handleCloseModal={onToggleMissingDetailsModal}
                />
            }
        </WhiteContainer>
    );
};

export default MissingItemDetails;
